const searchFor = (word, array, page) => {
    let key = "rowName";
    if (page === "unit") key = "unitName";
    if (page === "shelf") key = "shelfName";

    return Array.isArray(array) &&  array.filter(function (e) {
      return e[key].toLowerCase().includes(word.toLowerCase());
    });
  };

  export { searchFor }