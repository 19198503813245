import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { CommonSuccessReponce, LogsSuccessReponce, RetailsSuccessReponce, SingleProductSliceI } from "apps/product/modules/productDetailsContainer/types";

const initialState: SingleProductSliceI = {
  getSingleProduct: false,
  getSingleProductData: null,
  getSingleProductError: null,
  updateBasicProduct: false,
  updateBasicProductData: null,
  updateBasicProductError: null,
  updateSinglePendingProduct: false,
  updateSinglePendingProductData: null,
  updateSinglePendingProductError: null,
  updateMeasurementsProduct: false,
  updateMeasurementsProductData: null,
  updateMeasurementsProductError: null,
  updateIdentifiersProduct: false,
  updateIdentifiersProductData: null,
  updateIdentifiersProductError: null,
  updateFlagesProduct: false,
  updateFlagesProductData: null,
  updateFlagesProductError: null,
  updateSeoProduct: false,
  updateSeoProductData: null,
  updateSeoProductError: null,
  updateImagesProduct: false,
  updateImagesProductData: null,
  updateImagesProductError: null,
  updateCostAndPriceProduct: false,
  updateCostAndPriceProductData: null,
  updateCostAndPriceProductError: null,
  getCategoriesMenuProductData: null,
  getCategoriesMenuProduct: false,
  getCategoriesMenuProductError: null,
  getLogsProduct: false,
  getLogsProductData : null,
  getLogsProductError: null,
  isRetailPriceScedulesProduct : false,
  getRetailPriceScedulesProductData : null,
  getRetailPriceScedulesProductError : null,
  removeClearancePriceProductData : null,
  isRemoveClearancePriceProduct : false,
  removeClearancePriceProductError: null,
  removeDiscontinuedPriceProductData : null ,
  isDiscontinuedPriceProduct : false,
  removeDiscontinuedPriceProductError : null,
  updateRetailPriceScedulesProductError: null,
  updateRetailPriceScedulesProductData : null,
  isUpdateRetailPriceScedulesProduct : false,
  deletePriceScedulesProductError : null,
  deletePriceScedulesProductData : null,
  isDeletePriceScedulesProduct : false,
  addMinMaxBatchProductData:null,
  isAddMinMaxBatchProduct: false,
  addMinMaxBatchProductError :null,
  removeMinMaxBatchProductData: null,
  isRemoveMinMaxBatchProduct: false,
  removeMinMaxBatchProductError :null,
  getProductTicketsCountError : null,
  getProductTicketsCountData : null,
  isProductTicketsCount : false,
  isUpdateOverviewProduct : false,
  updateOverviewProductData : null,
  updateOverviewProductError : null
};

export const singleProductSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    getSingleProductStart: (state) => {
      state.getSingleProduct = true;
      state.getSingleProductData = null;
      state.updateBasicProduct = false;
      state.updateMeasurementsProduct = false;
      state.updateIdentifiersProduct = false;
      state.updateFlagesProduct = false,
      state.updateSeoProduct = false,
      state.updateImagesProduct = false,
      state.updateBasicProductData = null;
      state.updateMeasurementsProductData = null;
      state.updateCostAndPriceProductData = null;
      state.updateIdentifiersProductData = null;
      state.updateFlagesProductData = null;
      state.updateSeoProductData = null;
      state.updateImagesProductData = null;
      state.removeDiscontinuedPriceProductData = null;
      state.removeClearancePriceProductData = null;
      state.isRemoveClearancePriceProduct = false;
      state.isDiscontinuedPriceProduct = false;
      state.isDeletePriceScedulesProduct = false;
      state.isUpdateRetailPriceScedulesProduct = false;
      state.updateRetailPriceScedulesProductData = null;
      state.isProductTicketsCount = false;
      state.isUpdateOverviewProduct = false;
      state.updateOverviewProductData = null;
      state.updateOverviewProductError = null;

    },
    getSingleProductSuccess: (state, action: PayloadAction<any>) => {
      state.getSingleProductData = action.payload;
      state.getSingleProduct = false;
    },
    getSingleProductError: (state, action: PayloadAction<any>) => {
      state.getSingleProductError = action.payload;
      state.getSingleProductData = null;
      state.getSingleProduct = false;
    },
    updateBasicProductStart: (state) => {
      state.updateBasicProductData = null;
      state.updateBasicProduct = true;
    },
    updateBasicProductSuccess: (state, action: PayloadAction<any>) => {
      state.updateBasicProductData = action.payload;
      state.updateBasicProduct = false;
    },
    updateBasicProductError: (state, action: PayloadAction<any>) => {
      state.updateBasicProductError = action.payload;
      state.updateBasicProductData = null;
      state.updateBasicProduct = false;
    },
    updateSinglePendingProductStart: (state) => {
      state.updateSinglePendingProductData = null;
      state.updateSinglePendingProduct = true;
    },
    updateSinglePendingProductSuccess: (state, action: PayloadAction<any>) => {
      state.updateSinglePendingProductData = action.payload;
      state.updateSinglePendingProduct = false;
    },
    updateSinglePendingProductError: (state, action: PayloadAction<any>) => {
      state.updateSinglePendingProductError = action.payload;
      state.updateSinglePendingProductData = null;
      state.updateSinglePendingProduct = false;
    },
    updateMeasurementsProductStart: (state) => {
      state.updateMeasurementsProductData = null;
      state.updateMeasurementsProduct = true;
    },
    updateMeasurementsProductSuccess: (state, action: PayloadAction<any>) => {
      state.updateMeasurementsProductData = action.payload;
      state.updateMeasurementsProduct = false;
    },
    updateMeasurementsProductError: (state, action: PayloadAction<any>) => {
      state.updateMeasurementsProductError = action.payload;
      state.updateMeasurementsProductData = null;
      state.updateMeasurementsProduct = false;
    },
    updateIdentifiersProductStart: (state) => {
      state.updateIdentifiersProductData = null;
      state.updateIdentifiersProduct = true;
    },
    updateIdentifiersProductSuccess: (state, action: PayloadAction<any>) => {
      state.updateIdentifiersProductData = action.payload;
      state.updateIdentifiersProduct = false;
    },
    updateIdentifiersProductError: (state, action: PayloadAction<any>) => {
      state.updateIdentifiersProductError = action.payload;
      state.updateIdentifiersProductData = null;
      state.updateIdentifiersProduct = false;
    },
    updateFlagesProductStart: (state) => {
      state.updateFlagesProductData = null;
      state.updateFlagesProduct = true;
    },
    updateFlagesProductSuccess: (state, action: PayloadAction<any>) => {
      state.updateFlagesProductData = action.payload;
      state.updateFlagesProduct = false;
    },
    updateFlagesProductError: (state, action: PayloadAction<any>) => {
      state.updateFlagesProductError = action.payload;
      state.updateFlagesProductData = null;
      state.updateFlagesProduct = false;
    },
    updateSeoProductStart: (state) => {
      state.updateSeoProductData = null;
      state.updateSeoProduct = true;
    },
    updateSeoProductSuccess: (state, action: PayloadAction<any>) => {
      state.updateSeoProductData = action.payload;
      state.updateSeoProduct = false;
    },
    updateSeoProductError: (state, action: PayloadAction<any>) => {
      state.updateSeoProductError = action.payload;
      state.updateSeoProductData = null;
      state.updateSeoProduct = false;
    },
    updateImagesProductStart: (state) => {
      state.updateImagesProductData = null;
      state.updateImagesProduct = true;
    },
    updateImagesProductSuccess: (state, action: PayloadAction<any>) => {
      state.updateImagesProductData = action.payload;
      state.updateImagesProduct = false;
    },
    updateImagesProductError: (state, action: PayloadAction<any>) => {
      state.updateImagesProductError = action.payload;
      state.updateImagesProductData = null;
      state.updateImagesProduct = false;
    },
    updateCostAndPriceProductStart: (state) => {
      state.updateCostAndPriceProductData = null;
      state.updateCostAndPriceProduct = true;
    },
    updateCostAndPriceProductSuccess: (state, action: PayloadAction<CommonSuccessReponce>) => {
      state.updateCostAndPriceProductData = action.payload;
      state.updateCostAndPriceProduct = false;
    },
    updateCostAndPriceProductError: (state, action: PayloadAction<any>) => { // TODO : changes once i get responce
      state.updateCostAndPriceProductError = action.payload;
      state.updateCostAndPriceProductData = null;
      state.updateCostAndPriceProduct = false;
    },
    getCategoriesMenuProductStart: (state) => {
      state.getCategoriesMenuProductData = null;
      state.getCategoriesMenuProduct = true;
    },
    getCategoriesMenuProductSuccess: (state, action: PayloadAction<CommonSuccessReponce>) => {
      state.getCategoriesMenuProductData = action.payload;
      state.getCategoriesMenuProduct = false;
    },
    getCategoriesMenuProductError: (state, action: PayloadAction<any>) => { // TODO : changes once i get responce
      state.getCategoriesMenuProductError = action.payload;
      state.getCategoriesMenuProductData = null;
      state.getCategoriesMenuProduct = false;
    },
    getLogsProductStart: (state) => {
      state.getLogsProductData = null;
      state.getLogsProduct = true;
    },
    getLogsProductSuccess: (state, action: PayloadAction<LogsSuccessReponce>) => {
      state.getLogsProductData = action.payload;
      state.getLogsProduct = false;
    },
    getLogsProductError: (state, action: PayloadAction<any>) => { // TODO : changes once i get responce
      state.getLogsProductError = action.payload;
      state.getLogsProductData = null;
      state.getLogsProduct = false;
    },
    getRetailPriceScedulesProductStart: (state) => {
      state.isRetailPriceScedulesProduct = true;
      state.updateRetailPriceScedulesProductData = null;
    },
    getRetailPriceScedulesProductSuccess: (state, action: PayloadAction<RetailsSuccessReponce>) => {
      state.getRetailPriceScedulesProductData = action.payload;
      state.isRetailPriceScedulesProduct = false;
    },
    getRetailPriceScedulesProductError: (state, action: PayloadAction<any>) => { // TODO : changes once i get responce
      state.getRetailPriceScedulesProductError = action.payload;
      state.getRetailPriceScedulesProductData = null;
      state.isRetailPriceScedulesProduct = false;
    },
    removeClearancePriceProductStart: (state) => {
      state.removeClearancePriceProductData = null;
      state.isRemoveClearancePriceProduct = true;
    },
    removeClearancePriceProductSuccess: (state, action: PayloadAction<any>) => {
      state.removeClearancePriceProductData = action.payload;
      state.isRemoveClearancePriceProduct = false;
    },
    removeClearancePriceProductError: (state, action: PayloadAction<any>) => { // TODO : changes once i get responce
      state.removeClearancePriceProductError = action.payload;
      state.removeClearancePriceProductData = null;
      state.isRemoveClearancePriceProduct = false;
    },
    removeDiscontinuedPriceProductStart: (state) => {
      state.removeDiscontinuedPriceProductData = null;
      state.isDiscontinuedPriceProduct = true;
    },
    removeDiscontinuedPriceProductSuccess: (state, action: PayloadAction<any>) => {
      state.removeDiscontinuedPriceProductData = action.payload;
      state.isDiscontinuedPriceProduct = false;
    },
    removeDiscontinuedPriceProductError: (state, action: PayloadAction<any>) => { // TODO : changes once i get responce
      state.removeDiscontinuedPriceProductError = action.payload;
      state.removeDiscontinuedPriceProductData = null;
      state.isDiscontinuedPriceProduct = false;
    },
    updateSingleProductDetail : (state , action) =>{
      state.getSingleProductData = action.payload;
    },
    updateRetailPriceScedulesProductStart: (state) => {
      state.updateRetailPriceScedulesProductData = null;
      state.isUpdateRetailPriceScedulesProduct = true;
    },
    updateRetailPriceScedulesProductSuccess: (state, action) => {
      state.updateRetailPriceScedulesProductData = action.payload;
      state.isUpdateRetailPriceScedulesProduct = false;
    },
    updateRetailPriceScedulesProductError: (state, action) => {
      state.updateRetailPriceScedulesProductError = action.payload;
      state.updateRetailPriceScedulesProductData = null;
      state.isUpdateRetailPriceScedulesProduct = false;
    },
    deletePriceScedulesProductStart: (state) => {
      state.deletePriceScedulesProductData = null;
      state.isDeletePriceScedulesProduct = true;
    },
    deletePriceScedulesProductSuccess: (state, action) => {
      state.deletePriceScedulesProductData = action.payload;
      state.isDeletePriceScedulesProduct = false;
    },
    deletePriceScedulesProductError: (state, action) => {
      state.deletePriceScedulesProductError = action.payload;
      state.deletePriceScedulesProductData = null;
      state.isDeletePriceScedulesProduct = false;
    },
    addMinMaxBatchProductStart: (state) => {
      state.addMinMaxBatchProductData = null;
      state.isAddMinMaxBatchProduct = true;
    },
    addMinMaxBatchProductSuccess: (state, action) => {
      state.addMinMaxBatchProductData = action.payload;
      state.isAddMinMaxBatchProduct = false;
    },
    addMinMaxBatchProductError: (state, action) => {
      state.addMinMaxBatchProductError = action.payload;
      state.addMinMaxBatchProductData = null;
      state.isAddMinMaxBatchProduct = false;
    },
    clearMinMaxBatchProductStart: (state) => {
      state.addMinMaxBatchProductData = null;
      state.isAddMinMaxBatchProduct = false;
      state.addMinMaxBatchProductError = null;
    },
    removeMinMaxBatchProductStart: (state) => {
      state.removeMinMaxBatchProductData = null;
      state.isRemoveMinMaxBatchProduct = true;
    },
    removeMinMaxBatchProductSuccess: (state, action) => {
      state.removeMinMaxBatchProductData = action.payload;
      state.isRemoveMinMaxBatchProduct = false;
      state.removeMinMaxBatchProductError = null;
    },
    removeMinMaxBatchProductError: (state, action) => {
      state.removeMinMaxBatchProductError = action.payload;
      state.removeMinMaxBatchProductData = null;
      state.isRemoveMinMaxBatchProduct = false;
    },
    getProductTicketsCountStart: (state) => {
      state.getProductTicketsCountData = null;
      state.isProductTicketsCount = true;
    },
    getProductTicketsCountSuccess: (state, action: PayloadAction<any>) => {
      state.getProductTicketsCountData = action.payload;
      state.isProductTicketsCount = false;
    },
    getProductTicketsCountError: (state, action: PayloadAction<any>) => {
      state.getProductTicketsCountError = action.payload;
      state.getProductTicketsCountData = null;
      state.isProductTicketsCount = false;
    },
    updateOverviewProductStart: (state) => {
      state.updateOverviewProductData = null;
      state.isUpdateOverviewProduct = true;
    },
    updateOverviewProductSuccess: (state, action: PayloadAction<any>) => {
      state.updateOverviewProductData = action.payload;
      state.isUpdateOverviewProduct = false;
    },
    updateOverviewProductError: (state, action: PayloadAction<any>) => {
      state.updateOverviewProductError = action.payload;
      state.updateOverviewProductData = null;
      state.isUpdateOverviewProduct = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getSingleProductStart,
  getSingleProductSuccess,
  getSingleProductError,
  updateBasicProductStart,
  updateBasicProductSuccess,
  updateBasicProductError,
  updateSinglePendingProductStart,
  updateSinglePendingProductSuccess,
  updateSinglePendingProductError,
  updateMeasurementsProductStart,
  updateMeasurementsProductSuccess,
  updateMeasurementsProductError,
  updateIdentifiersProductStart,
  updateIdentifiersProductSuccess,
  updateIdentifiersProductError,
  updateFlagesProductStart,
  updateFlagesProductSuccess,
  updateFlagesProductError,
  updateSeoProductStart,
  updateSeoProductSuccess,
  updateSeoProductError,
  updateImagesProductStart,
  updateImagesProductSuccess,
  updateImagesProductError,
  updateCostAndPriceProductStart,
  updateCostAndPriceProductSuccess,
  updateCostAndPriceProductError,
  getCategoriesMenuProductStart,
  getCategoriesMenuProductSuccess,
  getCategoriesMenuProductError,
  getLogsProductError,
  getLogsProductSuccess,
  getLogsProductStart,
  getRetailPriceScedulesProductError,
  getRetailPriceScedulesProductSuccess,
  getRetailPriceScedulesProductStart,
  removeClearancePriceProductStart,
  removeClearancePriceProductSuccess,
  removeClearancePriceProductError,
  removeDiscontinuedPriceProductStart,
  removeDiscontinuedPriceProductSuccess,
  removeDiscontinuedPriceProductError,
  updateSingleProductDetail,
  updateRetailPriceScedulesProductError,
  updateRetailPriceScedulesProductSuccess,
  updateRetailPriceScedulesProductStart,
  deletePriceScedulesProductSuccess,
  deletePriceScedulesProductError,
  deletePriceScedulesProductStart,
  addMinMaxBatchProductStart,
  addMinMaxBatchProductSuccess,
  addMinMaxBatchProductError,
  clearMinMaxBatchProductStart,
  removeMinMaxBatchProductStart,
  removeMinMaxBatchProductSuccess,
  removeMinMaxBatchProductError,
  getProductTicketsCountStart,
  getProductTicketsCountSuccess,
  getProductTicketsCountError,
  updateOverviewProductStart,
  updateOverviewProductSuccess,
  updateOverviewProductError,
} = singleProductSlice.actions;

export default singleProductSlice.reducer;
