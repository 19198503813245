import { motion, MotionConfig } from 'framer-motion/dist/framer-motion';

import ReturnToLineItem from '../ReturnToLineItem';

import './ReturnToGroup.scss';

const ReturnToGroup = ({categoryMenu , returnTo , setReturnTo }) => {

    return (
        <>
            <MotionConfig
                transition={{
                    type: 'spring',
                    duration: 0.25,
                    bounce: 0,
                }}
            >
                <motion.div className="return-to-line-items-product " layout>
                        <ReturnToLineItem
                            key={returnTo?.id}
                            returnTo={returnTo}
                            setReturnTo={setReturnTo}
                            categoryMenu={categoryMenu}
                        />
                </motion.div>
            </MotionConfig>
        </>
    );
}

export default ReturnToGroup;
