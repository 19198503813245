import { PosTooltip } from "apps/pos/Common/uiCore";
import { IconButton } from "apps/springboard/Component/SBItems/elements";

interface Props {
  title?: string;
  placement:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  link: string;
  className?: string;
  name: string;
}

const IconWithTooltip = ({
  title,
  placement,
  link,
  className,
  name,
}: Props) => {
  return (
    <>
      <PosTooltip title={title} placement={placement}>
        <span>
          <IconButton
            name={name}
            href={link}
            target="_blank"
            type="tertiary"
            className={className}
          />
        </span>
      </PosTooltip>
    </>
  );
};

export default IconWithTooltip;
