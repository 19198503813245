import Avvvatars from 'avvvatars-react';
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";

const LogItem = (props) => {
    const { log } = props;
    const { firstName, lastName, createdAt, isPriceChanged, action, subject, oldValue, newValue } = log;

    const char1 = firstName ? firstName.charAt(0).toUpperCase() : "";
    const char2 = lastName ? lastName.charAt(0).toUpperCase() : "";

    const initials = char1 + char2;

    const timeFormatter = new Intl.DateTimeFormat('en-US', {
        hour: "2-digit",
        minute: "2-digit",
    });
    const time = timeFormatter.format(new Date(createdAt));

    return (
        <div className="log-item">
            <div className="log-item-avatar">
                <Avvvatars value={initials} size={40} />
                <div className="log-type-indicator">
                    <MaterialIcon icon={isPriceChanged ? 'attach_money' : 'subject'} size="18" classes="log-type-icon" />
                </div>
            </div>
            <div className="log-item-info">
                <p className="log-item-text">
                    <span className="log-person-name">{`${firstName} ${lastName}`}</span> <span className="log-item-action">{action}</span> <span className="log-item-entity">{subject}</span>.
                </p>
                <p className={`log-item-subtext ${isPriceChanged ? 'log-item-subtext-price-change' : 'log-item-subtext-product-change'}`}>
                    {isPriceChanged ? (
                       <>
                       <span className="log-item-old-price">
                           $ {oldValue}
                       </span>
                       →
                       <span className="log-item-new-price">
                           $ {newValue}
                       </span>
                   </>
                    ) : (
                        newValue
                    )}
                </p>
            </div>
            <time dateTime={time} className="log-item-time">
                {time}
            </time>
        </div>
    )
}

export default LogItem;