import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import useFilterSections from "apps/product/Components/ProductDetails/useSectionFilter";
import CreateGridStarIcon from "apps/product/Components/ProductDetails/CreateGridStarIcon";

import { PosButton, PosImage } from "apps/pos/Common/uiCore";

import images from "constants/images";
import { buttonTitle } from "constants/constant";
import { uploadLink } from "constants/staticData";
import { commonConstants } from "apps/product/helper/commonConstants";
import { labels, sectionName } from "apps/product/modules/productDetailsContainer/constants";
import { getSingleProductDataFunction } from "sagas/productsDetails/selector";
import { distributorData } from "sagas/distributor/selector";

const ProductDetailsImages = ({ isStarred = false, searchText }) => {
  const { defaultIcon } = images;
  const { upload } = buttonTitle;

  const getSingleProductData = useSelector(getSingleProductDataFunction);
  const distributor = useSelector(distributorData)
  const { images: imagesSection } = sectionName;
  const { showSection } = useFilterSections({ searchText, sectionName: imagesSection, isStarred });
  const { details } = getSingleProductData || {}
  const { imgDocs } = details || {}
  const { bigImg, pib, psds, thumbImg } = imgDocs || {}

  const { DefaultLargeImage, DefaultThumbnail, PIB, PSDS, DOCS, notAdded, document } = labels;


  const openLinkForUpload = () => {
    return window.open(`${distributor.httpsUrl}${uploadLink}`, commonConstants.target, commonConstants.noreferrer);
  }

  let fileType = "";
  if (pib) {
    const parts = pib.split('.');
    fileType = parts?.length > 1 ? parts?.pop()?.toUpperCase() : '';
  }
  return (
    <>
      <section className="product-report-section">
        <Grid container className="product-report-container">
          {showSection("defaultImage",DefaultLargeImage) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='defaultImage' label={DefaultLargeImage} value={bigImg} sectionName={imagesSection} />
              <Grid item xs={12} sm={8} className="product-detail-default-large-img">
                <PosImage
                  alt="image-icon"
                  className="product-image-container"
                  src={bigImg ? bigImg : defaultIcon}
                />
              </Grid>
            </Grid>
          ) : null}
          {showSection("defaultThumbnail",DefaultThumbnail) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='defaultThumbnail' label={DefaultThumbnail} value={thumbImg} sectionName={imagesSection} />
              <Grid item xs={12} sm={8} className="product-detail-default-thumbnail">
                <PosImage
                  alt="image-icon"
                  className="product-image-container"
                  src={thumbImg ? thumbImg : defaultIcon}
                />
              </Grid>
            </Grid>
          ) : null}
          {(!isStarred && !searchText) ? (
            <Grid container>
              <Grid item xs={12} className="cost-title-info">
                <span>{DOCS}</span>
              </Grid>
            </Grid>
          ) : null}
          {showSection("imagesPIB",PIB) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='imagesPIB' label={PIB} value={pib} sectionName={imagesSection} />
              <Grid item xs={12} sm={8}>
                {pib ? <div className="d-flex mb-2">
                  <div>
                    <PosImage
                      alt="image-icon"
                      className="product-image-container product-detail-image-common-style"
                      src={defaultIcon}
                    />
                  </div>
                  <div className="ml-2">
                    <div>
                      <span>{pib}</span>
                    </div>
                    <div className="small-text-without-margin gray-text">
                      <span>{`${fileType} ${document}`}</span>
                    </div>
                  </div>
                </div> :
                  <>
                    <div className="small-text-without-margin gray-text">
                      <span>{notAdded}</span>
                    </div>
                    <div className="mt-2">
                      <PosButton
                        variant="contained"
                        className="btn product-detail-medium-btn history"
                        size="small"
                        onClick={openLinkForUpload}
                      >
                        {upload}
                      </PosButton>
                    </div>
                  </>
                }
              </Grid>
            </Grid>
          ) : null}
          {showSection("imagesPSDS",PSDS) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='imagesPSDS' label={PSDS} value={psds} sectionName={imagesSection} />
              <Grid item xs={12} sm={8}>
                {psds ? <span> {psds} </span> :
                  <>
                    <div className="small-text-without-margin gray-text">
                      <span>{notAdded}</span>
                    </div>
                    <div className="mt-2">
                      <PosButton
                        variant="contained"
                        className="btn product-detail-medium-btn history"
                        size="small"
                        onClick={openLinkForUpload}
                      >
                        {upload}
                      </PosButton>
                    </div>
                  </>
                }
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </section>
    </>
  );
};

export default ProductDetailsImages;
