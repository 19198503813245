import { useSelector, useDispatch } from "react-redux";

import { PosSnackbar } from "apps/pos/Common/uiCore";

import { RootState } from "models";

import { ISnakbar, snakbarHide } from "sagas/pos/snakbar/snakbar.slice";

const Snackbar = () => {
  const { open, message , actionText} = useSelector<RootState, ISnakbar>(
    (state) => state.snakbarReducer
  );

  const dispatch = useDispatch();

  return (
    <PosSnackbar
      open={open}
      message={message}
      onClose={() => {
        dispatch(snakbarHide())
      }}
      actionText={actionText}
    />
  );
};

export default Snackbar;
