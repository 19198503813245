import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputAdornment } from "@material-ui/core";
import { Form, FormikProvider, useFormik } from "formik";

import {
  PosButton,
  PosImage,
  PosInput,
  PosSelect,
  PosTypography,
} from "apps/pos/Common/uiCore";
import ProductDetailsCommonModal from "./ProductDetailsCommonModal";
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";

import { LocalOfferIcon, SearchIcon, WorkspacesIcon } from "constants/icons";
import { labels, vpgComputePriceTypeOptions } from "../modules/productDetailsContainer/constants";
import { authorizationToken, blockInvalidChar } from "utils/utils";
import { alertMessage } from "constants/constant";
import images from "constants/images";

import useProductsDetailsHooks from "../modules/productDetailsContainer/hooks/mutationHooks";

import { deletePriceScedulesProductStart, updateRetailPriceScedulesProductStart } from "sagas/productsDetails/singleProductDetails.slice";
import { getIsUpdateRetailPriceScedulesProduct, getSingleProductDataFunction } from "sagas/productsDetails/selector";

import "./ProductDetailsLogsItemModels.scss";

const ProductDetailsRetailPriceItemModels = (props) => {
  const { open, onClose, schedules, retailPriceSchedulesInital ,
    setSchedules
  } = props;
  const isUpdateRetailPriceScedulesProduct = useSelector(getIsUpdateRetailPriceScedulesProduct)
  const getSingleProductData = useSelector(getSingleProductDataFunction);

  const dispatch = useDispatch()

  const {
    updateRetailPriceScedulesProductsMutation,
    deletePriceScedulesProductsMutation
  } = useProductsDetailsHooks();

  const { details } = getSingleProductData || {}
  const { vendorId, vendorName, productGroupName, productGroupId } = details || {}

  const [searchText, setSearchText] = useState("")
  const [searchData, setSearchData] = useState([])
  const { confirmRemovePriceSchedule, canNotBeUndone } = alertMessage
  const { deleteDarkGrayIcon, deleteHoverDarkGrayIcon } = images

  const { DefaultLoginId } = authorizationToken("Authorization") || {};

  const changeStatus = (name, hasBreakQty, id) => {
    const defaultHasQty = retailPriceSchedulesInital?.filter(data => data.name === name);
    setSchedules(prevState => {
      return prevState?.map(schedule => {
        const vendorProductGroupsOld = schedule?.vendorProductGroups
        if (schedule?.name === name) {
          if(defaultHasQty ?.length && (defaultHasQty[0]?.hasBreakQty)?.toString() === hasBreakQty.toString()){
            return {
              ...schedule,
              "hasBreakQty": defaultHasQty[0]?.hasBreakQty,
              vendorProductGroups: defaultHasQty[0]?.vendorProductGroups
            }
          } else {
            if (!hasBreakQty) {
              const newObject = {
                breakQty: "",
                createdAt: new Date().toString(),
                createdBy: DefaultLoginId,
                description: "",
                id: 0,
                markupMultiplier: "",
                priceScheduleId: id,
                productGroupId: productGroupId,
                productGroupName: productGroupName,
                vendorId: vendorId,
                vendorName: vendorName,
                vpgComputePriceType: vendorProductGroupsOld?.length ? vendorProductGroupsOld[0]?.vpgComputePriceType :vpgComputePriceTypeOptions[0]?.vpgComputePriceType,
              }
              return {
                ...schedule,
                "hasBreakQty": false,
                vendorProductGroups: [newObject]
              }
            } else {
              return {
                ...schedule,
                "hasBreakQty": true,
                vendorProductGroups : vendorProductGroupsOld
              };
            }
          }
        } else {
          return schedule;
        }
      });
    });
  };

  const formik = useFormik({
    initialValues: {
      retailPriceSchedules: schedules,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const { retailPriceSchedules } = values
      const newPriceSchedulesArray = retailPriceSchedules.map(schedule => {
        return {
          ...schedule,
          vendorProductGroups: schedule?.vendorProductGroups
            .map(vpg => {
              return {
                ...vpg,
                markupMultiplier: vpg.markupMultiplier === "" ? 0 : vpg.markupMultiplier,
                breakQty: vpg.breakQty === "" ? 0 : vpg.breakQty
              };
            })
        };
      });
      const body = { priceSchedules: newPriceSchedulesArray }
      dispatch(updateRetailPriceScedulesProductStart())
      updateRetailPriceScedulesProductsMutation.mutate(body)
    }
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    dirty
  } = formik;

  const {
    retailPriceSchedules
  } = values;

  const addBreakQtyData = (name, id) => {
    const newObject = {
      breakQty: "",
      createdAt: new Date().toString(),
      createdBy: DefaultLoginId,
      description: "",
      id: 0,
      markupMultiplier: "",
      priceScheduleId: id,
      productGroupId: productGroupId,
      productGroupName: productGroupName,
      vendorId: vendorId,
      vendorName: vendorName,
      vpgComputePriceType: vpgComputePriceTypeOptions[0]?.vpgComputePriceType,
    }

    setSchedules(prevState => {
      return prevState?.map(schedule => {
        if (schedule?.name === name) {
          return {
            ...schedule,
            vendorProductGroups: [
              ...schedule.vendorProductGroups,
              newObject
            ]
          };
        } else {
          return schedule;
        }
      });
    });

  };

  const handleOnClose = () => {
    setSchedules(retailPriceSchedulesInital)
    onClose()
  }

  const removeRow = (name, index, priceScheduleId) => {
    const updatedSchedules = retailPriceSchedules?.map(schedule => {
      if (schedule?.name === name) {
        if (schedule?.vendorProductGroups?.length === 1) {
          const permission = window.confirm(`${confirmRemovePriceSchedule} ${priceScheduleId}? ${canNotBeUndone}`)
          if (!permission) {
            return { ...schedule }
          } else {
            const body = { priceScheduleId: priceScheduleId }
            dispatch(deletePriceScedulesProductStart())
            deletePriceScedulesProductsMutation.mutate(body)
          }
        } else {
          const updatedVendorProductGroups = schedule?.vendorProductGroups.filter(
            (_, i) => (i !== index));
          return { ...schedule, vendorProductGroups: updatedVendorProductGroups };
        }
      }
      return schedule;
    });
    setSchedules(updatedSchedules);
  }


  const handleChangeSearchText = (e) => {
    const { value } = e.target;
    setSearchText(value)
    const filteredSchedules = retailPriceSchedules?.filter(schedule =>
      schedule?.name?.toLowerCase()?.includes(value.toLowerCase()) || schedule?.vendorProductGroups?.some(group => group?.description?.toLowerCase()?.includes(value?.toLowerCase()))
    );
    setSearchData(filteredSchedules)
  }

  const { SaveChanges, Back, breakQty, markupPercentage, basePriceType, describe, retailMessage } = labels

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <ProductDetailsCommonModal
            open={open}
            onClose={handleOnClose}
            modalMainTitle="Manage Retail Price Schedules"
            mainBodyClassName="retail-detail manage-retaile-price"
          >
            <div className="group-wrapper">
              <div className="group-content pt-0">
                <div className="input-with-add-button">
                  <PosInput
                    variant="filled"
                    id="filled-basic"
                    type="text"
                    className="document-search-input"
                    autoFocus
                    placeholder="Schedule name, description"
                    value={searchText}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChangeSearchText}
                  />
                </div>
                {retailPriceSchedules?.map((priceData, index) => {
                  const { name, id, vendorProductGroups, hasBreakQty } = priceData || {};
                  let showSection = true;
                  if (searchData?.length) {
                    showSection = searchData?.some(item => item.name === name);
                  }
                  if (showSection) {
                    return (
                      <>
                        {
                          vendorProductGroups?.length ?
                            <div className="manage-detail-section" key={name}>
                              <div className="d-flex align-items-start">
                                <div className="left-block">
                                  <PosTypography
                                    title={name}
                                    variant="subtitle1"
                                    className="title-label"
                                  />
                                  <div className="d-flex align-items-center hover-with-switch-productDetail" onClick={() => changeStatus(name, hasBreakQty ? false : true, id)}>
                                    <div
                                      className="d-flex align-items-center"
                                    >
                                      {hasBreakQty ? (
                                        <WorkspacesIcon className="w-sm me-2" />
                                      ) : (
                                        <LocalOfferIcon className="w-sm me-2" />
                                      )}

                                      <PosTypography
                                        title={hasBreakQty ? "VOLUME BREAKS" : "SIMPLE MARKUP"}
                                        variant="caption"
                                        className="title-label-1 mb-0"
                                      />
                                    </div>
                                    <span className="c--dot" />
                                    <PosTypography
                                      title="SWITCH"
                                      variant="subtitle2"
                                      className="title-label-2 mb-0"
                                    />
                                  </div>
                                </div>
                                <div className="right-block">
                                  {!index ? <div className="manage-detail-section-header">
                                    <span>{breakQty}</span>
                                    <span>{markupPercentage}</span>
                                    <span>{basePriceType}</span>
                                    <span>{describe}</span>
                                    <span></span>
                                  </div> : null}
                                  {vendorProductGroups?.map((groupData, groupIndex) => {
                                    const { priceScheduleId } = groupData;
                                    return (
                                      <div className="return-to-line-item manage-detail-section-action-items">
                                        <div className="return-to-input-group-sec">
                                          <div className="return-to-input-group qty-return-group">
                                            {!hasBreakQty ?
                                              <input
                                                type="text"
                                                className="return-qty-input"
                                                id="breakQty"
                                                name="breakQty"
                                                value=" - "
                                                disabled={!hasBreakQty} />
                                              :
                                              <input type="number"
                                                className="return-qty-input"
                                                id="breakQty"
                                                name="breakQty"
                                                min={0}
                                                max={100}
                                                value={values.retailPriceSchedules[index].vendorProductGroups[groupIndex].breakQty}
                                                onChange={(e) => {
                                                  const { name, value } = e.target;
                                                  setFieldValue(`retailPriceSchedules[${index}].vendorProductGroups[${groupIndex}].${name}`, value ? Number(value) : "");
                                                }}
                                                onKeyDown={(e) => blockInvalidChar(e)}
                                                onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                                                disabled={!hasBreakQty} />
                                            }

                                          </div>
                                        </div>
                                        <div className="return-to-input-group-sec">
                                          <div className="return-to-input-group qty-return-group">
                                            <input type="number"
                                              className="return-qty-input"
                                              id="markupMultiplier"
                                              name="markupMultiplier"
                                              value={values.retailPriceSchedules[index].vendorProductGroups[groupIndex].markupMultiplier}
                                              onKeyDown={(e) => blockInvalidChar(e)}
                                              onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                                              onChange={(e) => {
                                                const { name, value } = e.target;
                                                  setFieldValue(`retailPriceSchedules[${index}].vendorProductGroups[${groupIndex}].${name}`, value ? Number(value) : "");
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="return-to-input-group-sec">
                                          <PosSelect
                                            className=" product-detail-select"
                                            name="vpgComputePriceType"
                                            variant="filled"
                                            value={values.retailPriceSchedules[index].vendorProductGroups[groupIndex].vpgComputePriceType}
                                            defaultValue={values.retailPriceSchedules[index].vendorProductGroups[groupIndex].vpgComputePriceType}
                                            onChange={(e) => {
                                              const { name, value } = e.target;
                                              setFieldValue(`retailPriceSchedules[${index}].vendorProductGroups[${groupIndex}].${name}`, value);
                                            }}
                                            options={
                                              vpgComputePriceTypeOptions?.map(({ vpgComputePriceName, vpgComputePriceType }) => {
                                                return {
                                                  label: vpgComputePriceName,
                                                  value: vpgComputePriceType,
                                                };
                                              })
                                            }
                                          />
                                        </div>
                                        <div className="return-to-input-group-sec">
                                          <div className="return-to-input-group qty-return-group">
                                            <input
                                              type="text"
                                              className="return-qty-input"
                                              title="Replacement quantity"
                                              id="description"
                                              name="description"
                                              value={values.retailPriceSchedules[index].vendorProductGroups[groupIndex].description}
                                              onChange={(e) => {
                                                const { name, value } = e.target;
                                                setFieldValue(`retailPriceSchedules[${index}].vendorProductGroups[${groupIndex}].${name}`, value);
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="product-detail-stock-delete-sec">
                                          <div className="product-detail-partial-sec">
                                            <span>

                                            </span>
                                          </div>
                                          <div className="hover-with-delete-productDetail" onClick={() => removeRow(name, groupIndex, priceScheduleId)}>
                                            <PosImage
                                              src={deleteDarkGrayIcon}
                                              alt="delete-icon"
                                              className="delet-order-img"
                                            />
                                            <PosImage
                                              src={deleteHoverDarkGrayIcon}
                                              alt="delete-icon"
                                              className="delet-order-hover-img"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                                  {hasBreakQty ? <button className="return-to-line-add-button-product w-100 mt-1" onClick={() => addBreakQtyData(name, id)}>
                                    <MaterialIcon icon="add" />
                                  </button> : null}
                                </div>
                              </div>
                            </div> : null}
                      </>
                    )
                  }
                })}
              </div>
            </div>
            <div className="notes-model-footer position-absolute start-0 end-0">
              <div className="d-flex notes-btn-div product-detail-modal-footer-spacing pt-0 justify-content-end">
                <hr className="top--border" />
                <p className="int-msg">
                  {retailMessage}
                </p>
                <PosButton
                  variant="contained"
                  className="back-button first-product-detail-btn"
                  onClick={handleOnClose}
                >
                  {Back}
                </PosButton>
                <PosButton
                  fontWeight
                  className={`btn ml-2 second-product-detail-btn ${isUpdateRetailPriceScedulesProduct ? "button-accent button-is-loading-black" : ""}`}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={!dirty}
                >
                  {SaveChanges}
                </PosButton>
              </div>
            </div>
          </ProductDetailsCommonModal>
        </Form>
      </FormikProvider>
    </>
  );
};

export default ProductDetailsRetailPriceItemModels;
