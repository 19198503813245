import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import {
  setCustomerCode,
  checkCustomerCodeIfAlreadyExists,
  getCustomerById,
} from "services";

import { setNewCustomerCode } from "sagas/persistReducer/actions";
import { constantCustomerCode } from "./type";

const useSetCustomerCodeHooks = ({
  setOpenEditCustomerCode,
  setFieldError,
  setErrors,
  showSuccessSnakBar
}) => {
  const dispatch = useDispatch();

  const {customerId} = useSelector(
    ({ persistReducer }) =>
    persistReducer?.posFlowData?.customer || {}
    // (persistReducer?.loadCartDetails?.customer ? persistReducer?.loadCartDetails?.customer?.customerId : persistReducer?.customerAccount?.customerId) || persistReducer?.posFlowData?.customer?.customerId
    // TODO note: Need this if something breakdown the flow
  );


  // Mutations to get updated customer info
  const getCustomerCodeMutation = useMutation({
    mutationFn: getCustomerById,
    onSuccess: (data) => {
      dispatch(setNewCustomerCode({ posCustCode: data.posCustCode }));
    },
  });

  // Mutations to set new customer code
  const setCustomerCodeMutation = useMutation({
    mutationFn: setCustomerCode,
    onSuccess: () => {
      showSuccessSnakBar();
      setOpenEditCustomerCode(false);
      getCustomerCodeMutation.mutate({ customerId });
    },
    onError: () => {
      setFieldError("customerCode", constantCustomerCode.codeExistError)
    },
  });

  // Mutations to check if the customer code already exsists
  const checkCustomerCodeExsistMutation = useMutation({
    mutationFn: checkCustomerCodeIfAlreadyExists,
    onSuccess: (data: any) => {
      if(data.data){
        setFieldError("customerCode", constantCustomerCode.codeExistError)
      } else {
        setErrors({})
      }
    },
  });
  return {
    checkCustomerCodeExsistMutation,
    setCustomerCodeMutation
  };
};

export default useSetCustomerCodeHooks
