import {
  AddMessagePayload,
  AddNotePayload,
  UpdateNotePayload,
} from "../../models";
import {
  GET_MESSAGES_ERROR,
  GET_MESSAGES_START,
  GET_MESSAGES_SUCCESS,
  GET_REPS_ERROR,
  GET_REPS_START,
  GET_REPS_SUCCESS,
  SET_QUOTE_DETAIL,
  SELECTED_QUOTE_ID,
  SET_DOC_UPLOAD_MODAL,
  SET_SUB_QUOTE_LIST,
  SET_PARENT_QUOTE,
  SET_DOCUMENT_TYPE,
  SET_LIKELYHOOD_COMMENT,
  SET_SALESPERSON_DATA,
  ADD_MESSAGE_START,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_ERROR,
  GET_ESTIMATOR_NOTES_START,
  GET_ESTIMATOR_NOTES_SUCCESS,
  GET_ESTIMATOR_NOTES_ERROR,
  GET_ITEM_ACTION_NOTES_START,
  GET_ITEM_ACTION_NOTES_SUCCESS,
  GET_ITEM_ACTION_NOTES_ERROR,
  GET_MAJOR_REPS_START,
  GET_MAJOR_REPS_SUCCESS,
  GET_MAJOR_REPS_ERROR,
  GET_NOTES_START,
  GET_NOTES_SUCCESS,
  GET_NOTES_ERROR,
  ADD_NOTE_START,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_ERROR,
  GET_JOB_HIGHLIGHTS_START,
  GET_JOB_HIGHLIGHTS_SUCCESS,
  GET_JOB_HIGHLIGHTS_ERROR,
  GET_QUOTES_END_RECORD_START,
  GET_QUOTES_END_RECORD_ERROR,
  GET_QUOTES_END_RECORD_SUCCESS,
  SET_PACKAGE_INFO,
  SET_QUOTE_ITEMS,
  SET_QUOTE_DOCS,
  SET_QUOTE_COUNTS,
  ADD_DOCUMENT_ERROR,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_START,
  CLEAR_ALERT_DATA,
  UPDATE_JOB_HIGHLIGHT_NOTE_START,
  UPDATE_JOB_HIGHLIGHT_NOTE_SUCCESS,
  UPDATE_JOB_HIGHLIGHT_NOTE_ERROR,
  UPDATE_ESTIMATOR_NOTE_START,
  UPDATE_ESTIMATOR_NOTE_SUCCESS,
  UPDATE_ESTIMATOR_NOTE_ERROR,
  UPDATE_ITEMS_ACTION_NOTE_START,
  UPDATE_ITEMS_ACTION_NOTE_SUCCESS,
  UPDATE_ITEMS_ACTION_NOTE_ERROR,
  UPDATE_MAJOR_REPS_START,
  UPDATE_MAJOR_REPS_SUCCESS,
  UPDATE_MAJOR_REPS_ERROR,
  GET_VERSION_HISTORY_START,
  GET_VERSION_HISTORY_SUCCESS,
  GET_VERSION_HISTORY_ERROR,
  GET_RFI_SUCCESS,
  GET_RFI_START,
  GET_RFI_ERROR,
  GET_FOLLOW_UP_START,
  GET_FOLLOW_UP_SUCCESS,
  GET_FOLLOW_UP_ERROR,
  GET_ORDERS_DETAILS_ERROR,
  GET_ORDERS_DETAILS_SUCCESS,
  GET_ORDERS_DETAILS_START,
  RESET_ALL_NOTES_REPS
} from "./constants";

export function addMessageRequest(payload: AddMessagePayload) {
  return {
    type: ADD_MESSAGE_START,
    payload,
  };
}

export function addMessageSuccess(payload: string) {
  return {
    type: ADD_MESSAGE_SUCCESS,
    payload,
  };
}

export function addMessageError(payload: string) {
  return {
    type: ADD_MESSAGE_ERROR,
    payload,
  };
}

export function getEstimatorNotesRequest(id: number) {
  return {
    type: GET_ESTIMATOR_NOTES_START,
    id,
  };
}

export function getEstimatorNotesSuccess(payload) {
  return {
    type: GET_ESTIMATOR_NOTES_SUCCESS,
    payload,
  };
}

export function getEstimatorNotesError(errors: any) {
  return {
    type: GET_ESTIMATOR_NOTES_ERROR,
    errors,
  };
}

export function getItemActionNotesRequest(id: number) {
  return {
    type: GET_ITEM_ACTION_NOTES_START,
    id,
  };
}

export function getItemActionNotesSuccess(payload) {
  return {
    type: GET_ITEM_ACTION_NOTES_SUCCESS,
    payload,
  };
}

export function getItemActionNotesError(errors: any) {
  return {
    type: GET_ITEM_ACTION_NOTES_ERROR,
    errors,
  };
}

export function getNotesRequest(payload: number) {
  return {
    type: GET_NOTES_START,
    payload,
  };
}

export function getNotesSuccess(payload: any) {
  return {
    type: GET_NOTES_SUCCESS,
    payload,
  };
}

export function getNotesError(payload: string) {
  return {
    type: GET_NOTES_ERROR,
    payload,
  };
}

export function addNoteRequest(payload: AddNotePayload) {
  return {
    type: ADD_NOTE_START,
    payload,
  };
}

export function addNoteSuccess(payload: string) {
  return {
    type: ADD_NOTE_SUCCESS,
    payload,
  };
}

export function addNoteError(payload: string) {
  return {
    type: ADD_NOTE_ERROR,
    payload,
  };
}

export function updateJobHighlightNoteRequest(payload: UpdateNotePayload) {
  return {
    type: UPDATE_JOB_HIGHLIGHT_NOTE_START,
    payload,
  };
}

export function updateJobHighlightNoteSuccess(payload: string) {
  return {
    type: UPDATE_JOB_HIGHLIGHT_NOTE_SUCCESS,
    payload,
  };
}

export function updateJobHighlightNoteError(payload: string) {
  return {
    type: UPDATE_JOB_HIGHLIGHT_NOTE_ERROR,
    payload,
  };
}

export function updateEstimatorNoteRequest(payload: UpdateNotePayload) {
  return {
    type: UPDATE_ESTIMATOR_NOTE_START,
    payload,
  };
}

export function updateEstimatorNoteSuccess(payload: string) {
  return {
    type: UPDATE_ESTIMATOR_NOTE_SUCCESS,
    payload,
  };
}

export function updateEstimatorNoteError(payload: string) {
  return {
    type: UPDATE_ESTIMATOR_NOTE_ERROR,
    payload,
  };
}

export function updateItemsActionNoteRequest(payload: UpdateNotePayload) {
  return {
    type: UPDATE_ITEMS_ACTION_NOTE_START,
    payload,
  };
}

export function updateItemsActionNoteSuccess(payload: string) {
  return {
    type: UPDATE_ITEMS_ACTION_NOTE_SUCCESS,
    payload,
  };
}

export function updateItemsActionNoteError(payload: string) {
  return {
    type: UPDATE_ITEMS_ACTION_NOTE_ERROR,
    payload,
  };
}

export function updateMajorRepsRequest(payload: UpdateNotePayload) {
  return {
    type: UPDATE_MAJOR_REPS_START,
    payload,
  };
}

export function updateMajorRepsSuccess(payload: string) {
  return {
    type: UPDATE_MAJOR_REPS_SUCCESS,
    payload,
  };
}

export function updateMajorRepsError(payload: string) {
  return {
    type: UPDATE_MAJOR_REPS_ERROR,
    payload,
  };
}

export function getJobHighlightsRequest(id: number) {
  return {
    type: GET_JOB_HIGHLIGHTS_START,
    id,
  };
}

export function getJobHighlightsSuccess(payload) {
  return {
    type: GET_JOB_HIGHLIGHTS_SUCCESS,
    payload,
  };
}

export function getJobHighlightsError(errors: any) {
  return {
    type: GET_JOB_HIGHLIGHTS_ERROR,
    errors,
  };
}

export function getMajorRepsRequest(id: number) {
  return {
    type: GET_MAJOR_REPS_START,
    id,
  };
}

export function getMajorRepsSuccess(payload) {
  return {
    type: GET_MAJOR_REPS_SUCCESS,
    payload,
  };
}

export function getMajorRepsError(errors: any) {
  return {
    type: GET_MAJOR_REPS_ERROR,
    errors,
  };
}

export function getQuotesEndRecordStart(id: number) {
  return {
    type: GET_QUOTES_END_RECORD_START,
    id,
  };
}

export function getQuotesEndRecordSuccess(payload) {
  return {
    type: GET_QUOTES_END_RECORD_SUCCESS,
    payload,
  };
}

export function getQuoteEndRecordError(errors: any) {
  return {
    type: GET_QUOTES_END_RECORD_ERROR,
    errors,
  };
}

export function getRepsRequest(id: number) {
  return {
    type: GET_REPS_START,
    id,
  };
}

export function getRepsSuccess(payload) {
  return {
    type: GET_REPS_SUCCESS,
    payload,
  };
}

export function getRepsError(errors: any) {
  return {
    type: GET_REPS_ERROR,
    errors,
  };
}

export function getMessagesRequest(id: number) {
  return {
    type: GET_MESSAGES_START,
    id,
  };
}

export function getMessagesSuccess(payload) {
  return {
    type: GET_MESSAGES_SUCCESS,
    payload,
  };
}

export function getMessagesError(errors: any) {
  return {
    type: GET_MESSAGES_ERROR,
    errors,
  };
}

export function setQuoteDetail(payload) {
  return {
    type: SET_QUOTE_DETAIL,
    payload,
  };
}

export function selectedQuoteId(payload) {
  return {
    type: SELECTED_QUOTE_ID,
    payload,
  };
}

export function setDocUploadModal(payload) {
  return {
    type: SET_DOC_UPLOAD_MODAL,
    payload,
  };
}

export function setSubQuoteList(payload) {
  return {
    type: SET_SUB_QUOTE_LIST,
    payload,
  };
}

export function setParentQuote(payload) {
  return {
    type: SET_PARENT_QUOTE,
    payload,
  };
}

export function setDocumentTypes(payload) {
  return {
    type: SET_DOCUMENT_TYPE,
    payload,
  };
}

export function setLikelyHoodComment(payload) {
  return {
    type: SET_LIKELYHOOD_COMMENT,
    payload,
  };
}

export function setSalesPersonData(payload) {
  return {
    type: SET_SALESPERSON_DATA,
    payload,
  };
}

export function setPackageInfo(payload) {
  return {
    type: SET_PACKAGE_INFO,
    payload,
  };
}

export function setQuoteItems(payload) {
  return {
    type: SET_QUOTE_ITEMS,
    payload,
  };
}

export function setQuoteDocs(payload) {
  return {
    type: SET_QUOTE_DOCS,
    payload,
  };
}

export function setQuoteCounts(payload) {
  return {
    type: SET_QUOTE_COUNTS,
    payload,
  };
}

export function addDocumentRequest(payload) {
  return {
    type: ADD_DOCUMENT_START,
    payload,
  };
}

export function addDocumentSuccess(payload: string) {
  return {
    type: ADD_DOCUMENT_SUCCESS,
    payload,
  };
}

export function addDocumentError(payload: string) {
  return {
    type: ADD_DOCUMENT_ERROR,
    payload,
  };
}

export function getVersionHistoryRequest(payload: any) {
  return {
    type: GET_VERSION_HISTORY_START,
    payload,
  };
}

export function getVersionHistorySuccess(payload: number) {
  return {
    type: GET_VERSION_HISTORY_SUCCESS,
    payload,
  };
}

export function getVersionHistoryError(payload: number) {
  return {
    type: GET_VERSION_HISTORY_ERROR,
    payload,
  };
}

export function clearAlertData() {
  return {
    type: CLEAR_ALERT_DATA,
  };
}

export function getRfiRequest(id: number) {
  return {
    type: GET_RFI_START,
    id,
  };
}

export function getRfiSuccess(payload) {
  return {
    type: GET_RFI_SUCCESS,
    payload,
  };
}

export function getRfiError(errors: any) {
  return {
    type: GET_RFI_ERROR,
    errors,
  };
}

export function getFollowUpNoteRequest(id: number) {
  return {
    type: GET_FOLLOW_UP_START,
    id,
  };
}

export function getFollowUpNoteSuccess(payload) {
  return {
    type: GET_FOLLOW_UP_SUCCESS,
    payload,
  };
}

export function getFollowUpNoteError(errors: any) {
  return {
    type: GET_FOLLOW_UP_ERROR,
    errors,
  };
}


export function getOrdersDetailsRequest(id: number) {
  return {
    type: GET_ORDERS_DETAILS_START,
    id,
  };
}

export function getOrdersDetailsSuccess(payload) {
  return {
    type: GET_ORDERS_DETAILS_SUCCESS,
    payload,
  };
}

export function getOrdersDetailsError(errors: any) {
  return {
    type: GET_ORDERS_DETAILS_ERROR,
    errors,
  };
}

export function getResetAllTabsData() {
  return {
    type: RESET_ALL_NOTES_REPS
  };
}