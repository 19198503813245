import { useFormik } from "formik";
import { userFormValidation } from "layouts/formik/validationSchema";
import { useEffect, useState } from "react";
import { updateFavoriteLinkById } from "services";

import { ModelNameLabel, TargetValue } from "enums";
import { adminMessage } from "constants/message";

const EditBookmark = (props) => {
    const { changeModel, personID, bookmarkDetail, favoritesList, setFavoritesList } = props
    const { self , blank } = TargetValue;
    const { manage } = ModelNameLabel

    useEffect(() => {
        if (bookmarkDetail) {
            setFieldValue("linkName", bookmarkDetail?.linkName || "");
            setFieldValue("linkRoute", bookmarkDetail?.linkRoute ? bookmarkDetail?.linkRoute : "");
            setFieldValue("target", bookmarkDetail?.target || self);
        }

    }, [bookmarkDetail])

    const initialValues = {
        linkName: bookmarkDetail?.linkName || "",
        linkRoute: bookmarkDetail?.linkRoute ? bookmarkDetail?.linkRoute : "",
        target: bookmarkDetail?.target || self
    };
    const [loader, setLoader] = useState(false);
    const [success , setSuccess] = useState(false);

    const formik = useFormik({
        initialValues,
        validationSchema: userFormValidation,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            setLoader(true)
            const reqBody = {
                bookmarkID: bookmarkDetail?.bookmarkID,
                personID: personID,
                linkName: values?.linkName,
                linkRoute: values?.linkRoute,
                target: values?.target
            }

            try {
                const responseData = await updateFavoriteLinkById(bookmarkDetail?.bookmarkID, reqBody);
                setLoader(false)
                if (responseData && responseData?.data) {
                    setSuccess(true)
                    if (favoritesList?.length === 1) {
                        setFavoritesList([reqBody]);
                    } else {
                        // loop over the favoritesList list and find the provided id.
                        const updatedList = favoritesList.map(item => {
                            if (item.bookmarkID == bookmarkDetail?.bookmarkID) {
                                return reqBody; //updates 
                            }
                            return item; // else return unmodified item 
                        });
                        setFavoritesList(updatedList); // set state to new object with updated list
                    }
                    setTimeout(() => {
                        changeModel(manage);
                    }, 1000);

                }
            } catch (err) {
                setLoader(false);
                 if(err.status === 500){
                    setErrors({ linkRoute: adminMessage.urlInvalid });
                }else{
                    changeModel(manage);
                }
            }
        }
    });
    const { values, handleSubmit, setFieldValue, touched, errors , setErrors } = formik

    return (
        <>
            <header>
                <p className="dialog-title">Edit Bookmark</p>
                <button className="icon-btn dialog-close-btn" type="button" onClick={() => {  changeModel(manage) }}>
                    <span>Close</span>
                </button>
            </header>
            <form id="editBookmarkForm" onSubmit={handleSubmit}>
                <div className="edit-bookmark-form">
                    <div className="form-input-group title-group">
                        <input
                            type="text"
                            name="linkName"
                            id="linkName"
                            autoComplete="off"
                            placeholder="Title"
                            value={values.linkName}
                            onChange={(e) => setFieldValue("linkName", e.target.value)}
                            autoFocus
                            className={touched.linkName && errors.linkName ? "error" : ""}
                        />
                        {touched.linkName && errors.linkName ? (
                            <span className="form-input-message">
                                {errors.linkName.toString()}
                            </span>
                        ) : null}
                    </div>
                    <div className="form-input-group link-group">
                        <input
                            type="text"
                            name="linkRoute"
                            id="linkRoute"
                            inputMode="url"
                            autoComplete="off"
                            placeholder="Page url"
                            value={values.linkRoute}
                            onChange={(e) => setFieldValue("linkRoute", e.target.value)}
                            className={touched.linkRoute && errors.linkRoute ? "error" : ""}
                        />
                        {touched.linkRoute && errors.linkRoute ? (
                            <span className="form-input-message">
                                {errors.linkRoute.toString()}
                            </span>
                        ) : null}
                    </div>

                    <div className="form-checkbox-group">
                        <input type="checkbox" name="target" id="target" checked={values.target === "BLANK"}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setFieldValue("target", blank)
                                } else {
                                    setFieldValue("target", self)
                                }
                            }}
                        />
                        <label htmlFor="target">Open in a new tab</label>
                    </div>

                    <div className="actions">
                        <button className={`add-bookmark-btn ${loader ? "btn-loading" : ( success ? "btn-success": "")}`} type="submit" >
                            Save changes
                        </button>
                        <button className="edit-bookmark-cancel-btn" type="button" onClick={() => { changeModel(manage) }} >Cancel</button>
                    </div>
                </div>
            </form>
        </>
    )
}
export default EditBookmark;