import { makeStyles } from "@material-ui/core";


const useHeightStyles = props => makeStyles(() => ({
    fixedSuggestionsDiv: {
      maxWidth: '38.375rem',
    },
    searchedProducts: {
      maxWidth: '100%',
    },
    mainSuggestionStyle: {
      marginTop: 0,
      display: 'flex',
      "@media (min-width: 600px)": {
        display: 'grid',
        margin: `0 auto`
      },
      "@media (min-width: 1200px)": {
        marginTop: `${props?.searchProductsData?.length ? props?.withSearchData : props?.noSearchData}px`,
      }
    },
    productFoundStyle: {
      "@media (min-width: 1200px)": {
        position: 'fixed',
        top: `${props?.headerHeightCount + props?.settingIconDivHeight + 60}px`,
      },
    },
    productDataHeaderStyle: {
      "@media (min-width: 1200px)": {
        position: 'fixed',
        top: `${props?.headerHeightCount + props?.settingIconDivHeight + 117}px`,
      },
    },
    tableHeaderStyle: {
      "@media (min-width: 1200px)": {
        width: '100%',
        display: 'inherit',
      }
    },
}))();

export default useHeightStyles;