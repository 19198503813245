import {
  createStyles,
  Switch,
  SwitchClassKey,
  SwitchProps,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
  checked?: boolean;
  disabled?: boolean;
  onChange?(): void;
  onClick?(): void;
}

const JPSwitch = withStyles(() =>
  createStyles({
    root: {
      width: 50,
      height: 26,
      padding: 0,
    },
    switchBase: {
      padding: 4,
      "&$checked": {
        transform: "translateX(26px)",
        color: "#fff",
        "& + $track": {
          backgroundColor: "black",
          opacity: 1,
          border: "none",
        },
      },
      // Migth need in the future
      // "&$focusVisible $thumb": {
      //   color: "black",
      //   border: "6px solid black",
      // },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: "#aaa",
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, checked, disabled, onChange, onClick, ...props }: Props) => {
  return (
    <Switch
      disabled={disabled}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      checked={checked}
      onChange={onChange}
      onClick={onClick}
      {...props}
    />
  );
});

export default JPSwitch;
