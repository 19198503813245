import React from 'react';
import './OverlaySkrim.scss';
import { motion } from 'framer-motion/dist/framer-motion';

const OverlaySkrim = (props) => {
    const { onClick } = props;

    const skrimAnimVariants = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
        },
        exit: {
            opacity: 0,
        },
        transition: {
            duration: 0.1,
            type: 'spring',
            bounce: 0,
        }
    }
    return (
        <motion.div
            className="overlay-skrim"
            onClick={onClick}
            initial='initial'
            animate='animate'
            exit='exit'
            variants={skrimAnimVariants}
        >
        </motion.div>
    );
}

export default OverlaySkrim;