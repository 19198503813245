import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import './IconButton.scss';

const IconButton = (props) => {
    const {
        name = '',
        iconSize = 24,
        size = 40,
        onClick = null,
        href = '',
        target = '',
        type = 'primary',
        className = '',
        iconClassName = '',
        loading = false,
        disabled = false,
        title = '',
        tabIndex,
    } = props;

    const btnClasses = `sb-icon-btn type-${type} ${loading ? 'busy' : ''}`
    return (
        href ? (
            <a
                href={href}
                target={target ?? null}
                className={`${btnClasses} ${className}`}
                style={{ '--size': size }}
                disabled={disabled}
                title={title}
            >
            <MaterialIcon icon={name} size={iconSize} className={iconClassName} />
            </a>
        ) : (
            <button
                className={`${btnClasses} ${className}`}
                style={{ '--size': size }}
                disabled={disabled}
                onClick={onClick}
                title={title}
                tabIndex={tabIndex}
            >
            <MaterialIcon icon={name} size={iconSize} className={iconClassName} customeClass={iconClassName}/>
            </button>
        )
    )
}

export default IconButton;