import LogsGroup from "apps/product/Modals/ProductDetailsLogsItemModels/LogsGroup"

const ProductDetailLog = (props) => {
    const { logsData  , visible , handleOpenCloseLogsModal } = props
    const { data , total } = logsData || { }
    return (
            <div className="group-wrapper">
                <div className="group-content">
                    <LogsGroup logs={data} visible={visible} handleOpenCloseLogsModal={handleOpenCloseLogsModal} total={total}/>
                </div>
            </div>
    )
}

export default ProductDetailLog;