import { useSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Cookies from "universal-cookie";

import { PosSnackbar } from "apps/pos/Common/uiCore";
import TipsBox from "apps/pos/tipsBox/tipsBox";
import LinearLoader from "components/LinearProgress/LinearProgress";
import RouteComponent from "routes";
import { ThemeProvider } from "styled-components";
import { ModalNew } from "./components";
import { GlobalStyles } from "./theme/GlobalStyles";
import * as themesSchema from "./theme/schema.json";
import { useTheme } from "./theme/useTheme";

import { errorMessageRoute } from "constants/constant";
import { apiFiledRequest } from "sagas/pos/actions";
import { getTenantDetail } from "services";
import { authorizationToken } from "utils/utils";
import { Distributor, PosResponseProps, RootState } from "./models";
import { DistributorRequest } from "./sagas/distributor/actions";
import service from "./services/config";
import serviceV2 from "./services/config2";

import { StylesProvider, createGenerateClassName } from "@mui/styles";
import "apps/pos/posCommonResponsiveStyling.scss";
import "apps/pos/simplifiedReturnResponsiveStyling.scss";
import "./App.scss";
import "./styles/App.css";

export function App() {
  const cookies = new Cookies();
  const { themeLoaded } = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const data = cookies.get("Authorization");
  const decodedData = authorizationToken("Authorization");

  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );
  const { isApiFiledError } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );

  const [selectedTheme, setSelectedTheme] = useState(themesSchema.data.paper);
  const [distributorId, setDistributorId] = useState(0);

  const { cloudConfig } = distributor || {};
  const { jumperCloudFileStorageUrl, distributorCloudFileStorageUrl } =
    cloudConfig || {};
  const syncCookies = () => {
    const pathName = window.location.pathname;
    if (data && !window.location.pathname.includes(`/sso/logout`)) {
      if (decodedData) {
        const token = data;
        const tenantId = decodedData?.TenantId;
        const sellerId = decodedData?.SellerId;
        service.defaults.headers.common["Authorization"] = `${token}`;
        service.defaults.headers.common["X-TENANT-ID"] = tenantId;

        serviceV2.defaults.headers.common["Authorization"] = `${token}`;
        serviceV2.defaults.headers.common["X-TENANT-ID"] = tenantId;

        localStorage.setItem("sellerID", sellerId);
        getTenantDetail().then((result) => {
          const plan = result?.subscription?.plan?.name || "";
          const mode = plan === "Springboard LITE" ? "LITE" : "FULL";
          localStorage.setItem("jumperMode", mode);
        });
        dispatch(DistributorRequest(decodedData?.DistributorId));
        setDistributorId(decodedData?.DistributorId);
      }
      if (!decodedData?.SellerId) {
        window.location.href = `${window.location.origin}/sso?message=unauthorized`;
      }
    } else {
      if (!window.location.pathname.includes(`/sso`)) {
        if (window.location.pathname.includes("pos/cart")) {
          window.location.href = `${window.location.origin}/sso/logout?r=/app/pos/neworder`;
        } else {
          window.location.href = `${window.location.origin}/sso/logout?r=${pathName}`;
        }
      }
    }
  };

  useEffect(() => {
    syncCookies();
  }, []);

  useEffect(() => {
    //set theme according to the api data
    setSelectedTheme(
      Object.values(themesSchema.data).filter(
        (arr) => arr.name == distributor.theme
      )[0]
    );
  }, [distributor.theme]);

  useEffect(() => {
    setSelectedTheme(themesSchema.data.paper);
  }, [themeLoaded]);

  useEffect(() => {
    const head = document?.head;
    const link = document?.createElement("link");
    const link1 = document?.createElement("link");
    const link2 = document?.createElement("link");

    if (jumperCloudFileStorageUrl) {
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = `${jumperCloudFileStorageUrl}themes/sbadminpro/css/styles.css`;
      head.appendChild(link);

      link1.type = "text/css";
      link1.rel = "stylesheet";
      link1.href = `${jumperCloudFileStorageUrl}css/tips/style.css`;
      head.appendChild(link1);

      link2.type = "text/css";
      link2.rel = "stylesheet";
      link2.href = `${jumperCloudFileStorageUrl}themes/sbadminpro_override/css/${distributor.theme}.css`;
      head.appendChild(link2);

      const body = document?.body;
      const script = document?.createElement("script");
      script.src = `${jumperCloudFileStorageUrl}themes/sbadminpro/js/scripts.js`;
      body.appendChild(script);

      return () => {
        head.removeChild(link);
      };
    }
  }, [distributor?.cloudConfig && jumperCloudFileStorageUrl]);

  useEffect(() => {
    const head = document?.head;
    const favicon1 = document?.createElement("link");
    const favicon2 = document?.createElement("link");
    const favicon3 = document?.createElement("link");
    const favicon4 = document?.createElement("link");

    if (distributorCloudFileStorageUrl) {
      favicon1.type = "image/png";
      favicon1.rel = "icon";
      favicon1.href = `${distributorCloudFileStorageUrl}images/favicon/favicon-16x16.png`;
      head.appendChild(favicon1);

      favicon2.type = "image/png";
      favicon2.rel = "icon";
      favicon2.href = `${distributorCloudFileStorageUrl}images/favicon/favicon-32x32.png`;
      head.appendChild(favicon2);

      favicon3.type = "image/png";
      favicon3.rel = "icon";
      favicon3.href = `${distributorCloudFileStorageUrl}images/favicon/favicon-96x96.png`;
      head.appendChild(favicon3);

      favicon4.type = "image/x-icon";
      favicon4.rel = "shortcut icon";
      favicon4.href = `${distributorCloudFileStorageUrl}images/favicon/favicon.ico`;
      head.appendChild(favicon4);
    }
  }, [distributor?.cloudConfig && distributorCloudFileStorageUrl]);
  const { message, multiMessage, state: msgState } = isApiFiledError || {};
  const generateClassName = createGenerateClassName({
    productionPrefix: "j",
    seed: "j",
  });

  const shouldOpenSnackbar = () => {
    return isApiFiledError && message !== errorMessageRoute && message !== "";
  };

  return (
    <BrowserRouter>
      <PosSnackbar
        open={shouldOpenSnackbar() && msgState}
        message={message}
        onClose={() => {
          dispatch(
            apiFiledRequest({ state: false, message: "", multiMessage: [] })
          );
        }}
      />
      {isApiFiledError && multiMessage?.length > 0
        ? multiMessage?.map((msg) =>
          enqueueSnackbar(msg, {
            onClose: () => {
              dispatch(
                apiFiledRequest({
                  state: false,
                  message: "",
                  multiMessage: [],
                })
              );
            },
            preventDuplicate: true,
            className: "multiSnackbar",
          })
        )
        : null}
      <Helmet />
      <ModalNew />
      {themeLoaded && (
        <ThemeProvider theme={selectedTheme}>
          <StylesProvider generateClassName={generateClassName}>
            <GlobalStyles />
            {jumperCloudFileStorageUrl ? (
              <RouteComponent
                distributorId={distributorId}
                setSelectedTheme={setSelectedTheme}
              />
            ) : (
              <LinearLoader />
            )}
          </StylesProvider>
        </ThemeProvider>
      )}
      {jumperCloudFileStorageUrl && <TipsBox />}
    </BrowserRouter>
  );
}
