import { useEffect, useState } from "react";
import dayjs from "dayjs";

import {
  PosButton,
  PosInput,
  PosModel,
  PosImage,
} from "apps/pos/Common/uiCore";

import DatePickerNew from "../Common/DatePickerNew/DatePickerNew";
import images from "constants/images";
import { CheckIcon, ClearIcon } from "constants/icons";
import {
  handleFocus,
  numbersOnlyRegEx,
  numericOnlyRegex,
  staticData,
  storageItem,
  title,
} from "constants/constant";
import { StorageDataProps, storageProductData } from "constants/interfaces";
import { blockInvalidInputForPickQty } from "utils/utils";
import "./setStorage.scss";
import { Button } from "components";

interface StorageModalProps {
  setStorageModal?: (boolean) => void;
  storageModal?: boolean;
  isSetStorage?: boolean;
  putInStorage?: boolean;
  changeInStorage?: boolean;
  storageDetail?: (data: StorageDataProps) => void;
  productDetail?: storageProductData;
  itemInStorageData?: StorageDataProps;
  resetStorageData?: () => void;
  selectedStorageItem?: string;
}

const SetStorageModal = ({
  storageModal,
  setStorageModal,
  isSetStorage,
  putInStorage,
  changeInStorage,
  storageDetail,
  productDetail,
  itemInStorageData,
  resetStorageData,
  selectedStorageItem,
}: StorageModalProps) => {
  const { collapseBlack, expandBlack } = images;

  const { putInStorageTitle, quantity, estDeliveryDate } = title;

  const {
    noMoreThanPickQty,
    storeItemForCustomer,
    storageAppliesToAll,
    storageExpanded,
    storageCollapse,
  } = staticData;

  const [location, setLocation] = useState<string>(
    productDetail?.storageLocation
  );
  const [date, setdate] = useState(dayjs(productDetail?.estDeliveryDate));
  const [reason, setReason] = useState<string>(productDetail?.reason);
  const [qty, setQty] = useState<number>();
  const [qtyMore, setQtyMore] = useState<boolean>(false);
  const [expanded, setExpanded] = useState(
    localStorage.getItem("storageDetail")
  );

  const {
    storageLocation,
    estDeliveryDate: productDeliveryDate,
    reason: productReason,
    pickQty,
    lineId,
  } = productDetail || {};
  useEffect(() => {
    setLocation(storageLocation);
    setdate(dayjs(productDeliveryDate));
    setReason(productReason);
    setQty(pickQty);
    if (pickQty) {
      setQty(pickQty);
      setQtyMore(false);
    }
    if (changeInStorage) {
      const { items } = itemInStorageData || {};
      const item = items?.find((item) => item.lineId === lineId);
      if (item) {
        const { qtyPutInStorage } = item;
        setQty(qtyPutInStorage);
        setQtyMore(false);
      }
    }
  }, [productDetail]);

  const savePutStorage = () => {
    // Find the index of the item in the "items" array with the matching "productId"
    const itemIndex = itemInStorageData?.items?.findIndex(
      (item) => item.lineId === productDetail.lineId
    );

    // Create a copy of the original "items" array
    const updatedItems = [...itemInStorageData?.items];

    if (itemIndex !== -1) {
      // If the item with the productId exists, replace it with the new object
      updatedItems[itemIndex] = {
        lineId: productDetail.lineId,
        productId: productDetail.productId,
        manufacturerCode: productDetail.manufacturerCode,
        productName: productDetail.productName,
        itemType: productDetail.itemType,
        qtyPutInStorage: Number(qty),
      };
    } else {
      // If the item with the productId doesn't exist, add it to the "items" array
      updatedItems.push({
        lineId: productDetail.lineId,
        productId: productDetail.productId,
        manufacturerCode: productDetail.manufacturerCode,
        productName: productDetail.productName,
        itemType: productDetail.itemType,
        qtyPutInStorage: Number(qty),
      });
    }

    // Update the other properties
    const updatedData = {
      ...itemInStorageData,
      estDeliveryDate: date.toISOString(),
      storageLocation: location,
      reason: reason,
      items: updatedItems,
    };
    // Update the state or use the updatedData as required
    storageDetail(updatedData);
    setStorageModal(false);
    setQty(null);
    setQtyMore(true);
  };
  const changePutStorage = () => {
    // Update the other properties
    const updatedData = {
      ...itemInStorageData,
      estDeliveryDate: date.toISOString(),
      storageLocation: location,
      reason: reason,
    };
    // Update the state or use the updatedData as required
    storageDetail(updatedData);
    setStorageModal(false);
    setQty(null);
    setQtyMore(true);
  };
  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleDateChange = (e) => {
    if (e.target.value) {
      setdate(dayjs(e.target.value));
    } else {
      setdate(dayjs(productDetail?.estDeliveryDate));
    }
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleQtyChange = (e) => {
    let {
      target: { value },
    } = e || {};
    const { pickQty } = productDetail || {};
    const numericValue = value.replace(numericOnlyRegex, "");
    setQty(numericValue);
    if (Number(value) > 0 && numbersOnlyRegEx.test(value)) {
      setQtyMore(false);
      if (Number(value) && Number(value) <= Number(pickQty)) {
        setQty(value);
      } else {
        setQty(pickQty);
        value = pickQty;
      }
    } else {
      e.preventDefault();
      setQtyMore(true);
    }
  };

  const closeModel = () => {
    setStorageModal(false);
  };

  const removeStorage = () => {
    const itemIndex = itemInStorageData.items.findIndex(
      (item) => item.lineId === productDetail.lineId
    );
    // Create a copy of the original "items" array
    const updatedItems = [...itemInStorageData.items];

    if (itemIndex !== -1) {
      // If the item with the productId exists, remove it from the "items" array
      updatedItems.splice(itemIndex, 1);
    }
    if (itemInStorageData.items.length === 1) {
      resetStorageData();
    } else {
      // Update the other properties
      const updatedData = {
        ...itemInStorageData,
        items: updatedItems,
      };

      // Update the state or use the updatedData as required
      storageDetail(updatedData);
    }
    setStorageModal(false);
  };

  const changeExpandStatus = () => {
    if (expanded === storageExpanded) {
      localStorage.setItem("storageDetail", storageCollapse);
      setExpanded(storageCollapse);
    } else {
      localStorage.setItem("storageDetail", storageExpanded);
      setExpanded(storageExpanded);
    }
  };
  return (
    <>
      <PosModel
        open={storageModal}
        onClose={closeModel}
        className={`set-customer-code-modal`}
        dialogClassName={`set-customer-code-modal-container set-storage-modal-container put-storage-modal-container`}
        dialogHeader={
          <div className="modal-header align-items-center">
            {putInStorage || changeInStorage ? (
              <span className="modal-title">{putInStorageTitle}</span>
            ) : (
              <span className="modal-title">
                {isSetStorage ? "Set" : "Edit"} Storage details
              </span>
            )}
            <Button
              className="border-0 bg-transparent button-focus p-0"
              onClick={closeModel}
            >
              <ClearIcon className="clear-icon" />
            </Button>
          </div>
        }
        dialogFooter={
          <>
            <div
              className={`d-flex flex-wrap ${
                isSetStorage || changeInStorage
                  ? "justify-content-between w-100"
                  : ""
              }`}
            >
              {isSetStorage ? (
                <div>
                  <PosButton
                    type="button"
                    variant="contained"
                    className={`mr-2 mt-2 btn-font-600 custom-size-5 btn`}
                    fontWeight
                    onClick={closeModel}
                  >
                    Back
                  </PosButton>
                </div>
              ) : (
                changeInStorage && (
                  <PosButton
                    type="button"
                    variant="contained"
                    className={`mr-2 mt-2 btn-font-600 modal-remove-btn btn`}
                    fontWeight
                    startIcon={<ClearIcon />}
                    onClick={removeStorage}
                  >
                    Remove
                  </PosButton>
                )
              )}
              <div className="d-flex flex-wrap">
                {!isSetStorage && !changeInStorage && (
                  <PosButton
                    type="button"
                    variant="contained"
                    className={`mr-2 mt-2 btn-font-600 custom-size-7 btn`}
                    startIcon={<ClearIcon />}
                    fontWeight
                    onClick={closeModel}
                  >
                    Cancel
                  </PosButton>
                )}
                {isSetStorage ? (
                  putInStorageTitle
                ) : putInStorage || changeInStorage ? (
                  <PosButton
                    variant="contained"
                    color="primary"
                    className={`mt-2 change-doc-btn btn`}
                    startIcon={<CheckIcon />}
                    fontWeight
                    onClick={savePutStorage}
                    disabled={qtyMore}
                  >
                    Save
                  </PosButton>
                ) : (
                  <PosButton
                    variant="contained"
                    color="primary"
                    className={`mt-2 modal-remove-btn btn`}
                    startIcon={<CheckIcon />}
                    fontWeight
                    onClick={changePutStorage}
                  >
                    Change
                  </PosButton>
                )}
              </div>
            </div>
          </>
        }
      >
        {putInStorage || changeInStorage ? (
          <>
            <div className="title-text mb-2 font-weight-500 mb-3">
              {storeItemForCustomer}
            </div>

            <div className="set-storage-type-section modal-input-bottom-spacing border-bottom pb-4 mb-4">
              <div className="title-text mb-2 font-weight-600">{quantity}</div>
              <PosInput
                type="number"
                className="input-focus-styling"
                variant="filled"
                id="filled-basic"
                InputProps={{ disableUnderline: true }}
                name="qty"
                value={qty}
                onFocus={handleFocus}
                autoFocus
                onKeyPress={blockInvalidInputForPickQty}
                onChange={handleQtyChange}
                onWheel={(e) => (e?.target as HTMLElement)?.blur()}
              />
              <div className="text-black font-weight-500 fs-12 mt-2">
                {noMoreThanPickQty}: {productDetail?.pickQty}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <div className="title-text mb-2 font-weight-600">
                  Storage details
                </div>
                <div className="title-text font-weight-500">
                  {storageAppliesToAll}
                </div>
              </div>
              <div className="collapse-image" onClick={changeExpandStatus}>
                <PosImage
                  src={
                    expanded === storageExpanded ? collapseBlack : expandBlack
                  }
                  alt="ticket-icon"
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {expanded !== storageExpanded ? (
          <></>
        ) : (
          <>
            <div className="set-storage-type-section modal-input-bottom-spacing">
              <div className="title-text mb-2 font-weight-600">Location</div>
              <PosInput
                className="input-focus-styling"
                variant="filled"
                id="filled-basic"
                InputProps={{ disableUnderline: true }}
                name="location 11"
                value={location}
                autoFocus={selectedStorageItem === storageItem.location}
                onFocus={handleFocus}
                onChange={handleLocationChange}
              />
            </div>
            <div className="set-storage-type-section modal-input-bottom-spacing">
              <div className="title-text mb-2 font-weight-600">
                {estDeliveryDate}
              </div>
              <div className="custom-datePicker storage-date-picker custom--datepicker">
                <DatePickerNew
                  value={date.format("YYYY-MM-DD")}
                  onChange={handleDateChange}
                  minDate={dayjs(new Date()).format("YYYY-MM-DD")}
                  autoFocus={selectedStorageItem === storageItem.estDeliveryDate}
                />
              </div>
            </div>
            <div className="set-storage-type-section">
              <div className="title-text mb-2 font-weight-600">Reason</div>
              <PosInput
                className="input-focus-styling"
                variant="filled"
                id="filled-basic"
                InputProps={{ disableUnderline: true }}
                name="reason"
                value={reason}
                autoFocus={selectedStorageItem === storageItem.reason}
                onFocus={handleFocus}
                onChange={handleReasonChange}
              />
            </div>
          </>
        )}
      </PosModel>
    </>
  );
};

export default SetStorageModal;
