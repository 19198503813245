import { headCells } from './data';

export const EnhancedTableHead = (props) => {
  const {
    order,
    orderBy,
  } = props;

  return (
    <thead>
    <tr >
      {headCells.map((headCell) => (
        <th
          key={headCell.id}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span >
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </span>
          ) : null}
        </th>
      ))}
    </tr>
    </thead>
  );
}