import {  useState } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { PosButton, PosDatePicker } from "apps/pos/Common/uiCore";
import PosRadio from "apps/pos/Common/uiCore/posRadio/posRadio";
import { dateEnum,cashFilterOption } from "constants/staticData";

const GenerateCashDrawer = (props) => {
    const { closeAllDialogs,modelName,cashMenuUrl } = props;
    const {mdFormat,ymdFormat ,mmmmyyyy} = dateEnum || {};

    const currentDate = dayjs().format(mdFormat)
    const rangeGroup = ['Today, '+currentDate,'Custom range'];
    const [cashType,setCashType] = useState("Today, "+currentDate);
    const [fromDate, setFromDate] = useState(dayjs());
    const [toDate, setToDate] = useState(dayjs());

    const currentMonth = moment().format(mmmmyyyy);
    const prevousMonth = moment().subtract(1, "month").format(mmmmyyyy);
    const handleRadiocheck = (event: React.ChangeEvent<HTMLInputElement>)=>{
        setCashType(event.target.value);
    }
    

    const handleGenerate = () => {
        const currentDate = dayjs().format(ymdFormat)
        if(cashType == rangeGroup[0]){
            window.open(`${cashMenuUrl}?dt=${currentDate}&df=${currentDate}`,"_blank" );
        }else{
            window.open(`${cashMenuUrl}?dt=${toDate.format(ymdFormat)}&df=${fromDate.format(ymdFormat)}`,"_blank" );
        }
   }

    const setFilter = (type) =>{
        let startDt;
        let endDt;

        if(type == cashFilterOption.lastWeek){
            startDt = moment().subtract(7, 'day');
            endDt   = moment().subtract(1, 'day');
        }else if(type == cashFilterOption.prevMonth){
            startDt = moment(prevousMonth).startOf('month');
            endDt   = moment(prevousMonth).endOf('month');
        }else if(type == cashFilterOption.currMonth){
            startDt = moment(currentMonth).startOf('month');
            endDt   = moment(currentMonth).endOf('month');
        }else{
            startDt = fromDate;
            endDt = toDate;
        }
        setFromDate(dayjs(startDt.format(ymdFormat)))
        setToDate(dayjs(endDt.format(ymdFormat)))
    }    
    

    return (
        <>
            <header>
                <p className="dialog-title">{modelName}</p>
                <button className="icon-btn dialog-close-btn" type="button" onClick={closeAllDialogs}>
                    <span>Close</span>
                </button>
            </header>
            <div className="add-bookmark-form">
                <div className="hide-price-checkbox">
                    <PosRadio groupName="dateRange" label={rangeGroup} selected={cashType}  onChange={handleRadiocheck}/>
                </div>
                <div className="datePicker-group">
                    <div className="set-storage-type-section">
                        <div className="title-text mb-2 font-weight-600">
                            From
                        </div>
                        <div className="custom-datePicker storage-date-picker">
                            <PosDatePicker value={fromDate} onChange={(newValue) => {setFromDate(dayjs(newValue))}} isDefaultSet={true} toDate={toDate}/>
                        </div>
                    </div>
                    <div className="set-storage-type-section">
                        <div className="title-text mb-2 font-weight-600">
                            To
                        </div>
                        <div className="custom-datePicker storage-date-picker">
                        <PosDatePicker value={toDate} onChange={(newValue) => {setToDate(dayjs(newValue))}} isDefaultSet={false} fromDate={fromDate}/>
                        </div>
                    </div>
                </div>
                <div className="days-group">
                    <div className="days-list" onClick={()=>{setFilter(cashFilterOption.lastWeek)}}>Last 7 days</div>
                    <div className="days-list" onClick={()=>{setFilter(cashFilterOption.prevMonth)}}>{prevousMonth}</div>
                    <div className="days-list" onClick={()=>{setFilter(cashFilterOption.currMonth)}}>{currentMonth}</div>
                </div>
                <div className="actions">
                    <PosButton type="submit"  variant="contained"
                    color="primary" onClick={handleGenerate}>Generate</PosButton>
                </div>
            </div>
        </>
    )
}
export default GenerateCashDrawer;