import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { PosFlowDataProp } from "constants/interfaces";
import { RootState } from "models";

import { setCustomerAlertHide, setPosFlowData } from "sagas/persistReducer/actions";
import { setJobNameForAll, setMarkAsQuoteReducer, setShippingInstructionNote, setStoreCreditAmout } from "sagas/pos/actions";


const schema = object().shape({
  title: string().required("Please enter job name.").nullable(),
});

const useSaveForLaterFormik = (setShowSaveForLater, setIsSnackbarVisible, saveForLaterAPI, savedJobName) => {
  const dispatch = useDispatch();
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );

  const formik = useFormik({
    initialValues: {
      title: savedJobName,
    },
    validationSchema: schema,
    onSubmit: () => {
      setShowSaveForLater(false);
      const data = {
        ...posFlowData,
        isCartAvailable: false,
        isLogoutClick: false,
        addDocument: [],
        poNumber: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        companyName: "",
      };
      dispatch(setPosFlowData(data));
      dispatch(setShippingInstructionNote(""));
      dispatch(setJobNameForAll(""));
      dispatch(setMarkAsQuoteReducer(false));
      dispatch(setCustomerAlertHide(true));
      dispatch(setStoreCreditAmout(""));
      setIsSnackbarVisible(true);
      setTimeout(() => {
        setIsSnackbarVisible(false);
        saveForLaterAPI(savedJobName);
      }, 1000);
    },
  });

  return formik;
};

export default useSaveForLaterFormik;
