import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

import "./index.css";

const PosLoader = ({ progress, visible, fixed=false, className= "" }) => {
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className={`loading-container ${fixed ? "fixed-loader":""} ${className}` }
          initial={{
            opacity: 0,
            y: "-100%",
            scaleX: 0,
            transformOrigin: "left",
          }}
          animate={{ opacity: 1, y: "0%", scaleX: 1, transformOrigin: "left" }}
          exit={{ y: "-100%", opacity: 0 }}
          transition={{ duration: 0.6, ease: "circOut" }}
        >
          <div className="progress-bar-wrapper">
            <div className="progress-track" />
            <div
              className={`progress-bar-1 ${!progress && "indeterminate"}`}
              style={{ transform: `scaleX(${progress}%)` }}
            />
            {!progress && <div className="progress-bar-2 indeterminate" />}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default PosLoader;
