import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import {
  Button,
  Input,
  Select,
} from "../../../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";
import { Formik } from "formik";
import * as Yup from "yup";

import { emailDistrosRequest } from "../../../sagas/setting/actions";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const emailData = [
  {
    title: "Do Not Send",
    id: "doNotSend",
    name: "doNotSend",
    access: "BOTH",
  },
  {
    title: "Accounting",
    id: "accounting",
    name: "accounting",
    access: "BOTH",
  },
  {
    title: "Accounts Payable",
    id: "accountsPayable",
    name: "accountsPayable",
    access: "BOTH",
  },
  {
    title: "Account Receivable",
    id: "accountReceivable",
    name: "accountReceivable",
    access: "BOTH",
  },
  {
    title: "Credit Hold",
    id: "creditHold",
    name: "creditHold",
    access: "BOTH",
  },
  {
    title: "Customer Service",
    id: "customerService",
    name: "customerService",
    access: "BOTH",
  },
  {
    title: "Forward Copy To",
    id: "forwardCopyTo",
    name: "forwardCopyTo",
    access: "BOTH",
  },
  {
    title: "Large Order",
    id: "largeOrder",
    name: "largeOrder",
    access: "FULL",
  },
  {
    title: "Order Changes",
    id: "orderChanges",
    name: "orderChanges",
    access: "FULL",
  },
  {
    title: "Order Margin Changes",
    id: "orderMarginChanges",
    name: "orderMarginChanges",
    access: "FULL",
  },
  {
    title: "Credit Paid Alert",
    id: "creditPaidAlert",
    name: "creditPaidAlert",
    access: "FULL",
  },
  {
    title: "Purchase Order Warning",
    id: "purchaseOrderWarning",
    name: "purchaseOrderWarning",
    access: "FULL",
  },
  {
    title: "Product Alert",
    id: "productAlert",
    name: "productAlert",
    access: "BOTH",
  },
  {
    title: "Quote System",
    id: "quoteSystem",
    name: "quoteSystem",
    access: "BOTH",
  },
  {
    title: "Quotes",
    id: "quotes",
    name: "quotes",
    access: "BOTH",
  },
  {
    title: "Returns",
    id: "returns",
    name: "returns",
    access: "FULL",
  },
  {
    title: "Team",
    id: "team",
    name: "team",
    access: "BOTH",
  },
  {
    title: "Warehouse",
    id: "warehouse",
    name: "warehouse",
    access: "FULL",
  },
  {
    title: "Sales",
    id: "sales",
    name: "sales",
    access: "BOTH",
  },
  {
    title: "Accounting Admin",
    id: "accountingAdmin",
    name: "accountingAdmin",
    access: "BOTH",
  },
  {
    title: "Revise Warehouse Dispatch",
    id: "reviseWarehouseDispatch",
    name: "reviseWarehouseDispatch",
    access: "FULL",
  },
  {
    title: "Lot Quote Revise Due Date",
    id: "lotQuoteReviseDueDate",
    name: "lotQuoteReviseDueDate",
    access: "BOTH",
  },
  {
    title: "Receive Vendor Po in Warehouse",
    id: "receiveVendorPoWarehouse",
    name: "receiveVendorPoWarehouse",
    access: "FULL",
  },
  {
    title: "Bill Variance Item Change",
    id: "billVarianceItemChange",
    name: "billVarianceItemChange",
    access: "FULL",
  },
  {
    title: "Commission Finalized",
    id: "commissionFinalized",
    name: "commissionFinalized",
    access: "BOTH",
  },
  {
    title: "Commission Approved",
    id: "commissionApproved",
    name: "commissionApproved",
    access: "BOTH",
  },
  {
    title: "Opt Out",
    id: "optOut",
    name: "optOut",
    access: "BOTH",
  },
  {
    title: "Default From",
    id: "defaultFrom",
    name: "defaultFrom",
    access: "BOTH",
  },
  {
    title: "Blog Changes",
    id: "blogChanges",
    name: "blogChanges",
    access: "FULL",
  },
  {
    title: "Ticket Changes",
    id: "ticketChanges",
    name: "ticketChanges",
    access: "BOTH",
  },
  {
    title: "Ship Confirm",
    id: "shipConfirm",
    name: "shipConfirm",
    access: "BOTH",
  },
  {
    title: "Career Ad",
    id: "careerAd",
    name: "careerAd",
    access: "FULL",
  },
  {
    title: "Daily Login Report",
    id: "dailyLoginReport",
    name: "dailyLoginReport",
    access: "BOTH",
  },
  {
    title: "Proof Delivery Alert",
    id: "ProofDeliveryAlert",
    name: "ProofDeliveryAlert",
    access: "FULL",
  },
];

const EmailDistorSchema = Yup.object().shape({
  doNotSend: Yup.string()
    .email("Please enter valid email")
    .required("Do not send email required"),
  accounting: Yup.string()
    .email("Please enter valid email")
    .required("Accounting email required"),
  accountsPayable: Yup.string()
    .email("Please enter valid email")
    .required("Accounts payable email required"),
  accountReceivable: Yup.string()
    .email("Please enter valid email")
    .required("Account receivable email required"),
  creditHold: Yup.string()
    .email("Please enter valid email")
    .required("Credit hold email required"),
  customerService: Yup.string()
    .email("Please enter valid email")
    .required("Customer service email required"),
  forwardCopyTo: Yup.string()
    .email("Please enter valid email")
    .required("Forward copy to email required"),
  largeOrder: Yup.string()
    .email("Please enter valid email")
    .required("Large rrder email required"),
  orderChanges: Yup.string()
    .email("Please enter valid email")
    .required("Order changes email required"),
  orderMarginChanges: Yup.string()
    .email("Please enter valid email")
    .required("Order margin changes email required"),
  creditPaidAlert: Yup.string()
    .email("Please enter valid email")
    .required("Credit paid alert email required"),
  purchaseOrderWarning: Yup.string()
    .email("Please enter valid email")
    .required("Purchase order warning email required"),
  productAlert: Yup.string()
    .email("Please enter valid email")
    .required("Product alert email required"),
  quoteSystem: Yup.string()
    .email("Please enter valid email")
    .required("Quote System email required"),
  quotes: Yup.string()
    .email("Please enter valid email")
    .required("Quotes email required"),
  returns: Yup.string()
    .email("Please enter valid email")
    .required("Returns email required"),
  team: Yup.string()
    .email("Please enter valid email")
    .required("Team email required"),
  warehouse: Yup.string()
    .email("Please enter valid email")
    .required("Warehouse email required"),
  sales: Yup.string()
    .email("Please enter valid email")
    .required("Sales email required"),
  accountingAdmin: Yup.string()
    .email("Please enter valid email")
    .required("Accounting admin email required"),
  reviseWarehouseDispatch: Yup.string()
    .email("Please enter valid email")
    .required("Revise warehouse dispatch email required"),
  lotQuoteReviseDueDate: Yup.string()
    .email("Please enter valid email")
    .required("Lot quote revise due date email required"),
  receiveVendorPoWarehouse: Yup.string()
    .email("Please enter valid email")
    .required("Receive vendor po in warehouse email required"),
  billVarianceItemChange: Yup.string()
    .email("Please enter valid email")
    .required("Bill variance item change email required"),
  commissionFinalized: Yup.string()
    .email("Please enter valid email")
    .required("Commission finalized email required"),
  commissionApproved: Yup.string()
    .email("Please enter valid email")
    .required("Commission approved email required"),
  optOut: Yup.string()
    .email("Please enter valid email")
    .required("Opt out email required"),
  defaultFrom: Yup.string()
    .email("Please enter valid email")
    .required("Default from email required"),
  blogChanges: Yup.string()
    .email("Please enter valid email")
    .required("Blog changes email required"),
  ticketChanges: Yup.string()
    .email("Please enter valid email")
    .required("Ticket changes email required"),
  shipConfirm: Yup.string()
    .email("Please enter valid email")
    .required("Ship confirm email required"),
  careerAd: Yup.string()
    .email("Please enter valid email")
    .required("Career ad email required"),
  dailyLoginReport: Yup.string()
    .email("Please enter valid email")
    .required("Daily login report email required"),
  ProofDeliveryAlert: Yup.string()
    .email("Please enter valid email")
    .required("Proof delivery alert email required"),
});

export function EmailDistors(props) {
  const { tenantPlan } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(emailDistrosRequest(1));
  });
  return (    
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Email Distors</div>
        <div className="devider2px" />

        <Formik
          initialValues={{
            doNotSend: "",
            accounting: "",
            accountsPayable: "",
            accountReceivable: "",
            creditHold: "",
            customerService: "",
            forwardCopyTo: "",
            largeOrder: "",
            orderChanges: "",
            orderMarginChanges: "",
            creditPaidAlert: "",
            purchaseOrderWarning: "",
            productAlert: "",
            quoteSystem: "",
            quotes: "",
            returns: "",
            team: "",
            warehouse: "",
            sales: "",
            accountingAdmin: "",
            reviseWarehouseDispatch: "",
            lotQuoteReviseDueDate: "",
            receiveVendorPoWarehouse: "",
            billVarianceItemChange: "",
            commissionFinalized: "",
            commissionApproved: "",
            optOut: "",
            defaultFrom: "",
            blogChanges: "",
            ticketChanges: "",
            shipConfirm: "",
            careerAd: "",
            dailyLoginReport: "",
            ProofDeliveryAlert: "",
          }}
          validationSchema={EmailDistorSchema}
          onSubmit={() => {
            // Required Later
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => {
            return (
              <form id="addEmailDistorsForm">
                {emailData.map((item, index) => {
                  return (
                    (item?.access === tenantPlan ||
                      item?.access === "BOTH") && (
                      <div className="container" key={index}>
                        <div className="typeText mb-0">{item?.title}</div>
                        <div className="d-flex">
                          <div className="emailcontainer">
                            <Input
                              id={item?.id}
                              name={item?.name}
                              label={"Email"}
                              value={values[item?.name]}
                              onChange={handleChange}
                              textInputStyle="phoneinput"
                              type={"text"}
                              placeholder={"hello@apple.com"}
                            />
                          </div>
                          <div className="sendtypecontainer">
                            <Select
                              id={"emailSendtype"}
                              name={"emailSendtype"}
                              label={"Send Type"}
                              options={[{ label: "SEND", value: "SEND" }]}
                            />
                          </div>
                        </div>
                        <div className="dimensions">
                          {errors[item?.name] && touched[item?.name] ? (
                            <p className="validation-error-text">
                              {errors[item?.name]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    )
                  );
                })}

                <Button
                  onClick={handleSubmit}
                  type={"submit"}
                  className="manage-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#jumper_modal"
                >
                  <span className="manage-button-txt">save changes</span>
                </Button>
              </form>
            );
          }}
        </Formik>
        <div className="devider1px" />
      </div>
    </PerfectScrollbar>
  );
}
