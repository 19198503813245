import styled from "styled-components";

export const TableHeaderRowdiv = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  flex-direction: column;
  div {
    display: flex;
    flex-direction: row;
  }
`;

export const TableStyled = styled.div`
  width: 100%;
`;

export const DropDownItem = styled.div`
  border: none;
  border-bottom: 1px solid #eeeeee !important;
  color: black;
  &:hover {
    background-color: #eeeeee;
    border-radius: 5px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DropDownItem2 = styled.div`
  border: none;
  color: black;
  &:hover {
    background-color: #eeeeee;
    border-radius: 5px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DropDownHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 9px;
  padding: 0px 0px 5px 0px;
  color: #aaaaaa;
`;

export const OpenQuoteStyled = styled.aside`
  width: 490px;
`;

export const QuoteDetailsStyled = styled.div``;

export const QuoteDetailMetaHeader = styled.section`
  .meta-form {
    cursor: pointer;
  }
`;

export const QuoteDocumentsStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  row-gap: 3rem;
  column-gap: 3rem;
`;

export const NoteStyled = styled.div`
  width: auto;
  min-height: 450px;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const NoteDiv = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    scrollbar-width: none;
    overflow-y: scroll;

  &:hover {
    cursor: pointer;
  }
`;
