import "styles/common.css";

interface FooterProps {
  text: string;
  classname?: string;
}

const Footer = ({ classname }: FooterProps) => {
  return (
    <>
    <footer className={`footer-container ${classname ? classname : ""}`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 small">
            {/* {text} */}
          </div>
        </div>
      </div>
    </footer>
    </>
  );
}

export default Footer;