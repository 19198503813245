import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface IPrivacySettings {
  priceVisible: boolean;
}

const initialState: IPrivacySettings = {
  priceVisible: true,
};

export const privacySettingsSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    tooglePrivacySetting: (state) => {
      state.priceVisible = !state.priceVisible;
    },
    setPrivacySetting: (state, action: PayloadAction<{priceVisible: boolean}>) => {
      state.priceVisible = action.payload.priceVisible;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  tooglePrivacySetting,
  setPrivacySetting
} = privacySettingsSlice.actions;

export default privacySettingsSlice.reducer;
