import React, { ReactNode } from "react";
import { Container, ContainerProps } from "@material-ui/core";

interface Props extends ContainerProps{
  children: ReactNode;
}
const PosContainer = ({ ref, className, children }: Props) => {
  return (
    <Container ref={ref} className={className}>
        {children}
    </Container>
  );
};
export default PosContainer;
