import { AnyAction } from "redux";

import * as constants from "./constants";

const initialState = {
  defaultTenantId: "",
  jwtToken: "",
  refreshToken: "",
  isLoggedIn: false,
  isLoading: false,
  error: null,
  newPasswordSave: false,
  newPasswordTokenSave: null,
  setNewPasswordSave: null,
  authenticateUserResponses: [
    {
      tenantId: "",
      tenantName: "",
      distributorId: 0,
      fullName: "",
      defaultLoginId: 0,
      managerId: 0,
      sellerId: 0,
      wPersonId: 0,
      vendorAgentId: 0,
      manufacturerAgentId: 0,
      accountantId: 0,
      buyerId: 0,
      userType: "",
      couponOwnerId: 0,
    },
  ],
  resetPassword: "",
  isSetPassword: false,
  inviteSuccess : "",

};
const reducer = function authenticatedUserReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case constants.RESET_PASSWORD_REQUEST_START:
      return { ...state, isLoading: true, resetPassword: "start" };

    case constants.RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        setNewPasswordSave: action.payload.data,
        isLoading: false,
        resetPassword: constants.SUCCESS,
        message: constants.RESET_LINK
      };

    case constants.RESET_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        resetPassword: constants.ERROR,
        message: constants.ERROR_RESET_LINK
      };
    case constants.NEW_PASSWORD_TOKEN_END:
      return {
        ...state,
        setNewPasswordSave: null,
        isLoading: false,
      };
    case constants.NEW_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
        isSetPassword: true
      };

    case constants.NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        newPasswordSave: action.payload.data,
        isLoading: false,
        isSetPassword: false,
        message: constants.PASSWORD_CHANGES
      };

    case constants.NEW_PASSWORD_END:
      return {
        ...state,
        newPasswordSave: false,
        isLoading: false,
        isSetPassword: false
      };

    case constants.NEW_PASSWORD_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isSetPassword: false,
        message: constants.PASSWORD_CHANGES_ERROR
      };

    case constants.NEW_PASSWORD_TOKEN_START:
      return {
        ...state,
        isLoading: true,
      };

    case constants.NEW_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        newPasswordTokenSave: action.payload.token,
        isLoading: false,
      };

    case constants.NEW_PASSWORD_TOKEN_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
      case constants.INVITE_START:
        return {
          ...state,
          isLoading: true,
          inviteSuccess: constants.START,
        };
  
      case constants.INVITE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          inviteSuccess: constants.SUCCESS,
        };
      case constants.INVITE_ERROR:
        return {
          ...state,
          error: action.payload,
          isLoading: false,
          inviteSuccess: constants.ERROR,
        };

    default:
      return state;
  }
};

export default reducer;
