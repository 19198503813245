import { makeStyles } from "@material-ui/core/styles";

export const headCells = [
  {
    id: "createdAt",
    disablePadding: true,
    label: "DATE",
    left: true,
    class: "inv-date-col",
  },
  {
    id: "poNum",
    disablePadding: false,
    label: "PO #",
    left: true,
  },
  {
    id: "jobName",
    disablePadding: false,
    label: "JOB NAME",
    left: true,
  },
  {
    id: "quoteId",
    disablePadding: false,
    label: "QUOTE #",
    left: true,
  },
  {
    id: "lineId",
    disablePadding: false,
    label: "LINE",
    left: true,
  },
  {
    id: "customer",
    disablePadding: false,
    label: "CUSTOMER",
    left: true,
    class: "customer-col-width",
  },
  {
    id: "openValue",
    disablePadding: false,
    label: "OPEN VALUE",
    left: true,
  },
  {
    id: "qtyQuoted",
    disablePadding: false,
    label: "QTY.",
    left: true,
  },
  {
    id: "priceMU",
    disablePadding: false,
    label: "NET PRICE",
    left: true,
  },
];

export const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  nextIcon: {
    color: "#000000",
  },
}));
