import styled from "styled-components";

export const colors = {
  white: "#ffffff",
  border: "#DDDDDD",
  black: "#000000",
  titleColor:"#1B212A",
  lightWhite:"#F1F1F1",
  brightGray:"#EEEEEE"
};

export const UserListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0rem;
  margin-top: 4rem;
`;