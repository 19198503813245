import "@fontsource/inter";
import { InputAdornment, Tab } from "@mui/material";
import Fuse from 'fuse.js';
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  PosInput,
  PosTab,
  PosTabPanel,
} from "apps/pos/Common/uiCore";
import CustomerDelivered from "apps/pos/pages/customerInfo/CustomerDelivered/CustomerDelivered";
import CustomerPending from "apps/pos/pages/customerInfo/CustomerPending/CustomerPending";

import { staticData } from "constants/constant";
import { storageTabTypeEnum } from "constants/enum";
import { SearchIcon } from "constants/icons";
import { PosResponseProps, RootState } from "models";
import { keys } from "utils/helper";
import { scrollToSetting } from "utils/utils";

import {
  arrowDownIndexGeneric,
  arrowResetGeneric,
  arrowUpIndexGeneric,
  setHeaderHeightCount,
} from "sagas/pos/actions";

import "./customerStorage.scss";


interface StorageTabProps {
  headerHeightCount?: number;
  shipmentOrdersData: any;
  deliveredStorageItemsData: any;
  storageSearchInputRef: React.RefObject<HTMLDivElement>;
  storagePillsRef: React.RefObject<HTMLDivElement>;
  storageTopDataHeight: number;
  isPendingApiLoading?:boolean;
  isDeliveredApiLoading?:boolean;
  savedOrderFoundInfoRef?: React.MutableRefObject<any>
}

const StorageTab = ({
  headerHeightCount,
  shipmentOrdersData,
  deliveredStorageItemsData,
  storageSearchInputRef,
  storagePillsRef,
  storageTopDataHeight,
  isPendingApiLoading,
  isDeliveredApiLoading,
  savedOrderFoundInfoRef
}: StorageTabProps) => {
  const dispatch = useDispatch();
  const { selectedIndexGeneric } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );
  const [tabValue, setTabValue] = useState(storageTabTypeEnum.pending);
  const [searchValue, setSearchValue] = useState("");
  const [downPress, setDownPress] = useState(false);
  const [upPress, setUpPress] = useState(false);
  const [enterPress, setEnterPress] = useState(false);
  const [filteredShipmentOrdersData, setFilteredShipmentOrdersData] = useState(
    []
  );
  const [filteredDeliveredStorageItemsData, setFilteredDeliveredStorageItemsData] = useState(
    []
  );
  
  const { pendingShipmentSearchPlaceholder, deliveredShipmentSearchPlaceholder } = staticData
  const searchCustomersStorageRef = useRef<HTMLInputElement>();
  const searchCustomersStoragePendingRef = useRef<HTMLElement>();
  const searchCustomersStorageDeliveredRef = useRef<HTMLElement>();
  const tableRowRef = useRef<React.Ref<HTMLTableRowElement>>();

  useEffect(() => {
    if (document?.activeElement === searchCustomersStorageRef.current) {
      dispatch(arrowResetGeneric());
    }
  }, [])

  useEffect(() => {
    if (shipmentOrdersData) {
      setFilteredShipmentOrdersData(shipmentOrdersData);
    }
  }, [shipmentOrdersData]);

  useEffect(() => {
    if (deliveredStorageItemsData) {
      setFilteredDeliveredStorageItemsData(deliveredStorageItemsData);
    }
  }, [deliveredStorageItemsData]);

  useEffect(() => {
    async function downPressHandler() {
      if (downPress) {
        scrollToSetting(selectedIndexGeneric);
        if (searchCustomersStoragePendingRef.current){
          if (filteredShipmentOrdersData?.length) {
            await dispatch(arrowDownIndexGeneric(filteredShipmentOrdersData.length));
          }
        }
        if (searchCustomersStorageDeliveredRef?.current){
          if (deliveredStorageItemsData?.length) {
            await dispatch(arrowDownIndexGeneric(deliveredStorageItemsData.length));
          }
        }
        setDownPress(false);
      }
    }
    downPressHandler()
  }, [downPress]);

  useEffect(() => {
    async function upPressHandler() {
      if (upPress) {
        await dispatch(arrowUpIndexGeneric());
        if (selectedIndexGeneric > -1) {
          scrollToSetting(selectedIndexGeneric);
        }
        setUpPress(false);
      }
    }
    upPressHandler()

  }, [upPress]);

  const handleSearchChange = (e) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);
    dispatch(arrowResetGeneric());
    if(tabValue === storageTabTypeEnum.pending) {
      if (!shipmentOrdersData?.length) return shipmentOrdersData;
      if (!inputValue) return setFilteredShipmentOrdersData(shipmentOrdersData);
      const searchConfig = {
        keys: ['orderID', 'shipmentID', 'customerInvoiceID', 'storageLocation', 'poNum', 'jobName', 'shipmentInStorageID','estDeliveryDate'],
        threshold: 0.2,
      };
      const finder = new Fuse(shipmentOrdersData, searchConfig);
      return setFilteredShipmentOrdersData(finder.search(inputValue).map((item) => item.item));

    } else if(storageTabTypeEnum.delivered) {
      if (!deliveredStorageItemsData?.length) return deliveredStorageItemsData;
      if (!inputValue) return setFilteredDeliveredStorageItemsData(deliveredStorageItemsData);
      const searchConfig = {
        keys: ['orderId', 'deliveryId', 'handlerName', 'deliveryMethod', 'trackingNum', 'proofOfDeliveryDocName','deliveryTime'],
        threshold: 0.2,
      };
      const finder = new Fuse(deliveredStorageItemsData, searchConfig);
      return setFilteredDeliveredStorageItemsData(finder.search(inputValue).map((item) => item.item));
    }

  };

  const getCustomerInfoTab = () => {
    switch (tabValue) {
      case storageTabTypeEnum.pending:
        return (
          <PosTabPanel value={tabValue} index={tabValue}>
            <CustomerPending 
            data={filteredShipmentOrdersData} 
            storageTopDataHeight={storageTopDataHeight} 
            searchCustomersStoragePendingRef={searchCustomersStoragePendingRef} 
            tableRowRef={tableRowRef }
            enterPress={enterPress}
            setEnterPress={setEnterPress}
            searchValue={searchValue}
            isApiLoading={isPendingApiLoading}
            />
          </PosTabPanel>
        );

      case storageTabTypeEnum.delivered:
        return (
          <PosTabPanel value={tabValue} index={tabValue}>
            <CustomerDelivered 
            deliveredStorageItemsData={filteredDeliveredStorageItemsData} 
            storageTopDataHeight={storageTopDataHeight} 
            searchCustomersStorageDeliveredRef={searchCustomersStorageDeliveredRef} 
            tableRowRef={tableRowRef}
            enterPress={enterPress}
            setEnterPress={setEnterPress}
            searchValue={searchValue}
            isApiLoading={isDeliveredApiLoading}
            />
          </PosTabPanel>
        );

      default:
        return null;
    }
  };

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    // dispatch(setTabIndexNumber(newValue));
    setSearchValue('');
    setTabValue(newValue);
    setFilteredShipmentOrdersData(shipmentOrdersData)
    setFilteredDeliveredStorageItemsData(deliveredStorageItemsData)

  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  useEffect(() => {
    dispatch(setHeaderHeightCount(headerHeightCount));
  }, [headerHeightCount]);

  const handleSearchBoxPlaceholder = () => {
    if (tabValue === storageTabTypeEnum.pending) {
      return `${pendingShipmentSearchPlaceholder}`
    } else if (tabValue === storageTabTypeEnum.delivered) {
      return `${deliveredShipmentSearchPlaceholder}`
    }
  }
  const handleKeyPress = (e) => {
    const { keyCode } = e;
    const { downarrow, uparrow ,enter} = keys
    if (keyCode === downarrow && !savedOrderFoundInfoRef.current) {
      setDownPress(true);
    } else if (keyCode === uparrow && !savedOrderFoundInfoRef.current) {
      setUpPress(true);
    }

    if(keyCode === enter){
      const element = document.querySelector('.Mui-focusVisible');
      if (element){
        return
      }
      else if (selectedIndexGeneric > -1){
        setEnterPress(true)
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedIndexGeneric, searchCustomersStorageDeliveredRef, searchCustomersStoragePendingRef, tabValue]);

  return (
    <div className="customer-info-section customer-info-container customer-storage-sub-info-container">
      <div ref={storagePillsRef}>
        <PosTab value={tabValue} onChange={handleChange} className="customer-tab-header customer-storage-sub-tab-header">
          <Tab label="Pending" {...a11yProps(0)} className="customer-tab-header-sec" />
          <Tab label="Delivered" {...a11yProps(1)} className="customer-tab-header-sec" />
        </PosTab>
      </div>
       <div className="input-with-add-button" ref={storageSearchInputRef}>
            <PosInput
              variant="filled"
              id="filled-basic"
              type="text"
              className="document-search-input search-input-focus"
              autoFocus
              placeholder={handleSearchBoxPlaceholder()}
              value={searchValue}
              inputRef={searchCustomersStorageRef}
              onChange={handleSearchChange}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>  
          
      {getCustomerInfoTab()}
    </div>
  );
};

export default StorageTab;
