import { TableCell, withStyles } from "@material-ui/core";

const StyledTableCell = withStyles(() => ({
  head: {
    color: "#aaa",
    fontSize: 10,
    fontFamily: "Inter, Regular",
    paddingLeft: 0,
    borderBottomWidth: 0,
    lineHeight: '1.25',
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "Inter, Regular",
    borderBottomWidth: 0,
    paddingLeft: 0,
  },
}))(TableCell);

export default StyledTableCell;