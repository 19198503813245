import { useEffect } from "react";
import { ErrorMessage, Form, FormikProvider, useFormik } from "formik";
import { ErrorMessage as ErrorMessageShow } from "apps/pos/formik/errorMessage";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";

import { useUpdateStorageLocation } from "../pages/customerInfo/CustomerStorage/hooks/useUpdateStorageLocation";
import { useUpdateEstDeliveryDate } from "../pages/customerInfo/CustomerStorage/hooks/useUpdateEstDeliveryDate";
import { setIsApiLoading } from "../pages/customerInfo/CustomerOrders/redux/customerOrder.slice";
import DatePickerNew from "../Common/DatePickerNew/DatePickerNew";

import {
  PosButton,
  PosInput,
  PosModel,
} from "apps/pos/Common/uiCore";
import { Button } from "components";
import { CheckIcon, ClearIcon } from "constants/icons";
import { title } from "constants/constant";
import { DateFormat } from "constants/enum";

import "./setStorage.scss";

interface RowDataProps {
  orderID: number;
  shipmentID: number;
  customerInvoiceID: number;
  shipmentItemValue: number;
  inStorageDate: string;
  inStorageBy: number;
  referenceNum: string;
  storageLocation: string;
  estDeliveryDate: string;
  purchaseOrderNum: string;
  jobName: string;
  custCompanyName: string;
  shipAddress: string;
  handlerName: string;
  shipmentInStorageID: number;
  customerID: number;
}

interface SetStorageDetailModalProps {
  setStorageModal?: (boolean) => void;
  storageModal?: boolean;
  rowData?: RowDataProps;
}
const EditStorageDetailModal = ({
  storageModal,
  setStorageModal,
  rowData,
}: SetStorageDetailModalProps) => {
  const dispatch = useDispatch();

  const { updateStorageLocation, isLoading: updateLocationIsLoading, isStorageLocationSuccess } = useUpdateStorageLocation();
  const { updateEstDeliveryDate, isLoading: updateEstDeliveryDateIsLoading, isEstDeliveryDateSuccess } = useUpdateEstDeliveryDate();

  const isApiLoading = [
    updateLocationIsLoading,
    updateEstDeliveryDateIsLoading,
  ].includes(true); 

  const {storageLocation, estDeliveryDate, shipmentID, shipmentInStorageID} = rowData || {}
  
  const { estDeliveryDate: estDeliveryDateTitle } = title;
  const {YYYY_MM_DD} = DateFormat;

  const storageDetailsSchema = Yup.object().shape({
    estDeliveryDate: Yup.string().required("Please Select Date."),
  });
  const formik = useFormik({
    initialValues: {
      storageLocation: "",
      estDeliveryDate: "",
    },
    validationSchema: storageDetailsSchema,
    onSubmit: () => {
      updateStorageLocation({
        shipmentID,
        storageLocation: values?.storageLocation,
        shipmentInStorageID,
      });
      updateEstDeliveryDate({
        shipmentID,
        estimatedDeliveryTime: dayjs(values?.estDeliveryDate).format(YYYY_MM_DD),
        shipmentInStorageID,
      })
      
    },
  });
  const { values, setFieldValue, handleSubmit } = formik;

  useEffect(() => {
    if (storageLocation) {
      setFieldValue("storageLocation", storageLocation);
    }
  }, [storageLocation]);

  useEffect(() => {
    if (estDeliveryDate) {
      setFieldValue("estDeliveryDate", dayjs(estDeliveryDate));
    }
  }, [estDeliveryDate]);
  
  useEffect(() => {
    dispatch(setIsApiLoading(isApiLoading));
  }, [isApiLoading]);

  const closeModal = () => {
    if(!isApiLoading){
      setStorageModal(false)
    }
  }

  useEffect(() => {
    if(isEstDeliveryDateSuccess || isStorageLocationSuccess) {
      closeModal()
    }
  },[isStorageLocationSuccess, isEstDeliveryDateSuccess])

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <PosModel
            open={storageModal}
            onClose={closeModal}
            className={`set-customer-code-modal`}
            dialogClassName={`set-customer-code-modal-container put-storage-modal-container`}
            dialogHeader={
              <div className="modal-header align-items-center">
                <span className="modal-title">
                  {"Edit Storage details"}
                </span>
                <Button className="bg-transparent border-0 p-0 button-focus" onClick={closeModal}>
                  <ClearIcon
                    className="clear-icon"       
                  />
                </Button>
                
              </div>
            }
            dialogFooter={
              <>
                <div
                  className={`d-flex flex-wrap flex-row-reverse`}
                >
                 
                  <div className="d-flex flex-wrap">
                    <PosButton
                      disabled={isApiLoading}
                      variant="contained"
                      color="primary"
                      className={`add-to-return-cart-button mt-2 modal-remove-btn btn`}
                      startIcon={<CheckIcon />}
                      fontWeight
                      onClick={() => handleSubmit()}
                    >
                      Change
                    </PosButton>
                  </div>
                  <div>
                    <PosButton
                      type="button"
                      variant="contained"
                      className={`add-to-return-cart-button modal-remove-btn mr-2 mt-2 btn-font-600 btn`}
                      fontWeight
                      startIcon={<ClearIcon />}
                      onClick={closeModal}
                    >
                      Cancel
                    </PosButton>
                  </div>
                </div>
              </>
            }
          >
            <>
              <div className="set-storage-type-section modal-input-bottom-spacing">
                <div className="title-text mb-2 font-weight-600">Location</div>
                <PosInput
                  className="input-focus-styling search-input-focus"
                  variant="filled"
                  id="filled-basic"
                  autoFocus
                  InputProps={{ disableUnderline: true }}
                  name="location"
                  value={values?.storageLocation}
                  onChange={(e) => {
                    setFieldValue("storageLocation", e.target.value);
                  }}
                />
                <ErrorMessage name="storageLocation" />
              </div>
              <div className="set-storage-type-section modal-input-bottom-spacing">
                <div className="title-text mb-2 font-weight-600">
                  {estDeliveryDateTitle}
                </div>
                <div className="custom--datepicker">
                  <DatePickerNew value={dayjs(values?.estDeliveryDate).format("YYYY-MM-DD")} onChange={(e) => {
                    if (e.target.value){
                      setFieldValue("estDeliveryDate", dayjs(e.target.value));
                    }
                    else{
                      setFieldValue("estDeliveryDate", "");
                    }
                  }} minDate={dayjs(new Date()).format("YYYY-MM-DD")} />
                  <ErrorMessageShow name="estDeliveryDate" />
                </div>
              </div>
            </>
          </PosModel>
        </Form>
      </FormikProvider>
    </>
  );
};
export default EditStorageDetailModal;
