import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";

const DocumentsListSkeleton = () => {
    return (
        <>
            {
                Array(3).fill('_').map(() => (
                    <ShapedSkeleton
                        width={1500}
                        height={64}
                        shape='10 10 240 20, 500 10 120 20, 860 10 120 20, 1080 10 120 20, 1210 10 120 20, 10 40 120 10'
                        radius={4}
                        fullWidth={true}
                    />
                ))
            }
        </>
    )
}

export default DocumentsListSkeleton;