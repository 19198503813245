import { ReactNode } from "react";
import {
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  Typography,
  DialogProps,
  DialogActions,
} from "@material-ui/core";
import { PosIconButton } from "apps/pos/Common/uiCore";

import texts from "apps/pos/locales";
import { CloseIcon } from "constants/icons";

import useStyle from "./style";

interface Props extends DialogProps {
title?:string;
dialogClassName?:string;
dialogHeader?:ReactNode;
dialogTitle?:boolean;
dialogFooter?:ReactNode
onClose:() => void
disableBackgroundClose?:boolean
closeIconTabIndex?:number
}

const PosModel = ({ title, dialogClassName, children, open, onClose, dialogHeader, dialogTitle, dialogFooter, disableBackgroundClose, closeIconTabIndex }: Props) => {
  const classes = useStyle();
  const {backDropClick} = texts
  const handleDlgClose = (event, reason) => {
    if (reason === backDropClick && disableBackgroundClose) {
      return;
    }
    onClose();
  }

  return (
    <Dialog open={open} className={dialogClassName} onClose={handleDlgClose}>
        {dialogHeader && <>{dialogHeader}</>}
        {dialogTitle && <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography className={`${classes.title} model--title`}>{title}</Typography>
          {onClose && <PosIconButton onClick={() => onClose()} tabIndex={closeIconTabIndex}>
              <CloseIcon />
            </PosIconButton>}
          </Box>
        </DialogTitle>}
        <DialogContent>{children}</DialogContent>
        {dialogFooter && <DialogActions>{dialogFooter}</DialogActions>}
      </Dialog>
  );
};

export default PosModel;