export const constantCustomerInfo = {
  taxExempt: "Tax Exempt",
  creditHold: "Credit Hold",
  company: "Company",
  customerName: "Name",
  emailAddress: "Email",
  address: "Address",
  phoneNumber: "Phone",
  lastOrder: "Last Order",
  customerSince: "Customer Since",
  unPaidText: "has unpaid balance.",
  pricingSchedule: "Pricing Schedule",
  searchInStorage: "Search 4 shipments in storage",
  draftsEmptyState: "Save a cart as draft and pick up where you left off, anytime later.",
  noDraftFound: "No drafts found for",
};

export enum customerType {
  generic = "GENERIC"
}