import moment from "moment";
import { useState } from "react";

import {
  PosButton,
  PosIconButton,
  PosImage,
  PosModel,
} from "apps/pos/Common/uiCore";
import { Button } from "components";

import TruncatedText from "apps/pos/Common/TruncatedText/TruncatedText";
import DeleteDocumentModal from "apps/pos/pages/customerInfo/Documents/deleteDocument/deleteDocumentModal";
import OpenEditDocumentModal from "apps/pos/pages/customerInfo/Documents/openEditDocument/openEditDocumentModal";
import { ClearIcon, GetAppIcon } from "constants/icons";
import images from "constants/images";
import { fileNameUtil } from "utils/utils";

import { arrowResetGeneric } from "sagas/pos/actions";

import "apps/pos/pages/customerInfo/Documents/openDocument/openDocument.scss";
import { useDispatch } from "react-redux";

const OpenDocumentModal = ({
  open,
  setOpenModel,
  readOnlyInfo,
  keyIndex,
  setDeleteDocumentData,
  deleteDocumentData,
  removeDocumentItem,
  fileData,
  handleCallback,
  taxExemption,
}) => {
  const [openEditModel, setOpenEditModel] = useState(false);

  const { editIcon, whiteNewTab, orangeDeleteIcon } = images;
  const { item, date } = fileData || {};
  const { file, description, documentSaveTypeName, typeId, size, fileUrl } =
    item || {};
  const fileExtension = file ? file.split(".") : "";
  let fileType;

  if (fileExtension.length) {
    const data = fileNameUtil(fileExtension[1]);
    fileType = data + "(." + fileExtension[1] + ")";
  }
  const dispatch = useDispatch()
  return (
    <>
      <PosModel
        dialogClassName="document-info-modal"
        open={open}
        onClose={() => {
          setOpenModel(false);
          dispatch(arrowResetGeneric());
        }}
      >
        <div className="document-preview-section">
          <PosIconButton
            className="close-modal-icon button-focus"
            onClick={() => {
              setOpenModel(false)
              dispatch(arrowResetGeneric())
            }
            }
          >
            <ClearIcon />
          </PosIconButton>
        </div>
        <div className="open-document-container">
          <div className="document-title-section document-name-title">
            <div className="document-title document-name-info">
              <div className="title-modal">{file}</div>
            </div>
            {readOnlyInfo ? null : (
              <div
                className="document-edit-section"
                onClick={() => {
                  setOpenModel(false);
                  setOpenEditModel(true);
                }}
              >
                <Button className="edit-icon border-0 p-0 bg-transparent button-focus-edit-doc" type="button" >
                  <PosImage
                    className="setting-icon "
                    src={editIcon}
                    alt="setting-icon"
                  />
                </Button>
              </div>
            )}
          </div>
          <div className="document-description">
            <span id="my_div">
              {description?.length > 190 ? <>  <TruncatedText allowCollapse={true} minLength={190} className="show-more-less-clickable button-focus-class">{description}</TruncatedText></> : description}
            </span>
          </div>
          <div className="document-type-info">
            <div className="document-types">
              <div className="document-types-title">
                <span>Document type</span>
              </div>
              <div>
                <span>{documentSaveTypeName}</span>
              </div>
            </div>
            <div className="document-types">
              <div className="document-types-title">
                <span>Last Modified</span>
              </div>
              <div>
                <span>
                  {moment(item?.modifiedAt).format("MM/DD/YY") ??
                    moment(date).format("MM/DD/YY")}
                </span>
              </div>
            </div>
            <div className="document-types">
              <div className="document-types-title">
                <span>{readOnlyInfo ? "Uploaded" : "Added"}</span>
              </div>
              <div>
                <span>
                  {moment(item?.modifiedAt).format("MM/DD/YY") ??
                    moment(date).format("MM/DD/YY")}
                </span>
              </div>
            </div>
            {readOnlyInfo ? (
              <div className="document-types">
                <div className="document-types-title">
                  <span>ID</span>
                </div>
                <div>
                  <span>{typeId}</span>
                </div>
              </div>
            ) : null}
            <div className="document-types">
              <div className="document-types-title">
                <span>Size</span>
              </div>
              <div>
                <span>{size} bytes</span>
              </div>
            </div>
            <div className="document-types">
              <div className="document-types-title">
                <span>File type</span>
              </div>
              <div>
                <span>{fileType}</span>
              </div>
            </div>
          </div>
          <div className="document-bottom-section flex-row-reverse">
            <div className="d-flex flex-row-reverse">
              <div>
                <PosButton
                  startIcon={
                    <PosImage
                      className="setting-icon"
                      src={whiteNewTab}
                      alt="setting-icon"
                    />
                  }
                  variant="contained"
                  color="primary"
                  className="new-tab-open-btn btn"
                  onClick={() => window.open(fileUrl)}
                >
                  Open
                </PosButton>
              </div>
              <div>
                <PosButton
                  startIcon={<GetAppIcon />}
                  variant="contained"
                  className="download-btn btn"
                  onClick={() => window.open(fileUrl)}
                >
                  Download
                </PosButton>
              </div>

            </div>
            <div className={readOnlyInfo ? "" : "document-edit-section"}>
              {!readOnlyInfo && (
                <Button
                  type="button"
                  className="edit-icon border-0 p-0 bg-transparent button-focus-edit-doc "
                  onClick={() => {
                    setOpenModel(false);
                    setDeleteDocumentData(true);
                  }}
                >
                  <PosImage
                    className="delete-icon"
                    src={orangeDeleteIcon}
                    alt="setting-icon"
                  />
                </Button>
              )}
            </div>
          </div>
        </div>
      </PosModel>

      <OpenEditDocumentModal
        openEditModel={openEditModel}
        setOpenEditModel={setOpenEditModel}
        keyIndex={keyIndex}
        setDeleteDocumentData={setDeleteDocumentData}
        deleteDocumentData={deleteDocumentData}
        removeDocumentItem={removeDocumentItem}
        fileData={fileData}
        parentCallback={handleCallback}
        fileName={fileData?.item?.file}
        taxExemption={taxExemption}
      />
      <DeleteDocumentModal
        deleteDocumentData={deleteDocumentData}
        setDeleteDocumentData={setDeleteDocumentData}
        removeDocumentItem={removeDocumentItem}
        keyIndex={keyIndex}
        fileName={fileData?.item?.file}
      />
    </>
  );
};

export default OpenDocumentModal;
