import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

import { PosImage, PosPageHeading, PosInput,PosButton } from "apps/pos/Common/uiCore"
import { setIsLoading } from "sagas/pos/actions";

import { RootState } from "models";
import { productLocationProps } from "constants/interfaces";
import { EditIcon, InputAdornment } from "constants/icons";
import { title } from "constants/constant";
import { saleable } from "utils/utils";

import "./availableStocks.scss";

interface Props {
  warehouseDetails?: warehouseDetailsProps[];
  setModalVisible?: (value: boolean) => void;
  setAllStockModal?: (value: boolean) => void;
  ProductCountOnLocation?: ProductCountOnLocationProps[];
  productIdInfo?: number;
  handlePrePopulateLocationData?: any;
  hideAdjustStock ?: boolean;
  productDetail ?: productDetailsProps;
  discontinueProduct?: boolean;
  findQtyByWarehouse?: any;
}
interface productDetailsProps {
  thumbImg: string;
  productName: string;
  manufacturerCode : string;
  handlePrePopulateLocationData?: any;
}
interface warehouseDetailsProps {
  productId: number;
  stockWarehouses: ProductCountOnLocationProps[];
}
interface ProductCountOnLocationProps {
  stockWarehouseId: number;
  unitQtyAvailable: number;
  warehouseName: string;
}

const AvailableAllStock = ({ handlePrePopulateLocationData, setModalVisible, warehouseDetails, setAllStockModal, ProductCountOnLocation, productIdInfo , hideAdjustStock =false , productDetail, discontinueProduct, findQtyByWarehouse}: Props) => {

  const dispatch = useDispatch();
  
  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  const { productsData, allRowsData } = useSelector<RootState, any>((state) => state.pos);

  const filterListData = (searchValue: string) => {
    setSearchInput(searchValue);
    const itemWarehouseInfo = warehouseDetails?.find((i) => i?.productId === productIdInfo)
    if (searchValue !== "") {
      const filterData = itemWarehouseInfo?.stockWarehouses?.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      }
      );
      setFilteredResults(filterData);
    } else {
      setFilteredResults(itemWarehouseInfo?.stockWarehouses);
    }
  };

  const { availableStockLocation } = title

  const saleableRows = useMemo(() => Array.isArray(allRowsData) ? allRowsData.filter(saleable) : [], [allRowsData])

  const handleAdjustStock = () => {

    setAllStockModal(false)
    dispatch(setIsLoading(true))
    if (saleableRows?.length === 1) {
      handlePrePopulateLocationData()
    } else {
      setModalVisible(true)
      dispatch(setIsLoading(false))
    }
  }

  const handleSearchWarehouses = (e) => filterListData(e.target.value)

  const handleQtyAvailable = (unitQtyAvailable) => unitQtyAvailable === 0 ?"available-qty-grey" : "available-unit-qty"

  return (
    <div className="available-stock-container">
      <PosPageHeading
        className="available-stock-name"
        heading={availableStockLocation}
        blackHeading
      />
      <div className="available-stock-product">
        <div className="available-stock-image-and-text">
          <div className="available-stock-product-img">
              <PosImage src={hideAdjustStock ? productDetail?.thumbImg : productsData?.thumbImg} className="item-img" alt="item-img" />           
          </div>
          <div className="available-stock-product-info">
            <div className="available-stock-product-name">
              {hideAdjustStock ? productDetail?.productName : productsData?.productName}
            </div>
            <div className="available-stock-manufacturer-code">
              {hideAdjustStock ? productDetail?.manufacturerCode : productsData?.manufacturerCode}
            </div>
          </div>
        </div>
        {!hideAdjustStock ? <div className="available-stock-adjust-btn">
          <PosButton
            variant="contained"
            onClick={handleAdjustStock}
            startIcon={<EditIcon/>}
            className="btn adjust-button adjust-stock-button move-stock-btn"
            disabled={discontinueProduct && findQtyByWarehouse?.unitQtyAvailable === 0}
           >
            Adjust Stock
          </PosButton>
        </div> : null }
      </div>
      <div className="available-search-input-box">
        <PosInput
            variant="filled"
            id="filled-basic"
            className="input-focus-styling"
            name="description"
            placeholder="Search 2 warehouse"
            onChange={handleSearchWarehouses}
            InputProps={{
                disableUnderline: true,
                startAdornment: (
                    <InputAdornment position="start">
                        <div className="search-bar-icon">
                          <SearchIcon />
                        </div>
                    </InputAdornment>
                ),
            }}
        />
      </div>
      <div>
        <Table className="available-stock-table">
          <TableHead className="sticky-table-header">
            <TableRow className="product-search-table-header-row">
              <TableCell width={260} className="available-stock-table-location">
                WAREHOUSE
              </TableCell>
              <TableCell width={260} className="available-stock-table-avl-qty">
                AVL. QTY.
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchInput?.length > 0
              ? filteredResults?.length ? filteredResults?.map(({unitQtyAvailable,stockWarehouseName}: productLocationProps, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      width={260}
                      component="th"
                      scope="row"
                      className={handleQtyAvailable(unitQtyAvailable)}
                    >
                      {stockWarehouseName}
                    </TableCell>
                    <TableCell
                      width={260}
                      className={handleQtyAvailable(unitQtyAvailable)}
                    >
                      {unitQtyAvailable}
                    </TableCell>
                  </TableRow>
                );
              }): null
              : ProductCountOnLocation?.length ? ProductCountOnLocation?.map(({unitQtyAvailable,stockWarehouseName}: productLocationProps, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      width={260}
                      component="th"
                      scope="row"
                      className={`${unitQtyAvailable === 0
                          ? "available-wh-name-grey"
                          : "available-warehouse-name"
                      }`}
                    >
                      {stockWarehouseName}
                    </TableCell>
                    <TableCell
                      width={260}
                      className={`${
                        unitQtyAvailable == 0
                          ? "available-qty-grey"
                          : "available-unit-qty"
                      }`}
                    >
                      {unitQtyAvailable}
                    </TableCell>
                  </TableRow>
                  )}):null}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
export default AvailableAllStock;
