import cartPage from "./cartPage.json"
import itemLedger from "./itemLedger.json"
import searchProduct from "./searchProduct.json"
import importQuote from "./importQuote.json"
import adjustStock from "./adjustStock.json"
import qa from "./qa.json"
import customerFlow from "./customerFlow.json"
import pickQtyPage from "./pickQtyPage.json"

export default {
  ...cartPage,
  ...itemLedger,
  ...searchProduct,
  ...importQuote,
  ...adjustStock,
  ...qa,
  ...customerFlow,
  ...pickQtyPage,
}