import { PosImage } from "apps/pos/Common/uiCore";

import images from "constants/images";

import {
  CheckRadioProps,
  InputIconProps,
  InputProps,
  InputWithIconProps,
  SelectProps,
  TextAreaProps,
} from "./type";

export function Input({
  className,
  id,
  label,
  type,
  placeholder,
  name,
  register,
  onChange,
  textInputStyle,
  defaultValue,
  ...props
}: InputProps) {
  return (
    <div className={`form-group ${className ? className : ""} `}>
      {label && (
        <label className="fs-14 inter-reg black_text mb-2" htmlFor={id}>
          {label}
        </label>
      )}
      <input
        {...props}
        className={`form-control ${textInputStyle}`}
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        ref={register}
        onChange={onChange}
        defaultValue={defaultValue}
      />
    </div>
  );
}

export function InputOptional({
  className,
  id,
  label,
  type,
  placeholder,
  name,
  register,
  onChange,
  defaultValue,
  textInputStyle,
  value,
  ...props
}: InputProps) {
  return (
    <div className={`form-group ${className ? className : ""} `}>
      <div className="d-flex align-items-center">
        {label && (
          <label className="label-styling" htmlFor={id}>
            {label}
          </label>
        )}
        <div className="optionaltext"> (optional)</div>
      </div>
      <input
        {...props}
        className={`form-control ${textInputStyle}`}
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        ref={register}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
      />
    </div>
  );
}

export function MultilineTextArea({
  className,
  id,
  label,
  placeholder,
  name,
  register,
  textInputStyle,
  onChange,
  value,
  defaultValue,
}: TextAreaProps) {
  return (
    <div className={`form-group ${className ? className : ""} `}>
      <label htmlFor={id} className="fs-14 inter-reg black_text">
        {label}
      </label>
      <textarea
        className={`form-control ${textInputStyle}`}
        id={id}
        placeholder={placeholder}
        name={name}
        ref={register}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
      />
    </div>
  );
}

export function TextArea({
  className,
  id,
  label,
  placeholder,
  name,
  register,
  value,
  onChange,
  rows,
}: TextAreaProps) {
  return (
    <div className={`form-group ${className ? className : ""} `}>
      <label htmlFor={id}>{label || ""}</label>
      <textarea
        className="form-control"
        id={id}
        placeholder={placeholder}
        name={name}
        ref={register}
        value={value}
        onChange={onChange}
        rows={rows}
      />
    </div>
  );
}

export function Select({
  className,
  selectClassName,
  id,
  label,
  options,
  name,
  register,
  defaultValue,
  isClassName = true,
  disabled,
  onChange,
  isSelectOption,
  customSelectPlaceholder,
  isFacilityOption,
  labelClassName,
}: SelectProps) {
  return (
    <div className={`form-group ${className ? className : ""} `}>
      {label && (
        <label
          htmlFor={id}
          className={`fs-14 inter-reg black_text mb-2 ${labelClassName}`}
        >
          {label}
        </label>
      )}
      <select
        className={
          isClassName ? `form-control ${selectClassName}` : selectClassName
        }
        id={id}
        name={name}
        ref={register}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        {isSelectOption && (
          <option key={"0"} value={""}>
            {"Select"}
          </option>
        )}

        {customSelectPlaceholder && (
          <option key={"0"} value={""}>
            {customSelectPlaceholder}
          </option>
        )}

        {isFacilityOption && (
          <option key={"0"} value={""}>
            {"Select facility type"}
          </option>
        )}

        {options?.length &&
          options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
}

export function RadioCheckInput({
  className,
  id,
  label,
  type,
  name,
  register,
  onChange,
}: CheckRadioProps) {
  return (
    <div
      className={`custom-control custom-${type} ${className ? className : ""}`}
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <input
        className="custom-control-input"
        id={id}
        value={id}
        type={type}
        name={name}
        ref={register}
        onChange={onChange}
      />
      <label
        className="custom-control-label fs-16 inter-reg black_text ms-2 "
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
}

function InputIcon({ iconName, iconPosition, iconStyle }: InputIconProps) {
  return (
    <div className={`input-group-${iconPosition}`}>
      <span className="input-group-text">
        <i className={`fas fa-${iconName} ${iconStyle}`}></i>
      </span>
    </div>
  );
}

export function InputWithIcon({
  className,
  type,
  placeholder,
  name,
  ariaLabel,
  iconPosition,
  iconName,
  textInputStyle,
  iconStyle,
  isSearch,
  ...props
}: InputWithIconProps) {
  const { pressEnter } = images;
  return (
    <div
      className={`input-group input-group-joined flex-align-item-center ${
        className ? className : ""
      }`}
    >
      {iconPosition === "prepend" && (
        <InputIcon
          iconName={iconName}
          iconPosition={iconPosition}
          iconStyle={iconStyle}
        />
      )}
      <input
        className={`form-control ${textInputStyle}`}
        type={type}
        name={name}
        placeholder={placeholder}
        aria-label={ariaLabel}
        {...props}
      />
      {iconPosition === "append" && (
        <InputIcon
          iconName={iconName}
          iconPosition={iconPosition}
          iconStyle={iconStyle}
        />
      )}
      {isSearch ? (
        <div className="enter-search-text-admin">
          <span>Press Enter</span>
          <PosImage
            src={pressEnter}
            alt="enter-icon"
            className="press-enter-image"
          />
          <span>to search</span>
        </div>
      ) : null}
    </div>
  );
}
