import { TextField, TextFieldProps } from "@material-ui/core";

import useStyles from "./style";

const PosInput = ({
  autoComplete,
  type,
  onChange,
  className,
  color,
  inputRef,
  placeholder,
  value,
  autoFocus,
  onBlur,
  onFocus,
  onKeyPress,
  variant,
  inputProps,
  InputProps,
  id,
  fullWidth,
  multiline = false,
  maxRows,
  disabled,
  onKeyDown,
  onClick,
  style,
  label,
  onWheel,
  ref,
  tabIndex,
  defaultValue,
  maxLength
}: TextFieldProps & React.InputHTMLAttributes<HTMLInputElement>) => {
  const classes = useStyles();
  const handleInput = (event: any) => {
    if (maxLength && (type === "number" || "text") && (event.target.value.length > maxLength)) {
      event.preventDefault();
      event.stopPropagation();
      event.target.value = event.target.value.slice(0, maxLength);
    }
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <>
      {/* start */}
      <TextField
      defaultValue={defaultValue}
        tabIndex={tabIndex}
        fullWidth={fullWidth}
        variant={variant}
        id={id}
        maxRows={maxRows}
        autoComplete={autoComplete || "off"}
        type={type || "text"}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        color={color}
        inputRef={inputRef}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        autoFocus={autoFocus}
        inputProps={inputProps}  
        InputProps={InputProps}
        multiline={multiline}
        disabled={disabled}
        onKeyDown={onKeyDown}
        className={`${className ? className : ""} ${classes.root}`}
        onClick={onClick}
        style={style}
        label={label}
        onWheel={onWheel}
        ref={ref}
        onInput={handleInput}
      />
    </>
  );
};
export default PosInput;
