import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Divider,
  PosButton,
  PosImage,
  PosPageHeading,
} from "apps/pos/Common/uiCore";
import { Button } from "components";

import texts from "apps/pos/locales";
import images from "constants/images";
import { CartProductsProp, PosFlowDataProp, UploadedDocumentItemProp } from "constants/interfaces";
import { Paths } from "enums";
import { PosResponseProps, RootState } from "models";

import { ProductPriceType, ProductTypes } from "constants/enum";
import { loadCartRequest, recipientInfoSwitchInfoData } from "sagas/persistReducer/actions";
import {
  cartDraftClear,
  cartDraftRequest,
  getupdateItemModal,
} from "sagas/pos/actions";
import { authorizationToken, getLocalStoraGeItem, priceToBeFixedConverter, roundToTwoDecimalPlaces } from "utils/utils";

import "./updateproduct.scss";

const UpdateProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loadCartDetails, posFlowData, isTaxExempted, allowPickupCheck } = useSelector<
    RootState,
    PosFlowDataProp
  >((state) => state.persistReducer);

  const { cartDraftSuccess, updateItemModal, isPurchaseOrderNoEdit, orderInstructionNote, shippingInstructionNote } = useSelector<
    RootState,
    PosResponseProps
  >((state) => state.pos);

  const { products, shipToByFacility } = loadCartDetails;
  const { typeDiscontinued } = ProductTypes;

  const isAvailableInStockProduct = products?.length
    ? products?.filter(
      (item: CartProductsProp) =>
        item?.isPriceChanged &&
        item?.qtyInStock > 0 &&
        item?.productPrices?.length &&
        item?.productPrices[0]?.type !== typeDiscontinued &&
        (item?.qtyInStock > item?.qtyOrdered ||
          item?.qtyInStock === item?.qtyOrdered)
    )
    : [];

  const isAvailableOutOfStockProduct = products?.filter(
    (item: CartProductsProp) =>
      item?.isPriceChanged &&
      (item?.qtyInStock === 0 || item?.qtyInStock < item?.qtyOrdered) &&
      item?.productPrices?.length &&
      item?.productPrices[0]?.type !== typeDiscontinued
  );

  const isDiscontinuedProduct = products?.filter(
    (item: CartProductsProp) =>
      item?.isPriceChanged &&
      item?.qtyInStock > 0 &&
      item?.productPrices?.length &&
      item?.productPrices[0]?.type === typeDiscontinued
  );

  const isDeactivatedProduct = products?.filter(
    (item: CartProductsProp) =>
      item?.qtyInStock === 0 &&
      item?.productPrices?.length &&
      item?.productPrices[0]?.type === typeDiscontinued &&
      item?.qtyInStock < item?.qtyOrdered
  );

  const allProductStatusTotal =
    isAvailableInStockProduct?.length +
    isDiscontinuedProduct?.length +
    isAvailableOutOfStockProduct?.length +
    isDeactivatedProduct?.length;
  const { dollerCheck, outStock, disContinue, deactivated, cautionIcon } =
    images;

  const findLength =
    isAvailableInStockProduct?.length ||
    isAvailableOutOfStockProduct?.length ||
    isDiscontinuedProduct?.length ||
    isDeactivatedProduct?.length;
  const totalLength =
    isAvailableInStockProduct?.length +
    isAvailableOutOfStockProduct?.length +
    isDiscontinuedProduct?.length +
    isDeactivatedProduct?.length;
  const {
    cartProducts,
    savedCart,
    shiptoFacilities,
    customerInfoUpdated,
    poNumber,
    schedule,
    selectQuoteId,
    selectQuoteType,
    customer,
    salesman,
    addDocument,
  } = posFlowData || {};
  const { cartId } = savedCart || {}

  const {
    taxExemptFlag,
    customerId,
    fullName,
    phone,
    email,
    creditHoldFlag,
    showPrices,
    isPosGeneric,
    lookupType,
    posCustCode,
    priceScheduleId,
  } = savedCart?.customer || {};
  const firstName = fullName?.split(" ")?.[0];
  const lastName = fullName?.split(" ")?.[1];
  const decodedData = authorizationToken("Authorization");
  const { pickup } = texts;

  let cartExtPrice = 0;
  cartProducts?.length &&
    cartProducts.forEach(({ extPrice, qtyOrdered }) => {
      cartExtPrice = cartExtPrice + extPrice * qtyOrdered;
    });


  let taxRateToBeSent;

  if (taxExemptFlag && isTaxExempted) {
    taxRateToBeSent = 0.0;
  } else {
    if (
      savedCart?.shipToByFacility?.recipient?.taxExemptFlag &&
      isTaxExempted
    ) {
      taxRateToBeSent = 0.0;
    } else {
      if (isTaxExempted) {
        taxRateToBeSent = 0.0;
      } else {
        if (savedCart?.shipToByFacility?.tax?.shipTaxFlag === 0) {
          taxRateToBeSent = 0.0;
        } else {
          taxRateToBeSent = pickup
            ? shipToByFacility?.tax?.taxRate
            : shiptoFacilities?.tax?.taxRate;
        }
      }
    }
  }
  const subTotalAmount = cartExtPrice ? cartExtPrice.toFixed(2) : 0;
  const taxAmountWithoutRoundOff = (cartExtPrice * taxRateToBeSent) / 100;
  const taxAmount = roundToTwoDecimalPlaces(taxAmountWithoutRoundOff);

  const currentTaker = JSON.parse(localStorage.getItem("taker"));
  const takerFullName = currentTaker?.fullName;

  const handleSkip = () => {
    const cartData = cartProducts;
    const cartAddedData = [];

    cartData?.length &&
      cartData.forEach(
        ({
          qtyInStock,
          qtyOrdered,
          shippingNote,
          orderNote,
          discontinued,
          posRetailPrice,
          productId,
          manufacturerCode,
          vendorId,
          manufacturerId,
          productName,
          vendorPrice,
          salesPrice,
          priceMU,
          itemMU,
          itemMuName,
          unitPerCase,
          clearance,
          muName,
          clearanceFlag,
          discontinueFlag,
          priceScheduleVendorProductGroupId,
          overridePrice,
          lineId,
          productPriceTypeApplied,
          itemType,
          markupMultiplier
        }) => {

          const newQtyClearance =
            qtyOrdered > qtyInStock ? qtyInStock : qtyOrdered;
          const isEligible = discontinued && qtyInStock <= 0;

          !isEligible &&
            cartAddedData.push({
              finalPosRetailPrice: posRetailPrice || 0,
              productId,
              manufacturerCode,
              manufacturerId,
              vendorId,
              itemType: itemType || null,
              productName,
              vendorPrice: vendorPrice?.price ?? vendorPrice,
              salesPrice: salesPrice?.price ?? salesPrice,
              initialPosRetailPrice: posRetailPrice || 0,
              priceMU,
              muName,
              itemMU,
              itemMuName,
              unitPerCase,
              qtyInStock,
              qtyOrdered: clearance ? newQtyClearance : qtyOrdered,
              clearanceFlag,
              discontinueFlag,
              markupMultiplier,
              overridePrice: overridePrice || null,
              priceScheduleVendorProductGroupId,
              pickConfigs: null,
              shippingNote,
              orderNote,
              posRetailPrice: posRetailPrice || 0,
              lineId,
              productPriceTypeApplied: typeof productPriceTypeApplied === "string" ? ProductPriceType[productPriceTypeApplied] : productPriceTypeApplied,
            });
        }
      );

    const customerData = {
      customerId: customerId || "",
      buyOnBanNote: customer?.hasInternalNotes,
      customerNote: savedCart?.customerNote || "",
      customerInfoUpdated: customerInfoUpdated,
      firstName: firstName,
      lastName: lastName,
      phone: phone || null,
      email: email ? email : "",
      buyOnCreditHold: creditHoldFlag === 1,
      showPrices,
      isPosGeneric,
      lookupType: lookupType,
      posCustCode: posCustCode || null,
    };
    const uploadData = [];
    const formFiles = [];
    addDocument?.length &&
      addDocument.map((item: UploadedDocumentItemProp) => {
        const {
          file,
          description,
          typeId,
          documentSaveType,
          locationSaveType,
          uploadedBy,
          originalFile,
        } = item;
        formFiles.push(originalFile);
        if (originalFile) {
          const data = {
            fileName: file,
            description: description,
            typeId: typeId,
            documentSaveType: documentSaveType,
            locationSaveType: locationSaveType,
            uploadedBy: uploadedBy,
          };
          uploadData.push(data);
        }
      });

    const savedDraftTaxExempt = savedCart?.customer?.taxExemptFlag
      ? 1
      : savedCart?.shipToByFacility?.recipient?.taxExemptFlag
        ? 1
        : isTaxExempted
          ? 1
          : 0
    const preparedData = {
      jobName: savedCart?.jobName,
      sessionStartedAt: savedCart?.sessionStartedAt
        ? savedCart?.sessionStartedAt
        : "",
      cartId: savedCart?.cartId ? savedCart?.cartId : "",
      loggedInUserId: decodedData?.DefaultLoginId,
      transactionType: "SALE",
      stockWarehouseId,
      takerId: currentTaker?.personId,
      purchaseOrderNum: (poNumber || (!isPurchaseOrderNoEdit && loadCartDetails?.products?.length && loadCartDetails.purchaseOrderNum) || null),
      subTotalAmt: Number(subTotalAmount),

      customer: customerData,
      taxRate: savedDraftTaxExempt === 1 ? 0 : taxRateToBeSent,
      taxSchedule: savedDraftTaxExempt === 1 ? "NONTAX" : savedCart?.shipToByFacility?.tax?.taxSchedule,
      initialTaxAmt: savedDraftTaxExempt === 1 ? 0 : taxAmount,
      initialTaxExemptFlag: savedDraftTaxExempt === 1 ? 1 : 0,
      shipTaxFlag: savedCart?.shipToByFacility?.tax?.shipTaxFlag
        ? savedCart?.shipToByFacility?.tax?.shipTaxFlag
        : 0,
      initialShipToAddressId: savedCart?.shipToByFacility?.facilityId,
      finalShipToAddressId: shiptoFacilities?.facilityId,
      salesPersonId: posFlowData?.salesman?.ssoId || posFlowData?.salesman?.personId || 0,
      initialScheduleId: priceScheduleId,
      finalScheduleId: schedule?.id
        ? schedule?.id
        : priceScheduleId
          ? priceScheduleId
          : "",
      paymentMode: "CASH",
      paymentRef1: null,
      paymentRef2: null,
      taxAmt: taxExemptFlag
        ? 0
        : savedCart?.shipToByFacility?.recipient?.taxExemptFlag
          ? 0
          : isTaxExempted
            ? 0
            : 0,
      taxExemptFlag: savedDraftTaxExempt,
      shippingFeeAmt: 0,
      shippingTaxAmt: 0,
      grandTotalAmt: 0,
      tenderAmt: 0,
      tenderBalanceAmt: Number(priceToBeFixedConverter(0)),
      quoteId: selectQuoteId ? selectQuoteId : null,
      quoteType: selectQuoteType ? selectQuoteType : "NOT_SET",
      products: cartAddedData,
      uploadFiles: uploadData?.length ? uploadData : null,
      shippingNote: shippingInstructionNote,
      orderNote: orderInstructionNote,
      takerName: takerFullName,
      salesPersonName: salesman?.salespersonName ? salesman?.salespersonName : '',
      noDeliverToShipAddressButPickupFlag: allowPickupCheck ?? false
    };
    const processDataFormat = JSON.stringify(JSON.stringify(preparedData));

    const formData = new FormData();
    formData.append("posOrderTransactionRequest", processDataFormat);

    addDocument.forEach((item: UploadedDocumentItemProp) => {
      const { originalFile } = item;
      if (originalFile) {
        formData.append("formFiles", originalFile);
      }
    });

    dispatch(cartDraftRequest(formData, cartId));
  };

  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {};
  const currentTakerInfoStorage = getLocalStoraGeItem("taker");

  useEffect(() => {
    if (cartDraftSuccess) {
      const loadRequest = {
        stockWarehouseId,
        customerId: customer?.customerId,
        takerId: currentTakerInfoStorage?.personId,
        cartId: savedCart?.cartId,
      };
      dispatch(loadCartRequest(loadRequest));
      dispatch(cartDraftClear());
      dispatch(recipientInfoSwitchInfoData(false))
      if (updateItemModal) {
        dispatch(getupdateItemModal(false));
      } else {
        navigate(`${Paths.posCart}/${loadCartDetails?.cartId}`);
      }
    }
  }, [cartDraftSuccess]);

  return (
    <div className="update-product-container">
      <PosPageHeading
        className="update-product-heading"
        heading={`${allProductStatusTotal > 1
          ? `${allProductStatusTotal} ` + "items have updates"
          : `${allProductStatusTotal} ` + "item has updates"
          } `}
        blackHeading
      />
      <div className="update-product-decription">
        {totalLength === 1 || totalLength === 0
          ? "Review it to select which price to use."
          : "Review them to select which prices to use."}
      </div>
      <div className="update-product-items">
        <div className="update-product-changes">WHAT CHANGED</div>
        <div className="update-product-list-container">
          {isAvailableInStockProduct?.length ? (
            <>
              <div className="update-product-list">
                <div className="update-product-price-change">
                  <PosImage src={dollerCheck} alt="dollar-icon" />{" "}
                  {isAvailableInStockProduct?.length === 1 ||
                    isAvailableInStockProduct?.length === 0
                    ? "Price"
                    : " Prices"}{" "}
                  Changed
                </div>
                <div className="update-product-price-item">
                  {isAvailableInStockProduct?.length}{" "}
                  {isAvailableInStockProduct?.length === 1 ||
                    isAvailableInStockProduct?.length === 0
                    ? "item"
                    : " items"}
                </div>
              </div>
              <Divider className="update-product-container-line" />
            </>
          ) : null}
          {isAvailableOutOfStockProduct?.length ? (
            <>
              <div className="update-product-list">
                <div className="update-product-price-change">
                  <PosImage src={outStock} alt="stock-icon" /> Out of Stock
                </div>
                <div className="update-product-price-item">
                  {isAvailableOutOfStockProduct?.length}{" "}
                  {isAvailableOutOfStockProduct?.length === 1 ||
                    isAvailableOutOfStockProduct?.length === 0
                    ? "item"
                    : " items"}
                </div>
              </div>
              <Divider className="update-product-container-line" />
            </>
          ) : null}
          {isDiscontinuedProduct?.length ? (
            <>
              <div className="update-product-list">
                <div className="update-product-price-change">
                  <PosImage src={disContinue} alt="discontinue-icon" />{" "}
                  Discontinued, Available in Stock
                </div>
                <div className="update-product-price-item">
                  {isDiscontinuedProduct?.length}{" "}
                  {isDiscontinuedProduct?.length === 1 ||
                    isDiscontinuedProduct?.length === 0
                    ? "item"
                    : " items"}
                </div>
              </div>
              <Divider className="update-product-container-line" />
            </>
          ) : null}
          {isDeactivatedProduct?.length ? (
            <div className="update-product-list">
              <div className="update-product-price-change">
                <PosImage src={deactivated} alt="deactivated-icon" />{" "}
                Discontinued, Out of Stock
              </div>
              <div className="update-product-price-item">
                {isDeactivatedProduct?.length}{" "}
                {isDeactivatedProduct?.length === 1 ||
                  isDeactivatedProduct?.length === 0
                  ? "item"
                  : " items"}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="update-product-footer">
        <PosImage src={cautionIcon} alt="caution" className="mr-2" />
        Skipping removes discontinued items that are out of stock.
      </div>
      <div className="update-product-btn flex-row-reverse">
        <PosButton
          variant="contained"
          color="primary"
          onClick={() => navigate(Paths.posReviewItems)}
          className="btn-savechanges btn-review-item review-item-modal-btn btn"
        >
          {findLength === 1 || findLength === 0
            ? "Review Item"
            : "Review Items"}
        </PosButton>
        <Button className="update-skip-odl-price border-0 button-focus-class" onClick={() => handleSkip()}>
          {findLength === 1 || findLength === 0
            ? "Continue with Old Price"
            : "Continue with Old Prices"}
        </Button>
      </div>
    </div>
  );
};

export default UpdateProduct;
