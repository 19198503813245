import { useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { PosImage } from "apps/pos/Common/uiCore";
import { Button } from "components";
import HandleLongData from "../Common/HandleLongData";

import images from "constants/images";
import { PosResponseProps, RootState } from "models";
import { optionsDraftSort, dateEnum } from "constants/staticData";
import { renamePersonData } from "utils/helper";

const DraftList = ({
  draftRowRef,
  loadCartAPI,
  setIsDraftCartId,
  setDeletePopUp,
  setDeleteCartId,
  stringSearch,
  isSnackbarVisibleResume,
  selectedOption,
  searchString,
  setIsDeleteClick
}) => {
  const { savedDrafts } = useSelector<
    RootState,
    PosResponseProps
  >((state) => ({ ...state.pos, ...state.persistReducer }));
  let draftCount = 0;

  const filterdDraftList = useMemo(() => {
    let tempDraftList = savedDrafts?.data;
    if (savedDrafts?.data.length) {
      switch (selectedOption) {
        case optionsDraftSort[0].value:
          tempDraftList = savedDrafts?.data?.reverse();
          break;
        case optionsDraftSort[1].value:
          tempDraftList = [...savedDrafts?.data.reverse()];
          break;
      }
    }
    if (stringSearch) {
      tempDraftList = tempDraftList?.filter(
        (data) =>
          data.jobName
            ?.toLocaleLowerCase()
            ?.includes(stringSearch.toLocaleLowerCase()) ||
          data.companyName
            ?.toLocaleLowerCase()
            ?.includes(stringSearch.toLocaleLowerCase()) ||
          data.purchaseOrderNum
            ?.toLocaleLowerCase()
            ?.includes(stringSearch.toLocaleLowerCase()) ||
          `${data.cartId}`
            ?.toLocaleLowerCase()
            ?.includes(stringSearch.toLocaleLowerCase())
      );
    }
    return tempDraftList;
  }, [selectedOption, savedDrafts, stringSearch]);

  const { trashIcon } = images;
  const currentDateLocal = moment().format(dateEnum.ymdFormat);

  const sortedByDate = filterdDraftList.reduce((groups, draft) => {
    const date = moment(draft.createdAt).format(dateEnum.ymdFormat);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(draft);
    return groups;
  }, []);

  const dates = Object.keys(sortedByDate).map((item) => {
    return new Date(item)
  }).sort((a, b) => b.getTime() - a.getTime()).map((item) => moment(item).format(dateEnum.ymdFormat));
  
  const sortedDateObject = Object.fromEntries(dates.map(date => [date, sortedByDate[date]]));

  const handleScheduleList = (cartId) => () =>{
    if(!isSnackbarVisibleResume){
      loadCartAPI(cartId);
      setIsDraftCartId(cartId);
    }
  }
  const onDeletePopUp = (draft)=> () =>{
    setDeletePopUp(true);
    setDeleteCartId(draft);
    setIsDeleteClick(true);
  }
  return (
    <>
      {filterdDraftList.length ? (
        Object.keys(sortedDateObject).map((item, index) => {
          const itemDate = (moment(item).format(dateEnum.mdFormat)).toUpperCase();
          const checkDate = currentDateLocal === item;
          return (
            <div key={`draft-list-${index}`} id="shippingScrollStyling" className={searchString ? '' : 'pt-0'}>
              <div className="d-flex align-items-center mt-20">
                <div className="date-title">
                  {checkDate
                    ? dateEnum.dateToday+", " + itemDate
                    : itemDate}
                </div>
                <div className="divider-date" />
              </div>
              {sortedDateObject[item]?.map((draft, index) => {
                const {cartId, jobName, companyName, purchaseOrderNum} = draft;
                draftCount = jobName ? draftCount : draftCount + 1;
                return (
                  <div
                    key={`draftList-${index}`}
                    ref={draftRowRef[index]}
                    id={`draftRowRef${index}`}
                  >
                    <div className="frequently-used-schedule-focus frequently-used-schedule">
                      <Button className="saved-jobname border-0 bg-transparent" onClick={handleScheduleList(cartId)}>
                        <h3 className="select-schedule-list select-list-data d-flex flex-wrap text-start">
                          {jobName ? <HandleLongData jobName={jobName}/> : `Draft ${draftCount}`}
                        </h3>
                        <div className="d-flex align-items-center mt-1">
                          <span className="int-16-reg-gray">
                            {renamePersonData(companyName)}
                          </span>
                        </div>
                        <div className="d-flex align-items-center mt-1">
                          <span className="int-12-reg-gray">
                            {cartId}
                          </span>
                          {purchaseOrderNum? <><div className="draft-badge" />
                          <span className="int-12-reg-gray d-flex w-100">
                            <span className="mr-1">PO #</span><HandleLongData jobName={purchaseOrderNum} />
                          </span></> : null}
                        </div>
                      </Button>
                      <Button className="bg-transparent border-0 p-0 button-focus" tabIndex={0} onClick={onDeletePopUp(draft)} >
                        <PosImage
                          src={trashIcon}
                          alt="trash-icon"
                          className="trash-icon"
                        />
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};
export default DraftList;
