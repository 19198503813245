import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { PosResponseProps, RootState } from "models";
import { PosFlowDataProp } from "constants/interfaces";
import { getLocalStoraGeItem } from "utils/utils";
import { addAdjustQuantitySchema } from "apps/pos/formik/validationSchema";

import { stockInRequest } from "sagas/pos/actions";
import { setPosFlowData } from "sagas/persistReducer/actions";

const useAddStockFormik = () => {
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { stockInDetails, productsData } = useSelector<
    RootState,
    PosResponseProps
  >((state) => state.pos);
  const dispatch = useDispatch();
  const { cartProductDetails, savedCart } = posFlowData || {};
  const { productId } = cartProductDetails || {};
  const { takerId } = savedCart || {};
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace || {};
  const windowsUrl = window.location.pathname;

  const formik = useFormik({
    initialValues: {
      addAdjustStockRow: "",
      addAdjustStockUnit: "",
      addAdjustStockShelf: "",
      quantityToAdd: "",
      addReason: "",
    },
    validationSchema: addAdjustQuantitySchema,
    onSubmit: (values) => {
      const products = posFlowData?.cartProducts?.length
        ? posFlowData?.cartProducts.map((item) => {
            if (item.productId === productsData.productId) {
              return {
                ...item,
                qtyOnLocationData: values?.quantityToAdd,
                qtyInStock:
                  Number(item.qtyInStock) + Number(values?.quantityToAdd),
              };
            }
            return item;
          })
        : null;

      const newPosFlowData = {
        ...posFlowData,
        proceedToPayment: false,
        cartProducts: products,
      };
      dispatch(setPosFlowData(newPosFlowData));
      const {
        quantityToAdd,
        addAdjustStockUnit,
        addAdjustStockShelf,
        addReason,
      } = values || {};
      const data = {
        productID: productId,
        qtyOfUnits: Number(quantityToAdd),
        reasonID: 4,
        handlerID: takerId,
        orderID: 0,
        extraInfo: addReason,
        stockWarehouseID: stockWarehouseId,
        stockUnitID: Number(addAdjustStockUnit),
        stockShelfID: Number(addAdjustStockShelf),
        currentRequestUrl: windowsUrl,
      };
      if (!stockInDetails?.length) {
        dispatch(stockInRequest(data));
      }
    },
  });
  return formik;
};

export default useAddStockFormik;
