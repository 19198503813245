import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import { useTheme } from "@material-ui/core/styles";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  Container,
  Divider,
  PosDataGrid,
  PosHelmet,
  PosIconButton,
  PosImage,
  PosPageHeading,
  TablePagination
} from "apps/pos/Common/uiCore";
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import { title, warehouseName } from "constants/constant";
import { DateFormat, PaginationFormat } from "constants/enum";
import {
  ArrowBackRoundedIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  SkipNextIcon,
  SkipPreviousIcon,
} from "constants/icons";
import images from "constants/images";
import { PosFlowDataProp } from "constants/interfaces";
import { Paths } from "enums";
import { RootState } from "models";
import { addToCartDrawer, itemLedgerInoutRequest, itemLedgerRequest } from "sagas/pos/actions";
import { keys } from "utils/helper";
import { getLocalStoraGeItem, isoToFormat, returnFixedOrEmptyValue } from "utils/utils";

import "./itemLedger.scss";


const ItemLedger = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productIdInfo: any = useParams();
  const productIdUrl = Number(productIdInfo?.id);
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace || {};
  const { itemLedgerDetails , itemLedgerInoutDetails, productsData} =
    useSelector<RootState, any>((state) => state.pos);
  const { posFlowData, loadCartDetails } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { cartProductDetails, customer } = posFlowData || {};
  const { personId, customerId } = customer || {};
  const { thumbImg, posRetailPrice } = cartProductDetails || {};
  const { MM_DD_YYYY } = DateFormat;
  const { Ten, One } = PaginationFormat;
  const { data, total, size } = itemLedgerDetails || {};
  const {
    allocatedQty,
    availableQty,
    inTransitQty,
    onBackOrderQty,
    onPurchaseOrderQty,
    movingAvgCost,
    vendorPrice,
    name,
    productId,
    manufacturerCode,
    upc,
    vendorName,
    manufacturerName,
    firstPurchaseAt,
    lastPurchaseAt
  } = itemLedgerInoutDetails || {}

  const [rowsPerPage, setRowsPerPage] = useState(Ten);
  const [page, setPage] = useState(0);
  const { defaultIcon } = images;
  const { pathname } = location;
  const isLeapFrongReport = (pathname === `${Paths.product}/${productIdInfo.id}/${Paths.reports}/${Paths.ledger}`)
  const hideFooterPagination = !data || data?.length <0 ? true :false
  const { noRecordsAvailable } = title

  useEffect(() => {
    const data = {
      productId: productIdUrl,
      personId: isLeapFrongReport ? 0 : personId || customerId,
      stockWarehouseId: isLeapFrongReport ? 0 : stockWarehouseId,
    };
    dispatch(itemLedgerInoutRequest(data))
  }, [])

  useEffect(() => {
    const data = {
      productId: productIdUrl,
      personId: isLeapFrongReport ? 0 : personId || customerId,
      stockWarehouseId : isLeapFrongReport ? 0 : stockWarehouseId,
      page: page + One,
      size: rowsPerPage,
    };
    if (!itemLedgerDetails?.length) {
      dispatch(itemLedgerRequest(data));
    }
  }, [page, rowsPerPage]);

  const priceInfo = (title: string, price: number | string) => {
    return (
      <div className="show-price-wrapper-main">
        <div className="price-title">{title}</div>
        <div className="price-key">{price}</div>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "takenByTime",
      headerName: "DATE",
      sortable: false,
      flex: 0.8,
      editable: false,
      renderCell: (params) => isoToFormat(`${params?.value}`, MM_DD_YYYY),
    },
    {
      field: "takerName",
      headerName: "HANDLER",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => params.value ? `${params.value}` : " ",
    },
    {
      field: "transactionId",
      headerName: "TRANSACTION #",
      sortable: false,
      flex: 1,
      renderCell: (params) => (params.value ? `${params.value}` : " "),
    },
    {
      field: "reason",
      headerName: "REASON",
      sortable: false,
      flex: 3,
      renderCell: (params) => (params.value ? `${params.value}` : " "),
    },
    {
      field: "orderId",
      headerName: "ORDER #",
      sortable: false,
      flex: 1,
      renderCell: (params) => (params.value ? `${params.value}` : " "),
    },
    {
      field: "price",
      headerName: "PRICE",
      sortable: false,
      flex: 1,
      renderCell: (params) => (params.value ? `$${params.value.toFixed(2)}` : " "),
    },
    {
      field: "quantity",
      headerName: "COMMITTED QTY.",
      sortable: false,
      align: "right",
      flex: 1,
      renderCell: (params) => {
          const { value } = params;
          if (!value){
            return ""
          }
          const icon = value < 0 ? "call_made" : "call_received";
          const iconClass = value < 0 ? "outgoing-call-made" : ""; 
         return (
          <>
          <div className="d-flex gap-1">
           <div>{value}</div>
           <MaterialIcon icon={icon} customeClass={iconClass} size="18" />
          </div>
          </>
         )
      },
    },
    {
      field: "movingAvgCost",
      headerName: "MOVING AVG. COST",
      sortable: false,
      align: "center",
      flex: 1,
      renderCell: (params) => (params.value ? `$${params.value.toFixed(2)}` : ""),
    },
    {
      field: "balance",
      headerName: "BALANCE",
      sortable: false,
      align: "center",
      flex: 1,
      renderCell: (params) => (params.value || ""),
    },
    {
      field: "stockWarehouseID",
      headerName: "WAREHOUSE",
      sortable: false,
      align: "center",
      flex: 1,
      renderCell: (params) => (warehouseName[params.value] || ""),
    },
  ];

  const theme = useTheme();

  const customPagination = () => {
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(event.target.value);
      setPage(1);
    };
    const tablePaginationActions = (props: TablePaginationActionsProps) => {
      const { count, page, rowsPerPage, onPageChange } = props;
      const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
      ) => {
        onPageChange(event, 0);
      };
      const handleBackButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
      ) => {
        onPageChange(event, page - 1);
      };
      const handleNextButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
      ) => {
        onPageChange(event, page + 1);
      };
      const handleLastPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
      ) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      };
      return (
        <div className="pagination-next-container">
          <PosIconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
            tabIndex={1}
            className="next-button-icon pagination-button-focus"
          >
            {theme.direction === "rtl" ? (
              <SkipNextIcon />
            ) : (
              <SkipPreviousIcon />
            )}
          </PosIconButton>
          <PosIconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            tabIndex={1}
            aria-label="previous page"
            className="next-button-icon pagination-button-focus"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </PosIconButton>
          <PosIconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
            tabIndex={1}
            className="next-button-icon pagination-button-focus"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </PosIconButton>
          <PosIconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
            tabIndex={1}
            className="next-button-icon pagination-button-focus"
          >
            {theme.direction === "rtl" ? (
              <SkipPreviousIcon />
            ) : (
              <SkipNextIcon />
            )}
          </PosIconButton>
        </div>
      );
    };

    return (
      <TablePagination
        component="div"
        count={total ? total : 0}
        rowsPerPage={size}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[]}
        ActionsComponent={tablePaginationActions}
      />
    );
  };

  const backToCartPage = () => {
    const { cartId } = loadCartDetails
    const { productId } = productsData
    if(productId) {
      dispatch(addToCartDrawer(true))
    }
    navigate(`${Paths.posCart}/${cartId}`);
  }

  const handleKeyPressDown = (e) => {
    const { keyCode } = e;
    const { esc } = keys;
    const { cartId } = loadCartDetails
    if (keyCode === esc && cartId) {
      backToCartPage();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPressDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPressDown);
    };
  }, [loadCartDetails, productsData]);

  return (
    <>
      <div className="item-ledger-main-section" tabIndex={-1}>
        <PosHelmet title={!isLeapFrongReport ? "POS" : `Product Detail - ${name} - ${productIdInfo.id}` } metaName="item-ledger" content="item-ledger" />
        <section className={`item-ledger-section ${isLeapFrongReport ? "pl-122" : ""}`}>
          <div className="item-ledger-container">
            {!isLeapFrongReport ? <PosIconButton
              className="back-button"
              onClick={() => {
                backToCartPage();
              }}
              tabIndex={-1}
            >
              <ArrowBackRoundedIcon className="back-icon" />
            </PosIconButton> : null}
            <div className="item-ledger-title-container" tabIndex={-1} >
              <div className="title">
                <PosPageHeading heading="Item Ledger" blackHeading />
              </div>
              <div className="item-ledger-products-container">
                <div className="product-ledger-image">
                  <PosImage
                    src={thumbImg || defaultIcon}
                    alt="product-icon"
                  />
                  <div className="product-ledger-name">
                    <div className="name">{name}</div>
                    <div className="key">{productId}</div>
                  </div>
                </div>
              </div>

              <div className="download-csv-btn"></div>
              {/*  may need this later
              <CsvDownloader
                filename="myfile"
                separator=";"
                columns={itemLedgerColumnsCsv}
                datas={csvItemLedger}
              >
                <PosButton
                  startIcon={<GetAppIcon />}
                  variant="contained"
                  className="download-csv-btn"
                >
                  {texts.downloadCSV}
                </PosButton>
              </CsvDownloader> */}
            </div>
          </div>
        </section>
        <Container maxWidth={false} className="table-main-container">
          <section className="price-quantity-section">
            <Divider className="divider-div" />
            <div className="show-price-quantity-container">
              {priceInfo(
                "CURRENT RETAIL PRICE",
                returnFixedOrEmptyValue(posRetailPrice, true) || "0.00"
              )}
              {priceInfo("COST", `$${vendorPrice? vendorPrice?.toFixed(2) : "0.00"}`)}
              {priceInfo("MOVING AVG. COST", `$${movingAvgCost? movingAvgCost?.toFixed(2) : "0.00"}`)}
              {priceInfo("AVAILABLE QTY.",availableQty ? availableQty?.toFixed(2): "0.00")}
              {priceInfo("ALLOCATED", allocatedQty ? allocatedQty?.toFixed(2): "0.00")}
              {priceInfo("ON BACKORDER", onBackOrderQty ? onBackOrderQty?.toFixed(2): "0.00")}
              {priceInfo("ON PURCHASE ORDER", onPurchaseOrderQty ? onPurchaseOrderQty?.toFixed(2): "0.00")}
              {priceInfo("IN TRANSIT", inTransitQty ? inTransitQty?.toFixed(2): "0.00")}
            </div>
            <hr className="second-divider-div" />
            <div className="show-price-quantity-container">
              {priceInfo("LAST PURCHASE DATE", lastPurchaseAt ? isoToFormat(`${lastPurchaseAt}`, MM_DD_YYYY)  : "")}
              {priceInfo("STARTING DATE", firstPurchaseAt ? isoToFormat(`${firstPurchaseAt}`, MM_DD_YYYY) : "")}
              {priceInfo("MANUFACTURER CODE", manufacturerCode ? manufacturerCode: "")}
              {priceInfo("UPC", upc ? upc : "")}
              {priceInfo("MANUFACTURER", manufacturerName ? manufacturerName : "")}
              {priceInfo("VENDOR", vendorName ? vendorName : "")}
            </div>
            <PosDataGrid
              rowHeight={50}
              rows={data || []}
              columns={columns}
              className="item-ledger-data-grid-container"
              components={{
                Pagination: customPagination,
              }}
              localeText={{ noRowsLabel: noRecordsAvailable }}
              hideFooterPagination={hideFooterPagination}
              disableSelectionOnClick
            />
          </section>
        </Container>
      </div>
    </>
  );
};

export default ItemLedger;
