import InputMask from "react-input-mask";
import useStyle from "./style";

interface Props {
  name?: string;
  onChange: any;
  value: string | number;
  placeholder?:string;
  mask?: string | (string | RegExp)[];
  isClassName?:string;
  id?:string;
  alwaysShowMask?:boolean;
  onKeyPress?:React.KeyboardEventHandler<HTMLDivElement>;
  type?:string;
  onWheel?:React.KeyboardEventHandler<HTMLDivElement>;
  maskPlaceholder?:string;
  onBlur?:React.KeyboardEventHandler<HTMLDivElement>;
  onFocus?:React.KeyboardEventHandler<HTMLDivElement>;
}


const MaskInput = ({ onBlur, type, onKeyPress, name, onChange, value,placeholder,maskPlaceholder,isClassName,mask,id,alwaysShowMask,onWheel, onFocus}: Props) => {
  const classes = useStyle();
  return (
    <InputMask
      className={isClassName||classes.maskInputContainer}
      value={value}
      id={id}
      name={name}
      onChange={onChange}
      autoComplete="off"
      maskPlaceholder={maskPlaceholder||""}
      mask={mask}
      alwaysShowMask={alwaysShowMask}
      onKeyPress={onKeyPress}
      type={type}
      onWheel={onWheel}
      maskChar=""
      placeholder={placeholder||""}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

export default MaskInput;
