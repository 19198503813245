import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import { JobNameInput } from "apps/pos/Common/FacilityInput";
import { PosButton, PosInput, PosPageHeading } from "apps/pos/Common/uiCore";
import AddDocumentModal from "apps/pos/pages/customerInfo/Documents/addDocument/AddDocumentModal";

import AttachedDocumentModal from "apps/pos/AddNewCustomer/AttachedDocumentModal/AttachedDocumentModal";
import { geneCustomerOrderLimit, jobAndReceiptNameRegex, numbersOnlyRegEx, poNumberRegex } from "constants/constant";
import { documentByModule } from "constants/enum";
import { FileUploadIcon } from "constants/icons";
import {
  PosFlowDataProp,
} from "constants/interfaces";
import { RootState } from "models";

import CashDrawerDropdown from "apps/pos/CashDrawerDropdown/CashDrawerDropdown";
import { PurchaseOrderInput } from "apps/pos/Common/PurchaseOrderInput";
import { setPosFlowData } from "sagas/persistReducer/actions";
import { isPurchaseOrderNoEdited, setJobNameForAll } from "sagas/pos/actions";
import OrderInfo from "./OrderInfo";

const CheckOption = ({
  finalTotal,
  tempJobNameSave,
  checkNumber,
  setTempJobNameSave,
  checkNumberError,
  setCheckNumber,
  setCheckNumberError,
  isGenericCustomer,
  setCheckDisable,
  cashOptionsSelected,
  setCashOptionsSelected,
  setCashDrawerError,
  cashDrawerError,
  subTotal,
  taxAmount,
  shipTaxAmount,
  orderId,
  shippingTaxAmount,
  salesPersonName,
  shipToTaxFlag,
  shippingFee,
  creditAmtApplied,
  poNumberSave,
  setPoNumberSave,
}) => {

  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );

  const [openModel, setOpenModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const { addDocument } = posFlowData || {}
  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isGenericCustomer) {
      const countDocuments = addDocument.filter(
        (e) =>
          e?.documentSaveTypeName?.toLowerCase() ===
          documentByModule.addressProf.toLowerCase()
      ).length;
      if ((checkNumber && countDocuments >= 1) || finalTotal < 50) {
        setCheckDisable(false)
      } else {
        setCheckDisable(true)
      }
    }
  }, [addDocument, checkNumber]);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const checkNumberChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9.]/g, '');
    setCheckNumber(numericValue);
    if (numbersOnlyRegEx.test(e.target.value)) {
      setCheckNumber(e.target.value);
      setCheckNumberError(false);
    }
  }
  const handleJobName = (e) => { if (jobAndReceiptNameRegex.test(e.target.value)) { setTempJobNameSave(e.target.value); dispatch(setJobNameForAll(e.target.value)) } }

  const handlePoNumberChange = (e) => {
    if (e.target.value === "" || poNumberRegex.test(e.target.value)) {
      dispatch(isPurchaseOrderNoEdited(true))
      const data = {
        ...posFlowData,
        poNumber: e.target.value,
      };
      dispatch(setPosFlowData(data));
      setPoNumberSave(e.target.value);
    }
  };


  return (
    <div>
      <div className="pricing-modal-header">
        <div className="payment-option-heading">
          <div>
            <PosPageHeading heading="Payment" grayHeading />
            <div className="pricing-schedule-heading-checkout mb-4">
              <PosPageHeading
                heading={`Collect check worth $${finalTotal}`}
                blackHeading
              />
            </div>
          </div>
        </div>
      </div>
      <OrderInfo
        subTotal={subTotal}
        taxAmount={taxAmount}
        shipTaxAmount={shipTaxAmount}
        shippingTaxAmount={shippingTaxAmount}
        orderId={orderId}
        salesPersonName={salesPersonName}
        shipToTaxFlag={shipToTaxFlag}
        shippingFee={shippingFee}
        creditAmtApplied={creditAmtApplied} />
      {isGenericCustomer ? (
        <>
          <div className="int-20-reg"> Attach Address Proof/State ID </div>
          {addDocument?.length ? (
            <div className="mt-1 mb-2">
              <span>
                {addDocument?.length}{" "}
                {`${addDocument?.length === 1 ? "Document" : "Documents"
                  } added`}
              </span>
              <PosButton
                isClassName="tax-chnage-btn tabInput-focus"
                type="submit"
                onClick={() => {
                  setOpenEditModel(true);
                }}
              >
                Change
              </PosButton>
            </div>
          ) : (
            <>
              <div className="phone-optional-head">
                Mandatory for generic customer orders over ${geneCustomerOrderLimit}
              </div>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <PosButton
                  variant="contained"
                  color="primary"
                  className="btn-add-customer tax-file-upload-btn btn ms-0 mb-3 mt-2"
                  type="button"
                  onClick={() => {
                    setOpenModel(true);
                  }}
                  fontWeight
                  startIcon={<FileUploadIcon />}
                >
                  Add Document
                </PosButton>
              </div>
            </>
          )}
        </>
      ) : null}

      <div className="facility-box mt-4 input">
        <span className="int-16-reg">Check Number</span>
        <div className="margintop-10 mb-0">
          <PosInput
            variant="filled"
            id="filled-basic"
            InputProps={{ disableUnderline: true }}
            value={checkNumber}
            inputProps={{ maxLength: 20 }}
            onChange={checkNumberChange}
            className="search-input-focus"
          />
        </div>
      </div>
      {checkNumberError && (
        <p className="validation-error-text margintop-10">
          Please enter check number.
        </p>
      )}

      <div className="check-margin-top">
        <div className="form-field-space-custom-drop-down-div">
          <CashDrawerDropdown
            cashOptionsSelected={cashOptionsSelected}
            setCashOptionsSelected={setCashOptionsSelected}
            setCashDrawerError={setCashDrawerError}
            cashDrawerError={cashDrawerError}
          />
        </div>
        <div className="margintop-10 pay-later-job-input input-btn-spacing-div">
          <PurchaseOrderInput
            value={poNumberSave}
            onChange={handlePoNumberChange}
          />
          <JobNameInput value={tempJobNameSave} onChange={handleJobName} />
        </div>
      </div>

      {addDocument?.length > 0 && (
        <AttachedDocumentModal
          taxOpen={openEditModel}
          setTaxOpen={setOpenEditModel}
          handleClick={handleClick}
          documentBy={documentByModule.payment}
        />
      )}
      {openModel ? (
        <AddDocumentModal
          openModel={openModel}
          setOpenModel={setOpenModel}
          taxExemption={false}
          documentBy={documentByModule.payment}
        />
      ) : null}
    </div>
  );
};

export default CheckOption;
