import { applyMiddleware, compose, createStore } from "redux";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SnackbarProvider } from "notistack";

import Snakbar from "apps/pos/Common/Snakbar";

import IndexReducer from "./sagas/index-reducers";
import RootSagas from "./sagas/index-saga";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { App } from "./App";
import { setToLS } from "./utils/storage";
import * as themes from "./theme/schema.json";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["persistReducer", 'storageSlice', 'customerOrderSlice'],
};
const persistedReducer = persistReducer(persistConfig, IndexReducer);
const sagaMiddleware = createSagaMiddleware();

const composeSetup =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
/* eslint-enable */

export const store = createStore(
  persistedReducer,
  composeSetup(applyMiddleware(sagaMiddleware)) // allows redux devtools to watch sagas
);
const persistor = persistStore(store);
sagaMiddleware.run(RootSagas);

setToLS("all-themes", themes.data.paper);

const root = createRoot(document?.querySelector("#root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
      <Snakbar/>
        <App />
    </SnackbarProvider>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);
