import { TableColumnProps } from "constants/interfaces";

export function TableColumn({
    className,
    value,
    children
}: TableColumnProps) {
    return (
        <>
            {
                children ?
                    <div className={`table-body-col ${className ? className : ""}`}>
                        {children}
                    </div>
                    :
                    <div className={`table-body-col ${className ? className : ""}`}>
                        {value}
                    </div>
            }
        </>

    )
}