import { useRef } from 'react';
import IconButton from '../IconButton';
import './TextInput.scss';


export default function TextInput(props) {
    const inputID = useRef(crypto.randomUUID());
    const {
        id = inputID.current,
        label,
        type = 'text',
        wide,
        placeholder,
        description,
        disabled,
        buttonIcon, // if true, shows icon button 
        buttonLabel, // label for icon button
        buttonClasses, // custom classes for icon button
        onButtonClick, // click listener for icon button
        autofocus,
        required = true, // if false, shows "(optional)" in the label 
        hasError = false, // shows error state
        onChange,
        value
    } = props;

    return (
        <div className="text-input-wrapper">
            {label ? (
                <label htmlFor={id} className="text-input-label">
                    {label}
                    {required ? null : <span className="text-input-label-optional">(optional)</span>}
                </label>
            ) : null}

            <div className="text-input-group">
                <input
                    type={type}
                    id={id}
                    placeholder={placeholder}
                    className={`text-input ${wide ? 'text-input-wide' : ''} ${hasError ? 'text-input-has-error' : ''}`}
                    autoFocus={autofocus}
                    disabled={disabled}
                    required={required}
                    onChange={onChange}
                    value={value}
                />
                {buttonIcon ? (
                    <IconButton
                        icon={buttonIcon}
                        onClick={onButtonClick}
                        classes={buttonClasses}
                        title={buttonLabel}
                        secondary="true"
                        iconSize="20"
                        disabled={disabled}
                    />
                ) : null}
            </div>

            {hasError && description ? (
                <p className={`text-input-description ${hasError ? 'text-input-description-error' : ''}`}>
                    {description}
                </p>
            ) : null}
        </div>
    )
}
