export enum Label {
    CancelButton = "Cancel",
    SubmitButton = "submit",
    SaveChanges = "Save Changes",
    Reject ="Reject",
    Role ="Role",
    unlockAccount ="UNLOCK ACCOUNT",
    lockAccount ="LOCK ACCOUNT",
    firstName="First name",
    lastName="Last name",
    emailAddress="Email Address",
    phoneNumberInfo="Phone Number",
    changePassword="Change Password",
    deactiveAccount="Deactivate Account",
    posCustCode="POS Customer Code",
    companyName="Company Name",
    zip="Zip",
    uploadSigned="Upload customer signed Delivery proof",
}

export enum ModelNameLabel {
    add = "add",
    manage = "manage",
    edit = "edit"
}

export enum PaymentMode {
    cashPayment = "CASH",
    checkPayment = "CHECK",
    cardPayment = "CREDIT_CARD",
    accountPayment = "REGISTERED_ACCOUNT",
    customerCredit = "CUSTOMER_CREDIT"
}

export enum TransactionType {
    sale = "SALE"
}

export enum SortIndex {
    ASCENDING ="ASCENDING",
    DESCENDING = "DESCENDING"
}