import { AnyAction } from "redux";

import * as constants from "./constants";

const initialState = {
  isLoading: false,
  customerDocumentSuccess: false,
  customerDocumentError: false,
  error: "",
  customerDocumentDetails: [],
  taxDocumentSuccess: false,
  taxDocumentError: false,
  taxDocumentDetails: [],
  isTaxDocLoading: false
};

const reducer = function posReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case constants.GET_CUSTOMER_DOCUMENT_START:
      return {
        ...state,
        isLoading: true,
        customerDocumentSuccess: false,
        customerDocumentError: false
      };
    case constants.GET_CUSTOMER_DOCUMENT_SUCCESS:
      return {
        ...state,
        customerDocumentDetails: action.payload,
        isLoading: false,
        customerDocumentSuccess: true,
        customerDocumentError: false
      };
    case constants.GET_CUSTOMER_DOCUMENT_ERROR:
      return {
        ...state,
        customerDocumentDetails: action.payload,
        isLoading: false,
        customerDocumentSuccess: false,
        customerDocumentError: true
      };
    case constants.GET_CUSTOMER_DOCUMENT_CLEAR:
      return {
        ...state,
        customerDocumentDetails: [],
        isLoading: false,
        customerDocumentSuccess: false,
        customerDocumentError: false
      };
    case constants.GET_TAX_DOCUMENT_START:
      return {
        ...state,
        isTaxDocLoading: true,
        taxDocumentSuccess: false,
        taxDocumentError: false
      };
    case constants.GET_TAX_DOCUMENT_SUCCESS:
      return {
        ...state,
        taxDocumentDetails: action.payload,
        isTaxDocLoading: false,
        taxDocumentSuccess: true,
        taxDocumentError: false
      };
    case constants.GET_TAX_DOCUMENT_ERROR:
      return {
        ...state,
        taxDocumentDetails: action.payload,
        isTaxDocLoading: false,
        taxDocumentSuccess: false,
        taxDocumentError: true
      };
    case constants.GET_TAX_DOCUMENT_CLEAR:
      return {
        ...state,
        taxDocumentDetails: [],
        isTaxDocLoading: false,
        taxDocumentSuccess: false,
        taxDocumentError: false
      };
    default:
      return state;
  }
}

export default reducer;