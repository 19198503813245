import { useLocation } from "react-router-dom";
import {
  Container,
  PosHelmet,
} from "apps/pos/Common/uiCore";

import StorageDataSubTab from "../Common/StorageDataSubTab/StorageDataSubTab";

import "apps/pos/storage/putInStorage.scss";

const SubStorageItems = () => {

  const {state} = useLocation();

  const storageItemInfo = state?.isStorageInfo;

  return (
    <div className="put-storage-container">
      <PosHelmet
        title="pos :: Items in Storage"
        metaName="itemsInStorage page"
        content="itemsInStorage page"
      />

      <Container className="new-container large-screen-container-styling">
        <div className="customer-info-page">
          <StorageDataSubTab storageItemInfo={storageItemInfo} />
        </div>
      </Container>
    </div>
  );
};

export default SubStorageItems;
