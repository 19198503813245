import { useEffect, useState } from "react";
// import { FixedSizeList } from "react-window";

import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Chip, CircularProgress } from "@material-ui/core";
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import { commonConstants, statusValue } from "apps/product/helper/commonConstants";
import { ICustomDropDownProps, IOption } from "./type";

import "./Customdropdown.scss";

const CustomDropDown = ({ label, dropdownData, setOptions, setPreData, preData, handleFilter, filter }: ICustomDropDownProps) => {
  const [values, setValues] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const { one, three, four } = statusValue;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const { status, manufacturer, manufacturerIds, statutable, group, productGroupIds, vendorIds, vendor, category, categoryIds, clearanceFlag, discontinueFlag } = commonConstants

  const renderValue = (selected) => (
    <div>
      {selected.map((value) => (
        <Chip key={value} label={value} />
      ))}
    </div>
  );

  const handleChange = (event) => {
    const text = event.target.value;
    setValues(text);
    setPreData(text);
    setSearchTerm(''); // Clear search term when selecting an option
    manageFilterApi(label, text)
  };

  useEffect(() => {
    setValues(preData);
    const filtered = Object.entries(dropdownData)
      .filter(([key, innerLabel]) => {
        const labelValue = valueLabel(key, innerLabel);
        return labelValue ? labelValue.toLowerCase().includes(searchTerm.toLowerCase()) : null;
      })
      .map(([key]) => Number(key));
    setFilteredOptions(filtered);
    setLoading(false);
  }, [preData, dropdownData, searchTerm]);

  const valueLabel = (key ,innerLabel ) => {
    if (Array.isArray(key)) {
          // Handle the empty array value here
          return null;
        }
        // Filter options based on search term
        const valueType = innerLabel as string;
        const labelValue = label === status ? valueType.charAt(0).toUpperCase() + valueType.slice(1).toLowerCase() : valueType;
        return labelValue;
  }
  const manageFilterApi = (label, text) => {
    let selectedTexts: number[] = []; // Array to store all text values
    let passKey;
    const data: IOption[] = []
    if (text.length) {
      for (let i = 0; i < text.length; i++) {
        Object.entries(dropdownData).filter(([key, innerLabel]) => {
          const labelValue = valueLabel(key, innerLabel);
          if(labelValue){
            const isMatch = Number(key) === Number(text[i]);
            passKey = getKeyName(label, Number(text[i]));
            if (isMatch) {
              selectedTexts.push(Number(key)); // Push the text value to the array
              data.push({ "label": label === status ? statutable : passKey, "value": labelValue, "key": Number(key) })
            }
            return isMatch;
          } else {
            return null;
          }
        });
      }
    } else {
      passKey = getKeyName(label, "")
      selectedTexts = null; // Push the text value to the array
    }
    setOptions(data, passKey);
    const clear = selectedTexts?.includes(three);
    const discontinue = selectedTexts?.includes(four);
    const newStatus = selectedTexts?.filter((element) => element !== three && element !== four);
    let newFilter;

    if (passKey === clearanceFlag || passKey === discontinueFlag || passKey === statutable) {
      newFilter = {
        ...filter,
        clearanceFlag: clear ? one : null,
        discontinueFlag: discontinue ? one : null,
        statuses: newStatus?.length ? newStatus : null
      }
    }
    else {
      newFilter = {
        ...filter,
        [passKey]: selectedTexts?.length ? selectedTexts : null
      }
    }
    handleFilter(newFilter)
  }
  const handleUnwantedSelection = (e) => {
    e.stopPropagation()
  }

  const getKeyName = (label, labelValue) => {
    let value;
    switch (label) {
      case status:
        if (labelValue === three) {
          value = clearanceFlag;
        } else if (labelValue === four) {
          value = discontinueFlag;
        } else {
          value = statutable;
        }
        break;
      case manufacturer:
        value = manufacturerIds;
        break;
      case vendor:
        value = vendorIds;
        break;
      case category:
        value = categoryIds;
        break;
      case group:
        value = productGroupIds;
        break;
      default:
        value = statutable;
        break;
    }
    return value;
  }
  //  need later
  // const Row = ({ index, style }) => {
  //   const key = filteredOptions[index];
  //   const innerLabel = dropdownData[key];
  //   const valueType = innerLabel as string;
  //   const labelValue = label === status ? valueType.charAt(0).toUpperCase() + valueType.slice(1).toLowerCase() : valueType;
  //   return (
  //     <MenuItem
  //       key={labelValue}
  //       value={Number(key)}
  //       className="product-list-filter-options cursor-pointer"
  //       style={style}
  //     >
  //       <Checkbox checked={values.indexOf(Number(key)) > -1} />
  //       <ListItemText primary={labelValue} />
  //     </MenuItem>
  //   );
  // };

  return (
    <>
      <div className="product-list-select-dropdown">
        <FormControl>
          <InputLabel >{label}</InputLabel>
          <Select
            multiple
            value={values}
            onChange={handleChange}
            MenuProps={MenuProps}
            renderValue={renderValue}
            id={label}
          >
            <div className="search-container" onClick={handleUnwantedSelection}>
              <InputBase
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                startAdornment={<SearchIcon />}
                className="search-input"
                onClick={handleUnwantedSelection}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
              />
            </div>
            {loading ? (
              // Render the loading animation
              <MenuItem disabled className="loading-item">
                <CircularProgress size={20} />
              </MenuItem>
            ) : (
              // Render the dropdown options when not loading : need later
              // <FixedSizeList
              //   height={ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP}
              //   width={250}
              //   itemSize={ITEM_HEIGHT}
              //   itemCount={filteredOptions.length}
              // >
              //   {Row}
              // </FixedSizeList>
              // Render the dropdown options when not loading
              filteredOptions.map((key ) => {
                const innerLabel = dropdownData[key];
                if (Array.isArray(key)) {
                  // Handle the empty array value here
                  return null;
                }
                const valueType = innerLabel as string; // Type assertion
                const labelValue = label === status ? valueType?.charAt(0).toUpperCase() + valueType?.slice(1).toLowerCase() : valueType;
                return (
                    <MenuItem
                      key={labelValue}
                      value={Number(key)}
                      className={`product-list-filter-options cursor-pointer ${label === status ? 'label-border-styling' : ''}`}
                    >
                      <Checkbox checked={values.indexOf(Number(key)) > -1} />
                      <ListItemText primary={labelValue} />
                    </MenuItem>
                );
              })
            )}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default CustomDropDown;
