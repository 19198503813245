import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";

import useFilterSections from "apps/product/Components/ProductDetails/useSectionFilter";
import CommonEditButton from "apps/product/Components/ProductDetails/CommonEditButton";
import CreateGridStarIcon from "apps/product/Components/ProductDetails/CreateGridStarIcon";
import { PosImage  } from "apps/pos/Common/uiCore";

import images from "constants/images";
import { AccessorieI } from "apps/product/modules/productDetailsContainer/types";
import { labels, sectionName } from "apps/product/modules/productDetailsContainer/constants";
import { accessoriesKey } from "constants/enum";

interface ProductDetailsAccessoriesProps {
  isStarred?: boolean;
  searchText?: string;
  productAccessorieReplacesData?:AccessorieI[];
  productAccessorieGoesWithData?:AccessorieI[];
  handleSearchAccessories: any
}

const ProductDetailsAccessories = ({ isStarred = false, searchText,productAccessorieReplacesData,productAccessorieGoesWithData, handleSearchAccessories }:ProductDetailsAccessoriesProps) => {
  const params = useParams();
  const { defaultIcon } = images;
  const { accessories } = sectionName;
  const { showSection } = useFilterSections({ searchText, sectionName: accessories, isStarred });

  const { notSet , replaces , goesWith} = labels
  const productID = params?.id;

  const { goesWith: GoesWith, replaces: Replaces } = accessoriesKey;
  return (
    <>
      <section className="product-report-section">
        <Grid container className="product-report-container">
          {showSection("goesWith",goesWith) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='goesWith' label={goesWith} value={productID} sectionName={accessories} />
              <Grid item xs={12} sm={8}>
                {
                  productAccessorieGoesWithData?.length ?
                  productAccessorieGoesWithData?.map((option) => {
                      const { productName, thumbImg, manufacturerCode , productToProductID} = option || {}
                      return (
                          <div className="d-flex mb-4" key={productToProductID}>
                            <div>
                              <PosImage
                                alt="image-icon"
                                className="product-image-container product-detail-image-common-style"
                                src={thumbImg ? thumbImg : defaultIcon}
                              />
                            </div>
                            <div className="ml-2">
                              <div>
                                <span>{productName}</span>
                              </div>
                              <div className="small-text-without-margin gray-text">
                                <span>{manufacturerCode}</span>
                              </div>
                            </div>
                          </div>
                      )
                    }) : <div className="small-text-without-margin gray-text">
                      <span>{notSet}</span>
                    </div>
                }
                
              </Grid>
              {
                   productAccessorieGoesWithData?.length ? 
                   <CommonEditButton onClick={handleSearchAccessories(GoesWith)} /> : null
                }

            </Grid>
          ) : null }
          {showSection("replaces",replaces) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='replaces' label={replaces} value={productID} sectionName={accessories} />
              <Grid item xs={12} sm={8}>
                {
                  productAccessorieReplacesData?.length ?
                  productAccessorieReplacesData.map((option) => {
                      const { productName, thumbImg, manufacturerCode , productToProductID} = option || {}
                      return (
                          <div className="d-flex mb-4" key={productToProductID}>
                            <div className="product-img">
                              <PosImage
                                alt="image-icon"
                                className="product-image-container product-detail-image-common-style"
                                src={thumbImg ? thumbImg : defaultIcon}
                              />
                            </div>
                            <div className="ml-2">
                              <div>
                                <span>{productName}</span>
                              </div>
                              <div className="small-text-without-margin gray-text">
                                <span>{manufacturerCode}</span>
                              </div>
                            </div>
                          </div>
                      )
                    }) : <div className="small-text-without-margin gray-text">
                      <span>{notSet}</span>
                    </div>
                }
                
              </Grid>
                {
                   productAccessorieReplacesData?.length ? 
                    <CommonEditButton onClick={handleSearchAccessories(Replaces)} /> : null
                }
            </Grid>
          ) : null }
        </Grid>
      </section>
    </>
  );
};

export default ProductDetailsAccessories;
