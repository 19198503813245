import { Fragment, useState } from "react";
import { Grid } from "@material-ui/core";
import { useFormik } from "formik";

import { PosInput, PosModel, PosSelect } from "apps/pos/Common/uiCore";
import { IconButton } from "apps/springboard/Component/SBItems/elements";
import Button from "components/SPButton/Button";
import { ConfirmationModal } from "apps/springboard/Component/ConfirmationModal";
import { ButtonContainerSBbuttons } from "components";

import { QuoteDetailItem } from "models";
import {
  alertMessage,
  springboardSuccessMessages,
  springBoardText,
  title,
} from "constants/constant";
import { QuoteStatus, statusConditions } from "utils/data";

import { setAsNew, setAsNoQuote, setExpired, setRevision } from "services";
import "./ChangeQuoteStatusForm.scss";

interface ChangeQuoteStatusProps {
  jobQuoteID?: number;
  handlerID?: number;
  open: boolean;
  handleCloseModel: () => void;
  selectedQuote?: QuoteDetailItem;
  sellerID?: string | number;
  handleCommonSuccess?: ({ text }: { text: string }) => void;
  distributerHttpUrl?: string;
}

export const ChangeQuoteStatusForm = ({
  jobQuoteID,
  handleCloseModel,
  open,
  handleCommonSuccess,
  selectedQuote,
  sellerID,
  distributerHttpUrl,
}: ChangeQuoteStatusProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const {
    currentStatus,
    setAs,
    changeQuoteStatus,
    revision,
    noQuote,
    killed,
    expired,
    new: newStatus,
    activate,
    set,
  } = springBoardText;
  const { reasonTitle } = title;
  const { reasonIsRquired } = alertMessage;
  const {
    statusChangedToRevision,
    statusChangedToNoQuote,
    statusChangedToNew,
    statusChangedToExpired,
  } = springboardSuccessMessages;
  const { reasonForRevision, killReason, jobLotPackageID, jobQuoteStatusName } =
    selectedQuote || {};

  const initialFormValues = {
    newQuoteStatus: jobQuoteStatusName,
    reason: "",
  };

  const handleFormSubmit = (values) => {
    const { newQuoteStatus, reason } = values;
    if (showReasonInput && !reason) {
      setErrors({ reason: reasonIsRquired });
    } else {
      setIsLoading(true);
      if (
        newQuoteStatus === expired ||
        newQuoteStatus === noQuote ||
        newQuoteStatus === killed
      ) {
        switch (newQuoteStatus) {
          case expired:
            onNewOrExpiredStatus();
            break;
          case noQuote:
            onNoQuoteStatus(reason);
            break;
          default:
            break;
        }
      } else {
        switch (newQuoteStatus) {
          case newStatus:
            onNewOrExpiredStatus();
            break;
          case revision:
            onRevisionStatus(reason);
            break;
          case activate:
            onActiveStatus();
            break;
          default:
            break;
        }
      }
    }
  };

  const {
    values,
    setFieldValue,
    setErrors,
    handleSubmit,
    resetForm,
    errors, // Add errors from useFormik
    dirty,
  } = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { newQuoteStatus, reason } = values;
  const showReasonInput =
    (newQuoteStatus === revision || newQuoteStatus === noQuote) &&
    newQuoteStatus !== jobQuoteStatusName;
  const displayKillReason =
    jobQuoteStatusName === noQuote || jobQuoteStatusName === killed;

  const filteredOptions = QuoteStatus.filter(
    (option) => !statusConditions[jobQuoteStatusName]?.includes(option)
  );

  const openLink = (url) => {
    window.open(url, "_blank");
  };

  const onNewOrExpiredStatus = () => {
    setIsConfirmationModal(true);
  };

  const onSuccess = (successText) => {
    handleCommonSuccess({ text: successText });
    resetForm();
    setIsLoading(false);
    handleCloseModel();
  };

  const onRevisionStatus = (reason) => {
    setRevision(Number(sellerID), jobQuoteID, reason).then((response) => {
      if (response) {
        onSuccess(statusChangedToRevision);
      }
    });
  };
  const onActiveStatus = () => {
    handleCloseModel();
    openLink(
      `${distributerHttpUrl}/gmSale/msJobLotQuoteActivate?quotePackageID=${jobLotPackageID}&jobLotQuoteID=${jobQuoteID}`
    );
  };

  const onKillStatus = () => {
    handleCloseModel();
    openLink(
      `${distributerHttpUrl}/gmSale/msJobLotQuote_kill?jobLotQuoteID=${jobQuoteID}`
    );
  };

  const onNoQuoteStatus = (reason) => {
    setAsNoQuote(Number(sellerID), jobQuoteID, reason).then((response) => {
      if (response) {
        onSuccess(statusChangedToNoQuote);
      }
    });
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationModal((prev) => !prev);
    setIsLoading(false);
  };

  const handleConfirmation = (newQuoteStatus) => {
    setIsConfirmLoading(true);
    if (newQuoteStatus === newStatus) {
      setAsNew(jobQuoteID).then(() => {
        onSuccess(statusChangedToNew);
        setIsConfirmLoading(false);
        handleCloseConfirmation();
      });
    }
    if (newQuoteStatus === expired) {
      setExpired(jobQuoteID).then(() => {
        onSuccess(statusChangedToExpired);
        handleCloseConfirmation();
        setIsConfirmLoading(false);
      });
    }
  };

  return (
    <Fragment>
      <PosModel
        open={open}
        onClose={handleCloseModel}
        className="set-customer-code-modal"
        dialogClassName="set-customer-code-modal-container"
        dialogHeader={
          <div className="modal-header pl-4">
            <span className="modal-title">
              <div className="pt-2">
                <h3 className="f20-Inter-500-sab mb-0 mb-0">
                  {changeQuoteStatus}
                </h3>
              </div>
            </span>
            <IconButton
              name="close"
              className="close-icon"
              type="tertiary"
              onClick={handleCloseModel}
            />
          </div>
        }
      >
        <div className="mb-4 arrow-black">
          <span className="form-field-title mb-2">{setAs}</span>
          <PosSelect
            id="newQuoteStatus"
            name="newQuoteStatus"
            label={setAs}
            value={newQuoteStatus}
            onChange={(e) => {
              setFieldValue("newQuoteStatus", e.target.value);
            }}
            options={filteredOptions.map((option) => ({
              value: option,
              label: option,
            }))}
          />
        </div>
        {showReasonInput ? (
          <>
            <div>
              <span className="form-field-title mb-2">{reasonTitle}</span>
              <PosInput
                variant="filled"
                id="filled-basic"
                InputProps={{ disableUnderline: true }}
                type="text"
                className="search-input-focus"
                onKeyDown={(e) => e.stopPropagation()}
                value={reason}
                onChange={(e) => {
                  setFieldValue("reason", e.target.value);
                }}
              />
            </div>
            {errors.reason && (
              <div className="validation-error-text mt-1">{errors.reason}</div>
            )}{" "}
            {/* Display validation error */}
          </>
        ) : null}

        <section className="subquote-report-section mt-4">
          <Grid container className="subquote-report-container">
            {jobQuoteStatusName ? (
              <Grid container className="report-div">
                <Grid item xs={12} sm={7}>
                  <div className="document-types-title">
                    <span>{currentStatus}</span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <div className="send_rfi_row_new ml-2">
                    <span>{jobQuoteStatusName}</span>
                  </div>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          {displayKillReason ? (
            <>
              <div className="reason-block">
                <div className="reason-label">{`${
                  jobQuoteStatusName === noQuote ? noQuote : killed
                } Reason`}</div>
                <div className="reason-value">{killReason}</div>
              </div>
            </>
          ) : null}
          {
            reasonForRevision ?  <div className="reason-block">
            <div className="reason-label">{`${revision} Reason`}</div>
            <div className="reason-value">{reasonForRevision}</div>
          </div>:null
          }
         
        </section>

        <ButtonContainerSBbuttons
          className={`${
            jobQuoteStatusName !== killed
              ? "justify-content-between"
              : "justify-content-end"
          } d-flex gap-2 mt-2`}
        >
          {jobQuoteStatusName !== killed ? (
            <Button
              label="Kill"
              loading={isLoading}
              disabled={isLoading}
              onClick={onKillStatus}
              type="danger"
            />
          ) : null}

          <Button
            color="primary"
            label={
              newQuoteStatus !== jobQuoteStatusName
                ? `${setAs} ${newQuoteStatus}`
                : set
            }
            type="primary"
            loading={isLoading}
            disabled={
              isLoading || !dirty || newQuoteStatus === jobQuoteStatusName
            }
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          />
        </ButtonContainerSBbuttons>
      </PosModel>
      {isConfirmationModal ? (
        <>
          <ConfirmationModal
            open={isConfirmationModal}
            handleCloseModel={handleCloseConfirmation}
            newQuoteStatus={newQuoteStatus}
            handleConfirmation={handleConfirmation}
            isConfirmLoading={isConfirmLoading}
          />
        </>
      ) : null}
    </Fragment>
  );
};
