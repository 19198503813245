interface addNewUserTabs {
  addCustomerForm: number;
  addTextForm: number;
  addCustomerDetails: number;
}

interface addNewShipToTabs {
  facilityTab: number;
  facilityForm: number;
  addressTextForm: number;
  facilityDetails: number;
}

const ADD_NEW_USER_TABS: addNewUserTabs = {
  addCustomerForm: 1,
  addTextForm: 2,
  addCustomerDetails: 3,
};

const ADD_NEW_SHIP_TO_TABS: addNewShipToTabs = {
  facilityTab: 1,
  facilityForm: 2,
  addressTextForm: 3,
  facilityDetails: 4,
};

export const { addCustomerForm, addTextForm, addCustomerDetails } = ADD_NEW_USER_TABS;

export const { facilityTab, facilityForm, addressTextForm, facilityDetails } =
  ADD_NEW_SHIP_TO_TABS;

export const MMDDYYformat = 'MM/DD/YY';
export const MMDDYYYYformat = 'MM/DD/YYYY';
export const MMDDYYYY = 'MMM DD, YYYY';
export const oneBusinessDay = 1;


export const uploadDocRegion = [
  { "text": "Whole Nation", "value": 0 },
  { "text": "New Jersey", "value": 32 },
  { "text": "New Mexico", "value": 33 },
  { "text": "New York", "value": 35 },
  { "text": "Texas", "value": 44 }
]