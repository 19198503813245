import { all, call, put, takeEvery } from "redux-saga/effects";
import { AnyAction } from "redux";

import * as services from "../../services";
import * as actions from "./actions";
import * as constants from "./constants";

//Worker Saga

function* getAllPriceSchedulesAsync() {
  try {
    const priceSchedules = yield call(services.getAllPriceSchedules);
    yield put(actions.getAllPriceSchedulesSuccess(priceSchedules));
  } catch (errors) {
    yield put(actions.getAllPriceSchedulesError("errors"));
  }
}

function* getSelectedPriceScheduleAsync(action: AnyAction) {
  try {
    const addCustomerData = yield call(
      services.getSelectedPriceSchedules,
      action.payload
    );
    yield put(actions.getSelectedPriceScheduleSuccess(addCustomerData));
  } catch (errors) {
    yield put(actions.getSelectedPriceScheduleError("errors"));
  }
}

function* getProductPriceScheduleAsync(action: AnyAction) {
  try {
    const addCustomerData = yield call(
      services.getProductPriceSchedule,
      action.payload
    );
    yield put(actions.getProductPriceScheduleSuccess(addCustomerData));
  } catch (errors) {
    yield put(actions.getProductPriceScheduleError("errors"));
  }
}

function* addAllPriceSchedulesAsync(action: AnyAction) {
  try {
    const priceSchedules = yield call(
      services.addPriceSchedules,
      action.payload
    );
    yield put(actions.addAllPriceSchedulesSuccess(priceSchedules));
  } catch (errors) {
    yield put(actions.addAllPriceSchedulesError("errors"));
  }
}

function* addAllProductsAsync(action: AnyAction) {
  try {
    const priceSchedules = yield call(
      services.addAllPriceSchedules,
      action.payload
    );
    yield put(actions.addAllProductsSuccess(priceSchedules));
  } catch (errors) {
    yield put(actions.addAllProductsError("errors"));
  }
}

function* addSelectedProductsAsync(action: AnyAction) {
  try {
    const priceSchedules = yield call(services.addAllProducts, action.payload);
    yield put(actions.addSelectedProductsSuccess(priceSchedules));
  } catch (errors) {
    yield put(actions.addSelectedProductsError("errors"));
  }
}

function* removeProductsAsync(action: AnyAction) {
  try {
    const priceSchedules = yield call(services.removeProducts, action.payload);
    yield put(actions.removeProductsSuccess(priceSchedules));
  } catch (errors) {
    yield put(actions.removeProductsError("errors"));
  }
}

function* updateSelectedProductAsync(action: AnyAction) {
  try {
    const priceSchedules = yield call(
      services.updateSelectedProduct,
      action.payload
    );
    yield put(actions.updateSelectedProductsSuccess(priceSchedules));
  } catch (errors) {
    yield put(actions.updateSelectedProductsError("errors"));
  }
}

function* updateSelectedPriceScheduleAsync(action: AnyAction) {
  try {
    const priceSchedules = yield call(
      services.updateSelectedPriceSchedule,
      action.payload
    );
    yield put(actions.updateSelectedPriceScheduleSuccess(priceSchedules));
  } catch (errors) {
    yield put(actions.updateSelectedPriceScheduleError("errors"));
  }
}

function* updateProductsRequestAsync(action: AnyAction) {
  try {
    const priceSchedules = yield call(services.updateProducts, action.payload);
    yield put(actions.updateProductsSuccess(priceSchedules));
  } catch (errors) {
    yield put(actions.updateProductsError("errors"));
  }
}

function* removePriceScheduleAsync(action: AnyAction) {
  try {
    const priceSchedules = yield call(
      services.deletePriceSchedules,
      action.payload
    );
    yield put(actions.removePriceScheduleSuccess(priceSchedules));
  } catch (errors) {
    yield put(actions.removePriceScheduleError("errors"));
  }
}

function* removeSelectedPriceScheduleAsync(action: AnyAction) {
  try {
    const priceSchedules = yield call(services.deleteProducts, action.payload);
    yield put(actions.removeSelectedPriceScheduleSuccess(priceSchedules));
  } catch (errors) {
    yield put(actions.removeSelectedPriceScheduleError("errors"));
  }
}

//Watcher saga

function* getAllPriceSchedulesWatcher() {
  yield takeEvery(
    constants.GET_ALL_PRICE_SCHEDULES_START,
    getAllPriceSchedulesAsync
  );
}

function* getSelectedPriceScheduleWatcher() {
  yield takeEvery(
    constants.GET_SELECTED_PRICE_SCHEDULES_START,
    getSelectedPriceScheduleAsync
  );
}
function* getProductPriceScheduleWatcher() {
  yield takeEvery(
    constants.GET_PRODUCT_PRICE_SCHEDULES_START,
    getProductPriceScheduleAsync
  );
}

function* addAllPriceSchedulesWatcher() {
  yield takeEvery(
    constants.ADD_ALL_PRICE_SCHEDULES_START,
    addAllPriceSchedulesAsync
  );
}

function* addAllProductsWatcher() {
  yield takeEvery(constants.ADD_ALL_PRODUCTS_START, addAllProductsAsync);
}

function* addSelectedProductsWatcher() {
  yield takeEvery(
    constants.ADD_SELECTED_PRODUCTS_START,
    addSelectedProductsAsync
  );
}

function* removeProductsWatcher() {
  yield takeEvery(constants.REMOVE_PRODUCTS_START, removeProductsAsync);
}

function* updateSelectedProductWatcher() {
  yield takeEvery(
    constants.UPDATE_SELECTED_PRODUCTS_START,
    updateSelectedProductAsync
  );
}

function* updateSelectedPriceScheduleWatcher() {
  yield takeEvery(
    constants.UPDATE_SELECTED_PRICE_SCHEDULE_START,
    updateSelectedPriceScheduleAsync
  );
}

function* updateProductsWatcher() {
  yield takeEvery(constants.UPDATE_PRODUCTS_START, updateProductsRequestAsync);
}

function* removePriceScheduleWatcher() {
  yield takeEvery(
    constants.REMOVE_PRICE_SCHEDULES_START,
    removePriceScheduleAsync
  );
}

function* removeSelectedPriceScheduleWatcher() {
  yield takeEvery(
    constants.REMOVE_SELECTED_PRICESCHEDULE_START,
    removeSelectedPriceScheduleAsync
  );
}

export default function* priceSchedulesSaga() {
  yield all([
    getAllPriceSchedulesWatcher(),
    getSelectedPriceScheduleWatcher(),
    getProductPriceScheduleWatcher(),
    addAllPriceSchedulesWatcher(),
    addAllProductsWatcher(),
    addSelectedProductsWatcher(),
    removeProductsWatcher(),
    updateSelectedProductWatcher(),
    updateSelectedPriceScheduleWatcher(),
    updateProductsWatcher(),
    removePriceScheduleWatcher(),
    removeSelectedPriceScheduleWatcher(),
  ]);
}
