import { useRef } from "react";
import "./DatePickerNew.scss";

interface DatePickerProps {
  value?: string;
  onChange?: (e: any) => void;
  minDate?: string;
  maxDate?: string;
  className?: string;
  autoFocus?: boolean;
}
const DatePickerNew = (props: DatePickerProps) => {
  const dateInputRef = useRef(null);
  const { value, onChange, minDate, className ,autoFocus} = props;

  const handleClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker();
    }
  };
  return (
    <input
      autoFocus={autoFocus}
      type="date"
      value={value}
      onChange={onChange}
      min={minDate}
      className={className}
      ref={dateInputRef}
      onClick={handleClick}
      onKeyDown={(e) => {
        switch (e.key) {
          case "Enter":
          case " ":
            dateInputRef.current.showPicker();
            break;
        }
      }}
    />
  );
};
export default DatePickerNew;
