import service from "../config";

const TENANT_API_PATH = "/tenants";

export function getTenant (domain: string) {
  return service.get(`${TENANT_API_PATH}/key/${domain}`).then((response) => {
    return response?.data;
  });
}
export function getHistory() {
  return service.get(`/TenantInvoices`).then((response) => {
    return response?.data;
  });
}