import { makeStyles } from "@material-ui/core/styles";

export const headCells = [
  {
    id: "invoiceDate",
    disablePadding: true,
    label: "INV. DATE",
    left: true,
    class: "inv-date-col",
  },
  {
    id: "poNum",
    disablePadding: false,
    label: "PO #",
    left: true,
  },
  {
    id: "jobName",
    disablePadding: false,
    label: "JOB NAME",
    left: true,
  },
  {
    id: "orderId",
    disablePadding: false,
    label: "ORDER #",
    left: true,
  },
  {
    id: "lineId", 
    disablePadding: false,
    label: "SHIP LINE",
    left: true,
  },
  {
    id: "invoicedQtyOfUnits",
    disablePadding: false,
    label: "QTY.",
    left: true,
  },
  {
    id: "muName",
    disablePadding: false,
    label: "UOM",
    left: true,
  },
  {
    id: "netPrice",
    disablePadding: false,
    label: "NET PRICE",
    left: true,
  },
  {
    id: "extPrice",
    disablePadding: false,
    label: "EXT. PRICE",
    left: true,
  },
  {
    id: "profPct",
    disablePadding: false,
    label: "PROF. %",
    left: true,
  }
];

export const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  nextIcon: {
    color: "#000000",
  },
}));
