export const GET_RETURN_PRODUCTS_START = "GET_RETURN_PRODUCTS_START";
export const GET_RETURN_PRODUCTS_SUCCESS = "GET_RETURN_PRODUCTS_SUCCESS";
export const GET_RETURN_PRODUCTS_ERROR = "GET_RETURN_PRODUCTS_ERROR";
export const GET_RETURN_PRODUCTS_CLEAR = "GET_RETURN_PRODUCTS_CLEAR";


// RMAS RETURN PRODUCT

export const GET_RETURN_ITEMS_START = "GET_RETURN_ITEMS_START";
export const GET_RETURN_ITEMS_SUCCESS = "GET_RETURN_ITEMS_SUCCESS";
export const GET_RETURN_ITEMS_ERROR = "GET_RETURN_ITEMS_ERROR";

// return Products Picking

export const RETURN_PRODUCTS_PICKING_START = "RETURN_PRODUCTS_PICKING_START";
export const RETURN_PRODUCTS_PICKING_SUCCESS = "RETURN_PRODUCTS_PICKING_SUCCESS";
export const RETURN_PRODUCTS_PICKING_ERROR = "RETURN_PRODUCTS_PICKING_ERROR";
export const RETURN_PRODUCTS_PICKING_CLEAR = "RETURN_PRODUCTS_PICKING_CLEAR";

// return Products Ticket Print

export const RETURN_PRODUCTS_PRINT_TICKET_START = "RETURN_PRODUCTS_PRINT_TICKET_START";
export const RETURN_PRODUCTS_PRINT_TICKET_SUCCESS = "RETURN_PRODUCTS_PRINT_TICKET_SUCCESS";
export const RETURN_PRODUCTS_PRINT_TICKET_ERROR = "RETURN_PRODUCTS_PRINT_TICKET_ERROR";
export const RETURN_PRODUCTS_PRINT_TICKET_CLEAR = "RETURN_PRODUCTS_PRINT_TICKET_CLEAR";

// return Products Credit Memo

export const RETURN_PRODUCTS_CREDIT_MEMO_START = "RETURN_PRODUCTS_CREDIT_MEMO_START";
export const RETURN_PRODUCTS_CREDIT_MEMO_SUCCESS = "RETURN_PRODUCTS_CREDIT_MEMO_SUCCESS";
export const RETURN_PRODUCTS_CREDIT_MEMO_ERROR = "RETURN_PRODUCTS_CREDIT_MEMO_ERROR";
export const RETURN_PRODUCTS_CREDIT_MEMO_CLEAR = "RETURN_PRODUCTS_CREDIT_MEMO_CLEAR";

// return Products Orders

export const RETURN_PRODUCTS_ORDERS_START = "RETURN_PRODUCTS_ORDERS_START";
export const RETURN_PRODUCTS_ORDERS_SUCCESS = "RETURN_PRODUCTS_ORDERS_SUCCESS";
export const RETURN_PRODUCTS_ORDERS_ERROR = "RETURN_PRODUCTS_ORDERS_ERROR";
export const RETURN_PRODUCTS_ORDERS_CLEAR = "RETURN_PRODUCTS_ORDERS_CLEAR";

// start
export const SEARCH_RETURN_INVOICE_START = "SEARCH_RETURN_INVOICE_START";
export const SEARCH_RETURN_INVOICE_SUCCESS = "SEARCH_RETURN_INVOICE_SUCCESS";
export const SEARCH_RETURN_INVOICE_ERROR = "SEARCH_RETURN_INVOICE_ERROR";
export const SEARCH_RETURN_INVOICE_CLEAR = "SEARCH_RETURN_INVOICE_CLEAR";

export const GET_RETURN_REASON_START = "GET_RETURN_REASON_START";
export const GET_RETURN_REASON_SUCCESS = "GET_RETURN_REASON_SUCCESS";
export const GET_RETURN_REASON_ERROR = "GET_RETURN_REASON_ERROR";

export const GET_RETURN_CART_DATA = "GET_RETURN_CART_DATA";
export const ADD_RETURN_DATA_MODAL_BTN = "ADD_RETURN_DATA_MODAL_BTN";
export const IF_RETURN_CART_ADDED_PRODUCT = "IF_RETURN_CART_ADDED_PRODUCT";
export const MOVE_TO_RETURN_PAGE = "MOVE_TO_RETURN_PAGE";

export const PICK_REPLACE_CART_PRINT_START = "PICK_REPLACE_CART_PRINT_START";
export const PICK_REPLACE_CART_PRINT_SUCCESS = "PICK_REPLACE_CART_PRINT_SUCCESS";
export const PICK_REPLACE_CART_PRINT_ERROR = "PICK_REPLACE_CART_PRINT_ERROR";
export const PICK_REPLACE_CART_PRINT_CLEAR = "PICK_REPLACE_CART_PRINT_CLEAR";
// end
export const CUSTOMER_NAME_INFO = "CUSTOMER_NAME_INFO";
export const RETURN_SEARCHED_PRODUCT_DATA = "RETURN_SEARCHED_PRODUCT_DATA";
export const RETURN_PRODUCT_DATA_MODAL = "RETURN_PRODUCT_DATA_MODAL";
export const RETURN_SEARCHED_CUSTOMER_DATA = "RETURN_SEARCHED_CUSTOMER_DATA";
export const RETURN_SEARCHED_INVOICE_DATA = "RETURN_SEARCHED_INVOICE_DATA";

export const GET_COMPLETE_RETURN_PAYLOAD = "GET_COMPLETE_RETURN_PAYLOAD";

export const RETURN_CART_SUBMIT_FAIL = "RETURN_CART_SUBMIT_FAIL";

export const REMOVED_ARR_DATA = "REMOVED_ARR_DATA";
export const REMOVED_ARR_PARTIAL_DATA = "REMOVED_ARR_PARTIAL_DATA";
export const FINAL_ARR_INFO = "FINAL_ARR_INFO";
