import { all, call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

import * as services from "../../services";
import * as actions from "./actions";
import * as constants from "./constants";
import { UpdateModalContent } from "../modal/actions";

//Worker Saga

function* getRepsAsync(action: AnyAction) {
  try {
    const repsData = yield call(services.getQuoteDetailReps, action.id);
    yield put(actions.getRepsSuccess(repsData.data));
  } catch (errors) {
    yield put(actions.getRepsError(errors));
  }
}

//Watcher Saga

function* getRepsWatcher() {
  yield takeLatest(constants.GET_REPS_START, getRepsAsync);
}

function* getMessagesAsync(action: AnyAction) {
  try {
    const reqData = yield call(services.getQuoteDetailMessages, action.id);
    yield put(actions.getMessagesSuccess(reqData.data));
  } catch (errors) {
    yield put(actions.getMessagesError(errors));
  }
}

function* getRfiAsync(action: AnyAction) {
  try {
    const reqData = yield call(services.getQuoteDetailRfi, action.id);
    yield put(actions.getRfiSuccess(reqData.data));
  } catch (errors) {
    yield put(actions.getRfiError(errors));
  }
}

function* getVersionHistoryAsync(action: AnyAction) {
  try {
    const reqData = yield call(services.getVersionHistory, action.payload);
    yield put(actions.getVersionHistorySuccess(reqData.data));
  } catch (errors) {
    yield put(actions.getVersionHistoryError(errors));
  }
}

function* getMessagesWatcher() {
  yield takeLatest(constants.GET_MESSAGES_START, getMessagesAsync);
}

function* getRfiWatcher() {
  yield takeLatest(constants.GET_RFI_START, getRfiAsync);
}

function* getEstimatorNotesAsync(action: AnyAction) {
  try {
    const reqData = yield call(
      services.getQuoteDetailEstimatorNotes,
      action.id
    );
    yield put(actions.getEstimatorNotesSuccess(reqData.data));
  } catch (errors) {
    yield put(actions.getEstimatorNotesError(errors));
  }
}

function* getEstimatorNotesWatcher() {
  yield takeLatest(constants.GET_ESTIMATOR_NOTES_START, getEstimatorNotesAsync);
}

function* getItemActionNotesAsync(action: AnyAction) {
  try {
    const reqData = yield call(
      services.getQuoteDetailItemActionNotes,
      action.id
    );
    yield put(actions.getItemActionNotesSuccess(reqData.data));
  } catch (errors) {
    yield put(actions.getItemActionNotesError(errors));
  }
}

function* getItemActionNotesWatcher() {
  yield takeLatest(
    constants.GET_ITEM_ACTION_NOTES_START,
    getItemActionNotesAsync
  );
}

function* addMessageAsync() {
  try {
    yield put(actions.addMessageSuccess(""));
    yield put(
      UpdateModalContent({
        isOpen: false,
        children: null,
        fixedModal: false,
        removePaddingBottom: false,
      })
    );
    yield put(actions.addMessageSuccess("Added Message Successfully"));
  } catch (errors) {
    yield put(actions.addMessageError("Error while Adding Message"));
  }
}

function* addMessageWatcher() {
  yield takeLatest(constants.ADD_MESSAGE_START, addMessageAsync);
}

function* getNotesAsync(action: AnyAction) {
  try {
    const reqData = yield call(services.getQuoteDetailNotes, action.payload);
    yield put(actions.addNoteSuccess(""));
    yield put(
      UpdateModalContent({
        isOpen: false,
        children: null,
        fixedModal: false,
        removePaddingBottom: false,
      })
    );
    yield put(actions.getNotesSuccess(reqData.data));
  } catch (errors) {
    yield put(actions.getNotesError(errors));
  }
}

function* getNotesWatcher() {
  yield takeLatest(constants.GET_NOTES_START, getNotesAsync);
}

function* addNoteAsync() {
  try {
    yield put(actions.addNoteSuccess(""));
    yield put(
      UpdateModalContent({
        isOpen: false,
        children: null,
        fixedModal: false,
        removePaddingBottom: false,
      })
    );
    yield put(actions.addNoteSuccess("Added Note Successfully"));
  } catch (errors) {
    yield put(actions.addNoteError("Error while Adding Note"));
  }
}

function* addNoteWatcher() {
  yield takeLatest(constants.ADD_NOTE_START, addNoteAsync);
}

function* updateJobHighlightNoteAsync(action: AnyAction) {
  try {
    yield put(actions.updateJobHighlightNoteSuccess(""));
    yield put(
      UpdateModalContent({
        isOpen: false,
        children: null,
        fixedModal: false,
        removePaddingBottom: false,
      })
    );
    yield put(actions.getJobHighlightsRequest(action.payload.jobLotQuoteID));
    yield put(
      actions.updateJobHighlightNoteSuccess(
        "Update Job Highlight Note Successfully"
      )
    );
  } catch (errors) {
    yield put(
      actions.updateJobHighlightNoteError(
        "Error while Updating Job Highlight Note"
      )
    );
  }
}

function* updateJobHighlightNoteWatcher() {
  yield takeLatest(
    constants.UPDATE_JOB_HIGHLIGHT_NOTE_START,
    updateJobHighlightNoteAsync
  );
}

function* updateEstimatorNoteAsync(action: AnyAction) {
  try {
    yield put(actions.updateEstimatorNoteSuccess(""));
    yield put(
      UpdateModalContent({
        isOpen: false,
        children: null,
        fixedModal: false,
        removePaddingBottom: false,
      })
    );
    yield put(actions.getEstimatorNotesRequest(action.payload.jobLotQuoteID));
    yield put(
      actions.updateEstimatorNoteSuccess("Update Estimator Note Successfully")
    );
  } catch (errors) {
    yield put(
      actions.updateEstimatorNoteError("Error while Updating Estimator Note")
    );
  }
}

function* updateEstimatorNoteWatcher() {
  yield takeLatest(
    constants.UPDATE_ESTIMATOR_NOTE_START,
    updateEstimatorNoteAsync
  );
}

function* updateItemsActionNoteAsync(action: AnyAction) {
  try {
    yield put(actions.updateItemsActionNoteSuccess(""));
    yield put(
      UpdateModalContent({
        isOpen: false,
        children: null,
        fixedModal: false,
        removePaddingBottom: false,
      })
    );
    yield put(actions.getItemActionNotesRequest(action.payload.jobLotQuoteID));
    yield put(
      actions.updateItemsActionNoteSuccess(
        "Update Items Action Note Successfully"
      )
    );
  } catch (errors) {
    yield put(
      actions.updateItemsActionNoteError(
        "Error while Updating Items Action Note"
      )
    );
  }
}

function* updateItemsActionNoteWatcher() {
  yield takeLatest(
    constants.UPDATE_ITEMS_ACTION_NOTE_START,
    updateItemsActionNoteAsync
  );
}

function* updateMajorRepsAsync(action: AnyAction) {
  try {
    yield put(actions.updateMajorRepsSuccess(""));
    yield put(
      UpdateModalContent({
        isOpen: false,
        children: null,
        fixedModal: false,
        removePaddingBottom: false,
      })
    );
    yield put(actions.getMajorRepsRequest(action.payload.jobLotQuoteID));
    yield put(actions.updateMajorRepsSuccess("Update Major Reps Successfully"));
  } catch (errors) {
    yield put(actions.updateMajorRepsError("Error while Updating Major Reps"));
  }
}

function* updateMajorRepsWatcher() {
  yield takeLatest(constants.UPDATE_MAJOR_REPS_START, updateMajorRepsAsync);
}

function* getJobHighlightsAsync(action: AnyAction) {
  try {
    const reqData = yield call(services.getJobHighlights, action.id);
    yield put(actions.getJobHighlightsSuccess(reqData.data));
  } catch (errors) {
    yield put(actions.getJobHighlightsError(errors));
  }
}

function* getJobHighlightsWatcher() {
  yield takeLatest(constants.GET_JOB_HIGHLIGHTS_START, getJobHighlightsAsync);
}

function* getMajorRepsAsync(action: AnyAction) {
  try {
    const reqData = yield call(services.getMajorReps, action.id);
    yield put(actions.getMajorRepsSuccess(reqData.data));
  } catch (errors) {
    yield put(actions.getMajorRepsError(errors));
  }
}

function* getFollowUpNoteAsync(action: AnyAction) {
  try {
    const reqData = yield call(services.getFollowUpNote, action.id);
    yield put(actions.getFollowUpNoteSuccess(reqData.data));
  } catch (errors) {
    yield put(actions.getFollowUpNoteError(errors));
  }
}

function* getMajorRepsWatcher() {
  yield takeLatest(constants.GET_MAJOR_REPS_START, getMajorRepsAsync);
}

function* getFollowUpNoteWatcher() {
  yield takeLatest(constants.GET_FOLLOW_UP_START, getFollowUpNoteAsync);
}


function* getQuotesEndRecordAsync(action: AnyAction) {
  try {
    const reqData = yield call(services.getQuotesEndRecord, action.id);
    yield put(actions.getQuotesEndRecordSuccess(reqData.data));
  } catch (errors) {
    yield put(actions.getQuoteEndRecordError(errors));
  }
}

function* getQuotesEndRecordWatcher() {
  yield takeLatest(
    constants.GET_QUOTES_END_RECORD_START,
    getQuotesEndRecordAsync
  );
}

function* addDocumentAsync(action: AnyAction) {
  try {
    yield call(services.addDocument, action.payload);
    yield put(actions.addDocumentSuccess(""));
    yield put(
      UpdateModalContent({
        isOpen: false,
        children: null,
        fixedModal: false,
        removePaddingBottom: false,
      })
    );
    yield put(actions.addDocumentSuccess("Added Document Successfully"));
  } catch (errors) {
    yield put(actions.addDocumentError("Error while Adding Document"));
  }
}

function* addDocumentWatcher() {
  yield takeLatest(constants.ADD_DOCUMENT_START, addDocumentAsync);
}

function* getVersionHistoryWatcher() {
  yield takeLatest(constants.GET_VERSION_HISTORY_START, getVersionHistoryAsync);
}

function* getOrdersDetailsAsync(action: AnyAction) {
  try {
    const repsData = yield call(services.getOrdersDetails, action.id);
    yield put(actions.getOrdersDetailsSuccess(repsData.data));
  } catch (errors) {
    yield put(actions.getOrdersDetailsError(errors));
  }
}

function* getOrdersDetailsWatcher() {
  yield takeLatest(constants.GET_REPS_START, getOrdersDetailsAsync);
}


export default function* quoteDetailSaga() {
  yield all([
    getRepsWatcher(),
    getMessagesWatcher(),
    getEstimatorNotesWatcher(),
    getItemActionNotesWatcher(),
    getMajorRepsWatcher(),
    addMessageWatcher(),
    getNotesWatcher(),
    addNoteWatcher(),
    updateJobHighlightNoteWatcher(),
    updateEstimatorNoteWatcher(),
    updateItemsActionNoteWatcher(),
    updateMajorRepsWatcher(),
    getJobHighlightsWatcher(),
    getQuotesEndRecordWatcher(),
    addDocumentWatcher(),
    getVersionHistoryWatcher(),
    getRfiWatcher(),
    getFollowUpNoteWatcher(),
    getOrdersDetailsWatcher()
  ]);
}
