import { Typography, TypographyProps } from "@mui/material";

import useStyles from "./style";

const PosTypography = ({
  variant,
  title,
  className,
  id,
  variantMapping,
  align,
}: TypographyProps) => {

  const classes = useStyles();
  return (
    <Typography
      component="div"
      variant={variant}
      gutterBottom
      className={`${classes.root} ${className}`}
      id={id}
      variantMapping={variantMapping}
      align={align}
    >
      {title}
    </Typography>
  );
};

export default PosTypography;
