import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import {
  Button,
  Input,
  InputOptional,
  Select,
} from "../../../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";

import { Formik } from "formik";
import * as Yup from "yup";
import "yup-phone-lite";
import MaskInput from "apps/pos/Common/maskInput/maskInput";
import { contactInfoRequest } from "../../../sagas/setting/actions";
import { RootState } from "../../../models";
import { contactInfo } from "../../../models/setting";
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const ContactInfoSchema = Yup.object().shape({
  companyPhone: Yup.string().required("A phone number is required"),
  zip: Yup.string().required("Zip required.").min(5).max(6),
  address1: Yup.string().required("Address required."),
  street: Yup.string().required("Street name required."),
  city: Yup.string()
    .required("City required.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for company name"),
  state: Yup.string()
    .required("State required.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for company name"),
  country: Yup.string()
    .required("Country required.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for company name"),
});

export function ContactInfo({ distributorId }) {
  const dispatch = useDispatch();

  const [contactInfoData, setContactInfoData] = useState<contactInfo>();

  const { contactInfo } = useSelector<RootState, any>((state) => state.setting);

  useEffect(() => {
    dispatch(contactInfoRequest(distributorId));
  }, []);

  useEffect(() => {
    setContactInfoData(contactInfo);
  }, [contactInfo]);

  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">ContactInfo</div>
        <div className="devider2px mb-0" />

        {/* contactInfoData?.phone?.fullPhoneNum, */}
        <Formik
          initialValues={{
            companyPhone: contactInfoData?.phone?.fullPhoneNum
              ? contactInfoData?.phone?.fullPhoneNum
              : "",
            postalCode: contactInfoData?.address?.postalCode ? contactInfoData?.address?.postalCode : "",
            address1: contactInfoData?.address?.addressLine? contactInfoData?.address?.addressLine : "",
            address2: "",
            street: "",
            city: contactInfoData?.address?.city ? contactInfoData?.address?.city : "",
            state: contactInfoData?.address?.state ? contactInfoData?.address?.state :"",
            zip: contactInfoData?.address?.postalCode ? contactInfoData?.address?.postalCode :"",
            country: contactInfoData?.address?.country ?contactInfoData?.address?.country : "",
          }}
          validationSchema={ContactInfoSchema}
          enableReinitialize
          onSubmit={() => {
            // Required Later
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => {
            return (
              <form className="formContainer" id="addContactInfoForm">
                <div className="d-flex">
                  <Select
                    className="setting-country-select-div"
                    id={"CompanyPhone"}
                    name={"CompanyPhone"}
                    label={"Company Phone"}
                    textInputStyle="phoneinput mt-1"
                    options={[
                      { label: "+1", value: "+1" },
                      { label: "+91", value: "+91" },
                    ]}
                  />

                  <div className="phone-number-container setting-phone-number-info">
                    <MaskInput                    
                      mask="(999) 999-9999"
                      isClassName="masked-phone-input"
                      id={"companyPhone"}
                      name={"companyPhone"}
                      alwaysShowMask={true}
                      onChange={handleChange}
                      value={values.companyPhone}
                    />
                  </div>
                  <div className="ext-container">
                    <InputOptional
                      textInputStyle="extinput"
                      id={"addExt"}
                      name={"addExt"}
                      label={"Ext"}
                      type={"text"}
                      placeholder={""}
                    />
                  </div>
                </div>
                {errors.companyPhone && touched.companyPhone ? (
                  <p className="validation-error-text">
                    <>{errors.companyPhone}</>
                  </p>
                ) : null}
                <div className="addressTitle setting-company-address-sec">
                  Company Address
                </div>

                {/* <Input
                  textInputStyle="mt-1"
                  id={"postalCode"}
                  name={"postalCode"}
                  label={"Postal Code"}
                  type={"text"}
                  placeholder={""}
                  onChange={handleChange}
                  value={values.postalCode}
                />
                {errors.postalCode && touched.postalCode ? (
                  <p className="validation-error-text">{errors.postalCode}</p>
                ) : null} */}
                <Input
                  textInputStyle="mt-1"
                  id={"address1"}
                  name={"address1"}
                  label={"Address 1"}
                  type={"text"}
                  onChange={handleChange}
                  value={values.address1}
                  placeholder={""}
                  className="mt-3"
                />
                {errors.address1 && touched.address1 ? (
                  <p className="validation-error-text">
                    <>{errors.address1}</>
                  </p>
                ) : null}
                <InputOptional
                  textInputStyle="mt-1"
                  id={"address2"}
                  name={"address2"}
                  label={"Address 2"}
                  onChange={handleChange}
                  value={values.address2}
                  type={"text"}
                  placeholder={""}
                />
                <Input
                  textInputStyle="mt-1"
                  id={"city"}
                  name={"city"}
                  label={"City"}
                  onChange={handleChange}
                  value={values.city}
                  type={"text"}
                  placeholder={""}
                />
                {errors.city && touched.city ? (
                  <p className="validation-error-text">
                    <>{errors.city}</>
                  </p>
                ) : null}
                <div className="d-flex">
                  <Input
                    textInputStyle="mt-1"
                    className="cityinput"
                    id={"state"}
                    name={"state"}
                    label={"State"}
                    onChange={handleChange}
                    value={values.state}
                    type={"text"}
                    placeholder={""}
                  />
                  <div className="width-20"></div>

                  <Input
                    textInputStyle="mt-1"
                    className="cityinput"
                    id={"zip"}
                    name={"zip"}
                    label={"Zip"}
                    type={"text"}
                    onChange={handleChange}
                    value={values.zip}
                    placeholder={""}
                  />
                </div>

                <div className="subscription-heading">
                  <div>
                    {errors.state && touched.state ? (
                      <p className="validation-error-text">
                        <>{errors.state}</>
                      </p>
                    ) : null}
                  </div>

                  <div>
                    {errors.zip && touched.zip ? (
                      <p className="validation-error-text">
                        <>{errors.zip}</>
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex" style={{ width: "48%" }}>
                  <Input
                    textInputStyle="mt-1"
                    className="cityinput"
                    id={"country"}
                    name={"country"}
                    label={"Country"}
                    type={"text"}
                    onChange={handleChange}
                    value={values.country}
                    placeholder={""}
                  />
                </div>
                {errors.country && touched.country ? (
                  <p className="validation-error-text">
                    <>{errors.country}</>
                  </p>
                ) : null}

                <div className="devider1px" />

                <Button
                  type={"submit"}
                  onClick={handleSubmit}
                  className="manage-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#jumper_modal"
                >
                  <span className="manage-button-txt">save changes</span>
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </PerfectScrollbar>
  );
}
