import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { getSubQuotes } from "services/quoteDetail/request";

export const useGetSubQuotes = () => {
  const [id, setId] = useState(null);

  const { data, isSuccess, isFetching } = useQuery(
    ["get-sub-quotes", id],
    async () => {
      if (id) {
        return getSubQuotes(id);
      }
    },
    {
      cacheTime: 0,
    }
  );

  const setQuoteParamsId = useCallback((data) => {
    if (data) {
      setId(data);
    }
  }, []);

  return {
    setQuoteParamsId,
    getSubQuoteData: data?.data ?? {},
    isSubQuoteSuccess: isSuccess,
    isSubQuoteFetching: isFetching,
  };
};
