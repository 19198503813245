import AuthenticatedUserSaga from "./authenticatedUser/saga";
import DistributorSaga from "./distributor/saga";
import ModalSaga from "./modal/saga";
import TenanatSaga from "./tenant/saga";
import quoteDetailSaga from "./quoteDetail/saga";
import settingSaga from "./setting/saga";
import userSaga from "./user/saga";
import posSaga from "./pos/saga";
import priceSchedulesSaga from "./priceSchedules/saga";
import persistSaga from "./persistReducer/saga";
import returnSaga from "./returns/saga";
import importQuotesSaga from "./pos/importQuotes/saga";
import customerDocumentSaga from "./pos/documentUpload/saga";

import { all } from "redux-saga/effects";

export default function* IndexSaga() {
  yield all([
    AuthenticatedUserSaga(),
    DistributorSaga(),
    TenanatSaga(),
    ModalSaga(),
    quoteDetailSaga(),
    settingSaga(),
    userSaga(),
    posSaga(),
    persistSaga(),
    priceSchedulesSaga(),
    returnSaga(),
    importQuotesSaga(),
    customerDocumentSaga(),
  ]);
}
