import React, { useEffect, useState, ReactNode } from "react";
import styled from "styled-components";

export interface ToastProps {
  className?: string;
  children: ReactNode;
  status: "Success" | "Error" | "Notice";
  delayAmount?: number;
  autoHide?: boolean;
  textButton?: boolean;
  onClose?: () => void;
  closeIcon?: boolean;
  infoIcon?: boolean;
  messageClassName?: string;
  messageHeaderClassName?: string;
}

export const ToastContainer = styled.div`
  position: absolute;
  top: 70px;
  margin: auto;
  display: block;
  right: 180px;
  z-index: 999;

`;

export const Toast = ({
  className,
  status = "Success",
  children,
  delayAmount = 3000,
  autoHide = true,
  textButton,
  onClose,
  closeIcon = true,
  infoIcon = true,
  messageClassName,
  messageHeaderClassName,
  ...props
}: ToastProps) => {
  const [visible, setVisible] = useState(true);
  let timeOut: ReturnType<typeof setTimeout>;

  useEffect(() => {
    if (autoHide) {
      startTimer();
    }
  }, []);

  const startTimer = () => {
    timeOut = setTimeout(() => {
      setVisible(false);
    }, delayAmount);
  };

  return (
    <div
      className={`toast fade ${visible ? "show" : ""} ${
        className ? className : ""
      }`}
      id="toastBasic"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      onMouseEnter={() => (autoHide ? clearTimeout(timeOut) : null)}
      onMouseLeave={() => (autoHide ? startTimer() : null)}
      {...props}
    >
      <div
        className={`toast-header text-white`}
        style={{
          backgroundColor: "black",
          borderRadius: 5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 15,
          width: 500,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
          className={messageHeaderClassName}
        >
          {infoIcon && (
            <i
              className={
                status === "Success"
                  ? "fa fa-info-circle"
                  : "fa fa-exclamation-circle"
              }
              color={status === "Success" ? "white" : "red"}
              style={{ fontSize: 16 }}
            ></i>
          )}
          <span
            className={`mr-auto ${messageClassName}`}
            style={{ marginLeft: 20, fontSize: 16 }}
          >
            {children}
          </span>
        </div>
        {closeIcon && (
          <button
            aria-label="Close"
            className="text-white"
            data-dismiss="toast"
            onClick={() => {
              textButton ? onClose() : setVisible(false);
            }}
            type="button"
            style={{ backgroundColor: "black", border: "0px solid black" }}
          >
            <span aria-hidden="true" style={{ fontSize: textButton ? 16 : 24 }}>
              {textButton ? "CLEAR CART" : "×"}
            </span>
          </button>
        )}
      </div>
      {/* <small className="text-white-50 ml-2">just now</small> */}
      {/* <div className="toast-body" style={{ backgroundColor: "black" }}>
        {children}
      </div> */}
    </div>
  );
};