import { motion } from 'framer-motion/dist/framer-motion';
import { useEffect, useRef } from 'react';
import IconButton from '../components/Elements/IconButton';
import useScrollLock from '../hooks/useScrollLock';
import OverlaySkrim from '../OverlaySkrim/OverlaySkrim';
import './AdminDialog.scss';

const AdminDialog = (props) => {
    const { children, visible, onClose, classes } = props;
    const { toggleScroll } = useScrollLock();
    const dialogRef = useRef();


    useEffect(() => {
        // toggle scroll based on dialog visibility
        // if dialog is visible, disable scroll
        // if dialog is not visible, enable scroll
        toggleScroll(!visible);

        // set focus to the dialog when visible
        if (visible) {
            dialogRef.current.focus();
        }
    }, [visible]);


    // close the dialog on ESC keypress
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key !== 'Escape') return;
            if (e.repeat) return;
            onClose();
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, []);


    const dialogAnimVariants = {
        initial: {
            opacity: 0,
            y: 10,
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.2,
                ease: 'circOut',
            }
        },
        exit: {
            opacity: 0,
            y: 10,
            transition: {
                duration: 0.2,
                ease: 'backOut',
            }
        },
    }
    const handleKeyDownOnDiv = (e) => {
        if (e.key === 'Escape') onClose();
        e.stopPropagation()
       }
    return (
        visible ? (
            <>
                <OverlaySkrim onClick={onClose} />
                <motion.div
                    className="dialog-wrapper"
                    initial='initial'
                    animate='animate'
                    exit='exit'
                    variants={dialogAnimVariants}
                    onKeyDown={(e) => handleKeyDownOnDiv(e)}
                >
                    <div
                        className="admin-dialog"
                        ref={dialogRef}
                        tabIndex='0'
                    >
                        <div className={`dialog-contents ${classes ? classes : ''}`}>
                            {children}
                            <IconButton
                                icon="close"
                                secondary="true"
                                onClick={onClose}
                                title="Close"
                                classes="dialog-close-button"
                            />

                        </div>
                    </div>
                </motion.div>
            </>
        ) : null
    );
}

export default AdminDialog;
