import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import {
  Button,
  Input,
  InputOptional,
} from "../../../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";

import { domainUrlRequest } from "../../../sagas/setting/actions";
import { RootState } from "../../../models";
import { domainAndUrlInfo } from "../../../models/setting";
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function DomainUrls({distributorId}) {

  const [domainAndUrlData, setDomainAndUrlData] = useState<domainAndUrlInfo>();

  const { domainAndUrl } = useSelector<RootState, any>((state) => state.setting);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(domainUrlRequest(distributorId));
  }, []);

  useEffect(() => {
    setDomainAndUrlData(domainAndUrl);
  }, [domainAndUrl]);  

  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Domain and Urls</div>
        <div className="devider2px" />
        <form className="formContainer" id="addDomainForm">
          <Input
            textInputStyle="mt-1"
            disabled={true}
            id={"addFullDomain"}
            name={"addFullDomain"}
            value={domainAndUrlData?.fullDomain}
            label={"Full Domain"}
            type={"text"}
            placeholder={"applecomputers.quoteanything.com"}
          />
          <Input
            textInputStyle="mt-1"
            disabled={true}
            id={"rootDomain"}
            name={"rootDomain"}
            value={domainAndUrlData?.rootDomain}
            label={"Root Domain"}
            type={"text"}
            placeholder={"applecomputers.quoteanything.com"}
          />
          <Input
            textInputStyle="mt-1"
            disabled={true}
            id={"subDomain"}
            name={"subDomain"}
            label={"Sub Domain"}
            value={domainAndUrlData?.subDomain}
            type={"text"}
            placeholder={"applecomputers.quoteanything.com"}
          />
          <Input
            textInputStyle="mt-1"
            disabled={true}
            id={"emailDomain"}
            name={"emailDomain"}
            label={"Email Domain"}
            value={domainAndUrlData?.emailDomain}
            type={"text"}
            placeholder={"applecomputers.quoteanything.com"}
          />
          <Input
            textInputStyle="mt-1"
            disabled={true}
            id={"emailDomainVerified"}
            name={"emailDomainVerified"}
            label={"Is Email Domain Verified"}
            value={domainAndUrlData?.isEmailDomainVerified}
            type={"text"}
            placeholder={"applecomputers.quoteanything.com"}
          />
          <Input
            textInputStyle="mt-1"
            disabled={true}
            id={"baseFolderName"}
            name={"baseFolderName"}
            label={"Base Folder Name"}
            value={domainAndUrlData?.baseFolderName}
            type={"text"}
            placeholder={"applecomputers.quoteanything.com"}
          />
          <Input
            textInputStyle="mt-1"
            disabled={true}
            id={"secureUrl"}
            name={"secureUrl"}
            label={"Secure Url (HTTPS)"}
            value={domainAndUrlData?.httpsUrl}
            type={"text"}
            placeholder={"applecomputers.quoteanything.com"}
          />
          <InputOptional
            textInputStyle="mt-1"
            id={"addBlogurl"}
            name={"addBlogurl"}
            label={"Blog URL"}
            value={domainAndUrlData?.blogUrl}
            type={"text"}
            placeholder={""}
          />
        </form>
        <div className="devider1px" />

        <Button
          type={"submit"}
          className="manage-btn"
          data-bs-toggle="modal"
          data-bs-target="#jumper_modal"
        >
          <span className="manage-button-txt">save changes</span>
        </Button>
      </div>
    </PerfectScrollbar>
  );
}
