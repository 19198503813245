import { ErrorMessage as ErrorMessageFormik } from "formik";

interface Props {
  name?: string;
  className?: string;
}

export const ErrorMessage = (props: Props) => {
  const { name, className } = props;
  return (
    <p className={`${className ? className : ''} validation-error-text mt-2 int-error-text mb-0`}>
      <ErrorMessageFormik name={name || ""} component="span" />
    </p>
  );
};
