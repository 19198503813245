import React, {
  useEffect,
  useState,
  useCallback,
  startTransition,
  CSSProperties,
  useRef,
} from "react";
import { InputAdornment, Radio } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import _ from "loadsh";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList as List } from "react-window";

import { PosIconButton, PosInput, PosButton } from "apps/pos/Common/uiCore";
import { Loading } from "components";

import { CloseIcon, SearchIcon, CheckCircleIcon } from "constants/icons";
import { ImportQuoteDetail, RootState } from "models";
import { DateFormat } from "constants/enum";
import { isoToFormat, getLocalStoraGeItem, scrollToSetting } from "utils/utils";
import { keys } from "utils/helper";
import texts from "apps/pos/locales";
import { PosFlowDataProp } from "constants/interfaces";

import {
  arrowDownIndexImportQuote,
  arrowResetImportQuote,
  arrowUpIndexImportQuote,
  importQuoteItemsRequest,
  importQuoteRequest,
  importQuoteSearchClear,
  importQuoteSearchRequest,
} from "sagas/pos/importQuotes/action";
import { setPosFlowData } from "sagas/persistReducer/actions";

interface Props {
  closeModel?: () => void;
  changeItemView?: () => void;
  importQuotePageProductSearchRef?: React.MutableRefObject<any>
}
interface InfiniteLoaderProps {
  index: number;
  style: CSSProperties;
}
const ImportQuote = ({ closeModel, changeItemView, importQuotePageProductSearchRef}: Props) => {

  const {
    importQuotesDetails,
    importQuotesTotal,
    importQuotesSearchDetails,
    isImportQuotesItemsLoading,
    importQuotesSearchError,
    isImportQuotesSearchLoading,
    loadCartDetails,
    selectedIndex,
  } = useSelector<RootState, ImportQuoteDetail>(
    (state) => state.posImportQuote
  );

  const { posFlowData } = useSelector<
    RootState,
    PosFlowDataProp
  >((state) => state.persistReducer);
  const { customer } = posFlowData || {};
  const { personId, customerId } = customer || {};
  const [downPress, setDownPress] = useState(false);
  const [upPress, setUpPress] = useState(false);
  const dispatch = useDispatch();
  const { MM_DD_YYYY } = DateFormat;
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace || {};
  const {
    importQuote,
    activeQuotesAvailable,
    SelectItemsToImport,
    SelectQuoteToContinue,
  } = texts || {};
  const [customAutoFocus, setCustomAutoFocus] = useState(true)
  const [selectionValue, setSelectionValue] = useState([]);
  const [quotesData, setQuotesData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchImportQuotes, setSearchImportQuotes] = useState("");
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const tableRowRef = useRef<HTMLDivElement>();

  function handleKeyPress(e) {
    const { keyCode } = e;
    const { downarrow, uparrow,enter } = keys;
    const isButtonFocused = document.activeElement.tagName.toLowerCase() === 'button'
    if (keyCode === downarrow) {
      setDownPress(true);
      setSelectionValue([])
    }
    else if (keyCode === uparrow) {
      setUpPress(true);
      setSelectionValue([])

    }
    else{
      setCustomAutoFocus(true)
    }

    if(keyCode === enter && !isButtonFocused){
      if (selectedIndex > -1 ){
        rowHandleClick(quotesData[selectedIndex])
      }
    }
  }

  useEffect(() => {
    const data = searchImportQuotes
      ? importQuotesSearchDetails
      : importQuotesDetails;
    const rowItemData = data?.map((item) => {
      return { ...item, isSelected: false };
    });
    setQuotesData(rowItemData);
    setIsLoading(
      isImportQuotesSearchLoading
        ? isImportQuotesSearchLoading
        : isImportQuotesItemsLoading
    );
   
  }, [importQuotesDetails, importQuotesSearchDetails, searchImportQuotes, selectedIndex]);

  useEffect(()=>{
    document.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document?.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedIndex, selectionValue])

  useEffect(()=>{
    if (selectedIndex > -1){
      dispatch(arrowResetImportQuote())
    }
  },[])

  useEffect(() => {
    async function downPressHandler() {
      if (downPress) {
        if (quotesData?.length){
          await dispatch(arrowDownIndexImportQuote(quotesData.length));
        }
        scrollToSetting(selectedIndex + 1);
        setDownPress(false);
      }
    }
    downPressHandler();
  }, [downPress]);

  useEffect(() => {
    async function upPressHandler() {
      if (upPress) {
        await dispatch(arrowUpIndexImportQuote());
        if (selectedIndex > -1) {
          scrollToSetting(selectedIndex);
        }
        setUpPress(false);
      }
    }
    upPressHandler()
  }, [upPress]);

  const inputOnChange = (e: string) => {
    setSearchImportQuotes(e);
    if (e.length) {
      debounceFn(e);
    } else {
      dispatch(importQuoteSearchClear());
    }
  };
  // search api call
  const importSearchApiCall = (e: string) => {
    const data = {
      page: page,
      size: 20,
      query: e,
    };
    dispatch(importQuoteSearchRequest(data));
  };
  const debounceFn = useCallback(_.debounce(importSearchApiCall, 500), []);

  // Import Items api call
  const selectItemImport = () => {
    selectionValue && changeItemView();
    const data = {
      quoteId: selectionValue[0]?.quoteId,
      personId: personId || customerId,
      stockWarehouseId: stockWarehouseId,
      quoteType: selectionValue[0]?.quoteType,
      priceScheduleId: posFlowData?.schedule?.id
        ? posFlowData?.schedule?.id
        : loadCartDetails?.customer?.priceScheduleId,
    };
    dispatch(importQuoteItemsRequest(data));
    const quoteData = {
      ...posFlowData,
      selectQuoteId: selectionValue[0]?.quoteId,
      selectQuoteType: selectionValue[0]?.quoteType,
      selectQuoteName : selectionValue[0]?.name,
    };
    dispatch(setPosFlowData(quoteData));
  };

  const rowHandleClick = (itemData) => {
    const updatedImportItems = quotesData?.map((item) => {
      if (itemData.quoteId === item.quoteId) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return { ...item, isSelected: false };
      }
    });
    setQuotesData(updatedImportItems);
    const selectedRow = updatedImportItems?.filter((item) => {
      return item?.isSelected;
    });
    setSelectionValue(selectedRow);
  };
 
  const handleFocusOnSearchInput = ()=>{
    setDownPress(false)
    setUpPress(false)
  }

  const ImportModelHeader = ({ importQuotePageProductSearchRef }: any) => { //TODO : Give proper Type to importQuotePageProductSearchRef
    return (
      <div className="model-header-main-section">
        <div className="header-container">
          <div className="header-title-div">
            <span className="main-title">{importQuote}</span>
            <span className="description">
              {searchImportQuotes
                ? importQuotesSearchDetails?.length
                : importQuotesTotal}{" "}
              {activeQuotesAvailable}
            </span>
          </div>
          <PosInput
            variant="outlined"
            type="text"
            className="quote-search-input search-input-focus"
            autoFocus={customAutoFocus}  
            onFocus={handleFocusOnSearchInput}
            inputRef={importQuotePageProductSearchRef}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (
                !e.target.value.includes("  ") &&
                e.target.value.charAt(0) !== " "
              ) {
                inputOnChange(e.target.value);
                dispatch(arrowResetImportQuote());
                setSelectionValue([])
              }
            }}
            value={searchImportQuotes}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <PosIconButton
            onClick={closeModel}
            className="model-header-close-button button-focus"
          >
            <CloseIcon className="close-icon" />
          </PosIconButton>
        </div>
      </div>
    );
  };

  const ImportModelFooter = () => {
    return (
      <div className="model-footer-section">
        <span className="title">{`${
          selectionValue[0]
            ? `Quote #${selectionValue[0]?.quoteId} selected`
            : `${SelectQuoteToContinue}`
        }`}</span>
        {selectionValue[0]?.quoteId ? (
          <PosButton
            variant="contained"
            color="primary"
            className="select-item-btn btn"
            disabled={!selectionValue[0]?.quoteId}
            onClick={() => selectItemImport()}
          >
            {SelectItemsToImport}
          </PosButton>
        ) : null}
      </div>
    );
  };

  const fetchData = (page: number) => {
    setIsNextPageLoading(true);
    startTransition(() => {
      const data = {
        page: page,
        size: 20,
      };
      dispatch(importQuoteRequest(data));
      setIsNextPageLoading(false);
    });
  };

  const loadMoreItems = (startIndex: number, stopIndex: number) => {
    if (stopIndex + 2 >= page * 20) {
      setPage(page + 1);
    }
  };
  // First time api call
  useEffect(() => {
    if (page === 1) setIsLoading(true);
    fetchData(page);
  }, [page]);

  const isItemLoaded = () => isNextPageLoading;

  const Row = ({ index, style }: InfiniteLoaderProps) => {
    return (
      <div className="import-quote-main-table-section" style={style}>
        <div className="item-import-table-body-section">
          <div
            className={`item-import-body-container ${
              quotesData[index].isSelected ? "item-isSelected-values" : ""
            } `}
            style={{
              cursor: "pointer",
              backgroundColor:
                index === selectedIndex
                  ? "lightgray"
                  : "white",
              borderRadius:
                index === selectedIndex ? 30 : 0,
            }}
            onClick={() => rowHandleClick(quotesData[index])}
            key={quotesData[index]?.quoteId}
            ref={tableRowRef[index]}
            id={`tableRowRef${index}`}
          >
            <div className="item-import-wrapper-main first-quote-info">
             
              <span className="title">
                <Radio
                  disableRipple
                  checked={quotesData[index].isSelected}
                  checkedIcon={
                    <CheckCircleIcon fontSize="small" className="check-icon" />
                  }
                  tabIndex={-1}
                />
              </span>
            </div>
            <div className="item-import-wrapper-main second-quote-info">
              <span className="title image-with-title">
                <span className="title-id">{quotesData[index].quoteId}</span>
                <span className="title-description">
                  {quotesData[index].name}
                </span>
              </span>
            </div>
            <div className="item-import-wrapper-main third-quote-info">
              <span className="title">
                {isoToFormat(`${quotesData[index].createdAt}`, MM_DD_YYYY)}
              </span>
            </div>
            <div className="item-import-wrapper-main third-quote-info">
              <span className="title">{quotesData[index].quoteType}</span>
            </div>
            <div className="item-import-wrapper-main third-quote-info">
              <span className="title">{quotesData[index].lineCount}</span>
            </div>
            <div className="item-import-wrapper-main third-quote-info">
              <span className="title">${quotesData[index].totalAmt}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <ImportModelHeader
        importQuotePageProductSearchRef={importQuotePageProductSearchRef}
      />
      {isLoading ? (
        <Loading className="select-item-loading" />
      ) : (
        <>
          {importQuotesSearchError && !quotesData.length ? (
            <div className="import-no-data-found">
              {importQuotesSearchError}
            </div>
          ) : null}
          {quotesData.length > 0 && (
            <>
                <div className="import-quote-title">
              <div className="item-import-wrapper-main first-quote-info">
              </div>
              <div className="item-import-wrapper-main second-quote-info">
                <div className="">Quote</div>
              </div>
              <div className="item-import-wrapper-main third-quote-info">
                <div className="">Date</div>
              </div>
              <div className="item-import-wrapper-main third-quote-info">
                <div className="">Type</div>
              </div>
              <div className="item-import-wrapper-main third-quote-info">
                <div className="">Items</div>
              </div>
              <div className="item-import-wrapper-main third-quote-info">
                <div className="">Totals</div>
              </div>
            </div>
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={quotesData.length}
              loadMoreItems={loadMoreItems}
              threshold={20}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  className="item-list"
                  height={570}
                  itemCount={quotesData.length}
                  itemSize={80}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width={1000}
                >
                  {Row}
                </List>
              )}
            </InfiniteLoader>
            </>
          )}
        </>
      )}
      <ImportModelFooter />
    </>
  );
};

export default ImportQuote;
