import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormikProvider, useFormik } from "formik";

import MaskInput from "apps/pos/Common/maskInput/maskInput";
import { PosInput, PosModel } from "apps/pos/Common/uiCore";
import Button from "components/SPButton/Button";
import { Distributor, RootState, quoteDetail } from "models";
import { CustomerInfo } from "./CustomerInfo";
import { IconButton } from "../SBItems/elements";
import { customerInfoValidateSchema } from "layouts/formik/validationSchema";

import { blockInvalidChar, blockInvalidCharForPhone } from "utils/utils";
import { springboardCustomerInfo } from "constants/constant";
import { keys } from "utils/helper";

import { getCustomerQuoteInfo, updateQuoteCustomer } from "services";
import { setPackageInfo } from "sagas/quoteDetail/actions";

interface LinkQuoteRecipientProps {
  jobLotPackageId?: number;
  handlerID?: number;
  jobLotQuoteID?: number;
  open: boolean;
  handleCloseModel: () => void;
}


export const LinkQuoteRecipientForm = (props: LinkQuoteRecipientProps) => {
  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );
  const {
    packageInfoData,
  } = useSelector<RootState, quoteDetail>((state) => state.quoteDetail);

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const { jobLotPackageId, jobLotQuoteID, open, handleCloseModel } = props;
  const { httpsUrl } = distributor || {}
  const { companyName, customerFirstName, customerLastName, customerEmail, commPrefer, creatorType, showQRCodeOnPackingSlip, creatorID, ph1, ph2, ph3, ph4, quoteCustomerName } = packageInfoData || {}
  const { customer, editCustomerInfo, saveChanges, cancel, phone, ext: extTitle, email, contactName, companyName: company, recipientName , recipientMessage } = springboardCustomerInfo;

  const formik = useFormik({
    initialValues: {
      quotePackageID: jobLotPackageId,
      quotePackageName: quoteCustomerName,
      creatorID,
      fname: customerFirstName + " " + customerLastName,
      email: customerEmail,
      phone: ph1 + ph2 + ph3,
      companyName,
      creatorType,
      commPrefer,
      showQRCodeOnPackingSlip,
      ext: ph4
    },
    enableReinitialize: true,
    validationSchema: customerInfoValidateSchema,
    onSubmit: (values) => {
      setSubmitting(true)
      const { fname, phone, email, companyName, ext, quotePackageName, creatorID } = values;
      const firstName = fname?.split(" ")?.[0];
      const lastName = fname?.split(" ")?.[1];
      updateQuoteCustomer({
        quotePackageID: jobLotPackageId,
        quotePackageName,
        creatorID,
        fname : firstName,
        lname: lastName,
        email,
        ph1: String(phone).slice(0, 3),
        ph2: String(phone).slice(3, 6),
        ph3: String(phone).slice(6, 10),
        ph4: ext,
        phone2: "",
        companyName,
        creatorType,
        commPrefer,
        showQRCodeOnPackingSlip,
      }).then(() => {
        getCustomerQuoteInfo(jobLotPackageId).then((res) => {
          dispatch(setPackageInfo(res));
          handleClose();
        })
      });
    },
  });
  const handleKeyDown = (e) => {
    // Prevent form submission on Enter key press
    if (e.keyCode === keys.enter) {
      e.preventDefault();
    } else {
      e.stopPropagation();
    }
  };

  const { errors, touched, handleSubmit, resetForm, setFieldValue, values , isSubmitting , setSubmitting , dirty} = formik;

  const handleClose = () => { setShow(false); setSubmitting(false); resetForm(); };
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <PosModel
        open={open}
        onClose={handleCloseModel}
        className={`set-customer-code-modal`}
        dialogClassName={`set-customer-code-modal-container`}
        dialogHeader={
          <div className="modal-header pl-4">
            <span className="modal-title">
              {show ? <div className="pt-2"> <h3 className="f20-Inter-500-sab mb-0">{editCustomerInfo}</h3> </div> : <div className="pt-2"><h3 className="f20-Inter-500 mb-0">{customer}
              </h3>
                <h3 className="f20-Inter-500-sab">{companyName}</h3></div>}
            </span>
            <IconButton
              name='close'
              className="close-icon"
              type='tertiary'
              onClick={show ? handleClose : handleCloseModel}
            />
          </div>
        }>
        {show ? (
          <FormikProvider value={formik}>
            <Form id="linkQuoteRecipientForm">
              <div onKeyDown={handleKeyDown}>
              <div className="mb-4">
                <div className="notes-added-user-name">
                  <span>
                    {recipientName}
                  </span>
                </div>
                <div className="characteristics-main-info">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={"quotePackageName"}
                      name={"quotePackageName"}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      autoFocus
                      className={`product-detail-search-input input-focus-styling-sb ${errors.quotePackageName ? 'error' : ''}`}
                      value={values.quotePackageName}
                      type="text"
                      onChange={(e) => {
                        setFieldValue("quotePackageName", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <span className="desc-for-role">
                  {recipientMessage}
                </span>
                <br />
                {touched.quotePackageName && errors.quotePackageName ? (
                  <span className="validation-error-text">
                    {errors.quotePackageName}
                  </span>
                ) : null}
              </div>
              <div className="mb-4">
                <div className="notes-added-user-name">
                  <span>
                    {company}
                  </span>
                </div>
                <div className="characteristics-main-info">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={"companyName"}
                      name={"companyName"}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      className={`product-detail-search-input input-focus-styling-sb ${errors.companyName ? 'error' : ''}`}
                      type="text"
                      onChange={(e) => {
                        setFieldValue("companyName", e.target.value);
                      }}
                      value={values.companyName}
                    />
                  </div>
                </div>
                {touched.companyName && errors.companyName ? (
                  <span className="validation-error-text">
                    {errors.companyName}
                  </span>
                ) : null}
              </div>
              <div className="mb-4">
                <div className="notes-added-user-name">
                  <span>
                    {contactName}
                  </span>
                </div>
                <div className="characteristics-main-info">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={"fname"}
                      name={"fname"}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      className={`product-detail-search-input input-focus-styling-sb ${errors.fname ? 'error' : ''}`}
                      type="text"
                      onChange={(e) => {
                        setFieldValue("fname", e.target.value);
                      }}
                      value={values.fname}
                    />
                  </div>
                </div>
                {touched.fname && errors.fname ? (
                  <span className="validation-error-text">
                    {errors.fname}
                  </span>
                ) : null}
              </div>
              <div className="mb-4">
                <div className="notes-added-user-name">
                  <span>
                    {email}
                  </span>
                </div>
                <div className="characteristics-main-info">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={"email"}
                      name={"email"}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      className={`product-detail-search-input input-focus-styling-sb ${errors.email ? 'error' : ''}`}
                      type="text"
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                      value={values.email}
                    />
                  </div>
                </div>
                {touched.email && errors.email ? (
                  <span className="validation-error-text">
                    {errors.email}
                  </span>
                ) : null}
              </div>
              <div className="mb-1 masked-phone-number-group">
                <div>
                  <div className="notes-added-user-name">
                    <span>
                      {phone}
                    </span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100 masked-phone-number ">
                      <MaskInput
                        isClassName={`masked-phone-number-input mb-0 tabInput-mobile-sb ${errors.phone ? 'error' : ''}`}
                        mask="999 999 9999"
                        id={"filled-basic"}
                        name={"phone"}
                        alwaysShowMask={true}
                        onChange={(e) => {
                          setFieldValue("phone", e.target.value);
                        }}
                        onKeyPress={(e) => blockInvalidCharForPhone(e)}
                        value={values.phone}
                      />
                    </div>
                  </div>
                </div>
                <div className="ext-input">
                  <div className="notes-added-user-name">
                    <span>
                      {extTitle}
                    </span>
                  </div>
                  <div>
                    <PosInput
                      variant="filled"
                      id={"ext"}
                      name={"ext"}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      className={`product-detail-search-input input-focus-styling-sb ${errors.ext ? 'error' : ''}`}
                      type="number"
                      value={values.ext}
                      onChange={(e) => {
                        setFieldValue("ext", e.target.value);
                      }}
                      inputProps={{ maxLength: 4 }}
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                    />
                  </div>
                </div>
              </div>
              {touched.phone && errors.phone ? (
                <span className="validation-error-text">
                  {errors.phone}
                </span>
              ) : null}
              </div>
              <div className="edit_cust_btn">
                <div className="border-bottom-lightgray border-bottom border-md mb-2"></div>
                  <Button
                    label={cancel}
                    type='secondary'
                    onClick={handleClose}
                    disabled={isSubmitting}
                  />
                  <Button
                    label={saveChanges}
                    type='primary'
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={!dirty || isSubmitting}
                  />
              </div>
            </Form>
          </FormikProvider>
        ) : <CustomerInfo custInfo={packageInfoData} handleShow={handleShow} jobLotQuoteID={jobLotQuoteID} httpsUrl={httpsUrl} jobLotPackageId={jobLotPackageId} />}
      </PosModel>
    </Fragment>
  );
}