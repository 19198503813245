import { makeStyles, Theme } from "@material-ui/core";

const useStyle = makeStyles<Theme>(() => ({
  dataGrid: {
    "&.MuiDataGrid-root": {
      border: "none",
      "& .MuiDataGrid-columnHeader": {
        "&:focus": {
          outline: "none",
        },
      },
      "& .MuiDataGrid-cell" : {
        border: "none",
      },
      "& .MuiDataGrid-cell:focus": {
        outline: "none",
      },
    },
  },
}));

export default useStyle;