import { makeStyles } from "@material-ui/core/styles";

export const headCells = [
  {
    id: "createdAt",
    disablePadding: true,
    label: "DATE",
    left: true,
    class: "inv-date-col",
  },
  {
    id: "takerName",
    disablePadding: false,
    label: "CHANGED BY",
    left: true,
    class: "customer-col-width",
  },
  {
    id: "orderId",
    disablePadding: false,
    label: "ORDER #",
    left: true,
  },
  {
    id: "vendorPrice", 
    disablePadding: false,
    label: "VENDOR PRICE",
    left: true,
  },
  {
    id: "initialPosRetailPrice",
    disablePadding: false,
    label: "OLD POS RETAIL PRICE",
    left: true,
  },
  {
    id: "arrow",
    disablePadding: false,
    label: "",
    left: false,
  },
  {
    id: "finalPosRetailPrice",
    disablePadding: false,
    label: "NEW POS RETAIL PRICE",
    left: true,
  },
  {
    id:"percentage",
    disablePadding : false,
    label:"",
    left:true
  }
];

export const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  nextIcon: {
    color: "#000000",
  },
}));
