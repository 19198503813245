import uploading_gif from "apps/pos/assets/images/uploading.gif";
import { PosButton, PosIconButton, PosModel } from "apps/pos/Common/uiCore";
import { ClearIcon } from "constants/icons";
import FileUploadProgress from "./FileUploadProgress";

const UploadDocumentComponent = ({ openModel, isDeliveryProofModal, progress, timeRemaining, cancelRequest, fileName }) => {

    // Format time remaining into minutes and seconds
    const formatTimeRemaining = (seconds) => {
        const remainingSeconds = Math.floor(seconds % 60);
        if (remainingSeconds < 60) {
            return "Less than a minute remaining";
        } else if (remainingSeconds >= 3600) {
            return `${Math.round(remainingSeconds / 3600)} hours remaining`;
        } else {
            return `${Math.round(remainingSeconds / 60)} minutes remaining`;
        }
    };

    return (
        <PosModel
            dialogClassName={`${isDeliveryProofModal ? 'delivery-proof-add-doc-modal' : ''} add-document-upload-modal`}
            open={openModel}
            onClose={() => {
                return false;
            }}
        >
            <div className="add-document-container d-flex">
                <div className="title-modal">{`${isDeliveryProofModal ? "Add Customer Signed Delivery Proof" : "Add Document"}`}</div>
                <PosIconButton
                    className="clear-icon button-focus"
                    onClick={cancelRequest}
                >
                    <ClearIcon />
                </PosIconButton>

            </div>
            <div className="upload-document-progress-container">
                <img src={uploading_gif} className="uploading-gif" />
                <FileUploadProgress progress={progress} />
                <div className="d-flex flex-column">
                    <p className="title-upload-progress mb-0">Uploading {fileName?.[0]?.name}</p>
                    <p className="show-remaining-time">{`${formatTimeRemaining(timeRemaining)}`}</p>
                </div>

                <div className="add-button d-flex justify-content-center">
                    <PosButton
                        variant="contained"
                        color="secondary"
                        onClick={cancelRequest}
                    >
                        Cancel
                    </PosButton>
                </div>

            </div>
        </PosModel>
    );
}

export default UploadDocumentComponent;