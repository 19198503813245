import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Drawer } from "@material-ui/core";
import { drawerUseStyles } from "theme/GlobalStyles";
import "@fontsource/inter";

import SelectTakerModal from "../SelectTakerModal/SelectTakerModal";
import PricingScheduleModal from "../PricingModal";
import SavedOrdersFound from "../SavedOrdersFound";
import AddNewCustomer from "../AddNewCustomer/AddNewCustomer";
import Button from "../Common/Button/Button";
import { PosResponseProps, RootState } from "models";

import { savedDraftsRequest, savedDraftsReset } from "sagas/pos/actions";
import { addNewCustomerClear } from "sagas/persistReducer/actions";

import "./drawers.scss";

const NewOrderDrawer = ({
  setDraftCartModal,
  draftCartModal,
  open,
  drawerClass,
  state,
  addCustomerFormModal,
  setState,
  openTaker,
  customerPricingScheduleRef,
  newOrderShippingAddress,
  onCloseDrawer,
  savedOrderFoundInfoRef,
  setSelectSchedule = () => {
    // Required Later
  },
}) => {
  const { savedDrafts } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );

  const { cartLoading } = useSelector<RootState, PosResponseProps>(
    (state) => state.persistReducer
  );
  const classes = drawerUseStyles();

  const dispatch = useDispatch();
  const [isDeleteClick, setIsDeleteClick] = useState(false)
  const onlyScheduleData =
    !cartLoading &&
    !draftCartModal &&
    !savedDrafts?.data?.length &&
    !addCustomerFormModal &&
    !openTaker;

  return (
    <Drawer
      anchor={"right"}
      open={open}
      hideBackdrop={false}
      className={`${drawerClass} ${classes.drawer} default-drawer-style no-cart-drawer main-drawer-classes drawer-scrollbar-style saved-draft-list-new`}
      ref={customerPricingScheduleRef}
      onClose={async (_,reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          const draftData = {
            stockWarehouseId: JSON.parse(
              await localStorage.getItem("workspace")
            ).stockWarehouseId,
            buyerId: null,
          };
          if (draftCartModal && !savedOrderFoundInfoRef.current) {
            dispatch(savedDraftsRequest(draftData));
          }
        }else{
          if(isDeleteClick){
              setIsDeleteClick(false);
            }else{
              setState({ ...state, right: false });
              dispatch(addNewCustomerClear());
              dispatch(savedDraftsReset());
              setDraftCartModal(false);
            }
        }
      }}
      
      // need for check in next PR
      // onClose={async () => {
      //   const draftData = {
      //     stockWarehouseId: JSON.parse(await localStorage.getItem("workspace"))
      //       .stockWarehouseId,
      //     buyerId: null,
      //   };
      //   if (draftCartModal) {
      //     dispatch(savedDraftsRequest(draftData));
      //   }
      // }}
      id="cartDrawerContent"
    >
      <Box sx={{ width: 600 }} role="presentation">
        <Button
          classes="back-next-btn-small back-responsive-btn responsive-back-btn"
          tabIndex={-1}
          onClick={() => {
            if(isDeleteClick){
              setIsDeleteClick(false);
            }else{
              setState({ ...state, right: false });
              dispatch(addNewCustomerClear());
              dispatch(savedDraftsReset());
              setDraftCartModal(false);
            }
          }}
        >
          <i className="fas fa-arrow-left"></i>
        </Button>

        {addCustomerFormModal ? (
          <AddNewCustomer state={state} setState={setState} />
        ) : draftCartModal && savedDrafts?.data?.length ? (
          <SavedOrdersFound
            state={state}
            setState={setState}
            savedOrderFoundInfoRef={savedOrderFoundInfoRef}
            setIsDeleteClick={setIsDeleteClick}
            isDeleteClick={isDeleteClick}
          />
        ) : openTaker ? (
          <SelectTakerModal newOrderTakerDrawer state={state} setState={setState} />
        ) : (
          onlyScheduleData && (
            <PricingScheduleModal
              selectSchedule={false}
              state={state}
              setState={setState}
              showTaker={true}
              newOrderShippingAddress={newOrderShippingAddress}
              onCloseDrawer={onCloseDrawer}
              setSelectSchedule={setSelectSchedule}
            />
          )
        )}
      </Box>
    </Drawer>
  );
};

export default NewOrderDrawer;
