import { MouseEventHandler, ReactNode, TouchEventHandler } from "react";
import styled from "styled-components";

export interface ButtonProps {
  children?: ReactNode;
  form?: string;
  className?: string;
  style?: any;
  buttonStyle?: "light" | "dark" | "link" | "btn-primary";
  type?: "button" | "submit" | "reset";
  icon?: boolean;
  iconName?: string;
  label?: string | JSX.Element;
  transparent?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  name?: string;
  isClassName?: boolean;
  title?: string | undefined;
  onTouchStart?: TouchEventHandler<any> | undefined;
  onMouseOver?: MouseEventHandler<any> | undefined;
  tabIndex?:number
}

export interface AnchorButtonProps {
  children?: ReactNode;
  target?: string;
  href: string;
  className?: string;
  buttonStyle?: "light" | "dark" | "link" | "btn-primary";
  icon?: boolean;
  iconName?: string;
  label?: string;
  transparent?: boolean;
  onClick?: () => void;
  download?: boolean;
}

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  button {
    width: 100%;
  }
`;

export const ButtonContainer2 = styled.div`
  display: flex;
  justify-content: center;
  button {
    width: 100%;
  }
`;

export const ButtonContainerSBbuttons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export function Button({
  form,
  children,
  className,
  type = "button",
  label,
  icon = false,
  iconName,
  style,
  buttonStyle = "btn-primary",
  transparent = false,
  onClick,
  disabled,
  isClassName = true,
  title,
  onTouchStart,
  onMouseOver,
  tabIndex,
  ...props
}: ButtonProps) {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`${isClassName && "btn"}inter-med ${icon ? "btn-icon" : ""} ${transparent ? "-transparent" : ""
        }-${buttonStyle} ${className ? className : ""}`}
      onClick={onClick}
      style={style}
      title={title}
      onTouchStart={onTouchStart}
      onMouseOver={onMouseOver}
      tabIndex={tabIndex}
      {...(form ? { form } : {})}
      // disabled={name === "save" ? disabled : false}
      {...props}
    >
      {label}
      {iconName && <i className={`fas fa-${iconName}`} />}
      {children}
    </button>
  );
}

export function AnchorButton({
  href,
  children,
  className,
  label,
  icon = false,
  iconName,
  buttonStyle = "dark" || "btn-primary",
  transparent = false,
  onClick,
  download,
  ...props
}: AnchorButtonProps) {
  return (
    <a
      {...(download ? { download: "" } : {})}
      target="_blank"
      rel="noreferrer"
      href={href}
      className={`btn ${icon ? "btn-icon" : ""} btn${transparent ? "-transparent" : ""
        }-${buttonStyle} ${className ? className : ""}`}
      onClick={onClick}
      {...props}
    >
      {children}
      {label}
      {iconName && <i className={`fas ${iconName}`} />}
    </a>
  );
}

export function AdminButton({
  form,
  className,
  type = "button",
  label,
  style,
  onClick,
  disabled,
  title,
  onTouchStart,
  onMouseOver,
  ...props
}: ButtonProps) {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`${className ? className : ""}`}
      onClick={onClick}
      style={style}
      title={title}
      onTouchStart={onTouchStart}
      onMouseOver={onMouseOver}
      {...(form ? { form } : {})}
      {...props}
    >
      <div className="button-label">{label}</div>
    </button>
  );
}