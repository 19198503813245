import { RecentCustomersRequest } from "apps/pos/models/pos";
import { DocumentTypeDetailProps } from "models";
import * as constants from "./constants";

export function workStationsRequest() {
  return {
    type: constants.GET_WORKSTATIONS_START,
  };
}

export function workStationsSuccess(payload: any) {
  return {
    type: constants.GET_WORKSTATIONS_SUCCESS,
    payload,
  };
}

export function workStationsError(error: any) {
  return {
    type: constants.GET_WORKSTATIONS_ERROR,
    error,
  };
}


export function addNewProductRequest(payload: any) {
  return {
    type: constants.ADD_NEW_PRODUCT_START,
    payload,
  };
}

export function addNewProductSuccess(payload: any) {
  return {
    type: constants.ADD_NEW_PRODUCT_SUCCESS,
    payload,
  };
}

export function addNewProductError(error: any) {
  return {
    type: constants.ADD_NEW_PRODUCT_ERROR,
    error,
  };
}

export function addNewProductClear() {
  return {
    type: constants.ADD_NEW_PRODUCT_CLEAR,
  };
}

export function priceSchedulesRequest(payload: any) {
  return {
    type: constants.GET_PRICESCHEDULES_START,
    payload,
  };
}

export function priceSchedulesSuccess(payload: any) {
  return {
    type: constants.GET_PRICESCHEDULES_SUCCESS,
    payload,
  };
}

export function priceSchedulesError(error: any) {
  return {
    type: constants.GET_PRICESCHEDULES_ERROR,
    error,
  };
}

export function savedDraftsRequest(payload: any) {
  return {
    type: constants.GET_SAVED_DRAFTS_START,
    payload,
  };
}

export function savedDraftsSuccess(payload: any) {
  return {
    type: constants.GET_SAVED_DRAFTS_SUCCESS,
    payload,
  };
}

export function savedDraftsError(error: any) {
  return {
    type: constants.GET_SAVED_DRAFTS_ERROR,
    error,
  };
}

export function savedDraftsReset() {
  return {
    type: constants.GET_SAVED_DRAFTS_RESET,
  };
}

export function takersRequest() {
  return {
    type: constants.GET_TAKERS_START,
  };
}

export function takersSuccess(payload: any) {
  return {
    type: constants.GET_TAKERS_SUCCESS,
    payload,
  };
}

export function takersError(error: any) {
  return {
    type: constants.GET_TAKERS_ERROR,
    error,
  };
}

export function salespersonsRequest() {
  return {
    type: constants.GET_SALESPERSONS_START,
  };
}

export function salespersonsSuccess(payload: any) {
  return {
    type: constants.GET_SALESPERSONS_SUCCESS,
    payload,
  };
}

export function salespersonsError(error: any) {
  return {
    type: constants.GET_SALESPERSONS_ERROR,
    error,
  };
}

export function recentCustomersRequest(payload: RecentCustomersRequest) {
  return {
    type: constants.GET_RECENT_CUSTOMERS_START,
    payload,
  };
}

export function recentCustomersSuccess(payload: any) {
  return {
    type: constants.GET_RECENT_CUSTOMERS_SUCCESS,
    payload,
  };
}

export function recentCustomersError(error: any) {
  return {
    type: constants.GET_RECENT_CUSTOMERS_ERROR,
    error,
  };
}

export function recentCustomersClear() {
  return {
    type: constants.GET_RECENT_CUSTOMERS_CLEAR,
  };
}

export function productsRequest(payload: any, updatePrice = undefined, initialCustomPrice = undefined,) {
  return {
    type: constants.GET_PRODUCTS_START,
    payload,
    updatePrice,
    initialCustomPrice
  };
}

export function productsSuccess(payload: any) {
  return {
    type: constants.GET_PRODUCTS_SUCCESS,
    payload,
  };
}

export function productsError(error: any) {
  return {
    type: constants.GET_PRODUCTS_ERROR,
    error,
  };
}

export function productsDataClear() {
  return {
    type: constants.GET_PRODUCTS_DATA_CLEAR,
  };
}

export function editProduct(payload: any) {
  return {
    type: constants.EDIT_PRODUCT,
    payload,
  };
}

export function suggestedProductsRequest(payload: any) {
  return {
    type: constants.GET_SUGGESTED_PRODUCTS_START,
    payload,
  };
}

export function suggestedProductsSuccess(payload: any) {
  return {
    type: constants.GET_SUGGESTED_PRODUCTS_SUCCESS,
    payload,
  };
}

export function suggestedProductsError(error: any) {
  return {
    type: constants.GET_SUGGESTED_PRODUCTS_ERROR,
    error,
  };
}

export function suggestedProductsClear() {
  return {
    type: constants.GET_SUGGESTED_PRODUCTS_CLEAR,
  };
}

export function getFacilityTypesRequest() {
  return {
    type: constants.GET_FACILITY_TYPES_START,
  };
}

export function getFacilityTypesSuccess(payload: any) {
  return {
    type: constants.GET_FACILITY_TYPES_SUCCESS,
    payload,
  };
}

export function getFacilityTypesError(error: any) {
  return {
    type: constants.GET_FACILITY_TYPES_ERROR,
    error,
  };
}

export function getJobDescriptionRequest() {
  return {
    type: constants.GET_JOB_DESCRIPTION_START,
  };
}

export function getJobDescriptionSuccess(payload: any) {
  return {
    type: constants.GET_JOB_DESCRIPTION_SUCCESS,
    payload,
  };
}

export function getJobDescriptionError(error: any) {
  return {
    type: constants.GET_JOB_DESCRIPTION_ERROR,
    error,
  };
}

export function getStatesRequest() {
  return {
    type: constants.GET_STATES_START,
  };
}

export function getStatesSuccess(payload: any) {
  return {
    type: constants.GET_STATES_SUCCESS,
    payload,
  };
}

export function getStatesError(error: any) {
  return {
    type: constants.GET_STATES_ERROR,
    error,
  };
}

export function getShiptoFacilitiesRequest(payload: any) {
  return {
    type: constants.GET_SHIPTO_FACILITIES_START,
    payload,
  };
}

export function getShiptoFacilitiesSuccess(payload: any) {
  return {
    type: constants.GET_SHIPTO_FACILITIES_SUCCESS,
    payload,
  };
}

export function getShiptoFacilitiesError(error: any) {
  return {
    type: constants.GET_SHIPTO_FACILITIES_ERROR,
    error,
  };
}

export function getShippingFacilitiesRequest(payload: any) {
  return {
    type: constants.GET_SHIPPING_FACILITIES_START,
    payload,
  };
}

export function getShippingFacilitiesSuccess(payload: any) {
  return {
    type: constants.GET_SHIPPING_FACILITIES_SUCCESS,
    payload,
  };
}

export function getShippingFacilitiesClear() {
  return {
    type: constants.GET_SHIPPING_FACILITIES_CLEAR,
  };
}

export function getShippingFacilitiesError(error: any) {
  return {
    type: constants.GET_SHIPPING_FACILITIES_ERROR,
    error,
  };
}

// start
export function getSalesHistoryRequest(payload: any) {
  return {
    type: constants.GET_SALES_HISTORY_START,
    payload,
  };
}

export function getSalesHistorySuccess(payload: any) {
  return {
    type: constants.GET_SALES_HISTORY_SUCCESS,
    payload,
  };
}

export function getSalesHistoryError(error: any) {
  return {
    type: constants.GET_SALES_HISTORY_ERROR,
    error,
  };
}

export function getUserPriceScheduleDataRequest(payload: any) {
  return {
    type: constants.USER_PRICE_SCHEDULE_DATA_REQUEST,
    payload,
  };
}

export function getUserPriceScheduleDataSuccess(payload: any) {
  return {
    type: constants.USER_PRICE_SCHEDULE_DATA_SUCCESS,
    payload,
  };
}

export function getUserPriceScheduleDataError(error: any) {
  return {
    type: constants.USER_PRICE_SCHEDULE_DATA_ERROR,
    error,
  };
}
// end

export function addShippingFacilitiesRequest(payload: any, customerId: number) {
  return {
    type: constants.ADD_SHIPPING_FACILITIES_START,
    payload,
    customerId,
  };
}

export function addShippingFacilitiesSuccess(payload: any) {
  return {
    type: constants.ADD_SHIPPING_FACILITIES_SUCCESS,
    payload,
  };
}

export function addShippingFacilitiesError(error: any) {
  return {
    type: constants.ADD_SHIPPING_FACILITIES_ERROR,
    error,
  };
}

export function addShippingFacilitiesClear() {
  return {
    type: constants.ADD_SHIPPING_FACILITIES_CLEAR,
  };
}

export function addCustomersRequest(payload: any) {
  return {
    type: constants.SEARCH_CUSTOMERS_START,
    payload,
  };
}

export function addCustomersSuccess(payload: any) {
  return {
    type: constants.SEARCH_CUSTOMERS_SUCCESS,
    payload,
  };
}

export function addCustomersError(error: string) {
  return {
    type: constants.SEARCH_CUSTOMERS_ERROR,
    error,
  };
}

export function returnCustomersRequest(payload: any) {
  return {
    type: constants.SEARCH_RETURN_CUSTOMERS_START,
    payload,
  };
}

export function returnCustomersSuccess(payload: any) {
  return {
    type: constants.SEARCH_RETURN_CUSTOMERS_SUCCESS,
    payload,
  };
}

export function returnCustomersError(error: string) {
  return {
    type: constants.SEARCH_RETURN_CUSTOMERS_ERROR,
    error,
  };
}

export function resetAddCustomersError(error: string) {
  return {
    type: constants.RESET_SEARCH_CUSTOMERS_ERROR,
    error,
  };
}

export function clearSearchCustomer() {
  return {
    type: constants.CLEAR_SEARCH_CUSTOMER,
  };
}

export function searchProductRequest(payload: any) {
  return {
    type: constants.SEARCH_PRODUCTS_START,
    payload,
  };
}

export function searchProductSuccess(payload: any) {
  return {
    type: constants.SEARCH_PRODUCTS_SUCCESS,
    payload,
  };
}

export function searchProductError(error: string) {
  return {
    type: constants.SEARCH_PRODUCTS_ERROR,
    error,
  };
}

export function searchReturnProductRequest(payload: any) {
  return {
    type: constants.SEARCH_RETURN_PRODUCTS_START,
    payload,
  };
}

export function searchReturnProductSuccess(payload: any) {
  return {
    type: constants.SEARCH_RETURN_PRODUCTS_SUCCESS,
    payload,
  };
}

export function searchReturnProductError(error: string) {
  return {
    type: constants.SEARCH_RETURN_PRODUCTS_ERROR,
    error,
  };
}

export function clearSearchProduct() {
  return {
    type: constants.CLEAR_SEARCH_PRODUCT,
  };
}

export function addPriceSchedulesRequest(payload: any) {
  return {
    type: constants.ADD_PRICE_SCHEDULE_START,
    payload,
  };
}

export function addPriceSchedulesSuccess(payload: any) {
  return {
    type: constants.ADD_PRICE_SCHEDULE_SUCCESS,
    payload,
  };
}

export function addPriceSchedulesError(error: any) {
  return {
    type: constants.ADD_PRICE_SCHEDULE_ERROR,
    error,
  };
}

export function updatePriceSchedulesStart(payload: any) {
  return {
    type: constants.UPDATE_PRICE_SCHEDULE_START,
    payload,
  };
}

export function updatePriceSchedulesSuccess(payload: any) {
  return {
    type: constants.UPDATE_PRICE_SCHEDULE_SUCCESS,
    payload,
  };
}

export function updatePriceSchedulesError(error: any) {
  return {
    type: constants.UPDATE_PRICE_SCHEDULE_ERROR,
    error,
  };
}

export function stockByWarehouseStart(payload: any) {
  return {
    type: constants.STOCK_BY_WAREHOUSE_START,
    payload,
  };
}

export function stockByWarehouseSuccess(payload: any) {
  return {
    type: constants.STOCK_BY_WAREHOUSE_SUCCESS,
    payload,
  };
}

export function stockByWarehouseError(error: any) {
  return {
    type: constants.STOCK_BY_WAREHOUSE_ERROR,
    error,
  };
}

export function stockByWarehouseClear() {
  return {
    type: constants.STOCK_BY_WAREHOUSE_CLEAR,
  };
}
//cart page

export function cartSubmitRequest(payload: any, cartId: number) {
  return {
    type: constants.CART_SUBMIT_START,
    payload,
    cartId
  };
}

// check invoice refresh

export function checkInvicePageRefresh(payload: any) {
  return { type: constants.CHECK_PAGE_REFRESH, payload };
}

export function cartSubmitSuccess(payload: any) {
  return {
    type: constants.CART_SUBMIT_SUCCESS,
    payload,
  };
}

export function cartSubmitError(error: any) {
  return {
    type: constants.CART_SUBMIT_ERROR,
    error,
  };
}

export function cartSubmitClear() {
  return {
    type: constants.CART_SUBMIT_CLEAR,
  };
}

export function cartPrintRequest(payload: any) {
  return {
    type: constants.CART_PRINT_START,
    payload,
  };
}

export function cartPrintSuccess(payload: any) {
  return {
    type: constants.CART_PRINT_SUCCESS,
    payload,
  };
}

export function cartPrintError(error: any) {
  return {
    type: constants.CART_PRINT_ERROR,
    error,
  };
}

export function cartPrintClear() {
  return {
    type: constants.CART_PRINT_ERROR,
  };
}

export function cartPrintWithTotalsRequest(payload: any) {
  return {
    type: constants.CART_PRINT_WITH_TOTALS_START,
    payload,
  };
}

export function cartPrintWithTotalsSuccess(payload: any) {
  return {
    type: constants.CART_PRINT_WITH_TOTALS_SUCCESS,
    payload,
  };
}

export function cartPrintWithTotalsError(error: any) {
  return {
    type: constants.CART_PRINT_WITH_TOTALS_ERROR,
    error,
  };
}

export function cartPrintWithTotalsClear() {
  return {
    type: constants.CART_PRINT_WITH_TOTALS_CLEAR,
  };
}

export function cartDraftRequest(payload: any, cartId: number) {
  return {
    type: constants.CART_DRAFT_START,
    payload,
    cartId,
  };
}

export function cartDraftSuccess(payload: any) {
  return {
    type: constants.CART_DRAFT_SUCCESS,
    payload,
  };
}

export function cartDraftError(error: any) {
  return {
    type: constants.CART_DRAFT_ERROR,
    error,
  };
}

export function cartDraftClear() {
  return {
    type: constants.CART_DRAFT_CLEAR,
  };
}

export function getWarehourseLocationsRequest(payload: any) {
  return {
    type: constants.GET_WAREHOUSE_LOCATIONS_START,
    payload,
  };
}

export function getWarehourseLocationsSuccess(payload: any) {
  return {
    type: constants.GET_WAREHOUSE_LOCATIONS_SUCCESS,
    payload,
  };
}

export function getWarehourseLocationsError(error: any) {
  return {
    type: constants.GET_WAREHOUSE_LOCATIONS_ERROR,
    error,
  };
}

export function getWarehouseLocationsClear() {
  return {
    type: constants.GET_WAREHOUSE_LOCATIONS_CLEAR,
  };
}

export function arrowDownIndex(payload) {
  return {
    type: constants.ARR0W_DOWN_INDEX,
    payload,
  };
}

export function arrowUpIndex() {
  return {
    type: constants.ARR0W_UP_INDEX,
  };
}
export function setSelectedIndex(payload) {
  return {
    type: constants.SET_SELECTED_INDEX,
    payload
  };
}

export function arrowReset() {
  return {
    type: constants.ARR0W_INDEX_RESET,
  };
}

export function arrowResetGeneric() {
  return {
    type: constants.ARR0W_INDEX_RESET_GENERIC,
  };
}
export function arrowUpIndexGeneric() {
  return {
    type: constants.ARR0W_UP_INDEX_GENERIC,
  };
}
export function arrowDownIndexGeneric(payload) {
  return {
    type: constants.ARR0W_DOWN_INDEX_GENERIC,
    payload,
  };
}

export function arrowDownIndexProductPage(payload) {
  return {
    type: constants.ARR0W_DOWN_INDEX_PRODUCT_PAGE,
    payload,
  };
}

export function setSelectedProductIndex(payload) {
  return {
    type: constants.SET_SELECTED_PRODUCT_INDEX,
    payload,
  };
}

export function arrowUpIndexProductPage() {
  return {
    type: constants.ARR0W_UP_INDEX_PRODUCT_PAGE,
  };
}

export function arrowResetProductPage() {
  return {
    type: constants.ARR0W_INDEX_RESET_PRODUCT_PAGE,
  };
}

export function arrowDraftDownIndex(payload) {
  return {
    type: constants.ARR0W_DRAFT_DOWN_INDEX,
    payload,
  };
}

export function arrowDraftUpIndex() {
  return {
    type: constants.ARR0W_DRAFT_UP_INDEX,
  };
}

export function arrowDraftReset() {
  return {
    type: constants.ARR0W_DRAFT_INDEX_RESET,
  };
}

export function resetNewAddress() {
  return { type: constants.RESET_NEW_ADDRESS };
}

export function getNewPrice(payload) {
  return {
    type: constants.GET_NEW_PRICE,
    payload,
  };
}

export function getupdateItemModal(payload) {
  return {
    type: constants.GET_UPDATE_ITEM_MODAL,
    payload,
  };
}

export function getIsGoToCart(payload) {
  return {
    type: constants.IS_GO_TO_CART,
    payload,
  };
}

export function notAddedNewProduct(payload) {
  return {
    type: constants.NOT_ADDED_NEW_PRODUCT,
    payload,
  };
}

export function categoriesRequest() {
  return {
    type: constants.GET_CATEGORIES_REQUEST,
  };
}
export function categoriesSuccess(payload: any) {
  return {
    type: constants.GET_CATEGORIES_SUCCESS,
    payload,
  };
}
export function categoriesError(error: any) {
  return {
    type: constants.GET_CATEGORIES_ERROR,
    error,
  };
}
export function newProductManufacturerRequest() {
  return {
    type: constants.GET_NEW_PRODUCT_MANUFACTURER_REQUEST,
  };
}
export function newProductManufacturerSuccess(payload: any) {
  return {
    type: constants.GET_NEW_PRODUCT_MANUFACTURER_SUCCESS,
    payload,
  };
}
export function newProductManufacturerError(error: any) {
  return {
    type: constants.GET_NEW_PRODUCT_MANUFACTURER_ERROR,
    error,
  };
}
export function newProductVendorRequest() {
  return {
    type: constants.GET_NEW_PRODUCT_VENDOR_REQUEST,
  };
}
export function newProductVendorSuccess(payload: any) {
  return {
    type: constants.GET_NEW_PRODUCT_VENDOR_SUCCESS,
    payload,
  };
}
export function newProductVendorError(error: any) {
  return {
    type: constants.GET_NEW_PRODUCT_VENDOR_ERROR,
    error,
  };
}
export function newProductGroupRequest() {
  return {
    type: constants.GET_NEW_PRODUCT_GROUP_REQUEST,
  };
}

export function newProductGroupSuccess(payload: any) {
  return {
    type: constants.GET_NEW_PRODUCT_GROUP_SUCCESS,
    payload,
  };
}
export function newProductGroupError(error: any) {
  return {
    type: constants.GET_NEW_PRODUCT_GROUP_ERROR,
    error,
  };
}
//Start Adjust Stock api
// Gets Rows
export function getRowsActiveRequest(payload: any) {
  return {
    type: constants.GET_ROWS_ACTIVE_START,
    payload,
  };
}
export function getRowsActiveSuccess(payload: any) {
  return {
    type: constants.GET_ROWS_ACTIVE_SUCCESS,
    payload,
  };
}
export function getRowsActiveError(error: any) {
  return {
    type: constants.GET_ROWS_ACTIVE_ERROR,
    error,
  };
}
export function getRowsActiveClear() {
  return {
    type: constants.GET_ROWS_ACTIVE_CLEAR,
  };
}
//Get Units
export function getUnitsActiveRequest(payload: any) {
  return {
    type: constants.GET_UNIT_ACTIVE_START,
    payload,
  };
}
export function getUnitsActiveSuccess(payload: any) {
  return {
    type: constants.GET_UNIT_ACTIVE_SUCCESS,
    payload,
  };
}
export function getUnitsActiveError(error: any) {
  return {
    type: constants.GET_UNIT_ACTIVE_ERROR,
    error,
  };
}
export function getUnitsActiveClear() {
  return {
    type: constants.GET_UNIT_ACTIVE_CLEAR,
  };
}
// Get Shelf
export function getShelfRequest(payload: any) {
  return {
    type: constants.GET_SHELF_START,
    payload,
  };
}
export function getShelfSuccess(payload: any) {
  return {
    type: constants.GET_SHELF_SUCCESS,
    payload,
  };
}
export function getShelfError(error: any) {
  return {
    type: constants.GET_SHELF_ERROR,
    error,
  };
}
export function getShelfClear() {
  return {
    type: constants.GET_SHELF_CLEAR,
  };
}

// Stock In
export function stockInRequest(payload: any) {
  return {
    type: constants.STOCK_IN_START,
    payload,
  };
}

export function stockInSuccess(payload: any) {
  return {
    type: constants.STOCK_IN_SUCCESS,
    payload,
  };
}
export function stockInError(error: any) {
  return {
    type: constants.STOCK_IN_ERROR,
    error,
  };
}
export function stockInClear() {
  return {
    type: constants.STOCK_IN_CLEAR,
  };
}
// Stock Out
export function stockOutRequest(payload: any) {
  return {
    type: constants.STOCK_OUT_START,
    payload,
  };
}
export function stockOutSuccess(payload: any) {
  return {
    type: constants.STOCK_OUT_SUCCESS,
    payload,
  };
}
export function stockOutError(error: any) {
  return {
    type: constants.STOCK_OUT_ERROR,
    error,
  };
}
export function stockOutClear() {
  return {
    type: constants.STOCK_OUT_CLEAR,
  };
}
// Stock Move
export function stockMoveRequest(payload: any) {
  return {
    type: constants.STOCK_MOVE_START,
    payload,
  };
}
export function stockMoveSuccess(payload: any) {
  return {
    type: constants.STOCK_MOVE_SUCCESS,
    payload,
  };
}
export function stockMoveError(error: any) {
  return {
    type: constants.STOCK_MOVE_ERROR,
    error,
  };
}
export function stockMoveClear() {
  return {
    type: constants.STOCK_MOVE_CLEAR,
  };
}
export function disableAdjustStockTabs(payload) {
  return {
    type: constants.DISABLE_ADJUST_STOCK_TAB,
    payload,
  };
}
// End Adjust Stock api
// Start Item Ledger pagination
export function itemLedgerRequest(payload: any) {
  return {
    type: constants.ITEM_LEDGER_START,
    payload,
  };
}
export function itemLedgerSuccess(payload: any) {
  return {
    type: constants.ITEM_LEDGER_SUCCESS,
    payload,
  };
}
export function itemLedgerError(error: any) {
  return {
    type: constants.ITEM_LEDGER_ERROR,
    error,
  };
}
export function itemLedgerClear() {
  return {
    type: constants.ITEM_LEDGER_CLEAR,
  };
}
// End Item Ledger

//Start From location product
export function fromLocationProductRequest(payload: any) {
  return {
    type: constants.FROM_LOCATION_PRODUCT_START,
    payload,
  };
}
export function fromLocationProductSuccess(payload: any) {
  return {
    type: constants.FROM_LOCATION_PRODUCT_SUCCESS,
    payload,
  };
}
export function fromLocationProductError(error: any) {
  return {
    type: constants.FROM_LOCATION_PRODUCT_ERROR,

    error,
  };
}
export function fromLocationProductClear() {
  return {
    type: constants.FROM_LOCATION_PRODUCT_CLEAR,
  };
}
// End From location product
// Start From location Row
export function fromLocationRowRequest(payload: any) {
  return {
    type: constants.FROM_LOCATION_ROW_START,
    payload,
  };
}
export function fromLocationRowSuccess(payload: any) {
  return {
    type: constants.FROM_LOCATION_ROW_SUCCESS,
    payload,
  };
}
export function fromLocationRowError(error: any) {
  return {
    type: constants.FROM_LOCATION_ROW_ERROR,

    error,
  };
}
export function fromLocationRowClear() {
  return {
    type: constants.FROM_LOCATION_ROW_CLEAR,
  };
}
// End From location Row
// Start From location Unit
export function fromLocationUnitRequest(payload: any) {
  return {
    type: constants.FROM_LOCATION_UNIT_START,
    payload,
  };
}
export function fromLocationUnitSuccess(payload: any) {
  return {
    type: constants.FROM_LOCATION_UNIT_SUCCESS,
    payload,
  };
}
export function fromLocationUnitError(error: any) {
  return {
    type: constants.FROM_LOCATION_UNIT_ERROR,

    error,
  };
}
export function fromLocationUnitClear() {
  return {
    type: constants.FROM_LOCATION_UNIT_CLEAR,
  };
}
// End From location Unit
// Start From location Shelf
export function fromLocationShelfRequest(payload: any) {
  return {
    type: constants.FROM_LOCATION_SHELF_START,
    payload,
  };
}
export function fromLocationShelfSuccess(payload: any) {
  return {
    type: constants.FROM_LOCATION_SHELF_SUCCESS,
    payload,
  };
}
export function fromLocationShelfError(error: any) {
  return {
    type: constants.FROM_LOCATION_SHELF_ERROR,

    error,
  };
}
export function fromLocationShelfClear() {
  return {
    type: constants.FROM_LOCATION_SHELF_CLEAR,
  };
}
// End From location Row

export function getDocumentTypesRequest(payload: boolean) {
  return {
    type: constants.DOCUMENT_TYPES_REQUEST,
    payload,
  };
}

export function getDocumentTypesSuccess(payload: DocumentTypeDetailProps[]) {
  return {
    type: constants.DOCUMENT_TYPES_SUCCESS,
    payload,
  };
}

export function getDocumentTypesError(error: any) {
  return {
    type: constants.DOCUMENT_TYPES_ERROR,
    error,
  };
}


// Show API Failed snackbar 
export function apiFiledRequest(payload: { state: boolean, message: string, multiMessage?: any }) {
  return {
    type: constants.API_FAILED_START,
    payload,
  };
}

// Product data 
export function setChangeCartPrice(payload: number) {
  return {
    type: constants.SET_CHANGE_PRICE,
    payload,
  };
}

export function setInitialCustomPrice(payload: number) {
  return {
    type: constants.SET_INITIAL_CUSTOM_PRICE,
    payload,
  };
}

export function setHeaderHeightCount(payload: number) {
  return {
    type: constants.HEADER_HEIGHT_COUNT,
    payload,
  };
}

// Disabled enter functionality
export function enterDisableAddToCart(payload: boolean) {
  return {
    type: constants.DISABLE_ENTER_ADD_TO_CART,
    payload,
  };
}
export function addToCartDrawer(payload: boolean) {
  return {
    type: constants.ADD_TO_CART_DRAWER,
    payload,
  };
}

// Product Data Update by adjustStock
export function setAdjustStockByProduct() {
  return {
    type: constants.SET_ADJUST_STOCK_UPDATE_PRODUCT,
  };
}

export function getTabShip(payload: number) {
  return {
    type: constants.GET_TAB_SHIPPING,
    payload,
  };
}

export function getProductIndexNumber(payload: number) {
  return {
    type: constants.GET_PRODUCT_INDEX,
    payload,
  };
}

export function setCancelPrice(payload: number) {
  return {
    type: constants.SET_CANCEL_PRICE,
    payload,
  };
}

export function setShippingInstructionNote(payload: string) {
  return {
    type: constants.SET_SHIPPING_INSTRUCTION_NOTE,
    payload,
  };
}

export function setOrderInstructionNote(payload: string) {
  return {
    type: constants.SET_ORDER_INSTRUCTION_NOTE,
    payload,
  };
}

export function setQuoteExpiryDate(payload: any) {
  return {
    type: constants.SET_QUOTE_EXPIRY_DATE,
    payload,
  };
}

export function setTemporaryStorageLocationNote(payload: string) {
  return {
    type: constants.SET_TEMPORARY_LOCATION_INSTRUCTION_NOTE,
    payload,
  };
}
export function setPriceCheckReducer(payload: boolean) {
  return {
    type: constants.SET_PRICE_CHECK,
    payload,
  };
}
export function setMarkAsQuoteReducer(payload: boolean) {
  return {
    type: constants.SET_MARK_AS_QUOTE,
    payload,
  };
}

export function getAllRowsDataRequest() {
  return {
    type: constants.GET_ALL_ROWS_START,
  };
}
export function getAllRowsDataSuccess(payload) {
  return {
    type: constants.GET_ALL_ROWS_SUCCESS,
    payload,
  };
}
export function getAllRowsDataFail(payload) {
  return {
    type: constants.GET_ALL_ROWS_ERROR,
    payload
  };
}

export function getSingleRowsDataRequest() {
  return {
    type: constants.GET_SINGLE_ROWS_START,
  };
}
export function getSingleRowsDataSuccess(payload) {
  return {
    type: constants.GET_SINGLE_ROWS_SUCCESS,
    payload,
  };
}
export function getSingleRowsDataFail(payload) {
  return {
    type: constants.GET_SINGLE_ROWS_ERROR,
    payload,
  };
}
// Start Item Ledger Detail
export function itemLedgerInoutRequest(payload: any) {
  return {
    type: constants.ITEM_LEDGER_INOUT_START,
    payload,
  };
}
export function itemLedgerInoutSuccess(payload: any) {
  return {
    type: constants.ITEM_LEDGER_INOUT_SUCCESS,
    payload,
  };
}
export function itemLedgerInoutError(error: any) {
  return {
    type: constants.ITEM_LEDGER_INOUT_ERROR,
    error,
  };
}
export function itemLedgerInoutClear() {
  return {
    type: constants.ITEM_LEDGER_INOUT_CLEAR,
  };
}
// End Item Ledger

export function setMoveTabDisable(payload: boolean) {
  return {
    type: constants.SET_MOVE_TAB_DISABLE,
    payload,
  };
}

// start
export function getQuotesHistoryRequest(payload: any) {
  return {
    type: constants.GET_QUOTE_HISTORY_START,
    payload,
  };
}

export function getQuotesHistorySuccess(payload: any) {
  return {
    type: constants.GET_QUOTE_HISTORY_SUCCESS,
    payload,
  };
}

export function getQuotesHistoryError(error: any) {
  return {
    type: constants.GET_QUOTE_HISTORY_ERROR,
    error,
  };
}
//end

// start
export function getItemHistoryRequest(payload: any) {
  return {
    type: constants.GET_ITEM_HISTORY_START,
    payload,
  };
}

export function getItemHistorySuccess(payload: any) {
  return {
    type: constants.GET_ITEM_HISTORY_SUCCESS,
    payload,
  };
}

export function getItemHistoryError(error: any) {
  return {
    type: constants.GET_ITEM_HISTORY_ERROR,
    error,
  };
}
//end

// start
export function getInvoiceHistoryRequest(payload: any) {
  return {
    type: constants.GET_INVOICE_HISTORY_START,
    payload,
  };
}

export function getInvoiceHistorySuccess(payload: any) {
  return {
    type: constants.GET_INVOICE_HISTORY_SUCCESS,
    payload,
  };
}

export function getInvoiceHistoryError(error: any) {
  return {
    type: constants.GET_INVOICE_HISTORY_ERROR,
    error,
  };
}
//end

// start
export function getPurchaseHistoryRequest(payload: any) {
  return {
    type: constants.GET_PURCHASE_HISTORY_START,
    payload,
  };
}

export function getPurchaseHistorySuccess(payload: any) {
  return {
    type: constants.GET_PURCHASE_HISTORY_SUCCESS,
    payload,
  };
}

export function getPurchaseHistoryError(error: any) {
  return {
    type: constants.GET_PURCHASE_HISTORY_ERROR,
    error,
  };
}
//end     

// set isLoading start
export function setIsLoading(payload) {
  return {
    type: constants.SET_IS_LOADING,
    payload,
  };
}

// purchase order no. edited
export function isPurchaseOrderNoEdited(payload) {
  return {
    type: constants.PURCHASE_ORDER_NUMBER_EDIT,
    payload,
  };
}

export function searchDraftsRequest(payload: any) {
  return {
    type: constants.SEARCH_DRAFT_START,
    payload,
  };
}

export function searchDraftsSuccess(payload: any) {
  return {
    type: constants.SEARCH_DRAFT_SUCCESS,
    payload,
  };
}

export function searchDraftsError(error: string) {
  return {
    type: constants.SEARCH_DRAFT_ERROR,
    error,
  };
}

export function clearSearchDrafts() {
  return {
    type: constants.CLEAR_SEARCH_DRAFT,
  };
}

export function setJobNameForAll(payload: string) {
  return {
    type: constants.SET_JOB_NAME,
    payload,
  };
}

export function noMailStart(payload: any) {
  return {
    type: constants.NO_MAIL_START,
    payload,
  };
}

export function noMailSuccess(payload: any) {
  return {
    type: constants.NO_MAIL_SUCCESS,
    payload,
  };
}

export function noMailError(error: any) {
  return {
    type: constants.NO_MAIL_ERROR,
    error,
  };
}

export function setUnpaidBalanceAmout(payload: string) {
  return {
    type: constants.SET_UNPAID_BALANCE,
    payload,
  };
}

export function setStoreCreditAmout(payload: string) {
  return {
    type: constants.SET_STORE_CREDIT_AMOUNT,
    payload,
  };
}


export function newProductAdded(payload: any) {
  return {
    type: constants.NEW_PRODUCT_ID,
    payload,
  };
}

export function setBillingDetails(payload: any) {
  return {
    type: constants.SET_BILLING_DETAILS,
    payload,
  };
}
export function resetBillingDetails() {
  return {
    type: constants.RESET_BILLING_DETAILS,
  };
}

export function cartClearDraftsRequest(payload: any) {
  return {
    type: constants.CLEAR_CART_DRAFTS_START,
    payload,
  };
}

export function cartClearDraftsSuccess(payload: any) {
  return {
    type: constants.CLEAR_CART_DRAFTS_SUCCESS,
    payload,
  };
}

export function cartClearDraftsError(error: any) {
  return {
    type: constants.CLEAR_CART_DRAFTS_ERROR,
    error,
  };
}


// start
export function getPosPriceChangeHistoryRequest(payload: any) {
  return {
    type: constants.GET_POS_PRICE_CHANGE_HISTORY_START,
    payload,
  };
}

export function getPosPriceChangeHistorySuccess(payload: any) {
  return {
    type: constants.GET_POS_PRICE_CHANGE_HISTORY_SUCCESS,
    payload,
  };
}

export function getPosPriceChangeHistoryError(error: any) {
  return {
    type: constants.GET_POS_PRICE_CHANGE_HISTORY_ERROR,
    error,
  };
}
//end

export function getMovingAvgCostHistoryRequest(payload: any) {
  return {
    type: constants.GET_MOVING_AVG_COST_HISTORY_START,
    payload,
  };
}

export function getMovingAvgCostHistorySuccess(payload: any) {
  return {
    type: constants.GET_MOVING_AVG_COST_HISTORY_SUCCESS,
    payload,
  };
}

export function getMovingAvgCostHistoryError(error: any) {
  return {
    type: constants.GET_MOVING_AVG_COST_HISTORY_ERROR,
    error,
  };
}

export function lastProductQty(payload: number) {
  return {
    type: constants.ADD_TO_QTY,
    payload,
  };
}

export function lastViewProductIndex(payload: number) {
  return {
    type: constants.ADD_TO_INDEX,
    payload,
  };
}

export function setUpdatePriceScheduleFalse() {
  return {
    type: constants.UPDATE_PRICE_SCHEDULE_FALSE,
  };

}


export function getCustomerRegionRequest(payload) {
  return {
    type: constants.CUSTOMER_REGION_LIST_START,
    payload,
  };
}

export function getCustomerRegionSuccess(payload) {
  return {
    type: constants.CUSTOMER_REGION_LIST_SUCCESS,
    payload,
  };
}

export function getCustomerRegionError(error) {
  return {
    type: constants.CUSTOMER_REGION_LIST_ERROR,
    error,
  };
}
