import { Grid } from "@material-ui/core";
import { labels } from "apps/product/modules/productDetailsContainer/constants";
import { PosButton } from "apps/pos/Common/uiCore";
import { CommonGridSectionProps } from "apps/product/helper/types";
import { OpenInNewIcon } from "constants/icons";

const CommonGridSection = ({ value, displayValue = "", metaDataFlags = false, reportsSection = false, onClick }: CommonGridSectionProps) => {
  const { notSet, Yes, No } = labels

  return (
    <>
      {reportsSection ?
        <>
          <Grid item xs={12} sm={8}>
            <div>
              <PosButton
                variant="contained"
                className="btn product-detail-medium-btn history"
                size="small"
                endIcon={<OpenInNewIcon />}
                onClick={onClick}
              >
                {value}
              </PosButton>
            </div>
          </Grid>
        </> :
        metaDataFlags ?
          <>
            <span className="ml-2">{value ? Yes : No}</span>
          </> :
          <>
            <Grid item xs={12} sm={8}>
              {value ? (
                <span>{displayValue || value}</span>
              ) : (
                <div className="small-text-without-margin gray-text">
                  <span>{notSet}</span>
                </div>
              )}
            </Grid>
          </>}

    </>
  );
};

export default CommonGridSection;