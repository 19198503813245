import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { InputAdornment } from "@mui/material";

import { ErrorMessage } from "apps/pos/formik/errorMessage";
import { PosButton, PosImage, PosInput } from "apps/pos/Common/uiCore";
import { ProductDetailsModalsProp } from "./ProductDetailsCommonInterface";
import ProductDetailsCommonModal from "./ProductDetailsCommonModal";
import { UpdatedImageProductDetailSchema } from "apps/pos/formik/validationSchema";

import { FileUploadIcon } from "constants/icons";
import images from "constants/images";
import { authorizationToken } from "utils/utils";
import { commonConstants } from "../helper/commonConstants";
import { uploadLink } from "constants/staticData";
import {retailPriceLabel} from "../modules/productDetailsContainer/constants"

import useProductsDetailsHooks from "../modules/productDetailsContainer/hooks/mutationHooks";
import { updateImagesProductStart } from "sagas/productsDetails/singleProductDetails.slice";
import { getSingleProductDataFunction, getUpdateImagesProduct } from "sagas/productsDetails/selector";
import { distributorData } from "sagas/distributor/selector";
import { labels } from "../modules/productDetailsContainer/constants";

import "./ProductDetailsCommonModal.scss";
import "./ProductDetailsImagesModals.scss";
export default function ProductDetailsImagesModals({
    open,
    onClose,
}: ProductDetailsModalsProp) {
    const dispatch = useDispatch();

    const { defaultIcon } = images;
    const { Back, SaveChanges, imageText, Upload, DefaultLargeImage, DefaultThumbnail, DOCS, PIB, PSDS } = labels;
    const updateImagesProduct = useSelector(getUpdateImagesProduct)
    const getSingleProductData = useSelector(getSingleProductDataFunction);
    const distributor = useSelector(distributorData)

    const { details } = getSingleProductData || {}
    const { imgDocs, productId } = details || {}
    const { thumbImg, bigImg, pib, psds } = imgDocs || {}
    const {prodBigImg, prodThumImg} = retailPriceLabel || {}
    const {
        updateImageProductsMutation,
    } = useProductsDetailsHooks();

    const openLinkForUpload = () => {
        return window.open(`${distributor.httpsUrl}${uploadLink}`, commonConstants.target, commonConstants.noreferrer);
    }
    
    const handleChangeValue = (name , value , setFieldValue ) => {
        setFieldValue(name , value)
    }

    const handleClose = (resetForm) => () => {
        onClose();
        resetForm();
    }

    return (
        <Formik
            initialValues={{
                bigImg: bigImg,
                thumbImg: thumbImg,
                pib: pib,
                psds: psds,
            }}
            validationSchema={UpdatedImageProductDetailSchema}
            enableReinitialize
            onSubmit={(values) => {
                const { DefaultLoginId } = authorizationToken("Authorization") || {};
                const { bigImg , thumbImg , pib , psds} = values
                let desiredBigImg = ""
                let desiredThumbImg = ""

                if(bigImg !== imgDocs.bigImg){
                    desiredBigImg = prodBigImg + bigImg
                }else{
                    const productBigImg = bigImg && bigImg.split(prodBigImg)?.[1]
                    desiredBigImg= prodBigImg + productBigImg
                }

                if(thumbImg !== imgDocs.thumbImg){
                    desiredThumbImg = prodThumImg + thumbImg
                }else{
                    const productThumbImg = thumbImg && thumbImg.split(prodThumImg)?.[1]
                    desiredThumbImg = prodThumImg + productThumbImg
                }

                const body = {
                    productId,
                    handlerId: DefaultLoginId,
                    bigImg: desiredBigImg,
                    thumbImg: desiredThumbImg,
                    pib,
                    psds
                }
                dispatch(updateImagesProductStart())
                updateImageProductsMutation.mutate(body);
            }}
        >
            {({ values, setFieldValue,resetForm, handleSubmit , dirty }) => {
                const { bigImg, thumbImg, pib, psds } = values
                return (
                    <>
                        <ProductDetailsCommonModal
                            open={open}
                            onClose={handleClose(resetForm)}
                            modalMainTitle="Images & Docs" 
                            innerBodyClassName="pb-70">
                            <div className="product-detail-image-sec product-detail-image-sec-border d-flex">
                                <div className="notes-added-user-name font-normal">
                                    <span>
                                        {imageText}
                                    </span>
                                </div>
                                <div className="product-detail-large-image">
                                    <PosButton
                                        variant="contained"
                                        className="btn-discard btn font-size product-detail-common-small-btn mr-2"
                                        endIcon={<FileUploadIcon />}
                                        onClick={openLinkForUpload}
                                    >
                                        {Upload}
                                    </PosButton>
                                </div>

                            </div>

                            <div className="product-detail-image-sec product-detail-image-sec-border">
                                <div className="product-detail-medium-image mb-4">
                                    <PosImage
                                        src={bigImg || defaultIcon}
                                        className=""
                                        alt="product"
                                    />
                                </div>
                                <div className="notes-added-user-name mb-2">
                                    <span>
                                        {DefaultLargeImage}
                                    </span>
                                </div>
                                <div className="w-100">
                                    <PosInput
                                        variant="filled"
                                        id="filled-basic"
                                        type="text"
                                        className="product-detail-search-input input-focus-styling"
                                        name="bigImg"
                                        value={bigImg && bigImg.split(prodBigImg)?.[1]}
                                        onChange={(e) => {
                                            handleChangeValue("bigImg", e.target.value , setFieldValue)
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            startAdornment: <InputAdornment className="img-text-adornment" position="start">{prodBigImg}</InputAdornment>
                                        }}
                                    />
                                </div>
                                <ErrorMessage name="bigImg" />
                            </div>
                            <div className="product-detail-image-sec product-detail-image-sec-border">
                                <div className="product-detail-medium-image mb-4">
                                    <PosImage
                                        src={thumbImg || defaultIcon}
                                        className=""
                                        alt="product"
                                    />
                                </div>
                                <div className="notes-added-user-name mb-2">
                                    <span>
                                        {DefaultThumbnail}
                                    </span>
                                </div>
                                <div className="w-100">
                                    <PosInput
                                        variant="filled"
                                        id="filled-basic"
                                        type="text"
                                        className="product-detail-search-input input-focus-styling"
                                        name="thumbImg"
                                        value={thumbImg && thumbImg.split(prodThumImg)?.[1]}
                                        onChange={(e) => {
                                            handleChangeValue("thumbImg", e.target.value,setFieldValue)
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            startAdornment: <InputAdornment className="img-text-adornment" position="start">{prodThumImg}</InputAdornment>
                                        }}
                                    />
                                </div>
                                <ErrorMessage name="thumbImg" />
                            </div>
                            <div className="product-detail-image-sec product-detail-image-sec-border">
                                <div className="notes-added-title text-black mb-1">
                                    <span>
                                        {DOCS}
                                    </span>
                                </div>
                                <div className="notes-added-user-name product-detail-common-spacing font-medium">
                                    <span>
                                        {PIB}
                                    </span>
                                </div>

                                <div className="d-flex accessories-main-info-sec pb-0 mb-2">
                                    <div className="w-100">
                                        <PosInput
                                            variant="filled"
                                            id="filled-basic"
                                            type="text"
                                            className="product-detail-search-input input-focus-styling"
                                            name="pib"
                                            value={pib}
                                            onChange={(e) => {
                                                handleChangeValue("pib", e.target.value,setFieldValue)
                                            }}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                        />
                                    </div>
                                </div>
                                <ErrorMessage name="pib" />
                            </div>
                            <div>
                                <div className="notes-added-user-name product-detail-common-spacing font-medium">
                                    <span>
                                        {PSDS}
                                    </span>
                                </div>
                                <div className="product-detail-common-spacing">
                                    <div className="w-100">
                                        <PosInput
                                            variant="filled"
                                            id="filled-basic"
                                            type="text"
                                            className="product-detail-search-input input-focus-styling"
                                            name="psds"
                                            value={psds}
                                            onChange={(e) => {
                                                handleChangeValue("psds", e.target.value,setFieldValue)
                                            }}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                        />
                                    </div>
                                </div>
                                <ErrorMessage name="psds" />
                            </div>
                            <div className="notes-model-footer position-absolute start-0 end-0">
                                <div className="d-flex notes-btn-div product-detail-modal-footer-spacing justify-content-end">
                                    <PosButton
                                        variant="contained"
                                        onClick={handleClose(resetForm)}
                                        className="back-button first-product-detail-btn"
                                    >
                                        {Back}
                                    </PosButton>
                                    <PosButton
                                        fontWeight
                                        className={`btn ml-2 second-product-detail-btn ${updateImagesProduct ? "button-accent button-is-loading-black" : ""}`}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSubmit()}
                                        disabled={updateImagesProduct || !dirty}
                                    >
                                        {SaveChanges}
                                    </PosButton>
                                </div>
                            </div>
                        </ProductDetailsCommonModal>
                    </>
                );
            }}
        </Formik>
    );
}
