import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import {
    PosImage,
} from "apps/pos/Common/uiCore";
import StorageDataModal from "apps/pos/storage/StorageData";
import { setDeliveredStorageItemDetail, setDeliverySuccessData, setEditStorageDetailItem, setIsPendingOrderDelivery, setUpdateLocationSuccess } from "../CustomerStorage/redux/storage.slice";
import Skeleton from "../Skeleton";

import { PosResponseProps, RootState } from "models";
import { commonClassName, title } from "constants/constant";
import { Paths } from "enums";
import images from "constants/images";

import { arrowResetGeneric } from "sagas/pos/actions";

import "apps/pos/pages/customerInfo/CustomerDrafts/customerDrafts.scss";
import "apps/pos/pages/customerInfo/CustomerOrders/customerOrders.scss";
import "apps/pos/pages/customerInfo/CustomerStorage/customerStorage.scss";

const CustomerDelivered = ({ deliveredStorageItemsData, storageTopDataHeight, searchCustomersStorageDeliveredRef, tableRowRef, enterPress, setEnterPress, searchValue, isApiLoading }) => {
    const dispatch = useDispatch();
    const { selectedIndexGeneric } = useSelector<RootState, PosResponseProps>(
        (state) => state.pos
    );

    const navigate = useNavigate();

    const [storageDataModal, setStorageDataModal] = useState(false);

    const { noDeliveredShipmentsFound, noDeliveredShipmentsFoundEmptyState } = title

    const handleNavigate = (row) => {
        dispatch(setUpdateLocationSuccess(false))
        dispatch(setIsPendingOrderDelivery(false))
        dispatch(setDeliverySuccessData({}))
        dispatch(setEditStorageDetailItem({}))
        dispatch(setDeliveredStorageItemDetail(row))
        navigate(Paths.subStorageItems, {state: { isStorageInfo: true, isNotFromSR: true }})
    }
    
    const dynamicStorageHeight = {height: `calc(95vh - ${storageTopDataHeight - 20}px)`}

    useEffect(() => {
        if (searchCustomersStorageDeliveredRef?.current) {
            dispatch(arrowResetGeneric());
        }
    }, [searchCustomersStorageDeliveredRef])

    useEffect(() => {
        if (enterPress && selectedIndexGeneric > -1 && searchCustomersStorageDeliveredRef?.current) {
            const row = deliveredStorageItemsData[selectedIndexGeneric]
            if(row){
                handleNavigate(row)
                setEnterPress(false)
            }
        }
    }, [enterPress])

    return (
        <>
            <section className="document-main-section storage-info-table" ref={searchCustomersStorageDeliveredRef}>
                <div className="document-container">
                    <div>
                        <TableContainer component={Paper} className="customer-info-table-container"
                        style={dynamicStorageHeight}
                        >
                            {
                                isApiLoading ? <Skeleton count={5} /> :                           
                                <Table aria-label="simple table">
                                {!!deliveredStorageItemsData?.length ? <TableHead>
                                    <TableRow>
                                        <TableCell className="customer-info-table-title first-added-new-table-row">DATE</TableCell>
                                        <TableCell className="customer-info-table-title second-added-new-table-row">ID</TableCell>
                                        <TableCell className="customer-info-table-title third-added-new-table-row">HANDLED BY</TableCell>
                                        <TableCell className="customer-info-table-title fourth-added-new-table-row">METHOD</TableCell>
                                        <TableCell className="customer-info-table-title fifth-added-new-table-row">TRACKING NUMBER</TableCell>
                                        <TableCell className="customer-info-table-title sixth-added-new-table-row">ORDER #</TableCell>
                                        <TableCell className="customer-info-table-title seventh-added-new-table-row">DELIVERY PROOF</TableCell>
                                    </TableRow>
                                </TableHead>
                                : <></>
                            }
                                
                                <TableBody>
                                    {!!deliveredStorageItemsData?.length ?  deliveredStorageItemsData?.map((row, index) => {
                                        const { deliveryTime, deliveryId, handlerName, deliveryMethod, trackingNum, orderId, proofOfDeliveryDocName } = row
                                        return (
                                            <TableRow
                                                className={`customer-table-data-row ${index === selectedIndexGeneric ? "selectedRow" : ""}`}
                                                key={`row${index}`}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                onClick={() => handleNavigate(row)}
                                                style={{
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                        index === selectedIndexGeneric
                                                            ? "lightgray"
                                                            : "white",
                                                }}
                                                ref={tableRowRef[index]}
                                                id={`tableRowRef${index}`}
                                            >
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="10%">
                                                    <span>{dayjs(deliveryTime).format('MM/DD/YY')}</span>
                                                </TableCell>
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="10%">
                                                    <span>
                                                        {deliveryId}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="10%">
                                                    <span>
                                                        {handlerName}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="10%">
                                                    <span>
                                                        {deliveryMethod}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="10%">
                                                    <span>
                                                        {trackingNum}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="10%">
                                                    <span>
                                                        {orderId}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="40%">
                                                    <span>
                                                        {proofOfDeliveryDocName}
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }) : 
                                        <>
                                                    {
                                                        !searchValue ? (
                                                            <div className="mt-5 text-center row justify-content-center">
                                                                <PosImage
                                                                    src={images.noDeliveredStorage}
                                                                    height={140}
                                                                    width={120}
                                                                    alt="add product"
                                                                />
                                                                <span className="int-20-reg empty-state-fonts">{noDeliveredShipmentsFoundEmptyState}</span>
                                                            </div>
                                                        ) :
                                                            <>
                                                                <div className="mt-3 d-flex justify-content-center"><span className="int-18-reg-bold">{noDeliveredShipmentsFound}</span></div>
                                                            </>
                                                    }
                                        </>  
                                    }
                                </TableBody>
                                </Table>
                            }
                        </TableContainer>
                    </div>
                </div>
            </section>
            <StorageDataModal
                storageDataModal={storageDataModal}
                setStorageDataModal={setStorageDataModal}
            />
        </>
    );
};
export default CustomerDelivered;
