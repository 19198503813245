import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";

import PosToggle from "apps/pos/Common/uiCore/posToggle/posToggle";
import { PosButton } from "apps/pos/Common/uiCore";

import { ProductDetailsModalsProp } from "./ProductDetailsCommonInterface";
import ProductDetailsCommonModal from "./ProductDetailsCommonModal";

import { labels } from "../modules/productDetailsContainer/constants";
import { authorizationToken } from "utils/utils";

import { updateFlagesProductStart } from "sagas/productsDetails/singleProductDetails.slice";
import { getSingleProductDataFunction, getUpdateFlagesProduct } from "sagas/productsDetails/selector";
import useProductsDetailsHooks from "../modules/productDetailsContainer/hooks/mutationHooks";

import "./ProductDetailsCommonModal.scss";
import "../Modals/ProductDetailsMetadataIdentifiersModals/ProductDetailsMetadataIdentifiersModals.scss";

export default function ProductDetailsMetadataFlagesModals({
    open,
    onClose,
}: ProductDetailsModalsProp) {
    const dispatch = useDispatch();
    const updateFlagesProduct = useSelector(getUpdateFlagesProduct)
    const getSingleProductData = useSelector(getSingleProductDataFunction);
    const { details, metadata } = getSingleProductData || {}
    const { productId } = details || {};
    const { flags } = metadata || {}
    const { dropshipFlag, containMercuryFlag, hidePriceFlag, hideFromAmazonFeed, isVirtual, isForClearanceEmailBlast , isToSetOrderUnusual } = flags || {}

    const { Back , SaveChanges } = labels;

    const handleChange = (e : React.ChangeEvent<HTMLInputElement>, name, setFieldValue) => {
        setFieldValue(name, e.target.checked);
    }

    const {
        updateFlagesProductsMutation,
    } = useProductsDetailsHooks();

    const handleClose = (resetForm) => () => {
        onClose();
        resetForm();
    }

    return (
        <Formik
            initialValues={{
                dropshipFlag: Boolean(dropshipFlag),
                containMercuryFlag: Boolean(containMercuryFlag),
                hidePriceFlag: Boolean(hidePriceFlag),
                hideFromAmazonFeed: Boolean(hideFromAmazonFeed),
                isVirtual: Boolean(isVirtual),
                isForClearanceEmailBlast: Boolean(isForClearanceEmailBlast)
            }}
            enableReinitialize
            onSubmit={(values) => {
                const { DefaultLoginId } = authorizationToken("Authorization") || {};
                const { dropshipFlag , containMercuryFlag , hidePriceFlag , hideFromAmazonFeed , isVirtual , isForClearanceEmailBlast } = values;
                const body = {
                    productId,
                    handlerId: DefaultLoginId,
                    dropshipFlag: dropshipFlag ? 1 : 0,
                    containMercuryFlag: containMercuryFlag ? 1 : 0,
                    hidePriceFlag: hidePriceFlag ? 1 : 0,
                    hideFromAmazonFeed,
                    isVirtual,
                    isForClearanceEmailBlast,
                    isToSetOrderUnusual,
                    noFroogleFlag: 0
                }
                dispatch(updateFlagesProductStart())
                updateFlagesProductsMutation.mutate(body);
            }}
        >
            {({ values, setFieldValue ,resetForm, handleSubmit , dirty}) => {
                const { dropshipFlag, containMercuryFlag, hidePriceFlag, hideFromAmazonFeed, isVirtual, isForClearanceEmailBlast } = values
                return (
                    <>
                        <ProductDetailsCommonModal
                            open={open}
                            onClose={handleClose(resetForm)}
                            modalMainTitle="Metadata & Flags"
                            modaSecondTitle="Flags"
                            innerBodyClassName="pb-70"
                        >
                            <div className="product-detail-toggle-switch product-detail-info-sec-border metadata-toggle-common-spacing">
                                <PosToggle label="Can be Drop Shipped" value={dropshipFlag} onChange={(e) => handleChange(e, "dropshipFlag", setFieldValue)} />
                            </div>
                            <div className="product-detail-toggle-switch product-detail-info-sec-border metadata-toggle-common-spacing">
                                <PosToggle label="Contains Mercury" value={containMercuryFlag} onChange={(e) => handleChange(e, "containMercuryFlag", setFieldValue)} />
                            </div>
                            <div className="product-detail-toggle-switch product-detail-info-sec-border metadata-toggle-common-spacing">
                                <PosToggle label="Hide price and prevent online ordering" value={hideFromAmazonFeed} onChange={(e) => handleChange(e, "hideFromAmazonFeed", setFieldValue)} />
                            </div>
                            <div className="product-detail-toggle-switch product-detail-info-sec-border metadata-toggle-common-spacing">
                                <PosToggle label="Hide from Amazon Feed" value={hidePriceFlag} onChange={(e) => handleChange(e, "hidePriceFlag", setFieldValue)} />
                            </div>
                            <div className="product-detail-toggle-switch product-detail-info-sec-border metadata-toggle-common-spacing">
                                <PosToggle label="Virtual" value={isVirtual} onChange={(e) => handleChange(e, "isVirtual", setFieldValue)} />
                            </div>
                            <div className="product-detail-toggle-switch product-detail-info-sec-border metadata-toggle-common-spacing">
                                <PosToggle label="Clearance Email Blast" value={isForClearanceEmailBlast} onChange={(e) => handleChange(e, "isForClearanceEmailBlast", setFieldValue)} />
                            </div>
                            <div className="notes-model-footer position-absolute start-0 end-0">
                                <div className="d-flex notes-btn-div product-detail-modal-footer-spacing justify-content-end">
                                    <PosButton
                                        variant="contained"
                                        onClick={handleClose(resetForm)}
                                        className="back-button first-product-detail-btn"
                                    >
                                        {Back}
                                    </PosButton>
                                    <PosButton
                                        fontWeight
                                        className={`btn ml-2 second-product-detail-btn ${updateFlagesProduct ? "button-accent button-is-loading-black" : ""}`}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSubmit()}
                                        disabled={updateFlagesProduct || !dirty}
                                    >
                                        {SaveChanges}
                                    </PosButton>
                                </div>
                            </div>
                        </ProductDetailsCommonModal>
                    </>
                );
            }}
        </Formik>
    );
}
