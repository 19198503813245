import { Container, Grid } from "@material-ui/core";
import { InputAdornment } from "@mui/material";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import ProductDetailsAccessories from "apps/product/Components/ProductDetails/ProductDetailsAccessories";
import ProductDetailsCharacteristics from "apps/product/Components/ProductDetails/ProductDetailsCharacteristics";
import ProductDetailsCosts from "apps/product/Components/ProductDetails/ProductDetailsCosts";
import ProductDetailsCoupons from "apps/product/Components/ProductDetails/ProductDetailsCoupons";
import ProductDetailsImages from "apps/product/Components/ProductDetails/ProductDetailsImages";
import ProductDetailsNotes from "apps/product/Components/ProductDetails/ProductDetailsNotes";
import ProductDetailsOverview from "apps/product/Components/ProductDetails/ProductDetailsOverview";
import ProductDetailsReports from "apps/product/Components/ProductDetails/ProductDetailsReports";
import ProductDetailsSection from "apps/product/Components/ProductDetails/ProductDetailsSection";
import ProductDetailsSidebar from "apps/product/Components/ProductDetails/ProductDetailsSidebar";
import ProductsDetailsMetadata from "apps/product/Components/ProductDetails/ProductsDetailsMetadata";
import Skeleton from "apps/product/Components/Skeleton/Skeleton";

import ProductDetailLog from "apps/product/Components/ProductDetails/ProductDetailLog";
import ProductDetailRetailPrice from "apps/product/Components/ProductDetails/ProductDetailRetailPrice";
import ProductOverview from "apps/product/Components/ProductDetails/ProductOverview";
import ProductStatusShow from "apps/product/Components/ProductDetails/ProductStatusShow";
import NotesManager from "apps/product/Modals/NotesManager";
import ProductDetailsAccessoriesModals from "apps/product/Modals/ProductDetailsAccessoriesModals";
import ProductDetailsCharacteristicsModals from "apps/product/Modals/ProductDetailsCharacteristicsModals";
import ProductDetailsCostsModals from "apps/product/Modals/ProductDetailsCostsModals";
import ProductDetailsCouponsModals from "apps/product/Modals/ProductDetailsCouponsModals";
import ProductDetailsImagesModals from "apps/product/Modals/ProductDetailsImagesModals";
import ProductDetailsLogsModals from "apps/product/Modals/ProductDetailsLogsItemModels/ProductDetailsLogsItemModels";
import ProductDetailsMetadataFlagesModals from "apps/product/Modals/ProductDetailsMetadataFlagesModals";
import ProductDetailsMetadataIdentifiersModals from "apps/product/Modals/ProductDetailsMetadataIdentifiersModals";
import ProductDetailsMetadataSeoModals from "apps/product/Modals/ProductDetailsMetadataSeoModals";
import ProductDetailsModals from "apps/product/Modals/ProductDetailsModals";
import ProductDetailsOverviewModals from "apps/product/Modals/ProductDetailsOverviewModals";
import ProductDetailsRetailPriceItemModels from "apps/product/Modals/ProductDetailsRetailPriceItemModels";
import ProductOverviewMainModals from "apps/product/Modals/ProductOverviewMainModals";
import ProductOverviewModals from "apps/product/Modals/ProductOverviewModals/ProductOverviewModals";

import {
  PosButton,
  PosHelmet,
  PosImage,
  PosInput,
} from "apps/pos/Common/uiCore";

import {
  commonConstants,
  statusValue,
} from "apps/product/helper/commonConstants";
import { alertMessage, sectionTitle } from "constants/constant";
import { ProductTypes, accessoriesKey } from "constants/enum";
import { DoDisturbIcon, LocalOfferIcon, SearchIcon } from "constants/icons";
import images from "constants/images";
import { Paths } from "enums";
import { getAddToBatchProduct } from "utils/helper";
import { authorizationToken, getLocalStoraGeItem } from "utils/utils";
import {
  labels,
  notActiveCouponStatus,
  productModalNames,
  productStatusDefaultOptions,
  productStatusOptions,
  retailPriceLabel,
  sectionName,
} from "./constants";
import useProductsDetailsHooks from "./hooks/mutationHooks";
import { AccessorieI, StatusInterface } from "./types";

import { ProductDetailText } from "constants/enum";
import { distributorData } from "sagas/distributor/selector";
import { getStaredProductsDetails } from "sagas/persistReducer/selector";
import { apiFiledRequest, stockByWarehouseStart } from "sagas/pos/actions";
import {
  getNewProductGroupDataSelector,
  getNewProductManufacturer,
  getNewProductVendor,
} from "sagas/pos/selector";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import {
  addProductsAccessorieStart,
  getProductsAccessorieGoesWithStart,
  getProductsAccessorieReplacesStart,
  removeAllRelatedItemsByProductStart,
  removeParticularAccessoriesStart,
} from "sagas/productsDetails/productAccessories.slice";
import {
  AddCharacteristicStart,
  GetAllCharacteristicTypeListStart,
  getProductsCharacteristicsStart,
  removeAllCharacteristicsStart,
  removeParticularCharacteristicsStart,
  updateProductsCharacteristics,
} from "sagas/productsDetails/productCharacteristics.slice";
import {
  addProductsCouponsStart,
  getAllProductsCouponsStart,
  getProductsCouponsStart,
  removeAllProductsCouponsStart,
  removeProductsCouponsStart,
} from "sagas/productsDetails/productCoupons.slice";
import {
  addNotesProductStart,
  getProductsNotesStart,
  removeAllNotesProductStart,
  removeNotesNotesStart,
  updateNotesProductStart,
} from "sagas/productsDetails/productNotes.slice";
import { getProductsStockMinMaxStart } from "sagas/productsDetails/productStock.slice";
import {
  AddCharacteristicDataSelector,
  AddProductCharacteristicsErrorSelector,
  GetAllCharacteristicTypeListSelector,
  addMinMaxBatchProductDataSelector,
  addMinMaxBatchProductErrorSelector,
  addProductAccessorieErrorSelector,
  deletePriceScedulesProductDataSelector,
  getAllProductCoupons,
  getCategoriesMenuProductDataSelector,
  getIsAddProductsNotes,
  getLogsProductDataSelector,
  getProductCharacteristicsDataSelector,
  getProductCouponsDataSelector,
  getProductNotes,
  getProductTicketsCountDataSelector,
  getProductsStockMinMax,
  getRemoveAllProductNotesData,
  getRemoveProductNotesData,
  getRetailPriceScedulesProductDataSelector,
  getSingleProductDataFunction,
  getSingleProductErrorSelector,
  getSingleProductSelector,
  isAddProductsCharacteristicsSuccessSelector,
  isAddProductsCouponsSelector,
  productAccessorieGoesWithDataSelector,
  productAccessorieReplacesDataSelector,
  removeAllCharacteristicsDataSelector,
  removeAllCharacteristicsErrorSelector,
  removeAllProductsCouponsErrorSelector,
  removeAllRelatedItemsByProductDataErrorSelector,
  removeClearancePriceProductDataSelector,
  removeDiscontinuedPriceProductDataSelector,
  removeMinMaxBatchProductDataSelector,
  removeParticularAccessoriesErrorSelector,
  removeParticularCharacteristicsErrorSelector,
  removeProductsCouponsErrorSelector,
  updateBasicProductDataSelector,
  updateBasicProductSelector,
  updateCostAndPriceProductDataSelector,
  updateCostAndPriceProductSelector,
  updateFlagesProductDataSelector,
  updateIdentifiersProductDataSelector,
  updateIdentifiersProductSelector,
  updateImagesProductDataSelector,
  updateMeasurementsProductDataSelector,
  updateMeasurementsProductSelector,
  updateOverProductSelector,
  updateOverviewProductDataSelector,
  updateRetailPriceScedulesProductDataSelector,
  updateSeoProductDataSelector,
  updateSeoProductSelector,
} from "sagas/productsDetails/selector";
import {
  clearMinMaxBatchProductStart,
  getCategoriesMenuProductStart,
  getLogsProductStart,
  getProductTicketsCountStart,
  getRetailPriceScedulesProductStart,
  getSingleProductStart,
} from "sagas/productsDetails/singleProductDetails.slice";

import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";

import IconButton from "apps/product/Components/IconButton";
import "apps/product/Modals/ProductDetailsLogsItemModels.scss";
import "apps/product/Styles/ProductDetails.scss";
import "./styles.scss";

const ProductDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    getSingleProductsMutation,
    getProductMinMaxStockMutation,
    getProductNotesMutation,
    getProductCouponsMutation,
    getProductCharacteristicsMutation,
    getProductAccessorieGoesWithMutation,
    getProductReplacesMutation,
    removeParticularAccessoriesMutation,
    removeAllRelatedItemsByProductMutation,
    removeNotesProductsMutation,
    removeAllNotesProductsMutation,
    updateNotesProductsMutation,
    addNotesProductsMutation,
    getCategoriesMenuProductsMutation,
    removeAllCharacteristicsMutation,
    removeParticularCharacteristicsMutation,
    removeAllCouponsMutation,
    removeCouponsByRowIdMutation,
    getLogsProductsMutation,
    addAccessoriesAccessorieWithMutation,
    getRetailPriceScedulesProductsMutation,
    GetAllCharacteristicTypeListMutation,
    getProductsTicketsCountMutation,
    getCouponsAllMutation,
    AddCharacteristicMutation,
    addCouponsMutation
  } = useProductsDetailsHooks();

  const getProductsStockMinMaxData = useSelector(getProductsStockMinMax)
  const starredProductsDetails = useSelector(getStaredProductsDetails);
  const updateSeoProduct = useSelector(updateSeoProductSelector);
  const updateIdentifiersProduct = useSelector(updateIdentifiersProductSelector);
  const updateMeasurementsProduct = useSelector(updateMeasurementsProductSelector);
  const updateBasicProduct = useSelector(updateBasicProductSelector);
  const getCategoriesMenuProductData = useSelector(getCategoriesMenuProductDataSelector);
  const removeMinMaxBatchProductData = useSelector(removeMinMaxBatchProductDataSelector);
  const getSingleProduct = useSelector(getSingleProductSelector);
  const getLogsProductData = useSelector(getLogsProductDataSelector);
  const getRetailPriceScedulesProductData = useSelector(getRetailPriceScedulesProductDataSelector);
  const updateFlagesProductData = useSelector(updateFlagesProductDataSelector);
  const updateCostAndPriceProductData = useSelector(updateCostAndPriceProductDataSelector);
  const removeDiscontinuedPriceProductData = useSelector(removeDiscontinuedPriceProductDataSelector);
  const removeClearancePriceProductData = useSelector(removeClearancePriceProductDataSelector);
  const updateImagesProductData = useSelector(updateImagesProductDataSelector);
  const updateIdentifiersProductData = useSelector(updateIdentifiersProductDataSelector);
  const updateSeoProductData = useSelector(updateSeoProductDataSelector);
  const updateMeasurementsProductData = useSelector(updateMeasurementsProductDataSelector);
  const updateBasicProductData = useSelector(updateBasicProductDataSelector);
  const updateOverviewProductData= useSelector(updateOverviewProductDataSelector);
  const deletePriceScedulesProductData = useSelector(deletePriceScedulesProductDataSelector);
  const updateRetailPriceScedulesProductData = useSelector(updateRetailPriceScedulesProductDataSelector);
  const addMinMaxBatchProductData = useSelector(addMinMaxBatchProductDataSelector);
  const addMinMaxBatchProductError = useSelector(addMinMaxBatchProductErrorSelector);
  const getSingleProductError = useSelector(getSingleProductErrorSelector);
  const updateCostAndPriceProduct = useSelector(updateCostAndPriceProductSelector);
  const getProductTicketsCountData = useSelector(getProductTicketsCountDataSelector);
  const getSingleProductData = useSelector(getSingleProductDataFunction);
  const distributor = useSelector(distributorData)
  const getAllProductCouponsData = useSelector(getAllProductCoupons)
  const getProductNotesData = useSelector(getProductNotes)
  const isAddProductsNotes = useSelector(getIsAddProductsNotes);
  const removeAllProductNotesData = useSelector(getRemoveAllProductNotesData);
  const removeProductNotesData = useSelector(getRemoveProductNotesData);
  const getProductAccessorieGoesWithData = useSelector(productAccessorieGoesWithDataSelector);
  const getProductAccessorieReplacesData = useSelector(productAccessorieReplacesDataSelector);
  const removeAllRelatedItemsByProductDataError = useSelector(removeAllRelatedItemsByProductDataErrorSelector);
  const removeParticularAccessoriesError = useSelector(removeParticularAccessoriesErrorSelector);
  const addProductAccessorieError = useSelector(addProductAccessorieErrorSelector);
  const isAddProductsCharacteristicsSuccess = useSelector(isAddProductsCharacteristicsSuccessSelector);
  const GetAllCharacteristicTypeList = useSelector(GetAllCharacteristicTypeListSelector);
  const getProductCharacteristicsData = useSelector(getProductCharacteristicsDataSelector);
  const AddProductCharacteristicsError = useSelector(AddProductCharacteristicsErrorSelector);
  const removeParticularCharacteristicsError = useSelector(removeParticularCharacteristicsErrorSelector);
  const removeAllCharacteristicsError = useSelector(removeAllCharacteristicsErrorSelector);
  const AddCharacteristicData = useSelector(AddCharacteristicDataSelector);
  const removeAllCharacteristicsData = useSelector(removeAllCharacteristicsDataSelector);
  const getProductCouponsData = useSelector(getProductCouponsDataSelector);
  const removeAllProductsCouponsError = useSelector(removeAllProductsCouponsErrorSelector);
  const removeProductsCouponsError = useSelector(removeProductsCouponsErrorSelector);
  const isAddProductsCoupons = useSelector(isAddProductsCouponsSelector);
  const getNewProductManufacturerData = useSelector(getNewProductManufacturer)
  const getNewProductVendorData = useSelector(getNewProductVendor)
  const getNewProductGroupData = useSelector(getNewProductGroupDataSelector)
  const isUpdateOverviewProduct = useSelector(updateOverProductSelector);

  const { details, availability, costsAndPrices } = getSingleProductData || {}
  const { manufacturerCode, productName, manufacturerName, imgDocs, activeFlag } = details || {}
  const { qtyInStock } = availability || {}
  const { basePrices } = costsAndPrices || {}
  const { clearance, discontinued } = basePrices || {}
  const { defaultIcon, upDownArrow } = images;
  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {};
  const { DefaultLoginId, LoggedInUserFullName } = authorizationToken("Authorization") || {};
  const sectionId = location.hash.replace(/#/g, '');

  const { productSearchDetail, setAsClearance, setAsDiscontinued, edit, ImagesDocs, Details, Overview, Measurements, CostsPrices, MetadataFlags, Reports, ViewAll, Accessories, Characteristics, Add, Manage, Notes, Coupons, Logs, RetailPriceSchedules, Attach } = labels;

  const { RetailPriceSchedulesSection, LogsSection, NotesSection, CouponsSection, MetadataSection, ReportsSection, ImagesSection, AccessoriesSection, CharacteristicsSection, StarredSection, DetailsSection, MeasurementsSection, CostSection, StarredOption, OverviewOption, DetailsOption, CostOption, MetadataOption, ReportsOption, ImagesOption, AccessoriesOption, CharacteristicsOption, NotesOption, CouponsOption, LogsOption, RetailPriceSchedulesOption, OverviewMainOption } = sectionName;

  const { typeClearance, typeDiscontinued, outStock } = ProductTypes;
  const { goesWith, replaces } = accessoriesKey;
  const { starredStart, starredEnd } = retailPriceLabel;
  const { Discontinued, Clearance } = ProductDetailText

  const { stockMinMaxRemoved, confirmDeleteForCharacteristics, confirmDeleteForAccessories, confirmDeleteForCoupon, characteristicAlreadyExits, accessoriesAlreadyExits, accessoriesLimitExits, characteristicLimitExits, successRemoveDiscontinued, successRemoveClearance, successRemovePriceSchedule, batchAddedSuccess, batchAddError, couponAlreadyExits } = alertMessage

  const { one } = statusValue;
  const { starred } = sectionTitle

  const { productNotesModalConst, productCouponsModalConst, productCharacteristicsModalConst, productDetailsAccessoriesModalConst, productDetailsImagesModalConst, productDetailsMetadataModalConst, productDetailsMetadataFlagesModalConst, productDetailsMetadataSeoModalConst, productDetailsMeasurementModalConst, productDetailsCostsModalConst, productDetailsOverviewModalConst, productLogModalConst, retailListModelConst, productOverviewModalConst, productOverviewConst } = productModalNames

  const [searchTerm, setSearchTerm] = useState("");
  const [statusFirstValue, setStatusFirstValue] = useState<StatusInterface>();
  const [statusSecondValue, setStatusSecondValue] = useState<StatusInterface>();
  const [statusSecondBox, setStatusSecondBox] = useState(false);
  const [productCouponsModal, setProductCouponsModal] = useState(false);
  const [productCharacteristicsModal, setProductCharacteristicsModal] = useState(false);
  const [productDetailsAccessoriesModal, setProductDetailsAccessoriesModal] = useState(false);
  const [productDetailsImagesModal, setProductDetailsImagesModal] = useState(false);
  const [productDetailsMetadataModal, setProductDetailsMetadataModal] = useState(false);
  const [productDetailsMetadataFlagesModal, setProductDetailsMetadataFlagesModal] = useState(false);
  const [productDetailsMetadataSeoModal, setProductDetailsMetadataSeoModal] = useState(false);
  const [productDetailsModal, setProductDetailsModal] = useState(false);
  const [productDetailsCostsModal, setProductDetailsCostsModal] = useState(false);
  const [productDetailsOverviewModal, setProductDetailsOverviewModal] = useState(false);
  const [productOverviewModal, setProductOverviewModal] = useState(false);
  const [productLogModal, setProductLogModal] = useState(false);
  const [productNotesModal, setProductNotesModal] = useState(false);
  const [couponsList, setCouponsList] = useState(getAllProductCouponsData)
  const [focusNote, setFocusNote] = useState("");
  const [productNotes, setProductNotes] = useState([])
  const [productLogsData, setProductLogsData] = useState(getLogsProductData)
  const [page, setPage] = useState(1);
  const [logList, setLogList] = useState([]);
  const [retailListModel, setRetailListModel] = useState(false);
  const [productOverview, setProductOverview] = useState(false);
  const [doesHaveMore, setHaveMore] = useState(true);
  const [retailPriceSchedules, setRetailPriceSchedules] = useState([])
  const [retailPriceSchedulesInital, setRetailPriceInitalSchedules] = useState([])
  const [noteAddId, setNoteAddId] = useState<number>()
  const [starredShow, setStartedShow] = useState(true);
  const [selectedOption, setSelectedOption] = useState('Starred');
  const [id, setId] = useState('');
  const [workStationId, setWorkStationId] = useState(stockWarehouseId);
  const [productAccessorieGoesWithData, setProductAccessorieGoesWithData] = useState(getProductAccessorieGoesWithData);
  const [productAccessorieReplacesData, setProductAccessorieReplacesData] = useState(getProductAccessorieReplacesData);
  const [newAccessorisID, setNewAccessorisID] = useState();
  const [newAccessorisTypeID, setNewAccessorisTypeID] = useState();
  const [productCharacteristicsData, setProductCharacteristicsData] = useState(getProductCharacteristicsData);
  const [productCouponsData, setProductCouponsData] = useState(getProductCouponsData)
  const [removeCouponId, setRemoveCouponsId] = useState()
  const [searchSection, setSearchSection] = useState("")
  const [overviewSearch, setOverViewSearch] = useState("")
  const [overViewMainSearch, setOverViewMainSearch] = useState("")
  const [searchMeasurements, setSearchMeasurements] = useState("")
  const [searchAccessories, setSearchAccessories] = useState("")
  const [searchCharacteristics, setSearchCharacteristics] = useState("")
  const [searchIdentifiers, setSearchIdentifiers] = useState("")
  const [searchSEO, setSearchSEO] = useState("")

  const StarredSectionElement = document.getElementById("StarredSection");
  const OverviewMainSectionElement = document.getElementById("OverviewMainSection");
  const DetailsSectionElement = document.getElementById("DetailsSection");
  const MeasurementsSectionElement = document.getElementById("MeasurementsSection");
  const CostSectionElement = document.getElementById("CostSection");
  const MetadataSectionElement = document.getElementById("MetadataSection");
  const ReportsSectionElement = document.getElementById("ReportsSection");
  const ImagesSectionElement = document.getElementById("ImagesSection");
  const AccessoriesSectionElement = document.getElementById("AccessoriesSection");
  const CharacteristicsSectionElement = document.getElementById("CharacteristicsSection");
  const NotesSectionElement = document.getElementById("NotesSection");
  const CouponsSectionElement = document.getElementById("CouponsSection");
  const LogsSectionElement = document.getElementById("LogsSection");
  const RetailPriceSchedulesElement = document.getElementById("RetailPriceSchedulesSection");

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);
  const ref11 = useRef(null);
  const ref12 = useRef(null);
  const ref13 = useRef(null);
  const ref14 = useRef(null);


  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClick = (ref) => {
    const { current } = ref;
    current?.scrollIntoView({ behavior: "smooth" });
    
    switch (true) {
      case current?.contains(StarredSectionElement):
        setSelectedOption(StarredOption);
        setId(StarredSection);
        break;
      case current?.contains(OverviewMainSectionElement):
        setSelectedOption(OverviewMainOption);
        setId(OverviewMainOption);
        break;  
      case current?.contains(DetailsSectionElement):
        setSelectedOption(OverviewOption);
        setId(DetailsSection);
        break;
      case current?.contains(MeasurementsSectionElement):
        setSelectedOption(DetailsOption);
        setId(MeasurementsSection);
        break;
      case current?.contains(CostSectionElement):
        setSelectedOption(CostOption);
        setId(CostSection);
        break;
      case current?.contains(MetadataSectionElement):
        setSelectedOption(MetadataOption);
        setId(MetadataSection);
        break;
      case current?.contains(ReportsSectionElement):
        setSelectedOption(ReportsOption);
        setId(ReportsSection);
        break;
      case current?.contains(ImagesSectionElement):
        setSelectedOption(ImagesOption);
        setId(ImagesSection);
        break;
      case current?.contains(AccessoriesSectionElement):
        setSelectedOption(AccessoriesOption);
        setId(AccessoriesSection);
        break;
      case current?.contains(CharacteristicsSectionElement):
        setSelectedOption(CharacteristicsOption);
        setId(CharacteristicsSection);
        break;
      case current?.contains(NotesSectionElement):
        setSelectedOption(NotesOption);
        setId(NotesSection);
        break;
      case current?.contains(CouponsSectionElement):
        setSelectedOption(CouponsOption);
        setId(CouponsSection);
        break;
      case current?.contains(LogsSectionElement):
        setSelectedOption(LogsOption);
        setId(LogsSection);
        break;
      case current?.contains(RetailPriceSchedulesElement):
        setSelectedOption(RetailPriceSchedulesOption);
        setId(RetailPriceSchedulesSection);
        break;
      default:
        break;
    }
  };

  const handleSearchSelection = (sectionId: string) => {
    switch (sectionId) {
      case StarredSection:
        setSelectedOption(StarredOption);
        setId(StarredSection);
        break;
      case DetailsSection:
        setSelectedOption(OverviewOption);
        setId(DetailsSection);
        break;
      case MeasurementsSection:
        setSelectedOption(DetailsOption);
        setId(MeasurementsSection);
        break;
      case CostSection:
        setSelectedOption(CostOption);
        setId(CostSection);
        break;
      case MetadataSection:
        setSelectedOption(MetadataOption);
        setId(MetadataSection);
        break;
      case ReportsSection:
        setSelectedOption(ReportsOption);
        setId(ReportsSection);
        break;
      case ImagesSection:
        setSelectedOption(ImagesOption);
        setId(ImagesSection);
        break;
      case AccessoriesSection:
        setSelectedOption(AccessoriesOption);
        setId(AccessoriesSection);
        break;
      case CharacteristicsSection:
        setSelectedOption(CharacteristicsOption);
        setId(CharacteristicsSection);
        break;
      case NotesSection:
        setSelectedOption(NotesOption);
        setId(NotesSection);
        break;
      case CouponsSection:
        setSelectedOption(CouponsOption);
        setId(CouponsSection);
        break;
      case LogsSection:
        setSelectedOption(LogsOption);
        setId(LogsSection);
        break;
      case RetailPriceSchedulesSection:
        setSelectedOption(RetailPriceSchedulesOption);
        setId(RetailPriceSchedulesSection);
        break;
      default:
        break;
    }
  };

  const handleScroll = () => {
    setPage(page + one);
    if (getLogsProductData) {
      handleGetLogsForProduct(page + one);
    }
  };

  const handleSelectStatus = (item) => {
    setStatusFirstValue(item);
    setStatusSecondValue(item);
    setStatusSecondBox(false);
  };

  const getMinMaxStock = () => {
    dispatch(getProductsStockMinMaxStart())
    const payloadForStockMinMax = { stockWarehouseID: workStationId, productID: Number(params?.id) }
    getProductMinMaxStockMutation.mutate(payloadForStockMinMax);
  }

  const startedShow = () => {
    setStartedShow(!starredShow)
  }

  const toggleModal = (modalName) => {
    switch (modalName) {
      case productNotesModalConst:
        setProductNotesModal((prevState) => !prevState);
        break;
      case productCouponsModalConst:
        setProductCouponsModal((prevState) => !prevState);
        break;
      case productCharacteristicsModalConst:
        setProductCharacteristicsModal((prevState) => !prevState);
        break;
      case productDetailsAccessoriesModalConst:
        if (productDetailsAccessoriesModal) setSearchAccessories("");
        setProductDetailsAccessoriesModal((prevState) => !prevState);
        break;
      case productDetailsImagesModalConst:
        setProductDetailsImagesModal((prevState) => !prevState);
        break;
      case productDetailsMetadataModalConst:
        if (productDetailsMetadataModal) setSearchIdentifiers("");
        setProductDetailsMetadataModal((prevState) => !prevState);
        break;
      case productDetailsMetadataFlagesModalConst:
        setProductDetailsMetadataFlagesModal((prevState) => !prevState);
        break;
      case productDetailsMetadataSeoModalConst:
        if (productDetailsMetadataSeoModal) setSearchSEO("");
        setProductDetailsMetadataSeoModal((prevState) => !prevState);
        break;
      case productDetailsMeasurementModalConst:
        if (productDetailsModal) setSearchMeasurements("");
        setProductDetailsModal((prevState) => !prevState);
        break;
      case productDetailsCostsModalConst:
        if (productDetailsCostsModal) setSearchSection("");
        setProductDetailsCostsModal((prevState) => !prevState);
        break;
      case productDetailsOverviewModalConst:
        if (productDetailsOverviewModal) setOverViewSearch("");
        setProductDetailsOverviewModal((prevState) => !prevState);
        break;
      case productOverviewModalConst:
        if (productOverviewModal)
        setProductOverviewModal((prevState) => !prevState);
        break;  
      case productLogModalConst:
        setProductLogModal((prevState) => !prevState);
        break;
      case retailListModelConst:
        setRetailListModel((prevState) => !prevState);
        break;
      case productOverviewConst:
        if (productOverview) setOverViewMainSearch("");
        setProductOverview((prevState) => !prevState);
        break;
      default:
        break;
    }
  };

  const addNotesOpen = (text) => () => {
    setFocusNote(text);
    toggleModal(productNotesModalConst)
  }

  const backPage = () => {
    navigate(Paths.product)
  }

  const getRetailPriceApiCall = () => {
    const body = { vendorId: details?.vendorId, productGroupId: details?.productGroupId }
    dispatch(getRetailPriceScedulesProductStart());
    getRetailPriceScedulesProductsMutation.mutate(body);
  }

  const handleSearchSection = (id: string) => () => {
    setSearchSection(id)
    toggleModal(productDetailsCostsModalConst)
  }

  const handleOverviewSection = (id: string) => () => {
    setOverViewMainSearch(id)
    toggleModal(productOverviewConst)
  }
  const handleDetailsOverviewSection = (id: string) => () => {
    setOverViewSearch(id)
    toggleModal(productDetailsOverviewModalConst)
  }

  const handleNotesSearch = (text: string) => () => {
    setFocusNote(text)
    // handleOpenCloseNotesModal()
    toggleModal(productNotesModalConst)
  }

  const handleMeasurementsSection = (id: string) => () => {
    setSearchMeasurements(id)
    toggleModal(productDetailsMeasurementModalConst)
  }

  const handleSearchAccessories = (id: string) => () => {
    setSearchAccessories(id)
    toggleModal(productDetailsAccessoriesModalConst)
  }

  const handleSearchCharacteristics = (id: string) => () => {
    setSearchCharacteristics(id)
    toggleModal(productCharacteristicsModalConst)
  }

  const handleSearchIdentifiers = (id: string) => () => {
    setSearchIdentifiers(id)
    toggleModal(productDetailsMetadataModalConst)
  }

  const handleSearchSEO = (id: string) => () => {
    setSearchSEO(id)
    toggleModal(productDetailsMetadataSeoModalConst)
  }

  const getProductDetailApiCall = () => {
    const body = { productsId: Number(params?.id) }
    dispatch(getSingleProductStart());
    getSingleProductsMutation.mutate(body);
  }

  const removeAllNotes = () => {
    setProductNotes([])
    const body = { productsId: Number(params?.id) }
    dispatch(removeAllNotesProductStart());
    removeAllNotesProductsMutation.mutate(body);
  }

  const removeNote = (ids) => {
    const newValueNotesData = productNotes?.filter(item => item?.noteId !== ids)
    setProductNotes(newValueNotesData)

    const body = { productId: Number(params?.id), noteId: Number(ids) }
    dispatch(removeNotesNotesStart());
    removeNotesProductsMutation.mutate(body);
  }

  const updateNotes = (value, ids) => {
    const date = new Date()

    if (ids) {
      const newValueNotesData = productNotes?.find(item => item?.noteId === ids);
      const dataReplace = {};
      if (newValueNotesData) {
        dataReplace['note'] = value,
          dataReplace['modifiedByName'] = LoggedInUserFullName,
          dataReplace['modifiedBy'] = DefaultLoginId
        dataReplace['modifiedAt'] = date.toString()
        dataReplace['noteId'] = newValueNotesData.noteId
        dataReplace['productId'] = Number(params?.id)
      }

      setProductNotes(prevItems => {
        return prevItems.map(item => {
          if (item.noteId === ids) {
            return dataReplace;
          }
          return item;
        });
      });
      const body = { productId: Number(params?.id), noteId: Number(ids), note: value, handlerId: Number(DefaultLoginId) }
      dispatch(updateNotesProductStart());
      updateNotesProductsMutation.mutate(body);

    } else {
      const id = productNotes?.length ? Math.max(...productNotes?.map(item => item.noteId)) + 1 : 0; // Generate a unique id
      setNoteAddId(id)
      const newItem = {
        noteId: Number(id),
        productId: Number(params?.id),
        note: value,
        modifiedAt: date.toString(),
        modifiedBy: DefaultLoginId,
        modifiedByName: LoggedInUserFullName
      };

      if (productNotes) {
        setProductNotes(prevItems => [newItem, ...prevItems]);
      } else {
        setProductNotes([newItem])
      }

      const body = { productId: Number(params?.id), noteId: Number(id), note: value, handlerId: Number(DefaultLoginId) }
      dispatch(addNotesProductStart());
      addNotesProductsMutation.mutate(body);

    }
  }

  const openAllLinks = () => {
    const links = [
      `${Paths.product}/${Number(params?.id)}/${Paths.reports}/${Paths.ledger}`,
      `${Paths.product}/${Number(params?.id)}/${Paths.reports}/${Paths.itemHistory}`,
      `${Paths.product}/${Number(params?.id)}/${Paths.reports}/${Paths.quotesHistory}?productName=${details?.productName}`,
      `${Paths.product}/${Number(params?.id)}/${Paths.reports}/${Paths.salesHistory}?productName=${details?.productName}`,
      `${Paths.product}/${Number(params?.id)}/${Paths.reports}/${Paths.purchaseHistoryReport}?productName=${details?.productName}`,
      `${Paths.product}/${Number(params?.id)}/${Paths.reports}/${Paths.invoiceHistoryReport}?productName=${details?.productName}`,
      `${Paths.product}/${Number(params?.id)}/${Paths.reports}/${Paths.posPriceChangeReport}?productName=${details?.productName}`,
      `${Paths.product}/${Number(params?.id)}/${Paths.reports}/${Paths.movingAvgCostChangeReport}?productName=${details?.productName}`
    ];
    links.forEach(link => window.open(link, commonConstants.target));
  }

  const checkObject = (obj) => {
    if (obj) {
      for (const key in obj) {
        if (Array.isArray(obj[key]) && obj[key].length > 0) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  useEffect(() => {
    if (getLogsProductData?.page === one) {
      setProductLogsData(getLogsProductData)
    }
    if (getLogsProductData?.data) {
      const data = getLogsProductData || [];
      if (getLogsProductData?.page === one) {
        setLogList(data?.data);
        setHaveMore(data.isPaginated)
      } else {
        setLogList((prevState) => [...prevState, ...data?.data]);
        setHaveMore(data.isPaginated);
      }
    } else {
      setHaveMore(false);
    }
  }, [getLogsProductData])
  
  useEffect(() => {
    if (getRetailPriceScedulesProductData?.data) {
      const retailPriceSchedulesData = getRetailPriceScedulesProductData?.data;
      const updatedRetailPriceSchedules = retailPriceSchedulesData?.map(schedule => {
        const hasBreakQty = schedule?.vendorProductGroups?.some(group => group.breakQty !== null);
        return {
          ...schedule,
          hasBreakQty
        };
      });
      setRetailPriceSchedules(updatedRetailPriceSchedules)
      setRetailPriceInitalSchedules(updatedRetailPriceSchedules)
    }
  }, [getRetailPriceScedulesProductData?.data])

  useEffect(() => {
    id && navigate(generatePath(`${Paths.product}/${params?.id}#${id}`, { id }));
  }, [id])

  useEffect(() => {
    // Get Product detail
    const body = { productsId: Number(params?.id) }
    dispatch(getSingleProductStart());
    getSingleProductsMutation.mutate(body);

    //Get Ticket Count
    dispatch(getProductTicketsCountStart());
    getProductsTicketsCountMutation.mutate(body);

    // Get Stockwarhouse wise Product Qty
    dispatch(stockByWarehouseStart([Number(params?.id)]));
    getMinMaxStock();
    // Get All Notes 
    dispatch(getProductsNotesStart())
    getProductNotesMutation.mutate(body);
    // Get All Coupons 
    dispatch(getProductsCouponsStart())
    getProductCouponsMutation.mutate(body);
    // Get All Characteristics 
    dispatch(getProductsCharacteristicsStart())
    getProductCharacteristicsMutation.mutate(body);
    // Get Accessories For Goes with
    const goesWithPayload = { productsId: Number(params?.id), relativeType: goesWith }
    dispatch(getProductsAccessorieGoesWithStart())
    getProductAccessorieGoesWithMutation.mutate(goesWithPayload);
    // Get Accessories For Replaces
    const replacesPayload = { productsId: Number(params?.id), relativeType: replaces }
    dispatch(getProductsAccessorieReplacesStart())
    getProductReplacesMutation.mutate(replacesPayload);

    dispatch(getCategoriesMenuProductStart());
    getCategoriesMenuProductsMutation.mutate();

    handleGetLogsForProduct(goesWith)
    if (window.location.hash.includes(sectionId)) {
      const availabilitySectionNode = document.getElementById(sectionId);

      setTimeout(() => {
        availabilitySectionNode &&
          availabilitySectionNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 200); // Adjust the delay time as needed
    }
  }, [params?.id]);

  useEffect(() => {
    const currentStatus = productStatusOptions.filter((status) => Number(status.id) === Number(activeFlag))
    setStatusFirstValue(currentStatus?.length ? currentStatus[0] : productStatusDefaultOptions);
    setStatusSecondValue(currentStatus?.length ? currentStatus[0] : productStatusDefaultOptions);
  }, [activeFlag])

  useEffect(() => {
    setProductNotes(getProductNotesData)
  }, [getProductNotesData])

  useEffect(() => {
    if (removeMinMaxBatchProductData) {
      dispatch(snakbarShow({ message: stockMinMaxRemoved }))
      getMinMaxStock()
    }
  }, [removeMinMaxBatchProductData])


  useEffect(() => {
    if (getSingleProductData) {
      const { details } = getSingleProductData || {}
      if (details?.vendorId && details?.productGroupId) {
        const body = { vendorId: details?.vendorId, productGroupId: details?.productGroupId }
        dispatch(getRetailPriceScedulesProductStart());
        getRetailPriceScedulesProductsMutation.mutate(body);
      }
      if (window.location.hash.includes(sectionId)) {
        const availabilitySectionNode = document.getElementById(sectionId);
        handleSearchSelection(sectionId)
        setTimeout(() => {
          availabilitySectionNode &&
            availabilitySectionNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 200); // Adjust the delay time as needed
      }
    }
  }, [getSingleProductData])

  useEffect(() => {
    if (getSingleProductError) {
      backPage()
    }
  }, [getSingleProductError])

  useEffect(() => {
    if (!noteAddId) {
      const body = { productsId: Number(params?.id) }
      // Get All Notes
      dispatch(getProductsNotesStart())
      getProductNotesMutation.mutate(body);
    }
  }, [isAddProductsNotes])

  useEffect(() => {
    if (updateFlagesProductData && updateFlagesProductData?.data) {
      // Get Product detail
      getProductDetailApiCall()
      handleGetLogsForProduct(page)
      toggleModal(productDetailsMetadataFlagesModalConst)
    }
  }, [updateFlagesProductData])

  useEffect(() => {
    if (updateMeasurementsProductData && updateMeasurementsProductData?.data) {
      // Get Product detail
      getProductDetailApiCall()
      handleGetLogsForProduct(page)
      toggleModal(productDetailsMeasurementModalConst)
      // Also call stock min max get
      getMinMaxStock();
    }
    if (updateBasicProductData && updateBasicProductData?.data) {
      // Get Product detail
      getProductDetailApiCall()
      handleGetLogsForProduct(page)
      toggleModal(productDetailsOverviewModalConst)
    }
    if (updateSeoProductData && updateSeoProductData?.data) {
      // Get Product detail
      getProductDetailApiCall()
      handleGetLogsForProduct(page)
      toggleModal(productDetailsMetadataSeoModalConst)
    }
    if (updateIdentifiersProductData && updateIdentifiersProductData?.data) {
      // Get Product detail
      getProductDetailApiCall()
      handleGetLogsForProduct(page)
      toggleModal(productDetailsMetadataModalConst)
    }
    if (updateImagesProductData && updateImagesProductData?.data) {
      // Get Product detail
      getProductDetailApiCall()
      handleGetLogsForProduct(page)
      toggleModal(productDetailsImagesModalConst)
    }
    if ((updateCostAndPriceProductData && updateCostAndPriceProductData?.data)) {
      getProductDetailApiCall()
      handleGetLogsForProduct(page)
      toggleModal(productDetailsCostsModalConst)
    }
    if (removeClearancePriceProductData && removeClearancePriceProductData?.data) {
      getProductDetailApiCall()
      handleGetLogsForProduct(page)
      dispatch(snakbarShow({ message: successRemoveClearance }))
    }
    if (removeDiscontinuedPriceProductData && removeDiscontinuedPriceProductData?.data) {
      getProductDetailApiCall()
      handleGetLogsForProduct(page)
      dispatch(snakbarShow({ message: successRemoveDiscontinued }))
    }

    if (deletePriceScedulesProductData && deletePriceScedulesProductData?.data) {
      getRetailPriceApiCall()
      handleGetLogsForProduct(page)
      dispatch(snakbarShow({ message: successRemovePriceSchedule }))
    }

    if (updateRetailPriceScedulesProductData && updateRetailPriceScedulesProductData?.data) {
      getRetailPriceApiCall()
      toggleModal(retailListModelConst)
    }

    if (addMinMaxBatchProductData && addMinMaxBatchProductData?.data) {
      // Show success snack bar
      dispatch(snakbarShow({ message: batchAddedSuccess }));
      // update the productId timeStamp
      const minMaxBatchData = getAddToBatchProduct()
      if (minMaxBatchData?.length) {
        const payloadForStockMinMax = { productId: Number(params?.id), timeStamp: new Date().getTime() }
        const updatedArray = [...minMaxBatchData];
        const existingIndex = updatedArray.findIndex(item => item.productId === Number(params?.id));
        if (existingIndex !== -1) {
          updatedArray[existingIndex] = payloadForStockMinMax;
        }
      }
      dispatch(clearMinMaxBatchProductStart())
    }

    if (addMinMaxBatchProductError !== null) {
      // Show error snack bar
      dispatch(snakbarShow({ message: batchAddError }));
      // reset the productId timeStamp
      const minMaxBatchData = getAddToBatchProduct()
      if (minMaxBatchData?.length) {
        const payloadForStockMinMax = { productId: Number(params?.id) }
        const updatedArray = [...minMaxBatchData];
        const existingIndex = updatedArray.findIndex(item => item.productId === Number(params?.id));
        if (existingIndex !== -1) {
          updatedArray[existingIndex] = payloadForStockMinMax;
        }
      }
      dispatch(clearMinMaxBatchProductStart())
    }

    if (updateOverviewProductData && updateOverviewProductData?.data) {
      // Get Product detail
      getProductDetailApiCall()
      handleGetLogsForProduct(page)
      toggleModal(productOverviewConst)
    } 

  }, [updateMeasurementsProductData, updateBasicProductData, updateSeoProductData, updateIdentifiersProductData, updateImagesProductData, updateCostAndPriceProductData, removeClearancePriceProductData, removeDiscontinuedPriceProductData, deletePriceScedulesProductData, updateRetailPriceScedulesProductData, addMinMaxBatchProductData, addMinMaxBatchProductError , updateOverviewProductData , ])

  // Coupon start  

  const getProductCouponsDataList = () => {
    setTimeout(() => {
      if (isAddProductsCoupons) {
        const body = { productsId: Number(params?.id) }
        dispatch(getProductsCouponsStart())
        getProductCouponsMutation.mutate(body);
      }
    }, 1000);
  }

  const AddCoupon = (selectedCouponId, selectedPrice, selectedString) => {
    let couponId = selectedCouponId;
    let price = selectedPrice;
    if (selectedString) {
      const filteredData = couponsList?.filter((item) => {
        if (selectedString === item.couponCode) {
          return (
            item
          )
        }
      })
      const { couponID, couponDiscount } = filteredData[0] || {}
      couponId = couponID
      price = couponDiscount
    }
    const isExits = productCouponsData?.filter((item) => item?.coupon?.couponID === couponId)
    if (isExits?.length) {
      dispatch(apiFiledRequest({ state: true, message: couponAlreadyExits }))
    } else {
      const body = { couponId: Number(couponId), couponItemPrice: Number(price), productId: Number(params?.id) };
      dispatch(addProductsCouponsStart());
      addCouponsMutation.mutate(body);
    }

  }

  const couponRemoveById = (ids) => () => {
    setRemoveCouponsId(ids)
    const permission = window.confirm(confirmDeleteForCoupon)
    if (!permission) return
    const body = { rowID: Number(ids) }
    dispatch(removeProductsCouponsStart());
    removeCouponsByRowIdMutation.mutate(body);
    const newValueCouponsData = productCouponsData?.filter(item => item?.rowID !== ids)
    setProductCouponsData(newValueCouponsData)
  }

  const removeAllCoupons = () => {
    const permission = window.confirm(confirmDeleteForCoupon)
    if (!permission) return
    const body = { productId: Number(params?.id) }
    dispatch(removeAllProductsCouponsStart())
    removeAllCouponsMutation.mutate(body);
    setTimeout(() => {
      setProductCouponsData([])
    }, 1000)
  }

  const handleChangePage = (page) => {
    setPage(page)
  }

  const handleGetLogsForProduct = (pageNum) => {
    //Get Logs 
    const logsPayload = { productID: Number(params?.id), page: pageNum, size: 10 }
    dispatch(getLogsProductStart())
    getLogsProductsMutation.mutate(logsPayload);
  }
  useEffect(() => {
    if (!getAllProductCouponsData?.length) {
      dispatch(getAllProductsCouponsStart());
      getCouponsAllMutation.mutate();

    }
  }, [])

  useEffect(() => {
    if (getAllProductCouponsData?.length) {
      const today = new Date();
      const newData = getAllProductCouponsData.filter((coupone) => coupone?.couponStatus !== notActiveCouponStatus && new Date(coupone?.endDate) >= today)
      setCouponsList(newData)
    }
  }, [getAllProductCouponsData])

  useEffect(() => {
    if (isAddProductsCoupons) {
      getProductCouponsDataList()
    }
  }, [isAddProductsCoupons])

  useEffect(() => {
    if (getProductCouponsData?.length) {
      const reversedProductCouponsData = [...getProductCouponsData].reverse()
      setProductCouponsData(reversedProductCouponsData)
    }
  }, [getProductCouponsData])

  useEffect(() => {
    if (removeAllProductsCouponsError !== null) {
      setProductCouponsData(getProductCouponsData)
    }

    if (removeProductsCouponsError !== null) {
      const foundObject = getProductCouponsData?.find(item => item?.rowID === removeCouponId);

      if (foundObject) {
        if (productCouponsData?.length) {
          setProductCouponsData(prevData => [...prevData, foundObject]);
        } else {
          setProductCouponsData([foundObject])
        }
      }

    }
  }, [removeProductsCouponsError, removeAllProductsCouponsError])
  // Coupon end

  // Characteristic start

  const removeAllCharacteristics = () => {
    // Remove All
    const permission = window.confirm(confirmDeleteForCharacteristics)
    if (!permission) return
    const replacesPayload = { productsId: Number(params?.id) }
    dispatch(removeAllCharacteristicsStart());
    removeAllCharacteristicsMutation.mutate(replacesPayload);
    setTimeout(() => {
      setProductCharacteristicsData([])
    }, 1000)

  }

  const CharacteristicsRemoveById = (ids) => () => {
    const permission = window.confirm(confirmDeleteForCharacteristics)
    if (!permission) return
    const newValueGoesData = productCharacteristicsData?.filter(item => item?.productCharacteristicId !== ids)
    setProductCharacteristicsData(newValueGoesData)
    const body = { productId: Number(params?.id), characteristicId: ids }
    dispatch(removeParticularCharacteristicsStart());
    removeParticularCharacteristicsMutation.mutate(body);
    dispatch(updateProductsCharacteristics(newValueGoesData))
  }
  const addCharacteristics = (data) => {
    const payload = [];
    for (let i = 0; i < data.length; i++) {
      const obj = {
        "characteristicId": data[i]["productCharacteristicId"],
        "characteristicTypeId": data[i]["productCharacteristicTypeID"],
        "characteristicTypeValue1": data[i]["productCharacteristicValue"],
        "characteristicTypeValue2": data[i]["productCharacteristicValue2"],
      };
      payload.push(obj);
    }
    const body = {
      productId: Number(params?.id),
      characteristics: payload
    }
    dispatch(AddCharacteristicStart());
    AddCharacteristicMutation.mutate(body);
    dispatch(updateProductsCharacteristics(data))
  };

  const addNewAccessories = (id, type, productDetail) => {
    if (type === 1) {
      addGoesAccessories(id, productDetail, type)
    } else {
      addReplaceAccessories(id, productDetail, type)
    }
  }

  const addGoesAccessories = (id, productDetail, relationType) => {
    setNewAccessorisID(id);
    setNewAccessorisTypeID(relationType);
    if (productAccessorieGoesWithData?.length >= 12) {
      dispatch(snakbarShow({ message: accessoriesLimitExits }))
      return false;
    } else {

      let isExits = false;
      productAccessorieGoesWithData?.filter((item) => {
        if (item?.productToProductID === id) {
          isExits = true;
          dispatch(snakbarShow({ message: accessoriesAlreadyExits }))
          return false;
        }
      })
      if (!isExits) {
        const newData = {
          productName: productDetail.productName
          , thumbImg: productDetail.thumbImg, manufacturerCode: productDetail.manufacturerCode, productToProductID: Number(id)
        }
        setProductAccessorieGoesWithData([newData, ...productAccessorieGoesWithData]);
        const body = { productID2: Number(id), relationType: Number(relationType), productID1: Number(params?.id) };
        dispatch(addProductsAccessorieStart());
        addAccessoriesAccessorieWithMutation.mutate(body);
      }
    }
  }

  const addReplaceAccessories = (id, productDetail, relationType) => {
    setNewAccessorisID(id);
    setNewAccessorisTypeID(relationType);
    if (productAccessorieReplacesData?.length >= 12) {
      dispatch(snakbarShow({ message: accessoriesLimitExits }))
      return false;
    }
    else {
      let isExits = false;
      productAccessorieReplacesData?.filter((item) => {
        if (item?.productToProductID === id) {
          isExits = true;
          dispatch(snakbarShow({ message: accessoriesAlreadyExits }))
          return false;
        }
      })
      if (!isExits) {
        const newData = {
          productName: productDetail.productName
          , thumbImg: productDetail.thumbImg, manufacturerCode: productDetail.manufacturerCode, productToProductID: Number(id)
        }
        setProductAccessorieReplacesData([newData, ...productAccessorieReplacesData]);
        const body = { productID2: Number(id), relationType: Number(relationType), productID1: Number(params?.id) };
        dispatch(addProductsAccessorieStart());
        addAccessoriesAccessorieWithMutation.mutate(body);
      }
    }
  }


  const addNewCharacteristic = (id, name) => {
    if (productCharacteristicsData?.length >= 20) {
      dispatch(snakbarShow({ message: characteristicLimitExits }))
      return false;
    } else {
      let isExits = false;
      let characteristicId
      if (id) {
        characteristicId = id
      } else {
        const productCharacteristicsObject = GetAllCharacteristicTypeList?.filter((item) =>
          item?.productCharacteristicTypeName === name ? item : null
        )
        characteristicId = productCharacteristicsObject?.[0]?.productCharacteristicTypeId
      }
      productCharacteristicsData?.filter((item) => {
        if (item?.productCharacteristicTypeID === characteristicId) {
          isExits = true;
          dispatch(snakbarShow({ message: characteristicAlreadyExits }))
          return false;
        }
      })
      if (!isExits) {
        const newData = { productCharacteristicId: 0, productCharacteristicTypeID: characteristicId, productCharacteristicTypeName: name, productCharacteristicValue: "", productCharacteristicValue2: "" }
        setProductCharacteristicsData([newData, ...productCharacteristicsData]);
      }
    }
  }

  useEffect(() => {
    if (AddCharacteristicData) {
      toggleModal(productCharacteristicsModalConst)
    }

    if (AddProductCharacteristicsError !== null) {
      setProductCharacteristicsData(getProductCharacteristicsData)
    }
  }, [AddCharacteristicData, AddProductCharacteristicsError])

  useEffect(() => {
    if (!GetAllCharacteristicTypeList?.length) {
      dispatch(GetAllCharacteristicTypeListStart());
      GetAllCharacteristicTypeListMutation.mutate();
    }
  }, [])

  useEffect(() => {
    if ((removeAllProductNotesData || removeProductNotesData) && !productNotes?.length) {
      toggleModal(productNotesModalConst)
    }
  }, [removeAllProductNotesData, removeProductNotesData])

  useEffect(() => {
    setProductCharacteristicsData(getProductCharacteristicsData)
  }, [getProductCharacteristicsData])

  useEffect(() => {
    if (removeAllCharacteristicsError || removeParticularCharacteristicsError || AddProductCharacteristicsError) {
      setProductCharacteristicsData(getProductCharacteristicsData)
    }
    if (removeAllCharacteristicsData) {
      toggleModal(productCharacteristicsModalConst)
    }
  }, [removeAllCharacteristicsError, removeParticularCharacteristicsError, AddProductCharacteristicsError, removeAllCharacteristicsData])
  // Characteristic end

  // Accessories start

  const removeAllAccessories = (type) => () => {
    // Remove All
    const permission = window.confirm(confirmDeleteForAccessories)
    if (!permission) return
    const replacesPayload = { productsId: Number(params?.id), relativeType: type }
    if (type === 1) {
      setProductAccessorieGoesWithData([])
    } else {
      setProductAccessorieReplacesData([])
    }
    dispatch(removeAllRelatedItemsByProductStart());
    removeAllRelatedItemsByProductMutation.mutate(replacesPayload);
  }
  const AccessoriesRemoveById = (ids: number, type: accessoriesKey) => () => {
    const permission = window.confirm(confirmDeleteForAccessories)
    if (!permission) return
    const removeAccessory = (data: any[], setAccessorData: { (value: SetStateAction<AccessorieI[]>): void; (value: SetStateAction<AccessorieI[]>): void; (arg0: any): void; }) => {
      const newValue = data?.filter(item => item?.productToProductID !== ids);
      setAccessorData(newValue);
    };

    if (type === replaces) {
      removeAccessory(productAccessorieReplacesData, setProductAccessorieReplacesData);
    } else if (type === goesWith) {
      removeAccessory(productAccessorieGoesWithData, setProductAccessorieGoesWithData);
    }
    const body = { productToProductID: Number(ids) }
    dispatch(removeParticularAccessoriesStart());
    removeParticularAccessoriesMutation.mutate(body);
  }
  useEffect(() => {
    if (addProductAccessorieError) {
      if (newAccessorisTypeID == 1) {
        const newValueNotesData = getProductAccessorieGoesWithData?.filter(item => item?.productToProductID !== newAccessorisID)
        setProductAccessorieGoesWithData(newValueNotesData)
      }
      if (newAccessorisTypeID == 2) {
        const newValueNotesData = getProductAccessorieReplacesData?.filter(item => item?.productToProductID !== newAccessorisID)
        setProductAccessorieReplacesData(newValueNotesData)
      }
    }
  }, [addProductAccessorieError])

  useEffect(() => {
    setProductAccessorieGoesWithData(getProductAccessorieGoesWithData)
    setProductAccessorieReplacesData(getProductAccessorieReplacesData)
  }, [getProductAccessorieGoesWithData, getProductAccessorieReplacesData])

  useEffect(() => {
    if (removeParticularAccessoriesError || removeAllRelatedItemsByProductDataError) {
      setProductAccessorieGoesWithData(getProductAccessorieGoesWithData)
      setProductAccessorieReplacesData(getProductAccessorieReplacesData)
    }
  }, [removeAllRelatedItemsByProductDataError, removeParticularAccessoriesError])
  // Accessories end

  const isShow = checkObject(starredProductsDetails);

  return (
    <div>
      <PosHelmet title={`Product Detail ${productName ? "- " + productName : ""} - ${params?.id}`} metaName="Product Detail" content="Product Detail" />
      <Container className="product-detail-container new-container">
        <Grid container className="">
          <Grid item xs={12} sm={12} md={3}>
            {getSingleProduct ? (
              <Skeleton
                loading={getSingleProduct} // pass the isLoading state
                lineCount={11} // set a custom amount of lines
              />
            ) : (
              <div className="sidebar is-sticky">
                <div className="d-flex product-detail-sidebar align-items-center mb-3">
                  <IconButton
                    name="chevron_left"
                    iconSize="20"
                    size="40"
                    onClick={backPage}
                    type="secondary"
                    className="product-detail-back-btn"
                    loading={false}
                    disabled={false}
                    title="Products"
                  />
                  <span className="product-detail-back-btn span-text">{`/ ${params.id}`}</span>
                </div>
                <ProductDetailsSidebar
                  handleClick={handleClick}
                  ref1={ref1}
                  ref2={ref2}
                  ref3={ref3}
                  ref4={ref4}
                  ref5={ref5}
                  ref6={ref6}
                  ref7={ref7}
                  ref8={ref8}
                  ref9={ref9}
                  ref10={ref10}
                  ref11={ref11}
                  ref12={ref12}
                  ref13={ref13}
                  ref14={ref14}
                  selectedOption={selectedOption}
                  distributor={distributor}
                  productId={params?.id}
                  getProductTicketsCountData={getProductTicketsCountData}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Grid container className="">
              <Grid
                item
                xs={12}
                sm={12}
                className="d-flex product-detail-top-info-sec"
              >
                {
                  (getSingleProduct) ?
                    <div className="pl-5 w-100 mb-4">
                      <Skeleton loading={getSingleProduct} // pass the isLoading state
                        lineCount={3} // set a custom amount of lines
                      /> </div> :
                    <>
                      <div className="product-detail-top-left-info-sec">
                        <div className="main-detail-product-code">
                          <span>{manufacturerCode}</span>
                        </div>
                        <div className="main-detail-product-title">
                          <span>{manufacturerName}</span>
                        </div>
                        <div className="main-detail-product-desc">
                          <span>{productName}</span>
                        </div>
                        <ProductStatusShow
                          statusValue={statusFirstValue}
                        />
                        <div className="product-info-pill-sec d-flex mt-3">
                          {clearance?.currentPrice ? <div className="clearance-product-pill product-info-pill mr-2 cursor-pointer" onClick={handleSearchSection(Clearance)}>
                            <span className="text-white">
                              {typeClearance}
                            </span>
                          </div> : null}
                          {discontinued?.currentPrice ? <div className="discontinued-product-pill product-info-pill mr-2 cursor-pointer" onClick={handleSearchSection(Discontinued)}>
                            <span className="text-black">
                              {typeDiscontinued}
                            </span>
                          </div> : null}
                          {qtyInStock <= 0 ? <div className="out-stock-product-pill product-info-pill">
                            <span className="text-white">
                              {outStock}
                            </span>
                          </div> : null}
                        </div>
                        <div className="d-flex flex-wrap">
                          <div className="product-top-btns">
                            {!clearance ? <PosButton
                              variant="contained"
                              className="btn product-detail-medium-btn mt-4"
                              startIcon={<LocalOfferIcon />}
                              onClick={handleSearchSection(Clearance)}
                            >
                              {setAsClearance}
                            </PosButton> : null}
                          </div>
                          <div className="product-top-btns">
                            {!discontinued ? <PosButton
                              variant="contained"
                              className="btn product-detail-medium-btn mt-4"
                              startIcon={<DoDisturbIcon />}
                              onClick={handleSearchSection(Discontinued)}
                            >
                              {setAsDiscontinued}
                            </PosButton> : null}
                          </div>
                          {/* Require later */}
                          {/* <div className="product-top-btns">
                 <PosButton
                   variant="contained"
                   className="btn product-detail-medium-btn mt-4"
                   startIcon={<ContentCopyIcon />}
                 >
                   {cloneAsPending}
                 </PosButton>
               </div> */}
                        </div>
                      </div>
                      <div className="image-container product-detail-top-right-img">
                        <PosImage
                          alt="image-icon"
                          className="product-image-container"
                          src={imgDocs ? imgDocs?.bigImg : defaultIcon}
                        />
                      </div>
                    </>
                }
              </Grid>
            </Grid>
            <Grid container className="">
              <Grid item xs={12}>
                <div className={`${(!getSingleProduct) ? "product-detail-section-bottom-border product-detail-first-border" : ""}`} />
                {!getSingleProduct ? <div className="search-sticky">
                  <PosInput
                    variant="filled"
                    id="filled-basic"
                    type="text"
                    className="product-detail-search-input search-input-focus"
                    autoFocus
                    value={searchTerm}
                    placeholder={productSearchDetail}
                    onChange={handleChange}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div> : null}
                {(!searchTerm && !getSingleProduct) ?
                  <>
                    <div
                      className="d-flex justify-content-between main-title-section product-detail-section-bottom-border"
                      ref={ref1}
                      id="StarredSection"
                    >
                      <div className="sec-main-title mb-2">
                        <span>{starred}</span>
                      </div>
                      <div className="up-downb-arrow" onClick={startedShow}>
                        <PosImage src={upDownArrow} alt="add product" />
                      </div>
                    </div>
                    {starredShow ?
                      !isShow ?
                        <div className='group-wrapper'>
                          <div className='group-content'>
                            <p className='schedules-empty-state'>
                              {starredStart}
                              <MaterialIcon icon={"star"} size="20" classes="mui-starred-icon" />
                              {starredEnd}
                            </p>
                          </div>
                        </div>
                        : <div>
                          <ProductDetailsOverview
                            isStarred
                            statusValue={statusSecondValue}
                            searchText={searchTerm}
                            handleDetailsOverviewSection={handleDetailsOverviewSection}
                          />
                          <ProductDetailsSection isStarred searchText={searchTerm} handleMeasurementsSection={handleMeasurementsSection} />
                          <ProductDetailsCosts isStarred searchText={searchTerm} handleSearchSection={handleSearchSection} />
                          <ProductsDetailsMetadata isStarred
                            searchText={searchTerm}
                            handleOpenCloseMetadataModal={() => toggleModal(productDetailsMetadataModalConst)}
                            handleOpenCloseMetadataFlagesModal={() => toggleModal(productDetailsMetadataFlagesModalConst)}
                            handleOpenCloseMetadataSeoModal={() => toggleModal(productDetailsMetadataSeoModalConst)}
                            handleSearchIdentifiers={handleSearchIdentifiers}
                            handleSearchSEO={handleSearchSEO}
                          />
                          <ProductDetailsImages isStarred searchText={searchTerm} />
                          <ProductDetailsAccessories isStarred searchText={searchTerm} productAccessorieReplacesData={productAccessorieReplacesData} productAccessorieGoesWithData={productAccessorieGoesWithData} handleSearchAccessories={handleSearchAccessories} />
                          <ProductDetailsNotes isStarred searchText={searchTerm} notes={productNotes} handleNotesSearch={handleNotesSearch} />
                          <ProductDetailsCharacteristics productCharacteristicsData={productCharacteristicsData} isStarred searchText={searchTerm} handleSearchCharacteristics={handleSearchCharacteristics} />
                          <ProductDetailsReports isStarred searchText={searchTerm} />
                          <ProductDetailsCoupons productCouponsData={productCouponsData} isStarred searchText={searchTerm} />
                        </div> : null}
                  </>
                  : null
                }
                <div className={`${!getSingleProduct ? "product-detail-overview-border" : ""}`} />
                <div >
                  {
                    (getSingleProduct) ?
                      <div
                        className="d-flex justify-content-between main-title-section overview-tab pl-5 w-100 mb-3">
                        <Skeleton loading={getSingleProduct} // pass the isLoading state
                          lineCount={1} // set a custom amount of lines
                        />
                      </div>
                      :
                      !searchTerm ? <> <div
                        className="d-flex justify-content-between main-title-section overview-tab"
                        ref={ref14}
                        id="OverviewMainSection"
                      >
                        <div className="sec-main-title">
                          <span>{Overview}</span>
                        </div>
                        <div className="edit-main-btn">
                          <PosButton
                            variant="contained"
                            className="btn-discard btn font-size title-edit-section"
                            onClick={() => toggleModal(productOverviewConst)}

                          >
                            {edit}
                          </PosButton>
                        </div>
                      </div> <div className="product-detail-section-bottom-border" /> </> : null
                  }

                  {
                    (getSingleProduct) ?
                      <div className="pl-5 w-100">
                        <Skeleton loading={getSingleProduct} // pass the isLoading state
                          lineCount={9} // set a custom amount of lines
                        /> </div> :
                      !searchTerm ?
                        <ProductOverview
                          isStarred={false}
                          searchText={searchTerm}
                          handleOverviewSection={handleOverviewSection}
                          handleSearchSection={handleSearchSection}
                        /> : null
                  }
                </div>
                {getSingleProductData ? <>
                  {
                    !searchTerm ? <> <div
                      className="d-flex justify-content-between main-title-section"
                      ref={ref2}
                      id="DetailsSection"
                    >
                      <div className="sec-main-title">
                        <span>{Details}</span>
                      </div>
                      <div className="edit-main-btn">
                        <PosButton
                          variant="contained"
                          className="btn-discard btn font-size title-edit-section"
                          onClick={() => toggleModal(productDetailsOverviewModalConst)}

                        >
                          {edit}
                        </PosButton>
                      </div>
                    </div> <div className="product-detail-section-bottom-border" /> </> : null
                  }
                  <ProductDetailsOverview
                    isStarred={false}
                    statusValue={statusSecondValue}
                    searchText={searchTerm}
                    handleDetailsOverviewSection={handleDetailsOverviewSection}
                  />
                  {!searchTerm ? <> <div
                    className="d-flex justify-content-between main-title-section"
                    ref={ref3}
                    id='MeasurementsSection'
                  >
                    <div className="sec-main-title">
                      <span>{Measurements}</span>
                    </div>
                    <div className="edit-main-btn">
                      <PosButton
                        variant="contained"
                        className="btn-discard btn font-size title-edit-section"
                        onClick={() => toggleModal(productDetailsMeasurementModalConst)}
                      >
                        {edit}
                      </PosButton>
                    </div>
                  </div>
                    <div className="product-detail-section-bottom-border" /> </> : null}
                  <ProductDetailsSection
                    isStarred={false}
                    searchText={searchTerm}
                    handleMeasurementsSection={handleMeasurementsSection}
                  />
                  {!searchTerm ? <> <div
                    className="d-flex justify-content-between main-title-section"
                    ref={ref4}
                    id="CostSection"
                  >
                    <div className="sec-main-title">
                      <span>{CostsPrices}</span>
                    </div>
                    <div className="edit-main-btn">
                      <PosButton
                        variant="contained"
                        className="btn-discard btn font-size title-edit-section"
                        onClick={() => toggleModal(productDetailsCostsModalConst)}
                      >
                        {edit}
                      </PosButton>
                    </div>
                  </div>
                    <div className="product-detail-section-bottom-border" /> </> : null}
                  <ProductDetailsCosts searchText={searchTerm}
                    handleSearchSection={handleSearchSection} />
                  {!searchTerm ? <>
                    <div
                      className="d-flex justify-content-between main-title-section mb-0"
                      ref={ref5}
                      id="MetadataSection"
                    >
                      <div className="sec-main-title">
                        <span>{MetadataFlags}</span>
                      </div>
                    </div>
                  </> : null}
                  <ProductsDetailsMetadata
                    searchText={searchTerm}
                    handleOpenCloseMetadataModal={() => toggleModal(productDetailsMetadataModalConst)}
                    handleOpenCloseMetadataFlagesModal={() => toggleModal(productDetailsMetadataFlagesModalConst)}
                    handleOpenCloseMetadataSeoModal={() => toggleModal(productDetailsMetadataSeoModalConst)}
                    handleSearchIdentifiers={handleSearchIdentifiers}
                    handleSearchSEO={handleSearchSEO}
                  />
                  {!searchTerm ? <> <div
                    className="d-flex justify-content-between main-title-section"
                    ref={ref13}
                    id="RetailPriceSchedulesSection"
                  >
                    <div className="sec-main-title">
                      <span>{RetailPriceSchedules}</span>
                    </div>
                    {retailPriceSchedules?.length ? <div className="edit-main-btn">
                      <PosButton
                        variant="contained"
                        className="btn-discard btn font-size title-edit-section"
                        onClick={() => toggleModal(retailListModelConst)}
                      >
                        {Manage}
                      </PosButton>
                    </div> : null}
                  </div>
                    <div className="product-detail-section-bottom-border" />
                    <ProductDetailRetailPrice schedules={retailPriceSchedules} handleOpenCloseRetailModelModal={() => toggleModal(retailListModelConst)} />
                  </> : null}

                  {!searchTerm ? <> <div
                    className="d-flex justify-content-between main-title-section"
                    ref={ref6}
                    id="ReportsSection"
                  >
                    <div className="sec-main-title">
                      <span>{Reports}</span>
                    </div>
                    <div className="edit-main-btn">
                      <PosButton
                        variant="contained"
                        className="btn-discard btn font-size title-edit-section"
                        onClick={openAllLinks}
                      >
                        {ViewAll}
                      </PosButton>
                    </div>
                  </div>
                    <div className="product-detail-section-bottom-border" /> </> : null}
                  <ProductDetailsReports searchText={searchTerm} />
                  {!searchTerm ? <> <div
                    className="d-flex justify-content-between main-title-section"
                    ref={ref7}
                    id="ImagesSection"
                  >
                    <div className="sec-main-title">
                      <span>{ImagesDocs}</span>
                    </div>
                    <div className="edit-main-btn">
                      <PosButton
                        variant="contained"
                        className="btn-discard btn font-size title-edit-section"
                        onClick={() => toggleModal(productDetailsImagesModalConst)}
                      >
                        {edit}
                      </PosButton>
                    </div>
                  </div>
                    <div className="product-detail-section-bottom-border" /> </> : null}
                  <ProductDetailsImages searchText={searchTerm} />
                  {!searchTerm ? <> <div
                    className="d-flex justify-content-between main-title-section"
                    ref={ref8}
                    id="AccessoriesSection"
                  >
                    <div className="sec-main-title">
                      <span>{Accessories}</span>
                    </div>
                    <div className="edit-main-btn">
                      <PosButton
                        variant="contained"
                        className="btn-discard btn font-size title-edit-section"
                        onClick={()=> toggleModal(productDetailsAccessoriesModalConst)}
                      >
                        {edit}
                      </PosButton>
                    </div>
                  </div>
                    <div className="product-detail-section-bottom-border" /> </> : null}
                  <ProductDetailsAccessories searchText={searchTerm} productAccessorieGoesWithData={productAccessorieGoesWithData} productAccessorieReplacesData={productAccessorieReplacesData} handleSearchAccessories={handleSearchAccessories} />
                  {!searchTerm ? <> <div
                    className="d-flex justify-content-between main-title-section"
                    ref={ref9}
                    id="CharacteristicsSection"
                  >
                    <div className="sec-main-title">
                      <span>{Characteristics}</span>
                    </div>
                    <div className="display-row edit-main-btn">
                      {productCharacteristicsData?.length ?
                        <PosButton
                          variant="contained"
                          className="btn-discard btn font-size title-edit-section "
                          onClick={() => toggleModal(productCharacteristicsModalConst)}
                        >
                          {Manage}
                        </PosButton>
                        : <PosButton
                          variant="contained"
                          className="btn-discard btn font-size title-edit-section title-edit-section-margin"
                          onClick={() => toggleModal(productCharacteristicsModalConst)}
                        >
                          {Add}
                        </PosButton>}
                    </div>
                  </div>
                    <div className="product-detail-section-bottom-border" /> </> : null}
                  <ProductDetailsCharacteristics productCharacteristicsData={productCharacteristicsData} searchText={searchTerm} handleSearchCharacteristics={handleSearchCharacteristics} />
                  {!searchTerm ? <> <div
                    className="d-flex justify-content-between main-title-section"
                    ref={ref10}
                    id="NotesSection"
                  >
                    <div className="sec-main-title">
                      <span>{Notes}</span>
                    </div>
                    <div className="display-row edit-main-btn">
                      {productNotes?.length ? <PosButton
                        variant="contained"
                        className="btn-discard btn font-size title-edit-section "
                        onClick={addNotesOpen('')}
                      >
                        {Manage}
                      </PosButton> : <PosButton
                        variant="contained"
                        className="btn-discard btn font-size title-edit-section title-edit-section-margin"
                        onClick={addNotesOpen('add-new-note-input')}
                      >
                        {Add}
                      </PosButton>}
                    </div>
                  </div>
                    <div className="product-detail-section-bottom-border" /> </> : null}
                  <ProductDetailsNotes searchText={searchTerm} notes={productNotes} handleNotesSearch={handleNotesSearch} />
                  {!searchTerm ? <> <div
                    className="d-flex justify-content-between main-title-section"
                    ref={ref11}
                    id="CouponsSection"
                  >
                    <div className="sec-main-title">
                      <span>{Coupons}</span>
                    </div>
                    <div className="display-row edit-main-btn">
                      {productCouponsData?.length ? <PosButton
                        variant="contained"
                        className="btn-discard btn font-size title-edit-section "
                        onClick={() => toggleModal(productCouponsModalConst)}
                      >
                        {Manage}
                      </PosButton> : <PosButton
                        variant="contained"
                        className="btn-discard btn font-size title-edit-section title-edit-section-margin"
                        onClick={()=>toggleModal(productCouponsModalConst)}
                      >
                          {Attach}
                      </PosButton>}
                    </div>
                  </div>
                    <div className="product-detail-section-bottom-border" /> </> : null}
                  <ProductDetailsCoupons productCouponsData={productCouponsData} searchText={searchTerm} />
                  {!searchTerm ? <> <div
                    className="d-flex justify-content-between main-title-section"
                    ref={ref12}
                    id="LogsSection"
                  >
                    <div className="sec-main-title">
                      <span>{Logs}</span>
                    </div>
                    <div className="display-row edit-main-btn">
                      {productLogsData?.count > 0 ? <PosButton
                        variant="contained"
                        className="btn-discard btn font-size title-edit-section "
                        onClick={() => toggleModal(productLogModalConst)}
                      >
                        {ViewAll}
                      </PosButton> : null}
                    </div>
                  </div>
                    <div className="product-detail-section-bottom-border" />
                    <ProductDetailLog logsData={productLogsData} page={page} setPage={handleChangePage} handleOpenCloseLogsModal={() => toggleModal(productLogModalConst)} visible />
                  </> : null}

                </> : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {productNotesModal ? <NotesManager
        visible={productNotesModal}
        setVisible={() => toggleModal(productNotesModalConst)}
        notes={productNotes}
        focusElement={focusNote}
        removeAllNotes={removeAllNotes}
        removeNote={removeNote}
        updateNotes={updateNotes}
        loginUerName={LoggedInUserFullName}
      /> : null}
      {productCouponsModal ? <ProductDetailsCouponsModals
        productId={params?.id}
        open={productCouponsModal}
        onClose={() => toggleModal(productCouponsModalConst)}
        productCouponsData={productCouponsData}
        couponRemoveById={couponRemoveById}
        removeAllCoupons={removeAllCoupons}
        couponsList={couponsList}
        AddCoupon={AddCoupon}
      /> : null}
      {productCharacteristicsModal ? <ProductDetailsCharacteristicsModals
        open={productCharacteristicsModal}
        onClose={() => toggleModal(productCharacteristicsModalConst)}
        productCharacteristicsData={productCharacteristicsData}
        setProductCharacteristicsData={setProductCharacteristicsData}
        addCharacteristics={addCharacteristics}
        addNewCharacteristic={addNewCharacteristic}
        removeAllCharacteristics={removeAllCharacteristics}
        CharacteristicsRemoveById={CharacteristicsRemoveById}
        focusText={searchCharacteristics}
        getProductCharacteristicsData={getProductCharacteristicsData}
        GetAllCharacteristicTypeList={GetAllCharacteristicTypeList}
        isAddProductsCharacteristicsSuccess={isAddProductsCharacteristicsSuccess}
      /> : null}
      {productDetailsAccessoriesModal ? <ProductDetailsAccessoriesModals
        open={productDetailsAccessoriesModal}
        onClose={() => toggleModal(productDetailsAccessoriesModalConst)}
        productAccessorieReplacesData={productAccessorieReplacesData}
        productAccessorieGoesWithData={productAccessorieGoesWithData}
        removeAllAccessories={removeAllAccessories}
        AccessoriesRemoveById={AccessoriesRemoveById}
        // productId={params?.id}
        addNewAccessories={addNewAccessories}
        focusText={searchAccessories}
      /> : null}
      {productDetailsImagesModal ? <ProductDetailsImagesModals
        open={productDetailsImagesModal}
        onClose={() => toggleModal(productDetailsImagesModalConst)}
      /> : null}
      {productDetailsMetadataFlagesModal ? <ProductDetailsMetadataFlagesModals
        open={productDetailsMetadataFlagesModal}
        onClose={() => toggleModal(productDetailsMetadataFlagesModalConst)}
      /> : null}
      {productDetailsMetadataSeoModal ? <ProductDetailsMetadataSeoModals
        open={productDetailsMetadataSeoModal}
        onClose={() => toggleModal(productDetailsMetadataSeoModalConst)}
        focusText={searchSEO}
        getSingleProductData={getSingleProductData}
        updateSeoProduct={updateSeoProduct}
        removeFocus={setSearchSEO}
      /> : null}
      {productDetailsMetadataModal ? <ProductDetailsMetadataIdentifiersModals
        open={productDetailsMetadataModal}
        onClose={() => toggleModal(productDetailsMetadataModalConst)}
        getSingleProductData={getSingleProductData}
        updateIdentifiersProduct={updateIdentifiersProduct}
        focusText={searchIdentifiers}
        removeFocus={setSearchIdentifiers}
      /> : null}
      {productDetailsModal ? <ProductDetailsModals
        open={productDetailsModal}
        onClose={() => toggleModal(productDetailsMeasurementModalConst)}
        workStationId={workStationId}
        setWorkStationId={setWorkStationId}
        updateMeasurementsProduct={updateMeasurementsProduct}
        getSingleProductData={getSingleProductData}
        distributor={distributor}
        getProductsStockMinMaxData={getProductsStockMinMaxData}
        focusText={searchMeasurements}
        removeFocus={setSearchMeasurements}
      /> : null}
      {productDetailsCostsModal ? <ProductDetailsCostsModals
        open={productDetailsCostsModal}
        onClose={() => toggleModal(productDetailsCostsModalConst)}
        focusText={searchSection}
        removeFocus={setSearchSection}
        getSingleProductData={getSingleProductData}
        updateCostAndPriceProduct={updateCostAndPriceProduct}
        distributor={distributor}
      /> : null}
      {productDetailsOverviewModal ? <ProductDetailsOverviewModals
        open={productDetailsOverviewModal}
        onClose={() => toggleModal(productDetailsOverviewModalConst)}
        statusValue={statusSecondValue}
        setStatusBox={setStatusSecondBox}
        productStatusOptions={productStatusOptions}
        statusBox={statusSecondBox}
        handleSelectStatus={handleSelectStatus}
        focusText={overviewSearch}
        removeFocus={setOverViewSearch}
        updateBasicProduct={updateBasicProduct}
        getCategoriesMenuProductData={getCategoriesMenuProductData}
        getSingleProductData={getSingleProductData}
        distributor={distributor}
        getNewProductManufacturerData={getNewProductManufacturerData}
        getNewProductVendorData={getNewProductVendorData}
        getNewProductGroupData={getNewProductGroupData}
      /> : null}
      {productOverviewModal ? <ProductOverviewModals
        open={productDetailsOverviewModal}
        onClose={() => toggleModal(productDetailsOverviewModalConst)}
        statusValue={statusSecondValue}
        setStatusBox={setStatusSecondBox}
        productStatusOptions={productStatusOptions}
        statusBox={statusSecondBox}
        handleSelectStatus={handleSelectStatus}
        focusText={overviewSearch}
        removeFocus={setOverViewSearch}
        updateBasicProduct={updateBasicProduct}
        getCategoriesMenuProductData={getCategoriesMenuProductData}
        getSingleProductData={getSingleProductData}
        distributor={distributor}
        getNewProductManufacturerData={getNewProductManufacturerData}
        getNewProductVendorData={getNewProductVendorData}
        getNewProductGroupData={getNewProductGroupData}
      /> : null}
      {
        productLogModal ?
          <ProductDetailsLogsModals logList={logList} handleOpenCloseLogsModal={() => toggleModal(productLogModalConst)} open={productLogModal} handleScroll={handleScroll} doesHaveMore={doesHaveMore} /> : null
      }
      {retailListModel ?
        <ProductDetailsRetailPriceItemModels open={retailListModel} onClose={() => toggleModal(retailListModelConst)} schedules={retailPriceSchedules} setSchedules={setRetailPriceSchedules} retailPriceSchedulesInital={retailPriceSchedulesInital} />
        : null
      }
      {
        productOverview ?
          <ProductOverviewMainModals
            open={productOverview}
            onClose={() => toggleModal(productOverviewConst)}
            statusValue={statusSecondValue}
            focusText={overViewMainSearch}
            removeFocus={setOverViewMainSearch}
            updateBasicProduct={isUpdateOverviewProduct}
            getCategoriesMenuProductData={getCategoriesMenuProductData}
            getSingleProductData={getSingleProductData}
            distributor={distributor}
            getNewProductManufacturerData={getNewProductManufacturerData}
            getNewProductVendorData={getNewProductVendorData}
            getNewProductGroupData={getNewProductGroupData}
          /> : null
      }
    </div>
  );
};

export default ProductDetails;