import { ReactNode } from "react";
import { IconButton, IconButtonProps } from "@mui/material";

import useStyles from "./style";

interface Props extends IconButtonProps {
  children: ReactNode;
  width?:string,
  height?:string,
  tabIndex?:number,
}
const PosIconButton = ({
  children,
  className,
  onClick,
  disabled,
  color,
  width,
  height,
  title,
  tabIndex
}: Props) => {
  const classes = useStyles({ height, width});
  return (
    <IconButton
      className={`${className} ${classes.root} ${width ? classes.customButton : ""}`}
      onClick={onClick}
      disabled={disabled}
      disableRipple
      color={color || "secondary"}
      title={title}
      tabIndex={tabIndex}
    >
      {children}
    </IconButton>
  );
};
export default PosIconButton;
