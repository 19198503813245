import { ReturnProductsOrdersProps } from "constants/interfaces";
import * as constants from "./constants";


export function returnProductsClear() {
  return {
    type: constants.GET_RETURN_PRODUCTS_CLEAR,
  };
}

export function returnItemStart(payload: any) {
  return {
    type: constants.GET_RETURN_ITEMS_START,
    payload,
  };
}

export function returnItemSuccess(payload: any) {
  return {
    type: constants.GET_RETURN_ITEMS_SUCCESS,
    payload,
  };
}

export function returnItemError(error: any) {
  return {
    type: constants.GET_RETURN_ITEMS_ERROR,
    error,
  };
}

// return Products Picking

export function returnProductsPickingRequest(payload: number[], stockWarehouseId) {
  return {
    type: constants.RETURN_PRODUCTS_PICKING_START,
    payload,
    stockWarehouseId,
  };
}

export function returnProductsPickingSuccess(payload: any) {
  return {
    type: constants.RETURN_PRODUCTS_PICKING_SUCCESS,
    payload,
  };
}

export function returnProductsPickingError(error: any) {
  return {
    type: constants.RETURN_PRODUCTS_PICKING_ERROR,
    error,
  };
}

export function returnProductsPickingClear() {
  return {
    type: constants.RETURN_PRODUCTS_PICKING_CLEAR,
  };
}

// return Products Ticket Print

export function returnProductsTicketPrintRequest(payload: any) {
  return {
    type: constants.RETURN_PRODUCTS_PRINT_TICKET_START,
    payload,
  };
}

export function returnProductsTicketPrintSuccess(payload: any) {
  return {
    type: constants.RETURN_PRODUCTS_PRINT_TICKET_SUCCESS,
    payload,
  };
}

export function returnProductsTicketPrintError(error: any) {
  return {
    type: constants.RETURN_PRODUCTS_PRINT_TICKET_ERROR,
    error,
  };
}

export function returnProductsTicketPrintClear() {
  return {
    type: constants.RETURN_PRODUCTS_PRINT_TICKET_CLEAR,
  };
}

// return Products Credit Memo

export function returnProductsCreditMemoRequest(payload: any) {
  return {
    type: constants.RETURN_PRODUCTS_CREDIT_MEMO_START,
    payload,
  };
}

export function returnProductsCreditMemoSuccess(payload: any) {
  return {
    type: constants.RETURN_PRODUCTS_CREDIT_MEMO_SUCCESS,
    payload,
  };
}

export function returnProductsCreditMemoError(error: any) {
  return {
    type: constants.RETURN_PRODUCTS_CREDIT_MEMO_ERROR,
    error,
  };
}

export function returnProductsCreditMemoClear() {
  return {
    type: constants.RETURN_PRODUCTS_CREDIT_MEMO_CLEAR,
  };
}

// return Products Orders

export function returnProductsOrdersRequest(payload: ReturnProductsOrdersProps, stockWarehouseId) {
  return {
    type: constants.RETURN_PRODUCTS_ORDERS_START,
    payload,
    stockWarehouseId
  };
}

export function returnProductsOrdersSuccess(payload: any) {
  return {
    type: constants.RETURN_PRODUCTS_ORDERS_SUCCESS,
    payload,
  };
}

export function returnProductsOrdersError(error: any) {
  return {
    type: constants.RETURN_PRODUCTS_ORDERS_ERROR,
    error,
  };
}

export function returnProductsOrdersClear() {
  return {
    type: constants.RETURN_PRODUCTS_ORDERS_CLEAR,
  };
}

// start

export function getReturnCartData(payload) {
  return {
    type: constants.GET_RETURN_CART_DATA,
    payload,
  };
}

export function addReturnDataModalBtnAction(payload) {
  return {
    type: constants.ADD_RETURN_DATA_MODAL_BTN,
    payload,
  };
}

export function ifReturnCartAddedProductAction(payload) {
  return {
    type: constants.IF_RETURN_CART_ADDED_PRODUCT,
    payload,
  };
}

export function moveToReturnPageAction(payload) {
  return {
    type: constants.MOVE_TO_RETURN_PAGE,
    payload,
  };
}

export function searchReturnInvoiceRequest(payload: any) {
  return {
    type: constants.SEARCH_RETURN_INVOICE_START,
    payload,
  };
}

export function searchReturnInvoiceSuccess(payload: any) {
  return {
    type: constants.SEARCH_RETURN_INVOICE_SUCCESS,
    payload,
  };
}

export function searchReturnInvoiceError(error: string) {
  return {
    type: constants.SEARCH_RETURN_INVOICE_ERROR,
    error,
  };
}

export function searchReturnInvoiceClear() {
  return {
    type: constants.SEARCH_RETURN_INVOICE_CLEAR,
  };
}

export function getReasonRequest() {
  return {
    type: constants.GET_RETURN_REASON_START,
  };
}

export function getReasonSuccess(payload: any) {
  return {
    type: constants.GET_RETURN_REASON_SUCCESS,
    payload,
  };
}

export function getReasonError(error: string) {
  return {
    type: constants.GET_RETURN_REASON_ERROR,
    error,
  };
}

export function pickReplaceCartPrintRequest(payload) {
  return {
    type: constants.PICK_REPLACE_CART_PRINT_START,
    payload,
  };
}

export function pickReplaceCartPrintSuccess(payload) {
  return {
    type: constants.PICK_REPLACE_CART_PRINT_SUCCESS,
    payload,
  };
}

export function pickReplaceCartPrintError(error: string) {
  return {
    type: constants.PICK_REPLACE_CART_PRINT_ERROR,
    error,
  };
}

export function pickReplaceCartPrintClear() {
  return {
    type: constants.PICK_REPLACE_CART_PRINT_ERROR,
  };
}

export function setCustomerNameInfo(payload) {
  return {
    type: constants.CUSTOMER_NAME_INFO,
    payload,
  };
}

export function returnSearchedProductDataAction(payload) {
  return {
    type: constants.RETURN_SEARCHED_PRODUCT_DATA,
    payload,
  };
}

export function returnProductDataModalAction(payload) {
  return {
    type: constants.RETURN_PRODUCT_DATA_MODAL,
    payload,
  };
}

export function returnSearchedCustomerDataAction(payload) {
  return {
    type: constants.RETURN_SEARCHED_CUSTOMER_DATA,
    payload,
  };
}

export function returnSearchedInvoiceDataAction(payload) {
  return {
    type: constants.RETURN_SEARCHED_INVOICE_DATA,
    payload,
  };
}

export function getCompleteReturnPayloadData(payload) {
  return {
    type: constants.GET_COMPLETE_RETURN_PAYLOAD,
    payload,
  };
}

export function returnCartSubmitFailedAction() {
  return {
    type: constants.RETURN_CART_SUBMIT_FAIL,
  };
}

export function removedArrAllData(payload) {
  return {
    type: constants.REMOVED_ARR_DATA,
    payload,
  };
}

export function removedArrPartialData(payload) {
  return {
    type: constants.REMOVED_ARR_PARTIAL_DATA,
    payload,
  };
}

export function finalArrAction(payload: any) {
  return {
    type: constants.FINAL_ARR_INFO,
    payload,
  };
}


// end
