import { ChangeEvent, useEffect, useState } from "react";
import "@fontsource/inter";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  PosPageHeading,
  PosButton,
  PosIconButton,
  PosSelect,
  Container,
} from "apps/pos/Common/uiCore";
import { TabsNavigation } from "apps/pos/Common/TabsNavigation";
import JPSwitch from "components/Switch/JPSwitch";

import { ArrowBackRoundedIcon } from "constants/icons";
import { Distributor, RootState } from "models";
import { PosFlowDataProp,WarehouseDataProps } from "constants/interfaces";
import { setPosFlowData } from "sagas/persistReducer/actions";
import { recentCustomersRequest } from "sagas/pos/actions";

import "./privacySettings.scss";

const PrivacySettings = () => {
  const [selectedMainTab, setSelectedMainTab] = useState(2);
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );

  const { priceVisible , cartProducts } = posFlowData || {};
  const [privacy, setPrivacy] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [workStationData] = useState<any>([]);
  const [wareHouseSelected, setWareHouseSelected] = useState<string>("");

  const addWarehouseToList = (location) => {
    const tempLocation = {
      stockWarehouseId: location?.stockWarehouseId,
      warehouseName: location?.label,
    };
    workStationData.push(tempLocation);
  };

  useEffect(() => {
    if (priceVisible === undefined) {
      setPrivacy(false);
    } else {
      setPrivacy(priceVisible);
    }
  }, []);

  useEffect(() => {
    const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));
    const currentStockWarehouseId = currentWorkspace?.stockWarehouseId;

    distributor?.warehouses?.forEach((location) =>
      addWarehouseToList(location)
    );

    const defaultWS = distributor?.warehouses?.filter(
      (item) => item?.stockWarehouseId === currentStockWarehouseId
    );
    setWareHouseSelected(defaultWS[0]?.stockWarehouseId.toString());
  }, []);

  const saveAndUpdateWarehouse = () => {
    const filterWorkStation =
      workStationData?.length &&
      workStationData?.find(
        (item) => item.stockWarehouseId === Number(wareHouseSelected)
      );
    const data = {
      ...posFlowData,
      workspace: filterWorkStation,
      priceVisible: !privacy,
    };
    localStorage.setItem("workspace", JSON.stringify(filterWorkStation));
    dispatch(setPosFlowData(data));
    const preparedData = {
      id: filterWorkStation?.stockWarehouseId,
      name: filterWorkStation?.warehouseName,
    };
    dispatch(recentCustomersRequest(preparedData));
    navigate(-1);
  };

  const handleback = () => {
    navigate(-1);
  };

  return (
    <Container className="new-container">
      <div className="privacy-settings">
        <div>
          <div className="privacy-container">
            <div className="back-button">
              <PosIconButton className="back-button-func pos-icon-back-btn" onClick={handleback}>
                <ArrowBackRoundedIcon />
              </PosIconButton>
            </div>
            <PosPageHeading heading="Settings" blackHeading />
          </div>
          <TabsNavigation
            className="inbox-lists-tabs"
            tabs={[
              {
                id: "General",
                label: "General",
                // disabled: wareHouseDisabled,
                selected: selectedMainTab === 2,
                content: null,
                onClick: () => {
                  setSelectedMainTab(2);
                },
              },
              {
                id: "Privacy",
                label: "Privacy",
                selected: selectedMainTab === 1,
                content: null,
                onClick: () => {
                  setSelectedMainTab(1);
                },
              },
            ]}
          ></TabsNavigation>
          {selectedMainTab === 1 && (
            <div className="privacy-tab">
              <div className="show-costs">Show Costs</div>
              <div className="set-privacy">
                <div className="const-price">
                  Cost prices and profit details are
                  {privacy ? " visible" : " hidden by default"}
                </div>
                <div
                  className="privacy-switch"
                  onClick={() => {
                    const data = {
                      ...posFlowData,
                      priceVisible: !privacy,
                    };
                    dispatch(setPosFlowData(data));
                    setPrivacy(!privacy);
                  }}
                >
                  <JPSwitch checked={privacy} />
                </div>
              </div>
            </div>
          )}
          {selectedMainTab === 2 && (
            <div className="privacy-tab arrow-black">
              <div className="ws-heading-text">Warehouse</div>
              <span className="ws-sub-text">Current Warehouse</span>
              <PosSelect
                // isSearch : Removing Search as not needed for this dropdown
                id={"workstations"}
                name={"select workstation"}
                className=" tabDropdown"
                disabled={Boolean(cartProducts?.length)}
                placeholder={workStationData?.find(
                  (item) =>
                    item?.stockWarehouseId === Number(wareHouseSelected)
                )?.warehouseName}
                value={
                  workStationData?.find(
                    (item) =>
                      item?.stockWarehouseId === Number(wareHouseSelected)
                  )?.stockWarehouseId
                }
                defaultValue={
                  workStationData?.find(
                    (item) =>
                      item?.stockWarehouseId === Number(wareHouseSelected)
                  )?.stockWarehouseId
                }
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  setWareHouseSelected(e.target.value);
                }}
                options={
                  workStationData?.length &&
                  workStationData?.map(
                    ({
                      warehouseName,
                      stockWarehouseId,
                    }: WarehouseDataProps) => {
                      return {
                        label: warehouseName,
                        value: stockWarehouseId,
                      };
                    }
                  )
                }
              />
              <span className="ws-note-text">
                This helps get the correct inventory for all items.
              </span>
              <PosButton
                fontWeight
                className="btn-save-warehouse btn"
                variant="contained"
                color="primary"
                disabled={Boolean(cartProducts?.length)}
                onClick={() => {
                  if (wareHouseSelected) {
                    saveAndUpdateWarehouse();
                  }
                }}
              >
                Save Changes
              </PosButton>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default PrivacySettings;
