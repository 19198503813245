import React from "react";
import Select from "react-select";

interface CommonSelectProps {
  className?: string;
  style?: any;
  component?: any;
  theme?: any;
  options?: any;
  placeholder?: string;
  backspaceRemovesValue?: boolean;
  autoFocus?: boolean;
  controlShouldRenderValue?: boolean;
  hideSelectedOptions?: boolean;
  isClearable?: boolean;
  menuIsOpen?: boolean;
  onChange?(value): void;
  tabSelectsValue?: boolean;
  value?: any;
  isSearchable?:boolean;
  isMulti?:boolean;
}

const CommonSelect = (props: CommonSelectProps) => {
  const {
    className,
    component,
    style,
    theme,
    options,
    placeholder,
    backspaceRemovesValue,
    autoFocus,
    isClearable,
    hideSelectedOptions,
    controlShouldRenderValue,
    menuIsOpen,
    onChange,
    tabSelectsValue,
    value,
    isSearchable,
    isMulti,
  } = props;

  return (
    <div>
      <Select
        options={options}
        className={className}
        styles={style}
        theme={theme}
        placeholder={placeholder}
        autoFocus={autoFocus}
        backspaceRemovesValue={backspaceRemovesValue}
        controlShouldRenderValue={controlShouldRenderValue}
        hideSelectedOptions={hideSelectedOptions}
        isClearable={isClearable}
        menuIsOpen={menuIsOpen}
        onChange={onChange}
        tabSelectsValue={tabSelectsValue}
        value={value}
        components={component}
        isSearchable={isSearchable}
        searchable={false}
        isMulti={isMulti}
      />
    </div>
  );
};

export default CommonSelect;
