import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Distributor, RootState, user } from "models";
import "@fontsource/inter";

import {
  PosImage,
  PosPageHeading,
  PosSelect,
  Container,
  PosButton,
} from "apps/pos/Common/uiCore";
import SelectTakerModal from "./SelectTakerModal/SelectTakerModal";

import images from "constants/images";
import { WarehouseDataProps } from "constants/interfaces";

import { recentCustomersRequest } from "sagas/pos/actions";
import { setPosFlowData } from "sagas/persistReducer/actions";

import "./SelectWorkStation.scss"

const SelectWorkStation = ({
  setSelectedWorkspace,
  state,
  setState,
  startLoader,
  currentTakerInfoStorage,
  currentWorkspace,
}) => {
  const dispatch = useDispatch();

  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );

  const { loggedInUser } = useSelector<
    RootState,
    user
  >((state) => state.user);

  const [workStationArrayData, setWorkStationArrayData] = useState([]);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [value, setValue] = useState("");
  const [selectedWS, setSelectedWS] = useState(5);

  useEffect(() => {
    const workstationArray = [];

    distributor?.warehouses.map((location) => {
      const object = {
        stockWarehouseId: location?.stockWarehouseId,
        warehouseName: location?.label,
      };

      workstationArray.push(object);

      setWorkStationArrayData(workstationArray);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setDataAvailable(true);
    }, 750);
  }, []);

  const workstationChangeEvent = (e) => {
    setSelectedWS(Number(e.target.value));
    setSelectedWorkspace(e.target.value);
    setValue(e.target.value);
  };

  // When API available uncomment below two useEffect
  // useEffect(() => {
  //   dispatch(workStationsRequest());
  // }, []);

  // useEffect(() => {
  //   setWorkStationData(workStationDetails);
  // }, [workStationDetails]);

  const { caution } = images;

  return (
    <>
      {dataAvailable ? (
        !currentWorkspace ? (
          <>
            <Container className="modal-content-style modal-container-styling">
              <main>
                <div
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  <div className="pricing-modal-header mt-100 mb-0">
                    <PosPageHeading heading="Welcome!" grayHeading />
                    <PosPageHeading
                      heading="Select Your Warehouse"
                      blackHeading
                    />
                  </div>
                  <main>
                    <div className="workstation-bottom mt-50">
                      <PosImage src={caution} alt="caution" />
                      <span className="workstation-bottom-text int-16-reg font-weight-500">
                        This is a one time setting. It helps get the correct
                        inventory.
                      </span>
                    </div>
                    <div className="mt-50">
                      <div className="workstation-subheading">
                        Warehouse Name
                      </div>
                      <form id="addActorsForm">
                        <div className=" arrow-black">
                          <PosSelect
                            // isSearch : Removing Search as not needed for this dropdown
                            autoFocus={true}
                            className=" tabDropdown"
                            placeholder="Select Warehouse"
                            variant="outlined"
                            id="workstations"
                            name="select workstation"
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                              workstationChangeEvent(e);
                            }}
                            options={
                              workStationArrayData?.length &&
                              workStationArrayData.map(
                                ({
                                  warehouseName,
                                  stockWarehouseId,
                                }: WarehouseDataProps) => {
                                  return {
                                    label: warehouseName,
                                    value: stockWarehouseId,
                                  };
                                }
                              )
                            }
                          />
                        </div>
                      </form>
                    </div>
                    <PosButton
                      disabled={!value}
                      variant="contained"
                      color="primary"
                      className={`workstation-continue-btn btn ${!value ? "btn-disabled-opacity" : ""}`}
                      onClick={() => {
                        const filterWorkStation =
                          workStationArrayData?.length &&
                          workStationArrayData?.filter(
                            (item) => item.stockWarehouseId == selectedWS
                          );
                          if(loggedInUser) {
                            localStorage.setItem("taker", JSON.stringify(loggedInUser))
                          }
                        if (filterWorkStation[0]) {
                          const data = {
                            workspace: filterWorkStation[0],
                          };

                          const preparedData = {
                            id: filterWorkStation[0]?.stockWarehouseId,
                            name: filterWorkStation[0]?.warehouseName,
                          };

                          localStorage.setItem(
                            "workspace",
                            JSON.stringify(filterWorkStation[0])
                          );
                          startLoader();
                          dispatch(setPosFlowData(data));
                          dispatch(recentCustomersRequest(preparedData));
                          if (currentTakerInfoStorage && currentWorkspace) {
                            setState({ ...state, right: false });
                          }
                        }
                      }}
                    >
                      Continue
                    </PosButton>
                  </main>
                </div>
              </main>
            </Container>
          </>
        ) : (
          currentWorkspace &&
          !currentTakerInfoStorage && (
            <SelectTakerModal
              newOrderTakerDrawer
              state={state}
              setState={setState}
              newCustomerTakerClass
            />
          )
        )
      ) : null}
    </>
  );
};

export default SelectWorkStation;
