import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    "& .MuiSvgIcon-root": {
      fill: "black",
      "&:hover": {
        backgroundColor: "red",
      },
    },
  },
});

export default useStyles;
