import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { QueryKeys } from "apps/pos/pages/CustomerDetails/hooks/queryKeys";
import { getSpecificCustomerDetails } from "apps/pos/pages/CustomerDetails/request";

export const useGetCustomerDetails = () => {
  const [userId, setUserId] = useState();
  const { data } = useQuery(
    [QueryKeys.getSearchedCustomerDetails, userId],
    async () => {
      if (userId) {
        return getSpecificCustomerDetails(userId);
      }
    }
  );

  const setCustomerParamsId = useCallback(
    (id) => {
      setUserId(id);
    },
    [userId]
  );
  return { setCustomerParamsId, userDetails: data?.data ?? {} };
};
