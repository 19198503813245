/* eslint-disable @typescript-eslint/no-unused-vars */ // Due to some code comment for future use
import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/SPButton/Button";
import { IconButton } from "apps/springboard/Component/SBItems/elements";
import { QuoteDocuments } from "apps/springboard/QuoteDocuments/QuoteDocuments";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";

import {
  Distributor,
  quoteDetail,
  QuoteDetailRep,
  RootState,
} from "models";
import { getAcronym } from "utils/utils";
import { PosModel } from "apps/pos/Common/uiCore";
import { springBoardText } from "constants/constant";
import { getRepsRequest } from "sagas/quoteDetail/actions";
import { UpdateModalContent } from "sagas/modal/actions";
import { getQuoteDocs, getQuoteItems, getQuoteReps, getQuoteRepsLoading } from "sagas/quoteDetail/selector";

import "../QuoteDetail/QuoteDetail.scss"
import Avvvatars from "avvvatars-react";
interface quoteRepsProps {
  quoteId: number;
  goToDocumentTab: () => void;
  goToUploadDocument: () => void;
  jobLotPackageId?: number
}

export function QuoteReps({
  quoteId,
  goToDocumentTab,
  goToUploadDocument,
  jobLotPackageId
}: quoteRepsProps) {
  const [qReps, setQReps] = useState<QuoteDetailRep[]>();
  const [docOpen, setDocOpen] = useState<any>(false);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [docs, setDocs] = useState<any>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRepsRequest(quoteId));
  }, [quoteId]);

  const quoteReps = useSelector(getQuoteReps)
  const quoteItems = useSelector(getQuoteItems)
  const quoteDocs = useSelector(getQuoteDocs)
  const quoteRepsLoading = useSelector(getQuoteRepsLoading)

  const { rep, noRep } = springBoardText;

  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );

  useEffect(() => {
    const reps =
      quoteReps?.length &&
      quoteReps.map((e) => {
        const items =
          quoteItems?.length &&
          quoteItems.filter((e1) => e.quoteRepName === e1.repName);
        const docs =
          quoteDocs?.length &&
          quoteDocs.filter((e1) => e.quoteRepName === e1.repName);
        return { ...e, items: items?.length, docs: docs?.length };
      });
    setQReps(reps);
  }, [quoteReps, quoteItems, quoteDocs]);

  const openLink = (url) => {
    window.open(url, "_blank");
  };
  const documentModalClose = () => {
    if (openDocumentModal) {
      dispatch(
        UpdateModalContent({
          isOpen: false,
          children: null,
          fixedModal: false,
          removePaddingBottom: false,
          noMaxWidth: false,
          classModal: ""
        })
      );
      setOpenDocumentModal(false);
    } else {
      setDocOpen(false);
    }
  };
  

  const documentBrowse = () => {
    if(docs){
      return(
        <Fragment>
              <PosModel
                  open={docOpen}
                  onClose={documentModalClose}
                  className={`custom-customer-code-modal-container`}
                  dialogClassName={`custom-customer-code-modal-container`}
                  dialogHeader={
                      <div className="modal-header pl-4">
                          <span className="modal-title">
                              <div className="pt-2"><h3 className="f20-Inter-500-sab mb-0 mb-0">{docs?.quoteRepName}
                              </h3></div>
                          </span>
                          <IconButton
                              name='close'
                              className="close-icon"
                              type='tertiary'
                              onClick={documentModalClose}
                          />
                      </div>
                  }>
                    <QuoteDocuments quoteId={quoteId} isModal repDocs={docs} setOpenDocumentModal={setOpenDocumentModal} />
              </PosModel>
          </Fragment>
      )
    }
  }

  const onBrowse = (quoteRep) => { setDocOpen(true); setDocs(quoteRep); }

  return (
    <div className="quote-details-reps" style={{ paddingBottom: 50 }}>
      {
        quoteRepsLoading ?
          <ShapedSkeleton
            shape='0 0 300 20, 480 0 300 20, 0 28 150 12, 480 28 150 12'
            width={780}
            height={40}
            radius={4}
            classes="mt-3"
          />
          :
          <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="fw-medium fs-14 ">
          {qReps?.length
            ? `${qReps.length} ${rep}${
                qReps.length > 1 ? "s" : ""
          } assigned`
          : noRep}
          </div>
        <div className="header-actions">
          <Button
            label='Send Price Request'
            leadingIcon='local_offer'
            type='secondary'
            size='small'
            href={`${distributor?.httpsUrl}/gmSale/msJobLotQuote_sendPriceRequestToRep?jobLotQuoteID=${quoteId}`}
            target='_blank'
          />
          <Button
            label='Assign Rep'
            leadingIcon='person_add'
            type='secondary'
            size='small'
            href={`${distributor?.httpsUrl}/gmSale/msJobLotQuote_repAssigned_add.aspx?jobLotQuoteID=${quoteId}`}
            target='_blank'
          />
        </div>
      </div>
      {documentBrowse()}
      <div className="row pt-1">
        {qReps?.length ? (
          qReps?.map((quoteRep, index) => {
            return (
              <Fragment key={index}>
                <div className="col-xl-4 col-lg-6 col-md-12 col-12 mt-3 mb-3">
                  <div className="innder-padd-right border_body">
                    <div className="d-flex justify-content-between">
                      <div className="dam_sales">
                        <h3 className="fs-16 margin-b-0">
                          {quoteRep.quoteRepName}
                        </h3>
                        {/* <p className="fs-12 inter-reg">
                          Assigned on Apr 01, 2021
                        </p> */}
                      </div>
                      <Avvvatars value={quoteRep.quoteRepName} size={40} />
                    </div>
                    <div className="reps_price_dropdown  d-flex flex-nowrap my-3">
                      <div className="price_recd">
                        <p className="text-gray-500 fs-10 inter-reg mb-1">
                          BUY PRICE RCVD.
                        </p>
                        <span className="label inter-med">
                          {quoteRep.buyPriceReceived === 1 ? "Yes" : "No"}
                        </span>
                      </div>
                      <div className="price_recd">
                        <p className="text-gray-500 fs-10 inter-reg mb-1">
                          QUOTE UPLOADED
                        </p>
                        <span className="label inter-med">
                          {quoteRep.repQuoteUploadedFlag === 1 ? "Yes" : "No"}
                        </span>
                      </div>
                    </div>

                    <div className="divider-grey" />

                    <div className="d-flex justify-content-between align-items-center my-3">
                      <div className="flex-direction-column fs-10 inter-reg text-gray-500">
                        <span>ITEMS</span>
                        <span className="fs-14 inter-med black_text">
                          {quoteRep.items || 0}
                        </span>
                      </div>
                      <Button
                        label={quoteRep.items !== 0 ? "Edit Items" : "Add Items"}
                        leadingIcon={quoteRep.items !== 0 ? 'edit_note' : 'add'}
                        type='tertiary'
                        size='small'
                        href={`${distributor?.httpsUrl}/gmSale/msJobLotQuote_RepItem_add?jobLotQuoteID=${quoteId}&quoteRepID=${quoteRep.quoteRepID}`}
                        target='_blank'
                      />

                    </div>
                    <div className="divider-grey" />
                    <div className="d-flex justify-content-between align-items-center my-3">
                      <div className="flex-direction-column fs-10 inter-reg text-gray-500">
                        <span>PRICE REQUEST SENT</span>
                        <span className="fs-14 inter-med black_text">
                          {quoteRep.buyPriceReceived === 1 ? "Yes" : "No"}
                        </span>
                      </div>
                      <Button
                        label='Send Price Request'
                        leadingIcon='local_offer'
                        type='tertiary'
                        size='small'
                        href={`${distributor?.httpsUrl}/gmSale/msJobLotQuote_sendPriceRequestToRep?jobLotQuoteID=${quoteId}`}
                        target='_blank'
                      />
                    </div>
                    <div className="divider-grey" />
                    <div className="d-flex justify-content-between align-items-center my-3">
                      <div className="flex-direction-column fs-10 inter-reg text-gray-500">
                        <span>DOCUMENTS</span>
                        <span className="fs-14 inter-med black_text">
                          {quoteRep.docs || 0}
                        </span>
                      </div>
                      <div className="d-flex">
                      {quoteRep.docs > 0 &&
                        <Button
                          label='Browse'
                          leadingIcon='folder_open'
                          type='tertiary'
                          size='small'
                          target='_blank'
                          onClick={() => onBrowse(quoteRep)}
                        />
                      }
                          <Button
                            label='Upload'
                            leadingIcon='upload'
                            type='tertiary'
                            size='small'
                            href={`${distributor?.httpsUrl}/gmSale/msJobLotQuote_repDocUpload.aspx?jobLotQuoteID=${quoteId}&quoteRepID=${quoteRep.quoteRepID}`}
                            target='_blank'
                          />
                       
                  
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          })
        ) : (
          <></>
        )}


            </div>
          </>
      }
    </div>
  );
}
