import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { StarIconButton } from "apps/product/Components/StarIconButton/StarIconButton";
import { GridStarIconProps } from "apps/product/modules/productDetailsContainer/types";


import useFilterSections from "./useSectionFilter";
import { getStaredProductsDetails } from "sagas/persistReducer/selector";
import { sectionName as section } from "apps/product/modules/productDetailsContainer/constants";
import { getSingleProductDataFunction } from "sagas/productsDetails/selector";

const CreateGridStarIcon = ({ className, label, value, sectionName, smallText, oneTimeFlag }: GridStarIconProps) => {
  const starredProductsDetails = useSelector(getStaredProductsDetails);
  const { characteristics, notes, coupons } = section;
  const notShow = sectionName === characteristics || sectionName === coupons || sectionName === notes
  const getSingleProductData = useSelector(getSingleProductDataFunction);
  const { details } = getSingleProductData || {}
  const { productId } = details || {}
  const { hasKeyInCategory } = useFilterSections({ sectionName });

  return (
    <Grid item xs={12} sm={4}>
      {!notShow ? <StarIconButton
        className={className}
        section={sectionName}
        value={value}
        starredProductsDetails={starredProductsDetails}
        productID={productId}
        hasKeyInCategory={hasKeyInCategory}
      /> :null}
      <span>{label}</span>
      {oneTimeFlag ? <><div className="small-text gray-text mt-2">
        <span>One-time</span>
      </div></> : null}
      {smallText ? 
      <>
          <div className="small-text gray-text mt-2">
            <span>{smallText}</span>
          </div>
      </> : null}
    </Grid>
  )
}

export default CreateGridStarIcon