import { useNavigate } from "react-router-dom";

import images from "constants/images";
import { Paths } from "enums";
import { productStatus } from "apps/product/helper/commonConstants";

import "./style.scss";

const ProductListing = ({ productDetails }) => {
  const navigate = useNavigate();

  const { defaultIcon } = images;
  const {
    productName,
    manufacturerName,
    productId,
    activeFlag,
    unitPerCase,
    productGroupName,
    isOnPriceSchedule
  } = productDetails;

  const redirectToProductList = () => {
    productId && navigate(`${Paths.product}/${productId}`);
  }

  return (
    <div className="product-card-container" onClick={redirectToProductList}>
      <div className="product-image">
        <img src={defaultIcon} />
      </div>
      <div className="product-quick-info">
        <div className="product-quick-details">
          {productName ? (
            <>
              <span className="prod-name">{productName}</span>
            </>
          ) : null}
          {manufacturerName ? (
            <>
              <span className="prod-manufacturere-name">
                {manufacturerName}
              </span>
            </>
          ) : null}
          {productId ? (
            <>
              <span className="prod-manufacturere-name">{productId}</span>
            </>
          ) : null}
        </div>
        <div className="prod-availability-description">
          <span className="prod-status d-flex align-items-center">
            <span className="prod-status-icon mr-1 ml-0 coloured-prod-status-icon">
              {productStatus[activeFlag].Component}
            </span>
            <span>{productStatus[activeFlag].title}</span>
          </span>
          <span>{`${unitPerCase} unit/case`}</span>
          <div>
          {
            productGroupName ? <span>{productGroupName} </span> : null
          }
          {
            !isOnPriceSchedule ?
            <>
            {productGroupName ? <span className="gray-dot"></span> : null}
            <span>Price schedule not set</span>
            </> : null
          }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListing;
