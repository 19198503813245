export const GET_COMPANY_DETAILS_START = 'GET_COMPANY_DETAILS_START';
export const GET_COMPANY_DETAILS_SUCCESS = 'GET_COMPANY_DETAILS_SUCCESS';
export const GET_COMPANY_DETAILS_ERROR = 'GET_COMPANY_DETAILS_ERROR';

export const GET_CONTACT_INFO_START = 'GET_CONTACT_INFO_START';
export const GET_CONTACT_INFO_SUCCESS = 'GET_CONTACT_INFO_SUCCESS';
export const GET_CONTACT_INFO_ERROR = 'GET_CONTACT_INFO_ERROR';

export const GET_DOMAIN_URL_START = 'GET_DOMAIN_URL_START';
export const GET_DOMAIN_URL_SUCCESS = 'GET_DOMAIN_URL_SUCCESS';
export const GET_DOMAIN_URL_ERROR = 'GET_DOMAIN_URL_ERROR';

export const GET_LOGO_START = 'GET_LOGO_START';
export const GET_LOGO_SUCCESS = 'GET_LOGO_SUCCESS';
export const GET_LOGO_ERROR = 'GET_LOGO_ERROR';

export const GET_EMAIL_DISTROS_START = 'GET_EMAIL_DISTROS_START';
export const GET_EMAIL_DISTROS_SUCCESS = 'GET_EMAIL_DISTROS_SUCCESS';
export const GET_EMAIL_DISTROS_ERROR = 'GET_EMAIL_DISTROS_ERROR';

export const GET_CARRIERS_DETAILS_START = 'GET_CARRIERS_DETAILS_START';
export const GET_CARRIERS_DETAILS_SUCCESS = 'GET_CARRIERS_DETAILS_SUCCESS';
export const GET_CARRIERS_DETAILS_ERROR = 'GET_CARRIERS_DETAILS_ERROR';

export const GET_SIGNATURES_DETAILS_START = 'GET_SIGNATURES_DETAILS_START';
export const GET_SIGNATURES_DETAILS_SUCCESS = 'GET_SIGNATURES_DETAILS_SUCCESS';
export const GET_SIGNATURES_DETAILS_ERROR = 'GET_SIGNATURES_DETAILS_ERROR';

export const GET_PHONES_DETAILS_START = 'GET_PHONES_DETAILS_START';
export const GET_PHONES_DETAILS_SUCCESS = 'GET_PHONES_DETAILS_SUCCESS';
export const GET_PHONES_DETAILS_ERROR = 'GET_PHONES_DETAILS_ERROR';

export const GET_PAYMENT_METHODS_DETAILS_START = 'GET_PAYMENT_METHODS_DETAILS_START';
export const GET_PAYMENT_METHODS_DETAILS_SUCCESS = 'GET_PAYMENT_METHODS_DETAILS_SUCCESS';
export const GET_PAYMENT_METHODS_DETAILS_ERROR = 'GET_PAYMENT_METHODS_DETAILS_ERROR';

export const GET_SUBSCRIPTION_DETAILS_START = 'GET_SUBSCRIPTION_DETAILS_START';
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = 'GET_SUBSCRIPTION_DETAILS_SUCCESS';
export const GET_SUBSCRIPTION_DETAILS_ERROR = 'GET_SUBSCRIPTION_DETAILS_ERROR';

export const GET_SOCIAL_LINKS_START = 'GET_SOCIAL_LINKS_START';
export const GET_SOCIAL_LINKS_SUCCESS = 'GET_SOCIAL_LINKS_SUCCESS';
export const GET_SOCIAL_LINKS_ERROR = 'GET_SOCIAL_LINKS_ERROR';

export const GET_TRAMPOLINE_SETTINGS_START = 'GET_TRAMPOLINE_SETTINGS_START';
export const GET_TRAMPOLINE_SETTINGS_SUCCESS = 'GET_TRAMPOLINE_SETTINGS_SUCCESS';
export const GET_TRAMPOLINE_SETTINGS_ERROR = 'GET_TRAMPOLINE_SETTINGS_ERROR';

export const GET_CANNED_RESPONSES_START = 'GET_CANNED_RESPONSES_START';
export const GET_CANNED_RESPONSES_SUCCESS = 'GET_CANNED_RESPONSES_SUCCESS';
export const GET_CANNED_RESPONSES_ERROR = 'GET_CANNED_RESPONSES_ERROR';

export const GET_APPEARANCE_DETAILS_START = 'GET_APPEARANCE_DETAILS_START';
export const GET_APPEARANCE_DETAILS_SUCCESS = 'GET_APPEARANCE_DETAILS_SUCCESS';
export const GET_APPEARANCE_DETAILS_ERROR = 'GET_APPEARANCE_DETAILS_ERROR';

export const GET_PROCUREMENT_PARTNERS_DETAILS_START = 'GET_PROCUREMENT_PARTNERS_DETAILS_START';
export const GET_PROCUREMENT_PARTNERS_DETAILS_SUCCESS = 'GET_PROCUREMENT_PARTNERS_DETAILS_SUCCESS';
export const GET_PROCUREMENT_PARTNERS_DETAILS_ERROR = 'GET_PROCUREMENT_PARTNERS_DETAILS_ERROR';

export const GET_ACTORS_DETAILS_START = 'GET_ACTORS_DETAILS_START';
export const GET_ACTORS_DETAILS_SUCCESS = 'GET_ACTORS_DETAILS_SUCCESS';
export const GET_ACTORS_DETAILS_ERROR = 'GET_ACTORS_DETAILS_ERROR';

export const GET_WAREHOUSES_DETAILS_START = 'GET_WAREHOUSES_DETAILS_START';
export const GET_WAREHOUSES_DETAILS_SUCCESS = 'GET_WAREHOUSES_DETAILS_SUCCESS';
export const GET_WAREHOUSES_DETAILS_ERROR = 'GET_WAREHOUSES_DETAILS_ERROR';

export const GET_HR_REPRESENTATIVE_DETAILS_START = 'GET_HR_REPRESENTATIVE_DETAILS_START';
export const GET_HR_REPRESENTATIVE_DETAILS_SUCCESS = 'GET_HR_REPRESENTATIVE_DETAILS_SUCCESS';
export const GET_HR_REPRESENTATIVE_DETAILS_ERROR = 'GET_HR_REPRESENTATIVE_DETAILS_ERROR';

