import _ from "loadsh";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import Button from "apps/admin/components/Elements/Button";
import {
  PosButton,
  PosHelmet,
  PosIconButton,
  PosImage,
  PosLoader,
  PosTooltip,
} from "apps/pos/Common/uiCore";
import { CreditCardOffIcon, FileUploadIcon } from "constants/icons";

import {
  jobAndReceiptNameRegex,
  poNumberRegex,
  sectionTitle,
  snackbarDetail,
} from "constants/constant";
import { GlobalNumbers } from "constants/enum";
import { PosFlowDataProp } from "constants/interfaces";
import { Paths } from "enums";
import { PosResponseProps, RootState } from "models";
import { CustomerAndCartInfoProps } from "./type";

import {
  arrowDraftReset,
  clearSearchProduct,
  isPurchaseOrderNoEdited,
  salespersonsRequest,
  setJobNameForAll,
  takersRequest,
} from "sagas/pos/actions";

import {
  setPosFlowData,
  setShipToAddress,
  setTabIndexNumber,
  setToPickUp,
} from "sagas/persistReducer/actions";

import images from "constants/images";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import { getLocalStoraGeItem, showScrollCartPage } from "utils/utils";

const CustomerAndCartInfo = ({
  isCartCleared,
  suggestionListVisible,
  cartEmpty,
  importQuote,
  setProductSearch,
  closeImage,
  setShippingAddress,
  setCartEmpty,
  setSearchProductsData,
  setSuggestionListVisible,
  handleOpen,
  selectAddProductWrapper,
  setShowSaveForLater,
  handleClick,
  selectTaxExemptFlag,
  selectShippingWrapper,
  selectScheduleWrapper,
  getListSize,
  headerRef,
  settingIconRef,
  searchInputHeightCount,
  settingIconDivHeight,
  setFilteredSearchProduct,
  customerAndCardInfoRef,
  clearFilter,
}: CustomerAndCartInfoProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addProductIcon } = images;

  const { posFlowData, loadCartDetails, cartLoading, shippingAddressSelected } =
    useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);
  const {
    headerHeightCount,
    takerDetails,
    isPurchaseOrderNoEdit,
    salesPersonDetails,
    jobNameForAll,
  } = useSelector<RootState, PosResponseProps>((state) => state.pos);

  const { warehouseName } = getLocalStoraGeItem("workspace") || {};
  const [shippingTaxExemptFlag, setShippingTaxExemptFlag] = useState<number>(0);
  const [selectedSalesId, setSelectedSalesId] = useState(0);
  const [selectedTakerId, setSelectedTakerId] = useState(0);
  const ref = useRef(null);
  const refTaker = useRef(null);
  // might need later
  // const checkMixOrder =
  // posFlowData?.cartProducts?.length > 0 &&
  // posFlowData?.cartProducts?.some((item: QtyInStockProps) => {
  //   return item && item?.qtyInStock > 0;
  // });

  const { cartId, customer, shipToByFacility, purchaseOrderNum } =
    loadCartDetails || {};
  const { posCustCode, companyName } = customer || {};
  const { addTitle } = sectionTitle;
  const {
    customer: posCustomer,
    savedCart,
    selectQuoteName,
  } = posFlowData ?? {};
  const { jobName } = savedCart || {};
  const { creditHoldFlag, isPosGeneric } = posCustomer ?? {};
  const [poNumberSave, setPoNumberSave] = useState(
    isPurchaseOrderNoEdit ? posFlowData?.poNumber : purchaseOrderNum
  );
  const [tempJobNameSave, setTempJobNameSave] = useState("");

  const currentTaker = getLocalStoraGeItem("taker");
  useEffect(() => {
    const selectedSalesId = salesPersonDetails?.data?.findIndex(
      (obj: any) =>
        obj.salespersonName === posFlowData?.salesman?.salespersonName
    );
    setSelectedSalesId(selectedSalesId);
    const selectedTakerId = takerDetails?.data?.findIndex(
      (obj: { fullName: string }) => obj.fullName === currentTaker?.fullName
    );
    setSelectedTakerId(selectedTakerId);
  }, [posFlowData, currentTaker]);

  useEffect(() => {
    if (!cartId) {
      window.location?.replace(Paths.posNewOrder);
    }
    if (!takerDetails?.data?.length) dispatch(takersRequest());
    if (!salesPersonDetails?.data?.length) dispatch(salespersonsRequest());
    setPoNumberSave(
      isPurchaseOrderNoEdit ? posFlowData?.poNumber : purchaseOrderNum
    );
  }, []);

  useEffect(() => {
    window?.addEventListener("resize", getListSize);
    return () => {
      window?.removeEventListener("resize", getListSize);
    };
  }, [
    headerRef,
    settingIconRef,
    headerHeightCount,
    settingIconDivHeight,
    searchInputHeightCount,
  ]);

  useEffect(() => {
    if (jobNameForAll) {
      setTempJobNameSave(jobNameForAll);
    } else if (jobName) {
      setTempJobNameSave(jobName);
    } else if (selectQuoteName && !jobName) {
      setTempJobNameSave(selectQuoteName);
    }
  }, [jobName, selectQuoteName]);

  // Might need later
  // const shippingAddressName = () => {

  //   const posFacility = posFlowData?.shiptoFacilities?.facilityName?.substring(0,21) + "..."
  //   // TODO: Need later if we have to set company name for any reason
  //   // const newFacility = shipToByFacility?.recipient?.companyName?.substring(0,21)
  //   const newFacility = shipToByFacility?.facilityName?.substring(0,21)

  //   if(shippingAddressSelected && checkMixOrder){
  //     if(posFlowData?.shiptoFacilities?.facilityName?.length > 18){
  //       return posFacility
  //     }else{
  //       return posFlowData?.shiptoFacilities?.facilityName
  //     }
  //   }else{
  //     if(!shippingAddressSelected){
  //     return newFacility
  //     }
  //     else if (posFlowData?.shiptoFacilities?.facilityName){
  //       return posFacility
  //     }
  //     else{
  //       return posFlowData?.shiptoFacilities?.facilityName
  //     }
  //   }
  // }

  useEffect(() => {
    setShippingTaxExemptFlag(
      posFlowData?.shiptoFacilities?.recipient?.taxExemptFlag
    );
  }, [posFlowData?.shiptoFacilities?.recipient?.taxExemptFlag]);

  const handleShipping = () => {
    const data = {
      ...posFlowData,
      isModalOpen: true,
    };
    dispatch(setPosFlowData(data));
    selectShippingWrapper();
    dispatch(setToPickUp(false));
    dispatch(setShipToAddress(false));
    (document.activeElement as HTMLElement).blur();
  };

  const handleClose = () => {
    showScrollCartPage();
    setShowSaveForLater(true);
  };

  const handleClear = () => {
    dispatch(
      setPosFlowData({
        ...posFlowData,
        scheduleAdded: false,
        viaSwitchCustomer: false,
      })
    );
    showScrollCartPage();
    setProductSearch("");
    dispatch(clearSearchProduct());
    setShippingAddress(false);
    if (posFlowData?.cartProducts?.length > 0) {
      setCartEmpty(false);
    }
    clearFilter();
    setSearchProductsData([]);
    setFilteredSearchProduct([]);
    setSuggestionListVisible(true);
  };

  if (!cartId) {
    return <></>;
  }

  const getPoNum = () => {
    if (cartLoading) {
      return null;
    } else {
      if (loadCartDetails?.products.length) {
        if (isPurchaseOrderNoEdit) {
          return posFlowData?.poNumber || "Not set";
        } else if (purchaseOrderNum) {
          return purchaseOrderNum;
        } else {
          return "Not set";
        }
      } else {
        if (isPurchaseOrderNoEdit) {
          return posFlowData?.poNumber;
        } else {
          return "Not set";
        }
      }
    }
  };

  const handlePoNumberChange = (e) => {
    if (e.target.value === "" || poNumberRegex.test(e.target.value)) {
      dispatch(isPurchaseOrderNoEdited(true));
      const data = {
        ...posFlowData,
        poNumber: e.target.value,
      };
      dispatch(setPosFlowData(data));
      setPoNumberSave(e.target.value);
    }
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
  };

  const handleJobName = (e) => {
    if (jobAndReceiptNameRegex.test(e.target.value)) {
      setTempJobNameSave(e.target.value);
      dispatch(setJobNameForAll(e.target.value));
    }
  };

  const handleSalesManChange = async (e) => {
    const saleManName = e.target.value;
    const saleManDetail = salesPersonDetails?.data[saleManName];
    const data = {
      ...posFlowData,
      salesman: saleManDetail,
    };
    setSelectedSalesId(saleManName);
    const oldSalesman =
      JSON.parse(await localStorage.getItem("salesman")) || [];

    if (!_.find(oldSalesman, saleManDetail)) {
      oldSalesman.push(saleManDetail);
      localStorage.setItem("salesman", JSON.stringify(oldSalesman));
    }
    dispatch(setPosFlowData(data));
    dispatch(snakbarShow({ message: snackbarDetail.salePersonChange }))
    ref.current.blur();
  };

  const handleTakerChange = async (e) => {
    const takerName = e.target.value;
    const takerDetail = takerDetails?.data[takerName];
    const data = {
      ...posFlowData,
      taker: takerDetail,
    };
    setSelectedTakerId(takerName);
    if (takerDetail) {
      localStorage.setItem("taker", JSON.stringify(takerDetail));
      const oldRecentUser =
        JSON.parse(await localStorage.getItem("recentUser")) || [];
      if (!_.find(oldRecentUser, takerDetail)) {
        oldRecentUser.push(takerDetail);
        localStorage.setItem("recentUser", JSON.stringify(oldRecentUser));
      } else {
        const oldRecentUser =
          JSON.parse(await localStorage.getItem("recentUser")) || [];
        const filterRecentUser =
          oldRecentUser?.length &&
          oldRecentUser?.filter(
            (item) => item.personId !== takerDetail?.personId
          );
        filterRecentUser.push(takerDetail);
        localStorage.setItem("recentUser", JSON.stringify(filterRecentUser));
      }
      dispatch(setPosFlowData(data));
      dispatch(snakbarShow({ message: snackbarDetail.takerChange }))
      localStorage.setItem("taker", JSON.stringify(takerDetail));
      refTaker.current.blur();
    }
  };

  return (
    <>
      <div
        className={`titles-wrapper ${suggestionListVisible || !cartEmpty
          ? ""
          : "search-title-wrapper w-100"
          }`}
      >
        <PosHelmet title="POS" metaName="Admin page" content="admin page" />
        {!suggestionListVisible && cartEmpty && (
          <div className="cart-add-product-back-btn">
            <PosIconButton
              className="back-next-btn-small-left pos-icon-back-btn"
              onClick={handleClear}
            >
              {closeImage()}
            </PosIconButton>
          </div>
        )}
        <span className="title">
          {suggestionListVisible || !cartEmpty ? (
            `Cart #${cartId}`
          ) : (
            <div className="search-new-product-button">
              <div className="search-title-text">Search</div>
              <div className="import-and-product-btn-div">
                <PosButton
                  startIcon={<FileUploadIcon />}
                  variant="contained"
                  className="import-quote-btn"
                  onClick={handleOpen}
                >
                  {importQuote}
                </PosButton>
                <PosButton
                  variant="contained"
                  className="btn-add-customer new-product-btn"
                  onClick={selectAddProductWrapper}
                >
                  <PosImage
                    className="icn-btn"
                    src={addProductIcon}
                    alt="product"
                  />
                  New Product
                </PosButton>
              </div>
            </div>
          )}
        </span>
        <span className="subtitle">
          {!posFlowData?.cartProducts?.length ? (
            suggestionListVisible || !cartEmpty ? (
              addTitle
            ) : (
              ""
            )
          ) : !cartEmpty ? (
            <>
              {posFlowData?.cartProducts.length && (
                <div className="cart-add-product-back-btn">
                  <PosIconButton
                    className="back-next-btn-small-left pos-icon-back-btn button-focus"
                    onClick={handleClose}
                  >
                    {closeImage()}
                  </PosIconButton>
                </div>
              )}
              {posFlowData?.cartProducts?.length}
              {posFlowData?.cartProducts?.length
                ? posFlowData?.cartProducts?.length > 1
                  ? " items"
                  : " item"
                : ""}
              <Button
                label={"Clear Cart"}
                onClick={handleClick}
                classes={`clear-cart-button-focus ${isCartCleared ? "button-is-loading" : "clear-cart-button"
                  }`}
              />
            </>
          ) : null}
        </span>
      </div>
      {suggestionListVisible || !cartEmpty ? (
        <div className="info-blocks" ref={customerAndCardInfoRef}>
          {/* start CUSTOMER */}
          <div
            className="info-block"
            onClick={() => {
              dispatch(setTabIndexNumber(0));
              navigate(`${Paths.customer}?cart=${cartId}`, {
                state: { detail: posFlowData?.customer },
              });
            }}
          >
            <span className="label label-with-icon">
              CUSTOMER
              {selectTaxExemptFlag === GlobalNumbers.ONE && (
                <PosTooltip title="Customer Tax Exempt" placement="top">
                  <MoneyOffIcon className="money-off-icon ml-1" />
                </PosTooltip>
              )}
              {creditHoldFlag === GlobalNumbers.ONE && !isPosGeneric && (
                <PosTooltip title="Credit Hold" placement="top">
                  <span className="credit-hold-icon ml-5">
                    <CreditCardOffIcon />
                  </span>
                </PosTooltip>
              )}
            </span>
            {companyName ? (
              <div className={`primary-text one-line`}>{companyName}</div>
            ) : null}
            <div
              className={`${companyName ? "customer-and-cart-info" : "primary-text one-line"
                }`}
            >
              {cartLoading ? <PosLoader visible progress /> : posCustCode}
            </div>
          </div>
          {/* end CUSTOMER  */}
          {/* start PICKUP FROM */}
          <div className="info-block" onClick={handleShipping}>
            <span className="label label-with-icon">
              {/* TODO: might need later */}
              {/* {cartLoading ? (
                null
              ) : !shippingAddressSelected &&
                "PICKUP FROM"
              ) : shippingAddressSelected && checkMixOrder ? (
                "SHIP TO"
              ) : (
                "SHIP TO"
              )} */}
              {cartLoading
                ? null
                : posFlowData?.shiptoFacilities?.isStorePickup ||
                  (shipToByFacility?.isStorePickup && !shippingAddressSelected)
                  ? "PICKUP FROM"
                  : "SHIP TO"}
              {shippingTaxExemptFlag === 1 && (
                <PosTooltip title="Location Tax Exempt" placement="top">
                  <MoneyOffIcon className="money-off-icon ml-1" />
                </PosTooltip>
              )}
            </span>
            <span className="primary-text-ship">
              {posFlowData?.shiptoFacilities
                ? posFlowData?.shiptoFacilities?.facilityName
                : shipToByFacility?.facilityName}
            </span>
          </div>
          {/* End PICKUP FROM */}
          {/* Start SCHEDULE */}
          <div
            className="info-block"
            onClick={() => {
              selectScheduleWrapper();
              dispatch(arrowDraftReset());
              (document.activeElement as HTMLElement).blur();
            }}
          >
            <span className="label label-with-icon">SCHEDULE</span>
            <span className="primary-text">
              {cartLoading
                ? null
                : posFlowData?.schedule?.name
                  ? posFlowData?.schedule?.name
                  : customer?.priceScheduleName}
            </span>
          </div>
          {/* End SCHEDULE */}
          {/* Start Purchase Order */}
          <div onKeyDown={handleKeyDown}>
            <label htmlFor="poNumber" className="card-purchase-order pt-2">
              <span>PO Number</span>
              <input
                type="text"
                id="poNumber"
                placeholder={getPoNum() === "Not set" ? "Not set" : ""}
                autoComplete="off"
                spellCheck="false"
                value={poNumberSave}
                onChange={handlePoNumberChange}
              />
            </label>
          </div>
          {/* End Purchase Order # */}
          {/* Start Job name */}
          <div onKeyDown={handleKeyDown}>
            <label htmlFor="jobName" className="card-purchase-order pt-2">
              <span>Job Name</span>
              <input
                type="text"
                id="jobName"
                placeholder={!tempJobNameSave ? "Not set" : ""}
                autoComplete="off"
                spellCheck="false"
                value={tempJobNameSave}
                onChange={handleJobName}
              />
            </label>
          </div>
          {/* End Job name */}
          {/* Start SALESPERSON */}
          <div>
            <label
              htmlFor="salesperson"
              className="card-purchase-order-select pt-2 ">
              <span>SALESPERSON</span>
              <select
                id="salesperson"
                ref={ref}
                onChange={handleSalesManChange}
                value={selectedSalesId}
                key={selectedSalesId} // Dynamic key to force re-render
              >
                {salesPersonDetails?.data?.length &&
                  salesPersonDetails?.data?.map((option: any, index) => (
                    <option key={index} value={index}>
                      {option?.salespersonName}
                    </option>
                  ))}
              </select>
            </label>
          </div>
          {/* End SALESPERSON */}
          {/* Start TAKER */}
          {/* TODO: might need later */}
          {/* {products &&
          products?.length > 0 ? (
            <div className="info-block">
              <span className="label label-with-icon">TAKER</span>

              <span
                className={`primary-text ${
                  currentWorkspace?.warehouseName ? "one-line" : ""
                }`}
              >
                {
                  takerData?.data?.find(
                    (taker) => taker.personId === loadCartDetails.takerId
                  )?.fullName
                }
              </span>
              <span className="secondary-text">
                {currentWorkspace?.warehouseName}
              </span>
            </div>
          ) : ( */}
          <div>
            <label
              htmlFor="takerperson"
              className="card-purchase-order-select pt-2"
            >
              <span>TAKER</span>
              <select
                id="takerperson"
                ref={refTaker}
                onChange={handleTakerChange}
                value={selectedTakerId}
                key={selectedTakerId} // Dynamic key to force re-render
              >
                {takerDetails?.data?.length &&
                  takerDetails?.data?.map((option: any, index) => (
                    <option key={index} value={index}>
                      {option?.fullName}
                    </option>
                  ))}
              </select>
              <span className="warehouse-name">{warehouseName}</span>
            </label>
          </div>
          {/* End TAKER */}
        </div>
      ) : null}
    </>
  );
};

export default CustomerAndCartInfo;
