import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";

const MovingAvgSkeleton = (props) => {
  const {numberOfColumns} = props;

  const columns = Array.from({ length: numberOfColumns });

  return (
    <tr>
      {columns.map((_, index) => (
        <td key={index}>
          <ShapedSkeleton
            width={80}
            height={36}
            shape='0 11 100 14'
            radius={4}
          />
        </td>
      ))}
    </tr>
  );
};

export default MovingAvgSkeleton;