import { useDispatch } from 'react-redux';

import { PosIconButton } from "apps/pos/Common/uiCore";

import { addOrRemoveProperty } from "apps/product/helper/product-details-helper";

import { manipulateStarredProductDetails } from "sagas/persistReducer/actions";
import MaterialIcon from 'apps/pos/Common/uiCore/MaterialIcon';

export const StarIconButton = (props) => {
  const { className, section, starredProductsDetails,  hasKeyInCategory } = props; 
  const dispatch = useDispatch();

  const updateOrRemoveObject = (section: string) => {
    const result = addOrRemoveProperty(
      section,
      className,
      starredProductsDetails,
    )
    dispatch(
      manipulateStarredProductDetails(
        {...result}
      )
    );
  };
    
  return (
    <PosIconButton
      className="star-icon-report"
      color="primary"
      height={"30px"}
      width={"30px"}
      onClick={() => updateOrRemoveObject(section)}
      title={hasKeyInCategory(className) ? "Remove from Starred" : "Add to Starred"}
    >
      <MaterialIcon icon={hasKeyInCategory(className) ? "star" : "star_border"} size="20" />
      
    </PosIconButton>
  );
};
