const TakerList = ({
  selectFromAllUsers,
  takerRowRef,
  selectedIndex,
  allTakerData,
}) => {
  let refIndex = -1;
  const { takersData } = allTakerData;
  return takersData.map((takersData, index) => {
    return (
      <div className="pricing-frequently">
        <span className={`modal-subheading ${index === 0 ? "mt-0" : ""} mb-0`}>
          {takersData.label}
        </span>
        {takersData.list?.map((taker) => {
          refIndex = ++refIndex;
          const selectTaker = () => {
            selectFromAllUsers(taker)
          }
          return (
            <div
              onClick={selectTaker}
              key={`taker-${refIndex}`}
              ref={takerRowRef[refIndex]}
              id={`takerRowRef${refIndex}`}
              className={
                refIndex === selectedIndex
                  ? "frequently-used-schedule frequently-used-schedule-index"
                  : "frequently-used-schedule"
              }
            >
              <h3 className="select-schedule-list schedule-list-styling">
                {taker.fullName}
              </h3>
              <i className="fas fa-arrow-right right-icon"></i>
            </div>
          );
        })}
      </div>
    );
  });
};

export default TakerList;
