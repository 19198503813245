import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";

import { addShipmentInStorage } from "./constantUrl";
import { stopLoading } from "utils/utils";

export interface ItemProps {
  shipmentItemID: number;
  productID: number;
  manufacturerCode: string;
  productName: string;
  itemType: string;
  qtyPutInStorage: number;
}
interface AddShipmentInStorageProps {
  handlerID: number;
  orderID: number;
  shipmentID: number;
  storageLocation: string;
  estDeliveryDate: string;
  reason: string;
  items: ItemProps[];
}

export const useAddShipmentInStorage = () => {
  const { mutate, isLoading, data, error, isSuccess } = useMutation(
    async (payload: AddShipmentInStorageProps) => {
      return addShipmentInStorage(payload);
    },
    {onSuccess : stopLoading,
      onError : stopLoading
});

  const addShipmentInStorageFn = useCallback(
    (payload) => {
      mutate(payload);
    },
    [mutate]
  );

  return {
    addShipmentInStorageFn,
    isLoading,
    data,
    error,
    isSuccess,
  };
};
