import { TenantError, TenantSuccess } from "./actions";
import { call, put, takeLatest } from "redux-saga/effects";

import { AnyAction } from "redux";
import { TENANT_FETCHING } from "./constants";
import { getTenant } from "../../services";

export function* TenantFetchFlow(action: AnyAction): any {
  try {
    const tenantResponse = yield call(getTenant, action.fullDomain);
    yield put(TenantSuccess(tenantResponse));
  } catch (errors) {
    yield put(TenantError(errors));
  }
}

function* tenantWatcher() {
  yield takeLatest(TENANT_FETCHING, TenantFetchFlow);
}

export default tenantWatcher;
