import { useId, useState } from "react";

import { Button } from "components";

import "./TrunctedText.scss";
const TruncatedText = (props) => {
  const {
    children,
    minLength = 100,
    expandLabel = 'Show more',
    collapseLabel = 'Show less',
    allowCollapse = false,
    initialState = false,
    className = ""
  } = props;

  const [open, setOpen] = useState<boolean>(initialState);
  const id = useId();

  return (
    <>
      <span
        className="ellipsis-span"
        aria-live="polite"
      >
        {children.substring(0, open ? children.length : minLength)}
        {open ? '' : '…'}
      </span>
      <Button
        className={`${className} ellipsis-button ${open && !allowCollapse ? 'd-none' : 'ellipsis-button-display'}`}
        onClick={() => { setOpen(isOpen => !isOpen) }}
        aria-expanded={open}
        aria-controls={id}
      >
        {open ? collapseLabel : expandLabel}
      </Button>
      <span
        id={id}
        className="d-none"
      >
        {children}
      </span>
    </>
  )
}

export default TruncatedText