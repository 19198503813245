import {
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";
// component


const useStyles = makeStyles(({
  title: {
    fontSize: "1.3125rem",
    fontFamily: "Source Code Pro",
    fontStyle: "normal",
    color: "#1B212A",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  buttonClassName: {
    padding: '0',
    minWidth: '30px',
    width: '24px',
    height: '24px',
  },
  closeSharp: {
    fontSize: '20px'
  },
}));

//This is a Plain modal Component which will render different pages with children

const CommonModel = (props) => {
  const classes = useStyles();
  const { title, children, openModal, dialogClassName, closeModal, closeIconClassName, noCloseBtn, ...other } = props;
  return (
    <Box>
      <Dialog open={openModal} className={dialogClassName} onClose={closeModal}      
      {...other}
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography className={`${classes.title} model--title`}>{title}</Typography>
            {!noCloseBtn && <Button
              onClick={closeModal}
              // variant="outlined"
              size="small"
              className={`${classes.buttonClassName} ${closeIconClassName}`}
            >
              <i className="fa fa-times icn-btn"></i>
            </Button>}

          </Box>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </Box>
  );
};

export default CommonModel;