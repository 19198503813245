import React from "react";

import { InputAdornment, OutlinedInput } from "@material-ui/core";

import searchIcon from "../images/icons/searchIcon.svg";

import CommonSelect from "../../../components/Select";

import "../../../styles/admin/springboard/quoteSearch.scss";

const QuoteSearch = () => {
  return (
    <div className="mt-1">
      <div style={{ flexDirection: "column", display: "flex" }}>
        <OutlinedInput
          id="custom-css-outlined-input"
          startAdornment={
            <InputAdornment position="start">
              {/* <button type="button" className="btn btn-link p-0"> */}
              <img src={searchIcon} color="black" alt="" />
              {/* </button> */}
            </InputAdornment>
          }
          placeholder="Search quotes"
          className="search-input"
          labelWidth={0}
        />
        <span className="label-text-light">
          Press Enter to search, Esc to close
        </span>
      </div>

      <div className="mt-4 flex-direction-column">
        <span className="label-text-black inter-reg">Show quotes from</span>
        <CommonSelect
          options={[{ label: "Heather Valerie", value: "Heather Valerie" }]}
          isMulti
        />
      </div>

      <div className="mt-4 flex-direction-column">
        <span className="label-text-black inter-reg">Job Status</span>
        <CommonSelect
          options={[
            { label: "pending", value: "Pending" },
            { label: "approved", value: "Approved" },
          ]}
          placeholder="Any"
        />
      </div>

      <div className="mt-4 flex-direction-column">
        <span className="label-text-black inter-reg">Number of days</span>
        <CommonSelect
          options={[
            { label: "1 day", value: "1 day" },
            { label: "1 week", value: "1 week" },
            { label: "2 weeks", value: "2 weeks" },
          ]}
          placeholder="All time"
          style={{ height: 50 }}
        />
      </div>
      <div className="mt-4 flex-direction-column">
        <span className="label-text-black inter-reg">Sent between</span>
        <div className="mt-2 flex-row-space-between">
          <OutlinedInput
            className="date-input"
            id="date"
            type="date"
            placeholder="From"
          />
          <OutlinedInput
            className="date-input"
            id="date"
            type="date"
            placeholder="To"
          />
        </div>
      </div>
      <div className="divider-grey-search" />
      <div className="d-flex flex-row-space-between">
        <button
          type="button"
          className="btn bg-white fs-14 inter-med black_text"
        >
          CLEAR ALL
        </button>
        <button
          type="button"
          className="btn fs-14 inter-med pl-40 pr-40 btn-primary"
        >
          SEARCH
        </button>
      </div>
    </div>
  );
};

export default QuoteSearch;
