import SchedulesGroup from "apps/product/Modals/SchedulesGroup";

const ProductDetailRetailPrice = (props) => {
    const { schedules , handleOpenCloseRetailModelModal} = props || {}
    return (
        <div className="group-wrapper">
            <div className="group-content">
                <SchedulesGroup schedules={schedules} visible onClose={handleOpenCloseRetailModelModal}/>
            </div>
        </div>
    )
}

export default ProductDetailRetailPrice;