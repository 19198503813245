export const dataExportData = [
  { key: "createdAt", label: "Date" },
  { key: "cartId", label: "ID" },
  { key: "customerCode", label: "Customer Code" },
  { key: "purchaseOrderNum", label: "PO #" },
  { key: "jobName", label: "Job Name" },
  { key: "takerName", label: "Taker Name" },
  { key: "salesPersonName", label: "Salesperson" },
  { key: "itemCount", label: "Items" },
];
