import React from "react";
import "@fontsource/inter";
import MaterialIcon from "../uiCore/MaterialIcon";
import "./button.scss";

interface ButtonProps {
  icon?: any;
  variant?: any;
  shortcutKey?: any;
  disabled?: boolean;
  label?: string;
  // addClickListener(onclick: (this: void, e: Event) => void): void;
  onClick?: (e: any) => void;
  // onClick?: () => void;
  ref?: () => void;
  classes?: string;
  size?: string;
  title?: string;
  className?: string;
  type?: "submit" | "reset" | "button" | undefined;
  children?: any;
  tabIndex?: number;
  id?: string;
}

const KeyboardShortcutBlock = ({ text }) => {
  // TODO - handle keyboard events here itself
  return text && <div className="keyboard-shortcut-block">{text}</div>;
};

const Button = (props: ButtonProps) => {
  const {
    label,
    icon,
    onClick,
    classes,
    disabled,
    title,
    size,
    variant,
    shortcutKey,
    children,
    type,
    className,
    tabIndex,
    id,
    ref,
  } = props;

  return (
    <button
      className={`btn ${classes ? classes : ""} ${size ? size : ""} ${
        variant ? variant : ""
      } ${className ? className : ""}`}
      onClick={onClick}
      disabled={disabled}
      title={title}
      type={type}
      tabIndex={tabIndex}
      id={id}
      ref={ref}
    >
      <div className="label-group">
        {icon && <MaterialIcon icon={icon} classes="btn-icon" />}

        <span className="btn-label">{label}</span>
      </div>

      {shortcutKey && <KeyboardShortcutBlock text={shortcutKey} />}
      {children}
    </button>
  );
};

export default Button;
