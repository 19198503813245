import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import "@fontsource/inter";

import {
  PosHelmet,
  PosIconButton,
  PosImage,
  PosPageHeading,
  Paper,
  Container,
} from "apps/pos/Common/uiCore";
import { EnhancedTableHead } from "./PurchaseHistoryComponent/EnhancedTableHead";
import { EnhancedTableBody } from "./PurchaseHistoryComponent/EnhancedTableBody";
import { TablePaginationActions } from "./PurchaseHistoryComponent/TablePaginationActions";
import { useStyles } from "./PurchaseHistoryComponent/data";

import { ArrowBackRoundedIcon } from "constants/icons";
import images from "constants/images";
import { PosFlowDataProp } from "constants/interfaces";
import { Paths } from "enums";
import { keys } from "utils/helper";
import { getCurrentWorkspace } from "utils/utils";
import { PosResponseProps, RootState } from "models";

import {
  addToCartDrawer,
  getPurchaseHistoryRequest,
  stockByWarehouseStart,
} from "sagas/pos/actions";

import "apps/pos/PurchaseHistory.scss";

const PurchaseHistory = () => {
  const { loadCartDetails } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { purchaseHistory, purchaseHistoryTotal, isLoading, productsData } = useSelector<
    RootState,
    PosResponseProps
  >((state) => state.pos);

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productIdInfo: any = useParams();

  const { state , pathname } = location;
  const productInfo = state?.detail;
  const {productName} = productInfo || {}
  
  const isLeapFrongReport = (pathname === `${Paths.product}/${productIdInfo.id}/${Paths.reports}/${Paths.purchaseHistoryReport}`)
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('productName');

  useEffect(() => {
    const currentWorkspace = getCurrentWorkspace();
    const data = {
      productId: productIdInfo.id,
      stockWarehouseId: isLeapFrongReport ? 0 : currentWorkspace?.stockWarehouseId,
      page: page + 1,
      size: rowsPerPage,
    };
    dispatch(getPurchaseHistoryRequest(data));
  }, [page, rowsPerPage]);

  // start
  function stableSort(purchaseHistoryInfo, comparator) {
    if (purchaseHistory) {
      const stabilizedThis =
        purchaseHistoryInfo?.map((el, index) => [el, index]);
      stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis && stabilizedThis?.map((el) => el[0]);
    }
  }

  // end
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // TODO:need this code
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const { defaultIcon } = images;

  const backToCartPage = () => {
    const { cartId } = loadCartDetails
    const { productId } = productsData
    dispatch(stockByWarehouseStart([Number(productIdInfo.id)]));
    if(productId) {
      dispatch(addToCartDrawer(true))
    }
    navigate(`${Paths.posCart}/${cartId}`);
  }

  const handleKeyPressDown = (e) => {
    const { keyCode } = e;
    const { esc } = keys;
    const { cartId } = loadCartDetails
    if (keyCode === esc && cartId) {
      backToCartPage();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPressDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPressDown);
    };
  }, [loadCartDetails, productsData]);

  return (
    <div className="history-main-section">
      <PosHelmet title={!isLeapFrongReport ? "POS" : `Product Detail - ${isLeapFrongReport ? paramValue : productName} - ${productIdInfo.id}` } metaName="Admin page" content="Admin page" />
      <section className="item-history-section">
          <div className={`item-history-container ${isLeapFrongReport ? "ml-90" : ""}`}>
          {!isLeapFrongReport ?  <PosIconButton
                className="back-button pos-icon-back-btn"
                onClick={backToCartPage}
                tabIndex={-1}
              >
                <ArrowBackRoundedIcon className="back-icon" />
              </PosIconButton> : null}
            <div className="item-history-title-container">
              <div className="title">
              <PosPageHeading heading="Purchase History" blackHeading />
                  <PosPageHeading heading="What was Purchased" grayHeading />
              </div>
              <div className="item-history-products-container">
                <div className="product-ledger-image">
                  <PosImage
                    src={defaultIcon}
                    alt="product-icon"
                  />
                  <div className="product-ledger-name">
                    <div className="name">{isLeapFrongReport ? paramValue : productName}</div>
                    <div className="key">{productIdInfo.id}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      <Container maxWidth={false} className="table-main-container">
        <Paper className="purchase-history-table-section">
          <div
            className={`${classes.table} purchase-history-table-data`}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            {isLoading ? (
              <></>
            ) : (
              <EnhancedTableBody
                purchaseHistory={purchaseHistory}
                stableSort={stableSort}
              />
            )}
          </div>
          <TablePagination
            component="div"
            count={purchaseHistoryTotal ? purchaseHistoryTotal : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={[]}
          />
        </Paper>
      </Container>
    </div>
  );
};

export default PurchaseHistory;
