import './ManageUserForm.scss';

export default function Skeleton() {
    return (
        <div className='skeleton'>
            <div className='skeleton-icon'></div>
            <div className="dialog-title"></div>
            <div className="user-profile-joining-date"></div>
        </div>
    )
}