import "@fontsource/inter";
import { Drawer } from "@material-ui/core";
import { useEffect, useState } from "react";
import { drawerUseStyles } from "theme/GlobalStyles";

import AddNewProduct from "../AddNewProduct";
import CartPage from "../CartPage";
import EditPurchaseOrderNum from "../EditPurchaseOrderNum";
import PricingScheduleModal from "../PricingModal";
import SelectSalesPersons from "../SelectSalesPersons";
import SelectShippingAddress from "../SelectShippingAddress";
import SelectTakerModal from "../SelectTakerModal/SelectTakerModal";


import "./drawers.scss";

const CartDrawer = ({
  state,
  setUpdatedProductsFlag,
  updatedProductsFlag,
  selectSalesman,
  setState,
  onCloseDrawer,
  drawerClass,
  selectTaker,
  shippingAddress,
  setPickUpSelected,
  setShipToByFacilityId,
  showTaker,
  setPurchaseOrderNum,
  setSearchProductsData,
  setCartEmpty,
  selectSchedule,
  purchaseOrder,
  addProduct,
  cartDrawerRef,
  selectCustomer,
  drawerRef,
  setSelectSalesman,
  handleRemoveClick,
  setAddProduct,
  isWareHouseDrawer,
  setPurchaseOrder,
  setSelectTaker,
  setSelectSchedule,
  setNotesOpen,
  setNotesPlace,
  allocatedDraftRowRef,
  setFilteredSearchProduct
}) => {

  const classes = drawerUseStyles();

  // useEffect(() => {
  const element = document.getElementById("noScrollContent");

  if (element) {
    const cartElement = document.getElementById("cartDrawerContent");
    cartElement?.classList?.add("cart-drawer-styling");
  }
  const cardDrawer =
    !selectSalesman &&
    !selectTaker &&
    !shippingAddress &&
    !selectSchedule &&
    !purchaseOrder &&
    !addProduct &&
    !selectCustomer &&
    state["right"];
  const isOpenDrawer = [
    selectSalesman,
    selectTaker,
    shippingAddress,
    selectSchedule,
    purchaseOrder,
    addProduct,
    isWareHouseDrawer,
  ].includes(true);

  useEffect(() => {
    // Might need this code
    // !state["right"] && isWareHouseDrawer && dispatch(addToCartDrawer(false));
    state["right"] && setState({ ...state, right: isOpenDrawer });
  }, [state["right"]]);

  const [isNotCartDataLoaded, setIsNotCartDataLoaded] = useState(null);
  const handleCartDrawerCallback = (childData) => {
    setIsNotCartDataLoaded(childData);
  };
  const commonCloseDrawer = () => {
    onCloseDrawer();
    setAddProduct(false);
  };

  const handleDrawerClose = () => {
    if (isWareHouseDrawer) {
      !isNotCartDataLoaded && commonCloseDrawer();
    } else {
      commonCloseDrawer();
    }
  };

  return (
    <Drawer
      className={`${drawerClass} ${classes.drawer} main-drawer-classes drawer-scrollbar-style ${isWareHouseDrawer ? "cart-page-drawer-sec" : "no-cart-drawer"
        } default-drawer-style`}
      anchor={"right"}
      onClose={handleDrawerClose}
      open={isOpenDrawer}
      ref={cartDrawerRef}
      id={cardDrawer ? "cartDrawerContent" : ""}
    >
      {selectSalesman ? (
        <SelectSalesPersons
          state={state}
          setState={setState}
          onClick={onCloseDrawer}
          drawerRef={drawerRef}
          setSelectSalesman={setSelectSalesman}
        />
      ) : selectTaker ? (
        <SelectTakerModal
          selectTaker={selectTaker}
          setSelectTaker={setSelectTaker}
          state={state}
          setState={setState}
          drawerRef={drawerRef}
          onCloseDrawer={onCloseDrawer}
        />
      ) : shippingAddress ? (
        <SelectShippingAddress
          setPickUpSelected={setPickUpSelected}
          state={state}
          setState={setState}
          setShipToByFacilityId={setShipToByFacilityId}
          drawerRef={drawerRef}
          onCloseDrawer={onCloseDrawer}
          setPaymentMethodWrapper={() => {
            // Will remove this by providing types
          }}
          setEscapePress={() => {
            // Will remove this by providing types
          }}
          escapePress={false}
          openFromHeader={true}
        />
      ) : selectSchedule ? (
        <PricingScheduleModal
          selectSchedule={selectSchedule}
          state={state}
          setState={setState}
          showTaker={showTaker}
          drawerRef={drawerRef}
          onCloseDrawer={onCloseDrawer}
          setSelectSchedule={setSelectSchedule}
        />
      ) : purchaseOrder ? (
        <EditPurchaseOrderNum
          setPurchaseOrder={setPurchaseOrder}
          state={state}
          setState={setState}
          setPurchaseOrderNum={setPurchaseOrderNum}
          onCloseDrawer={onCloseDrawer}
          drawerRef={drawerRef}
        />
      ) : addProduct ? (
        <AddNewProduct
          onCloseDrawer={onCloseDrawer}
          setState={setState}
          setAddProduct={setAddProduct}
          state={state}
        />
      ) : isWareHouseDrawer ? (
        <CartPage
          setUpdatedProductsFlag={setUpdatedProductsFlag}
          updatedProductsFlag={updatedProductsFlag}
          setSearchProductsData={setSearchProductsData}
          setCartEmpty={setCartEmpty}
          state={state}
          setState={setState}
          onCloseDrawer={handleDrawerClose}
          handleRemoveClick={handleRemoveClick}
          setPickUpSelected={setPickUpSelected}
          setShipToByFacilityId={setShipToByFacilityId}
          handleCartDrawerCallback={handleCartDrawerCallback}
          setNotesOpen={setNotesOpen}
          setNotesPlace={setNotesPlace}
          allocatedDraftRowRef={allocatedDraftRowRef}
          setFilteredSearchProduct={setFilteredSearchProduct}
        />
      ) : null}
    </Drawer>
  );
};

export default CartDrawer;
