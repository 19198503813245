import PosGrid from "apps/pos/Common/uiCore/posDrawer/posGrid";
import PosMainGrid from "apps/pos/Common/uiCore/posMainGrid";
import texts from "apps/pos/locales";
import { staticData, title } from "constants/constant";
import { PosFlowDataProp } from "constants/interfaces";
import { RootState } from "models";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import OrderInfoCommonGrid from "./OrderInfoCommonGrid";

const OrderInfo = ({
    subTotal,
    taxAmount,
    shipTaxAmount,
    orderId,
    shippingTaxAmount,
    salesPersonName,
    shipToTaxFlag,
    shippingFee,
    creditAmtApplied,
}) => {

    const { isTaxExempted, posFlowData, shippingAddressSelected } = useSelector<RootState, PosFlowDataProp>(
        (state) => state.persistReducer
    );

    const { order, subTotalAmt, grandTotal, Salesperson, ShippingAddress, PickupAddress, totalTax, shippingFeeStatic, storeCredit } = texts;
    const { shiptoFacilities, shipToByFacility } = posFlowData || {};

    const { street, city, stateName, zip } = shiptoFacilities?.recipient || {};
    const salesShippingAddress = `${street ? street + ", " : ""} ${city ? city + ", " : ""
        } ${stateName ? stateName + ", " : ""} ${zip || ""}`;
    const salesFacilityName = shiptoFacilities?.facilityName;

    const addressLabel = shiptoFacilities?.isStorePickup ||
        (shipToByFacility?.isStorePickup && !shippingAddressSelected)
        ? PickupAddress
        : ShippingAddress


    const orderTaxTotal = useMemo(() => {
        // might need later : Tax amount calculation  Updated 
        // return (isTaxExempted ? 0 : Number(Number(taxAmount)?.toFixed(2)) + Number(Number(shipTaxAmount)?.toFixed(2)) + Number(Number(shippingTaxAmount)?.toFixed(2)));
        return ((isTaxExempted ? 0 : Number(Number(taxAmount)?.toFixed(2))) + Number(Number(shippingTaxAmount)?.toFixed(2)));
    }, [taxAmount, shipTaxAmount]);

    const orderGrandTotal = useMemo(() => {
        return (Number(Number(subTotal)?.toFixed(2)) + Number(Number(orderTaxTotal)?.toFixed(2)));
    }, [subTotal, orderTaxTotal]);

    const formatNumber = (num) => {
        const formatted = num.toFixed(2);
        return Math.abs(formatted) <= 0.00 ? "0.00" : formatted;
    }
    const { totalPaymentDue } = title
    const totalPayment = useMemo(() => {
        return (Number(subTotal) + Number(Number(shippingFee)?.toFixed(2)) + (isTaxExempted ? 0 : Number((Number(taxAmount)).toFixed(2))) - Number(creditAmtApplied ?? 0));
    }, [subTotal, shippingFee, taxAmount, shipTaxAmount, creditAmtApplied, isTaxExempted]);

    return (
        <>
            <div className="d-flex align-items-center mb-2">
                <div className="int-16-reg fw-400 letter-space-0-14 text-nowrap">
                    {`${order} # ${orderId}`}
                </div>
                <div className="divider-date-div" />
            </div>
            <div className="payment-info-layout">
                <PosMainGrid className="grid-container">
                    <OrderInfoCommonGrid label={subTotalAmt} value={`$${Number(subTotal)?.toFixed(2)}`} />
                    {
                        shipToTaxFlag ?
                            <>
                                <OrderInfoCommonGrid label={shippingFeeStatic} value={`$${Number(shippingFee)?.toFixed(2)}`} />
                            </> : null
                    }
                    <OrderInfoCommonGrid label={totalTax} value={`$${(isTaxExempted ? "0.00" : Number(Number(taxAmount)?.toFixed(2)))}`} />
                    {
                        !shipToTaxFlag ?
                            <>
                                <OrderInfoCommonGrid label={shippingFeeStatic} value={`$${Number(shippingFee)?.toFixed(2)}`} />
                            </> : null
                    }
                    <OrderInfoCommonGrid label={grandTotal} value={`$${orderGrandTotal?.toFixed(2)}`} />
                    {
                        creditAmtApplied ?
                            <>
                                <OrderInfoCommonGrid label={storeCredit} value={`-$${Number(creditAmtApplied)?.toFixed(2)}`} />
                            </> : null
                    }
                    {
                        creditAmtApplied ?
                            <>
                                <PosGrid xs={12} sm={6} md={6} className="balance-left-info">
                                    <div>
                                        <span className="int-16-reg fw-500">
                                            {totalPaymentDue}
                                        </span>
                                    </div>
                                </PosGrid>
                                <PosGrid xs={12} sm={6} md={6} className="grid-value">
                                    <span className="int-16-reg fw-400">
                                        ${formatNumber(totalPayment)}
                                    </span>
                                </PosGrid>
                            </> : null
                    }

                </PosMainGrid>
                <div>
                    <PosGrid xs={12} sm={6} md={6} className="mb-1 balance-left-info label-font">
                        <span className="fw-400">
                            {Salesperson}
                        </span>
                    </PosGrid>
                    <PosGrid xs={12} sm={6} md={6} className="mb-2 balance-left-info">
                        {
                            salesPersonName ?
                                <span className="int-16-reg fw-400 ">
                                    {salesPersonName}
                                </span> :
                                <span className="int-16-reg fw-500 light-gray-text">
                                    {staticData.notSet}
                                </span>
                        }
                    </PosGrid>

                    <PosGrid xs={12} sm={6} md={6} className="mb-1 balance-left-info label-font">
                        <span className="fw-400">
                            {addressLabel}
                        </span>
                    </PosGrid>
                    <PosGrid xs={12} sm={6} md={6} className="d-flex flex-column">
                        <span className="int-16-reg fw-500">
                            {salesFacilityName}
                        </span>
                        <span className="int-16-reg fw-400">
                            {salesShippingAddress}
                        </span>
                    </PosGrid>
                </div>

            </div>
            <div className="payment-hide-price-option"></div>
        </>
    )
}

export default OrderInfo;