import { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { formatDate } from "utils/dayjsFunc";
import HandleLongData from "apps/pos/Common/HandleLongData";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";
import { useGetOrder } from "apps/springboard/QuoteDetail/hooks/useGetOrder";

import "apps/springboard/Component/QuoteOrders/quoteOrder.scss";
import images from "constants/images";
import { PosImage } from "apps/pos/Common/uiCore";

const QuoteOrders = ({ quoteId, selectedQuote, httpsUrl }: any) => {
  const [quoteOrdersData, setQuoteOrdersData] = useState([]);
  const { orderData, isOrderLoading, isOrderSuccess, setOrderId } =
    useGetOrder();
  const { arrowForward } = images;

  useEffect(() => {
    setOrderId(quoteId);
  }, []);

  useEffect(() => {
    if (isOrderSuccess) {
      setQuoteOrdersData(orderData);
    }
  }, [isOrderSuccess]);

  const openLink = (url) => {
    window.open(url, "_blank");
  };

  const { jobQuoteTitle } = selectedQuote;

  return (
    <>
      <div className="quote-orders-container">
        {isOrderLoading ? (
          <ShapedSkeleton
            shape="0 0 30 16, 0 34 30 16, 0 68 30 16, 66 0 70 16, 66 34 70 16, 66 68 70 16, 176 0 100 16, 176 34 100 16, 176 68 100 16, 334 0 280 16, 334 34 230 16, 334 68 200 16"
            width={614}
            height={84}
            radius={4}
            classes="mt-3"
          />
        ) : (
          <>
            <div className="fw-medium fs-14 pd-top-15">
              {quoteOrdersData?.length
                ? `${quoteOrdersData.length} ${
                    quoteOrdersData.length > 1 ? "Orders" : "Order"
                  } placed`
                : `No Orders placed`}
            </div>
            <div id="scrollContainer">
              <TableContainer
                component={Paper}
                className="customer-info-table-container"
              >
                <Table aria-label="simple table">
                  {!!quoteOrdersData?.length ? (
                    <TableHead>
                      <TableRow>
                        <TableCell className="customer-order-table-title first-table-row">
                          ID
                        </TableCell>
                        <TableCell className="customer-order-table-title second-table-row">
                          Date
                        </TableCell>
                        <TableCell className="customer-order-table-title third-table-row">
                          Status
                        </TableCell>
                        <TableCell className="customer-order-table-title fourth-table-row">
                          Job Name
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  ) : (
                    <></>
                  )}

                  <TableBody>
                    {quoteOrdersData?.length > 0 &&
                      quoteOrdersData?.map((row) => {
                        const { orderID, placeDate, orderStatusName } = row;
                        return (
                          <TableRow
                            className="customer-table-data-row cursor-pointer"
                            key={orderID}
                            onClick={() => {
                              openLink(
                                `${httpsUrl}/gmSale/msOrder_detail?orderID=${orderID}`
                              );
                            }}
                          >
                            <TableCell className="customer-table-row-data-styling-for-order">
                              <span>{orderID}</span>
                            </TableCell>
                            <TableCell className="customer-table-row-data-styling-for-order">
                              <span>{formatDate(placeDate, "MMM DD, YY")}</span>
                            </TableCell>
                            <TableCell className="customer-table-row-data-styling-for-order">
                              <HandleLongData jobName={orderStatusName} />
                            </TableCell>
                            {jobQuoteTitle ? (
                              <TableCell className="customer-table-row-data-styling-for-order">
                                <HandleLongData jobName={jobQuoteTitle} />
                              </TableCell>
                            ) : null}
                            <TableCell key={orderID}>
                              <div className="draft-customer-table-row-data-styling customer-table-row-hover-data d-flex h-100 ">
                                <button
                                  className="customer-table-row-remove border-0 bg-transparent p-0 button-focus-delete-draft"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openLink(
                                      `${httpsUrl}/gmSale/msOrder_detail?orderID=${orderID}`
                                    );
                                  }}
                                >
                                  <PosImage
                                    src={arrowForward}
                                    alt="delete-icon"
                                    className="delet-order-img"
                                  />
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default QuoteOrders;
