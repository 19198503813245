import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PosButton, PosImage, PosModel } from "apps/pos/Common/uiCore";
import AddDocumentModal from "apps/pos/pages/customerInfo/Documents/addDocument/AddDocumentModal";
import { Button } from "components";

import images from "constants/images";
import { PosFlowDataProp, UploadedDocumentItemProp } from "constants/interfaces";
import { RootState } from "models";

import { setPosFlowData } from "sagas/persistReducer/actions";

import { buttonTitle, sectionTitle } from "constants/constant";
import { AddIcon } from "constants/icons";
import "./AttachedDocumentModal.scss";

export interface AttachedDocumentModalProp {
  taxOpen?: boolean;
  setTaxOpen?: Dispatch<SetStateAction<boolean>>;
  handleClick?: (e) => void;
  locationShow?: boolean;
  documentBy?: string;
}

export default function AttachedDocumentModal({
  taxOpen,
  setTaxOpen,
  documentBy
}: AttachedDocumentModalProp) {
  const dispatch = useDispatch();

  const [openAddDocumentModel, setOpenAddDocumentModel] = useState(false);

  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );

  const handleClose = () => {
    setTaxOpen(false);
  };

  const { plusWhiteIcon, closeIcon, noDocumentIcon } = images;
  const { remove } = buttonTitle;
  const { toBeUploaded } = sectionTitle;
  const [documentListItem, setDocumentListItem] = useState([])
  useEffect(() => {
    const filterDocumentByCustomer = posFlowData?.addDocument.filter((item) => item?.customerId === posFlowData?.customer?.customerId)
    filterDocumentByCustomer?.length && setDocumentListItem(filterDocumentByCustomer)
  }, [posFlowData?.addDocument])

  const removeItem = (index) => () => {
    const newDocumentList = documentListItem.filter((_, i) => i !== index);
    setDocumentListItem(newDocumentList);
  }

  const modalClose = () => {
    const data = {
      ...posFlowData,
      addDocument: documentListItem,
    }
    dispatch(setPosFlowData(data));
    handleClose();
  }

  return (
    <>
      <PosModel
        dialogClassName='attach-document-modal'
        open={taxOpen}
        onClose={() => modalClose()}
      >
        <div>
          <Button onClick={() => modalClose()} className='attach-document-close-icon button-focus'>
            <PosImage src={closeIcon} alt="close-icon" />
          </Button>
        </div>
        <div>
          <div id="transition-modal-title" className={`${!documentListItem?.length ? 'justify-content-between' : ''} d-flex`}>
            <div className='attach-document-title'>
              <span>Attach Documents</span>
            </div>
          </div>
          <div className="d-flex justify-content-between documents-added-title-sec">
            {documentListItem?.length ?
              <>
                <span className="documents-added-title">
                  {documentListItem?.length} {`${documentListItem?.length === 1 ? 'Document' : 'Documents'} added`}
                </span>
                <PosButton
                  className='attached-document-add-btn add-doc-btn btn'
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const data = {
                      ...posFlowData,
                      addDocument: documentListItem,
                    }
                    dispatch(setPosFlowData(data));
                    setOpenAddDocumentModel(true);
                    setTaxOpen(false);
                  }}
                  fontWeight
                >
                  <PosImage src={plusWhiteIcon} alt="upload-icon" className="upload-icon-spacing" />
                  Add Document
                </PosButton>
              </>
              : null}
          </div>
          <div className="td-list">
            {documentListItem?.length ? documentListItem.map((item: UploadedDocumentItemProp, index) => {
              const savedType = item?.documentSaveTypeName?.replace(/_/g, " ");
              return (
                <div className="attach-document-list" key={index}>
                  <div className="attach-document-data">
                    <div className="attach-document-name">
                      <span>{item.file}</span>
                    </div>
                    <div className="attach-document-type">
                      <div className="attach-document-type-info">
                        <span>{savedType}</span>
                      </div>
                      <span className="small-dot-type"></span>
                      <div className="attach-document-status">
                        <span>{toBeUploaded}</span>
                      </div>
                    </div>
                  </div>
                  <Button className="attach-document-remove-link" onClick={removeItem(index)}>
                    <span>{remove}</span>
                  </Button>
                </div>
              );
            }) : <div className="mt-10 text-center row justify-content-center">
              <PosImage
                src={noDocumentIcon}
                height={100}
                width={100}
                alt="add product"
              />
              <span className="int-20-reg">Drag and Drop a document here</span>
              <span className="int-20-reg">or click the button below to add</span>
              <div className="d-flex justify-content-center mt-4">
                <PosButton
                  className="add-document-main-btn add-doc-btn btn"
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  fontWeight
                  onClick={() => {
                    const data = {
                      ...posFlowData,
                      addDocument: documentListItem,
                    }
                    dispatch(setPosFlowData(data));
                    setOpenAddDocumentModel(true);
                    setTaxOpen(false);
                  }}
                >
                  Add Document
                </PosButton>
              </div>
            </div>}
          </div>
        </div>
      </PosModel>
      {openAddDocumentModel ? <AddDocumentModal openModel={openAddDocumentModel} setOpenModel={setOpenAddDocumentModel} taxExemption={false} documentBy={documentBy} /> : null}

    </>
  );
}
