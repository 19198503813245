import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { PosResponseProps, RootState } from "models";
import { PosFlowDataProp } from "constants/interfaces";
import { getLocalStoraGeItem } from "utils/utils";
import { removeAdjustQuantitySchema } from "apps/pos/formik/validationSchema";

import { stockOutRequest } from "sagas/pos/actions";

interface Props {
  removeQtyOnLocationData?: number;
}
const useRemoveStockFormik = ({ removeQtyOnLocationData }: Props) => {
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { stockOutDetails } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );
  const dispatch = useDispatch();
  const { cartProductDetails, savedCart } = posFlowData || {};
  const { productId } = cartProductDetails || {};
  const { takerId } = savedCart || {};
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace || {};
  const windowsUrl = window.location.pathname;

  const initialValues = {
    removeAdjustStockRow: "",
    removeAdjustStockUnit: "",
    removeAdjustStockShelf: "",
    quantityToRemove: "",
    removeReason: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: removeAdjustQuantitySchema(removeQtyOnLocationData),
    onSubmit: (values) => {
      const {
        quantityToRemove,
        removeReason,
        removeAdjustStockUnit,
        removeAdjustStockShelf,
      } = values || {};
      const data = {
        productID: productId,
        qtyOfUnits: Number(quantityToRemove),
        reasonID: 6,
        handlerID: takerId,
        extraInfo: removeReason,
        orderID: 0,
        stockWarehouseID: stockWarehouseId,
        stockUnitID: Number(removeAdjustStockUnit),
        stockShelfID: Number(removeAdjustStockShelf),
        currentRequestUrl: windowsUrl,
      };
      if (!stockOutDetails?.length) {
        dispatch(stockOutRequest(data));
      }
    },
  });
  return formik;
};

export default useRemoveStockFormik;
