// Start import Quote 
export const IMPORT_QUOTE_START = "IMPORT_QUOTE_START";
export const IMPORT_QUOTE_SUCCESS = "IMPORT_QUOTE_SUCCESS";
export const IMPORT_QUOTE_ERROR = "IMPORT_QUOTE_ERROR";
export const IMPORT_QUOTE_CLEAR = "IMPORT_QUOTE_CLEAR";
// End import Quote 

// Start import Quote Search
export const IMPORT_QUOTE_SEARCH_START = "IMPORT_QUOTE_SEARCH_START";
export const IMPORT_QUOTE_SEARCH_SUCCESS = "IMPORT_QUOTE_SEARCH_SUCCESS";
export const IMPORT_QUOTE_SEARCH_ERROR = "IMPORT_QUOTE_SEARCH_ERROR";
export const IMPORT_QUOTE_SEARCH_CLEAR = "IMPORT_QUOTE_SEARCH_CLEAR";
// End import Quote Search

// Start import Quote item
export const IMPORT_QUOTE_ITEMS_START = "IMPORT_QUOTE_ITEMS_START";
export const IMPORT_QUOTE_ITEMS_SUCCESS = "IMPORT_QUOTE_ITEMS_SUCCESS";
export const IMPORT_QUOTE_ITEMS_ERROR = "IMPORT_QUOTE_ITEMS_ERROR";
export const IMPORT_QUOTE_ITEMS_CLEAR = "IMPORT_QUOTE_ITEMS_CLEAR";
export const ARR0W_INDEX_RESET_IMPORT_QUOTE = "ARR0W_INDEX_RESET_IMPORT_QUOTE";
export const ARR0W_DOWN_INDEX_IMPORT_QUOTE = "ARR0W_DOWN_INDEX_IMPORT_QUOTE";
export const ARR0W_UP_INDEX_IMPORT_QUOTE = "ARR0W_UP_INDEX_IMPORT_QUOTE"
// End import Quote item
