import { headCells , headLeadCells} from './data';

export const EnhancedTableHead = (props) => {
    const {
      classes,
      order,
      orderBy,
      lead = false
    } = props;
  
    return (
      <div className= {`${lead ? "table-body-row-history-lead" : "table-body-row-history"}`}>
        {lead ? 
        headLeadCells.map((headCell) => (
          <div
            className={`item-history-table-header ${headCell.class}`}
            key={headCell.id}>
            {headCell.label}
            {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </span>
            ) : null}
          </div>
        ))
        :
        headCells.map((headCell) => (
          <div
            className={`item-history-table-header ${headCell.class}`}
            key={headCell.id} >
            {headCell.label}
            {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </span>
            ) : null}
          </div>
        ))}
      </div>
    );
  }