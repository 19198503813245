import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Will remove 'any' once we get proper data from BE
export interface ProductClearanceSliceI {
  isCreateProductsUserClearance: boolean;
  createProductsUserClearanceData: any;
  createProductsUserClearanceError: any;
  isCreateProductsPriceClearance: boolean;
  createProductsPriceClearanceData: any;
  createProductsPriceClearanceError: any;
  isDeleteProductsClearance: boolean;
  deleteProductsClearanceData: any;
  deleteProductsClearanceError: any;
}

const initialState: ProductClearanceSliceI = {
    isCreateProductsUserClearance: false,
    createProductsUserClearanceData: null,
    createProductsUserClearanceError: null,
    isCreateProductsPriceClearance: false,
    createProductsPriceClearanceData: null,
    createProductsPriceClearanceError: null,
    isDeleteProductsClearance: false,
    deleteProductsClearanceData: null,
    deleteProductsClearanceError: null,
};

export const productsUserClearanceSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    createProductsUserClearanceStart: (state) => {
      state.createProductsUserClearanceData = null;
      state.isCreateProductsUserClearance = true;
    },
    createProductsUserClearanceSuccess: (state, action: PayloadAction<any>) => {
      state.createProductsUserClearanceData = action.payload;
      state.isCreateProductsUserClearance = false;
    },
    createProductsUserClearanceError: (state, action: PayloadAction<any>) => {
      state.createProductsUserClearanceError = action.payload;
      state.createProductsUserClearanceData = null;
      state.isCreateProductsUserClearance = false;
    },
    createProductsPriceClearanceStart: (state) => {
      state.createProductsPriceClearanceData = null;
      state.isCreateProductsPriceClearance = true;
    },
    createProductsPriceClearanceSuccess: (state, action: PayloadAction<any>) => {
      state.createProductsPriceClearanceData = action.payload;
      state.isCreateProductsPriceClearance = false;
    },
    createProductsPriceClearanceError: (state, action: PayloadAction<any>) => {
      state.createProductsPriceClearanceError = action.payload;
      state.createProductsPriceClearanceData = null;
      state.isCreateProductsPriceClearance = false;
    },
    deleteProductsClearanceStart: (state) => {
      state.deleteProductsClearanceData = null;
      state.isDeleteProductsClearance = true;
    },
    deleteProductsClearanceSuccess: (state, action: PayloadAction<any>) => {
      state.deleteProductsClearanceData = action.payload;
      state.isDeleteProductsClearance = false;
    },
    deleteProductsClearanceError: (state, action: PayloadAction<any>) => {
      state.deleteProductsClearanceError = action.payload;
      state.deleteProductsClearanceData = null;
      state.isDeleteProductsClearance = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  createProductsUserClearanceStart,
  createProductsUserClearanceSuccess,
  createProductsUserClearanceError,
  createProductsPriceClearanceStart,
  createProductsPriceClearanceSuccess,
  createProductsPriceClearanceError,
  deleteProductsClearanceStart,
  deleteProductsClearanceSuccess,
  deleteProductsClearanceError
} = productsUserClearanceSlice.actions;

export default productsUserClearanceSlice.reducer;
