import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import {
  getAllProductsError,
  getAllProductsSuccess,
  getProductCategoriesDataError,
  getProductCategoriesDataSuccess,
  getProductSubCategoriesDataError,
  getProductSubCategoriesDataSuccess,
  searchProductsError,
  searchProductsSuccess,
} from "apps/product/modules/productListingContainer/redux/product.slice";
import {
  getProductManufacturersError,
  getProductManufacturersSuccess,
} from "apps/product/modules/productListingContainer/redux/productManufacturers.slice";
import {
  getProductVendorsError,
  getProductVendorsSuccess,
} from "apps/product/modules/productListingContainer/redux/productVendors.slice";
import { getProductFiltersError, getProductFiltersSuccess } from "apps/product/modules/productListingContainer/redux/productFilters.slice";

import {
  searchProduct,
  getAllProducts,
  getProductCategories,
  getProductSubCategories,
  getProductVendors,
  getProductManufacturers,
  getProductFilters,
} from "services";

const useProductsHooks = () => {
  const dispatch = useDispatch();

  // Mutations to get products after search
  const getProductsMutation = useMutation({
    mutationFn: searchProduct,
    onSuccess: (data) => {
      dispatch(searchProductsSuccess(data));
    },
    onError: (error) => {
      dispatch(searchProductsError(error));
    },
  });

  // Mutations to get products after search
  const getAllProductsMutation = useMutation({
    mutationFn: getAllProducts,
    onSuccess: (data) => {
      dispatch(getAllProductsSuccess(data));
    },
    onError: (error) => {
      dispatch(getAllProductsError(error));
    },
  });

  // Mutations to get products categories
  const productCategoriesMutation = useMutation({
    mutationFn: getProductCategories,
    onSuccess: (data) => {
      dispatch(getProductCategoriesDataSuccess(data));
    },
    onError: (error) => {
      dispatch(getProductCategoriesDataError(error));
    },
  });

  // Mutations to get products subCategories
  const productSubCategoriesMutation = useMutation({
    mutationFn: getProductSubCategories,
    onSuccess: (data) => {
      dispatch(getProductSubCategoriesDataSuccess(data));
    },
    onError: (error) => {
      dispatch(getProductSubCategoriesDataError(error));
    },
  });

  // Mutations to get products Vendors
  const productVendorsMutation = useMutation({
    mutationFn: getProductVendors,
    onSuccess: (data) => {
      dispatch(getProductVendorsSuccess(data));
    },
    onError: (error) => {
      dispatch(getProductVendorsError(error));
    },
  });

  // Mutations to get products Vendors
  const productManufacturersMutation = useMutation({
    mutationFn: getProductManufacturers,
    onSuccess: (data) => {
      dispatch(getProductManufacturersSuccess(data));
    },
    onError: (error) => {
      dispatch(getProductManufacturersError(error));
    },
  });

    // Mutations to get products Vendors
    const productFiltersMutation = useMutation({
      mutationFn: getProductFilters,
      onSuccess: (data) => {
        dispatch(getProductFiltersSuccess(data));
      },
      onError: (error) => {
        dispatch(getProductFiltersError(error));
      },
    });

  return {
    getProductsMutation,
    getAllProductsMutation,
    productCategoriesMutation,
    productSubCategoriesMutation,
    productVendorsMutation,
    productManufacturersMutation,
    productFiltersMutation
  };
};

export default useProductsHooks;
