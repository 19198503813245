import serviceV2 from "services/config2";

const POS_API_PATH = "pos";
const DISTRIBUTOR_API_PATH = "distributors";

export const productsByPriceSchedule = (payload) => {
    return serviceV2.post(`${POS_API_PATH}/warehouses/${payload.stockWarehouseId}/products/by/ids`, payload).then((response) => {
        return response?.data
    })
}

export function getCashReport(payload: any) {
  return serviceV2
    .get(
      `${POS_API_PATH}/warehouses/${payload.stockWarehouseId}/transactions/summary/report?dt=${payload.dt}`)
    .then((response) => {
      return response.data;
    });
}

export function getCashOptions() {
  return serviceV2
    .get(
      `${POS_API_PATH}/drawers/cash`)
    .then((response) => {
      return response.data;
    });
}

export function getCashReportSummary(payload: any) {
  return serviceV2
    .get(
      `${POS_API_PATH}/warehouses/${payload.stockWarehouseId}/drawers/summary?dt=${payload.dt}&df=${payload.df}`)
    .then((response) => {
      return response.data;
    });
}

export function getDraftsByProductId(payload: any) {
  return serviceV2
    .get(
      `${POS_API_PATH}/warehouses/${payload.stockWarehouseId}/products/${payload.productId}/drafts`)
    .then((response) => {
      return response.data;
    });
}
export function getPayPalSettings() {
  return serviceV2
    .get(
      `${DISTRIBUTOR_API_PATH}/setting/paypal`)
    .then((response) => {
      return response.data;
    });
}
