import { createSelector } from "@reduxjs/toolkit";

export const customerOrder = (state: any) => state?.customerOrderSlice;

export const getPutInStorageItems = createSelector(
  customerOrder,
  (customerOrder) => customerOrder.putInStorageItems
);
export const getOrderId = createSelector(
  customerOrder,
  (customerOrder) => customerOrder.orderId
);
export const getShipmentId = createSelector(
  customerOrder,
  (customerOrder) => customerOrder.shipmentId
);
export const getIsApiLoading = createSelector(
  customerOrder,
  (customerOrder) => customerOrder.isApiLoading
);
