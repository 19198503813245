import { renamePersonData } from "utils/helper";

const SelectShippingAddressNames = ({
  shippingData,
  recipient,
  isLoading,
  showShipToLocation,
  dataIndex,
  streetName,
}) => {
  const { companyName, buildingName } = shippingData || {};
  const { street, city, stateName, zip } = recipient || {};
  const isLocationVisible = showShipToLocation?.isTrue && showShipToLocation.index === dataIndex;
  return (
    <div className="select-schedule-list selectShipping-address shipping-address-styling shipping-address-block">
      {isLocationVisible ? (
        !isLoading && street?.length > 1 ? (
          <>
            {`${streetName}, ${city || ""}, ${stateName || ""}, ${zip || ""}`}
            <div className="d-flex align-items-center">
              <span className="int-14-reg-gray">{renamePersonData(companyName)}</span>
            </div>
          </>
        ) : (
          <>Loading...</>
        )
      ) : (
          <>
            <div>{buildingName}</div>
            <div className="d-flex align-items-center">
              <span className="int-14-reg-gray">{renamePersonData(companyName)}</span>
            </div>
          </>
      )}
    </div>
  );
};

export default SelectShippingAddressNames;
