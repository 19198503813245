import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { setUpdateLocationSuccess } from "../redux/storage.slice";
import { updateStorageLocationUrl } from "./constantUrl";
import { stopLoading } from "utils/utils";

export const useUpdateStorageLocation = () => {
  const dispatch = useDispatch()
  const { mutate, isLoading, data, error, isSuccess: isStorageLocationSuccess } = useMutation(
    async ({
      shipmentID,
      storageLocation,
      shipmentInStorageID,
    }: {
      shipmentID: number;
      storageLocation: string;
      shipmentInStorageID: number;
    }) => {
      return updateStorageLocationUrl({
        shipmentID,
        storageLocation,
        shipmentInStorageID,
      });
    },
    {onSuccess: () => {
      dispatch(setUpdateLocationSuccess(true))
      stopLoading();
    },
      onError : stopLoading
    }
  );

  const updateStorageLocation = useCallback(
    ({
      shipmentID,
      storageLocation,
      shipmentInStorageID,
    }: {
      shipmentID: number;
      storageLocation: string;
      shipmentInStorageID: number;
    }) => {
      mutate({ shipmentID, storageLocation, shipmentInStorageID });
    },
    [mutate]
  );

  return {
    updateStorageLocation,
    isLoading,
    data,
    error,
    isStorageLocationSuccess,
  };
};
