import { useDispatch, useSelector } from "react-redux";

import OrderInfo from "./OrderInfo";

import { JobNameInput } from "apps/pos/Common/FacilityInput";
import { PosImage, PosPageHeading } from "apps/pos/Common/uiCore";

import { jobAndReceiptNameRegex, poNumberRegex } from "constants/constant";
import images from "constants/images";

import CashDrawerDropdown from "apps/pos/CashDrawerDropdown/CashDrawerDropdown";
import { PurchaseOrderInput } from "apps/pos/Common/PurchaseOrderInput";
import { setPosFlowData } from "sagas/persistReducer/actions";
import { getPosFlowData } from "sagas/persistReducer/selector";
import { isPurchaseOrderNoEdited, setJobNameForAll } from "sagas/pos/actions";

const PayLaterOption = ({
  tempJobNameSave,
  setTempJobNameSave,
  subTotal,
  taxAmount,
  shipTaxAmount,
  orderId,
  cashOptionsSelected,
  setCashOptionsSelected,
  setCashDrawerError,
  cashDrawerError,
  shippingTaxAmount,
  salesPersonName,
  finalTotal,
  shipToTaxFlag,
  shippingFee,
  creditAmtApplied,
  poNumberSave,
  setPoNumberSave,
}) => {
  const dispatch = useDispatch();
  const posFlowData = useSelector(getPosFlowData);


  const handleJobName = (e) => {
    if (jobAndReceiptNameRegex.test(e.target.value)) {
      setTempJobNameSave(e.target.value);
      dispatch(setJobNameForAll(e.target.value));
    }
  };

  const handlePoNumberChange = (e) => {
    if (e.target.value === "" || poNumberRegex.test(e.target.value)) {
      dispatch(isPurchaseOrderNoEdited(true))
      const data = {
        ...posFlowData,
        poNumber: e.target.value,
      };
      dispatch(setPosFlowData(data));
      setPoNumberSave(e.target.value);
    }
  };

  const { editPencil } = images;

  return (
    <div>
      <div className="pricing-modal-header mb-0">
        <div className="payment-option-heading">
          <div>
            <PosPageHeading heading="Payment" grayHeading />
            <div className="pricing-schedule-heading-checkout mb-4">
              <PosPageHeading
                heading={`$${finalTotal} to be paid later`}
                blackHeading
              />
            </div>
          </div>
        </div>
      </div>
      <div className="facility-box">
        <span className="int-20-reg-bold mb-4">
          <PosImage src={editPencil} alt="edit_icon" className="edit_icon" />{" "}
          Please take customer's signature on the invoice for
          <wbr /> your records.
        </span>
      </div>
      <OrderInfo
        subTotal={subTotal}
        taxAmount={taxAmount}
        shipTaxAmount={shipTaxAmount}
        shippingTaxAmount={shippingTaxAmount}
        orderId={orderId}
        salesPersonName={salesPersonName}
        shipToTaxFlag={shipToTaxFlag}
        shippingFee={shippingFee}
        creditAmtApplied={creditAmtApplied}
      />
      <div className="paylater-margin-top mt-4">
        <div className="form-field-space-custom-drop-down-div">
          <CashDrawerDropdown
            cashOptionsSelected={cashOptionsSelected}
            setCashOptionsSelected={setCashOptionsSelected}
            setCashDrawerError={setCashDrawerError}
            cashDrawerError={cashDrawerError}
          />
        </div>
        <div className="margintop-10 pay-later-job-input input-btn-spacing-div">
          <PurchaseOrderInput
            value={poNumberSave}
            onChange={handlePoNumberChange}
          />
          <JobNameInput value={tempJobNameSave} onChange={handleJobName} />
        </div>
      </div>
    </div>
  );
};

export default PayLaterOption;
