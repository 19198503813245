import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Button, Input, InputOptional, Select } from "../../../components";

import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { hrRepresentativeRequest } from "../../../sagas/setting/actions";
import { RootState } from "../../../models";
import { hrRepresentativeInfo } from "../../../models/setting";
import MaskInput from "apps/pos/Common/maskInput/maskInput";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function HrRepresentative({distributorId}) {

  const [hrRepresentativeData, setHrRepresentativeData] = useState<hrRepresentativeInfo>();

  const { hrRepresentative } = useSelector<RootState, any>((state) => state.setting);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hrRepresentativeRequest(distributorId));
  }, []);

  useEffect(() => {
    setHrRepresentativeData(hrRepresentative);
  }, [hrRepresentative]);

  const HRSchema = Yup.object().shape({
    hrName: Yup.string()
      .required("HR name required.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    hrEmail: Yup.string()
      .email("Please Enter valid email")
      .required("HR email required."),
    hrPhone: Yup.string().required("Please Enter phone number"),
  });  

  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">HR Representative</div>
        <div className="devider2px" />

        <Formik
          initialValues={{
            hrName: hrRepresentativeData?.name,
            hrEmail: hrRepresentativeData?.email,
            hrPhone: hrRepresentativeData?.phone?.fullPhoneNum,
          }}
          enableReinitialize
          validationSchema={HRSchema}
          onSubmit={() => {
            // Required Later
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => {
            return (
              <form className="formContainer" id="addHrRepresentativeForm">
                <Input
                  id={"hrName"}
                  name={"hrName"}
                  label={"Name"}
                  type={"text"}
                  value={values.hrName}
                  onChange={handleChange}
                  placeholder={""}
                />
                {errors.hrName && touched.hrName ? (
                  <p className="validation-error-text">{errors.hrName}</p>
                ) : null}

                <Input
                  id={"hrEmail"}
                  name={"hrEmail"}
                  value={values.hrEmail}
                  onChange={handleChange}
                  label={"Email"}
                  type={"email"}
                  placeholder={""}
                />

                {errors.hrName && touched.hrName ? (
                  <p className="validation-error-text">{errors.hrEmail}</p>
                ) : null}

                <div className="d-flex">
                  <Select
                    id={"phone"}
                    name={"phone"}
                    label={"Phone"}
                    textInputStyle="phoneinput"
                    options={[
                      { label: "+1", value: "+1" },
                      { label: "+91", value: "+91" },
                    ]}
                  />
                  <div className="phone-number-container setting-phone-number-info">
                  <MaskInput                    
                      mask="(999) 999-9999"
                      isClassName="masked-phone-input"                      
                      id="hrPhone"
                      name="hrPhone"
                      alwaysShowMask={true}
                      onChange={handleChange}
                      value={values.hrPhone}
                    />
                 </div>
                  <div className="ext-container">
                    <InputOptional
                      textInputStyle="extinput"
                      id={"addExt"}
                      name={"addExt"}
                      label={"Ext"}
                      type={"text"}
                      placeholder={""}
                    />
                  </div>
                </div>
                {errors.hrName && touched.hrName ? (
                  <p className="validation-error-text">{errors.hrPhone}</p>
                ) : null}

                <div className="devider1px" />

                <Button
                  type="submit"
                  onClick={handleSubmit}
                  className="manage-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#jumper_modal"
                >
                  <span className="manage-button-txt">save changes</span>
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </PerfectScrollbar>
  );
}
