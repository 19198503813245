import { Tab, Tabs } from "@material-ui/core";
import { PosButton, PosImage, PosTabPanel } from "apps/pos/Common/uiCore";
import { FormikProps } from "formik";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import Add from "./add";
import Move from "./move";
import Remove from "./remove";

import texts from "apps/pos/locales";
import { ClearIcon } from "constants/icons";
import images from "constants/images";
import { PosResponseProps, RootState } from "models";
import { getLocalStoraGeItem, saleable } from "utils/utils";
import { useAllRowsData } from "./queryHooks/useAllRowsData";
import { useSingleRowsData } from "./queryHooks/useSingleRowData";
import { AdjustStockTab } from "./type";

import {
  enterDisableAddToCart,
  getWarehourseLocationsRequest,
  getWarehouseLocationsClear,
  setAdjustStockByProduct,
  setIsLoading,
  stockByWarehouseClear,
  stockByWarehouseStart,
} from "sagas/pos/actions";

import "./adjustStocks.scss";

interface Props {
  handlePrePopulateLocationData?: () => void;
  setModalVisible?: (e: boolean) => void;
  handleFocusInput?: () => void;
  units?: string[];
  setUnits?: Dispatch<SetStateAction<any[]>>;
  unitsErrors?: string[];
  setUnitsErrors?: Dispatch<SetStateAction<any[]>>;
  unitsLoading?: boolean;
  setUnitsLoading?: Dispatch<SetStateAction<boolean>>;
  shelves?: string[];
  setShelves?: Dispatch<SetStateAction<any[]>>;
  shelvesErrors?: string[];
  setShelvesErrors?: Dispatch<SetStateAction<any[]>>;
  shelvesLoading?: boolean;
  setShelvesLoading?: Dispatch<SetStateAction<boolean>>;
}
const AdjustStock = ({
  handlePrePopulateLocationData,
  setModalVisible,
  units,
  setUnits,
  unitsErrors,
  setUnitsErrors,
  unitsLoading,
  setUnitsLoading,
  shelves,
  setShelves,
  shelvesErrors,
  setShelvesErrors,
  shelvesLoading,
  handleFocusInput,
  setShelvesLoading,
}: Props) => {
  const {
    productsData,
    allRowsData,
    getRowsActiveDetails,
    getUnitActiveDetails,
    getShelfDetails,
    getWarehouseLocations,
    warehouseLocationLoader,
    isStockUpdateProduct,
    isMoveTabDisable,
    isStockInLoading,
  } = useSelector<RootState, PosResponseProps>((state) => state.pos);

  const { individualRowRefetch } = useSingleRowsData();

  useEffect(() => {
    individualRowRefetch();
  }, [individualRowRefetch]);

  const dispatch = useDispatch();
  const { } = useAllRowsData();
  //might need
  const { productId: productIdInfo } = productsData;
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const [tabValue, setTabValue] = useState(0);
  const [quantityAdd, setQuantityAdd] = useState(0);
  const [removeQuantity, setRemoveQuantity] = useState<number>(0);
  type FormModel = any;
  const addFormRef = useRef<FormikProps<FormModel>>(null);
  const removeFormRef = useRef<FormikProps<FormModel>>(null);
  const moveFormRef = useRef<FormikProps<FormModel>>(null);

  const saleableRows = useMemo(
    () => (Array.isArray(allRowsData) ? allRowsData.filter(saleable) : []),
    [allRowsData]
  );

  useEffect(() => {
    dispatch(setIsLoading(true));
    if (saleableRows?.length > 1) {
      dispatch(setIsLoading(false));
    } else if (saleableRows?.length === 1) {
      handlePrePopulateLocationData();
    }
  }, [allRowsData]);

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  // start
  const [rowName, setRowName] = useState("");
  const [unitName, setUnitName] = useState("");
  const [shelfName, setShelfName] = useState("");
  const [removeRowName, setRemoveRowName] = useState("");
  const [removeUnitName, setRemoveUnitName] = useState("");
  const [removeShelfName, setRemoveShelfName] = useState("");
  const [fromRowName, setFromRowName] = useState("");
  const [fromUnitName, setFromUnitName] = useState("");
  const [fromShelfName, setFromShelfName] = useState("");
  const [toRowName, setToRowName] = useState("");
  const [toUnitName, setToUnitName] = useState("");
  const [toShelfName, setToShelfName] = useState("");
  const [addLocation, setAddLocation] = useState("");
  const [removeLocation, setRemoveLocation] = useState("");
  const [moveFromLocation, setMoveFromLocation] = useState("");
  const [moveToLocation, setMoveToLocation] = useState("");
  const [removeQuantityValue, setRemoveQuantityValue] = useState("");
  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    if (newValue === tabValue) return;
    // Might need this code
    // if(isTabDisable) return
    dispatch(getWarehouseLocationsClear());
    setUnits([]);
    setShelves([]);
    // dispatch(disableAdjustStockTabs(true))
    setTabValue(newValue);
    setRowName("");
    setUnitName("");
    setShelfName("");

    setRemoveRowName("");
    setRemoveUnitName("");
    setRemoveShelfName("");

    setFromRowName("");
    setFromUnitName("");
    setFromShelfName("");
    setToRowName("");
    setToUnitName("");
    setToShelfName("");
  };

  // Might need later
  useEffect(() => {
    if (shelfName || removeShelfName || fromShelfName || toShelfName) {
      const productData = {
        stockWarehouseId: currentWorkspace.stockWarehouseId,
        body: [productIdInfo],
      };
      dispatch(getWarehourseLocationsRequest(productData));
    }
  }, [shelfName, removeShelfName, fromShelfName, toShelfName]);

  // start add location
  const selectedLocation = `${rowName ?? ""}${unitName ? `-${unitName}` : ""}${shelfName ? `-${shelfName}` : ""
    }`;

  setTimeout(() => {
    setAddLocation(selectedLocation);
  }, 200);
  const foundLocation = getWarehouseLocations.map((obj) => {
    return obj.locations.find((item) => item.location === addLocation);
  });
  const locationAvailableQuantity = foundLocation.map(
    (obj) => obj?.unitQtyAvailable
  );
  const qtyOnLocationData = locationAvailableQuantity[0] || 0;
  // end add location

  // start remove location
  const selectedRemoveLocation = `${removeRowName ?? ""}${removeUnitName ? `-${removeUnitName}` : ""
    }${removeShelfName ? `-${removeShelfName}` : ""}`;

  setTimeout(() => {
    setRemoveLocation(selectedRemoveLocation);
  }, 200);

  const foundRemoveLocation = getWarehouseLocations.map((obj) => {
    return obj.locations.find((item) => item.location === removeLocation);
  });

  const removeLocationAvailableQuantity = foundRemoveLocation.map(
    (obj) => obj?.unitQtyAvailable
  );
  const removeQtyOnLocationData = removeLocationAvailableQuantity[0] || 0;

  // start from-to location
  // start from location
  const selectedFromLocation = `${fromRowName ?? ""}${fromUnitName ? `-${fromUnitName}` : ""
    }${fromShelfName ? `-${fromShelfName}` : ""}`;

  setTimeout(() => {
    setMoveFromLocation(selectedFromLocation);
  }, 200);

  const foundFromLocation = getWarehouseLocations.map((obj) => {
    return obj.locations.find((item) => item.location === moveFromLocation);
  });

  const fromLocationAvailableQuantity = foundFromLocation.map(
    (obj) => obj?.unitQtyAvailable
  );
  const fromQtyOnLocationData = fromLocationAvailableQuantity[0];
  // end from location

  // start to location
  const selectedToLocation = `${toRowName}${toUnitName && "-" + toUnitName}${toShelfName && "-" + toShelfName
    }`;
  setTimeout(() => {
    setMoveToLocation(selectedToLocation);
  }, 200);
  const foundToLocation = getWarehouseLocations.map((obj) => {
    return obj.locations.find((item) => item.location === moveToLocation);
  });

  const toLocationAvailableQuantity = foundToLocation.map(
    (obj) => obj?.unitQtyAvailable
  );
  const toQtyOnLocationData = toLocationAvailableQuantity[0];
  // end to location
  // end from-to location
  const adjustStockValueCompare = () => {
    const formatter = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 3,
    });
    if (tabValue === AdjustStockTab.Add) {
      const plusQuantity = qtyOnLocationData + quantityAdd;
      return parseFloat(formatter.format(plusQuantity).replace(/,/g, "")) || 0;
    } else if (tabValue === AdjustStockTab.Remove) {
      const minusQuantity = removeQtyOnLocationData - (removeQuantity || 0);
      return parseFloat(formatter.format(minusQuantity).replace(/,/g, "")) || 0;
    }
    {
      return qtyOnLocationData || 0;
    }
  };

  const { availableQtyIn } = texts;

  const handleCancel = () => {
    if (!isStockInLoading) {
      handleFocusInput();
      if (isStockUpdateProduct) {
        dispatch(setAdjustStockByProduct());
        dispatch(stockByWarehouseClear());
        dispatch(stockByWarehouseStart([productsData.productId]));
      }
      setModalVisible(false);
      dispatch(enterDisableAddToCart(false));
      dispatch(getWarehouseLocationsClear());
    }
  };

  function number_test(n) {
    return n - Math.floor(n) !== 0;
  }

  const decimalQtyOnLocationData = number_test(qtyOnLocationData)
    ? qtyOnLocationData?.toFixed(3)
    : qtyOnLocationData;
  const decimalRemoveQtyOnLocationData = number_test(removeQtyOnLocationData)
    ? removeQtyOnLocationData?.toFixed(3)
    : removeQtyOnLocationData;
  const decimalFromQtyOnLocationData = number_test(fromQtyOnLocationData)
    ? fromQtyOnLocationData?.toFixed(3)
    : fromQtyOnLocationData;
  const decimalToQtyOnLocationData = number_test(toQtyOnLocationData)
    ? toQtyOnLocationData?.toFixed(3)
    : toQtyOnLocationData;

  const message = ({
    value,
    firstValue,
    SecondValue,
    thirdValue,
  }: {
    value: string;
    firstValue: string;
    SecondValue: string;
    thirdValue: string;
  }) => {
    if (!firstValue || !SecondValue || !thirdValue) {
      return `Checking inventory in ${value}`;
    } else {
      return getWarehouseLocations.length > 0
        ? `${availableQtyIn} ${value}`
        : ` Checking inventory in ${value}`;
    }
  };

  return (
    <div className="adjust-stock-container">
      <div className="d-flex flex-wrap flex-row-reverse">
        <div className="adjust-stock-right-col">
          <div className="adjust-stock-title">Adjust Stock</div>
          <div className="adjust-toggle-button">
            <Tabs
              value={tabValue}
              onChange={handleChange}
              className={`adjust-stock-tab-container`}
              selectionFollowsFocus
            >
              <Tab
                disableRipple
                label="Add"
                {...a11yProps(0)}
                className={`${isMoveTabDisable ? "hide-move-tab" : "do-not-hide-move-tab "
                  }`}
              />
              <Tab
                disableRipple
                label="Remove"
                {...a11yProps(1)}
                className={`${isMoveTabDisable ? "hide-move-tab" : "do-not-hide-move-tab "
                  }`}
              />
              <Tab
                hidden={isMoveTabDisable}
                disableRipple
                label="Move"
                {...a11yProps(2)}
                className="do-not-hide-move-tab "
              />
            </Tabs>
          </div>
          <PosTabPanel value={tabValue} index={AdjustStockTab.Add}>
            <Add
              rowName={rowName}
              setRowName={setRowName}
              setUnitName={setUnitName}
              setShelfName={setShelfName}
              rowsActiveData={getRowsActiveDetails}
              unitData={getUnitActiveDetails}
              shelfData={getShelfDetails}
              setModalVisible={setModalVisible}
              setQuantityAdd={setQuantityAdd}
              qtyOnLocationData={qtyOnLocationData}
              tabValue={tabValue}
              addFormRef={addFormRef}
              units={units}
              setUnits={setUnits}
              unitsErrors={unitsErrors}
              setUnitsErrors={setUnitsErrors}
              unitsLoading={unitsLoading}
              setUnitsLoading={setUnitsLoading}
              shelves={shelves}
              setShelves={setShelves}
              shelvesErrors={shelvesErrors}
              setShelvesErrors={setShelvesErrors}
              shelvesLoading={shelvesLoading}
              setShelvesLoading={setShelvesLoading}
            />
          </PosTabPanel>
          <PosTabPanel value={tabValue} index={AdjustStockTab.Remove}>
            <Remove
              removeRowName={removeRowName}
              setModalVisible={setModalVisible}
              setRemoveQuantity={setRemoveQuantity}
              tabValue={tabValue}
              setRemoveRowName={setRemoveRowName}
              setRemoveUnitName={setRemoveUnitName}
              setRemoveShelfName={setRemoveShelfName}
              removeQtyOnLocationData={removeQtyOnLocationData}
              removeFormRef={removeFormRef}
              setRemoveQuantityValue={setRemoveQuantityValue}
              units={units}
              setUnits={setUnits}
              unitsErrors={unitsErrors}
              setUnitsErrors={setUnitsErrors}
              unitsLoading={unitsLoading}
              setUnitsLoading={setUnitsLoading}
              shelves={shelves}
              setShelves={setShelves}
              shelvesErrors={shelvesErrors}
              setShelvesErrors={setShelvesErrors}
              shelvesLoading={shelvesLoading}
              setShelvesLoading={setShelvesLoading}
            />
          </PosTabPanel>
          <PosTabPanel value={tabValue} index={AdjustStockTab.Move}>
            <Move
              fromRowName={fromRowName}
              rowsActiveData={getRowsActiveDetails}
              unitData={getUnitActiveDetails}
              shelfData={getShelfDetails}
              setModalVisible={setModalVisible}
              tabValue={tabValue}
              setFromRowName={setFromRowName}
              setFromUnitName={setFromUnitName}
              setFromShelfName={setFromShelfName}
              setToRowName={setToRowName}
              setToUnitName={setToUnitName}
              setToShelfName={setToShelfName}
              fromQtyOnLocationData={fromQtyOnLocationData}
              moveFormRef={moveFormRef}
              units={units}
              setUnits={setUnits}
              unitsErrors={unitsErrors}
              setUnitsErrors={setUnitsErrors}
              unitsLoading={unitsLoading}
              setUnitsLoading={setUnitsLoading}
              shelves={shelves}
              setShelves={setShelves}
              shelvesErrors={shelvesErrors}
              setShelvesErrors={setShelvesErrors}
              shelvesLoading={shelvesLoading}
              setShelvesLoading={setShelvesLoading}
            />
          </PosTabPanel>
        </div>
        <div className="adjust-stock-left-col">
          <div className="adjuct-stock-product-img">
            <PosImage
              src={productsData?.thumbImg}
              alt="item-img"
              className="item-img"
            />
          </div>
          <div className="adjuct-stock-manufacturer-name">
            <span>{productsData?.manufacturerName}</span>
          </div>
          <div className="adjuct-stock-product-name">
            <span>{productsData?.productName}</span>
          </div>
          <div className="adjuct-stock-manufacturer-code">
            {productsData?.manufacturerCode}
          </div>
          <div className="adjust-stock-footer">
            {tabValue === AdjustStockTab.Add && selectedLocation ? (
              <>
                <div className="stock-available-qty">
                  <div className="stock-available-qty-title">
                    <span>
                      {message({
                        value: selectedLocation,
                        firstValue: rowName,
                        SecondValue: unitName,
                        thirdValue: shelfName,
                      })}
                    </span>
                  </div>
                  <div className="stock-available-qty-data font-weight-600">
                    <span>
                      {
                        (warehouseLocationLoader || !rowName || !unitName || !shelfName) ?
                          <LazyLoadImage
                            alt="spinner"
                            height={20}
                            src={images.spinner}
                            width={20}
                          />
                          : decimalQtyOnLocationData
                      }
                    </span>
                  </div>
                </div>
                {quantityAdd && Boolean(decimalQtyOnLocationData) ? (
                  <div className="stock-adjust-qty">
                    <div className="stock-adjust-qty-title">
                      <span>Adjusted qty.</span>
                    </div>
                    <div className="stock-adjust-qty-data">
                      <span className="font-weight-600">
                        {adjustStockValueCompare()}
                      </span>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}

            {tabValue === AdjustStockTab.Remove && selectedRemoveLocation ? (
              <>
                <div className="stock-available-qty">
                  <div className="stock-available-qty-title">
                    <span>
                      {message({
                        value: selectedRemoveLocation,
                        firstValue: removeRowName,
                        SecondValue: removeUnitName,
                        thirdValue: removeShelfName,
                      })}
                    </span>
                  </div>
                  <div className="stock-available-qty-data font-weight-600">
                    <span>
                      {(warehouseLocationLoader || !removeRowName || !removeUnitName || !removeShelfName) ? <LazyLoadImage
                        alt="spinner"
                        height={20}
                        src={images.spinner}
                        width={20}
                      /> : decimalRemoveQtyOnLocationData}

                    </span>
                  </div>
                </div>
                {removeQuantityValue &&
                  Boolean(decimalRemoveQtyOnLocationData) ? (
                  <div className="stock-adjust-qty">
                    <div className="stock-adjust-qty-title">
                      <span>Adjusted qty.</span>
                    </div>
                    <div className="stock-adjust-qty-data">
                      <span className="font-weight-600">
                        {adjustStockValueCompare()}
                      </span>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}

            {tabValue === AdjustStockTab.Move && selectedFromLocation ? (
              <>
                <div className="stock-available-qty">
                  <div className="stock-available-qty-title">
                    <span>
                      {message({
                        value: selectedFromLocation,
                        firstValue: fromRowName,
                        SecondValue: fromUnitName,
                        thirdValue: fromShelfName,
                      })}
                    </span>
                  </div>
                  <div className="stock-available-qty-data font-weight-600">
                    <span>
                      {fromShelfName ? (
                        decimalFromQtyOnLocationData
                      ) : (
                        <LazyLoadImage
                          alt="spinner"
                          height={20}
                          src={images.spinner}
                          width={20}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </>
            ) : null}
            {tabValue === AdjustStockTab.Move && toRowName ? (
              <>
                <div className="stock-adjust-qty">
                  <div className="stock-available-qty-title">
                    <span>
                      {message({
                        value: selectedToLocation,
                        firstValue: toRowName,
                        SecondValue: toUnitName,
                        thirdValue: toShelfName,
                      })}
                    </span>
                  </div>
                  <div className="stock-available-qty-data font-weight-600">
                    <span>
                      {toShelfName ? (
                        decimalToQtyOnLocationData
                      ) : (
                        <LazyLoadImage
                          alt="spinner"
                          height={20}
                          src={images.spinner}
                          width={20}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="adjust-stock-cancel-btn">
            <PosButton
              className="custom-size-7 btn"
              onClick={handleCancel}
              startIcon={<ClearIcon />}
              variant="contained"
            >
              Cancel
            </PosButton>
          </div>
        </div>
      </div>
    </div>
  );
};
// };
export default AdjustStock;
