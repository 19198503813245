import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    "&.MuiTypography-root": {
    },
    "&.MuiTypography-caption": {
      fontSize:"1rem",
      letterSpacing:"0px",
      color:"#999999;",
      opacity:"1"
    },
    "&.MuiTypography-subtitle1": {
      fontSize:"1.125rem",
      letterSpacing:"0px",
      color:"#000000;",
      fontWeight:"bold",
      opacity:"1"
    },
    "&.MuiTypography-subtitle2": {
      fontSize:"0.875rem",
      letterSpacing:"0px",
      color:"#000000;",
      fontWeight:"normal",
      opacity:"1"
    }
  }
});

export default useStyles;
