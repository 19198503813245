import RepMetadata from './RepMetadata';
import Prices from './Prices';
import ItemListHeader from './ItemListHeader';

import Actions from './Actions';

import './ItemGroupHeader.scss';

const ItemGroupHeader = ({rep, jobQuoteID, quoteRepId, distributor, buyPriceReceivedFromRepFlag}) => {
    const { note, title, data } = rep;
    const custRepPriceData = data?.[0]?.custRepPrice;
    const repCostData = data?.[0]?.repCost;
    const itemMarginPercentData = data?.[0]?.itemMarginPercent;
    return (
        <>
            <header className='item-group-header' id={title}>
                <RepMetadata name={title} buyPriceReceivedFromRepFlag={buyPriceReceivedFromRepFlag} note={note} />
                <Prices custRepPrice={custRepPriceData} repCost={repCostData} itemMarginPercent={itemMarginPercentData} />
                <Actions quoteRepId={quoteRepId} jobQuoteID={jobQuoteID} distributor={distributor}/>
            </header>
            <ItemListHeader />
        </>
    )
}

export default ItemGroupHeader;