import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";

import Button from "apps/admin/components/Elements/Button";

import { adminMessage } from "constants/message";
import { Label } from "enums/label";
import { RejectUserInvitationProps } from "models";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import { UpdateModalContent } from "sagas/modal/actions";
import {
    getAwaitingApprovalUsers
} from "sagas/user/actions";
import {
    rejectUser
} from "services";


export function RejectUserInvitation({ user, onSuccess, onError, currentPage }: RejectUserInvitationProps) {
    const dispatch = useDispatch();
    const [isBtnDisable, setIsBtnDisable] = useState(false)
    const { inviteReject, failInviteReject } = adminMessage
    const onSubmit = () => {
        setIsBtnDisable(true)
        rejectUser(user?.personId).then((data) => {
            setIsBtnDisable(false)
            if (data.data) {
                dispatch(snakbarShow({message : inviteReject}))
                onSuccess(inviteReject);
                dispatch(getAwaitingApprovalUsers(currentPage));
            } else {
                dispatch(snakbarShow({message : failInviteReject}))
                onError(failInviteReject)
            }
        }).catch(() => {
            setIsBtnDisable(false)
            dispatch(snakbarShow({message : failInviteReject}))
        });
    };
    const { CancelButton , Reject } = Label;

    const cancelClick = () => {
        dispatch(
            UpdateModalContent({
                isOpen: false,
                children: true,
                fixedModal: true,
                removePaddingBottom: false,
                noMaxWidth: false
            })
        );
    }
    return (
        <Fragment>
            <span className="pl-4 fs-24 roboto-med black_text">
                Reject Invite
            </span>

            <div className="mt-2 mb-5">
                <span className="fs-16 inter-reg black_text pr-40">{`Are you sure you want to reject ${user.fullName}'s invite?`}</span>
            </div>
            <div className="edit_cust_btn">
                <div className="cacel_btn">
                    <Button
                        label={CancelButton}
                        onClick={cancelClick}
                        secondary={true}
                    />
                </div>
                <div className="">
                    <Button
                        type="submit"
                        label={Reject}
                        disabled={isBtnDisable}
                        accent={true}
                        classes={`${isBtnDisable ? 'button-is-loading' : ""}`}
                        onClick={onSubmit}
                    />
                </div>
            </div>
        </Fragment>
    );
}
