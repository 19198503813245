import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    modal: {
      maxWidth: "45%",
      marginTop: "15%",
      marginLeft: "37%",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[6],
      padding: '1.875rem',
      borderRadius: "1.25rem",
      position: "absolute",
      width: "34.375rem",
    },
    closeIcon: {
      fontSize: 22,
      cursor: "pointer",
      float: "right",
    },
    headerWidth: {
      width: "100%",
    },
    header: {
      fontWeight: "bold",
      color: "black",
      fontSize: "1.25rem",
    },
    innerdata: {
      fontSize: "1rem",
      color: "black",
      paddingTop: "0.938rem",
    },
    buttondiv: {
      display: "flex",
      justifyContent: "end",
      paddingTop: "1.875rem",
      gap: "0.625rem",
    },
    buttonfirst: {
      backgroundColor: "white",
      color: "black",
      fontSize: "1rem",
      borderRadius: "15%",
      padding: "0.313rem",
      width: "auto",
      fontWeight: 700,
      "& span": {
        fontWeight: '600 !important',
      }
    },
    buttonseconds: {
      backgroundColor: "#FFE5E5 !important",
      color: "black !important",
      fontWeight: 700,
      fontSize: "1rem",
      borderRadius: "15%",
      padding: "0.313rem",
      width: "auto",
      "& span": {
        fontWeight: '600 !important',
      }
    },
  }));

  export default useStyles;