import axios from "axios";
import Cookies from "universal-cookie";

import texts from "apps/pos/locales"
import { authorizationToken } from "utils/utils";

const {tenantCurrent} = texts;

const cookies = new Cookies();
const decodedData = authorizationToken("Authorization");

export function getNavigationMenu() {
  const tenantId = decodedData?.TenantId;
  return axios({
    url: `/api/v2/nav`,
    method: "GET",
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      "X-TENANT-ID": tenantId,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response?.data;
  });
}

export const getTenantDetail = () => {
  const tenantId = decodedData?.TenantId;
  return axios({
    url: `/api/v1/tenants/${tenantId}`,
    method: "GET",
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      "X-TENANT-ID": tenantCurrent,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response?.data;
    })
    .catch(() => {
      // Required Code
    });
};

export const getTest = async (jobLotQuoteID, managerID) => {
  const tenantId = decodedData?.TenantId;
  const token = await cookies.get("Authorization");
  return axios({
    url: `/api/v1/msJobLotQuote_SetAsWon?jobLotQuoteID=${jobLotQuoteID}&managerID=${managerID}`,
    method: "GET",
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      "X-TENANT-ID": tenantId,
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response?.data;
    })
    .catch(() => {
      // Required Code
    });
};
