import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import ProductDetailsCommonModal from "../ProductDetailsCommonModal";
import LogsGroup from "./LogsGroup";

import { statusValue } from "apps/product/helper/commonConstants";
import { RootState } from "models";

import { SingleProductSliceI } from "apps/product/modules/productDetailsContainer/types";

import '../ProductDetailsLogsItemModels.scss'

const ProductDetailsLogsModals = (props) => {
    const { getLogsProductData } = useSelector<
        RootState,
        SingleProductSliceI
    >((state) => state.singleProductSlice);

    const { handleOpenCloseLogsModal, open,
        doesHaveMore , handleScroll , logList
    } = props
    const { zero } = statusValue;
    
    return (
        <>
            <ProductDetailsCommonModal
                open={open}
                onClose={handleOpenCloseLogsModal}
                modalMainTitle="Logs"
                mainBodyClassName="logs-detail">
                <InfiniteScroll
                    dataLength={logList?.length || zero}
                    next={handleScroll}
                    height={900}
                    hasMore={doesHaveMore}
                    loader={<></>}
                    scrollThreshold={0.9}
                >
                    <div className="group-wrapper">
                        <div className="group-content">
                            <LogsGroup logs={logList} handleOpenCloseLogsModal={handleOpenCloseLogsModal} total={getLogsProductData?.total} />
                        </div>
                    </div>
                </InfiniteScroll>
            </ProductDetailsCommonModal >
        </>
    );
}

export default ProductDetailsLogsModals;