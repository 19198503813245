import "@fontsource/inter";
import { Box } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MaterialIcon from "./Common/uiCore/MaterialIcon";

import { Container, Divider, PosButton, PosIconButton, PosImage, PosPageHeading } from "apps/pos/Common/uiCore";
import UnitsAvailable from "apps/pos/UnitsAvailable/UnitsAvailable";
import { Button } from "components";
import ConfirmationPrice from "./ConfirmPrice";

import texts from "apps/pos/locales";
import { decimal18RegEx, fiveDecimalRegex, profRegex, qtyRegEx } from "constants/constant";
import { ProductPriceType, ProductTypes, ProductVpgTypes } from "constants/enum";
import { ClearIcon } from "constants/icons";
import images from "constants/images";
import { addCustomerLabel } from "constants/posLabel";
import { Paths } from "enums";
import { RootState } from "models";
import { calculatePriceItemMuEach, calculatePriceItemMuEachNew, getIncrement, getLocalStoraGeItem, getMarkup, getProfit, getSalesPrice, roundToTwoDecimalPlaces, showScrollCartPage } from "utils/utils";

import {
  examptShipTolocationData,
  getChangePriceInfo, isGpmCustomFlag, isOrderTotalCustomFlag, saveProduct, setNoLoadDetailsAction, setPosFlowData, setShipToAddress, setToPickUp, shippingAddressSelectedData, showOriginalPriceInfo
} from "sagas/persistReducer/actions";
import {
  addToCartDrawer,
  arrowReset,
  clearSearchProduct, enterDisableAddToCart, getNewPrice, getupdateItemModal, lastProductQty, lastViewProductIndex, productsDataClear, setChangeCartPrice,
  setInitialCustomPrice,
  stockByWarehouseStart
} from "sagas/pos/actions";
import { getLastProductQty } from "sagas/pos/selector";
import { keys } from "utils/helper";
import CartPriceVolumeBox from "./CartPriceVolumeBox/CartPriceVolumeBox";
import TruncatedText from "./Common/TruncatedText/TruncatedText";

const CartPage = ({
  updatedProductsFlag,
  setUpdatedProductsFlag,
  setSearchProductsData,
  setCartEmpty,
  setState,
  state,
  onCloseDrawer,
  handleRemoveClick,
  setPickUpSelected,
  setShipToByFacilityId,
  handleCartDrawerCallback,
  setNotesOpen,
  setNotesPlace,
  allocatedDraftRowRef,
  setFilteredSearchProduct
}) => {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const lastUpdatedQty = useSelector(getLastProductQty)
  const {
    getCancelPrice,
    isWareHouseAvailable,
    productsData,
    productUpdateFlag,
    updatedPrice,
    productIndexInfo,
    updatedQty,
    isEnterDisabled,
    isWareHouseDrawer,
    stockWarehouseData,
    getProductIndex,
  } = useSelector<RootState, any>((state) => state.pos);
  const {
    posFlowData,
    changePriceInfo,
    loadCartDetails,
    isGPMCustom,
    isOrderTotalCustom
  } = useSelector<RootState, any>((state) => state.persistReducer);

  const { priceVisible } = posFlowData || {};

  const currentWorkspace = getLocalStoraGeItem("workspace");
  const [productStateIndex, setProductStateIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [changePrice, setChangePrice] = useState(false);
  const [priceChanged, setPriceChanged] = useState(false);
  const [isCustomPrice, setIsCustomPrice] = useState(false);
  const [priceInDecimal, setPriceInDecimal] = useState(false);
  const [showPriceType, setShowPriceType] = useState("");
  const [checkProf, setProf] = useState(priceVisible);
  const [tempPrice, setTempNewPrice] = useState(0)
  const [newPrice, setNewPrice] = useState(updatedPrice);
  const [newQty, setNewQty] = useState(0);
  const [breakQty, setBreakQty] = useState(1);
  const [qty, setQty] = useState(1);
  const [productOrderNote, setProductOrderNote] = useState("");
  const [productShippingNote, setProductShippingNote] = useState("");
  const [newPercentage, setNewPercentage] = useState<string | number>();
  const [modifiedPrice, setModifiedPrice] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [showSetPrice, isShowSetPrice] = useState(updatedProductsFlag);
  const [marginRateMarkup, setMarginRateMarkup] = useState(0);
  const [moreOrderedQtyData, setMoreOrderedQtyData] = useState(0)
  const inputQtyRef = useRef(null);
  const productNotesRef = useRef(null);
  const { typeVpgSalesPrice, typeVpgMovingAvgCost, typeVpgVendorPrice } = ProductVpgTypes || {}
  const { unitPerCase, notes, productPrices, vendorPrice, productId, priceScheduleVolume, priceMU, itemMU, discontinued, clearance, qtyInStock, manufacturerName, productName, manufacturerCode, thumbImg, pastPurchaseCount, isPopular, salesPrice, lastPosRetailPrice, salesOverride, muName, minOrderQty, vendorName, itemMuName, movingAvgCost, description, keywords } = productsData || {};
  let firstProductType = productPrices?.[0] || {}
  const { price: firstProductPrice } = firstProductType || {}
  const { typeClearance, typeDiscontinued, typeSalesPrice, typeVendorPrice, typeSalesOverride, typePriceScheduleSingle, typePriceScheduleVolume, typeLastPosRetailPrice, typeInActive, typeMovingAvgCost } = ProductTypes || {};
  const salesAndVenderPriceKey = firstProductType?.type === typeSalesPrice || firstProductType?.type === typeVendorPrice
  const [customInitialPrice, setCustomInitialPrice] = useState(0);
  const { customer } = loadCartDetails || {}
  const { fixMarginCustFlag, fixMarginRate } = customer || {}
  const { facilityId } = loadCartDetails?.shipToByFacility || {}
  const priceType = firstProductType?.type?.replace(/_/g, " ");
  const [isPriceAccept, setIsPriceAccept] = useState(productUpdateFlag ?? false);
  const [openConfirmPriceModel, setOpenConfirmPriceModel] = useState(false);

  useEffect(() => {
    if (firstProductType?.setInitialCustomPrice) {
      setCustomInitialPrice(firstProductType?.setInitialCustomPrice);
    }
    if (updatedPrice) {
      const changedPrice = modifiedPrice ? tempPrice : updatedPrice
      setTempNewPrice(changedPrice);
    } else {
      if (!salesAndVenderPriceKey && firstProductType?.price) {
        const changedPrice = modifiedPrice ? tempPrice : firstProductType?.price
        const finalPrice = firstProductType?.setPrice > 0 ? firstProductType?.setPrice.toFixed(5) : Number(changedPrice).toFixed(5);
        setTempNewPrice(finalPrice);
      }
    }
  }, [productsData]);
  const initialStockWarehouseData = stockWarehouseData?.find((i) => i?.productId === productId)
  const updatedAvailableQty = initialStockWarehouseData?.stockWarehouses?.find((i) => i?.stockWarehouseId === currentWorkspace?.stockWarehouseId)?.unitQtyAvailable
  const discontinuedOOS = discontinued && (updatedAvailableQty === 0)
  useEffect(() => {
    if (productsData?.productId && !stockWarehouseData.map(({ productId }) => productId).includes(productsData?.productId)) {
      dispatch(stockByWarehouseStart([productsData?.productId]));
    }
  }, [productsData?.productId]);

  const isValid = customer && fixMarginCustFlag && fixMarginRate > 0 && fixMarginRate < 1 && (vendorPrice?.price ?? vendorPrice);
  useEffect(() => {
    if (isValid) {
      const marginRate = Number(fixMarginRate);
      const calMarkup = (((Number(vendorPrice?.price ?? vendorPrice) / (1 - marginRate)) - Number(vendorPrice?.price ?? vendorPrice)) / (1.0 * Number(vendorPrice?.price ?? vendorPrice))) * 100
      setMarginRateMarkup(calMarkup);
      setTempNewPrice(getSalesPrice(marginRate, vendorPrice?.price ?? vendorPrice));
      // may need later
      // setTempNewPrice(
      //   Number(
      //     (
      //       (Number(
      //         (vendorPrice?.price).toFixed(
      //           5
      //         )
      //       ) *
      //         (100 +
      //           Number(calMarkup))) /
      //       100
      //     ).toFixed(5)
      //   )
      // );
    }
  }, [customer, vendorPrice])

  useEffect(() => {
    if (productIndexInfo.toString()) {
      setProductStateIndex(productIndexInfo);
    }
  }, [productIndexInfo]);

  useEffect(() => {
    if (productUpdateFlag) {
      setModifiedPrice(true);
      setNewPrice(updatedPrice);
      if (updatedPrice && !Array.isArray(updatedPrice)) {
        dispatch(getNewPrice(updatedPrice));
      }

    }
  }, []);
  const decimalCount = (num) => {
    // Convert to String
    const numStr = String(num);
    // String Contains Decimal
    if (numStr.includes(".")) {
      return numStr.split(".")[1].length;
    }
    // String Does Not Contain Decimal
    return 0;
  };

  useEffect(() => {
    setNewQty(updatedQty);
    if (updatedQty === 0) {
      let totalQty = 0;
      posFlowData?.cartProducts?.map(product => {
        if (product.productId === productId) {
          totalQty += product.qtyOrdered
        }
      })
      setNewQty(totalQty);
    }
    isShowSetPrice(productUpdateFlag);
    if (productUpdateFlag) {
      const customPrice = posFlowData?.cartProducts[productStateIndex]?.priceScheduleVolume?.find((item) => {
        item?.price === posFlowData?.cartProducts[productStateIndex]?.posRetailPrice
      })
      !isUpdate && setProductOrderNote(posFlowData?.cartProducts[productStateIndex]?.orderNote);
      !isUpdate && setProductShippingNote(posFlowData?.cartProducts[productStateIndex]?.shippingNote);
      if (lastUpdatedQty) {
        setQty(lastUpdatedQty);
        setBreakQty(lastUpdatedQty);
      } else {
        !isUpdate && setQty(posFlowData?.cartProducts[productStateIndex]?.qtyOrdered);
        !isUpdate && setBreakQty(posFlowData?.cartProducts[productStateIndex]?.qtyOrdered);
      }

      if (customPrice) {
        dispatch(getChangePriceInfo(true));
        dispatch(setChangeCartPrice(posFlowData?.cartProducts[productStateIndex]?.posRetailPrice))
      }
    }
    if (productId) {
      setLoader(false);
      handleCartDrawerCallback(false);
    } else {
      setLoader(true);
      handleCartDrawerCallback(true);
    }
  }, [productsData, productStateIndex, productUpdateFlag]);

  useEffect(() => {
    const input = document?.querySelector(".selfadapt");

    function resizeInput() {
      if (this?.value?.length) {
        this.style.width = this?.value?.length + "ch";
      } else if (this?.value?.length == "") {
        this.style.width = "4ch";
      } else if (this?.value?.length === null) {
        this.style.width = this?.value?.length + "px";
      } else {
        return 0;
      }
    }
    input?.addEventListener("input", resizeInput);
    resizeInput.call(input);
  }, [document?.querySelector(".selfadapt"), tempPrice, newPercentage]);

  const priceOnQuantityVolumeBreak = priceScheduleVolume?.length;
  const initialPricePosData = firstProductType?.type === typeSalesPrice ? customInitialPrice : firstProductType?.price;

  const unitPrice = priceOnQuantityVolumeBreak
    ? firstProductType?.price.toFixed(5)
    : modifiedPrice
      ? newPrice
        ? decimalCount(newPrice) < 2
          ? Number(newPrice)?.toFixed(5)
          : Number(newPrice)?.toFixed(5)
        : "0"
      : firstProductType?.price
        ? decimalCount(
          firstProductType?.price
        ) < 2
          ? (firstProductType?.price).toFixed(
            5
          )
          : Number(
            firstProductType?.price
          ).toFixed(5)
        : "0"
  const itemMuPriceWithUnitPrice = calculatePriceItemMuEach(priceMU, itemMU, unitPrice);
  const breakPrice = firstProductType?.setPrice?.toFixed(5) ?? (tempPrice ?? firstProductType?.price);
  const priceToSend = isCancel ? tempPrice : Number(newPrice)

  const getCustomPrice = (posRetailPrice) => {

    if (firstProductType?.type === typePriceScheduleVolume) {
      if (findRange?.price?.toFixed(5) === posRetailPrice) {
        return false
      }
      else {
        return true
      }
    } else {
      if (priceChanged) {
        return true
      }
      else {
        return false
      }
    }
  }

  function addToCartCalc() {
    const posRetailPrice = priceOnQuantityVolumeBreak
      ? breakPrice
      : modifiedPrice
        ? priceToSend
        : !isValid ? firstProductType?.price : tempPrice
          ? !isValid ? firstProductType?.price : tempPrice
          : 0
    const tempCartProducts = posFlowData?.cartProducts?.length
      ? posFlowData?.cartProducts
      : [];
    const tempProductData = productsData;
    tempProductData.qtyOrdered = qty;
    tempProductData.orderNote = productOrderNote;
    tempProductData.shippingNote = productShippingNote;
    tempProductData.initialPosPrice = firstProductType?.price
      ? firstProductType?.price
      : null;
    tempProductData.initialPriceData = initialPricePosData
      ? initialPricePosData
      : 0;
    if (isGPMCustom || isOrderTotalCustom) {
      dispatch(isGpmCustomFlag(false));
      dispatch(isOrderTotalCustomFlag(false));
      tempCartProducts?.length ? tempCartProducts.map((item) => {
        if (item.productId !== productsData.productId) {
          const itemMuPricePrices = Number(calculatePriceItemMuEach(item?.priceMU, item?.itemMU, item?.initialPriceData)).toFixed(5)
          item.posRetailPrice = item?.initialPriceData;
          const extPriceInfos = (item.qtyOrdered * Number(itemMuPricePrices)).toFixed(2);
          item.extPrice = Number(extPriceInfos).toFixed(2);
        }
      }) : []
      const itemMuPricePrices = Number(calculatePriceItemMuEach(tempProductData?.priceMU, tempProductData?.itemMU, tempProductData?.initialPriceData)).toFixed(5)
      tempProductData.posRetailPrice = tempProductData?.initialPriceData;
      tempProductData.itemMuPrice = itemMuPricePrices;
      const extPriceInfos = (qty * Number(itemMuPricePrices)).toFixed(2);
      tempProductData.extPrice = Number(extPriceInfos).toFixed(2);
      tempProductData.isCustomPrice = getCustomPrice(tempProductData?.initialPriceData);
      tempProductData.productPriceTypeApplied = getCustomPrice(tempProductData?.initialPriceData) ? ProductPriceType.CUSTOM : ProductPriceType[firstProductType?.type];
    } else {
      tempProductData.itemMuPrice = itemMuPriceWithUnitPrice;
      tempProductData.extPrice = Number(extPriceInfo).toFixed(2);
      tempProductData.posRetailPrice = posRetailPrice;
      tempProductData.isCustomPrice = getCustomPrice(posRetailPrice);
      tempProductData.productPriceTypeApplied = getCustomPrice(posRetailPrice) ? ProductPriceType.CUSTOM : ProductPriceType[firstProductType?.type];
    }
    tempProductData.itemMU = itemMU;
    tempProductData.itemMuName = itemMuName;
    tempProductData.totalQty = qtyInStock - qty;

    const initialStockWarehouseData = stockWarehouseData?.find((i) => i?.productId === productId)
    const updatedAvailableQty = initialStockWarehouseData?.stockWarehouses?.find((i) => i?.stockWarehouseId === currentWorkspace?.stockWarehouseId)?.unitQtyAvailable
    tempCartProducts.push({ ...tempProductData, ...productPriceCalculation(qty), updatedAvailableQty, qtyInStock: updatedAvailableQty, priceScheduleVendorProductGroupId: firstProductType?.priceScheduleVendorProductGroupId });

    const data = {
      ...posFlowData,
      proceedToPayment: false,
      cartProducts: tempCartProducts,
      viaSwitchCustomer: false,
      scheduleAdded: false
    };
    // check for remove if needed
    setShipToByFacilityId(
      facilityId
    );
    setPickUpSelected(true);
    dispatch(setToPickUp(true));
    dispatch(setShipToAddress(true));
    dispatch(examptShipTolocationData(false));
    !loadCartDetails?.shipToByFacility && dispatch(shippingAddressSelectedData(false));
    // end
    dispatch(saveProduct(data.cartProducts));
    dispatch(setPosFlowData(data));
    setCartEmpty(false);
    setState({ ...state, right: false });
    setTempNewPrice(0);
    dispatch(productsDataClear());
    dispatch(getupdateItemModal(false));
  }
  const discontinueProduct = firstProductType?.type === typeDiscontinued;
  const clearanceProduct = firstProductType?.type === typeClearance;

  const productInfoElement = document.getElementById("productInfoContent");

  if (productInfoElement) {
    const cartElement = document.getElementById("cartDrawerContent");
    cartElement?.classList?.add("cart-page-drawer-sec");
  }

  const { boltIcon } = images;

  const getPriceVpgCompute = () => {
    if (firstProductType.vpgComputePriceType === typeVpgVendorPrice) {
      return vendorPrice.price
    } else if (firstProductType.vpgComputePriceType === typeVpgMovingAvgCost) {
      return movingAvgCost.price
    } else if (firstProductType.vpgComputePriceType === typeVpgSalesPrice) {
      return salesPrice.price
    } else {
      return vendorPrice.price
    }
  }

  const setPriceBox = (!showSetPrice && salesAndVenderPriceKey && !modifiedPrice) || changePrice
  const changePriceInputAndQtyInEachBox = showSetPrice || modifiedPrice || (!salesAndVenderPriceKey && !changePrice)
  const qtyBox = changePriceInputAndQtyInEachBox
  const priceVolumeBox = priceScheduleVolume?.length && !setPriceBox
  const markupButtonArray = [
    {
      markup: "35",
      salesPrice: vendorPrice?.price ?? vendorPrice,
    },
    {
      markup: "45",
      salesPrice: vendorPrice?.price ?? vendorPrice,
    },
    {
      markup: "50",
      salesPrice: vendorPrice?.price ?? vendorPrice,
    },
    {
      markup: "55",
      salesPrice: vendorPrice?.price ?? vendorPrice
    },

  ]
  // It may need later 
  //{
  //   markup:"60",
  //   salesPrice: vendorPrice?.price
  // },
  const findRange = priceScheduleVolume?.find((item) => {
    return Math.min(item.minQty, item.maxQty) <= qty && Math.max(item.minQty, item.maxQty) >= qty
  })

  const productPriceCalculation = (qty?: number) => {
    const acceptedProductType = [
      typeClearance,
      typeDiscontinued,
      typeSalesOverride,
      typePriceScheduleSingle,
      typePriceScheduleVolume,
      typeLastPosRetailPrice,
      typeSalesPrice,
      typeVendorPrice,
      typeInActive,
      typeMovingAvgCost,
    ]
    const calculate = (item) => {
      if (item.maxQty && item.maxQty >= qty) {
        item.remaining = item.maxQty - qty;
        item.order = qty
        qty = 0;
      } else if (item.maxQty && item.maxQty < qty) {
        qty = qty - item.maxQty;
        item.order = item.maxQty
      } else if (!item.maxQty) {
        item.order = qty;
        qty = 0;
      }
      return item;
    };
    if (qty > 0 && acceptedProductType.includes(firstProductType?.type)) {
      return firstProductType = calculate(firstProductType);
    }
  }
  const profPercent = getProfit(tempPrice, getPriceVpgCompute());
  const remainingQty = posFlowData?.cartProducts?.length ? posFlowData?.cartProducts?.filter((item) => item?.productId === productId && (item?.discontinued || item?.clearance)) : []
  const actualQty = remainingQty.reduce((a, b) => a + b.qtyOrdered, 0)
  const disClearance = discontinued || clearance
  const updateDisable = posFlowData?.cartProducts?.length && (actualQty - posFlowData?.cartProducts[productStateIndex]?.qtyOrdered);

  const ProductCountOnLocation = stockWarehouseData?.find((item) => item.productId === productId)?.stockWarehouses?.map((item) => item);

  const { unitQtyAvailable } = ProductCountOnLocation?.find(
    (item) => item.stockWarehouseName === currentWorkspace.warehouseName
  ) || {};

  const [moreOrderedQty, setMoreOrderedQty] = useState(qty > unitQtyAvailable)

  useEffect(() => {
    setMoreOrderedQty(qty > unitQtyAvailable)
  }, [qty, unitQtyAvailable])

  useEffect(() => {
    setMoreOrderedQtyData(qty - unitQtyAvailable)
  }, [qty, unitQtyAvailable])

  const displayPrice = (isCancel || (Number(firstProductType?.setPrice) >= 0)) ? firstProductType?.setPrice : tempPrice;
  const itemMuPriceWithDisplayPrice = Number(calculatePriceItemMuEach(priceMU, itemMU, displayPrice)).toFixed(5)
  // const extPriceInfo = Number(roundToTwoDecimalPlaces((qty * Number(itemMuPriceWithDisplayPrice))));
  const extPriceInfo = Number(roundToTwoDecimalPlaces(calculatePriceItemMuEachNew(priceMU, itemMU, displayPrice, qty)));


  const findBreakPrice = () => {
    const data = priceScheduleVolume?.find((item) => {
      return Math.min(item.minQty, item.maxQty) <= qty && Math.max(item.minQty, item.maxQty) >= qty
    })
    !changePriceInfo && dispatch(setChangeCartPrice(data?.price))
  }

  useEffect(() => {
    if (!breakQty) return
    priceScheduleVolume?.length && findBreakPrice();
  }, [breakQty])

  const handleAddToCart = () => {
    if ((Number(profPercent) < 15) && !isPriceAccept) {
      setOpenConfirmPriceModel(true);
    } else {
      addToCartProduct();
    }
  }

  const addToCartProduct = () => {
    dispatch(addToCartDrawer(false))
    showScrollCartPage();
    dispatch(lastProductQty(0));
    dispatch(lastViewProductIndex(null));
    if (qty > 0) {
      addToCartCalc();
      setPriceChanged(false);
      dispatch(setNoLoadDetailsAction(true))
      setSearchProductsData([]);
      setFilteredSearchProduct([]);
      dispatch(clearSearchProduct());
      dispatch(arrowReset());
    }
    setState({ ...state, right: false });
  }

  const handleUpdateCart = () => {
    if ((Number(profPercent) < 15) && !isPriceAccept) {
      setOpenConfirmPriceModel(true);
    } else {
      updatedToCartProduct();
    }
  }

  const updatedToCartProduct = () => {
    dispatch(lastProductQty(0));
    dispatch(lastViewProductIndex(null));
    const volumeBreakPrice = priceOnQuantityVolumeBreak && (firstProductType?.setPrice ? firstProductType?.setPrice.toFixed(5) : Number(updatedPrice).toFixed(5));
    if (qty > 0) {
      setPriceChanged(false);
      setUpdatedProductsFlag(false);
      const indexFound = productStateIndex;
      const initialStockWarehouseData = stockWarehouseData?.find((i) => Boolean(i?.stockWarehouses))
      const updatedAvailableQty = initialStockWarehouseData?.stockWarehouses?.find((i) => i?.stockWarehouseId === currentWorkspace?.stockWarehouseId)?.unitQtyAvailable
      if (indexFound > -1) {
        const posRetailPrice = priceOnQuantityVolumeBreak
          ? volumeBreakPrice
          : modifiedPrice
            ? Number(changePriceInfo ? firstProductType?.setPrice : newPrice)
            : Number(firstProductType?.setPrice ?? tempPrice);
        const tempCartProducts =
          posFlowData?.cartProducts
            ? posFlowData?.cartProducts
            : [];

        const tempProductData = productsData;
        tempProductData.initialPriceData =
          initialPricePosData
            ? initialPricePosData
            : 0;
        if (isGPMCustom || isOrderTotalCustom) {
          dispatch(isGpmCustomFlag(false));
          dispatch(isOrderTotalCustomFlag(false));
          tempCartProducts?.length ? tempCartProducts.map((item) => {
            if (item.productId !== productsData.productId) {
              const itemMuPricePrices = Number(calculatePriceItemMuEach(item?.priceMU, item?.itemMU, item?.initialPriceData)).toFixed(5)
              item.posRetailPrice = item?.initialPriceData;
              const extPriceInfos = (item.qtyOrdered * Number(itemMuPricePrices)).toFixed(2);
              item.extPrice = Number(extPriceInfos).toFixed(2);
            }
          }) : []
          const itemMuPricePrices = Number(calculatePriceItemMuEach(tempProductData?.priceMU, tempProductData?.itemMU, tempProductData?.initialPriceData)).toFixed(5)
          tempProductData.posRetailPrice = tempProductData?.initialPriceData;
          tempProductData.itemMuPrice = itemMuPricePrices;
          const extPriceInfos = (qty * Number(itemMuPricePrices)).toFixed(2);
          tempProductData.extPrice = Number(extPriceInfos).toFixed(2);
          tempProductData.isCustomPrice = getCustomPrice(tempProductData?.initialPriceData);
          tempProductData.productPriceTypeApplied = getCustomPrice(tempProductData?.initialPriceData) ? ProductPriceType.CUSTOM : ProductPriceType[firstProductType?.type];
        } else {
          tempProductData.itemMuPrice = itemMuPriceWithUnitPrice;
          tempProductData.extPrice = Number(extPriceInfo).toFixed(2);
          tempProductData.posRetailPrice = posRetailPrice;
          tempProductData.isCustomPrice = getCustomPrice(posRetailPrice);
          tempProductData.productPriceTypeApplied = getCustomPrice(posRetailPrice) ? ProductPriceType.CUSTOM : ProductPriceType[firstProductType?.type];
        }
        tempProductData.qtyOrdered = qty;
        tempProductData.orderNote = productOrderNote;
        tempProductData.shippingNote = productShippingNote;
        tempProductData.itemMU = itemMU;
        tempProductData.itemMuName = itemMuName;
        tempProductData.updatedAvailableQty = updatedAvailableQty;
        tempProductData.qtyInStock = updatedAvailableQty;
        tempProductData.priceScheduleVendorProductGroupId = firstProductType?.priceScheduleVendorProductGroupId
        tempProductData.markupMultiplier = firstProductType?.markupMultiplier
        tempCartProducts[indexFound] = tempProductData;

        const tempPickData =
          posFlowData?.pickOrderQuantity
            ?.length &&
          posFlowData?.pickOrderQuantity?.filter(
            (item) =>
              item.productId !==
              productId
          );
        const tempPickArr =
          posFlowData?.pickQuantityArr
            ?.length &&
          posFlowData?.pickQuantityArr?.filter(
            (item) =>
              item.item !==
              productId
          );
        const tempAllPickQtyArray =
          posFlowData?.pickAllQuantityArray
            ?.length &&
          posFlowData?.pickAllQuantityArray?.filter(
            (item) =>
              item.item !==
              productId
          );

        const { addInStorage } = posFlowData || {}
        const { items } = addInStorage || {}
        const tempPutStorage = items?.filter(
          (item) =>
            item.productId !==
            productId
        );
        const data = {
          ...posFlowData,
          proceedToPayment: false,
          cartProducts: tempCartProducts,
          pickOrderQuantity: tempPickData,

          pickQuantityArr: tempPickArr,
          pickAllQuantityArray:
            tempAllPickQtyArray,
          addInStorage: tempPutStorage ? tempPutStorage : null,
          scheduleAdded: false
        };
        // check for remove if needed
        setShipToByFacilityId(
          facilityId
        );
        setPickUpSelected(true);
        dispatch(setToPickUp(true));
        dispatch(setShipToAddress(true));
        dispatch(examptShipTolocationData(false));
        !loadCartDetails?.shipToByFacility && dispatch(shippingAddressSelectedData(false));
        // end
        dispatch(setPosFlowData(data));
        setCartEmpty(false);
        dispatch(productsDataClear());
        dispatch(clearSearchProduct());
        setState({ ...state, right: false });
      } else {
        const posRetailPrice = priceOnQuantityVolumeBreak
          ? firstProductType?.setPrice.toFixed(2) : Number(tempPrice);
        const tempCartProducts =
          posFlowData?.cartProducts
            ? posFlowData?.cartProducts
            : [];
        const tempProductData = productsData;
        if (isGPMCustom || isOrderTotalCustom) {
          dispatch(isGpmCustomFlag(false));
          dispatch(isOrderTotalCustomFlag(false));
          tempCartProducts?.length ? tempCartProducts.map((item) => {
            if (item.productId !== productsData.productId) {
              const itemMuPricePrices = Number(calculatePriceItemMuEach(item?.priceMU, item?.itemMU, item?.initialPriceData)).toFixed(5)
              item.posRetailPrice = item?.initialPriceData;
              const extPriceInfos = (item.qtyOrdered * Number(itemMuPricePrices)).toFixed(2);
              item.extPrice = Number(extPriceInfos).toFixed(2);
            }
          }) : []
          const itemMuPricePrices = Number(calculatePriceItemMuEach(tempProductData?.priceMU, tempProductData?.itemMU, tempProductData?.initialPriceData)).toFixed(5)
          tempProductData.posRetailPrice = tempProductData?.initialPriceData;
          tempProductData.itemMuPrice = itemMuPricePrices;
          const extPriceInfos = (qty * Number(itemMuPricePrices)).toFixed(2);
          tempProductData.extPrice = Number(extPriceInfos).toFixed(2);
          tempProductData.isCustomPrice = getCustomPrice(tempProductData?.initialPriceData);
          tempProductData.productPriceTypeApplied = getCustomPrice(tempProductData?.initialPriceData) ? ProductPriceType.CUSTOM : ProductPriceType[firstProductType?.type];
        } else {
          tempProductData.itemMuPrice = itemMuPriceWithUnitPrice;
          tempProductData.extPrice = Number(extPriceInfo).toFixed(2);
          tempProductData.posRetailPrice = posRetailPrice;
          tempProductData.isCustomPrice = getCustomPrice(posRetailPrice);
          tempProductData.productPriceTypeApplied = getCustomPrice(posRetailPrice) ? ProductPriceType.CUSTOM : ProductPriceType[firstProductType?.type];
        }
        tempProductData.qtyOrdered = qty;
        tempProductData.orderNote = productOrderNote;
        tempProductData.shippingNote = productShippingNote;
        tempProductData.priceScheduleVendorProductGroupId = firstProductType?.priceScheduleVendorProductGroupId
        tempProductData.markupMultiplier = firstProductType?.markupMultiplier
        tempCartProducts.push({ ...tempProductData, updatedAvailableQty });
        tempProductData.itemMU = itemMU;
        tempProductData.itemMuName = itemMuName;
        const tempPickData =
          posFlowData?.pickOrderQuantity
            ?.length &&
          posFlowData?.pickOrderQuantity?.filter(
            (item) =>
              item.productId !==
              productId
          );

        const tempPickArr =
          posFlowData?.pickQuantityArr
            ?.length &&
          posFlowData?.pickQuantityArr?.filter(
            (item) =>
              item.item !==
              productId
          );
        const tempAllQtyPickArray =
          posFlowData?.pickAllQuantityArray
            ?.length &&
          posFlowData?.pickAllQuantityArray?.filter(
            (item) =>
              item.item !==
              productId
          );
        const { addInStorage } = posFlowData || {}
        const { items } = addInStorage || {}
        const tempPutStorage = items?.filter(
          (item) =>
            item.productId !==
            productId
        );
        const data = {
          ...posFlowData,
          proceedToPayment: false,
          cartProducts: tempCartProducts,
          pickOrderQuantity: tempPickData,
          pickQuantityArr: tempPickArr,
          pickAllQuantityArray:
            tempAllQtyPickArray,
          addInStorage: tempPutStorage ? tempPutStorage : null,
          scheduleAdded: false
        };

        // check for remove if needed
        setShipToByFacilityId(
          facilityId
        );
        setPickUpSelected(true);
        dispatch(setToPickUp(true));
        dispatch(setShipToAddress(true));
        dispatch(examptShipTolocationData(false));
        !loadCartDetails?.shipToByFacility && dispatch(shippingAddressSelectedData(false));
        // end
        dispatch(setPosFlowData(data));
        setCartEmpty(false);
        dispatch(productsDataClear());
        dispatch(clearSearchProduct());
        setState({ ...state, right: false });
      }
      setSearchProductsData([]);
      setFilteredSearchProduct([]);
    }
    setState({ ...state, right: false });
    dispatch(addToCartDrawer(false));
    dispatch(setNoLoadDetailsAction(true))
    dispatch(getupdateItemModal(false))
  }
  const handleChangePrice = () => {
    if (typeof tempPrice === "string" && tempPrice == ".") {
      dispatch(enterDisableAddToCart(false))
      setChangePrice(false);
      setModifiedPrice(true);
      dispatch(setChangeCartPrice(Number(getCancelPrice)));
      setIsCancel(true);
      if (changePriceInfo) {
        dispatch(getChangePriceInfo(true));
      }
    } else {
      setModifiedPrice(true);
      setChangePrice(false);
      setPriceChanged(true);
      setTempNewPrice(tempPrice);
      setNewPrice(tempPrice < 0 ? 0 : Number(tempPrice));
      setNewPercentage(newPercentage);
      dispatch(getNewPrice(tempPrice < 0 ? 0 : Number(tempPrice)));
      dispatch(getChangePriceInfo(true));
      dispatch(showOriginalPriceInfo(false));
      dispatch(setChangeCartPrice(Number(tempPrice)));
      !changePrice && dispatch(setInitialCustomPrice(Number(tempPrice)));
      dispatch(enterDisableAddToCart(false));
      if (salesAndVenderPriceKey && modifiedPrice) {
        handleAddToCart();
      }
    }
  };
  const handleKeyPress = (e) => {
    const { enter } = keys || {};
    const targetId = e.target.id;
    const isMatchingId = (targetId === productNotesRef?.current?.id) || targetId === inputQtyRef.current.id;
    if (e.keyCode === enter && ![isEnterDisabled].includes(true) && !isWareHouseAvailable && isMatchingId) {
      if (productUpdateFlag) {
        const isGtrQtyUpdate = (!qty || (disClearance ? qty > (qtyInStock - updateDisable) : false))
        if (!isGtrQtyUpdate) {
          e.preventDefault()
          handleUpdateCart()
        }
      } else {
        const isGtrQtyAdd = (!qty || (disClearance ? (actualQty ? (qty > (qtyInStock - actualQty)) : (qty > qtyInStock)) : false));
        if (!isGtrQtyAdd) {
          if (salesAndVenderPriceKey) {

            handleChangePrice();
          }
          else {
            handleAddToCart();
          }
        }

      }
    }
    if (e.keyCode === enter && changePrice && !document.activeElement.classList.contains("price-options") && !document.activeElement.classList.contains("button-cancel")) {
      handleChangePrice()
    }
  };

  const handleRemoveItemWithHotkey = () => {
    if (newQty > 0) {
      handleRemoveClick(productId);
    }
  };

  useHotkeys('delete, del, backspace', handleRemoveItemWithHotkey);


  useEffect(() => {
    if (productId) {
      document?.addEventListener("keydown", handleKeyPress);
      return function cleanup() {
        document?.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [productsData, qty, extPriceInfo, tempPrice, newQty, productOrderNote, productShippingNote]);

  const savingPrice = (((salesPrice?.price - (clearance?.price ?? discontinued?.price)) / salesPrice?.price) * 100).toFixed(2);

  const handleNotes = () => {
    setNotesOpen(true)
    setNotesPlace(false)
  }

  const handleFocusInput = () => {
    setTimeout(() => {
      inputQtyRef.current.focus();
    }, 0);
  }

  const handleUpdateQty = (e) => {
    if (decimal18RegEx.test(e.target.value)) {
      setQty(Number(e.target.value));
    } else {
      e.target.value = e.target.value.slice(0, -1);
    }

    if (e.target.value === "0") {
      setQty(Number(1));
    }

    if (!e.target.value) {
      setQty(null);
    }
    setBreakQty(Number(e.target.value));
    setIsUpdate(true)
  }

  const handleAddQty = (e) => {
    if (qtyRegEx.test(e.target.value)) {
      setQty(Number(e.target.value));
    }
    if (!e.target.value) {
      setQty(null);
    }
    setBreakQty(Number(e.target.value))
  }

  const handlePriceChangeInput = (value) => {
    setPriceInDecimal(false);
    if (
      !value ||
      fiveDecimalRegex.test(value)
    ) {
      const findPercentage =
        (Number(value) * 100) /
        Number(
          vendorPrice?.price ?? vendorPrice
        ) -
        100;

      setTempNewPrice(value);
      findPercentage > -1 &&
        findPercentage < 1
        ? setNewPercentage("--")
        : setNewPercentage(
          getProfit(value, vendorPrice?.price ?? vendorPrice)
        );
    }
  }

  const handleProfPercentChange = (e) => {
    if (
      !e.target.value ||
      profRegex.test(e.target.value)
    ) {
      if (!e.target.value) {
        setTempNewPrice(0);
      }
      setNewPercentage(e.target.value);

      if (!e.target.value) {
        setTempNewPrice(0);
      } else {
        setTempNewPrice(getSalesPrice(Number(e.target.value), vendorPrice?.price ?? vendorPrice));
      }
    }
  }

  useEffect(() => {
    posFlowData?.cartProducts.forEach((item) => {
      if (item.lineIdData === (getProductIndex + 1) && (item.productId === productId)) {
        setShowPriceType(`${item?.productPriceTypeApplied}`);
      }
    })
  }, [posFlowData?.cartProducts])

  useEffect(() => {
    setIsCustomPrice(((showPriceType === "12") || (showPriceType === "CUSTOM") || priceChanged) && changePriceInfo);
  }, [priceChanged, isCustomPrice, showPriceType, changePriceInfo]);

  const { itemLedger, itemHistory, quoteHistory, salesHistory, purchaseHistory, invoiceHistory, minusHundred, customPrice, popularItem, clearanceSale, lastPriceLabel, productOutOfStockAndDiscontinuedMessage, ManageProduct } = texts || {}

  const stateAndFunctions = { setChangePrice, setPriceInDecimal, setNewPercentage, isShowSetPrice, setTempNewPrice, handleRemoveClick, setProductOrderNote, setProductShippingNote }

  const openReport = () => {
    dispatch(lastProductQty(qty));
    setState({ ...state, right: false });
    dispatch(addToCartDrawer(false));
  }

  const handleCloseModel = () => {
    setOpenConfirmPriceModel(false);
  }

  const handleConfirmPrice = () => {
    setIsPriceAccept(true);
    setOpenConfirmPriceModel(false);
    if (productUpdateFlag) {
      updatedToCartProduct();
    } else {
      addToCartProduct();
    }
  }

  return (
    <>
      <PosIconButton
        className="btn back-next-btn-small cart-drawer-disable-btn-style cart-drawer-close-icon responsive-back-btn pos-icon-back-btn"
        onClick={onCloseDrawer}
        disabled={loader || isWareHouseAvailable}
        tabIndex={-1}
      >
        <ClearIcon className="back-button" />
      </PosIconButton>
      <Box
        role="presentation"
        className="h-100 cart-drawer-content"
        id="productInfoContent"
      >
        <Container className="product-drawer h-100 position-relative">
          <div className="product-drawer-main-container ">
            <div className="h-100 cart-page-add-product-drawer cart-drawer-first-part">
              <div className="cart-header">
                <div className="cart-sub-heading">
                  <PosPageHeading
                    heading={manufacturerName}
                    grayHeading
                  />
                  <PosPageHeading
                    heading={productName}
                    blackHeading
                  />
                  <h6 className="neworder-mfg-code">
                    {manufacturerCode}
                  </h6>
                </div>
                <div className="cart-sub-price">
                  <PosImage
                    src={thumbImg}
                    className="title-image"
                    alt="thumbImg"
                  />
                </div>
              </div>
              <div className="Manage-Product-link">
                <span className="product-id"
                >{productId}</span>
                <div className="gray-dot"></div>
                <Button
                  className="see-all button-focus-class"
                  onClick={() => window.open(`${Paths.product}/${productId}`, '_blank', 'noreferrer')}
                >
                  {ManageProduct}
                </Button>
              </div>
              <div className="cart-flags border-bottom mt-3">
                {pastPurchaseCount !== 0 && (
                  <div className="popular-flag popular-item-text pl-2 product-detailed-info">
                    <div className="popular-recent-order-info">
                      {isPopular && (
                        <div className="popular-item-info">
                          {popularItem}
                          <PosImage src={boltIcon} alt="bolt" />
                        </div>
                      )}
                      <div className="recent-order-info">
                        {pastPurchaseCount} recent order
                        {pastPurchaseCount > 1 ? "s" : ""}
                      </div>
                    </div>
                  </div>
                )}
                {clearance && (
                  <div className="popular-flag popular-item-text clearance-info-div">
                    <div className="popular-recent-order-info">
                      <div className="popular-item-info">
                        {clearanceSale}
                      </div>
                      <div className="clearance-sale-percentage">
                        {savingPrice}
                        % savings
                      </div>
                      <div className="clearance-sale-qty">
                        Only {clearance?.maxQty} left
                      </div>
                    </div>
                  </div>
                )}
                {lastPosRetailPrice ? (
                  <div
                    className={`${salesOverride?.price && firstProductType?.price
                      ? "pl-0"
                      : "popular-item-text"
                      } popular-flag product-detailed-info mr-0`}
                  >
                    <div className="popular-recent-order-info">
                      <div className="popular-item-info">
                        {lastPriceLabel}
                      </div>
                      <div className="recent-order-info">
                        ${lastPosRetailPrice?.price?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                ) : (
                  null
                )}
              </div>
              <div className="margintop-10 ">
                {isWareHouseDrawer ? <UnitsAvailable
                  handleFocusInput={handleFocusInput}
                  productIdInfo={productId}
                  discontinueProduct={discontinueProduct}
                  clearanceProduct={clearanceProduct}
                  allocatedDraftRowRef={allocatedDraftRowRef}
                /> : null}
              </div>
              <Divider className="pos-divider" />
              <div className="shipping-address-row border-bottom ptb-10 cart-product-drawer-left-info">
                <div className="cart-product-drawer-left-info-style left-sec">
                  <span className="int-18-reg">Description</span>
                </div>
                <div className="cart-product-drawer-left-info-style">
                  <div>
                    <span className="int-18-reg-bold">
                      {description?.length > 90 ? <><TruncatedText allowCollapse={true} className="button-focus-class" minLength={90}>{description}</TruncatedText></> : description}
                    </span>
                  </div>
                </div>
              </div>
              {keywords ? <div className="shipping-address-row border-bottom ptb-10 cart-product-drawer-left-info">
                <div className="cart-product-drawer-left-info-style left-sec">
                  <span className="int-18-reg">Keywords</span>
                </div>
                <div className="cart-product-drawer-left-info-style">
                  <div>
                    <span className="int-18-reg-bold">
                      {keywords}
                    </span>
                  </div>
                </div>
              </div> : null}
              <div className="shipping-address-row border-bottom ptb-10 cart-product-drawer-left-info">
                <div className="cart-product-drawer-left-info-style left-sec">
                  <span className="int-18-reg">Price Unit of Measure</span>
                </div>
                <div className="cart-product-drawer-left-info-style">
                  <span className="int-18-reg-bold">
                    {muName} ({priceMU})
                  </span>
                </div>
              </div>
              <div className="shipping-address-row border-bottom ptb-10 cart-product-drawer-left-info">
                <div className="cart-product-drawer-left-info-style left-sec">
                  <span className="int-18-reg">Increment</span>
                </div>
                <div className="cart-product-drawer-left-info-style">
                  <div>
                    <span className="int-18-reg-bold">
                      {getIncrement(unitPerCase, priceMU, itemMU)}
                    </span>
                  </div>
                </div>
              </div>
              {lastPosRetailPrice ?
                <div className="shipping-address-row border-bottom ptb-10">
                  <div className="w50">
                    <span className="int-18-reg">Last Price</span>
                  </div>
                  <div className="w50 int-18-reg-bold">
                    {lastPosRetailPrice?.price.toFixed(2)}
                  </div>
                </div>
                : null}
              <div className="shipping-address-row border-bottom ptb-10 cart-product-drawer-left-info">
                <div className="int-18-reg cart-product-drawer-left-info-style left-sec">
                  Vendor
                </div>
                <div className="int-18-reg-bold cart-product-drawer-left-info-style">
                  {vendorName}
                </div>
              </div>
              <div
                onMouseEnter={() => !priceVisible && setProf(true)}
                onMouseLeave={() => !priceVisible && setProf(false)}
                tabIndex={0}
                className={`${checkProf ? "always-open" : ""
                  } schedule-collapsed-group`}
              >
                <div className="shipping-address-row border-bottom ptb-10 cart-product-drawer-left-info schedule-collapsed-group-main">
                  <div className="int-18-reg cart-product-drawer-left-info-style left-sec">
                    Schedule
                  </div>
                  <div className="toggle-schedule int-18-reg-bold cart-product-drawer-left-info-style">
                    {posFlowData?.schedule?.name ||
                      loadCartDetails?.customer?.priceScheduleName}
                    <div className="toggle-schedule-icon-container">
                      <MaterialIcon
                        icon={checkProf ? "unfold_less" : "unfold_more"}
                        size="20"
                        classes="toggle-schedule-icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="schedule-collapsed-group-sub">
                  <div className="shipping-address-row border-bottom ptb-10 cart-product-drawer-left-info">
                    <div className="int-18-reg cart-product-drawer-left-info-style left-sec">
                      Prof %
                    </div>
                    <div className="cart-product-drawer-left-info-style">
                      <span className="int-18-reg-bold">
                        {profPercent}
                      </span>
                    </div>
                  </div>
                  <div className="shipping-address-row border-bottom ptb-10 cart-product-drawer-left-info">
                    <div className="int-18-reg cart-product-drawer-left-info-style left-sec">
                      Markup %
                    </div>
                    <div className="cart-product-drawer-left-info-style">
                      <span className="int-18-reg-bold">
                        {getMarkup(tempPrice, getPriceVpgCompute())}
                      </span>
                    </div>
                  </div>
                  <div className="shipping-address-row border-bottom ptb-10 cart-product-drawer-left-info">
                    <div className="int-18-reg cart-product-drawer-left-info-style left-sec">
                      Last Cost
                    </div>
                    <div className="cart-product-drawer-left-info-style">
                      <div>
                        <span className="int-18-reg-bold">
                          {vendorPrice && `$ ${vendorPrice?.price ?? vendorPrice}`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="shipping-address-row border-bottom ptb-10 cart-product-drawer-left-info">
                    <div className="int-18-reg cart-product-drawer-left-info-style left-sec">
                      Moving avg. Cost
                    </div>
                    <div className="cart-product-drawer-left-info-style">
                      <div>
                        <span className="int-18-reg-bold">
                          {movingAvgCost ? `$ ${movingAvgCost.price}` : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="shipping-address-row border-bottom ptb-10 cart-product-drawer-left-info">
                    <div className="int-18-reg cart-product-drawer-left-info-style left-sec">
                      Wholesale Price
                    </div>
                    <div className="cart-product-drawer-left-info-style">
                      <div>
                        <span className="int-18-reg-bold">
                          {salesPrice && `$ ${salesPrice?.price}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="shipping-address-row border-bottom ptb-10 cart-product-drawer-left-info">
                <div className="cart-product-drawer-left-info-style left-sec">
                  <span className="int-18-reg">Min. Order Qty.</span>
                </div>
                <div className="cart-product-drawer-left-info-style">
                  <div>
                    <span className="int-18-reg-bold">
                      {minOrderQty}
                    </span>
                  </div>
                </div>
              </div>
              {notes ?
                <div className="shipping-address-row border-bottom ptb-10">
                  <div className="w50">
                    <span className="int-18-reg">Notes</span>
                  </div>
                  <div className="w50">
                    <div className="sales-history-link">
                      <PosButton
                        className="btn sub-btn"
                        size="small"
                        onClick={handleNotes}
                        variant="text"
                      >See Notes</PosButton>
                    </div>
                  </div>
                </div>
                : null}
              <div className="shipping-address-row border-bottom ptb-10 cart-product-drawer-left-info">
                <div className="cart-product-drawer-left-info-style left-sec">
                  <span className="int-18-reg">Reports</span>
                </div>
                <div className="cart-product-drawer-left-info-style">
                  <div className="sales-history-link">
                    <PosButton
                      className="btn sub-btn"
                      size="small"
                      onClick={() => {
                        openReport();
                        navigate(
                          `${Paths.posItemLedger}/${productId}`
                        );
                        dispatch(setPosFlowData({
                          ...posFlowData,
                          cartProductDetails: { ...posFlowData?.cartProductDetails, posRetailPrice: tempPrice }
                        }
                        ))
                      }}
                      variant="text"
                    >{itemLedger}</PosButton>
                    <PosButton
                      className="btn sub-btn"
                      size="small"
                      onClick={() => {
                        openReport();
                        navigate(`${Paths.posItemHistory}/${productId}`, { state: { detail: productsData } });
                      }}
                      variant="text"
                    >{itemHistory}</PosButton>
                    <PosButton
                      className="btn sub-btn"
                      size="small"
                      onClick={() => {
                        openReport();
                        navigate(`${Paths.posQuotesHistory}/${productId}`, { state: { detail: productsData } });
                      }}
                      variant="text"
                    >{quoteHistory}</PosButton>
                    <PosButton
                      className="btn sub-btn"
                      size="small"
                      onClick={() => {
                        openReport();
                        navigate(`${Paths.posSalesHistory}/${productId}`, { state: { detail: productsData } });
                      }}
                      variant="text"
                    >{salesHistory}</PosButton>
                    <PosButton
                      className="btn sub-btn"
                      size="small"
                      onClick={() => {
                        openReport();
                        navigate(`${Paths.purchaseHistory}/${productId}`, { state: { detail: productsData } });
                      }}
                      variant="text"
                    >{purchaseHistory}</PosButton>
                    <PosButton
                      className="btn sub-btn"
                      size="small"
                      onClick={() => {
                        openReport();
                        navigate(`${Paths.invoiceHistory}/${productId}`, { state: { detail: productsData } });
                      }}
                      variant="text"
                    >{invoiceHistory}</PosButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="cart-drawer-second-part">
              <CartPriceVolumeBox
                salesPrice={salesPrice}
                setPriceBox={setPriceBox}
                changePrice={changePrice}
                fixMarginCustFlag={fixMarginCustFlag}
                fixMarginRate={fixMarginRate}
                inputQtyRef={inputQtyRef}
                tempPrice={tempPrice}
                priceInDecimal={priceInDecimal}
                decimalCount={decimalCount}
                marginRateMarkup={marginRateMarkup}
                minusHundred={minusHundred}
                newPercentage={newPercentage}
                markupButtonArray={markupButtonArray}
                priceMU={priceMU}
                muName={muName}
                handleProfPercentChange={handleProfPercentChange}
                handleAddQty={handleAddQty}
                handleUpdateQty={handleUpdateQty}
                handlePriceChangeInput={handlePriceChangeInput}
                customPrice={customPrice}
                productOutOfStockAndDiscontinuedMessage={productOutOfStockAndDiscontinuedMessage}
                moreOrderedQty={moreOrderedQty}
                qtyBox={qtyBox}
                priceVolumeBox={priceVolumeBox}
                addCustomerLabel={addCustomerLabel}
                discontinuedOOS={discontinuedOOS}
                priceType={priceType}
                moreOrderedQtyData={moreOrderedQtyData}
                setIsCancel={setIsCancel}
                getCancelPrice={getCancelPrice}
                setModifiedPrice={setModifiedPrice}
                setNewPrice={setNewPrice}
                stateAndFunctions={stateAndFunctions}
                changePriceInfo={changePriceInfo}
                handleChangePrice={handleChangePrice}
                changePriceInputAndQtyInEachBox={changePriceInputAndQtyInEachBox}
                isCustomPrice={isCustomPrice}
                displayPrice={displayPrice}
                discontinued={discontinued}
                clearance={clearance}
                firstProductPrice={firstProductPrice}
                vendorPrice={vendorPrice}
                itemMuName={itemMuName}
                itemMU={itemMU}
                priceScheduleVolume={priceScheduleVolume}
                findRange={findRange}
                productUpdateFlag={productUpdateFlag}
                qty={qty}
                disClearance={disClearance}
                updatedAvailableQty={updatedAvailableQty}
                updateDisable={updateDisable}
                isWareHouseAvailable={isWareHouseAvailable}
                handleUpdateCart={handleUpdateCart}
                modifiedPrice={modifiedPrice}
                newPrice={newPrice}
                actualQty={actualQty}
                itemMuPriceWithDisplayPrice={itemMuPriceWithDisplayPrice}
                extPriceInfo={extPriceInfo}
                discontinueProduct={discontinueProduct}
                newQty={newQty}
                productId={productId}
                productNotesRef={productNotesRef}
                productShippingNote={productShippingNote}
                productOrderNote={productOrderNote}
                handleAddToCart={handleAddToCart}
                profPercent={profPercent}
                isPriceAccept={isPriceAccept}
                setIsPriceAccept={setIsPriceAccept}
              />
            </div>
          </div>
        </Container>
      </Box>
      {openConfirmPriceModel ? (
        <ConfirmationPrice
          open={openConfirmPriceModel}
          handleCloseModel={handleCloseModel}
          handleConfirmPrice={handleConfirmPrice}
        />
      ) : null}
    </>
  );
}

export default CartPage;
