import { Grid } from "@material-ui/core";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { PosButton, PosSelect, PosSnackbar } from "apps/pos/Common/uiCore";
import AvailableAllStock from "apps/pos/UnitsAvailable/AvailableAllSctock/AvailableAllStock";
import CommonEditButton from "apps/product/Components/ProductDetails/CommonEditButton";
import CommonGridSection from "apps/product/Components/ProductDetails/CommonGridSection";
import CreateGridStarIcon from "apps/product/Components/ProductDetails/CreateGridStarIcon";
import useFilterSections from "apps/product/Components/ProductDetails/useSectionFilter";
import useProductsDetailsHooks from "apps/product/modules/productDetailsContainer/hooks/mutationHooks";
import CommonModel from "components/Dialog/Dialog";

import { commonConstants } from "apps/product/helper/commonConstants";
import { sectionName } from "apps/product/modules/productDetailsContainer/constants";
import { alertMessage } from "constants/constant";
import images from "constants/images";
import { WarehouseDataProps } from "constants/interfaces";
import { getAddToBatchProduct, saveAddToBatchProduct } from "utils/helper";
import { getLocalStoraGeItem } from "utils/utils";

import { distributorData } from "sagas/distributor/selector";
import { getProductsStockMinMaxStart } from "sagas/productsDetails/productStock.slice";
import { getIsAddMinMaxBatchProduct, getProductsStockMinMax, getSingleProductDataFunction } from "sagas/productsDetails/selector";
import { addMinMaxBatchProductStart } from "sagas/productsDetails/singleProductDetails.slice";

import "apps/product/Styles/productDetailCommonStyling.scss";
import { getStockWarehouseData } from "sagas/pos/selector";
import "./productDetailCommonStyling.scss";




const ProductDetailsSection = ({ isStarred = false, searchText, handleMeasurementsSection }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const distributor = useSelector(distributorData)
  const isAddMinMaxBatchProduct = useSelector(getIsAddMinMaxBatchProduct)
  const stockWarehouseData = useSelector(getStockWarehouseData)
  const getSingleProductData = useSelector(getSingleProductDataFunction);

  const getProductsStockMinMaxData = useSelector(getProductsStockMinMax)
  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {};

  const [workStationArrayData, setWorkStationArrayData] = useState([]);
  const [qtyInStock, setQtyInStock] = useState([]);
  const [allStockModal, setAllStockModal] = useState(false);
  const [workStationId, setWorkStationId] = useState(stockWarehouseId);
  const [isSnackbarVisibleInvitation, setIsSnackbarVisibleInvitation] = useState(false);
  const [snakbarmessage, setSnackbarMessage] = useState("");

  const { tryBitLater, tryAgain, minutes } = alertMessage;
  const { defaultIcon } = images;
  const { min, Max, Inc, AvlQty, WAREHOUSE, qtyinStock, SeeallLocations, StockMinMax, priceUOM, itemOUM, unitPerCases, minimumToOrder, Weight, addToBatch } = commonConstants;

  const { details } = getSingleProductData || {}
  const { productId, productName, manufacturerCode, imgDocs, measurements, manufacturerId } = details || {}
  const { itemMU, itemMuName, muName, priceMU, weight, unitPerCase, minimumCases } = measurements || {}
  const { warehouseName, minLevel, maxLevel, increment } = getProductsStockMinMaxData || {}
  const { details: detailsSection } = sectionName;
  const {
    getProductMinMaxStockMutation,
    addMinMaxBatchsProductsMutation
  } = useProductsDetailsHooks();
  const { showSection } = useFilterSections({ searchText, sectionName: detailsSection, isStarred });
  useEffect(() => {
    const workstationArray = [];

    distributor?.warehouses.map((location) => {
      const object = {
        stockWarehouseId: location?.stockWarehouseId,
        warehouseName: location?.label,
      };

      workstationArray.push(object);

      setWorkStationArrayData(workstationArray);
    });
    setWorkStationId(stockWarehouseId);

  }, []);

  useEffect(() => {
    if (stockWarehouseData?.length) {
      const stockWarehouses = stockWarehouseData
        ?.find((item) => item.productId === productId)
        ?.stockWarehouses?.map((item) => {
          return item;
        })
      if (stockWarehouses?.length) {
        const sortedStockWarehouses = stockWarehouses.sort((a, b) => b.unitQtyAvailable - a.unitQtyAvailable);
        setQtyInStock(sortedStockWarehouses.slice(0, 3));
      }
    }
  }, [stockWarehouseData]);

  const ProductCountOnLocation = stockWarehouseData?.length
    ? stockWarehouseData
      ?.find((item) => item.productId === productId)
      ?.stockWarehouses?.map((item) => {
        return item;
      })
    : [];

  const productDetail = {
    thumbImg: imgDocs ? imgDocs?.thumbImg : defaultIcon,
    productName: productName,
    manufacturerCode: manufacturerCode
  }

  const workstationChangeEvent = (e: ChangeEvent<HTMLSelectElement>) => {
    setWorkStationId(e.target.value);
    minMaxApiCall(e.target.value)
  };

  const minMaxApiCall = (id) => {
    dispatch(getProductsStockMinMaxStart())
    const payloadForStockMinMax = { stockWarehouseID: id, productID: Number(params?.id) }
    getProductMinMaxStockMutation.mutate(payloadForStockMinMax);
  }

  const addToBatchCall = () => {
    const minMaxBatchData = getAddToBatchProduct()
    //Store product detail in session
    const currentTimeStemp = new Date().getTime()
    const payloadForStockMinMax = { productId: Number(params?.id), timeStamp: currentTimeStemp }
    if (minMaxBatchData?.length) {
      const updatedArray = [...minMaxBatchData];
      const existingIndex = updatedArray.findIndex(item => item.productId === Number(params?.id));

      if (existingIndex !== -1) {
        const existingIndexData = updatedArray.filter(item => item.productId === Number(params?.id));
        if (existingIndexData[0]?.timeStamp) {

          const timeDifference = currentTimeStemp - parseInt(existingIndexData[0].timeStamp, 10);

          if (timeDifference > 300000) { // 5 minutes in milliseconds
            // Update the timeStamp
            updatedArray[existingIndex] = payloadForStockMinMax;
            callApiMinMaxBatch()
          } else {
            const remainingMinutes = Math.floor((300000 - timeDifference) / 60000);
            setIsSnackbarVisibleInvitation(true)
            if (!remainingMinutes) {
              setSnackbarMessage(tryBitLater)
            } else {
              setSnackbarMessage(`${tryAgain} ${remainingMinutes} ${minutes}`)
            }
          }
        } else {
          updatedArray.push(payloadForStockMinMax);
          callApiMinMaxBatch()
        }

      } else {
        updatedArray.push(payloadForStockMinMax);
        callApiMinMaxBatch()
      }
      saveAddToBatchProduct(updatedArray);

    } else {
      saveAddToBatchProduct([payloadForStockMinMax]);
      callApiMinMaxBatch()
    }

  }

  const callApiMinMaxBatch = () => {
    //Add to batch api call
    dispatch(addMinMaxBatchProductStart())
    const payloadForStockMinMax = { stockWarehouseId: workStationId, productId: Number(params?.id), isMfr: true, companyId: manufacturerId }
    addMinMaxBatchsProductsMutation.mutate(payloadForStockMinMax);
  }

  return (
    <>
      <section className="product-report-section">
        <Grid container className="product-report-container">
          {showSection("qtyInStock", qtyinStock) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='qtyInStock' label={qtyinStock} value={qtyInStock?.length} sectionName={detailsSection} />
              <Grid item xs={12} sm={8}>
                {/* start */}
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    className="product-detail-location-stock-title ps-0"
                  >
                    <span>{WAREHOUSE}</span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    className="product-detail-location-stock-title"
                  >
                    <span>{AvlQty}</span>
                  </Grid>
                </Grid>
                {/* end */}
                {/* start */}
                {
                  qtyInStock?.length ?
                    qtyInStock.map((stockData) => {
                      const { stockWarehouseName, unitQtyAvailable } = stockData;
                      return (
                        <Grid container className="product-detail-location-stock-info" key={stockWarehouseName}>
                          <Grid item xs={12} sm={4} className="ps-0">
                            <span>{stockWarehouseName}</span>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <span>{unitQtyAvailable}</span>
                          </Grid>
                        </Grid>
                      )
                    })
                    : null
                }
                {/* end */}
                {ProductCountOnLocation?.length > 3 ? <div>
                  <PosButton
                    variant="contained"
                    className="btn product-detail-medium-btn"
                    onClick={() => { setAllStockModal(true) }}
                  >
                    {SeeallLocations}
                  </PosButton>
                </div> : null}
              </Grid>
            </Grid>
          ) : null}
          {showSection("StockMin/Max", StockMinMax) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='StockMin/Max' label={StockMinMax} value={warehouseName} sectionName={detailsSection} />
              <Grid item xs={12} sm={8}>
                <div className="stock-info-dropdown">
                  <PosSelect
                    variant="outlined"
                    id="workstations"
                    name="select workstation"
                    value={workStationId}
                    onChange={workstationChangeEvent}
                    options={
                      workStationArrayData?.length &&
                      workStationArrayData.map(
                        ({
                          warehouseName,
                          stockWarehouseId,
                        }: WarehouseDataProps) => {
                          return {
                            label: warehouseName,
                            value: stockWarehouseId,
                          };
                        }
                      )
                    }
                  />
                </div>
                <div className="mt-2 d-flex span-gap">
                  <span className="mr-10">{min} {minLevel || 0}</span>
                  <span className="mr-10">{Max} {maxLevel || 0}</span>
                  <span>{Inc} {increment || 0}</span>
                </div>
                <div className="mt-2">
                  <PosButton
                    variant="contained"
                    className={`btn product-detail-medium-btn ${isAddMinMaxBatchProduct ?
                      "button-accent button-is-loading-black" : ""}`}
                    onClick={addToBatchCall}
                    disabled={isAddMinMaxBatchProduct}
                  >
                    {addToBatch}
                  </PosButton>
                </div>
              </Grid>
              <CommonEditButton onClick={handleMeasurementsSection(StockMinMax)} />
            </Grid>
          ) : null}
          {showSection("priceOUM", priceUOM) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='priceOUM' label={priceUOM} value={priceMU} sectionName={detailsSection} />
              <CommonGridSection value={priceMU && muName} displayValue={`${muName} (${priceMU})`} />
              <CommonEditButton onClick={handleMeasurementsSection(priceUOM)} />
            </Grid>
          ) : null}
          {showSection("itemOUM", itemOUM) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='itemOUM' label={itemOUM} value={itemMU} sectionName={detailsSection} />
              <CommonGridSection value={itemMU && itemMuName} displayValue={`${itemMuName} (${itemMU})`} />
            </Grid>
          ) : null}
          {showSection("unitPerCase", unitPerCases) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='unitPerCase' label={unitPerCases} value={unitPerCase} sectionName={detailsSection} />
              <CommonGridSection value={unitPerCase} />
              <CommonEditButton onClick={handleMeasurementsSection(unitPerCases)} />
            </Grid>
          ) : null}
          {showSection("minimumToOrder", minimumToOrder) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='unitPerCase' label={minimumToOrder} value={minimumCases} sectionName={detailsSection} />
              <CommonGridSection value={minimumCases} />
              <CommonEditButton onClick={handleMeasurementsSection(minimumToOrder)} />
            </Grid>
          ) : null}
          {showSection("Weight", Weight) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='Weight' label={Weight} value={weight} sectionName={detailsSection} />
              <CommonGridSection value={weight} />
              <CommonEditButton onClick={handleMeasurementsSection(Weight)} />
            </Grid>
          ) : null}
        </Grid>
      </section>
      <CommonModel
        openModal={allStockModal}
        closeModal={() => {
          setAllStockModal(false);
        }}
        dialogClassName="available-stock-dialog-box"
      >
        <AvailableAllStock
          ProductCountOnLocation={ProductCountOnLocation}
          setAllStockModal={setAllStockModal}
          warehouseDetails={stockWarehouseData}
          productIdInfo={Number(productId)}
          hideAdjustStock
          productDetail={productDetail}
        />
      </CommonModel>
      <PosSnackbar
        open={isSnackbarVisibleInvitation}
        message={snakbarmessage}
        onClose={() => {
          setIsSnackbarVisibleInvitation(false);
        }}
      />
    </>
  );
};

export default ProductDetailsSection;
