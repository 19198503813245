import "@fontsource/inter";

import {
    PosHelmet,
    Container,
} from "apps/pos/Common/uiCore";

import { getprivacySettingsIconHeight } from "utils/helper";
import CustomerDetails from "./pages/CustomerDetails";

import "./cusomer.scss";
import "./poscommonstyling.scss";

const Customer = () => {
    const orderLayout = document.querySelector("#order-layout");
    if (orderLayout) {
        orderLayout["style"].marginTop = `${getprivacySettingsIconHeight()}px`;
    }

    return (
        <>
            <PosHelmet title="POS" metaName="Customer page" content="Customer page" />
            <Container className="new-container large-screen-container-styling">
                <div className="customer-info-page">
                <CustomerDetails isNewOrder />
                </div>
            </Container>
        </>
    );
};

export default Customer;
