import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Button,
  Input,
  InputOptional,
  MultilineTextArea,
} from "../../../components";
import { companyDetailsRequest } from "../../../sagas/setting/actions";

import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import "../../../styles/common.css";
import { RootState } from "../../../models";
import { companyDetails } from "../../../models/setting";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const CompanySchema = Yup.object().shape({
  companyName: Yup.string()
    .required("Comapny name required.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for company name"),
  primaryFunction: Yup.string(),
  headline: Yup.string().required("Headline required."),
  description: Yup.string().required("Description required."),
  disclaimer: Yup.string().required("Disclaimer required."),
});

export function Company(props) {
  const { tenantPlan,distributorId } = props;
  const dispatch = useDispatch();

  const [companyData, setCompanyData] = useState<companyDetails>();

  const { companyDetails } = useSelector<RootState, any>(
    (state) => state.setting
  );

  useEffect(() => {
    dispatch(companyDetailsRequest(distributorId));
  }, []);

  useEffect(() => {
    setCompanyData(companyDetails);
  }, [companyDetails]);



  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Company</div>
        <div className="devider2px" />

        <Formik
          initialValues={{
            companyName: companyData?.longName ? companyData?.longName : "",
            shortName: companyData?.shortName ? companyData?.shortName : "",
            primaryFunction: companyData?.primaryFunction
              ? companyData?.primaryFunction
              : "",
            headline: companyData?.headline ? companyData?.headline : "",
            description: companyData?.description
              ? companyData?.description
              : "",
            businessHours: companyData?.businessHoursText
              ? companyData?.businessHoursText
              : "",
            disclaimer: companyData?.disclaimer ? companyData?.disclaimer : "",
          }}
          validationSchema={CompanySchema}
          enableReinitialize
          onSubmit={() => {
            // Required Later
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => {
            return (
              <form className="formContainer" id="addCompanyForm">
                <Input
                  id={"companyName"}
                  name={"companyName"}
                  label={"Company name"}
                  onChange={handleChange}
                  value={values.companyName}
                  type={"text"}
                  placeholder={"ex: Apple Computers Limited"}
                  textInputStyle="mt-1"
                />
                {errors.companyName && touched.companyName ? (
                  <p className="validation-error-text">{errors.companyName}</p>
                ) : null}
                <Input
                  textInputStyle="mt-1 mb-0"
                  disabled={true}
                  id={"addShortName"}
                  name={"addShortName"}
                  onChange={handleChange}
                  value={values.shortName}
                  label={"Short name"}
                  type={"text"}
                  placeholder={"AppleComputers"}
                />

                <InputOptional
                  textInputStyle="mt-1"
                  id={"primaryFunction"}
                  name={"primaryFunction"}
                  onChange={handleChange}
                  value={values.primaryFunction}
                  label={"Primary Function"}
                  type={"text"}
                  placeholder={"Ex. Apple Computers is technology company"}
                  className='setting-input-styling'
                />
                <div className="decription">
                  Describe what your company does
                </div>
                <MultilineTextArea
                  textInputStyle="input-style mt-1"
                  id={"headline"}
                  name={"headline"}
                  label={"Headline"}
                  onChange={handleChange}
                  value={values.headline}
                  placeholder={"ex: Apple Computers Ltd"}
                />
                {errors.headline && touched.headline ? (
                  <p className="validation-error-text">{errors.headline}</p>
                ) : null}

                {tenantPlan === "FULL" && (
                  <>
                    <MultilineTextArea
                      textInputStyle="input-style mt-1"
                      id={"description"}
                      name={"description"}
                      label={"Description"}
                      onChange={handleChange}
                      value={values.description}
                      placeholder={""}
                    />
                    {errors.description && touched.description ? (
                      <p className="validation-error-text">
                        {errors.description}
                      </p>
                    ) : null}
                    <InputOptional
                      textInputStyle="mt-1"
                      id={"businessHours"}
                      name={"businessHours"}
                      onChange={handleChange}
                      value={values.businessHours}
                      label={"Business hours"}
                      type={"text"}
                      placeholder={""}
                    />
                  </>
                )}
                <MultilineTextArea
                  textInputStyle="input-style mt-1"
                  id={"disclaimer"}
                  name={"disclaimer"}
                  label={"Disclaimer"}
                  onChange={handleChange}
                  value={values.disclaimer}
                  placeholder={""}
                />
                {errors.disclaimer && touched.disclaimer ? (
                  <p className="validation-error-text">{errors.disclaimer}</p>
                ) : null}
                <div className="devider1px" />
                <Button
                  type={"submit"}
                  onClick={handleSubmit}
                  className="manage-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#jumper_modal"
                >
                  <span className="manage-button-txt">save changes</span>
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </PerfectScrollbar>
  );
}
