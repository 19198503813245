import printJS from 'print-js';
import { authorizationToken } from './utils';

export const keys = {
  backspace: 8,
  tab: 9,
  enter: 13,
  shift: 16,
  ctrl: 17,
  alt: 18,
  pausebreak: 19,
  capslock: 20,
  esc: 27,
  space: 32,
  pageup: 33,
  pagedown: 34,
  end: 35,
  home: 36,
  leftarrow: 37,
  uparrow: 38,
  rightarrow: 39,
  downarrow: 40,
  insert: 45,
  delete: 46,
  numKey0: 48,
  numKey1: 49,
  numKey2: 50,
  numKey3: 51,
  numKey4: 52,
  numKey5: 53,
  numKey6: 54,
  numKey7: 55,
  numKey8: 56,
  numKey9: 57,
  a: 65,
  b: 66,
  c: 67,
  d: 68,
  e: 69,
  f: 70,
  g: 71,
  h: 72,
  i: 73,
  j: 74,
  k: 75,
  l: 76,
  m: 77,
  n: 78,
  o: 79,
  p: 80,
  q: 81,
  r: 82,
  s: 83,
  t: 84,
  u: 85,
  v: 86,
  w: 87,
  x: 88,
  y: 89,
  z: 90,
  leftwindowkey: 91,
  rightwindowkey: 92,
  selectkey: 93,
  numpad0: 96,
  numpad1: 97,
  numpad2: 98,
  numpad3: 99,
  numpad4: 100,
  numpad5: 101,
  numpad6: 102,
  numpad7: 103,
  numpad8: 104,
  numpad9: 105,
  multiply: 106,
  add: 107,
  subtract: 109,
  decimalpoint: 110,
  divide: 111,
  f1: 112,
  f2: 113,
  f3: 114,
  f4: 115,
  f5: 116,
  f6: 117,
  f7: 118,
  f8: 119,
  f9: 120,
  f10: 121,
  f11: 122,
  f12: 123,
  numlock: 144,
  scrolllock: 145,
  semicolon: 186,
  equalsign: 187,
  comma: 188,
  dash: 189,
  period: 190,
  forwardslash: 191,
  graveaccent: 192,
  openbracket: 219,
  backslash: 220,
  closebracket: 221,
  singlequote: 222,
  one:"1",
  two:"2",
  three:"3",
  four:"4",
  D:"D",
  T:"T"
};

export const getprivacySettingsIconHeight = () => {
  const privacySettingsIconElement = document?.querySelector(".privacy-settings-icon")
  return privacySettingsIconElement?.clientHeight || 0;
}

export const setLocalStorageValue = (value) => {
  localStorage.setItem("sidenavState", value);
}

export const setBookMarkImage = (linkRoute) => {
  return `https://www.google.com/s2/favicons?domain=${linkRoute}&amp;sz=24`
}

export type Anchor = "top" | "left" | "bottom" | "right";

export const saveRecentSearches = (value) => {
  sessionStorage.setItem("recentSearches", JSON.stringify(value));
}

export const getRecentSearches = () => {
  return sessionStorage.getItem("recentSearches")
}

export const getContentById = () => {
  const contentSec = document.getElementById("shippingScrollStyling")
  return contentSec;
}

export const  descendingComparator = (asc, calories, orderBy) => {
  if (calories[orderBy] < asc[orderBy]) {
    return -1;
  }
  if (calories[orderBy] > asc[orderBy]) {
    return 1;
  }
  return 0;
}

export const  getComparator = (order, orderBy) => {
return order === "desc"
  ? (asc, calories) => descendingComparator(asc, calories, orderBy)
  : (asc, calories) => -descendingComparator(asc, calories, orderBy);
}

export const searchProductsCount = (searchProducts) => {
  return searchProducts.length > 1 ? "s" : ""
}

function base64toBlob(base64Data: string) {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  
  return new Blob(byteArrays, { type: "application/pdf" });
}

export function openAndPrintPdf(base64Pdf: string,redirect= ""): void {
  if (!base64Pdf) return;
  const blob = base64toBlob(base64Pdf);

  const nav = window.navigator as any;
  if (nav && nav?.msSaveOrOpenBlob) {
    nav.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
  } else {
    const blobUrl = URL.createObjectURL(blob);
    // printJS({printable:base64Pdf, type:'pdf', base64:true})
    const win = window.open(blobUrl,redirect);
    if(!win) return;
    win.onload = () => {
      win?.print();
    };
  }
}

export function printPdf(url: string): void {
  if (!url) return;
  printJS(url, "pdf");
}

export function beforeUnloadCart(items:any):void{
  const decodedData = authorizationToken("Authorization");
  if(decodedData){
    window.onbeforeunload = function (e) {
      e = e || window.event;
      if (items.length > 0) {
        if (e) {
            e.returnValue = 'Sure?';
          }
          return 'Sure?';
        }
      };
  }
      
}

export const renamePersonData = (posCustCode?: number | string, companyName?: string, customerId?: number) => {
  if (posCustCode && companyName) {
    return `(${posCustCode}) ${companyName}`;
  }
  else if (posCustCode) {
    return posCustCode;
  }
  else if (companyName) {
    return companyName;
  }
  else {
    return customerId;
  }
}

export const saveAddToBatchProduct = (value) => {
  localStorage.setItem("addtoBatchProject", JSON.stringify(value));
}

export const getAddToBatchProduct = () => {
  return JSON.parse(localStorage.getItem('addtoBatchProject')) || [] ;
}