import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "./queryKeys";
import { getDocumentTypesData } from "../request";

export const useGetDocumentType = () => {
  const { data } = useQuery([QueryKeys.GET_DOCUMENT_TYPE], async () => {
    return getDocumentTypesData();
  });
  return { DocumentTypeDetails: data || [] };
};
