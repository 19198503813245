import { useEffect } from "react";

import { ModelNameLabel } from "enums";
import { setBookMarkImage } from "utils/helper";

const ManageBookmark = (props) => {
    const { favoritesList, changeModel, closeAllDialogs, setDeleteBookmarkId , setEditBookmarkId } = props
    useEffect(()=>{
        setEditBookmarkId("")
    },[])
    const { add , edit } = ModelNameLabel

    return (
        <>
            <header>
                <div className="header-wrapper">
                    <p className="dialog-title">Manage Bookmarks</p>

                    <button className="icon-btn dialog-close-btn" type="button" onClick={closeAllDialogs}>
                        <span>Close</span>
                    </button>
                </div>

                <div className="manage-bookmarks-controls-group">
                    <p><span className="count">{favoritesList?.length || 0} </span>saved</p>

                    <div className="actions">
                       { favoritesList?.length ? <button className="delete-all-bookmarks-btn" onClick={() => {
                           setDeleteBookmarkId('all')
                        }}>Delete all</button> :  null }
                        <button className="add-bookmark-btn" onClick={() => { changeModel(add) }}>Add Bookmark</button>
                    </div>
                </div>
            </header>

            <ul className="manage-bookmarks-list">
                {favoritesList?.length ?
                    favoritesList?.map((favorite, i) => (
                        <a className="manage-bookmarks-list-item" data-bookmark-id={favorite?.bookmarkID}
                            href={favorite?.linkRoute}
                            key={`favorite-${i}-${favorite?.bookmarkID}`}
                            target={"_"+favorite?.target?.toLowerCase()}
                        >
                            <div className="link">
                                <div className="icon">
                                    <img src={setBookMarkImage(favorite?.linkRoute)} alt="Link" draggable="false" loading="eager" />
                                </div>
                                <div className="details">
                                    <p className="title">{favorite?.linkName}</p>
                                    <span className="url">{favorite?.linkRoute}</span>
                                </div>
                            </div>
                            <div className="actions">
                                <button className="icon-btn edit-btn" title="Edit" type="button" onClick={(e) => {
                                    e.preventDefault();
                                    setEditBookmarkId(favorite?.bookmarkID)
                                    changeModel(edit)
                                }}>
                                    <span>Edit</span>
                                </button>
                                <button className="icon-btn delete-btn" title="Delete" type="button" onClick={
                                    (e) => {
                                        e.preventDefault();
                                        setDeleteBookmarkId(favorite?.bookmarkID)
                                    }
                                }>
                                    <span>Delete</span>
                                </button>
                            </div>
                        </a>
                    )) : null
                }
            </ul>
        </>
    )
}
export default ManageBookmark;