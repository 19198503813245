import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import dayjs, { Dayjs, ManipulateType } from "dayjs";
import { Grid } from "@material-ui/core";

import { ButtonContainer } from "components";
import Button from "components/SPButton/Button";
import { PosDatePicker, PosModel } from "apps/pos/Common/uiCore";
import { IconButton } from "apps/springboard/Component/SBItems/elements";
import { useEditDueDate } from "services/quoteDetail/hooks/useEditDueDate";

import { dateEnum } from "constants/staticData";
import { ExtendByData, byDays } from "utils/data";
import { dateType, springBoardText } from "constants/constant";
import { extendDays, getDateWithAddDays, setDateInYearFormat, setDateWithCommaFormat } from "utils/dayjsFunc";

import { UpdateModalContent } from "sagas/modal/actions";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import { useUpdateCloseDate } from "services/quoteDetail/hooks/useUpdateCloseDate";

interface DueDateFormData {
    dueDate: string;
}

interface DueDateFormProps {
    open: boolean;
    jobQuoteID: number;
    creatorId: number;
    currentDueDate: string;
    salespersonName?: string;
    handleCloseModel: () => void;
    handleChangeSuccess?: (string) =>void;
    modalType?: string;
    header?: string;
    fieldTitle?: string;
    revisedDueDate?: string;
    showEditOption?: boolean;
}

export const DueDateForm = (props: DueDateFormProps) => {
    const dispatch = useDispatch();
    const {
        jobQuoteID,
        creatorId,
        currentDueDate,
        open,
        handleCloseModel,
        handleChangeSuccess,
        modalType,
        header,
        fieldTitle,
        revisedDueDate,
        showEditOption,
    } = props;
    const {
      dueDateLabel,
      revise,
      dueDateChanged,
      closeDateChanged,
      extendBy: extendByLabel,
      change,
      cancel,
      zero,
      one,
      two,
      revisedDueDateLabel,
      notSet,
    } = springBoardText;
    const [extendByFlag, setExtendByFlag] = useState(null);
    const getDate = getDateWithAddDays({daysToAdd: one, period: byDays.days})
    const [selectedDate, setSelectedDate] = useState<Dayjs>(getDate);
    const [isLoading, setIsLoading] = useState(false)
    const [valueChanged, setValueChanged] = useState(false)
    const {editDueDateFn, isSuccess: dueDateSuccess } = useEditDueDate()
    const {setCloseDateParams, isCloseDateUpdateSuccess } = useUpdateCloseDate()
    const extendByOptions = [2, 1, 2];
    const timeUnits: ManipulateType[] = ["day", "week", "week"];
    const isOpenedDueDate = modalType === dateType.dueDate ? revise : change;

    const { handleSubmit, formState, reset } =
        useForm<DueDateFormData>();

        const calculateDueDate = (currentDate, flag) => {
          switch (flag) {
            case zero:
              return extendDays({
                date: currentDate,
                daysToAdd: two,
                type: byDays.days,
                dateFormat: dateEnum.ymdFormat
             } );
            case one:
              return extendDays({
                date: currentDate,
                daysToAdd: one,
                type: byDays.week,
                dateFormat: dateEnum.ymdFormat
              });
            case two:
              return extendDays({
                date: currentDate,
                daysToAdd: two,
                type: byDays.week,
                dateFormat: dateEnum.ymdFormat
              });
            default:
              return setDateInYearFormat(selectedDate);
          }
        };
      
    const onSubmit = () => {
      setIsLoading(true);
      const dueDate = calculateDueDate(new Date(), extendByFlag);
      const selectedDueDate = setDateInYearFormat(selectedDate);

      switch (modalType) {
        case dateType.dueDate:
          const payload = {
            jobQuoteID,
            revisedDueDate: dueDate || selectedDueDate,
            handlerID: creatorId,
          };
          editDueDateFn(payload);
          break;

        case dateType.closeDate:
          const updateCloseDate = {
            jobQuoteID,
            date: selectedDueDate || currentDueDate,
          };
          setCloseDateParams(updateCloseDate);
          break;
      }
    };

    useEffect(() => {
      if (dueDateSuccess || isCloseDateUpdateSuccess) {
        const showMessage =
          modalType === dateType.dueDate ? dueDateChanged : closeDateChanged;
        dispatch(snakbarShow({ message: showMessage }));
        setIsLoading(false);
        setValueChanged(false);
        reset();
        dispatch(
          UpdateModalContent({
            isOpen: false,
            children: null,
            fixedModal: false,
            removePaddingBottom: false,
            noMaxWidth: false,
          })
        );
        handleCloseModel();
        const setLoadingValue =
          modalType === dateType.dueDate ? dateType.dueDate : dateType.closeDate;
        handleChangeSuccess(setLoadingValue);
      }
    }, [dueDateSuccess, isCloseDateUpdateSuccess]);

    const cancelForm = () => {
        dispatch(
            UpdateModalContent({
                children: null,
                isOpen: false,
                fixedModal: false,
                removePaddingBottom: false,
                noMaxWidth: false,
            })
        );
        handleCloseModel();
    }
  
    const extendDueDate = (index) => {
        setValueChanged(true);
        setExtendByFlag(index);
        setSelectedDate(dayjs().add(extendByOptions[index], timeUnits[index]));
    }

    return (
      <Fragment>
        <PosModel
          open={open}
          onClose={handleCloseModel}
          className={`set-customer-code-modal`}
          dialogClassName={`set-customer-code-modal-container`}
          dialogHeader={
            <div className="modal-header pl-4">
              <span className="modal-title">
                <div className="pt-2">
                  <h3 className="f20-Inter-500-sab mb-0 mb-0">{header}</h3>
                </div>
              </span>
              <IconButton
                name="close"
                className="close-icon"
                type="tertiary"
                onClick={handleCloseModel}
              />
            </div>
          }
        >
          <form className="" id="DueDateForm" onSubmit={handleSubmit(onSubmit)}>
            {showEditOption && (
              <div>
                <div className="set-customer-code-modal-container">
                  <div className="notes-added-user-name">
                    <span>{fieldTitle}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="custom-datePicker storage-date-picker w-100">
                      <PosDatePicker
                        value={selectedDate}
                        onChange={(newValue) => {
                          setSelectedDate(newValue);
                          setValueChanged(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {formState.errors.dueDate && <div>Due date is required</div>}
                <div className="mb-3 d-flex align-items-center justify-content-between extended-week-info">
                  <div
                    className="d-flex align-items-center justify-content-sm-around"
                    style={{ width: "20rem" }}
                  >
                    <span className="extend-by-title">{extendByLabel}</span>
                    {ExtendByData.map((data: string, index: number) => {
                      return (
                        <div
                          key={index}
                          className={`d-flex align-items-center justify-content-center extend ${
                            extendByFlag != null
                              ? extendByFlag === index
                                ? `extend-selected`
                                : `extend-unselected`
                              : ""
                          }`}
                          onClick={() => extendDueDate(index)}
                        >
                          {data}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {modalType === dateType.dueDate && (
              <section className="subquote-report-section">
                <Grid container className="subquote-report-container">
                  <Grid container className="report-div">
                    <Grid item xs={12} sm={7}>
                      <div className="document-types-title">
                        <span>{dueDateLabel}</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <div className="send_rfi_row_new ml-2">
                        <span
                          className={`${
                            currentDueDate ? "text-black" : "light-gray-text"
                          }`}
                        >
                          {currentDueDate
                            ? setDateWithCommaFormat(currentDueDate)
                            : notSet}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="report-div">
                    <Grid item xs={12} sm={7}>
                      <div className="document-types-title">
                        <span>{revisedDueDateLabel}</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <div className="send_rfi_row_new ml-2">
                        <span
                          className={`${
                            revisedDueDate ? "text-black" : "light-gray-text"
                          }`}
                        >
                          {revisedDueDate
                            ? setDateWithCommaFormat(revisedDueDate)
                            : notSet}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </section>
            )}
            <ButtonContainer className={`justify-content-end gap-2 ${showEditOption ? 'mt-5' : 'mt-3'}`}>
              {showEditOption && (
                <div className="d-flex gap-2">
                  <Button
                    label={cancel}
                    disabled={isLoading}
                    onClick={cancelForm}
                  />
                  <Button
                    label={isOpenedDueDate}
                    type="primary"
                    loading={isLoading}
                    disabled={isLoading || !valueChanged}
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
              )}
            </ButtonContainer>
          </form>
        </PosModel>
      </Fragment>
    );
}
