export enum AppToURLPathName {
  Leapfrog = "leapfrog",
  Springboard = "springboard",
  Accounting = "accounting",
  Bouncer = "bouncer",
  HRPortal = "portal",
  Hunter = "tickets",
  Trampoline = "",
  Admin = "admin",
  Blog = "blog",
  PingPong = "pingpong",
  Falcon = "falcon",
  Vendor = "vendor",
  Hopper = "hopper",
  SingleSignOn = "sso",
  Api = "api",
}
