import useAutocomplete from '@mui/material/useAutocomplete';
import { styled } from '@mui/system';

import { SearchIcon } from 'constants/icons';
import { keys } from "utils/helper";

const Input = styled('input')(({ theme }) => ({
    width: 200,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
    color: theme.palette.mode === 'light' ? '#000' : '#fff',
}));

  const Listbox = styled('ul')(({ theme }) => ({
    width: 200,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li.Mui-focused': {
        backgroundColor: '#4a8df6',
        color: 'white',
        cursor: 'pointer',
    },
    '& li:active': {
        backgroundColor: '#2977f5',
        color: 'white',
    },
  }));

interface Props {
    productCharacteristicTypeName: string;
    productCharacteristicTypeId: number;
}

export default function UseAutocomplete({ characteristicList , addNewCharacteristic, placeHolder}) {

    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id:"user-auto-complete-characteristiclist",
        options: characteristicList ? characteristicList : null,
        getOptionLabel: (option: Props) => option.productCharacteristicTypeName,
    });

    const handleClick = (productCharacteristicTypeId: string, productCharacteristicTypeName: string) => () => {
        addNewCharacteristic(productCharacteristicTypeId, productCharacteristicTypeName);
    }

    const {uparrow, downarrow} = keys;
    const CustomListbox = (props) => {
        const handleKeyDown = (event) => {
          if (event.keyCode === uparrow || event.keyCode === downarrow) {
            event.preventDefault();
          }
        };
      
        return (
          <Listbox {...props} onKeyDown={handleKeyDown}>
            {(groupedOptions as typeof characteristicList).map((option, index) => {
              const { productCharacteristicTypeName, productCharacteristicTypeId } = option || {};
              return (
                <li {...getOptionProps({ option, index })} key={index}>
                  <div
                    onClick={handleClick(productCharacteristicTypeId, productCharacteristicTypeName)}
                    className="d-flex justify-content-between align-items-start"
                  >
                    <span className="notes-added-user-name">{productCharacteristicTypeName}</span>
                  </div>
                </li>
              );
            })}
          </Listbox>
        );
    };
    
    return (
      <div className="position-relative mt-2">
        <div {...getRootProps()} className="listgroup-search-input input-focus-styling">
          <SearchIcon />
          <Input {...getInputProps()} placeholder={placeHolder} />
        </div>
        {groupedOptions.length ? (
          <CustomListbox {...getListboxProps()} className="list-group-listbox" />
        ) : null}
      </div>
    );
}