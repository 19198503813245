import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import Button from "apps/admin/components/Elements/Button";

import { adminMessage } from "constants/message";
import { Label, UserAccountStatus } from "enums";
import { LockStatusFormData, LockStatusFormProps, RootState, user } from "models";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import { UpdateModalContent } from "sagas/modal/actions";
import {
  getAllUsers,
  setSearchUserId
} from "sagas/user/actions";
import { setUserLockState } from "services";


export function LockStatusForm({ user, onSuccess, onError, currentPage }: LockStatusFormProps) {
  const dispatch = useDispatch();
  const { handleSubmit, reset, setValue } = useForm<LockStatusFormData>();
  const [isBtnDisable, setIsBtnDisable] = useState(false)

  const { searchUser } = useSelector<
    RootState,
    user
  >((state) => state.user);

  useDeepCompareEffectNoCheck(() => {
    if (user) {
      setValue("userLockState", user.status.type);
    }
  }, [user]);

  const onSubmit = () => {
    setIsBtnDisable(true);
    const statusToSet = user?.status?.type === UserAccountStatus.locked_out ? "unlock" : "lock";
    setUserLockState(statusToSet, user.personId).then((data) => {
      setIsBtnDisable(false)
      if (data?.data) {
        if (searchUser) {
          dispatch(setSearchUserId(user.personId.toString()))
        } else {
          dispatch(getAllUsers(currentPage));
        }
        dispatch(snakbarShow({message : user?.status?.type === UserAccountStatus.locked_out
          ? adminMessage.unlock
          : adminMessage.lock}))
        onSuccess(
          user?.status?.type === UserAccountStatus.locked_out
            ? adminMessage.unlock
            : adminMessage.lock
        );
      } else {
        dispatch(snakbarShow({ message : user?.status?.type === UserAccountStatus.locked_out
          ? adminMessage.failLock
          : adminMessage.failUnlock
      }))
        onError(
          user?.status?.type === UserAccountStatus.locked_out
            ? adminMessage.failLock
            : adminMessage.failUnlock
        );
      }

      reset();
    }).catch(() => {
      setIsBtnDisable(false)
      onError(
        user?.status?.type === UserAccountStatus.locked_out
          ? adminMessage.failLock
          : adminMessage.failUnlock
      );
      dispatch(snakbarShow({message :user?.status?.type === UserAccountStatus.locked_out
        ? adminMessage.failLock
        : adminMessage.failUnlock
    }))
      reset();
    });
  };

  const cancelClick = () => {
    dispatch(
      UpdateModalContent({
        isOpen: false,
        children: true,
        fixedModal: true,
        removePaddingBottom: false,
        noMaxWidth: false
      })
    );
  }
  const { CancelButton, unlockAccount, lockAccount } = Label;
  return (
    <Fragment>
      <span className="pl-4 fs-24 roboto-med black_text">
        {user?.status?.type === UserAccountStatus.locked_out
          ? "Unlock account"
          : "Lock account"}
      </span>

      <div className="mt-2 mb-5">
        {user?.status?.type === UserAccountStatus.locked_out ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="fs-16 inter-reg black_text pr-40">{`Are you sure? This will allow ${user.fullName} to log back into their account.`}</span>
          </div>
        ) : (
          <span className="fs-16 inter-reg black_text pr-40">{`Are you sure? This will prevent ${user.fullName} from logging in to their account until it is unlocked back again. `}</span>
        )}
      </div>
      <div className="edit_cust_btn">
        <div className="cacel_btn">
          <Button
            label={CancelButton}
            onClick={cancelClick}
            secondary={true}
          />
        </div>
        <div>
          <Button
            type="submit"
            label={
              user?.status?.type === UserAccountStatus.locked_out
                ? unlockAccount
                : lockAccount
            }
            accent={true}
            classes={`${isBtnDisable ? 'button-is-loading' : ""}`}
            disabled={isBtnDisable}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </Fragment>
  );
}
