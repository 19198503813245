import serviceV2 from "../config2";

const PRICE_SCHEDULE_API_PATH = "priceschedules";

export function getAllPriceSchedules() {
  return serviceV2.get(`${PRICE_SCHEDULE_API_PATH}`).then((response) => {
    return response.data;
  });
}

export function getSelectedPriceSchedules({ priceScheduleId }) {
  return serviceV2
    .get(`${PRICE_SCHEDULE_API_PATH}/${priceScheduleId}`)
    .then((response) => {
      return response.data;
    });
}

export function getProductPriceSchedule(payload: any) {
  return serviceV2
    .get(
      `${PRICE_SCHEDULE_API_PATH}/${payload.priceScheduleId}/products/${payload.priceScheduleProductId}`
    )
    .then((response) => {
      return response.data;
    });
}

export function addPriceSchedules(body: any) {
  return serviceV2.post(`${PRICE_SCHEDULE_API_PATH}`, body).then((response) => {
    return response.data;
  });
}

export function addAllPriceSchedules(payload) {
  return serviceV2
    .post(
      `${PRICE_SCHEDULE_API_PATH}/${payload.priceScheduleId}/products`,
      payload.body
    )
    .then((response) => {
      return response.data;
    });
}

export function addAllProducts(payload) {
  return serviceV2
    .post(
      `${PRICE_SCHEDULE_API_PATH}/${payload.priceScheduleId}/products/add`,
      payload.body
    )
    .then((response) => {
      return response.data;
    });
}

export function removeProducts(payload) {
  return serviceV2
    .post(
      `${PRICE_SCHEDULE_API_PATH}/${payload.priceScheduleId}/products/remove`,
      payload.body
    )
    .then((response) => {
      return response.data;
    });
}

export function updateProducts(payload) {
  return serviceV2
    .put(
      `${PRICE_SCHEDULE_API_PATH}/${payload.priceScheduleId}/products/update`
    )
    .then((response) => {
      return response.data;
    });
}

export function updateSelectedPriceSchedule(payload) {
  return serviceV2
    .put(`${PRICE_SCHEDULE_API_PATH}/${payload.priceScheduleId}`)
    .then((response) => {
      return response.data;
    });
}

export function updateSelectedProduct(payload) {
  return serviceV2
    .put(
      `${PRICE_SCHEDULE_API_PATH}/${payload.priceScheduleId}/products/${payload.priceScheduleProductId}`
    )
    .then((response) => {
      return response.data;
    });
}

export function deletePriceSchedules(priceScheduleId: number) {
  return serviceV2
    .delete(`${PRICE_SCHEDULE_API_PATH}/${priceScheduleId}`)
    .then((response) => {
      return response?.data;
    });
}

export function deleteProducts(payload: any) {
  return serviceV2
    .delete(
      `${PRICE_SCHEDULE_API_PATH}/${payload.priceScheduleId}/products/${payload.priceScheduleProductId}`
    )
    .then((response) => {
      return response?.data;
    });
}
