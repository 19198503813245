import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { useSelector } from "react-redux";

import { PosButton, PosImage } from "apps/pos/Common/uiCore";
import HandleLongData from "apps/pos/Common/HandleLongData";

import images from "constants/images";
import { MMDDYYYYformat } from "apps/pos/constants";
import { RootState } from "models";
import { Label } from "enums/label";
import { PosFlowDataProp } from "constants/interfaces";

import "apps/pos/storage/putInStorage.scss";
import "./customerStoragePendingDetails.scss";

interface CustomerStoragePendingDetailsProps {
  storageItemInfo?: boolean;
  setOpenDeliveryProofModel?: (boolean) => void;
  pendingData?: any;
  deliveredData?: any;
}

const CustomerStoragePendingDetails = ({
  storageItemInfo,
  setOpenDeliveryProofModel,
  pendingData,
  deliveredData,
}: CustomerStoragePendingDetailsProps) => {

  const {
    posFlowData
  } = useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);

  const {customer} = posFlowData || {}

  const { uploadSigned } = Label;
  
  const {
    handlerName,
    shipMethod,
    trackingNum,
    deliveryDate,
    orderID: deliverOrderId,
    proofOfDeliveryDocName,
    proofOfDeliveryDocURL,
  } = deliveredData || {}
  
  const {
    orderID,
    shipmentID,
    customerInvoiceID,
    inStorageDate,
    estDeliveryDate,
    poNum,
    jobName,
    storageLocation,
    shipmentItemValue,
    shipAddress,
  } = pendingData || {};

  const { addDocument } = images;
  
  // Might need this
  // const deliveryDetails = useSelector(getDeliveryDetails)
  // const {
  //   orderID: deliverOrderId,
  //   shipMethod,
  //   deliveryTime,
  //   trackingNum,
  // } = deliveryDetails || {};

  const createData = (date, orderId, subData, subDatastyle, isDocument) => {
    return {
      date,
      orderId,
      subData,
      subDatastyle,
      isDocument,
    };
  };

  const rows = [
    createData("Order #", orderID, "", "", ""),
    createData("Shipment #", shipmentID, "", "", ""),
    createData("Invoice #", customerInvoiceID, "", "", ""),
    createData("Put-in Date", moment(inStorageDate).format(MMDDYYYYformat), `${moment(inStorageDate).fromNow()}`, "light-gray-text", ""),
    createData(
      "Est. Delivery Date",
      moment(estDeliveryDate).format(MMDDYYYYformat),
      `${moment(estDeliveryDate).fromNow()}`,
      "light-gray-text",
      ""
    ),
    createData("Customer PO", poNum, "", "", ""),
    createData("Job Name", jobName, "", "", ""),
    createData("Location", storageLocation, "", "", ""),
    createData("Value of Remaining Items", `$${shipmentItemValue}`, "", "", ""),
    createData(
      "Ship Address",
      shipAddress,
      "",
      "",
      ""
    ),
  ];

  const deliveredItemsDetails = [
    createData("Handled by", handlerName, "", "", ""),
    createData("Method", shipMethod, "", "", ""),
    createData("Tracking Number", trackingNum, "", "", ""),
    createData("Delivery Date", deliveryDate, "", "light-gray-text", ""),
    createData("Order #", deliverOrderId, "", "", ""),
    createData("Delivery Proof", proofOfDeliveryDocName, `${proofOfDeliveryDocName ? '' : 'Pending'}`, "", "isDocument"),
  ];

  const finalRow = storageItemInfo ? deliveredItemsDetails : rows;

  return (
    <section className="document-main-section">
      <div className="document-container">
      {!proofOfDeliveryDocName && deliveredData && !!customer?.requireProofOfDeliveryFlag ? <div className="d-flex justify-content-between delivery-proof-section align-items-center flex-wrap">
            <div>
              <span className="font-18">
                {uploadSigned}
              </span>
            </div>
            <div>
              <PosButton
                className="btn storage-upload-btn btn"
                variant="contained"
                color="primary"
                startIcon={<PosImage src={addDocument} alt="add-doc-icon" />}
                onClick={() => setOpenDeliveryProofModel(true)}
              >
                Upload
              </PosButton>
            </div>
          </div> : <></>}
        <div>
          <TableContainer
            component={Paper}
            className="customer-info-table-container"
          >
            <Table aria-label="simple table" className="detail-half-table">
              <TableBody>
                {finalRow.map((row) => {
                  const { date, orderId, subData, subDatastyle, isDocument } =
                    row;
                  return (
                    <TableRow
                      className=""
                      key={date}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className="customer-table-row-data-styling d-flex justify-content-start white-space-no-wrap"
                        width="50%"
                      >
                        <span>{date}</span>
                      </TableCell>
                      <TableCell
                        className="customer-table-row-data-styling"
                        width="50%"
                      >
                        <div>
                          <div>
                            {date == 'Job Name' ?
                                <HandleLongData jobName={orderId} />
                                : 
                                <span>{orderId}</span>
                              }
                          </div>
                          <div className={`${orderId ? 'd-flex' : ''}`}>
                            <div className={`${subDatastyle} fs-14`}>
                              {subData}
                            </div>
                            {isDocument ? (
                              <>
                                {
                                  orderId ? (
                                    <>
                                    <div className="change-btn-padding">
                                    <PosButton
                                      className="btn mt-2 change-doc-btn"
                                      variant="contained"
                                      onClick={() => setOpenDeliveryProofModel(true)}
                                    >
                                      Change
                                    </PosButton>
                                    </div>
                                    <PosButton
                                      className="btn mt-2 view-doc-btn"
                                      variant="contained"
                                      onClick={() => {
                                        window.open(proofOfDeliveryDocURL, '_blank','noreferrer')
                                      }}
                                    >
                                      View
                                    </PosButton>
                                    </>
                                  )
                                    :
                                    <PosButton
                                      className="btn mt-2 black-btn storage-upload-btn"
                                      variant="contained"
                                      color="primary"
                                      startIcon={<PosImage src={addDocument} alt="add-doc-icon" />}
                                      onClick={() => setOpenDeliveryProofModel(true)}
                                    >
                                      Upload
                                    </PosButton>
                                }
                              
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </section>
  );
};

export default CustomerStoragePendingDetails;
