import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from 'apps/admin/components/Elements/IconButton';
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import { ManageUserModal } from "apps/admin/ManageUserForm/ManageUserForm";
import { AuthenticatedUser, RootState, User } from "models";
import NewPasswordModal from "./NewPasswordModal";

import {
  resetPassword,
  resetPasswordRequsetEnd
} from "sagas/authenticatedUser/actions";
import { UpdateModalContent } from "sagas/modal/actions";

import "App.scss";
import "./ChangePassword.scss";
import { supportLink } from "constants/staticData";
interface ChangePasswordProps {
  user: User;
  onSuccess: (text) => void;
  loginUser: User;
  isUser: boolean;
}

function ChangePasswordModal({ user, loginUser, isUser }: ChangePasswordProps) {
  const dispatch = useDispatch();
  const { setNewPasswordSave, isLoading } = useSelector<
    RootState,
    AuthenticatedUser
  >((state) => state.authenticatedUser);
  const handleNewPassword = () => {
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        navigator: false,
        classModal:"change-password-dialog",
        children: (
          <NewPasswordModal
            isUser={isUser}
            loginUser={loginUser}
            user={user}
            onSuccess={() => {
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: true,
                  removePaddingBottom: true,
                  noMaxWidth: false
                })
              );
            }}
          />
        ),
        fixedModal: true,
        removePaddingBottom: true,
        noMaxWidth: false
      })
    );
  };
  const handleSendResetLink = () => {
    dispatch(resetPassword({ usernameOrEmail: user?.email }));
  };
  const backModal = () => {
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        classModal:"user-profile-dialog",
        children: (
          <ManageUserModal user={user} loginUser={loginUser} isApproval={false} currentTab={2} isUser={isUser} />
        ),
        fixedModal: true,
        removePaddingBottom: false,
        noMaxWidth: false
      })
    );
  };
  useEffect(() => {
    if (isLoading === false && setNewPasswordSave) {
      dispatch(
        UpdateModalContent({
          isOpen: false,
          children: null,
          fixedModal: true,
          removePaddingBottom: false,
          noMaxWidth: false
        })
      );
      dispatch(resetPasswordRequsetEnd());
    }
  }, [setNewPasswordSave]);

  return (
    <div className="password-options-dialog">
      <div className="dialog-header">
        <IconButton
          icon="arrow_back"
          title="Back"
          secondary={true}
          onClick={backModal}
        />
        <h3 className="dialog-title">
          Change Password
        </h3>
      </div>
      <div className="password-options">
        <PasswordOptionButton
          icon="password"
          label="Set new password"
          onClick={handleNewPassword}
        />
        {!setNewPasswordSave ? (
          <PasswordOptionButton
            icon="forward_to_inbox"
            label="Send Reset Link"
            description={`A password reset link will be sent to ${user?.email}`}
            onClick={handleSendResetLink}
          />
        ) : (
          <PasswordOptionButton
            icon="loading"
            label="Send Reset Link"
            description={`A password reset link will be sent to ${user?.email}`}
          />
        )}
        <PasswordOptionButton
          icon="groups"
          label="Need help? Contact Support"
          onClick={() =>
            window.open(
              supportLink,
              "_blank"
            )
          }
        />
      </div>
    </div>
  );
}

export default ChangePasswordModal;

const PasswordOptionButton = (props) => {
  const { icon, label, description, onClick, disabled } = props;
  return (
    <button className={`password-option-button ${description ? 'with-description' : ''}`}
      onClick={onClick}
      disabled={disabled}>

      <MaterialIcon icon={icon} size="20" />

      <div className="label-group">
        <span className="password-option-label">
          {label}
        </span>
        {description ? (
          <span className="password-option-description">
            {description}
          </span>
        ) : null}
      </div>

      <MaterialIcon icon="chevron_right" classes="arrow-icon" />

    </button>
  )
}