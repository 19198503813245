import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IProductManufacturers } from "../types";



const initialState: IProductManufacturers = {
  isProductManufacturersSearch: false,
  getProductManufacturersData: null,
  getProductManufacturersDataError: null
};

export const productManufacturersSlice = createSlice({
  name: "productManufacturersSlice",
  initialState,
  reducers: {
    getProductManufacturersStart: (state) => {
      state.getProductManufacturersData = null;
      state.isProductManufacturersSearch = true;
    },
    getProductManufacturersSuccess: (state, action: PayloadAction<any>) => { // Will remove 'any' once we get proper data from BE
      state.getProductManufacturersData = action.payload;
      state.isProductManufacturersSearch = false;
    },
    getProductManufacturersError: (state, action: PayloadAction<any>) => {  // Will remove 'any' once we get proper data from BE
      state.getProductManufacturersDataError = action.payload;
      state.getProductManufacturersData = null;
      state.isProductManufacturersSearch = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
    getProductManufacturersStart,
    getProductManufacturersSuccess,
    getProductManufacturersError
} = productManufacturersSlice.actions;

export default productManufacturersSlice.reducer;
