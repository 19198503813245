import { PosButton } from "apps/pos/Common/uiCore";
import { labels } from "apps/product/modules/productDetailsContainer/constants";

const CommonEditButton = ({ onClick }) => {
  const { edit } = labels
  return (
    <div className="edit-hover-btn">
      <PosButton
        variant="contained"
        className="btn-discard btn font-size title-edit-section"
        onClick={onClick}
      >
        {edit}
      </PosButton>
    </div>
  )
}

export default CommonEditButton