import { displayDateFormatValue, setDateWithFormat } from "utils/utils";

const NoteItemFollowUp = (props) => {
  const { note } = props;
  const { posterID, posterName, notes: message, postTime } = note;

  return (
    <li id={"note" + posterID} className="note-item-sb">
      <div className="note-metadata">
        <p className="note-author">{posterName}</p>
        <p>{setDateWithFormat(
                postTime,
                displayDateFormatValue.monthAndYearTimeFormat
              )}</p>
      </div>
      <p className="note-text">{message}</p>
    </li>
  );
};

export default NoteItemFollowUp;
