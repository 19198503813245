import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  PosButton,
  PosIconButton,
  PosImage,
  PosInput,
  PosModel,
  PosSelect,
} from "apps/pos/Common/uiCore";
import DeleteDocumentModal from "apps/pos/pages/customerInfo/Documents/deleteDocument/deleteDocumentModal";
import { Button } from "components";

import { UpdatedDocumentSchema } from "apps/pos/formik/validationSchema";
import { documentByModule } from "constants/enum";
import { ClearIcon } from "constants/icons";
import images from "constants/images";
import { PosFlowDataProp } from "constants/interfaces";
import { PosResponseProps, RootState } from "models";

import { setPosFlowData } from "sagas/persistReducer/actions";

import "apps/pos/pages/customerInfo/Documents/openEditDocument/openEditDocumentModal.scss";

const OpenEditDocumentModal = ({
  openEditModel,
  setOpenEditModel,
  deleteDocumentData,
  setDeleteDocumentData,
  keyIndex,
  removeDocumentItem,
  fileData,
  parentCallback,
  fileName,
  taxExemption,
  hideDeleteConfirm = false
}) => {
  const [documentTypes, setDocumentTypes] = useState([]);
  const { documentTypesDetails } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { item } = fileData || {};
  const { description, documentSaveTypeName, documentSaveType } = item || {};

  const isGenericUser = useMemo(() => {
    return posFlowData?.customer ? posFlowData?.customer.isPosGeneric : false;
  }, [posFlowData]);

  const { privateDoc } = documentByModule;
  const dispatch = useDispatch();

  useEffect(() => {
    const privateDocument = documentTypesDetails?.length
      ? documentTypesDetails.filter((v) => v.documentSaveType === privateDoc)
      : [];
    if (isGenericUser) {
      setDocumentTypes(privateDocument);
    } else {
      setDocumentTypes(documentTypesDetails);
    }
  }, [documentTypesDetails, documentSaveType]);

  const handleDeleteClick = () => {
    if (hideDeleteConfirm) {
      removeDocumentItem(keyIndex);
    } else {
      setOpenEditModel(false);
      setDeleteDocumentData(true);
    }
  }

  const { orangeDeleteIcon, blackCloseIcon, rightWhiteIcon } = images;
  return (
    <>
      <PosModel
        dialogClassName="edit-document-info-modal"
        open={openEditModel}
        onClose={() => {
          setOpenEditModel(false);
        }}
      >
        <div className="edit-document-info-section">
          <PosIconButton
            className="close-modal-icon  button-focus"
            onClick={() => setOpenEditModel(false)}
          >
            <ClearIcon />
          </PosIconButton>
        </div>
        <div className="edit-document-container">
          <div className="edit-document-title-section">
            <div className="edit-document-small-title">
              <div className="small-title-modal">Edit Document details</div>
            </div>
            <div className="edit-document-title">{fileName}</div>
          </div>
          <Formik
            initialValues={{
              description: description ?? "",
              documentSaveTypeName: documentSaveTypeName ?? "",
            }}
            validationSchema={UpdatedDocumentSchema}
            enableReinitialize
            onSubmit={(values) => {
              parentCallback(values);
              const documentAddedData = posFlowData?.addDocument?.filter(
                (data) =>
                  new Date(data?.modifiedAt).getTime().toString() === keyIndex
              )[0];
              const newDocumentData = values;
              const dataKeys = Object.keys(newDocumentData);
              dataKeys?.map((data) => {
                documentAddedData[data] = newDocumentData?.[data];
              });
              const data = {
                ...posFlowData,
                documentAddedData,
              };
              dispatch(setPosFlowData(data));
              setOpenEditModel(false);
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => {
              const { documentSaveTypeName, description } = values;

              return (
                <>
                  <div className="edit-document-type-section arrow-black">
                    <div className="edit-document-type-text">Document Type</div>
                    <PosSelect
                      // isSearch : Removing Search as not needed for this dropdown
                      name="documentSaveTypeName"
                      className=" tabDropdown"
                      value={documentSaveTypeName}
                      autoFocus
                      onChange={(e) => {
                        setFieldValue("documentSaveTypeName", e.target.value);
                      }}
                      disabled={taxExemption}
                      options={
                        documentTypes?.length
                          ? documentTypes.map(({ docTypeName, companyDocTypeName }) => {
                            return {
                              label: docTypeName || companyDocTypeName,
                              value: docTypeName || companyDocTypeName,
                            };
                          })
                          : null
                      }
                    />
                  </div>
                  <div className="edit-document-description-section">
                    <div className="title-text">
                      Description
                      <span className="document-optional-head ml-3">
                        (Optional)
                      </span>
                    </div>
                    <div className="description-data">
                      <PosInput
                        variant="outlined"
                        id="outlined-basic"
                        maxRows={1}
                        className="customer-company-address search-input-focus"
                        name="description"
                        multiline
                        rows={8}
                        onChange={(e) => {
                          setFieldValue("description", e.target.value);
                        }}
                        value={description}
                      />
                    </div>
                  </div>

                  <div className="edit-document-bottom-section flex-row-reverse">
                    <div className="d-flex flex-row-reverse">

                      <div>
                        <PosButton
                          onClick={() => {
                            handleSubmit();
                          }}
                          startIcon={
                            <PosImage
                              className="setting-icon"
                              src={rightWhiteIcon}
                              alt="setting-icon"
                            />
                          }
                          variant="contained"
                          color="primary"
                          className="new-tab-open-btn btn"
                        >
                          Save Changes
                        </PosButton>
                      </div>
                      <div>
                        <PosButton
                          startIcon={
                            <PosImage
                              className="delete-icon"
                              src={blackCloseIcon}
                              alt="setting-icon"
                            />
                          }
                          variant="contained"
                          className="discard-btn btn"
                          onClick={() => {
                            setOpenEditModel(false);
                          }}
                        >
                          Discard
                        </PosButton>
                      </div>
                    </div>
                    <div className="document-edit-section">
                      <Button
                        type="button"
                        className="edit-icon border-0 p-0 bg-transparent button-focus-edit-doc "
                        onClick={
                          handleDeleteClick
                        }
                      >
                        <PosImage
                          className="delete-icon"
                          src={orangeDeleteIcon}
                          alt="setting-icon"
                        />
                      </Button>
                    </div>

                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      </PosModel>
      <DeleteDocumentModal
        deleteDocumentData={deleteDocumentData}
        setDeleteDocumentData={setDeleteDocumentData}
        keyIndex={keyIndex}
        removeDocumentItem={removeDocumentItem}
        fileName={fileName}
      />
    </>
  );
};

export default OpenEditDocumentModal;
