import { Formik } from "formik";
import { useDispatch } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { ErrorMessage } from "apps/pos/formik/errorMessage";
import { PosButton, PosInput } from "apps/pos/Common/uiCore";
import IconButton from 'apps/product/Modals/Elements/IconButton'
import { UpdatedIdentifiersProductDetailSchema } from "apps/pos/formik/validationSchema";

import { authorizationToken, blockInvalidChar, setFocusTo } from "utils/utils";
import { labels } from "../../modules/productDetailsContainer/constants";
import useProductsDetailsHooks from "../../modules/productDetailsContainer/hooks/mutationHooks";

import { updateIdentifiersProductStart } from "sagas/productsDetails/singleProductDetails.slice";

import "../ProductDetailsCommonModal.scss";
import "./ProductDetailsMetadataIdentifiersModals.scss";

const ProductDetailsMetadataIdentifiersModals = ({
    open,
    onClose,
    getSingleProductData,
    updateIdentifiersProduct,
    focusText,
    removeFocus
}) => {
    const dispatch = useDispatch();

    const { details, metadata } = getSingleProductData || {}
    const { productId } = details || {};
    const { identifiers } = metadata || {}
    const { upc, ean, gtin, gcid, amazonAsin } = identifiers || {}

    const { UPC, EAN, GCID, GTIN, ASIN, Back, SaveChanges, Identifiers } = labels;

    const {
        updateIdentifiersProductsMutation,
    } = useProductsDetailsHooks();

    const handleClose = (resetForm) => () => {
        onClose();
        resetForm();
    }

    return (
        <Formik
            initialValues={{
                upc,
                ean,
                gtin,
                gcid,
                amazonAsin,
            }}
            validationSchema={UpdatedIdentifiersProductDetailSchema}
            enableReinitialize
            onSubmit={(values) => {
                const { DefaultLoginId } = authorizationToken("Authorization") || {};
                const { upc, ean, gtin, gcid, amazonAsin } = values
                const body = {
                    productId,
                    handlerId: DefaultLoginId,
                    upc,
                    ean,
                    gtin,
                    gcid,
                    amazonAsin,
                }
                dispatch(updateIdentifiersProductStart())
                updateIdentifiersProductsMutation.mutate(body);
            }}
        >
            {({ values, setFieldValue, resetForm, handleSubmit, dirty }) => {
                const { upc, ean, gtin, gcid, amazonAsin } = values

                return (
                    <>
                        <Dialog
                            className='overview-dialog'
                            open={open}
                            onClose={onClose}
                        >
                            <div className="dialog-header">
                                <h3>{Identifiers}</h3>
                                <IconButton
                                    classes='close-btn'
                                    secondary={true}
                                    title='Close'
                                    icon='close'
                                    onClick={handleClose(resetForm)}
                                />
                            </div>

                            <DialogContent>
                                <div className="product-details-notes-main-content-section table-select pt-0">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {UPC}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={UPC}
                                                type="number"
                                                className="product-detail-search-input input-focus-styling"
                                                name="upc"
                                                value={upc}
                                                onChange={(e) => {
                                                    setFieldValue("upc", e.target.value)
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                maxLength={12}
                                                onKeyDown={(e) => blockInvalidChar(e)}
                                                onWheel={(e) => (e?.target)?.blur()}
                                                inputRef={i => i && focusText === UPC && setFocusTo(i, removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="upc" />
                                </div>
                                <div className="product-details-notes-main-content-section">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {EAN}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={EAN}
                                                type="text"
                                                className="product-detail-search-input input-focus-styling"
                                                name="ean"
                                                value={ean}
                                                onChange={(e) => {
                                                    setFieldValue("ean", e.target.value)
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                maxLength={13}
                                                onKeyDown={(e) => blockInvalidChar(e)}
                                                onWheel={(e) => (e?.target)?.blur()}
                                                inputRef={i => i && focusText === EAN && setFocusTo(i, removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="ean" />
                                </div>
                                <div className="product-details-notes-main-content-section">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {GTIN}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={GTIN}
                                                type="number"
                                                className="product-detail-search-input input-focus-styling"
                                                name="gtin"
                                                value={gtin}
                                                onChange={(e) => {
                                                    setFieldValue("gtin", e.target.value)
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                maxLength={14}
                                                onKeyDown={(e) => blockInvalidChar(e)}
                                                onWheel={(e) => (e?.target)?.blur()}
                                                inputRef={i => i && focusText === GTIN && setFocusTo(i, removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="gtin" />
                                </div>
                                <div className="product-details-notes-main-content-section">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {GCID}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={GCID}
                                                type="text"
                                                className="product-detail-search-input input-focus-styling"
                                                name="gcid"
                                                value={gcid}
                                                onChange={(e) => {
                                                    setFieldValue("gcid", e.target.value)
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                maxLength={16}
                                                inputRef={i => i && focusText === GCID && setFocusTo(i, removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="gcid" />
                                </div>
                                <div className="product-details-notes-main-content-section">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {ASIN}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={ASIN}
                                                type="text"
                                                className="product-detail-search-input input-focus-styling"
                                                name="amazonAsin"
                                                value={amazonAsin}
                                                onChange={(e) => {
                                                    setFieldValue("amazonAsin", e.target.value)
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                maxLength={13}
                                                inputRef={i => i && focusText === ASIN && setFocusTo(i, removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="amazonAsin" />
                                </div>
                            </DialogContent>
                            <DialogActions className="notes-model-footer">
                                <div className="notes-footer-wrapper d-flex notes-btn-div product-detail-modal-footer-spacing justify-content-end">
                                    <PosButton
                                        variant="contained"
                                        onClick={handleClose(resetForm)}
                                        className="back-button first-product-detail-btn"
                                    >
                                        {Back}
                                    </PosButton>
                                    <PosButton
                                        fontWeight
                                        className={`btn ml-2 second-product-detail-btn ${updateIdentifiersProduct ? "button-accent button-is-loading-black" : ""}`}
                                        variant="contained"
                                        color="primary"
                                        disabled={updateIdentifiersProduct || !dirty}
                                        onClick={() => handleSubmit()}
                                    >
                                        {SaveChanges}
                                    </PosButton>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </>
                );
            }}
        </Formik>
    );
}

export default ProductDetailsMetadataIdentifiersModals;