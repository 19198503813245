import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { RootState } from "models";
import "@fontsource/inter";
import _ from "loadsh";

import {
  PosInput,
  PosIconButton,
  Container,
} from "apps/pos/Common/uiCore";
import { Button } from "components";

import { Paths } from "enums";
import {
  ArrowUpwardIcon,
  ClearIcon,
  SearchIcon,
  SkipNextIcon,
} from "constants/icons";
import { scrollOnContent } from "utils/utils";
import { getContentById } from "utils/helper";

import {
  arrowDownIndex,
  arrowReset,
  arrowUpIndex,
  salespersonsRequest,
} from "sagas/pos/actions";
import { setPosFlowData } from "sagas/persistReducer/actions";

const SelectSalesPersons = ({
  state,
  setState,
  drawerRef,
  setSelectSalesman,
  onClick,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const rowRef = useRef<any>();
  const rowSecondRef = useRef<any>();

  const { salesPersonDetails, selectedIndex, isLoading } = useSelector<
    RootState,
    any
  >((state) => state.pos);
  const { posFlowData } = useSelector<RootState, any>(
    (state) => state.persistReducer
  );

  const elHeight = document.getElementById("drawer-header")?.clientHeight;
  const frequentSalesman = JSON.parse(localStorage.getItem("salesman")) || [];
  const availSalesman = salesPersonDetails?.data.filter(user =>
    frequentSalesman.some(freqUser => user.personId === freqUser.personId)
  ) || [];
  const currentTaker = JSON.parse(localStorage.getItem("taker"));
  const takerFullName = currentTaker?.fullName;
  const finalHeaderHeight = elHeight + 80;
  const scrollContentHeight = {
    height: `calc(${100}vh - ${finalHeaderHeight}px)`,
  };

  const [searchString, setSearchString] = useState("");
  const [upPress, setUpPress] = useState(false);
  const [enterPress, setEnterPress] = useState(false);
  const [downPress, setDownPress] = useState(false);
  const [salesPersonData, setSalesPersonData] = useState<any>({});
  const [changeHeadingText, setChangeHeadingText] = useState(false);

  const scrollToSetting = (selectedIndex) => {
    let scrollIndex;
    if (selectedIndex < availSalesman?.length) {
      scrollIndex = selectedIndex;
    } else {
      scrollIndex = selectedIndex - availSalesman?.length;
    }

    const elementId =
      selectedIndex < availSalesman?.length
        ? document?.getElementById(`rowRef${scrollIndex}`)
        : document?.getElementById(`rowSecondRef${scrollIndex}`);

    elementId?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const selectSalesPerson = (taker) => {
    const data = {
      ...posFlowData,
      salesman: taker,
    };

    const oldSalesman = JSON.parse(localStorage.getItem("salesman")) || [];

    if (!_.find(oldSalesman, taker)) {
      oldSalesman.push(taker);
      localStorage.setItem("salesman", JSON.stringify(oldSalesman));
    }

    dispatch(setPosFlowData(data));
    setState({ ...state, right: false });
  };

  const handleSalesmanClick = async ({taker}) => {
    const data = {
      ...posFlowData,
      salesman: taker,
    };

    const oldSalesman =
      JSON.parse(
        await localStorage.getItem("salesman")
      ) || [];

    if (!_.find(oldSalesman, taker)) {
      oldSalesman.push(taker);
      localStorage.setItem(
        "salesman",
        JSON.stringify(oldSalesman)
      );
    }

    dispatch(setPosFlowData(data));
    setState({ ...state, right: false });
    (
      document.activeElement as HTMLElement
    ).blur();
    setSelectSalesman(false);
  }
  
  const handleRecentSalesman = ({taker}) => {
    const data = {
      ...posFlowData,
      salesman: taker,
    };
    dispatch(setPosFlowData(data));
    setState({ ...state, right: false });
    navigate(Paths.posCart);
    (document.activeElement as HTMLElement).blur();
    setSelectSalesman(false);
  }

  const handleKeyPress = (e) => {
    const { keyCode } = e;
    if (keyCode === 40) {
      setDownPress(true);
    } else if (keyCode === 38) {
      dispatch(arrowUpIndex());
      setUpPress(true);
    } else if (keyCode === 13) {
      setEnterPress(true);
    }
  };

  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    setSalesPersonData(salesPersonDetails);
  }, [salesPersonDetails]);

  useEffect(() => {
    if (downPress) {
      const recentSalesmanLength = availSalesman?.length ?? 0;
      const totalSalesmanLength = salesPersonData?.data?.length ?? 0;
      const dataLength = recentSalesmanLength + totalSalesmanLength;
      scrollToSetting(selectedIndex + 1);
      dispatch(arrowDownIndex(dataLength));
      setDownPress(false);
    }
  }, [downPress]);

  useEffect(() => {
    if (!salesPersonDetails?.data?.length) dispatch(salespersonsRequest());
  }, []);

  useEffect(() => {
    if (upPress) {
      if (selectedIndex > -1) {
        scrollToSetting(selectedIndex);
      }
      setUpPress(false);
    }
  }, [upPress]);

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);
    return function cleanup() {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (enterPress) {
      if (selectedIndex > -1 && selectedIndex < availSalesman?.length) {
        selectSalesPerson(availSalesman[selectedIndex]);
        setSelectSalesman(false)
      }
      if (
        (selectedIndex > -1 && selectedIndex > availSalesman.length) ||
        (selectedIndex > -1 && selectedIndex === availSalesman.length)
      ) {
        selectSalesPerson(
          salesPersonData?.data[selectedIndex - availSalesman.length]
        );
        setSelectSalesman(false)
      }
      setEnterPress(false);
    }
  }, [enterPress]);

  useEffect(() => {
    scrollOnContent();
  }, []);

  // start
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const contentSec = getContentById();

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, [contentSec]);

  useEffect(() => {
    const elHeight = document.getElementById("drawer-header")?.clientHeight;
    if (contentSec && matches) {
    contentSec.style.paddingTop = `${elHeight + 20}px`;
    }
    else if(contentSec && !matches) {
    contentSec.style.paddingTop = '0px';
    }
  }, [contentSec, matches]);
  // end

  return (
    <Box role="presentation" className="h-100 modal-box">
      <PosIconButton
        className="back-next-btn-small btn responsive-back-btn pos-icon-back-btn"
        onClick={onClick}
        tabIndex={-1}
      >
        <ClearIcon className="back-icon" />
      </PosIconButton>
      <div ref={myRef} className="topbar-cart m-0"></div>
      <Container
        className="modal-content-style modal-container-styling"
        ref={drawerRef}
        id="noScrollContent"
      >
        <main>
          <div>
            <div className="drawer-static-header" id="drawer-header">
              <div>
                <div className="pricing-modal-header">
                  <h4 className="pricing-modal-heading">
                    Who is processing this order?
                  </h4>
                  <h1 className="pricing-schedule-heading">
                    Select Salesperson
                  </h1>
                </div>
                <div
                  className="modal-select-default-btn schedule-section"
                  onClick={() => setState({ ...state, right: false })}
                >
                  <div className="select-default-text">
                    <SkipNextIcon />
                    <div className="default-schedule-heading">
                      <span className="int-14-reg-white">
                        Keep current Salesperson
                      </span>
                      <span className="schedule-number-info">
                        {posFlowData?.salesman?.salespersonName
                          ? posFlowData?.salesman?.salespersonName
                          : takerFullName
                          ? takerFullName
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {salesPersonDetails?.data?.length && (
                <>
                  <div className="search-with-close-input">
                    <div className="search-icon">
                      <SearchIcon />
                    </div>
                    <PosInput
                      variant="outlined"
                      id="outlined-basic"
                      className="double-bordered-input"
                      placeholder="Search Sales Person"
                      value={searchString}
                      autoFocus
                      onChange={(e) => {
                        if (
                          !e.target.value.includes("  ") &&
                          e.target.value.charAt(0) !== " "
                        ) {
                          if (e.target.value) {
                            setChangeHeadingText(true);
                          }
                          setSearchString(e.target.value);
                          setSearchData(
                            salesPersonData?.data.filter((data) =>
                              data?.salespersonName
                                ?.toLowerCase()
                                .includes(e.target.value.toLowerCase())
                            )
                          );
                          dispatch(arrowReset())
                        }
                      }}
                    />
                    <div
                      className="close-icon"
                      onClick={() => setSearchString("")}
                    >
                      <ClearIcon />
                    </div>
                  </div>
                </>
              )}
            </div>
            {salesPersonDetails?.data?.length ? (
              <>
                <div
                  className="drawer-content-scroll modal-subheading mb-0 mt-0 position-relative drawer-bottom-sec-styling"
                  style={scrollContentHeight}
                  id="shippingScrollStyling"
                >
                  <div className="pricing-frequently">
                    {availSalesman?.length ? (
                      <span className="modal-subheading mt-0 mb-0">RECENT</span>
                    ) : (
                      <></>
                    )}
                    {availSalesman?.length ? (
                      availSalesman?.map((taker, index) => {
                        return (
                          <div
                            onClick={() => handleRecentSalesman({taker})}
                            key={index}
                            ref={rowRef[index]}
                            id={`rowRef${index}`}
                            className={
                              index === selectedIndex
                                ? "frequently-used-schedule frequently-used-schedule-index"
                                : "frequently-used-schedule"
                            }
                          >
                            <h3 className="select-schedule-list">
                              {taker?.salespersonName}
                            </h3>
                            <i className="fas fa-arrow-right right-icon"></i>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="all-pricing-schedules">
                    {!searchData.length && changeHeadingText ? (
                      <span className="int-16-reg">No schedules found</span>
                    ) : (
                      <span className="modal-subheading mb-0">ALL SALESPERSONS</span>
                    )}
                    <div className="all-schedules-names">
                      {!searchString
                        ? salesPersonData?.data?.length &&
                          salesPersonData?.data?.map((taker, index) => {
                            return (
                              <div
                                onClick={()=>handleSalesmanClick({taker}) }
                                key={index}
                                ref={rowSecondRef[index]}
                                id={`rowSecondRef${index}`}
                                className={
                                  index + availSalesman?.length ===
                                  selectedIndex
                                    ? "frequently-used-schedule frequently-used-schedule-index"
                                    : "frequently-used-schedule"
                                }
                              >
                                <h3 className="select-schedule-list">
                                  {taker.salespersonName}
                                </h3>
                                <i className="fas fa-arrow-right right-icon"></i>
                              </div>
                            );
                          })
                        : searchData?.length
                        ? searchData?.map((taker, index) => {
                            return (
                              <div
                                onClick={()=>handleSalesmanClick({taker})}
                                key={index}
                                ref={rowSecondRef[index]}
                                id={`rowSecondRef${index}`}
                                className={
                                  index + availSalesman?.length ===
                                  selectedIndex
                                    ? "frequently-used-schedule frequently-used-schedule-index"
                                    : "frequently-used-schedule"
                                }
                              >
                                <h3 className="select-schedule-list">
                                  {taker.salespersonName}
                                </h3>
                                <i className="fas fa-arrow-right right-icon"></i>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                  <div className="back-to-top-section">
                    <Button
                      className="back-to-top-sales-person bg-transparent button-focus-class"
                      onClick={() => myRef.current.scrollIntoView()}
                    >
                      <ArrowUpwardIcon />
                      <div className="jump-back-text back-to-top-text">
                        Jump back to top
                      </div>
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              !isLoading && (
                <span
                  className="modal-subheading mb-0 mt-0"
                  style={{ fontSize: 12, textAlign: "center" }}
                >
                  No schedules found
                </span>
              )
            )}
          </div>
        </main>
      </Container>
    </Box>
  );
};

export default SelectSalesPersons;
