import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import renderHtml from "react-render-html";
import Avvvatars from "avvvatars-react";

import { ToastContainer, Toast } from "components";
import { useMediaQuery } from "apps/admin/hooks/useMediaQuery";
import Button from "components/SPButton/Button";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";

import { quoteDetailRfi } from "models";
import { quoteMessagesSortBy } from "utils/data";
import { displayDateFormatValue, setDateWithFormat } from "utils/utils";
import { InsertDriveFileIcon } from "constants/icons";
import { springBoardText } from "constants/constant";

import { getRfiRequest } from "sagas/quoteDetail/actions";
import { getAlertMessage, getAlertShow, getIsErrorAlert, getQuoteRfi, getQuoteRfiLoading } from "sagas/quoteDetail/selector";

import "react-perfect-scrollbar/dist/css/styles.css";
import { distributorData } from "sagas/distributor/selector";

interface quoteMessageProps {
  quoteId: number;
}

export const QuoteRfi = ({ quoteId }: quoteMessageProps) => {
  const dispatch = useDispatch();

 const distributor = useSelector(distributorData)
  const alertShow = useSelector(getAlertShow)
  const alertMessage = useSelector(getAlertMessage)
  const isErrorAlert = useSelector(getIsErrorAlert)
  const quoteRfi = useSelector(getQuoteRfi)
  const quoteRfiLoading = useSelector(getQuoteRfiLoading)
  const [sortIndex, setSortIndex] = useState(0);
  const [sortedArray, setSortedArray] = useState([]);

  useEffect(() => {
    dispatch(getRfiRequest(quoteId));
  }, [quoteId]);

  useEffect(() => {
    setSortedArray([...quoteRfi].reverse());
    if (sortIndex === 1) {
      setSortedArray([...sortedArray].reverse());
    }
  }, [quoteRfi, sortIndex]);

  const { httpsUrl } = distributor || {};
  const { sents , noRFISent , sendRFI , sortBy , newestFirst } = springBoardText
   
  return (
    <div className="quote-details-rfis">
      {quoteRfiLoading ? <ShapedSkeleton
        shape='0 0 26 26 26, 0 200 26 26 26, 46 0 200 14, 46 200 200 14, 621 0 100 14, 621 200 100 14, 46 22 100 14, 46 222 100 14, 46 52 400 10, 46 252 400 10, 46 70 200 10, 46 100 50 50, 116 111 250 10, 116 129 40 10, 46 300 50 50, 116 311 250 10, 116 329 40 10, 46 270 200 10'
        width={721}
        height={350}
        radius={4}
        classes="mt-3"
      /> :
        <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        {alertShow && (
          <ToastContainer>
            <Toast
              children={alertMessage}
              status={isErrorAlert ? "Error" : "Success"}
            />
          </ToastContainer>
        )}
        <div className="d-flex align-items-center">
          <div className="fw-medium fs-14">
            <span>
              {quoteRfi?.length ? `${quoteRfi.length} ${sents}` : noRFISent}
            </span>
          </div>
          {quoteRfi?.length ? (
            <div className="dropdown ms-2">
              <button
                className="fw-medium fs-14 text-dark btn btn-link p-0 dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {`${sortBy} ${
                  sortIndex ? quoteMessagesSortBy[sortIndex] : newestFirst
                }`}
              </button>
              <div
                className="dropdown-menu selectList"
                abyria-labelledby="dropdownMenuButton"
              >
                {quoteMessagesSortBy.map((option, index) => (
                  <button
                    onClick={() => setSortIndex(index)}
                    className={`btn btn-link ${
                      index === sortIndex ? "active" : ""
                    }`}
                    key={index}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          ) : null}
        </div>
        <div className="header-actions">
            <Button
              label={sendRFI}
              leadingIcon='add'
              type='secondary'
              size='small'
              href= {`${httpsUrl}/gmSale/msJobLotQuote_RFI_send.aspx?jobLotQuoteID=${quoteId}`}
              target='_blank'
            />
        </div>
        
      </div>
      <div className="rfi-list-container">
          {sortedArray && sortedArray?.length > 0
            ? sortedArray.map((message, index) => (<>
                <QuoteMessagesItem messageData={message} key={index} />
                <div className="divider-grey mb-2" /></>
              ))
            : null}
      </div>
        </>
      }
    </div>
  );
};

interface quoteMessagesItemProps {
  messageData: quoteDetailRfi;
}

export const QuoteMessagesItem = ({ messageData }: quoteMessagesItemProps) => {
  const isMobile = useMediaQuery("(max-width: 800px)");
  const avatarBgColor = "#fff";
  const {
    handlerName,
    sentDate,
    sentToName,
    subj,
    rfiContent,
    sentToCompany,
    sentToEmail,
    sentToPhone,
    pdfName,
    pdfLink
  } = messageData || {};
  const { rfi } = springBoardText;

  const openDocument = (pdfLink) => {
    window.open(pdfLink, "_blank");
  }
  return (
    <>
      <div className="message-line mb-2">
        <div>
          <Avvvatars
            value={handlerName}
            style="shape"
            size={isMobile ? 40 : 45}
            border
            borderSize={2}
            borderColor={avatarBgColor}
          />
        </div>
        <div className="message-content">
          <p className="sender">
            <span className="message-name-text">{handlerName}</span>
            <span className="message-date-text ml-2">
              {setDateWithFormat(sentDate,displayDateFormatValue.monthAndYearTimeFormat)}
            </span>
          </p>
          <p className="message-name-text">{subj}</p>
          <p className="message-body-text">{renderHtml(rfiContent)}</p>
          <div className="document-preview mt-4 cursor-pointer" onClick={ () => openDocument(pdfLink)}>
            <div className="preview">
              <InsertDriveFileIcon />
            </div>
            <div className="details">
              <p className="name">{pdfName}</p>
              <p className="type">{rfi}</p>
            </div>
          </div>
        </div>
        <div>
          <p className="message-name-text">{sentToName}</p>
          <p className="message-email-text mt-2">{sentToCompany}</p>
          <div className="message-email-text d-flex mt-1">
            <p>{sentToEmail}</p>
            <span className="gray-message-dot me-2 ms-2 align-center"></span>
            <p>{sentToPhone}</p>
          </div>
        </div>
      </div>
    </>
  );
};
