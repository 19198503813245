import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import * as services from "../../services";
import * as actions from "./actions";
import * as constants from "./constants";

//Worker Saga

function* getReturnInvoiceWatcher() {
  yield takeLatest(
    constants.GET_RETURN_ITEMS_START,
    getReturnInvoicesWatcherAsync
  );
}

function* returnProductsPickingWatcher() {
  yield takeLatest(
    constants.RETURN_PRODUCTS_PICKING_START,
    returnProductsPickingAsync
  );
}

function* returnProductsTicketPrintWatcher() {
  yield takeLatest(
    constants.RETURN_PRODUCTS_PRINT_TICKET_START,
    returnProductsTicketPrintAsync
  );
}

function* returnProductsCreditMemoWatcher() {
  yield takeLatest(
    constants.RETURN_PRODUCTS_CREDIT_MEMO_START,
    returnProductsCreditMemoAsync
  );
}

function* returnProductsOrdersWatcher() {
  yield takeLatest(
    constants.RETURN_PRODUCTS_ORDERS_START,
    returnProductsOrdersAsync
  );
}

function* getReturnInvoicesWatcherAsync(action: AnyAction) {
  try {
    const getReasonData = yield call(
      services.getSearchInvoiceList,
      action.payload
    );
    yield put(actions.returnItemSuccess(getReasonData));
  } catch (errors) {
    yield put(actions.returnItemError("errors"));
  }
}

// return Products Picking

function* returnProductsPickingAsync(action: AnyAction) {
  try {
    const importQuoteItemsData = yield call(services.returnProductsPicking, action.payload, action.stockWarehouseId);
    yield put(
      actions.returnProductsPickingSuccess(importQuoteItemsData)
    );
  } catch (errors) {
    yield put(actions.returnProductsPickingError(errors.title));
  }
}

// return Products Ticket Print

function* returnProductsTicketPrintAsync(action: AnyAction) {
  try {
    const importQuoteItemsData = yield call(services.returnProductsTicketPrint, action.payload);
    yield put(
      actions.returnProductsTicketPrintSuccess(importQuoteItemsData)
    );
  } catch (errors) {
    yield put(actions.returnProductsTicketPrintError(errors.title));
  }
}

// return Products Credit Memo

function* returnProductsCreditMemoAsync(action: AnyAction) {
  try {
    const importQuoteItemsData = yield call(services.returnProductsCreditMemo, action.payload);
    yield put(
      actions.returnProductsCreditMemoSuccess(importQuoteItemsData)
    );
  } catch (errors) {
    yield put(actions.returnProductsCreditMemoError(errors.title));
  }
}

// return Products Orders

function* returnProductsOrdersAsync(action: AnyAction) {
  try {
    const importQuoteItemsData = yield call(services.returnProductsOrders, action.payload, action.stockWarehouseId);
    if (importQuoteItemsData) {
      yield put(
        actions.returnProductsOrdersSuccess(importQuoteItemsData)
      );
    } else {
      yield put(actions.returnProductsOrdersError("errors"));
    }
  } catch (errors) {
    yield put(actions.returnProductsOrdersError(errors.title));
  }
}

// async start
function* searchReturnInviceAsync(action: AnyAction) {
  try {
    const searchInvoiceData = yield call(
      services.searchReturnInvoice,
      action.payload
    );
    yield put(actions.searchReturnInvoiceSuccess(searchInvoiceData));
  } catch (errors) {
    yield put(actions.searchReturnInvoiceError(errors?.title));
  }
}

function* getReturnReasonAsync() {
  try {
    const getReasonData = yield call(services.searchReturnReason);
    yield put(actions.getReasonSuccess(getReasonData));
  } catch (errors) {
    yield put(actions.getReasonError("errors"));
  }
}

function* pickReplaceCartPrintAsync(action: AnyAction) {
  try {
    const pickReplaceCartData = yield call(services.returnProductsTicketPrint, action.payload);
    yield put(actions.pickReplaceCartPrintSuccess(pickReplaceCartData));
  } catch (errors) {
    yield put(actions.pickReplaceCartPrintError("errors"));
  }
}
// async end

// watcher start
function* searchReturnInvoiceWatcher() {
  yield takeLatest(
    constants.SEARCH_RETURN_INVOICE_START,
    searchReturnInviceAsync
  );
}

function* searchReturnReasonWatcher() {
  yield takeLatest(constants.GET_RETURN_REASON_START, getReturnReasonAsync);
}


function* pickReplaceCartPrintWatcher() {
  yield takeLatest(constants.PICK_REPLACE_CART_PRINT_START, pickReplaceCartPrintAsync);
}

// watcher end

export default function* returnSaga() {
  yield all([
    getReturnInvoiceWatcher(),
    returnProductsPickingWatcher(),
    returnProductsTicketPrintWatcher(),
    returnProductsCreditMemoWatcher(),
    returnProductsOrdersWatcher(),
    // start
    searchReturnInvoiceWatcher(),
    searchReturnReasonWatcher(),
    pickReplaceCartPrintWatcher(),
    // end
  ]);
}