// import { response } from '../../../../Component/response';
import ItemGroup from "../ItemGroup";
import "./ItemGroupWrapper.scss";

const ItemGroupWrapper = ({
  quoteItemsData,
  quoteRepId,
  jobQuoteID,
  distributor,
  buyPriceReceivedFromRepFlag,
}) => {

 return (
    <div className="item-group-wrapper">
      {quoteItemsData?.map((rep, index) => (
        <ItemGroup
          key={`rep-${index}`}
          rep={rep}
          buyPriceReceivedFromRepFlag={buyPriceReceivedFromRepFlag}
          jobQuoteID={jobQuoteID}
          quoteRepId={quoteRepId}
          distributor={distributor}
        />
      ))}
    </div>
  );
};

export default ItemGroupWrapper;
