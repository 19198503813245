import React, { ReactNode } from "react";
import { Box, BoxProps } from "@material-ui/core";

interface Props extends BoxProps {
  children: ReactNode;
}
const PosBox = ({ className, children, sx }: Props) => {
  return (
    <Box className={className} sx={sx}>
        {children}
    </Box>
  );
};
export default PosBox;
