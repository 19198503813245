import MaterialIcon from 'apps/pos/Common/uiCore/MaterialIcon';
import './IconButton.scss';

const IconButton = (props) => {
    let { classes, secondary, disabled, onClick, icon, title , iconSize , size = 40} = props;
    return (
        <button
            className={`icon-button-primary ${secondary ? 'icon-button-secondary' : ''} ${classes ? classes : ''}`}
            disabled={disabled}
            title={title}
            onClick={onClick}
            style={{ '--size': `${size}` }}
        >
            <MaterialIcon icon={icon} size={iconSize ? iconSize : '24'} classes="icon" />
        </button>
    );
}

export default IconButton;
