import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import './IconButton.scss';

const IconButton = (props) => {
    const {
        name = '',
        iconSize = 24,
        size = 40,
        onClick = null,
        type = 'primary',
        className = '',
        iconClassName = '',
        loading = false,
        disabled = false,
        title = '',
        tabIndex,
    } = props;

    const btnClasses = `product-detail-id-icon-btn type-${type} ${loading ? 'busy' : ''}`
    return (
            <button
                className={`${btnClasses} ${className}`}
                style={{ '--size': size }}
                disabled={disabled}
                onClick={onClick}
                title={title}
                tabIndex={tabIndex}
            >
            <MaterialIcon icon={name} size={iconSize} className={iconClassName} customeClass={iconClassName}/>
             <span>{title}</span>
            </button>
    )
}

export default IconButton;