import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";

import TableCell from "@material-ui/core/TableCell";

export const useStylesProductLayout = makeStyles({
    root: {
      "& .MuiButtonBase-root": {
        fontSize: "0.875rem",
        borderRadius: "0.625rem",
        padding: "0.313rem 0.938rem",
      },
    },
  });

  export const StyledTableCell = withStyles(() => ({
    head: {
      fontSize: 10,
      fontFamily: "Inter, Regular",
      paddingLeft: 0,
      paddingTop: 10,
      paddingBottom: 10,
      borderBottomWidth: 0,
      backgroundColor: "white",
    },
    body: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Inter, Regular",
      borderBottomWidth: 0,
      paddingLeft: 0,
      paddingTop: 10,
      paddingBottom: 10,
      color: "#000",
    },
  }))(TableCell);