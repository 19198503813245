import { Form, FormikProvider, useFormik } from "formik";
import { object, string } from "yup";

import {
  PosButton,
  PosInput,
  PosModel,
} from "apps/pos/Common/uiCore";
import { Button } from "components";
import { CheckIcon, ClearIcon } from "constants/icons";
import { undoDeliveryModalMessage } from "constants/message";

import "./setStorage.scss";
import { useSelector } from "react-redux";
import { getDeliverySuccessData, getHandlerId, getIsPendingOrderDelivery } from "../pages/customerInfo/CustomerStorage/redux/selector";

interface UndoDeliveryModalProps {
  setUndoDeliveryModal: (boolean) => void;
  undoDeliveryModal: boolean;
  undoDeliveryFn: ({handlerID, deliveryID, reason}) => void;
  deliveredItemUndoDeliveryPayload?: {handlerID: number; deliveryID: number}
  deliveryId?: string;
  isApiLoading?: boolean;
}
const UndoDeliveryModal = ({
    setUndoDeliveryModal,
    undoDeliveryModal,
    undoDeliveryFn,
    deliveredItemUndoDeliveryPayload,
    deliveryId,
    isApiLoading
}:
UndoDeliveryModalProps) => {

  const deliverySuccessData = useSelector(getDeliverySuccessData)
  const handlerId = useSelector(getHandlerId)

  const isPendingStorageDelivery = useSelector(getIsPendingOrderDelivery)

  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: object().shape({
      reason: string().required(undoDeliveryModalMessage.reason),
    }),
    onSubmit: () => {
      const undoDeliveryPayload = isPendingStorageDelivery ? {
        handlerID: handlerId ??  '',
        deliveryID:  deliverySuccessData?.deliveryID ?? deliverySuccessData?.data,
        reason: values?.reason,
      } : {
        handlerID: deliveredItemUndoDeliveryPayload?.handlerID ??  '',
        deliveryID: deliveredItemUndoDeliveryPayload?.deliveryID ?? '',
        reason: values?.reason,
      }
      undoDeliveryFn(undoDeliveryPayload)
    },
  });

  const { values, setFieldValue, handleSubmit, errors, touched } = formik;

  const closeModal = () => {
    if(!isApiLoading){
      setUndoDeliveryModal(false);
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <PosModel
            open={undoDeliveryModal}
            onClose={closeModal}
            className={`set-customer-code-modal`}
            dialogClassName={`set-customer-code-modal-container set-storage-modal-container put-storage-modal-container`}
            dialogHeader={
              <div className="modal-header align-items-center">
                <span className="modal-title">{`Undo Delivery #${deliveryId ?? ''}`}</span>
                <Button className="bg-transparent border-0 p-0 button-focus" tabIndex={0} onClick={closeModal} >
                  <ClearIcon
                    className="clear-icon"
                  />
                </Button>
              </div>
            }
            dialogFooter={
              <>
                <div className={`d-flex flex-wrap undo-delivery-modal-footer flex-row-reverse`}>
                
                  <div className="d-flex flex-wrap">
                    <PosButton
                    disabled={isApiLoading}
                      variant="contained"
                      color="primary"
                      className={`add-to-return-cart-button mt-2 put-in-storage-modal-btn btn`}
                      startIcon={<CheckIcon />}
                      fontWeight
                      onClick={() => handleSubmit()}
                    >
                      {`Undo Delivery`}
                    </PosButton>
                  </div>
                  <div>
                    <PosButton
                      type="button"
                      variant="contained"
                      className={`add-to-return-cart-button mr-2 mt-2 btn-font-600 undo-delivery-modal-back-btn btn`}
                      fontWeight
                      startIcon={<ClearIcon />}
                      onClick={closeModal}
                    >
                      Back
                    </PosButton>
                  </div>
                </div>
              </>
            }
          >
            <>
              <div className="set-storage-type-section set-storage-type-section-padding">
                {`This will move all items in this delivery back into storage`}
              </div>

              <div className="set-storage-type-section">
                <div className="title-text mb-2 font-weight-600">Reason</div>
                <PosInput
                  className="input-focus-styling"
                  variant="filled"
                  id="filled-basic"
                  autoFocus
                  InputProps={{ disableUnderline: true }}
                  name="reason"
                  value={values?.reason}
                  onChange={(e) => setFieldValue("reason", e.target.value)}
                />
              </div>
              {errors.reason && touched.reason ? (
                <p className="validation-error-text mt-2">
                  {errors.reason}
                </p>
              ) : null}
            </>
          </PosModel>
        </Form>
      </FormikProvider>
    </>
  );
};
export default UndoDeliveryModal;
