import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PosButton } from "apps/pos/Common/uiCore";
import CustomDropDown from "apps/product/Components/CustomDropDown";
import { getOptionsData, getProductFilters } from "apps/product/modules/productListingContainer/redux/selector";

import { IOption } from "apps/product/Components/CustomDropDown/type";
import { commonConstants, defaultFilter, layoutType, staticStatus, statusValue } from "apps/product/helper/commonConstants";
import { setOptionsRequest } from "apps/product/modules/productListingContainer/redux/productFilters.slice";
import { CloseIcon } from "constants/icons";


const ProductSearchFilter = ({ handleFilter, selectedLayoutType, setlayoutType, filter }) => {
  const dispatch = useDispatch();
  const getProductFiltersData = useSelector(getProductFilters);
  const getOptionsDatas = useSelector(getOptionsData);

  const { statuses, manufacturers, vendors, categories, productGroups } = getProductFiltersData || {}
  const { one, three, four } = statusValue;
  const [options, setOptions] = useState<IOption[]>([]);
  const [productStatus, setProductStatus] = useState([]);
  const [productManufacturer, setProductManufacturer] = useState([]);
  const [productVendor, setProductVendor] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [productGroup, setProductGroup] = useState([]);
  const [statusData, setStatusData] = useState<Record<number, string>>();

  const {
    status,
    manufacturer,
    vendor,
    category,
    group,
    manufacturerIds,
    vendorIds,
    categoryIds,
    productGroupIds,
    statutable
  } = commonConstants


  const handleOptions = (newElem, label) => {
    const existingOption = options?.filter((item) => item.label !== label);
    const mergedArray = [
      ...existingOption,
      ...newElem
    ];
    const uniquePairs = Array.from(
      new Set(mergedArray.map(obj => JSON.stringify(obj) as string)),
      obj => JSON.parse(obj)
    );
    setOptions(uniquePairs);
    dispatch(setOptionsRequest(uniquePairs));
  }

  useEffect(() => {
    if (getOptionsDatas) {
      setOptions(getOptionsDatas);
      getOptionsDatas.forEach(item => {
        switch (item.label) {
          case statutable:
            setProductStatus(prev => [...prev, item.key]);
            break;
          case manufacturerIds:
            setProductManufacturer(prev => [...prev, item.key]);
            break;
          case vendorIds:
            setProductVendor(prev => [...prev, item.key]);
            break;
          case categoryIds:
            setProductCategory(prev => [...prev, item.key]);
            break;
          case productGroupIds:
            setProductGroup(prev => [...prev, item.key]);
            break;
          default:
            setProductStatus(prev => [...prev, item.key]);
            break;
        }
      });
    }
  }, []);

  useEffect(() => {
    const addData = {
      ...statuses,
      ...staticStatus
    }
    setStatusData(addData)
  }, [statuses])
  const clearAll = () => {
    setOptions([]);
    dispatch(setOptionsRequest([]));
    setProductStatus([]);
    setProductManufacturer([]);
    setProductVendor([]);
    setProductCategory([]);
    setProductGroup([]);
    handleFilter(defaultFilter);
  }

  const remove = (label, key) => () => {

    setOptions(prevOptions => prevOptions.filter(option => option.key !== key));
    dispatch(setOptionsRequest(prevOptions => prevOptions.filter(option => option.key !== key)));
    const latestData = manageFilter(label, key);
    let newFilter;

    if (label === statutable) {
      const clear = latestData?.includes(three);
      const discontinue = latestData?.includes(four);
      const newStatus = latestData?.filter((element) => element !== three && element !== four);

      newFilter = {
        ...filter,
        clearanceFlag: clear ? one : null,
        discontinueFlag: discontinue ? one : null,
        statuses: newStatus?.length ? newStatus : null
      }
    }
    else {
      newFilter = {
        ...filter,
        [label]: latestData?.length ? latestData : null
      }
    }

    handleFilter(newFilter)
  };

  const manageFilter = (label, data) => {
    let value;
    switch (label) {
      case statutable:
        value = productStatus.filter(status => status !== data);
        setProductStatus(prevStatus => prevStatus.filter(status => status !== data));
        break;
      case manufacturerIds:
        value = productManufacturer.filter(manufacturer => manufacturer !== data);
        setProductManufacturer(prevManufacturer => prevManufacturer.filter(manufacturer => manufacturer !== data));
        break;
      case vendorIds:
        value = productVendor.filter(vendor => vendor !== data);
        setProductVendor(prevVendor => prevVendor.filter(vendor => vendor !== data));
        break;
      case categoryIds:
        value = productCategory.filter(category => category !== data);
        setProductCategory(prevCategory => prevCategory.filter(category => category !== data));
        break;
      case productGroupIds:
        value = productGroup.filter(status => status !== data);
        setProductGroup(prevStatus => prevStatus.filter(group => group !== data));
        break;
      default:
        value = productStatus.filter(status => status !== data);
        setProductStatus(prevStatus => prevStatus.filter(status => status !== value));
        break;
    }
    return value;
  }

  return (
    <>
      <div className="filter-toogle-container">
        <div className="filters">
          <CustomDropDown label={status} dropdownData={statusData ? statusData : []} setOptions={handleOptions} setPreData={setProductStatus} preData={productStatus} handleFilter={handleFilter} filter={filter} />
          <CustomDropDown label={manufacturer} dropdownData={manufacturers ? manufacturers : []} setOptions={handleOptions} setPreData={setProductManufacturer} preData={productManufacturer} handleFilter={handleFilter} filter={filter} />
          <CustomDropDown label={vendor} dropdownData={vendors ? vendors : []} setOptions={handleOptions} setPreData={setProductVendor} preData={productVendor} handleFilter={handleFilter} filter={filter} />
          <CustomDropDown label={category} dropdownData={categories ? categories : []} setOptions={handleOptions} setPreData={setProductCategory} preData={productCategory} handleFilter={handleFilter} filter={filter} />
          <CustomDropDown label={group} dropdownData={productGroups ? productGroups : []} setOptions={handleOptions} setPreData={setProductGroup} preData={productGroup} handleFilter={handleFilter} filter={filter} />
        </div>
        <div className="toogle-button">
          <PosButton
            variant="contained"
            color="secondary"
            className="btn"
            value={selectedLayoutType}
            onClick={() => {
              layoutType.list === selectedLayoutType
                ? setlayoutType(layoutType.grid)
                : setlayoutType(layoutType.list);
            }}
          >
            {commonConstants[selectedLayoutType]}
          </PosButton>
        </div>
      </div>
      {options.length ? <div className="option-list-view-product">
        {options.map(({ label, value, key }) => {
          return (
            <>
              <PosButton
                key={key}
                value={value}
                variant="outlined"
                color="default"
                className="btn"
                onClick={remove(label, key)}
                startIcon={<CloseIcon />}
              >
                {value}
              </PosButton>
            </>
          );
        })}
        <PosButton
          variant="outlined"
          color="default"
          className="btn"
          onClick={clearAll}
        >
          {commonConstants.clearAll}
        </PosButton>
      </div> : null}
    </>
  );
};

export default ProductSearchFilter;
