import { AnyAction } from "redux";
import * as constants from "./constants";

const initialState = {
  isLoading: false,
  allPriceSchdulesList: {},
  selectedPriceSchedule: {},
  productPriceSchedule: {},
  addpriceSchedules: [],
  addProducts: {},
  selectedProducts: {},
  updateSelectedPriceSchedule: {},
  updateProducts: [],
  updateSelectedProducts: {},
  removePriceSchedule: {},
  removeProducts: {},
  removeSelectedPS: {},
};

const reducer = function priceScheduleReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case constants.GET_ALL_PRICE_SCHEDULES_START:
      return { ...state, isLoading: true };
    case constants.GET_ALL_PRICE_SCHEDULES_SUCCESS:

      return {
        ...state,
        allPriceSchdulesList: action.payload,
        isLoading: false,
      };
    case constants.GET_ALL_PRICE_SCHEDULES_ERROR:
      return {
        ...state,
        allPriceSchdulesList: action.payload,
        isLoading: false,
      };

    case constants.GET_SELECTED_PRICE_SCHEDULES_START:
      return { ...state, isLoading: true };
    case constants.GET_SELECTED_PRICE_SCHEDULES_SUCCESS:
      return {
        ...state,
        selectedPriceSchedule: action.payload,
        isLoading: false,
      };
    case constants.GET_SELECTED_PRICE_SCHEDULES_ERROR:
      return {
        ...state,
        selectedPriceSchedule: action.payload,
        isLoading: false,
      };

    case constants.GET_PRODUCT_PRICE_SCHEDULES_START:
      return { ...state, isLoading: true };
    case constants.GET_PRODUCT_PRICE_SCHEDULES_SUCCESS:
      return {
        ...state,
        productPriceSchedule: action.payload,
        isLoading: false,
      };
    case constants.GET_PRODUCT_PRICE_SCHEDULES_ERROR:
      return {
        ...state,
        productPriceSchedule: action.payload,
        isLoading: false,
      };

    case constants.ADD_ALL_PRICE_SCHEDULES_START:
      return { ...state, isLoading: true };
    case constants.ADD_ALL_PRICE_SCHEDULES_SUCCESS:
      return { ...state, addpriceSchedules: action.payload, isLoading: false };
    case constants.ADD_ALL_PRICE_SCHEDULES_ERROR:
      return { ...state, addpriceSchedules: action.payload, isLoading: false };

    case constants.ADD_ALL_PRODUCTS_START:
      return { ...state, isLoading: true };
    case constants.ADD_ALL_PRODUCTS_SUCCESS:
      return { ...state, addProducts: action.payload, isLoading: false };
    case constants.ADD_ALL_PRODUCTS_ERROR:
      return { ...state, addProducts: action.payload, isLoading: false };

    case constants.ADD_SELECTED_PRODUCTS_START:
      return { ...state, isLoading: true };
    case constants.ADD_SELECTED_PRODUCTS_SUCCESS:
      return { ...state, selectedProducts: action.payload, isLoading: false };
    case constants.ADD_SELECTED_PRODUCTS_ERROR:
      return { ...state, selectedProducts: action.payload, isLoading: false };

    case constants.UPDATE_SELECTED_PRICE_SCHEDULE_START:
      return { ...state, isLoading: true };
    case constants.UPDATE_SELECTED_PRICE_SCHEDULE_SUCCESS:
      return {
        ...state,
        updateSelectedPriceSchedule: action.payload,
        isLoading: false,
      };
    case constants.UPDATE_SELECTED_PRICE_SCHEDULE_ERROR:
      return {
        ...state,
        updateSelectedPriceSchedule: action.payload,
        isLoading: false,
      };

    case constants.UPDATE_PRODUCTS_START:
      return { ...state, isLoading: true };
    case constants.UPDATE_PRODUCTS_SUCCESS:
      return { ...state, updateProducts: action.payload, isLoading: false };
    case constants.UPDATE_PRODUCTS_ERROR:
      return { ...state, updateProducts: action.payload, isLoading: false };

    case constants.UPDATE_SELECTED_PRODUCTS_START:
      return { ...state, isLoading: true };
    case constants.UPDATE_SELECTED_PRODUCTS_SUCCESS:
      return {
        ...state,
        updateSelectedProducts: action.payload,
        isLoading: false,
      };
    case constants.UPDATE_SELECTED_PRODUCTS_ERROR:
      return {
        ...state,
        updateSelectedProducts: action.payload,
        isLoading: false,
      };

    case constants.REMOVE_PRICE_SCHEDULES_START:
      return { ...state, isLoading: true };
    case constants.REMOVE_PRICE_SCHEDULES_SUCCESS:
      return {
        ...state,
        removePriceSchedule: action.payload,
        isLoading: false,
      };
    case constants.REMOVE_PRICE_SCHEDULES_ERROR:
      return {
        ...state,
        removePriceSchedule: action.payload,
        isLoading: false,
      };

    case constants.REMOVE_PRODUCTS_START:
      return { ...state, isLoading: true };
    case constants.REMOVE_PRODUCTS_SUCCESS:
      return { ...state, removeProducts: action.payload, isLoading: false };
    case constants.REMOVE_PRODUCTS_ERROR:
      return { ...state, removeProducts: action.payload, isLoading: false };

    case constants.REMOVE_SELECTED_PRICESCHEDULE_START:
      return { ...state, isLoading: true };
    case constants.REMOVE_SELECTED_PRICESCHEDULE_SUCCESS:
      return { ...state, removeSelectedPS: action.payload, isLoading: false };
    case constants.REMOVE_SELECTED_PRICESCHEDULE_ERROR:
      return { ...state, removeSelectedPS: action.payload, isLoading: false };

    default:
      return state;
  }
};

export default reducer;
