import { InputAdornment } from "@material-ui/core";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import { PosButton, PosInput } from "apps/pos/Common/uiCore";

import { numberRegex } from "constants/constant";
import { CheckIcon, CloseIcon } from "constants/icons";
import { handlingFeeDataInfo } from "sagas/persistReducer/actions";
import { blockInvalidChar } from "utils/utils";

import "./handlingFee.scss";

interface HandlingFeeProps {
  handlingFeeDataCallback?: (number) => void;
  setModal?: (boolean) => void;
  handlingFeeIndex?: number;
  passHandlingData?: number;
}

const HandlingFee = ({ handlingFeeDataCallback, setModal, handlingFeeIndex, passHandlingData }: HandlingFeeProps) => {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        handlingFee: passHandlingData ?? null,
      }}
      enableReinitialize
      onSubmit={(values) => {
        handlingFeeDataCallback(values)
        dispatch(handlingFeeDataInfo({
          orderId: Number(handlingFeeIndex),
          handlingFee: Number(values?.handlingFee)
        }))
        setModal(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {

        const handlingFeeOnChange = (e) => {
          if (
            e.target.value === "" ||
            numberRegex.test(e.target.value)
          ) {
            setFieldValue("handlingFee", e.target.value);
          }
        }

        const handleFocuse = (event) => {
          if (Number(event.target.value) === 0) {
            event.target.value = "";
            setFieldValue("handlingFee", event.target.value);
          }
        }

        return (
          <section className="handling-fee-section">
            <div className="handling-input-main-div">
              <span className="input-title">Handling Fee</span>
              <PosInput
                variant="outlined"
                type="number"
                className="handling-input"
                autoFocus
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                name="handlingFee"
                onKeyPress={(e) => blockInvalidChar(e)}
                value={values.handlingFee}
                onChange={(e) => handlingFeeOnChange(e)}
                onFocus={(e) => handleFocuse(e)}
                onWheel={(e) => (e?.target as HTMLElement)?.blur()}
              />
            </div>
            <div className="save-cancel-button-section">
              <PosButton
                variant="contained"
                color="secondary"
                className="use-default-btn"
                startIcon={<CloseIcon />}
                onClick={() => setModal(false)}
              >
                Cancel
              </PosButton>
              <PosButton
                variant="contained"
                color="primary"
                className="use-default-btn"
                startIcon={<CheckIcon />}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save Changes
              </PosButton>
            </div>
          </section>
        );
      }}

    </Formik>
  );
};
export default HandlingFee;