import { dateEnum, dayJsFormat } from "constants/staticData";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

export const getDateWithAddDays = ({daysToAdd, period}) => {
  return dayjs().add(daysToAdd, period)
}
export const extendDays = ({date, daysToAdd, type, dateFormat}) => {
  return dayjs(date).add(daysToAdd, type).format(dateFormat);
};

export const setDateInYearFormat = (selectedDate) => {
  return dayjs(selectedDate).format(dateEnum.ymdFormat)
}

export const setDateWithCommaFormat = (selectedDate) => {
  return dayjs(selectedDate).format(dayJsFormat.MMMDYYYY)
}

export const setDateWithUsFromNow = (selectedDate) => {
  // Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(relativeTime);
  return dayjs.utc(selectedDate).utcOffset(-240).fromNow();
}

export const setDateWithHrMmFormat = (selectedDate) => {
  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  return dayjs.utc(selectedDate).utcOffset(-240).format(dateEnum.mmddyyyyhhmm)
}

export const dateIsAfter = ({date,selectedDate}) => {
  return dayjs(date).isAfter(selectedDate,'day');
}

export const dateIsSame = ({date,selectedDate}) => {
  return dayjs(date).isSame(selectedDate,'day');
}

export const formatDate = (date ,dateFormat) => {
    return date ? dayjs(date).format(dateFormat) : "";
  };
