import { InputAdornment } from "@material-ui/core";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import { PosButton, PosInput } from "apps/pos/Common/uiCore";

import { numberRegex } from "constants/constant";
import { CheckIcon, CloseIcon } from "constants/icons";
import { restockingFeeDataInfo } from "sagas/persistReducer/actions";
import { blockInvalidChar } from "utils/utils";

import "./restockingFeeModal.scss";

interface Props {
  onClose: () => void;
  handleChangeRestockingCallback?: (number) => void;
  restockingFeeIndex?: number;
  passRestockingData?: number;
  passRestockingRateData?: number;
  itemCreditData?: number;
}
const RestockingFeeModal = ({ restockingFeeIndex, onClose, handleChangeRestockingCallback, passRestockingData, passRestockingRateData, itemCreditData }: Props) => {
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{
        feeAmount: passRestockingData ?? null,
        rate: passRestockingRateData ?? null,
      }}
      enableReinitialize
      onSubmit={(values) => {
        dispatch(restockingFeeDataInfo({
          orderId: Number(restockingFeeIndex),
          restockingFee: Number(values?.feeAmount),
          restockingRate: Number(values?.rate),
        }))
        handleChangeRestockingCallback(values);
        setTimeout(() => {
          onClose();
        }, 100);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        const handleFeeInput = (event, handleRestockingFee = true) => {
          let feeAmount;
          let rate;
          if (handleRestockingFee) {
            const restockCountFee = 100 * event.target.value / itemCreditData;
            feeAmount = Number(event.target.value);
            rate = (Number(restockCountFee))?.toFixed(2);
          } else {
            const restockingCount = event.target.value * itemCreditData / 100;
            feeAmount = (Number(restockingCount))?.toFixed(2);
            rate = Number(event.target.value);
          }

          if (event.target.value != "") {
            setFieldValue('feeAmount', feeAmount);
            setFieldValue('rate', rate);
          } else {
            setFieldValue('feeAmount', event.target.value);
            setFieldValue('rate', event.target.value);
          }

        }
        const restockFeeOnChange = (e) => {
          if (
            e.target.value === "" ||
            numberRegex.test(e.target.value)
          ) {
            handleFeeInput(e)
          }
        }

        const restockRateOnChange = (e) => {
          if (
            e.target.value === "" ||
            numberRegex.test(e.target.value)
          ) {
            handleFeeInput(e, false)
          }
        }

        const handleFocuse = (event,) => {
          if (Number(event.target.value) === 0) {
            event.target.value = "";
            setFieldValue('feeAmount', event.target.value);
            setFieldValue('rate', event.target.value);
          }
        }

        return (
          <div className="restocking-fee-modal">
            <span className="input-title">Restocking Fee</span>
            <div className="restocking-fee-amount-input-div">
              <div className="fee-amount-input-div">
                <span className="fee-amt-title">Fee Amt.</span>
                <div className="fee-amt-input-div">
                  <PosInput
                    variant="standard"
                    id="standard-basic"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                    autoFocus
                    className="fee-amt-input"
                    name="feeAmount"
                    type='number'
                    value={values.feeAmount}
                    onKeyPress={(e) => blockInvalidChar(e)}
                    onChange={(e) => restockFeeOnChange(e)}
                    onFocus={(e) => handleFocuse(e)}
                    onWheel={(e) => {
                      e.target.blur()
                    }}
                  />
                </div>

              </div>
              <div className="fee-amount-input-div">
                <span className="fee-amt-title">Rate</span>
                <div className="fee-amt-input-div">
                  <PosInput
                    variant="standard"
                    id="standard-basic"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: <div className="rate-icon">%</div>,
                    }}
                    className="rate-input"
                    name="rate"
                    type='number'
                    value={values.rate}
                    onKeyPress={(e) => blockInvalidChar(e)}
                    onChange={(e) => restockRateOnChange(e)}
                    onFocus={(e) => handleFocuse(e)}
                    onWheel={(e) => {
                      e.target.blur()
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="cancel-button-section">
              <PosButton
                variant="contained"
                color="secondary"
                className="use-default-btn"
                startIcon={<CloseIcon />}
                onClick={onClose}
              >
                Cancel
              </PosButton>
              <PosButton
                variant="contained"
                color="primary"
                className="use-default-btn"
                startIcon={<CheckIcon />}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save Changes
              </PosButton>
            </div>
          </div>
        );
      }}

    </Formik>
  );
};
export default RestockingFeeModal;