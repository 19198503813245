export const paymentMethodLabel = {
    hidePrices: "Hide prices",
    invoiceEmail: "Send invoice to email",
    allowPickUp: "Allow customer pick up",
    KeepShippingLocation: "Keep shipping location"
}

export const addCustomerLabel = {
    showPrices: "Show prices",
    fixMargin: "Fix margin",
    fixMarginRates: "Fixed Margin Rate",
    freeShip: "Offer free shipping always",
    onlyCreditCard: "Can pay only by Cash or Credit Card",
    creditHold: "Credit hold",
    shippingInstructions: "Shipping Notes",
    temporaryStorageLocation: "Storage Notes",
    orderNotes: "Order Notes",
    selectPriceSchedule: "Select a sales pricing schedule",
    priceSchedule: "Sales Pricing Schedule",
    option: "optional",
    additionalSetting: "ADDITIONAL SETTINGS",
    ShippingInstructions: "Shipping instructions"
}