import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    "&.MuiIconButton-root" : {
      color: "#000000",
      backgroundColor: "#F1F1F1"
    },
    "&.MuiIconButton-colorPrimary": {
      color: "#919191",
      backgroundColor: "#ffffff",
      "&:hover": {
        backgroundColor: "#EEEEEE",
        color: "#000000",
      },
    },
    "&.MuiIconButton-colorSecondary": {
      color: "#000000",
      backgroundColor: "#ffffff",
      "&:hover": {
        color: "#000000",
        backgroundColor: "#FFFFFF",
      },
    },
  },
  customButton:{
    minWidth:(props:{height:string,width:string})=>props.width,
    maxWidth:(props:{height:string,width:string})=>props.width,
    height:(props:{height:string,width:string})=>props.height
  }
});

export default useStyles;
