import { useDispatch, useSelector } from "react-redux";

import OrderInfo from "./OrderInfo";

import { JobNameInput } from "apps/pos/Common/FacilityInput";
import { PosImage, PosPageHeading } from "apps/pos/Common/uiCore";

import { jobAndReceiptNameRegex, poNumberRegex } from "constants/constant";
import images from "constants/images";

import { PurchaseOrderInput } from "apps/pos/Common/PurchaseOrderInput";
import { PosFlowDataProp } from "constants/interfaces";
import { RootState } from "models";
import { setPosFlowData } from "sagas/persistReducer/actions";
import { isPurchaseOrderNoEdited, setJobNameForAll } from "sagas/pos/actions";

const SetUnpaidDues = ({
  tempJobNameSave,
  setTempJobNameSave,
  subTotal,
  taxAmount,
  shipTaxAmount,
  orderId,
  shippingTaxAmount,
  salesPersonName,
  shipToTaxFlag,
  shippingFee,
  creditAmtApplied,
  poNumberSave,
  setPoNumberSave,
}) => {
  const dispatch = useDispatch();

  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );

  const handlePoNumberChange = (e) => {
    if (e.target.value === "" || poNumberRegex.test(e.target.value)) {
      dispatch(isPurchaseOrderNoEdited(true))
      const data = {
        ...posFlowData,
        poNumber: e.target.value,
      };
      dispatch(setPosFlowData(data));
      setPoNumberSave(e.target.value);
    }
  };

  const handleJobName = (e) => {
    if (jobAndReceiptNameRegex.test(e.target.value)) {
      setTempJobNameSave(e.target.value);
      dispatch(setJobNameForAll(e.target.value));
    }
  };
  const { greenCircleRightIcon } = images;

  return (
    <div>
      <div className="pricing-modal-header">
        <div className="payment-option-heading">
          <div>
            <PosPageHeading heading="Payment" grayHeading />
            <div className="pricing-schedule-heading-checkout">
              <PosPageHeading heading="Customer Credit" blackHeading />
            </div>
          </div>
        </div>
      </div>
      <div className="int-20-reg customer-credit-letter">
        <PosImage
          src={greenCircleRightIcon}
          alt="edit_icon"
          className="edit_icon"
        />{" "}
        Order paid in full using previous credit balance.
      </div>
      <OrderInfo
        subTotal={subTotal}
        taxAmount={taxAmount}
        shipTaxAmount={shipTaxAmount}
        shippingTaxAmount={shippingTaxAmount}
        orderId={orderId}
        salesPersonName={salesPersonName}
        shipToTaxFlag={shipToTaxFlag}
        shippingFee={shippingFee}
        creditAmtApplied={creditAmtApplied}
      />
      <div className="margintop-10 pay-later-job-input input-btn-spacing-div">
        <PurchaseOrderInput
          value={poNumberSave}
          onChange={handlePoNumberChange}
        />
        <JobNameInput value={tempJobNameSave} onChange={handleJobName} />
      </div>
    </div>
  );
};

export default SetUnpaidDues;
