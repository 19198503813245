import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { PosResponseProps, RootState } from "models";
import { PosFlowDataProp } from "constants/interfaces";
import { getLocalStoraGeItem } from "utils/utils";
import { moveAdjustQuantitySchema } from "apps/pos/formik/validationSchema";
import { stockMoveRequest } from "sagas/pos/actions";

interface Props {
  fromQtyOnLocationData?: number;
}
const useMoveStockFormik = ({ fromQtyOnLocationData }: Props) => {
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { stockMoveDetails } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );
  const dispatch = useDispatch();
  const { cartProductDetails, savedCart } = posFlowData || {};
  const { productId } = cartProductDetails || {};
  const { takerId } = savedCart || {};
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace || {};
  const windowsUrl = window.location.pathname;

  const initialValues = {
    productLocationRow: "",
    productLocationUnit: "",
    productLocationShelf: "",
    moveToLocationRowD2: "",
    moveToLocationUnitD2: "",
    moveToLocationShelfD2: "",
    quantityToMove: "",
    moveReason: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: moveAdjustQuantitySchema(fromQtyOnLocationData),
    onSubmit: (values) => {
      const {
        productLocationUnit,
        productLocationShelf,
        moveToLocationUnitD2,
        moveToLocationShelfD2,
        quantityToMove,
      } = values || {};
      const data = {
        stockWarehouseID: stockWarehouseId,
        productID: productId,
        stockUnitID1: Number(productLocationUnit),
        stockShelfID1: Number(productLocationShelf),
        stockUnitID2: Number(moveToLocationUnitD2),
        stockShelfID2: Number(moveToLocationShelfD2),
        unitQtyToMove: Number(quantityToMove),
        handlerID: takerId,
        currentRequestUrl: windowsUrl,
      };
      if (!stockMoveDetails?.length) {
        dispatch(stockMoveRequest(data));
      }
    },
  });
  return formik;
};

export default useMoveStockFormik;
