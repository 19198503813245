import { useEffect, useState } from "react";
import { Badge, Radio, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { PosButton, PosIconButton, PosImage } from "apps/pos/Common/uiCore";
import { Button, Loading } from "components";

import { CheckCircleIcon, CloseIcon } from "constants/icons";
import images from "constants/images";
import { RootState } from "models";
import { ProductTypes } from "constants/enum";
import { ProductPriceProps } from "constants/interfaces";

import { showScrollCartPage } from "utils/utils";
import { setPosFlowData } from "sagas/persistReducer/actions";
import { importQuoteItemsClear } from "sagas/pos/importQuotes/action";

import "./selectItemImport.scss";

interface Props {
  open?: boolean;
  closeModel?: () => void;
  backButtonOnClick?: () => void;
  setCartEmpty?:(e: boolean) => void;
  suggestionListVisible?:(e:boolean) => void
  setIsSnackbarVisible:(e:boolean) => void
}

const SelectItemImport = ({ closeModel, backButtonOnClick, setCartEmpty, suggestionListVisible, setIsSnackbarVisible }: Props) => {
  const { importQuotesItemsDetails, isImportQuotesItemsLoading, importQuotesItemsError } = useSelector<
  RootState,
  any
  >((state) => state.posImportQuote);
  const {
    posFlowData,
  } = useSelector<RootState, any>((state) => state.persistReducer);
  const cartProducts = posFlowData?.cartProducts || [];
  const dispatch = useDispatch();

  const [isAvailableInStockItems, setIsAvailableInStockItems] = useState([]);
  const [isAvailableOutOfStockItems, setIsAvailableOutOfStockItems] = useState(
    []
  );
  const [isDiscontinuedAvailableItems, setIsDiscontinuedAvailableItems] =
    useState([]);
  const [selectAllItem, setSelectAllItem] = useState(false);
  const {typeClearance, typeDiscontinued, typeSalesPrice, typeVendorPrice, typePriceScheduleSingle, typePriceScheduleVolume, typeLastPosRetailPrice, typeSalesOverride} = ProductTypes

  // discontinued Out Of Stock
  const isDiscontinuedOutOfStock = importQuotesItemsDetails?.filter(
    ({posProductResponse,qtyOrdered}) =>
      posProductResponse?.qtyInStock === 0 &&
      posProductResponse?.productPrices[0].type === typeDiscontinued && posProductResponse?.qtyInStock < qtyOrdered
  );

  useEffect(() => {
    // qtyInStock data
    const updatedAvailableInStockItems = importQuotesItemsDetails
      ?.filter(({posProductResponse,qtyOrdered}) => posProductResponse?.qtyInStock > 0 && posProductResponse?.productPrices[0].type !== typeDiscontinued && (posProductResponse?.qtyInStock > qtyOrdered || posProductResponse?.qtyInStock === qtyOrdered))
      .map((item) => {
        return { ...item, isSelected: true };
      });
    setIsAvailableInStockItems(updatedAvailableInStockItems);

    // Out Of Stock
    const updateAvailableOutOfStockItems = importQuotesItemsDetails
      ?.filter(({posProductResponse,qtyOrdered}) => (posProductResponse?.qtyInStock === 0 || posProductResponse?.qtyInStock < qtyOrdered) && posProductResponse?.productPrices[0].type !== typeDiscontinued)
      .map((item) => {
        return { ...item, isSelected: true };
      });
    setIsAvailableOutOfStockItems(updateAvailableOutOfStockItems);

    // Discontinued, Available in Stock
    const updateDiscontinuedAvailableItems = importQuotesItemsDetails
      ?.filter(
        ({posProductResponse}) =>
          posProductResponse?.qtyInStock > 0 &&
          posProductResponse?.productPrices[0].type === typeDiscontinued
      )
      .map((item) => {
        return { ...item, isSelected: true };
      });
    setIsDiscontinuedAvailableItems(updateDiscontinuedAvailableItems);
  }, [importQuotesItemsDetails]);

  // qtyInStock onClick
  const availableInStockOnClick = (quoteItemId: number) => {
    const updatedAvailableInStockItems = isAvailableInStockItems?.map(
      (item) => {
        if (quoteItemId === item.quoteItemId) {
          return { ...item, isSelected: !item.isSelected };
        } else {
          return item;
        }
      }
    );
    setIsAvailableInStockItems(updatedAvailableInStockItems);
  };

  // Out Of Stock onClick
  const outOfStockOnClick = (quoteItemId: number) => {
    const updatedOutOfStockItems = isAvailableOutOfStockItems?.map((item) => {
      if (quoteItemId === item.quoteItemId) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setIsAvailableOutOfStockItems(updatedOutOfStockItems);
  };
  //  Discontinued, Available in Stock onClick
  const DiscontinuedAvailableOnClick = (quoteItemId: number) => {
    const updateDiscontinuedAvailableData = isDiscontinuedAvailableItems?.map(
      (item) => {
        if (quoteItemId === item.quoteItemId) {
          return { ...item, isSelected: !item.isSelected };
        } else {
          return item;
        }
      }
    );
    setIsDiscontinuedAvailableItems(updateDiscontinuedAvailableData);
  };

  const selectAll = (selectAllValue: boolean) => {
    // qtyInStock onClick
    const updatedAvailableInStockItems = isAvailableInStockItems?.map(
      (item) => {
        return { ...item, isSelected: selectAllValue };
      }
    );
    setIsAvailableInStockItems(updatedAvailableInStockItems);
    // Out Of Stock onClick
    const updatedOutOfStockItems = isAvailableOutOfStockItems?.map((item) => {
      return { ...item, isSelected: selectAllValue };
    });
    setIsAvailableOutOfStockItems(updatedOutOfStockItems);
    //  Discontinued, Available in Stock onClick
    const updateDiscontinuedAvailableData = isDiscontinuedAvailableItems?.map(
      (item) => {
        return { ...item, isSelected: selectAllValue };
      }
    );
    setIsDiscontinuedAvailableItems(updateDiscontinuedAvailableData);
    setSelectAllItem(selectAllValue);
  };
  
  const availableInStockLength = isAvailableInStockItems?.filter((item) => item?.isSelected)
  const OutOfStockLength = isAvailableOutOfStockItems.filter((item)=> item.isSelected)
  const discontinuedLength = isDiscontinuedAvailableItems.filter((item)=> item.isSelected)
  const selectLengthData = availableInStockLength.length + OutOfStockLength.length + discontinuedLength.length 

  const moveImportItem = () => {
    closeModel()
    backButtonOnClick()
    suggestionListVisible(false)
    setIsSnackbarVisible(true)
    setTimeout(() => {
      setIsSnackbarVisible(false)
      setCartEmpty(false) 
      showScrollCartPage()
    }, 3000);
  }

  const importAllProductPrice = (stockItems) => {
    const stockPrice = [...new Set(stockItems?.map((item) => {
      const {posProductResponse, quoteId, quoteItemId, qtyOrdered, quotePrice } = item || {}
      const clearanceData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typeClearance)
      const discontinuedData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typeDiscontinued)
      const salesOverrideData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typeSalesOverride)
      const priceScheduleSingleData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typePriceScheduleSingle)
      const priceScheduleVolumeData = posProductResponse?.productPrices?.filter((item: ProductPriceProps)=>item.type === typePriceScheduleVolume)
      const lastPosRetailPriceData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typeLastPosRetailPrice)
      const salesPriceData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typeSalesPrice)
      const vendorPriceData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typeVendorPrice)
      return {...posProductResponse,
        quoteId,
        quoteItemId,
        qtyOrdered,
        posRetailPrice:quotePrice,
        clearance:clearanceData || {},
        discontinued:discontinuedData || {},
        salesOverride:salesOverrideData || {},
        priceScheduleSingle: priceScheduleSingleData || {},
        priceScheduleVolume: priceScheduleVolumeData || [],
        lastPosRetailPrice:lastPosRetailPriceData || {},
        salesPrice:salesPriceData || {},
        vendorPrice:vendorPriceData || {},
      }
    }))]
    return stockPrice
  }

  // Import all item button click
  const importAllItems = () => {
    selectAll(!selectAllItem) 
    // Stock
    const uniqueIsAvailableInStock = importAllProductPrice(isAvailableInStockItems)
    // AvailableOutOfStock
    const uniqueIsAvailableOutOfStock = importAllProductPrice(isAvailableOutOfStockItems)
    // Discontinued Available Items
    const uniqueIsDiscontinuedAvailable = importAllProductPrice(isDiscontinuedAvailableItems)

    const selectedProductItem = [...cartProducts,...uniqueIsAvailableInStock, ...uniqueIsAvailableOutOfStock, ...uniqueIsDiscontinuedAvailable]
    const data = {  
        ...posFlowData,
      cartProducts : selectedProductItem,
    }
    dispatch(setPosFlowData(data));
    moveImportItem()
  }
  // Import single item button click
  const importSingleItemPrice = (stockItem) => {
    const data = stockItem?.filter((item) => item?.isSelected).map((item) => {
      const { posProductResponse, quoteId, quoteItemId, qtyOrdered, quotePrice, itemType } = item || {}
      const clearanceData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typeClearance)
      const discontinuedData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typeDiscontinued)
      const salesOverrideData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typeSalesOverride)
      const priceScheduleSingleData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typePriceScheduleSingle)
      const priceScheduleVolumeData = posProductResponse?.productPrices?.filter((item: ProductPriceProps)=>item.type === typePriceScheduleVolume)
      const lastPosRetailPriceData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typeLastPosRetailPrice)
      const salesPriceData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typeSalesPrice)
      const vendorPriceData = posProductResponse?.productPrices?.find((item: ProductPriceProps)=>item.type === typeVendorPrice)
      return { ...posProductResponse, 
        quoteId, 
        quoteItemId, 
        qtyOrdered, 
        posRetailPrice:quotePrice,
        clearance:clearanceData || {} ,
        discontinued:discontinuedData || {},
        salesOverride:salesOverrideData || {},
        priceScheduleSingle: priceScheduleSingleData || {},
        priceScheduleVolume: priceScheduleVolumeData || [],
        lastPosRetailPrice:lastPosRetailPriceData || {},
        salesPrice:salesPriceData || {},
        vendorPrice:vendorPriceData || {},
        itemType
      };
    })
    return data
  }

  const importSingleItems = () => {
    // Stock
    const uniqueIsAvailableInStockSingle = importSingleItemPrice(isAvailableInStockItems)
    // AvailableOutOfStock
    const uniqueIsAvailableOutOfStockSingle = importSingleItemPrice(isAvailableOutOfStockItems)
    // Discontinued Available Items
    const uniqueIsDiscontinuedAvailableSingle = importSingleItemPrice(isDiscontinuedAvailableItems)
    
    const selectedSingleProductItem = [...cartProducts,...uniqueIsAvailableInStockSingle, 
      ...uniqueIsAvailableOutOfStockSingle, ...uniqueIsDiscontinuedAvailableSingle
    ]
    const data = {  
        ...posFlowData,
      cartProducts : selectedSingleProductItem,
    }
    dispatch(setPosFlowData(data));
    moveImportItem()
  }
  // Header and footer 
  const SelectItemImportHeader = () => {
    return (
      <div className="select-model-header-main-section">
        <div className="header-div-container">
          <div className="header-title">
            <span className="main-title">Select Items to Import</span>
            <div className="description">
              Quote #{importQuotesItemsDetails[0]?.quoteId}
              <Badge color="secondary" variant="dot" />
              <span>{`${selectLengthData} of ${importQuotesItemsDetails?.length} items selected`}</span>
              <Button
                type="button"
                className="select-all-text border-0 bg-transparent p-0 button-focus-class"
                onClick={() => selectAll(true)}
              >
                Select all
              </Button>
            </div>
          </div>
          <PosIconButton
            onClick={()=> {
              closeModel()
              backButtonOnClick()
            }}
            className="model-header-close-button button-focus"
          >
            <CloseIcon className="close-icon" />
          </PosIconButton>
        </div>
        <div className="item-import-header-section">
              <div className="item-import-wrapper-main w-5">
                <span className="title">
                  <Tooltip title="Select all items" placement="top" arrow> 
                  <Radio
                    disableRipple
                    className="radio-inp-focus"
                    onClick={() => selectAll(!selectAllItem)}
                    checked={selectLengthData === isAvailableInStockItems?.length + isAvailableOutOfStockItems?.length + isDiscontinuedAvailableItems?.length ? true : false }
                    checkedIcon={
                      <CheckCircleIcon
                        fontSize="small"
                        className="check-icon"
                      />
                    }
                  />
                  </Tooltip>
                </span>
              </div>
              <div className="item-import-wrapper-main w-35">
                <span className="title">ITEM</span>
              </div>
              <div className="item-import-wrapper-main w-20">
                <span className="title">VENDOR</span>
              </div>
              <div className="item-import-wrapper-main w-15">
                <span className="title">AVL. QTY.</span>
              </div>
              <div className="item-import-wrapper-main w-15">
                <span className="title">ORDER QTY.</span>
              </div>
              <div className="item-import-wrapper-main w-15">
                <span className="title">PRICE</span>
              </div>
            </div>
      </div>
    );
  };
  
  const SelectItemImportFooter = () => {
    return (
      <div className="select-item-footer-section">
        <PosButton
          variant="contained"
          className="back-button button-focus-class"
          onClick={()=> {
            backButtonOnClick()
            dispatch(importQuoteItemsClear());
          }}
        >
          Back
        </PosButton>
        <div className="select-item-footer-import-all">
          <PosButton variant="contained" className="import-all-item button-focus-class" onClick={() => importAllItems()}>
            Import all items
          </PosButton>
          <PosButton
            variant="contained"
            disabled={!selectLengthData}
            color="primary"
            className="select-item-btn button-focus-class"
            onClick={() => importSingleItems()}

          >
            {`Import ${selectLengthData ? selectLengthData : ""} Items`} 
          </PosButton>
        </div>
      </div>
    );
  };

  const currencyFormatter = Intl.NumberFormat('en-us', { currency: 'USD', currencyDisplay: 'narrowSymbol', style: 'currency' });

  return (
    <div className="select-item-import-main-container">
      {isImportQuotesItemsLoading ? (
        <Loading className="select-item-loading"/>
      ) : (
        <>
          <SelectItemImportHeader />
          <div className="items-import-table-main-section">
            {/* Start Stock data */}
            {importQuotesItemsError ? <div className="import-no-data-found">{importQuotesItemsError}</div> : (
            <div className="item-import-table-body-section">
              {isAvailableInStockItems?.length ? (
                <div className="stock-item-list">
                  {/* start qtyInStock  */}
                  {isAvailableInStockItems.map(({quotePrice,posProductResponse,quoteItemId,isSelected,qtyOrdered}, index: number) => {
                        const {
                          vendorName,
                          qtyInStock,
                          productName,
                          manufacturerCode,
                          thumbImg,
                        } = posProductResponse || {};
                        return (
                          <div key={quoteItemId} className={`item-import-body-section ${
                            isSelected ? "item-isSelected-values" : ""
                          } `}
                          onClick={() =>
                            availableInStockOnClick(quoteItemId)
                          }>
                              <div className="item-import-wrapper-main w-5">
                                <span className="title">
                                  <Radio
                                    disableRipple
                                    className="radio-inp-focus"
                                    checked={isSelected}
                                    key={index}
                                    checkedIcon={
                                      <CheckCircleIcon
                                        fontSize="small"
                                        className="check-icon"
                                      />
                                    }
                                  />
                                </span>
                              </div>
                              <div className="item-import-wrapper-main w-35">
                                <span className="title image-with-title">
                                  <PosImage src={thumbImg} alt="product-icon" />
                                  <div className="product-title-div">
                                    <span className="product-name">{productName}</span>
                                    <span className="manufacture-code">{manufacturerCode}</span>
                                  </div>
                                </span>
                              </div>
                              <div className="item-import-wrapper-main w-20">
                                <span className="title">{vendorName}</span>
                              </div>
                              <div className="item-import-wrapper-main w-15">
                                <span className="title">{qtyInStock}</span>
                              </div>
                              <div className="item-import-wrapper-main w-15">
                                <span className="title">{qtyOrdered}</span>
                              </div>
                              <div className="item-import-wrapper-main w-15">
                                <span className="title">
                                  {currencyFormatter.format(quotePrice)}
                                </span>
                              </div>
                            </div>
                        );
                      })
                    }
                </div>
              ) : null}
              {/* end qtyInStock */}
                {/* Start Out Of Stock */}
                {isAvailableOutOfStockItems?.length ? (
                  <>
                  <div className="images-with-title-div">
                    <PosImage src={images.outStock} alt="out-of-stock" />
                    <div className="icon-with-stock-title">
                      <span className="stock-title">
                        {isAvailableOutOfStockItems.length} Out of Stock
                      </span>
                      <span className="stock-description">
                        These items will be backordered
                      </span>
                    </div>
                  </div>
                <div className="stock-item-list">
                {isAvailableOutOfStockItems.map(({qtyOrdered,quoteItemId,posProductResponse,isSelected,quotePrice}) => {
                    const {
                      vendorName,
                      qtyInStock,
                      productName,
                      manufacturerCode,
                      thumbImg,
                    } = posProductResponse || {};
                    return (
                        <div
                          onClick={() => outOfStockOnClick(quoteItemId)}
                          className={`item-import-body-section ${
                            isSelected ? "item-isSelected-values" : ""
                          } `}
                          key={quoteItemId}
                        >
                          <div className="item-import-wrapper-main w-5">
                            <span className="title">
                              <Radio
                                disableRipple
                                className="radio-inp-focus"
                                checked={isSelected}
                                checkedIcon={
                                  <CheckCircleIcon
                                    fontSize="small"
                                    className="check-icon"
                                  />
                                }
                              />
                            </span>
                          </div>
                          <div className="item-import-wrapper-main w-35">
                            <span className="title image-with-title">
                              <PosImage src={thumbImg} alt="product-icon" />
                              <div className="product-title-div">
                                <span className="product-name">{productName}</span>
                                <span className="manufacture-code">{manufacturerCode}</span>
                              </div>
                            </span>
                          </div>
                          <div className="item-import-wrapper-main w-20">
                            <span className="title">{vendorName}</span>
                          </div>
                          <div className="item-import-wrapper-main w-15">
                            <span className="title">{qtyInStock}</span>
                          </div>
                          <div className="item-import-wrapper-main w-15">
                            <span className="title">{qtyOrdered}</span>
                          </div>
                          <div className="item-import-wrapper-main w-15">
                            <span className="title">
                              {quotePrice && <sup>$</sup>}
                              {quotePrice?.toFixed(2)}
                            </span>
                          </div>
                        </div>
                    );
                  })} </div> 
                  </> 
                  ) : null}
              {/* end Out Of Stock */}
              {/* Start Discontinued, Available in Stock */}
              {isDiscontinuedAvailableItems.length ? ( 
                <> 
                <div className="images-with-title-div">
                  <PosImage
                    src={images.discontinuedStockIcon}
                    alt="out-of-stock"
                  />
                  <div className="icon-with-stock-title">
                    <span className="stock-title">
                      {isDiscontinuedAvailableItems.length} Discontinued,
                      Available in Stock
                    </span>
                    <span className="stock-description">
                      These items cannot be backordered
                    </span>
                  </div>
                </div>
              <div className="stock-item-list">
              {isDiscontinuedAvailableItems.map(({qtyOrdered,posProductResponse,quotePrice, quoteItemId,isSelected}) => {
                  const {
                    vendorName,
                    qtyInStock,
                    productName,
                    manufacturerCode,
                    thumbImg,
                  } = posProductResponse || {};
                  return (
                      <div
                        onClick={() =>
                          DiscontinuedAvailableOnClick(quoteItemId)
                        }
                        className={`item-import-body-section ${
                          isSelected ? "item-isSelected-values" : ""
                        } `}
                        key={quoteItemId}
                      >
                        <div className="item-import-wrapper-main w-5">
                          <span className="title">
                            <Radio
                              disableRipple
                              className="radio-inp-focus"
                              checked={isSelected}
                              checkedIcon={
                                <CheckCircleIcon
                                  fontSize="small"
                                  className="check-icon"
                                />
                              }
                            />
                          </span>
                        </div>
                        <div className="item-import-wrapper-main w-35">
                          <span className="title image-with-title">
                            <PosImage src={thumbImg} alt="product-icon" />
                            <div className="product-title-div">
                              <span className="product-name">{productName}</span>
                              <span className="manufacture-code">{manufacturerCode}</span>
                            </div>
                          </span>
                        </div>
                        <div className="item-import-wrapper-main w-20">
                          <span className="title">{vendorName}</span>
                        </div>
                        <div className="item-import-wrapper-main w-15">
                          <span className="title">{qtyInStock}</span>
                        </div>
                        <div className="item-import-wrapper-main w-15">
                          <span className="title">{qtyOrdered}</span>
                        </div>
                        <div className="item-import-wrapper-main w-15">
                          <span className="title">
                            {quotePrice && <sup>$</sup>}
                            {quotePrice?.toFixed(2)}
                          </span>
                        </div>
                      </div>
                  );
                })} </div>  
                </> ) 
                :null}
              {/* end Discontinued, Available in Stock */}
              {/* Start Discontinued, Out of Stock */}
              {isDiscontinuedOutOfStock?.length ? (
                <>
                <div className="images-with-title-div">
                  <PosImage src={images.disContinue} alt="out-of-stock" />
                  <div className="icon-with-stock-title">
                    <span className="stock-title">
                      {isDiscontinuedOutOfStock?.length} Discontinued, Out of
                      Stock
                    </span>
                    <span className="stock-description">
                      These items can no longer br purchased
                    </span>
                  </div>
                </div>
              {isDiscontinuedOutOfStock.map(({quotePrice,posProductResponse,quoteItemId,qtyOrdered}) => {
                  const {
                    vendorName,
                    qtyInStock,
                    productName,
                    manufacturerCode,
                    thumbImg,
                  } = posProductResponse || {};
                  return (
                    <div key={quoteItemId}>
                      <div className="item-import-body-section">
                        <div className="item-import-wrapper-main w-5">
                          <span className="title" />
                        </div>
                        <div className="item-import-wrapper-main w-35">
                          <span className="title image-with-title">
                            <PosImage src={thumbImg} alt="product-icon" />
                            <div className="product-title-div">
                              <span className="product-name">{productName}</span>
                              <span className="manufacture-code">{manufacturerCode}</span>
                            </div>
                          </span>
                        </div>
                        <div className="item-import-wrapper-main w-20">
                          <span className="title">{vendorName}</span>
                        </div>
                        <div className="item-import-wrapper-main w-15">
                          <span className="title">{qtyInStock}</span>
                        </div>
                        <div className="item-import-wrapper-main w-15">
                          <span className="title">{qtyOrdered}</span>
                        </div>
                        <div className="item-import-wrapper-main w-15">
                          <span className="title">
                            {quotePrice && <sup>$</sup>}
                            {quotePrice?.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }) } </>
                ) : null}
              {/* end Discontinued, Out of Stock */}
            </div>)}
          </div>
          <SelectItemImportFooter />
        </>
      )}
    </div>
  );
};
export default SelectItemImport;
