import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Will remove 'any' once we get proper data from BE
export interface ProductStockSliceI {
  isCreateProductsStock: boolean;
  createProductsStockData: any;
  createProductsStockError: any;
  isUpdateProductsStock: boolean;
  UpdateProductsStockData: any;
  UpdateProductsStockError: any;
  isDeleteProductsStock: boolean;
  deleteProductsStockData: any;
  deleteProductsStockError: any;
  isCreateProductsSMinMaxStock: boolean,
  getProductsStockMinMaxData : any,
  getProductsStockMinMaxErrorData: null,
}

const initialState: ProductStockSliceI = {
    isCreateProductsStock: false,
    createProductsStockData: null,
    createProductsStockError: null,
    isUpdateProductsStock: false,
    UpdateProductsStockData: null,
    UpdateProductsStockError: null,
    isDeleteProductsStock: false,
    deleteProductsStockData: null,
    deleteProductsStockError: null,
    isCreateProductsSMinMaxStock: false,
    getProductsStockMinMaxData : null,
    getProductsStockMinMaxErrorData: null,
};

export const productsStockSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    createProductsStockStart: (state) => {
      state.createProductsStockData = null;
      state.isCreateProductsStock = true;
    },
    createProductsStockSuccess: (state, action: PayloadAction<any>) => {
      state.createProductsStockData = action.payload;
      state.isCreateProductsStock = false;
    },
    createProductsStockError: (state, action: PayloadAction<any>) => {
      state.createProductsStockError = action.payload;
      state.createProductsStockData = null;
      state.isCreateProductsStock = false;
    },
    updateProductsStockStart: (state) => {
      state.UpdateProductsStockData = null;
      state.isUpdateProductsStock = true;
    },
    updateProductsStockSuccess: (state, action: PayloadAction<any>) => {
      state.UpdateProductsStockData = action.payload;
      state.isUpdateProductsStock = false;
    },
    updateProductsStockError: (state, action: PayloadAction<any>) => {
      state.UpdateProductsStockError = action.payload;
      state.UpdateProductsStockData = null;
      state.isUpdateProductsStock = false;
    },
    deleteProductsStockStart: (state) => {
      state.deleteProductsStockData = null;
      state.isDeleteProductsStock = true;
    },
    deleteProductsStockSuccess: (state, action: PayloadAction<any>) => {
      state.deleteProductsStockData = action.payload;
      state.isDeleteProductsStock = false;
    },
    deleteProductsStockError: (state, action: PayloadAction<any>) => {
      state.deleteProductsStockError = action.payload;
      state.deleteProductsStockData = null;
      state.isDeleteProductsStock = false;
    },
    getProductsStockMinMaxStart: (state) => {
      state.getProductsStockMinMaxData = null;
      state.isCreateProductsSMinMaxStock = true;
    },
    getProductsStockMinMaxSuccess: (state, action: PayloadAction<any>) => {
      state.getProductsStockMinMaxData = action.payload;
      state.isCreateProductsSMinMaxStock = false;
    },
    getProductsStockMinMaxError: (state, action: PayloadAction<any>) => {
      state.getProductsStockMinMaxErrorData = action.payload;
      state.getProductsStockMinMaxData = null;
      state.isCreateProductsSMinMaxStock = false;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  createProductsStockStart,
  createProductsStockSuccess,
  createProductsStockError,
  updateProductsStockStart,
  updateProductsStockSuccess,
  updateProductsStockError,
  deleteProductsStockStart,
  deleteProductsStockSuccess,
  deleteProductsStockError,
  getProductsStockMinMaxStart,
  getProductsStockMinMaxSuccess,
  getProductsStockMinMaxError
} = productsStockSlice.actions;

export default productsStockSlice.reducer;
