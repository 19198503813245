import { FormikProps} from "formik";

import { FormModel, GetRowsActiveDetailsProp, RowProps } from "constants/interfaces"
import { Dispatch, SetStateAction } from "react";

export interface AddProps {
    rowsActiveData?: RowProps[] | GetRowsActiveDetailsProp[];
    unitData?: RowProps[] | GetRowsActiveDetailsProp[];
    shelfData?: RowProps[] | GetRowsActiveDetailsProp[];
    adjustStockRowOnchange?: (e:string) => void;
    adjustStockUnitOnchange?: (e:string) => void;
    setModalVisible?: (e: boolean) => void;
    setQuantityAdd?: (e: number) => void
    setRowName?: (e: string) => void;
    setUnitName?: (e: string) => void;
    setShelfName?: (e: string) => void;
    qtyOnLocationData?: number
    tabValue?: number,
    addFormRef?: React.MutableRefObject<FormikProps<FormModel>>
    rowName?:string;
    units?: string[];
    setUnits?: Dispatch<SetStateAction<any[]>>;
    unitsErrors?: string[];
    setUnitsErrors?: Dispatch<SetStateAction<any[]>>;
    unitsLoading?: boolean;
    setUnitsLoading?: Dispatch<SetStateAction<boolean>>;
    shelves?:string[];
    setShelves?: Dispatch<SetStateAction<any[]>>;
    shelvesErrors?: string[];
    setShelvesErrors?: Dispatch<SetStateAction<any[]>>;
    shelvesLoading?: boolean;
    setShelvesLoading?: Dispatch<SetStateAction<boolean>>;
}

export interface MoveProps {
    rowsActiveData?: RowProps[] | GetRowsActiveDetailsProp[];
    unitData?: RowProps[] | GetRowsActiveDetailsProp[];
    shelfData?: RowProps[] | GetRowsActiveDetailsProp[];
    adjustStockRowOnchange?: (e: string) => void;
    adjustStockUnitOnchange?: (e: string) => void;
    setModalVisible?: (e: boolean) => void;
    tabValue?: number;
    setFromRowName?: (e: string) => void;
    setFromUnitName?: (e: string) => void;
    setFromShelfName?: (e: string) => void;
    setToRowName?: (e: string) => void;
    setToUnitName?: (e: string) => void;
    setToShelfName?: (e: string) => void;
    fromQtyOnLocationData?: number;
    moveFormRef?: React.MutableRefObject<FormikProps<FormModel>>;
    fromRowName ?: string;
    toShelfName?: string;
    units?: string[];
    setUnits?: Dispatch<SetStateAction<any[]>>;
    unitsErrors?: string[];
    setUnitsErrors?: Dispatch<SetStateAction<any[]>>;
    unitsLoading?: boolean;
    setUnitsLoading?: Dispatch<SetStateAction<boolean>>;
    shelves?:string[];
    setShelves?: Dispatch<SetStateAction<any[]>>;
    shelvesErrors?: string[];
    setShelvesErrors?: Dispatch<SetStateAction<any[]>>;
    shelvesLoading?: boolean;
    setShelvesLoading?: Dispatch<SetStateAction<boolean>>;
}

export interface RemoveProps {
    rowsActiveData?: RowProps[];
    unitData?: RowProps[];
    shelfData?: RowProps[];
    adjustStockRowOnchange?: (e: string) => void;
    adjustStockUnitOnchange?: (e: string) => void;
    setModalVisible?: (e: boolean) => void;
    setRemoveQuantity: (e: number) => void;
    tabValue?: number;
    setRemoveRowName?: (e: string) => void;
    setRemoveUnitName?: (e: string) => void;
    setRemoveShelfName?: (e: string) => void;
    removeQtyOnLocationData?: number;
    value?: number;
    removeFormRef?: React.MutableRefObject<FormikProps<FormModel>>;
    setRemoveQuantityValue: (e: string) => void;
    removeRowName ?:string;
    units?: string[];
    setUnits?: Dispatch<SetStateAction<any[]>>;
    unitsErrors?: string[];
    setUnitsErrors?: Dispatch<SetStateAction<any[]>>;
    unitsLoading?: boolean;
    setUnitsLoading?: Dispatch<SetStateAction<boolean>>;
    shelves?:string[];
    setShelves?: Dispatch<SetStateAction<any[]>>;
    shelvesErrors?: string[];
    setShelvesErrors?: Dispatch<SetStateAction<any[]>>;
    shelvesLoading?: boolean;
    setShelvesLoading?: Dispatch<SetStateAction<boolean>>;
}

export enum AdjustStockTab{
    Add = 0,
    Remove = 1,
    Move = 2,
}