/* eslint-disable */
import "@fontsource/inter";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import "@fontsource/inter";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { PosResponseProps, RootState } from "models";
import { ProductSuggestionProps } from "./type";

import images from "constants/images";

import { Divider, PosButton, PosImage } from "apps/pos/Common/uiCore";
import { Button } from "components";

import { availabilityStatus, commonConstants } from "apps/product/helper/commonConstants";
import { hashRoute } from "constants/enum";
import { CloseIcon, FileUploadIcon, KeyboardIcon } from "constants/icons";
import { keys } from "utils/helper";

import texts from "apps/pos/locales";
import { getChangePriceInfo } from "sagas/persistReducer/actions";
import { stockByWarehouseStart } from "sagas/pos/actions";

import PosBox from "../Common/uiCore/posBox/box";
import PosContainer from "../Common/uiCore/posContainer/container";
import "./productSuggestion.scss";
import useHeightStyles from "./productSuggestionStyle";
import StyledTableCell from "./style";

const ProductSuggestion = ({
  suggestionListVisible,
  addProductPageRef,
  suggestedProducts,
  showProductIncart,
  singleSuggestedLength,
  importQuote,
  searchProductsData,
  classes,
  tableRowRef,
  handleOpen,
  loader,
  handleScroll,
  handleProductSelect,
  searchInputHeightCount,
  setNotesOpen,
  setNotesPlace,
  settingIconDivHeight,
  heightClasses,
  selectAddProductWrapper,
  addProductPageProductSearchRef,
  importQuotePageProductSearchRef,
  addProduct,
  handleCloseDrawer,
  setSelectItemImportModel,
  universalSearchPage,
  moreUniversalSearchAvailable,
  pageSize,
  setUniversalSearchPage,
  options,
  filteredSearchProduct,
  remove
}: ProductSuggestionProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const hasHash = location.hash;
  const {
    searchProducts,
    suggestedProductsData,
    stockWarehouseData,
    error,
    selectedProductIndex,
    searchProductsTotal,
    productDataCount,
    headerHeightCount,
    isProductSearch
  } = useSelector<RootState, PosResponseProps>((state) => state.pos);

  const { boltIcon } = images;
  const { errorsText } = texts;

  const handleSearch = (product) => {
    setNotesOpen(true);
    setNotesPlace(true);
    showProductIncart(product?.productId);
    dispatch(stockByWarehouseStart([product?.productId]));
    dispatch(getChangePriceInfo(false));
  };

  // start

  const headerSecRef = useRef(null);
  const imageDivRef = useRef(null);
  const codeDivRef = useRef(null);
  const productDivRef = useRef(null);
  const vendorDivRef = useRef(null);
  const muDivRef = useRef(null);
  const cityDivRef = useRef(null);

  const [headerSecHeight, setHeaderSecHeight] = useState(0);
  const [imageSecWidth, setImageSecWidth] = useState(0);
  const [codeSecWidth, setCodeSecWidth] = useState(0);
  const [productSecWidth, setProductSecWidth] = useState(0);
  const [vendorSecWidth, setVendorSecWidth] = useState(0);
  const [muSecWidth, setMuSecWidth] = useState(0);
  const [citySecWidth, setCitySecWidth] = useState(0);

  const getDivWidth = () => {
    setHeaderSecHeight(headerSecRef?.current?.offsetHeight);
    setImageSecWidth(imageDivRef?.current?.offsetWidth);
    setCodeSecWidth(codeDivRef?.current?.offsetWidth);
    setProductSecWidth(productDivRef?.current?.offsetWidth);
    setVendorSecWidth(vendorDivRef?.current?.offsetWidth);
    setMuSecWidth(muDivRef?.current?.offsetWidth);
    setCitySecWidth(cityDivRef?.current?.offsetWidth);
  };
  useEffect(() => {
    getDivWidth();
  }, [filteredSearchProduct?.length]);

  useEffect(() => {
    window?.addEventListener("resize", getDivWidth);
    return () => {
      window?.removeEventListener("resize", getDivWidth);
    };
  }, [
    imageDivRef,
    codeDivRef,
    productDivRef,
    vendorDivRef,
    muDivRef,
    cityDivRef,
    headerSecRef,
  ]);

  // end
  const handleKeyPress = (e) => {
    const { keyCode } = e;
    const { q } = keys;
    if (
      (keyCode === q && e.altKey) && document.activeElement !== importQuotePageProductSearchRef?.current && !addProduct
    ) {
      handleCloseDrawer()
      handleOpen();
      e.preventDefault();
    }

  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [addProduct]);

  const noSearchData = headerHeightCount - searchInputHeightCount;
  const withSearchData =
    headerHeightCount - searchInputHeightCount + (headerSecHeight ?? 56) + 57;

  const suggestionClasses = useHeightStyles({
    searchProductsData,
    withSearchData,
    noSearchData,
    settingIconDivHeight,
    headerHeightCount,
  });

  let arrayData = 0;
  function handleKeyUp(e) {
    const { downarrow, uparrow } = keys;
    if (e.keyCode === downarrow) {
      if (arrayData === suggestedProductsData?.length) {
        arrayData = 0;
      } else {
        arrayData = arrayData + 1;
      }
    }
    if (e.keyCode === uparrow) {
      if (arrayData === 0) {
        arrayData = suggestedProductsData?.length;
      } else {
        arrayData = arrayData - 1;
      }
    }
  }
  useEffect(() => {
    document?.addEventListener("keydown", handleKeyUp);
  }, [arrayData]);

  useEffect(() => {
    if (hasHash === hashRoute.search) return;

    const handleProductKeyDown = (event: KeyboardEvent) => {
      const { keyCode } = event;

      if (keyCode === keys.uparrow || keyCode === keys.downarrow) {
        event.preventDefault();
        const tableElement = document.getElementById("product-mui-table");

        if (tableElement) {
          const tableContainer = tableElement.closest(
            ".MuiTableContainer-root"
          ) as HTMLElement;
          if (tableContainer) {
            const { scrollTop, clientHeight, scrollHeight } = tableContainer;
            const rowHeight =
              tableContainer.querySelectorAll("tr")[0]?.offsetHeight || 0;

            if (keyCode === keys.uparrow) {
              tableContainer.scrollTop = Math.max(scrollTop - rowHeight, 0);
            } else if (keyCode === keys.downarrow) {
              tableContainer.scrollTop = Math.min(
                scrollTop + rowHeight,
                scrollHeight - clientHeight
              );
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleProductKeyDown);

    return () => {
      document.removeEventListener("keydown", handleProductKeyDown);
    };
  }, [hasHash]);

  const getShortcutString = () => {
    const shortcutString = `Use key${suggestedProductsData?.length === 1 ? "" : "s"
      }
    ${singleSuggestedLength ? "1" : "1 to " + suggestedProductsData?.length} 
    on your keyboard to directly search for the item`;

    return shortcutString;
  };

  useEffect(() => {
    if (!filteredSearchProduct) return;
    const observerOptions = {
      root: document.querySelector(".body"),
      rootMargin: "0px",
      threshold: 0,
    };
    // watch all tips when they enter the viewport
    const watchTips = (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        setUniversalSearchPage((prev) => prev + 1);
      });
    };

    // observe the tips footer to lazily load more available tips
    const tipsObserver = new IntersectionObserver(watchTips, observerOptions);

    setTimeout(() => {
      const tipItems = document.querySelectorAll(".more-results-product");
      tipItems.forEach((item) => {
        tipsObserver.observe(item);
      });
    }, 0);

    // cleanup
    return () => {
      tipsObserver.disconnect();
    };
  }, [filteredSearchProduct, moreUniversalSearchAvailable]);

  const {
    searchProduct, productFound, notFound
  } = commonConstants

  return (
    <div
      id="cart-wrapper"
      className={`cart-wrapper ${suggestionListVisible ? "empty-cart-wrapper" : ""
        } ${suggestionListVisible
          ? suggestionClasses.fixedSuggestionsDiv
          : suggestionClasses.searchedProducts
        }
                ${suggestionClasses.mainSuggestionStyle}
                `}
    >
      {suggestionListVisible ? (
        <div className="product-search-suggestions search-product-suggestion-container">
          {suggestedProductsData?.length ? (
            <div className="title">
              <span>Suggestions</span>
            </div>
          ) : (
            <></>
          )}
          <div className="suggestions-list" ref={addProductPageRef}>
            {suggestedProducts?.map((product, index) => {
              return (
                <div key={`suggestion ${index}`}>
                  <Button
                    className="suggestion-list-item btn cart-page-product-suggestion suggetion-focus "
                    onClick={() => {
                      handleSearch(product);
                    }}
                  >
                    <div className="suggestion-info">
                      <div className="pos-product-image suggestion-image">
                        <PosImage src={product?.thumbImg} alt="back" />
                      </div>
                      <span className="name">{product?.productName}</span>
                    </div>

                    <div className="keyboard-shortcut-block">{index + 1}</div>
                  </Button>
                </div>
              );
            })}
          </div>
          {suggestedProductsData?.length ? (
            <>
              <div className="hint">
                <KeyboardIcon />
                <span className="hint-copy">{getShortcutString()}</span>
              </div>
              <div className="divider-main-div">
                <Divider />
                <div className="import-btn">
                  <PosButton
                    startIcon={<FileUploadIcon />}
                    onClick={() => handleOpen()}
                    onKeyDown={(e) => handleKeyPress(e)}
                    variant="contained"
                    className="import-quote-btn btn"
                  >
                    {importQuote}
                  </PosButton>
                </div>
              </div>
            </>
          ) : null}
        </div>
      ) : (
        <>
          {loader ? (
            <PosBox sx={{ width: "100%" }}>
              {error === errorsText ? (
                <div className="products-found product-found-info-cust">
                  <div className="product-found-text">
                    <span className="int-14-reg">
                      {notFound}
                      {
                        options?.value && options?.value !== availabilityStatus["5"] ? (
                          <div className="option-list-view pl-10">
                            <PosButton
                              key={options.key}
                              value={options.value}
                              variant="outlined"
                              color="default"
                              className="btn rounded"
                              onClick={() => remove()}
                              endIcon={<CloseIcon />}
                            >
                              {options.value}
                            </PosButton>
                          </div>
                        ) : null
                      }
                    </span>
                  </div>
                  <div className="horizontal-liner-sp product-found-horizontal-line" />
                </div>
              ) : (
                <></>
              )}
            </PosBox>
          ) : (
            <div className="list-title w-100">
              {filteredSearchProduct?.length ? (
                <PosContainer
                  className={`fixed-cart-pos-header searchHeader-fix-top ${heightClasses.fixedHeader} ${suggestionClasses.productFoundStyle}`}
                >
                  <div id="cart-wrapper" className={`cart-wrapper mb-0`}>
                    <div className={`products-found product-found-info-cust`}>
                      <div className="product-found-text product-found-header">
                        {/* {isProductSearch ?
                          <PosImage className="ml-1" src={images.searchFilterIcon} alt="searchFilterIcon" />
                          : null} */}
                        <span className="int-14-reg">
                          {
                            // isProductSearch ? `${searchProduct}`
                            // :
                            `${searchProductsTotal} ${productFound}`
                          }
                          {
                            options?.value && options?.value !== availabilityStatus["5"] ? (
                              <div className="option-list-view">
                                <PosButton
                                  key={options.key}
                                  value={options.value}
                                  variant="outlined"
                                  color="default"
                                  className="btn rounded"
                                  onClick={() => remove()}
                                  endIcon={<CloseIcon />}
                                >
                                  {options.value}
                                </PosButton>
                              </div>
                            ) : null
                          }
                        </span>
                      </div>
                      <div className="horizontal-liner-sp product-found-horizontal-line" />
                    </div>
                  </div>
                </PosContainer>
              ) : null}
              <div className="scroll-styling product-search-scroll-styling">
                <div
                  className="search-tab-block"
                  data-tabname={pageSize}
                  key={pageSize}
                >
                  <TableContainer>
                    {/* <InfiniteScroll
                    className="pb-60"
                    dataLength={filteredSearchProduct?.length}
                    next={handleScroll}
                    height={620}
                    hasMore={searchProductsTotal !== productDataCount}
                    loader={<></>}
                    scrollThreshold={0.9}
                  > */}
                    <Table
                      className={classes.root}
                      stickyHeader
                      aria-label="sticky table"
                      id="product-mui-table"
                    >
                      {filteredSearchProduct?.length ? (
                        <PosContainer
                          ref={headerSecRef}
                          className={`fixed-cart-pos-header search-table-header ${heightClasses.fixedHeader} ${suggestionClasses.productDataHeaderStyle}`}
                        >
                          <TableHead
                            className={`sticky-table-header  ${suggestionClasses.tableHeaderStyle}`}
                          >
                            <TableRow
                              className={`product-search-table-header-row ${suggestionClasses.tableHeaderStyle}`}
                            >
                              <StyledTableCell align="left"></StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{ width: `${imageSecWidth}px` }}
                              ></StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{ width: `${codeSecWidth}px` }}
                              >
                                NAME, CODE
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{ width: `${productSecWidth}px` }}
                              >
                                DESCRIPTION
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{ width: `${vendorSecWidth}px` }}
                              >
                                VENDOR
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{ width: `${muSecWidth}px` }}
                              >
                                PRICE U/M
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                style={{ width: `${citySecWidth}px` }}
                              >
                                QTY. PER WAREHOUSE
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                        </PosContainer>
                      ) : null}

                      {filteredSearchProduct?.length ? (
                        <TableBody className="table-border-styling searched-product-data-styling">
                          {filteredSearchProduct?.length &&
                            filteredSearchProduct?.map((product, index) => {
                              return (
                                <TableRow
                                  onClick={() => {
                                    setNotesOpen(true);
                                    setNotesPlace(true);
                                    handleProductSelect(product);
                                  }}
                                  key={`product${index}`}
                                  className={`${index === selectedProductIndex
                                    ? "frequently-used-schedule frequently-used-schedule-index"
                                    : "frequently-used-schedule"
                                    } search-result-item`}
                                  ref={tableRowRef[product.productId]}
                                  id={`tableRowRef${index}`}
                                >
                                  <StyledTableCell align="left"></StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    ref={imageDivRef}
                                  >
                                    <div className={`text-center user-avatar`}>
                                      <PosImage
                                        src={product?.thumbImg}
                                        alt="back"
                                        height={40}
                                        width={40}
                                      />

                                      {product?.isPopular ? (
                                        <PosImage
                                          src={boltIcon}
                                          className="search-list-icon"
                                          alt="bolt1"
                                        />
                                      ) : null}
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    ref={codeDivRef}
                                  >
                                    <span className="int-16-reg normal-content d-block lh-sm">
                                      {product.productName}
                                    </span>
                                    <span className="int-14-reg normal-content d-block gray_text lh-sm mt-1">
                                      {product.manufacturerCode}
                                    </span>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    ref={productDivRef}
                                  >
                                    <span className="int-16-reg normal-content lh-sm">
                                      {product.description}
                                    </span>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    align="left"
                                    ref={vendorDivRef}
                                  >
                                    <span className="int-16-reg lh-sm">
                                      {product.vendorName
                                        ? product.vendorName
                                        : "-"}
                                    </span>
                                  </StyledTableCell>
                                  <StyledTableCell align="left" ref={muDivRef}>
                                    <span className="int-16-reg lh-sm">
                                      {`${product.muName} (${product.priceMU})`}
                                    </span>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="left"
                                    className="min-width-cell"
                                    ref={cityDivRef}
                                  >
                                    {stockWarehouseData?.length
                                      ? stockWarehouseData
                                        ?.find(
                                          (item) =>
                                            item.productId ===
                                            product?.productId
                                        )
                                        ?.stockWarehouses?.map(
                                          (
                                            {
                                              stockwarehouseId,
                                              stockWarehouseName,
                                              unitQtyAvailable,
                                            },
                                            index
                                          ) => {
                                            return (
                                              <div key={index}>
                                                <div key={stockwarehouseId}>
                                                  <span className="int-16-reg normal-content lh-sm">
                                                    {stockWarehouseName}
                                                  </span>
                                                  <span className="int-16-reg ml-3 lh-sm">
                                                    {unitQtyAvailable}
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                      : null}
                                  </StyledTableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      ) : null}
                    </Table>
                    {/* may need 
                     <div className="search-button">
                      {isShowMoreResult && (
                          <PosButton
                            className="loader-button btn-group btn-label-show"
                            onClick={handleScroll}
                            variant="contained"
                          >
                            Show more results
                          </PosButton>
                        )}
                    </div> */}
                    {/* </InfiniteScroll> */}
                    {searchProductsData && moreUniversalSearchAvailable ? (
                      <div
                        className="more-results-product"
                        data-container-tab={pageSize}
                        data-page={universalSearchPage}
                      ></div>
                    ) : null}
                  </TableContainer>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductSuggestion;
