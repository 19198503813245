import { springBoardText } from "constants/constant";

import "apps/springboard/Component/SBSelect/SBSelect.scss";

const SBSelect = (props) => {
  const {
    salesPerson,
    onSelected,
    selectedQuoteByValue
  } = props;

  const { everyone } = springBoardText
  return (
      <select name="salesperson" id="salesperson-selection" onChange={onSelected} value={selectedQuoteByValue}>
        <option value={0}>{everyone}</option>
          {salesPerson && salesPerson?.map(({label, value}) => {
            return (
              <option value={value} label={label} key={value}>{label}</option>
            )
          })}
      </select>
  );
};

export default SBSelect;