import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import jwt_decode from "jwt-decode";
import { useForm } from "react-hook-form";
import Cookies from "universal-cookie";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import { AdminButton, ButtonContainer2, Input } from "components";
import { adminMessage } from "constants/message";
import { AuthenticatedUser, RootState, User } from "models";
import { ResponceStatus } from "enums/status";

import { inviteStart } from "sagas/authenticatedUser/actions";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";

import "styles/common.css";

interface AddUserFormData {
  addFirstName: string;
  addLastName: string;
  addUserEmail: string;
}

interface AddUserFormProps {
  user?: User;
  onError?: (text: string) => void;
  onSuccess?: (text: string) => void;
  mesaage?: (text: string) => void;
  snackBarSet?: (text: boolean) => void;
}

export function AddUserForm({
  user,
  onError,
  onSuccess,
}: AddUserFormProps) {
  const [buttonDisable, setButtonDisable] = useState(false);
  const dispatch = useDispatch();
  const { success, error } = ResponceStatus;
  
  const { inviteSuccess , error : errorMessage } = useSelector<
  RootState,
  AuthenticatedUser
>((state) => state.authenticatedUser);
  // const notes = useSelector((state) => getNotes(state.app, "notice"));

  const { register, handleSubmit, formState, reset, setValue } =
    useForm<AddUserFormData>();

  useDeepCompareEffectNoCheck(() => {
    if (user) {
      const [fname, lname] = user?.fullName.split(" ");
      setValue("addFirstName", fname);
      setValue("addLastName", lname);
      setValue("addUserEmail", user?.email);
    }
  }, [user]);

  const onSubmit = (data: AddUserFormData) => {
    setButtonDisable(true)

    const cookies = new Cookies();
    const token = cookies.get("Authorization");
    const decoded: {
      id: string;
    } = jwt_decode(token || "");
    const decodedData = JSON.parse(decoded?.id);

    const managerId = decodedData?.DefaultLoginId;
    const { addFirstName, addLastName, addUserEmail } = data
    const body = {
        managerId: Number(managerId),
        firstName: addFirstName,
        lastName: addLastName,
        email: addUserEmail,
    }
    dispatch(inviteStart(body));
  };

  useEffect(() => {
    if(inviteSuccess === success) {
      setButtonDisable(false);
      dispatch(snakbarShow({message : adminMessage.inviteSent}))
      onSuccess(adminMessage.inviteSent);
      reset();
    }
    if(inviteSuccess === error) {
      setButtonDisable(false);
      dispatch(snakbarShow({message : errorMessage}))
      onError(errorMessage);
    }
  },[inviteSuccess])

  return (
    <>
      <Fragment>
        <div className="ml-4">
          <span className="fs-24 roboto-med black_text">Add a new User</span>
          <p
            className="mt-3 mb-5 fs-16 inter-reg black_text pr-50"
          >
            User will receive an email requesting to register
            <br /> themself into Jumper. They will be able to change
            <br /> their username and also set a new password.
          </p>
        </div>
        <form
          id="addFirstForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            id="addFirstName"
            name="addFirstName"
            label="First name"
            type="text"
            placeholder="John"
            register={register({ required: true })}
            className="mb-0"
          />
          {formState.errors.addFirstName ? (
            <div>
              <span className="error-message">
                First name is required
              </span>
            </div>
          ) : ""}
          <div className="add-user-form-div"></div>
          <Input
            id="addLastName"
            name="addLastName"
            label="Last name"
            type="text"
            placeholder="Smith"
            register={register({ required: true })}
            className="mb-0"
          />
          {formState.errors.addLastName ? (
            <div>
              <span className="error-message">
                Last name is required
              </span>
            </div>
          ) : ""}
          <div className="add-user-form-div"></div>
          <Input
            id="addUserEmail"
            name="addUserEmail"
            label="Email address"
            type="email"
            placeholder="john@smith.com"
            register={register({ required: true })}
            className="mb-0"
          />
          {formState.errors.addUserEmail ? (
            <div>
              <span className="error-message">
                Email is required
              </span>
            </div>
          ) : ""}
          <div className="pb-40"></div>
          <ButtonContainer2>
            <AdminButton
              type="submit"
              className={`button-wrapper button-primary button-accent button-wide ${buttonDisable ? 'button-is-loading' : ""}`}
              label="SEND INVITE"
              disabled={buttonDisable}
              onClick={() => {
                // Required Later
              }}
            />
          </ButtonContainer2>
        </form>
      </Fragment>
    </>
  );
}
