import { ChangeEvent, useRef } from "react";
import { useDispatch } from "react-redux";

import IconButton from "apps/product/Modals/Elements/IconButton";
import Filters from "apps/product/modules/productListingContainer/components/productListing/components/ProductFilterToogle/Filters";
import Icon from "apps/springboard/Component/SBItems/elements/Icon/Icon";

import { commonConstants } from "apps/product/helper/commonConstants";

import { searchProductsClear } from "apps/product/modules/productListingContainer/redux/product.slice";

import { setSearchKeyword } from "apps/product/modules/productListingContainer/redux/productFilters.slice";
import "./style.scss";

const ProductSearchFilter = ({
  selectedLayoutType,
  setlayoutType,
  handleSearch,
  keyword,
  setKeyword,
  handleFilter,
  filter,
}) => {
  const dispatch = useDispatch();
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && keyword) {
      dispatch(searchProductsClear());
      handleSearch(1, 20);
    }
  };
  const handleChanges = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchKeyword(e.target.value))
    setKeyword(e.target.value);
  };
  const searchInputRef = useRef(null);

  const clearSearch = () => {
    dispatch(setSearchKeyword(""))
    setKeyword("");
    searchInputRef.current.focus();
  };

  return (
    <div className="product-search-filter-container">
      <div className="product-filter-search-wrapper">
        <Icon name="search" className="search-icon" />
        <input
          type="text"
          placeholder={commonConstants.productSearchBarPlaceholder}
          value={keyword}
          onChange={handleChanges}
          ref={searchInputRef}
          autoFocus
          onKeyPress={handleKeyPress}
        />

        {keyword ? (
          <IconButton
            icon="close"
            type="tertiary"
            title="Clear search"
            classes="clear-search-btn"
            onClick={clearSearch}
            size="32"
          />
        ) : null}
      </div>
      <div>
        <Filters
          handleFilter={handleFilter}
          filter={filter}
          selectedLayoutType={selectedLayoutType}
          setlayoutType={setlayoutType}
        />
      </div>
    </div>
  );
};

export default ProductSearchFilter;
