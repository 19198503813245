import * as constants from "./constants";

export function getAllUsers(payload :number) {
  return {
    type: constants.GET_ALL_USERS_START,
    payload
  };
}

export function getAllUsersSuccess(payload: any) {
  return {
    type: constants.GET_ALL_USERS_SUCCESS,
    payload,
  };
}

export function getAllUsersError(payload: string) {
  return {
    type: constants.GET_ALL_USERS_ERROR,
    payload,
  };
}

export function editUserDetails(payload) {
  return {
    type: constants.EDIT_USERS_DETAILS_START,
    payload,
  };
}

export function editUserDetailsSuccess(payload: any) {
  return {
    type: constants.EDIT_USERS_DETAILS_SUCCESS,
    payload,
  };
}

export function editUserDetailsError(payload: string) {
  return {
    type: constants.EDIT_USERS_DETAILS_ERROR,
    payload,
  };
}

export function editUserDetailsCancel() {
  return {
    type: constants.EDIT_USERS_DETAILS_CANCEL,
  };
}

export function editPermissions(payload) {
  return {
    type: constants.EDIT_PERMISSIONS_START,
    payload,
  };
}

export function editPermissionsSuccess(payload: any) {
  return {
    type: constants.EDIT_PERMISSIONS_SUCCESS,
    payload,
  };
}

export function editPermissionsError(payload: string) {
  return {
    type: constants.EDIT_PERMISSIONS_ERROR,
    payload,
  };
}

export function getAwaitingApprovalUsers(payload :number) {
  return {
    type: constants.GET_AWAITING_APPROVAL_START,
    payload
  };
}

export function getAwaitingApprovalUsersSuccess(payload: any) {
  return {
    type: constants.GET_AWAITING_APPROVAL_SUCCESS,
    payload,
  };
}

export function getAwaitingApprovalUsersError(payload: string) {
  return {
    type: constants.GET_AWAITING_APPROVAL_ERROR,
    payload,
  };
}

export function getInvitedUsers(payload: number) {
  return {
    type: constants.GET_INVITED_START,
    payload,
  };
}

export function getInvitedUsersSuccess(payload: any) {
  return {
    type: constants.GET_INVITED_SUCCESS,
    payload,
  };
}

export function getInvitedUsersError(payload: string) {
  return {
    type: constants.GET_INVITED_ERROR,
    payload,
  };
}

export function rejectUser(payload) {
  return {
    type: constants.REJECT_USER_START,
    payload,
  };
}

export function rejectUserSuccess(payload: any) {
  return {
    type: constants.REJECT_USER_SUCCESS,
    payload,
  };
}

export function rejectUserError(payload: string) {
  return {
    type: constants.REJECT_USER_ERROR,
    payload,
  };
}

export function reviewUserStart(payload) {
  return {
    type: constants.REVIEW_USER_START,
    payload,
  };
}

export function reviewUserEnd() {
  return {
    type: constants.REVIEW_USER_END,
  };
}

export function approveUser(payload) {
  return {
    type: constants.APPROVE_USER_START,
    payload,
  };
}

export function approveUserSuccess(payload: any) {
  return {
    type: constants.APPROVE_USER_SUCCESS,
    payload,
  };
}

export function approveUserError(payload: string) {
  return {
    type: constants.APPROVE_USER_ERROR,
    payload,
  };
}

export function removeUser(payload: any) {
  return {
    type: constants.REMOVE_USER_START,
    payload,
  };
}

export function removeUserSuccess(payload: any) {
  return {
    type: constants.REMOVE_USER_SUCCESS,
    payload,
  };
}

export function removeUserError(payload: string) {
  return {
    type: constants.REMOVE_USER_ERROR,
    payload,
  };
}

export function resetUserFlag() {
  return {
    type: constants.RESET_USER_FLAG,
  };
}

export function setEditUserDetailFalse() {
  return {
    type: constants.EDIT_USERS_FALSE
  }
}

export function userMessageRemove() {
  return {
    type: constants.USER_MESSAGE_REMOVE,
  };
}

export function searchValue(payload: string) {
  return {
    type: constants.SEARCH_USER_ADD,
    payload,
  }
}

export function getAllSearchUsers(payload : any) {
  return {
    type: constants.GET_ALL_SEARCH_USERS_START,
    payload
  };
}

export function getAllSearchUsersSuccess(payload: any) {
  return {
    type: constants.GET_ALL_SEARCH_USERS_SUCCESS,
    payload,
  };
}

export function getAllSearchUsersError(payload: string) {
  return {
    type: constants.GET_ALL_SEARCH_USERS_ERROR,
    payload,
  };
}

export function setLoginUser(payload: any) {
  return {
    type: constants.SET_LOGIN_USER,
    payload,
  };
}

export function setSearchUserId(payload: string) {
  return {
    type: constants.SET_SEARCH_USER_ID,
    payload,
  };
}
