import { AnyAction } from "redux";
import * as constants from "./constants";

const initialState = {
  isLoading: false,
  getReasonData: [],
  isReturnProductsPickingLoading: false,
  returnProductsPickingData: [],
  returnProductsPickingError: "",
  isReturnProductsCreditMemoLoading: false,
  returnProductsCreditMemoData: [],
  returnProductsCreditMemoError: "",
  isReturnProductsTicketPrintLoading: false,
  returnProductsTicketPrintData: [],
  returnProductsTicketPrintError: "",
  isReturnProductsOrdersLoading: false,
  returnProductsOrdersData: [],
  returnProductsOrdersError: "",
  returnData: [],
  returnreason: [],
  getReturnCartProduct: [],
  returnFlowDataLoading: false,
  searchReturnInvoiceLoading: false,
  returnReasonLoading: false,
  searchReturnTotal: 0,
  addReturnDataModalBtn: false,
  ifReturnCartAddedProduct: [],
  getCompleteReturnPayload: [],
  moveToReturnPage: false,  
  pickReplaceCartPrint: {},
  pickReplaceCartPrintSuccess: false,  
  pickReplaceCartPrintLoading: false,
  hasReturnInvoiceError: false,
  searchReturnInvoiceError: '',
  customerNameInfo: '',
  printCreditMemoSuccess: false,
  printOrderInvoiceSuccess: false,
  returnSearchedProductData: {},
  returnProductDataModal: false,
  returnSearchedCustomerData: {},
  returnSearchedInvoiceData: null,
  removedArrData: false,
  removedArrPartialData: false,
  addedArr: false,
  arr: [],
};

const reducer = function returnReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case constants.GET_RETURN_PRODUCTS_START:
      return { ...state, isLoading: true };
    case constants.GET_RETURN_PRODUCTS_SUCCESS:
      return {
        ...state,
        getReasonData: action.payload.data,
        isLoading: false,
      };
    case constants.GET_RETURN_PRODUCTS_ERROR:
      return {
        ...state,
        getReasonData: action.payload,
        isLoading: false,
      };
    case constants.GET_RETURN_PRODUCTS_CLEAR:
      return {
        ...state,
        getReasonData: [],
        isLoading: false,
      };
    // return Products Picking
    case constants.RETURN_PRODUCTS_PICKING_START:
      return { ...state, isReturnProductsPickingLoading: true };
    case constants.RETURN_PRODUCTS_PICKING_SUCCESS:
      return {
        ...state,
        returnProductsPickingData: action.payload,
        isReturnProductsPickingLoading: false,
      };
    case constants.RETURN_PRODUCTS_PICKING_ERROR:
      return {
        ...state,
        returnProductsPickingData: [],
        returnProductsPickingError: action.error,
        isReturnProductsPickingLoading: false,
      };
    case constants.RETURN_PRODUCTS_PICKING_CLEAR:
      return {
        ...state,
        returnProductsPickingData: [],
        returnProductsPickingError: "",
        isReturnProductsPickingLoading: false,
      };

    // return Products Ticket Print
    case constants.RETURN_PRODUCTS_PRINT_TICKET_START:
      return { ...state, isReturnProductsCreditMemoLoading: true };
    case constants.RETURN_PRODUCTS_PRINT_TICKET_SUCCESS:
      return {
        ...state,
        returnProductsCreditMemoData: action.payload.data,
        isReturnProductsCreditMemoLoading: false,
      };
    case constants.RETURN_PRODUCTS_PRINT_TICKET_ERROR:
      return {
        ...state,
        returnProductsCreditMemoData: [],
        returnProductsCreditMemoError: action.error,
        isReturnProductsCreditMemoLoading: false,
      };
    case constants.RETURN_PRODUCTS_PRINT_TICKET_CLEAR:
      return {
        ...state,
        returnProductsCreditMemoData: [],
        returnProductsCreditMemoError: "",
        isReturnProductsCreditMemoLoading: false,
      };

    // return Products Credit Memo
    case constants.RETURN_PRODUCTS_CREDIT_MEMO_START:
      return { ...state, isReturnProductsTicketPrintLoading: true, printCreditMemoSuccess: false };
    case constants.RETURN_PRODUCTS_CREDIT_MEMO_SUCCESS:
      return {
        ...state,
        returnProductsTicketPrintData: action.payload.data,
        isReturnProductsTicketPrintLoading: false,
        printCreditMemoSuccess: true
      };
    case constants.RETURN_PRODUCTS_CREDIT_MEMO_ERROR:
      return {
        ...state,
        returnProductsTicketPrintData: [],
        returnProductsTicketPrintError: action.error,
        isReturnProductsTicketPrintLoading: false,
        printCreditMemoSuccess: false
      };
    case constants.RETURN_PRODUCTS_CREDIT_MEMO_CLEAR:
      return {
        ...state,
        returnProductsTicketPrintData: [],
        returnProductsTicketPrintError: "",
        isReturnProductsTicketPrintLoading: false,
        printCreditMemoSuccess: false
      };

    // return Products Orders
    case constants.RETURN_PRODUCTS_ORDERS_START:
      return { ...state, isReturnProductsOrdersLoading: true,
        returnCartSubmitSuccess: false
      };
    case constants.RETURN_PRODUCTS_ORDERS_SUCCESS:
      return {
        ...state,
        returnProductsOrdersData: action.payload,
        isReturnProductsOrdersLoading: false,
        returnCartSubmitSuccess: true
      };
    case constants.RETURN_PRODUCTS_ORDERS_ERROR:
      return {
        ...state,
        returnProductsOrdersData: action.payload,
        returnProductsOrdersError: action.error,
        isReturnProductsOrdersLoading: false,
        returnCartSubmitSuccess: false
      };
    case constants.RETURN_PRODUCTS_ORDERS_CLEAR:
      return {
        ...state,
        // returnProductsOrdersData: [],
        returnProductsOrdersError: "",
        isReturnProductsOrdersLoading: false,
        returnCartSubmitSuccess: false
      };

      // start

      case constants.SEARCH_RETURN_INVOICE_START:
        return { ...state, searchReturnInvoiceLoading: true,
          hasReturnInvoiceError: false
         };
      case constants.SEARCH_RETURN_INVOICE_SUCCESS:
        const finalData = [...state.returnData]
        return {
          ...state,
          returnData: [...finalData, ...action.payload.data.data],
          searchReturnInvoiceLoading: false,
          searchReturnTotal: action.payload.data.total,
          hasReturnInvoiceError: false
        };
      case constants.SEARCH_RETURN_INVOICE_ERROR:
        return { ...state, returnData: action.payload, searchReturnInvoiceLoading: false,
          searchReturnInvoiceError: action.error,
          hasReturnInvoiceError: true
         };
      case constants.SEARCH_RETURN_INVOICE_CLEAR:
        return { ...state, returnData: [], searchReturnInvoiceLoading: false,
          hasReturnInvoiceError: false
         };
  
  
      case constants.GET_RETURN_REASON_START:
        return { ...state, returnReasonLoading: true };
      case constants.GET_RETURN_REASON_SUCCESS:
        return {
          ...state,
          returnReason: action.payload.data,
          returnReasonLoading: false,
          returnCartSubmitSuccess: false
        };

      case constants.RETURN_CART_SUBMIT_FAIL:
        return {
          ...state,
          returnCartSubmitSuccess: false
        };
      case constants.REMOVED_ARR_DATA:
        return {
          ...state,
          removedArrData: action.payload
        };
      case constants.REMOVED_ARR_PARTIAL_DATA:
        return {
          ...state,
          removedArrPartialData: action.payload
        };

      case constants.FINAL_ARR_INFO:
        return {
          ...state,
          arr: action.payload,
          addedArr: true
        };

      case constants.GET_RETURN_REASON_ERROR:
        return { ...state, returnReason: action.payload, returnReasonLoading: false };
      
    case constants.GET_RETURN_CART_DATA:
      return { ...state, getReturnCartProduct: action.payload };
    case constants.ADD_RETURN_DATA_MODAL_BTN:
      return { ...state, addReturnDataModalBtn: action.payload };

    case constants.IF_RETURN_CART_ADDED_PRODUCT:
      return { ...state, ifReturnCartAddedProduct: action.payload };

    case constants.MOVE_TO_RETURN_PAGE:
      return { ...state, moveToReturnPage: action.payload };

    case constants.CUSTOMER_NAME_INFO:
      return { ...state, customerNameInfo: action.payload };

    case constants.RETURN_SEARCHED_PRODUCT_DATA:
      return { ...state, returnSearchedProductData: action.payload };

    case constants.RETURN_PRODUCT_DATA_MODAL:
      return { ...state, returnProductDataModal: action.payload };

    case constants.RETURN_SEARCHED_CUSTOMER_DATA:
      return { ...state, returnSearchedCustomerData: action.payload };

    case constants.RETURN_SEARCHED_INVOICE_DATA:
      return { ...state, returnSearchedInvoiceData: action.payload };

    case constants.GET_COMPLETE_RETURN_PAYLOAD:
      return { ...state, getCompleteReturnPayload: action.payload };

    case constants.PICK_REPLACE_CART_PRINT_START:
      return { ...state, pickReplaceCartPrintLoading: true, pickReplaceCartPrintSuccess: false };
    case constants.PICK_REPLACE_CART_PRINT_SUCCESS:
      return {
        ...state,
        pickReplaceCartPrint: action.payload,
        pickReplaceCartPrintLoading: false,
        pickReplaceCartPrintSuccess: true,
      };
    case constants.PICK_REPLACE_CART_PRINT_ERROR:
      return {
        ...state,
        pickReplaceCartPrint: action.payload,
        pickReplaceCartPrintLoading: false,
        pickReplaceCartPrintSuccess: false,
      };
    case constants.PICK_REPLACE_CART_PRINT_CLEAR:
      return {
        ...state,
        pickReplaceCartPrint: {},
        pickReplaceCartPrintLoading: false,
        pickReplaceCartPrintSuccess: false,
      };
        // end

    default:
      return state;
  }
};

export default reducer;
