import "@fontsource/inter";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { PosResponseProps, RootState } from "models";
import moment from "moment";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Container,
  PosButton,
  PosHelmet,
  PosIconButton,
  PosImage,
  PosPageHeading,
  PosSnackbar,
} from "apps/pos/Common/uiCore";
import ReturnCartNewModal from "apps/pos/ReturnProduct/Modal/ReturnCart/ReturnCartModalNew";
import SearchCustomerModel from "apps/pos/ReturnProduct/Modal/SearchCustomerModel/SearchCustomerModel";
import SearchProductModel from "apps/pos/ReturnProduct/Modal/SearchProductModel/SearchProductModel";
import SearchCommonComponent from "./searchCommonComponent/searchCommonComponent";

import useStyles from "apps/pos/ReturnProduct/Modal/SearchProductModel/style";
import { letterRegex } from "constants/constant";
import { DateFormat } from "constants/enum";
import { ArrowBackRoundedIcon, CloseIcon } from "constants/icons";
import images from "constants/images";
import {
  ReturnFlowDataMainProps,
  returnProductsProps
} from "constants/interfaces";
import { Paths } from "enums";
import { renamePersonData } from "utils/helper";
import { getLocalStoraGeItem } from "utils/utils";
import { keyPressHandle } from "./ReturnProductHelperFunction";

import { setReturnFlowData } from "sagas/persistReducer/actions";
import {
  arrowDownIndex,
  clearSearchCustomer,
  clearSearchProduct,
  stockByWarehouseStart,
} from "sagas/pos/actions";
import {
  addReturnDataModalBtnAction,
  getReasonRequest,
  moveToReturnPageAction,
  returnProductDataModalAction,
  returnSearchedCustomerDataAction,
  returnSearchedInvoiceDataAction,
  returnSearchedProductDataAction,
  searchReturnInvoiceClear,
  searchReturnInvoiceRequest,
} from "sagas/returns/actions";

import "apps/pos/poscommonstyling.scss";
import "apps/pos/PrivacySettings/privacySettings.scss";
import "apps/pos/ReturnProduct/ReturnProduct.scss";

const StyledTableCell = withStyles(() => ({
  head: {
    color: "#aaa",
    fontSize: 11,
    fontFamily: "Inter, Regular",
    paddingLeft: 0,
    borderBottomWidth: 0,
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "Inter, Regular",
    borderBottomWidth: 0,
    paddingLeft: 0,
    verticalAlign: 'middle',
  },
}))(TableCell);

const ReturnProduct = () => {
  const [scmodel, setScModel] = useState<boolean>(false);
  const [cartmodel, setCartModel] = useState<boolean>(false);
  const [doesHaveMore, setHaveMore] = useState<boolean>(true);
  const [selectRowReturnData, setSelectRowReturnData] = useState();
  const [pageSize, setPageSize] = useState<number>(1);
  const [isProductAdded, setIsProductAdded] = useState(false);
  const [productAdded, setProductAdded] = useState(false);
  const [hasNoReturnableQty, setHasNoReturnableQty] = useState(false);
  const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(false);
  const [hasNoInStorageQty, setNoInStorageQty] = useState(false);
  // const [addedProduct, setAddedProduct] = useState<CartProductsProp>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    returnSearchedProductData,
    returnProductDataModal,
    returnSearchedCustomerData,
    returnSearchedInvoiceData,
    hasReturnInvoiceError,
    returnReason,
  } = useSelector<RootState, ReturnFlowDataMainProps>(
    (state) => state.returnReducer
  );

  const handleCloseSearchProductModel = () => {
    dispatch(moveToReturnPageAction(false));
    dispatch(returnProductDataModalAction(false));
  };

  const handleCloseSearchCustomerModel = () => {
    setScModel(false);
  };

  const handleCloseReturnProductModel = () => {
    setCartModel(false);
  };

  const { selectedProductIndex } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );

  const { returnData, searchReturnTotal } = useSelector<
    RootState,
    returnProductsProps
  >((state) => state.returnReducer);

  const { returnFlowData } = useSelector<RootState, ReturnFlowDataMainProps>(
    (state) => state.persistReducer
  );

  const { addedReturnedProduct, returnCartData } = returnFlowData || {};
  const { productMainInformation } = returnCartData || {};

  const currentWorkspace = getLocalStoraGeItem("workspace");

  function handleScroll() {
    setPageSize(pageSize + 1);

    if (returnData.length === searchReturnTotal) {
      setHaveMore(false);
    } else {
      const payload = {
        stockWarehouseId: currentWorkspace?.stockWarehouseId,
        productId: returnSearchedProductData.productId,
        customerId: returnSearchedCustomerData.customerId,
        invoiceOrOrderId: Number(returnSearchedInvoiceData),
        numOfDays: 0,
        page: pageSize + 1,
        size: 10,
      };

      dispatch(searchReturnInvoiceRequest(payload));
    }


  }

  const handleReturnSelect = (product) => {
    const { qtyInStorage, returnableQty } = product || {};
    const noReturnableQtyData = returnableQty === 0;
    const noInStorageQty = qtyInStorage === 0;
    const isQtyInStorage = qtyInStorage > 0
    if (noReturnableQtyData && isQtyInStorage) {
      setIsAddBtnDisabled(true);
    }
    if (noReturnableQtyData && noInStorageQty) {
      setHasNoReturnableQty(true);
      setNoInStorageQty(true)
    } else {
      setHasNoReturnableQty(noReturnableQtyData);
      // ** If you enable this code then while return product search if product is alredy added then you can edit that product not again added the same product until that product is removed                                  

      // const filteredProduct =
      //   addedReturnedProduct?.length &&
      //   addedReturnedProduct?.filter((data) => {
      //     return (
      //       data.invoiceId === product?.invoiceId &&
      //       data.orderId === product?.orderId &&
      //       data.productName === product?.productName &&
      //       data.lineId === product?.lineId
      //     );
      //   });
      // if (filteredProduct?.length) {
      //   setIsProductAdded(true);
      // } else {
      dispatch(stockByWarehouseStart([product?.productId]));
      dispatch(addReturnDataModalBtnAction(false));
      setCartModel(true);
      product['id'] = crypto.randomUUID().toString();
      setSelectRowReturnData(product);
      if (!returnReason?.length) dispatch(getReasonRequest());
      // }
    }
  };

  const classes = useStyles();
  const { boltIcon, noReturnItem, defaultIcon } = images;

  const location = useLocation();
  const returnedProductInfo = location?.state?.detail;
  const returnedCartInvoiceInfo = location?.state?.invoiceDetail;
  const productInformation = returnedProductInfo
    ? returnedProductInfo
    : returnSearchedProductData;

  useEffect(() => {
    if (productInformation) {
      dispatch(returnSearchedProductDataAction(productInformation));
    }
  }, [productInformation]);

  useEffect(() => {
    if (returnedCartInvoiceInfo) {
      dispatch(returnSearchedInvoiceDataAction(returnedCartInvoiceInfo));
    }
  }, [returnedCartInvoiceInfo]);

  function containsAnyLetters(str) {
    return letterRegex.test(str);
  }

  const searchCustomerOnChange = (event, setScmodel) => {
    if (containsAnyLetters(event.target.value)) {
      dispatch(
        returnSearchedCustomerDataAction({
          ...returnSearchedCustomerData,
          fullName: event.target.value,
        })
      );
      dispatch(returnSearchedInvoiceDataAction(""));
      setScmodel(true);
    } else {
      dispatch(returnSearchedCustomerDataAction(""));
      dispatch(returnSearchedInvoiceDataAction(event.target.value));
    }
  };

  const [downPress, setDownPress] = useState<boolean>(false);
  const [upPress, setUpPress] = useState<boolean>(false);

  const tableRowRef = useRef<any>();

  const scrollToSetting = (selectedIndex) => {
    const elementId = document?.getElementById(`tableRowRef${selectedIndex}`);

    elementId?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "end",
    });
  };
  useEffect(() => {
    if (downPress) {
      scrollToSetting(selectedProductIndex);
      dispatch(arrowDownIndex(searchReturnTotal));
      setDownPress(false);
    }
  }, [downPress]);

  useEffect(() => {
    if (upPress) {
      if (selectedProductIndex > -1) {
        scrollToSetting(selectedProductIndex);
      }
      setUpPress(false);
    }
  }, [upPress]);

  const handleCustomerKeyPress = (event) => {
    if (
      event.key === "Enter" &&
      (event.target as HTMLInputElement)?.value !== ""
    ) {
      if (
        (!Number(returnSearchedInvoiceData) &&
          Object.keys(returnSearchedCustomerData).length === 0 &&
          Object.keys(returnSearchedProductData).length === 0) ||
        (Object.keys(returnSearchedCustomerData).length > 0 &&
          Object.keys(returnSearchedProductData).length === 0)
      ) {
        dispatch(returnProductDataModalAction(!returnProductDataModal));
      } else {
        setPageSize(1);
        setHaveMore(true);
        dispatch(searchReturnInvoiceClear());
        keyPressHandle(
          returnSearchedProductData,
          searchReturnInvoiceRequest,
          returnSearchedCustomerData,
          returnSearchedInvoiceData,
          dispatch
        );
      }
    }
  };

  // start
  const [returnAllDataArray, setReturnAllDataArray] =
    useState(addedReturnedProduct);
  const [cartEditModel, setCartEditModel] = useState<boolean>(false);

  const removeReturnedProduct = (key, id, lineId) => {
    if (returnAllDataArray?.length === 1) {
      returnAllDataArray.pop();
      dispatch(
        setReturnFlowData({
          addedReturnedProduct: returnAllDataArray,
          returnCartData: {},
        })
      );
      setReturnAllDataArray(returnAllDataArray);
      setCartEditModel(false);
      navigate(Paths.posReturnProducts);
    } else {
      returnAllDataArray?.splice(
        returnAllDataArray?.findIndex(
          (a) => a.orderId === key && a.productId === id && a.lineId === lineId
        ),
        1
      );
      dispatch(
        setReturnFlowData({
          addedReturnedProduct: returnAllDataArray,
          returnCartData,
        })
      );
      setReturnAllDataArray(returnAllDataArray);
      setCartEditModel(false);
    }
  };
  const handleCloseReturnProductEditModel = () => {
    setCartEditModel(false);
    setIsProductAdded(false);
  };

  useEffect(() => {
    if (!returnReason?.length) dispatch(getReasonRequest());
  }, []);

  // ** If you enable this code then while return product search if product is already added then you can edit that product not again added the same product until that product is removed                                  

  // const handleEditReturnSelect = (product) => {
  //   dispatch(stockByWarehouseStart([product?.productId]));
  //   if (!returnReason?.length) dispatch(getReasonRequest());
  //   dispatch(addReturnDataModalBtnAction(false));
  //   setSelectRowReturnData(product);
  //   if (returnReason?.length) {
  //     setTimeout(() => {
  //       setCartEditModel(true);
  //     }, 100);
  //   }
  // };

  // useEffect(() => {
  //   if (isProductAdded) {
  //     handleEditReturnSelect(addedProduct);
  //   }
  // }, [isProductAdded]);

  // end
  const searchProductOnChange = (
    e: ChangeEvent<HTMLSelectElement>,
    returnSearchedProductDataAction
  ) => {
    if (!e.target.value.includes("  ") && e.target.value.charAt(0) !== " ") {
      dispatch(returnSearchedProductDataAction(e.target.value));
    }
  };

  const returnCartProductContainerElement = document?.querySelector(
    "#return-cart-product-container"
  );

  if (returnCartProductContainerElement) {
    returnCartProductContainerElement[
      "style"
    ].marginTop = `88px`;
  }

  return (
    <>
      <PosHelmet
        title="Simplified Return"
        metaName="Return page"
        content="Return page"
      />
      {!returnSearchedProductData?.productName && (
        <div className="privacy-settings-icon"></div>
      )}
      <Container
        id="return-cart-product-container"
        className="new-container position-relative p-0"
      >
        <PosSnackbar
          open={productAdded}
          message="Item added"
          onClose={() => {
            setProductAdded(false);
          }}
        />
        <PosSnackbar
          open={hasNoReturnableQty && hasNoInStorageQty}
          message="This product has no returnable quantity, Please try another product."
          onClose={() => {
            setHasNoReturnableQty(false);
            setNoInStorageQty(false)
          }}
        />

        <div
          className={`privacy-settings ${returnSearchedProductData?.productName
            ? "return-cart"
            : "return-cart-no-padding"
            }`}
        >
          <div className="order-heading position-relative">
            <PosIconButton
              className="back-next-btn-small-left newOrder-qty-back-btn position-back-return return-product-page-back-btn pos-icon-back-btn"
              onClick={() => {
                dispatch(clearSearchCustomer());
                addedReturnedProduct?.length
                  ? navigate(Paths.posReturnCart)
                  : navigate(Paths.posNewOrder);
              }}
            >
              {returnSearchedProductData?.productName ? (
                <CloseIcon />
              ) : (
                <ArrowBackRoundedIcon />
              )}
            </PosIconButton>
            <div className="d-flex justify-content-between w-100">
              <div className="order-sub-heading return-product-title-header">
                <PosPageHeading
                  heading={`${returnSearchedProductData?.productName
                    ? "Search"
                    : "Returns Cart"
                    }`}
                  blackHeading
                />
                {returnSearchedProductData?.productName ? (
                  ""
                ) : (
                  <div className="new-customer-line">
                    <PosPageHeading heading={"Add items"} grayHeading />
                  </div>
                )}
              </div>
            </div>
          </div>
          <SearchCommonComponent
            searchProductOnClick={() => {
              dispatch(clearSearchProduct());
              dispatch(returnProductDataModalAction(!returnProductDataModal));
            }}
            searchProductImage={returnSearchedProductData?.thumbImg}
            searchProductValue={returnSearchedProductData?.productName}
            searchProductOnChange={(
              event: React.ChangeEvent<HTMLSelectElement>
            ) => {
              searchProductOnChange(event, returnSearchedProductDataAction);
              setIsProductAdded(false);
            }}
            searchCustomerOnChange={(event) => {
              event && searchCustomerOnChange(event, setScModel);
              setIsProductAdded(false);
            }}
            searchCustomerValue={
              renamePersonData(returnSearchedCustomerData?.posCustCode, returnSearchedCustomerData?.companyName) || returnSearchedInvoiceData
            }
            customerKeyPress={(event) => handleCustomerKeyPress(event)}
          />
        </div>
        <>
          {
            // need to verify
            // !returnData?.length ? (
            //   <div className="mt-4 mb-4">
            //   </div>
            // ) : 
            hasReturnInvoiceError ? (
              <div className="products-found product-found-info no-product-found-info">
                <div className="product-found-text">
                  <span className="no-product-found-text">No results found</span>
                </div>
                <div className="horizontal-liner-sp product-found-horizontal-line" />
              </div>
            ) : returnData?.length ? (
              <>
                {returnData?.length ? (
                  <div className="products-found product-found-info">
                    <div className="product-found-text">
                      <span className="int-14-reg">
                        {searchReturnTotal} result
                        {returnData.length > 1 ? "s" : ""} found
                      </span>
                    </div>
                    <div className="horizontal-liner-sp product-found-horizontal-line" />
                  </div>
                ) : (
                  <></>
                )}
                <div className="scroll-styling return-invoice-all-data">
                  <TableContainer className={addedReturnedProduct?.length > 0 && "return-product-table"}>
                    <InfiniteScroll
                      dataLength={returnData?.length}
                      next={() => handleScroll()}
                      height={608}
                      hasMore={doesHaveMore}
                      loader={doesHaveMore ? <div></div> : <h4>Limit Reached</h4>}
                      scrollThreshold={0.9}
                    >
                      <Table
                        className={`${classes.root} invoice-returned-table`}
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead className="sticky-table-header">
                          <TableRow className="product-search-table-header-row">
                            <StyledTableCell align="left"></StyledTableCell>
                            <StyledTableCell align="left"></StyledTableCell>
                            <StyledTableCell align="left">ITEM</StyledTableCell>
                            <StyledTableCell>CUSTOMER</StyledTableCell>
                            <StyledTableCell>ORDER DATE</StyledTableCell>
                            <StyledTableCell>ORDER #</StyledTableCell>
                            <StyledTableCell>INVOICE #</StyledTableCell>
                            <StyledTableCell>EXT. PRICE</StyledTableCell>
                            <StyledTableCell>SHIPPED</StyledTableCell>
                            <StyledTableCell>IN STORAGE</StyledTableCell>
                            <StyledTableCell>RETURNABLE</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="table-border-styling">
                          {returnData?.map((product, index) => {
                            const { productId, invoiceId, orderId, thumbImg, isPopular, productName, companyName, posCustCode, orderDate, extendedPrice, shippedQty, returnableQty, qtyInStorage } = product

                            return (
                              <TableRow
                                onClick={() => {
                                  // ** If you enable this code then while return product search if product is already added then you can edit that product not again added the same product until that product is removed                                  
                                  // if (findExactProduct) {
                                  // setIsProductAdded(true);
                                  // setAddedProduct(findExactProduct);
                                  // } else {
                                  // setIsProductAdded(false);
                                  handleReturnSelect(product);
                                  // }
                                }}
                                key={index}
                                className={`${index === selectedProductIndex
                                  ? "frequently-used-schedule-index"
                                  : ""
                                  } frequently-used-schedule table-divider cursor-pointer invoice-returned-data`}
                                id={`tableRowRef${index}`}
                                ref={tableRowRef[index]}
                              >
                                <StyledTableCell align="left"></StyledTableCell>
                                <StyledTableCell>
                                  <div className={`text-center user-avatar d-block`}>
                                    <PosImage
                                      src={thumbImg ?? defaultIcon}
                                      className="search-list-img"
                                      width={80}
                                      alt="default-icon"
                                    />

                                    {isPopular ? (
                                      <PosImage
                                        src={boltIcon}
                                        className="search-list-icon"
                                        alt="popular-icon"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span className="int-16-reg normal-content">
                                      {productName}
                                    </span>
                                    <span className="return-product-table-customer-secondary-text">{productId}</span>
                                  </div>
                                </StyledTableCell>

                                <StyledTableCell>
                                  <div className="return-product-table-customer">
                                    <span className="int-16-reg">
                                      {companyName ? companyName : "-"}
                                    </span>
                                    <span className="return-product-table-customer-secondary-text">
                                      {posCustCode}
                                    </span>
                                  </div>
                                </StyledTableCell>

                                <StyledTableCell>
                                  <span className="int-16-reg normal-content">
                                    {moment(orderDate).format(
                                      DateFormat?.MM_DD_YYYY
                                    )}
                                  </span>
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                  <span className="int-16-reg normal-content">
                                    {orderId}
                                  </span>
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                  <span className="int-16-reg normal-content">
                                    {invoiceId}
                                  </span>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <span className="int-16-reg normal-content">
                                    $
                                    {extendedPrice &&
                                      (extendedPrice).toFixed(2)}
                                  </span>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <span className="int-16-reg normal-content">
                                    {shippedQty}
                                  </span>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <span className="int-16-reg normal-content">
                                    {qtyInStorage}
                                  </span>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <span className="int-16-reg normal-content">
                                    {returnableQty}
                                  </span>
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </InfiniteScroll>
                  </TableContainer>
                </div>
              </>
            ) : (
              <div className={classes.noSearchReturnDiv}>
                <div>
                  <PosImage
                    src={noReturnItem}
                    alt="No return items"
                    width={198}
                    height={158}
                  />
                  <p className={classes.returnSearchHeading}>
                    Search for products to return
                  </p>
                </div>
              </div>
            )}
        </>
        <section className="set-replacement-info-main-div sr-fixed-footer">
          <Container className="new-container return-cart-product-container p-0">
            {addedReturnedProduct?.length > 0 && (
              <div className="cart-added-items-imfo ">
                <div className="added-products-count">
                  <span>
                    {addedReturnedProduct?.length}{" "}
                    {addedReturnedProduct?.length === 1 ? "item" : "items"}{" "}
                    added
                  </span>
                </div>
                <PosButton
                  variant="contained"
                  color="primary"
                  className="return-set-picking-btn complete-return-btn"
                  onClick={() => {
                    navigate(Paths.posReturnCart);
                  }}
                  fontWeight
                >
                  Review Returns Cart
                </PosButton>
              </div>
            )}
          </Container>
        </section>
        {returnProductDataModal ? <SearchProductModel
          open={returnProductDataModal}
          onClose={handleCloseSearchProductModel}
          setSelectProduct={returnSearchedProductDataAction}
          selectCustomer={returnSearchedCustomerData}
          invoiceNumber={returnSearchedInvoiceData}
        /> : null}
        {scmodel ? <SearchCustomerModel
          productInformation={returnSearchedProductData}
          open={scmodel}
          onClose={handleCloseSearchCustomerModel}
        /> : null}
        {(isProductAdded && cartEditModel) ?
          <ReturnCartNewModal
            removeReturnedProduct={removeReturnedProduct}
            open={cartEditModel}
            cartEditModalData
            onClose={() => handleCloseReturnProductEditModel()}
            selectRowReturnData={selectRowReturnData}
            selectProduct={productMainInformation}
            setIsProductAdded={setIsProductAdded}
            isAddToReturnCartDisabled={hasNoReturnableQty}
          />
          :
          <ReturnCartNewModal
            open={cartmodel}
            setProductAdded={setProductAdded}
            setIsProductAdded={setIsProductAdded}
            onClose={() => handleCloseReturnProductModel()}
            selectRowReturnData={selectRowReturnData}
            selectProduct={
              returnedProductInfo
                ? returnedProductInfo
                : returnSearchedProductData
            }
            isAddToReturnCartDisabled={isAddBtnDisabled}
            setIsAddBtnDisabled={setIsAddBtnDisabled}
          />
        }
      </Container>
    </>
  );
};

export default ReturnProduct;
