import {
  Divider,
  PosButton,
  PosImage,
  PosInput,
  PosPageHeading,
} from "apps/pos/Common/uiCore";

import { AttachMoneyIcon, CheckIcon } from "constants/icons";
import images from "constants/images";
import { renamePersonData } from "utils/helper";
import texts from "apps/pos/locales";

const ShippingInfo = ({
  isFreeShip,
  currentCustomerDetail,
  shiptoFacilities,
  baseAmount,
  shippingAmount,
  isTaxExempted,
  taxValue,
  taxAdded,
  finalTotal,
  priceInDecimal,
  decimalCount,
  saveCharges,
  shippingHandle,
  posFlowData
}) => {
  const {
    greenPartiallyFilled
  } = images;

  const {shippingAndHandlingCharges, continueBtn } = texts;
  
const {street, city, stateName, Country, zip} = shiptoFacilities?.recipient

  return (
    <div>
      <div className="pricing-modal-header">
        <PosPageHeading heading="Delivery" grayHeading className="mb-1" />
        <PosPageHeading
          heading={shippingAndHandlingCharges}
          blackHeading
        />
      </div>
      {isFreeShip ? (
        <div className="sec-with-green-bg">
          <PosImage src={greenPartiallyFilled} alt="free-ship" />
          <span>Shipping is FREE for {renamePersonData(currentCustomerDetail?.posCustCode, currentCustomerDetail?.companyName)}</span>
        </div>
      ) : (
        <></>
      )}
      {(shiptoFacilities?.recipient && !posFlowData?.shiptoFacilities?.isStorePickup) &&
      <div className="shipping-address-row mt-5">
        <div className="w50">
          <span className="int-20-reg text-black">Shipping address</span>
        </div>
        <div className="w50">
          <div className="shipping-address-desc int-20-reg-bold d-flex justify-content-end">
              {street}, {city}, {stateName}, {Country} {zip}
          </div>
        </div>
      </div>}
      <Divider className="light-liner" />
      <div className="shipping-address-row">
        <div className="w50">
          <span className="int-20-reg text-black">Order</span>
        </div>
        <div className="w50">
          <div className="d-flex justify-content-end">
            <span className="int-20-reg fw-500 text-black">
              <sup className="dollar-sup">$</sup>
              {baseAmount ? baseAmount : 0}
            </span>
          </div>
        </div>
      </div>
      {shippingAmount !== 0 && (
        <div className="shipping-address-row">
          <div className="w50">
            <span className="int-20-reg text-black">
            + Shipping & handling charges
            </span>
          </div>
          <div className="w50">
            <div className="d-flex justify-content-end">
              <span className="int-20-reg fw-500">
                <sup className="dollar-sup">$</sup>
                {Number(shippingAmount)?.toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      )}
      {isFreeShip ? (
        <div className="shipping-address-row free-ship-info">
          <div className="free-ship-title-style">
            <span className="int-20-reg text-black">
              + Shipping & handling charges{" "}
              <span className="shipping-free-label">FREE</span>
            </span>
          </div>
          <div className="w50">
            <div className="d-flex justify-content-end">
              <span className="int-20-reg fw-500">
                <sup className="dollar-sup">$</sup>
                {Number(shippingAmount).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      ) : null}
      <div className="shipping-address-row">
        <div className="w50">
          <span className="int-20-reg text-black">
          + Tax ({!isTaxExempted ? taxValue : 0.0}%)
          </span>
        </div>
        <div className="w50">
          <div className="d-flex justify-content-end">
            <span className="int-20-reg fw-500">
              <sup className="dollar-sup">$</sup>
              {taxAdded}
            </span>
          </div>
        </div>
      </div>
      <Divider className="light-liner" />
      <div className="shipping-address-row mb-4">
        <div className="w50">
          <span className="int-20-reg text-black fw-600">Total</span>
        </div>
        <div className="w50">
          <div className="d-flex justify-content-end">
            <span className="int-20-reg-bold">
              <sup className="dollar-sup">$</sup>
              {finalTotal}
            </span>
          </div>
        </div>
      </div>
      {isFreeShip ? (
        <></>
      ) : (
        <>
          <div className="facility-box">
            <span className="payment-text-light mtb-10 text-black">
              Shipping & handling charges
            </span>
            <div className="margintop-10 dollar-with-input-section">
              <div className="dollar-icon ">
                <AttachMoneyIcon />
              </div>
              <PosInput
                variant="outlined"
                id="outlined-basic"
                inputProps={{ maxLength: 8 }}
                className="double-bordered-input"
                value={
                  shippingAmount < 0
                    ? 0
                    : priceInDecimal
                    ? decimalCount(shippingAmount) <= 2
                      ? Number(shippingAmount).toFixed(2)
                      : Number(Number(shippingAmount).toFixed(2))
                    : shippingAmount
                }
                onFocus={(e) => {
                  e.target.select();
                }}
                onChange={(e) => shippingHandle(e)}
              />
            </div>
          </div>
          <div className="shipping-btns mt-5">
            <PosButton
              fontWeight
              startIcon={<CheckIcon />}
              variant="contained"
              color="primary"
              className="modal-select-default-btn ship-btn-dark justify-content-start ship-charge-continue-btn btn"
              onClick={() => saveCharges()}
            >
              {continueBtn}
            </PosButton>
          </div>
        </>
      )}
    </div>
  );
};

export default ShippingInfo;
