import { useState } from "react";
import { Divider, Grid, Box } from "@mui/material";
import { useSelector } from "react-redux";

import { PosButton, PosImage } from "apps/pos/Common/uiCore";

import DeliveryDetailsModals from "apps/pos/storage/DeliveryDetails";
import { Label } from "enums/label";
import images from "constants/images";
import { RootState } from "models";
import { PosFlowDataProp } from "constants/interfaces";

import "apps/pos/storage/putInStorage.scss";
import "apps/pos/pages/customerInfo/Common/customerCommonStyling.scss";
import "./customerStorageDeliveredItems.scss"

const CustomerStorageDeliveredItems = ({deliveredData, setOpenDeliveryProofModel}) => {

    const { defaultIcon, addDocument } = images;
    const { uploadSigned } = Label;

    const {
        posFlowData
      } = useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);
    
      const {customer} = posFlowData || {}

    const [deliveryDetails, setDeliveryDetails] = useState(false);

    return (
        <div className="put-storage-container delivery-items-data">
            <div>
            {!!customer?.requireProofOfDeliveryFlag && <div className="d-flex justify-content-between delivery-proof-section align-items-center flex-wrap">
            <div>
              <span className="font-18">
                {uploadSigned}
              </span>
            </div>
            <div>
              <PosButton
                className="btn storage-doc-upload-btn btn"
                variant="contained"
                color="primary"
                startIcon={<PosImage src={addDocument} alt="add-doc-icon" />}
                onClick={() => setOpenDeliveryProofModel(true)}
              >
                Upload
              </PosButton>
            </div>
          </div>}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} className="table-header-styling">
                        <Grid item xs={3} className="fs-10">
                            <div className="storage-first-sec-title">
                                ITEM
                            </div>
                        </Grid>
                        <Grid item xs={1} className="fs-10">
                            PRODUCT ID
                        </Grid>
                        <Grid item xs={1} className="fs-10">
                            MANUFACTURER
                        </Grid>
                        <Grid item xs={1} className="fs-10">
                            INVOICE #
                        </Grid>
                        <Grid item xs={1} className="fs-10">
                            SHIPMENT #
                        </Grid>
                        <Grid item xs={1} className="fs-10 text-right">
                            IN PREVIOUS DELIVERIES
                        </Grid>
                        <Grid item xs={1} className="fs-10 text-right">
                            IN THIS DELIVERY
                        </Grid>
                        <Grid item xs={1} className="fs-10 text-right">
                            IN STORAGE
                        </Grid>
                    </Grid>
                </Box>
            </div>

            <div className="put-in-storage-table">
            {deliveredData?.map((item, index) => {
                const {productName, productID, manufacturerName, customerInvoiceID, shipmentID, qtyInPreviousDelivery, qtyInDelivery, qtyInShipment} = item
                return (
                    <div key={`index${index}`}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} className="order-items j-content-space-between">
                                <Grid item xs={12} sm={8} md={6} lg={3} className="customer-first-info-col storage-data-info">
                                    <PosImage src={defaultIcon} alt="filter" className='customer-storage-img' />
                                    <div className="customer-name fs-16">
                                        <span className="fw-medium">{productName}</span>
                                        <span className="int-14-reg-gray">{productID}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={1} className="fs-16 storage-data-info">
                                    <div className="d-flex align-items-center">
                                        <span className="fs-10 small-screen-title mr-2">PRODUCT ID: </span> {productID}
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={1} className="fs-16 storage-data-info">
                                    <div className="d-flex align-items-center">
                                        <span className="fs-10 small-screen-title mr-2">MANUFACTURER: </span> {manufacturerName}
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={1} className="fs-16 storage-data-info">
                                    <div className="d-flex align-items-center">
                                        <span className="fs-10 small-screen-title mr-2">INVOICE #: </span> {customerInvoiceID}
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={1} className="fs-16 storage-data-info">
                                    <div className="d-flex align-items-center">
                                        <span className="fs-10 small-screen-title mr-2">SHIPMENT #: </span> {shipmentID}
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={1} className="fs-16 storage-text-alignment storage-data-info">
                                    <div className="d-flex align-items-center">
                                        <span className="fs-10 small-screen-title mr-2">IN PREVIOUS DELIVERIES: </span> {qtyInPreviousDelivery}
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={1} className="fs-16 storage-text-alignment storage-data-info">
                                    <div className="d-flex align-items-center">
                                        <span className="fs-10 small-screen-title mr-2">IN THIS DELIVERY: </span> {qtyInDelivery}
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={1} className="fs-16 storage-text-alignment storage-data-info">
                                    <div className="d-flex align-items-center">
                                        <span className="fs-10 small-screen-title mr-2">IN STORAGE: </span> {qtyInShipment}
                                    </div>

                                </Grid>
                            </Grid>
                            <Divider className="update-product-container-line" />
                        </Box>
                    </div>
                );
            })}
            </div>
            
            <DeliveryDetailsModals
                deliveryDetails={deliveryDetails}
                setDeliveryDetails={setDeliveryDetails}
            />
        </div>
    );
};

export default CustomerStorageDeliveredItems;
