// export enum Status {
//   locked_out = "LOCKED_OUT",
//   qa = "QA",
//   invited = "INVITED",
// }

export enum UserStatus {
  locked_out = "LOCKED_OUT",
  invited = "INVITED",
}

export enum QAStatus {
  qa = "QA",
}

export enum UserAccountStatus {
  lockedOut = "Locked Out",
  awaitingApproval = "Awaiting Approval",
  processed = "New account-waiting to be processed",
  locked = "Locked",
  awaiting = "Awaiting",
  invited = "Invited",
  active = "ACTIVE",
  locked_out = "LOCKED_OUT",
  activeUser = "Active"
}

export enum ResponceStatus {
  success = "success",
  error = "error",
  cancel = "cancel"
}

export enum Placeholders {
  countryCodePh = "1",
  phoneNumberPh = "00000000",
  search = "search",
  Search = "Search",
  searchAmongUser = "Search among users",
  john = "John",
  smith = "Smith",
  emailJohn = "john@smith.com",
  phoneType="DIRECT1"
}

export enum Tabs {
  tabIndexZero = 0,
  tabIndexFirst  = 1,
  tabIndexTwo = 2,
  tabIndexThree = 3,
  tabIndexFour = 4,
  profileTab = "profile",
  roleTab = "roles & permissions",
  settingTab = "settings"
}

export enum Roles {
  dataManager = "DataManager",
  data_manager = "Data Manager",
  Salesman = "Salesman",
  WarehousePerson = "WarehousePerson",
  AccountingUser = "AccountingUser"
}

export enum TargetValue {
  self = "SELF",
  blank = "BLANK"
}