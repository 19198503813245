import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import { Button, InputOptional } from "../../../components";
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import "../../../styles/common.css";

import { socialLinksRequest } from "../../../sagas/setting/actions";
import { RootState } from "../../../models";
import { socialLinksDetails } from "../../../models/setting";
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function SocialLinks({distributorId}) {

  const [socialLinksDetailsData, setSocialLinksDetailsData] = useState<socialLinksDetails>();

  const { socialLinksDetails } = useSelector<RootState, any>((state) => state.setting);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(socialLinksRequest(distributorId));
  },[]);

  useEffect(() => {
    setSocialLinksDetailsData(socialLinksDetails);
  }, [socialLinksDetails]);
  

  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Social links</div>
        <div className='devider2px' />
        <form
          className="formContainer"
          id="addScialLinksForm"
        >
          <InputOptional
            textInputStyle="mt-1"
            id={"addGoogleid"}
            name={"addGoogleid"}
            value={socialLinksDetailsData?.google?.googleAnalyticsKey}
            label={"Google Analytics Tracking ID"}
            type={"text"}
            placeholder={""}
          />
            <div className="decription">Description for this field goes here in this space</div>

          <InputOptional
            textInputStyle="mt-1"
            id={"addTwitter"}
            name={"addTwitter"}
            value={socialLinksDetailsData?.twitter?.distributorSocialMediaType}
            label={"Twitter"}
            type={"text"}
            placeholder={"@username"}
          />
          <InputOptional
            textInputStyle="mt-1"
            id={"addLinkedin"}
            name={"addLinkedin"}
            value={socialLinksDetailsData?.linkedIn?.orgType}
            label={"Linkedin"}
            type={"text"}
            placeholder={"linkedin.com/c/company-name"}
          />
          <InputOptional
            textInputStyle="mt-1"
            id={"addFacebook"}
            name={"addFacebook"}
            value={socialLinksDetailsData?.facebook?.type}
            label={"Facebook"}
            type={"text"}
            placeholder={"facebook.com/username"}
          />
        </form>
        <div className='devider1px' />
        <Button
          type={"submit"}
          className="manage-btn"
          data-bs-toggle="modal"
          data-bs-target="#jumper_modal"
        >
          <span className="manage-button-txt">save changes</span>
        </Button>
      </div>
    </PerfectScrollbar>

  );
}
