import { supportLink } from "constants/staticData";
import React, { Fragment } from "react";

export function PageNotFound() {
  return (
    <Fragment>
      <div id="layoutError">
        <div id="layoutError_content">
          <main>
            <div className="container-fluid page-not-found">
              <div className="row justify-content-center">
                <div className="col-lg-6 align-self-center">
                  <h1 className="error_404">404 : Not found</h1>
                </div>
              </div>
              <div className="row justify-content-center" >
                <div className="col-lg-6 exclamation-circle-text">
                  <i className="fas fa-exclamation-circle exclamation-circle"></i>
                </div>
                <div className="col-lg-6 align-self-start">
                  <span>
                    <p className="error_404_text">The page you tried to visit does not exist. <br /> <span>If you need help, <u>
                      <a href={supportLink} target="_blank">contact us.</a></u></span> 
                      </p>
                  </span>
                  <p className="error_404_text">
                    Redirecting you &nbsp;
                    <u><a className="text-arrow-icon" href="/">
                      back to homepage
                    </a></u> in 10...</p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  );
}
