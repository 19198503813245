import { createSelector } from "@reduxjs/toolkit";
import { AccessoriesSliceI, CouponsSliceI, NotesSliceI, PriceHistorySliceI, SingleProductSliceI, StockMinMaxSliceI } from "apps/product/modules/productDetailsContainer/types";
import { ProductCouponsSliceI } from "./productCoupons.slice";
import { ProductCharacteristicsSliceI } from "./productCharacteristics.slice";

export const singleProductSlice = (state: { singleProductSlice:SingleProductSliceI  }) =>
  state?.singleProductSlice;

export const productsStockSlice = (state:{productsStockSlice:StockMinMaxSliceI}) => 
  state.productsStockSlice

export const productsPriceHistorySlice = (state: {productsPriceHistorySlice:PriceHistorySliceI}) => 
  state.productsPriceHistorySlice

export const productsCouponSlice = (state : {productsCouponSlice :ProductCouponsSliceI}) => 
  state.productsCouponSlice

export const productsCouponsSlice = (state : {productsCouponSlice :CouponsSliceI}) => 
  state.productsCouponSlice

export const productsNoteSlice = (state : {productsNoteSlice :NotesSliceI}) => 
  state.productsNoteSlice

export const productsAccessorieSlice = (state : {productsAccessorieSlice :AccessoriesSliceI}) => 
  state.productsAccessorieSlice

export const productsCharacteristicSlice = (state : {productsCharacteristicSlice :ProductCharacteristicsSliceI}) => 
  state.productsCharacteristicSlice

export const getSingleProductDataFunction = createSelector(
  singleProductSlice ,
  (data) => data.getSingleProductData
)

export const getIsAddMinMaxBatchProduct = createSelector(
  singleProductSlice , 
  (data) => data.isAddMinMaxBatchProduct
)

export const getUpdateImagesProduct = createSelector(
  singleProductSlice , 
  (data) => data.updateImagesProduct
)

export const getUpdateFlagesProduct = createSelector(
  singleProductSlice , 
  (data) => data.updateFlagesProduct
)

export const getIsUpdateRetailPriceScedulesProduct = createSelector(
  singleProductSlice , 
  (data) => data.isUpdateRetailPriceScedulesProduct
)

export const getProductsStockMinMax = createSelector(
  productsStockSlice ,
  (data) => data.getProductsStockMinMaxData
)

export const getProductPriceHistory = createSelector(
  productsPriceHistorySlice ,
  (data) => data.getProductPriceHistoryData
)

export const getIsGetProductsPriceHistory = createSelector(
  productsPriceHistorySlice ,
  (data) => data.isGetProductsPriceHistory
)

export const getIsGetAllProductsCoupons = createSelector(
  productsCouponSlice,
    (data) => data.isGetAllProductsCoupons
)

export const getAllProductCoupons = createSelector(
  productsCouponSlice,
    (data) => data.getAllProductCouponsData
)

export const getIsAddProductsCoupons = createSelector(
  productsCouponSlice,
    (data) => data.isAddProductsCoupons
)

export const getProductNotes = createSelector(
  productsNoteSlice,
    (data) => data.getProductNotesData
)
export const getIsAddProductsNotes = createSelector(
  productsNoteSlice,
  (data) => data.isAddProductsNotes
);

export const getRemoveAllProductNotesData = createSelector(
  productsNoteSlice,
  (data) => data.removeAllProductNotesData
);

export const getRemoveProductNotesData = createSelector(
  productsNoteSlice,
  (data) => data.removeProductNotesData
);

export const productAccessorieGoesWithDataSelector = createSelector(
  productsAccessorieSlice,
  (data) => data.getProductAccessorieGoesWithData
);

export const productAccessorieReplacesDataSelector = createSelector(
  productsAccessorieSlice,
  (data) => data.getProductAccessorieReplacesData
);

export const removeAllRelatedItemsByProductDataErrorSelector = createSelector(
  productsAccessorieSlice,
  (data) => data.removeAllRelatedItemsByProductDataError
);

export const removeParticularAccessoriesErrorSelector = createSelector(
  productsAccessorieSlice,
  (data) => data.removeParticularAccessoriesError
);

export const addProductAccessorieErrorSelector = createSelector(
  productsAccessorieSlice,
  (data) => data.addProductAccessorieError
);

export const isAddProductsCharacteristicsSuccessSelector = createSelector(
  productsCharacteristicSlice,
  (data) => data.isAddProductsCharacteristicsSuccess
);

export const GetAllCharacteristicTypeListSelector = createSelector(
  productsCharacteristicSlice,
  (data) => data.GetAllCharacteristicTypeList
);

export const getProductCharacteristicsDataSelector = createSelector(
  productsCharacteristicSlice,
  (data) => data.getProductCharacteristicsData
);

export const AddProductCharacteristicsErrorSelector = createSelector(
  productsCharacteristicSlice,
  (data) => data.AddProductCharacteristicsError
);

export const removeParticularCharacteristicsErrorSelector = createSelector(
  productsCharacteristicSlice,
  (data) => data.removeParticularCharacteristicsError
);

export const removeAllCharacteristicsErrorSelector = createSelector(
  productsCharacteristicSlice,
  (data) => data.removeAllCharacteristicsError
);

export const AddCharacteristicDataSelector = createSelector(
  productsCharacteristicSlice,
  (data) => data.AddCharacteristicData
);

export const removeAllCharacteristicsDataSelector = createSelector(
  productsCharacteristicSlice,
  (data) => data.removeAllCharacteristicsData
);

export const updateSeoProductSelector = createSelector(
  singleProductSlice,
  (data) => data.updateSeoProduct
);

export const updateIdentifiersProductSelector = createSelector(
  singleProductSlice,
  (data) => data.updateIdentifiersProduct
);

export const updateMeasurementsProductSelector = createSelector(
  singleProductSlice,
  (data) => data.updateMeasurementsProduct
);

export const updateBasicProductSelector = createSelector(
  singleProductSlice,
  (data) => data.updateBasicProduct
);

export const getCategoriesMenuProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.getCategoriesMenuProductData
);

export const removeMinMaxBatchProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.removeMinMaxBatchProductData
);

export const getSingleProductSelector = createSelector(
  singleProductSlice,
  (data) => data.getSingleProduct
);

export const getLogsProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.getLogsProductData
);

export const getRetailPriceScedulesProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.getRetailPriceScedulesProductData
);

export const updateFlagesProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.updateFlagesProductData
);

export const updateCostAndPriceProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.updateCostAndPriceProductData
);

export const removeDiscontinuedPriceProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.removeDiscontinuedPriceProductData
);

export const removeClearancePriceProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.removeClearancePriceProductData
);

export const updateImagesProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.updateImagesProductData
);

export const updateIdentifiersProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.updateIdentifiersProductData
);

export const updateSeoProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.updateSeoProductData
);

export const updateMeasurementsProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.updateMeasurementsProductData
);

export const updateBasicProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.updateBasicProductData
);

export const deletePriceScedulesProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.deletePriceScedulesProductData
);

export const updateRetailPriceScedulesProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.updateRetailPriceScedulesProductData
);

export const addMinMaxBatchProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.addMinMaxBatchProductData
);

export const addMinMaxBatchProductErrorSelector = createSelector(
  singleProductSlice,
  (data) => data.addMinMaxBatchProductError
);

export const getSingleProductErrorSelector = createSelector(
  singleProductSlice,
  (data) => data.getSingleProductError
);

export const updateCostAndPriceProductSelector = createSelector(
  singleProductSlice,
  (data) => data.updateCostAndPriceProduct
);

export const getProductTicketsCountDataSelector = createSelector(
  singleProductSlice,
  (data) => data.getProductTicketsCountData
);

export const getProductCouponsDataSelector = createSelector(
  productsCouponsSlice,
  (data) => data.getProductCouponsData
);

export const removeAllProductsCouponsErrorSelector = createSelector(
  productsCouponsSlice,
  (data) => data.removeAllProductsCouponsError
);

export const removeProductsCouponsErrorSelector = createSelector(
  productsCouponsSlice,
  (data) => data.removeProductsCouponsError
);

export const isAddProductsCouponsSelector = createSelector(
  productsCouponsSlice,
  (data) => data.isAddProductsCoupons
);

export const updateOverProductSelector = createSelector(
  singleProductSlice,
  (data) => data.isUpdateOverviewProduct
);

export const updateOverviewProductDataSelector = createSelector(
  singleProductSlice,
  (data) => data.updateOverviewProductData
);
