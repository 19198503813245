import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Will remove 'any' once we get proper data from BE
export interface ProductCouponsSliceI {
  isGetProductsCoupons: boolean;
  getProductCouponsData: any;
  getProductCouponsError: any;
  getAllProductCouponsData:any;
  isGetAllProductsCoupons:boolean;
  getAllProductCouponsError:any;
  removeProductCouponsData : any;
  isRemoveProductsCoupons : boolean;
  removeProductCouponsError : any;
  removeAllProductCouponsData : any;
  isRemoveAllProductsCoupons : boolean;
  removeAllProductCouponsError : any;
  isAddProductsCoupons : boolean;
  addProductCouponsData : any;
  addProductCouponsError : any;
  getProductCouponsErrorByKey: any;
  getProductCouponsDataByKey: any;
  isGetProductsCouponsByKey: boolean;
  isGetProductsCouponsByKeySuccess: boolean;
}

const initialState: ProductCouponsSliceI = {
    isGetProductsCoupons: false,
    getProductCouponsData: null,
    getProductCouponsError: null,
    getAllProductCouponsData: [],
    isGetAllProductsCoupons:false,
    getAllProductCouponsError:null,
    removeProductCouponsData : null,
    isRemoveProductsCoupons : false,
    removeProductCouponsError : null,
    removeAllProductCouponsData : null,
    isRemoveAllProductsCoupons : false,
    removeAllProductCouponsError : null,
    isAddProductsCoupons : false,
    addProductCouponsData : null,
    addProductCouponsError : null,
    getProductCouponsDataByKey:[],
    getProductCouponsErrorByKey : null,
    isGetProductsCouponsByKey: false,
    isGetProductsCouponsByKeySuccess: false,
};

export const productsCouponSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    getProductsCouponsStart: (state) => {
      state.isGetProductsCoupons = true;
    },
    getProductsCouponsSuccess: (state, action: PayloadAction<any>) => {
      state.getProductCouponsData = action.payload.data;
      state.isGetProductsCoupons = false;
    },
    getProductsCouponsError: (state, action: PayloadAction<any>) => {
      state.getProductCouponsError = action.payload;
      state.getProductCouponsData = null;
      state.isGetProductsCoupons = false;
    },
    getProductsCouponsByKeyStart: (state) => {
      state.getProductCouponsDataByKey = [];
      state.isGetProductsCouponsByKey = true;
      state.isGetProductsCouponsByKeySuccess = false;
    },
    getProductsCouponsByKeySuccess: (state, action: PayloadAction<any>) => {
      state.getProductCouponsDataByKey = action.payload.data;
      state.isGetProductsCouponsByKey = false;
      state.isGetProductsCouponsByKeySuccess = true;
    },
    getProductsCouponsByKeyError: (state, action: PayloadAction<any>) => {
      state.getProductCouponsErrorByKey = action.payload;
      state.getProductCouponsDataByKey = [];
      state.isGetProductsCouponsByKey = false;
      state.isGetProductsCouponsByKeySuccess = false;
    },
    getAllProductsCouponsStart: (state) => {
      state.getAllProductCouponsData = [];
      state.isGetAllProductsCoupons = true;
    },
    getAllProductsCouponsSuccess: (state, action: PayloadAction<any>) => {
      state.getAllProductCouponsData = action.payload.data;
      state.isGetAllProductsCoupons = false;
    },
    getAllProductsCouponsError: (state, action: PayloadAction<any>) => {
      state.getAllProductCouponsError = action.payload;
      state.getAllProductCouponsData = [];
      state.isGetAllProductsCoupons = false;
    },
    getProductsCouponsByKeyClear: (state) => {
      state.getProductCouponsErrorByKey = null;
      state.getProductCouponsDataByKey = [];
      state.isGetProductsCouponsByKey = false;
      state.isGetProductsCouponsByKeySuccess = false;
    },
    
    removeProductsCouponsStart: (state) => {
      state.removeProductCouponsData = null;
      state.isRemoveProductsCoupons = true;
    },
    removeProductsCouponsSuccess: (state, action: PayloadAction<any>) => {
      state.removeProductCouponsData = action.payload.data;
      state.isRemoveProductsCoupons = false;
    },
    removeProductsCouponsError: (state, action: PayloadAction<any>) => {
      state.removeProductCouponsError = action.payload;
      state.removeProductCouponsData = null;
      state.isRemoveProductsCoupons = false;
    },
    removeAllProductsCouponsStart: (state) => {
      state.removeAllProductCouponsData = null;
      state.isRemoveAllProductsCoupons = true;
    },
    removeAllProductsCouponsSuccess: (state, action: PayloadAction<any>) => {
      state.removeAllProductCouponsData = action.payload.data;
      state.isRemoveAllProductsCoupons = false;
    },
    removeAllProductsCouponsError: (state, action: PayloadAction<any>) => {
      state.removeAllProductCouponsError = action.payload;
      state.removeAllProductCouponsData = null;
      state.isRemoveAllProductsCoupons = false;
    },
    addProductsCouponsStart: (state) => {
      state.addProductCouponsData = null;
      state.isAddProductsCoupons = true;
    },
    addProductsCouponsSuccess: (state, action: PayloadAction<any>) => {
      state.addProductCouponsData = action.payload.data;
      state.isAddProductsCoupons = false;
    },
    addProductsCouponsError: (state, action: PayloadAction<any>) => {
      state.addProductCouponsError = action.payload;
      state.addProductCouponsData = null;
      state.isAddProductsCoupons = false;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  getProductsCouponsStart,
  getProductsCouponsSuccess,
  getProductsCouponsError,
  getProductsCouponsByKeyStart,
  getProductsCouponsByKeySuccess,
  getProductsCouponsByKeyError,
  getProductsCouponsByKeyClear,
  getAllProductsCouponsStart,
  getAllProductsCouponsSuccess,
  getAllProductsCouponsError,
  removeProductsCouponsStart,
  removeProductsCouponsSuccess,
  removeProductsCouponsError,
  removeAllProductsCouponsStart,
  removeAllProductsCouponsSuccess,
  removeAllProductsCouponsError,
  addProductsCouponsStart,
  addProductsCouponsSuccess,
  addProductsCouponsError
} = productsCouponSlice.actions;

export default productsCouponSlice.reducer;
