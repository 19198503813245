import { useEffect, useState } from "react";
import { setToLS, getFromLS } from "../utils/storage";

//creating a custom hook for theme builder
export const useTheme = () => {
  //get the default theme from local storage
  const themes = getFromLS("all-themes");

  //initialize paper theme
  const [theme, setTheme] = useState(themes);
  const [themeLoaded, setThemeLoaded] = useState(false);

  //set theme mode
  const setMode = (mode: any) => {
    //set to local storage
    setToLS("theme", mode);
    setTheme(mode);
  };

  useEffect(() => {
    //it will retrieve to get local theme
    const localTheme = getFromLS("theme");
    //if not then set the default theme
    localTheme ? setTheme(localTheme) : setTheme(themes);
    setThemeLoaded(true);
  }, []);

  return { theme, setMode, themeLoaded };
};
