const QUOTE_API_PATH = "quotes/job";

export const endpoints = {
  revisedDueDate: (personId: number) =>
    `users/${personId}/customer/duedate/set/verify`,
  verifyDueDate: "quotes/job/revised/due/date",
  setCloseDate: (data) =>
    `quotes/job/${data.jobQuoteID}/closetimes/${data.date}`,
  setPlaceOrderId: (id) =>
    `/quotes/job/ok/place/preorder?JobLotQuoteId=${id}&AccessLevel=0`,
  convertToExpress: (jobLotQuoteId) =>
    `quotes/job/preorders/${jobLotQuoteId}/convert/ready/verify`,
  updateEstimator: (jobQuoteID) =>
    `quotes/job/joblotquoteid/${jobQuoteID}/update/estimator`,
  productByKeyword: (data) =>
    `products/by/keywords/sales?page=${data.page}&size=${data.size}`,
  expirationDateUrl: (data) => `${QUOTE_API_PATH}/${data}/activate`,
  convertIdToExpress: (jobLotQuoteId) =>
    `quotes/job/${jobLotQuoteId}/converted/quoteid`,
  setSubQuotes: (parentQuoteId) =>
    `${QUOTE_API_PATH}/${parentQuoteId}/subquotes/forcustomer/0`,
  setEmail: (parentQuoteId) => `users/internal/${parentQuoteId}/email`,
  sendMessage: `quotes/job/messages`,
  documentTypeData: `pos/docs/types`,
  uploadDocument: `quotes/job/rep/assigned/documents`,
  getOrderData: (id) => `${QUOTE_API_PATH}/${id}/orders`,
};
