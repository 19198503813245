import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';

import { shipmentOrdersUrl } from './constantUrl';
import { stopLoading } from 'utils/utils';

export const useGetShipmentsFromOrderId = () => {

  const {mutate, isLoading, data} = useMutation(
    async ({stockWarehouseId, orderId}: {stockWarehouseId: number; orderId: number}) => {
      return shipmentOrdersUrl({stockWarehouseId, orderId})
      .then((response) => {
        return response.data;
      });
    },
    {onSuccess : stopLoading,
      onError : stopLoading
});

  const getShipmentIdFromOrderId = useCallback(
    ({stockWarehouseId, orderId}) => {
      mutate({stockWarehouseId, orderId})
    },[mutate]
  )

  return { getShipmentIdFromOrderId, data, getShipmentIdLoading: isLoading };
};