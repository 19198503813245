import { useState } from 'react'
import TextareaAutosize from 'react-autosize-textarea'

import MaterialIcon from 'apps/pos/Common/uiCore/MaterialIcon';
import IconButton from '../Elements/IconButton'

import './NotesInput.scss';

const NotesInput = (props) => {
    const { id, initialValue = '', placeholder = '', autoFocus, remove, showIcon , updateNotes , isNew = false} = props
    const [value, setValue] = useState(initialValue)

    const handleCancel = () => {
        leaveInput()
        setValue(initialValue)
    }

    const leaveInput = () => {
        document.activeElement.blur()
    }

    const handleChange = (e) => {
        setValue(e.target.value)
    }

    const handleSubmit = () => {
        updateNotes(value , isNew ? '' : id)
        leaveInput()
        isNew && setValue(initialValue);
    }

    return (
        <div
            className={`notes-input-wrapper ${value === initialValue ? '' : 'notes-input-value-changed'}`}
        >
            {showIcon && !value.length ? (
                <MaterialIcon icon='add' classes='add-new-note-icon' />
            ) : null}
            <TextareaAutosize
                id={id}
                value={value}
                onChange={(e) => handleChange(e)}
                rows={1}
                style={{ minInlineSize: '1em' }}
                spellCheck="true"
                async={true}
                placeholder={placeholder}
                autoFocus={autoFocus}
            />

            <div className="notes-input-controls">
                <div>
                    {remove ? (
                        <IconButton
                            icon='delete_outline'
                            secondary={true}
                            size='30'
                            iconSize='22'
                            classes='notes-input-delete-btn'
                            onClick={remove}
                            title='Delete'
                        />
                    ) : null}
                </div>
                <button
                    className='notes-input-btn secondary'
                    onClick={handleCancel}
                >
                    Cancel
                </button>
                <button
                    className='notes-input-btn primary'
                    disabled={value === initialValue}
                    onClick={handleSubmit}
                >
                    Save
                </button>
            </div>
        </div>
    )
}

export default NotesInput