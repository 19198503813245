export const colors = [
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
  "#D5D5F0",
  "#C6E5F4",
  "#C6E5F4",
  "#D5D5F0",
  "#F0DCD5",
  "#C6E5F4",
  "#BCF0C9",
];
