import { useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { PosButton } from "apps/pos/Common/uiCore";
import CommonEditButton from "apps/product/Components/ProductDetails/CommonEditButton";
import CreateGridStarIcon from "apps/product/Components/ProductDetails/CreateGridStarIcon";
import CommonGridSection from "apps/product/Components/ProductDetails/CommonGridSection";
import useFilterSections from "apps/product/Components/ProductDetails/useSectionFilter";

import ProductDetailsWholesaleModal from "./ProductDetailModals/ProductDetailsWholesaleModal";

import { isoToFormat } from "utils/utils";
import { labels, sectionName } from "apps/product/modules/productDetailsContainer/constants";
import { DoDisturbIcon, LocalOfferIcon } from "constants/icons";
import { ProductDetailText } from "constants/enum";
import { distributorData } from "sagas/distributor/selector";
import { getSingleProductDataFunction } from "sagas/productsDetails/selector";

const ProductDetailsCosts = ({ isStarred = false, searchText, handleSearchSection }) => {

  const getSingleProductData = useSelector(getSingleProductDataFunction);
  const distributor = useSelector(distributorData)
  const { costs } = sectionName;
  const { showSection } = useFilterSections({ searchText, sectionName: costs, isStarred });

  const { details, costsAndPrices } = getSingleProductData || {}
  const { dates } = details || {}
  const { basePrices, displayPrices } = costsAndPrices || {}
  const { vendorPrice, movingAvgCost, overridePrice, clearance, discontinued, salesPricePerCase, salesPrice, salesPricePerItemMU } = basePrices || {}
  const { manufacturerPrice, stockPrice, hotBuyPrice, hotBuyExpiryDate, goldenRodPrice, claimBackPrice, claimBackFlag, listPrice } = displayPrices || {}
  const { manufacturer, setAsClearance, setAsDiscontinued, vendorPriceLabel, wholeSalePriceLabel, venderPriceId, wholeSalePriceId, notSet, claimBack, hotBuy, list, Clearance, Discontinued, appliestoPosonly, Override, priceEach, priceCase, appliestoTrampolineonly, seeHistory } = labels;
  const { Cost, Vendor, Stock, movingAvg, Wholesale, Price, Golden_Rod, changedFrom, wholesalePrice: salesPriceType, WholesalePrice, VendorCost } = ProductDetailText

  const [wholesaleModal, setWholesaleModal] = useState(false);
  const [priceTypeID, setPriceTypeID] = useState();
  const [priceLable, setPriceLable] = useState();

  const openModel = (priceTypeId, priceText) => () => {
    setPriceTypeID(priceTypeId)
    setWholesaleModal(true);
    setPriceLable(priceText)
  }

  return (
    <>
      <section className="product-report-section">
        <Grid container className="product-report-container">
          {(!isStarred && !searchText) ? (
            <Grid container>
              <Grid item xs={12} className="cost-title-info">
                <span>{Cost}</span>
              </Grid>
            </Grid>
          ) : null}
          {showSection("vendorPrice",Vendor) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='vendorPrice' label={isStarred ? VendorCost : Vendor} value={vendorPrice?.currentPrice} sectionName={costs} />
              <Grid item xs={12} sm={8}>
                <span>${vendorPrice?.currentPrice || 0}</span>
                <div className="small-text-without-margin gray-text">
                  {vendorPrice?.previousPrice ? <span>{`${changedFrom}${vendorPrice?.previousPrice || 0}`}
                    {
                      vendorPrice?.lastUpdatedByName ? " by " + vendorPrice?.lastUpdatedByName : (dates?.createdByName ? " by " + dates?.createdByName : "")
                    }
                    {vendorPrice?.lastUpdatedAt ? " on " + isoToFormat(vendorPrice?.lastUpdatedAt, "MM/DD/YYYY") :
                      " on " + isoToFormat(vendorPrice?.createdAt, "MM/DD/YYYY")
                    }</span> :
                    <span>
                      {`Set`}
                      {
                        vendorPrice?.lastUpdatedByName ? " by " + vendorPrice?.lastUpdatedByName : (dates?.createdByName ? " by " + dates?.createdByName : "")
                      }
                      {vendorPrice?.lastUpdatedAt ? " on " + isoToFormat(vendorPrice?.lastUpdatedAt, "MM/DD/YYYY") :
                        " on " + isoToFormat(vendorPrice?.createdAt, "MM/DD/YYYY")
                      } </span>}
                </div>
                <div>
                  {vendorPrice?.previousPrice ? <PosButton
                    variant="contained"
                    className="btn product-detail-medium-btn mt-2"
                    onClick={openModel(venderPriceId, vendorPriceLabel)}
                  >
                    {seeHistory}
                  </PosButton> : null}
                </div>
              </Grid>
              <CommonEditButton onClick={handleSearchSection(Vendor)} />
            </Grid>
          ) : null}
          {showSection("movingAvgCost",movingAvg) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='movingAvgCost' label={movingAvg} value={movingAvgCost?.currentPrice} sectionName={costs} />
              <Grid item xs={12} sm={8}>
                <span>${movingAvgCost?.currentPrice || 0}</span>
              </Grid>
            </Grid>
          ) : null}
          {showSection("manufacturerPrice",manufacturer) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='manufacturerPrice' label={manufacturer} value={manufacturerPrice} sectionName={costs} />
              <CommonGridSection value={manufacturerPrice} />
              <CommonEditButton onClick={handleSearchSection(manufacturer)} />
            </Grid>
          ) : null}
          {showSection("stockPrice",Stock) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='stockPrice' label={Stock} value={stockPrice} sectionName={costs} />
              <CommonGridSection value={stockPrice} />
              <CommonEditButton onClick={handleSearchSection(Stock)} />
            </Grid>
          ) : null}
          {showSection("goldenRodPrice",Golden_Rod) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='goldenRodPrice' label={Golden_Rod} value={goldenRodPrice} sectionName={costs} />
              <CommonGridSection value={goldenRodPrice} />
              <CommonEditButton onClick={handleSearchSection(Golden_Rod)} />
            </Grid>
          ) : null}
          {(!isStarred && !searchText) ? (
            <Grid container>
              <Grid item xs={12} className="cost-title-info">
                <span>{Price}</span>
              </Grid>
            </Grid>
          ) : null}
          {showSection("wholeSalePrice",Wholesale) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='wholeSalePrice' label={isStarred ? WholesalePrice : Wholesale} value={salesPrice?.currentPrice} sectionName={costs} smallText={`${distributor?.shortName} ${salesPriceType}`} />
              <Grid item xs={12} sm={8}>
                <div>
                  <span>${salesPrice?.currentPrice || 0}</span>
                </div>
                <div className="wholesale-des-text gray-text mt-2">
                  {
                    salesPrice?.previousPrice ?
                      <span>{`${changedFrom}${salesPrice?.previousPrice || 0}`}
                        {
                          salesPrice?.lastUpdatedByName ? " by " + salesPrice?.lastUpdatedByName : (dates?.createdByName ? " by " + dates?.createdByName : "")
                        }
                        {salesPrice?.lastUpdatedAt ? " on " + isoToFormat(salesPrice?.lastUpdatedAt, "MM/DD/YYYY") :
                          " on " + isoToFormat(salesPrice?.createdAt, "MM/DD/YYYY")
                        }</span>
                      : <span>
                        {`Set`}
                        {
                          salesPrice?.lastUpdatedByName ? " by " + salesPrice?.lastUpdatedByName : (dates?.createdByName ? " by " + dates?.createdByName : "")
                        }
                        {salesPrice?.lastUpdatedAt ? " on " + isoToFormat(salesPrice?.lastUpdatedAt, "MM/DD/YYYY") :
                          " on " + isoToFormat(salesPrice?.createdAt, "MM/DD/YYYY")
                        }
                      </span>
                  }

                </div>
                <div className="mt-2">
                  {salesPrice?.previousPrice ?
                    <PosButton
                      variant="contained"
                      className="btn product-detail-medium-btn history"
                      size="small"
                      onClick={openModel(wholeSalePriceId, wholeSalePriceLabel)}
                    >
                      {seeHistory}
                    </PosButton> : null}
                </div>
              </Grid>
              <CommonEditButton onClick={handleSearchSection(Wholesale)} />
            </Grid>
          ) : null}
          {showSection("Price/Case",priceCase) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='Price/Case' label={priceCase} value={salesPricePerCase?.currentPrice} sectionName={costs} smallText={appliestoTrampolineonly} />
              <Grid item xs={12} sm={8}>
                {
                  salesPricePerCase?.currentPrice ?
                    <>
                      <div>
                        <span>${salesPricePerCase?.currentPrice || 0}</span>
                      </div>
                      <div className={`wholesale-des-text ${salesPricePerCase?.margin > 0 ? "dark-mint-green-text" : "dark-red-color-text"} mt-2`}>
                        <span>{salesPricePerCase?.margin > 0 ? "+" + salesPricePerCase?.margin.toFixed(2) : "-" + salesPricePerCase?.margin.toFixed(2)}% margin</span>
                      </div>
                    </> : <div className="small-text-without-margin gray-text">
                      <span>{notSet}</span>
                    </div>
                }
              </Grid>
            </Grid>
          ) : null}
          {showSection("Price/item",priceEach) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='Price/item' label={priceEach} value={salesPricePerItemMU?.currentPrice} sectionName={costs} smallText={appliestoPosonly} />
              <Grid item xs={12} sm={8}>
                {
                  salesPricePerItemMU?.currentPrice ?
                    <>
                      <div>
                        <span>${salesPricePerItemMU?.currentPrice || 0}</span>
                      </div>
                      <div className={`wholesale-des-text ${salesPricePerItemMU?.margin > 0 ? "dark-mint-green-text" : "dark-red-color-text"} mt-2`}>
                        <span>{salesPricePerItemMU?.margin > 0 ? "+" + salesPricePerItemMU?.margin.toFixed(2) : "-" + salesPricePerItemMU?.margin.toFixed(2)}% margin</span>
                      </div>
                    </> : <div className="small-text-without-margin gray-text">
                      <span>{notSet}</span>
                    </div>
                }
              </Grid>
            </Grid>
          ) : null}
          {showSection("overridePrice",Override) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='overridePrice' label={Override} value={overridePrice ? overridePrice?.currentPrice : 0} sectionName={costs} smallText={appliestoPosonly} />
              <CommonGridSection value={overridePrice} displayValue={overridePrice?.currentPrice} />
              <CommonEditButton onClick={handleSearchSection(Override)} />
            </Grid>
          ) : null}
          {showSection("discountedPrice",Discontinued) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='discountedPrice' label={Discontinued} value="123" sectionName={costs} />
              <Grid item xs={12} sm={8}>
                {
                  discontinued?.currentPrice ? <span>{discontinued.currentPrice}</span> :
                    <>
                      <div className="small-text-without-margin gray-text">
                        <span>{notSet}</span>
                      </div>
                      <div className="mt-2">
                        <PosButton
                          variant="contained"
                          className="btn product-detail-medium-btn"
                          startIcon={<DoDisturbIcon />}
                          onClick={handleSearchSection(Discontinued)}
                        >
                          {setAsDiscontinued}
                        </PosButton>
                      </div>
                    </>
                }
              </Grid>
              <CommonEditButton onClick={handleSearchSection(Discontinued)} />
            </Grid>
          ) : null}
          {showSection("clearancePrice",Clearance) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='clearancePrice' label={Clearance} value="123" sectionName={costs} />
              <Grid item xs={12} sm={8}>
                {
                  clearance?.currentPrice ? <span>${clearance.currentPrice}</span> :
                    <>
                      <div className="small-text-without-margin gray-text">
                        <span>{notSet}</span>
                      </div>
                      <div className="mt-2">
                        <PosButton
                          variant="contained"
                          className="btn product-detail-medium-btn"
                          startIcon={<LocalOfferIcon />}
                          onClick={handleSearchSection(Clearance)}
                        >
                          {setAsClearance}
                        </PosButton>
                      </div>
                    </>
                }

              </Grid>
              <CommonEditButton onClick={handleSearchSection(Clearance)} />
            </Grid>
          ) : null}
          {showSection("list",list) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='list' label={list} value={listPrice} sectionName={costs} />
              <Grid item xs={12} sm={8}>
                <span>${listPrice || 0}</span>
              </Grid>
              <CommonEditButton onClick={handleSearchSection(list)} />
            </Grid>
          ) : null}
          {showSection("HotBuy",hotBuy) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='HotBuy' label={hotBuy} value="123" sectionName={costs} />
              <Grid item xs={12} sm={8}>
                <div>
                  {hotBuyPrice ? <span> ${hotBuyPrice}</span> :
                    <div className="small-text-without-margin gray-text">
                      <span>{notSet}</span>
                    </div>
                  }
                </div>
                <div className="wholesale-des-text gray-text mt-2">
                  <span>{hotBuyExpiryDate ? "Expires " + isoToFormat(hotBuyExpiryDate, "MM/DD/YY") : ""}</span>
                </div>
              </Grid>
              <CommonEditButton onClick={handleSearchSection(hotBuy)} />
            </Grid>
          ) : null}
          {showSection("claimBackPrice",claimBack) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='claimBackPrice' label={claimBack} value={claimBackPrice} sectionName={costs} />
              <Grid item xs={12} sm={8}>
                {claimBackFlag && claimBackPrice ? <>
                  <span> ${claimBackPrice}</span>
                  <div className="wholesale-des-text gray-text mt-2">
                    <span>{!claimBackFlag ? "Not in use" : ""}</span>
                  </div>
                </>
                  :
                  <div className="small-text-without-margin gray-text">
                    <span>{notSet}</span>
                  </div>
                }
              </Grid>
              <CommonEditButton onClick={handleSearchSection(claimBack)} />
            </Grid>
          ) : null}
        </Grid>
      </section>
      {wholesaleModal && <ProductDetailsWholesaleModal setWholesaleModal={setWholesaleModal} wholesaleModal={wholesaleModal} priceTypeID={priceTypeID} priceLable={priceLable} />}
    </>
  );
};

export default ProductDetailsCosts;
