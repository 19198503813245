
const getMode = localStorage.getItem("jumperMode")
const products = getMode === "FULL" ? "/app/leapfrog/products" : "/app/springboard/products"

export const Paths = {
  login: "/sso/login",
  adminHome: "/app/admin/home",
  adminUsers: "/app/admin/users",
  adminInvitedUsers: "/app/admin/users/invited",
  adminReviewUsers: "/app/admin/users/review",
  adminReviewIdUsers: "review",
  quoteDetail: "/app/springboard/quotes",
  quoteJob: "/app/springboard/quotes/job",
  // Start pos path
  posNewOrder: "/app/pos/neworder",
  posNavNewOrder: "/app/pos/newOrder",
  posAddProducts: "/app/pos/addproducts",
  posOrderComplete: "/app/pos/ordercomplete",
  posPickQuantity: "/app/pos/pickquantity",
  posSalesHistory: "/app/pos/posSalesHistory",
  posQuotesHistory: "/app/pos/posQuotesHistory",
  purchaseHistory: "/app/pos/purchaseHistory",
  invoiceHistory: "/app/pos/invoiceHistory",
  privacySettingsPage: "/app/pos/settings",
  cashDrawer: "/app/pos/drawer/summary/report",
  posCart: "/app/pos/cart",
  posReturnCart: "/app/return/cart",
  history: "/app/admin/subscription/payments/history",
  pos: "/app/pos",
  posReturnProducts: "/app/return/product",
  posReplacePickQuantity: "/app/return/replacepickquantity",
  posReturnComplete: "/app/return/complete",
  posReviewItems: "/app/pos/reviewitems",
  posItemLedger: "/app/pos/item-ledger",
  posItemHistory: "/app/pos/item-history",
  posDrafts: "/app/pos/drafts",
  // End pos path
  settings: "/app/admin/settings",
  unauthorized: "/sso/default?message=unauthorized",
  historyAdmin: "admin/subscription/payments/history",

  // Product Start
  product: products,
  productDetailsNew: "/app/product/details/new",
  customer: "/app/pos/customer",
  putInStorage: "/app/pos/customer/putInStorage",
  deliverItemsInStorage: "/app/pos/customer/deliverItemsInStorage",
  subStorageItems: "/app/pos/customer/subStorageItems",

  pendingProductLink: "/manager/mProd_pending_Add",
  regularProductLink: "/manager/mProd_AddProduct",
  rebateProductLink: "/manager/mProd_pending_rebateItem_add",
  manageSchedulesLink: "/manager/mProd_PriceSchedules_List",
  reports: "reports",
  itemHistory: "itemHistory",
  ledger: "ledger",
  salesHistory: "salesHistory",
  quotesHistory: "quotesHistory",
  purchaseHistoryReport: "purchaseHistory",
  invoiceHistoryReport: "invoiceHistory",
  posPriceChangeReport: "posPriceChange",
  movingAvgCostChangeReport: "movingAvgCostChangeHistory",
  productDetail: "/app/product/details",
  adminUsersPath: "/admin/users",
  adminSetting: "/admin/settings",
  manageOrder: "/manager/mOrder_detail",
  productCreateTicket: "/tickets/msTicket_by_target",
  // Product End

  // Search Product
  searchProduct: "pos/search/products",

  //Notepad
  notepad: "/app/notepad"
  /*
    Note : Required Later
  */
  // signature = "/app/admin/home/signature",
  // contactinfo = "/app/admin/home/contactinfo",
  // domains = "/app/admin/home/domains",
  // logo = "/app/admin/home/logo",
  // email = "/app/admin/home/email",
  // phones = "/app/admin/home/phones",
  // sociallinks = "/app/admin/home/sociallinks",

  // 4.14.1
  // http://localhost:3000/app/setCookie?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2MzM5Mjc4NTcsImV4cCI6MTYzMzkyODc1NywiaWF0IjoxNjMzOTI3ODU3fQ.vA85EzDjDVBViT5qhJITB4IKopQ6CoElxJWE3DWrqsc&tenantId=3337E633245E45A887F3FEF8F7A9891D&sellerId=3591563&distributorId=1
}
