import { useQuery } from "@tanstack/react-query";

import { stopLoading } from "utils/utils";
import { getDraftsByProductId } from "./constantUrl";

interface ProductIdsProps {
  stockWarehouseId: number,
  productId: number,
}

export const useGetDraftsByProductId = (payload: ProductIdsProps) => {
  const { isLoading, data, error, isSuccess } = useQuery(
    ['get-drafts-by-products-id', payload],
    async () => {
      return getDraftsByProductId(payload);
    },
    {
      retry: false,
      onSuccess : stopLoading,
      onError : stopLoading
});

  // Might need in future
  // const getDraftByProductIdFn = useCallback(
  //   (payload) => {
  //     refetch(payload);
  //   },
  //   [refetch]
  // );

  return {
    isLoading,
    draftsByProductIdData: data,
    error,
    isSuccess,
  };
};
