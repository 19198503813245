import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import { PosResponseProps, RootState } from "models";

import { singleRowURL } from "./constantUrl";
import { getLocalStoraGeItem } from "utils/utils";

import { getSingleRowsDataFail, getSingleRowsDataRequest, getSingleRowsDataSuccess } from "sagas/pos/actions";



export const useSingleRowsData = () => {
  const dispatch = useDispatch();

  const { productsData } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );

  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {};

  const { productId } = productsData

  const { mutate: individualRowRefetch ,isLoading} = useMutation(
    ["individual-rows"],
    async () => {
      dispatch(getSingleRowsDataRequest());
      return singleRowURL(stockWarehouseId, productId).then((response) => {
        return response?.data;
      });
    },
    {
      retry: false,
      // refetchOnWindowFocus: false,
      onSuccess(data) {
        dispatch(getSingleRowsDataSuccess(data));
      },
      onError(err) {
        dispatch(getSingleRowsDataFail(err));
      },
    }
  );

  return { individualRowRefetch, isSingleRowsData: isLoading };
};