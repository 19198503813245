import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface QuoteDetailsSliceValues {
  openMiniNote: boolean;
}

const initialState = {
  openMiniNote: false,
};

export const quoteDetailsSlice = createSlice({
  name: "quoteDetailsSlice",
  initialState,
  reducers: {
    setOpenMiniNote: (state, action: PayloadAction<boolean>) => {
      state.openMiniNote = action.payload;
    },
  },
});

export const { setOpenMiniNote } = quoteDetailsSlice.actions;

export default quoteDetailsSlice.reducer;
