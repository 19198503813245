import { AnyAction } from "redux";
import * as constants from "./constants";

const initialState = {
  isLoading: false,
  quoteReps: [],
  quoteMessages: [],
  getNotes: [],
  quoteEstimatorNotes: "",
  quoteItemActionNotes: "",
  quoteJobHighlights: "",
  quoteMajorReps: "",
  quoteEndRecords: [],
  quoteDetail: [],
  selectedQuoteId: "",
  subQuoteList: [],
  parentQuote: "",
  documentTypeList: [],
  likelyHoodComment: "",
  salesPersonData: [],
  packageInfoData: [],
  versionHistory: [],
  quoteItems: [],
  quoteDocs: [],
  quoteCounts: 0,
  alertShow: false,
  alertMessage: "",
  isErrorAlert: false,
  isDocUploadModal: false,
  quoteRfi: [],
  quoteFollowUpNotes: [],
  isEstimatorLoading: false,
  isInitialLoading: false,
  isMajorRepsLoading: false,
  isJobHighlightsLoading: false,
  isItemActionLoading: false,
  orderDetails: [],
  quoteRfiLoading : false,
  quoteMessagesLoading : false,
  orderDetailsLoading : false,
  quoteRepsLoading : false,
};

const reducer = function quotesReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case constants.GET_REPS_START:
      return { ...state, isLoading: true , quoteRepsLoading : true};
    case constants.GET_REPS_SUCCESS:
      return { ...state, quoteReps: action.payload, isLoading: false , quoteRepsLoading : false };
    case constants.GET_REPS_ERROR:
      return { ...state, isLoading: false , quoteRepsLoading : false };
    case constants.GET_MESSAGES_START:
      return { ...state, isLoading: true , quoteMessagesLoading : true };
    case constants.GET_MESSAGES_SUCCESS:
      return { ...state, quoteMessages: action.payload, isLoading: false , quoteMessagesLoading : false};
    case constants.GET_MESSAGES_ERROR:
      return { ...state, isLoading: false ,quoteMessagesLoading : false};
    case constants.ADD_MESSAGE_START:
      return {
        ...state,
        isLoading: true,
        alertShow: false,
        alertMessage: "",
        isErrorAlert: false,
      };
    case constants.ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: false,
      };
    case constants.ADD_MESSAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: true,
      };
    case constants.GET_ESTIMATOR_NOTES_START:
      return { ...state, isLoading: true , isEstimatorLoading : true};
    case constants.GET_ESTIMATOR_NOTES_SUCCESS:
      return {
        ...state,
        quoteEstimatorNotes: action.payload,
        isLoading: false,
        isEstimatorLoading : false
      };
    case constants.GET_ESTIMATOR_NOTES_ERROR:
      return { ...state, isLoading: false , isEstimatorLoading : false};
    case constants.GET_ITEM_ACTION_NOTES_START:
      return { ...state, isLoading: true , isItemActionLoading : true};
    case constants.GET_ITEM_ACTION_NOTES_SUCCESS:
      return {
        ...state,
        quoteItemActionNotes: action.payload,
        isLoading: false,
        isItemActionLoading : false
      };
    case constants.GET_ITEM_ACTION_NOTES_ERROR:
      return { ...state, isLoading: false , isItemActionLoading : false};

    case constants.GET_NOTES_START:
      return {
        ...state,
        isLoading: true,
        alertShow: false,
        alertMessage: "",
        isErrorAlert: false,
        isInitialLoading : true
      };
    case constants.GET_NOTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        alertShow: false,
        getNotes: action.payload,
        isErrorAlert: false,
        isInitialLoading : false
      };
    case constants.GET_NOTES_ERROR:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: true,
        isInitialLoading : false
      };

    case constants.ADD_NOTE_START:
      return {
        ...state,
        isLoading: true,
        alertShow: false,
        alertMessage: "",
        isErrorAlert: false,
      };
    case constants.ADD_NOTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: false,
      };
    case constants.ADD_NOTE_ERROR:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: true,
      };

    case constants.UPDATE_JOB_HIGHLIGHT_NOTE_START:
      return {
        ...state,
        isLoading: true,
        alertShow: false,
        alertMessage: "",
        isErrorAlert: false,
      };
    case constants.UPDATE_JOB_HIGHLIGHT_NOTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: false,
      };
    case constants.UPDATE_JOB_HIGHLIGHT_NOTE_ERROR:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: true,
      };

    case constants.UPDATE_ESTIMATOR_NOTE_START:
      return {
        ...state,
        isLoading: true,
        alertShow: false,
        alertMessage: "",
        isErrorAlert: false,
      };
    case constants.UPDATE_ESTIMATOR_NOTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: false,
      };
    case constants.UPDATE_ESTIMATOR_NOTE_ERROR:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: true,
      };

    case constants.UPDATE_ITEMS_ACTION_NOTE_START:
      return {
        ...state,
        isLoading: true,
        alertShow: false,
        alertMessage: "",
        isErrorAlert: false,
      };
    case constants.UPDATE_ITEMS_ACTION_NOTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: false,
      };
    case constants.UPDATE_ITEMS_ACTION_NOTE_ERROR:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: true,
      };

    case constants.UPDATE_MAJOR_REPS_START:
      return {
        ...state,
        isLoading: true,
        alertShow: false,
        alertMessage: "",
        isErrorAlert: false,
      };
    case constants.UPDATE_MAJOR_REPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: false,
      };
    case constants.UPDATE_MAJOR_REPS_ERROR:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: true,
      };

    case constants.ADD_DOCUMENT_START:
      return {
        ...state,
        isLoading: true,
        alertShow: false,
        alertMessage: "",
        isErrorAlert: false,
      };
    case constants.ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: false,
      };
    case constants.ADD_DOCUMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        alertShow: true,
        alertMessage: action.payload,
        isErrorAlert: true,
      };
    case constants.GET_JOB_HIGHLIGHTS_START:
      return { ...state, isLoading: true , isJobHighlightsLoading : true};
    case constants.GET_JOB_HIGHLIGHTS_SUCCESS:
      return { ...state, quoteJobHighlights: action.payload, isLoading: false , isJobHighlightsLoading : false};
    case constants.GET_JOB_HIGHLIGHTS_ERROR:
      return { ...state, isLoading: false ,isJobHighlightsLoading : false };
    case constants.GET_MAJOR_REPS_START:
      return { ...state, isLoading: true , isMajorRepsLoading : true};
    case constants.GET_MAJOR_REPS_SUCCESS:
      return { ...state, quoteMajorReps: action.payload, isLoading: false, isMajorRepsLoading : false };
    case constants.GET_MAJOR_REPS_ERROR:
      return { ...state, isLoading: false , isMajorRepsLoading : false};
    case constants.GET_QUOTES_END_RECORD_START:
      return { ...state, isLoading: true };
    case constants.GET_QUOTES_END_RECORD_SUCCESS:
      return { ...state, quoteEndRecords: action.payload, isLoading: false };
    case constants.GET_QUOTES_END_RECORD_ERROR:
      return { ...state, isLoading: false };
    case constants.SET_QUOTE_DETAIL:
      return { ...state, quoteDetail: action.payload, isLoading: false };
    case constants.SELECTED_QUOTE_ID:
      return {
        ...state,
        selectedQuoteId: action.payload,
        isLoading: false,
      };

    case constants.GET_VERSION_HISTORY_START:
      return { ...state, isLoading: true };
    case constants.GET_VERSION_HISTORY_SUCCESS:
      return { ...state, versionHistory: action.payload, isLoading: false };
    case constants.GET_VERSION_HISTORY_ERROR:
      return { ...state, isLoading: false };

    case constants.SET_DOC_UPLOAD_MODAL:
      return { ...state, isDocUploadModal: action.payload, isLoading: false };
    case constants.SET_SUB_QUOTE_LIST:
      return { ...state, subQuoteList: action.payload, isLoading: false };
    case constants.SET_PARENT_QUOTE:
      return { ...state, parentQuote: action.payload, isLoading: false };
    case constants.SET_DOCUMENT_TYPE:
      return { ...state, documentTypeList: action.payload, isLoading: false };
    case constants.SET_LIKELYHOOD_COMMENT:
      return { ...state, likelyHoodComment: action.payload, isLoading: false };
    case constants.SET_SALESPERSON_DATA:
      return { ...state, salesPersonData: action.payload, isLoading: false };
    case constants.SET_PACKAGE_INFO:
      return { ...state, packageInfoData: action.payload, isLoading: false };
    case constants.SET_QUOTE_ITEMS:
      return { ...state, quoteItems: action.payload, isLoading: false };
    case constants.SET_QUOTE_DOCS:
      return { ...state, quoteDocs: action.payload, isLoading: false };
    case constants.SET_QUOTE_COUNTS:
      return { ...state, quoteCounts: action.payload, isLoading: false };
    case constants.CLEAR_ALERT_DATA:
      return {
        ...state,
        alertShow: false,
        alertMessage: "",
        isErrorAlert: false,
        isLoading: false,
      };
    case constants.GET_RFI_START:
      return { ...state, isLoading: true , quoteRfiLoading : true};
    case constants.GET_RFI_SUCCESS:
      return { ...state, quoteRfi: action.payload, isLoading: false , quoteRfiLoading : false};
    case constants.GET_RFI_ERROR:
      return { ...state, isLoading: false , quoteRfiLoading : false};
    case constants.GET_FOLLOW_UP_START:
      return { ...state, isLoading: true };
    case constants.GET_FOLLOW_UP_SUCCESS:
      return { ...state, quoteFollowUpNotes: action.payload, isLoading: false };
    case constants.GET_FOLLOW_UP_ERROR:
      return { ...state, isLoading: false };
    case constants.GET_ORDERS_DETAILS_START:
      return {...state, isLoading: true , orderDetailsLoading : true};
    case constants.GET_ORDERS_DETAILS_SUCCESS:
      return { ...state, orderDetails: action.payload, isLoading: false , orderDetailsLoading : false};
    case constants.GET_ORDERS_DETAILS_ERROR:
      return { ...state, isLoading: false , orderDetailsLoading : false};
    case constants.RESET_ALL_NOTES_REPS :
      return { ...state , getNotes : [] , quoteEstimatorNotes : "" , quoteItemActionNotes : "" , quoteJobHighlights : "" , quoteMajorReps : "" , quoteFollowUpNotes : [] , quoteReps :[] , quoteMessages : [] , quoteRfi : [] , orderDetails : []}
    default:
      return state;
  }
};

export default reducer;
