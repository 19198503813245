import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Will remove 'any' once we get proper data from BE
export interface ProductPriceHistorySliceI {
  isGetProductsPriceHistory: boolean;
  getProductPriceHistoryData: any;
  getProductPriceHistoryError: any;
}

const initialState: ProductPriceHistorySliceI = {
    isGetProductsPriceHistory: false,
    getProductPriceHistoryData: null,
    getProductPriceHistoryError: null,
};

export const productsPriceHistorySlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    getProductsPriceHistoryStart: (state) => {
      state.getProductPriceHistoryData = null;
      state.isGetProductsPriceHistory = true;
    },
    getProductsPriceHistorySuccess: (state, action: PayloadAction<any>) => {
      state.getProductPriceHistoryData = action.payload;
      state.isGetProductsPriceHistory = false;
    },
    getProductsPriceHistoryError: (state, action: PayloadAction<any>) => {
      state.getProductPriceHistoryError = action.payload;
      state.getProductPriceHistoryData = null;
      state.isGetProductsPriceHistory = false;
    },
    getProductsPriceHistoryReset: (state) => {
      state.getProductPriceHistoryData = null;
      state.isGetProductsPriceHistory = false;
    },

  }
});

// Action creators are generated for each case reducer function
export const {
    getProductsPriceHistoryStart,
    getProductsPriceHistorySuccess,
    getProductsPriceHistoryError,
    getProductsPriceHistoryReset
} = productsPriceHistorySlice.actions;

export default productsPriceHistorySlice.reducer;
