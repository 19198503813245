import "@fontsource/inter";
import { Box, Grid, Tooltip } from "@material-ui/core";
import { Container, Drawer } from "@mui/material";
import _ from "loadsh";
import { PosResponseProps, RootState, user } from "models";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  PosButton,
  PosHelmet,
  PosIconButton,
  PosImage,
  PosModel,
  PosPageHeading,
} from "apps/pos/Common/uiCore";
import HandlingFee from "apps/pos/ReturnProduct/handlingFee";
import LeaveReturnCartModal from "apps/pos/ReturnProduct/Modal/LeaveReturnCartModal/LeaveReturnCartModal";
import ReturnCartNewModal from "apps/pos/ReturnProduct/Modal/ReturnCart/ReturnCartModalNew";
import SearchCustomerModel from "apps/pos/ReturnProduct/Modal/SearchCustomerModel/SearchCustomerModel";
import SearchProductModel from "apps/pos/ReturnProduct/Modal/SearchProductModel/SearchProductModel";
import RestockingFeeModal from "apps/pos/ReturnProduct/restockingFeeModal";
import ShippingCreditModal from "apps/pos/ReturnProduct/shippingCreditModal";
import SelectTakerSRDrawer from "../SelectTakerSRDrawer/SelectTakerSRDrawer";
import RemoveReturnCartOrder from "./Modal/RemoveReturnCartOrder/RemoveReturnCartOrder";
import SearchCommonComponent from "./searchCommonComponent/searchCommonComponent";

import { letterRegex, staticData } from "constants/constant";
import { DateFormat, paymentType, PaymentTypeEnum } from "constants/enum";
import { CloseIcon } from "constants/icons";
import images from "constants/images";
import { FeeDataProps, ReturnFlowDataMainProps } from "constants/interfaces";
import { Paths, PaymentMode } from "enums";
import { getLocalStoraGeItem, isoToFormat, roundToTwoDecimalPlaces } from "utils/utils";

import { keyPressHandle } from "apps/pos/ReturnProduct/ReturnProductHelperFunction";
import {
  handlingFeeDataClearData,
  handlingFeeReplaceInfo,
  restockingFeeDataClearData,
  restockingFeeReplaceInfo,
  setRefundMethodCheckData,
  setReturnAllDataArrayAction,
  setReturnFlowData,
  setTaxMessage,
  shippingFeeDataClearData,
} from "sagas/persistReducer/actions";
import {
  clearSearchProduct,
  stockByWarehouseStart,
  takersRequest
} from "sagas/pos/actions";
import {
  addReturnDataModalBtnAction,
  finalArrAction,
  getCompleteReturnPayloadData,
  getReasonRequest,
  returnProductDataModalAction,
  returnProductsOrdersRequest,
  returnSearchedCustomerDataAction,
  returnSearchedInvoiceDataAction,
  returnSearchedProductDataAction,
  searchReturnInvoiceClear,
  searchReturnInvoiceRequest,
} from "sagas/returns/actions";
import { beforeUnloadCart, getprivacySettingsIconHeight, renamePersonData } from "utils/helper";

import "apps/pos/poscommonstyling.scss";
import "./returnProductCart.scss";

const ReturnProductCart = () => {
  const {
    returnCartSubmitSuccess,
    returnSearchedCustomerData,
    returnSearchedProductData,
    returnProductDataModal,
    returnSearchedInvoiceData,
    isReturnProductsOrdersLoading,
    returnReason,
  } = useSelector<RootState, ReturnFlowDataMainProps>(
    (state) => state.returnReducer
  );

  const {
    returnFlowData,
    shippingFeeData,
    restockingFeeData,
    handlingFeeValue,
    returnAllDataArray,
    refundMethodCheckedArray
  } = useSelector<RootState, ReturnFlowDataMainProps>(
    (state) => state.persistReducer
  );
  const { loggedInUser } = useSelector<
    RootState,
    user
  >((state) => state.user);
  const { getRowsActiveDetails, takerDetails } = useSelector<
    RootState,
    PosResponseProps
  >((state) => state.pos);
  const { addedReturnedProduct, returnCartData } = returnFlowData || {};
  const { productMainInformation } = returnCartData || {};

  const [scmodel, setScmodel] = useState(false);
  const [leavemodel, setLeavemodel] = useState(false);
  const [removeOrder, setRemoveOrder] = useState(false);
  const [modal, setModal] = useState({ isOpen: false, modalTitle: "" });
  const [cartEditModel, setCartEditModel] = useState<boolean>(false);
  const [selectRowReturnData, setSelectRowReturnData] = useState<any>();
  const [shippingReturnedValue, setShippingReturnedValue] = useState(null);
  const [shippingFeeValue, setShippingFeeValue] = useState(null);
  const [shippingTotalFeeValue, setShippingTotalFeeValue] = useState(null);
  const [shippingCreditValue, setShippingCreditValue] = useState(null);
  const [shippingPriceTotalValue, setShippingPriceTotalValue] = useState(null);
  const [initialSubTotalAmount, setInitialSubTotalAmount] = useState(null);
  const [feeIndex, setFeeIndex] = useState(null);
  const [restockingTotalInfo, setRestockingTotalInfo] = useState(null);
  const [passShippingData, setPassShippingData] = useState(0);
  const [passRestockingData, setPassRestockingData] = useState(0);
  const [passHandlingData, setPassHandlingData] = useState(0);
  const [passRestockingRateData, setPassRestockingRateData] = useState(0);
  const [selectTakerDrawer, setSelectTakerDrawer] = useState(false);
  const [isConditionTrue, setIsConditionTrue] = useState(true);
  const [itemCredit, setItemCredit] = useState(0);
  const currentReturnTaker = getLocalStoraGeItem("returnTaker");
  const [orderArrayValue, setOrderArrayValue] = useState([]);
  const [orderArrayId, setOrderArrayId] = useState<number>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cardIcon, cashIcon, storeCreditIcon, payLaterIcon, checkIcon } = images
  // if the page is closed when cart has items, show a confirmation alert
  useEffect(() => {
    beforeUnloadCart(returnAllDataArray);
  }, [returnAllDataArray]);

  useEffect(() => {
    if (!returnFlowData?.addedReturnedProduct?.length) {
      navigate(Paths.posNewOrder);
    }
  }, []);

  useEffect(() => {
    dispatch(setReturnAllDataArrayAction(addedReturnedProduct));
  }, []);

  const handleCloseSearchProductModel = () => {
    dispatch(returnProductDataModalAction(false));
  };

  const handleCloseSearchCustomerModel = () => {
    setScmodel(false);
  };

  const handleShippingCreditCallback = () => {
    // Required later
  };
  const handleChangeRestockingCallback = () => {
    // Required later
  };
  const handlingFeeDataCallback = () => {
    // Required later
  };

  const findRefundCheckedData = (orderId) => {
    return refundMethodCheckedArray?.find(refund => refund.orderId === orderId) ?? {};
  }
  const sameOrderIdData = _.groupBy(returnAllDataArray, "orderId");

  // start
  const itemCreditCalculationArray = _.map(sameOrderIdData, (value) => {
    return value.filter((item) => item.paidVia === "CASH");
  }).flat();

  const output = [];
  const uniqueName = Array.from(
    new Set(itemCreditCalculationArray.map((x) => x.orderId))
  );
  uniqueName.forEach((n) => {
    output.push(
      itemCreditCalculationArray
        .filter((x) => x.orderId === n)
        .reduce((a, item) => {
          const returnQtyValue =
            a["orderId"] === undefined
              ? Number(item.returnQty)
              : a["returnQty"] + Number(item.returnQty);
          const unitePriceValue =
            a["orderId"] === undefined
              ? Number(item.unitPrice)
              : a["unitPrice"] + Number(item.unitPrice);

          return {
            orderId: n,
            returnQty: returnQtyValue,
            unitPrice: unitePriceValue,
          };
        }, {})
    );
  });

  const mergeById = (array1, array2) =>
    array1?.map((itm) => ({
      ...array2?.find((item) => item?.orderId === itm?.orderId && item),
      ...itm,
    }));

  const finalAllShippingFeeData = mergeById(output, shippingFeeData);
  const finalAllRestockingFeeData = mergeById(output, restockingFeeData);
  const finalAllHandlingFeeData = mergeById(output, handlingFeeValue);

  const newShippingFee = finalAllShippingFeeData
    ?.map((i) => {
      const refundMethodData = findRefundCheckedData(i.orderId)
      return refundMethodData.toggle ? 0 : (i?.shippingFeeChange ?? 0)
    })
    .reduce((total, obj) => Number(obj) + Number(total), 0);

  const newRestockingFee = finalAllRestockingFeeData
    ?.map((i) => {
      const refundMethodData = findRefundCheckedData(i.orderId);
      return refundMethodData.toggle ? 0 : (i?.restockingFee ?? 0)
    })
    .reduce((total, obj) => Number(obj) + Number(total), 0);

  const newHandlingFee = finalAllHandlingFeeData
    ?.map((i) => {
      const refundMethodData = findRefundCheckedData(i.orderId);
      return refundMethodData.toggle ? 0 : (i?.handlingFee ?? 0)
    })
    .reduce((total, obj) => Number(obj) + Number(total), 0);

  const newItemCreditDataForReturn = _.map(sameOrderIdData, (value) => {
    return value;
  })
    .flat()
    .filter((item) => {
      const refundMethodData = findRefundCheckedData(item.orderId);
      if (item.paidVia === PaymentTypeEnum.CASH && !refundMethodData?.toggle) {
        return item
      }
    })
    .map((item) => {
      return item.unitPrice / (item.priceMU * 1.0) * Number(item.returnQty) * item.itemMU
    })
    .reduce((total, obj) => Number(obj) + Number(total), 0);

  const newItemCreditDataForReplace = _.map(sameOrderIdData, (value) => {
    return value;
  })
    .flat()
    .filter((item) => {
      const refundMethodData = findRefundCheckedData(item.orderId);
      if (item.paidVia === PaymentTypeEnum.CASH && !refundMethodData?.toggle) {
        return item
      }
    })
    .map((item) => {
      return item.unitPrice / (item.priceMU * 1.0) * Number(item.replaceQty) * item.itemMU
    })
    .reduce((total, obj) => Number(obj) + Number(total), 0);


  const newtotalTaxCredit = _.map(sameOrderIdData, (value) => {
    return value;
  })
    .flat()
    .filter((item) => {
      const refundMethodData = findRefundCheckedData(item.orderId);
      if (item.paidVia === PaymentTypeEnum.CASH && !refundMethodData?.toggle) {
        return item
      }
    })
    .map((item) => {
      return (Number(item.unitPrice / (item.priceMU * 1.0) * Number(item.returnQty + item.replaceQty) * item.itemMU) * Number(item.taxRate))
    })
    .reduce((total, obj) => Number(obj) + Number(total), 0);

  const totalRefundViaCashNew =
    Number(Number(roundToTwoDecimalPlaces(newItemCreditDataForReturn)) + Number(roundToTwoDecimalPlaces(newItemCreditDataForReplace)) + Number(roundToTwoDecimalPlaces(newtotalTaxCredit)) +
      Number(roundToTwoDecimalPlaces(newShippingFee ?? 0))) -
    (Number(newRestockingFee ?? 0) + Number(newHandlingFee ?? 0));

  // end

  const orderDetailsModalOpen = () => {
    switch (modal.modalTitle) {
      case "Change Shipping Credit":
        return (
          <ShippingCreditModal
            setModal={setModal}
            shippingReturnedInfo={shippingReturnedValue}
            setShippingFeeInfo={shippingFeeValue}
            setShippingCreditInfo={shippingCreditValue}
            shippingPriceTotal={shippingPriceTotalValue}
            shippingFeeIndex={feeIndex}
            handleShippingCreditCallback={handleShippingCreditCallback}
            passShippingData={passShippingData}
            restockingTotalInfo={restockingTotalInfo}
            shippingFee={shippingTotalFeeValue}
            initialSubTotalAmount={initialSubTotalAmount}
          />
        );
      case "Change Restocking Fee":
        return (
          <RestockingFeeModal
            passRestockingData={passRestockingData}
            passRestockingRateData={passRestockingRateData}
            restockingFeeIndex={feeIndex}
            handleChangeRestockingCallback={handleChangeRestockingCallback}
            itemCreditData={itemCredit}
            onClose={() =>
              setModal({
                isOpen: false,
                modalTitle: modal.modalTitle,
              })
            }
          />
        );
      case "Change Handling Fee":
        return (
          <HandlingFee
            handlingFeeIndex={feeIndex}
            handlingFeeDataCallback={handlingFeeDataCallback}
            setModal={setModal}
            passHandlingData={passHandlingData}
          />
        );
    }
  };

  useEffect(() => {
    if (!returnReason?.length) dispatch(getReasonRequest());
    dispatch(takersRequest());
  }, []);

  useEffect(() => {
    if (loggedInUser && loggedInUser?.ssoId && loggedInUser?.fullName) {
      const data = {
        "personId": loggedInUser?.ssoId,
        "fullName": loggedInUser?.fullName,
        "loginSessionID": loggedInUser?.personId,
        "email": loggedInUser?.email,
        "phone": loggedInUser?.phone
      }
      localStorage.setItem("returnTaker", JSON.stringify(data));
    } else {
      (async () => {
        if (Object.keys(takerDetails).length && Object.keys(loggedInUser).length) {
          const taker = await takerDetails?.data?.filter((taker) => taker?.personId === loggedInUser?.ssoId)
          localStorage.setItem("returnTaker", JSON.stringify(taker?.length ? taker[0] : takerDetails?.data[0]))
        }
      })();
    }
  }, [takerDetails, loggedInUser])

  useEffect(() => {
    if (!isConditionTrue) {
      setSelectTakerDrawer(true);
    }
    else {
      setSelectTakerDrawer(false);
    }
  }, [isConditionTrue])

  const { MM_DD_YYYY } = DateFormat;

  const handleEditReturnSelect = (product) => {
    dispatch(stockByWarehouseStart([product?.productId]));
    if (!returnReason?.length) dispatch(getReasonRequest());
    dispatch(addReturnDataModalBtnAction(false));
    setSelectRowReturnData(product);
    if (returnReason?.length) {
      setTimeout(() => {
        setCartEditModel(true);
      }, 100);
    }
  };

  const handleCloseReturnProductEditModel = () => {
    setCartEditModel(false);
  };

  const handleClearCart = (pathName) => {
    addedReturnedProduct?.length > 0 && addedReturnedProduct?.pop();
    dispatch(
      setReturnFlowData({
        addedReturnedProduct: addedReturnedProduct,
        returnCartData: {},
      })
    );
    // setReturnAllDataArray(returnAllDataArray?.splice(0, returnAllDataArray.length));
    dispatch(
      setReturnAllDataArrayAction(
        returnAllDataArray?.splice(0, returnAllDataArray.length)
      )
    );
    // returnAllDataArray = [];
    dispatch(setReturnAllDataArrayAction([]));
    dispatch(searchReturnInvoiceClear());
    dispatch(shippingFeeDataClearData());
    dispatch(restockingFeeDataClearData());
    dispatch(handlingFeeDataClearData());
    dispatch(finalArrAction([]));
    dispatch(setRefundMethodCheckData([]))
    navigate(pathName);
    dispatch(setTaxMessage(true));
  };
  const handleRemoveOrder = () => {
    const finalReturnAllDataArray = returnAllDataArray.filter((item) => {
      return Number(item?.orderId) != Number(orderArrayId);
    });
    returnAllDataArray.splice(
      0,
      returnAllDataArray.length,
      ...finalReturnAllDataArray
    );
    const refundMethodArray = refundMethodCheckedArray.filter((item) => {
      return Number(item?.orderId) != Number(orderArrayId);
    })
    refundMethodCheckedArray.splice(
      0,
      refundMethodCheckedArray.length,
      ...refundMethodArray
    )
    dispatch(setRefundMethodCheckData(refundMethodArray))
    dispatch(
      setReturnFlowData({
        addedReturnedProduct: returnAllDataArray,
        returnCartData,
      })
    );
    dispatch(finalArrAction(returnAllDataArray));
    // setReturnAllDataArray(returnAllDataArray);
    dispatch(setReturnAllDataArrayAction(returnAllDataArray));
    setRemoveOrder(false);
    if (returnAllDataArray?.length === 0) {
      navigate(Paths.posReturnProducts);
      dispatch(searchReturnInvoiceClear());
      dispatch(returnSearchedInvoiceDataAction(""));
      dispatch(returnSearchedCustomerDataAction({}));
      dispatch(returnSearchedProductDataAction({}));
    }
  };

  const removeReturnedProduct = (key, id, lineId) => {
    if (returnAllDataArray?.length === 1) {
      returnAllDataArray.pop();
      dispatch(
        setReturnFlowData({
          addedReturnedProduct: returnAllDataArray,
          returnCartData: {},
        })
      );
      // setReturnAllDataArray(returnAllDataArray);
      dispatch(setReturnAllDataArrayAction(returnAllDataArray));
      dispatch(finalArrAction(returnAllDataArray));
      dispatch(restockingFeeDataClearData());
      dispatch(handlingFeeDataClearData());
      setCartEditModel(false);
      navigate(Paths.posReturnProducts);
    } else {
      returnAllDataArray.splice(
        returnAllDataArray.findIndex(
          (a) =>
            Number(a.orderId) === Number(key) &&
            Number(a.productId) === Number(id) &&
            Number(a.lineId) === Number(lineId)
        ),
        1
      );
      dispatch(
        setReturnFlowData({
          addedReturnedProduct: returnAllDataArray,
          returnCartData,
        })
      );
      dispatch(finalArrAction(returnAllDataArray));
      // setReturnAllDataArray(returnAllDataArray);
      dispatch(setReturnAllDataArrayAction(returnAllDataArray));
      const latestRestocking: FeeDataProps[] = restockingFeeData?.filter((element) => Number(element?.orderId) !== Number(key));
      dispatch(restockingFeeReplaceInfo(latestRestocking))
      const latestHandling: FeeDataProps[] = handlingFeeValue?.filter((element) => Number(element?.orderId) !== Number(key));
      dispatch(handlingFeeReplaceInfo(latestHandling))
      setCartEditModel(false);
    }
  };
  const currentPathUrl = window.location.href;
  const currentWorkspace = getLocalStoraGeItem("workspace");

  const completeReturn = () => {
    const returnOrdersDetails = [];

    _.forEach(sameOrderIdData, (value, key) => {
      const totalReturnQuantity = value.reduce(
        (total, obj) => Number(obj.returnQty) + Number(total),
        0
      );
      const refundMethodData = findRefundCheckedData(Number(key));
      returnOrdersDetails.push({
        orderID: Number(key),
        customerId: value?.[0]?.customerId,
        stockWarehouseId: currentWorkspace?.stockWarehouseId,
        takerID: currentReturnTaker?.personId,
        isStoreCredit: refundMethodData?.toggle,
        shipCredit: !totalReturnQuantity
          ? 0
          : Number(
            shippingFeeData?.find(
              (item) => Number(item?.orderId) === Number(key)
            )?.shippingFeeChange
          )
            ? Number(
              shippingFeeData?.find(
                (item) => Number(item?.orderId) === Number(key)
              )?.shippingFeeChange
            )
            : 0,
        restockFee: !totalReturnQuantity
          ? 0
          : Number(
            restockingFeeData?.length &&
            restockingFeeData?.find(
              (item) => Number(item?.orderId) === Number(key)
            )?.restockingFee
          )
            ? Number(
              restockingFeeData?.length &&
              restockingFeeData?.find(
                (item) => Number(item?.orderId) === Number(key)
              )?.restockingFee
            )
            : 0,
        handlingFee: !totalReturnQuantity
          ? 0
          : Number(
            handlingFeeValue?.length &&
            handlingFeeValue?.find(
              (item) => Number(item?.orderId) === Number(key)
            )?.handlingFee
          )
            ? Number(
              handlingFeeValue?.length &&
              handlingFeeValue?.find(
                (item) => Number(item?.orderId) === Number(key)
              )?.handlingFee
            )
            : 0,
        orderRmaId: 0,
        returnItems: value?.map((item) => {
          return {
            activeFlag: item?.activeFlag,
            productID: item?.productId,
            shipmentId: item?.shipmentId,
            lineID: item?.lineId,
            uniqueID: item?.id,
            returnQty: Number(item?.returnQty),
            itemPrice: Number(item?.unitPrice),
            replaceQty: item?.replaceQty ? Number(item?.replaceQty) : 0,
            overShipmentQty: Number(item?.overShipmentQty),
            additionalQty: item?.additionalQty
              ? Number(item?.additionalQty)
              : 0,
            rmaReasonID: item?.reason,
            mfrReturnPolicy: item?.manufacturerReturnPolicy,
            returnReceiptLocations: item?.returnTo?.map((data) => {
              const selectedSaleFlag = getRowsActiveDetails?.find((item) => {
                return item?.rowID === data?.row.id;
              });
              return {
                stockRowID: data?.row.id,
                stockUnitID: data?.unit.id,
                stockShelfID: data?.shelf.id,
                qtyToReturn: Number(data?.returnQty) + Number(data?.replaceQty) + Number(data?.overShipmentQty),
                notForSell: selectedSaleFlag?.notForSellFlag ?? 0,
              };
            }),
            replacePickupLocations: [],
          };
        }),
      });
    });

    const returnProcessData = {
      requestUrl: currentPathUrl,
      returnOrders: returnOrdersDetails,
    };
    dispatch(getCompleteReturnPayloadData(sameOrderIdData));
    dispatch(
      returnProductsOrdersRequest(
        returnProcessData,
        currentWorkspace?.stockWarehouseId
      )
    );
    dispatch(setTaxMessage(true))
  };

  useEffect(() => {
    if (returnCartSubmitSuccess) {
      navigate(`${Paths.posReturnComplete}`, {
        state: {
          addedReturnedProduct: addedReturnedProduct,
          returnAllDataArray: returnAllDataArray,
        },
      });
    }
  }, [returnCartSubmitSuccess]);

  function containsAnyLetters(str) {
    return letterRegex.test(str);
  }

  const searchCustomerOnChange = (event, setScmodel) => {
    if (containsAnyLetters(event.target.value)) {
      dispatch(
        returnSearchedCustomerDataAction({
          ...returnSearchedCustomerData,
          fullName: event.target.value,
        })
      );
      dispatch(returnSearchedInvoiceDataAction(""));
      setScmodel(true);
    } else {
      dispatch(returnSearchedCustomerDataAction(""));
      dispatch(returnSearchedInvoiceDataAction(event.target.value));
    }
  };

  const searchProductOnChange = (e, returnSearchedProductDataAction) => {
    if (!e.target.value.includes("  ") && e.target.value.charAt(0) !== " ") {
      dispatch(returnSearchedProductDataAction(e.target.value));
    }
  };

  const returnCartProductContainerElement = document?.querySelector(
    "#return-cart-product-container"
  );

  if (returnCartProductContainerElement) {
    returnCartProductContainerElement["style"].marginTop = `${getprivacySettingsIconHeight() - 10
      }px`;
  }

  const { taxApplicable, taxReturns } = staticData;

  return (
    <>
      <PosHelmet
        title="Simplified Return Cart"
        metaName="Return Cart page"
        content="Return Cart page"
      />
      <div className="privacy-settings-icon"></div>
      <Container
        id="return-cart-product-container"
        className="new-container return-cart-product-container p-0"
      >
        <section className="return-product-cart-main-section">
          <div className="return-product-cart-heading position-relative">
            <PosIconButton
              className="newOrder-qty-back-btn position-back-return return-product-page-back-btn"
              onClick={() => setLeavemodel(true)}
            >
              <CloseIcon />
            </PosIconButton>
            <div className="d-flex justify-content-between">
              <div>
                <PosPageHeading heading="Returns Cart" blackHeading />
                <div className="return-product-items-div">
                  <PosPageHeading
                    heading={`${returnAllDataArray?.length} ${returnAllDataArray?.length === 1 ? "item" : "items"
                      }`}
                    grayHeading
                  />
                  <PosButton
                    onClick={() => handleClearCart(Paths.posReturnProducts)}
                    variant="contained"
                    color="secondary"
                    className="return-clear-cart"
                  >
                    Clear Cart
                  </PosButton>
                </div>
              </div>
              <div className="info-blocks-sr">
                <div
                  className="info-block"
                  onClick={() => {
                    setSelectTakerDrawer(true);
                  }}
                >
                  <span className="label label-with-icon">TAKER</span>

                  <span
                    className={`primary-text ${currentWorkspace?.warehouseName ? "one-line" : ""
                      }`}
                  >
                    {currentReturnTaker?.fullName ?? ""}
                  </span>
                  <span className="secondary-text">
                    {currentWorkspace?.warehouseName}
                  </span>
                </div>
                {selectTakerDrawer && <SelectTakerSRModal
                  setIsConditionTrue={setIsConditionTrue}
                  isConditionTrue={isConditionTrue}
                  selectTakerDrawer={selectTakerDrawer}
                  setSelectTakerDrawer={setSelectTakerDrawer}
                />}
              </div>
            </div>
          </div>
          <SearchCommonComponent
            searchCustomerOnClick={() => {
              navigate(Paths.posReturnProducts);
            }}
            searchFromReturnCart
            searchProductOnClick={() => {
              dispatch(clearSearchProduct());
              dispatch(returnProductDataModalAction(true));
              navigate(Paths.posReturnProducts);
            }}
            searchProductImage={returnSearchedProductData?.thumbImg}
            searchProductValue={returnSearchedProductData?.productName}
            searchProductOnChange={(event) => {
              searchProductOnChange(event, returnSearchedProductDataAction);
            }}
            searchCustomerOnChange={(event) =>
              event && searchCustomerOnChange(event, setScmodel)
            }
            searchCustomerValue={
              renamePersonData(returnSearchedCustomerData?.posCustCode, returnSearchedCustomerData?.companyName) || returnSearchedInvoiceData
            }
            customerKeyPress={(event) => {
              if (
                event.key === "Enter" &&
                (event.target as HTMLInputElement)?.value !== ""
              ) {
                if (
                  (!Number(returnSearchedInvoiceData) &&
                    Object.keys(returnSearchedCustomerData).length === 0 &&
                    Object.keys(returnSearchedProductData).length === 0) ||
                  (Object.keys(returnSearchedCustomerData).length > 0 &&
                    Object.keys(returnSearchedProductData).length === 0)
                ) {
                  dispatch(
                    returnProductDataModalAction(!returnProductDataModal)
                  );
                } else {
                  dispatch(searchReturnInvoiceClear());
                  keyPressHandle(
                    returnSearchedProductData,
                    searchReturnInvoiceRequest,
                    returnSearchedCustomerData,
                    returnSearchedInvoiceData,
                    dispatch
                  );
                  navigate(Paths.posReturnProducts, {
                    state: { invoiceDetail: returnSearchedInvoiceData },
                  });
                }
              }
            }}
          />

          {_.map(sameOrderIdData, (value, key) => {
            const isPosGenericCustomer = value[0]?.isPosGenericCustomer;
            const refundMethodCheckDetail = findRefundCheckedData(Number(key));
            const paidViaPaylater = value[0]?.paidVia === paymentType.payLater
            const paidViaCustomerCredit = value[0]?.paidVia === paymentType.customerStoreCredit
            const totalReturnQuantity = value.reduce(
              (total, obj) => Number(obj.returnQty + obj.replaceQty) + Number(total),
              0
            );
            const shippingFeeByOrderId = {};
            const totalReturnShippingFee = value.reduce((total, obj) => {
              const { orderId, shippingFee } = obj;

              // If order ID already exists in shippingFeeByOrderId, don't add its shipping fee
              if (!shippingFeeByOrderId[orderId]) {
                shippingFeeByOrderId[orderId] = true;
                return total + Number(shippingFee);
              }

              return total;
            }, 0);
            const shippingCreditByOrderId = {};
            const totalReturnShippingCredit = value.reduce((total, obj) => {
              const { orderId, shippingCredit } = obj;

              // If order ID already exists in shippingCreditByOrderId, don't add its shipping fee
              if (!shippingCreditByOrderId[orderId]) {
                shippingCreditByOrderId[orderId] = true;
                return total + Number(shippingCredit);
              }

              return total;
            }, 0);

            const totalPriceValue = value.reduce(
              (total, obj) => Number(obj.extendedPrice) + Number(total),
              0
            );

            const totalForRestocking = value.reduce(
              (total, obj) =>
                Number((obj.extendedPrice / obj?.shippedQty) * obj.returnQty) +
                Number(total),
              0
            );

            const shippingFeeInfo = shippingFeeData?.find(
              (item) => Number(item?.orderId) === Number(key)
            );
            const restockingFeeInfo = restockingFeeData?.find(
              (item) => Number(item?.orderId) === Number(key)
            );
            const handlingFeeInfo = handlingFeeValue?.find(
              (item) => Number(item?.orderId) === Number(key)
            );
            const shippingFeeDataFromValue = value?.find(
              (item) => Number(item?.orderId) === Number(key)
            );
            const { shippingFeeChange } = shippingFeeInfo || {};
            const { shippingFee, initialSubTotalAmt } =
              shippingFeeDataFromValue || {};
            const { restockingFee, restockingRate } = restockingFeeInfo || {};
            const { handlingFee } = handlingFeeInfo || {};
            const availableShippingCredit =
              Number(totalReturnShippingFee) -
              Number(totalReturnShippingCredit);

            const totalItemCreditNewReturn = value
              ?.map((item) => {
                return item.unitPrice / (item.priceMU * 1.0) * Number(item.returnQty) * item.itemMU
              })
              .reduce((total, obj) => Number(obj) + Number(total), 0);

            const totalItemCreditNewReplace = value
              ?.map((item) => {
                return item.unitPrice / (item.priceMU * 1.0) * Number(item.replaceQty) * item.itemMU
              })
              .reduce((total, obj) => Number(obj) + Number(total), 0);

            const tax = value[0]?.taxRate || 0;
            const totalItemCreditNew = Number(roundToTwoDecimalPlaces(totalItemCreditNewReturn)) + Number(roundToTwoDecimalPlaces(totalItemCreditNewReplace));
            const totalTaxCredit = Number(totalItemCreditNew) * Number(tax);

            const totalRefundNew =
              Number((Number(roundToTwoDecimalPlaces(totalItemCreditNew)) + Number((roundToTwoDecimalPlaces(totalTaxCredit))) +
                Number(roundToTwoDecimalPlaces(shippingFeeChange ?? 0)))) -
              (Number(restockingFee ?? 0) + Number(handlingFee ?? 0));

            const handleRestockingFee = () => {
              setModal({
                isOpen: true,
                modalTitle: "Change Restocking Fee",
              });
              setRestockingTotalInfo(totalForRestocking);
              setFeeIndex(key);
              setPassRestockingData(restockingFee);
              setPassRestockingRateData(restockingRate);
              setItemCredit(totalItemCreditNew);
            }

            const handleHandlingFee = () => {
              setPassHandlingData(handlingFee);
              setFeeIndex(key);
              setModal({
                isOpen: true,
                modalTitle: "Change Handling Fee",
              });
              setItemCredit(totalItemCreditNew);
            }

            const handleRefundMethodChange = () => {
              if (refundMethodCheckedArray?.length) {
                const index = refundMethodCheckedArray?.findIndex(item => item.orderId === Number(key));
                if (index !== -1) {
                  const updatedRefundMethodCheckData = [...refundMethodCheckedArray];
                  updatedRefundMethodCheckData[index] = { ...updatedRefundMethodCheckData[index], toggle: !updatedRefundMethodCheckData[index].toggle };
                  dispatch(setRefundMethodCheckData(updatedRefundMethodCheckData));
                } else {
                  // If orderId doesn't exist, push a new object
                  dispatch(setRefundMethodCheckData([...refundMethodCheckedArray, { orderId: Number(key), toggle: true }]));
                }
              }
              else {
                dispatch(setRefundMethodCheckData([{ orderId: Number(key), toggle: true }]));
              }
            }

            return (
              <section
                className="return-product-cart-list-main-section"
                key={key}
              >
                <div className="return-product-order-list-main">
                  <div className="order-list-header">
                    <div className="order-list-name">
                      <span className="order-title">Order #</span>
                      <span className="order-product-id">{key}</span>
                    </div>
                    <div className="d-flex order-first-sec">
                      <div className="d-flex w-100 order-first-left-sec">
                        <div className="order-list-title-fee-div">
                          <div className="order-list-title-item-credit-div">
                            <div
                              className={`shipping-cart-item-credit-div ${!totalItemCreditNew || !totalReturnQuantity
                                ? "is-disabled"
                                : ""
                                }`}
                            >
                              <span className="title">ITEM CREDIT</span>
                              <span className="order-product-id data-with-currancy">
                                <span >$&nbsp;</span>
                                {!totalItemCreditNew || !totalReturnQuantity
                                  ? "0.00"
                                  : totalItemCreditNew
                                    ? `${Number(roundToTwoDecimalPlaces(totalItemCreditNew))}`
                                    : "0.00"}
                              </span>
                            </div>
                          </div>
                          <div className="order-list-title-item-credit-div">
                            <div
                              className={`shipping-cart-item-credit-div`}
                            >
                              <span className="title">TAX CREDIT</span>
                              <span className="order-product-id data-with-currancy">
                                <span >$&nbsp;</span>
                                {totalTaxCredit
                                  ? `${Number(roundToTwoDecimalPlaces(totalTaxCredit))}`
                                  : "0.00"}
                              </span>
                            </div>
                          </div>

                          <div
                            className={`shipping-cart-div ${!shippingFee ||
                              !availableShippingCredit ||
                              !totalReturnQuantity
                              ? "is-disabled"
                              : ""
                              }`}
                            onClick={() => {
                              if (
                                shippingFee &&
                                availableShippingCredit &&
                                totalReturnQuantity
                              ) {
                                setModal({
                                  isOpen: true,
                                  modalTitle: "Change Shipping Credit",
                                });
                                setShippingReturnedValue(totalReturnQuantity);
                                setShippingFeeValue(totalReturnShippingFee);
                                setShippingCreditValue(
                                  totalReturnShippingCredit
                                );
                                setShippingPriceTotalValue(totalPriceValue);
                                setFeeIndex(key);
                                setShippingTotalFeeValue(shippingFee);
                                setPassShippingData(
                                  Number(
                                    shippingFeeInfo
                                      ? Number(roundToTwoDecimalPlaces(shippingFeeChange))
                                      : 0
                                  )
                                );
                                setRestockingTotalInfo(totalForRestocking);
                                setInitialSubTotalAmount(initialSubTotalAmt);
                              }
                            }}
                            aria-disabled={
                              !shippingFee ||
                              !availableShippingCredit ||
                              !totalReturnQuantity
                            }
                          >
                            <span className="title">SHIPPING CREDIT</span>
                            <span className="order-product-id data-with-currancy">
                              <span >$&nbsp;</span>
                              {!availableShippingCredit || !totalReturnQuantity
                                ? "0.00"
                                : shippingFeeInfo
                                  ? `${Number(roundToTwoDecimalPlaces(shippingFeeChange))}`
                                  : "0.00"}
                            </span>
                          </div>

                          <div
                            className='shipping-cart-div'
                            onClick={handleRestockingFee}
                          >
                            <span className="title">RESTOCKING FEE</span>
                            <span className="order-product-id data-with-currancy">
                              <span >$&nbsp;</span>
                              {!totalReturnQuantity
                                ? "0.00"
                                : restockingFeeInfo
                                  ? `${Number(roundToTwoDecimalPlaces(restockingFee))}`
                                  : "0.00"}
                            </span>
                          </div>
                          <div
                            className='shipping-cart-div'
                            onClick={handleHandlingFee}
                          >
                            <span className="title">HANDLING FEE</span>
                            <span className="order-product-id data-with-currancy">
                              <span >$&nbsp;</span>
                              {!totalReturnQuantity
                                ? "0.00"
                                : handlingFeeInfo
                                  ? `${Number(roundToTwoDecimalPlaces(handlingFee))}`
                                  : "0.00"}
                            </span>
                          </div>
                          <button className="shipping-cart-div order-list-paid-sec refund-via-sec" onClick={handleRefundMethodChange} disabled={paidViaPaylater || paidViaCustomerCredit || isPosGenericCustomer}>
                            {
                              refundMethodCheckDetail?.toggle ? <span className="title">{`REFUND IN STORE CREDIT`}</span> :
                                <>
                                  {
                                    paidViaPaylater ? <span className="title">{`REFUND TO ACCOUNT`}</span> : <span className="title">{`REFUND VIA ${PaymentTypeEnum[value[0]?.paidVia]
                                      }`}</span>
                                  }
                                </>

                            }
                            <span className="order-product-id data-with-currancy">
                              {refundMethodCheckDetail?.toggle ? <span><img src={storeCreditIcon} alt=""></img>&nbsp;</span> :
                                <>
                                  {
                                    paidViaPaylater ? <span><img src={payLaterIcon} alt=""></img>&nbsp;</span> :
                                      <>{
                                        value[0]?.paidVia === PaymentTypeEnum.CHECK ? <span><img src={checkIcon} alt=""></img>&nbsp;</span> : value[0]?.paidVia === PaymentTypeEnum.CASH || value[0]?.paidVia === PaymentMode.cardPayment ? <span><img src={value[0]?.paidVia === PaymentTypeEnum.CASH ? cashIcon : cardIcon} alt=""></img>&nbsp;</span> : null
                                      }</>
                                  }
                                </>}

                              <span>$&nbsp;</span>
                              {roundToTwoDecimalPlaces(totalRefundNew)}
                            </span>
                          </button>
                        </div>
                      </div>
                      <Tooltip title="Remove" placement="top">
                        <div
                          className="order-delete-section"
                          onClick={() => {
                            setOrderArrayValue(value);
                            setRemoveOrder(true);
                            setOrderArrayId(key);
                          }}
                        >
                          <PosImage
                            src={images.deleteDarkGrayIcon}
                            alt="delete-icon"
                            className="delet-order-img"
                          />
                          <PosImage
                            src={images.deleteHoverDarkGrayIcon}
                            alt="delete-icon"
                            className="delet-order-hover-img"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="returns-order-list-table-main-div">
                    <Box
                      sx={{ flexGrow: 1 }}
                      className="return-cart-table-main-header"
                    >
                      <Grid
                        container
                        className="cart-data-header-sec cart-only-title-header"
                      >
                        <Grid item xs={1}>
                          <div className="cart-product-data-header">
                            <span className="title">LINE #</span>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div className="cart-product-data-header cart-product-first-data-header">
                            <span className="title">ITEM</span>
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <div className="cart-product-data-header">
                            <span className="title">CUSTOMER</span>
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <div className="cart-product-data-header">
                            <span className="title">ORDER DATE</span>
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <div className="cart-product-data-header">
                            <span className="title">INVOICE #</span>
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <div className="cart-product-data-header">
                            <span className="title">EXT. PRICE</span>
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <div className="cart-product-data-header">
                            <span className="title">RETURNABLE QTY.</span>
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <div className="cart-product-data-header">
                            <span className="title">CREDIT QTY.</span>
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <div className="cart-product-data-header">
                            <span className="title">REPLACE QTY.</span>
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <div className="cart-product-data-header">
                            <span className="title">OVER SHIPPED QTY.</span>
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <div className="cart-product-data-header">
                            <span className="title">ADDITIONAL QTY.</span>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                    {/* </div> */}
                    <Box sx={{ flexGrow: 1 }} className="return-cart-data-info">
                      {value?.map((item) => {
                        const {
                          lineId,
                          productName,
                          manufacturerCode,
                          customerId,
                          invoiceId,
                          extendedPrice,
                          returnableQty,
                          returnQty,
                          replaceQty,
                          orderDate,
                          additionalQty,
                          posCustCode,
                          companyName,
                          overShipmentQty,
                        } = item;

                        return (
                          <>
                            <Grid
                              container
                              onClick={() => handleEditReturnSelect(item)}
                              className="return-cart-data-sec"
                            >
                              <Grid item xs={1}>
                                <div className="cart-product-info">
                                  {lineId}
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <div className="return-cart-product-name-sec">
                                  <div className="return-cart-product-image">
                                    <PosImage
                                      src={images.defaultIcon}
                                      alt="product-icon"
                                    />
                                  </div>
                                  <div className="return-cart-product-name return-cart-product">
                                    <span className="product-name cart-product-info">
                                      {productName}
                                    </span>
                                    <span className="manufacture-code cart-product-info">
                                      {manufacturerCode}
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs={1}>
                                <div className="return-cart-customer-name">
                                  <span className="customer-name cart-product-info">
                                    {companyName}
                                  </span>
                                  <span className="customer-code cart-product-info">
                                    {posCustCode}
                                  </span>
                                </div>
                              </Grid>
                              <Grid item xs={1}>
                                <div className="cart-product-info">
                                  {isoToFormat(`${orderDate}`, MM_DD_YYYY)}
                                </div>
                              </Grid>
                              <Grid item xs={1}>
                                <div className="cart-product-info">
                                  {invoiceId}
                                </div>
                              </Grid>
                              <Grid item xs={1}>
                                <div className="cart-product-info">
                                  ${roundToTwoDecimalPlaces(extendedPrice)}
                                </div>
                              </Grid>
                              <Grid item xs={1}>
                                <div className="cart-product-info">
                                  {returnableQty}
                                </div>
                              </Grid>
                              <Grid item xs={1}>
                                <div className="cart-product-info">
                                  {returnQty || 0}
                                </div>
                              </Grid>
                              <Grid item xs={1}>
                                <div className="cart-product-info">
                                  {replaceQty || 0}
                                </div>
                              </Grid>
                              <Grid item xs={1}>
                                <div className="cart-product-info">
                                  {overShipmentQty || 0}
                                </div>
                              </Grid>
                              <Grid item xs={1}>
                                <div className="cart-product-info">
                                  {additionalQty || 0}
                                </div>
                              </Grid>
                            </Grid>
                            {/* start */}
                            <div className="return-cart-responsive-table-sec">
                              <Grid
                                container
                                className="return-cart-responsive-table"
                              >
                                <Grid item xs={12} sm={12} md={6} lg={3}>
                                  <div className="product-multiple-info-sec">
                                    <div className="responsive-sec-title">
                                      <span className="title">ITEM:</span>
                                    </div>
                                    <div className="product-multiple-info">
                                      <span className="product-info-data product-name">
                                        {productName}
                                      </span>
                                      <span className="product-info-data manufacturer-code-color">
                                        {manufacturerCode}
                                      </span>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3}>
                                  <div className="product-multiple-info-sec">
                                    <div className="responsive-sec-title">
                                      <span className="title">CUSTOMER:</span>
                                    </div>
                                    <div className="product-multiple-info">
                                      <span className="product-info-data customer-name">
                                        {renamePersonData(posCustCode, companyName)}
                                      </span>
                                      <span className="product-info-data manufacturer-code-color">
                                        {customerId}
                                      </span>
                                    </div>
                                  </div>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                  <div className="return-cart-responsive-data-div">
                                    <div className="responsive-sec-title">
                                      <span className="title">LINE #:</span>
                                    </div>
                                    <div className="responsive-sec-data">
                                      <span className="product-info-data">
                                        {lineId}
                                      </span>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                  <div className="return-cart-responsive-data-div">
                                    <div className="responsive-sec-title">
                                      <span className="title">ORDER DATE:</span>
                                    </div>

                                    <div className="responsive-sec-data">
                                      <span className="product-info-data">
                                        {isoToFormat(
                                          `${orderDate}`,
                                          MM_DD_YYYY
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                  <div className="return-cart-responsive-data-div">
                                    <div className="responsive-sec-title">
                                      <span className="title">INVOICE #:</span>
                                    </div>
                                    <div className="responsive-sec-data">
                                      <span className="product-info-data">
                                        {invoiceId}
                                      </span>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                  <div className="return-cart-responsive-data-div">
                                    <div className="responsive-sec-title">
                                      <span className="title">EXT. PRICE:</span>
                                    </div>
                                    <div className="responsive-sec-data">
                                      <span className="product-info-data">
                                        ${roundToTwoDecimalPlaces(extendedPrice)}
                                      </span>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                  <div className="return-cart-responsive-data-div">
                                    <div className="responsive-sec-title">
                                      <span className="title">
                                        RETURNABLE QTY.:
                                      </span>
                                    </div>
                                    <div className="responsive-sec-data">
                                      <span className="product-info-data">
                                        {returnableQty}
                                      </span>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                  <div className="return-cart-responsive-data-div">
                                    <div className="responsive-sec-title">
                                      <span className="title">
                                        RETURN QTY.:
                                      </span>
                                    </div>
                                    <div className="responsive-sec-data">
                                      <span className="product-info-data">
                                        {returnQty || 0}
                                      </span>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                  <div className="return-cart-responsive-data-div">
                                    <div className="responsive-sec-title">
                                      <span className="title">
                                        REPLACE QTY.:
                                      </span>
                                    </div>
                                    <div className="responsive-sec-data">
                                      <span className="product-info-data">
                                        {replaceQty || 0}
                                      </span>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                  <div className="return-cart-responsive-data-div">
                                    <div className="responsive-sec-title">
                                      <span className="title">
                                        ADDITIONAL QTY.:
                                      </span>
                                    </div>
                                    <div className="responsive-sec-data">
                                      <span className="product-info-data">
                                        {additionalQty || 0}
                                      </span>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            {/* end */}
                          </>
                        );
                      })}
                    </Box>
                  </div>
                </div>
              </section>
            );
          })}
        </section>
        <section className="set-replacement-info-main-div sr-fixed-footer">
          <Container className="new-container return-cart-product-container p-0">
            <div className="d-flex justify-content-between total-refund-main-div">
              <div className="order-list-title-item-credit-div">
                <div className={`shipping-cart-item-credit-div`}>
                  <span className="total-refund-cash-section-title">{`TOTAL REFUND VIA CASH`}</span>
                  <span className="total-refund-cash-section-amount data-with-currancy">
                    <span><img src={cashIcon} alt=""></img>&nbsp;</span>
                    <span >$&nbsp;</span>
                    {Number(roundToTwoDecimalPlaces(totalRefundViaCashNew)) ?? "0.00"}
                  </span>
                </div>
              </div>

              <div>
                <div className="complete-return-block">
                  <div className="tax-message">
                    <span className="font-weight-400">{taxApplicable}</span>
                    <span className="font-weight-400">{taxReturns}</span>
                  </div>
                  <PosButton
                    variant="contained"
                    color="primary"
                    className={`complete-return-btn return-set-picking-btn ${isReturnProductsOrdersLoading
                      ? "button-accent button-is-loading-black"
                      : ""
                      }`}
                    onClick={completeReturn}
                    disabled={isReturnProductsOrdersLoading}
                  >
                    <span>
                      {/* need to verify */}
                      {!isReturnProductsOrdersLoading
                        ? "Complete Return"
                        : null}
                    </span>
                  </PosButton>
                </div>

              </div>
            </div>
          </Container>
        </section>
        {returnProductDataModal ? <SearchProductModel
          open={returnProductDataModal}
          onClose={handleCloseSearchProductModel}
          setSelectProduct={returnSearchedProductDataAction}
          selectCustomer={returnSearchedCustomerData}
          invoiceNumber={returnSearchedInvoiceData}
        /> : null}
        {scmodel ? <SearchCustomerModel
          productInformation={returnSearchedProductData}
          open={scmodel}
          onClose={handleCloseSearchCustomerModel}
        /> : null}
        <LeaveReturnCartModal
          leaveCartModalOpen={leavemodel}
          setLeaveCartModalOpen={setLeavemodel}
          handleLeaveCartModal={handleClearCart}
        />

        <RemoveReturnCartOrder
          removeOrder={removeOrder}
          setRemoveOrder={setRemoveOrder}
          handleRemoveOrder={handleRemoveOrder}
          orderArrayValue={orderArrayValue}
          orderArrayId={orderArrayId}
        />

        <PosModel
          open={modal.isOpen}
          onClose={() =>
            setModal({ isOpen: false, modalTitle: modal.modalTitle })
          }
          dialogTitle
          title={modal.modalTitle}
          dialogClassName="order-details-modal-container modal-btn-content-width common-modal-btn-style"
        >
          {orderDetailsModalOpen()}
        </PosModel>

        <ReturnCartNewModal
          editWithCartPage
          removeReturnedProduct={removeReturnedProduct}
          open={cartEditModel}
          cartEditModalData
          onClose={() => handleCloseReturnProductEditModel()}
          selectRowReturnData={selectRowReturnData}
          selectProduct={productMainInformation}
        />
      </Container>
    </>
  );
};

const SelectTakerSRModal = ({
  selectTakerDrawer,
  setSelectTakerDrawer,
  setIsConditionTrue,
  isConditionTrue
}) => {
  const handleCloseDrawer = () => {
    setSelectTakerDrawer(false);
    setIsConditionTrue(true);
  };
  return (
    <Drawer
      anchor="right"
      open={selectTakerDrawer}
      className="sr-select-taker-drawer default-drawer-style no-cart-drawer main-drawer-classes drawer-scrollbar-style"
    >
      <Box sx={{ width: 600 }} role="presentation">
        <SelectTakerSRDrawer
          selectTakerDrawer={selectTakerDrawer}
          setSelectTakerDrawer={setSelectTakerDrawer}
          onCloseDrawer={handleCloseDrawer}
          setIsConditionTrue={setIsConditionTrue}
          isConditionTrue={isConditionTrue}
        />
      </Box>
    </Drawer>
  );
};

export default ReturnProductCart;
