import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { setPlaceOrderFlag } from "apps/springboard/QuoteItems/request";
import { QueryKeys } from "./queryKeys";

export const usePlaceOrder = () => {
  const [id, setId] = useState();

  const { data } = useQuery([QueryKeys.get_place_order_flag, id], async () => {
    if (id) {
      return setPlaceOrderFlag(id);
    }
  });

  const setPlaceOrderId = useCallback(
    (data) => {
      if (data) {
        setId(data);
      }
    },
    [id]
  );
  return { setPlaceOrderId, placeOrderFlag: data?.data };
};
