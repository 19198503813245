import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";

import { removeEstimator } from "../quoteDetail.service";

export const useRemoveEstimator = () => {
  const { mutate, isLoading, data, error, isSuccess } = useMutation(
    async (payload: any) => {
      return removeEstimator(payload?.id, payload?.handlerID);
    },
    );

  const removeEstimatorFn = useCallback(
    (payload) => {
      mutate(payload);
    },
    [mutate]
  );

  return {
    removeEstimatorFn,
    isLoading,
    data,
    error,
    isSuccess,
  };
};
