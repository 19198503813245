import { useCallback, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { customerEmailCall } from "apps/springboard/QuoteMessages/request";

export const useGetCustomerEmail = () => {
  const [id, setId] = useState();
  const { data } = useQuery(["get-customer-email", id], async () => {
    if (id) {
      return customerEmailCall(id);
    }
  });

  const setCustomerEmailId = useCallback((data) => {
    setId(data);
  }, []);

  return { toSendEmail: data?.data ?? [], setCustomerEmailId };
};
