export const GET_REPS_START = "GET_REPS_START";
export const GET_REPS_SUCCESS = "GET_REPS_SUCCESS";
export const GET_REPS_ERROR = "GET_REPS_ERROR";

export const GET_MESSAGES_START = "GET_MESSAGES_START";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_ERROR = "GET_MESSAGES_ERROR";

export const SET_QUOTE_DETAIL = "SET_QUOTE_DETAIL";
export const SELECTED_QUOTE_ID = "SELECTED_QUOTE_ID";
export const SET_SUB_QUOTE_LIST = "SET_SUB_QUOTE_LIST";
export const SET_PARENT_QUOTE = "SET_PARENT_QUOTE";
export const SET_DOCUMENT_TYPE = "SET_DOCUMENT_TYPE";
export const SET_LIKELYHOOD_COMMENT = "SET_LIKELYHOOD_COMMENT";
export const SET_SALESPERSON_DATA = "SET_SALESPERSON_DATA";
export const SET_PACKAGE_INFO = "SET_PACKAGE_INFO";
export const SET_QUOTE_ITEMS = "SET_QUOTE_ITEMS";
export const SET_QUOTE_DOCS = "SET_QUOTE_DOCS";
export const SET_QUOTE_COUNTS = "SET_QUOTE_COUNTS";
export const SET_DOC_UPLOAD_MODAL = "SET_DOC_UPLOAD_MODAL";
export const CLEAR_ALERT_DATA = "CLEAR_ALERT_DATA";

export const ADD_MESSAGE_START = "ADD_MESSAGE_START";
export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS";
export const ADD_MESSAGE_ERROR = "ADD_MESSAGE_ERROR";

export const GET_ESTIMATOR_NOTES_START = "GET_ESTIMATOR_NOTES_START";
export const GET_ESTIMATOR_NOTES_SUCCESS = "GET_ESTIMATOR_NOTES_SUCCESS";
export const GET_ESTIMATOR_NOTES_ERROR = "GET_ESTIMATOR_NOTES_ERROR";
export const GET_ESTIMATOR_NOTES_RESET = "GET_ESTIMATOR_NOTES_RESET";

export const GET_ITEM_ACTION_NOTES_START = "GET_ITEM_ACTION_NOTES_START";
export const GET_ITEM_ACTION_NOTES_SUCCESS = "GET_ITEM_ACTION_NOTES_SUCCESS";
export const GET_ITEM_ACTION_NOTES_ERROR = "GET_ITEM_ACTION_NOTES_ERROR";
export const GET_ITEM_ACTION_NOTES_RESET = "GET_ITEM_ACTION_NOTES_RESET";

export const GET_NOTES_START = "GET_NOTES_START";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const GET_NOTES_ERROR = "GET_NOTES_ERROR";
export const GET_NOTES_RESET = "GET_NOTES_RESET";

export const ADD_NOTE_START = "ADD_NOTE_START";
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";
export const ADD_NOTE_ERROR = "ADD_NOTE_ERROR";

export const UPDATE_JOB_HIGHLIGHT_NOTE_START =
  "UPDATE_JOB_HIGHLIGHT_NOTE_START";
export const UPDATE_JOB_HIGHLIGHT_NOTE_SUCCESS =
  "UPDATE_JOB_HIGHLIGHT_NOTE_SUCCESS";
export const UPDATE_JOB_HIGHLIGHT_NOTE_ERROR =
  "UPDATE_JOB_HIGHLIGHT_NOTE_ERROR";
export const UPDATE_ESTIMATOR_NOTE_START = "UPDATE_ESTIMATOR_NOTE_START";
export const UPDATE_ESTIMATOR_NOTE_SUCCESS = "UPDATE_ESTIMATOR_NOTE_SUCCESS";
export const UPDATE_ESTIMATOR_NOTE_ERROR = "UPDATE_ESTIMATOR_NOTE_ERROR";
export const UPDATE_ITEMS_ACTION_NOTE_START = "UPDATE_ITEMS_ACTION_NOTE_START";
export const UPDATE_ITEMS_ACTION_NOTE_SUCCESS =
  "UPDATE_ITEMS_ACTION_NOTE_SUCCESS";
export const UPDATE_ITEMS_ACTION_NOTE_ERROR = "UPDATE_ITEMS_ACTION_NOTE_ERROR";
export const UPDATE_MAJOR_REPS_START = "UPDATE_MAJOR_REPS_START";
export const UPDATE_MAJOR_REPS_SUCCESS = "UPDATE_MAJOR_REPS_SUCCESS";
export const UPDATE_MAJOR_REPS_ERROR = "UPDATE_MAJOR_REPS_ERROR";

export const GET_JOB_HIGHLIGHTS_START = "GET_JOB_HIGHLIGHTS_START";
export const GET_JOB_HIGHLIGHTS_SUCCESS = "GET_JOB_HIGHLIGHTS_SUCCESS";
export const GET_JOB_HIGHLIGHTS_ERROR = "GET_JOB_HIGHLIGHTS_ERROR";
export const GET_JOB_HIGHLIGHTS_RESET = "GET_JOB_HIGHLIGHTS_RESET";

export const GET_MAJOR_REPS_START = "GET_MAJOR_REPS_START";
export const GET_MAJOR_REPS_SUCCESS = "GET_MAJOR_REPS_SUCCESS";
export const GET_MAJOR_REPS_ERROR = "GET_MAJOR_REPS_ERROR";
export const GET_MAJOR_REPS_RESET = "GET_MAJOR_REPS_RESET";

export const GET_QUOTES_END_RECORD_START = "GET_QUOTES_END_RECORD_START";
export const GET_QUOTES_END_RECORD_SUCCESS = "GET_QUOTES_END_RECORD_SUCCESS";
export const GET_QUOTES_END_RECORD_ERROR = "GET_QUOTES_END_RECORD_ERROR";

export const ADD_DOCUMENT_START = "ADD_DOCUMENT_START";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_ERROR = "ADD_DOCUMENT_ERROR";

export const GET_VERSION_HISTORY_START = "GET_VERSION_HISTORY_START";
export const GET_VERSION_HISTORY_SUCCESS = "GET_VERSION_HISTORY_SUCCESS";
export const GET_VERSION_HISTORY_ERROR = "GET_VERSION_HISTORY_ERROR";

export const GET_RFI_START = "GET_RFI_START";
export const GET_RFI_SUCCESS = "GET_RFI_SUCCESS";
export const GET_RFI_ERROR = "GET_RFI_ERROR";

export const GET_FOLLOW_UP_START = "GET_FOLLOW_UP_START";
export const GET_FOLLOW_UP_SUCCESS = "GET_FOLLOW_UP_SUCCESS";
export const GET_FOLLOW_UP_ERROR = "GET_FOLLOW_UP_ERROR";
export const GET_FOLLOW_UP_RESET = "GET_FOLLOW_UP_RESET";

export const GET_ORDERS_DETAILS_START = "GET_ORDERS_DETAILS_START";
export const GET_ORDERS_DETAILS_SUCCESS = "GET_ORDERS_DETAILS_SUCCESS";
export const GET_ORDERS_DETAILS_ERROR = "GET_ORDERS_DETAILS_ERROR";

export const RESET_ALL_NOTES_REPS = "RESET_ALL_NOTES_REPS"