import { useState, useRef } from 'react';
import './ProductImage.scss';

const ProductImage = ({ src = '', alt = '', className = '' }) => {
    const errorRef = useRef(false);
    const [error, setError] = useState(!src ?? false);
    const LOADED_CLASSNAME = 'product-image-loaded';

    const handeLoad = e => {
        e.target.classList.add(LOADED_CLASSNAME);
    }

    const handleError = () => {
        if (errorRef.current) return;
        setError(true);
        errorRef.current = true;
    }

    return (
        <div className={`product-image-sb ${className}`}>
            {error ? (
                <PlaceholderProductImage className={error ? LOADED_CLASSNAME : ''} />
            ) : (
                <img src={src} alt={alt} onLoad={handeLoad} onError={handleError} loading='lazy' />
            )}
        </div>
    )
}

export default ProductImage;

const PlaceholderProductImage = ({ size = 40, className = '' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width={size} height={size} viewBox="0 0 40 40">
            <g transform="translate(-248 -2244)"><rect width="40" height="40" transform="translate(248 2244)" fill="#fff" /><g transform="translate(256 2252)"><path d="M0,0H24V24H0Z" fill="none" /><path d="M11.15,3.4,7.43,9.48A1,1,0,0,0,8.28,11h7.43a1,1,0,0,0,.85-1.52L12.85,3.4a.993.993,0,0,0-1.7,0Z" fill="#ccc" /><circle cx="4.5" cy="4.5" r="4.5" transform="translate(13 13)" fill="#ccc" /><path d="M4,21.5h6a1,1,0,0,0,1-1v-6a1,1,0,0,0-1-1H4a1,1,0,0,0-1,1v6A1,1,0,0,0,4,21.5Z" fill="#ccc" /></g></g>
        </svg>
    )
}