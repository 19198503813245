import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IProductsSlice } from "../types";



const initialState: IProductsSlice = {
  productSearchLoader: false,
  productsData: null,
  productsSearchedData: null,
  productSearchError: null,
  isProductCategoriesSearch: false,
  getProductCategoriesData: null,
  getProductCategoriesError: null,
  isProductSubCategoriesSearch: false,
  getProductSubCategoriesData: null,
  getProductSubCategoriesError: null,
  productLoader: false,
  productSkeleton: false
};

export const productsSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    searchProductsStart: (state, action: PayloadAction<boolean>) => {
      state.productsData = null;
      state.productSearchLoader = true;
      state.productLoader = action.payload;
      state.productSkeleton = action.payload;
    },
    searchProductsSuccess: (state, action: PayloadAction<any>) => { // Will remove 'any' once we get proper data from BE
      state.productsSearchedData = {
        count: action.payload.count,
        isPaginated: action.payload.isPaginated,
        message: action.payload.message,
        page: action.payload.page,
        size: action.payload.size,
        success: action.payload.success,
        total: action.payload.total,
        data: action.payload.page === 1 ? action.payload.data : [...state.productsSearchedData.data, ...action.payload.data]
      };
      state.productSearchLoader = false;
      state.productLoader = false;
      state.productSkeleton = false;
    },
    searchProductsError: (state, action: PayloadAction<any>) => { // Will remove 'any' once we get proper data from BE
      state.productSearchError = action.payload;
      state.productsSearchedData = null;
      state.productSearchLoader = false;
      state.productLoader = false;
      state.productSkeleton = false;
    },
    getAllProductsStart: (state, action: PayloadAction<boolean>) => {
      state.productsSearchedData = null;
      state.productSearchLoader = true;
      state.productSkeleton = action.payload;
    },
    getAllProductsSuccess: (state, action: PayloadAction<any>) => { // Will remove 'any' once we get proper data from BE
      state.productsData = {
        count: action.payload.count,
        isPaginated: action.payload.isPaginated,
        message: action.payload.message,
        page: action.payload.page,
        size: action.payload.size,
        success: action.payload.success,
        total: action.payload.total,
        data: action.payload.page === 1 ? action.payload.data : [...state.productsData.data, ...action.payload.data]
      };
      state.productSearchLoader = false;
      state.productSkeleton = false;
    },
    getAllProductsError: (state, action: PayloadAction<any>) => { // Will remove 'any' once we get proper data from BE
      state.productSearchError = action.payload;
      state.productsData = null;
      state.productSearchLoader = false;
      state.productSkeleton = false;
    },
    searchProductsClear: (state) => {
      state.productsData = null;
      state.productsSearchedData = null;
      state.productSearchLoader = false;
      state.productSearchError = null;
      state.productLoader = false;
      state.productSkeleton = false;
    },
    getProductCategoriesDataStart: (state) => {
      state.getProductCategoriesData = null;
      state.isProductCategoriesSearch = true;
    },
    getProductCategoriesDataSuccess: (state, action: PayloadAction<any>) => { // Will remove 'any' once we get proper data from BE
      state.getProductCategoriesData = action.payload;
      state.isProductCategoriesSearch = false;
    },
    getProductCategoriesDataError: (state, action: PayloadAction<any>) => { // Will remove 'any' once we get proper data from BE
      state.getProductCategoriesError = action.payload;
      state.getProductCategoriesData = null;
      state.isProductCategoriesSearch = false;
    },
    getProductSubCategoriesDataStart: (state) => {
      state.getProductSubCategoriesData = null;
      state.isProductSubCategoriesSearch = true;
    },
    getProductSubCategoriesDataSuccess: (state, action: PayloadAction<any>) => { // Will remove 'any' once we get proper data from BE
      state.getProductSubCategoriesData = action.payload;
      state.isProductSubCategoriesSearch = false;
    },
    getProductSubCategoriesDataError: (state, action: PayloadAction<any>) => { // Will remove 'any' once we get proper data from BE
      state.getProductSubCategoriesError = action.payload;
      state.getProductSubCategoriesData = null;
      state.isProductSubCategoriesSearch = false;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  searchProductsClear,
  searchProductsError,
  searchProductsStart,
  searchProductsSuccess,
  getAllProductsSuccess,
  getAllProductsError,
  getAllProductsStart,
  getProductCategoriesDataStart,
  getProductCategoriesDataSuccess,
  getProductCategoriesDataError,
  getProductSubCategoriesDataStart,
  getProductSubCategoriesDataSuccess,
  getProductSubCategoriesDataError
} = productsSlice.actions;

export default productsSlice.reducer;
