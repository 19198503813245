import { useRef, useEffect } from "react"
import { motion, useMotionTemplate, useScroll, useSpring, useTransform } from 'framer-motion/dist/framer-motion';

import { PosImage } from "apps/pos/Common/uiCore";
import IconButton from 'apps/product/Modals/Elements/IconButton'
import AutoComplete from "../AutoComplete";

import images from "constants/images";
import { accessoriesKey } from "constants/enum";
import { setFocusTo } from "utils/utils"
import { retailPriceLabel, labels } from "../../modules/productDetailsContainer/constants";

import "../ProductDetailsCharacteristicsModals/ProductDetailsCharacteristicsModals.scss";
import "./ProductDetailsAccessoriesModals.scss";
import "../ProductDetailsCommonModal.scss";

const ProductDetailsAccessoriesModals = ({
    open,
    onClose,
    productAccessorieReplacesData,
    productAccessorieGoesWithData,
    removeAllAccessories,
    AccessoriesRemoveById,
    addNewAccessories,
    focusText
}) => {

    const { goesWith, replaces } = accessoriesKey;
    const { defaultIcon , deleteDarkGrayIcon , deleteHoverDarkGrayIcon} = images;
    const { Accessories, replaces: Replaces, goesWith: Goeswith } = labels;

    const handleClose = () => {
        onClose();
    }

    const ref = useRef()

    useEffect(() => {
        if (open) {
            if (ref.current.open) return
            ref.current?.showModal()
        }
        else {
            ref.current?.close()
        }
    }, [open])

    useEffect(() => {
        ref.current?.addEventListener('close', onClose)

        return () => {
            ref.current?.removeEventListener('close', onClose)
        }
    }, [])

    useEffect(() => {
        if (!focusText) return
        const element = document.getElementById(focusText)
        setFocusTo(element)
    }, [focusText])

    const { scrollY } = useScroll({ container: ref })
    const yInitial = useTransform(scrollY, [200, 250], [-20, 0])
    const opacity = useTransform(scrollY, [200, 250], [0, 1])
    const borderColor = useTransform(scrollY, [0, 50], ['#fff', '#ddd'])
    const physics = {
        stiffness: 1000,
        damping: 100,
    }
    const y = useSpring(yInitial, physics)

    const scrollToTop = () => {
        ref.current?.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <>
            <motion.div
                className="backdrop"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
            </motion.div>
            <motion.dialog
                className='notes-dialog-costs-price'
                ref={ref}
            >
                <motion.header
                    className="dialog-header"
                    style={{ borderBottom: useMotionTemplate`1px solid ${borderColor}` }}
                >
                    <h3>{Accessories}</h3>
                    <IconButton
                        classes='close-btn'
                        secondary={true}
                        title='Close'
                        icon='close'
                        onClick={handleClose}
                    />

                    <motion.div
                        className='dialog-back-to-top'
                        style={{ opacity, y }}
                    >
                        <IconButton
                            classes='dialog-back-to-top-btn'
                            title='Back to top'
                            icon='expand_less'
                            tabIndex={-1}
                            onClick={scrollToTop}
                        />
                    </motion.div>
                </motion.header>

                <div className="dialog-body">
                    <div className="notes-added-user-name" id={goesWith} >
                        <span>
                            {Goeswith}
                        </span>
                    </div>
                    <AutoComplete relationType={1} addNewAccessories={addNewAccessories} placeHolder={retailPriceLabel.accessoryPlaceholder} />
                    {
                        productAccessorieGoesWithData?.length ?
                            <>
                                <div className='notes-manager-controls'>
                                    <p className='notes-manager-count'>
                                        {productAccessorieGoesWithData?.length || 0} OF 12 ADDED
                                    </p>
                                    <button className='notes-manager-btn' onClick={removeAllAccessories(goesWith)}>Remove all</button>
                                </div>

                                {productAccessorieGoesWithData?.map((option) => {
                                    const { productName, thumbImg, manufacturerCode, productToProductID } = option || {}
                                    return (
                                        <div className="d-flex accessories-main-info-sec product-detail-info-sec-border align-items-center" >
                                            <div className="d-flex accessories-left-info">
                                                <div className="accessories-icon">
                                                    <PosImage
                                                        src={thumbImg ? thumbImg : defaultIcon}
                                                        className="search-list-img"
                                                        alt="product"
                                                    />
                                                </div>
                                                <div className="accessories-data">
                                                    <div className="accessories-name">
                                                        <span>
                                                            {productName}
                                                        </span>
                                                    </div>
                                                    <div className="accessories-number">
                                                        <span>
                                                            {manufacturerCode}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="remove-accessories-icon hover-with-delete-productDetail" onClick={AccessoriesRemoveById(productToProductID, goesWith)} >
                                                <PosImage
                                                    src={deleteDarkGrayIcon}
                                                    alt="delete-icon"
                                                    className="delet-order-img"
                                                />
                                                <PosImage
                                                    src={deleteHoverDarkGrayIcon}
                                                    alt="delete-icon"
                                                    className="delet-order-hover-img"
                                                />
                                            </div>
                                        </div>
                                    )
                                })} </> : null
                    }

                    <div className="mt-4 mb-2">
                        <div className="notes-added-user-name" id={replaces}  >
                            <span>
                                {Replaces}
                            </span>
                        </div>
                    </div>
                    <AutoComplete relationType={2} addNewAccessories={addNewAccessories} placeHolder={retailPriceLabel.accessoryPlaceholder} />
                    {
                        productAccessorieReplacesData?.length ?
                            <>
                                <div className='notes-manager-controls'>
                                    <p className='notes-manager-count'>
                                        {`${productAccessorieReplacesData?.length} OF 12 ADDED`}
                                    </p>
                                    <button className='notes-manager-btn' onClick={removeAllAccessories(replaces)}>Remove all</button>
                                </div>
                                {productAccessorieReplacesData.map((option) => {
                                    const { productName, thumbImg, manufacturerCode, productToProductID } = option || {}
                                    return (
                                        <div className="d-flex accessories-main-info-sec product-detail-info-sec-border align-items-center">
                                            <div className="d-flex accessories-left-info">
                                                <div className="accessories-icon">
                                                    <PosImage
                                                        src={thumbImg ? thumbImg : defaultIcon}
                                                        className="search-list-img"
                                                        alt="product"
                                                    />
                                                </div>
                                                <div className="accessories-data">
                                                    <div className="accessories-name">
                                                        <span>
                                                            {productName}
                                                        </span>
                                                    </div>
                                                    <div className="accessories-number">
                                                        <span>
                                                            {manufacturerCode}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="remove-accessories-icon hover-with-delete-productDetail" onClick={AccessoriesRemoveById(productToProductID, replaces)}>
                                                <PosImage
                                                    src={deleteDarkGrayIcon}
                                                    alt="delete-icon"
                                                    className="delet-order-img"
                                                />
                                                <PosImage
                                                    src={deleteHoverDarkGrayIcon}
                                                    alt="delete-icon"
                                                    className="delet-order-hover-img"
                                                />
                                            </div>
                                        </div>
                                    )
                                })} </> : null
                    }
                </div>
                <footer className="notes-model-footer">
                    <div className="notes-footer-wrapper d-flex notes-btn-div product-detail-modal-footer-spacing justify-content-end">

                    </div>
                </footer>

            </motion.dialog>
        </>
    );
}

export default ProductDetailsAccessoriesModals;
