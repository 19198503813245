import { Helmet } from "react-helmet";

interface Props {
  title?: string;
  metaName?: string;
  content?: string;
}

const PosHelmet = ({ title, content, metaName }: Props) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name={metaName} content={content} />
    </Helmet>
  );
};

export default PosHelmet;