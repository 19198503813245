import { buttonTitle } from "constants/constant";

const ProductStatusShow = ({
  statusValue,
}) => {
  const { active } = buttonTitle;

    return (
        <>
            <div className={`main-detail-product-status product-status-${statusValue?.status ? statusValue?.status : 'Active'}`}>
                <span className="status-icon"></span>
                <span>
                    {statusValue?.label ? statusValue?.label : active}
                </span>
            </div>
        </>
    )
}

export default ProductStatusShow
