/* eslint-disable @typescript-eslint/no-unused-expressions */
import Avvvatars from 'avvvatars-react';
import { motion } from 'framer-motion/dist/framer-motion';
import queryString from "querystring";
import { Fragment, useEffect, useState } from "react";
import Pagination from "react-pagination-library";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";


import { ManageUserModal } from "apps/admin";
import ChangePasswordModal from "apps/admin/ChangePassword/ChangePassword";
import IconButton from 'apps/admin/components/Elements/IconButton';
import MaterialIcon from 'apps/pos/Common/uiCore/MaterialIcon';
import useScrollLock from 'apps/admin/hooks/useScrollLock';
import PosSnackbar from "apps/pos/Common/uiCore/posSnackbar";
import { LockStatusForm, RejectUserInvitation } from "components";
import { RootState, user, User, UserListItemProps, UserListProps } from "models";
import EmptyState from './EmptyState';
import Skeleton from './Skeleton';

import { SearchKey } from 'constants/enum';
import { adminMessage } from 'constants/message';
import { Paths, ResponceStatus, Roles, Tabs, UserAccountStatus } from "enums";
import { UpdateModalContent } from "sagas/modal/actions";
import { snakbarShow } from 'sagas/pos/snakbar/snakbar.slice';
import {
  getAllUsers,
  getAwaitingApprovalUsers, getInvitedUsers, resetUserFlag
} from "sagas/user/actions";
import { cancelInviteUser, getLoggedInUser, resendInviteUser } from "services";

import "styles/admin/UserList/Pagination.scss";
import "styles/admin/UserList/UserListStyle.css";
import "styles/common.css";
import "../ManageUserForm/ManageUserForm.scss";
import "./UserListItem.scss";


const UserListItem = ({
  user,
  setUserToEdit,
  isInvited,
  isAwaitingApproval,
  loginUser,
  currentPage,
  activeTab,
  searchTerm,
  setSelectedTab
}: UserListItemProps) => {

  const dispatch = useDispatch();
  
  const [
    isSnackbarVisibleInvitationCancel,
    setIsSnackbarVisibleInvitationCancel,
  ] = useState(false);
  const [snackbarCancelMessage, setSnackbarCancelMessage] = useState("");
  const { card, permission, lockUnlocks, changePassword } = SearchKey;
  const [isUserLoading] = useState(true);
  useEffect(() => {
    setIsSnackbarVisibleInvitationCancel(false);
  }, [user]);

  const userId = searchTerm ? user?.id : user?.personId;
  const { tabIndexThree } = Tabs;

  const { awaitingApprovalData, reviewUserId, rejectUserSuccess } = useSelector<
    RootState,
    user
  >((state) => state.user);
  const { inviteReject, failInviteReject } = adminMessage;
  const { success, error } = ResponceStatus;
  const navigate = useNavigate();
  const { toggleScroll } = useScrollLock();

  useEffect(() => {
    if (rejectUserSuccess === success) {
      dispatch(snakbarShow({message : inviteReject}))
      dispatch(resetUserFlag());
      if (loginUser?.permissions.userAdminPermissions.canView === 1) {
        dispatch(getAllUsers(currentPage));
      }
      dispatch(getAwaitingApprovalUsers(currentPage));
      dispatch(
        UpdateModalContent({
          isOpen: false,
          children: null,
          fixedModal: true,
          removePaddingBottom: false,
          noMaxWidth: false
        })
      );
    }
    if (rejectUserSuccess === error) {
      dispatch(snakbarShow({message : failInviteReject}))
      dispatch(
        UpdateModalContent({
          isOpen: false,
          children: null,
          fixedModal: true,
          removePaddingBottom: false,
          noMaxWidth: false
        })
      );
      dispatch(resetUserFlag());
    }
  }, [rejectUserSuccess]);

  const onCloseIcon = (e) => {
    e.stopPropagation();
    closeMenu(e);
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        navigate: true,
        classModal: "confirmation-dialog",
        children: (
          <RejectUserInvitation
            currentPage={currentPage}
            user={user}
            onSuccess={() => {
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: true,
                  removePaddingBottom: false,
                  noMaxWidth: false
                })
              );
            }}
            onError={() => {
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: true,
                  removePaddingBottom: false,
                  noMaxWidth: false
                })
              );
            }}
          />
        ),
        fixedModal: true,
        removePaddingBottom: true,
        noMaxWidth: false
      })
    );
  }

  useEffect(() => {
    setIsSnackbarVisibleInvitationCancel(false)
    if (reviewUserId) {
      const userArray = awaitingApprovalData?.data?.find((record) => {
        return record?.personId == reviewUserId;
      });
      if (userArray) {
        dispatch(
          UpdateModalContent({
            isOpen: true,
            isAdmin: true,
            classModal: "user-profile-dialog",
            children: <ManageUserModal user={userArray} isApproval page={currentPage} />,
            modalSize: "xl",
            fixedModal: true,
            removePaddingBottom: false,
            noMaxWidth: false
          })
        )
        setSelectedTab(tabIndexThree)
        navigate(`${Paths.adminUsers}/${reviewUserId}/${Paths.adminReviewIdUsers}`)
      } else {
        navigate(`${Paths.adminUsers}`);
      }
    }
  }, [reviewUserId, awaitingApprovalData?.data]);

  let userAccountStatus;
  let role;
  if (searchTerm) {
    userAccountStatus = user?.status;
    role = user?.roles[0];
  } else {
    userAccountStatus = user?.status?.name.trim();
    role = user?.roles[0].name
  }

  switch (userAccountStatus) {
    case UserAccountStatus.lockedOut:
      userAccountStatus = UserAccountStatus.locked;
      break;
    case UserAccountStatus.awaitingApproval:
      userAccountStatus = UserAccountStatus.awaiting;
      break;
    case UserAccountStatus.processed:
      userAccountStatus = UserAccountStatus.invited;
      break;
    case UserAccountStatus.locked_out:
      userAccountStatus = UserAccountStatus.locked;
      break;
    case UserAccountStatus.active:
      userAccountStatus = UserAccountStatus.activeUser;
      break;
    default:
      userAccountStatus = userAccountStatus;
      break;
  }

  switch (role) {
    case Roles.dataManager:
      role = Roles.data_manager;
      break;
    case Roles.Salesman:
      role = Roles.Salesman;
      break;
    case Roles.WarehousePerson:
      role = Roles.WarehousePerson;
      break;
    case Roles.AccountingUser:
      role = Roles.AccountingUser;
      break;
    default:
      role = role;
      break;
  }
  const [menuOpen, setMenuOpen] = useState(false);

  // calculate window height
  const windowHeight = window.innerHeight;

  // set a custom class if the list item is near the bottom edge
  const [menuClasses, setMenuClasses] = useState('');

  const openMenu = (e) => {
    e.stopPropagation();
    setMenuOpen(() => true);
    toggleScroll(false);

    // change overflow menu position if it is near the bottom edge
    const isBelowEquator = e.screenY > windowHeight * 75 / 100;

    if (isBelowEquator) {
      setMenuClasses(() => 'bottom-edge');
    }
  }

  const closeMenu = (e) => {
    e.stopPropagation();
    setMenuOpen(() => false);
    toggleScroll(true);

    // reset overflow menu classes
    setMenuClasses(() => '');
  }

  const toggleMenu = (e) => {
    if (menuOpen) {
      closeMenu(e);
    }
    else {
      openMenu(e);
    }
  }

  const searchUserDetail = (userId, type) => {
    if (type === card) {
      const isUser = loginUser?.personId === userId;
      setUserToEdit(user);
      if (loginUser?.permissions?.userAdminPermissions?.canView === 1) {
        activeTab == 2 ? "" :
          navigate(`${Paths.adminUsers}/${userId}`)
        isAwaitingApproval
          ? dispatch(
            UpdateModalContent({
              isOpen: true,
              isAdmin: true,
              navigate: true,
              classModal: "user-profile-dialog",
              children: (
                <ManageUserModal
                  isUser={isUser}
                  isApproval={true}
                  loginUser={loginUser}
                  page={currentPage}
                  personId={userId}
                  isUserLoading={isUserLoading}
                />
              ),
              modalSize: "xl",
              fixedModal: true,
              removePaddingBottom: false,
              noMaxWidth: false
            })
          )
          : isInvited
            ? ""
            : dispatch(
              UpdateModalContent({
                isOpen: true,
                isAdmin: true,
                navigate: true,
                classModal: "user-profile-dialog",
                children: (
                  <ManageUserModal
                    isUser={isUser}
                    isApproval={false}
                    loginUser={loginUser}
                    page={currentPage}
                    personId={userId}
                    isUserLoading={isUserLoading}
                  />
                ),
                modalSize: "xl",
                fixedModal: true,
                removePaddingBottom: false,
                noMaxWidth: false
              })
            );
      } else {
        if (userId === loginUser.personId) {
          navigate(`${Paths.adminUsers}/${userId}`)
          dispatch(
            UpdateModalContent({
              isOpen: true,
              isAdmin: true,
              navigate: true,
              classModal: "user-profile-dialog",
              children: (
                <ManageUserModal
                  isUser={isUser}
                  isUserLoading={isUserLoading}
                  isApproval={false}
                  personId={userId}
                  loginUser={loginUser}
                  page={currentPage}
                />
              ),
              modalSize: "xl",
              fixedModal: true,
              removePaddingBottom: false,
              noMaxWidth: false
            })
          );
        }
      }


    } else {
      getLoggedInUser(userId).then((response) => {
        if (type === permission) {
          permissionModel(response);
        }
        if (type === lockUnlocks) {
          lockUnlockModel(response);
        }
        if (type === changePassword) {
          changePasswordModel(response);
        }
      });
    }
  }

  const onCardClick = (e) => {
    e.stopPropagation();
    closeMenu(e);
    if (searchTerm) {
      searchUserDetail(userId, card)
    } else {
      mangeUserModel(user);
    }
  };

  const mangeUserModel = (user) => {
    const isUser = loginUser?.personId === userId;
    setUserToEdit(user);
    if (loginUser?.permissions?.userAdminPermissions?.canView === 1) {
      activeTab == 2 ? "" : (
        isAwaitingApproval
          ? navigate(`${Paths.adminReviewUsers}/${userId}`)
          : navigate(`${Paths.adminUsers}/${userId}`)
      )
      isAwaitingApproval
        ? dispatch(
          UpdateModalContent({
            isOpen: true,
            isAdmin: true,
            navigate: true,
            classModal: "user-profile-dialog",
            children: (
              <ManageUserModal
                isUser={isUser}
                user={user}
                isApproval={true}
                loginUser={loginUser}
                page={currentPage}
              />
            ),
            modalSize: "xl",
            fixedModal: true,
            removePaddingBottom: false,
            noMaxWidth: false
          })
        )
        : isInvited
          ? ""
          : dispatch(
            UpdateModalContent({
              isOpen: true,
              isAdmin: true,
              navigate: true,
              classModal: "user-profile-dialog",
              children: (
                <ManageUserModal
                  isUser={isUser}
                  user={user}
                  isApproval={false}
                  loginUser={loginUser}
                  page={currentPage}
                />
              ),
              modalSize: "xl",
              fixedModal: true,
              removePaddingBottom: false,
              noMaxWidth: false
            })
          );
    } else {
      if (userId === loginUser.personId) {
        isAwaitingApproval
          ? navigate(`${Paths.adminReviewUsers}/${userId}`)
          : navigate(`${Paths.adminUsers}/${userId}`)
        dispatch(
          UpdateModalContent({
            isOpen: true,
            isAdmin: true,
            navigate: true,
            classModal: "user-profile-dialog",
            children: (
              <ManageUserModal
                isUser={isUser}
                user={user}
                isApproval={false}
                loginUser={loginUser}
                page={currentPage}
              />
            ),
            modalSize: "xl",
            fixedModal: true,
            removePaddingBottom: false,
            noMaxWidth: false
          })
        );
      }
    }
  }

  const changePermission = (e) => {
    closeMenu(e);
    if (searchTerm) {
      searchUserDetail(userId, permission)
    } else {
      permissionModel(user);
    }
  }

  const permissionModel = (user) => {
    const isUser = loginUser?.personId === userId;
    isAwaitingApproval
      ? navigate(`${Paths.adminReviewUsers}/${userId}`)
      : navigate(`${Paths.adminUsers}/${userId}`)
    setUserToEdit(user);
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        navigate: true,
        classModal: "user-profile-dialog",
        children: (
          <ManageUserModal
            isUser={isUser}
            user={user}
            isApproval={false}
            loginUser={loginUser}
            page={currentPage}
            currentTab={1}
          />
        ),
        modalSize: "xl",
        fixedModal: true,
        removePaddingBottom: false,
        noMaxWidth: false
      }))
  }

  const lockUnlock = (e) => {
    closeMenu(e);
    if (searchTerm) {
      searchUserDetail(userId, lockUnlocks)
    } else {
      lockUnlockModel(user);
    }

  };

  const lockUnlockModel = (user) => {
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        navigate: false,
        classModal: "confirmation-dialog",
        children: (
          <LockStatusForm
            currentPage={currentPage}
            user={user}
            onSuccess={() => {
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: true,
                  removePaddingBottom: false,
                  noMaxWidth: false
                })
              );
            }}
            onError={() => {
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: true,
                  removePaddingBottom: false,
                  noMaxWidth: false
                })
              );
            }}
          />
        ),
        fixedModal: true,
        removePaddingBottom: true,
        noMaxWidth: true
      })
    );
  }

  const handleChangePassword = (e) => {
    closeMenu(e);
    if (searchTerm) {
      searchUserDetail(userId, changePassword)
    } else {
      changePasswordModel(user);
    }
  };

  const changePasswordModel = (user) => {
    const isUser = loginUser?.personId === userId;

    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        navigate: false,
        classModal: "change-password-dialog",
        children: (
          <ChangePasswordModal
            isUser={isUser}
            loginUser={loginUser}
            user={user}
            onSuccess={(msg) => {
              setSnackbarCancelMessage(msg);
              setIsSnackbarVisibleInvitationCancel(true);
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: true,
                  removePaddingBottom: false,
                  noMaxWidth: false
                })
              );
            }}
          />
        ),
        fixedModal: true,
        removePaddingBottom: true,
        noMaxWidth: false
      })
    );
  }

  const resendOption = (e) => {
    closeMenu(e);
    resendInviteUser(userId, loginUser?.personId)
      .then((res) => {
        if (res?.data) {
          setIsSnackbarVisibleInvitationCancel(true);
          setSnackbarCancelMessage(adminMessage.inviteSent);
        } else {
          setIsSnackbarVisibleInvitationCancel(true);
          setSnackbarCancelMessage(
            adminMessage.failInviteSent
          );
        }
      })
      .catch(() => {
        // Required Later
      });
  };

  const cancelInviteOption = (e) => {
    closeMenu(e);
    cancelInviteUser(userId)
      .then((res) => {
        if (res?.data && res?.data?.data) {
          setIsSnackbarVisibleInvitationCancel(true);
          setSnackbarCancelMessage(adminMessage.inviteCancel);
          dispatch(getInvitedUsers(currentPage));
        } else {
          setIsSnackbarVisibleInvitationCancel(true);
          setSnackbarCancelMessage(
            adminMessage.failInviteCancel
          );
        }
      })
      .catch(() => {
        // Required Later
      });
  };
  const { permissions } = loginUser || {};
  const userEmail = user?.email;
  const avatarBgColor = '#fff';
  return (
    <>
      <div
        className='user-list-item'
        onMouseEnter={() => {
          // Required Later
        }}
        onMouseLeave={() => {
          // Required Later
        }}
        tabIndex={0}
        onClick={onCardClick}
      >
        <div className='avatar'>
          <Avvvatars
            value={userEmail}
            style='shape'
            size={50}
            border={true}
            borderSize={2}
            borderColor={avatarBgColor}
          />
        </div>
        <div className='name-group'>
          <p className='name mb0' title={user.fullName}>{user.fullName}</p>
          <p className='email mb0' title={user.email}>{user.email}</p>
        </div>

        <p className="created-at mb0">{Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date(user.createdAt))}</p>
        <p className="role mb0" title={role}>{role}</p>
        <div className={`status ${isAwaitingApproval ? 'awaiting' : userAccountStatus.toLowerCase()}`}>
          {userAccountStatus ? (
            <>
              {isAwaitingApproval ? <p className='mb0'>Awaiting</p> : <p className='mb0'>{userAccountStatus}</p>}
              {!isAwaitingApproval && !isInvited && userAccountStatus === "Locked" ? (<MaterialIcon size="16" icon="lock_outline" />) : null}
              {isAwaitingApproval ? <MaterialIcon size="16" icon="hourglass_top" /> : null}
            </>
          ) : null}
        </div>
        {
          permissions?.userAdminPermissions?.canView === 1 || userId === loginUser.personId ?
            (<div className="actions">
              {
                isAwaitingApproval ? (<>
                  <IconButton
                    title="Accept"
                    icon="check"
                    onClick={onCardClick}
                  />
                  <IconButton
                    title="Reject"
                    icon="close"
                    onClick={onCloseIcon}
                  />
                </>
                ) : (<>

                  <div className="menu-wrapper">
                    <IconButton
                      title="More actions"
                      icon="more_vertical"
                      classes="action"
                      secondary
                      onClick={toggleMenu}
                    />
                    {menuOpen ? (
                      !isInvited ? (<>
                        <div className="menu-skrim" onClick={closeMenu}></div>
                        <div className={`menu ${menuClasses}`}>
                          {/* Lock-unlock buttons */}
                          {userId !== loginUser.personId && permissions?.userAdminPermissions?.canLock === 1 ?
                            (userAccountStatus === UserAccountStatus.locked ? (
                              <OverflowMenuItem label="Unlock Account" icon="lock_open" onClick={lockUnlock} />
                            ) : (
                              <OverflowMenuItem label="Lock Account" icon="lock_outline" onClick={lockUnlock} />
                            )) : ""
                          }
                          {permissions?.userAdminPermissions?.canSetUserPermissions === 1 ?
                            <OverflowMenuItem label="Change Permissions" icon="checklist" onClick={changePermission} /> : ""}
                          {
                            userAccountStatus !== UserAccountStatus.locked ?
                              <OverflowMenuItem label="Change Password" icon="key" onClick={handleChangePassword} />
                              : null
                          }

                          {/* Manage button */}
                          <OverflowMenuItem label="Manage" icon="settings" onClick={onCardClick} />

                        </div>
                      </>) : (
                        <>
                          <div className="menu-skrim" onClick={closeMenu}></div>
                          <div className={`menu ${menuClasses}`}>
                            {/* Buttons for Invited Tab */}
                            <OverflowMenuItem label="Resend Invite" icon="forward_to_inbox" onClick={resendOption} />
                            <OverflowMenuItem label="Cancel Invite" icon="cancel" classes='menu-item-danger' onClick={cancelInviteOption} />
                            {/* - - - - - */}
                          </div>
                        </>
                      )
                    ) : null}
                  </div>
                </>)
              }
            </div>) : ""
        }

      </div>
          <PosSnackbar
            open={isSnackbarVisibleInvitationCancel}
            message={snackbarCancelMessage}
            onClose={() => {
              setIsSnackbarVisibleInvitationCancel(false);
            }}
          /> 
    </>
  );
};
export function UserListItems({
  users,
  options,
  setUserToEdit,
  usersCount,
  page,
  setPage,
  isInvited,
  isAwaitingApproval,
  searchTerm,
  lockUnlockOptions,
  loginUser,
  activeTab,
  isLoader,
  moreSearchAvailable,
  setSelectedTab
}: UserListProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams('page');
  const [
    isSnackbarListVisibleInvitationError,
    setIsSnackbarListVisibleInvitationError,
  ] = useState(false);
  const [snackbarMessage] = useState("");
  const [isSnackbarListVisibleInvitation, setIsSnackbarListVisibleInvitation] =
    useState(false);
  const handleSnackBarClose = () => {
    setIsSnackbarListVisibleInvitation(false);
    setIsSnackbarListVisibleInvitationError(false);
  };
  const { noRecordFound, noUsersFoundFor } = adminMessage;

  const handleQueryString = searchParams.get('page');

  useEffect(() => {
    if (page != Number(handleQueryString) && handleQueryString != null && Number(handleQueryString) != 0) {
      setPage(Number(handleQueryString));
    } else {
      const pathName = location.pathname;
      if (pathName == Paths.adminUsers && Number(handleQueryString) == 0) {
        setPage(1);
      }
    }
  }, [handleQueryString])

  const userListVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.015,
      }
    },
  }

  const listItemVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    }
  }

  const changePage = (page) => {
    setPage(page);
    const query = queryString.stringify({ page: page });
    const pathName = location.pathname;
    navigate(`${pathName}?${query}`)
  }

  return (
    <Fragment>
      {
        users?.length && !isLoader ? (
          <>
            {users?.length !== 0 ? (
              <>
                <div className="header">
                  <span className='header-label'></span>
                  <span className='header-label name'>Name</span>
                  <span className='header-label created-at'>Created on</span>
                  <span className='header-label role'>Role</span>
                  <span className='header-label status'>Status</span>
                  <span className='header-label actions'></span>
                </div>

                {users.map((user: User) => {
                  const userId = searchTerm ? user?.id : user?.personId;
                  if (userId) {
                    return (
                      <motion.div key={userId} variants={listItemVariants} initial="initial" animate="animate">
                        <UserListItem
                          key={userId}
                          user={user}
                          options={options}
                          setUserToEdit={setUserToEdit}
                          isInvited={isInvited}
                          isAwaitingApproval={isAwaitingApproval}
                          lockUnlockOption={lockUnlockOptions}
                          loginUser={loginUser}
                          currentPage={page}
                          activeTab={activeTab}
                          searchTerm={searchTerm}
                          setSelectedTab ={setSelectedTab}
                        />
                      </motion.div>
                    )
                  }
                }
                )}
              </>
            ) : searchTerm ? (
              <div className="no-record-found">
                <span className="message-span">{noRecordFound}</span>
              </div>
            ) : (
              <EmptyState />
            )}
          </>
        ) : (
          searchTerm && !isLoader ?
            (
              <div className="no-record-found">
                <span className="message-span">{noUsersFoundFor} "{searchTerm}"</span>
              </div>
            ) : (
              <motion.div className="user-list-items" variants={userListVariants} title='Loading, please wait...'>
                {Array(10).fill("").map((user, index) => (
                  <Skeleton key={`[user]-${index}`} />
                ))}
              </motion.div>
            )
        )
      }
      {searchTerm && moreSearchAvailable ? (
        <div className="no-record-found">
          <span className="target_scroll">Loading, please wait..</span>
        </div>
      ) : null}
      <div className='pd-25'>
        {searchTerm === "" && usersCount > 10 && (
          <div className="ht-60 mt-20">
            <span className="fs-12 inter-reg status-title-color">
              Showing page {page} of {Math.ceil(usersCount / 10)}
            </span>
            <Pagination
              className="mt-3"
              currentPage={page}
              totalPages={Math.ceil(usersCount / 10)}
              changeCurrentPage={changePage}
              theme="square-i"
            />
          </div>
        )}
      </div>

 <PosSnackbar
        open={
          isSnackbarListVisibleInvitation ||
          isSnackbarListVisibleInvitationError
        }
        message={snackbarMessage}
        onClose={handleSnackBarClose}
      />
    </Fragment>
  );
}

const OverflowMenuItem = (props) => {
  const { icon, label, onClick, classes } = props;
  return (
    <button className={`menu-item ${classes ? classes : ''}`} onClick={onClick} tabIndex={0}>
      <MaterialIcon icon={icon} size="24" />
      <span className="label">{label}</span>
    </button>
  )
}