import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShowMoreText from "react-show-more-text";

import PosTooltip from "apps/pos/Common/uiCore/posTooltip";
import { IconButton } from "apps/springboard/Component/SBItems/elements";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";
import { DocModule } from "apps/springboard/DocModule/DocModule";
import Button from "components/SPButton/Button";

import externallink from "apps/springboard/images/icons/external-link.svg";
import fileImage from "file.png";

import { springBoardDocumentsTabs, springBoardText } from "constants/constant";
import { Distributor, QuoteDetailDocument, RootState } from "models";

import { UpdateModalContent } from "sagas/modal/actions";
import {
  getVersionHistoryRequest,
  setDocumentTypes,
  setQuoteCounts,
  setQuoteDocs,
} from "sagas/quoteDetail/actions";
import { getDocumentType, getQuoteDetailDocuments } from "services";

import { GlobalNumbers } from "constants/enum";
import { setOpenMiniNote } from "sagas/quoteDetail/quoteDetailsSlice";
import "styles/admin/springboard/quoteDetails.scss";
import { formatFileSize, handleFilterItems } from "utils/utils";
import { DocumentUpload } from "../Component/documentUpload";
import "./QuoteDocuments.scss";

interface QuoteDocumentsProps {
  quoteId?: number;
  isModal?: boolean;
  repDocs?: any; // necessary
  setDocClose?: any; // necessary
  setOpenDocumentModal?: any; // necessary
}

interface DocumentModalProps {
  document: QuoteDetailDocument;
}

function DocumentModal({ document }: DocumentModalProps) {
  const dispatch = useDispatch();

  const myRef = useRef(null);

  const {
    activeFlag,
    absolutePath,
    jobQuoteID,
    jobLotQuoteRepDocID,
    docTypeName,
    repName,
    uploadDate,
    uploaderName,
    docSize,
    docName,
    custPoAmt,
  } = document;
  const { number, type, rep, addedOn, addedBy, size, fileType, open, download, amountOfPoDocument } =
    springBoardDocumentsTabs;

  const validImageFormat = (fileName) => {
    const fileFormat = fileName.split(".").pop();
    if (
      fileFormat === "jpg" ||
      fileFormat === "png" ||
      fileFormat === "jpeg" ||
      fileFormat === "gif"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );

  useEffect(() => {
    dispatch(getVersionHistoryRequest({ id: jobQuoteID, showAll: 1 }));
  }, []);

  const openLink = (url) => {
    window.open(url, "_blank");
  };

  const tabsView = (key, value) => {
    return (
      <>
        <div className="border-bottom-lightgray border-bottom border-md"></div>
        <div className="d-flex justify-content-between align-items-center p-2">
          <div className="cotype_left w-50">
            <p className="card-text mb-0 fs-16 card-label-style">
              {key}
            </p>
          </div>
          <div className="cotype_right w-50">
            <p className="mb-0 f16-inter-med">
              <span>{value}</span>
            </p>
          </div>
        </div>
      </>
    )
  }

  const deleteDoc = () => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      openLink(
        `${distributor?.httpsUrl}/gmSale/msJobLotQuote_repDocDelete.aspx?jobLotQuoteID=${document?.jobQuoteID}&docID=${document?.jobLotQuoteRepDocID}`
      );
    }
  }

  return (
    <div>
      {validImageFormat(document?.docName) ? (
        <div className="doc_card_img ">
          <div className="doc_head_img">
            <img
              alt={document?.docName.split(".").pop()}
              src={document?.absolutePath}
              className="img-fluid"
            ></img>
            <div className="ext_link middle">
              <img src={externallink} alt=""></img>
            </div>
          </div>
        </div>
      ) : (
        <div className="doc_head_img">
          <img className="card-img-top" alt="" src={fileImage} />
          <div className="ext_link middle">
            <img src={externallink} alt=""></img>
          </div>
        </div>
      )}
      <div className=" justify-content-between align-items-center p-3">
        <div className="d-flex justify-content-between">
          <p className="card-text f20-slab-500 mb-0 text-dark ">
            <strong className="text-break">{document?.docName}</strong>
          </p>

          <p
            className="card-text text-dark  pl-2 pr-2 rounded-sm f14_inter_regular ml-2 doc_type_badge"
            style={{ marginTop: 5 }}
          >
            <strong>{document?.docName.split(".").pop()}</strong>
          </p>
        </div>
        <div className="document-description mt-2">
          <span id="document-description">
            <ShowMoreText
              lines={2}
              more=" Show more"
              less=" Show less"
              className="content-css"
              anchorClass="show-more-less-clickable"
              expanded={false}
              truncatedEndingComponent={"..."}
            >
              {document?.docDescription}
            </ShowMoreText>
          </span>
        </div>
      </div>
      {tabsView(number, jobLotQuoteRepDocID)}
      {tabsView(type, docTypeName)}
      {tabsView(amountOfPoDocument, custPoAmt)}
      {tabsView(rep, repName)}
      {tabsView(addedOn, uploadDate)}
      {tabsView(addedBy, uploaderName)}
      {tabsView(size, formatFileSize(docSize))}
      {tabsView(fileType, docName.split(".").pop())}
      <div className="border-bottom-lightgray border-bottom border-md"></div>

      {/* Removing Code as no longer needed for now but keeping it commented might need later 
      {versionHistory?.map((version) => {
        return (
          <div>
            <div className="d-flex justify-content-between align-items-center p-3">
              <div className="w-50">
                <p className="card-text  mb-0  f14_inter_medium">
                  {versionHistory[0]?.versionNum === version.versionNum ? (
                    <strong>Latest</strong>
                  ) : (
                    <strong>Version - {version.versionNum}</strong>
                  )}
                </p>
                <p className="card-text mb-0 fs-10 label-style-version text-uppercase">
                  {version.versionCreatorName} - {version.versionCreateTime}
                </p>
              </div>
              <div>
                <i
                  className="fa fa-download download-icon"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            <hr className="slim-divider"></hr>
          </div>
        );
      })} */}

      <div ref={myRef}></div>

      {activeFlag ? (
        <div className="doc_action_btn">
          <div className="doc_action_left">
            <IconButton
              className="bg-transparent border-0"
              onClick={deleteDoc}
              name="delete"
              type="danger"
              iconClassName="unique-icon-class"
            />
          </div>
          <div className="doc_action_right justify-content-between align-items-center d-flex">
            {absolutePath && (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    label={download}
                    type="secondary"
                    size="small"
                    href={absolutePath}
                    target="_blank"
                    leadingIcon="download"
                    className="bg-transparent border-0"
                    classes="image-download-btn"
                    isDownload
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    label={open}
                    type="primary"
                    size="small"
                    href={absolutePath}
                    target="_blank"
                    leadingIcon="launch"
                    className="bg-transparent border-0"
                    classes="image-open-btn"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export function QuoteDocuments({
  quoteId,
  isModal,
  repDocs,
  setOpenDocumentModal,
}: QuoteDocumentsProps) {
  const dispatch = useDispatch();
  const [quoteDocuments, setQuoteDocuments] = useState<QuoteDetailDocument[]>();
  const [listView, setListView] = useState(true);
  const [isDeletedDocsShow, setIsDeletedDocsShow] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(true);
  const { quoteRepName } = repDocs || {};
  const {
    no,
    name,
    docType,
    rep,
    amountOfPo,
    lastUpdated,
    uploadedBy,
    upload,
    deleted,
  } = springBoardDocumentsTabs;
  const { document, noDocument } = springBoardText;
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);

  useEffect(() => {
    let loadedQuoteDocuments = false;
    getDocumentType().then((res) => {
      dispatch(setDocumentTypes(res));
    });
    getQuoteDetailDocuments(quoteId).then((response) => {
      const { count } = response || {};
      if (!loadedQuoteDocuments) {
        dispatch(setQuoteCounts(count));
        dispatch(setQuoteDocs(response?.data));
      }
    });
    return () => {
      loadedQuoteDocuments = true;
    };
  }, [quoteId]);

  useEffect(() => {
    getQuoteDetailDocuments(quoteId).then((response) => {
      setDocumentLoading(false);
      const { data } = response || {};
      if (isModal) {
        const docsData =
          data?.length &&
          data.filter(({ repName }) => quoteRepName === repName);
        setQuoteDocs(docsData);
        setQuoteDocuments(docsData);
      } else {
        setQuoteDocs(data);
        setQuoteDocuments(data);
      }
    });
  }, [quoteId]);

  useEffect(() => {
    if (isOpenUploadModal) {
      dispatch(setOpenMiniNote(true));
    } else {
      dispatch(setOpenMiniNote(false));
    }
  }, [isOpenUploadModal]);

  const quoteDocumentsList = handleFilterItems({
    data: quoteDocuments,
    key: "activeFlag",
    value: GlobalNumbers.ONE,
  });
  const deletedDocument = handleFilterItems({
    data: quoteDocuments,
    key: "activeFlag",
    value: GlobalNumbers.ZERO,
  });

  const documentComponent = (documentData, status) => {
    return (
      <table className="table table-hover">
        {status && (
          <thead className="doc-table-status-header">
            <tr>
              <th scope="col" className="f12_inter_regular border-0 inter-reg">
                {no}
              </th>
              <th scope="col" className="f12_inter_regular border-0 inter-reg">
                {name}
              </th>
              <th
                scope="col"
                className="f12_inter_regular border-0 inter-reg"
              />
              <th scope="col" className="f12_inter_regular border-0 inter-reg">
                {docType}
              </th>
              <th scope="col" className="f12_inter_regular border-0 inter-reg">
                {rep}
              </th>
              <th scope="col" className="f12_inter_regular border-0 inter-reg">
                {amountOfPo}
              </th>
              <th scope="col" className="f12_inter_regular border-0 inter-reg">
                {lastUpdated}
              </th>
              <th scope="col" className="f12_inter_regular border-0 inter-reg">
                {uploadedBy}
              </th>
            </tr>
          </thead>
        )}
        {documentData?.map((doc, index) => {
          const {
            jobLotQuoteRepDocID,
            docName,
            docTypeName,
            repName,
            custPoAmt,
            uploadDate,
            uploaderName,
          } = doc;
          return (
            <tbody key={index}>
              <tr
                className="docs_grid_tab"
                style={{ cursor: "pointer" }}
                data-bs-toggle="modal"
                data-bs-target="#jumper_modal"
                onClick={() => {
                  isModal && setOpenDocumentModal(true);
                  dispatch(
                    UpdateModalContent({
                      children: <DocumentModal document={doc} />,
                      modalSize: "md",
                      isOpen: true,
                      fixedModal: false,
                      removePaddingBottom: false,
                      navigate: false,
                      closeNavigator: "sbDocument",
                    })
                  );
                }}
              >
                <th className="f14_inter_regular ">{jobLotQuoteRepDocID}</th>
                <td className="f14_inter_regular ">
                  <span className="listed-doc-name">{docName}</span>
                </td>
                <th className="f14_inter_regular ">
                  <p
                    className="card-text text-dark rounded-sm card-type-tag"
                    style={{ marginTop: 5 }}
                  >
                    {docName.split(".").pop()}
                  </p>
                </th>
                <td className="f14_inter_regular ">
                  <div
                    className="float_left subquote-dot doc_type_container"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span className="color-black fs-14">{docTypeName}</span>
                  </div>
                </td>
                <td className="f14_inter_regular ">{repName}</td>
                <td className="f14_inter_regular ">{custPoAmt}</td>
                <td className="f14_inter_regular ">{uploadDate}</td>
                <td className="f14_inter_regular ">{uploaderName}</td>
              </tr>
            </tbody>
          );
        })}
      </table>
    );
  };

  const deletedDocumentTitle = () => {
    return (
      <span
        role="button"
        className="border-bottom-lightgray border-bottom border-md"
        onClick={() => {
          setIsDeletedDocsShow(!isDeletedDocsShow);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 25,
        }}
      >
        {!isDeletedDocsShow ? (
          <div
            className="add_rep_tag"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              top: 16,
              width: 144,
              backgroundColor: "#ffffff",
            }}
          >
            <span
              role="button"
              onClick={() => {
                setIsDeletedDocsShow(true);
              }}
              className="fs-12 fw-medium inter-med"
            >
              <span className="color-black fs-14" role="button">
                +{deletedDocument?.length} {deleted}
              </span>
            </span>
          </div>
        ) : null}
      </span>
    );
  };

  const handleCloseModel = () => {
    setIsOpenUploadModal(false);
  };

  const handleUploadModal = () => {
    setIsOpenUploadModal(true);
  };

  return (
    <div className="quote-details-documents">
      {documentLoading ? (
        <ShapedSkeleton
          shape="0 20 40 14, 0 50 40 14, 0 80 40 14, 
              384 20 24 14, 384 50 24 14, 384 80 24 14, 
              663 20 60 14, 663 50 60 14, 663 80 100 14, 
              54 20 250 14, 54 50 200 14, 54 80 200 14, 
              428 20 140 14, 428 50 100 14, 428 80 140 14"
          width={763}
          height={150}
          radius={4}
        />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex mb-1">
              <div className="filter-sub-header">
                <span className="font-size12 mb-3">
                  {typeof quoteDocumentsList !== "undefined"
                    ? quoteDocumentsList?.length
                      ? `${quoteDocumentsList.length} ${document}${quoteDocumentsList.length > 1 ? "s" : ""
                      }`
                      : noDocument
                    : null}
                </span>
              </div>
            </div>

            <div className="header-actions">
              <Button
                label={upload}
                leadingIcon="upload"
                type="secondary"
                size="small"
                onClick={handleUploadModal}
              />
              <PosTooltip
                title={listView ? "Grid view" : "List view"}
                placement="top"
              >
                <span>
                  <IconButton
                    name={listView ? "calendar_view_month" : "view_list"}
                    type="tertiary"
                    onClick={() => setListView((listView) => !listView)}
                  />
                </span>
              </PosTooltip>
            </div>
          </div>
          {listView ? (
            <>
              {quoteDocumentsList?.length > 0
                ? documentComponent(quoteDocumentsList, true)
                : null}
              {deletedDocument?.length > 0 ? (
                <>
                  {deletedDocumentTitle()}
                  {isDeletedDocsShow ? (
                    <>
                      <div className="mt-3 mb-2">
                        <strong className="fs-15">
                          {deletedDocument?.length} {deleted}
                        </strong>
                      </div>
                      {documentComponent(
                        deletedDocument,
                        !(quoteDocumentsList?.length > 0)
                      )}{" "}
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          ) : (
            <>
              {!listView && quoteDocumentsList?.length ? (
                <div className="sb-doc-cards">
                  {quoteDocumentsList.map((doc, index) => (
                    <DocModule
                      data-bs-toggle="modal"
                      data-bs-target="#jumper_modal"
                      key={index}
                      isCardModule
                      absolutePath={doc.absolutePath}
                      number={doc.jobLotQuoteRepDocID}
                      fileName={doc.docName}
                      docTitle={doc.docTypeName}
                      rep={doc.repName}
                      uploadedBy={doc.uploaderName}
                      onClick={() => {
                        isModal && setOpenDocumentModal(true);
                        dispatch(
                          UpdateModalContent({
                            children: <DocumentModal document={doc} />,
                            modalSize: "md",
                            isOpen: true,
                            fixedModal: false,
                            removePaddingBottom: false,
                            navigate: false,
                            closeNavigator: "sbDocument",
                          })
                        );
                      }}
                    />
                  ))}
                </div>
              ) : null}
              {!listView && deletedDocument?.length ? (
                <>
                  {deletedDocumentTitle()}
                  {isDeletedDocsShow ? (
                    <>
                      <div className="mt-3 mb-2">
                        <strong className="fs-15">
                          {deletedDocument?.length} {deleted}
                        </strong>
                      </div>
                      <div className="sb-doc-cards">
                        {deletedDocument.map((doc, index) => (
                          <DocModule
                            data-bs-toggle="modal"
                            data-bs-target="#jumper_modal"
                            key={index}
                            absolutePath={doc.absolutePath}
                            isCardModule
                            number={doc.jobLotQuoteRepDocID}
                            fileName={doc.docName}
                            docTitle={doc.docTypeName}
                            rep={doc.repName}
                            uploadedBy={doc.uploaderName}
                            onClick={() => {
                              isModal && setOpenDocumentModal(true);
                              dispatch(
                                UpdateModalContent({
                                  children: <DocumentModal document={doc} />,
                                  modalSize: "md",
                                  isOpen: true,
                                  fixedModal: false,
                                  navigate: false,
                                  closeNavigator: "sbDocument",
                                  removePaddingBottom: false,
                                })
                              );
                            }}
                          />
                        ))}
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </>
      )}
      {isOpenUploadModal && (
        <DocumentUpload
          open={isOpenUploadModal}
          quoteId={quoteId}
          handleCloseModel={handleCloseModel}
          updatedDocument={setQuoteDocuments}
        />
      )}
    </div>
  );
}
