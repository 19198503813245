const ShapedSkeleton = (props) => {
    const { shape = '', width = 0, height = 0, radius = 0, fill = 'inherit', fullWidth = false , classes} = props;
    const blocks = shape.split(',');
    const rectangles = blocks.map(block => block.trim().split(' '));
  
    return (
      <svg
        className={`shaped-skeleton-block ${classes}`}
        width={fullWidth ? '100%' : width}
        height={height}
        fill='none'
        viewBox={fullWidth ? `0 0 ${width} ${height}` : null}
        preserveAspectRatio={fullWidth ? 'none' : ''}
      >
        {
          rectangles.map((r, i) => (
            <rect
              key={'shaped-skeleton-rect-' + i + r}
              className='shaped-skeleton-block-rect'
              x={r[0]}
              y={r[1]}
              width={r[2]}
              height={r[3]}
              rx={r[4] ?? radius}
              fill={fill}
            />
          ))
        }
      </svg>
    )
  }

export default ShapedSkeleton;