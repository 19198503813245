import React, { useEffect, useRef, useState } from "react";
import { InputAdornment } from "@mui/material";
import dayjs from "dayjs";
import Fuse from 'fuse.js';
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { PosImage, PosInput } from "apps/pos/Common/uiCore";
import CustomerOrdersData from "apps/pos/customerOrders/CustomerOrdersData";
import HandleLongData from "apps/pos/Common/HandleLongData";
import Skeleton from "../Skeleton";

import { useGetShipmentsFromOrderId } from "../CustomerStorage/hooks/useGetShipmentIdHook";
import {
  setIsApiLoading,
  setOrderId,
} from "apps/pos/pages/customerInfo/CustomerOrders/redux/customerOrder.slice";
import { useGetOrders } from "./hooks/useGetOrders";
import { RootState, PosResponseProps, } from "models";
import { PosFlowDataProp } from "constants/interfaces";
import { SearchIcon } from "constants/icons";
import { formatCurrency } from "utils/numberFormat";
import { DocumentSortBy } from "utils/data";
import { keys } from "utils/helper";
import { MMDDYYformat } from "apps/pos/constants";
import { PaymentTypeEnum } from "constants/enum";
import { getLocalStoraGeItem, scrollToSetting } from "utils/utils";
import { staticData, title } from "constants/constant";
import images from "constants/images";

import { arrowDownIndexGeneric, arrowResetGeneric, arrowUpIndexGeneric } from "sagas/pos/actions";

import "apps/pos/pages/customerInfo/CustomerDrafts/customerDrafts.scss";
import "./customerOrders.scss";


const CustomerOrders = ({ savedOrderFoundInfoRef }) => {
  const { orderHistoryData, getCompletedOrders, getOrderHistoryIsLoading } =
    useGetOrders();
  const { getShipmentIdFromOrderId, data, getShipmentIdLoading } =
    useGetShipmentsFromOrderId();

  const isApiLoading = [
    getOrderHistoryIsLoading,
    getShipmentIdLoading,
  ].includes(true);

  const dispatch = useDispatch();

  const { noOrdersFoundEmptyState, noOrdersFound } = title;
  const tableRowRef = useRef<React.Ref<HTMLTableRowElement>>();
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const {  selectedIndexGeneric } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace || {};
  const { customer } = posFlowData || {};
  const { isPosGeneric } = customer || {};
  const searchCustomersOrder = useRef<any>();
  const customersOrderModelRef = useRef<React.MutableRefObject<any>>();
  const [sortIndex, setSortIndex] = useState(0);
  const [customerOrdersDataModal, setCustomerOrdersDataModal] = useState(false);
  const [ordersData, setOrdersData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [doesHaveMore, setHaveMore] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOrdersData, setFilteredOrdersData] = useState([]);
  const [downPress, setDownPress] = useState(false);
  const [upPress, setUpPress] = useState(false);

  useEffect(() => {
    if (!isApiLoading) {
      const dataCopy = [...filteredOrdersData];
      if (sortIndex === 1) {
        dataCopy.sort((a, b) => {
          const firstDate = new Date(a.createdAt).getTime();
          const secondDate = new Date(b.createdAt).getTime();
          return (firstDate - secondDate);
        });
      }
      else{
        dataCopy.sort((a, b) => {
          const firstDate = new Date(a.createdAt).getTime();
          const secondDate = new Date(b.createdAt).getTime();
          return (secondDate - firstDate);
        });
      }
      setFilteredOrdersData(dataCopy)
    }

  }, [sortIndex, isApiLoading]);

  useEffect(()=>{
    if (document?.activeElement === searchCustomersOrder.current) {
      dispatch(arrowResetGeneric());
    }
  },[])

  useEffect(()=>{
    document.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedIndexGeneric])

  useEffect(() => {
    dispatch(setIsApiLoading(isApiLoading));
  }, [isApiLoading]);
  
  useEffect(() => {
    getCompletedOrders({
      stockWarehouseId,
      customerId: customer?.customerId,
      page: pageNumber,
      pageSize: 20,
    });
  }, []);

  useEffect(() => {
    if (orderHistoryData?.data?.length) {
      setOrdersData([...ordersData, ...orderHistoryData?.data]);
    }
  }, [orderHistoryData]);


  useEffect(() => {
    async function downPressHandler() {
      if (downPress) {
        scrollToSetting(selectedIndexGeneric);
        if (filteredOrdersData?.length) {
          await dispatch(arrowDownIndexGeneric(filteredOrdersData.length));
        }
        setDownPress(false);
      }
    }
    downPressHandler()
  }, [downPress]);

  useEffect(() => {
    async function upPressHandler() {
      if (upPress) {
        await dispatch(arrowUpIndexGeneric());
        if (selectedIndexGeneric > -1) {
          scrollToSetting(selectedIndexGeneric);
        }
        setUpPress(false);
      }
    }
    upPressHandler()
  }, [upPress]);

  const handleKeyPress = (e) => {
    const { keyCode } = e;
    const { downarrow, uparrow, enter,tab } = keys

    if (keyCode === downarrow && !customersOrderModelRef.current && !savedOrderFoundInfoRef.current) {
      setDownPress(true);
    }
    else if (keyCode === uparrow && !customersOrderModelRef.current && !savedOrderFoundInfoRef.current) {
      setUpPress(true);
    }
    if (keyCode === enter && !customersOrderModelRef.current){
      const element = document.querySelector('.Mui-focusVisible');
      if (element) {
        return
      }
      else if (selectedIndexGeneric > -1) {
        const { orderId } = filteredOrdersData[selectedIndexGeneric]
        handleOnRowClick(orderId)
      }
    }
    if (keyCode === tab) {
      dispatch(arrowResetGeneric())
    }
  };

  useEffect(() => {
    if (ordersData?.length) {
      setFilteredOrdersData(ordersData);

    }
  }, [ordersData]);


  const handleSearchChange = (e) => {
    const {
      target: { value },
    } = e;
    setSearchValue(value);
    dispatch(arrowResetGeneric());
    if (!ordersData.length) return ordersData;
    if (!value) return setFilteredOrdersData(ordersData);
    const mappedOrdersData = ordersData.map(order => ({
      ...order,
      paymentModeModified: PaymentTypeEnum[order.paymentMode]
    }));
    const searchConfig = {
      keys: ["orderId", "purchaseOrderNum", 'jobName', 'shipmentCount', 'finalGrandTotalAmt', 'paymentModeModified'],
      threshold: 0.2,

    };
    const finder = new Fuse(mappedOrdersData, searchConfig);
    setFilteredOrdersData(finder.search(value).map((item) => item.item));
  };

  // need to verify before remove
  // useEffect(() => {
  //   if (storageItemsData?.length) {
  //     setCustomerOrdersDataModal(true);
  //   }
  // }, [storageItemsData]);

  const handleScroll = () => {
    const lastScroll = ordersData?.length === orderHistoryData?.total;
    if (lastScroll) {
      setHaveMore(false);
    }
    if (ordersData?.length < orderHistoryData?.total) {
      setSearchValue("");
      setSortIndex(0)
      setPageNumber(pageNumber + 1);
      getCompletedOrders({
        stockWarehouseId,
        customerId: customer?.customerId,
        page: pageNumber + 1,
        pageSize: 20,
      });
    }
  };

  const handleSort = (index) => {
    setSortIndex(index)
    document.getElementById("dropdownMenuButton").focus();
  };

  const handleOnRowClick = (orderId) => {
    getShipmentIdFromOrderId({ stockWarehouseId, orderId });
    dispatch(setOrderId(orderId));
    dispatch(arrowResetGeneric());
    setCustomerOrdersDataModal(true);
  };

  return (
    <section className="document-main-section">
      <div className="document-container">
        <div className="input-with-add-button">
          <PosInput
            variant="filled"
            id="filled-basic"
            type="text"
            className="document-search-input search-input-focus"
            autoFocus
            placeholder={staticData.orderSearchPlaceholder}
            value={searchValue}
            onChange={handleSearchChange}
            inputRef={searchCustomersOrder}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {/* // Need For sort data */}
        <div className="dropdown txt-align sort-by-dropdown">
          <div className="relative-light-border"></div>
          <button
            className="sort fw-medium fs-14 text-dark btn btn-link dropdown-toggle sorting-dropdown-btn"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {`Sort By: ${DocumentSortBy[sortIndex]
            }`}
          </button>

          <div
            className="dropdown-menu selectList-drop"
            abyria-labelledby="dropdownMenuButton"
          >
            {DocumentSortBy.map((option, index) => (
              <button
                onClick={()=>handleSort(index)}
                className={`btn btn-link ${sortIndex === index ? "active" : ""
                  }`}
                key={index}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
        <div id="scrollContainer">
          <TableContainer
            component={Paper}
            className="customer-info-table-container"
          >
            {
              isApiLoading && !filteredOrdersData?.length ?<Skeleton count={5} /> :
            <InfiniteScroll
              className="pb-60"
              dataLength={ordersData?.length || 0}
              next={handleScroll}
              height={550}
              hasMore={doesHaveMore}
              loader={<div></div>}
              scrollThreshold={0.9}
            >
             
              <Table aria-label="simple table">
                {!!filteredOrdersData?.length ? (
                  <TableHead>
                    <TableRow>
                      <TableCell className="customer-info-table-title first-table-row">
                        DATE
                      </TableCell>
                      <TableCell className="customer-info-table-title second-table-row">
                        ID
                      </TableCell>
                      <TableCell className="customer-info-table-title third-table-row">
                        PO #
                      </TableCell>
                      <TableCell className="customer-info-table-title fourth-table-row">
                        JOB NAME
                      </TableCell>
                      {isPosGeneric && (
                        <TableCell className="customer-info-table-title fifth-new-table-row">
                          ORDERER
                        </TableCell>
                      )}
                      {/* <TableCell className="customer-info-table-title sixth-new-table-row">STATUS</TableCell> */}
                      <TableCell className="customer-info-table-title seventh-new-table-row">
                        SHIPMENTS
                      </TableCell>
                      <TableCell className="customer-info-table-title eigth-new-table-row">
                        PAID VIA
                      </TableCell>
                      <TableCell className="customer-info-table-title eigth-new-table-row">
                        TOTAL
                      </TableCell>
                      <TableCell
                        className="customer-info-table-title ninth-new-table-row"
                        align="right"
                      >
                        CREDIT
                      </TableCell>
                    </TableRow>
                  </TableHead>
                ) : (
                  <></>
                )}

                <TableBody>
                  {!!filteredOrdersData?.length ? (
                    filteredOrdersData?.map((row, index) => {
                      const {
                        id,
                        orderId,
                        purchaseOrderNum,
                        jobName,
                        paymentMode,
                        finalGrandTotalAmt,
                        createdAt,
                        customer,
                        shipmentCount,
                        customerCreditAmount,
                      } = row;
                      const { firstName, lastName } = customer || {};
                      const formattedCreditAmount = formatCurrency(customerCreditAmount);
                      return (
                        <TableRow
                          className={`customer-table-data-row cursor-pointer ${index === selectedIndexGeneric && !customersOrderModelRef.current ? "selectedRow" : ""}`}
                          key={`${id}-${index}`}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          onClick={() => handleOnRowClick(orderId)}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              index === selectedIndexGeneric && !customersOrderModelRef.current
                                ? "lightgray"
                                : "white",
                          }}
                          ref={tableRowRef[index]}
                          id={`tableRowRef${index}`}
                        >
                          <TableCell className="customer-table-row-data-styling">
                            <span>{dayjs(createdAt).format(MMDDYYformat)}</span>
                          </TableCell>
                          <TableCell className="customer-table-row-data-styling">
                            <span>{orderId}</span>
                          </TableCell>
                          <TableCell className="customer-table-row-data-styling">
                            <HandleLongData jobName={purchaseOrderNum} />
                          </TableCell>
                          <TableCell className="customer-table-row-data-styling">
                            <HandleLongData jobName={jobName} />
                          </TableCell>
                          {isPosGeneric && (
                            <TableCell className="customer-table-row-data-styling">
                              <span>{`${firstName} ${lastName}`}</span>
                            </TableCell>
                          )}
                          {/* <TableCell className="customer-table-row-data-styling">
                                                <span>
                                                    {posTransactionStatus}
                                                </span>
                                            </TableCell> */}
                          <TableCell className="customer-table-row-data-styling">
                            <span>{shipmentCount}</span>
                          </TableCell>
                          <TableCell className="customer-table-row-data-styling">
                            <span>{PaymentTypeEnum[paymentMode]}</span>
                          </TableCell>
                          <TableCell className="customer-table-row-data-styling">
                          <span>{`$${finalGrandTotalAmt}`}</span>
                          </TableCell>
                          <TableCell
                            className="customer-table-row-data-styling"
                            align="right"
                          >
                            {customerCreditAmount ? <span>{formattedCreditAmount}</span> : "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (                     
                        !searchValue ? (<div className="mt-5 text-center row justify-content-center">
                          <PosImage
                            src={images.noOrdersIcon}
                            height={170}
                            width={247}
                            alt="add product"
                          />
                          <span className="int-20-reg empty-state-fonts">{noOrdersFoundEmptyState}</span>
                        </div>) : <><div className="mt-3 d-flex justify-content-center"><span className="int-18-reg-bold">{noOrdersFound}</span></div></>
                  )}
                </TableBody>
              </Table>
            </InfiniteScroll>
              }
          </TableContainer>
        </div>
      </div>
      <CustomerOrdersData
        isLoading={getShipmentIdLoading}
        setCustomerOrdersDataModal={setCustomerOrdersDataModal}
        customerOrdersDataModal={customerOrdersDataModal}
        shipmentIdData={data}
        customersOrderModelRef={customersOrderModelRef}
      />
    </section>
  );
};
export default CustomerOrders;
