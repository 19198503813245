import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { sendMessageCall } from "apps/springboard/QuoteMessages/request";

export const useSendMessage = () => {
  const { mutate, isLoading, data, error, isSuccess } = useMutation(
    async (payload) => {
      return sendMessageCall(payload);
    }
  );

  const callSendMessage = useCallback(
    (payload) => {
      if (payload) {
        mutate(payload);
      }
    },
    [mutate]
  );

  return {
    callSendMessage,
    isLoading,
    data,
    error,
    isSendMessageSuccess: isSuccess,
  };
};
