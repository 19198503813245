import { useSelector } from "react-redux";

import { useStylesProductLayout } from "apps/product/helper/mui-style-helper";

import { PosButton } from "apps/pos/Common/uiCore";
import ProductSearchFilter from "apps/product/modules/productListingContainer/components/productListing/components/ProductFilterToogle";

import {
  ArrowDropDownIcon,
  PersonAddIcon,
  // DownloadIcon, need later
} from "constants/icons";
import { Paths } from "enums";
import { commonConstants , statusValue} from "apps/product/helper/commonConstants";

import { distributorData } from "sagas/distributor/selector";
import "./style.scss";
import { useState } from "react";

const ProductLayout = ({
  children,
  selectedLayoutType,
  setlayoutType,
  handleSearch,
  keyword,
  setKeyword,
  handleFilter,
  filter
}) => {
  const distributor = useSelector(distributorData)
  const classes = useStylesProductLayout();
  const getMode = localStorage.getItem("jumperMode");
  const [show , setShow] = useState(false);
  const { full } = statusValue;
  const pendingProduct = () => {
    return window.open(`${distributor?.httpsUrl}${Paths.pendingProductLink}`,commonConstants.target, commonConstants.noreferrer);
  }
  const regularProduct = () => {
    return window.open(`${distributor?.httpsUrl}${Paths.regularProductLink}`,commonConstants.target, commonConstants.noreferrer);
  }
  const rebateProduct = () => {
    return window.open(`${distributor?.httpsUrl}${Paths.rebateProductLink}`,commonConstants.target, commonConstants.noreferrer);
  }

  return (
    <div className={`layout-container ${classes.root}`}>
      <div className="back-button-container">
        <div className="header-container header-back-button" />
      </div>
      <div className="lable-export-new-product-button-container">
        <div className="header-container lable-button-div">
          <div className="label-div">{commonConstants.products}</div>
          <div>
            <div className="btn-div">
              {/* need later
              <PosButton
                variant="contained"
                color="secondary"
                className="btn"
                startIcon={<DownloadIcon />}
              >
                {commonConstants.export}
              </PosButton> */}
              {
                getMode === full ?
                  <PosButton
                    variant="contained" color="secondary"
                    button-is-loading
                    className={`return-successful-all-print-btn modal-select-default-btn print-btn picking-qty-btn mb-0 h-auto w-auto`}
                    onClick={() => {setShow(!show)}}
                    >
                    <div>
                      <div className="select-default-text w-100">
                        <div>
                          <PersonAddIcon />
                        </div>
                        <div className="default-schedule-heading w-100">
                          <span className="int-18-reg-bold">{commonConstants.newProduct}</span>
                        </div>
                        <div>
                          <ArrowDropDownIcon fontSize="small" />
                        </div>
                      </div>
                      <div className={`product-list-dropdown ${show ? 'd-block' : ''}`}>
                        <div className="print-dropdown-content" onClick={pendingProduct}>Pending Product</div>
                        <div className="print-dropdown-content" onClick={regularProduct}>Regular Product </div>
                        <div className="print-dropdown-content" onClick={rebateProduct}>Rebate Product</div>
                      </div>
                    </div>
                  </PosButton> : 
                  <PosButton
                    variant="contained"
                    color="primary"
                    className="btn"
                    onClick={pendingProduct}
                    startIcon={<PersonAddIcon />}
                  >
                    {commonConstants.addPendingProduct}
                  </PosButton>
              }

            </div>
          </div>
        </div>
        <ProductSearchFilter
          selectedLayoutType={selectedLayoutType}
          setlayoutType={setlayoutType}
          handleSearch={handleSearch}
          handleFilter={handleFilter}
          filter={filter}
          keyword={keyword}
          setKeyword={setKeyword}
        />
        {children}
      </div>
    </div>
  );
};

export default ProductLayout;
