import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ISnakbar {
  message: string;
  open: boolean
  actionText?: string;
}

const initialState: ISnakbar = {
    message: "",
    open: false,
    actionText: ""
};

export const snakbarSlice = createSlice({
  name: "snakbarReducer",
  initialState,
  reducers: {
    snakbarShow: (state, action: PayloadAction<{message: string; actionText?: string}>) => {
      state = {
        open: true,
        ...action.payload
      };
      return state
    },
    snakbarHide: (state) => {
      state = {
        ...state,
        open: false,
      };
      return state
    },
  },
});

// Action creators are generated for each case reducer function
export const {
    snakbarShow,
    snakbarHide
} = snakbarSlice.actions;

export default snakbarSlice.reducer;