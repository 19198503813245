import { makeStyles, Theme } from "@material-ui/core";

const useStyle = makeStyles<Theme>(() => ({
  maskInputContainer: {
    backgroundColor: "#f1f1f1",
    maxWidth: "232px",
    border: "none",
  },
}));

export default useStyle;
