import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

import { useGetCashReport } from "../hooks/useCashReport";

import { getLocalStoraGeItem } from "utils/utils";
import { openAndPrintPdf } from "utils/helper";
import { setIsApiLoading } from "../pages/customerInfo/CustomerOrders/redux/customerOrder.slice";
import { dateEnum } from "constants/staticData";

const CashDrawer = () => {
  const dispatch = useDispatch()

  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {};
  const date = dayjs().format(dateEnum.ymdFormat)
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const dtDate = queryParams.get('dt');
  const dfDate = queryParams.get('df');

  const payload = {
    stockWarehouseId,
    dt:dtDate ? dtDate : date,
    df:dfDate ? dfDate : date
    }

  const { cashReport, cashReportIsLoading } =
  useGetCashReport(payload);

  const printInvoice = (cashReport) => {
    openAndPrintPdf(cashReport, "_self")
  };

  useEffect(()=>{
    printInvoice(cashReport)
  },[cashReport])

  const isApiLoading = [
    cashReportIsLoading,
  ].includes(true);

  useEffect(() => {
    dispatch(setIsApiLoading(isApiLoading));
  }, [isApiLoading]);
  return (
    <></>
  )
}

export default CashDrawer