import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { getDeliveredOrderItemsUrl } from "./constantUrl";
import { stopLoading } from "utils/utils";

export const useGetDeliveredOrderItems = () => {
  
  const { mutate, isLoading, data, error, isSuccess } = useMutation(
    async ({shipmentId, deliveryId}: {shipmentId: number; deliveryId: number}) => {
      return getDeliveredOrderItemsUrl(shipmentId, deliveryId);
    },
    {
      retry: false,
      onSuccess: stopLoading,
      onError:stopLoading
    },
  );

  const getDeliveredOrderItems = useCallback(
    (shipmentId, deliveryId) => {
      mutate({shipmentId, deliveryId});
    },
    [mutate]
  );

  return {
    getDeliveredOrderItems,
    getDeliveredOrderItemsIsLoading: isLoading,
    deliveredItemData: data,
    error,
    isSuccess,
  };
};
