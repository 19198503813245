

import "@fontsource/inter";
import TablePagination from "@material-ui/core/TablePagination";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Container,
  Paper,
  PosHelmet,
  PosIconButton,
  PosImage,
  PosPageHeading,
} from "apps/pos/Common/uiCore";

import CustomRangeDateModal from "apps/pos/Common/CustomRangeDateModal";
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import { EnhancedTableBody } from "apps/pos/MovingAvgCostHistoryComponent/EnhancedTableBody";
import { EnhancedTableHead } from "apps/pos/MovingAvgCostHistoryComponent/EnhancedTableHead";
import MovingAvgSkeleton from "apps/pos/MovingAvgCostHistoryComponent/MovingAvgCostSkeleton";
import { TablePaginationActions } from "apps/pos/MovingAvgCostHistoryComponent/TablePaginationActions";

import { DateFormat } from "constants/enum";
import { ArrowBackRoundedIcon } from "constants/icons";
import images from "constants/images";
import { PosFlowDataProp } from "constants/interfaces";
import { dateEnum, minDate1970, movingAvgCostFilterOption } from "constants/staticData";
import { Paths } from "enums";
import { RootState } from "models";
import {
  addToCartDrawer,
  getMovingAvgCostHistoryRequest,
  stockByWarehouseStart,
} from "sagas/pos/actions";
import { getIsLoading, getMovingCostHistory, getMovingCostHistoryTotal, getProductsData } from "sagas/pos/selector";
import { getLocalStoraGeItem } from "utils/utils";
import "../pos/InvoiceHistory.scss";
import "../pos/MovingAvgCostHistory.scss";

const MovingAvgCostChangeHistory = () => {
  const { loadCartDetails } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const movingAvgCostHistory = useSelector(getMovingCostHistory)
  const movingAvgCostHistoryTotal = useSelector(getMovingCostHistoryTotal)
  const isLoading = useSelector(getIsLoading)
  const productsData = useSelector(getProductsData)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customDateRangeModal, setCustomDateRangeModal] = useState(false)
  const [fromDate, setFromDate] = useState(dayjs(minDate1970));
  const [toDate, setToDate] = useState(dayjs());
  const [customRangeSelected, setCustomRangeSelected] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productIdInfo = useParams();

  const { state, pathname } = location;
  const productInfo = state?.detail;
  const productName = productInfo?.productName;

  const isLeapFrongReport = (pathname === `${Paths.product}/${productIdInfo.id}/${Paths.reports}/${Paths.movingAvgCostChangeReport}`)
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('productName');
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace || {};
  const { mmddyyyy } = dateEnum || {};
  const { MM_DD_YYYY } = DateFormat

  useEffect(() => {
    const data = {
      stockWareHouseId: isLeapFrongReport ? 0 : stockWarehouseId,
      page: page + 1,
      size: rowsPerPage,
      startDate: fromDate,
      endDate: toDate,
      productId:productIdInfo.id
    };
    dispatch(getMovingAvgCostHistoryRequest(data));
  }, [page, rowsPerPage]);

  // start
  function stableSort(movingAvgCostHistoryInfo, comparator) {
    if (movingAvgCostHistory) {
      const stabilizedThis =
        movingAvgCostHistoryInfo && movingAvgCostHistoryInfo?.map((el, index) => [el, index]);
      stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return moment(b[0].takeInTime, MM_DD_YYYY).valueOf() - moment(a[0].takeInTime, MM_DD_YYYY).valueOf();
      });
      return stabilizedThis && stabilizedThis?.map((el) => el[0]);
    }
  }

  // end
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const { defaultIcon } = images;

  const backToCartPage = () => {
    const { cartId } = loadCartDetails
    const { productId } = productsData
    dispatch(stockByWarehouseStart([Number(productIdInfo.id)]));
    if (productId) {
      dispatch(addToCartDrawer(true))
    }
    navigate(`${Paths.posCart}/${cartId}`);
  }

  const resetPagination = () => {
    setPage(0)
    setRowsPerPage(10)
  }

  const setFilter = (type) => {
    setCustomRangeSelected(false)
    resetPagination()
    let startDt;
    let endDt;
    if (type == movingAvgCostFilterOption.allTime) {
      startDt = dayjs(minDate1970);
      endDt = dayjs();
    } else {
      startDt = fromDate;
      endDt = toDate;
    }
    const data = {
      stockWareHouseId:isLeapFrongReport ? 0 : stockWarehouseId,
      page: page + 1,
      size: rowsPerPage,
      startDate: startDt,
      endDate: endDt,
      productId:productIdInfo.id
    };  
    dispatch(getMovingAvgCostHistoryRequest(data));
    setFromDate(dayjs(startDt))
    setToDate(dayjs(endDt))
  }

  const handleUnCheckOfRadios = () => {
    const radioButtons = document.getElementsByName("dateRange") as NodeListOf<HTMLInputElement>;
    radioButtons.forEach((radio) => {
      radio.checked = false;
    });
  }

  return (
    <div className="history-main-section">
      <PosHelmet title={!isLeapFrongReport ? "POS" : `Product Detail - ${isLeapFrongReport ? paramValue : productName} - ${productIdInfo.id}`} metaName="Admin page" content="Admin page" />
      <section className="item-history-section">
        <div className={`item-history-container ${isLeapFrongReport ? "ml-90" : ""}`}>
          {!isLeapFrongReport ? <PosIconButton
            className="back-button pos-icon-back-btn"
            onClick={() => {
              backToCartPage()
            }}
            tabIndex={-1}
          >
            <ArrowBackRoundedIcon className="back-icon" />
          </PosIconButton> : null}
          <div className="item-history-title-container">
            <div className="title">
              <PosPageHeading heading="Moving Avg. Cost" />
              <PosPageHeading heading="Change History" grayHeading />
            </div>
            <div className="item-history-products-container">
              <div className="product-ledger-image">
                <PosImage
                  src={defaultIcon}
                  alt="product-icon"
                />
                <div className="product-ledger-name">
                  <div className="name">{isLeapFrongReport ? paramValue : productName}</div>
                  <div className="key">{productIdInfo.id}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Container maxWidth={false} className="table-main-container">
        <div className="days-group">
          <label className="days-list">
            <input type="radio" name="dateRange" value="allTime" onClick={() => { setFilter(movingAvgCostFilterOption.allTime) }} defaultChecked/>
            All Time
          </label>
          <div className={`days-list ${customRangeSelected ? "custom-range-selected" : ""}`} onClick={() => setCustomDateRangeModal((prev) => !prev)}><span className="d-flex">{customRangeSelected ? `${fromDate.format(mmddyyyy)} — ${toDate.format(mmddyyyy)} ` : "Select Date Range"}<MaterialIcon icon={"expand_more"} size="20" /></span></div>
        </div>
        <Paper className="invoice-history-table-section">
          <table
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            cellPadding='6' cellSpacing='0' border={0} width='100%'
            className="moving-history-table"
          >
            <colgroup>
              <col className="numeric-column" />
              <col />
              <col className="numeric-column" />
              <col className="numeric-column" />
              <col />
              <col className="numeric-column" />
              <col className="numeric-column" />
            </colgroup>
            <EnhancedTableHead />
            {isLoading ? (
              <>   {Array(3).fill("-").map((item, index) => (
                  <MovingAvgSkeleton key={item + index} numberOfColumns={8}/>
              ))}</>
            ) : (
              <EnhancedTableBody
                movingAvgCostHistory={movingAvgCostHistory}
                stableSort={stableSort}
              />
            )}
          </table>
          <TablePagination
            component="div"
            count={movingAvgCostHistoryTotal ? movingAvgCostHistoryTotal : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={[]}
          />
        </Paper>
      </Container>
      {customDateRangeModal ?
        <>
          <CustomRangeDateModal
            customDateRangeModal={customDateRangeModal}
            setCustomDateRangeModal={setCustomDateRangeModal}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            stockWarehouseId={stockWarehouseId}
            page={page}
            rowsPerPage={rowsPerPage}
            setCustomRangeSelected={setCustomRangeSelected}
            handleUnCheckOfRadios={handleUnCheckOfRadios}
            resetPagination={resetPagination}
            customRangeSelected={customRangeSelected}
            isLeapFrongReport={isLeapFrongReport}
            productId={productIdInfo.id}
          />
        </> : null}
    </div>
  );
};

export default MovingAvgCostChangeHistory;
