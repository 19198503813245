import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';

import { orderHistory } from './constantUrl';
import { stopLoading } from 'utils/utils';

export const useGetOrders = () => {
  const { mutate, isLoading, data } = useMutation(
    async ({stockWarehouseId, customerId, page, pageSize}: {stockWarehouseId: number; customerId: number; page: number; pageSize: number}) => {
      return orderHistory(stockWarehouseId, customerId, page, pageSize)
    },
    {onSuccess : stopLoading,
      onError : stopLoading
  });

  const getCompletedOrders = useCallback(
    ({stockWarehouseId, customerId, page, pageSize}) => {
      mutate({stockWarehouseId, customerId, page, pageSize})
    },[mutate]
  )

  return { getCompletedOrders, orderHistoryData: data?.data, getOrderHistoryIsLoading: isLoading };
};