import service from "./config";

import { authorizationToken } from "utils/utils";

let authTokenRequest;
export const getAuthToken = () => {
    const decodedData = authorizationToken("Authorization");

    const data = {
      personId: decodedData?.DefaultLoginId,
      refreshToken: decodedData?.RefreshToken,
      tenantId: decodedData?.TenantId,
      source: "SSO",
    };
  if (!authTokenRequest) {
    authTokenRequest= service.post("/auth/token/refresh", data)
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }
  return authTokenRequest;
}

function resetAuthTokenRequest() {
    authTokenRequest = null;
  }