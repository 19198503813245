import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Will remove 'any' once we get proper data from BE
export interface SingleProductCharacteristicsSliceI {
  getSingleProductCharacteristics: boolean;
  getSingleProductCharacteristicsData: any;
  getSingleProductCharacteristicsError: any;
}

const initialState: SingleProductCharacteristicsSliceI = {
  getSingleProductCharacteristics: false,
  getSingleProductCharacteristicsData: null,
  getSingleProductCharacteristicsError: null,
};

export const singleProductCharacteristicsSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    getSingleProductCharacteristicsStart: (state) => {
      state.getSingleProductCharacteristicsData = null;
      state.getSingleProductCharacteristics = true;
    },
    getSingleProductCharacteristicsSuccess: (state, action: PayloadAction<any>) => {
      state.getSingleProductCharacteristicsData = action.payload;
      state.getSingleProductCharacteristics = false;
    },
    getSingleProductCharacteristicsError: (state, action: PayloadAction<any>) => {
      state.getSingleProductCharacteristicsError = action.payload;
      state.getSingleProductCharacteristicsData = null;
      state.getSingleProductCharacteristics = false;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  getSingleProductCharacteristicsStart,
  getSingleProductCharacteristicsSuccess,
  getSingleProductCharacteristicsError
} = singleProductCharacteristicsSlice.actions;

export default singleProductCharacteristicsSlice.reducer;
