import { makeStyles } from "@material-ui/core/styles";

export enum UesrType {
    allUser = "ALL USERS",
    recent = "RECENT"
}

export const useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
    root: {
      position: "unset",
      "& .MuiTableHead-root": {
        position: "unset",
        color: "black",
      },
    },
    drawer: {
      "& .MuiDrawer-paper": {
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
      },
    },
  });

  export const scrollBehaviour : ScrollIntoViewOptions = {
    behavior: "smooth",
    block: "center",
    inline: "center",
  }

export const errorConstants ={
noSearchResult : "No search results found",
noTakerFound: "No taker found"
}