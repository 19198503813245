import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { TextField } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { ErrorMessage } from "apps/pos/formik/errorMessage";
import { UpdatedSeoProductDetailSchema } from "apps/pos/formik/validationSchema";
import { PosButton, PosInput } from "apps/pos/Common/uiCore";
import IconButton from 'apps/product/Modals/Elements/IconButton'

import { authorizationToken, setFocusTo } from "utils/utils";
import { labels } from "../../modules/productDetailsContainer/constants";

import useProductsDetailsHooks from "../../modules/productDetailsContainer/hooks/mutationHooks";
import { updateSeoProductStart } from "sagas/productsDetails/singleProductDetails.slice";

import "../ProductDetailsCommonModal.scss";
import "../ProductDetailsMetadataIdentifiersModals/ProductDetailsMetadataIdentifiersModals.scss";

const ProductDetailsMetadataSeoModals = ({
    open,
    onClose,
    focusText,
    getSingleProductData,
    updateSeoProduct,
    removeFocus
}) => {
    const dispatch = useDispatch();

    const { Hyperlink, Keywords, Folder_Path, Footnote, readonly, Back, SaveChanges, SEO } = labels;

    const { details, metadata } = getSingleProductData || {}
    const { productId } = details || {}
    const { seo } = metadata || {}
    const { footNote, hyperlink, keywords, folderPath } = seo || {}

    const {
        updateSeoProductsMutation,
    } = useProductsDetailsHooks();

    const handleChangeValue = (name , value , setFieldValue ) => {
        setFieldValue(name , value)
    }

    const calculateLines = (text) => {
        const lines = text.split('\n').length;
        return lines <= 5 ? lines : 5;
    };
    const handleClose = (resetForm) => () => {
        resetForm();
        onClose();
    }
    
    return (
        <Formik
        initialValues={{
            hyperlink,
            keywords,
            folderPath,
            footNote
        }}
        validationSchema={UpdatedSeoProductDetailSchema}
        enableReinitialize
        onSubmit={(values) => {
            const { DefaultLoginId } = authorizationToken("Authorization") || {};
            const { hyperlink , keywords , folderPath , footNote } = values
            const body = {
                productId,
                handlerId: DefaultLoginId,
                hyperlink,
                keywords,
                folderPath,
                footNote
            }
            dispatch(updateSeoProductStart())
            updateSeoProductsMutation.mutate(body);
        }}
        >
           {({ values, handleSubmit, resetForm, setFieldValue , dirty}) => {
                const { footNote, hyperlink, keywords, folderPath } = values
                return (
                    <>
                         <Dialog
                            className='overview-dialog'
                            open={open}
                            onClose={onClose}
                        >
                            <div className="dialog-header">
                                <h3>{SEO}</h3>
                                <IconButton
                                    classes='close-btn'
                                    secondary={true}
                                    title='Close'
                                    icon='close'
                                    onClick={handleClose(resetForm)}
                                />
                            </div>

                            <DialogContent>
                                <div className="product-details-notes-main-content-section table-select pt-0">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {Keywords}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <TextField
                                                id={Keywords}
                                                variant="filled"
                                                multiline
                                                name="keywords"
                                                value={keywords}
                                                onChange={(e) => {
                                                    handleChangeValue("keywords", e.target.value, setFieldValue)
                                                }}
                                                className="product-detail-search-input input-focus-styling scroll--input"
                                                rows={calculateLines(keywords)}
                                                fullWidth
                                                maxRows={5}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                inputRef={i => i && focusText === Keywords && setFocusTo(i,removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="keywords" />
                                </div>
                                <div className="product-details-notes-main-content-section">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {Footnote}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <TextField
                                                id={Footnote}
                                                variant="filled"
                                                multiline
                                                name="footNote"
                                                value={footNote}
                                                onChange={(e) => {
                                                    handleChangeValue("footNote", e.target.value, setFieldValue)
                                                }}
                                                className="product-detail-search-input input-focus-styling scroll--input"
                                                rows={calculateLines(footNote)}
                                                fullWidth
                                                maxRows={5}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                inputRef={i => i && focusText === Footnote && setFocusTo(i, removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="footNote" />
                                </div>
                                <div className="product-details-notes-main-content-section">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {Hyperlink}
                                        </span> &nbsp;
                                        <span className="text-color-gray">
                                            {readonly}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={Hyperlink}
                                                type="text"
                                                className="product-detail-search-input input-focus-styling"
                                                name="hyperlink"
                                                value={hyperlink}
                                                disabled
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                inputRef={i => i && focusText === Hyperlink && setFocusTo(i,removeFocus)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="product-details-notes-main-content-section">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {Folder_Path}
                                        </span> &nbsp;
                                        <span className="text-color-gray">
                                            {readonly}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={Folder_Path}
                                                type="text"
                                                className="product-detail-search-input input-focus-styling"
                                                value={folderPath}
                                                name="folderPath"
                                                disabled
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                inputRef={i => i && focusText === Folder_Path && setFocusTo(i,removeFocus)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                </DialogContent>
                                <DialogActions className="notes-model-footer">
                                <div className="notes-footer-wrapper d-flex notes-btn-div product-detail-modal-footer-spacing justify-content-end">
                                    <PosButton
                                        variant="contained"
                                        onClick={handleClose(resetForm)}
                                        className="back-button first-product-detail-btn"
                                    >
                                        {Back}
                                    </PosButton>
                                    <PosButton
                                        fontWeight
                                        className={`btn ml-2 second-product-detail-btn ${updateSeoProduct ? "button-accent button-is-loading-black" : ""}`}
                                        variant="contained"
                                        color="primary"
                                        disabled={updateSeoProduct || !dirty}
                                        onClick={() => handleSubmit()}
                                    >
                                        {SaveChanges}
                                    </PosButton>
                                </div>
                                </DialogActions>
                        </Dialog>
                    </>
                );
            }}
        </Formik>
    );
}

export default ProductDetailsMetadataSeoModals;