import { useCallback, useMemo } from "react";

import LocationItem from "./LocationItemComponenet";
import LocationPickerEmptyState from "./LocationPickerEmptyStateComponent";
import { title } from "constants/constant";

import { searchFor } from '../helper'
import { nonSaleable, saleable } from "utils/utils";

const RowsList = ({ onItemClick, selectedLocation, query, rows, error, isLoading, isNonSaleableHide}) => {

  const {row: locationRow} = selectedLocation;
  const {id: locationRowId} = locationRow;

  const getRow = useCallback((rowOption)=>{
    return searchFor(query, rowOption, "row") 
  },[query])
  
  const nonSaleableRows = useMemo(() => Array.isArray(rows) ? rows.filter(nonSaleable) : [], [rows]) 
  const saleableRows = useMemo(() => Array.isArray(rows) ? rows.filter(saleable) : [], [rows]) 

  if (isLoading) return <LocationPickerEmptyState />;

  return (
    error?.length ? 
      <span className="location-picker-error-message">Unable to load rows</span> : 
      <>
    {!getRow(saleableRows)?.length && !getRow(nonSaleableRows).length
      ?
      <span className="list-header">{title.noDataFound}</span>
      :
      <>
        {getRow(saleableRows)?.length ? (
          <span className="list-header">Saleable</span>
        ) : null}
        {getRow(saleableRows)?.map((row) => (
          <LocationItem
            key={row?.rowID ? row?.rowID : row?.stockRowID}
            item={{
              id: row?.rowID ? row?.rowID : row?.stockRowID,
              name: row.rowName,
            }}
            onClick={onItemClick}
            checked={locationRowId === row.rowID}
          />
        ))}

            {
              isNonSaleableHide !== true ? (
                getRow(nonSaleableRows).length ? (
                  <>
                    <span className="list-header">Non-Saleable</span>
                    {getRow(nonSaleableRows).map((row) => (
                      <LocationItem
                        key={row?.rowID ? row?.rowID : row?.stockRowID}
                        item={{
                          id: row?.rowID,
                          name: row.rowName,
                        }}
                        onClick={onItemClick}
                        checked={locationRowId === row.rowID}
                      />
                    ))}
                  </>
                ) : null
              ) : null
            }
      </>
    }
      </>
  );
};

export default RowsList;
