import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "./querykeys";
import { getOrdersDetails } from "../request";
import { useCallback, useState } from "react";

export const useGetOrder = () => {
  const [param, setParam] = useState();
  const { data, isFetching, isSuccess } = useQuery(
    [QueryKeys.GET_ORDER_DETAILS, param],
    async () => {
      if (param) {
        return getOrdersDetails(param);
      }
    }
  );

  const setOrderId = useCallback((data) => {
    if (data) {
      setParam(data);
    }
  }, []);

  return {
    setOrderId,
    orderData: data?.data ?? [],
    isOrderLoading: isFetching,
    isOrderSuccess: isSuccess,
  };
};
