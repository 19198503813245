import Cookies from "universal-cookie";
import { authorizationToken } from "utils/utils";

const cookies = new Cookies();

export const fetcher = (...args) => {
    const decodedData = authorizationToken("Authorization");
    const token = cookies.get("Authorization");

    const headers = {
        'Authorization': token,
        'x-tenant-id': decodedData?.TenantId,
    };

    return fetch(...args, { headers }).then(res => res.json());
};