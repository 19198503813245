import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Will remove 'any' once we get proper data from BE
export interface ProductDiscontinuedSliceI {
  isCreateProductsUserDiscontinued: boolean;
  createProductsUserDiscontinuedData: any;
  createProductsUserDiscontinuedError: any;
  isUpdateProductsPriceDiscontinued: boolean;
  UpdateProductsPriceDiscontinuedData: any;
  UpdateProductsPriceDiscontinuedError: any;
  isDeleteProductsDiscontinued: boolean;
  deleteProductsDiscontinuedData: any;
  deleteProductsDiscontinuedError: any;
}

const initialState: ProductDiscontinuedSliceI = {
    isCreateProductsUserDiscontinued: false,
    createProductsUserDiscontinuedData: null,
    createProductsUserDiscontinuedError: null,
    isUpdateProductsPriceDiscontinued: false,
    UpdateProductsPriceDiscontinuedData: null,
    UpdateProductsPriceDiscontinuedError: null,
    isDeleteProductsDiscontinued: false,
    deleteProductsDiscontinuedData: null,
    deleteProductsDiscontinuedError: null,
};

export const productsUserDiscontinuedSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    createProductsUserDiscontinuedStart: (state) => {
      state.createProductsUserDiscontinuedData = null;
      state.isCreateProductsUserDiscontinued = true;
    },
    createProductsUserDiscontinuedSuccess: (state, action: PayloadAction<any>) => {
      state.createProductsUserDiscontinuedData = action.payload;
      state.isCreateProductsUserDiscontinued = false;
    },
    createProductsUserDiscontinuedError: (state, action: PayloadAction<any>) => {
      state.createProductsUserDiscontinuedError = action.payload;
      state.createProductsUserDiscontinuedData = null;
      state.isCreateProductsUserDiscontinued = false;
    },
    updateProductsPriceDiscontinuedStart: (state) => {
      state.UpdateProductsPriceDiscontinuedData = null;
      state.isUpdateProductsPriceDiscontinued = true;
    },
    updateProductsPriceDiscontinuedSuccess: (state, action: PayloadAction<any>) => {
      state.UpdateProductsPriceDiscontinuedData = action.payload;
      state.isUpdateProductsPriceDiscontinued = false;
    },
    updateProductsPriceDiscontinuedError: (state, action: PayloadAction<any>) => {
      state.UpdateProductsPriceDiscontinuedError = action.payload;
      state.UpdateProductsPriceDiscontinuedData = null;
      state.isUpdateProductsPriceDiscontinued = false;
    },
    deleteProductsDiscontinuedStart: (state) => {
      state.deleteProductsDiscontinuedData = null;
      state.isDeleteProductsDiscontinued = true;
    },
    deleteProductsDiscontinuedSuccess: (state, action: PayloadAction<any>) => {
      state.deleteProductsDiscontinuedData = action.payload;
      state.isDeleteProductsDiscontinued = false;
    },
    deleteProductsDiscontinuedError: (state, action: PayloadAction<any>) => {
      state.deleteProductsDiscontinuedError = action.payload;
      state.deleteProductsDiscontinuedData = null;
      state.isDeleteProductsDiscontinued = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  createProductsUserDiscontinuedStart,
  createProductsUserDiscontinuedSuccess,
  createProductsUserDiscontinuedError,
  updateProductsPriceDiscontinuedStart,
  updateProductsPriceDiscontinuedSuccess,
  updateProductsPriceDiscontinuedError,
  deleteProductsDiscontinuedStart,
  deleteProductsDiscontinuedSuccess,
  deleteProductsDiscontinuedError
} = productsUserDiscontinuedSlice.actions;

export default productsUserDiscontinuedSlice.reducer;
