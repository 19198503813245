import serviceV2 from "services/config2";
import { endpoints } from "../endpoints";

export const getDocumentTypesData = () => {
  return serviceV2.get(endpoints.documentTypeData).then((response) => {
    return response.data;
  });
};

export const uploadDocument = (payload) => {
  return serviceV2
    .post(endpoints.uploadDocument, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      return response.data;
    });
};
