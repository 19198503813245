
import { PosButton, PosImage, PosInput } from "apps/pos/Common/uiCore";
import { motion, useMotionTemplate, useScroll, useSpring, useTransform } from 'framer-motion/dist/framer-motion';
import { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import images from "constants/images";
import { labels } from "../../modules/productDetailsContainer/constants";
import IconButton from 'apps/product/Modals/Elements/IconButton'
import { alertMessage } from 'constants/constant';
import useProductsDetailsHooks from 'apps/product/modules/productDetailsContainer/hooks/mutationHooks';
import { GetAllCharacteristicTypeListStart } from "sagas/productsDetails/productCharacteristics.slice";
import CharacteristicsSearch from "../CharacteristicsSearch";
import { setFocusTo } from "utils/utils"

import "./ProductDetailsCharacteristicsModals.scss";
import "../ProductDetailsAccessoriesModals/ProductDetailsAccessoriesModals.scss";
import "../ProductDetailsCommonModal.scss";

const ProductDetailsCharacteristicsModals = ({
    open,
    onClose,
    productCharacteristicsData,
    CharacteristicsRemoveById,
    removeAllCharacteristics,
    addCharacteristics,
    setProductCharacteristicsData,
    addNewCharacteristic,
    focusText,
    getProductCharacteristicsData,
    GetAllCharacteristicTypeList,
    isAddProductsCharacteristicsSuccess,
}) => {
    const { Back, SaveChanges, addCharacteristic, Characteristics } = labels;
    const { deleteDarkGrayIcon , deleteHoverDarkGrayIcon} = images;
    const { characteristicValue } = alertMessage
    const dispatch = useDispatch()
    const { GetAllCharacteristicTypeListMutation } = useProductsDetailsHooks();

    const [characteristicList, setcharacteristicList] = useState(GetAllCharacteristicTypeList)
    const [errorMessage, setErrorMessage] = useState("");
    const [Index, setIndex] = useState([]);
    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
        if (!GetAllCharacteristicTypeList?.length) {
            dispatch(GetAllCharacteristicTypeListStart());
            GetAllCharacteristicTypeListMutation.mutate();
        }
        setErrorMessage("")
    }, [])

    useEffect(() => {
        setcharacteristicList(GetAllCharacteristicTypeList)
    }, [GetAllCharacteristicTypeList])

    const handleClose = () => {
        setErrorMessage("");
        setIndex([]);
        onClose(open);
        setProductCharacteristicsData(getProductCharacteristicsData)
    }
    const handleChange = (characteristicTypeId, value, index) => {
        productCharacteristicsData?.filter((item) => {
            if (item?.productCharacteristicTypeID === characteristicTypeId) {
                const data = { ...item };
                data.productCharacteristicValue = value;
                const newItem = productCharacteristicsData.map(item => item.productCharacteristicTypeID !== characteristicTypeId ? item : data);
                setProductCharacteristicsData(newItem)
            }
        })
        if (value) {
            if (Index.length) {
                const indexNew = Index?.filter((i) => i !== index)
                setIndex(indexNew)
            } else {
                setIndex([])
            }
            setDisabled(false)
        }
        else {
            setErrorMessage(characteristicValue);
            if (Index.length) {
                setIndex((prevState => [...prevState, index]))
            } else {
                setIndex([index])
            }
            setDisabled(true)
        }
    };

    const handleChange2 = (characteristicTypeId, value) => {
        productCharacteristicsData?.filter((item) => {
            if (item?.productCharacteristicTypeID === characteristicTypeId) {
                const data = { ...item };
                data.productCharacteristicValue2 = value;
                const newItem = productCharacteristicsData.map(item => item.productCharacteristicTypeID !== characteristicTypeId ? item : data);
                setProductCharacteristicsData(newItem)
            }
        })
    };

    const handleSubmit = () => {
        addCharacteristics(productCharacteristicsData)
    }

    useEffect(() => {
        const value = productCharacteristicsData?.filter((item) => !item?.productCharacteristicValue)
        if (value?.length > 0) {
            setDisabled(true)
        } else if (getProductCharacteristicsData?.length === productCharacteristicsData?.length) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [productCharacteristicsData])

    const ref = useRef()

    useEffect(() => {
        if (open) {
            if (ref.current.open) return
            ref.current?.showModal()
        }
        else {
            ref.current?.close()
        }
    }, [open])

    useEffect(() => {
        ref.current?.addEventListener('close', onClose)

        return () => {
            ref.current?.removeEventListener('close', onClose)
        }
    }, [])
    useEffect(() => {
        if (!focusText) return
        const element = document.getElementById(focusText)
        setFocusTo(element)
    }, [focusText])

    const { scrollY } = useScroll({ container: ref })
    const yInitial = useTransform(scrollY, [200, 250], [-20, 0])
    const opacity = useTransform(scrollY, [200, 250], [0, 1])
    const borderColor = useTransform(scrollY, [0, 50], ['#fff', '#ddd'])
    const physics = {
        stiffness: 1000,
        damping: 100,
    }
    const y = useSpring(yInitial, physics)

    const scrollToTop = () => {
        ref.current?.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    return (
        <>
            <motion.div
                className="backdrop"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
            </motion.div>
            <motion.dialog
                className='notes-dialog-costs-price'
                ref={ref}
            >
                <motion.header
                    className="dialog-header"
                    style={{ borderBottom: useMotionTemplate`1px solid ${borderColor}` }}
                >
                    <h3>{Characteristics}</h3>
                    <IconButton
                        classes='close-btn'
                        secondary={true}
                        title='Close'
                        icon='close'
                        onClick={handleClose}
                    />

                    <motion.div
                        className='dialog-back-to-top'
                        style={{ opacity, y }}
                    >
                        <IconButton
                            classes='dialog-back-to-top-btn'
                            title='Back to top'
                            icon='expand_less'
                            tabIndex={-1}
                            onClick={scrollToTop}
                        />
                    </motion.div>
                </motion.header>

                <div className="dialog-body">
                    <CharacteristicsSearch characteristicList={characteristicList} addNewCharacteristic={addNewCharacteristic} placeHolder={addCharacteristic} />
                    {productCharacteristicsData?.length ? 
                        <div className='notes-manager-controls'>
                            <p className='notes-manager-count'>
                                {productCharacteristicsData?.length || 0} OF 20 ADDED
                            </p>
                            <button className='notes-manager-btn' onClick={removeAllCharacteristics}>Remove all</button>
                        </div>
                       : null}
                    {
                        productCharacteristicsData?.length ?
                            productCharacteristicsData?.map((option, index) => {
                                const { productCharacteristicTypeName, productCharacteristicValue, productCharacteristicValue2, productCharacteristicTypeID, productCharacteristicId } = option || {}
                                const isError = Index.includes(index);
                                return (
                                    <>
                                        <div className="product-details-notes-main-content-section">
                                            <div className="notes-added-user-name">
                                                <span>
                                                    {productCharacteristicTypeName}
                                                </span>
                                            </div>
                                            <div className="characteristics-main-info">
                                                <div className="w-100 d-flex product-detail-stock-main-div">
                                                    <div className="product-detail-search-input">
                                                        <PosInput
                                                            variant="filled"
                                                            type="text"
                                                            id={productCharacteristicTypeName}
                                                            name="productCharacteristicValue"
                                                            value={productCharacteristicValue}
                                                            className="product-detail-counter-sec first-counter-sec"
                                                            onChange={(e) => handleChange(productCharacteristicTypeID, e.target.value, index)}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="product-detail-search-input">
                                                        <PosInput
                                                            variant="filled"
                                                            id={productCharacteristicTypeName}
                                                            type="text"
                                                            name="productCharacteristicValue2"
                                                            value={productCharacteristicValue2}
                                                            className="product-detail-counter-sec second-counter-sec"
                                                            onChange={(e) => handleChange2(productCharacteristicTypeID, e.target.value)}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="product-detail-stock-delete-sec">
                                                        <div className="remove-characteristics-icon hover-with-delete-productDetail" onClick={CharacteristicsRemoveById(productCharacteristicId)}>
                                                            <PosImage
                                                                src={deleteDarkGrayIcon}
                                                                alt="delete-icon"
                                                                className="delet-order-img"
                                                            />
                                                            <PosImage
                                                                src={deleteHoverDarkGrayIcon}
                                                                alt="delete-icon"
                                                                className="delet-order-hover-img"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {(isError || !productCharacteristicValue) && <span className="validation-error-text">{isError ? errorMessage : !productCharacteristicValue ? characteristicValue : null}</span>}
                                            {/* </div> */}
                                        </div>
                                    </>)
                            })
                            : null}

                </div>

                <footer className="notes-model-footer">
                    <div className="notes-footer-wrapper d-flex notes-btn-div product-detail-modal-footer-spacing justify-content-end">
                        <PosButton
                            variant="contained"
                            onClick={handleClose}
                            className="back-button first-product-detail-btn"
                        >
                            {Back}
                        </PosButton>
                        <PosButton
                            fontWeight
                            className={`btn ml-2 second-product-detail-btn ${isAddProductsCharacteristicsSuccess ? "button-accent button-is-loading-black" : ""}`}
                            variant="contained"
                            color="primary"
                            disabled={isAddProductsCharacteristicsSuccess || disabled}
                            onClick={() => handleSubmit()}
                        >
                            {SaveChanges}
                        </PosButton>
                    </div>
                </footer>
            </motion.dialog>
        </>
    );
}

export default ProductDetailsCharacteristicsModals;