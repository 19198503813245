
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Keys } from 'constants/enum';  

interface PosDatePickerProps {
    // kept optional for now, will check everywhere and change
    value?: Dayjs | string;
    onChange?: any;
    isDefaultSet?:boolean;
    fromDate?:any;
    toDate?:any;
  }

const PosDatePicker = ({value, onChange,isDefaultSet,fromDate, toDate}: PosDatePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleFieldClick = (value) => {
    setIsOpen(value);
  };
  
  const handleKeyPressEvent = (event) => {
    if (event.key !== Keys.enter){
      event.preventDefault() 
    } 
  }
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            slotProps={{
              textField: {
                onKeyPress: (event) => handleKeyPressEvent(event) ,
                onClick: () => handleFieldClick(true),
              },
            }}
            open={isOpen}
            onClose={() => handleFieldClick(false)}
            value={value}
            onChange={onChange}
            format={"MM-DD-YYYY"}
            minDate={!isDefaultSet && fromDate ? dayjs(new Date(fromDate)) : !isDefaultSet && !fromDate ? dayjs(new Date()) : ''}
           maxDate={toDate ? dayjs(new Date(toDate)) : ''}
          />
        </LocalizationProvider>
      </>
    );
};
export default PosDatePicker;