import { useEffect } from "react";
import { useSelector } from "react-redux";

import { PosImage, PosPageHeading } from "apps/pos/Common/uiCore";
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";

import images from "constants/images";
import { CustomerInfoProp } from "constants/interfaces";
import { renamePersonData } from "utils/helper";
import { RootState } from "models";


const ShowAllPaymentOptions = ({
  currentCustomerDetail,
  saveCardPayment,
  setPaymentMethodWrapper,
  setCheckNumberError,
  isGenericCustomer,
  setCheckDisable,
  setCashDrawerError
}) => {
  const { creditHold } = images;
  useEffect(()=>{
    setCheckDisable(false);
  },[])

  const cashOption = () => {
    setPaymentMethodWrapper({
      setShippingCharge: false,
      setUnpaidDues: false,
      showAllPaymentOptions: false,
      cashOption: true,
      checkOption: false,
      cardOption: false,
      payLaterOption: false,
    })
  }

  const checkOption = () => {
    setPaymentMethodWrapper({
      setShippingCharge: false,
      setUnpaidDues: false,
      showAllPaymentOptions: false,
      cashOption: false,
      checkOption: true,
      cardOption: false,
      payLaterOption: false,
    })
    setCheckNumberError(false)
    setCashDrawerError(false)
    if (isGenericCustomer) {
      setCheckDisable(true); // to disable Complete Order button
    }
  }

  const payLaterOption = () => {
    setPaymentMethodWrapper({
      setShippingCharge: false,
      setUnpaidDues: false,
      showAllPaymentOptions: false,
      cashOption: false,
      checkOption: false,
      cardOption: false,
      payLaterOption: true,
    })
  }
  const customer = useSelector<
    RootState,
    CustomerInfoProp
  >((state) => state.persistReducer.posFlowData.customer);
  return(
    <div>
      <div className="pricing-modal-header mb-4">
        <PosPageHeading heading="Select Payment Method" blackHeading />
      </div>
      <main>
        <div className="pricing-frequently pricing-options-section">
          {
            (currentCustomerDetail?.creditCardOnlyFlag === 1 || currentCustomerDetail?.hasInternalNotes) ?
              <>
                <div
                  className="frequently-used-schedule"
                  onClick={cashOption}
                >
                  <h3 className="select-schedule-list d-flex gap-4">
                    <MaterialIcon icon="payments" size={"24"} classes="icon" />
                    Cash
                  </h3>
                  <div>
                    <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut">
                      1
                    </span>
                    <i className="fas fa-arrow-right right-icon" />
                  </div>
                </div>
                <div
                  className="frequently-used-schedule"
                  onClick={checkOption}
                >
                  <h3 className="select-schedule-list d-flex gap-4">
                    <MaterialIcon icon="price_check" size="24" classes="icon" />
                    Check
                  </h3>
                  <div>
                    <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut">
                      2
                    </span>
                    <i className="fas fa-arrow-right right-icon" />
                  </div>
                </div>
                <div
                  className="frequently-used-schedule"
                  onClick={saveCardPayment}
                >
                  <h3 className="select-schedule-list">
                    <div className="d-flex">
                      <div className="payment-icon-spacing" >
                        <MaterialIcon icon="credit_card" size={"24"} classes="icon" />
                      </div>
                      Card
                    </div>
                  </h3>
                  <div>
                    <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut">
                      3
                    </span>
                    <i className="fas fa-arrow-right right-icon" />
                  </div>
                </div>
              </>
              : <>
                <div
                  className="frequently-used-schedule"
                  onClick={cashOption}                  >
                  <h3 className="select-schedule-list d-flex gap-4">
                    <MaterialIcon icon="payments" size={"24"} classes="icon" />
                    Cash
                  </h3>
                  <div>
                    <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut">
                      1
                    </span>
                    <i className="fas fa-arrow-right right-icon" />
                  </div>
                </div>
                <div
                  className="frequently-used-schedule"
                  onClick={checkOption}
                >
                  <h3 className="select-schedule-list d-flex gap-4">
                    <MaterialIcon icon="price_check" size="24" classes="icon" />
                    Check
                  </h3>
                  <div>
                    <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut">
                      2
                    </span>
                    <i className="fas fa-arrow-right right-icon" />
                  </div>
                </div>
                <div
                  className="frequently-used-schedule"
                  onClick={saveCardPayment}
                >
                  <h3 className="select-schedule-list">
                    <div className="d-flex">
                      <div className="payment-icon-spacing" >
                        <MaterialIcon icon="credit_card" size={"24"} classes="icon" />
                      </div>
                      Card
                    </div>
                  </h3>
                  <div>
                    <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut">
                      3
                    </span>
                    <i className="fas fa-arrow-right right-icon" />
                  </div>
                </div>
                {!customer?.isPosGeneric && customer?.creditHoldFlag !== 1 ? (
                  <div
                    className="frequently-used-schedule"
                    onClick={payLaterOption}
                  >
                    <h3 className="select-schedule-list">
                      <div className="d-flex">
                        <div className="payment-icon-spacing">
                          <MaterialIcon icon="account_balance_wallet" size={"24"} classes="icon" />
                        </div>
                        Pay Later
                      </div>
                    </h3>
                    <div>
                      <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut">
                        4
                      </span>
                      <i className="fas fa-arrow-right right-icon" />
                    </div>
                  </div>
                ) : (
                  !customer?.isPosGeneric && (
                    <div className="disable-option">
                      <div className="facilit-phone-number jcs">
                        <h3 className="d-flex">
                          <div className="payment-icon-spacing">
                          <MaterialIcon icon="account_balance_wallet" size={"24"} classes="icon" />
                          </div>
                        </h3>
                        <div className="facility-box">
                          <h3 className="select-schedule-list">
                            Pay Later (disabled)
                          </h3>
                          <span className="int-14-reg">
                            {!customer?.isPosGeneric &&
                              `${renamePersonData(customer?.posCustCode, customer?.companyName)} is on Credit Hold`}
                            {!customer?.isPosGeneric && (
                              <PosImage className="icn-btn" src={creditHold} alt="credit-hold" />
                            )}
                          </span>
                        </div>
                        <i className="fas fa-arrow-right right-icon" />
                      </div>
                      <i className="fas fa-arrow-right right-icon" />
                    </div>
                  )
                )}
              </>
          }
        </div>
      </main>
    </div>
  )
}

export default ShowAllPaymentOptions