// Helps to get the first letters of each word. Eg : hello world :- HW

import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import moment from "moment";
import Cookies from "universal-cookie";


import { setIsApiLoading } from "apps/pos/pages/customerInfo/CustomerOrders/redux/customerOrder.slice";
import { fileExtension, fileType, upperCaseRegex } from "constants/constant";
import { ProductTypes } from "constants/enum";
import { store } from "index";
import { keys } from 'utils/helper';

const { typePriceScheduleVolume } = ProductTypes;

export const getAcronym = (string: string) => {
  const matches = string.match(upperCaseRegex);
  const acronym = matches.join("");
  return acronym.toUpperCase();
};

export function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.set(key, object[key]));
  return formData;
}

export function normalizeInput(value, previousValue) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )} ${currentValue.slice(6, 10)}`;
  }
}

export function toFixedTrunc(x, n) {
  const v = (typeof x === "string" ? x : x.toString()).split(".");
  if (n <= 0) return v[0];
  let f = v[1] || "";
  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
  while (f.length < n) f += "0";
  return `${v[0]}.${f}`;
}

export function scrollOnContent() {
  const element = document.getElementById("noScrollContent");

  if (element) {
    const cartElement = document.getElementById("cartDrawerContent");
    cartElement?.classList?.add("cart-drawer-styling");
  }
}

export const getLocalStoraGeItem = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const authorizationToken = (key: string) => {
  const cookies = new Cookies();
  const token = cookies.get(key);
  let decodedData = null;
  try {
    const decoded: { id: string } = jwt_decode(token);
    decodedData = JSON.parse(decoded?.id);
    return decodedData;
  } catch (error) {
    if (window.location.href.includes('logout')) {
      return;
    }
    if (window.location.pathname.includes("pos/cart")) {
      window.location.href = `${window.location.origin}/sso/logout?r=/app/pos/neworder`;
    } else {
      window.location.href = `${window.location.origin}/sso/logout?r=${window.location.pathname}`;
    }
    return;
  }
};

export const isoToFormat = (date: string, format: string) => {
  return date ? moment(date).format(format) : moment().format(format);
};

export const requiredMessage = (inputName: string) => {
  return `${inputName} is required.`;
};

export const objectToSingleDataArray: (
  arr: {
    value: string;
    label: string;
  }[]
) => {
  value: string;
  label: string;
}[] = (arr: { value: string; label: string }[]) => {
  const output = arr.map(({ value, label }) => ({
    value,
    label,
  }));

  return output;
};

export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const removeEmptySpaces = (stringVal) => {
  return /\s/g.test(stringVal);
};
export const changestringVal = (event) => {
  return removeEmptySpaces(event.target.value);
};

export const calculatePriceItemMuEach = (
  priceMu: number,
  itemMu: number,
  vendorPrice: number
) => {
  const unitMuPriceEach = vendorPrice / priceMu;
  const unitMuPrice = unitMuPriceEach * itemMu;
  return unitMuPrice;
};

export const decodeToken = () => {
  const cookies = new Cookies();
  const token = cookies.get("Authorization");
  const decoded: {
    id: string;
  } = jwt_decode(token || "");
  return decoded;
};

export const jsonParse = (obj) => {
  return JSON.parse(obj);
};

export const getProfit = (salesPrice, vendorPrice) => {
  const convertSalePrice = Number(salesPrice)
  const pricePercentage = convertSalePrice !== 0 ? (
    ((Number(salesPrice) - vendorPrice) / Number(salesPrice)) * 100
  ).toFixed(2) : "-100.00"
  if (isNaN(Number(pricePercentage))) {
    return 0;
  }
  return pricePercentage;
}

export const getMarkup = (salesPrice, vendorPrice) => {
  const convertSalePrice = Number(salesPrice)
  const pricePercentage = convertSalePrice !== 0 ? (
    ((salesPrice - vendorPrice) / vendorPrice) * 100
  ).toFixed(2) : "-100.00"
  return pricePercentage;
}

export const getIncrement = (unitPerCase, priceMU, itemMU) => {
  const increment = unitPerCase * priceMU / itemMU
  return increment;
}

export const getSalesPrice = (percentage, vendorPrice) => {
  const tempPricePercentage = (percentage > 0 && percentage < 100) ? (vendorPrice / (1 - (percentage / 100))).toFixed(5) : percentage === 100 ? vendorPrice.toFixed(5) : 0.00000;
  return tempPricePercentage;
}

export const priceToBeFixedConverter = (price: number | string, decimal = 2) => {
  return Number(price)?.toFixed(decimal);
};
//TODO : remove any later
export const productTypeConverter = (productItems: any[]) => {
  const data = Object.entries(ProductTypes).reduce((acc, entry) => {
    if (entry[1] === typePriceScheduleVolume) {
      acc[entry[0]] = productItems?.filter(
        (product) => product.type === typePriceScheduleVolume
      );
    } else {
      acc[entry[0]] = productItems?.find(
        (product) => product.type === entry[1]
      ) || {};
    }
    return acc;
  }, {});
  return data;
};

export const getStringifyData = (key) => {
  return JSON.stringify(key);
};

export const fileNameUtil = (extension: string) => {
  let name;
  switch (extension) {
    case fileExtension.pdf:
      name = fileType.pdf;
      break;
    case fileExtension.png:
      name = fileType.png;
      break;
    case fileExtension.jpeg:
      name = fileType.jpeg;
      break;
    case fileExtension.jpg:
      name = fileType.jpg;
      break;
    case fileExtension.gif:
      name = fileType.gif;
      break;
    case fileExtension.svg:
      name = fileType.svg;
      break;
    case fileExtension.doc:
      name = fileType.doc;
      break;
    case fileExtension.docx:
      name = fileType.docx;
      break;
    case fileExtension.txt:
      name = fileType.txt;
      break;
    case fileExtension.html:
      name = fileType.html;
      break;
    case fileExtension.psd:
      name = fileType.psd;
      break;
    case fileExtension.log:
      name = fileType.log;
      break;
    case fileExtension.odt:
      name = fileType.odt;
      break;
    case fileExtension.csv:
      name = fileType.csv;
      break;
    default:
      name = extension;
      break;
  }

  return name;
}
export const phoneNumberFormat = (intCode: string, areaCode: string, prefix: string, lineNum: string, extn: string) => {
  if (intCode && areaCode && prefix && lineNum) {
    const phnNumber = "+" + intCode + " " + areaCode.concat(" ", prefix, " ", lineNum);
    const data = phnNumber + (extn ? ("(" + extn + ")") : "");
    return data;
  } else {
    return "";
  }
}

export const preventArrowKeys = event => {
  // 38 is key code for up-arrow on keyboard.
  // 40 is key code for down-arrow on keyboard.
  if ([keys.uparrow, keys.downarrow].includes(event.keyCode)) {
    event.preventDefault();
    event.target.blur();
  }
};

export const showScrollCartPage = () => {
  const mainContentCollapsedElement = document.querySelector(".main-content-collapsed")
  if (mainContentCollapsedElement) {
    mainContentCollapsedElement?.classList?.remove("new-order-search-section");
  }
}

export const hideScrollCartPage = () => {
  const mainContentCollapsedElement = document.querySelector(".main-content-collapsed")
  if (mainContentCollapsedElement) {
    mainContentCollapsedElement?.classList?.add("new-order-search-section");
  }
}

export const blockInvalidCharForPhone = (e) =>
  ["e", "E", "+", "-", " ", "."].includes(e.key) && e.preventDefault();

export const blockInvalidInputForPickQty = (e) =>
  ["e", "E", "+", "-", " ", "."].includes(e.key) && e.preventDefault();

export const phoneNumberFormatNew = (intCode: string, areaCode: string, prefix: string, lineNum: string, extn: string, forNewCust: boolean) => {
  if (intCode && areaCode && prefix && lineNum) {
    let phnNumber = ""
    if (forNewCust) {
      phnNumber = `${intCode} (${areaCode}) ${prefix} ${lineNum} ${extn ? `ext: ${extn}` : ''}`
    } else {
      phnNumber = `+${intCode} (${areaCode}) ${prefix} ${lineNum} ${extn ? `ext: ${extn}` : ''}`
    }
    return phnNumber;
  } else {
    return "";
  }
}

export const replacePhoneNumber = (phoneNumber) => {
  return phoneNumber?.replace(/\s+/g, "")
}

export const noSearchData = (headerHeightCount, searchInputHeightCount) => {
  return headerHeightCount - searchInputHeightCount
}
export const withSearchData = (headerHeightCount, searchInputHeightCount, headerSecHeight) => {
  return headerHeightCount - searchInputHeightCount + (headerSecHeight ?? 56) + 57
}

export const getCurrentWorkspace = () => {
  return getLocalStoraGeItem("workspace");
};

export const returnFixedOrEmptyValue = (value, dollerSign = false) => {
  if (dollerSign) {
    return value ? `$${value}` : ""
  } else {
    return value ? value.toFixed(2) : ""
  }
}

export const showDot = (value: string, numberAfter: number) => {
  return value?.length > numberAfter ? value.substr(0, numberAfter - 1) + "..." : value
};

export const stopLoading = () => {
  store.dispatch(setIsApiLoading(false));
};

export const customBusinessDaysAdd = (startDate, businessDaysToAdd) => {
  let currentDate = dayjs(startDate);
  let addedDays = 0;

  while (addedDays < businessDaysToAdd) {
    currentDate = currentDate.add(1, 'day');
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      addedDays++;
    }
  }

  return currentDate;
};

export const saleable = (item) => {
  return !item.notForSellFlag;
};

export const nonSaleable = (item) => {
  return item.notForSellFlag;
};

export const roundToDecimalPlaces = (num, decimalPlaces = 2) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
}

export function toFixedFunction(num, precision) {
  return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}

export const roundToTwoDecimalPlaces = (number) => {
  const result = (toFixedFunction(number, 2))
  return result
}

export const setFocusTo = (element, removeFocuse) => {
  if (!element) return
  element.scrollIntoView()
  element.focus()
  removeFocuse && removeFocuse('');
}

export const convertToThreeDigits = (inputNumber: number) => {
  const absInput = Math.abs(inputNumber);
  let threeDigitNumber: any = absInput % 1000;

  if (threeDigitNumber < 100) {
    threeDigitNumber = threeDigitNumber.toString().padStart(3, '0');
  }

  threeDigitNumber = inputNumber < 0 ? -threeDigitNumber : threeDigitNumber;

  return threeDigitNumber;
}

export enum displayDateFormatValue {
  defaultFormat = 'MMM DD, YY',
  nonUSFormat = 'DD MMM, YYYY',
  usFormatSmall = 'MMM dd, yyyy',
  nonUSFormatSmall = 'dd MMM, yyyy',
  monthYearFormat = 'MMM yyyy',
  monthAndYearFormat = 'MMM YYYY',
  monthAndYearTimeFormat = "MMM DD, YY hh:mm A"
}

export const setDateWithFormat = (input, format = displayDateFormatValue.defaultFormat) => moment(input).format(format);
export const calculatePriceItemMuEachNew = (
  priceMu: number,
  itemMu: number,
  vendorPrice: number,
  qty: number
) => {
  const valueNew = calculatePriceItemMuEach(priceMu, itemMu, vendorPrice);
  const mutiplyValue = valueNew * 100;
  const extValue = (mutiplyValue * qty) / 100;
  return extValue;
};

export const scrollToSetting = (selectedIndex) => {
  const elementId = document?.getElementById(`tableRowRef${selectedIndex}`);
  elementId?.scrollIntoView({
    behavior: "auto",
    block: "center",
    inline: "end",
  });
};

export const handleFilterItems = ({ data, key, value }) => {
  return data?.filter(obj => obj[key] === value)
}

export const setScrollTo = (element) => {
  if (!element) return
  element.scrollIntoView()
}

export function formatFileSize(sizeInKB) {
  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let unitIndex = 0;
  let size = sizeInKB;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  // Round to 2 decimal places
  size = size.toFixed(2);

  // Remove trailing zeroes if any
  size = parseFloat(size) == parseInt(size) ? parseInt(size) : size;

  return size + ' ' + units[unitIndex];
}


export const getLatestHeader = () => {
  const cookies = new Cookies();

  const decodedData = authorizationToken("Authorization");
  const token = cookies.get("Authorization");

  const headers = {
    'Authorization': token,
    'x-tenant-id': decodedData?.TenantId,
  };
  return headers;
}