import { PosButton, PosIconButton, PosModel } from "apps/pos/Common/uiCore";
import { documentByModule } from "constants/enum";
import { AddIcon, ClearIcon } from "constants/icons";
import { useState } from "react";

import "apps/pos/pages/customerInfo/Documents/docReuploadAlert/docReuploadModal.scss";
import AddDocumentModal from "../addDocument/AddDocumentModal";


const DocReuploadModal = ({ open, closeModal, uplodedDocs }) => {
  const [openModel, setOpenModel] = useState(false);

  const handleAddNewDoc = () => {
    closeModal();
    setOpenModel(true);
  }

  return (
    <>
      <PosModel
        dialogClassName={"alert-modal-container"}
        open={open}
        onClose={() => closeModal()}
        disableBackgroundClose={true}
      >
        <div className="alert-modal-info-section">
          <div className="alert-modal-title">{uplodedDocs?.length > 1 ? `${uplodedDocs?.length} documents were removed` : `1 document was removed`}</div>
          <PosIconButton
            className="close-alert-modal-icon button-focus"
            onClick={() => closeModal()}
          >
            <ClearIcon />
          </PosIconButton>
        </div>
        <div>
          <div>
            {uplodedDocs?.length === 1 ? `1 attached document got removed due to page refresh. Please add the document again.` : `${uplodedDocs?.length} attached documents got removed due to page refresh. Please add the documents again.`}
          </div>
          <div>
            <div className="alert-modal-files-list-header">WHAT WAS REMOVED</div>
            {uplodedDocs ?
              (<>
                <div className="files-list-container">
                  {uplodedDocs.map((file) => {
                    const { path } = file?.originalFile || {};
                    const { documentSaveTypeName } = file || {};
                    return (
                      <div key={path} className="file-details-section d-flex justify-content-between">
                        <div className="doc-name">
                          {path}
                        </div>
                        <div>
                          {documentSaveTypeName}
                        </div>
                      </div>
                    );
                  }
                  )}
                </div>
              </>
              )
              : <></>}
          </div>
          <div className="edit-document-bottom-section">
            <div className="d-flex justify-content-end">
              <PosButton
                className="doc-reupload-modal-add-doc-btn btn"
                startIcon={<AddIcon />}
                onClick={handleAddNewDoc}
              >
                Add Document
              </PosButton>
            </div>
          </div>
        </div>
      </PosModel>
      {openModel ? (
        <AddDocumentModal
          openModel={openModel}
          setOpenModel={setOpenModel}
          taxExemption={false}
          documentBy={documentByModule.customerList}
        />
      ) : null}
    </>
  );
};

export default DocReuploadModal;
