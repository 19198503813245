import PosGrid from "apps/pos/Common/uiCore/posDrawer/posGrid";
import texts from "apps/pos/locales";

const OrderInfoCommonGrid = ({ label, value }) => {
  const { grandTotal, storeCredit } = texts;
  return (
    <>
      <PosGrid xs={12} sm={6} md={6} className="balance-left-info">
        <div>
          <span className={`int-16-reg ${label === grandTotal ? "fw-500" : "fw-400"} ${label === storeCredit ? "applied-store-credit" : ""}`}>
            {label}
          </span>
        </div>
      </PosGrid>
      <PosGrid xs={12} sm={6} md={6} className="grid-value">
        <span className={`int-16-reg ${label === grandTotal ? "fw-500" : "fw-400"} ${label === storeCredit ? "applied-store-credit" : ""}`}>
          {value}
        </span>
      </PosGrid>
    </>
  )
}

export default OrderInfoCommonGrid