import { useSelector } from "react-redux";
import { Box, Drawer } from "@material-ui/core";
import { drawerUseStyles } from "theme/GlobalStyles";
import "@fontsource/inter";

import { PosImage } from "apps/pos/Common/uiCore";
import PrintOptions from "../PrintOptions";
import SaveForLater from "../SaveForLater";
import TaxCalculation from "../TaxCalculation";
import Button from "../Common/Button/Button";

import images from "constants/images";
import { OrderDetailsDrawerProps, PaymentMethodProps } from "constants/interfaces";
import { RootState } from "models";

import "./drawers.scss";

const OrderDetailsDrawer = ({
  setPrintLoader,
  open,
  drawerClass,
  onClick,
  showPrintOptions,
  printInvoice,
  showSaveLater,
  setShowSaveForLater,
  saveForLaterAPI,
  setCartEmpty,
  setSuggestedProducts,
  setSuggestionListVisible,
  showTax,
  setCalculateTax,
  taxRate,
  setTaxRate,
  taxAmount,
  shipCharge,
  state,
  setState,
  setShowShipping,
  setShowTax,
  setShowSaveLater,
  setShowPrintOptions,
  setPrintLoaderWithTotal,
  cartDrawerRef,
  showPrices,
  priceCheck,
  markAsQuote,
  handlePriceCheck,
  handleMarkAsQuoteCheck,
  expiryDate,
  setExpiryDate,
  setCustomerInfo,
  orderInstructions,
  setOrderInstructions,
  shippingInstructions,
  setShippingInstructions,
  temporaryStorageLocation,
  setTemporaryStorageLocation,
  setIsToggleChange,
  taxExemptFlag,
}: OrderDetailsDrawerProps) => {
  const { processLoading } = useSelector<RootState, PaymentMethodProps>(
    (state) => state.pos
  );

  const classes = drawerUseStyles();

  const { closeIcon } = images;

  return (
    <Drawer
      anchor={"right"}
      open={open}
      className={`${drawerClass} ${classes.drawer} ${showTax ? classes.taxDrawer: ""} default-drawer-style no-cart-drawer main-drawer-classes drawer-scrollbar-style`}
      onClose={() => {
        !processLoading && setState({ ...state, right: false });
        setShowShipping(false);
        setShowTax(false);
        setShowSaveLater(false);
        setShowPrintOptions(false);
        setShowSaveForLater(false);
      }}
      ref={cartDrawerRef}
    >
      <Box sx={{ width: 600 }} role="presentation">
        <Button classes={`back-next-btn-small ${showTax ? "back-next-btn-small-tax": ""} responsive-back-btn`} onClick={onClick} tabIndex={-1}>
          <PosImage src={closeIcon} alt="back" />
        </Button>
        {showPrintOptions ? (
          <PrintOptions
            setPrintLoaderWithTotal={setPrintLoaderWithTotal}
            printInvoice={printInvoice}
            setPrintLoader={setPrintLoader}
            showPrices={showPrices}
            priceCheck={priceCheck}
            markAsQuote={markAsQuote}
            handlePriceCheck={handlePriceCheck}
            handleMarkAsQuoteCheck={handleMarkAsQuoteCheck}
            expiryDate={expiryDate}
            setExpiryDate={setExpiryDate}
            orderInstructions={orderInstructions}
            setOrderInstructions={setOrderInstructions}
            shippingInstructions={shippingInstructions}
            setShippingInstructions={setShippingInstructions}
            temporaryStorageLocation={temporaryStorageLocation}
            setTemporaryStorageLocation={setTemporaryStorageLocation}
          />
        ) : showSaveLater ? (
          <SaveForLater
            saveForLaterAPI={saveForLaterAPI}
            setCartEmpty={setCartEmpty}
            setSuggestionListVisible={setSuggestionListVisible}
            setSuggestedProducts={setSuggestedProducts}
            setShowSaveForLater={setShowSaveForLater}
          />
        ) : showTax ? (
          <TaxCalculation
          setIsToggleChange={setIsToggleChange}
            setTaxRate={setTaxRate}
            taxExemptFlag={taxExemptFlag}
            setCalculateTax={setCalculateTax}
            taxRate={taxRate}
            taxAmount={taxAmount}
            shipTaxAmount={(shipCharge * taxRate) / 100}
            setCustomerInfo={setCustomerInfo}
          />
        ) : (<></>)}
      </Box>
    </Drawer>
  );
};

export default OrderDetailsDrawer;
