import { Route, Routes } from "react-router-dom";

import LinearLoader from "components/LinearProgress";
import { PageNotFound } from "../apps/error";
import Layout from "../layouts/layout";
import { pageRoutes } from "./pageRoutes";


const RouteComponent = ({ setSelectedTheme, distributorId }) => {
  return (
    <div>
      <LinearLoader />
      <Layout themeSetter={setSelectedTheme}>
        <Routes>
          {pageRoutes.map(({ component: Component, path }) => {
            return (
              <Route
                key={path}
                path={path}
                element={<Component isDistributorId={distributorId} />}
              />
            );
          })}
          {/* Leapfrog Report routes  */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Layout>
    </div>
  );
};

export default RouteComponent;
