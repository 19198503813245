import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Will remove 'any' once we get proper data from BE
export interface ProductGroupsSliceI {
  isGetProductsUserGroups: boolean;
  getProductsUserGroupsData: any;
  getProductsUserGroupsError: any;
  isUpdateProductsPriceGroups: boolean;
  updateProductsPriceGroupsData: any;
  updateProductsPriceGroupsError: any;
  isGetHistoryForProductsGroups: boolean;
  getHistoryForProductsGroupsData: any;
  getHistoryForProductsGroupsError: any;
}

const initialState: ProductGroupsSliceI = {
    isGetProductsUserGroups: false,
    getProductsUserGroupsData: null,
    getProductsUserGroupsError: null,
    isUpdateProductsPriceGroups: false,
    updateProductsPriceGroupsData: null,
    updateProductsPriceGroupsError: null,
    isGetHistoryForProductsGroups: false,
    getHistoryForProductsGroupsData: null,
    getHistoryForProductsGroupsError: null,
};

export const productsUserGroupsSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    createProductsUserGroupsStart: (state) => {
      state.getProductsUserGroupsData = null;
      state.isGetProductsUserGroups = true;
    },
    createProductsUserGroupsSuccess: (state, action: PayloadAction<any>) => {
      state.getProductsUserGroupsData = action.payload;
      state.isGetProductsUserGroups = false;
    },
    createProductsUserGroupsError: (state, action: PayloadAction<any>) => {
      state.getProductsUserGroupsError = action.payload;
      state.getProductsUserGroupsData = null;
      state.isGetProductsUserGroups = false;
    },
    updateProductsPriceGroupsStart: (state) => {
      state.updateProductsPriceGroupsData = null;
      state.isUpdateProductsPriceGroups = true;
    },
    updateProductsPriceGroupsSuccess: (state, action: PayloadAction<any>) => {
      state.updateProductsPriceGroupsData = action.payload;
      state.isUpdateProductsPriceGroups = false;
    },
    updateProductsPriceGroupsError: (state, action: PayloadAction<any>) => {
      state.updateProductsPriceGroupsError = action.payload;
      state.updateProductsPriceGroupsData = null;
      state.isUpdateProductsPriceGroups = false;
    },
    getHistoryForProductsGroupsStart: (state) => {
      state.getHistoryForProductsGroupsData = null;
      state.isGetHistoryForProductsGroups = true;
    },
    getHistoryForProductsGroupsSuccess: (state, action: PayloadAction<any>) => {
      state.getHistoryForProductsGroupsData = action.payload;
      state.isGetHistoryForProductsGroups = false;
    },
    getHistoryForProductsGroupsError: (state, action: PayloadAction<any>) => {
      state.getHistoryForProductsGroupsError = action.payload;
      state.getHistoryForProductsGroupsData = null;
      state.isGetHistoryForProductsGroups = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  createProductsUserGroupsStart,
  createProductsUserGroupsSuccess,
  createProductsUserGroupsError,
  updateProductsPriceGroupsStart,
  updateProductsPriceGroupsSuccess,
  updateProductsPriceGroupsError,
  getHistoryForProductsGroupsStart,
  getHistoryForProductsGroupsSuccess,
  getHistoryForProductsGroupsError
} = productsUserGroupsSlice.actions;

export default productsUserGroupsSlice.reducer;
