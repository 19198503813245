import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";

import { updateEstDeliveryDateUrl } from "./constantUrl";
import { stopLoading } from "utils/utils";

export const useUpdateEstDeliveryDate = () => {
  
  const { mutate, isLoading, data, error, isSuccess: isEstDeliveryDateSuccess } = useMutation(
    async ({
      shipmentID,
      estimatedDeliveryTime,
      shipmentInStorageID,
    }: {
      shipmentID: number;
      estimatedDeliveryTime: string;
      shipmentInStorageID: number;
    }) => {
      return updateEstDeliveryDateUrl({
        shipmentID,
        estimatedDeliveryTime,
        shipmentInStorageID,
      });
    },
    {onSuccess : stopLoading,
      onError : stopLoading
});

  const updateEstDeliveryDate = useCallback(
    ({
      shipmentID,
      estimatedDeliveryTime,
      shipmentInStorageID,
    }: {
      shipmentID: number;
      estimatedDeliveryTime: string;
      shipmentInStorageID: number;
    }) => {
      mutate({ shipmentID, estimatedDeliveryTime, shipmentInStorageID });
    },
    [mutate]
  );

  return {
    updateEstDeliveryDate,
    isLoading,
    data,
    error,
    isEstDeliveryDateSuccess,
  };
};
