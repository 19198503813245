import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import { Button } from "../../../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import classicEditor from "@ckeditor/ckeditor5-build-classic";

import { trampolineSettingsRequest } from "../../../sagas/setting/actions";
import { RootState } from "../../../models";
import { trampolineSettingsDetailsInfo } from "../../../models/setting";
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function TrampolineSettings({distributorId}) {
  const [trampolineSettingsDetailsData, setTrampolineSettingsDetailsData] = useState<trampolineSettingsDetailsInfo>();
  const [error] = useState("");

  const { trampolineSettingsDetails } = useSelector<RootState, any>((state) => state.setting);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(trampolineSettingsRequest(distributorId));
  },[]);

  useEffect(() => {
    setTrampolineSettingsDetailsData(trampolineSettingsDetails);
  }, [trampolineSettingsDetails]);
  

  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Trampoline Settings</div>
        <div className="devider2px" />
        <form id="addSignatureForm">
          <div className="fs-14 fw-500 text-black mb-2">Terms and conditions</div>
          <div className="trampoline-editor">
            <CKEditor
              editor={classicEditor}
              data={trampolineSettingsDetailsData?.terms}
              onChange={(_, editor) => {
                editor.getData();
              }}
            />
          </div>
          <br></br>
          <div className="fs-14 fw-500 text-black mb-2">About us</div>
          <div className="trampoline-editor">
            <CKEditor
              editor={classicEditor}
              data={trampolineSettingsDetailsData?.aboutUs}
              onChange={(_, editor) => {
                editor.getData();
              }}
            />
          </div>
          <br></br>
          <div className="fs-14 fw-500 text-black mb-2">Privacy Policy</div>
          <div className="trampoline-editor">
            <CKEditor
              editor={classicEditor}
              data={trampolineSettingsDetailsData?.privacy?.title}
              onChange={(_, editor) => {
                editor.getData();
              }}
            />
          </div>
          <br></br>
          <div className="fs-14 fw-500 text-black mb-2">Featured category notes (optional)</div>
          <div className="trampoline-editor">
            <CKEditor
              editor={classicEditor}
              data={trampolineSettingsDetailsData?.featuredCategoryNotes}
              onChange={(_, editor) => {
                editor.getData();
              }}
            />
          </div>
          <br></br>
          <div className="fs-14 fw-500 text-black mb-2">Error Pages</div>
          <div className="trampoline-editor">
            <CKEditor
              editor={classicEditor}
              data={error}
              onChange={(_, editor) => {
                editor.getData();
              }}
            />
          </div>
          <br></br>
          <div className="fs-14 fw-500 text-black mb-2">SEO </div>
          <div className="trampoline-editor">
            <CKEditor
              editor={classicEditor}
              data={trampolineSettingsDetailsData?.seo?.metaDescription}
              onChange={(_, editor) => {
                editor.getData();
              }}
            />
          </div>
        </form>
        <div className="devider1px" />

        <Button
          type={"submit"}
          className="manage-btn"
          data-bs-toggle="modal"
          data-bs-target="#jumper_modal"
        >
          <span className="manage-button-txt">save changes</span>
        </Button>
      </div>
    </PerfectScrollbar>
  );
}
