import { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import { Button } from "../../../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";
import delete_gray from "../../springboard/images/icons/delete_outline_gray.svg";
import delete_black from "../../springboard/images/icons/delete.svg";
import { Distributor, RootState } from "../../../models";
import { logoRequest } from "../../../sagas/setting/actions";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const HeaderLogoStyled = styled.img`
  height: 5rem !important;
  width: 70% !important;
  align-self: center;
`;

export function Logo({distributorId}) {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logoRequest(distributorId));
  }, []);
  
  const [hover, setHover] = useState(false);
  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );

  const uploadLogoContainer = (title) => {
    return (
      <div className="mt-5">
        <div className="mb-3">
        <span className="fs-16 text-black fw-500">{title}</span></div>
        <div className="imagecontainer w-100">
          <HeaderLogoStyled
            style={{ alignSelf: "center", marginTop: 30, marginLeft: 60 }}
            src={`${distributor?.cloudConfig?.distributorCloudFileStorageUrl}images/logo/${distributor?.logos[0]?.defaultLogo}`}
            alt={""}
          />
        </div>

        <div className="d-flex align-items-center justify-content-between">
          <Button
            type={"submit"}
            className="manage-btn mt-4"
            data-bs-toggle="modal"
            data-bs-target="#jumper_modal"
            buttonStyle="light"
          >
            <span className="manage-lightbutton-txt">change</span>
          </Button>
          {hover ? (
            <div
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => {
                setHover(false);
              }}
              style={{ marginTop: 40, paddingLeft: 5 }}
              className="pointer text-center avatar rounded-circle bg-gray-200"
            >
              <img src={delete_black} alt="" className="delete-img" />
            </div>
          ) : (
            <img
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => {
                setHover(false);
              }}
              style={{ marginTop: 40 }}
              src={delete_gray}
              alt=""
              className="delete-img"
            />
          )}
        </div>
        <div className="dimensions">Recommended dimensions; 240x32</div>
      </div>
    );
  };

  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Logo</div>
        <div className="devider2px" />
        {uploadLogoContainer("Default Logo")}
        {uploadLogoContainer("Header Logo")}
        {uploadLogoContainer("Small Logo")}
        {uploadLogoContainer("Medium Logo")}
        {uploadLogoContainer("Large Logo")}
        {uploadLogoContainer("Logo on Documents")}
        {uploadLogoContainer("Logo with Phone Num")}
        <div className="devider1px" />

        <Button
          type={"submit"}
          className="manage-btn"
          data-bs-toggle="modal"
          data-bs-target="#jumper_modal"
        >
          <span className="manage-button-txt">save changes</span>
        </Button>
      </div>
    </PerfectScrollbar>
  );
}
