import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    modal: {
        maxWidth: "90%",
        marginTop: "2%",
        marginLeft: "5%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[6],
        position: "relative",
        width: "650px",
        borderRadius: "20px",
        maxHeight: "80vh",
        overflowY: "hidden",
    },
    headingMain: {
        display: "flex",
        paddingBottom: "25px",
    },
    heading: {
        width: "280px",
        height: "30px",
        marginTop: "10px",
        marginLeft: "10px",
        fontWeight: "bold",
        fontSize: "24px",
    },
    banned: {
        backgroundColor: "#FEF4E5",
    },
    span: {
        marginLeft: "10px",
        marginTop: "10px",
        marginBottom: "10px",
        padding: "10px",
        fontSize: "16px",
    },
    border: {
        borderBottom: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: "#DDDDDD",
    },
}));

export default useStyles;