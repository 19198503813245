import { TextareaAutosize } from "@material-ui/core";
import { Grid } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import _ from "lodash";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReturnCartNewModalProps } from "./type";

import {
  PosButton,
  PosIconButton,
  PosImage,
  PosInput,
  PosModel,
  PosSelect,
  PosTypography,
} from "apps/pos/Common/uiCore";
import { ErrorMessage } from "apps/pos/formik/errorMessage";
import { useAllRowsData } from "apps/pos/UnitsAvailable/AdjustStock/queryHooks/useAllRowsData";
import ReturnToGroup from "components/ReturnTo/ReturnToGroup";

import { addReturnCartSchema } from "apps/pos/formik/validationSchema";
import texts from "apps/pos/locales";
import { newReturnLine } from "components/ReturnTo/ReturnToGroup/ReturnToGroup";
import { alertMessage, noStartEndSpaceRegEx, numberRegex, title } from "constants/constant";
import { Numbers, paymentType } from "constants/enum";
import {
  AddIcon,
  CheckIcon,
  ClearIcon,
  CloseIcon,
  DeleteOutlineRoundedIcon,
} from "constants/icons";
import images from "constants/images";
import { ILocationProps, ReturnFlowDataMainProps } from "constants/interfaces";
import { PosResponseProps, RootState } from "models";
import { getLocalStoraGeItem } from "utils/utils";

import {
  handlingFeeDataInfo,
  restockingFeeDataInfo,
  setRefundMethodCheckData,
  setReturnFlowData,
  shippingFeeDataInfo,
} from "sagas/persistReducer/actions";
import {
  addReturnDataModalBtnAction,
  finalArrAction,
  removedArrAllData,
} from "sagas/returns/actions";

import "./returnCartModal.scss";

const ReturnCartNewModal = ({
  open,
  onClose,
  selectRowReturnData,
  selectProduct,
  cartEditModalData,
  removeReturnedProduct,
  setProductAdded,
  setIsProductAdded,
  editWithCartPage,
  isAddToReturnCartDisabled,
  setIsAddBtnDisabled
}: ReturnCartNewModalProps) => {
  const dispatch = useDispatch();
  const { inactive, notAvailable } = texts
  const { stockWarehouseData } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );

  const {
    returnTo: selectedReturnTo,
    thumbImg,
    manufacturerName,
    productName,
    manufacturerCode,
    returnableQty,
    productId,
    mfrReturnPolicy,
    qtyInStorage: selectedRowReturnToQtyInStorage,
    activeFlag,
    orderNote,
    shippingNote,
    // May need later
    // customerId,
    // orderId,
    // shipmentId,
    // shipmentInStorageID
  } = selectRowReturnData || {}
  const { returnReason, addReturnDataModalBtn, removedArrData, arr } =
    useSelector<RootState, ReturnFlowDataMainProps>(
      (state) => state.returnReducer
    );

  const { returnFlowData } = useSelector<RootState, ReturnFlowDataMainProps>(
    (state) => state.persistReducer
  );
  const { addedReturnedProduct } = returnFlowData || {};

  const { zero } = Numbers
  const currentWorkspace = getLocalStoraGeItem("workspace");

  const [totalSum, setTotalSum] = useState(false);
  const [totalQty, setTotalQty] = useState(0);
  const [noQtyAdded, setNoQtyAdded] = useState(false);

  const { } = useAllRowsData(); // fetch all rows data

  const selectedReason =
    returnReason?.length &&
    returnReason.find((item) => {
      return item?.orderRmaReasonID === selectRowReturnData?.reason;
    });

  const emptyObject = (object) => {
    for (const name in object) {
      if (object.hasOwnProperty(name)) {
        delete object[name];
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      returnQty: cartEditModalData
        ? selectRowReturnData?.returnQty
          ? selectRowReturnData?.returnQty
          : selectRowReturnData?.returnQty === null
            ? 0
            : 0
        : null,
      manufacturerReturnPolicy: cartEditModalData
        ? selectRowReturnData?.manufacturerReturnPolicy
        : selectRowReturnData?.mfrReturnPolicy || "",
      reason: cartEditModalData ? selectedReason?.orderRmaReasonID : "",
      replaceQty: cartEditModalData
        ? selectRowReturnData?.replaceQty
          ? selectRowReturnData?.replaceQty
          : selectRowReturnData?.replaceQty === null
            ? 0
            : 0
        : null,
      additionalQty: cartEditModalData
        ? selectRowReturnData?.additionalQty
        : null,
      returnTo:
        selectRowReturnData?.returnTo?.length && cartEditModalData
          ? selectRowReturnData.returnTo
          : ([] as ILocationProps[]),
      qtyInStorage: selectedRowReturnToQtyInStorage
        ? selectedRowReturnToQtyInStorage
        : 0,
      overShipmentQty: cartEditModalData
        ? selectRowReturnData?.overShipmentQty
          ? selectRowReturnData?.overShipmentQty
          : selectRowReturnData?.overShipmentQty === null
            ? 0
            : 0
        : null,
    },
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: addReturnCartSchema,
    onSubmit: (values) => {
      if (Number(totalQty) > Number(selectRowReturnData?.returnableQty)) {
        setTotalSum(true);
      }
      else if (!findNoAnyQtyData) {
        setNoQtyAdded(true)
      }
      else {
        if (values?.reason) {
          dispatch(addReturnDataModalBtnAction(true));
        }
        const conditionCheck = cartEditModalData ? "Edit" : "Add";
        if (!totalSum) {
          switch (conditionCheck) {
            case "Edit":
              editReturnCartAction(values);
              break;
            case "Add":
              addToReturnCartAction(values);
              break;
            default:
            // code block
          }
        }
      }
    },
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    errors,
    setErrors,
    resetForm,
    submitCount,
  } = formik;
  const {
    manufacturerReturnPolicy,
    returnQty,
    replaceQty,
    overShipmentQty,
    reason,
    returnTo,
    additionalQty,
    qtyInStorage,
  } = values;

  const addToReturnCartAction = (values) => {
    const value = values;
    const findExactProduct = returnTotalQty()

    value["replaceQty"] = values.replaceQty - (findExactProduct ? findExactProduct["replaceQty"] : 0);
    value["returnQty"] = values.returnQty - (findExactProduct ? findExactProduct["returnQty"] : 0);

    const data = {
      ...returnFlowData,
      returnCartData: {
        AddToReturnCartValues: { ...value, ...selectRowReturnData },
        productMainInformation: selectProduct,
      },
    };

    dispatch(setReturnFlowData(data));
    setProductAdded(true);
    onClose();
  };

  const editReturnCartAction = (values) => {

    if (values?.replaceQty === "") {
      setFieldValue("replaceQty", 0);
    }
    if (values?.returnQty === "") {
      setFieldValue("returnQty", 0);
    }
    if (values?.overShipmentQty === "") {
      setFieldValue("overShipmentQty", 0);
    }

    const originalObject = selectRowReturnData;
    const newObject = values;
    const findExactProduct = returnTotalQty()

    newObject["replaceQty"] = values.replaceQty - (findExactProduct ? findExactProduct["replaceQty"] : 0);
    newObject["returnQty"] = values.returnQty - (findExactProduct ? findExactProduct["returnQty"] : 0);


    const keys = Object.keys(newObject);
    keys.map((data) => {
      originalObject[data] = newObject[data];
    });
    const data = {
      ...returnFlowData,
      returnCartData: {
        AddToReturnCartValues: originalObject,
        productMainInformation: selectProduct,
      },
    };

    // if (!Boolean(values?.returnQty + values?.replaceQty)) { //Need later
    const { orderId } = originalObject || {};
    dispatch(
      shippingFeeDataInfo({
        orderId,
        shippingFeeChange: 0,
      })
    );
    dispatch(
      restockingFeeDataInfo({
        orderId,
        restockingFee: 0,
        restockingRate: 0,
      })
    );
    dispatch(
      handlingFeeDataInfo({
        orderId,
        handlingFee: 0,
      })
    );
    // }

    dispatch(setReturnFlowData(data));
    onClose();
    dispatch(addReturnDataModalBtnAction(false));
  };

  const fieldValidation = (e, fieldName) => {
    const inputValue = e.target.value.replace(/^0+/, "");
    if (Number(inputValue) > 0) {
      const checkValue =
        numberRegex.test(inputValue) &&
        !inputValue.includes("  ") &&
        inputValue.charAt(0) !== " " &&
        inputValue.charAt(0) !== ".";
      if (inputValue.charAt(0) === ".") {
        setFieldValue(
          fieldName,
          inputValue?.replace(inputValue.charAt(0), "0.")
        );
      }
      if (checkValue) {
        setFieldValue(fieldName, inputValue);
      }
    } else {
      setFieldValue(fieldName, "");
    }
  };

  const ProductCountOnLocation = stockWarehouseData?.length ? (
    stockWarehouseData
      ?.find((item) => item?.productId === productId)
      ?.stockWarehouses?.find((warehouse) => {
        if (
          warehouse?.stockWarehouseId === currentWorkspace?.stockWarehouseId
        ) {
          return warehouse;
        }
      })
  ) : (
    <></>
  );

  useEffect(() => {
    if (removedArrData) {
      dispatch(finalArrAction([]));
    }
  }, [removedArrData]);

  useEffect(() => {
    if (addReturnDataModalBtn && !cartEditModalData && returnQty + replaceQty + overShipmentQty) {
      // merging array with object
      const mergedObject = { ...values, ...selectRowReturnData };

      // Combine and ensure unique entries by 'id'
      const combinedUniqueArray = [...arr, mergedObject].filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.id === item.id)
      );

      dispatch(finalArrAction(combinedUniqueArray));


      dispatch(
        finalArrAction(combinedUniqueArray)
      );
      const addedReturnProductArray = {
        addedReturnedProduct: combinedUniqueArray,
      };
      const sameOrderIdData = _.groupBy(addedReturnProductArray.addedReturnedProduct, "orderId");
      const refundMethodCheckData = _.map(sameOrderIdData, (order) => {
        return { orderId: order[0].orderId, toggle: order[0].paidVia === paymentType.customerStoreCredit }
      })
      dispatch(setRefundMethodCheckData(refundMethodCheckData))
      dispatch(setReturnFlowData(addedReturnProductArray));
      dispatch(removedArrAllData(false));
      resetForm();
    }
  }, [
    addReturnDataModalBtn,
    selectRowReturnData,
    !cartEditModalData,
    returnQty,
    replaceQty,
    overShipmentQty
  ]);

  const findNoLocationData = returnTo?.every((item) => item?.row?.id && item?.shelf?.id && item?.unit?.id)
  const findNoAnyQtyData = returnTo?.every((item) => item?.replaceQty || item?.returnQty || item?.overShipmentQty)

  const noEditInfo = () => {
    if (!cartEditModalData) {
      resetForm({});
      setFieldValue("returnQty", "");
      setFieldValue(
        "manufacturerReturnPolicy",
        mfrReturnPolicy || ""
      );
      setFieldValue("reason", "");
      setFieldValue("replaceQty", "");
      setFieldValue("additionalQty", "");
      setFieldValue("overShipmentQty", "");
      setFieldValue("returnTo", [newReturnLine()]);
    }
  };

  const manufacturePolicyOnChange = (e) => {
    if (e.target.value === "" || noStartEndSpaceRegEx.test(e.target.value)) {
      setFieldValue("manufacturerReturnPolicy", e.target.value);
    }
  };

  const handelCancelInfo = () => {
    setErrors({});
    onClose();
    setFieldValue(
      "returnQty",
      cartEditModalData
        ? selectRowReturnData?.returnQty
          ? selectRowReturnData?.returnQty
          : 0
        : selectRowReturnData?.returnQty
    );
    setFieldValue(
      "overShipmentQty",
      cartEditModalData
        ? selectRowReturnData?.overShipmentQty
          ? selectRowReturnData?.overShipmentQty
          : 0
        : selectRowReturnData?.overShipmentQty
    );
    setFieldValue(
      "manufacturerReturnPolicy",
      selectRowReturnData?.manufacturerReturnPolicy
    );
    setFieldValue("reason", selectedReason?.orderRmaReasonID);
    setFieldValue(
      "replaceQty",
      cartEditModalData
        ? selectRowReturnData?.replaceQty
          ? selectRowReturnData?.replaceQty
          : 0
        : selectRowReturnData?.replaceQty
    );
    setFieldValue("additionalQty", selectRowReturnData?.additionalQty);
    setFieldValue("returnTo", selectRowReturnData.returnTo);
    !editWithCartPage && setIsProductAdded(false);
  };

  const { defaultIcon, cautionIcon } = images;
  useEffect(() => {
    if (open) {
      emptyObject(errors);
      JSON.stringify(errors, null, 4);
    }
  }, [open]);

  const productModalOnClose = () => {
    handelCancelInfo();
    noEditInfo();
    setTotalSum(false);
    setNoQtyAdded(false);
    setIsAddBtnDisabled?.(false);
    !editWithCartPage && setIsProductAdded(false);
  };

  const disableAddReturnToButton = useMemo(() => {
    setTotalQty(returnQty + replaceQty);
    setTotalSum(returnQty + replaceQty > returnableQty);
    return (returnQty + replaceQty < returnableQty) && (findNoLocationData);
  }, [returnQty, replaceQty, findNoLocationData]);

  const returnTotalQty = () => {
    return addedReturnedProduct?.length > 0 &&
      addedReturnedProduct?.reduce((acc, item) => {
        if (
          item?.productId === selectRowReturnData?.productId &&
          item?.invoiceId === selectRowReturnData?.invoiceId &&
          item?.orderId === selectRowReturnData?.orderId &&
          item?.lineId === selectRowReturnData?.lineId &&
          item?.id !== selectRowReturnData?.id
        ) {
          if (!acc) {
            acc = { ...item, returnQty: 0, replaceQty: 0 };
          }
          acc.returnQty += item.returnQty;
          acc.replaceQty += item.replaceQty;
        }
        return acc;
      }, null);
  }
  useEffect(() => {

    const totalReturnQuantity = returnTo.reduce(
      (total, obj) => Number(obj.returnQty) + Number(total),
      0
    );
    const totalReplaceQuantity = returnTo?.reduce(
      (total, obj) => Number(obj.replaceQty) + Number(total),
      0
    );
    const findExactProduct = returnTotalQty();
    const qtyReplace = totalReplaceQuantity + (findExactProduct ? findExactProduct["replaceQty"] : 0);
    const qtyReturn = totalReturnQuantity + (findExactProduct ? findExactProduct["returnQty"] : 0);

    const totalOverShipmentQuantity = returnTo?.reduce(
      (total, obj) => Number(obj.overShipmentQty) + Number(total),
      0
    );
    setFieldValue("returnQty", qtyReturn);
    setFieldValue("replaceQty", qtyReplace);
    setFieldValue("overShipmentQty", totalOverShipmentQuantity);

    if (findNoAnyQtyData) {
      setNoQtyAdded(false)
    }
  }, [returnTo]);

  const {
    availableQtyTitle,
    returnableQtyTitle,
    totalReturnableQtyTitle,
    orderNotesTitle,
    shippingNotesTitle,
    totalReplacementQuantity,
    inStorage,
    additionalQtyTitle,
  } = title;
  const { returnProductErrorMessage } = alertMessage
  return open ? (
    <>
      <FormikProvider value={formik}>
        <Form>
          <PosModel
            open={open}
            onClose={productModalOnClose}
            dialogClassName={`${cartEditModalData ? "" : "return-add-cart-main-modal"
              } return-cart-info-modal-main`}
            dialogFooter={
              <>
                {cartEditModalData ? (
                  <>
                    <div>
                      <PosButton
                        variant="contained"
                        className="modal-remove-btn"
                        onClick={() => {
                          removeReturnedProduct(
                            selectRowReturnData?.orderId?.toString(),
                            selectRowReturnData?.productId,
                            selectRowReturnData?.lineId
                          );
                          !editWithCartPage && setIsProductAdded(false);
                        }}
                        startIcon={<DeleteOutlineRoundedIcon />}
                        fontWeight
                      >
                        Remove
                      </PosButton>
                    </div>
                    <div className="d-flex">
                      <PosButton
                        type="button"
                        variant="contained"
                        className="mr-2 modal-remove-btn"
                        onClick={handelCancelInfo}
                        startIcon={<ClearIcon />}
                        fontWeight
                      >
                        Cancel
                      </PosButton>
                      <PosButton
                        variant="contained"
                        color="primary"
                        className={`return-save-changes-modal-btn ${totalSum ? "add-to-return-cart-button-error" : ""
                          }`}
                        onClick={() => {
                          handleSubmit();
                        }}
                        startIcon={<CheckIcon />}
                        fontWeight
                      >
                        Save Changes
                      </PosButton>
                    </div>
                  </>
                ) : (
                  <>
                    {isAddToReturnCartDisabled ? <span className="w-39">{returnProductErrorMessage}</span> : null}
                    <PosButton
                      variant="contained"
                      color="primary"
                      className={`add-return-cart-btn ${totalSum ? "add-to-return-cart-button-error" : ""}`}
                      disabled={isAddToReturnCartDisabled}
                      onClick={() => {
                        handleSubmit();
                      }}
                      startIcon={<AddIcon />}
                      fontWeight
                    >
                      Add to Returns Cart
                    </PosButton>
                  </>
                )}
              </>
            }
          >
            <section className="return-cart-info-modal-section">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  lg={5}
                  xl={5}
                  className="return-cart-product-section"
                >
                  <PosImage
                    src={thumbImg ?? defaultIcon}
                    alt="product-img"
                    className="return-cart-product-img"
                  />
                  <PosTypography
                    title={manufacturerName}
                    variant="caption"
                    className="return-manufacturer-name"
                  />
                  <PosTypography
                    title={productName}
                    variant="subtitle1"
                    className="return-product-name"
                  />
                  <PosTypography
                    title={manufacturerCode}
                    variant="subtitle2"
                    className="return-manufacturer-code"
                  />
                  {activeFlag === zero && <div className="activeFlag-status">
                    <div className={`indicator`}>
                      <span className={`indicator-style indicator-dot mr-2`} />
                      <span className="text-red">{inactive}</span>
                    </div>
                    {notAvailable}
                  </div>
                  }

                  <div className="return-cart-info return-cart-info-margin-bottom available-replacement-block">
                    <div className="return-cart-info-section">
                      <PosTypography
                        title={availableQtyTitle}
                        variant="subtitle2"
                        className="return-avl-qty-name"
                      />
                      <PosTypography
                        title={String(
                          ProductCountOnLocation?.unitQtyAvailable ?? ""
                        )}
                        variant="subtitle1"
                        className="return-returnable-qty"
                      />

                    </div>
                    <div>
                      <PosTypography
                        title={returnableQtyTitle}
                        variant="subtitle2"
                        className="return-returnable-qty-name"
                      />
                      <PosTypography
                        title={String(returnableQty)}
                        variant="subtitle1"
                        className="return-returnable-qty"
                      />

                    </div>

                  </div>


                  <div className={`return-cart-info return-cart-info-margin-bottom ${qtyInStorage > 0 ? 'return-cart-info-margin-bottom' : ''}`}>
                    <div className="return-cart-info-section">
                      <PosTypography
                        title={totalReturnableQtyTitle}
                        variant="subtitle2"
                        className="return-returnable-qty-name"
                      />
                      <PosTypography
                        title={String(returnQty || 0)}
                        variant="subtitle1"
                        className="return-returnable-qty"
                      />
                    </div>
                    <div>
                      <PosTypography
                        title={totalReplacementQuantity}
                        variant="subtitle2"
                        className="return-returnable-qty-name"
                      />
                      <PosTypography
                        title={String(replaceQty || 0)}
                        variant="subtitle1"
                        className="return-returnable-qty"
                      />
                    </div>
                  </div>

                  {qtyInStorage > 0 ? <div className="return-cart-info return-cart-info-margin-bottom">
                    <div className="return-cart-info-section">
                      <PosTypography
                        title={inStorage}
                        variant="subtitle2"
                        className="return-returnable-qty-name"
                      />
                      <PosTypography
                        title={String(qtyInStorage || 0)}
                        variant="subtitle1"
                        className="return-returnable-qty"
                      />
                    </div>
                    {/* May need later */}
                    {/* <div className="return-cart-info-deliver-btn">
                      <PosButton
                        className="h-auto w-auto font-weight-600"
                        variant="contained"
                        onClick={() => {
                          dispatch(setEditStorageDetailItem({
                            shipmentID: shipmentId,
                            orderID: orderId,
                            shipmentInStorageID

                          }))
                          dispatch(setPosFlowData({
                            customer: {
                              customerId
                            }
                          }));
                          window.open(`${Paths.subStorageItems}?isReturn=true`, '_blank', 'noreferrer')
                        }}
                      >
                        Deliver
                      </PosButton>
                    </div> */}
                  </div> : <></>}

                  {orderNote ?
                    <div className={`${shippingNote ? "order-notes-section" : ""}`}>
                      <PosTypography
                        title={orderNotesTitle}
                        variant="subtitle2"
                        className="return-returnable-qty-name"
                      />
                      <PosTypography
                        title={orderNote}
                        className="return-returnable-shipping-notes"
                      />
                    </div> : <></>}

                  {shippingNote ?
                    <div>
                      <PosTypography
                        title={shippingNotesTitle}
                        variant="subtitle2"
                        className="return-returnable-qty-name"
                      />
                      <PosTypography
                        title={shippingNote}
                        className="return-returnable-shipping-notes"
                      />
                    </div> : <></>}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  xl={7}
                  className="return-cart-qty-section"
                >
                  <div className="search-customer-header">
                    <PosIconButton
                      onClick={() => {
                        handelCancelInfo();
                        noEditInfo();
                        !editWithCartPage && setIsProductAdded(false);
                        setIsAddBtnDisabled?.(false);
                      }}
                      color="primary"
                      className="model-header-close-button"
                    >
                      <CloseIcon className="close-icon" />
                    </PosIconButton>
                  </div>
                  <PosTypography
                    title={title.reasonTitle}
                    variant="subtitle1"
                    className="return-qty-label"
                  />
                  <PosSelect
                    isSearch
                    isClassName
                    value={reason}
                    onChange={(e) => {
                      setFieldValue("reason", e.target.value);
                    }}
                    name="reason"
                    className="return-reason-select"
                    options={
                      returnReason?.length &&
                      returnReason?.map((item) => {
                        const { orderRmaReasonName, orderRmaReasonID } = item;
                        return {
                          label: orderRmaReasonName,
                          value: orderRmaReasonID,
                        };
                      })
                    }
                  />
                  {
                    reason === 6 ?
                      <div className="return-qty-foot-note">
                        <PosImage
                          src={cautionIcon}
                          className="mr-1"
                          alt="caution"
                        />
                        <span>Returned items for “Over shipped than ordered to customer” are not qualified for credit</span>
                      </div>
                      : null
                  }
                  {errors.reason && !values.reason && (
                    <>
                      <span className="custome-error-color">
                        {errors.reason}
                      </span>
                      <br />
                    </>
                  )}

                  <>
                    <PosTypography
                      title={title.returnToTitle}
                      variant="subtitle1"
                      className="return-qty-label"
                    />
                    <ReturnToGroup
                      setLocationData={(returnTo) => {
                        setFieldValue("returnTo", returnTo);
                      }}
                      activeFlag={activeFlag}
                      cartEditModalData={cartEditModalData}
                      editReturnTo={selectedReturnTo ? selectedReturnTo : returnTo}
                      isReturnCart={true}
                      disabled={!disableAddReturnToButton}
                      tabValue='return-cart'
                      reason={Number(values.reason)}
                    />
                    {(Number(returnQty) + Number(replaceQty) == 0 && reason !== 6) &&
                      submitCount > 0 ? (
                      <div>
                        <span className="custome-error-color">
                          {activeFlag !== zero ? 'Return quantity and Replace quantity both must not be empty.' : 'Return quantity must not be empty.'}
                        </span>
                      </div>
                    ) : null}
                    {reason !== 6 && totalSum ? (
                      <div className="return-qty-foot-note">
                        <PosImage
                          src={cautionIcon}
                          className="mr-1"
                          alt="caution"
                        />
                        <span>Total cannot be more than Returnable Qty.</span>
                      </div>
                    ) : null}
                  </>

                  {/* May needed 
                    {(errors?.returnTo as  FormikErrors<ILocationProps>[])?.[0]?.row?.id && (
                      <div className="custome-error-color">
                        {(errors?.returnTo as  FormikErrors<ILocationProps>[])?.[0]?.row?.id}
                      </div>
                  )} */}
                  {(submitCount && !findNoLocationData) ? (
                    <div>
                      <span className="custome-error-color">
                        Please Select Return To Location
                      </span>
                    </div>
                  ) : null}
                  {noQtyAdded ? (
                    <div>
                      <span className="custome-error-color">
                        Please Add Quantity Data
                      </span>
                    </div>
                  ) : null}

                  {activeFlag !== zero &&
                    <Fragment>
                      <PosTypography
                        title={additionalQtyTitle}
                        variant="subtitle1"
                        className="return-qty-label"
                      />
                      <PosInput
                        variant="filled"
                        id="standard-basic"
                        InputProps={{ disableUnderline: true }}
                        className="return-qty-input mb-2"
                        name="additionalQty"
                        onChange={(e) => fieldValidation(e, "additionalQty")}
                        value={additionalQty}
                      />
                      <div className="return-qty-foot-note">
                        <span>
                          A POS draft order will be created for this. You can
                          process it after this return, or search for the customer
                          to process anytime later
                        </span>
                      </div>
                    </Fragment>
                  }
                  <PosTypography
                    title={title.manufacturerPolicy}
                    variant="subtitle1"
                    className="return-qty-label"
                  />
                  <TextareaAutosize
                    minRows={1}
                    id="standard-basic"
                    className="auto-resize-bg-text-field"
                    name="manufacturerReturnPolicy"
                    onChange={(e) => manufacturePolicyOnChange(e)}
                    value={manufacturerReturnPolicy}
                  />
                  <ErrorMessage name="manufacturerReturnPolicy" />
                </Grid>
              </Grid>
            </section>
          </PosModel>
        </Form>
      </FormikProvider>
    </>
  ) : null;
};
export default ReturnCartNewModal;
