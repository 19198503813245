import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import { useFormik } from "formik";

import { PosModel, PosSelect } from "apps/pos/Common/uiCore";
import texts from "apps/springboard/locales";
import { IconButton } from "../SBItems/elements";
import Button from "components/SPButton/Button";
import { ButtonContainerSBbuttons } from "components";

import { springBoardText } from "constants/constant";

import { UpdateModalContent } from "sagas/modal/actions";
import { assignToSalesperson } from "services";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";

interface SalespersonProps {
    assignedDate: string;
    salespersonName: string;
    salespersonData: any[];
    jobQuoteID: number;
    handlerID?: number;
    salespersonID?: number;
    removeEstimatorFn?: ({ jobQuoteID, handlerID }: any) => void;
    open: boolean;
    handleCloseModel: () => void;
    handleChangeSuccess: () => void
}

export const SalespersonForm = ({
    assignedDate,
    salespersonName,
    jobQuoteID,
    salespersonID,
    handleCloseModel,
    open,
    salespersonData,
    handleChangeSuccess
}: SalespersonProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const { currentSalesperson , newSalesperson : newSalesPersonLabel , changeSalesperson , assignedOn } = springBoardText;
    const {
        values,
        setFieldValue,
        handleSubmit,
        resetForm,
        dirty,
    } = useFormik({
        initialValues: {
            newSalesperson: `${salespersonID}`
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const { newSalesperson } = values;
            setIsLoading(true)
            assignToSalesperson(jobQuoteID, parseInt(newSalesperson)).then(() => {
                handleCloseModel();
                handleChangeSuccess();
                dispatch(snakbarShow({ message: texts.salespersonUpdated }));
                setIsLoading(false);
                resetForm();
            });
        },
    });
    const { newSalesperson } = values;

    const cancelForm = () => {
        dispatch(
            UpdateModalContent({
                children: null,
                isOpen: false,
                fixedModal: false,
                removePaddingBottom: false,
                noMaxWidth: false,
            })
        );
        handleCloseModel();
    }

    return (
        <Fragment>
            <PosModel
                open={open}
                onClose={handleCloseModel}
                className={`set-customer-code-modal`}
                dialogClassName={`set-customer-code-modal-container`}
                dialogHeader={
                    <div className="modal-header pl-4">
                        <span className="modal-title">
                            <div className="pt-2"><h3 className="f20-Inter-500-sab mb-0 mb-0">{changeSalesperson}
                            </h3></div>
                        </span>
                        <IconButton
                            name='close'
                            className="close-icon"
                            type='tertiary'
                            onClick={handleCloseModel}
                        />
                    </div>
                }>
                <div className="mb-4 arrow-black">
                    <span className="form-field-title mb-2">{newSalesPersonLabel}</span>
                    <PosSelect
                        id={"newSalesperson"}
                        name={"newSalesperson"}
                        label={"New salesperson"}
                        value={newSalesperson}
                        onChange={((e) => {
                            setFieldValue("newSalesperson", e.target.value);
                        })}
                        options={salespersonData}
                    />
                </div>
                <section className="subquote-report-section">
                    <Grid container className="subquote-report-container">
                        {
                            salespersonName ?
                                <Grid container className="report-div">
                                    <Grid item xs={12} sm={7}>
                                        <div className="document-types-title">
                                            <span>{currentSalesperson}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <div className="send_rfi_row_new ml-2">
                                            <span>{salespersonName}</span>
                                        </div>
                                    </Grid>
                                </Grid> : null
                        }
                        {assignedDate ? <Grid container className="report-div">
                            <Grid item xs={12} sm={7}>
                                <div className="document-types-title">
                                    <span>{assignedOn}</span>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div className="send_rfi_row_new ml-2">
                                    <span>{assignedDate}</span>
                                </div>
                            </Grid>
                        </Grid> : null}
                    </Grid>
                </section>

                <ButtonContainerSBbuttons className="justify-content-end d-flex gap-2 mt-2">
                    <Button
                        label={'Cancel'}
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={cancelForm}
                    />
                    <Button
                        color="primary"
                        label={'Change'}
                        type='primary'
                        loading={isLoading}
                        disabled={isLoading || !dirty}
                        onClick={(e) => {
                            e.preventDefault()
                            handleSubmit();
                        }}
                    />
                </ButtonContainerSBbuttons>
            </PosModel>
        </Fragment>
    );
}
