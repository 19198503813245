import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Tick mark icon
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'; // Default circle
import { Box, FormControlLabel, Radio, Typography } from '@mui/material';

const DocumentSelection = ({ selectedValue, handleRadioChange }) => {

    return (
        <Box className="document-selection mb-4">
            {/* Buyer Company Selection */}
            <Box className={`option ${selectedValue === 'buyer' ? 'selected' : ''}`}>
                <FormControlLabel
                    value="buyer"
                    control={
                        <Radio
                            checked={selectedValue === 'buyer'}
                            onChange={handleRadioChange}
                            value="buyer"
                            icon={<RadioButtonUncheckedIcon />} // Default icon
                            checkedIcon={<CheckCircleIcon />} // Tick icon when selected
                        />
                    }
                    label={
                        <Box>
                            <Typography variant="body1">Buyer Company</Typography>
                            <Typography variant="caption">All orders will be tax-exempt</Typography>
                        </Box>
                    }
                    labelPlacement="start" // Places the label on the left side
                />
            </Box>

            {/* Shipping Locations */}
            <Box className={`option ${selectedValue === 'shipping' ? 'selected' : ''}`}>
                <FormControlLabel
                    value="shipping"
                    control={
                        <Radio
                            checked={selectedValue === 'shipping'}
                            onChange={handleRadioChange}
                            value="shipping"
                            icon={<RadioButtonUncheckedIcon />} // Default icon
                            checkedIcon={<CheckCircleIcon />} // Tick icon when selected
                        />

                    }
                    label={<Typography variant="body1">Shipping locations</Typography>}
                    labelPlacement="start" // Places the label on the left side
                />
            </Box>
        </Box>
    );
};

export default DocumentSelection;
