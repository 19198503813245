import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@fontsource/inter";

import { PosPageHeading, PosInput,PosButton, PosIconButton, Container } from "apps/pos/Common/uiCore"

import { CheckIcon, ClearIcon } from "constants/icons";
import { poNumberRegex } from "constants/constant";
import { RootState } from "models";

import { setPosFlowData } from "sagas/persistReducer/actions";
import { isPurchaseOrderNoEdited, takersRequest } from "sagas/pos/actions";

import "./EditPurchaseOrderNum.scss";

const EditPurchaseOrderNum = ({
  state,
  setState,
  setPurchaseOrderNum,
  onCloseDrawer,
  drawerRef,
  setPurchaseOrder
}) => {
  const dispatch = useDispatch();

  const { takerDetails, isPurchaseOrderNoEdit } = useSelector<RootState, any>((state) => state.pos);

  const { posFlowData, loadCartDetails } = useSelector<RootState, any>(
    (state) => state.persistReducer
  );
  const [updateValue, setUpdateValue] = useState<any>(posFlowData?.poNumber);
  const getPoNum = () => {
      if (loadCartDetails?.products.length) {
        if (isPurchaseOrderNoEdit) {
          return posFlowData?.poNumber || "";
        } else if (loadCartDetails?.purchaseOrderNum) {
          return loadCartDetails?.purchaseOrderNum;
        } else {
          return "";
        }
      } else {
        if (isPurchaseOrderNoEdit) {
          return posFlowData?.poNumber;
        } else {
          return "";
        }
      }
  };
  const [tempPurchaseOrderNum, setTempPurchaseOrderNum] =
  useState<string>(getPoNum());
  
  useEffect(() => {
    if (!takerDetails?.data?.length) dispatch(takersRequest());
  }, []);

  const handleChange = (e) => {
    if (e.target.value === "" || poNumberRegex.test(e.target.value)) {
      dispatch(isPurchaseOrderNoEdited(true))
      setUpdateValue(e.target.value);
      setTempPurchaseOrderNum(e.target.value);
    }
  }
  return (
    <Box role="presentation" className="h-100 modal-box">
      <PosIconButton
        className="back-next-btn-small btn responsive-back-btn pos-icon-back-btn"
        onClick={onCloseDrawer}
        tabIndex={-1}
      >
        <ClearIcon className="back-icon" />
      </PosIconButton>
      <div className="topbar-cart"></div>
      <Container className="modal-content-style modal-container-styling" ref={drawerRef}>
        <main>
          <div>
            <div className="pricing-modal-header">
              <PosPageHeading heading="Edit Purchase Order number" blackHeading />
            </div>

            <main className="mt-50">
              <div className="order-num-input">
                <span className="int-16-reg">Purchase Order #</span>
              </div>
              <div className="margintop-10">
              <PosInput
                  variant="outlined"
                  id="outlined-basic"
                  inputProps={{ maxLength:20 }}
                  value={tempPurchaseOrderNum ?? ""}
                  onChange={handleChange}
                  className="double-bordered-input"
                  autoFocus
                  onFocus={(event) => event.target.select()}
                  />
              </div>
              <div className="char-count">
                <span>{tempPurchaseOrderNum?.length || 0}/20</span>
              </div>
            </main>
            <div className="btns-container-discard-save delete-drawer-btn-spacing shipping-btns flex-row-reverse">
              <PosButton
                variant="contained"
                color="primary"
                className="modal-select-default-btn ship-btn-dark justify-content-start edit-purchase-discard-btn btn"
                onClick={() => {
                  setPurchaseOrderNum(tempPurchaseOrderNum);
                  const data = {
                    ...posFlowData,
                    poNumber: updateValue,
                  };
                  dispatch(setPosFlowData(data));
                  setState({ ...state, right: false });
                  (document.activeElement as HTMLElement).blur();
                  setPurchaseOrder(false);
                  !updateValue && !loadCartDetails?.products.length && dispatch(isPurchaseOrderNoEdited(false))
                }}
                startIcon={<CheckIcon />}
              >
                Save changes
              </PosButton>
              <PosButton
                variant="contained"
                className="modal-select-default-btn ship-btn justify-content-start edit-purchase-discard-btn btn"
                onClick={() => {
                  setPurchaseOrderNum(posFlowData?.poNumber);
                  setState({ ...state, right: false });
                  (document.activeElement as HTMLElement).blur();
                  setPurchaseOrder(false);
                }}
                startIcon={<ClearIcon/>}
              >
                Discard
              </PosButton>
            </div>
          </div>
        </main>
      </Container>
    </Box>
  );
};

export default EditPurchaseOrderNum;
