import Avvvatars from "avvvatars-react";
import { useFormik } from "formik";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

import ChangePasswordModal from "apps/admin/ChangePassword/ChangePassword";
import Button from "apps/admin/components/Elements/Button";
import IconButton from "apps/admin/components/Elements/IconButton";
import DeactivateUserForm from "apps/admin/DeactivateUserForm/DeactivateUserForm";
import { userFormValidation } from "apps/admin/formik/validationSchema";
import { useMediaQuery } from "apps/admin/hooks/useMediaQuery";
import PosSnackbar from "apps/pos/Common/uiCore/posSnackbar";
import {
  Input,
  LockStatusForm,
  RadioCheckInput,
  RejectUserInvitation,
  Select
} from "components";
import Skeleton from "./Skeleton";
import { UserProfileSettingsButton } from "./UserProfileSettingsButton";


import { numbersOnlyRegEx } from "constants/constant";
import { FieldName } from "constants/enum";
import { adminMessage } from "constants/message";
import { permissionOptions, selectOptions, userAdminPermissionOptions } from "constants/staticData";
import { Label, Tabs } from "enums";
import { Placeholders, ResponceStatus, UserAccountStatus } from "enums/status";
import {
  ManageUserModalProps, RootState,
  user,
  UserAdminPermissions,
  UserPermissions, UserPermissionsFormData
} from "models";
import { UpdateModalContent } from "sagas/modal/actions";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import {
  approveUser,
  editPermissions,
  editUserDetails,
  getAllUsers,
  getAwaitingApprovalUsers,
  resetUserFlag,
  setSearchUserId
} from "sagas/user/actions";
import { getLoggedInUser, setUserPermissions } from "services";
import { decodeToken, jsonParse } from "utils/utils";

import "./ManageUserForm.scss";



const permissionsReducer = (permissionArray: string[]): UserPermissions => {
  return permissionOptions.reduce((accumulator, permission) => {
    accumulator[permission.id] = permissionArray.includes(permission.id)
      ? 1
      : 0;
    return { ...accumulator };
  }, {} as UserPermissions);
};

const userAdminPermissionReducer = (
  permissionArray: string[]
): UserAdminPermissions => {
  return userAdminPermissionOptions.reduce((accumulator, permission) => {
    accumulator[permission.id] = permissionArray.includes(permission.id)
      ? 1
      : 0;
    return { ...accumulator };
  }, {} as UserAdminPermissions);
};

export function ManageUserModal({
  user,
  isApproval,
  loginUser,
  currentTab,
  page,
  isUser,
  isUserLoading,
  personId,
  url,
  fromHeader=false
}: ManageUserModalProps) {
  const [manageUser, setManageUser] = useState(user);
  const [loader, setLoader] = useState(isUserLoading);
  const { tabIndexZero, tabIndexFirst, tabIndexTwo, profileTab, roleTab, settingTab } = Tabs;
  const [userProfileTabs, setUserProfileTabs] = useState([profileTab, roleTab, settingTab]);
  const [selectedTab, setSelectedTab] = useState(userProfileTabs[currentTab] || userProfileTabs[0]);
  const [isChange, setIsChange] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [permissionsGiven, setPermissionsGiven] = useState([]);
  const [adminPermissionsGiven, setAdminPermissionsGiven] = useState([]);
  const [roleAssigned, setRoleAssigned] = useState("DataManager");
  const { approveUserSuccess, rejectUserSuccess, editUser, isApproveStart } =
    useSelector<RootState, user>((state) => state.user);
  const cookies = new Cookies();
  const token = cookies.get("Authorization");
  const decoded: {
    id: string;
  } = jwt_decode(token || "");
  const decodedData = JSON.parse(decoded?.id);

  const isMobile = useMediaQuery('(max-width: 800px)');
  const { inviteApprove, failInviteApprove, inviteReject, failInviteReject } = adminMessage;
  const { success, error, cancel } = ResponceStatus;
  const { CancelButton, SubmitButton, SaveChanges, Reject, Role, firstName, lastName, emailAddress, changePassword, deactiveAccount } = Label;
  const userPhoneNumber = manageUser ? manageUser.phone : "";
  const userEmailId = manageUser ? manageUser.email : "";
  const fullPhoneNumber = userPhoneNumber ? (userPhoneNumber.areaCode + userPhoneNumber.prefix + userPhoneNumber.lineNum) : ""
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState(userEmailId);
  const [countryCode, setCountryCode] = useState(userPhoneNumber?.intCode);
  const [phoneNumber, setPhoneNumber] = useState(fullPhoneNumber);
  const [extCode, setExtCode] = useState(userPhoneNumber?.extn || "");
  const [
    isSnackbarVisibleInvitationCancel,
    setIsSnackbarVisibleInvitationCancel,
  ] = useState(false);
  const [snackbarCancelsMessage, setSnackbarCancelsMessage] = useState("");
  const { permissions } = loginUser || {};
  const { userAdminPermissions } = permissions || {};
  const { countryCodePh, phoneNumberPh, john, smith, emailJohn } = Placeholders;
  const { userCountryCode, userProfilePhone, userProfileName, userProfileLastName, userProfileEmail, extCodes, adminPermissions, userPermissions } = FieldName;
  const userEmail = manageUser?.email;
  const avatarBgColor = '#fff';

  useEffect(() => {
    setUserProfileTabs([profileTab, roleTab, settingTab]);
    setSelectedTab(userProfileTabs[currentTab] || profileTab);
  }, []);

  useEffect(() => {
    if (isUserLoading && !user) {
      getLoggedInUser(personId).then((response) => {
        setManageUser(response);
        setLoader(false);
      })
    }
  }, [isUserLoading]);

  useEffect(() => {
    if (manageUser) {
      setEmail(manageUser?.email);
      setCountryCode(userPhoneNumber?.intCode || "");
      setPhoneNumber(fullPhoneNumber || "");
      setExtCode(userPhoneNumber?.extn || "");
      const permissionsArray = [];
      const adminPermissionsArray = [];

      const permissions = manageUser?.permissions;
      Object.keys(permissions).map((permission) => {
        permissions[permission] === 1 && permissionsArray.push(permission);
      });
      setPermissionsGiven(permissionsArray);

      const adminPermissions = manageUser?.permissions?.userAdminPermissions;
      Object.keys(adminPermissions).map((adminPermission) => {
        adminPermissions[adminPermission] === 1 && adminPermissionsArray.push(adminPermission);
      });
      setAdminPermissionsGiven(adminPermissionsArray);
    }
  }, [manageUser]);

  const changeTab = (e) => {
    const clickedTab = e.target.innerText.trim().toLowerCase();
    setSelectedTab(() => clickedTab);
  }
  useEffect(() => {
    if (approveUserSuccess === success) {
      dispatch(snakbarShow({message :inviteApprove}))
      if (userAdminPermissions?.canView === 1) {
        dispatch(getAllUsers(page));
      }
      dispatch(getAwaitingApprovalUsers(page));
      dispatch(
        UpdateModalContent({
          isOpen: false,
          children: null,
          fixedModal: true,
          removePaddingBottom: false,
          noMaxWidth: false
        })
      );
      dispatch(resetUserFlag());
    }
    if (approveUserSuccess === error) {
      dispatch(snakbarShow({message :failInviteApprove}))
      dispatch(
        UpdateModalContent({
          isOpen: false,
          children: null,
          fixedModal: true,
          removePaddingBottom: false,
          noMaxWidth: false
        })
      );
      dispatch(resetUserFlag());
    }
  }, [approveUserSuccess]);


  useEffect(() => {
    if (rejectUserSuccess === success) {
      dispatch(snakbarShow({message : inviteReject}))
      if (userAdminPermissions?.canView === 1) {
        dispatch(getAllUsers(page));
      }
      dispatch(getAwaitingApprovalUsers(page));
      dispatch(
        UpdateModalContent({
          isOpen: false,
          children: null,
          fixedModal: true,
          removePaddingBottom: false,
          noMaxWidth: false
        })
      );
      dispatch(resetUserFlag());
    }
    if (rejectUserSuccess === error) {
      dispatch(snakbarShow({message : failInviteReject}))
      dispatch(
        UpdateModalContent({
          isOpen: false,
          children: null,
          fixedModal: true,
          removePaddingBottom: false,
          noMaxWidth: false
        })
      );
      dispatch(resetUserFlag());
    }
  }, [rejectUserSuccess]);


  const {
    register: userPermissionsRegister,
    handleSubmit: userPermissionsHandleSubmit,
    setValue: userPermissionsSetValue,
    reset: userPermissionsReset,
  } = useForm<UserPermissionsFormData>();

  const userPermissionsOnSubmit = (data: UserPermissionsFormData) => {
    const permissionObject = permissionsReducer(data.userPermissions);
    const userAdminPermissionObject = userAdminPermissionReducer(
      data.userAdminPermissions
    );

    setUserPermissions(
      manageUser?.personId,
      data?.userPermissionsRole,
      permissionObject,
      userAdminPermissionObject,
      decodedData?.ManagerId
    ).then(() => {
      userPermissionsReset();
    });
  };

  const initialValues = {
    userProfileName: "",
    userProfileLastName: "",
    userProfileEmail: "",
    userProfilePhone: "",
    countryCode: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: userFormValidation,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      handleSaveChanges();
    },
  });

  const { values, handleSubmit, setFieldValue, touched, handleChange, errors } = formik


  useDeepCompareEffectNoCheck(() => {
    if (manageUser?.permissions) {
      const permissions = [];

      for (const [key, value] of Object.entries(manageUser?.permissions)) {
        if (value === 1) {
          permissions.push(key);
        }
      }
      setFirst(manageUser?.firstName);
      setLast(manageUser?.lastName);
      setFieldValue("userProfileName", manageUser?.firstName);
      setFieldValue("userProfileLastName", manageUser?.lastName);
      setFieldValue("userProfileEmail", manageUser?.email);
      setFieldValue("userProfilePhone", manageUser?.phone?.number || "");
      setFieldValue("countryCode", manageUser?.phone?.intCode || "");

      userPermissionsSetValue(
        "userPermissionsRole",
        manageUser.roles.map((role) => role.type)
      );

      userPermissionsSetValue("userPermissions", permissions);
      if (manageUser?.permissions?.userAdminPermissions) {
        const adminpermissions = [];

        for (const [key, value] of Object.entries(
          manageUser?.permissions?.userAdminPermissions
        )) {
          if (value === 1) {
            adminpermissions.push(key);
          }
        }
        userPermissionsSetValue("userAdminPermissions", adminpermissions);
      }
    }
  }, [manageUser]);
  
  const handleKeyDown = (e) => {
   e.stopPropagation()
  }

  const handleProfileChange = (e, field) => {
    handleChange(e)

    switch (field) {
      case userProfileName:
        setFirst(e.target.value);
        if (e.target.value === manageUser?.firstName) {
          setIsChange(false)
        } else {
          setIsChange(true)
        }
        break;
      case userProfileLastName:
        setLast(e.target.value);
        if (e.target.value === manageUser?.lastName) {
          setIsChange(false)
        } else {
          setIsChange(true)
        }
        break;
      case userProfileEmail:
        setEmail(e.target.value);
        if (e.target.value === manageUser?.email) {
          setIsChange(false)
        } else {
          setIsChange(true)
        }
        break;
      case userCountryCode:
        if (!e.target.value || numbersOnlyRegEx.test(e.target.value)) {
          setCountryCode(e.target.value);
        }
        const code = !manageUser?.phone ? "" : manageUser?.phone?.intcode;
        if (e.target.value === code) {
          setIsChange(false)
        } else {
          setIsChange(true)
        }
        setFieldValue(userCountryCode, e.target.value)
        break;
      case userProfilePhone:
        const phone = !manageUser?.phone ? "" : manageUser?.phone?.number;
        if (e.target.value === phone) {
          setIsChange(false)
        } else {
          setIsChange(true)
        }
        setPhoneNumber(e.target.value);
        setFieldValue(userProfilePhone, e.target.value);
        break;
      case extCodes:
        if (!e.target.value || numbersOnlyRegEx.test(e.target.value)) {
          setExtCode(e.target.value);
        }
        const intcode = !manageUser?.phone ? "" : manageUser?.phone?.intCode;
        if (e.target.value === intcode) {
          setIsChange(false)
        } else {
          setIsChange(true)
        }
        break;
      default:
        break;
    }

  }

  const handlePermissionChange = (e, permissionArray, permissionName) => {
    const tempPermissions = permissionArray;
    if (tempPermissions.includes(e.target.value)) {
      const filterData = tempPermissions.filter(
        (item) => item !== e.target.value
      );
      if (permissionName === adminPermissions) {
        setPermissionsGiven(filterData);
      } else {
        setAdminPermissionsGiven(filterData);
      }
    } else {
      tempPermissions.push(e.target.value);
      if (permissionName === adminPermissions) {
        setPermissionsGiven(tempPermissions);
      } else {
        setAdminPermissionsGiven(tempPermissions);
      }
    }
  }

  //something important----
  // function UserSettings() {
  //   const [checkboxClicked, setCheckboxClicked] = useState(false);
  //   const dispatch = useDispatch();
  //   const handleCheckbox = () => {
  //     setCheckboxClicked(!checkboxClicked);
  //   };

  //   const removeUserApi = () => {
  //     if (checkboxClicked) {
  //       dispatch(removeUser({ username: manageUser.username }));
  //       dispatch(getAllUsers());
  //       dispatch(
  //         UpdateModalContent({
  //           isOpen: false,
  //           children: null,
  //           fixedModal: false,
  //           removePaddingBottom: false,
  //         })
  //       );
  //     }
  //   };

  //   return (
  //     <SettingsContentStyled
  //       onSubmit={userSettingsHandleSubmit(userSettingsOnSubmit)}
  //     >
  //       <h6 className="f16-inter-med">Remove this manageUser</h6>
  //       <p className="f14-inter-med text-align-justify">
  //         This action will deactivate the user and they will no longer be able
  //         to access Jumper portal. Their personal data will still be securely
  //         stored and available in case they are ever reinstated. If you would
  //         like to permanently terminated their account please contact support.
  //       </p>
  //       {/* <div onClick={handleCheckbox}> */}
  //       <RadioCheckInput
  //         className="f16-inter-med"
  //         id={"userSettingsRemove"}
  //         label={"I read the description and understand the changes."}
  //         type={"checkbox"}
  //         name={"userSettingsRemove"}
  //         value={checkboxClicked}
  //         onChange={handleCheckbox}
  //         register={userSettingsRegister({ required: true })}
  //       />
  //       <ButtonContainer>
  //         <Button
  //           className="remove-btn-text btn-remove-user text-uppercase"
  //           type={"submit"}
  //           buttonStyle={"link"}
  //           label={"Remove User"}
  //           onClick={removeUserApi}
  //         />
  //       </ButtonContainer>
  //     </SettingsContentStyled>
  //   );
  // }

  const handleLock = () => {
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        navigate: true,
        classModal: "confirmation-dialog",
        children: (
          <LockStatusForm
            currentPage={page}
            user={manageUser}
            onSuccess={() => {
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: true,
                  removePaddingBottom: false,
                  noMaxWidth: false
                })
              );
            }}
            onError={() => {
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: true,
                  removePaddingBottom: false,
                  noMaxWidth: false
                })
              );
            }}
          />
        ),
        fixedModal: true,
        removePaddingBottom: true,
        noMaxWidth: true
      })
    );
  };
  const handleDeActivate = () => {
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        navigate: true,
        classModal: "deactivate-account-dialog",
        children: (
          <DeactivateUserForm
            isUser={isUser}
            currentPage={page}
            user={manageUser}
            loginUser={loginUser}
            onSuccess={() => {
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: true,
                  removePaddingBottom: false,
                })
              );
            }}
          />
        ),
        fixedModal: false,
        removePaddingBottom: true,
      })
    );
  };
  const handleChangePassword = () => {
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        navigate: true,
        fromHeader: fromHeader,
        classModal: "change-password-dialog",
        children: (
          <ChangePasswordModal
            loginUser={loginUser}
            user={manageUser}
            isUser={isUser}
            onSuccess={(msg) => {
              setSnackbarCancelsMessage(msg);
              setIsSnackbarVisibleInvitationCancel(true);
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: true,
                  removePaddingBottom: false,
                  noMaxWidth: false
                })
              );
            }}
          />
        ),
        fixedModal: true,
        removePaddingBottom: true,
        noMaxWidth: false
      })
    );
  };

  const handleSaveChanges = () => {
    if (selectedTab === userProfileTabs[tabIndexZero]) {
      const newNumber = phoneNumber && phoneNumber?.replace(/\s+/g, "");

      const areaCodeInfo = newNumber && newNumber?.substring(0, 3);
      const prefixInfo = newNumber && newNumber?.substring(3, 6);
      const lineNumInfo = newNumber && newNumber?.substring(6, 10);

      const customerPhone = {
        type: Placeholders.phoneType,
        areaCode: areaCodeInfo,
        prefix: prefixInfo,
        lineNum: lineNumInfo,
        extn: extCode,
        intCode: countryCode,
      };

      const preparedUserData = {
        firstName: first,
        lastName: last,
        email: email,
        phone: customerPhone,
        personId: manageUser?.personId,
        username: manageUser?.username,
      };
      dispatch(editUserDetails(preparedUserData));
      dispatch(setSearchUserId(manageUser.personId.toString()));
    }

    if (selectedTab === userProfileTabs[tabIndexFirst]) {
      const preparedPermissionData = {
        personId: manageUser?.personId,
        roles: [roleAssigned],
        permissions: {
          canViewLogs: permissionsGiven.includes("canViewLogs") ? 1 : 0,
          canManageProducts: permissionsGiven.includes("canManageProducts")
            ? 1
            : 0,
          canActivateCustomer: permissionsGiven.includes("canActivateCustomer")
            ? 1
            : 0,
          canApproveCredit: permissionsGiven.includes("canApproveCredit")
            ? 1
            : 0,
          canSetFixedMargin: permissionsGiven.includes("canSetFixedMargin")
            ? 1
            : 0,
          canSetFreeShipping: permissionsGiven.includes("canSetFreeShipping")
            ? 1
            : 0,
          canSetCreditHold: permissionsGiven.includes("canSetCreditHold")
            ? 1
            : 0,
          canEditDueDate: permissionsGiven.includes("canEditDueDate") ? 1 : 0,
          canEditInitialOrderMargin: permissionsGiven.includes(
            "canEditInitialOrderMargin"
          )
            ? 1
            : 0,
          canEditOrderShipmentHold: permissionsGiven.includes(
            "canEditOrderShipmentHold"
          )
            ? 1
            : 0,
          canReviewProducts: permissionsGiven.includes("canReviewProducts")
            ? 1
            : 0,
          userAdminPermissions: {
            canView: adminPermissionsGiven.includes("canView") ? 1 : 0,
            canInvite: adminPermissionsGiven.includes("canInvite") ? 1 : 0,
            canReview: adminPermissionsGiven.includes("canReview") ? 1 : 0,
            canLock: adminPermissionsGiven.includes("canLock") ? 1 : 0,
            canSetUserPermissions: adminPermissionsGiven.includes(
              "canSetUserPermissions"
            )
              ? 1
              : 0,
            canDeactivate: adminPermissionsGiven.includes("canDeactivate")
              ? 1
              : 0,
          },
        },
        ticketAssigneeSelectors: {},
        managerId: loginUser?.personId,
      };
      dispatch(editPermissions(preparedPermissionData));
    }
  };

  const approveUserInvite = () => {

    const decoded = decodeToken();
    const decodedData = jsonParse(decoded?.id);

    const preparedData = {
      personId: manageUser?.personId,
      roles: [roleAssigned],
      permissions: {
        canViewLogs: permissionsGiven.includes("canViewLogs")
          ? 1
          : 0,
        canManageProducts: permissionsGiven.includes(
          "canManageProducts"
        )
          ? 1
          : 0,
        canActivateCustomer: permissionsGiven.includes(
          "canActivateCustomer"
        )
          ? 1
          : 0,
        canApproveCredit: permissionsGiven.includes(
          "canApproveCredit"
        )
          ? 1
          : 0,
        canSetFixedMargin: permissionsGiven.includes(
          "canSetFixedMargin"
        )
          ? 1
          : 0,
        canSetFreeShipping: permissionsGiven.includes(
          "canSetFreeShipping"
        )
          ? 1
          : 0,
        canSetCreditHold: permissionsGiven.includes(
          "canSetCreditHold"
        )
          ? 1
          : 0,
        canEditDueDate: permissionsGiven.includes(
          "canEditDueDate"
        )
          ? 1
          : 0,
        canEditInitialOrderMargin: permissionsGiven.includes(
          "canEditInitialOrderMargin"
        )
          ? 1
          : 0,
        canEditOrderShipmentHold: permissionsGiven.includes(
          "canEditOrderShipmentHold"
        )
          ? 1
          : 0,
        canReviewProducts: permissionsGiven.includes(
          "canReviewProducts"
        )
          ? 1
          : 0,
        userAdminPermissions: {
          canView: adminPermissionsGiven.includes("canView")
            ? 1
            : 0,
          canInvite: adminPermissionsGiven.includes("canInvite")
            ? 1
            : 0,
          canReview: adminPermissionsGiven.includes("canReview")
            ? 1
            : 0,
          canLock: adminPermissionsGiven.includes("canLock")
            ? 1
            : 0,
          canSetUserPermissions: adminPermissionsGiven.includes(
            "canSetUserPermissions"
          )
            ? 1
            : 0,
          canDeactivate: adminPermissionsGiven.includes(
            "canDeactivate"
          )
            ? 1
            : 0,
        },
      },

      ticketAssigneeSelectors: {},
      managerId: decodedData?.ManagerId,
    };
    dispatch(approveUser(preparedData));
  }

  const rejectUserInvite = () => {
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        navigate: true,
        classModal: "confirmation-dialog",
        children: (
          <RejectUserInvitation
            currentPage={page}
            user={manageUser}
            onSuccess={() => {
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: true,
                  removePaddingBottom: false,
                  noMaxWidth: false
                })
              );
            }}
            onError={() => {
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: true,
                  removePaddingBottom: false,
                  noMaxWidth: false
                })
              );
            }}
          />
        ),
        fixedModal: true,
        removePaddingBottom: true,
        noMaxWidth: false
      })
    );
  }

  const cancelChanges = () => {
    if (editUser) {
      const body = { cancel: cancel, personId: manageUser?.personId }
      dispatch(editUserDetails(body));
    }
    dispatch(
      UpdateModalContent({
        isOpen: false,
        children: null,
        fixedModal: true,
        removePaddingBottom: true,
        noMaxWidth: false
      })
    );
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  }

  const saveChanges = () => {
    if (selectedTab === userProfileTabs[tabIndexZero]) {
      handleSubmit()
    } else {
      handleSaveChanges()
    }
  }

  return (
    <>
      {
        loader ?
          <Skeleton />
          : (<>
            <header className="dialog-header">
              <div className="user-profile-info">
                <div className='avatars'>
                  <Avvvatars
                    value={userEmail}
                    style='shape'
                    size={isMobile ? 60 : 70}
                    border={true}
                    borderSize={2}
                    borderColor={avatarBgColor}
                  />
                  {manageUser?.status?.type === UserAccountStatus.locked_out ? (
                    <IconButton
                      icon="lock"
                      iconSize="16"
                      size="28"
                      classes="avatars-lock-button"
                      title="Account locked"
                      onClick={handleLock}
                    />) : (
                    null
                  )}
                </div>
                <h3 className="dialog-title">
                  {manageUser.fullName}
                </h3>
                <p className="user-profile-joining-date">
                  User since {moment(manageUser?.createdAt).local().format("LL")}
                </p>
              </div>
              <div className="user-profile-tabs">
                {isApproval ? (
                  null
                ) : (
                  userProfileTabs.map((tab, index) => {
                    if ((tab === roleTab && userAdminPermissions?.canSetUserPermissions === 1) || tab !== roleTab ) {
                      return (<button
                        key={index}
                        className={`user-profile-tab ${tab === selectedTab ? 'active' : ''}`}
                        onClick={changeTab}
                      >
                        <p>{tab}</p>
                      </button>)
                    }
                  })
                )}
              </div>
            </header>

            <div className="dialog-body">
              <div className="user-profile-tab-panes" onKeyDown={(e) => handleKeyDown(e)}>
                {
                  !isApproval ? <>
                    {/* Profile */}
                    <div className={`user-profile-tab-pane profile ${selectedTab === profileTab ? 'active' : 'hidden'}`}>
                      <form id="userProfileForm">
                        <div>
                          <Input
                            className="f14-inter-reg"
                            id="userProfileName"
                            name="userProfileName"
                            label={firstName}
                            type="text"
                            placeholder={john}
                            value={values.userProfileName}
                            onChange={(e) => handleProfileChange(e, userProfileName)}
                          />
                          {touched.userProfileName && errors.userProfileName ? (
                            <span className="name_field_manageuser_form">
                              First name is required
                            </span>
                          ) : null}
                        </div>
                        <div>
                          <Input
                            className="f14-inter-reg"
                            id="userProfileLastName"
                            name="userProfileLastName"
                            label={lastName}
                            type="text"
                            placeholder={smith}
                            value={values.userProfileLastName}
                            onChange={(e) => handleProfileChange(e, userProfileLastName)}
                          />
                          {touched.userProfileLastName && errors.userProfileLastName ? (
                            <div>
                              <span className="name_field_manageuser_form">
                                Last name is required
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <Input
                            id="userProfileEmail"
                            name="userProfileEmail"
                            label={emailAddress}
                            type="email"
                            placeholder={emailJohn}
                            value={values.userProfileEmail}
                            onChange={(e) => handleProfileChange(e, userProfileEmail)}
                          />
                          {touched.userProfileEmail && errors.userProfileEmail ? (
                            <div>
                              <span className="name_field_manageuser_form">
                                Email is required
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div className="facility-box form-field-spacing-admin">
                          <div className="phone-number-title">
                            <span className="fs-14 inter-reg black_text mb-2">
                              Phone Number
                            </span>
                            <div className="phone-ext-title">
                              <span className="fs-14 inter-reg black_text mb-2">Ext</span>
                              <span className="fs-14 inter-reg gray_text mb-2">(Optional)</span>
                            </div>
                          </div>
                          <div className="facilit-phone-number">
                            <input
                              maxLength={3}
                              id="countryCode"
                              name="countryCode"
                              autoComplete="off"
                              placeholder={countryCodePh}
                              className="phone-country-code-input-admin mb-0"
                              value={countryCode}
                              onChange={(e) => handleProfileChange(e, userCountryCode)}
                            />
                            <input
                              className="phone-number-input-admin mb-0"
                              id="userProfilePhone"
                              name="userProfilePhone"
                              placeholder={phoneNumberPh}
                              value={phoneNumber.length ? phoneNumber : ""}
                              onChange={(e) => handleProfileChange(e, userProfilePhone)}
                            />
                            <input
                              id="extCode"
                              name="extCode"
                              value={extCode}
                              autoComplete="off"
                              className="phone-ext-input-admin mb-0"
                              maxLength={4}
                              onChange={(e) => handleProfileChange(e, extCodes)}
                            />
                          </div>
                          {(touched.userProfilePhone && errors.userProfilePhone) ?
                            (<span className="name_field_manageuser_form">
                              Phone number is required.
                            </span>) :
                            (touched.countryCode && errors.countryCode) ? (
                              <span className="name_field_manageuser_form">
                                Country Code is required.
                              </span>
                            ) : null}
                        </div>

                      </form>
                    </div>

                    {/* Roles & Permissions */}
                    {userAdminPermissions?.canSetUserPermissions === 1 ? <div className={`user-profile-tab-pane permissions ${selectedTab === roleTab ? 'active' : 'hidden'}`}>
                      <form
                        onSubmit={userPermissionsHandleSubmit(userPermissionsOnSubmit)}
                      >
                        <Select
                          className="mt-4"
                          id="defaultAttentionName"
                          name="defaultAttentionName"
                          selectClassName="user-role"
                          onChange={(e) => setRoleAssigned(e.target.value)}
                          label={Role}
                          labelStyle="f14-inter-reg-black"
                          options={selectOptions}
                        />
                        <div className="border-bottom-lightgray border-bottom border-md mb-2"></div>

                        <div className="permission_div_manageuser_form">
                          <label
                            className="f10-inter-reg-gray mb-2"
                            htmlFor="userPermissions"
                          >
                            PERMISSIONS
                          </label>
                          {permissionOptions.map((option, i) => (
                            <RadioCheckInput
                              className="radio-options-text w-100 p-1"
                              key={i}
                              id={option.id}
                              label={option.label}
                              type={"checkbox"}
                              name={"userPermissions"}
                              onChange={(e) => handlePermissionChange(e, permissionsGiven, adminPermissions)}
                              register={userPermissionsRegister({ required: true })}
                            />
                          ))}
                          <div className="lineBreak"></div>
                          {/* //something important---- */}
                          <label
                            className="f10-inter-reg-gray mb-2"
                            htmlFor="userAdminPermissions"
                          >
                            USER ADMIN PERMISSIONS
                          </label>
                          {userAdminPermissionOptions.map((option, i) => (
                            <RadioCheckInput
                              className="radio-options-text w-100 p-1"
                              key={i}
                              id={option.id}
                              label={option.label}
                              type={"checkbox"}
                              name={"userAdminPermissions"}
                              onChange={(e) => handlePermissionChange(e, adminPermissionsGiven, userPermissions)}
                              register={userPermissionsRegister({ required: true })}
                            />
                          ))}

                          {/* something important---- {isApproval && (
              <div>
                <label
                  className="f10-inter-reg-gray mt-3 mb-3"
                  htmlFor="userPermissions"
                >
                  SCOPES
                </label>
                <div className="mb-10">
                  {scopesOptions.map((option, i) => (
                    <RadioCheckInput
                      className="radio-options-text"
                      key={i}
                      id={option.value}
                      label={option.label}
                      type={"checkbox"}
                      name={"userPermissionsRole"}
                      register={userPermissionsRegister({ required: true })}
                    />
                  ))}
                </div>
              </div>
            )} */}
                          <div className="border-bottom-lightgray border-bottom border-md mt-60"></div>
                        </div>
                      </form>
                    </div> : null}

                    {/* Settings */}
                    <div className={`user-profile-tab-pane settings ${selectedTab === settingTab ? 'active' : 'hidden'}`}>

                      <UserProfileSettingsButton
                        onClick={manageUser?.status?.type !== UserAccountStatus.locked_out ? handleChangePassword : null}
                        icon="key"
                        label={changePassword}
                        description={manageUser?.status?.type === UserAccountStatus.locked_out ? 'Unlock account to change password' : null}
                        disabled={manageUser?.status?.type === UserAccountStatus.locked_out ? true : null}
                      />
                      {(!isUser && userAdminPermissions?.canLock === 1) ? (
                        <UserProfileSettingsButton
                          onClick={handleLock}
                          icon={manageUser?.status?.type === UserAccountStatus.locked_out ? 'lock_open' : 'lock'}
                          label={manageUser?.status?.type === UserAccountStatus.locked_out ? 'Unlock Account' : 'Lock Account'}
                        />) : null}
                      {(!isUser && userAdminPermissions?.canDeactivate === 1) ?
                        <UserProfileSettingsButton
                          icon="person_off"
                          iconClasses="settings-button-icon-danger"
                          label={deactiveAccount}
                          onClick={handleDeActivate}
                        /> : null
                      }
                    </div>
                  </> : <>
                    <div className='user-profile-tab-pane permissions'>
                      <form
                        onSubmit={userPermissionsHandleSubmit(userPermissionsOnSubmit)}
                      >
                        <Select
                          className="mt-4"
                          id="defaultAttentionName"
                          name="defaultAttentionName"
                          selectClassName="user-role"
                          onChange={(e) => setRoleAssigned(e.target.value)}
                          label={Role}
                          labelStyle="f14-inter-reg-black"
                          options={selectOptions}
                        />
                        <div className="border-bottom-lightgray border-bottom border-md mb-2"></div>

                        <div className="permission_div_manageuser_form">
                          <label
                            className="f10-inter-reg-gray mb-2"
                            htmlFor="userPermissions"
                          >
                            PERMISSIONS
                          </label>
                          {permissionOptions.map((option, i) => (
                            <RadioCheckInput
                              className="radio-options-text w-100 p-1"
                              key={i}
                              id={option.id}
                              label={option.label}
                              type={"checkbox"}
                              name={"userPermissions"}
                              onChange={(e) => handlePermissionChange(e, permissionsGiven, adminPermissions)}
                              register={userPermissionsRegister({ required: true })}
                            />
                          ))}
                          <div className="lineBreak"></div>
                          {/* //something important---- */}
                          <label
                            className="f10-inter-reg-gray mb-2"
                            htmlFor="userAdminPermissions"
                          >
                            USER ADMIN PERMISSIONS
                          </label>
                          {userAdminPermissionOptions.map((option, i) => (
                            <RadioCheckInput
                              className="radio-options-text w-100 p-1"
                              key={i}
                              id={option.id}
                              label={option.label}
                              type={"checkbox"}
                              name={"userAdminPermissions"}
                              onChange={(e) => handlePermissionChange(e, adminPermissionsGiven, userPermissions)}
                              register={userPermissionsRegister({ required: true })}
                            />
                          ))}

                          {/* something important---- {isApproval && (
              <div>
                <label
                  className="f10-inter-reg-gray mt-3 mb-3"
                  htmlFor="userPermissions"
                >
                  SCOPES
                </label>
                <div className="mb-10">
                  {scopesOptions.map((option, i) => (
                    <RadioCheckInput
                      className="radio-options-text"
                      key={i}
                      id={option.value}
                      label={option.label}
                      type={"checkbox"}
                      name={"userPermissionsRole"}
                      register={userPermissionsRegister({ required: true })}
                    />
                  ))}
                </div>
              </div>
            )} */}
                          <div className="border-bottom-lightgray border-bottom border-md mt-60"></div>
                        </div>
                      </form>
                    </div>
                  </>
                }
              </div>
            </div>

            {!isApproval ? (
              selectedTab !== userProfileTabs[tabIndexTwo] && (
                <div className="dialog-footer">
                  <Button
                    label={CancelButton}
                    secondary={true}
                    onClick={cancelChanges}
                  />
                  <Button
                    classes={`${editUser ? 'button-is-loading' : ""}`}
                    type={SubmitButton}
                    label={SaveChanges}
                    accent={true}
                    onClick={saveChanges}
                    disabled={editUser || (selectedTab === userProfileTabs[tabIndexZero] && !isChange)}
                  />
                </div>
              )) : <div className="dialog-footer">
              <Button
                label={Reject}
                onClick={rejectUserInvite}
              />
              <Button
                label={SaveChanges}
                accent={true}
                disabled={isApproveStart}
                onClick={approveUserInvite}
                classes={`${isApproveStart ? 'button-is-loading' : ""}`}
              />
            </div>
            }

            <Fragment>
                <PosSnackbar
                  open={isSnackbarVisibleInvitationCancel}
                  message={snackbarCancelsMessage}
                  onClose={() => {
                    setIsSnackbarVisibleInvitationCancel(false);
                  }}
                />
            </Fragment>

          </>)
      }
    </>
  );
}
