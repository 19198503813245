import axios from "axios";
import { endpoints } from "apps/springboard/endpoints";
import { AddFollowUpNotePayload, updateCustomerInfo, updateLikelyHoodComment } from "../../models";
import {
  AddMessagePayload,
  AddNotePayload,
  UpdateNotePayload,
} from "../../models";
import service from "../config";
import serviceV2 from "services/config2";
import { AppToURLPathName } from "../../enums";

import { authorizationToken } from "utils/utils";

const QUOTE_API_PATH = "quotes/job";
const QUOTE_MESSAGE_PATH = "quotes";
const USERS_PATH = "users";
const DOC_PATH = "docs";
const decodedData = authorizationToken("Authorization");

export function getQuotes() {
  return service
    // .get(`${QUOTE_API_PATH}/getactivequotelist`)
    .get(`${QUOTE_API_PATH}/active`)
    .then((response) => {
      return response?.data;
    });
}

export function getParentQuote(id: number) {
  return service
    // .get(`${QUOTE_API_PATH}/${id}/getparentquoteid`)
    .get(`quotes/job/${id}/parent/quoteid`)
    .then((response) => {
      return response?.data;
    });
}

export function getDocumentType() {
  // serviceV2.get(`pos/docs/types`)
  return service
    .get(`${DOC_PATH}/types`)
    // .get(`${DOC_PATH}/types`)
    .then((response) => {
    return response?.data;
  });
}

export function getLikelyHoodComment(id: number) {
  return service
    // get(`${QUOTE_API_PATH}/${id}/getcomments`)
    .get(`quotes/job/${id}/comments`).then((response) => {
    return response?.data;
  });
}

export function getQuotesByStatus(salesPersonId: number,status: number, page: number, size: number) {
  return service
    .get(
      `quotes/job/viewers/${salesPersonId}/status/${status}?page=${page}&size=${size}`
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error;
    });
}

export function getQuoteDetail(id: number) {
  return service
    // .get(`${QUOTE_API_PATH}/${id}/getquoteinfobyquoteid`)
    .get(`${QUOTE_API_PATH}/${id}`)
    .then((response) => {
      return response?.data;
    });
}

export function getQuoteDetailNotes(id: number) {
  return service
    .get(`${QUOTE_API_PATH}/${id}/initial/note`)
    .then((response) => {
    return response?.data;
  });
}

export function getQuoteDetailDocuments(id: number, orderNumber = 0) {
  return service
    // .get(`${QUOTE_API_PATH}/${id}/getrepdoclist/${orderNumber}`)
    .get(`${QUOTE_API_PATH}/${id ? id : 54315}/rep/documents/order/${orderNumber}`)
    .then((response) => {
      return response?.data;
    });
}

export function getQuoteDetailReps(id: number) {
  return service
    // .get(`${QUOTE_API_PATH}/${id}/getassignedreplist`)
    .get(`${QUOTE_API_PATH}/${id}/reps/assigned`)
    .then((response) => {
      return response?.data;
    });
}

export function getQuoteItemsbyQuoteId(id: number) {
  return service.get(`${QUOTE_API_PATH}/${id ? id : 54315}/items/active/by/quoteid`).then((response) => {
    return response?.data;
  });
}

export function updateBuyPriceReceivedFromRepFlag(id: number, flag: number) {
  return service
    .put(`${QUOTE_API_PATH}/${id}/buypricereceivedfromrep/${flag}`)
    .then((response) => {
    return response;
  });
}

export function getOrdersDetails(id: number) {
  return service
    .get(`${QUOTE_API_PATH}/${id}/orders`)
    .then((response) => {
    return response?.data;
  });
}

export function setRevision(handlerId: number, id: number, reason: string) {
  return service
    .put(`${QUOTE_API_PATH}/${id}/revision`, {
      handlerID: handlerId,
      jobQuoteID: id,
      reasonForRevision: reason,
    })
    .then((response) => {
      return response?.data;
    });
}

export function setKill(jobLotQuoteID: number, reason: string) {
  const url = `${QUOTE_API_PATH}/${jobLotQuoteID}/kill`;
  return service
    .put(url, {
      jobLotQuoteID,
      killReason: reason,
    })
    .then((response) => {
      return response?.data;
    });
}

export function setAsNew(jobLotQuoteID: number) {
  const urlNew = `${QUOTE_API_PATH}/${jobLotQuoteID}/asnew`;
  return service.put(urlNew).then((response) => {
    return response?.data;
  });
}

export function setAsNoQuote(handlerId: number, id: number, reason: string) {
  const urlNew = `${QUOTE_API_PATH}/${id}/noquote`;
  return service
    .put(urlNew, {
      handlerID: handlerId,
      jobQuoteID: id,
      killReason: reason,
    })
    .then((response) => {
      return response?.data;
    });
}

export function setAsSent(jobLotQuoteID: number) {
  const urlNew = `${QUOTE_API_PATH}/${jobLotQuoteID}/assent`;
  return service.put(urlNew).then((response) => {
    return response?.data;
  });
}

export function setExpired(jobLotQuoteID: number) {
  const urlNew = `${QUOTE_API_PATH}/${jobLotQuoteID}/expired`;
  return service.put(urlNew).then((response) => {
    return response?.data;
  });
}
export function getQuoteDetailEstimatorNotes(id: number) {
  return service
    .get(`${QUOTE_API_PATH}/${id}/notes/estimator`)
    .then((response) => {
      return response?.data;
    });
}

export function getQuoteDetailItemActionNotes(id: number) {
  return service
    .get(`${QUOTE_API_PATH}/${id}/items/notes/action`)
    .then((response) => {
      return response?.data;
    });
}

export function assignToEstimator(
  jobQuoteID: number,
  newEstimatorID: number,
  handlerID: number,
  changeSubQuoteFlag: number,
  sendEmailToCustomerChangeCurrentQuoteFlag:boolean,
  sendEmailToCustomerChangeAllQuoteFlag:boolean
) {
  const url = endpoints.updateEstimator(jobQuoteID)
  return serviceV2
    .put(url, {
      jobQuoteID,
      newEstimatorID,
      handlerID,
      changeSubQuoteFlag,
      sendEmailToCustomerChangeCurrentQuoteFlag,
      sendEmailToCustomerChangeAllQuoteFlag
    })
    .then((response) => {
      return response?.data;
    });
}

export function assignToSalesperson(
  jobLotQuoteID: number,
  newSalespersonID: number
) {
  const url = `${QUOTE_API_PATH}/${jobLotQuoteID}/assign/salespersons/${newSalespersonID}`;
  return service.put(url).then((response) => {
    return response?.data;
  });
}

export function getActiveSalesPerson() {
  const url = `${USERS_PATH}/salesperson`;
  return service.get(url).then((response) => {
    return response?.data;
  });
}

export function getLikeliHoodOfClose(jobQuoteID: number) {
  const url = `${QUOTE_API_PATH}/${jobQuoteID}/likelihood/close`;
  return service.get(url).then((response) => {
    return response?.data;
  });
}

export function getShipFeeByJobQuoteId(jobQuoteID: number) {
  const url = `${QUOTE_API_PATH}/${jobQuoteID}/price/fees/ship`;
  return service.get(url).then((response) => {
    return response?.data;
  });
}

export function updateLikeliHoodOfClose(id: number, likelihoodOfClose: number) {
  const url = `${QUOTE_API_PATH}/${id}/likelihoodofcloses/${likelihoodOfClose}`;
  return service.put(url).then((response) => {
    return response?.data;
  });
}

export function updateLikeliHoodComment(
  id: number,
  data: updateLikelyHoodComment
) {
  const url = `${QUOTE_API_PATH}/${id}/comments`;
  return service.put(url, data).then((response) => {
    return response?.data;
  });
}

export function getPackageInfo(quotePackageID: number) {
  const url = `quotes/job/customers/${quotePackageID}/active`;
  return service.get(url).then((response) => {
    return response?.data;
  });
}

export function getTicketTarget(quoteId: number) {
  const url = `tickets/targettypes/1/targets/${quoteId}`;
  return service.get(url).then((response) => {
    return response?.data;
  });
}

export function updateQuoteCustomer(data: updateCustomerInfo) {
  const url = `${QUOTE_MESSAGE_PATH}/express/customers/${data.quotePackageID}`;
  return service.put(url, data).then((response) => {
    return response?.data;
  });
}

export function getItemCostTotal(id: number) {
  return service
    .get(`${QUOTE_API_PATH}/${id}/item/cost/total`)
    .then((response) => {
      return response?.data;
    });
}
export function getQuoteDetailMessages(id: number) {
  return service
    .get(`${QUOTE_API_PATH}/${id}/message`)
    .then((response) => {
      return response?.data;
    });
}

export function getQuoteDetailRfi(id: number) {
  return service
    .get(`${QUOTE_API_PATH}/${id}/rfis`)
    .then((response) => {
      return response?.data;
    });
}

export function getVersionHistory({id,showAll}) {
  return service
    .get(`${QUOTE_API_PATH}/${id}/versions/showall/${showAll}`)
    .then((response) => {
      return response?.data;
    });
}

export function addQuoteMessage(body: AddMessagePayload) {
  return service
    .post(`${QUOTE_API_PATH}/${body.jobLotQuoteID}/message`, body)
    .then((response) => {
      return response?.data;
    });
}

export function addQuoteNote(body: AddNotePayload) {
  return service
    .post(`${QUOTE_API_PATH}/${body.posterID}/note`, body)
    .then((response) => {
      return response?.data;
    });
}

export function updateJobHighlightNote(body: UpdateNotePayload) {
  return service
    .put(`quotes/job/${body.jobQuoteID}/job/highlights`, body)
    .then((response) => {
      return response?.data;
    });
}

export function updateEstimatorNote(body: UpdateNotePayload) {
  return service
    .put(`${QUOTE_API_PATH}/${body.jobQuoteID}/estimator/notes`, body)
    .then((response) => {
      return response?.data;
    });
}

export function updateItemsActionNote(body: UpdateNotePayload) {
  return service
    .put(`${QUOTE_API_PATH}/${body.jobQuoteID}/item/action/notes`, body)
    .then((response) => {
      return response?.data;
    });
}

export function updateMajorReps(body: UpdateNotePayload) {
  return service
    .put(`${QUOTE_API_PATH}/${body.jobQuoteID}/major/reps`, body)
    .then((response) => {
      return response?.data;
    });
}

export function getJobHighlights(id: number) {
  return service
    .get(`quotes/job/${id}/highlights`)
    .then((response) => {
      return response?.data;
    });
}

export function getMajorReps(id: number) {
  return service
    .get(`${QUOTE_API_PATH}/${id}/reps/major`)
    .then((response) => {
      return response?.data;
    });
}

export function getQuotesEndRecord(id: number) {
  return service
    .get(`${QUOTE_API_PATH}/${id ? id : 54315}/send`)
    .then((response) => {
      return response?.data;
    });
}

export function removeEstimator(id: number, handlerID: number) {
  return service.delete(`${QUOTE_API_PATH}/${id}/estimator/users/${handlerID}`)
    .then((response) => {
      return response?.data;
    });
}

export function changeEstimator(body: any) {
  return service.post(`${QUOTE_API_PATH}/${body?.jobQuoteID}/assign/estimator`, body)
    .then((response) => {
      return response?.data;
    });
}

export function addDocument(body) {
  const tenantId = decodedData?.TenantId;
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_BASE_URL}/${AppToURLPathName.Api}/v2/${QUOTE_API_PATH}/${body.quoteId}/assigned/rep/document`,
    data: body.docData,
    headers: {
      "Content-Type": "multipart/form-data",
      "X-TENANT-ID": tenantId,
    },
  }).then((response) => {
    return response?.data;
  });
}

export function getFollowUpNote(id: number) {
  return service
    .get(`${QUOTE_API_PATH}/${id}/notes`)
    .then((response) => {
      return response?.data;
    });
}

export function updateInitialNote(body : UpdateNotePayload) {
  return service
    .put(`${QUOTE_API_PATH}/${body.jobQuoteID}/initial/note` , body)
    .then((response) => {
      return response?.data;
    });
}

export function getCustomerQuoteInfo(quotePackageID: number) {
  const url = `quotes/express/customers/${quotePackageID}/quote/info`;
  return service.get(url).then((response) => {
    return response?.data;
  });
}


export function addFollowUpNote(body : AddFollowUpNotePayload) {
  return service
    .post(`${QUOTE_API_PATH}/note` , body)
    .then((response) => {
      return response?.data;
    });
}

export function verifyForChildQuotes(jobLotQuoteID: number) {
  return service
    .get(`quotes/job/${jobLotQuoteID}/child/verify`)
    .then((response) => {
      return response?.data;
    });
}
