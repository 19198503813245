import { AnyAction } from "redux";
import { SET_QUOTE_LIST} from "./constants";


const initialState = {
  isLoading: false,
  quoteList: [],
  totalQuotes: 0,
}


const reducer = function quotesListReducer(state = initialState, action: AnyAction) {
  
  switch (action.type) {
    case SET_QUOTE_LIST:
      return { 
        ...state, 
        quoteList: action.payload?.data, 
        totalQuotes: action.payload?.total,
        isLoading: false 
      };
      default:
      return state;
  }


}

export default reducer