import { number, object, string } from "yup";

import { adminMessage, bookMarkValidationMessage } from "constants/message";
import { pageUrlRegex , alphanumericWithoutSpaceRegex } from "constants/constant";


const {titleReq , pageUrlReq} = bookMarkValidationMessage;

const userFormValidation = object().shape({
  linkName: string()
      .required(titleReq),
  linkRoute: string()
      .required(pageUrlReq)
      .matches(pageUrlRegex, adminMessage.urlInvalid)
})

const customerInfoValidateSchema = object().shape({
  quotePackageName: string()
    .required("Recipient Name is required")
    .matches(alphanumericWithoutSpaceRegex,' ')
    .nullable(),
  companyName: string()
  .required("Company Name is required"),
  fname:string()
  .required("Contact Name  is required"),
  email:string()
  .required("Email is required"),
  phone:number()
  .required("Phone is required")
  .nullable(),
  ext:number()
});

export {
  userFormValidation,
  customerInfoValidateSchema
};
