import { useSelector } from "react-redux";
import InlineNote from "../InlineNote";
import { getQuoteCounts } from "sagas/quoteDetail/selector";
import { formatBuyPriceText, formatQuoteText } from "./utils";

const RepMetadata = ({ name, note = "", buyPriceReceivedFromRepFlag }) => {
  const quoteCount = useSelector(getQuoteCounts);
  const buyPriceText = formatBuyPriceText(buyPriceReceivedFromRepFlag);
  const quoteText = formatQuoteText(quoteCount);

  return (
    <div className="item-group-rep">
      <h3 title={name}>{name}</h3>
      <div className="item-group-rep-metadata">
        <p>Buy price {buyPriceText}</p>
        <p>Quote {quoteText}</p>
      </div>
      <InlineNote note={note} type="rep" />
    </div>
  );
};

export default RepMetadata;
