import { ReactNode } from "react";
import { Grid, GridProps } from "@material-ui/core";

interface GridInfoProps extends GridProps {
    containerClass?: string;
    className?: string;
    children: ReactNode;
}

const PosGrid = ({
    xs,
    sm,
    md,
    lg,
    xl,
    className,
    children
}: GridInfoProps) => {

    return (
        <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={className}>
            {children}
        </Grid>
    );
};

export default PosGrid;
