export const GET_ALL_PRICE_SCHEDULES_START = "GET_ALL_PRICE_SCHEDULES_START";
export const GET_ALL_PRICE_SCHEDULES_SUCCESS =
  "GET_ALL_PRICE_SCHEDULES_SUCCESS";
export const GET_ALL_PRICE_SCHEDULES_ERROR = "GET_ALL_PRICE_SCHEDULES_ERROR";

export const GET_SELECTED_PRICE_SCHEDULES_START =
  "GET_SELECTED_PRICE_SCHEDULES_START";
export const GET_SELECTED_PRICE_SCHEDULES_SUCCESS =
  "GET_SELECTED_PRICE_SCHEDULES_SUCCESS";
export const GET_SELECTED_PRICE_SCHEDULES_ERROR =
  "GET_SELECTED_PRICE_SCHEDULES_ERROR";

export const GET_PRODUCT_PRICE_SCHEDULES_START =
  "GET_PRODUCT_PRICE_SCHEDULES_START";
export const GET_PRODUCT_PRICE_SCHEDULES_SUCCESS =
  "GET_PRODUCT_PRICE_SCHEDULES_SUCCESS";
export const GET_PRODUCT_PRICE_SCHEDULES_ERROR =
  "GET_PRODUCT_PRICE_SCHEDULES_ERROR";

export const ADD_ALL_PRICE_SCHEDULES_START = "ADD_ALL_PRICE_SCHEDULES_START";
export const ADD_ALL_PRICE_SCHEDULES_SUCCESS =
  "ADD_ALL_PRICE_SCHEDULES_SUCCESS";
export const ADD_ALL_PRICE_SCHEDULES_ERROR = "ADD_ALL_PRICE_SCHEDULES_ERROR";

export const ADD_ALL_PRODUCTS_START = "ADD_ALL_PRODUCTS_START";
export const ADD_ALL_PRODUCTS_SUCCESS = "ADD_ALL_PRODUCTS_SUCCESS";
export const ADD_ALL_PRODUCTS_ERROR = "ADD_ALL_PRODUCTS_ERROR";

export const ADD_SELECTED_PRODUCTS_START = "ADD_SELECTED_PRODUCTS_START";
export const ADD_SELECTED_PRODUCTS_SUCCESS = "ADD_SELECTED_PRODUCTS_SUCCESS";
export const ADD_SELECTED_PRODUCTS_ERROR = "ADD_SELECTED_PRODUCTS_ERROR";

export const UPDATE_PRODUCTS_START = "UPDATE_PRODUCTS_START";
export const UPDATE_PRODUCTS_SUCCESS = "UPDATE_PRODUCTS_SUCCESS";
export const UPDATE_PRODUCTS_ERROR = "UPDATE_PRODUCTS_ERROR";

export const UPDATE_SELECTED_PRICE_SCHEDULE_START = "UPDATE_SELECTED_PRICE_SCHEDULE_START";
export const UPDATE_SELECTED_PRICE_SCHEDULE_SUCCESS = "UPDATE_SELECTED_PRICE_SCHEDULE_SUCCESS";
export const UPDATE_SELECTED_PRICE_SCHEDULE_ERROR = "UPDATE_SELECTED_PRICE_SCHEDULE_ERROR";

export const UPDATE_SELECTED_PRODUCTS_START = "UPDATE_SELECTED_PRODUCTS_START";
export const UPDATE_SELECTED_PRODUCTS_SUCCESS = "UPDATE_SELECTED_PRODUCTS_SUCCESS";
export const UPDATE_SELECTED_PRODUCTS_ERROR = "UPDATE_SELECTED_PRODUCTS_ERROR";

export const REMOVE_PRICE_SCHEDULES_START = "REMOVE_PRICE_SCHEDULES_START";
export const REMOVE_PRICE_SCHEDULES_SUCCESS =
  "REMOVE_PRICE_SCHEDULES_SUCCESS";
export const REMOVE_PRICE_SCHEDULES_ERROR = "REMOVE_PRICE_SCHEDULES_ERROR";

export const REMOVE_PRODUCTS_START = "REMOVE_PRODUCTS_START";
export const REMOVE_PRODUCTS_SUCCESS =
  "REMOVE_PRODUCTS_SUCCESS";
export const REMOVE_PRODUCTS_ERROR = "REMOVE_PRODUCTS_ERROR";

export const REMOVE_SELECTED_PRICESCHEDULE_START = "REMOVE_SELECTED_PRICESCHEDULE_START";
export const REMOVE_SELECTED_PRICESCHEDULE_SUCCESS =
  "REMOVE_SELECTED_PRICESCHEDULE_SUCCESS";
export const REMOVE_SELECTED_PRICESCHEDULE_ERROR = "REMOVE_SELECTED_PRICESCHEDULE_ERROR";
