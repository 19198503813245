import { PosTooltip } from "apps/pos/Common/uiCore";

import texts from "apps/springboard/locales";
import { IconButton } from "../../elements";

import "./ItemGroupHeader.scss"

const Actions = ({jobQuoteID, quoteRepId, distributor}) => {
  const { addDoc, editItems } = texts
    return (
        <div className="item-group-actions">
          <PosTooltip
            title={addDoc}
            placement="top"
          >
            <span>
          <IconButton className="fs-12 add_item_tag" target="_blank" href={`${distributor?.httpsUrl}/gmSale/msJobLotQuote_repDocUpload?jobLotQuoteID=${jobQuoteID}&quoteRepID=${quoteRepId}`} name="note_add" type="secondary"/>
            </span>
          </PosTooltip>
          <PosTooltip
          title={editItems}
            placement="top"
          >
            <span>
            <IconButton className="fs-12 add_item_tag" target="_blank" href={`${distributor?.httpsUrl}/gmSale/msJobLotQuote_RepItem_Add?jobLotQuoteID=${jobQuoteID}&quoteRepID=${quoteRepId}`} name="edit" type="secondary" />
            </span>
          </PosTooltip>
        </div>
    )
}

export default Actions;