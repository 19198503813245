import { useNavigate } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { StyledTableCell } from "apps/product/helper/mui-style-helper";

import { productStatus } from "apps/product/helper/commonConstants";
import images from "constants/images";
import { Paths } from "enums";

import "./style.scss";

const ProductListing = ({ productList }: { productList: any[] }) => {
  const navigate = useNavigate();

  const { defaultIcon } = images;
  const redirectToProductList = (productId) => () => {
    productId && navigate(`${Paths.product}/${productId}`);
  }
  return (
    <div className="product-table-container">
      <TableContainer className="cart-product-listing-data">
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="head-table">
            <TableRow>
              <StyledTableCell align="left">PRODUCT NAME</StyledTableCell>
              <StyledTableCell align="left">ID</StyledTableCell>
              <StyledTableCell align="left">STATUS</StyledTableCell>
              <StyledTableCell align="left">UNIT/CASE</StyledTableCell>
              <StyledTableCell align="left">GROUP</StyledTableCell>
              <StyledTableCell align="left">PRICE SCHEDULE</StyledTableCell>
              <StyledTableCell align="left">UOM</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productList.map((productDetails) => {
              const {
                productName,
                manufacturerName,
                productId,
                activeFlag,
                unitPerCase,
                muName,
                priceMU,
                isOnPriceSchedule,
                thumbImg
              } = productDetails
              return (
                <TableRow className="table-body" onClick={redirectToProductList(productId)}>
                  <StyledTableCell>
                    <div className="product-quick-info">
                      <div className="product-image">
                        <img src={thumbImg ? thumbImg : defaultIcon} />
                      </div>
                      <div className="product-text-info">
                        <span className="prod-name">
                          {productName}
                        </span>
                        <span className="prod-manufacturere-name">
                          {manufacturerName}
                        </span>
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span className="prod-status">
                      {productId}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span className="prod-status">
                      <span className="prod-status-icon">
                        {productStatus[activeFlag].Component}
                      </span>
                      <span className="prod-status-title">
                        {productStatus[activeFlag].title}
                      </span>
                    </span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span className="prod-status">
                      {unitPerCase}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span className="prod-status">
                      {unitPerCase}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span className="prod-status">{!isOnPriceSchedule ? "Price schedule not set" : ""}</span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span className="prod-status">
                      {muName} ({priceMU})
                    </span>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProductListing;
