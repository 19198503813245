import NotesInput from '../NotesInput'

import './NotesItem.scss'

const NotesItem = (props) => {
    const { note, remove , updateNotes , loginUerName } = props
    const {modifiedByName , modifiedAt , noteId , note : noteText} = note || {}

    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    const isMe = (loginUerName === modifiedByName)
    return (
        <div
            className='notes-item'
        >
            <div className="notes-item-header">
                <p className="notes-item-created-by">
                    {modifiedByName} {isMe ? "(You)" : ""}
                </p>
                <p className="notes-item-created-at">
                    {formatDate(modifiedAt)}
                </p>
            </div>
            <NotesInput id={noteId} initialValue={noteText} remove={remove} updateNotes={updateNotes}/>

            {modifiedAt ? (
                <div className='notes-item-controls'>
                    <p className='notes-item-last-modified'>
                        Last edited by {modifiedByName} on {formatDate(modifiedAt)}
                    </p>
                </div>
            ) : null}
        </div>
    )
}

export default NotesItem