import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";

import { stopLoading } from "utils/utils";
import { productsByPriceSchedule } from "./constantUrl";

interface ProductIdsPros {
  stockWarehouseId: number,
  productIds: number[],
  personId: number,
  priceScheduleId: number
}

export const useProductsByPriceSchedule = () => {
  const { mutate, isLoading, data, error, isSuccess } = useMutation(
    async (payload: ProductIdsPros) => {
      return productsByPriceSchedule(payload);
    },
    {onSuccess : stopLoading,
      onError : stopLoading
});

  const productsByPriceScheduleFn = useCallback(
    (payload) => {
      mutate(payload);
    },
    [mutate]
  );

  return {
    productsByPriceScheduleFn,
    isLoading,
    data,
    error,
    isSuccess,
  };
};
