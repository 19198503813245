import "@fontsource/inter";
import React from "react";

import PosInput from "apps/pos/Common/uiCore/posInput";

interface PurchaseOrderInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => string | void;
  value: string;
}

export const PurchaseOrderInput = ({
  value,
  onChange,
}: PurchaseOrderInputProps) => {
  return (
    <div className="facility-box job-input">
      <span className="int-16-reg job-input-span">
        Purchase Order #
        <span className="phone-optional-head ml-3">(optional)</span>
      </span>
      <PosInput
        variant="filled"
        id="filled-basic"
        InputProps={{ disableUnderline: true }}
        inputProps={{ maxLength: 100 }}
        value={value}
        onChange={onChange}
        className="search-input-focus"
      />
    </div>
  );
};
