import { endpoints } from "constants/endpoint";
import {
  UserAdminPermissions,
  UserFavoritesRequest,
  UserPermissions,
} from "models";
import service from "../config";
import serviceV2 from "../config2";

const USER_API_PATH = "/users";

export function getUsers(page: number) {
  return serviceV2
    .get(`${USER_API_PATH}/portal?page=${page}&size=10`)
    .then((response) => {
      return response?.data;
    });
}

export function editUserDetails(body: any) {
  return serviceV2
    .put(`${USER_API_PATH}/${body?.personId}/portal`, body)
    .then((response) => {
      return response?.data;
    });
}

export function editPermissions(body: any) {
  return serviceV2
    .put(`${USER_API_PATH}/${body?.personId}/portal/access`, body)
    .then((response) => {
      return response?.data;
    });
}

export function getAwaitingApprovalUsers(page: number) {
  return serviceV2
    .get(`${USER_API_PATH}/portal/review?page=${page}&size=10`)
    .then((response) => {
      return response?.data;
    });
}

export function getInvitedUsers(page: number) {
  return serviceV2
    .get(`${USER_API_PATH}/portal/invited?page=${page}&size=10`)
    .then((response) => {
      return response.data;
    });
}

export function rejectUser(personId: number) {
  return serviceV2
    .put(`${USER_API_PATH}/${personId}/portal/reject`)
    .then((response) => {
      return response?.data;
    });
}

export function approveUser(body: any) {
  return serviceV2
    .put(`${USER_API_PATH}/${body?.personId}/portal/approve`, body)
    .then((response) => {
      return response?.data;
    });
}

export function setUserPermissions(
  personId: number,
  roles: string[],
  permissions: UserPermissions,
  adminUserPermissions: UserAdminPermissions,
  managerId: number
) {
  return serviceV2
    .post(`${USER_API_PATH}/${personId}/portal/access`, {
      personId,
      roles,
      permissions,
      adminUserPermissions,
      ticketAssigneeSelectors: {
        submittalManager: 0,
        buyPriceManager: 0,
        productManager: 0,
        projectManager: 0,
        rmaManager: 0,
      },
      managerId,
    })
    .then((response) => {
      return response?.data;
    });
}

export function setUserLockState(state: string, personId: number) {
  return serviceV2
    .put(`${USER_API_PATH}/${personId}/portal/${state}`)
    .then((response) => {
      return response?.data;
    });
}

export function addFavoriteLink(
  personId: number,
  favorite: UserFavoritesRequest
) {
  return serviceV2
    .post(`/bookmarks${USER_API_PATH}/${personId}`, favorite)
    .then((response) => {
      return response?.data;
    });
}

export function getFavoriteLinks(personId: number) {
  return serviceV2
    .get(`/bookmarks${USER_API_PATH}/${personId}`)
    .then((response) => {
      return response?.data;
    });
}

export function deleteFavoriteLinks(personId: number) {
  return serviceV2
    .delete(`/bookmarks${USER_API_PATH}/${personId}`)
    .then((response) => {
      return response?.data;
    });
}

export function updateFavoriteLinkById(bookmarkId: number, obj: any) {
  return serviceV2.put(`/bookmarks/${bookmarkId}`, obj).then((response) => {
    return response?.data;
  });
}

export function deleteFavoriteLinkById(bookmarkId: number, personId: number) {
  return serviceV2
    .delete(`/bookmarks/${bookmarkId}${USER_API_PATH}/${personId}`)
    .then((response) => {
      return response?.data;
    });
}

export function getLoggedInUser(personId: number) {
  return serviceV2
    .get(`${USER_API_PATH}/${personId}/portal`)
    .then((response) => {
      return response?.data;
    });
}

export function removeUserbyName(username: string) {
  return service
    .put(`${USER_API_PATH}/deactivate/username`, {
      userName: username,
    })
    .then((response) => {
      return response?.data;
    });
}

export function searchUserList(page: number, query: string) {
  return serviceV2
    .post(`${USER_API_PATH}/portal/search`, {
      page: page,
      size: 10,
      sortBy: "ASCENDING",
      query: query,
    })
    .then((response) => {
      return response?.data;
    });
}

export function searchUserByParam(obj: any) {
  return serviceV2
    .post(`${USER_API_PATH}/portal/search`, obj)
    .then((response) => {
      return response;
    });
}

export function universalSearch(obj: any) {
  return serviceV2
    .post(`/search/universal`, obj)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function searchApiUrlSuffix(paramKey: string, obj: any) {
  return serviceV2
    .post(`/search/universal${paramKey}`, obj)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getUsersNotes() {
  return serviceV2.get(endpoints.note).then((response) => {
    return response;
  });
}

export function updateUsersNote(notes) {
  return serviceV2
    .post(endpoints.note, notes)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
