import axios from "axios";
import { AnyAction } from "redux";
import { all, call, cancelled, put, takeLatest } from "redux-saga/effects";

import * as services from "services";
import * as actions from "./actions";
import * as constants from "./constants";
import { adminMessage } from "constants/message";

//Worker Saga

function* getAllUsersDataAsync(action: AnyAction) {
  try {
    const usersData = yield call(services.getUsers, action.payload);
    yield put(actions.getAllUsersSuccess(usersData));
  } catch (errors) {
    yield put(actions.getAllUsersError("errors"));
  }
}

function* editUserDetailsDataAsync(action: AnyAction) {
  const cancelTokenSource = axios.CancelToken.source();
  try {
    if (action.payload.cancel) {
      yield put(actions.editUserDetailsCancel());
    } else {
      const editUserDetailsData = yield call(
        services.editUserDetails,
        action.payload
      );

      if (editUserDetailsData?.errors) {
        yield put(actions.editUserDetailsError("errors"));
      } else {
        yield put(actions.editUserDetailsSuccess(editUserDetailsData));
      }
    }

  } catch (errors) {
    yield put(actions.editUserDetailsError("errors"));
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

function* editUserPermissionsDataAsync(action: AnyAction) {
  try {
    const editUserPermissionsData = yield call(
      services.editPermissions,
      action.payload
    );
    yield put(actions.editPermissionsSuccess(editUserPermissionsData));
  } catch (errors) {
    yield put(actions.editPermissionsError(errors?.title ? errors?.title : adminMessage.failProfileUpdate));
  }
}

function* getAwaitingApprovalUsersDataAsync(action: AnyAction) {
  try {
    const awaitingApprovalUsersData = yield call(
      services.getAwaitingApprovalUsers, action.payload
    );
    yield put(
      actions.getAwaitingApprovalUsersSuccess(awaitingApprovalUsersData)
    );
  } catch (errors) {
    yield put(actions.getAwaitingApprovalUsersError(errors));
  }
}

function* getInvitedUsersDataAsync(action: AnyAction) {
  try {
    const invitedUsersData = yield call(
      services.getInvitedUsers,
      action.payload
    );
    yield put(actions.getInvitedUsersSuccess(invitedUsersData));
  } catch (errors) {
    yield put(actions.getInvitedUsersError(errors));
  }
}

function* rejectUserAsync(action) {
  try {
    const rejectUserData = yield call(services.rejectUser, action.payload);
    yield put(actions.rejectUserSuccess(rejectUserData.data));
  } catch (errors) {
    yield put(actions.rejectUserError(errors));
  }
}

function* approveUserAsync(action) {
  try {
    const approveUserData = yield call(services.approveUser, action.payload);
    yield put(actions.approveUserSuccess(approveUserData.data));
  } catch (errors) {
    yield put(actions.approveUserError(errors));
  }
}

function* removeUserAsync(action) {
  try {
    const removeUserData = yield call(
      services.removeUserbyName,
      action.payload.username
    );
    yield put(actions.removeUserSuccess(removeUserData.data));
  } catch (errors) {
    yield put(actions.removeUserError(errors));
  }
}

function* getAllSearchUsersDataAsync(action: AnyAction) {
  try {
    const usersData = yield call(services.searchUserList, action.payload.page, action.payload.query);
    yield put(actions.getAllSearchUsersSuccess(usersData));
  } catch (errors) {
    yield put(actions.getAllSearchUsersError("errors"));
  }
}

//Watcher Saga

function* getAllUsersDataWatcher() {
  yield takeLatest(constants.GET_ALL_USERS_START, getAllUsersDataAsync);
}
function* editUserDetailsWatcher() {
  yield takeLatest(
    constants.EDIT_USERS_DETAILS_START,
    editUserDetailsDataAsync
  );
}

function* editUserPermissionssWatcher() {
  yield takeLatest(
    constants.EDIT_PERMISSIONS_START,
    editUserPermissionsDataAsync
  );
}

function* getAwaitingApprovalUsersDataWatcher() {
  yield takeLatest(
    constants.GET_AWAITING_APPROVAL_START,
    getAwaitingApprovalUsersDataAsync
  );
}
function* getInvitedUsersDataWatcher() {
  yield takeLatest(constants.GET_INVITED_START, getInvitedUsersDataAsync);
}
function* rejectUserWatcher() {
  yield takeLatest(constants.REJECT_USER_START, rejectUserAsync);
}

function* approveUserWatcher() {
  yield takeLatest(constants.APPROVE_USER_START, approveUserAsync);
}
function* removeUserWatcher() {
  yield takeLatest(constants.REMOVE_USER_START, removeUserAsync);
}

function* getAllSearchUsersDataWatcher() {
  yield takeLatest(constants.GET_ALL_SEARCH_USERS_START, getAllSearchUsersDataAsync);
}

export default function* quoteDetailSaga() {
  yield all([
    getAllUsersDataWatcher(),
    editUserDetailsWatcher(),
    editUserPermissionssWatcher(),
    getAwaitingApprovalUsersDataWatcher(),
    getInvitedUsersDataWatcher(),
    rejectUserWatcher(),
    removeUserWatcher(),
    approveUserWatcher(),
    getAllSearchUsersDataWatcher()
  ]);
}
