import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: '100vh',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[6],
      padding: '1.875rem',
      borderRadius: "1.25rem",
      maxWidth: "31.25rem",
      width: '90vw',
    },
    closeIcon: {
      fontSize: 22,
      cursor: "pointer",
      float: "right",
    },
    headerWidth: {
      width: "100%",
    },
    header: {
      fontWeight: "bold",
      color: "black",
      marginLeft: "0.625rem",
      fontSize: "1.25rem",
    },
    innerdata: {
      fontSize: "1rem",
      color: "black",
      marginLeft: "0.625rem",
      paddingTop: "0.938rem",
    },
    buttondiv: {
      display: "flex",
      flexWrap: 'wrap',
      justifyContent: "end",
      paddingTop: "1.875rem",
      gap: "0.625rem",
    },
    buttonfirst: {
      backgroundColor: "white",
      color: "black",
      fontSize: "1rem",
      borderRadius: "15%",
      padding: "0.313rem",
      width: "auto",
    },
    buttonseconds: {
      backgroundColor: "black",
      color: "white",
      fontSize: "1rem",
      borderRadius: "15%",
      padding: "0.313rem",
      width: "auto",
    },
  }));

  export default useStyles;