import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ProductListing } from "apps/product/modules/productListingContainer/components/productListing";
import { ProductListingLayout } from "apps/product/modules/productListingContainer/components/productListingLayout";

import { defaultFilter, layoutType } from "apps/product/helper/commonConstants";

import {
  searchProductsStart
} from "apps/product/modules/productListingContainer/redux/product.slice";
import useProductsHooks from "./hooks/mutationHooks";
import { getProductFiltersStart, setFilterRequest } from "./redux/productFilters.slice";

import {
  getfilterData,
  getSearchKeyword
} from "apps/product/modules/productListingContainer/redux/selector";
import { PaginationFormat } from "constants/enum";
import { IFilters } from "services";

const ProductListingContainer = () => {
  const { One, Twenty, Ascending } = PaginationFormat;

  const [selectedLayoutType, setlayoutType] = useState(layoutType.list);
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState<IFilters>();
  const [currentPage, setCurrentPage] = useState(One);
  const filterData = useSelector(getfilterData);
  const searchText = useSelector(getSearchKeyword);

  const dispatch = useDispatch();
  const {
    getProductsMutation,
    productFiltersMutation
  } = useProductsHooks();

  useEffect(() => {
    setFilter(filterData ?? defaultFilter);
  }, [filterData]);

  useEffect(() => {
    setKeyword(searchText);
  }, [searchText]);

  const handleFilter = (filter: IFilters) => {
    setFilter(filter);
    dispatch(setFilterRequest(filter));
    if (keyword) {
      handleSearch(One, Twenty, filter, true);
    }
  }

  const handlePage = (page) => {
    setCurrentPage(page)
  }

  const handleSearch = (page: number, size: number, filterData?: IFilters, flag = false) => {
    if (keyword) {
      if (!flag && !keyword.trim()) return;
      setCurrentPage(page)
      const body = {
        page,
        size: size ?? Twenty,
        sortBy: Ascending,
        query: keyword ? keyword : "",
        filters: filterData ? filterData : filter
      };
      dispatch(searchProductsStart(page === One));
      getProductsMutation.mutate(body);
    }
  };

  const handleGetFilter = () => {
    dispatch(getProductFiltersStart());
    productFiltersMutation.mutate();
  };

  return (
    <ProductListingLayout
      selectedLayoutType={selectedLayoutType}
      setlayoutType={setlayoutType}
      handleSearch={handleSearch}
      keyword={keyword}
      setKeyword={setKeyword}
      handleFilter={handleFilter}
      filter={filter}
    >
      <ProductListing
        selectedLayoutType={selectedLayoutType}
        handleSearch={handleSearch}
        handleGetFilter={handleGetFilter}
        setCurrentPage={handlePage}
        currentPage={currentPage}
        keyword={keyword}
      />
    </ProductListingLayout>
  );
};

export default ProductListingContainer;
