import { useEffect } from "react";
import { Form, FormikProvider } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { PosInput } from "apps/pos/Common/uiCore";
import { ErrorMessage } from "apps/pos/formik/errorMessage";
import Button from "apps/admin/components/Elements/Button";
import ReturnToGroup from "components/ReturnTo/ReturnToGroup";

import { MoveProps } from "../type";
import { decimal18RegEx, qtyRegEx } from "constants/constant";
import { PosFlowDataProp } from "constants/interfaces";
import { PosResponseProps, RootState } from "models";
import { CheckIcon } from "constants/icons";
import { getLocalStoraGeItem } from "utils/utils";
import { tabType } from "constants/enum";
import texts from "apps/pos/locales";
import useMoveStockFormik from "./useMoveStockFormik";

import {
  fromLocationUnitClear,
  fromLocationShelfClear,
  getUnitsActiveClear,
  getShelfClear,
  stockMoveClear,
  getWarehouseLocationsClear,
  getWarehourseLocationsRequest,
} from "sagas/pos/actions";

import "../add/add.scss";

const Move = ({
  fromRowName,
  tabValue,
  setFromRowName,
  setFromUnitName,
  setFromShelfName,
  setToRowName,
  setToUnitName,
  setToShelfName,
  fromQtyOnLocationData,
  units,
  setUnits,
  unitsErrors,
  setUnitsErrors,
  unitsLoading,
  setUnitsLoading,
  shelves,
  setShelves,
  shelvesErrors,
  setShelvesErrors,
  shelvesLoading,
  setShelvesLoading,
}: MoveProps) => {
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );

  const { stockMoveDetails, isStockMoveLoading, inStockMoveSuccess } =
    useSelector<RootState, PosResponseProps>((state) => state.pos);
  const { cartProductDetails } = posFlowData || {};
  const { productId } = cartProductDetails || {};
  const { moveStock } = texts;

  const dispatch = useDispatch();
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace || {};

  useEffect(() => {
    dispatch(fromLocationUnitClear());
    dispatch(fromLocationShelfClear());
    dispatch(getUnitsActiveClear());
    dispatch(getShelfClear());
  }, [tabValue]);

  useEffect(() => {
    if (!fromRowName) {
      resetForm();
      setFromRowName("");
      setFromUnitName("");
      setFromShelfName("");
      setToRowName("");
      setToUnitName("");
      setToShelfName("");
    }
  }, [fromRowName]);

  const quantityToMoveOnchange = (
    quantiMove: string,
    setFieldValue: (field: string, value: number | string) => void
  ) => {
    const moveQty = qtyRegEx.test(quantiMove);
    if (Number(quantiMove) > fromQtyOnLocationData) {
      setFieldValue("quantityToMove", quantiMove);
    } else if (moveQty) {
      setFieldValue("quantityToMove", Number(quantiMove));
    } else if (quantiMove === "") {
      setFieldValue("quantityToMove", "");
    }
  };
  // move api call success
  useEffect(() => {
    if (inStockMoveSuccess) {
      setTimeout(() => {
        const data = {
          stockWarehouseId,
          body: [productId],
        };
        dispatch(getWarehourseLocationsRequest(data));
        dispatch(stockMoveClear());
      }, 1000);
      setFieldValue("quantityToMove", "");
      setFieldValue("moveReason", "");
    }
  }, [inStockMoveSuccess]);

  useEffect(() => {
    if (tabValue === 2 && stockMoveDetails.title) {
      setTimeout(() => {
        dispatch(stockMoveClear());
      }, 2000);
    }
  }, [stockMoveDetails?.title, tabValue]);

  const formik = useMoveStockFormik({ fromQtyOnLocationData });

  const { values, setFieldValue, resetForm } = formik || {};

  const {
    productLocationRow,
    productLocationUnit,
    productLocationShelf,
    moveToLocationRowD2,
    moveToLocationUnitD2,
    moveToLocationShelfD2,
    quantityToMove,
    moveReason,
  } = values || {};

  const disableButtons = [
    productLocationRow,
    productLocationUnit,
    productLocationShelf,
    moveToLocationRowD2,
    moveToLocationUnitD2,
    moveToLocationShelfD2,
    quantityToMove,
  ].includes("");

  return (
    <>
      <div className="add-main-container">
        <FormikProvider value={formik}>
          <Form>
            {/*Start For Location */}
            <div className="quantity-adjust-title">
              <span>From location</span>
            </div>
            {tabValue === tabType.move ? (
              <ReturnToGroup
                setSingleLocationData={(locationData) => {
                  if (locationData?.row) {
                    dispatch(getWarehouseLocationsClear());
                    dispatch(fromLocationShelfClear());
                    setFieldValue("productLocationRow", locationData?.row?.id);
                    setFromRowName(locationData?.row?.name);
                  }
                  if (locationData?.unit) {
                    dispatch(getWarehouseLocationsClear());
                    dispatch(fromLocationShelfClear());
                    setFromUnitName(locationData?.unit?.name);
                    setFieldValue(
                      "productLocationUnit",
                      locationData?.unit?.id
                    );
                  }
                  if (locationData?.shelf) {
                    setFromShelfName(locationData?.shelf?.name);
                    setFieldValue(
                      "productLocationShelf",
                      locationData?.shelf?.id
                    );
                  }
                }}
                toLocationShelfDetails={{
                  shelfID: values.moveToLocationShelfD2,
                }}
                getLocationProductWise
                cartEditModalData={false}
                editReturnTo={[]}
                disabledMultiple
                disableReturnReplace
                tabValue={tabValue}
                units={units}
                setUnits={setUnits}
                unitsErrors={unitsErrors}
                setUnitsErrors={setUnitsErrors}
                unitsLoading={unitsLoading}
                setUnitsLoading={setUnitsLoading}
                shelves={shelves}
                setShelves={setShelves}
                shelvesErrors={shelvesErrors}
                setShelvesErrors={setShelvesErrors}
                shelvesLoading={shelvesLoading}
                setShelvesLoading={setShelvesLoading}
              />
            ) : null}
            {/*End For Location */}

            {/*Start To Location */}
            <div className="quantity-adjust-title">
              <span>To location</span>
            </div>
            {tabValue === tabType.move ? (
              <ReturnToGroup
                setSingleLocationData={(locationData) => {
                  if (locationData?.row) {
                    setToRowName(locationData?.row?.name);
                    setFieldValue("moveToLocationRowD2", locationData?.row?.id);
                    dispatch(getShelfClear());
                  }
                  if (locationData?.unit) {
                    setToUnitName(locationData?.unit?.name);
                    setFieldValue(
                      "moveToLocationUnitD2",
                      locationData?.unit?.id
                    );
                    dispatch(getShelfClear());
                  }
                  if (locationData?.shelf) {
                    setToShelfName(locationData?.shelf?.name);
                    setFieldValue(
                      "moveToLocationShelfD2",
                      locationData?.shelf?.id
                    );
                  }
                }}
                cartEditModalData={false}
                editReturnTo={[]}
                toLocationShelfDetails={{
                  shelfID: values.productLocationShelf,
                }}
                disabledMultiple
                disableReturnReplace
                tabValue={tabValue}
                units={units}
                setUnits={setUnits}
                unitsErrors={unitsErrors}
                setUnitsErrors={setUnitsErrors}
                unitsLoading={unitsLoading}
                setUnitsLoading={setUnitsLoading}
                shelves={shelves}
                setShelves={setShelves}
                shelvesErrors={shelvesErrors}
                setShelvesErrors={setShelvesErrors}
                shelvesLoading={shelvesLoading}
                setShelvesLoading={setShelvesLoading}
              />
            ) : null}
            {/*End To Location */}
            <div className="quantity-adjust-title">Quantity to Move</div>
            <PosInput
              variant="outlined"
              id="outlined-basic"
              name="quantityToMove"
              disabled={isStockMoveLoading}
              value={quantityToMove}
              onChange={(e) => {
                const addQty = decimal18RegEx.test(e.target.value);
                if (!addQty) {
                  e.target.value = e.target.value.slice(0, -1);
                }
                if (addQty) {
                  setFieldValue("quantityToMove", e.target.value);
                  quantityToMoveOnchange(e.target.value, setFieldValue);
                } else if (!e.target.value) {
                  setFieldValue("quantityToMove", "");
                }
              }}
              className="quantity-input input-focus"
              onFocus={(e) => e.target.select()}
            />
            <ErrorMessage name="quantityToMove" />
            <div className="quantity-adjust-title">
              Reason{" "}
              <span className="optional adjust-reason-optional-text">
                (optional)
              </span>
            </div>
            <PosInput
              variant="filled"
              id="filled-basic"
              autoComplete="off"
              InputProps={{ disableUnderline: true }}
              className="quantity-input"
              type="text"
              name="moveReason"
              onChange={(e) => setFieldValue("moveReason", e.target.value)}
              value={moveReason}
            />
            <div className="adjust-stock-btn-div">
              <Button
                label={moveStock}
                accent={true}
                disabled={disableButtons}
                icon={<CheckIcon />}
                classes={`${
                  isStockMoveLoading ? "button-is-loading" : "adjust-stock-btn move-stock-btn"
                }`}
              />
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};
export default Move;
