import { useState, useEffect, useRef } from 'react';

import IconButton from 'apps/admin/components/Elements/IconButton';
import MaterialIcon from 'apps/pos/Common/uiCore/MaterialIcon';

import './InlineNote.scss';

const InlineNote = ({ note, type = 'item' }) => {
    const MAX_LENGTH = 30;
    const overflow = note.length > MAX_LENGTH;
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        if (!overflow) return;
        open ? ref.current.showModal() : ref.current.close();
    }, [open, overflow]);

    const onClose = () => setOpen(false);

    useEffect(() => {
        if (!overflow) return;
        ref.current?.addEventListener('close', onClose);

        return () => ref.current?.removeEventListener('close', onClose);
    }, [overflow]);

    const SEE_FULL_NOTE_TITLE = overflow ? `See full ${type === 'item' ? 'item ' : 'rep '}note` : '';

    if (!note || !note.length) return null;

    return (
        <>
            <button className='inline-note' onClick={() => setOpen(true)} disabled={!overflow} title={SEE_FULL_NOTE_TITLE ?? null}>
                <span style={{ '--max-length': MAX_LENGTH }}>
                    {note}
                </span>
                {overflow ? <MaterialIcon icon='expand_more' size='20' /> : null}
            </button>
            {
                overflow ? (
                    <dialog ref={ref} className='inline-note-viewer'>
                        <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 20}}>
                            <h3>{type === 'item' ? 'Item' : 'Rep'} Note</h3>
                            <IconButton icon="close" secondary="true" onClick={() => setOpen(false)} />
                        </header>
                        <p className="full-note">{note}</p>
                    </dialog>
                ) : null
            }
        </>
    )
}

export default InlineNote;