import { useSelector } from "react-redux";

import { PosImage, PosPageHeading } from "apps/pos/Common/uiCore";
import { Button } from "components";
import HandleLongData from "apps/pos/Common/HandleLongData";

import { deleteDraftProps } from "./deleteDraftType";
import { alertMessage, staticData } from "constants/constant";

import images from "constants/images";
import { PosResponseProps, RootState } from "models";

const DeleteDraftComponent = ({
    deleteAllPopUp,
    deleteCartId,
    clearAllDraftAPI,
    setDeletePopUp,
    clearCartIdAPI,
    setCustomerDraftDelete,
    setIsDeleteClick,
    setDeleteAllPopUp
  }: deleteDraftProps) => {

  const { deleteAllDraftConfirmation} = alertMessage;
  const { canNotUndoAction } = staticData

  const handleKeepItFunction = () =>{
    setDeletePopUp(false);
    setCustomerDraftDelete(false);
    setIsDeleteClick(false);
    setDeleteAllPopUp(false);
  }
    const { isCartDraftLoading } = useSelector<RootState, PosResponseProps>(
      (state) => state.pos
    );
    const { trashRedOpen } = images;
     
    const clearCartIdDraft = () => {
      !isCartDraftLoading && clearCartIdAPI()
    }
    return(
        <>
        <div className="pricing-modal-header">
          <PosPageHeading heading="Confirm delete" blackHeading />
        </div>
        <div className="select-ws-text drawer-heading-spacing">
          {deleteAllPopUp ? (
            <h3 className="select-schedule-list">
              {deleteAllDraftConfirmation} <br />
              <br />
              {canNotUndoAction}
            </h3>
          ) : (
            <h3 className="select-schedule-list">
              {`Are you sure you want to delete ${deleteCartId?.jobName? "" : "this"} draft`} {deleteCartId?.jobName ? <HandleLongData jobName={deleteCartId?.jobName} />: null} from saved drafts? <br />
              <br />
              {canNotUndoAction}
            </h3>
          )}
        </div>
        <div className="shipping-btns delete-drawer-btn-spacing flex-row-reverse">
          {deleteAllPopUp ? (
            <Button
              className="modal-select-default-btn ship-btn delete delet-draft-btn border-0 button-focus"
              type="button"
              onClick={clearAllDraftAPI}
            >
              <div className="select-default-text">
                <div>
                  <PosImage src={trashRedOpen} alt="red-trash-icon" className="trash-icon" />
                </div>
                <div className="default-schedule-heading">
                  <span className="int-18-reg-bold d-flex align-items-center">
                    Yes, delete all
                  </span>
                </div>
              </div>
        </Button>
          ) : (
              <Button
                  className={`modal-select-default-btn ship-btn delete delet-draft-btn border-0 button-focus${isCartDraftLoading ? "disable-delete-draft" : ""}`}
              onClick={clearCartIdDraft}
            >
              <div className="select-default-text">
                <div>
                  <PosImage src={trashRedOpen} alt="red-trash-icon" />
                </div>
                <div className="default-schedule-heading">
                  <span className="int-18-reg-bold d-flex align-items-center">
                    Yes, delete
                  </span>
                </div>
              </div>
            </Button>
          )}
          <Button
            type="button"
            className="modal-select-default-btn ship-btn keep-draft-btn border-0 button-focus"
            onClick={handleKeepItFunction}
          >
            <div className="select-default-text">
              <div>
                <i className="fas fa-arrow-left"></i>
              </div>
              <div className="default-schedule-heading">
                <span className="int-18-reg-bold">No, keep it</span>
              </div>
            </div>
          </Button>
        </div>
      </>
    )
}

export default DeleteDraftComponent