import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";

import { deliverEntireShipmentUrl } from "./constantUrl";
import { stopLoading } from "utils/utils";

interface DeliverEntireShipmentInStorageProps {
    handlerID: number;
    orderID: number;
    shipMethod: string;
    trackingNum: string;
    referenceNum: string;
    tenantPaidShipFee: number;
    deliveryTime: string;
}

export const useDeliverEntireShipment = () => {
  const { mutate, isLoading, data, error, isSuccess } = useMutation(
    async (payload: DeliverEntireShipmentInStorageProps) => {
      return deliverEntireShipmentUrl(payload);
    },
    {onSuccess : stopLoading,
      onError : stopLoading
});

  const deliverEntireShipmentFn = useCallback(
    (payload) => {
      mutate(payload);
    },
    [mutate]
  );

  return {
    deliverEntireShipmentFn,
    isLoading,
    data,
    error,
    isSuccess,
  };
};
