import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Drawer } from "@material-ui/core";
import _ from "loadsh";
import "@fontsource/inter";
import { setTimeout } from "timers";

import { PosPageHeading, PosInput, PosIconButton, Container } from "apps/pos/Common/uiCore"
import { Button } from "components";
import SavedOrdersFound from "apps/pos/SavedOrdersFound";

import { drawerUseStyles } from "theme/GlobalStyles";
import { ClearIcon, ArrowBackRoundedIcon, SearchIcon, ArrowUpwardIcon, SkipNextIcon } from "constants/icons";
import { Paths } from "enums";
import { scrollOnContent } from "utils/utils";
import { getContentById, keys } from "utils/helper";
import { RootState } from "models";

import { loadCartRequest, setPosFlowData } from "sagas/persistReducer/actions";
import {
  savedDraftsReset,
  arrowDownIndex,
  arrowUpIndex,
  takersRequest,
  arrowReset
} from "sagas/pos/actions";

import useStyles from "./style";

const SelectTakerModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [enterPress, setEnterPress] = useState<boolean>(false);
  const {
    takerDetails,
    draftLoadError,
    draftLoadMessage,
    selectedIndex,
    isLoading,
  } = useSelector<RootState, any>((state) => state.pos);
  const { posFlowData } = useSelector<RootState, any>(
    (state) => state.persistReducer
  );
  const myRef = useRef(null);
  const searchBuildingRef = useRef<HTMLInputElement>();
  const navigate = useNavigate();

  const [takerData, setTakerData] = useState<any>({});
  const [draftCartModal, setDraftCartModal] = useState(false);
  const [changeHeadingText, setChangeHeadingText] = useState<boolean>(false);

  const [downPress, setDownPress] = useState<boolean>(false);
  const [upPress, setUpPress] = useState<boolean>(false);

  const { t, T, uparrow, downarrow, enter } = keys;

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [availTakers,setAvailTakers] = useState([])

  useEffect(() => {
    if (!searchString) {
      setSearchData(takerData?.data);
    }
  }, [searchString]);

  const recentUsers = JSON.parse(localStorage.getItem("recentUser")) || [];
  
  useEffect(()=>{
    if(takerDetails?.data){
      const newData = takerDetails?.data.filter(user => recentUsers.some(recentTaker => user.personId === recentTaker.personId)
        );
      setAvailTakers(newData);
    }
  },[takerDetails])
  
  useEffect(() => {
    if (enterPress) {
      if (searchData?.length) {
        selectFromAllUsers(searchData[selectedIndex]);
      } else {
        if (selectedIndex > -1 && selectedIndex < availTakers.length) {
          selectFromAllUsers(availTakers[selectedIndex]);
          setSelectTaker && setSelectTaker(false);
        }
        if (
          (selectedIndex > -1 && selectedIndex > availTakers.length) ||
          (selectedIndex > -1 && selectedIndex === availTakers.length)
        ) {
          selectFromAllUsers(
            takerData?.data[selectedIndex - availTakers.length]
          );
          setSelectTaker && setSelectTaker(false);
        }
        setEnterPress(false);
      }
    }
  }, [enterPress]);

  useEffect(() => {
    if (draftLoadMessage) {
      setDraftCartModal(true);
      setState({ ...state, right: true });
    }
  }, [draftLoadMessage]);

  useEffect(() => {
    if (!takerDetails?.data?.length) dispatch(takersRequest());
  }, []);



  useEffect(() => {
    if (draftLoadError) {
      const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));
      const currentTaker = JSON.parse(localStorage.getItem("taker"));
      const preparedData = {
        stockWarehouseId: currentWorkspace.stockWarehouseId,
        customerId: posFlowData?.customer?.customerId,
        takerId: currentTaker?.personId,
        cartId: null,
      };
      dispatch(loadCartRequest(preparedData));
      setTimeout(() => {
        setDraftCartModal(false);
        navigate(Paths.posCart);
        dispatch(savedDraftsReset());
      }, 1000);
    }
  }, [draftLoadError]);

  useEffect(() => {
    setTakerData(takerDetails);
  }, [takerDetails]);

  async function selectFromAllUsers(taker: any) {
    const data = {
      ...posFlowData,
      taker,
    };
    if (taker) {
      localStorage.setItem("taker", JSON.stringify(taker));
      const oldRecentUser =
        JSON.parse(await localStorage.getItem("recentUser")) || [];
      if (!_.find(oldRecentUser, taker)) {
        oldRecentUser.push(taker);
        localStorage.setItem("recentUser", JSON.stringify(oldRecentUser));
      } else {
        const oldRecentUser =
          JSON.parse(await localStorage.getItem("recentUser")) || [];
        const filterRecentUser =
          oldRecentUser?.length &&
          oldRecentUser?.filter((item) => item.personId !== taker?.personId);
        filterRecentUser.push(taker);
        localStorage.setItem("recentUser", JSON.stringify(filterRecentUser));
      }
      dispatch(setPosFlowData(data));
      localStorage.setItem("taker", JSON.stringify(taker));
      props.setState({ ...state, right: false });
    }
  }

  function selectLastTaker() {
    let taker = null;
    if (availTakers.length) {
      taker = availTakers[availTakers.length - 1];
    }
    const data = {
      ...posFlowData,
      taker,
    };
    dispatch(setPosFlowData(data));

    props.setState({ ...props.state, right: false });
    if (props.state) {
      props.setState({ ...props.state, right: false });
    }
    setSelectTaker && setSelectTaker(false);
  }

  const takerRowRef = useRef<any>();
  const takerRowSecondRef = useRef<any>();

  const scrollToSetting = (selectedIndex) => {
    let scrollIndex;
    if (selectedIndex < recentUsers.length) {
      scrollIndex = selectedIndex;
    } else {
      scrollIndex = selectedIndex - recentUsers.length;
    }

    const elementId =
      selectedIndex < recentUsers?.length
        ? document?.getElementById(`takerRowRef${scrollIndex}`)
        : document?.getElementById(`takerRowSecondRef${scrollIndex}`);

    elementId?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  useEffect(() => {
    if (downPress) {
      const dataLength = recentUsers?.length
        ? recentUsers?.length + takerData?.data?.length
        : takerData?.data?.length;
      scrollToSetting(selectedIndex + 1);
      dispatch(arrowDownIndex(dataLength));
      setDownPress(false);
    }
  }, [downPress]);

  useEffect(() => {
    if (upPress) {
      if (selectedIndex > -1) {
        scrollToSetting(selectedIndex);
      }
      setUpPress(false);
    }
  }, [upPress]);

  function handleKeyPress(e) {
    const { key, keyCode } = e;
    if (recentUsers.length) {
      if ((key === T || keyCode === t) && document?.activeElement !== searchBuildingRef.current) {
        setSelectTaker && setSelectTaker(false);
        setState({ ...state, right: false });
      }
    }
    if (keyCode === enter) {
      setEnterPress(true);
    }
  }

  const handleKeyUp = (e) => {
    const { keyCode } = e;
      if (keyCode === downarrow) {
        setDownPress(true);
      } else if (keyCode === uparrow) {
        dispatch(arrowUpIndex());
        setUpPress(true);
      }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    document.addEventListener("keyup", handleKeyUp);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const drawerClass = drawerUseStyles();

  const { newCustomerTakerClass, setSelectTaker } = props;

  useEffect(() => {
    if (!props.newOrderTakerDrawer) {
      scrollOnContent();
    }
  }, []);

  const takerClick = (taker) => {
    selectFromAllUsers(taker)
    setSelectTaker && setSelectTaker(false)
  }

  // start
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )
  const contentSec = getContentById()

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, [contentSec]);

  useEffect(() => {
    const elHeight = document.getElementById("drawer-header")?.clientHeight;
    if (contentSec && matches) {
      getContentById().style.paddingTop = `${elHeight + 20}px`;
    }
    else if (contentSec && !matches) {
      getContentById().style.paddingTop = '0px';
    }
  }, [contentSec, matches]);
  // end

  const handleSearchTaker = (value) => {
    if (
      !value.includes("  ") &&
      value.charAt(0) !== " "
    ) {
      if (value) {
        setChangeHeadingText(true);
      }
      dispatch(arrowReset());
      setSearchString(value);
      setSearchData(
        takerData?.data.filter((data) =>
          data?.fullName
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      );
    }
  }

  return (
    <Box
      role="presentation"
      className={`${newCustomerTakerClass ? "new-customer-taker-modal" : ""
        } taker-modal-section h-100`}
    >
      {props.onCloseDrawer &&
        <PosIconButton
          className="back-next-btn-small btn responsive-back-btn pos-icon-back-btn"
          onClick={props.onCloseDrawer}
          tabIndex={-1}
        >
          <ClearIcon className="back-icon" />
        </PosIconButton>}
      <div ref={myRef}></div>
      <Container
        className="modal-content-style modal-container-styling"
        ref={props.drawerRef}
        id="noScrollContent"
      >
        <main>
          <div>
            <div>
              <div className="drawer-static-header" id="drawer-header">
                <div className="pricing-modal-header">
                  <PosPageHeading
                    heading="Who is processing this order?"
                    grayHeading
                  />
                  <h1 className="pricing-schedule-heading">
                    <PosPageHeading heading="Select Taker" blackHeading />
                  </h1>
                </div>
                {availTakers?.length ? (
                  <div className="modal-select-default-btn schedule-section">
                    <div className="select-default-text">
                      <div>
                        <SkipNextIcon />
                      </div>
                      <div
                        className="default-schedule-heading"
                        onClick={selectLastTaker}
                      >
                        <span className="int-14-reg-white">
                          Keep current taker
                        </span>
                        <span className="schedule-number-info">
                          {JSON.parse(localStorage.getItem("taker"))?.fullName}
                        </span>
                      </div>
                    </div>
                    <div>
                      <span className="schedule-shortcut">T</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {takerDetails?.data?.length &&
                  <>
                    <div className={`search-with-close-input ${classes.takerDrawerSearch}`}>
                      <div className="search-icon">
                        <SearchIcon />
                      </div>
                      <PosInput
                        variant="outlined"
                        autoFocus
                        id="outlined-basic"
                        className="double-bordered-input"
                        placeholder="Search taker"
                        inputRef={searchBuildingRef}
                        value={searchString}
                        onChange={(e) => handleSearchTaker(e.target.value)}
                      />
                      <div
                        className="close-icon"
                        onClick={() => setSearchString("")}>
                        <ClearIcon />
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
            {takerDetails?.data?.length ? (
              <>
                <div
                  className="modal-subheading mb-0 mt-0 position-relative drawer-bottom-sec-styling"
                  id="shippingScrollStyling"
                >
                  {!searchData?.length ||
                    takerData?.data?.length === searchData?.length ? (
                    <div className="pricing-frequently">
                      {availTakers?.length ? (
                        <span className="modal-subheading mt-0 mb-0">
                          RECENT
                        </span>
                      ) : (
                        <></>
                      )}
                      {availTakers?.length ? (
                        availTakers?.map((taker, index) => {
                          return (
                            <div
                              onClick={() => {
                                selectFromAllUsers(taker)
                                setSelectTaker(false)
                              }}
                              key={index}
                              ref={takerRowRef[index]}
                              id={`takerRowRef${index}`}
                              className={
                                index === selectedIndex
                                  ? "frequently-used-schedule frequently-used-schedule-index"
                                  : "frequently-used-schedule"
                              }
                            >
                              <h3 className="select-schedule-list schedule-list-styling">
                                {taker.fullName}
                              </h3>
                              <i className="fas fa-arrow-right right-icon"></i>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="all-pricing-schedules mt-0">
                    {!searchData?.length && changeHeadingText ? (
                      <span className="modal-subheading mb-0">
                        No search results found
                      </span>
                    ) : (
                      <span className="modal-subheading mb-0">ALL USERS</span>
                    )}
                    <div className="all-schedules-names mt-0">
                      {takerData?.data?.length && searchData?.length
                        ? searchData?.map((taker, index) => {
                          return (
                            <div
                              onClick={() => {
                                selectFromAllUsers(taker)
                                setSelectTaker && setSelectTaker(false)
                              }}
                              key={index}
                              ref={takerRowSecondRef[index]}
                              id={`takerRowSecondRef${index}`}
                              className={
                                index + availTakers?.length === selectedIndex
                                  ? "frequently-used-schedule frequently-used-schedule-index schedule-list-div"
                                  : "frequently-used-schedule schedule-list-div"
                              }
                            >
                              <h3 className="select-schedule-list schedule-list-styling">
                                {taker.fullName}
                              </h3>
                              <i className="fas fa-arrow-right right-icon"></i>
                            </div>
                          );
                        })
                        : takerData?.data?.length &&
                        !searchString?.length &&
                        takerData?.data?.map((taker, index) => {
                          return (
                            <div
                              key={index}
                              ref={takerRowSecondRef[index]}
                              id={`takerRowSecondRef${index}`}
                              onClick={() => takerClick(taker)}
                              className={
                                index + availTakers?.length === selectedIndex
                                  ? "frequently-used-schedule frequently-used-schedule-index"
                                  : "frequently-used-schedule"
                              }
                            >
                              <h3 className="select-schedule-list">
                                {taker.fullName}
                              </h3>
                              <i className="fas fa-arrow-right right-icon"></i>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="back-to-top-section">
                    <Button
                      className="back-to-top-btn bg-transparent button-focus-class"
                      onClick={() => myRef.current.scrollIntoView()}
                    >
                      <ArrowUpwardIcon />
                      <span className="back-to-top-text">Jump back to top</span>
                      <span> </span>
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              !isLoading && (
                <span
                  className="modal-subheading mb-0 mt-0"
                  style={{ fontSize: 12, textAlign: "center" }}
                >
                  No taker found
                </span>
              )
            )}
          </div>
        </main>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          className={`${classes.drawer} ${drawerClass.drawer} default-drawer-style no-cart-drawer main-drawer-classes drawer-scrollbar-style`}
        >
          <Box sx={{ width: 750 }} role="presentation">
            <PosIconButton
              className="back-next-btn-small btn back-responsive-btn responsive-back-btn pos-icon-back-btn"
              onClick={() => setState({ ...state, right: false })}
            >
              <ArrowBackRoundedIcon className="back-icon" />
            </PosIconButton>
            {draftCartModal ? (
              <SavedOrdersFound
                state={state}
                setState={setState}
              />
            ) : (
              <></>
            )}
          </Box>
        </Drawer>
      </Container>
    </Box>
  );
};

export default SelectTakerModal;
