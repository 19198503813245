import { Grid } from "@material-ui/core";

import { PosIconButton } from "apps/pos/Common/uiCore";
import { IconButton } from 'apps/springboard/Component/SBItems/elements';
import { Icon } from "apps/springboard/Component/SBItems/elements";

import { springBoardText } from "constants/constant";

const QuoteInfoSub = ({ value, isStarred, isItemStarred, handleStarButtonClick, datakey ,title}) => {
  const { removeFromStar , addToStar } = springBoardText
  const checkIsStar = isStarred || isItemStarred 
  return (
    <>
      {value ? <Grid container className="report-div">
        <Grid item xs={12} sm={4}>
          <PosIconButton
            className="star-icon-report"
            color="primary"
            height="1.875rem"
            width="1.875rem"
            onClick={() => handleStarButtonClick(datakey, value, title)}
            title={checkIsStar ? removeFromStar : addToStar }
          >
            <IconButton name={checkIsStar ? "star" : "star_border"} iconSize={20} size={30} type='tertiary' tabIndex={-1}/>
          </PosIconButton>
          <span>{datakey}</span>
        </Grid>
        <Grid item xs={12} sm={8} className="pl-4">
          {typeof value === "number" ? (
            <span className="d-flex boolean-span align-items-center">
              <Icon name='check_circle' color='#217F39' size={18} />
              <span>Yes</span>
            </span>
          ) : (
            <span>{value}</span>
          )}
        </Grid>
      </Grid> : null}
    </>
  )
}

export default QuoteInfoSub
