import { endpoints } from "apps/springboard/endpoints";
import service from "../config";
import serviceV2 from "services/config2";

export const getDueDateEditFlag = (personId: number) => {
  return service.get(endpoints.revisedDueDate(personId));
};

export const revisedDueDateQuote = (payload) => {
  return service.post(endpoints.verifyDueDate, payload);
};

export const updateCloseDate = (data) => {
  return service.put(endpoints.setCloseDate(data));
};

export const activateQuote = (params) => {
  return service.put(endpoints.expirationDateUrl(params.jobQuoteID), {
    ...params,
  });
};

export const getSubQuotes = (parentQuoteId) => {
  return serviceV2
    .get(endpoints.setSubQuotes(parentQuoteId))
    .then((response) => {
      return response.data;
    });
};
