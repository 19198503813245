import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Drawer } from "@material-ui/core";
import { drawerUseStyles } from "theme/GlobalStyles";
import "@fontsource/inter";

import { PosImage } from "apps/pos/Common/uiCore"
import SelectTakerModal from "../SelectTakerModal/SelectTakerModal";
import SelectWorkStation from "../SelectWorkStation";
import SavedOrdersFound from "../SavedOrdersFound";
import AddNewCustomer from "../AddNewCustomer/AddNewCustomer";
import Button from "../Common/Button/Button";

import images from "constants/images";
import { PosResponseProps, RootState } from "models";

import {
  savedDraftsReset,
} from "sagas/pos/actions";
import { addNewCustomerClear } from "sagas/persistReducer/actions";

import "./drawers.scss";

const WorkStationDrawer = ({
  setSelectedWorkspace,
  addCustomerFormModal,
  draftCartModal,
  currentWorkspace,
  drawerClass,
  state,
  setState,
  open,
  openTaker,
  startLoader,
  currentTakerInfoStorage,
}) => {
  const { savedDrafts } = useSelector<RootState, PosResponseProps>((state) => state.pos);
  const classes = drawerUseStyles();
  const dispatch = useDispatch();

  const draftDrawer = useRef<any>();
  const { closeIcon } = images;

  const handleClose = () => {
    setState({ ...state, right: false });
    dispatch(addNewCustomerClear());
    dispatch(savedDraftsReset());
  }

  return (
    <Drawer
      anchor={"right"}
      open={open}
      className={`${drawerClass} ${classes.drawer} default-drawer-style no-cart-drawer main-drawer-classes drawer-scrollbar-style`}
      ref={draftDrawer}
      onClose={handleClose}
      id="cartDrawerContent"
    >
      <Box sx={{ width: 600 }} role="presentation">
        {currentWorkspace && currentTakerInfoStorage ? (
          <>
            <Button
              classes="back-next-btn-small back-responsive-btn responsive-back-btn"
              onClick={handleClose}
              tabIndex={-1}
            >
              <PosImage src={closeIcon} alt="back" />
            </Button>
            {addCustomerFormModal ? (
              <AddNewCustomer state={state} setState={setState} />
            ) : (draftCartModal && savedDrafts?.data?.length) ? (
              <SavedOrdersFound
                state={state}
                setState={setState}
                draftDrawer={draftDrawer}
              />
            ) : (
              openTaker && (
                <SelectTakerModal state={state} setState={setState} />
              )
            )}
          </>
        ) : (
          <SelectWorkStation
            setSelectedWorkspace={setSelectedWorkspace}
            state={state}
            setState={setState}
            startLoader={startLoader}
            currentWorkspace={currentWorkspace}
            currentTakerInfoStorage={currentTakerInfoStorage}
          />
        )}
      </Box>
    </Drawer>
  );
};

export default WorkStationDrawer