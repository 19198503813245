import {  FormikProvider, useFormik } from "formik";
import { Fragment, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { PosModel } from "apps/pos/Common/uiCore";
import Button from "components/SPButton/Button";
import { IconButton } from "../SBItems/elements";
import {  springBoardText } from "constants/constant";

import { sendMessageValidation } from "apps/springboard/utils/validationSchema";
import { useGetCustomerEmail } from "apps/springboard/QuoteMessages/hooks/useGetCustomerEmail";
import { useSendMessage } from "apps/springboard/QuoteMessages/hooks/useSendMessage";
import { getMessagesRequest } from "sagas/quoteDetail/actions";

import "apps/springboard/Component/SendMessage/sendMessage.scss";
import { customerQuoteData } from "sagas/quoteDetail/selector";

interface SendMessageProps {
  open: boolean;
  quoteId: number;
  handleCloseModel: () => void;
}

export const SendMessage = ({
  open,
  handleCloseModel,
  quoteId,
}: SendMessageProps) => {
  const dispatch = useDispatch();
  const { sendMessageToCustomer,toCustomerEmail,Subject,Message } = springBoardText;
  const customerQuoteDetails = useSelector(customerQuoteData);
  const { creatorID, customerEmail } = customerQuoteDetails || {};
  const { callSendMessage, isSendMessageSuccess } = useSendMessage();
  const { setCustomerEmailId, toSendEmail } = useGetCustomerEmail();
  const { data: fromEmail } = toSendEmail || {};

  useEffect(() => {
    if (creatorID) {
      setCustomerEmailId(creatorID);
    }
  }, [creatorID]);

  useEffect(() => {
    if (isSendMessageSuccess) {
      handleCloseModel();
      dispatch(getMessagesRequest(quoteId));
    }
  }, [isSendMessageSuccess]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: customerEmail,
      subject: "",
      message: "",
    },
    validationSchema: sendMessageValidation,
    onSubmit: (values) => {
      const messagePayload = {
        jobQuoteID: quoteId,
        fromEmail: fromEmail,
        toEmail: values.email,
        subj: values.subject,
        msgBody: values.message,
        msgTypeID: 1,
        senderID: creatorID,
      };
      callSendMessage(messagePayload);
    },
  });
  const { values, setFieldValue, handleSubmit } = formik;

  const cancelForm = () => {
    handleCloseModel();
  };

  return (
    <Fragment>
      <PosModel
        open={open}
        onClose={handleCloseModel}
        className="set-customer-code-modal"
        dialogClassName="set-send-message-modal-container"
        dialogHeader={
          <div className="modal-header pl-4">
            <span className="modal-title">
              <div className="pt-2">
                <h3 className="f20-Inter-500-sab mb-0 mb-0">
                  {sendMessageToCustomer}
                </h3>
              </div>
            </span>
            <IconButton
              name="close"
              className="close-icon"
              type="tertiary"
              onClick={handleCloseModel}
            />
          </div>
        }
      >
        <FormikProvider value={formik}>
          <div className="send-message">
            <div className="send-message-email">
              <span className="form-field-title mb-2">{toCustomerEmail}</span>
              <TextField
                type="email"
                fullWidth
                id="email"
                variant="filled"
                name="email"
                value={values.email}
                className="email-input"
                onChange={(e) => {
                  setFieldValue("email", String(e.target.value));
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                required
              />
            </div>
            <div className="send-message-subject">
              <span className="form-field-title mb-2">{Subject}</span>
              <TextField
                autoFocus
                required
                fullWidth
                id="subject"
                variant="filled"
                name="subject"
                value={values.subject}
                onChange={(e) => {
                  setFieldValue("subject", String(e.target.value));
                }}
                className="subject-input"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className="send-message-message">
              <span className="form-field-title mb-2">{Message}</span>
              <textarea
                required
                name="message"
                id="message"
                className="new-note-input"
                onChange={(e) => {
                  setFieldValue("message", String(e.target.value));
                }}
                value={values.message}
              />
            </div>
            <div className="d-flex gap-2 justify-content-end mt-3 mb-3">
              <Button label="Cancel" onClick={cancelForm} />
              <Button label="Send" type="primary" onClick={handleSubmit} />
            </div>
          </div>
        </FormikProvider>
      </PosModel>
    </Fragment>
  );
};
