import moment from "moment";

import Tooltip from '@mui/material/Tooltip';
import { TableColumn } from "components/TableColumn/TableColumn";

import { getComparator } from "utils/helper";
import { PaginationFormat } from "constants/enum";
import { isoToFormat, returnFixedOrEmptyValue, showDot } from "utils/utils";

export const EnhancedTableBody = ({
  salesHistory,
  stableSort
}) => {
  const { Asc, Calories } = PaginationFormat;
  return salesHistory?.length ? (
    stableSort(salesHistory, getComparator(Asc, Calories))?.map(
      (row, index) => {
        const { invoiceDate, companyName, posCustCode, cityStateZip, phone, quoteId, quoteDate, orderId, invoicedQty, salesPrice, itemMuName, itemMU, extendedPrice, profitPct, warehouseLabel , poNum , jobName } = row

        // Split the string by comma
        const parts = cityStateZip ? cityStateZip.split(',') : "";

        // Extract the first two parts (city and state)
        const cityState = parts ? parts.slice(0, 2).join(',') : "";

        // Remove all non-digit characters from the string
        const digitsOnly = phone ? phone.replace(/\D/g, '') : "";

        // Extract the desired parts
        const areaCode = digitsOnly ? digitsOnly.slice(1, 3) : "";
        const prefix = digitsOnly ? digitsOnly.slice(3, 6) : "";
        const lineNum = digitsOnly ? digitsOnly.slice(6, 11) : "";

        const phoneNumber = areaCode + " " + prefix + "  " + lineNum;
        const poNumber = showDot(poNum, 10);
        const job = showDot(jobName, 10);
        return (
          <div className="table-body-row" key={index}>
            <TableColumn className="inv-date-col" value={moment(invoiceDate, "DD-MM-YYYY").format("MM/DD/YY")} />
            <TableColumn className="customer-col-width">
              <div className="flex-1">{companyName} </div>
              {posCustCode ? <div className="flex-1 customer-code">{posCustCode}</div> : null}
              <div className="flex-1 customer-code">{cityState ? cityState : ""} {phoneNumber ? phoneNumber : ""}</div>
            </TableColumn>
            <Tooltip title={poNum} placement="top">
              <div className={`table-body-col`}>
                {poNumber || ""}
              </div>
            </Tooltip>
            <Tooltip title={jobName} placement="top">
              <div className={`table-body-col`}>
                {job || ""}
              </div>
            </Tooltip>
            <TableColumn value={quoteId || ""} />
            <TableColumn value={quoteDate ? isoToFormat(quoteDate,"MM/DD/YY") : ""} />
            <TableColumn value={orderId || ""} />
            <TableColumn value={invoicedQty || ""} />
            <TableColumn value= {returnFixedOrEmptyValue(salesPrice)} />
            <TableColumn value={itemMuName && itemMU ? `${itemMuName} (${itemMU})` : ""} />
            <TableColumn value={returnFixedOrEmptyValue(extendedPrice)} />
            <TableColumn value={returnFixedOrEmptyValue(profitPct)} />
            <TableColumn value={warehouseLabel || ""} />
          </div>
        );
      }
    )
  ) : (
    <div className="sales-history-no-data-found">
      No Data Found
    </div>
  )
}
