import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IProductsFilters } from "../types";



const initialState: IProductsFilters = {
  isProductFiltersSearch: false,
  getProductFiltersData: null,
  getProductFiltersDataError: null,
  filterData: null,
  optionsData: null,
  searchKeyword: null
};

export const productFiltersSlice = createSlice({
  name: "productFiltersSlice",
  initialState,
  reducers: {
    getProductFiltersStart: (state) => {
      state.getProductFiltersData = null;
      state.isProductFiltersSearch = true;
    },
    getProductFiltersSuccess: (state, action: PayloadAction<any>) => { // Will remove 'any' once we get proper data from BE
      state.getProductFiltersData = action.payload;
      state.isProductFiltersSearch = false;
    },
    getProductFiltersError: (state, action: PayloadAction<any>) => {  // Will remove 'any' once we get proper data from BE
      state.getProductFiltersDataError = action.payload;
      state.getProductFiltersData = null;
      state.isProductFiltersSearch = false;
    },
    setFilterRequest: (state, action) => {
      state.filterData = action.payload;
    },
    setOptionsRequest: (state, action) => {
      state.optionsData = action.payload;
    },
    setSearchKeyword: (state, action) => {
      state.searchKeyword = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  getProductFiltersStart,
  getProductFiltersSuccess,
  getProductFiltersError,
  setFilterRequest,
  setOptionsRequest,
  setSearchKeyword
} = productFiltersSlice.actions;

export default productFiltersSlice.reducer;
