import { ChangeEvent, useState } from "react";
import {
  FilledInput,
  ListSubheader,
  OutlinedInput,
  Select,
  SelectProps,
} from "@material-ui/core";
import { MenuItem } from "@mui/material";

import { title } from "constants/constant";
import { PosInput } from "apps/pos/Common/uiCore";

import useStyles from "./style";

interface Props {
  options?: { value: string | number; label: string }[];
  isSelectOption?: boolean;
  outlined?: boolean;
  isClassName?: boolean;
  isSearch?: boolean;
  placeholder?: string;
  autoFocus?:boolean
  onChange?: (
    e?:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | ChangeEvent<HTMLSelectElement>
  ) => void;
  isTwoOptionCategory?: boolean;
  tabIndex?:number
}

interface ItemProps {
  value: number;
  label: string;
}

const Placeholder = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

const PosSelect = ({
  options,
  onChange,
  value,
  name,
  variant,
  className,
  isClassName,
  outlined,
  placeholder,
  isSearch,
  isTwoOptionCategory,
  style,
  children,
  disabled,
  autoFocus,
  inputRef,
  id,
  tabIndex,
}: Props & SelectProps) => {
  const classes = useStyles();

  const [searchText, setSearchText] = useState("");
  const handleSearchChange = (e) => {
    setSearchText(e?.target?.value);
  };

  const handleUnwantedSelection = (e) => {
    e.stopPropagation()
  }

  const displayOptions =
    options?.length &&
    options?.filter((item: ItemProps) =>
      item?.label?.toLowerCase()?.includes(searchText?.toLowerCase())
    );

  return (
    <>
      {isTwoOptionCategory ? (
        <Select
          defaultValue={"DEFAULT"}
          className={
            isClassName
              ? className
              : `${classes.root} ${className ? className : ""}`
          }
          name={name}
          variant={variant}
          value={value}
          displayEmpty
          onChange={(data: React.ChangeEvent<HTMLInputElement>) => {
            onChange(data);
            setSearchText("");
          }}
          renderValue={
            value === ""
              ? () => (
                  <Placeholder>
                    {placeholder ? placeholder : "Select"}
                  </Placeholder>
                )
              : undefined
          }
          input={outlined ? <OutlinedInput /> : <FilledInput />}
          disableUnderline
          MenuProps={{
            classes: { paper: classes.select },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          style={style}
          ref={inputRef}
          id={id}
        >
          {children}
        </Select>
      ) : (
        <Select
          defaultValue={"DEFAULT"}
          autoFocus={autoFocus}
          className={
            isClassName
              ? className
              : `${classes.root} ${className ? className : ""}`
          }
          name={name}
          variant={variant}
          value={value}
          displayEmpty
          onChange={(data: React.ChangeEvent<HTMLInputElement>) => {
            onChange(data);
            setSearchText("");
          }}
          onClose={() => {
            setSearchText("");
          }}
          disabled={disabled}
          renderValue={
            value === ""
              ? () => (
                  <Placeholder>
                    {placeholder ? placeholder : "Select"}
                  </Placeholder>
                )
              : undefined
          }
          input={outlined ? <OutlinedInput /> : <FilledInput />}
          disableUnderline
          MenuProps={{
            autoFocus: true,
            classes: { paper: classes.select },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          style={style}
          ref={inputRef}
          id={id}
          tabIndex={tabIndex}
        >
            {isSearch ? (
          <div className={`${isSearch ? classes.searchBox : ""}`}>
              <div  onClick={(e) => {
                handleUnwantedSelection(e)
              }}>
                <ListSubheader  onClick={(e) => {
                    handleUnwantedSelection(e)
                  }}>
                  <PosInput
                    autoFocus
                    variant="outlined"
                    placeholder="SEARCH"
                    onChange={handleSearchChange}
                    value={searchText}
                    className={classes.selectSearch}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    onClick={(e) => {
                      handleUnwantedSelection(e)
                    }}
                  />
                </ListSubheader>
              </div>
          </div>
            ) : null}

          {displayOptions?.length ? (
            <option
              value="DEFAULT"
              disabled
              selected
              className="option-display"
            >
              {placeholder || "Select"}
            </option>
          ) : null}
          {isSearch ? (
            displayOptions?.length ? (
              displayOptions?.map((option, index) => {
                const { label, value } = option;
                return (
                  <MenuItem
                    key={`${label}-${index}`}
                    value={value}
                    className="select-menu-item"
                  >
                    {label}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem value="" disabled selected className="select-menu-item">
                {title.noDataFound}
              </MenuItem>
            )
          ) : options?.length ? (
            options?.map((option, index) => {
              const { label, value } = option;
              return (
                <MenuItem
                  key={`${label}-${index}`}
                  value={value}
                  className="select-menu-item"
                >
                  {label}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value="" disabled selected className="select-menu-item">
              {title.noDataFound}
            </MenuItem>
          )}
        </Select>
      )}
    </>
  );
};

export default PosSelect;
