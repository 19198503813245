import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';

import { storageItemsUrl } from './constantUrl';
import { stopLoading } from 'utils/utils';

export const useGetStorageItems = () => {
  const { mutate, isLoading, data } = useMutation(
    async ({shipmentId}: {shipmentId: number}) => {
      return storageItemsUrl({shipmentId}).then((response) => {
        return response.data;
      });
    },
    {onSuccess : stopLoading,
      onError : stopLoading
});

  const getShipmentDataFromShipmentId = useCallback(
    ({shipmentId}) => {
      mutate({shipmentId})
    },[mutate]
  )
  return { getShipmentDataFromShipmentId, storageItemsData: data?.data, getStorageItemLoading: isLoading };
};