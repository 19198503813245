import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { setConvertToExpressApi } from "apps/springboard/QuoteItems/request";
import { QueryKeys } from "./queryKeys";

export const useConvertToExpress = () => {
  const [jobLotQuoteId, setJobLotQuoteId] = useState(null);

  const { data } = useQuery(
    [QueryKeys.GET_convert_to_express, jobLotQuoteId],
    async () => {
      if (jobLotQuoteId) {
        return setConvertToExpressApi(jobLotQuoteId);
      }
    },
    {
      enabled: !!jobLotQuoteId,
    }
  );

  const setConvertToExpress = useCallback((data) => {
    setJobLotQuoteId(data);
  }, []);

  return { setConvertToExpress, showConvertToExpress: data };
};
