import { useQuery } from "@tanstack/react-query";
import { getTakers } from "apps/pos/pages/Drafts/request";
import { QueryKeys } from "apps/pos/pages/Drafts/hooks/queryKeys";

export const useGetTakers = () => {
  const { data } = useQuery([QueryKeys.getTakers], async () => {
    return getTakers();
  });

  return { takersDetails: data?.data || [] };
};
