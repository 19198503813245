
import { AnyAction } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import * as services from "services/index";
import * as actions from "./actions";
import * as constants from "./constants";


function* customerDetailInfoAsync(action: AnyAction) {
  try {
    const addNewCustomerInfoData = yield call(
      services.getCustomerDocsData,
      action.payload
    );

    yield put(
      actions.customerDocumentSuccess(addNewCustomerInfoData)
    );
  } catch (errors) {
    yield put(actions.customerDocumentError(errors.detail));
  }
}
function* customerDocumentWatcher() {
  yield takeLatest(
    constants.GET_CUSTOMER_DOCUMENT_START, customerDetailInfoAsync
  );
}

function* taxDetailInfoAsync(action: AnyAction) {
  try {
    const customerTaxDocData = yield call(
      services.getTaxDocsData,
      action.payload
    );

    yield put(
      actions.taxDocumentSuccess(customerTaxDocData)
    );
  } catch (errors) {
    yield put(actions.taxDocumentError(errors.detail));
  }
}
function* taxDocumentWatcher() {
  yield takeLatest(
    constants.GET_TAX_DOCUMENT_START, taxDetailInfoAsync
  );
}
export default function* customerDocumentSaga() {
  yield all([
    customerDocumentWatcher(),
    taxDocumentWatcher()
  ]);
}