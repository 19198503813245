import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { PosImage } from "apps/pos/Common/uiCore";
import { Button } from "components";

import images from "constants/images";

import { getTips } from "services";

import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";

import "./tips.scss";
import { supportLink } from "constants/staticData";

const TipsBox = () => {
  const [tips, setTips] = useState([]);
  const [page, setPage] = useState(1);
  const [moreTipsAvailable] = useState(false);
  const [tipsVisible, setTipsVisible] = useState(false);
  const [tipsExpanded, setTipsExpanded] = useState(() => {
    const expanded = localStorage.getItem("tipsExpanded");
    return expanded === "true";
  });
  const [tipsNudgeVisible, setTipsNudgeVisible] = useState(false);
  const [tipsValue, setTipsValue] = useState(
    window.location.pathname.substring(5)
  );

  const tipsNudgeTimer = useRef(null);
  const greeting = useRef(null);

  const getTipsData = (tipsValue, page) => {
    return getTips(tipsValue, page).then((result) => {
      setTips(result?.data);
    });
  };

  useEffect(() => {
    if (!tips) return;

    const observerOptions = {
      root: document.querySelector(".page-tips-viewer"),
      rootMargin: "0px",
      threshold: 0,
    };

    // watch all tips when they enter the viewport
    const watchTips = (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        setPage((prev) => prev + 1);
        if (moreTipsAvailable) {
          getTipsData(tipsValue, page);
        }
      });
    };

    // observe the tips footer to lazily load more available tips
    const tipsObserver = new IntersectionObserver(watchTips, observerOptions);

    const tipItems = document.querySelectorAll(".page-tips-pre-footer");
    tipItems.forEach((item) => {
      tipsObserver.observe(item);
    });

    // cleanup
    return () => {
      tipsObserver.disconnect();
    };
  }, [tips]);

  const location = useLocation();

  useEffect(() => {
    setTipsValue(location.pathname.substring(5));
    setPage(1);
  }, [location.pathname]);

  // fetch tips on initial page load
  useEffect(() => {
    getTipsData(tipsValue, page);
    setPage((prev) => prev + 1);
  }, [tipsValue]);

  // show-hide tips
  const toggleTipsVisibility = () => setTipsVisible(!tipsVisible);

  const closeTips = () => setTipsVisible(false);

  // close tips on ESC keypress
  const handleTipsKeydown = (e) => {
    if (e.key === "Escape") {
      closeTips();
    }
  };

  const toggleTipsSize = () => {
    setTipsExpanded(!tipsExpanded);
  };

  // save page tips size preference to localStorage
  useEffect(() => {
    localStorage.setItem("tipsExpanded", "tipsExpanded");
  }, [tipsExpanded]);

  // show tips nudge on a new session
  useEffect(() => {
    if (localStorage.getItem("tipsNudgeShown")) return;

    showTipsNudge();
    // hide the tips nudge automatically after 5 seconds
    tipsNudgeTimer.current = setTimeout(hideTipsNudge, 5000);

    // cleanup
    return () => {
      clearTimeout(tipsNudgeTimer.current);
    };
  }, []);

  // show tips nudge after 1 second
  const showTipsNudge = () => {
    setTimeout(() => {
      setTipsNudgeVisible(true);
      localStorage.setItem("tipsNudgeShown", "true");
    }, 1000);
  };

  const hideTipsNudge = () => {
    setTipsNudgeVisible(false);
    clearTimeout(tipsNudgeTimer.current);
  };

  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    greeting.current = "Good morning! ";
  } else if (currentHour > 11 && currentHour < 17) {
    greeting.current = "Good afternoon! ";
  } else if (currentHour > 16 && currentHour <= 23) {
    greeting.current = "Good evening! ";
  } else {
    greeting.current = "";
  }

  const { tipOpenIcon } = images;

  return (
    <div
      className={tipsVisible ? "page-tips page-tips-visible" : "page-tips"}
      tabIndex={0}
      onKeyDown={(e) => handleTipsKeydown(e)}
    >
      <div
        className={
          tipsExpanded
            ? "page-tips-viewer page-tips-viewer-expanded"
            : "page-tips-viewer"
        }
      >
        <div className="page-tips-header">
          <span className="page-tips-header-title">Tips</span>
          <div className="page-tips-header-buttons">
            <Button
              className="page-tips-expand-button"
              title={tipsExpanded ? "Collapse" : "Expand"}
              onClick={() => toggleTipsSize()}
            >
              <svg
                className="page-tips-expand-icon"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z" />
              </svg>
              <svg
                className="page-tips-collapse-icon"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M7.41 18.59L8.83 20 12 16.83 15.17 20l1.41-1.41L12 14l-4.59 4.59zm9.18-13.18L15.17 4 12 7.17 8.83 4 7.41 5.41 12 10l4.59-4.59z" />
              </svg>
            </Button>
            <Button
              className="page-tips-close-button"
              title="Close [Esc]"
              onClick={() => {
                closeTips();
              }}
            >
              <svg
                className="page-tips-close-button-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M0,0H24V24H0Z" fill="none" />
                <path d="M18.3,5.71a1,1,0,0,0-1.41,0L12,10.59,7.11,5.7A1,1,0,0,0,5.7,7.11L10.59,12,5.7,16.89A1,1,0,0,0,7.11,18.3L12,13.41l4.89,4.89a1,1,0,0,0,1.41-1.41L13.41,12,18.3,7.11A1,1,0,0,0,18.3,5.71Z" />
              </svg>
            </Button>
          </div>
        </div>
        <div className="page-tips-content">
          <div className="page-tips-content-wrapper">
            <div
              className="page-tip-items-pos"
              data-empty-message="No tips found for this page."
            >
              {tips &&
                tips.map((item) => (
                  <div
                    className="page-tip-item-pos"
                    key={item.id}
                    data-title={item.tipTitle}
                  >
                    <div>
                      <PosImage src={tipOpenIcon} alt="tip-icon" />
                      {item.tipTitle}
                    </div>
                    <div>{item.tip}</div>
                  </div>
                ))}
            </div>
            {moreTipsAvailable && (
              <span className="page-tips-pre-footer">Loading...</span>
            )}
            <div className="tips-footer">
              <svg
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z" />
              </svg>
              <span>
                Need help?{" "}
                <a className="tips-visit" href={supportLink} target="_blank">
                  Visit Support
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* page tips toggle button */}
      <Button
        isClassName={false}
        className={
          tipsNudgeVisible
            ? "page-tips-toggle page-tips-toggle-nudge"
            : "page-tips-toggle"
        }
        data-nudge-message="See useful tips here"
        title={tipsVisible ? "Hide page tips" : "Show page tips"}
        onClick={() => toggleTipsVisibility()}
        onTouchStart={() => hideTipsNudge()}
        onMouseOver={() => hideTipsNudge()}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="page-tips-toggle-icon-off"
        >
          <path d="M0,0H24V24H0Z" fill="none" />
          <path
            d="M9,21a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V20H9ZM12,2A7,7,0,0,0,8,14.74V17a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V14.74A7,7,0,0,0,12,2Zm2.85,11.1-.85.6V16H10V13.7l-.85-.6a5,5,0,1,1,5.7,0Z"
            transform="translate(0 0)"
            fill="#ff9f0a"
          />
        </svg>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="page-tips-toggle-icon-on"
        >
          <path d="M0,0H24V24H0Z" fill="none" />
          <path
            d="M9,21a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V20H9ZM12,2A6.957,6.957,0,0,0,5,9a6.827,6.827,0,0,0,3,5.7V17a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V14.7A7.1,7.1,0,0,0,19,9,6.957,6.957,0,0,0,12,2Z"
            transform="translate(0 0)"
            fill="#ff9f0a"
          />
        </svg>
      </Button>
      {/* backdrop */}
      <div className="page-tips-skrim" />
    </div>
  );
};

export default TipsBox;
