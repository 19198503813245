import { useRef, useEffect, useState } from "react";

import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import { IconButton, Icon } from "apps/springboard/Component/SBItems/elements";
import NoteItemFollowUp from "../NoteItemFollowUp";
import NewNoteInput from "../NewNoteInput";

import { keys } from "utils/helper";

import { springBoardText } from "constants/constant";
import { useDispatch } from "react-redux";


const NotesDialog = ({
  open,
  notes,
  category,
  followUp,
  close,
  quoteId,
  handlerID,
  highlighted,
  noteLength,
  openAddNote = false,
  setOpenAddNote = null,
  setOpenMiniNote,
}) => {
  const ref = useRef();
  const { customerNoteText, confirmDialog, majorReps } = springBoardText;
  const [note, setNote] = useState(notes);
  const [openAddFollowupNote, setOpenAddFollowupNote] = useState(openAddNote);
  const [followupNote, setFollowupNote] = useState("");
  const dispatch = useDispatch();
  const isMajorReps = category === majorReps
    useEffect(() => {
    if (open) {
      dispatch(setOpenMiniNote(true))
      ref.current.showModal();
    } else {
      dispatch(setOpenMiniNote(false))
      setOpenAddNote && setOpenAddNote(false);
      ref.current.close();
    }
  }, [open, notes, openAddFollowupNote]);

  const handleKeyDown = (e) => {
    if (e.keyCode === keys.esc) { 
      setOpenAddNote && setOpenAddNote(false);
      if (notes !== note) {
        preventUnsavedChanges(e);
      } else {
        close()
      }
      }
    if (e.keyCode !== keys.esc) return;
   
  };
  const preventUnsavedChanges = (e) => {
    let permission = confirm(confirmDialog);
    if (permission) { 
      setOpenAddNote && setOpenAddNote(false); close()}
    else{
       e.preventDefault()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, note, openAddFollowupNote]);

  const closeFollowupNotes = () => {
    setOpenAddNote && setOpenAddNote(false);
    setOpenAddFollowupNote(false);
    setFollowupNote("");
    dispatch(setOpenMiniNote(false))
  };

  return (
    <dialog ref={ref} className="notes-dialog-sb">
      <div className="notes-dialog-wrapper-sb">
        <header>
          <div className="d-flex align-items-center justify-content-between">
            {highlighted ? <MaterialIcon icon="bolt" size="24"/> : null}
            {isMajorReps ? <MaterialIcon icon="supervisor_account" size="24" /> : null}
            <h3 className="ms-1">{category}</h3>
            {highlighted ?
              <div className="job-highlight-notes d-flex align-items-center justify-content-between ms-2">
                <MaterialIcon icon="visibility" size="24" classes="remove-eye-icon"/>
                <span className="customer-notes">{customerNoteText}</span>
              </div>: null}
          </div>
          <IconButton
            name='close'
            className="header-actions-note icon-button-notes"
            type='tertiary'
            onClick={close}
          />
        </header>
        <div className="dialog-content">
          {(followUp && openAddFollowupNote) ? (
            <NewNoteInput
              close={closeFollowupNotes}
              note={followupNote}
              category={category}
              quoteId={quoteId}
              handlerID={handlerID}
              setNote={setFollowupNote}
              noteLength={noteLength}
              preventUnsavedChanges={preventUnsavedChanges}

            />
          ) : null }

          {(followUp && !openAddFollowupNote) ? (
            <>
              <button
                className="open-new-note-btn"
                onClick={() => setOpenAddFollowupNote(true)}
              >
                <span>Add a new note...</span>
                <Icon name="add" />
              </button>

              {notes?.map((note) => (
                <ul
                  className="dialog-content-ul notes-list-sb"
                  key={"note" + note.posterID}
                >
                  <NoteItemFollowUp note={note} />
                </ul>
              ))}
            </>
          ) : null}

          {!followUp ? (
            <NewNoteInput
              close={close}
              note={note}
              category={category}
              quoteId={quoteId}
              handlerID={handlerID}
              setNote={setNote}
              noteLength={noteLength}
              notes={notes}
              preventUnsavedChanges={preventUnsavedChanges}
            />
          ) : null}
          
        </div>
      </div>
    </dialog>
  );
};

export default NotesDialog;
