import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Helmet } from "react-helmet";

import { getHistory } from "../../../services";

import { Button, Loading } from "../../../components";

import download_white from "../../springboard/images/icons/download_white.svg";

import { PaymentList } from "../../../models";

import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/common.css";

const StyledTableRow = withStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  head: {
    color: "#aaa",
    fontSize: 11,
    fontFamily: "Inter, Regular",
    paddingLeft: 0,
    borderBottomWidth: 0,
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "Inter, Regular",
    borderBottomWidth: 0,
    paddingLeft: 0,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  root: {
    position: "unset",
    "& .MuiTableHead-root": {
      position: "unset",
    },
  },
});

export function PaymentHistory() {
  const [paymentListData, setPaymentListData] = useState<PaymentList[]>();
  const [page] = useState(1);
  const [loader, setLoader] = useState(false);
  const [allPaymentList, setallPaymentList] = useState<PaymentList[]>();
  const classes = useStyles();

  useEffect(() => {
    let loadedPayments = false;
    setLoader(true);
    getHistory().then((response) => {
      setLoader(false);
      if (response.length > 0) {
        if (!loadedPayments) {
          setallPaymentList(response);
        }
      }
    });
    return () => {
      loadedPayments = true;
    };
  }, []);
  useEffect(() => {
    setPaymentListData(allPaymentList?.slice(0, 10));
  }, [allPaymentList]);

  useEffect(() => {
    setPaymentListData(allPaymentList?.slice(page * 10 - 10, page * 10));
  }, [page]);
  const ListHeader = styled.div`
    display: flex;
    justify-content: flex-end;
  `;

  return !loader ? (
    <div className="history-list-container">
      <Helmet>
        <title>Payment History</title>
        <meta name="Admin page" content="admin page" />
      </Helmet>
      <div className="d-flex align-items-center justify-content-between">
        <div className="historyLeftbarTitle">Payment History</div>
        <ListHeader>
          <Button
            data-bs-toggle="modal"
            data-bs-target="#jumper_modal"
            className="manage-sub-btn btn-primary"
            buttonStyle="light"
          >
            <span className="manage-sub-txt">Manage SubScription</span>
          </Button>
        </ListHeader>
      </div>
      <hr
        style={{
          color: "gray",
          backgroundColor: "gray",
          height: 1,
        }}
      />
      <TableContainer>
        <Table className={classes.root}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">DATE</StyledTableCell>
              <StyledTableCell align="left">TRANSACTION</StyledTableCell>
              <StyledTableCell align="left">AMOUNT</StyledTableCell>
              <StyledTableCell align="left">PAYMENT METHOD</StyledTableCell>
              <StyledTableCell align="left">INVOICE</StyledTableCell>
            </TableRow>
          </TableHead>

          {paymentListData && paymentListData?.length > 0 ? (
            <TableBody>
              {paymentListData.map((mainItem: PaymentList, i: number) => (
                <StyledTableRow key={`repSwitch_not_${i}`}>
                  <StyledTableCell style={{ paddingLeft: 5 }}>
                    {moment(mainItem.transactionDateTime).format("DD MMM,YYYY")}
                  </StyledTableCell>
                  <StyledTableCell>{mainItem.description}</StyledTableCell>
                  <StyledTableCell>{mainItem.totalAmt}$</StyledTableCell>
                  <StyledTableCell>{mainItem.paymentProcessor}</StyledTableCell>
                  <StyledTableCell>
                    <img src={download_white} alt="" className="download-img" />
                    DOWNLOAD
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <StyledTableCell style={{ paddingLeft: 5, borderBottomWidth: 0 }}>
              No Record Found
            </StyledTableCell>
          )}
        </Table>
      </TableContainer>
    </div>
  ) : (
    <Loading value={100} message="Loading Payment History" type="success" />
  );
}
