import { useMediaQuery } from 'apps/admin/hooks/useMediaQuery';
import MaterialIcon from 'apps/pos/Common/uiCore/MaterialIcon';
import './Button.scss';

const Button = (props) => {
    const { classes, iconSize, secondary, accent, wide, disabled, onClick, icon, label, mobileLabel, title, type } = props;


    {/* use  mobileLabel if available, on smaller screen sizes 📱*/ }
    const isMobile = useMediaQuery('(max-width: 1000px)');
    const buttonLabel = isMobile ? (mobileLabel ? mobileLabel : label) : label;

    return (
        <button
            type={type ? type : ""}
            className={`button-primary ${icon ? 'button-with-icon' : ''} ${secondary ? 'button-secondary' : ''} ${accent ? 'button-accent' : ''} ${wide ? 'button-wide' : ''} ${classes ? classes : ''}`}
            disabled={disabled}
            title={title ? title : label}
            onClick={onClick}
        >

            {icon ? (
                <MaterialIcon icon={icon} classes='button-icon' size={isMobile ? '18' : iconSize} />
            ) : null}

            <div className='button-label'>{buttonLabel}</div>
        </button>
    );
}

export default Button;
