import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { deliverShipmentUrl } from "./constantUrl";
import { stopLoading } from "utils/utils";

export interface DeliverShipmentItemProps {
    salesPrice: number;
    shipmentInStorageItemID: number;
    qtyInDelivery: number;
}
interface DeliverShipmentProps {
    handlerID: number;
    orderID: number;
    shipMethod: string;
    trackingNum: string;
    referenceNum: string;
    tenantPaidShipFee: number;
    deliveryItemValue: number;
    deliveryTime: string;
    items: DeliverShipmentItemProps[];
}

export const useDeliverShipment = () => {

  const { mutate, isLoading, data, error, isSuccess } = useMutation(
    async (payload: DeliverShipmentProps) => {
      return deliverShipmentUrl(payload);
    },
    {onSuccess : stopLoading,
      onError : stopLoading
});

  const deliverShipmentFn = useCallback(
    (payload) => {
      mutate(payload);
    },
    [mutate]
  );

  return {
    deliverShipmentFn,
    isLoading,
    data,
    error,
    isSuccess,
  };
};
