import { MODAL_UPDATE_CONTENT, RESET_MODAL } from "./constants";

export function UpdateModalContent(payload: any) {
  return {
    type: MODAL_UPDATE_CONTENT,

    payload,
  };
}

export function resetModal() {
  return {
    type: RESET_MODAL,
  };
}
