import { Container, Grid } from "@material-ui/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  PosButton,
  PosIconButton,
  PosImage,
  PosPageHeading,
} from "apps/pos/Common/uiCore";
import AdjustStock from "apps/pos/UnitsAvailable/AdjustStock/AdjustStock";
import { Button } from "components";
import CommonModel from "components/Dialog/Dialog";

import texts from "apps/pos/locales";
import { ProductPriceType, ProductTypes } from "constants/enum";
import { ArrowBackRoundedIcon } from "constants/icons";
import images from "constants/images";
import { CartProductsProp, PosFlowDataProp, UploadedDocumentItemProp } from "constants/interfaces";
import { Paths } from "enums";
import { PosResponseProps, RootState } from "models";
import {
  authorizationToken,
  calculatePriceItemMuEach,
  getLocalStoraGeItem,
  getProfit,
  priceToBeFixedConverter,
  roundToTwoDecimalPlaces,
} from "utils/utils";

import { loadCartRequest, recipientInfoSwitchInfoData, skipOrdererInfo } from "sagas/persistReducer/actions";
import {
  cartDraftClear,
  cartDraftRequest,
  getIsGoToCart,
  getupdateItemModal,
} from "sagas/pos/actions";

import "./reviewItem.scss";

const Reviewitems = () => {
  const { posFlowData, loadCartDetails, isTaxExempted, allowPickupCheck } = useSelector<
    RootState,
    PosFlowDataProp
  >((state) => state.persistReducer);
  const { productsData, cartDraftSuccess, cartDraft, isPurchaseOrderNoEdit, orderInstructionNote, shippingInstructionNote, } = useSelector<
    RootState,
    PosResponseProps
  >((state) => state.pos);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [oldPriceProductId, setOldPriceProductId] = useState([]);
  const [selectedNewPrice, setSelectedNewPrice] = useState([]);
  const [outStockSelectedNewPrice, setOutStockSelectedNewPrice] = useState([]);
  const [discontinuedSelectedNewPrice, setDiscontinuedSelectedNewPrice] =
    useState([]);
  const { shipToByFacility, products } = loadCartDetails || {};
  const {
    pickOrderQuantity,
    cartProducts,
    customer,
    savedCart,
    shiptoFacilities,
    customerInfoUpdated,
    poNumber,
    schedule,
    selectQuoteId,
    selectQuoteType,
    salesman,
    addDocument,
  } = posFlowData || {};
  const [loadCartDetailsProducts, setLoadCartDetailsProducts] =
    useState(cartProducts);
  const { cartId } = savedCart || {}

  const {
    taxExemptFlag,
    customerId,
    fullName,
    phone,
    email,
    creditHoldFlag,
    showPrices,
    isPosGeneric,
    lookupType,
    posCustCode,
    priceScheduleId,
  } = savedCart?.customer || {};
  const firstName = fullName?.split(" ")?.[0];
  const lastName = fullName?.split(" ")?.[1];
  const { typeDiscontinued } = ProductTypes;
  const { pickup, oldBlockType, newBlockType } = texts;

  const isAvailableInStockProduct = products
    ?.filter(
      (item: CartProductsProp) =>
        item?.isPriceChanged &&
        item?.qtyInStock > 0 &&
        item?.productPrices[0].type !== typeDiscontinued &&
        (item?.qtyInStock > item?.qtyOrdered ||
          item?.qtyInStock === item?.qtyOrdered)
    )
    .map((i) => {
      return {
        ...i,
        newPriceObj: {
          price: i?.productPrices?.[0]?.price,
          type: newBlockType,
        },
        oldPriceObj: {
          price: i?.posRetailPrice,
          type: oldBlockType,
        },
        isNewType: false,
        isOldType: false,
      };
    });

  const isAvailableOutOfStockProduct = products
    ?.filter(
      (item: CartProductsProp) =>
        item?.isPriceChanged &&
        (item?.qtyInStock === 0 || item?.qtyInStock < item?.qtyOrdered) &&
        item?.productPrices[0].type !== typeDiscontinued
    )
    .map((i) => {
      return {
        ...i,
        newPriceObj: {
          price: i?.productPrices?.[0]?.price,
          type: newBlockType,
        },
        oldPriceObj: {
          price: i?.posRetailPrice,
          type: oldBlockType,
        },
        isNewType: false,
        isOldType: false,
      };
    });

  const isDiscontinuedProduct = products
    ?.filter(
      (item: CartProductsProp) =>
        item?.isPriceChanged &&
        item?.qtyInStock > 0 &&
        item?.productPrices[0].type === typeDiscontinued
    )
    .map((i) => {
      return {
        ...i,
        newPriceObj: {
          price: i?.productPrices?.[0]?.price,
          type: newBlockType,
        },
        oldPriceObj: {
          price: i?.posRetailPrice,
          type: oldBlockType,
        },
        isNewType: false,
        isOldType: false,
      };
    });

  const isDeactivatedProduct = products?.filter(
    (item: CartProductsProp) =>
      item?.qtyInStock === 0 &&
      item?.productPrices[0].type === typeDiscontinued &&
      item?.qtyInStock < item?.qtyOrdered
  );

  useEffect(() => {
    isAvailableInStockProduct?.map((item) =>
      oldPriceProductId.push(item.productId)
    );
    setOldPriceProductId(oldPriceProductId);
  }, []);

  useEffect(() => {
    setLoadCartDetailsProducts(cartProducts);
  }, []);

  useEffect(() => {
    if (cartDraftSuccess) {
      const loadRequest = {
        stockWarehouseId,
        customerId: customer?.customerId,
        takerId: currentTakerInfoStorage?.personId,
        cartId: savedCart?.cartId,
      };

      dispatch(loadCartRequest(loadRequest));
      dispatch(cartDraftClear());
      dispatch(recipientInfoSwitchInfoData(false))
      dispatch(skipOrdererInfo(false))
      navigate(`${Paths.posCart}/${cartDraft.data}`);
    }
  }, [cartDraftSuccess]);

  const commonPriceSelectAll = (type, operateData, toOperateData) => {
    const isNewType = type === newBlockType;

    const copyNewPriceData = [...operateData];
    const finalNewPriceInfo = copyNewPriceData?.map((item) => {
      const { newPriceObj, oldPriceObj } = item;
      return {
        ...item,
        isNewType,
        isOldType: !isNewType,
        finalOutStockPrice: isNewType ? newPriceObj?.price : oldPriceObj?.price,
        isSelect: 1,
      };
    });
    toOperateData(finalNewPriceInfo);
  };

  const commonPriceSelector = (selectedItem, operateData, setOperateData) => {

    const isNewType = selectedItem?.type === newBlockType;
    const findInStockIndex = operateData.findIndex(
      (item) => item.lineId === selectedItem?.lineId
    );

    if (findInStockIndex >= 0) {
      const copyNewPriceData = [...operateData];
      const stockFinalSaveData = {
        ...selectedItem,
        isNewType,
        isOldType: !isNewType,
        finalOutStockPrice: isNewType
          ? selectedItem?.newPriceObj?.price
          : selectedItem?.oldPriceObj?.price,
        isSelect: 1,
      };
      copyNewPriceData[findInStockIndex] = stockFinalSaveData;

      setOperateData(copyNewPriceData);
    }
  };
  const currentTaker = JSON.parse(localStorage.getItem("taker"));
  const takerFullName = currentTaker?.fullName;

  useEffect(() => {
    setSelectedNewPrice(isAvailableInStockProduct);
    setOutStockSelectedNewPrice(isAvailableOutOfStockProduct);
    setDiscontinuedSelectedNewPrice(isDiscontinuedProduct);
  }, []);

  const decodedData = authorizationToken("Authorization");
  const PickConfigData = [];

  pickOrderQuantity?.length &&
    pickOrderQuantity?.forEach((product) => {
      const productDetails = cartProducts.filter(
        (productData, index) =>
          productData.manufacturerCode === product.manufacturerId &&
          product.entry === index
      );
      const manufacturerId =
        productDetails &&
        productDetails.length &&
        productDetails[0].manufacturerId;
      PickConfigData.push({
        entry: product.entry,
        stockWarehouseId:
          product?.stockWarehouseId || stockWarehouseId,
        productId: product?.productId,
        manufacturerId: manufacturerId,
        stockRowId: product?.stockRowId,
        stockUnitId: product?.stockUnitId,
        stockShelfId: product?.stockShelfId,
        availableQty: product?.availableQty,
        pickedQty: Number(product?.pickedQty),
      });
    });

  const pickConfigProductData = _.groupBy(PickConfigData, "entry");

  for (const product in pickConfigProductData) {
    pickConfigProductData[product].length &&
      pickConfigProductData[product].map((item) => {
        function DeleteKeys(myObj, array) {
          for (let index = 0; index < array.length; index++) {
            delete myObj[array[index]];
          }
          return myObj;
        }
        const data_array = [];
        const array = ["entry"];
        const finalobj = DeleteKeys(item, array);
        data_array.push(finalobj);
      });
  }

  let taxRateToBeSent;

  if (taxExemptFlag && isTaxExempted) {
    taxRateToBeSent = 0.0;
  } else {
    if (
      savedCart?.shipToByFacility?.recipient?.taxExemptFlag &&
      isTaxExempted
    ) {
      taxRateToBeSent = 0.0;
    } else {
      if (isTaxExempted) {
        taxRateToBeSent = 0.0;
      } else {
        if (savedCart?.shipToByFacility?.tax?.shipTaxFlag === 0) {
          taxRateToBeSent = 0.0;
        } else {
          taxRateToBeSent = pickup
            ? shipToByFacility?.tax?.taxRate
            : shiptoFacilities?.tax?.taxRate;
        }
      }
    }
  }

  let cartExtPrice = 0;
  cartProducts?.length &&
    cartProducts.forEach(({ extPrice, qtyOrdered }) => {
      cartExtPrice = cartExtPrice + extPrice * qtyOrdered;
    });

  const subTotalAmount = cartExtPrice ? cartExtPrice.toFixed(2) : 0;
  const taxAmountWithoutRoundOff = (cartExtPrice * taxRateToBeSent) / 100;
  const taxAmount = roundToTwoDecimalPlaces(taxAmountWithoutRoundOff);

  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {};
  const currentTakerInfoStorage = getLocalStoraGeItem("taker");

  const saveAndGotoCart = () => {
    const cartData = cartProducts;
    let loadCartDetailsProductsUpdates = loadCartDetailsProducts;
    isAvailableOutOfStockProduct?.map((item) => {
      loadCartDetailsProductsUpdates = loadCartDetailsProducts.filter(
        (object) => {
          return item.productId !== object.productId;
        }
      );
    });
    isDiscontinuedProduct.map((item) => {
      loadCartDetailsProductsUpdates = loadCartDetailsProducts.filter(
        (object) => {
          return item.productId !== object.productId;
        }
      );
    });
    isDeactivatedProduct.map((item) => {
      loadCartDetailsProductsUpdates = loadCartDetailsProducts.filter(
        (object) => {
          return item.productId !== object.productId;
        }
      );
    });
    setLoadCartDetailsProducts([...loadCartDetailsProductsUpdates]);
    const cartAddedData = [];

    cartData?.length &&
      cartData.forEach(
        (
          {
            qtyInStock,
            qtyOrdered,
            shippingNote,
            orderNote,
            discontinued,
            posRetailPrice,
            productId,
            manufacturerCode,
            vendorId,
            manufacturerId,
            productName,
            vendorPrice,
            salesPrice,
            priceMU,
            itemMU,
            itemMuName,
            unitPerCase,
            clearance,
            muName,
            clearanceFlag,
            discontinueFlag,
            priceScheduleVendorProductGroupId,
            overridePrice,
            lineId,
            productPrices,
            productPriceTypeApplied,
            itemType,
            markupMultiplier
          },
          index
        ) => {
          const newQtyClearance =
            qtyOrdered > qtyInStock ? qtyInStock : qtyOrdered;
          const isEligible = discontinued && qtyInStock <= 0;
          const finalPriceInStock = selectedNewPrice.find(
            (item) => item.productId === productId && item.lineId === lineId
            // Will remove after verifying that all works fine
            // && item?.finalOutStockPrice
          );
          const finalPriceOOS = outStockSelectedNewPrice.find(
            (item) => item.productId === productId && item.lineId === lineId
            // Will remove after verifying that all works fine
            // && item?.finalOutStockPrice
          );
          const finalPriceDis = discontinuedSelectedNewPrice.find(
            (item) => item.productId === productId && item.lineId === lineId
            // Will remove after verifying that all works fine
            // && item?.finalOutStockPrice
          );
          const finalPrice =
            finalPriceInStock ?? finalPriceOOS ?? finalPriceDis;

          !isEligible &&
            cartAddedData.push({
              finalPosRetailPrice: finalPrice
                ? finalPrice.finalOutStockPrice
                : productPrices?.[0].price,
              productId,
              manufacturerCode,
              manufacturerId,
              vendorId,
              itemType: itemType || null,
              productName,
              vendorPrice: vendorPrice?.price ?? vendorPrice,
              salesPrice: salesPrice?.price ?? salesPrice,
              initialPosRetailPrice: posRetailPrice || 0,
              priceMU,
              muName,
              itemMU,
              itemMuName,
              unitPerCase,
              qtyInStock,
              qtyOrdered: clearance ? newQtyClearance : qtyOrdered,
              clearanceFlag,
              discontinueFlag,
              markupMultiplier,
              overridePrice: overridePrice || null,
              priceScheduleVendorProductGroupId,
              lineId: index + 1,
              pickConfigs: null,
              shippingNote,
              orderNote,
              posRetailPrice: finalPrice
                ? finalPrice.posRetailPrice
                : productPrices?.[0].price,
              productPriceTypeApplied: finalPrice && finalPrice?.isNewType ? customer?.fixMarginCustFlag > 0 ? ProductPriceType.FIXED_MARGIN_PRICE : ProductPriceType[finalPrice?.productPrices[0]?.type] : typeof productPriceTypeApplied === "string" ? ProductPriceType[productPriceTypeApplied] : productPriceTypeApplied,
            });
        }
      );

    const customerData = {
      customerId: customerId || "",
      buyOnBanNote: customer?.hasInternalNotes,
      customerNote: savedCart?.customerNote || "",
      customerInfoUpdated: customerInfoUpdated,
      firstName: firstName,
      lastName: lastName,
      phone: phone || null,
      email: email ? email : "",
      buyOnCreditHold: creditHoldFlag === 1,
      showPrices: showPrices ? showPrices : false,
      isPosGeneric: isPosGeneric ? isPosGeneric : false,
      lookupType: lookupType,
      posCustCode: posCustCode || null,
    };
    const uploadData = [];
    const formFiles = [];
    addDocument?.length &&
      addDocument.map((item: UploadedDocumentItemProp) => {
        const {
          file,
          description,
          typeId,
          documentSaveType,
          locationSaveType,
          uploadedBy,
          originalFile,
        } = item;
        formFiles.push(originalFile);
        if (originalFile) {
          const data = {
            fileName: file,
            description: description,
            typeId: typeId,
            documentSaveType: documentSaveType,
            locationSaveType: locationSaveType,
            uploadedBy: uploadedBy,
          };
          uploadData.push(data);
        }
      });

    const savedDraftTaxExempt = savedCart?.customer?.taxExemptFlag
      ? 1
      : savedCart?.shipToByFacility?.recipient?.taxExemptFlag
        ? 1
        : isTaxExempted
          ? 1
          : 0
    const preparedData = {
      jobName: savedCart?.jobName,
      sessionStartedAt: savedCart?.sessionStartedAt
        ? savedCart?.sessionStartedAt
        : "",
      cartId: savedCart?.cartId ? savedCart?.cartId : "",
      loggedInUserId: decodedData?.DefaultLoginId,
      transactionType: "SALE",
      stockWarehouseId: stockWarehouseId,
      takerId: currentTaker?.personId,
      purchaseOrderNum: (poNumber || (!isPurchaseOrderNoEdit && loadCartDetails?.products?.length && loadCartDetails.purchaseOrderNum) || null),
      subTotalAmt: Number(subTotalAmount),
      customer: customerData,
      shipTaxFlag: savedCart?.shipToByFacility?.tax?.shipTaxFlag
        ? savedCart?.shipToByFacility?.tax?.shipTaxFlag
        : 0,
      taxRate: savedDraftTaxExempt === 1 ? 0 : taxRateToBeSent,
      taxSchedule: savedDraftTaxExempt === 1 ? "NONTAX" : savedCart?.shipToByFacility?.tax?.taxSchedule,
      initialTaxAmt: savedDraftTaxExempt === 1 ? 0 : taxAmount,
      initialTaxExemptFlag: savedDraftTaxExempt === 1 ? 1 : 0,
      initialShipToAddressId: savedCart?.shipToByFacility?.facilityId,
      finalShipToAddressId: shiptoFacilities?.facilityId,
      salesPersonId: posFlowData?.salesman?.ssoId || posFlowData?.salesman?.personId || 0,
      initialScheduleId: priceScheduleId,
      finalScheduleId: schedule?.id
        ? schedule?.id
        : priceScheduleId
          ? priceScheduleId
          : "",
      paymentMode: "CASH",
      paymentRef1: null,
      paymentRef2: null,
      taxAmt: taxExemptFlag
        ? 0
        : savedCart?.shipToByFacility?.recipient?.taxExemptFlag
          ? 0
          : isTaxExempted
            ? 0
            : 0,
      taxExemptFlag: savedDraftTaxExempt,
      shippingFeeAmt: 0,
      shippingTaxAmt: 0,
      grandTotalAmt: 0,
      tenderAmt: 0,
      tenderBalanceAmt: Number(priceToBeFixedConverter(0)),
      quoteId: selectQuoteId ? selectQuoteId : null,
      quoteType: selectQuoteType ? selectQuoteType : "NOT_SET",
      products: cartAddedData,
      uploadFiles: uploadData?.length ? uploadData : null,
      shippingNote: shippingInstructionNote,
      orderNote: orderInstructionNote,
      takerName: takerFullName,
      salesPersonName: salesman?.salespersonName ? salesman?.salespersonName : takerFullName,
      noDeliverToShipAddressButPickupFlag: allowPickupCheck ?? false
    };
    const processDataFormat = JSON.stringify(JSON.stringify(preparedData));

    const formData = new FormData();
    formData.append("posOrderTransactionRequest", processDataFormat);

    addDocument.forEach((item: UploadedDocumentItemProp) => {
      const { originalFile } = item;
      if (originalFile) {
        formData.append("formFiles", originalFile);
      }
    });

    dispatch(cartDraftRequest(formData, cartId));
    dispatch(getupdateItemModal(false));
    dispatch(getIsGoToCart(true));
  };

  const findExtTotal = (
    data,
    productId,
    qtyOrdered,
    posRetailPrice,
    firstPrice,
    lineId,
    index
  ) => {
    const findOldTotal = data.find(
      (data) =>
        data.productId === productId && data.lineId === lineId && data.isOldType
    );
    const findNewTotal = data.find(
      (data) =>
        data.productId === productId && data.lineId === lineId && data.isNewType
    );
    const oldExtPrice = calculatePriceItemMuEach(
      data[index]?.priceMU,
      data[index]?.itemMU,
      posRetailPrice
    );
    const newExtPrice = calculatePriceItemMuEach(
      data[index]?.priceMU,
      data[index]?.itemMU,
      firstPrice
    );
    const oldTotalValue = (qtyOrdered * oldExtPrice).toFixed(2);
    const newTotalValue = (qtyOrdered * newExtPrice).toFixed(2);

    return findNewTotal
      ? `$${newTotalValue}`
      : findOldTotal && `$${oldTotalValue}`;
  };

  const { dollerCheck, outStock, disContinue, deactivated } = images;
  const isSelectOOS = outStockSelectedNewPrice?.filter((item) => item.isSelect);
  const isSelectInStock = selectedNewPrice?.filter((item) => item.isSelect);
  const isSelectDiscountine = discontinuedSelectedNewPrice?.filter(
    (item) => item.isSelect
  );
  const disableSubmit =
    isSelectOOS?.length + isSelectInStock?.length + isSelectDiscountine?.length;
  const isDisableData =
    isAvailableInStockProduct?.length +
    isAvailableOutOfStockProduct?.length +
    isDiscontinuedProduct?.length;
  const totalData =
    isAvailableInStockProduct?.length +
    isAvailableOutOfStockProduct?.length +
    isDiscontinuedProduct?.length +
    isDeactivatedProduct?.length;

  return (
    <Container className="new-container review-item-container">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div>
          <div className="review-item-heading">
            <div>
              <PosIconButton
                className="review-back-btn btn pos-icon-back-btn"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackRoundedIcon />
              </PosIconButton>
            </div>
            <PosPageHeading
              className="update-product-heading"
              heading="Review Changes"
              blackHeading
            />
          </div>
          <div className="review-item-description">
            <span>
              {totalData === 0 || totalData === 1
                ? "1 item in the cart has changed. Review it before continuing to the Cart."
                : `${totalData} items in the cart have changed. Review them before continuing to the Cart.`}
            </span>
          </div>
        </div>
        <div className="main-apply-to-all">
          <div className="d-flex review-title-spacing review-apply-all">
            <p className="mb-0">Apply to all </p>
            <Button
              className="old-price-apply d-flex align-items-center justify-content-center border-0 button-focus-class"
              tabIndex={1}
              onClick={() => {
                commonPriceSelectAll(
                  oldBlockType,
                  selectedNewPrice,
                  setSelectedNewPrice
                );
                commonPriceSelectAll(
                  oldBlockType,
                  outStockSelectedNewPrice,
                  setOutStockSelectedNewPrice
                );
                commonPriceSelectAll(
                  oldBlockType,
                  discontinuedSelectedNewPrice,
                  setDiscontinuedSelectedNewPrice
                );
              }}
            >
              <p className="mb-0"> Old Price </p>
            </Button>
            <Button
              className="new-price-apply d-flex align-items-center justify-content-center border-0 button-focus-class"
              tabIndex={1}
              onClick={() => {
                commonPriceSelectAll(
                  newBlockType,
                  selectedNewPrice,
                  setSelectedNewPrice
                );
                commonPriceSelectAll(
                  newBlockType,
                  outStockSelectedNewPrice,
                  setOutStockSelectedNewPrice
                );
                commonPriceSelectAll(
                  newBlockType,
                  discontinuedSelectedNewPrice,
                  setDiscontinuedSelectedNewPrice
                );
              }}
            >
              <p className="mb-0"> New Price</p>
            </Button>
          </div>
        </div>
      </div>
      {isAvailableInStockProduct?.length ? (
        <div className="review-item-heading-details">
          <div className="review-item-price-change">
            <div className="review-item-price-change-details">
              <div className="review-item-select-price">
                <div className="review-item-all-price">
                  <div className="review-item-price-container">
                    <PosImage src={dollerCheck} alt="doller-icon" />
                    <div className="review-item-price-span">
                      {isAvailableInStockProduct?.length} Price
                      {isAvailableInStockProduct?.length > 1 ? "s" : ""} Changed
                      <span className="review-item-span">
                        Select which price to use
                      </span>
                    </div>
                  </div>
                  <div className="main-apply-to-all">
                    <div className="d-flex review-title-spacing review-apply-all">
                      <p className="mb-0">Apply to all </p>
                      <Button
                        className="old-price-apply border-0 button-focus-class p-0"
                        tabIndex={1}
                        onClick={() =>
                          commonPriceSelectAll(
                            oldBlockType,
                            selectedNewPrice,
                            setSelectedNewPrice
                          )
                        }
                      >
                        <p> Old Price </p>
                      </Button>
                      <Button
                        className="new-price-apply border-0 button-focus-class p-0"
                        tabIndex={1}
                        onClick={() =>
                          commonPriceSelectAll(
                            newBlockType,
                            selectedNewPrice,
                            setSelectedNewPrice
                          )
                        }
                      >
                        <p> New Price</p>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="review-stock-table">
              <Grid container className="mt-4 mb-4 review-normal-title">
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={3}
                  lg={5}
                  xl={5}
                  className="d-flex"
                >
                  <div className="review-table-item-header review-table-item-left-info-cell review-table-item-icon" />
                  <div className="review-table-item-header review-table-item-name">
                    ITEM
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                  <div className="review-table-item-header review-table-item-right-info-cell text-center">
                    AVAILABLE QTY.
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                  <div className="review-table-item-header review-table-item-right-info-cell text-center">
                    ORDER QTY.
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                  <div className="review-table-item-header review-table-item-right-info-cell text-center">
                    NEW QTY.
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={3}
                  className="d-flex price-main-title"
                >
                  <div className="review-table-item-header tab-body-old-price review-table-item-right-info-cell text-center">
                    OLD PRICE
                  </div>
                  <div className="review-table-item-header tab-body-new-price review-table-item-right-info-cell text-center">
                    NEW PRICE
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={1} xl={1}>
                  <div className="review-table-item-header review-table-item-right-info-cell price-break d-flex justify-content-end">
                    EXT-PRICE
                  </div>
                </Grid>
              </Grid>

              {isAvailableInStockProduct?.length ? (
                selectedNewPrice?.map((item, index) => {
                  const {
                    clearance,
                    manufacturerName,
                    productName,
                    qtyInStock,
                    qtyOrdered,
                    productId,
                    posRetailPrice,
                    vendorPrice,
                    lineId,
                    newPriceObj,
                  } = item;

                  return (
                    <Grid
                      container
                      key={`${index}-inStock`}
                      className="main-review-data-grid"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={3}
                        lg={5}
                        xl={5}
                        className="d-flex align-items-center review-data-grid"
                      >
                        <div className="review-responsive-title">
                          <span>ITEM:</span>
                        </div>
                        <div className="review-table-item-left-info d-flex review-data align-items-center">
                          <div className="review-table-item-left-info-cell review-table-item-icon">
                            <PosImage
                              src={
                                productsData?.thumbImg || images?.defaultIcon
                              }
                              alt="product-icon"
                            />
                          </div>
                          <div className="review-table-item-name">
                            <div className="review-table-item-name-manufacturer">
                              {manufacturerName}
                            </div>
                            <div className="review-table-item-name-product">
                              {productName}
                              {clearance ? (
                                <div className="clearance-product m-0 mt-1">
                                  <span>CLEARANCE</span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={1}
                        lg={1}
                        xl={1}
                        className="d-flex align-items-center review-data-grid"
                      >
                        <div className="review-responsive-title">
                          <span>AVAILABLE QTY.</span>
                        </div>
                        <div className="font-weight-600 review-table-item-right-info-cell review-responsive-data">
                          {qtyInStock}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={1}
                        lg={1}
                        xl={1}
                        className="d-flex align-items-center review-data-grid"
                      >
                        <div className="review-responsive-title">
                          <span>ORDER QTY.</span>
                        </div>
                        <div className="font-weight-600 review-table-item-right-info-cell review-responsive-data">
                          {qtyOrdered}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={1}
                        lg={1}
                        xl={1}
                        className="d-flex align-items-center review-data-grid"
                      >
                        <div className="review-responsive-title">
                          <span>NEW QTY.</span>
                        </div>
                        <div className="font-weight-600 review-table-item-right-info-cell review-responsive-data">
                          {qtyOrdered}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={3}
                        lg={3}
                        xl={3}
                        className="d-flex price-sec-grid review-data-grid"
                      >
                        <div className="review-responsive-title price-responsive-title">
                          <div className="old-price-responsive-title">
                            <span>OLD PRICE:</span>
                          </div>
                          <div className="new-price-responsive-title">
                            <span>NEW PRICE:</span>
                          </div>
                        </div>
                        <div className="d-flex flex-row w-100 justify-content-center">
                          <div className="tab-body-old-price review-table-item-right-info-cell">
                            <Button
                              className={`bg-transparent button-focus-class ${selectedNewPrice[index]?.isOldType
                                ? "old-price-btn-contain"
                                : "old-price-btn-contain-blank"
                                }`}
                              tabIndex={1}
                              onClick={() => {
                                commonPriceSelector(
                                  { ...item, type: oldBlockType },
                                  selectedNewPrice,
                                  setSelectedNewPrice
                                )
                              }}
                            >
                              <div className="old-price-text">
                                <div>{`$ ${posRetailPrice?.toFixed(5)}`}</div>
                                <div className="mt-1 profit-percentage">
                                  {getProfit(
                                    posRetailPrice,
                                    vendorPrice?.price ?? vendorPrice
                                  )}{" "}
                                  %
                                </div>
                              </div>
                            </Button>
                          </div>
                          <div className="tab-body-new-price review-table-item-right-info-cell">
                            <Button
                              className={`bg-transparent button-focus-class ${selectedNewPrice[index]?.isNewType
                                ? "new-price-btn-contain"
                                : "new-price-btn-contain-blank"
                                }`

                              }
                              tabIndex={1}
                              onClick={() => {
                                commonPriceSelector(
                                  { ...item, type: newBlockType },
                                  selectedNewPrice,
                                  setSelectedNewPrice
                                )
                              }}
                            >
                              <div className="new-price-text">
                                <div>
                                  {`$ ${newPriceObj.price.toFixed(5)}`}
                                </div>
                                <div className="mt-1 profit-percentage">
                                  {newPriceObj.price
                                    ? `${getProfit(
                                      newPriceObj.price,
                                      vendorPrice?.price ?? vendorPrice
                                    )} %`
                                    : ""}
                                </div>
                              </div>
                            </Button>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={1}
                        xl={1}
                        className="d-flex align-items-center review-data-grid"
                      >
                        {selectedNewPrice?.length ? (
                          <div className="review-responsive-title ml-5">
                            <span>EXT-PRICE:</span>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="review-table-item-right-info-cell price-break font-weight-600 d-flex review-ext-price-value">
                          {selectedNewPrice?.length ? (
                            <>
                              {findExtTotal(
                                selectedNewPrice,
                                productId,
                                qtyOrdered,
                                posRetailPrice,
                                newPriceObj?.price,
                                lineId,
                                index
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : null}

      {isAvailableOutOfStockProduct?.length ? (
        <div className="review-item-not-in-stock">
          <div className="review-item-heading-details">
            <div className="review-item-price-change">
              <div className="review-item-price-change-details">
                <div className="review-item-select-price">
                  <div className="review-item-all-price">
                    <div className="review-item-price-container">
                      <PosImage src={outStock} alt="stock-icon" />
                      <div className="review-item-price-span">
                        {isAvailableOutOfStockProduct?.length} Out of Stock
                        <span className="review-item-span">
                          {isAvailableOutOfStockProduct?.length <= 1
                            ? "item except Clearance will be backordered"
                            : "All items except Clearance will be backordered"}
                        </span>
                      </div>
                    </div>
                    <div className="main-apply-to-all">
                      <div className="d-flex review-title-spacinbutton">
                        <p className="mb-0">Apply to all </p>
                        <Button
                          className="old-price-apply border-0 p-0 button-focus-class"
                          tabIndex={1}
                          onClick={() =>
                            commonPriceSelectAll(
                              oldBlockType,
                              outStockSelectedNewPrice,
                              setOutStockSelectedNewPrice
                            )
                          }
                        >
                          <p> Old Price </p>
                        </Button>
                        <Button
                          className="new-price-apply border-0 p-0 button-focus-class"
                          tabIndex={1}
                          onClick={() =>
                            commonPriceSelectAll(
                              newBlockType,
                              outStockSelectedNewPrice,
                              setOutStockSelectedNewPrice
                            )
                          }
                        >
                          <p> New Price</p>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="review-stock-table">
                <Grid container className="mt-4 mb-4 review-normal-title">
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={3}
                    lg={5}
                    xl={5}
                    className="d-flex"
                  >
                    <div className="review-table-item-header review-table-item-left-info-cell review-table-item-icon" />
                    <div className="review-table-item-header review-table-item-name">
                      ITEM
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                    <div className="review-table-item-header review-table-item-right-info-cell text-center">
                      AVAILABLE QTY.
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                    <div className="review-table-item-header review-table-item-right-info-cell text-center">
                      ORDER QTY.
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                    <div className="review-table-item-header review-table-item-right-info-cell text-center">
                      NEW QTY.
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    className="d-flex price-main-title"
                  >
                    <div className="review-table-item-header tab-body-old-price review-table-item-right-info-cell text-center">
                      OLD PRICE
                    </div>
                    <div className="review-table-item-header tab-body-new-price review-table-item-right-info-cell text-center">
                      NEW PRICE
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={1} xl={1}>
                    <div className="review-table-item-header review-table-item-right-info-cell price-break d-flex justify-content-end">
                      EXT-PRICE
                    </div>
                  </Grid>
                </Grid>

                {isAvailableOutOfStockProduct?.length > 0 ? (
                  outStockSelectedNewPrice?.map((item, index) => {
                    const {
                      clearance,
                      manufacturerName,
                      productName,
                      qtyInStock,
                      qtyOrdered,
                      productId,
                      posRetailPrice,
                      vendorPrice,
                      lineId,
                      newPriceObj,
                    } = item;
                    const newQtyClearance =
                      qtyOrdered > qtyInStock ? qtyInStock : qtyOrdered;
                    const clearanceQty = clearance
                      ? newQtyClearance
                      : qtyOrdered;

                    return (
                      <Grid
                        container
                        key={`${index}-outOfStock`}
                        className="main-review-data-grid"
                      >
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={3}
                          lg={5}
                          xl={5}
                          className="d-flex align-items-center review-data-grid"
                        >
                          <div className="review-responsive-title">
                            <span>ITEM:</span>
                          </div>
                          <div className="review-table-item-left-info d-flex review-data">
                            <div className="review-table-item-left-info-cell review-table-item-icon">
                              <PosImage
                                src={
                                  productsData?.thumbImg || images.defaultIcon
                                }
                                alt="product-icon"
                              />
                            </div>
                            <div className="review-table-item-name">
                              <div className="review-table-item-name-manufacturer">
                                {manufacturerName}
                              </div>
                              <div className="review-table-item-name-product">
                                {productName}
                                {clearance ? (
                                  <div className="clearance-product m-0 mt-1">
                                    <span>CLEARANCE</span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={1}
                          lg={1}
                          xl={1}
                          className="d-flex align-items-center review-data-grid"
                        >
                          <div className="review-responsive-title">
                            <span>AVAILABLE QTY.</span>
                          </div>
                          <div className="font-weight-600 review-table-item-right-info-cell review-responsive-data">
                            {qtyInStock}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={1}
                          lg={1}
                          xl={1}
                          className="d-flex align-items-center review-data-grid"
                        >
                          <div className="review-responsive-title">
                            <span>ORDER QTY.</span>
                          </div>
                          <div className="font-weight-600 review-table-item-right-info-cell review-responsive-data">
                            {qtyOrdered}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={1}
                          lg={1}
                          xl={1}
                          className="d-flex align-items-center review-data-grid"
                        >
                          <div className="review-responsive-title">
                            <span>NEW QTY.</span>
                          </div>
                          <div className="font-weight-600 review-table-item-right-info-cell review-responsive-data">
                            {clearanceQty}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={3}
                          lg={3}
                          xl={3}
                          className="d-flex price-sec-grid review-data-grid"
                        >
                          <div className="review-responsive-title price-responsive-title">
                            <div className="old-price-responsive-title">
                              <span>OLD PRICE:</span>
                            </div>
                            <div className="new-price-responsive-title">
                              <span>NEW PRICE:</span>
                            </div>
                          </div>
                          <div className="d-flex flex-row w-100 justify-content-center">
                            <div className="tab-body-old-price review-table-item-right-info-cell">
                              <Button
                                className={`bg-transparent button-focus-class ${outStockSelectedNewPrice[index]?.isOldType
                                  ? "old-price-btn-contain"
                                  : "old-price-btn-contain-blank"
                                  }`}
                                tabIndex={1}
                                onClick={() =>
                                  commonPriceSelector(
                                    {
                                      ...item,
                                      type: oldBlockType,
                                    },
                                    outStockSelectedNewPrice,
                                    setOutStockSelectedNewPrice
                                  )
                                }
                              >
                                <div className="old-price-text">
                                  <div>
                                    {`$ ${posRetailPrice?.toFixed(5)}`}
                                  </div>
                                  <div className="mt-1 profit-percentage">
                                    {getProfit(
                                      posRetailPrice,
                                      vendorPrice?.price ?? vendorPrice
                                    )}{" "}
                                    %
                                  </div>
                                </div>
                              </Button>
                            </div>
                            <div className="tab-body-new-price review-table-item-right-info-cell">
                              <Button
                                className={`bg-transparent button-focus-class ${outStockSelectedNewPrice[index]?.isNewType
                                  ? "new-price-btn-contain"
                                  : "new-price-btn-contain-blank"
                                  }`}
                                tabIndex={1}
                                onClick={() =>
                                  commonPriceSelector(
                                    { ...item, type: newBlockType },
                                    outStockSelectedNewPrice,
                                    setOutStockSelectedNewPrice
                                  )
                                }
                              >
                                <div className="new-price-text">
                                  <div>
                                    {`$ ${newPriceObj?.price.toFixed(5)}`}
                                  </div>
                                  <div className="mt-1 profit-percentage">
                                    {newPriceObj?.price
                                      ? `${getProfit(
                                        newPriceObj?.price,
                                        vendorPrice?.price ?? vendorPrice
                                      )} %`
                                      : ""}
                                  </div>
                                </div>
                              </Button>
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={3}
                          lg={1}
                          xl={1}
                          className="d-flex align-items-center review-data-grid"
                        >
                          {outStockSelectedNewPrice?.length ? (
                            <div className="review-responsive-title ml-5">
                              <span>EXT-PRICE:</span>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="review-table-item-right-info-cell price-break font-weight-600 d-flex review-ext-price-value">
                            {outStockSelectedNewPrice?.length ? (
                              <>
                                {findExtTotal(
                                  outStockSelectedNewPrice,
                                  productId,
                                  clearanceQty,
                                  posRetailPrice,
                                  newPriceObj?.price,
                                  lineId,
                                  index
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {/* end */}
        </div>
      ) : null}

      <div>
        <CommonModel
          openModal={modalVisible}
          closeModal={() => setModalVisible(false)}
          dialogClassName="adjust-stock-dialog-box"
          closeIconClassName="adjust-stock-close"
        >
          <AdjustStock setModalVisible={setModalVisible} />
        </CommonModel>
      </div>
      {isDiscontinuedProduct?.length ? (
        <div className="review-item-heading-details">
          <div className="review-item-price-change">
            <div className="review-item-price-change-details">
              <div className="review-item-select-price">
                <div className="review-item-all-price">
                  <div className="review-item-price-container">
                    <PosImage src={disContinue} alt="disContinue-icon" />
                    <div className="review-item-price-span">
                      {isDiscontinuedProduct?.length} Discontinued, Available in
                      Stock
                      <span className="review-item-span">
                        {isDiscontinuedProduct?.length <= 1
                          ? "This item cannot be backordered"
                          : "These items cannot be backordered"}
                      </span>
                    </div>
                  </div>
                  <div className="main-apply-to-all">
                    <div className="d-flex review-title-spacing review-apply-all">
                      <p className="mb-0">Apply to all </p>
                      <Button
                        className="old-price-apply border-0 button-focus-class p-0"
                        tabIndex={1}
                        onClick={() =>
                          commonPriceSelectAll(
                            oldBlockType,
                            discontinuedSelectedNewPrice,
                            setDiscontinuedSelectedNewPrice
                          )
                        }
                      >
                        <p> Old Price </p>
                      </Button>
                      <Button
                        className="new-price-apply border-0 button-focus-class p-0"
                        tabIndex={1}
                        onClick={() =>
                          commonPriceSelectAll(
                            newBlockType,
                            discontinuedSelectedNewPrice,
                            setDiscontinuedSelectedNewPrice
                          )
                        }
                      >
                        <p> New Price</p>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="review-stock-table">
              <Grid container className="mt-4 mb-4 review-normal-title">
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={3}
                  lg={5}
                  xl={5}
                  className="d-flex"
                >
                  <div className="review-table-item-header review-table-item-left-info-cell review-table-item-icon" />
                  <div className="review-table-item-header review-table-item-name">
                    ITEM
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                  <div className="review-table-item-header review-table-item-right-info-cell text-center">
                    AVAILABLE QTY.
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                  <div className="review-table-item-header review-table-item-right-info-cell text-center">
                    ORDER QTY.
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                  <div className="review-table-item-header review-table-item-right-info-cell text-center">
                    NEW QTY.
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={3}
                  className="d-flex price-main-title"
                >
                  <div className="review-table-item-header tab-body-old-price review-table-item-right-info-cell text-center">
                    OLD PRICE
                  </div>
                  <div className="review-table-item-header tab-body-new-price review-table-item-right-info-cell text-center">
                    NEW PRICE
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={1} xl={1}>
                  <div className="review-table-item-header review-table-item-right-info-cell price-break d-flex justify-content-end">
                    EXT-PRICE
                  </div>
                </Grid>
              </Grid>

              {isDiscontinuedProduct?.length > 0 ? (
                discontinuedSelectedNewPrice?.map((item, index) => {
                  const {
                    manufacturerName,
                    productName,
                    qtyInStock,
                    qtyOrdered,
                    productId,
                    posRetailPrice,
                    vendorPrice,
                    lineId,
                    newPriceObj,
                  } = item;

                  return (
                    <Grid
                      container
                      key={`${index}-discontinuedStock`}
                      className="main-review-data-grid"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={3}
                        lg={5}
                        xl={5}
                        className="d-flex align-items-center review-data-grid"
                      >
                        <div className="review-responsive-title">
                          <span>ITEM:</span>
                        </div>
                        <div className="review-table-item-left-info d-flex review-data">
                          <div className="review-table-item-left-info-cell review-table-item-icon">
                            <PosImage
                              src={
                                productsData?.thumbImg || images.defaultIcon
                              }
                              alt="product-icon"
                            />
                          </div>
                          <div className="review-table-item-name">
                            <div className="review-table-item-name-manufacturer">
                              {manufacturerName}
                            </div>
                            <div className="review-table-item-name-product">
                              {productName}
                            </div>
                          </div>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={1}
                        lg={1}
                        xl={1}
                        className="d-flex align-items-center review-data-grid"
                      >
                        <div className="review-responsive-title">
                          <span>AVAILABLE QTY.</span>
                        </div>
                        <div className="font-weight-600 review-table-item-right-info-cell review-responsive-data">
                          {qtyInStock}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={1}
                        lg={1}
                        xl={1}
                        className="d-flex align-items-center review-data-grid"
                      >
                        <div className="review-responsive-title">
                          <span>ORDER QTY.</span>
                        </div>
                        <div className="font-weight-600 review-table-item-right-info-cell review-responsive-data">
                          {qtyOrdered}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={1}
                        lg={1}
                        xl={1}
                        className="d-flex align-items-center review-data-grid"
                      >
                        <div className="review-responsive-title">
                          <span>NEW QTY.</span>
                        </div>
                        <div className="font-weight-600 review-table-item-right-info-cell review-responsive-data">
                          {qtyOrdered}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={3}
                        lg={3}
                        xl={3}
                        className="d-flex price-sec-grid review-data-grid"
                      >
                        <div className="review-responsive-title price-responsive-title">
                          <div className="old-price-responsive-title">
                            <span>OLD PRICE:</span>
                          </div>
                          <div className="new-price-responsive-title">
                            <span>NEW PRICE:</span>
                          </div>
                        </div>
                        <div className="d-flex flex-row w-100 justify-content-center">
                          <div className="tab-body-old-price review-table-item-right-info-cell">
                            <Button
                              className={`bg-transparent button-focus-class ${discontinuedSelectedNewPrice[index]?.isOldType
                                ? "old-price-btn-contain"
                                : "old-price-btn-contain-blank"
                                }`}
                              tabIndex={1}
                              onClick={() =>
                                commonPriceSelector(
                                  { ...item, type: oldBlockType },
                                  discontinuedSelectedNewPrice,
                                  setDiscontinuedSelectedNewPrice
                                )
                              }
                            >
                              <div className="old-price-text">
                                <div>{`$ ${posRetailPrice?.toFixed(5)}`}</div>
                                <div className="mt-1 profit-percentage">
                                  {getProfit(
                                    posRetailPrice,
                                    vendorPrice?.price ?? vendorPrice
                                  )}{" "}
                                  %
                                </div>
                              </div>
                            </Button>
                          </div>
                          <div className="tab-body-new-price review-table-item-right-info-cell">
                            <Button
                              className={`bg-transparent button-focus-class ${discontinuedSelectedNewPrice[index]?.isNewType
                                ? "new-price-btn-contain"
                                : "new-price-btn-contain-blank"
                                }`}
                              tabIndex={1}
                              onClick={() =>
                                commonPriceSelector(
                                  { ...item, type: newBlockType },
                                  discontinuedSelectedNewPrice,
                                  setDiscontinuedSelectedNewPrice
                                )
                              }
                            >
                              <div className="new-price-text">
                                <div>
                                  {`$ ${newPriceObj.price.toFixed(5)}`}
                                </div>
                                <div className="mt-1 profit-percentage">
                                  {newPriceObj.price
                                    ? `${getProfit(
                                      newPriceObj.price,
                                      vendorPrice?.price ?? vendorPrice
                                    )} %`
                                    : ""}
                                </div>
                              </div>
                            </Button>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={1}
                        xl={1}
                        className="d-flex align-items-center review-data-grid"
                      >
                        {discontinuedSelectedNewPrice?.length ? (
                          <div className="review-responsive-title ml-5">
                            <span>EXT-PRICE:</span>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="review-table-item-right-info-cell price-break font-weight-600 d-flex review-ext-price-value">
                          {discontinuedSelectedNewPrice?.length ? (
                            <>
                              {findExtTotal(
                                discontinuedSelectedNewPrice,
                                productId,
                                qtyOrdered,
                                posRetailPrice,
                                newPriceObj?.price,
                                lineId,
                                index
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {isDeactivatedProduct?.length ? (
        <div className="review-item-heading-details">
          <div className="review-item-price-change">
            <div className="review-item-price-change-details">
              <div className="review-item-select-price">
                <div className="review-item-all-price">
                  <div className="review-item-price-container">
                    <PosImage src={deactivated} alt="deactivated-icon" />
                    <div className="review-item-price-span">
                      {isDeactivatedProduct?.length} Discontinued, Out of Stock
                      <span className="review-item-span">
                        {isDeactivatedProduct?.length === 0 ||
                          isDeactivatedProduct?.length === 1
                          ? "This item can no longer be purchased and hence removed from the cartd"
                          : "These items can no longer be purchased and hence removed from the cart"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="review-stock-table">
              <Grid container className="mt-4 mb-4 review-normal-title">
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={3}
                  lg={5}
                  xl={5}
                  className="d-flex"
                >
                  <div className="review-table-item-header review-table-item-left-info-cell review-table-item-icon" />
                  <div className="review-table-item-header review-table-item-name">
                    ITEM
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                  <div className="review-table-item-header review-table-item-right-info-cell text-center">
                    AVAILABLE QTY.
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                  <div className="review-table-item-header review-table-item-right-info-cell text-center">
                    ORDER QTY.
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
                  <div className="review-table-item-header review-table-item-right-info-cell text-center">
                    NEW QTY.
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={3}
                  className="d-flex price-main-title"
                >
                  <div className="review-table-item-header tab-body-old-price review-table-item-right-info-cell text-center"></div>
                  <div className="review-table-item-header tab-body-new-price review-table-item-right-info-cell text-center">
                    NET PRICE
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={1} xl={1}>
                  <div className="review-table-item-header review-table-item-right-info-cell price-break d-flex justify-content-end">
                    EXT-PRICE
                  </div>
                </Grid>
              </Grid>

              {isDeactivatedProduct?.length > 0 ? (
                isDeactivatedProduct?.map((item, index) => {
                  const {
                    manufacturerName,
                    productName,
                    qtyInStock,
                    qtyOrdered,
                    posRetailPrice,
                  } = item;
                  return (
                    <>
                      <Grid
                        container
                        key={`${index}-deActiveStock`}
                        className="main-review-data-grid"
                      >
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={3}
                          lg={5}
                          xl={5}
                          className="d-flex align-items-center review-data-grid"
                        >
                          <div className="review-responsive-title">
                            <span>ITEM:</span>
                          </div>
                          <div className="review-table-item-left-info d-flex review-data">
                            <div className="review-table-item-left-info-cell review-table-item-icon">
                              <PosImage
                                src={
                                  productsData?.thumbImg || images?.defaultIcon
                                }
                                alt="product-icon"
                              />
                            </div>
                            <div className="review-table-item-name">
                              <div className="review-table-item-name-manufacturer">
                                {manufacturerName}
                              </div>
                              <div className="review-table-item-name-product">
                                {productName}
                              </div>
                            </div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={1}
                          lg={1}
                          xl={1}
                          className="d-flex align-items-center review-data-grid"
                        >
                          <div className="review-responsive-title">
                            <span>AVAILABLE QTY.</span>
                          </div>
                          <div className="font-weight-600 review-table-item-right-info-cell review-responsive-data">
                            {qtyInStock}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={1}
                          lg={1}
                          xl={1}
                          className="d-flex align-items-center review-data-grid"
                        >
                          <div className="review-responsive-title">
                            <span>ORDER QTY.</span>
                          </div>
                          <div className="font-weight-600 review-table-item-right-info-cell review-responsive-data">
                            {qtyOrdered}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={1}
                          lg={1}
                          xl={1}
                          className="d-flex align-items-center review-data-grid"
                        >
                          <div className="review-responsive-title">
                            <span>NEW QTY.</span>
                          </div>
                          <div className="font-weight-600 review-table-item-right-info-cell review-responsive-data">
                            {qtyInStock}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={3}
                          lg={3}
                          xl={3}
                          className="d-flex price-sec-grid review-data-grid"
                        >
                          <div className="review-responsive-title price-responsive-title">
                            <div className="new-price-responsive-title">
                              <span>NET PRICE:</span>
                            </div>
                          </div>
                          <div className="d-flex flex-row w-100 justify-content-center">
                            <div className="tab-body-old-price review-table-item-right-info-cell">
                              {posRetailPrice}
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={3}
                          lg={1}
                          xl={1}
                          className="d-flex align-items-center review-data-grid"
                        >
                          <div className="review-responsive-title ml-5">
                            <span>EXT-PRICE:</span>
                          </div>
                          <div className="review-table-item-right-info-cell price-break font-weight-600 d-flex review-ext-price-value">
                            <sup>$ </sup>
                            {(qtyInStock * posRetailPrice).toFixed(2)}
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : null}

      <div className="review-items-footer"></div>
      <div className="review-item-save-and-go-to-cart">
        <PosButton
          variant="contained"
          color="primary"
          type="submit"
          className="btn-savechanges save-go-to-cart-btn btn"
          onClick={saveAndGotoCart}
          endIcon="S"
          size="large"
          disabled={disableSubmit !== isDisableData}
          tabIndex={1}
        >
          Save and Go to Cart
        </PosButton>
      </div>
    </Container>
  );
};
export default Reviewitems;
