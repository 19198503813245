import { AppToURLPathName } from "enums";
import { getLatestHeader } from "utils/utils";
import { getLocalStoraGeItem } from "./utils";

export const getLocationData = ({ setUnitsLoading, setUnits, setShelvesLoading, setShelves, setShelvesErrors, setUnitsErrors, row, productsData, getLocationProductWise }) => {
  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {};
  const headers = getLatestHeader();
  const singleUnits = `${process.env.REACT_APP_API_BASE_URL}/${AppToURLPathName.Api}/v2/stock/warehouses/${stockWarehouseId}/products/${productsData.productId}/locations/rows/${row?.stockRowID ?? row?.rowID}/units`
  const allUnits = `${process.env.REACT_APP_API_BASE_URL}/${AppToURLPathName.Api}/v1/stock/unit/rows/${row?.stockRowID ?? row?.rowID}/active?page=1&size=100`
  fetch(getLocationProductWise ? singleUnits : allUnits, { headers })
    .then(res => res.json())
    .then(data => {
      setUnitsLoading(false)
      setUnits(data)

      if (data?.length === 1) {
        setShelvesLoading(true)
        const unit = data?.find((item) => Boolean(item))

        const singleShelves = `${process.env.REACT_APP_API_BASE_URL}/${AppToURLPathName.Api}/v2/stock/warehouses/${stockWarehouseId}/products/${productsData.productId}/locations/rows/${row?.stockRowID ?? row?.rowID}/units/${unit?.stockUnitID}/shelves`
        const allShelves = `${process.env.REACT_APP_API_BASE_URL}/${AppToURLPathName.Api}/v1/stock/rows/${row?.stockRowID ?? row?.rowID}/units/${unit?.stockUnitID ?? unit?.unitID}/shelves?page=1&size=100`

        fetch(getLocationProductWise ? singleShelves : allShelves, { headers })
          .then(res => res.json())
          .then(data => {
            setShelvesLoading(false)
            setShelves(data)
          }).catch((error) => {
            setShelvesLoading(false)
            setShelvesErrors(error)
          })

      }

    }).catch((error) => {
      setUnitsLoading(false)
      setUnitsErrors(error)
    })
}
