import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Distributor, RootState } from "models";
import '@fontsource/inter';
import "./pageHeading.scss";
import PosImage from "../posImage/image";
import { Paths } from "enums";

interface HeadingProps {
  heading: string | number;
  grayHeading?: boolean;
  blackHeading?: boolean;
  mediumHeading?: boolean;
  startingIcon?: string;
  className?: string;
  iconStyling?: string;
  titlelink?: string;
  imageWithLink?: ReactNode
  id?:string;
}

const PosPageHeading = (props: HeadingProps) => {
  const {
    heading,
    grayHeading,
    blackHeading,
    className,
    startingIcon,
    iconStyling,
    mediumHeading,
    titlelink,
    imageWithLink,
    id
  } = props;

  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );

  return (
    <div className={`page-heading d-flex gap-2 ${className ? className : ''}`} id={id}>
      {startingIcon ?
        <div>
          <PosImage src={startingIcon} alt="starting-icon" className={iconStyling} />
        </div>
        : <></>}
      {heading && <span className={`${grayHeading ? 'heading-gray-text' : blackHeading ? 'heading-black-text' : 'heading-black-text'} ${mediumHeading ? 'medium-heading-text' : ''}`}>
      {heading}
      {titlelink && <a href={`${distributor?.httpsUrl}${Paths.manageOrder}?orderID=${titlelink}`} target="_blank">{titlelink}</a>  }
      {imageWithLink && imageWithLink}
      </span>}
    </div>
  );
};

export default PosPageHeading;
