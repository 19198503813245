import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";

import { stopLoading } from "utils/utils";
import { getDraftsJobName } from "../request";

export const useDraftSearchParam = () => {
  const { mutate, isLoading, data, error, isSuccess ,isError} = useMutation(
    async (payload) => {
      return getDraftsJobName(payload);
    },
    {
      onSuccess: stopLoading,
      onError: stopLoading,
    }
  );

  const setDraftSearchParam = useCallback(
    (payload) => {
      mutate(payload);
    },
    [mutate]
  );

  return {
    setDraftSearchParam,
    isDraftSearchLoading: isLoading,
    draftSearchData : data,
    error,
    isSuccess,
    isDraftSearchError:isError,
  };
};
