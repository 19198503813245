import { useSelector } from "react-redux";
import styled from "styled-components";
import { Button } from "../../components";
import { ModalProps, RootState } from "../../models";

const ModalContent = styled.div`
  padding: 1rem;
  .modal-header {
    padding: 0 1rem 0 0;
    border: none;

    .btn-transparent-dark:hover {
      background: transparent;
    }
  }
  .modal-footer {
    border: none;
  }
`;

export function Modal() {
  const modal = useSelector<RootState, ModalProps>((state) => state.modal);
  return (
    <div
      id="jumper_modal"
      className={`modal fade ${modal.isOpen ? modal.className : ""}`}
      data-backdrop={`${modal.notClosableByOverlay ? "static" : true}`}
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div
        className={`modal-dialog ${
          modal.modalSize ? `modal-${modal.modalSize}` : ""
        } ${modal.scrollableContent ? "modal-dialog-scrollable" : ""} ${
          modal.verticallyCentered ? "modal-dialog-centered" : ""
        }`}
        role="document"
      >
        <ModalContent className="modal-content">
          <div className="modal-header">
            <Button
              className="close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              label="&times;"
              transparent={true}
              onClick={modal.close}
            />
          </div>
          <div className="modal-body p-0">{modal.children}</div>
        </ModalContent>
      </div>
    </div>
    // <div>
    //   <Modall
    //     isOpen={modal.isOpen}
    //     onRequestClose={closeModal}
    //     style={customStyles}
    //   >
    //     <ModalContent className="modal-content">
    //       <div className="modal-header">
    //         <Button
    //           className="close"
    //           type="button"
    //           label="&times;"
    //           transparent={true}
    //           onClick={modal.close}
    //         />
    //       </div>
    //       <div className="modal-body p-0">{modal.children}</div>
    //     </ModalContent>
    //   </Modall>
    // </div>

    //TODO : Create New File for below Modal and use it because our UI is breaking due to below Modal

    // <div>
    //   <Modall
    //     isOpen={modal.isOpen}
    //     onRequestClose={closeModal}
    //     style={customStyles}
    //   >
    //     <Button
    //           className="close"
    //           type="button"
    //           label="&times;"
    //           transparent={true}
    //           onClick={closeModal}
    //         />
    //     <div>{modal.children}</div>
    //   </Modall>
    // </div>
  );
}
