export const GET_ALL_USERS_START = "GET_ALL_USERS_START";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";

export const EDIT_USERS_DETAILS_START = "EDIT_USERS_DETAILS_START";
export const EDIT_USERS_DETAILS_SUCCESS = "EDIT_USERS_DETAILS_SUCCESS";
export const EDIT_USERS_DETAILS_ERROR = "EDIT_USERS_DETAILS_ERROR";
export const EDIT_USERS_DETAILS_CANCEL = "EDIT_USERS_DETAILS_CANCEL";

export const EDIT_PERMISSIONS_START = "EDIT_PERMISSIONS_START";
export const EDIT_PERMISSIONS_SUCCESS = "EDIT_PERMISSIONS_SUCCESS";
export const EDIT_PERMISSIONS_ERROR = "EDIT_PERMISSIONS_ERROR";

export const GET_AWAITING_APPROVAL_START = "GET_AWAITING_APPROVAL_START";
export const GET_AWAITING_APPROVAL_SUCCESS = "GET_AWAITING_APPROVAL_SUCCESS";
export const GET_AWAITING_APPROVAL_ERROR = "GET_AWAITING_APPROVAL_ERROR";

export const GET_INVITED_START = "GET_INVITED_START";
export const GET_INVITED_SUCCESS = "GET_INVITED_SUCCESS";
export const GET_INVITED_ERROR = "GET_INVITED_ERROR";

export const REJECT_USER_START = "REJECT_USER_START";
export const REJECT_USER_SUCCESS = "REJECT_USER_SUCCESS";
export const REJECT_USER_ERROR = "REJECT_USER_ERROR";

export const REVIEW_USER_START = "REVIEW_USER_START";
export const REVIEW_USER_END = "REVIEW_USER_END";

export const APPROVE_USER_START = "APPROVE_USER_START";
export const APPROVE_USER_SUCCESS = "APPROVE_USER_SUCCESS";
export const APPROVE_USER_ERROR = "APPROVE_USER_ERROR";

export const REMOVE_USER_START = "REMOVE_USER_START";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
export const REMOVE_USER_ERROR = "REMOVE_USER_ERROR";

export const RESET_USER_FLAG = "RESET_USER_FLAG";

export const EDIT_USERS_FALSE = "EDIT_USERS_FALSE";

export const USER_MESSAGE_REMOVE = "USER_MESSAGE_REMOVE";

export const SEARCH_USER_ADD = "SEARCH_USER_ADD";

export const START = "start";
export const SUCCESS = "success";
export const ERROR = "error";
export const CANCEL = "cancel";

export const GET_ALL_SEARCH_USERS_START = "GET_ALL_SEARCH_USERS_START";
export const GET_ALL_SEARCH_USERS_SUCCESS = "GET_ALL_SEARCH_USERS_SUCCESS";
export const GET_ALL_SEARCH_USERS_ERROR = "GET_ALL_SEARCH_USERS_ERROR";

export const SET_LOGIN_USER ="SET_LOGIN_USER";

export const SET_SEARCH_USER_ID="SET_SEARCH_USER_ID"