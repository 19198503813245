import { createSelector } from "@reduxjs/toolkit";
import { IProductsFilters, IProductsSlice } from "../types";

export const productSliceReducer = (state: { productSliceReducer: IProductsSlice }) =>
  state?.productSliceReducer;

export const productFiltersSlice = (state: { productFiltersSlice: IProductsFilters }) =>
  state?.productFiltersSlice;

export const getProductsData = createSelector(
  productSliceReducer,
  (data) => data.productsData
);

export const productsSearched = createSelector(
  productSliceReducer,
  (data) => data.productsSearchedData
);

export const getProductSearchLoader = createSelector(
  productSliceReducer,
  (data) => data.productSearchLoader
);

export const getProductFilters = createSelector(
  productFiltersSlice,
  (data) => data.getProductFiltersData
);

export const getProductLoader = createSelector(
  productSliceReducer,
  (data) => data.productLoader
);

export const getProductSkeleton = createSelector(
  productSliceReducer,
  (data) => data.productSkeleton
);

export const getfilterData = createSelector(
  productFiltersSlice,
  (data) => data.filterData
);

export const getOptionsData = createSelector(
  productFiltersSlice,
  (data) => data.optionsData
);

export const getSearchKeyword = createSelector(
  productFiltersSlice,
  (data) => data.searchKeyword
);