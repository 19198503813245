import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import { Box } from "@material-ui/core";

import useStyle from "./style";

const PosDataGrid = ({
  rows,
  columns,
  pagination,
  pageSize,
  className,
  components,
  hideFooter,
  selectionModel,
  onSelectionModelChange,
  disableSelectionOnClick,
  checkboxSelection,
  rowHeight,
  getRowId,
  loading,
  localeText,
  hideFooterPagination
}: DataGridProps) => {
  const classes = useStyle();
  return (
    <Box className={classes.dataGridContainer} tabIndex={-1}>
      <DataGrid
        pagination={pagination}
        className={`${classes.dataGrid} ${className}`}
        rows={rows}
        loading={loading}
        rowHeight={rowHeight}
        columns={columns}
        pageSize={pageSize}
        autoHeight
        disableColumnMenu
        disableDensitySelector
        disableSelectionOnClick={disableSelectionOnClick}
        disableColumnFilter
        disableColumnSelector
        components={components}
        hideFooter={hideFooter}
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selectionModel}
        getRowId={getRowId}
        localeText={localeText}
        hideFooterPagination={hideFooterPagination}
      />
    </Box>
  );
};

export default PosDataGrid;
