import {
  DISTRIBUTOR_FETCHING,
  DISTRIBUTOR_FETCH_ERROR,
  DISTRIBUTOR_FETCH_SUCCESS,
} from "./constants";

import { Distributor } from "../../models";

export function DistributorRequest(payload: number) {
  return {
    type: DISTRIBUTOR_FETCHING,
    distributorId: payload
  };
}

export function DistributorSuccess(payload: Distributor) {
  return {
    type: DISTRIBUTOR_FETCH_SUCCESS,
    payload,
  };
}

export function DistributorError(errors: any) {
  return {
    type: DISTRIBUTOR_FETCH_ERROR,
    errors,
  };
}
