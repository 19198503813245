import { useDispatch } from "react-redux";
import {
    PosButton,
    PosIconButton,
    PosModel,
} from "apps/pos/Common/uiCore";
import { ClearIcon } from "constants/icons";

import "apps/pos/pages/customerInfo/Documents/openEditDocument/openEditDocumentModal.scss";

import { arrowResetGeneric } from "sagas/pos/actions";

interface deleteDocumentModalProps {
    deleteDocumentData: boolean;
    setDeleteDocumentData: (value: boolean) => void;
    keyIndex?: string;
    removeDocumentItem?: (value: string) => void;
    fileName: string;
}

const deleteDocumentModal = ({ 
    deleteDocumentData, 
    setDeleteDocumentData,
    keyIndex,
    removeDocumentItem,
    fileName
}: deleteDocumentModalProps) => {

const dispatch = useDispatch()
    return (
        <>
            <PosModel
                dialogClassName="edit-document-info-modal delete-document-modal"
                open={deleteDocumentData}
                onClose={() => {
                    setDeleteDocumentData(false);
                }}
            >
                <div className="edit-document-info-section">
                    <PosIconButton
                        className="close-modal-icon button-focus"
                        onClick={() => setDeleteDocumentData(false)}
                    >
                        <ClearIcon />
                    </PosIconButton>
                </div>
                <div className="edit-document-container">
                    <div className="edit-document-title-section">
                        <div className="edit-document-title">
                            Delete Document?
                        </div>
                    </div>
                    <div className="edit-document-type-section">
                        <div className="edit-document-type-text">
                        Are you sure you want to delete <span className="doc-customer-name">{fileName}</span>? 
                        <br/>
                        This action cannot be undone.
                        </div>
                        
                    </div>

                    <div className="edit-document-bottom-section">
                        <div className="d-flex justify-content-end w-100">
                            <div>
                                <PosButton
                                    variant="contained"
                                    className="cancel-btn w-auto h-auto btn"
                                    onClick={() => {
                                        setDeleteDocumentData(false);
                                    }}
                                >
                                    Cancel
                                </PosButton>
                            </div>
                            <div>
                                <PosButton
                                    variant="contained"
                                    className="delete-confirmation-btn w-auto h-auto btn"
                                    onClick={() => {
                                        removeDocumentItem(keyIndex)                         
                                        dispatch(arrowResetGeneric());
                                    }
                                    }
                                >
                                    Delete
                                </PosButton>
                            </div>
                        </div>
                    </div>
                </div>
            </PosModel>
        </>
    );
};

export default deleteDocumentModal;
