import * as constants from "./constants";

//GET Requests

export function getAllPriceSchedulesRequest() {
  return {
    type: constants.GET_ALL_PRICE_SCHEDULES_START,
  };
}

export function getAllPriceSchedulesSuccess(payload: any) {
  return {
    type: constants.GET_ALL_PRICE_SCHEDULES_SUCCESS,
    payload,
  };
}

export function getAllPriceSchedulesError(error: any) {
  return {
    type: constants.GET_ALL_PRICE_SCHEDULES_ERROR,
    error,
  };
}

export function getSelectedPriceScheduleRequest() {
  return {
    type: constants.GET_SELECTED_PRICE_SCHEDULES_START,
  };
}

export function getSelectedPriceScheduleSuccess(payload: any) {
  return {
    type: constants.GET_SELECTED_PRICE_SCHEDULES_SUCCESS,
    payload,
  };
}

export function getSelectedPriceScheduleError(error: any) {
  return {
    type: constants.GET_SELECTED_PRICE_SCHEDULES_ERROR,
    error,
  };
}
export function getProductPriceScheduleRequest() {
  return {
    type: constants.GET_PRODUCT_PRICE_SCHEDULES_START,
  };
}

export function getProductPriceScheduleSuccess(payload: any) {
  return {
    type: constants.GET_PRODUCT_PRICE_SCHEDULES_SUCCESS,
    payload,
  };
}

export function getProductPriceScheduleError(error: any) {
  return {
    type: constants.GET_PRODUCT_PRICE_SCHEDULES_ERROR,
    error,
  };
}

//POST Requests

export function addAllPriceSchedulesRequest() {
  return {
    type: constants.ADD_ALL_PRICE_SCHEDULES_START,
  };
}

export function addAllPriceSchedulesSuccess(payload: any) {
  return {
    type: constants.ADD_ALL_PRICE_SCHEDULES_SUCCESS,
    payload,
  };
}

export function addAllPriceSchedulesError(error: any) {
  return {
    type: constants.ADD_ALL_PRICE_SCHEDULES_ERROR,
    error,
  };
}

export function addAllProductsRequest() {
  return {
    type: constants.ADD_ALL_PRODUCTS_START,
  };
}

export function addAllProductsSuccess(payload: any) {
  return {
    type: constants.ADD_ALL_PRODUCTS_SUCCESS,
    payload,
  };
}

export function addAllProductsError(error: any) {
  return {
    type: constants.ADD_ALL_PRODUCTS_ERROR,
    error,
  };
}

export function addSelectedProductsRequest() {
  return {
    type: constants.ADD_SELECTED_PRODUCTS_START,
  };
}

export function addSelectedProductsSuccess(payload: any) {
  return {
    type: constants.ADD_SELECTED_PRODUCTS_SUCCESS,
    payload,
  };
}

export function addSelectedProductsError(error: any) {
  return {
    type: constants.ADD_SELECTED_PRODUCTS_ERROR,
    error,
  };
}

export function removeProductsRequest() {
  return {
    type: constants.REMOVE_PRODUCTS_START,
  };
}

export function removeProductsSuccess(payload: any) {
  return {
    type: constants.REMOVE_PRODUCTS_SUCCESS,
    payload,
  };
}

export function removeProductsError(error: any) {
  return {
    type: constants.REMOVE_PRODUCTS_ERROR,
    error,
  };
}
//PUT Requests

export function updateSelectedProductsRequest() {
  return {
    type: constants.UPDATE_SELECTED_PRODUCTS_START,
  };
}

export function updateSelectedProductsSuccess(payload: any) {
  return {
    type: constants.UPDATE_SELECTED_PRODUCTS_SUCCESS,
    payload,
  };
}

export function updateSelectedProductsError(error: any) {
  return {
    type: constants.UPDATE_SELECTED_PRODUCTS_ERROR,
    error,
  };
}

export function updateSelectedPriceScheduleRequest() {
  return {
    type: constants.UPDATE_SELECTED_PRICE_SCHEDULE_START,
  };
}

export function updateSelectedPriceScheduleSuccess(payload: any) {
  return {
    type: constants.UPDATE_SELECTED_PRICE_SCHEDULE_SUCCESS,
    payload,
  };
}

export function updateSelectedPriceScheduleError(error: any) {
  return {
    type: constants.UPDATE_SELECTED_PRICE_SCHEDULE_ERROR,
    error,
  };
}

export function updateProductsRequest() {
  return {
    type: constants.UPDATE_PRODUCTS_START,
  };
}

export function updateProductsSuccess(payload: any) {
  return {
    type: constants.UPDATE_PRODUCTS_SUCCESS,
    payload,
  };
}

export function updateProductsError(error: any) {
  return {
    type: constants.UPDATE_PRODUCTS_ERROR,
    error,
  };
}

//DELETE Requests

export function removePriceScheduleRequest() {
  return {
    type: constants.REMOVE_PRICE_SCHEDULES_START,
  };
}

export function removePriceScheduleSuccess(payload: any) {
  return {
    type: constants.REMOVE_PRICE_SCHEDULES_SUCCESS,
    payload,
  };
}

export function removePriceScheduleError(error: any) {
  return {
    type: constants.REMOVE_PRICE_SCHEDULES_ERROR,
    error,
  };
}

export function removeSelectedPriceScheduleRequest() {
  return {
    type: constants.REMOVE_SELECTED_PRICESCHEDULE_START,
  };
}

export function removeSelectedPriceScheduleSuccess(payload: any) {
  return {
    type: constants.REMOVE_SELECTED_PRICESCHEDULE_SUCCESS,
    payload,
  };
}

export function removeSelectedPriceScheduleError(error: any) {
  return {
    type: constants.REMOVE_SELECTED_PRICESCHEDULE_ERROR,
    error,
  };
}
