import { endpoints } from "apps/pos/endpoints";
import serviceV2 from "services/config2";

export const getSalesPersons = () => {
  return serviceV2.get(endpoints.salesPerson).then((response) => {
    return response.data;
  });
};

export const getTakers = () => {
  return serviceV2.get(endpoints.takers).then((response) => {
    return response.data;
  });
};

export function getDraftsDetails(payload: any) {
  return serviceV2.get(endpoints.draftsDetails(payload)).then((response) => {
    return response.data;
  });
}

export function getDraftsJobName(payload: any) {
  return serviceV2.post(endpoints.draftJobName, { ...payload }).then((response) => {
    return response.data;
  });
}
