import React, { ReactNode } from "react";

interface TabPanelProps {
  children: ReactNode;
  index: number;
  value: number;
  className?: string;
}

const PosTabPanel = (props: TabPanelProps) => {
  const { children, value, index,className, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      className={className}
    >
      {children}
    </div>
  );
};

export default PosTabPanel;
