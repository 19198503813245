import { motion } from 'framer-motion/dist/framer-motion';
import './OverlaySkrim.scss';

const OverlaySkrim = (props) => {
    const { onClick } = props;

    const skrimAnimVariants = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            transition: {
                duration: 0.2,
                ease: 'circOut',
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.1,
                ease: 'circOut',
            }
        },
    }
    return (
        <motion.div
            className="overlay-skrim"
            onClick={onClick}
            initial='initial'
            animate='animate'
            exit='exit'
            variants={skrimAnimVariants}
        >
        </motion.div>
    );
}

export default OverlaySkrim;