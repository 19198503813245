import { Grid } from "@material-ui/core";

import Button from "components/SPButton/Button";

import { springboardCustomerInfo } from "constants/constant";

export const CustomerInfo = ({custInfo , handleShow , jobLotQuoteID , httpsUrl , jobLotPackageId }) => {

    const { customerFirstName , customerLastName , customerEmail , customerPhone , score } = custInfo || {}
    const { customerInfo , edit , name , email , phone , score : scoreTitle , manage , assignToAnotherRecipient , requestMissingInfo , linkQuoteRecipient , assign , sendRfi , linkRecipient , preferred } = springboardCustomerInfo;

    return(
        <section className="subquote-report-section">
          <Grid container className="subquote-report-container">
            <form
               className="pr-2"
               id="linkQuoteRecipientForm"
            >
              <div className="cust_info-main">
                <div className="d-flex justify-content-between flex-align-item-center">
                  <div className="cust-info-sab">
                    {customerInfo}
                  </div>
                    <Button
                     label={edit}
                     type='secondary'
                     size='small'
                     onClick={handleShow}
                    />
                </div>
                <Grid container className="report-div">
                  <Grid item xs={12} sm={7}>
                    <div className="document-types-title">
                      <span>{name}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <div className="send_rfi_row_new ml-2">
                      <span>{customerFirstName || customerLastName
                              ? `${customerFirstName || ''} ${customerLastName || ''} `
                            : null}</span>
                    </div>
                  </Grid>
                </Grid>
                <Grid container className="report-div">
                  <Grid item xs={12} sm={7}>
                    <div className="document-types-title">
                      <span>{email}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <div className="send_rfi_row_new ml-2">
                      <span>{customerEmail ?? null}</span>
                    </div>
                  </Grid>
                </Grid>
                <Grid container className="report-div">
                  <Grid item xs={12} sm={7}>
                    <div className="document-types-title">
                      <span>{phone}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <div>
                      <span className="send_rfi_row_new ml-2"> {customerPhone ?? null}</span>
                      {customerPhone ? <> <br />
                      <span className="fs-14 inter-reg span-info-sec ml-2">
                        {preferred}
                      </span> </> : null}
                    </div>
                  </Grid>
                </Grid>
                <Grid container className="report-div">
                  <Grid item xs={12} sm={7}>
                    <div className="document-types-title">
                      <span>{scoreTitle}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <div className="send_rfi_row_new ml-2">
                      <span>{score ?? null}</span>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="cust_info-main pt-4 manage-sub">
                <div className="pb-2 d-flex justify-content-between flex-align-item-center">
                  <div className="cust-info-sab">
                    {manage}
                  </div>
                </div>
                <Grid container className="report-div">
                  <Grid item xs={12} sm={7}>
                    <div className="document-types-title">
                      <span>{assignToAnotherRecipient}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <div className="ml-2">
                    <Button
                      label={assign}
                      type='secondary'
                      size='small'
                      target="_blank"
                      href={`${httpsUrl}/gmSale/msJobLotQuote_copyQuoteToPackage?quotePackageID=${jobLotPackageId}&jobLotQuoteID=${jobLotQuoteID}`}
                    />
                    </div>
                  </Grid>
                </Grid>
                <Grid container className="report-div">
                  <Grid item xs={12} sm={7}>
                    <div className="document-types-title">
                      <span>{requestMissingInfo}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <div className="ml-2">
                      <Button
                        label={sendRfi}
                        type='secondary'
                        size='small'
                        href={`${httpsUrl}/gmSale/msJobLotQuote_RFI_send?jobLotQuoteID=${jobLotQuoteID}`}
                        target="_blank"
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container className="report-div align-items-center">
                  <Grid item xs={12} sm={7}>
                    <div className="document-types-title">
                      <span>{linkQuoteRecipient}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <div className="ml-2">
                      <Button
                        label={linkRecipient}
                        type='secondary'
                        size='small'
                        href={`${httpsUrl}/gmSale/msQuote_linkOrderCust?quotePackageID=${jobLotPackageId}`}
                        target="_blank"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </form>
          </Grid>
        </section>
      )
}