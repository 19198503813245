import {  useRef } from 'react';
import {  useSelector } from 'react-redux';
import { useForkRef } from '@material-ui/core';
import useAutocomplete, { createFilterOptions } from '@mui/material/useAutocomplete';
import { styled } from '@mui/system';
import dayjs from 'dayjs';

import { PosButton } from 'apps/pos/Common/uiCore';
import { keys } from 'utils/helper';

import { SearchIcon } from 'constants/icons';
import { MMDDYYYY } from 'apps/pos/constants';
import { staticData, couponListItemLimit } from 'constants/constant';
import { retailPriceLabel } from "../../modules/productDetailsContainer/constants";

import { getIsGetAllProductsCoupons } from 'sagas/productsDetails/selector';


const Input = styled('input')(({ theme }) => ({
  width: 200,
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
}));

const Listbox = styled('ul')(({ theme }) => ({
  width: 200,
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: 'absolute',
  listStyle: 'none',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
  overflow: 'auto',
  maxHeight: 200,
  border: '1px solid rgba(0,0,0,.25)',
}));

export default function UseAutocomplete({ couponsList, AddCoupon }) {

  const isGetAllProductsCoupons = useSelector(getIsGetAllProductsCoupons)
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    limit: couponListItemLimit,
  });
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    popupOpen,
    setAnchorEl,
    getOptionProps,
    groupedOptions,

  } = useAutocomplete({
    options: couponsList ? couponsList : null,
    getOptionLabel: (option: any) => option.couponCode,
    filterOptions,
  });

  const refen = useRef();
  const rootRef = useForkRef(refen, setAnchorEl);

 const CustomListBox = (props) =>{
   const handleKeyDown = (event) => {
     const { downarrow ,uparrow } = keys
     if (event.keyCode === downarrow || event.keyCode === uparrow) {
       event.preventDefault();
     }
   };

   return (
     <Listbox {...props} className='list-group-listbox' onKeyDown={handleKeyDown}>
       {groupedOptions?.map((option, index) => {
         const { couponID, couponCode, couponDescription, couponDiscount, endDate, minOrderAmt, startDate } = option || {};
         return (
           <li {...getOptionProps({ option, index })} key={index} className='coupons-list-items' >
             <div className='d-flex justify-content-between align-items-start' >
               <div>
                 <span className='notes-added-user-name'>{couponCode}</span>
                 <span className='ml-2'>{Math.floor(couponDiscount)}%</span>
               </div>
               <PosButton
                 onClick={() =>
                   AddCoupon(couponID, couponDiscount, "")
                 }
                 variant="contained"
                 color='primary'
                 className="btn-discard btn font-size title-edit-section title-edit-section-margin small--btn coupons-list-add-btn"
               >
                 Add
               </PosButton>
             </div>
             <p className='small-text gray-text mt-2 mb-0'>Valid {dayjs(startDate).format(MMDDYYYY)} - {dayjs(endDate).format(MMDDYYYY)}</p>
             <p className='small-text gray-text mt-2 mb-0'>Min. order ${minOrderAmt}</p>
             <p className='small-text gray-text mt-2 mb-0'>{couponDescription}</p>
           </li>


         )
       })}
     </Listbox>
   )
 }
  return (
    <div className='position-relative' ref={refen}>
      <div {...getRootProps()} className='listgroup-search-input input-focus-styling' ref={rootRef}>
        <SearchIcon />
        <Input {...getInputProps()}
          placeholder={retailPriceLabel.couponsPlaceholder}
        />
      </div>
      {groupedOptions?.length > 0 ? (
        <CustomListBox {...getListboxProps()}></CustomListBox>
      ) :
        <>
          {
            isGetAllProductsCoupons && popupOpen &&
            <Listbox {...getListboxProps()} className='list-group-listbox'>
              <li className='p-0 border-0 text-center'>
                  {staticData.couponsLoading}
              </li>
            </Listbox>
          }
          {
            !isGetAllProductsCoupons && popupOpen && !groupedOptions.length &&
            <Listbox {...getListboxProps()} className='list-group-listbox'>
              <li className='p-0 border-0 text-center'>
                  {staticData.noActiveCoupons}
              </li>
            </Listbox>
          }
        </>}
    </div>
  );
}
