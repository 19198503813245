import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";

const SkeletonWrapper = ({ shape, width, height, radius }) => {
  return (
    <ShapedSkeleton
      shape={shape}
      width={width}
      height={height}
      radius={radius}
    />
  );
};

export default SkeletonWrapper;