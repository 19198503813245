import { AnyAction } from "redux";
import * as constants from "./constants";

const initialState = {
  isLoading: false,
  companyDetails: {},
  contactInfo: {},
  domainAndUrl: {},
  logoDetails: {},
  emailDistrosDetails: {},
  carrierDetails: [],
  signatureInfo: {},
  phonesDetails: [],
  paymentMethodsDetails: {},
  subscriptionDetails: {},
  socialLinksDetails: {},
  trampolineSettingsDetails: {},
  cannedResponses: {},
  appearances: {},
  procurementPartners: {},
  actors: {},
  warehouses: [],
  hrRepresentative: {},
};

const reducer = function quotesReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case constants.GET_COMPANY_DETAILS_START:
      return { ...state, isLoading: true };
    case constants.GET_COMPANY_DETAILS_SUCCESS:
      return { ...state, companyDetails: action.payload, isLoading: false };
    case constants.GET_COMPANY_DETAILS_ERROR:
      return { ...state, companyDetails: action.payload, isLoading: false };

    case constants.GET_CONTACT_INFO_START:
      return { ...state, isLoading: true };
    case constants.GET_CONTACT_INFO_SUCCESS:
      return { ...state, contactInfo: action.payload, isLoading: false };
    case constants.GET_CONTACT_INFO_ERROR:
      return { ...state, contactInfo: action.payload, isLoading: false };

    case constants.GET_DOMAIN_URL_START:
      return { ...state, isLoading: true };
    case constants.GET_DOMAIN_URL_SUCCESS:
      return { ...state, domainAndUrl: action.payload, isLoading: false };
    case constants.GET_DOMAIN_URL_ERROR:
      return { ...state, domainAndUrl: action.payload, isLoading: false };

    case constants.GET_LOGO_START:
      return { ...state, isLoading: true };
    case constants.GET_LOGO_SUCCESS:
      return { ...state, logoDetails: action.payload, isLoading: false };
    case constants.GET_LOGO_ERROR:
      return { ...state, logoDetails: action.payload, isLoading: false };

    case constants.GET_EMAIL_DISTROS_START:
      return { ...state, isLoading: true };
    case constants.GET_EMAIL_DISTROS_SUCCESS:
      return {
        ...state,
        emailDistrosDetails: action.payload,
        isLoading: false,
      };
    case constants.GET_EMAIL_DISTROS_ERROR:
      return {
        ...state,
        emailDistrosDetails: action.payload,
        isLoading: false,
      };

    case constants.GET_CARRIERS_DETAILS_START:
      return { ...state, isLoading: true };
    case constants.GET_CARRIERS_DETAILS_SUCCESS:
      return { ...state, carrierDetails: action.payload, isLoading: false };
    case constants.GET_CARRIERS_DETAILS_ERROR:
      return { ...state, carrierDetails: action.payload, isLoading: false };

    case constants.GET_SIGNATURES_DETAILS_START:
      return { ...state, isLoading: true };
    case constants.GET_SIGNATURES_DETAILS_SUCCESS:
      return { ...state, signatureInfo: action.payload, isLoading: false };
    case constants.GET_SIGNATURES_DETAILS_ERROR:
      return { ...state, signatureInfo: action.payload, isLoading: false };

    case constants.GET_PHONES_DETAILS_START:
      return { ...state, isLoading: true };
    case constants.GET_PHONES_DETAILS_SUCCESS:
      return { ...state, phonesDetails: action.payload, isLoading: false };
    case constants.GET_PHONES_DETAILS_ERROR:
      return { ...state, phonesDetails: action.payload, isLoading: false };

    case constants.GET_PAYMENT_METHODS_DETAILS_START:
      return { ...state, isLoading: true };
    case constants.GET_PAYMENT_METHODS_DETAILS_SUCCESS:
      return {
        ...state,
        paymentMethodsDetails: action.payload,
        isLoading: false,
      };
    case constants.GET_PAYMENT_METHODS_DETAILS_ERROR:
      return {
        ...state,
        paymentMethodsDetails: action.payload,
        isLoading: false,
      };

    case constants.GET_SUBSCRIPTION_DETAILS_START:
      return { ...state, isLoading: true };
    case constants.GET_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        subscriptionDetails: action.payload,
        isLoading: false,
      };
    case constants.GET_SUBSCRIPTION_DETAILS_ERROR:
      return {
        ...state,
        subscriptionDetails: action.payload,
        isLoading: false,
      };

    case constants.GET_SOCIAL_LINKS_START:
      return { ...state, isLoading: true };
    case constants.GET_SOCIAL_LINKS_SUCCESS:
      return { ...state, socialLinksDetails: action.payload, isLoading: false };
    case constants.GET_SOCIAL_LINKS_ERROR:
      return { ...state, socialLinksDetails: action.payload, isLoading: false };

    case constants.GET_TRAMPOLINE_SETTINGS_START:
      return { ...state, isLoading: true };
    case constants.GET_TRAMPOLINE_SETTINGS_SUCCESS:
      return {
        ...state,
        trampolineSettingsDetails: action.payload,
        isLoading: false,
      };
    case constants.GET_TRAMPOLINE_SETTINGS_ERROR:
      return {
        ...state,
        trampolineSettingsDetails: action.payload,
        isLoading: false,
      };

    case constants.GET_CANNED_RESPONSES_START:
      return { ...state, isLoading: true };
    case constants.GET_CANNED_RESPONSES_SUCCESS:
      return { ...state, cannedResponses: action.payload, isLoading: false };
    case constants.GET_CANNED_RESPONSES_ERROR:
      return { ...state, cannedResponses: action.payload, isLoading: false };

    case constants.GET_APPEARANCE_DETAILS_START:
      return { ...state, isLoading: true };
    case constants.GET_APPEARANCE_DETAILS_SUCCESS:
      return { ...state, appearances: action.payload, isLoading: false };
    case constants.GET_APPEARANCE_DETAILS_ERROR:
      return { ...state, appearances: action.payload, isLoading: false };

    case constants.GET_PROCUREMENT_PARTNERS_DETAILS_START:
      return { ...state, isLoading: true };
    case constants.GET_PROCUREMENT_PARTNERS_DETAILS_SUCCESS:
      return {
        ...state,
        procurementPartners: action.payload,
        isLoading: false,
      };
    case constants.GET_PROCUREMENT_PARTNERS_DETAILS_ERROR:
      return {
        ...state,
        procurementPartners: action.payload,
        isLoading: false,
      };

    case constants.GET_ACTORS_DETAILS_START:
      return { ...state, isLoading: true };
    case constants.GET_ACTORS_DETAILS_SUCCESS:
      return { ...state, actors: action.payload, isLoading: false };
    case constants.GET_ACTORS_DETAILS_ERROR:
      return { ...state, actors: action.payload, isLoading: false };

    case constants.GET_WAREHOUSES_DETAILS_START:
      return { ...state, isLoading: true };
    case constants.GET_WAREHOUSES_DETAILS_SUCCESS:
      return { ...state, warehouses: action.payload, isLoading: false };
    case constants.GET_WAREHOUSES_DETAILS_ERROR:
      return { ...state, warehouses: action.payload, isLoading: false };

    case constants.GET_HR_REPRESENTATIVE_DETAILS_START:
      return { ...state, isLoading: true };
    case constants.GET_HR_REPRESENTATIVE_DETAILS_SUCCESS:
      return { ...state, hrRepresentative: action.payload, isLoading: false };
    case constants.GET_HR_REPRESENTATIVE_DETAILS_ERROR:
      return { ...state, hrRepresentative: action.payload, isLoading: false };
    default:
      return state;
  }
};

export default reducer;
