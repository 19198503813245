import { createSelector } from "@reduxjs/toolkit";
import { PosResponseProps } from "models";

export const posResponseData = (state: { pos: PosResponseProps }) => state?.pos;

export const getStockWarehouseData = createSelector(
  posResponseData,
  (data) => data.stockWarehouseData
)

export const getNewProductGroupDataSelector = createSelector(
  posResponseData,
  (data) => data.getNewProductGroupData
)

export const getNewProductManufacturer = createSelector(
  posResponseData,
  (data) => data.getNewProductManufacturerData
)

export const getNewProductVendor = createSelector(
  posResponseData,
  (data) => data.getNewProductVendorData
)

export const getMovingCostHistory = createSelector(
  posResponseData,
  (data) => data.movingAvgCostHistory
)

export const getMovingCostHistoryTotal = createSelector(
  posResponseData,
  (data) => data.movingAvgCostHistoryTotal
)

export const getIsLoading = createSelector(
  posResponseData,
  (data) => data.isLoading
)

export const getProductsData = createSelector(
  posResponseData,
  (data) => data.productsData
)

export const getLastProductQty = createSelector(
  posResponseData,
  (data) => data.lastProductQty
)

export const getLastProductViewIndex = createSelector(
  posResponseData,
  (data) => data.lastProductViewIndex
)
