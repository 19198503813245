import { Formik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PosButton, PosModel, PosSelect } from "apps/pos/Common/uiCore";
import { Button } from "components";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";

import { constantCustomerCode, SetCustomerCodeModalProps } from "apps/pos/pages/customerInfo/Modal/type";
import { CheckIcon, ClearIcon } from "constants/icons";
import { PosFlowDataProp, ScheduleDataProps } from "constants/interfaces";
import { addCustomerLabel } from "constants/posLabel";
import { PosResponseProps, RootState } from "models";

import { setPosFlowData } from "sagas/persistReducer/actions";
import { priceSchedulesRequest, setUpdatePriceScheduleFalse, updatePriceSchedulesStart } from "sagas/pos/actions";

import { snackbarDetail } from "constants/constant";
import "./style.scss";


const SetPricingScheduleModal = ({
  openEditPricingSchedule,
  setOpenEditPricingSchedule,
  priceScheduleId
}: SetCustomerCodeModalProps) => {
  const dispatch = useDispatch();

  const [priceSchedulesData, setpriceSchedulesData] = useState([]);
  const { selectPriceSchedule } = addCustomerLabel;
  const { priceScheduleDetails, updatePriceScheduleLoading, updatePriceScheduleSuccess } = useSelector<RootState, PosResponseProps>(
    (state) => {
      return { ...state.pos, ...state.persistReducer };
    }
  );

  useEffect(() => {
    if (updatePriceScheduleSuccess) {
      dispatch(snakbarShow({ message: snackbarDetail.priceSchedule }));
      setTimeout(() => {
        dispatch(setUpdatePriceScheduleFalse());
      }, 0);
    }
  }, [updatePriceScheduleSuccess]);
  useEffect(() => {
    if (!priceScheduleDetails?.data?.length) {
      dispatch(priceSchedulesRequest({ pageSize: 1, size: 100 }));
    }
    if (!updatePriceScheduleLoading) {
      setOpenEditPricingSchedule(false)
    }
  }, [updatePriceScheduleLoading]);
  useEffect(() => {
    if (priceScheduleDetails?.data?.length) {
      setpriceSchedulesData(priceScheduleDetails?.data);
    }
  }, [priceScheduleDetails]);
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { customer, schedule } = posFlowData || {}
  const setSchedulesDataWithDrawer = async (item) => {
    const updatedPriceSchedule = priceSchedulesData.find(element => element.id === item.pricingSchedule);
    dispatch(
      updatePriceSchedulesStart({
        customerId: customer?.customerId,
        priceScheduleId: item?.pricingSchedule,
      })
    );
    const data = {
      ...posFlowData,
      schedule: updatedPriceSchedule,
      customer: {
        ...posFlowData?.customer,
        priceScheduleId: updatedPriceSchedule?.id,
        priceScheduleName: updatedPriceSchedule?.name,
      },
      customerInfoUpdated: true,
    };
    dispatch(setPosFlowData(data));

    const oldSchedules = JSON.parse(localStorage.getItem("schedule")) || [];
    const itemIndex = _.findIndex(oldSchedules, item);
    if (itemIndex !== -1) {
      oldSchedules.splice(itemIndex, 1);
    }
    oldSchedules.unshift(item);
    oldSchedules.splice(5);
    localStorage.setItem("schedule", JSON.stringify(oldSchedules));
  };

  return (
    <>
      <Formik
        initialValues={{
          pricingSchedule: schedule?.id || priceScheduleId || "",
        }}
        enableReinitialize
        onSubmit={(values) => setSchedulesDataWithDrawer(values)}
      >
        {({ values, setFieldValue, resetForm, handleSubmit }) => {
          const { pricingSchedule } = values;

          return (
            <PosModel
              open={openEditPricingSchedule}
              onClose={() => {
                if (!updatePriceScheduleLoading) {
                  setOpenEditPricingSchedule(false)
                }
              }}
              className={`set-customer-code-modal`}
              dialogClassName={`set-customer-code-modal-container`}
              dialogHeader={
                <div className="modal-header">
                  <span className="modal-title">
                    {constantCustomerCode.setPricingSchedule}
                  </span>
                  <Button className="bg-transparent border-0 p-0 button-focus"
                    onClick={() => {
                      if (!updatePriceScheduleLoading) {
                        resetForm();
                        setOpenEditPricingSchedule(false);
                      }
                    }
                    } >
                    <ClearIcon
                      className="clear-icon"
                    />
                  </Button>
                </div>
              }
              dialogFooter={
                <>
                  <div className="d-flex flex-row-reverse">
                    <PosButton
                      disabled={updatePriceScheduleLoading}
                      variant="contained"
                      color="primary"
                      className="change-cust-code-save-btn btn"
                      startIcon={<CheckIcon />}
                      onClick={() => handleSubmit()}
                      fontWeight
                    >
                      {constantCustomerCode.saveChanges}
                    </PosButton>
                    <PosButton
                      type="button"
                      variant="contained"
                      className="mr-2 change-code-cancel-btn btn"
                      startIcon={<ClearIcon />}
                      fontWeight
                      onClick={() => {
                        if (!updatePriceScheduleLoading) {
                          resetForm();
                          setOpenEditPricingSchedule(false);
                        }
                      }}
                    >
                      {constantCustomerCode.cancel}
                    </PosButton>
                  </div>
                </>
              }
            >
              <div className="modal-form">
                <div className="mt-20 modal-input-title mb-2">
                  {constantCustomerCode.pricingSchedule}
                </div>
                <div className="w-100">
                  <PosSelect
                    className=" product-detail-select tabDropdown"
                    name="pricingSchedule"
                    variant="filled"
                    id="filled-basic"
                    autoFocus
                    value={pricingSchedule}
                    onChange={(e) => {
                      setFieldValue("pricingSchedule", e.target.value);
                    }}
                    placeholder={selectPriceSchedule}
                    options={
                      priceSchedulesData?.length &&
                      priceSchedulesData.map(
                        ({ name, id }: ScheduleDataProps) => {
                          return {
                            label: name,
                            value: id,
                          };
                        }
                      )
                    }
                  />
                </div>
              </div>
            </PosModel>
          );
        }}
      </Formik>
    </>
  );
};
export default SetPricingScheduleModal;
