import * as constants from "./constants";

// Start import Quote
export function importQuoteRequest(payload: any) {
  return {
    type: constants.IMPORT_QUOTE_START,
    payload,
  };
}
export function importQuoteSuccess(payload: any) {
  return {
    type: constants.IMPORT_QUOTE_SUCCESS,
    payload,
  };
}
export function importQuoteError(error: any) {
  return {
    type: constants.IMPORT_QUOTE_ERROR,
    error,
  };
}
export function importQuoteClear() {
  return {
    type: constants.IMPORT_QUOTE_CLEAR,
  };
}
// End import Quote

// Start import Quote Search
export function importQuoteSearchRequest(payload: any) {
  return {
    type: constants.IMPORT_QUOTE_SEARCH_START,
    payload,
  };
}
export function importQuoteSearchSuccess(payload: any) {
  return {
    type: constants.IMPORT_QUOTE_SEARCH_SUCCESS,
    payload,
  };
}
export function importQuoteSearchError(error: any) {
  return {
    type: constants.IMPORT_QUOTE_SEARCH_ERROR,
    error,
  };
}
export function importQuoteSearchClear() {
  return {
    type: constants.IMPORT_QUOTE_SEARCH_CLEAR,
  };
}
// End import Quote Search

// Start import Quote Item
export function importQuoteItemsRequest(payload: any) {
  return {
    type: constants.IMPORT_QUOTE_ITEMS_START,
    payload,
  };
}
export function importQuoteItemsSuccess(payload: any) {
  return {
    type: constants.IMPORT_QUOTE_ITEMS_SUCCESS,
    payload,
  };
}
export function importQuoteItemsError(error: any) {
  return {
    type: constants.IMPORT_QUOTE_ITEMS_ERROR,
    error,
  };
}
export function importQuoteItemsClear() {
  return {
    type: constants.IMPORT_QUOTE_ITEMS_CLEAR,
  };
}

export function arrowResetImportQuote() {
  return {
    type: constants.ARR0W_INDEX_RESET_IMPORT_QUOTE,
  };
}

export function arrowDownIndexImportQuote(payload) {
  return {
    type: constants.ARR0W_DOWN_INDEX_IMPORT_QUOTE,
    payload,
  };
}
export function arrowUpIndexImportQuote() {
  return {
    type: constants.ARR0W_UP_INDEX_IMPORT_QUOTE,
  };
}
// End import Quote Item

