import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {
    PosImage,
} from "apps/pos/Common/uiCore";
import { setDeliveredStorageItemDetail, setEditStorageDetailItem, setUpdateLocationSuccess } from "../CustomerStorage/redux/storage.slice";
import HandleLongData from "apps/pos/Common/HandleLongData";
import { PosResponseProps, RootState } from "models";
import Skeleton from "../Skeleton";

import { Paths } from "enums";
import images from "constants/images";
import { commonClassName, title } from "constants/constant";
import { MMDDYYYYformat } from "apps/pos/constants";

import { arrowResetGeneric } from "sagas/pos/actions";

import "apps/pos/pages/customerInfo/CustomerDrafts/customerDrafts.scss";
import "apps/pos/pages/customerInfo/CustomerOrders/customerOrders.scss";
import "apps/pos/pages/customerInfo/CustomerStorage/customerStorage.scss";

const CustomerPending = ({ data, storageTopDataHeight, searchCustomersStoragePendingRef, tableRowRef, enterPress, setEnterPress ,searchValue,isApiLoading}) => {

    const dispatch = useDispatch();
    const { selectedIndexGeneric } = useSelector<RootState, PosResponseProps>(
      (state) => state.pos
    );

    const navigate = useNavigate();

    const { noPendingShipmentsFound, noPendingShipmentsFoundEmptyState } = title
    const {customerInfoTableTitle} = commonClassName

    const handleRowClick = (row) => {
        dispatch(setUpdateLocationSuccess(false))
        dispatch(setDeliveredStorageItemDetail({}))
        dispatch(setEditStorageDetailItem(row))
        navigate(Paths.subStorageItems, {state: { isNotFromSR: true }})
    }

    const dynamicStorageHeight = {height: `calc(95vh - ${storageTopDataHeight - 20}px)`}
    useEffect(() => {
        if (searchCustomersStoragePendingRef.current) {
            dispatch(arrowResetGeneric());  
        }
    }, [searchCustomersStoragePendingRef])


    useEffect(()=>{
        if (enterPress && selectedIndexGeneric> -1 ){
            const row = data[selectedIndexGeneric]
            if (row){
               handleRowClick(row)
               setEnterPress(false)
            }
        }
    }, [enterPress])

    return (
        <>
            <section className="document-main-section storage-info-table" ref={searchCustomersStoragePendingRef}>
                <div className="document-container">
                    <div>
                        <TableContainer component={Paper} className="customer-info-table-container" 
                        style={dynamicStorageHeight}
                        >
                       {
                        isApiLoading ? <Skeleton count={5} /> :
                            <Table aria-label="simple table">
                                {!!data?.length ? 
                                <TableHead>
                                <TableRow>
                                    <TableCell className={`${customerInfoTableTitle} first-added-new-table-row`}>ID</TableCell>
                                    <TableCell className={`${customerInfoTableTitle} second-added-new-table-row`}>ORDER #</TableCell>
                                    <TableCell className={`${customerInfoTableTitle} third-added-new-table-row`}>SHIPMENT #</TableCell>
                                    <TableCell className={`${customerInfoTableTitle} fourth-added-new-table-row`}>INVOICE #</TableCell>
                                    <TableCell className={`${customerInfoTableTitle} fifth-added-new-table-row`}>CUSTOMER PO</TableCell>
                                    <TableCell className={`${customerInfoTableTitle} sixth-added-new-table-row`}>JOB NAME</TableCell>
                                    <TableCell className={`${customerInfoTableTitle} seventh-added-new-table-row`}>LOCATION</TableCell>
                                    <TableCell className={`${customerInfoTableTitle} eigth-added-new-table-row`}>EST. DELIVERY DATE</TableCell>
                                </TableRow>
                            </TableHead>
                            : <></>
                                }
                                
                                <TableBody>
                                    {!!data?.length ? data?.map((row, index) => {
                                        const {
                                            orderID,
                                            shipmentID,
                                            poNum,
                                            jobName,
                                            storageLocation,
                                            estDeliveryDate,
                                            customerInvoiceID,
                                            shipmentInStorageID,
                                          } = row;
                                        return (
                                            <TableRow
                                                className={`customer-table-data-row ${index === selectedIndexGeneric ? "selectedRow" : ""}`}
                                                key={`${orderID}row${index}`}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}                                                
                                                onClick={() => handleRowClick(row)}
                                                style={{
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                        index === selectedIndexGeneric
                                                            ? "lightgray"
                                                            : "white",
                                                }}
                                                ref={tableRowRef[index]}
                                                id={`tableRowRef${index}`}
                                            >
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="10%">
                                                    <span>{shipmentInStorageID}</span>
                                                </TableCell>
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="10%">
                                                    <span>
                                                        {orderID}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="10%">
                                                    <span>
                                                        {shipmentID}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="10%">
                                                    <span>
                                                        {customerInvoiceID}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="15%">
                                                    <span>
                                                        {poNum}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="25%">
                                                    <HandleLongData jobName={jobName} />
                                                </TableCell>
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="10%">
                                                    <span>
                                                        {storageLocation}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={commonClassName.customerTableRowDataStyling} width="10%">
                                                    <span>
                                                        {moment(estDeliveryDate).format(MMDDYYYYformat)}
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }) : 
                                        <>
                                            {
                                                !searchValue ? (
                                                    <div className="mt-5 text-center row justify-content-center">
                                                        <PosImage
                                                            src={images.noPendingStorage}
                                                            height={140}
                                                            width={120}
                                                            alt="add product"
                                                        />
                                                        <span className="int-20-reg empty-state-fonts">{noPendingShipmentsFoundEmptyState}</span>
                                                    </div>
                                                ) :
                                                    <>
                                                        <div className="mt-3 d-flex justify-content-center"><span className="int-18-reg-bold">{noPendingShipmentsFound}</span></div>
                                                    </>
                                            }
                                        </>                                       
                                    }
                                </TableBody>
                            </Table>
                       }
                        </TableContainer>
                    </div>
                </div>
            </section>
        </>
    );
};
export default CustomerPending;
