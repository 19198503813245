import { AnyAction } from "redux";
import { MODAL_UPDATE_CONTENT, RESET_MODAL } from "./constants";

const initialState = {
  className: "",
  notClosableByOverlay: false,
  scrollableContent: false,
  verticallyCentered: false,
  modalSize: "md",
  children: null,
  isOpen: false,
  removePaddingBottom: false,
  fixedModal: false,
};
const reducer = function distributorReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case MODAL_UPDATE_CONTENT:
      return { ...state, ...action.payload };
    case RESET_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
