import { useState, useRef, useEffect } from "react";
import "apps/pos/Common/uiCore/posSnackbar/snackbar.scss";
import { IconButton } from "apps/springboard/Component/SBItems/elements";

type SnackbarProps = {
  message: string;
  open: boolean;
  onClose: () => void;
  actionText?: string;
  actionOnClick?: () => void;
  autohideDuration?: number;
};

const PosSnackbar = (props: SnackbarProps) => {
  const {
    open,
    message,
    onClose,
    actionText,
    actionOnClick,
    autohideDuration = 10e3,
  } = props;
  const LEAVING_DURATION = autohideDuration - 2e3;
  const [leaving, setLeaving] = useState(false);

  const clock: any = useRef<number | null>(null);
  const seconds = useRef(0);

  const tick = () => {
    unwind();
    clock.current = setInterval(() => {
      seconds.current++;
      setLeaving(seconds.current >= LEAVING_DURATION / 1000);

      if (seconds.current === autohideDuration / 1000) reset();
    }, 1e3);
  };

  const unwind = () => {
    seconds.current = 0;
    if (clock.current) clearInterval(clock.current);
  };

  const reset = () => {
    setLeaving(false);
    onClose();
    unwind();
  };

  const onEnter = () => {
    setLeaving(false);
    unwind();
  };

  const onLeave = () => {
    if (!open) return;
    tick();
  };

  useEffect(() => {
    if (open) tick();
    if (!open) reset();
  }, [open, message]);

  interface ActionsProps {
    actionText?: string;
    actionOnClick?: () => void;
    onClose: () => void;
  }

  const Actions = (props: ActionsProps) => {
    const { actionText, onClose, actionOnClick } = props;
    return (
      <div className="snackbar-actions">
        {actionText && actionOnClick && (
          <button className="snackbar-action-button" onClick={actionOnClick}>
            {actionText}
          </button>
        )}
        <IconButton name="close" onClick={onClose} />
      </div>
    );
  };

  return (
    <div className={`snackbar-platform`}>
      <div
        className={`snackbar ${open ? "visible" : "hidden"} ${
          leaving ? "leaving" : ""
        }`}
        onPointerEnter={onEnter}
        onPointerLeave={onLeave}
      >
        <p className="snackbar-message">{message}</p>
        <Actions
          onClose={onClose}
          actionText={actionText}
          actionOnClick={actionOnClick}
        />
      </div>
    </div>
  );
};

export default PosSnackbar;
