import { makeStyles, TableCell, withStyles } from "@material-ui/core";

import { colors } from "constants/styles";

export const StyledTableCell = withStyles(() => ({
  head: {
    color: "#aaa",
    fontSize: 11,
    fontFamily: "Inter, Regular",
    paddingLeft: 0,
    borderBottomWidth: 0,
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "Inter, Regular",
    borderBottomWidth: 0,
    paddingLeft: 0,
  },
}))(TableCell);

export const useStyles = makeStyles({
  maindiv: {
    paddingTop: "50px",
  },
  button: {
    backgroundColor: "black",
    color: "white",
    borderRadius: "10px",
    padding: "7px",
    marginRight: "10%",
    fontSize: "16px",
    width: "15%",
  },
  buttondiv: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  searchdiv: {
    display: "flex",
    paddingTop: "3.5%",
  },
  main: {
    paddingTop: "0px",
  },
  table: {
    minWidth: 500,
  },
  root: {
    position: "unset",
    "& .MuiTableHead-root": {
      position: "unset",
      color: "black",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: 30,
      borderBottomLeftRadius: 30,
    },
  },
  spanReturn: {
    fontSize: "1.25rem",
  },
  spanNoReturn: {
    color: "red",
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  divSpan: {
    display: "flex",
    gap: "1rem",
    paddingTop: "15px",
    alignItems: "center",
  },
  returnHeaderWrapper: {
    width: "100% ",
  },
  headerSummary: {
    color: colors.black,
    fontSize: "1.25rem",
    marginTop: "1rem",
    paddingBottom: "0.6rem",
    fontWeight: 700,
    borderBottom: `2px solid ${colors.border} !important`,
  },
  borderBottomLine: {
    borderBottom: `1px solid ${colors.black}`,
  },
  mainDivTable: {
    width: "80%",
  },
  noReturnIcon: {
    width: "1.875rem",
    height: "1.875rem",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  partialFilledIcon: {
    display: "flex",
    // width: "1.5rem",
    // height: "1.5rem",
    "& img": {
      width: "1.5rem",
      height: "1.5rem",
    },
  },
});
