import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Will remove 'any' once we get proper data from BE
export interface ProductCharacteristicsSliceI {
  isGetProductsCharacteristics: boolean;
  getProductCharacteristicsData: any;
  getProductCharacteristicsError: any;
  getAllProductCharacteristicsData:any;
  isGetAllProductsCharacteristics : boolean;
  getAllProductCharacteristicsError : any;
  isRemoveAllCharacteristics: boolean;
  removeAllCharacteristicsData: any;
  removeAllCharacteristicsError: any;
  isRemoveParticularCharacteristics?: boolean;
  removeParticularCharacteristicsData: any;
  removeParticularCharacteristicsError: any;
  GetAllCharacteristicTypeList:any;
  AddProductCharacteristicsError:any
  AddCharacteristicData:any
  isAddProductsCharacteristicsSuccess:boolean
}

const initialState: ProductCharacteristicsSliceI = {
    isGetProductsCharacteristics: false,
    getProductCharacteristicsData: null,
    getProductCharacteristicsError: null,
    getAllProductCharacteristicsData : null,
    isGetAllProductsCharacteristics : false,
    getAllProductCharacteristicsError : null,
    isRemoveAllCharacteristics: false,
    removeAllCharacteristicsData: null,
    removeAllCharacteristicsError: null,
    isRemoveParticularCharacteristics: false,
    removeParticularCharacteristicsData: null,
    removeParticularCharacteristicsError:null,
    GetAllCharacteristicTypeList:[],
    AddProductCharacteristicsError :null,
    AddCharacteristicData : null,
    isAddProductsCharacteristicsSuccess : false,
};

export const productsCharacteristicSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    getProductsCharacteristicsStart: (state) => {
      state.getProductCharacteristicsData = null;
      state.isGetProductsCharacteristics = true;
    },
    getProductsCharacteristicsSuccess: (state, action: PayloadAction<any>) => {
      state.getProductCharacteristicsData = action.payload.data;
      state.isGetProductsCharacteristics = false;
    },
    getProductsCharacteristicsError: (state, action: PayloadAction<any>) => {
      state.getProductCharacteristicsError = action.payload;
      state.getProductCharacteristicsData = null;
      state.isGetProductsCharacteristics = false;
    },
    getAllProductsCharacteristicsStart: (state) => {
      state.getAllProductCharacteristicsData = null;
      state.isGetAllProductsCharacteristics = true;
    },
    getAllProductsCharacteristicsSuccess: (state, action: PayloadAction<any>) => {
      state.getAllProductCharacteristicsData = action.payload.data;
      state.isGetAllProductsCharacteristics = false;
    },
    getAllProductsCharacteristicsError: (state, action: PayloadAction<any>) => {
      state.getAllProductCharacteristicsError = action.payload;
      state.getAllProductCharacteristicsData = null;
      state.isGetAllProductsCharacteristics = false;
    },
    removeAllCharacteristicsStart: (state) => {
      state.removeAllCharacteristicsData = null;
      state.isRemoveAllCharacteristics = true;
    },
    removeAllCharacteristicsSuccess: (state, action: PayloadAction<any>) => {
      state.removeAllCharacteristicsData = action.payload.data;
      state.isRemoveAllCharacteristics = false;
    },
    removeAllCharacteristicsError: (state, action: PayloadAction<any>) => {
      state.removeAllCharacteristicsError = action.payload;
      state.removeAllCharacteristicsData = null;
      state.isRemoveAllCharacteristics = false;
    },
    removeParticularCharacteristicsStart: (state) => {
      state.removeParticularCharacteristicsData = null;
      state.isRemoveParticularCharacteristics = true;
    },
    removeParticularCharacteristicsSuccess: (state, action: PayloadAction<any>) => {
      state.removeParticularCharacteristicsData = action.payload.data;
      state.isRemoveParticularCharacteristics = false;
    },
    removeParticularCharacteristicsError: (state, action: PayloadAction<any>) => {
      state.removeParticularCharacteristicsError = action.payload;
      state.removeParticularCharacteristicsData = null;
      state.isRemoveParticularCharacteristics = false;
    },
    GetAllCharacteristicTypeListStart: (state) => {
      state.GetAllCharacteristicTypeList = [];
      state.isGetAllProductsCharacteristics = true;
    },
    GetAllCharacteristicTypeListSuccess: (state, action: PayloadAction<any>) => {
      state.GetAllCharacteristicTypeList = action.payload.data;
      state.isGetAllProductsCharacteristics = false;
    },
    GetAllCharacteristicTypeListError: (state, action: PayloadAction<any>) => {
      state.getAllProductCharacteristicsError = action.payload;
      state.GetAllCharacteristicTypeList = [];
      state.isGetAllProductsCharacteristics = false;
    },
    AddCharacteristicStart: (state) => {
      state.AddCharacteristicData = null;
      state.isAddProductsCharacteristicsSuccess = true;
    },
    AddCharacteristicSuccess: (state, action: PayloadAction<any>) => {
      state.AddCharacteristicData = action.payload?.data;
      state.isAddProductsCharacteristicsSuccess = false;
    },
    AddCharacteristicError: (state, action: PayloadAction<any>) => {
      state.AddProductCharacteristicsError = action.payload;
      state.AddCharacteristicData = null;
      state.isAddProductsCharacteristicsSuccess = false;
    },
    updateProductsCharacteristics: (state, action) => {
      state.getProductCharacteristicsData = action.payload;
      state.isGetProductsCharacteristics = false;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
    getProductsCharacteristicsStart,
    getProductsCharacteristicsSuccess,
    getProductsCharacteristicsError,
    getAllProductsCharacteristicsStart,
    getAllProductsCharacteristicsSuccess,
    getAllProductsCharacteristicsError,
    removeAllCharacteristicsStart,
    removeAllCharacteristicsSuccess,
    removeAllCharacteristicsError,
    removeParticularCharacteristicsStart,
    removeParticularCharacteristicsSuccess,
    removeParticularCharacteristicsError,
    GetAllCharacteristicTypeListStart,
    GetAllCharacteristicTypeListSuccess,
    GetAllCharacteristicTypeListError,
    AddCharacteristicStart,
    AddCharacteristicSuccess,
    AddCharacteristicError,
    updateProductsCharacteristics
} = productsCharacteristicSlice.actions;

export default productsCharacteristicSlice.reducer;
