import service from "services/config";
import { endpoints } from "../endpoints";

export const customerEmailCall = (jobLotQuoteId) => {
  return service.get(endpoints.setEmail(jobLotQuoteId));
};

export const sendMessageCall = (payload) => {
  return service.post(endpoints.sendMessage, payload);
};
