import { Tabs, TabsProps } from "@mui/material";

const PosTabs = ({ onChange, value, children, className, ref }: TabsProps) => {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      variant="fullWidth"
      indicatorColor="primary"
      className={className}
      ref={ref}
    >
      {children}
    </Tabs>
  );
};

export default PosTabs;
