import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Box } from "@material-ui/core";
import "@fontsource/inter";

import { ErrorMessage } from "apps/pos/formik/errorMessage";
import { PosImage, PosPageHeading, PosInput, PosSelect, PosButton, PosIconButton, Container } from "apps/pos/Common/uiCore";
import { Button } from "components";
import { AddProductSchemaValidation } from "./formik/validationSchema";

import { blockInvalidChar, getLocalStoraGeItem } from "utils/utils";
import { productTypeProps } from "constants/interfaces";
import { ClearIcon, RefreshIcon } from "constants/icons";
import images from "constants/images";
import { Distributor, RootState } from "models";
import texts from "apps/pos/locales";
import { notAllowWithDecimalZeroRegex, notAllowZeroRegex, numbersOnlyRegEx,numericOnlyRegex,rotationCSS, title } from "constants/constant";
import { addManufactureLink, addVendorLink } from "constants/staticData";

import { addNewProductRequest, categoriesRequest, newProductAdded, newProductGroupRequest, newProductManufacturerRequest, newProductVendorRequest, notAddedNewProduct, productsRequest, stockByWarehouseStart } from "sagas/pos/actions";

import "./AddNewProduct.scss";
import "./CartPage.scss";

const AddNewProduct = ({ onCloseDrawer, setState, state, setAddProduct }) => {

  const {
    getCategoriesData,
    getNewProductManufacturerData,
    getNewProductVendorData,
    getNewProductGroupData,
    newProductData,
    productAddedSuccess,
    notAddedNewProductData,
    loadCartDetails,
    isProductManufacturerLoading,
    isProductVendorLoading,
    newProductIds
  } = useSelector<RootState, any>(
    (state) => state.pos
  );

  const {
    posFlowData
  } = useSelector<RootState, any>((state) => state.persistReducer);

  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );

  const dispatch = useDispatch();
  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {};
  const {intCode} = texts;
  const {wholeSalePrice, mustBeLessThan} = title;

  useEffect(() => {
    if (!getCategoriesData?.length) {
      dispatch(categoriesRequest());
    }
    if (!getNewProductManufacturerData?.length) {
      dispatch(newProductManufacturerRequest());
    }
    if (!getNewProductVendorData?.length) {
      dispatch(newProductVendorRequest());
    }
    if (!getNewProductGroupData?.length) {
      dispatch(newProductGroupRequest());
    }
  }, []);

  const currentTaker = getLocalStoraGeItem("taker");
  const addNewProductData = (formData) => {
    const {
      category,
      manufacturer,
      minimumCases,
      priceMeasure,
      productGroup,
      lumijectQAPrice,
      stockUnits,
      units,
      vendorName,
      vendorPrice,
      productName,
      description,
      manufacturerProductCode,
      universalProductCode,
      itemsInMeasure
    } = formData

    const preparedCustData = {
      handlerID: currentTaker?.personId,
      categoryID: Number(category),
      manufacturerID: Number(manufacturer),
      vendorID: Number(vendorName),
      productName,
      description,
      manufacturerCode: manufacturerProductCode,
      upc: universalProductCode,
      hyperlink: "",
      folderPath: "",
      minimumCases: Number(minimumCases) || Number(intCode),
      unitPerCase: Number(units) || Number(intCode),
      stockUnitPerCase: Number(stockUnits) || Number(intCode),
      priceMU: Number(itemsInMeasure) || Number(intCode),
      itemMU: Number(intCode),
      muName: priceMeasure,
      itemMuName: 'Each',
      salesPrice: Number(lumijectQAPrice),
      vendorPrice: Number(vendorPrice),
      dropShipFlag: 0,
      containMercuryFlag: 0,
      weight: 0,
      productGroupId: Number(productGroup),
    };
    dispatch(addNewProductRequest(preparedCustData));

  };

  const showProductIncart = async (id) => {
    const { customer , schedule } = posFlowData || {}
    const data = {
      stockWarehouseId,
      productId: id,
      personId: customer?.personId || customer?.customerId,
      priceScheduleId: schedule?.id ? schedule?.id : loadCartDetails?.customer?.priceScheduleId
    };
    dispatch(productsRequest(data));
  };

  useEffect(() => {
    dispatch(notAddedNewProduct(false))
    if (productAddedSuccess && !notAddedNewProductData) {
        dispatch(
          stockByWarehouseStart([newProductData])
        );
        showProductIncart(newProductData)
        setAddProduct(false);
        dispatch(newProductAdded([...newProductIds,newProductData]))
        setState({ ...state, right: true });
    }
  }, [productAddedSuccess]);

  const { addProductIcon } = images;

  const handleFocus = (event) => event.target.select();

  const {features, shortName} = distributor
  
  const findFeatureType = features.find(feature => feature._type === "ITEM_MU_SUPPORT_TYPE" )
  const {featureOptionName} = findFeatureType;

  const getVendorList =()=>{
    dispatch(newProductVendorRequest());
  }

  const getManufactureList =()=>{
    dispatch(newProductManufacturerRequest());
  }

  const handleChange = (e ,feild , setFieldValue) => {
    const inputValue = e.target.value;
      if (!inputValue || notAllowZeroRegex.test(inputValue)) {
        setFieldValue(feild, inputValue);
      }
  }

  const handleItemMeasure = (e , setFieldValue) => {
    const inputValue = e.target.value;
      if (!inputValue ||notAllowWithDecimalZeroRegex.test(inputValue) && !inputValue.includes("  ") && inputValue.charAt(0) !== " ") {
        setFieldValue('itemsInMeasure', inputValue);
      }
  }
  return (
    <Box role="presentation" className="h-100">
      <PosIconButton
        className="btn back-next-btn-small responsive-back-btn pos-icon-back-btn"
        onClick={onCloseDrawer}
        tabIndex={-1}
      >
        <ClearIcon />
      </PosIconButton>
      <Formik
        initialValues={{
          productName: "",
          description: "",
          category: "",
          manufacturer: "",
          manufacturerProductCode: "",
          vendorName: "",
          universalProductCode: "",
          units: 1,
          stockUnits: 1,
          minimumCases: 1,
          itemsInMeasure: 1,
          priceMeasure: 'EACH',
          lumijectQAPrice: null,
          vendorPrice: null,
          measureText: 'EACH',
          measureValue: 1,
          locationRow: "",
          locationUnit: "",
          locationShelf: "",
          productGroup: "",
        }}
        validationSchema={AddProductSchemaValidation({shortName})}
        enableReinitialize
        onSubmit={(values) => {
          addNewProductData(values)
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <Container className="modal-content-style drawer-container-spacing">
              <main>
                <div className="add-product">
                  <div className="pricing-modal-header mb-0">
                    <div className="payment-option-heading">
                      <h1 className="pricing-schedule-heading mb-0">
                        <PosPageHeading heading="Add a New Product" blackHeading />
                      </h1>
                      <PosImage src={addProductIcon} alt="add product" />
                    </div>
                  </div>
                  <form className="add-product-form">
                    <div className="facility-box form-field-spacing">
                      <span className="form-field-title big-form-field-title"> Product Name</span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 search-input-focus"
                        name="productName"
                        onChange={(e) => {
                          if (
                            !e.target.value.includes("  ") &&
                            e.target.value.charAt(0) !== " "
                          ) {
                            setFieldValue('productName', e.target.value);
                          }
                        }}
                        value={values.productName || ""}
                      />
                    </div>
                    <ErrorMessage name="productName" />
                    <div className="facility-box form-field-spacing">
                      <span className="form-field-title big-form-field-title">Description</span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        maxRows={1}
                        className="customer-company-address search-input-focus"
                        name="description"
                        onChange={(e) => {
                          if (
                            !e.target.value.includes("  ") &&
                            e.target.value.charAt(0) !== " "
                          ) {
                            setFieldValue('description', e.target.value);
                          }
                        }}
                        value={values.description}
                        multiline
                        rows={8}
                      />
                    </div>
                    <ErrorMessage name="description" />
                    <div className="facility-box form-field-spacing arrow-black">
                      <span className="form-field-title big-form-field-title mb-2">Category</span>
                      <PosSelect
                        isSearch
                        name="category"
                        defaultValue={values.category}
                        value={values.category}
                        className=" tabDropdown"
                        onChange={(e) => {
                          setFieldValue('category', e.target.value);
                        }}
                        options={
                          getCategoriesData?.length &&
                          getCategoriesData.map(({ categoryName, categoryId }: productTypeProps) => {
                            return {
                              label: categoryName,
                              value: categoryId
                              ,
                            };
                          })
                        }
                      />
                    </div>
                    <ErrorMessage name="category" />
                    <div className="manufactureAdd form-field-spacing arrow-black">
                    <div className="action--block">
                      <span className="form-field-title big-form-field-title mb-2 manudactureLink ">Manufacturer
                      </span>
                        <a href={`${distributor.httpsUrl}${addManufactureLink}`} target="_blank" className="addManufacture">Add Manufacturer</a>
                        <Button type="button" className="bg-transparent border-0 p-0 refresh-btn-focus" onClick={!isProductManufacturerLoading ? getManufactureList : null}>
                        <RefreshIcon sx={isProductManufacturerLoading ? rotationCSS : {}} className="refresh"/>
                        </Button>
                        </div>
                      <PosSelect
                        isSearch
                        name="manufacturer"
                        defaultValue={values.manufacturer}
                        className=" tabDropdown"
                        value={values.manufacturer}
                        onChange={(e) => {
                          setFieldValue("manufacturer", e.target.value);
                        }}
                        variant="filled"
                        options={
                          getNewProductManufacturerData?.length &&
                          getNewProductManufacturerData?.map(({ companyName, companyId }: productTypeProps) => {
                            return {
                              label: companyName,
                              value: companyId,
                            };
                          })
                        }
                      />
                    </div>
                    <ErrorMessage name="manufacturer" />
                    <div className="facility-box form-field-spacing">
                      <span className="form-field-title big-form-field-title">
                      Manufacturer Code
                      </span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 search-input-focus"
                        name="manufacturerProductCode"
                        onChange={(e) => {
                          if (
                            !e.target.value.includes("  ") &&
                            e.target.value.charAt(0) !== " "
                          ) {
                            setFieldValue('manufacturerProductCode', e.target.value);
                          }
                        }}
                        value={values.manufacturerProductCode || ""}
                      />
                    </div>
                    <ErrorMessage name="manufacturerProductCode" />
                    <div className="facility-box manufactureAdd form-field-spacing arrow-black">
                      <div className="action--block">
                      <span className="form-field-title big-form-field-title manudactureLink mb-2">Vendor</span>
                      <a href={`${distributor.httpsUrl}${addVendorLink}`} target="_blank" className="addManufacture">Add Vendor
                      </a>
                        <Button type="button" className="bg-transparent border-0 p-0 refresh-btn-focus" onClick={!isProductVendorLoading ? getVendorList : null}>
                         <RefreshIcon sx={isProductVendorLoading? rotationCSS : {}} className="refresh"/>
                      </Button>
                      </div>
                      <PosSelect
                        isSearch
                        name="vendorName"
                        className=" tabDropdown"
                        defaultValue={values.vendorName}
                        value={values.vendorName}
                        variant="filled"
                        onChange={(e) => {
                          setFieldValue("vendorName", e.target.value);
                        }}
                        options={
                          getNewProductVendorData?.length &&
                          getNewProductVendorData.map(({ companyName, companyId }: productTypeProps) => {
                            return {
                              label: companyName,
                              value: companyId,
                            };
                          })
                        }
                      />
                    </div>
                    <ErrorMessage name="vendorName" />
                    <div className="facility-box form-field-spacing">
                      <span className="form-field-title big-form-field-title">
                        UPC (Universal Product Code) <span className="optional-text">(optional)</span>
                      </span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        inputProps={{maxLength:12}}
                        className="mt-2 search-input-focus"
                        name="universalProductCode"
                        onChange={(e) => {
                          const {value} = e.target
                          const numericValue = value.replace(numericOnlyRegex, '');
                          setFieldValue('universalProductCode', numericValue);
                          if (!value.includes(" ") &&
                            value.charAt(0) !== "" && numbersOnlyRegEx.test(value)
                          ) {
                            setFieldValue('universalProductCode', value);
                          }
                        }}
                        value={values.universalProductCode || ""}
                      />
                      <span className="must-be-value">Must be 12 digits</span>
                    </div>
                    <ErrorMessage name="universalProductCode" />
                    <div className="facility-box form-field-spacing">
                      <span className="form-field-title big-form-field-title">Units / Case </span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 search-input-focus"
                        type="number"
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onFocus={handleFocus}
                        name="units"
                        onChange={(e) => handleChange(e,"units",setFieldValue)}
                        value={values.units || ""}
                        onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                      />
                    </div>
                    <ErrorMessage name="units" />
                    <div className="facility-box form-field-spacing">
                      <span className="form-field-title big-form-field-title">
                        Stock Units / Case{" "}
                      </span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 search-input-focus"
                        type="number"
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onFocus={handleFocus}
                        name="stockUnits"
                        onChange={(e) => handleChange(e,"stockUnits",setFieldValue)}
                        value={values.stockUnits || ""}
                        onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                      />
                      <span className="must-be-value">
                        Only used for MFR order
                      </span>
                    </div>
                    <ErrorMessage name="stockUnits" />
                    <div className="facility-box form-field-spacing">
                      <span className="form-field-title big-form-field-title">
                        Minimum Cases to Order{" "}
                      </span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 search-input-focus"
                        type="number"
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onFocus={handleFocus}
                        name="minimumCases"
                        onChange={(e) => handleChange(e,"minimumCases",setFieldValue)}
                        value={values.minimumCases || ""}
                        onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                      />
                    </div>
                    <ErrorMessage name="minimumCases" />
                    {featureOptionName === "Each (1) Only" ?
                    null : 
                    <>
                    <div className="facility-box form-field-spacing">
                    <span className="form-field-title big-form-field-title">
                      Item Unit of Measure text
                    </span>
                    <PosInput
                      variant="filled"
                      id="filled-basic"
                      InputProps={{ disableUnderline: true }}
                      className="mt-2 search-input-focus"
                      name="measureText"
                      onChange={(e) => {
                        if (
                          !e.target.value.includes("  ") &&
                          e.target.value.charAt(0) !== " "
                        ) {
                          setFieldValue('measureText', e.target.value);
                        }
                      }}
                      value={values.measureText || ""}
                    />
                    </div>
                    <ErrorMessage name="measureText" />
                    <div className="facility-box form-field-spacing">
                      <span className="form-field-title big-form-field-title">
                        Item Unit of Measure value
                      </span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 search-input-focus"
                        type="number"
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onFocus={handleFocus}
                        name="measureValue"
                        onChange={(e) => {
                          const re = /^\d{0,9}(\.\d{0,2})?$/;
                          if (
                            (e.target.value === "" ||
                              re.test(e.target.value) && !e.target.value.includes("  ") &&
                              e.target.value.charAt(0) !== " ")
                          ) {
                            setFieldValue('measureValue', e.target.value);
                          }
                        }}
                        value={values.measureValue || ""}
                        onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                      />
                    </div>
                    <ErrorMessage name="measureValue" />
                    </>}
                    <div className="facility-box form-field-spacing">
                      <span className="form-field-title big-form-field-title">
                        Price Unit of Measure text
                      </span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 search-input-focus"
                        name="priceMeasure"
                        onChange={(e) => {
                          if (
                            !e.target.value.includes("  ") &&
                            e.target.value.charAt(0) !== " "
                          ) {
                            setFieldValue('priceMeasure', e.target.value);
                          }
                        }}
                        value={values.priceMeasure || ""}
                        placeholder="Ex: Each, Pack, Box"
                      />
                    </div>
                    <ErrorMessage name="priceMeasure" />
                    <div className="facility-box form-field-spacing">
                      <span className="form-field-title big-form-field-title">
                        Price Unit of Measure value
                      </span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 search-input-focus"
                        type="number"
                        onFocus={handleFocus}
                        name="itemsInMeasure"
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onChange={(e) => handleItemMeasure(e,setFieldValue) }
                        value={values.itemsInMeasure || ""}
                        onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                      />
                    </div>
                    <ErrorMessage name="itemsInMeasure" />
                    <div className="facility-box form-field-spacing">
                      <span className="form-field-title big-form-field-title">
                        {`${shortName} ${wholeSalePrice}`}
                      </span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 search-input-focus"
                        type="number"
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onFocus={handleFocus}
                        name="lumijectQAPrice"
                        onChange={(e) => {
                          const re = /^\d{0,9}(\.\d{0,2})?$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setFieldValue('lumijectQAPrice', e.target.value);
                          }
                        }}
                        value={values.lumijectQAPrice || ""}
                        onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                      />
                    </div>
                    <ErrorMessage name="lumijectQAPrice" />
                    <div className="facility-box form-field-spacing">
                      <span className="form-field-title big-form-field-title">Vendor Price</span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 search-input-focus"
                        type="number"
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onFocus={handleFocus}
                        name="vendorPrice"
                        onChange={(e) => {
                          const re = /^\d{0,9}(\.\d{0,2})?$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setFieldValue('vendorPrice', e.target.value);
                          }
                        }}
                        value={values.vendorPrice || ""}
                        onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                      />
                      <span className="must-be-value">
                        {`${mustBeLessThan} ${shortName} ${wholeSalePrice}`}
                      </span>
                    </div>
                    <ErrorMessage name="vendorPrice" />
                    <div className="facility-box form-field-spacing arrow-black">
                      <span className="form-field-title big-form-field-title mb-2">Product Group</span>
                      <PosSelect
                        isSearch
                        name="productGroup"
                        variant="filled"
                        className=" tabDropdown"
                        defaultValue={values.productGroup}
                        value={values.productGroup}
                        onChange={(e) => {
                          setFieldValue("productGroup", e.target.value);
                        }}
                        options={
                          getNewProductGroupData?.length &&
                          getNewProductGroupData?.map(({ name, id }: productTypeProps) => {
                            return {
                              label: name,
                              value: id,
                            };
                          })
                        }
                      />
                    </div>
                    <ErrorMessage name="productGroup" />
                    <PosButton
                      className="add-product-save-btn btn"
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit()}
                    >Save and Add Product</PosButton>
                  </form>
                </div>
              </main>
            </Container>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddNewProduct;
