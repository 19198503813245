import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import {  getPendingShipmentInStorageItemsUrl} from './constantUrl';
import { stopLoading } from 'utils/utils';

export const useGetPendingStorageItems = () => {
  const { mutate, isLoading, data } = useMutation(
    async ({shipmentID,shipmentInStorageID}:any) => {
      return getPendingShipmentInStorageItemsUrl(shipmentID,shipmentInStorageID).then((response) => {
        return response.data;
      });
    },
    {
      retry: false,
      onSuccess: stopLoading,
      onError: stopLoading
    },
  );

  const getGetPendingStorageItems = useCallback(
    (shipmentID,shipmentInStorageID) => {
      mutate({shipmentID,shipmentInStorageID});
    },
    [mutate]
  );
  
  return { pendingShipmentStorageItemsData: data, getStorageShipmentsIsLoading: isLoading, getGetPendingStorageItems };
};