import { createSelector } from "@reduxjs/toolkit";
import { PersistReducerProps } from "./type";

export const persistData = (state: { persistReducer: PersistReducerProps }) =>
  state?.persistReducer;

export const getStarredMetadataData = createSelector(
  persistData,
  (data) => data.starredMetadataSb ?? []
);

export const getStaredProductsDetails = createSelector(
  persistData,
  (data) => data.starredProductsDetails
)

export const getPosFlowData = createSelector(persistData , (data) => data.posFlowData)