import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";

const LocationItem = (props) => {
    const { item, onClick, checked} = props;
    const handleClick = () => {
      onClick(item.id, item.name);
    };
  
    return (
      <li>
        <button
          className={`location-picker-item ${
            checked ? "location-picker-item-checked" : ""
          }`}
          onClick={handleClick}
          data-location-id={item.id}
          data-location-name={item.name}
          type="button"
        >
          {checked ? (
            <MaterialIcon icon="check" size="20" classes="checked-icon" />
          ) : null}
  
          <span className="location-picker-item-label" title={item.name}>
            {item.name}
          </span>
  
          <MaterialIcon icon="chevron_right" size="20" classes="arrow-icon" />
        </button>
      </li>
    );
  };

  export default LocationItem