import "@fontsource/inter";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  PosButton,
  PosHelmet,
  PosImage,
  PosModel,
  PosTooltip,
} from "apps/pos/Common/uiCore";
import { Button } from "components";
import CommonModel from "components/Dialog/Dialog";
import FooterBlock from "./Common/FooterBlock";
import { OrderDetailsDrawer } from "./Drawers";
import GpmChangeModal from "./GpmChange";
import OrderTotalChangeModal from "./OrderTotalChange/OrderTotalChangeModal";
import PaymentMethod from "./PaymentMethod";
import UpdateProduct from "./UpdateProduct/UpdateProduct";

import { title } from "constants/constant";
import {
  ProductPriceType,
  ProductTypes,
  ProductVpgTypes,
  documentByModule,
} from "constants/enum";
import {
  PosFlowDataProp,
  UploadedDocumentItemProp,
} from "constants/interfaces";
import { Paths, Placeholders } from "enums";
import { Distributor, RootState } from "models";
import { beforeUnloadCart, keys, openAndPrintPdf } from "utils/helper";
import {
  authorizationToken,
  calculatePriceItemMuEach,
  calculatePriceItemMuEachNew,
  getLocalStoraGeItem,
  getSalesPrice,
  priceToBeFixedConverter,
  roundToTwoDecimalPlaces,
  showScrollCartPage,
} from "utils/utils";
import { useStyles } from "./OrderDetailsComponent/styleHelper";
import { useProductsByPriceSchedule } from "./hooks/useProductsByPriceSchedule";

import {
  clearLoadCartDetails,
  getChangePriceInfo,
  isGpmCustomFlag,
  isOrderTotalCustomFlag,
  recipientInfoSwitchInfoData,
  setExemptTax,
  setNoLoadDetailsAction,
  setOriginalOrderTotal,
  setOriginalPercentage,
  setPosFlowData,
  setShipTaxAmtToBeSent,
  setShippingAmountReducer,
  setShippingTaxAmount,
  setSubTotalAmount,
  setTaxAmount,
  setTaxRateToBeSent,
  skipOrdererInfo,
} from "sagas/persistReducer/actions";
import {
  cartDraftClear,
  cartDraftRequest,
  cartPrintClear,
  cartPrintRequest,
  cartPrintWithTotalsRequest,
  clearSearchCustomer,
  getIsGoToCart,
  getNewPrice,
  getProductIndexNumber,
  getupdateItemModal,
  lastViewProductIndex,
  productsRequest,
  resetBillingDetails,
  savedDraftsReset,
  setMarkAsQuoteReducer,
  setPriceCheckReducer,
  setQuoteExpiryDate,
  setStoreCreditAmout,
  stockByWarehouseStart,
} from "sagas/pos/actions";
import { setIsApiLoading } from "./pages/customerInfo/CustomerOrders/redux/customerOrder.slice";

import images from "constants/images";
import { getLastProductViewIndex } from "sagas/pos/selector";
import "./orderDetails.scss";
import "./poscommonstyling.scss";
// import { useGetPayPalSettings } from "./hooks/useGetPayPalSettings";
interface QtyInStockProps {
  qtyInStock?: number;
}

const OrderDetails = ({
  setShowSaveForLater,
  showSaveForLater,
  showProductIncart,
  setSuggestedProducts,
  setCartEmpty,
  setSuggestionListVisible,
  pickUpSelected,
  cartDrawerRef,
  setCustomerInfo,
  settingIconDivHeight,
  setPickUpSelected,
  setNotesOpen,
  setNotesPlace,
  modal,
  setModal,
  paymentMethodRef,
  modalRef,
}) => {
  const {
    posFlowData,
    loadCartDetails,
    shippingAddressSelected,
    recipientSwitchInfo,
    isOrderTotalCustom,
    isGPMCustom,
    originalProfit,
    originalTotal,
    isLoadCartSuccess,
    allowPickupCheck
  } = useSelector<RootState, PosFlowDataProp>(
    (reducerState) => reducerState.persistReducer
  );
  const lastUpdatedProductIndex = useSelector(getLastProductViewIndex);

  const addedDocument = posFlowData?.addDocument;

  const {
    customer,
    savedCart,
    shiptoFacilities,
    cartProducts,
    cartProductDetails,
    shippingCharge,
    poNumber,
    customerInfoUpdated,
    selectQuoteType,
    selectQuoteId,
    schedule,
    email,
    firstName: posFirstName,
    lastName: posLastName,
    phone: posPhone,
    companyName,
    isLogoutClick,
    pickQuantityArr,
    shiptoFacilities: posShiptoFacilities,
    viaSwitchCustomer,
    scheduleAdded,
    priceVisible,
  } = posFlowData || {};
  const { cartId } = savedCart || {}

  const { shipToByFacility, purchaseOrderNum } = loadCartDetails || {};
  const {
    personId,
    customerId,
    isPosGeneric,
    taxExemptFlag: customerTaxExemptFlag,
    hasInternalNotes,
  } = customer || {};
  const { typeVpgSalesPrice, typeVpgMovingAvgCost, typeVpgVendorPrice } =
    ProductVpgTypes;
  const currentCustomerDetail = loadCartDetails?.customer;

  // const {isPayPalAccount} = useGetPayPalSettings();

  useEffect(() => {
    if (isLoadCartSuccess) {
      const setTaxFlag = Boolean(loadCartDetails?.customer?.taxExemptFlag || loadCartDetails?.taxExemptFlag);
      dispatch(setExemptTax(setTaxFlag));
      setIsToggleChange(setTaxFlag);
      if (
        isPosGeneric &&
        currentCustomerDetail?.fullName &&
        loadCartDetails?.products?.length
      ) {
        const splitFullname = currentCustomerDetail?.fullName?.split(" ");
        const genericUserFirstName = splitFullname?.slice(0, -1).join(" ");
        const genericUserLastName = splitFullname[splitFullname?.length - 1];

        const data = {
          ...posFlowData,
          customer: {
            ...currentCustomerDetail,
          },
          firstName: genericUserFirstName || "",
          lastName: genericUserLastName || "",
          phone: currentCustomerDetail?.phone || "",
          email: currentCustomerDetail?.email || "",
          companyName: currentCustomerDetail?.companyName || "",
          isStorePickup: false,
        };
        dispatch(recipientInfoSwitchInfoData(false));
        dispatch(skipOrdererInfo(false));
        dispatch(setPosFlowData(data));
      } else {
        const data = {
          ...posFlowData,
          customer: {
            ...currentCustomerDetail,
          },
        };
        dispatch(setPosFlowData(data));
      }
    }
  }, [isLoadCartSuccess]);

  const { customer: savedCartCustomer } = savedCart || {};
  const customerName = savedCartCustomer?.fullName;
  const firstName = customerName?.split(" ")?.[0];
  const lastName = customerName?.split(" ")?.[1];
  const currentTaker = getLocalStoraGeItem("taker");
  const takerFullName = currentTaker?.fullName;
  const { personId: takerPersonId } = currentTaker || {};
  const { salespersonName } = posFlowData?.salesman || {};
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace || {};
  const pickedQuantity = pickQuantityArr ?? "";

  useEffect(() => {
    if (!stockWarehouseId || !takerPersonId) {
      navigate(Paths.posNewOrder);
    }
  }, [stockWarehouseId, takerPersonId]);

  const {
    cartDraftSuccess,
    isGoToCart,
    cartPrint,
    cartPrintSuccess,
    productsData,
    productLoadSuccess,
    updateItemModal,
    headerHeightCount,
    quoteExpiryDate,
    orderInstructionNote,
    shippingInstructionNote,
    temporaryStorageLocationNote,
    priceCheckReducer,
    markAsQuoteReducer,
    storeCreditAmount,
    isPurchaseOrderNoEdit,
    jobNameForAll
  } = useSelector<RootState, any>((state) => state.pos);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isTaxExempted, saveProductInfo } =
    useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);
  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );

  const [updatedPrice, setUpdatedPrice] = useState([]);
  const [priceInDecimal, setPriceInDecimal] = useState(false);
  const [updatedQty, setUpdatedQty] = useState(0);
  const [updatedProduct, setUpdatedProduct] = useState(null);
  const [cartExtPrice, setCartExtPrice] = useState(0);
  const [profitPercentage, setProfitPercentage] = useState(0);
  const [showShipping, setShowShipping] = useState<boolean>(false);
  const [orderTotalSet, setOrderTotal] = useState(0);
  const [showTax, setShowTax] = useState(false);
  const [showSaveLater, setShowSaveLater] = useState(false);
  const [showPrintOptions, setShowPrintOptions] = useState(false);
  const [calculateTax, setCalculateTax] = useState<boolean>(isTaxExempted);
  const [printLoader, setPrintLoader] = useState(false);
  const [printLoaderWithTotal, setPrintLoaderWithTotal] = useState(false);
  const [orderInstructions, setOrderInstructions] = useState("");
  const [shippingInstructions, setShippingInstructions] = useState("");
  const [temporaryStorageLocation, setTemporaryStorageLocation] = useState("");
  const [isToggleChange, setIsToggleChange] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(false);
  const [balanceDetail, setBalanceDetail] = useState(false);
  const [, setShipToByFacilityId] = useState<number>(
    loadCartDetails?.shipToByFacility?.facilityId
  );
  // Might need
  // const [pickUpFlag, setPickUpFlag] = useState(true);

  const [creditAmtApplied, setCreditAmtApplied] = useState(
    customer?.storeCreditAmt
  );

  const [taxRate, setTaxRate] = useState<number>(
    shiptoFacilities?.tax?.taxRate * 100
  );
  const [paymentMethodDrawer, setPaymentMethodDrawer] =
    useState<boolean>(false);

  const {
    typeClearance,
    typeDiscontinued,
    typePriceScheduleVolume,
    typePriceScheduleSingle,
  } = ProductTypes;

  const drawerRef = useRef<React.Ref<HTMLDivElement>>();
  const {
    productsByPriceScheduleFn,
    isSuccess: isProductIdsSuccess,
    isLoading: productByIdsLoading,
    data: productByIdsData,
  } = useProductsByPriceSchedule();

  const checkMixOrder = cartProducts?.some((item: QtyInStockProps) => {
    return item?.qtyInStock > 0;
  });

  useEffect(() => {
    if (viaSwitchCustomer) {
      const payload = {
        stockWarehouseId,
        productIds: cartProducts.map((item) => item.productId),
        personId: customer.customerId,
        priceScheduleId: customer.priceScheduleId,
      };
      productsByPriceScheduleFn(payload);
    }
  }, []);

  useEffect(() => {
    if (posFlowData?.schedule?.id && cartProducts?.length && scheduleAdded) {
      const payload = {
        stockWarehouseId,
        productIds: cartProducts.map((item) => item.productId),
        personId: customer.customerId,
        priceScheduleId: posFlowData?.schedule?.id,
      };
      productsByPriceScheduleFn(payload);
    }
  }, [posFlowData?.schedule?.id]);

  const replacePricebyIds = () => {
    const data = cartProducts?.map((cartItem) => {
      const { productId, qtyOrdered } = cartItem;
      const findMatch = productByIdsData.find(
        (findMatchData) => findMatchData.productId === productId
      );
      const findPriceVolume = findMatch.productPrices.filter(
        (findPriceVolumeItem) =>
          findPriceVolumeItem.type === typePriceScheduleVolume
      );
      const findRange = findPriceVolume.find((findRangeItem) => {
        return (
          Math.min(findRangeItem.minQty, findRangeItem.maxQty) <= qtyOrdered &&
          Math.max(findRangeItem.minQty, findRangeItem.maxQty) >= qtyOrdered
        );
      });
      if (findMatch?.productPrices[0].type === typePriceScheduleVolume) {
        (cartItem.posRetailPrice = findRange?.price),
          (cartItem.extPrice = qtyOrdered * findRange?.price);
      } else if (findMatch?.productPrices[0].type === typePriceScheduleSingle) {
        (cartItem.posRetailPrice = findMatch.productPrices[0]?.price),
          (cartItem.extPrice = qtyOrdered * findMatch.productPrices[0]?.price);
      }
      const productPriceTypeApplied =
        findMatch?.productPrices[0].type === typePriceScheduleVolume
          ? Number(ProductPriceType.PRICE_SCHEDULE_VOLUME)
          : findMatch?.productPrices[0].type === typePriceScheduleSingle
            ? Number(ProductPriceType.PRICE_SCHEDULE_SINGLE)
            : Number(ProductPriceType.CUSTOM);
      return { ...cartItem, productPriceTypeApplied };
    });
    dispatch(setPosFlowData({ ...posFlowData, cartProducts: data }));
  };

  const isApiLoading = [productByIdsLoading].includes(true);

  useEffect(() => {
    dispatch(setIsApiLoading(isApiLoading));
  }, [isApiLoading]);

  useEffect(() => {
    if (isProductIdsSuccess) {
      replacePricebyIds();
    }
  }, [isProductIdsSuccess]);
  // if the page is closed when cart has items, show a confirmation alert
  useEffect(() => {
    beforeUnloadCart(cartProducts);
  }, [cartProducts]);

  useEffect(() => {
    dispatch(savedDraftsReset());
    showScrollCartPage();
  }, []);

  useEffect(() => {
    if (isLogoutClick) {
      const data = {
        ...posFlowData,
        cartProducts: [],
        addInStorage: [],
        poNumber: "",
        customer: {},
        savedCart: {},
        schedule: {},
        taker: {},
        pickOrderQuantity: [],
        pickQuantityArr: [],
        finalQtyArrayInfoData: [],
        isLogoutClick: false,
      };
      dispatch(setPosFlowData(data));
      dispatch(clearSearchCustomer());
      beforeUnloadCart([]);
      window.location.href = `${distributor.httpsUrl}/sso/logout`;
    }
  }, [isLogoutClick, cartProducts]);

  const openShowLater = () => {
    setShowSaveLater(true);
    setState({ ...state, right: true });
    dispatch(clearSearchCustomer());
  };

  useEffect(() => {
    if (showSaveForLater) {
      openShowLater();
    }
  }, [showSaveForLater]);

  const handleKeyPress = (e) => {
    const { keyCode } = e;
    const { f7, f8, f9, f10 } = keys;
    if (
      keyCode === f7 &&
      !modalRef.current &&
      !cartDrawerRef.current &&
      !paymentMethodRef.current
    ) {
      e.preventDefault();
      setModal({ isOpen: true, modalTitle: modalTitleGPM });
    }
    if (
      keyCode === f8 &&
      !modalRef.current &&
      !cartDrawerRef.current &&
      !paymentMethodRef.current
    ) {
      setShowTax(true);
      setState({ ...state, right: true });
    }
    if (
      keyCode === f9 &&
      !modalRef.current &&
      !cartDrawerRef.current &&
      !paymentMethodRef.current &&
      !(
        shiptoFacilities?.isStorePickup ||
        (shipToByFacility?.isStorePickup && !shippingAddressSelected)
      )
    ) {
      setShowShipping(true);
      setPriceInDecimal(true);
      setPaymentMethodDrawer(true);
    }
    if (
      keyCode === f10 &&
      !modalRef.current &&
      !cartDrawerRef.current &&
      !paymentMethodRef.current
    ) {
      e.preventDefault();
      setModal({ isOpen: true, modalTitle: "Change Order Total" });
    }
    if (!cartDrawerRef.current && !modalRef.current) {
      if (keyCode === keys.s && !paymentMethodDrawer && !shippingAddress) {
        e.preventDefault();
        e.target.blur();
        window.getSelection().removeAllRanges();
        setShowSaveLater(true);
        setState({ ...state, right: true });
        dispatch(clearSearchCustomer());
      } else if (keyCode === keys.d && !checkMixOrder) {
        handlePaymentClick();
      } else if (
        keyCode === keys.p &&
        !paymentMethodDrawer &&
        !shippingAddress
      ) {
        setShowPrintOptions(true);
        setState({ ...state, right: true });
      } else if (!e.ctrlKey && keyCode === keys.q && checkMixOrder) {
        dispatch(
          setStoreCreditAmout(
            storeCreditAmount === ""
              ? customer?.storeCreditAmt?.toString()
              : storeCreditAmount
          )
        );
        navigate(Paths.posPickQuantity);
      }
    }
  };
  useEffect(() => {
    if (orderInstructionNote) {
      setOrderInstructions(orderInstructionNote);
    }
  }, [orderInstructionNote]);

  useEffect(() => {
    if (shippingInstructionNote) {
      setShippingInstructions(shippingInstructionNote);
    }
  }, [shippingInstructionNote]);

  useEffect(() => {
    if (temporaryStorageLocationNote) {
      setTemporaryStorageLocation(temporaryStorageLocationNote);
    }
  }, [temporaryStorageLocationNote]);

  useEffect(() => {
    if (!isTaxExempted) {
      if (pickUpSelected) {
        setTaxRate(shipToByFacility?.tax?.taxRate * 100);
      } else {
        const countDocuments = posFlowData?.addDocument?.filter(
          (e) =>
            e?.documentSaveTypeName?.toLowerCase() ===
            documentByModule.taxExemptCerti.toLowerCase()
        ).length;
        if (countDocuments > 0) {
          setTaxRate(0);
        } else {
          setTaxRate(shiptoFacilities?.tax?.taxRate * 100);
        }
      }
    }
  }, [pickUpSelected]);

  useEffect(() => {
    if (!isTaxExempted) {
      setTaxRate(shiptoFacilities?.tax?.taxRate * 100);
    }
  }, [shiptoFacilities?.tax?.taxRate, isTaxExempted]);

  useEffect(() => {
    if (productLoadSuccess) {
      const productsDataWithPrice = {
        updatedPrice,
        updatedQty,
        productsData,
        updatedProduct,
        productIndex: lastUpdatedProductIndex !== null ? lastUpdatedProductIndex : cartProducts?.length + 1,
      };
      showProductIncart(productsDataWithPrice);
    }
  }, [productLoadSuccess]);

  useEffect(() => {
    if (cartPrintSuccess) {
      if (printLoader) {
        setPrintLoader(false);
      }
      if (printLoaderWithTotal) {
        setPrintLoaderWithTotal(false);
      }
      dispatch(cartPrintClear());
      // might need later
      // const pdfWindow = window.open("");
      // pdfWindow?.document?.write(
      //   "<iframe width='100%' height='100%' src='" +
      //     `data:application/pdf;base64,${cartPrint?.data}` +
      //     "'></iframe>"
      // );
      openAndPrintPdf(cartPrint?.data);
    }
  }, [cartPrintSuccess]);

  useEffect(() => {
    if (cartDraftSuccess && !updateItemModal) {
      dispatch(getIsGoToCart(false));

      if (!isGoToCart) {
        dispatch(cartDraftClear());
        dispatch(savedDraftsReset());

        const data = {
          priceVisible: priceVisible || false,
        };

        dispatch(setPosFlowData(data));
        navigate(Paths.posNewOrder);
        dispatch(clearLoadCartDetails());
      }

      dispatch(recipientInfoSwitchInfoData(false));
      dispatch(skipOrdererInfo(false));
    }
  }, [cartDraftSuccess, priceVisible, isGoToCart]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    // Might need if not working on QA
    // state,
    // checkMixOrder,
    // cartDrawerRef,
    // pickUpSelected,
    // proceedToPayment,
    // shippingAddressSelected,
    // shippingAddress,
    // paymentMethodDrawer,
    posFlowData?.cartProducts,
  ]);

  const updateCalculation = () => {
    let calculateExtPrice = 0;
    let calculateTotalQuantity = 0;
    let calculateVendorPrice = 0;
    let calculateNetPrice = 0;
    let totalVendorPrice = 0;
    cartProducts?.length &&
      cartProducts.forEach(
        ({
          priceMU,
          itemMU,
          posRetailPrice,
          qtyOrdered,
          vendorPrice,
          productPrices,
          movingAvgCost,
          salesPrice
        }) => {
          const itemMuPriceWithPosRetailPrice =
            calculatePriceItemMuEachNew(
              priceMU,
              itemMU,
              posRetailPrice,
              qtyOrdered
            ) || 0;
          calculateExtPrice =
            calculateExtPrice +
            parseFloat(roundToTwoDecimalPlaces(itemMuPriceWithPosRetailPrice));
          if (productPrices.length) {
            if (productPrices?.[0].vpgComputePriceType === typeVpgVendorPrice) {
              totalVendorPrice = vendorPrice.price;
            } else if (
              productPrices?.[0].vpgComputePriceType === typeVpgMovingAvgCost
            ) {
              totalVendorPrice = movingAvgCost.price;
            } else if (
              productPrices?.[0].vpgComputePriceType === typeVpgSalesPrice
            ) {
              totalVendorPrice = salesPrice.price;
            } else {
              totalVendorPrice = vendorPrice.price;
            }
            calculateVendorPrice += Number(totalVendorPrice);
          } else {
            calculateVendorPrice += Number(vendorPrice?.price ?? vendorPrice);
          }

          calculateTotalQuantity = calculateTotalQuantity + qtyOrdered;
          calculateNetPrice += Number(posRetailPrice);
        }
      );

    const profitPercentageCalc =
      ((calculateNetPrice - calculateVendorPrice) / calculateNetPrice) * 100;
    setCartExtPrice(Number(roundToTwoDecimalPlaces(calculateExtPrice)));
    setProfitPercentage(profitPercentageCalc);
    if (!isGPMCustom && !isOrderTotalCustom) {
      dispatch(setOriginalPercentage(profitPercentageCalc));
    }
  };

  useEffect(() => {
    updateCalculation();
  }, [cartProducts?.length, cartProducts, cartProductDetails?.qtyOrdered]);

  const subTotalAmount = cartExtPrice ? cartExtPrice.toFixed(2) : 0;
  const taxAmountWithoutRoundOff = (cartExtPrice * taxRate) / 100;
  const taxAmount = roundToTwoDecimalPlaces(taxAmountWithoutRoundOff);
  const shippingAmount = shippingCharge?.shippingAmt || 0;
  const shippingTaxAmount = Number((shippingAmount * taxRate) / 100);

  useEffect(() => {
    dispatch(setSubTotalAmount(subTotalAmount));
  }, [subTotalAmount]);

  useEffect(() => {
    dispatch(setTaxAmount(taxAmount));
  }, [taxAmount]);

  useEffect(() => {
    dispatch(setShippingAmountReducer(shippingAmount));
  }, [shippingAmount]);

  useEffect(() => {
    dispatch(setShippingTaxAmount(shippingTaxAmount));
  }, [shippingTaxAmount]);

  let takerIdToBeSent;

  if (savedCart?.products?.length > 0) {
    takerIdToBeSent = savedCart?.takerId;
  } else {
    // TODO:Need this code
    // if (taker) {
    //   takerIdToBeSent = taker?.personId;
    // } else {
    if (currentTaker?.personId) {
      takerIdToBeSent = currentTaker?.personId;
    }
  }

  useEffect(() => {
    dispatch(setShippingTaxAmount(takerIdToBeSent));
  }, [takerIdToBeSent]);

  const calculateTotals = () => {
    let grandTotalAmount,
      shipTaxAmtToBeSent,
      taxRateToBeSent,
      taxScheduleToBeSent;

    if (savedCart?.customer?.taxExemptFlag && isTaxExempted) {
      grandTotalAmount = Number(subTotalAmount) + Number(shippingAmount);
      shipTaxAmtToBeSent = 0;
      taxRateToBeSent = 0.0;
      taxScheduleToBeSent = "NONTAX";
    } else {
      if (
        savedCart?.shipToByFacility?.recipient?.taxExemptFlag &&
        isTaxExempted
      ) {
        grandTotalAmount = Number(subTotalAmount) + Number(shippingAmount);
        shipTaxAmtToBeSent = 0;
        taxRateToBeSent = 0.0;
        taxScheduleToBeSent = "NONTAX";
      } else {
        if (isTaxExempted) {
          grandTotalAmount = Number(subTotalAmount) + Number(shippingAmount);
          shipTaxAmtToBeSent = 0;
          taxRateToBeSent = 0.0;
          taxScheduleToBeSent = "NONTAX";
        } else {
          shipTaxAmtToBeSent = Number(
            roundToTwoDecimalPlaces(Number(shippingTaxAmount))
          );
          grandTotalAmount = Number(subTotalAmount) + Number(shippingAmount);
          taxScheduleToBeSent = pickUpSelected
            ? loadCartDetails?.shipToByFacility?.tax?.taxSchedule
            : posFlowData?.shiptoFacilities?.tax?.taxSchedule;
        }
      }
    }

    return {
      grandTotalAmount,
      shipTaxAmtToBeSent,
      taxRateToBeSent,
      taxScheduleToBeSent,
    };
  };

  const {
    grandTotalAmount,
    shipTaxAmtToBeSent,
    taxRateToBeSent,
    taxScheduleToBeSent,
  } = calculateTotals();

  useEffect(() => {
    dispatch(setShipTaxAmtToBeSent(shipTaxAmtToBeSent));
  }, [shipTaxAmtToBeSent]);

  useEffect(() => {
    if (!isTaxExempted) {
      dispatch(setTaxRateToBeSent(taxRateToBeSent));
    }
  }, [taxRateToBeSent]);

  // start
  const { showPrices } = savedCart?.customer || {};

  useEffect(() => {
    dispatch(setPriceCheckReducer(showPrices));
  }, [showPrices]);

  const [priceCheck, setPriceCheck] = useState(showPrices);
  const [markAsQuote, setMarkAsQuote] = useState(markAsQuoteReducer);
  const [expiryDate, setExpiryDate] = useState(quoteExpiryDate);

  useEffect(() => {
    setExpiryDate(quoteExpiryDate);
  }, [quoteExpiryDate]);

  const handlePriceCheck = () => {
    setPriceCheck(!priceCheck);
    dispatch(setPriceCheckReducer(!priceCheck));
  };

  const handleMarkAsQuoteCheck = () => {
    setMarkAsQuote(!markAsQuote);
    dispatch(setQuoteExpiryDate(null));
    dispatch(setMarkAsQuoteReducer(!markAsQuote));
  };
  // end

  const printInvoice = (optionNumber: number) => {
    //DO NOT CHANGE OR REMOVE
    // const grandTotalAmount =
    // savedCart?.shipToByFacility?.tax?.shipTaxFlag === 1
    //   ? Number(subTotalAmount) +
    //     Number(taxAmount) +
    //     Number(shippingAmount) +
    //     Number(shippingTaxAmount)
    //   : Number(subTotalAmount) + Number(taxAmount) + Number(shippingAmount);

    // isTaxExempted
    //   ? savedCart?.shipToByFacility?.tax?.shipTaxFlag === 1
    //     ? Number(subTotalAmount) + Number(shippingAmount)
    //     : Number(subTotalAmount) +
    //       Number(shippingAmount) +
    //       Number(shippingAmount)
    //   : savedCart?.shipToByFacility?.tax?.shipTaxFlag === 1
    //   ? Number(subTotalAmount) + Number(taxAmount) + Number(shippingTaxAmount)
    //   : Number(subTotalAmount) +
    //     Number(taxAmount) +
    //     Number(shippingAmount) +
    //     Number(shippingTaxAmount);

    const cartProductsWithTotal = [];
    const cartWithTotalProducts = [];

    cartProducts?.length > 0 &&
      cartProducts?.forEach(
        ({
          productId,
          manufacturerCode,
          manufacturerId,
          vendorId,
          productName,
          vendorPrice,
          salesPrice,
          posRetailPrice,
          priceMU,
          muName,
          itemMU,
          itemMuName,
          unitPerCase,
          qtyInStock,
          qtyOrdered,
          orderNote,
          clearanceFlag,
          discontinueFlag,
          manufacturerName,
          overridePrice,
          updatedAvailableQty,
          totalPickedValue,
          itemType,
          markupMultiplier
        }) => {
          cartProductsWithTotal.push({
            productId,
            manufacturerCode,
            manufacturerId,
            manufacturerName,
            vendorId,
            vendorName: manufacturerName,
            itemType: itemType || null,
            productName,
            priceMU,
            muName,
            itemMU,
            itemMuName,
            unitPerCase,
            qtyOrdered,
            vendorPrice: vendorPrice?.price ?? vendorPrice,
            salesPrice: salesPrice?.price ?? salesPrice,
            posRetailPrice: posRetailPrice ? Number(posRetailPrice) : 0,
            pickedQty: totalPickedValue ? Number(totalPickedValue) : 0,
            clearanceFlag,
            qtyInStock,
            updatedAvailableQty,
            discontinueFlag,
            markupMultiplier,
            overridePrice,
            orderNote,
          });
        }
      );

    const {
      sessionStartedAt,
      cartId,
      customer: savedCartCustomer,
      customerNote,
      jobName,
    } = savedCart || {};

    const preparedData = {
      sessionStartedAt: sessionStartedAt ? sessionStartedAt : "",
      cartId: cartId ? cartId : "",
      stockWarehouseId,
      customerId: savedCartCustomer?.customerId
        ? savedCartCustomer?.customerId
        : "",
      orderNote: orderInstructions,
      shippingNote: shippingInstructions,
      purchaseOrderNum: isPurchaseOrderNoEdit ? poNumber : purchaseOrderNum,
      customerNote: customerNote ? customerNote : "",
      products: cartWithTotalProducts,
      storageNote: temporaryStorageLocation || "",
      takerName: takerFullName,
      salesPersonName: salespersonName ? salespersonName : takerFullName,
    };

    cartProducts?.length > 0 &&
      cartProducts?.forEach(
        ({
          productId,
          manufacturerCode,
          manufacturerId,
          vendorId,
          productName,
          vendorPrice,
          salesPrice,
          posRetailPrice,
          priceMU,
          muName,
          itemMU,
          itemMuName,
          unitPerCase,
          qtyInStock,
          qtyOrdered,
          shippingNote,
          orderNote,
          clearanceFlag,
          discontinueFlag,
          manufacturerName,
          overridePrice,
          updatedAvailableQty,
          totalPickedValue,
          itemType,
          markupMultiplier
        }) => {
          cartWithTotalProducts.push({
            productId,
            manufacturerCode,
            manufacturerId,
            manufacturerName,
            vendorId,
            vendorName: manufacturerName,
            itemType: itemType || null,
            productName,
            priceMU,
            muName,
            itemMU,
            itemMuName,
            unitPerCase,
            qtyOrdered,
            vendorPrice: vendorPrice?.price ?? vendorPrice,
            salesPrice: salesPrice?.price ?? salesPrice,
            posRetailPrice: posRetailPrice ? Number(posRetailPrice) : 0,
            pickedQty: totalPickedValue ? Number(totalPickedValue) : 0,
            clearanceFlag,
            qtyInStock,
            updatedAvailableQty,
            discontinueFlag,
            markupMultiplier,
            overridePrice,
            shippingNote,
            orderNote,
          });
        }
      );

    const withTotalsData = {
      sessionStartedAt: sessionStartedAt,
      cartId: cartId,
      stockWarehouseId,
      customerId: savedCartCustomer?.customerId,
      purchaseOrderNum: isPurchaseOrderNoEdit ? poNumber : purchaseOrderNum,
      customerNote: savedCartCustomer?.customerNote,
      shippingNote: shippingInstructions,
      orderNote: orderInstructions,
      showPrices: savedCartCustomer?.showPrices ? priceCheck : false,
      buyOnCreditHold: savedCartCustomer?.creditHoldFlag === 1,
      jobName: jobNameForAll ? jobNameForAll : jobName,
      takerId: currentTaker?.personId,
      taxRate: taxRateToBeSent,
      taxSchedule: taxScheduleToBeSent,
      shipTaxFlag: shipToByFacility?.tax?.shipTaxFlag,
      shipToAddressId: shippingAddressSelected
        ? posFlowData?.shiptoFacilities?.facilityId
        : shipToByFacility?.facilityId,
      subTotalAmt: Number(subTotalAmount),
      taxAmt:
        savedCartCustomer?.taxExemptFlag && isTaxExempted
          ? 0
          : shipToByFacility?.recipient?.taxExemptFlag && isTaxExempted
            ? 0
            : isTaxExempted
              ? 0
              : Number(taxAmount) || 0,
      taxExemptFlag: savedCartCustomer?.taxExemptFlag
        ? 1
        : savedCart?.shipToByFacility?.recipient?.taxExemptFlag
          ? 1
          : isTaxExempted
            ? 1
            : 0,
      shippingFeeAmt: Number(shippingAmount),
      shippingTaxAmt: shipTaxAmtToBeSent ? shipTaxAmtToBeSent : 0.0,
      grandTotalAmt: Number(grandTotalAmount?.toFixed(4)) || 0,
      products: cartProductsWithTotal,
      takerName: currentTaker?.fullName,
      salesPersonName: salespersonName ? salespersonName : takerFullName,
      setAsQuote: markAsQuote,
      setAsQuoteExpiryDate: expiryDate ? expiryDate : dayjs().add(1, "day"),
    };
    if (optionNumber === 1) {
      dispatch(cartPrintRequest(preparedData));
    }
    if (optionNumber === 2) {
      dispatch(cartPrintWithTotalsRequest(withTotalsData));
    }
  };

  const productsInCart = cartProducts ? cartProducts : saveProductInfo;

  const redirectToPickQty = () => {
    productsInCart.forEach((product) => {
      product.backOrder = product.qtyOrdered - product.qtyInStock;
    });
    dispatch(
      setStoreCreditAmout(
        storeCreditAmount === ""
          ? customer?.storeCreditAmt?.toString()
          : storeCreditAmount
      )
    );
    navigate(Paths.posPickQuantity, {
      state: {
        detail: productsInCart,
        subTotalAmount: subTotalAmount,
        taxRate: taxRate,
        taxAmount: taxAmount,
        shipTaxFlag: shipTaxFlagValue,
      },
    });
  };

  const handlePaymentClick = () => {
    dispatch(resetBillingDetails());
    const result = productsInCart.filter((obj) => {
      return obj.qtyInStock === 0;
    });

    // start
    if (cartProducts?.length) {
      const flag = false;
      const lessAddedQty = cartProducts?.some(
        ({ totalPickedValue, qtyOrdered }) => {
          const numberQuantityValue = isNaN(Number(totalPickedValue))
            ? 0
            : Number(totalPickedValue);
          return (
            numberQuantityValue < qtyOrdered &&
            (!shippingAddressSelected || false)
          );
        }
      );

      const noAddedQty = cartProducts?.every(({ totalPickedValue }) => {
        const numberQuantityValue = isNaN(Number(totalPickedValue))
          ? 0
          : Number(totalPickedValue);
        return !numberQuantityValue;
      });

      return cartProducts?.map(() => {
        if (flag) {
          return;
        }
        const showAddressDrawer =
          (lessAddedQty ||
            (customer?.isPosGeneric && result.length > 0) ||
            noAddedQty) &&
          !shippingAddressSelected;

        if (
          loadCartDetails?.products.length &&
          !loadCartDetails?.shipToByFacility?.isStorePickup
        ) {
          setShippingAddress(false);
          // might need
          // setPickUpFlag(false);
          setShowShipping(false);
          openPaymentDrawer();
        } else {
          if (showAddressDrawer) {
            // might need
            // setPickUpFlag(false);
            setShippingAddress(true);
          } else {
            setShowShipping(false);
            openPaymentDrawer();
          }
        }
      });
    } else if (
      result.length > 0 &&
      customer?.isPosGeneric &&
      !shippingAddressSelected
    ) {
      setShippingAddress(true);
    } else if (
      pickedQuantity?.length === 0 &&
      !shippingAddressSelected &&
      result.length === 0
    ) {
      setShippingAddress(true);
    } else {
      setShowShipping(false);
      setState({ ...state, right: true });
    }
    // end
  };

  const openPaymentDrawer = () => {
    if (
      customer?.storeCreditAmt
    ) {
      setBalanceDetail(true);
    } else {
      setPaymentMethodDrawer(true);
    }
  };
  const saveForLaterAPI = async (draftSaveName) => {
    const decodedData = authorizationToken("Authorization");

    const cartProductsForLater = [];

    productsInCart?.length &&
      productsInCart?.forEach(
        (
          {
            productId,
            manufacturerCode,
            manufacturerId,
            vendorId,
            productName,
            vendorPrice,
            salesPrice,
            posRetailPrice,
            priceMU,
            muName,
            itemMU,
            shippingNote,
            orderNote,
            itemMuName,
            unitPerCase,
            qtyInStock,
            qtyOrdered,
            initialPriceData,
            clearance,
            discontinued,
            priceScheduleProductPrice,
            salesOverride,
            updatedAvailableQty,
            priceScheduleVendorProductGroupId,
            type,
            isCustomPrice,
            productPriceTypeApplied,
            itemType,
            markupMultiplier
          },
          index
        ) => {
          cartProductsForLater.push({
            lineId: index + 1,
            productId: productId,
            manufacturerCode: manufacturerCode,
            manufacturerId: manufacturerId,
            vendorId: vendorId,
            itemType: itemType || null,
            productName: productName,
            vendorPrice: vendorPrice?.price ?? vendorPrice,
            salesPrice: salesPrice?.price ?? salesPrice,
            initialPosRetailPrice: initialPriceData ? initialPriceData : 0,
            finalPosRetailPrice: posRetailPrice ? Number(posRetailPrice) : 0,
            priceScheduleVendorProductGroupId,
            productPriceTypeApplied:
              productPriceTypeApplied && customer?.fixMarginCustFlag === 0
                ? typeof productPriceTypeApplied === "string"
                  ? ProductPriceType[productPriceTypeApplied]
                  : productPriceTypeApplied
                : customer?.fixMarginCustFlag > 0
                  ? ProductPriceType.FIXED_MARGIN_PRICE
                  : isCustomPrice
                    ? ProductPriceType.CUSTOM
                    : ProductPriceType[type],
            priceMU: priceMU,
            muName: muName,
            itemMU: itemMU,
            itemMuName: itemMuName,
            unitPerCase: unitPerCase,
            qtyInStock: qtyInStock,
            updatedAvailableQty: updatedAvailableQty,
            qtyOrdered: qtyOrdered,
            clearanceFlag: clearance?.type === typeClearance ? 1 : 0,
            discontinueFlag: discontinued?.type === typeDiscontinued ? 1 : 0,
            priceScheduleProductId:
              priceScheduleProductPrice?.priceScheduleProductId || null,
            markupMultiplier,
            overridePrice: salesOverride?.price || null,
            pickConfigs: null,
            shippingNote: shippingNote,
            orderNote: orderNote,
          });
        }
      );

    const subTotalAmount = cartExtPrice ? cartExtPrice.toFixed(2) : 0;
    const taxAmountWithoutRoundOff = (cartExtPrice * taxRate) / 100;
    const taxAmount = customerTaxExemptFlag
      ? 0
      : roundToTwoDecimalPlaces(taxAmountWithoutRoundOff);
    // might need later
    // const taxAmount = taxExemptFlag ? 0 : ((cartExtPrice * taxRate) / 100).toFixed(2);
    const shippingTaxAmount = roundToTwoDecimalPlaces(
      (shippingAmount * taxRate) / 100
    );

    const grandTotalAmount =
      savedCart?.shipToByFacility?.tax?.shipTaxFlag === 1
        ? Number(subTotalAmount) +
        Number(taxAmount) +
        Number(shippingAmount) +
        Number(shippingTaxAmount)
        : Number(subTotalAmount) + Number(taxAmount) + Number(shippingAmount);

    const { recipient } = posShiptoFacilities || {};
    const genericPhoneInfo = {
      type: Placeholders.phoneType,
      areaCode: recipient?.phone1,
      prefix: recipient?.phone2,
      lineNum: recipient?.phone3,
      number: `${recipient?.phone1}${recipient?.phone2}${recipient?.phone3}`,
      extn: recipient?.phone4 || "",
      intCode: "1",
    };
    const firstNameGeneric = recipientSwitchInfo
      ? recipient?.firstName
      : posFirstName;
    const lastNameGeneric = recipientSwitchInfo
      ? recipient?.lastName
      : posLastName;
    const emailNameGeneric = recipientSwitchInfo
      ? recipient?.email
        ? recipient.email
        : null
      : email
        ? email
        : null;
    const companyNameGeneric = recipientSwitchInfo
      ? recipient?.companyName
      : companyName;
    const phoneNumberGeneric = recipientSwitchInfo
      ? genericPhoneInfo?.number
        ? genericPhoneInfo
        : null
      : posPhone?.number
        ? posPhone
        : null;
    const customerData = {
      buyOnBanNote: hasInternalNotes,
      customerId: savedCart?.customer?.customerId
        ? savedCart?.customer?.customerId
        : "",
      customerNote: savedCart?.customerNote || "",
      customerInfoUpdated: customerInfoUpdated,
      // will change in next PR
      firstName: isPosGeneric ? firstNameGeneric : firstName || "",
      lastName: isPosGeneric ? lastNameGeneric : lastName || "",
      posCustCode: savedCart?.customer?.posCustCode || null,
      phone: isPosGeneric
        ? phoneNumberGeneric
        : savedCart?.customer?.phone || null,
      email: isPosGeneric ? emailNameGeneric : savedCart?.customer?.email ?? "",
      companyName: isPosGeneric ? companyNameGeneric : null,
      hasInternalNotes: savedCart?.customer?.hasInternalNotes
        ? savedCart?.customer?.hasInternalNotes
        : false,
      buyOnCreditHold: savedCart?.customer?.creditHoldFlag === 1,
      showPrices: savedCart?.customer?.showPrices
        ? savedCart?.customer?.showPrices
        : false,
      isPosGeneric: savedCart?.customer?.isPosGeneric
        ? savedCart?.customer?.isPosGeneric
        : false,
      lookupType: savedCart?.customer?.lookupType,
    };

    const uploadData = [];
    const formFiles = [];
    addedDocument?.length &&
      addedDocument.map((item: UploadedDocumentItemProp) => {
        const {
          file,
          description,
          typeId,
          documentSaveType,
          locationSaveType,
          uploadedBy,
          originalFile,
        } = item;
        formFiles.push(originalFile);
        if (originalFile) {
          const data = {
            fileName: file,
            description: description,
            typeId: typeId,
            documentSaveType: documentSaveType,
            locationSaveType: locationSaveType,
            uploadedBy: uploadedBy,
          };
          uploadData.push(data);
        }
      });

    const savedDraftTaxExempt = savedCart?.customer?.taxExemptFlag
      ? 1
      : savedCart?.shipToByFacility?.recipient?.taxExemptFlag
        ? 1
        : isTaxExempted
          ? 1
          : 0

    const preparedData = {
      sessionStartedAt: savedCart?.sessionStartedAt
        ? savedCart?.sessionStartedAt
        : "",
      quoteId: selectQuoteId ? selectQuoteId : null,
      quoteType: selectQuoteType ? selectQuoteType : "NOT_SET",
      cartId: savedCart?.cartId ? savedCart?.cartId : "",
      loggedInUserId: decodedData?.DefaultLoginId,
      transactionType: "SALE",
      stockWarehouseId,
      takerId: currentTaker?.personId,
      purchaseOrderNum:
        poNumber ||
        (!isPurchaseOrderNoEdit &&
          loadCartDetails?.products?.length &&
          loadCartDetails.purchaseOrderNum) ||
        null,
      jobName: draftSaveName,
      customer: customerData,
      taxRate: savedDraftTaxExempt === 1 ? 0 : savedCart?.shipToByFacility?.tax?.taxRate,
      taxSchedule: savedDraftTaxExempt === 1 ? "NONTAX" : savedCart?.shipToByFacility?.tax?.taxSchedule,
      initialTaxAmt: savedDraftTaxExempt === 1 ? 0 : Number(taxAmount),
      initialTaxExemptFlag: savedDraftTaxExempt === 1 ? 1 : 0,
      shipTaxFlag: savedCart?.shipToByFacility?.tax?.shipTaxFlag
        ? savedCart?.shipToByFacility?.tax?.shipTaxFlag
        : 0,
      initialShipToAddressId: savedCart?.shipToByFacility?.facilityId,
      finalShipToAddressId: shiptoFacilities?.facilityId,
      salesPersonId:
        posFlowData?.salesman?.ssoId || posFlowData?.salesman?.personId || 0,
      initialScheduleId: savedCart?.customer?.priceScheduleId,
      finalScheduleId: schedule?.id
        ? schedule?.id
        : savedCart?.customer?.priceScheduleId
          ? savedCart?.customer?.priceScheduleId
          : "",
      paymentMode: "CASH",
      paymentRef1: null,
      paymentRef2: null,
      subTotalAmt: Number(subTotalAmount),
      taxAmt: savedCart?.customer?.taxExemptFlag
        ? 0
        : savedCart?.shipToByFacility?.recipient?.taxExemptFlag
          ? 0
          : isTaxExempted
            ? 0
            : Number(taxAmount) || 0,
      taxExemptFlag: savedDraftTaxExempt,
      shippingFeeAmt: Number(shippingAmount),
      shippingTaxAmt: shipTaxAmtToBeSent ? shipTaxAmtToBeSent : 0.0,
      grandTotalAmt: Number(grandTotalAmount.toFixed(4)) || 0,
      tenderAmt: 0,
      tenderBalanceAmt:
        Number(priceToBeFixedConverter(0 - grandTotalAmount)) || 0,
      products: cartProductsForLater,
      uploadFiles: uploadData?.length ? uploadData : null,
      shippingNote: shippingInstructions,
      orderNote: orderInstructions,
      takerName: takerFullName,
      salesPersonName: salespersonName ? salespersonName : "",
      noDeliverToShipAddressButPickupFlag: allowPickupCheck ?? false
    };
    dispatch(setNoLoadDetailsAction(false));

    const processDataFormat = JSON.stringify(JSON.stringify(preparedData));

    const formData = new FormData();
    formData.append("posOrderTransactionRequest", processDataFormat);

    addedDocument.forEach((item: UploadedDocumentItemProp) => {
      const { originalFile } = item;
      if (originalFile) {
        formData.append("formFiles", originalFile);
      }
    });

    dispatch(cartDraftRequest(formData, cartId));
    if (isLogoutClick) {
      const posData = {
        ...posFlowData,
        isLogoutClick: false,
        cartProducts: [],
        addInStorage: [],
        poNumber: "",
        customer: {},
        savedCart: {},
        schedule: {},
        taker: {},
        pickOrderQuantity: [],
        pickQuantityArr: [],
        finalQtyArrayInfoData: [],
      };
      dispatch(setPosFlowData(posData));
      beforeUnloadCart([]);
      window.location.href = `${distributor.httpsUrl}/sso/logout`;
    }
  };

  const orderTotal = useMemo(() => {
    const extPrice = Number(cartExtPrice.toFixed(2)) || 0;
    // const taxAmt = !calculateTax ? Number(((cartExtPrice * taxRate) / 100).toFixed(2)) : 0;
    // const shippingTaxFee =  !calculateTax ? Number((((shippingCharge?.shippingAmt || 0) * taxRate) / 100).toFixed(2)) : 0
    const shippingAmt = Number(shippingCharge?.shippingAmt) || 0;
    // might need later
    // const shippingFee = Number(
    //   !calculateTax
    //     ? Number(((cartExtPrice * taxRate) / 100).toFixed(2)) +
    //     +Number(
    //       (((shippingCharge?.shippingAmt || 0) * taxRate) / 100).toFixed(2)
    //     )
    //     : 0
    // );
    // const newShippingFee = Number(
    //   !calculateTax
    //     ? Number(((cartExtPrice * taxRate) / 100)) +
    //     +Number(
    //       ((Number((shippingAmt).toFixed(2)) * taxRate) / 100)
    //     )
    //     : 0
    // );
    // might need later : Tax amount calculation  Updated
    // const taxAmountWithoutRoundOff = ((cartExtPrice * taxRate) / 100);
    const taxAmountWithoutRoundOff =
      ((Number(subTotalAmount) + Number(shippingAmount)) * taxRate) / 100;
    const taxAmount = customerTaxExemptFlag
      ? 0
      : roundToTwoDecimalPlaces(taxAmountWithoutRoundOff);
    const newShippingFee = Number(
      !calculateTax
        ? Number(taxAmount)
        : // might need later : Tax amount calculation  Updated
        // +
        // +Number(
        //   ((Number((shippingAmt)) * taxRate) / 100)
        // )
        0
    );
    const shippingFee = Number(newShippingFee.toFixed(2));

    if (!isOrderTotalCustom && !isGPMCustom) {
      dispatch(setOriginalOrderTotal(extPrice + shippingFee + shippingAmt));
    }
    setOrderTotal(extPrice + shippingFee + shippingAmt);
    return extPrice + shippingFee + shippingAmt;
  }, [calculateTax, cartExtPrice, shippingCharge?.shippingAmt, taxRate]);

  // const finaTaxValue = useMemo(() => {

  // const taxAmountWithoutRoundOff =
  //   ((Number(subTotalAmount) + Number(shippingAmount)) * taxRate) / 100;
  // const taxAmount = customerTaxExemptFlag
  //   ? 0
  // : roundToTwoDecimalPlaces(taxAmountWithoutRoundOff);
  //   const taxValue = !calculateTax ? Number(taxAmount) : 0;
  //   return isNaN(taxValue) ? 0 : taxValue;
  // might need later : Tax amount calculation  Updated
  // const taxAmountWithoutRoundOff = ((cartExtPrice * taxRate) / 100);
  // might need later : Tax amount calculation  Updated
  // const taxValue = !calculateTax ?
  // Number(taxAmount) + +(((shippingCharge?.shippingAmt || 0) * taxRate) / 100)
  // : 0;

  // }, [cartExtPrice, shippingCharge?.shippingAmt, taxRate, calculateTax]);
  // might need later
  // const finaTaxValue = useMemo(() => {
  //   const taxValue = !calculateTax ?
  //   (cartExtPrice * taxRate) / 100 + +(((shippingCharge?.shippingAmt || 0) * taxRate) / 100)
  //   : 0;
  //   return isNaN(taxValue) ? 0 : taxValue;
  // }, [cartExtPrice, shippingCharge?.shippingAmt, taxRate,calculateTax]);

  const isFreeShip = currentCustomerDetail?.freeShipCustFlag;

  const footerRef: any = useRef();
  const productTitleInfoRef: any = useRef();
  const [footerHeight, setFooterHeight] = useState(
    footerRef?.current?.clientHeight
  );
  const [productHeaderHeight, setProductHeaderHeight] = useState(
    productTitleInfoRef?.current?.clientHeight
  );

  const getFooterSize = () => {
    const newFooterHeight = footerRef?.current?.clientHeight;
    setFooterHeight(newFooterHeight);
    const newProductHeaderHeight = productTitleInfoRef?.current?.clientHeight;
    setProductHeaderHeight(newProductHeaderHeight);
  };

  useEffect(() => {
    getFooterSize();
  }, [productTitleInfoRef?.current?.clientHeight]);

  useEffect(() => {
    window?.addEventListener("resize", getFooterSize);
  }, [
    footerRef,
    productTitleInfoRef,
    productTitleInfoRef?.current?.clientHeight,
  ]);

  const useHeightStyles = makeStyles({
    fixedHeader: {
      "@media (min-width: 1200px)": {
        paddingTop: `${headerHeightCount}px`,
        paddingBottom: `${footerHeight}px`,
      },
    },
    fixedTitle: {
      position: "relative",
      "@media (min-width: 1200px)": {
        top: `${headerHeightCount + settingIconDivHeight + 58}px`,
        position: "fixed",
      },
    },
    productListing: {
      "@media (min-width: 1200px)": {
        paddingTop: `${productHeaderHeight}px`,
      },
    },
  });

  const replaceStickyInfoClasses = useHeightStyles();

  useEffect(() => {
    const { addDocument } = posFlowData || {};
    if (addDocument?.length > 0) {
      addDocument?.map((item: UploadedDocumentItemProp) => {
        const { documentSaveTypeName } = item || {};
        if (
          documentSaveTypeName?.toLowerCase() ===
          documentByModule.taxExemptCerti.toLowerCase() ||
          posFlowData.isOrderDocument
        ) {
          setCalculateTax(true);
          dispatch(setExemptTax(true));
        }
      });
    } else {
      setCalculateTax(isToggleChange);
      dispatch(setExemptTax(isToggleChange));
    }
    if (customerTaxExemptFlag === 1) {
      dispatch(setExemptTax(true));
    }
  }, [calculateTax, posFlowData?.addDocument, taxRate, isToggleChange]);

  const handleProductClick = async ({
    priceScheduleVolume,
    posRetailPrice,
    productId,
    index,
  }) => {
    const matchVolume = priceScheduleVolume?.find((item) => {
      return item?.price.toFixed(5) === Number(posRetailPrice).toFixed(5);
    });
    if (matchVolume) {
      dispatch(getChangePriceInfo(false));
    } else {
      dispatch(getChangePriceInfo(true));
    }
    // dispatch for cart update here
    dispatch(stockByWarehouseStart([productId]));
    const updatePrice = productsInCart[index].posRetailPrice;
    const initialCustomPrice = saveProductInfo
      ? saveProductInfo[index]?.setInitialCustomPrice
      : productsInCart[index]?.productPrices[0]?.setInitialCustomPrice;
    setUpdatedPrice(updatePrice);
    const updatedProductData = productsInCart[index];
    setUpdatedProduct(updatedProductData);
    const updateQty = productsInCart[index].qtyOrdered;
    dispatch(lastViewProductIndex(index));
    setUpdatedQty(updateQty);
    dispatch(getNewPrice(updatePrice));
    const data = {
      stockWarehouseId,
      productId: productId,
      personId: personId || customerId,
      priceScheduleId: posFlowData?.schedule?.id
        ? posFlowData?.schedule?.id
        : loadCartDetails?.customer?.priceScheduleId,
      productUpdate: true,
    };
    dispatch(productsRequest(data, updatePrice, initialCustomPrice));
    dispatch(getProductIndexNumber(index));
    setNotesOpen(false);
    setNotesPlace(false);
  };

  const shipTaxFlagValue =
    !savedCart?.customer?.taxExemptFlag &&
    !shiptoFacilities?.recipient?.taxExemptFlag &&
    !isTaxExempted;

  const { continueToPayment, modalTitleGPM } = title;

  const handleSubmitPercentageChange = (
    percentage,
    originalPercentage,
    reset = false
  ) => {
    dispatch(isOrderTotalCustomFlag(false));
    if (reset) {
      setProfitPercentage(Number(originalPercentage));
      dispatch(isGpmCustomFlag(false));
      replacePricebyPercentage(originalPercentage, true);
    } else {
      setProfitPercentage(Number(percentage));
      if (Number(originalPercentage) !== Number(percentage)) {
        dispatch(isGpmCustomFlag(true));
      } else {
        dispatch(isGpmCustomFlag(false));
      }
      if (Number(originalPercentage) !== Number(percentage)) {
        replacePricebyPercentage(percentage, false);
      }
    }
    setModal({
      isOpen: false,
      modalTitle: modal.modalTitle,
    });
  };
  const handleSubmitTotalChange = (total, originalTotal, reset) => {
    dispatch(isGpmCustomFlag(false));
    if (reset) {
      setOrderTotal(Number(originalTotal));
      dispatch(isOrderTotalCustomFlag(false));
      replacePricebyPercentage(originalProfit, true);
    } else {
      // Old Logic : might need later
      // const newPercentage = roundToTwoDecimalPlaces((Number(total) * Number(originalProfit)) / Number(originalTotal))
      // setProfitPercentage(Number(newPercentage));
      if (Number(originalTotal) !== Number(total)) {
        dispatch(isOrderTotalCustomFlag(true));
      } else {
        dispatch(isOrderTotalCustomFlag(false));
      }
      if (Number(originalTotal) !== Number(total)) {
        // Old Logic : might need later
        // replacePricebyPercentage(newPercentage , false)
        calculateNewOrderTotal(Number(total));
      }
    }
    const data = {
      ...posFlowData,
      shippingCharge: {
        shippingAmt: 0,
        shippingAmtTax: 0,
      },
    };
    dispatch(setPosFlowData(data));
    setModal({
      isOpen: false,
      modalTitle: modal.modalTitle,
    });
  };
  const calculateNewOrderTotal = (total) => {
    let calculateVendorPrice = 0;
    let calculateNetPrice = 0;
    const data = cartProducts?.map((cartItem) => {
      const { qtyOrdered, priceMU, itemMU, initialPriceData } = cartItem;
      const newItemTotalWithTax =
        total - Number(shippingTaxAmount) - Number(shippingAmount);
      const newItemTotalWithoutTax = roundToTwoDecimalPlaces(
        newItemTotalWithTax / (1 + shiptoFacilities?.tax?.taxRate)
      );
      const itemVendorCostTotal = roundToTwoDecimalPlaces(
        (initialPriceData / (priceMU * 1.0)) * qtyOrdered * itemMU
      );
      const percent =
        Number(newItemTotalWithoutTax) / Number(itemVendorCostTotal);
      const newItemSalePrice = percent * initialPriceData;
      cartItem.posRetailPrice = Number(newItemSalePrice);
      const new_item_ext_price = roundToTwoDecimalPlaces(
        (Number(newItemSalePrice) / (priceMU * 1.0)) * qtyOrdered * itemMU
      );
      cartItem.extPrice = Number(new_item_ext_price);
      calculateVendorPrice += Number(initialPriceData);
      calculateNetPrice += Number(newItemSalePrice);
      return cartItem;
    });
    const profitPercentageCalc =
      ((calculateNetPrice - calculateVendorPrice) / calculateNetPrice) * 100;
    setProfitPercentage(Number(profitPercentageCalc));

    dispatch(setPosFlowData({ ...posFlowData, cartProducts: data }));
  };

  const priceBasedOnType = (item) => {
    const {
      productPrices: priceOfProduct,
      vendorPrice,
      movingAvgCost,
      salesPrice,
    } = item;
    const firstProductTypeS = priceOfProduct?.[0] || {};
    let price = 0;
    if (firstProductTypeS?.vpgComputePriceType === typeVpgVendorPrice) {
      price = vendorPrice.price;
    } else if (
      firstProductTypeS?.vpgComputePriceType === typeVpgMovingAvgCost
    ) {
      price = movingAvgCost.price;
    } else if (firstProductTypeS?.vpgComputePriceType === typeVpgSalesPrice) {
      price = salesPrice.price;
    } else {
      price = vendorPrice.price;
    }
    return price;
  };

  const replacePricebyPercentage = (percentage, reset) => {
    const data = cartProducts?.map((cartItem) => {
      const { qtyOrdered, priceMU, itemMU, initialPriceData } = cartItem;
      if (reset) {
        const itemMuPricePrices = Number(
          calculatePriceItemMuEach(priceMU, itemMU, initialPriceData)
        ).toFixed(5);
        cartItem.posRetailPrice = initialPriceData;
        const extPriceInfos = (qtyOrdered * Number(itemMuPricePrices)).toFixed(
          2
        );
        cartItem.extPrice = Number(Number(extPriceInfos).toFixed(2));
      } else {
        const priceItem = priceBasedOnType(cartItem);
        const newPrice = getSalesPrice(percentage, priceItem);
        cartItem.posRetailPrice = newPrice;
        const itemMuPricePrices = Number(
          calculatePriceItemMuEach(priceMU, itemMU, newPrice)
        ).toFixed(5);
        cartItem.extPrice = Number(
          (qtyOrdered * Number(itemMuPricePrices)).toFixed(2)
        );
      }
      return cartItem;
    });
    dispatch(setPosFlowData({ ...posFlowData, cartProducts: data }));
  };
  const gpmDetailsModalOpen = () => {
    return (
      <GpmChangeModal
        profit={profitPercentage ? profitPercentage?.toFixed(2) : "0"}
        profitPercentageOrignal={
          originalProfit ? originalProfit.toFixed(2) : "0"
        }
        setProfitPercentage={setProfitPercentage}
        isProfitPercentage={isGPMCustom}
        handleSubmitChange={handleSubmitPercentageChange}
        modalRef={modalRef}
      />
    );
  };
  const orderTotalModalOpen = () => {
    return (
      <OrderTotalChangeModal
        finalTotal={isNaN(originalTotal) ? "0" : originalTotal?.toFixed(2)}
        total={orderTotalSet ? orderTotalSet?.toFixed(2) : "0"}
        setTotal={setOrderTotal}
        isTotal={isOrderTotalCustom}
        handleSubmitChange={handleSubmitTotalChange}
        modalRef={modalRef}
      />
    );
  };
  const taxBlock = !isTaxExempted ? Number(taxAmount).toFixed(2) : (0).toFixed(2);

  return (
    <>
      <PosHelmet title="POS" metaName="Cart page" content="Cart page" />
      <>
        <div
          className={`cart-wrapper ${replaceStickyInfoClasses.fixedHeader}`}
          id="cart-product"
        >
          {productsInCart?.length ? (
            <>
              <div
                className={`cart-product-responsive-list cart-product-responsive-header-list cart-items-responsive-header p-0 ${replaceStickyInfoClasses.fixedTitle}`}
                ref={productTitleInfoRef}
              >
                <Grid
                  container
                  className="cart-data-header-sec p-0 cart-only-title-header justify-content-center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    md={1}
                    lg={1}
                    xl={1}
                    className="cart-product-line-id cart-product-grid"
                  >
                    <div className="cart-product-all-data cart-line-id text-center">
                      <div className="">
                        <span className="main-sec-titles">LINE ID</span>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    lg={7}
                    xl={7}
                    className="cart-product-item-info cart-product-grid"
                  >
                    <div className="cart-product-all-data">
                      <div className="">
                        <span className="main-sec-titles">ITEM</span>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={2}
                    md={1}
                    lg={1}
                    xl={1}
                    className="cart-product-ordered-qty cart-product-grid"
                  >
                    <div className="cart-product-all-data product-ordered-qty">
                      <div className="">
                        <span className="main-sec-titles">QTY.</span>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={1}
                    lg={1}
                    xl={1}
                    className="cart-product-picked-qty cart-product-grid"
                  >
                    {pickUpSelected || checkMixOrder ? (
                      <div className="cart-product-all-data product-picked-qty">
                        <div className="">
                          <span className="main-sec-titles">PICKED QTY.</span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={2}
                    lg={1}
                    xl={1}
                    className="cart-product-new-price cart-product-grid"
                  >
                    <div className="cart-product-all-data product-net-price">
                      <div className="">
                        <span className="main-sec-titles">NET PRICE</span>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={2}
                    lg={1}
                    xl={1}
                    className="cart-product-ext-price cart-product-grid"
                  >
                    <div className="cart-product-all-data product-ext-price">
                      <div className="">
                        <span className="main-sec-titles">EXT-PRICE</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </>
          ) : null}
          <CommonModel
            openModal={updateItemModal}
            noCloseBtn
            closeModal={async (reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                await dispatch(getupdateItemModal(false));
              }
            }}
            dialogClassName="update-product-dialog-box"
            disableBackdropClick
            disableEscapeKeyDown
          >
            <UpdateProduct />
          </CommonModel>
          {/* cart items */}
          <div
            id="cart-list-product"
            className={replaceStickyInfoClasses.productListing}
          >
            <div className="cart-list">
              {cartProducts?.length ? (
                cartProducts?.map(
                  (
                    {
                      lineIdData,
                      priceScheduleVolume,
                      priceMU,
                      itemMU,
                      posRetailPrice,
                      qtyOrdered,
                      thumbImg,
                      clearance,
                      totalPickedValue,
                      productName,
                      manufacturerCode,
                      discontinueFlag,
                      discontinued,
                      clearanceFlag,
                      productId,
                      isPopular,
                      qtyInStock,
                    },
                    index
                  ) => {
                    const { defaultIcon } = images;
                    const itemMuPriceWithPosRetailPrice =
                      calculatePriceItemMuEachNew(
                        priceMU,
                        itemMU,
                        posRetailPrice,
                        qtyOrdered
                      ) || 0;
                    const clearanceData =
                      clearance?.type === typeClearance || clearanceFlag === 1;
                    const discontinuedData =
                      discontinued?.type === typeDiscontinued ||
                      discontinueFlag === 1;
                    return (
                      <Button
                        key={`cart-product-${index}`}
                        className={`cart-product-responsive-list border-0 bg-transparent 
                        ${isPopular ? "popularitem-bottom-spacing" : ""}
                      `}
                        onClick={() =>
                          handleProductClick({
                            priceScheduleVolume,
                            posRetailPrice,
                            productId,
                            index,
                          })
                        }
                      >
                        <Grid
                          container
                          className="cart-data-header-sec p-0 justify-content-center"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            md={1}
                            lg={1}
                            xl={1}
                            className="cart-product-line-id cart-product-grid"
                          >
                            <div className="cart-product-all-data cart-line-id text-center direction-col">
                              <div className="">
                                <span className="title">LINE ID</span>
                              </div>
                              <div className="">
                                <span>{lineIdData}</span>
                              </div>
                              <div className="cart-indicator d-flex align-items-center">
                                <PosTooltip
                                  title={
                                    discontinuedData
                                      ? "Discontinued"
                                      : qtyInStock > 0
                                        ? "Available in stock"
                                        : qtyInStock === 0 && "Out of stock"
                                  }
                                  placement="top"
                                >
                                  <div className="indicator">
                                    <span
                                      className={`indicator-style ${discontinuedData
                                        ? classes.colorYellow
                                        : qtyInStock
                                          ? classes.colorGreen
                                          : classes.colorRed
                                        }`}
                                    />
                                  </div>
                                </PosTooltip>
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            md={5}
                            lg={7}
                            xl={7}
                            className="cart-product-item-info cart-product-grid"
                          >
                            <div className="cart-product-all-data">
                              <div className="">
                                <span className="title">ITEM</span>
                              </div>
                              <div className="cart-product-info">
                                <div>
                                  <PosImage
                                    src={thumbImg || defaultIcon}
                                    alt="search-list-icon"
                                    className="search-list-img"
                                  />
                                </div>
                                <div className="product-data-text">
                                  <div>
                                    <div>
                                      <span className="product-name-cart">
                                        {productName}
                                      </span>
                                    </div>
                                    <div>
                                      <span>{manufacturerCode}</span>
                                    </div>
                                  </div>
                                  <div>
                                    {clearanceData ? (
                                      <div
                                        className={`${classes.clearanceProduct} clearance-product`}
                                      >
                                        CLEARANCE
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={2}
                            md={1}
                            lg={1}
                            xl={1}
                            className="cart-product-ordered-qty cart-product-grid"
                          >
                            <div className="cart-product-all-data product-ordered-qty">
                              <div className="">
                                <span className="title">QTY.</span>
                              </div>
                              <div className="">
                                <span className="">{qtyOrdered}</span>
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            md={1}
                            lg={1}
                            xl={1}
                            className="cart-product-picked-qty cart-product-grid"
                          >
                            <div className="cart-product-all-data product-picked-qty">
                              <div className="">
                                <span className="title">PICKED QTY.</span>
                              </div>
                              <div className="">
                                <span className="">
                                  {isNaN(Number(totalPickedValue))
                                    ? ""
                                    : Number(totalPickedValue) ?? 0}
                                </span>
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={2}
                            lg={1}
                            xl={1}
                            className="cart-product-new-price cart-product-grid"
                          >
                            <div className="cart-product-all-data product-net-price">
                              <div className="">
                                <span className="title">NET PRICE</span>
                              </div>
                              <div className="">
                                <span className="">
                                  <sup>$ </sup>
                                  {priceToBeFixedConverter(posRetailPrice, 5)
                                    ? priceToBeFixedConverter(
                                      posRetailPrice,
                                      5
                                    )
                                    : "0.00000"}
                                </span>
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={2}
                            lg={1}
                            xl={1}
                            className="cart-product-ext-price cart-product-grid"
                          >
                            <div className="cart-product-all-data product-ext-price">
                              <div className="">
                                <span className="title">EXT-PRICE</span>
                              </div>
                              <div className="">
                                <span className="">
                                  <sup>$</sup>
                                  {roundToTwoDecimalPlaces(
                                    itemMuPriceWithPosRetailPrice
                                  )}
                                </span>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Button>
                    );
                  }
                )
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* cart footer */}
        </div>
        <div className="cart-wrapper" id="cart-product">
          <div
            id="cart-footer"
            className="cart-footer-wrapper new-pos-footer-style"
            ref={footerRef}
          >
            <div className="cart-footer">
              <div className="footer-blocks">
                <div className="particulars">
                  <FooterBlock
                    label="GPM"
                    hidden={true}
                    infoLabel={isGPMCustom ? "CUSTOM" : ""}
                    value={
                      isFinite(profitPercentage)
                        ? profitPercentage
                          ? `${profitPercentage.toFixed(2)}%`
                          : 0
                        : "--"
                    }
                    mainClass="profit-block-styling"
                    clickable={true}
                    infoLabelStyling={isGPMCustom ? "gpm-custom-label" : ""}
                    onClick={() => {
                      setModal({ isOpen: true, modalTitle: modalTitleGPM });
                    }}
                  />
                  <FooterBlock
                    notClickable
                    label="EXT-PRICE"
                    value={`<sup>$</sup>${cartExtPrice ? cartExtPrice.toFixed(2) : 0.0
                      }`}
                  />
                  <FooterBlock
                    label="Tax"
                    onClick={() => {
                      setShowTax(true);
                      setState({ ...state, right: true });
                    }}
                    value={`<sup>$</sup>
                      ${taxBlock}`
                    }
                    clickable={true}
                    infoLabel={isTaxExempted ? "EXEMPT" : ""}
                    infoLabelStyling={isTaxExempted ? "exempt-custom-label" : ""}
                  />
                  <FooterBlock
                    onClick={() => {
                      setShowShipping(true);
                      setPriceInDecimal(true);
                      setPaymentMethodDrawer(true);
                    }}
                    label="SHIPPING"
                    value={`<sup>$</sup>
                  ${isNaN(shippingCharge?.shippingAmt)
                        ? Number(0.0).toFixed(2)
                        : Number(shippingCharge?.shippingAmt).toFixed(2) ||
                        Number(0.0).toFixed(2)
                      }`}
                    infoLabel={isFreeShip ? "Free" : ""}
                    clickable={true}
                    infoLabelStyling={isFreeShip ? "shipping-free-label" : ""}
                  />
                </div>
                <div className="totals">
                  <FooterBlock
                    label="Order Total"
                    clickable={true}
                    infoLabel={isOrderTotalCustom ? "CUSTOM" : ""}
                    value={`<sup>$</sup>
                    ${isNaN(orderTotal) ? 0 : orderTotal.toFixed(2)}`}
                    infoLabelStyling={
                      isOrderTotalCustom ? "gpm-custom-label" : ""
                    }
                    onClick={() => {
                      setModal({
                        isOpen: true,
                        modalTitle: "Change Order Total",
                      });
                    }}
                  />
                </div>
              </div>
              <div className="actions">
                <div className="cart-footer-btn">
                  {/* need for new functionality */}
                  {/* <PosButton
                      onClick={() => {
                        setOrderersDetails(true);
                      }}
                      onKeyDown={(e) => handleKeyPress(e)}
                      className="save-for-later-btn btn"
                      size="large"
                      variant="contained"
                      endIcon="S"
                    >
                      Save for later
                    </PosButton> */}
                  <PosButton
                    onClick={() => {
                      setShowSaveLater(true);
                      setState({ ...state, right: true });
                      dispatch(clearSearchCustomer());
                    }}
                    className="save-for-later-btn btn"
                    size="large"
                    variant="contained"
                    endIcon="S"
                  >
                    Save for later
                  </PosButton>
                  <PosButton
                    onClick={() => {
                      setShowPrintOptions(true);
                      setState({ ...state, right: true });
                    }}
                    className="cart-print-btn btn"
                    size="large"
                    variant="contained"
                    endIcon="P"
                  >
                    Print
                  </PosButton>
                </div>
                <div className="cart-footer-btn">
                  {checkMixOrder ? (
                    <PosButton
                      fontWeight
                      onClick={redirectToPickQty}
                      className="set-picking-info-btn btn pick-info-btn"
                      size="large"
                      variant="contained"
                      color="primary"
                      endIcon="Q"
                    >
                      Set Picking info.
                    </PosButton>
                  ) : (
                    <PosButton
                      fontWeight
                      onClick={() => {
                        handlePaymentClick();
                      }}
                      variant="contained"
                      color="primary"
                      className="set-picking-info-btn btn continue-payment-btn"
                      endIcon="D"
                    >
                      <div className="select-default-text mr-2">
                        <span className="int-18-reg-white">
                          {continueToPayment}
                        </span>
                      </div>
                    </PosButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <OrderDetailsDrawer
        open={state["right"]}
        drawerClass={classes.drawer}
        onClick={() => {
          setState({ ...state, right: false });
          setShowShipping(false);
          setShowTax(false);
          setShowSaveLater(false);
          setShowPrintOptions(false);
          setShowSaveForLater(false);
        }}
        setIsToggleChange={setIsToggleChange}
        orderInstructions={orderInstructions}
        setOrderInstructions={setOrderInstructions}
        shippingInstructions={shippingInstructions}
        setShippingInstructions={setShippingInstructions}
        temporaryStorageLocation={temporaryStorageLocation}
        setTemporaryStorageLocation={setTemporaryStorageLocation}
        showPrices={showPrices}
        handlePriceCheck={handlePriceCheck}
        handleMarkAsQuoteCheck={handleMarkAsQuoteCheck}
        expiryDate={quoteExpiryDate}
        setExpiryDate={setExpiryDate}
        priceCheck={priceCheckReducer}
        markAsQuote={markAsQuoteReducer}
        setPrintLoaderWithTotal={setPrintLoaderWithTotal}
        setPrintLoader={setPrintLoader}
        setShowSaveForLater={setShowSaveForLater}
        showPrintOptions={showPrintOptions}
        setSuggestionListVisible={setSuggestionListVisible}
        setCartEmpty={setCartEmpty}
        setSuggestedProducts={setSuggestedProducts}
        printInvoice={printInvoice}
        showSaveLater={showSaveLater}
        saveForLaterAPI={saveForLaterAPI}
        showTax={showTax}
        setCalculateTax={setCalculateTax}
        taxRate={taxRate}
        setTaxRate={setTaxRate}
        taxAmount={taxAmount}
        shipCharge={shippingCharge?.shippingAmt || 0}
        state={state}
        setState={setState}
        setShowShipping={setShowShipping}
        setShowTax={setShowTax}
        setShowSaveLater={setShowSaveLater}
        setShowPrintOptions={setShowPrintOptions}
        cartDrawerRef={cartDrawerRef}
        setCustomerInfo={setCustomerInfo}
        taxExemptFlag={customerTaxExemptFlag}
      />

      {paymentMethodDrawer || shippingAddress || balanceDetail ? (
        <PaymentMethod
          pickUpSelected={pickUpSelected}
          shipCharge={shippingCharge?.shippingAmt || 0}
          setShipChargeTax={() => {
            // Required Later
          }}
          setShipCharge={() => {
            // Required Later
          }}
          showShipping={showShipping}
          state={state}
          setState={setState}
          priceInDecimal={priceInDecimal}
          setPriceInDecimal={setPriceInDecimal}
          orderInstructions={orderInstructions}
          shippingInstructions={shippingInstructions}
          setShippingInstructions={setShippingInstructions}
          open={paymentMethodDrawer || shippingAddress || balanceDetail}
          onClose={() => {
            setShowShipping(false);
            setPriceInDecimal(false);
            setPaymentMethodDrawer(false);
          }}
          className=""
          setShippingAddress={setShippingAddress}
          setPickUpSelected={setPickUpSelected}
          drawerState={state}
          setDrawerState={setState}
          setShipToByFacilityId={setShipToByFacilityId}
          drawerRef={drawerRef}
          shippingAddress={shippingAddress}
          balanceDetail={balanceDetail}
          setBalanceDetail={setBalanceDetail}
          creditAmtApplied={creditAmtApplied}
          setCreditAmtApplied={setCreditAmtApplied}
          // isPayPalAccount={isPayPalAccount}
          paymentMethodRef={paymentMethodRef}
        />
      ) : (
        <></>
      )}
      <PosModel
        open={modal.isOpen}
        onClose={() =>
          setModal({ isOpen: false, modalTitle: modal.modalTitle })
        }
        dialogTitle
        title={modal.modalTitle}
        dialogClassName="gpm-details-modal-container modal-btn-content-width common-modal-btn-style"
        closeIconTabIndex={0}
      >
        {modal.modalTitle === modalTitleGPM
          ? gpmDetailsModalOpen()
          : orderTotalModalOpen()}
      </PosModel>
    </>
  );
};

export default OrderDetails;
