import { all, call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

import * as actions from "./action";
import * as services from "services/index";
import * as constants from "./constants";

// Start import Quote 
function* importQuoteAsync(action: AnyAction) {
    try {
      const importQuoteData = yield call(services.getImportQuotesData, action.payload);
      yield put(
        actions.importQuoteSuccess(importQuoteData)
      );
    } catch (errors) {
      yield put(actions.importQuoteError("errors"));
    }
  }
// End import Quote 

// Start import Quote search
function* importQuoteSearchAsync(action: AnyAction) {
  try {
    const importQuoteSearchData = yield call(services.getImportQuotesSearchData, action.payload);
    yield put(
      actions.importQuoteSearchSuccess(importQuoteSearchData)
    );
  } catch (errors) {
    yield put(actions.importQuoteSearchError(errors.title));
  }
}
// End import Quote search

// Start import Quote Item
function* importQuoteItemsAsync(action: AnyAction) {
  try {
    const importQuoteItemsData = yield call(services.getImportQuotesItemsData, action.payload);
    yield put(
      actions.importQuoteItemsSuccess(importQuoteItemsData)
    );
  } catch (errors) {
    yield put(actions.importQuoteItemsError(errors.title));
  }
}
// End import Quote search

//Watcher Saga

// Start import Quote 
function* importQuoteWatcher() {
  yield takeLatest(
    constants.IMPORT_QUOTE_START,
    importQuoteAsync
  );
}
// End import Quote 

// Start import Quote search
function* importQuoteSearchWatcher() {
  yield takeLatest(
    constants.IMPORT_QUOTE_SEARCH_START,
    importQuoteSearchAsync
  );
}
// End import Quote search  

// Start import Quote Items
function* importQuoteItemsWatcher() {
  yield takeLatest(
    constants.IMPORT_QUOTE_ITEMS_START,
    importQuoteItemsAsync
  );
}
// End import Quote Items

export default function* importQuotesSaga() {
  yield all([
    importQuoteWatcher(),
    importQuoteSearchWatcher(),
    importQuoteItemsWatcher(),
  ])}