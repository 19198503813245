import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import './Skeleton.scss';

const Skeleton = ({ loading, lineCount = 5 }) => {
    const physics = {
        type: "spring",
        stiffness: 1000,
        damping: 400,
    }

    const skeletonContainerVariants = {
        animate: {
            transition: {
                staggerChildren: 0.015,
            }
        }
    }

    const skeletonLineVariants = {
        initial: {
            opacity: 0
        },
        animate: {
            opacity: 1,
            transition: physics,
        },
        exit: {
            opacity: 0,
        }
    }

    return (
        <AnimatePresence mode='popLayout'>
            {loading ? (
                <motion.div
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={skeletonContainerVariants}
                    className='skeleton-container'
                >
                    {[...new Array(lineCount).fill("")].map((item, index) => (
                        <motion.div
                            key={index}
                            variants={skeletonLineVariants}
                            className='skeleton-line'
                        />
                    ))}
                </motion.div>
            ) : null}
        </AnimatePresence>
    )
}

export default Skeleton