export interface SetCustomerCodeModalProps {
    openEditCustomerCode?: boolean;
    setOpenEditCustomerCode?: React.Dispatch<React.SetStateAction<boolean>>;
    openEditPricingSchedule?: boolean;
    setOpenEditPricingSchedule?: React.Dispatch<React.SetStateAction<boolean>>;
    posCustCode?: number;
    posPricingschedule?: number;
    priceScheduleName?: string;
    priceScheduleId?: number;
}

export const constantCustomerCode = {
    setCustomerCode: "Set Customer Code",
    setPricingSchedule: "Set Pricing Schedule",
    pricingSchedule: "Pricing Schedule",
    cancel: "Cancel",
    saveChanges: "Save Changes",
    setCode: "Set Code",
    customerCode: "Customer Code",
    codeExistError: "This code already exists. Try a different code",
    customerCodeChanged: "Customer code changed",
    customerCodeSet: "Customer code set",
    close: "Close"
}