import { useEffect } from "react";
import { Form, FormikProvider } from "formik";
import { useSelector, useDispatch } from "react-redux";

import {
  PosInput,
} from "apps/pos/Common/uiCore";
import { ErrorMessage } from "apps/pos/formik/errorMessage";
import Button from "apps/admin/components/Elements/Button";
import ReturnToGroup from "components/ReturnTo/ReturnToGroup";
import { useSingleRowsData } from "../queryHooks/useSingleRowData";

import { RemoveProps } from '../type'
import { decimal18RegEx } from "constants/constant";
import { CheckIcon } from "constants/icons";
import { RootState } from "models";
import { getLocalStoraGeItem } from "utils/utils";
import { PosFlowDataProp } from "constants/interfaces";
import texts from "apps/pos/locales";
import useRemoveStockFormik from "./useRemoveStockFormik";

import { setPosFlowData } from "sagas/persistReducer/actions";
import {
  fromLocationShelfClear,
  fromLocationUnitClear,
  getWarehourseLocationsRequest,
  getWarehouseLocationsClear,
  stockOutClear,
} from "sagas/pos/actions";

import "../add/add.scss";
import { tabType } from "constants/enum";

const Remove = ({
  removeRowName,
  setRemoveQuantity,
  tabValue,
  setRemoveRowName,
  setRemoveUnitName,
  setRemoveShelfName,
  removeQtyOnLocationData,
  setRemoveQuantityValue,
  units,
  setUnits,
  unitsErrors,
  setUnitsErrors,
  unitsLoading,
  setUnitsLoading,
  shelves,
  setShelves,
  shelvesErrors,
  setShelvesErrors,
  shelvesLoading,
  setShelvesLoading,
}: RemoveProps) => {
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );

  const {
    isStockOutLoading,
    productsData,
    stockOutDetails,
    inStockOutSuccess,
    isPickingSuccess,
  } = useSelector<RootState, any>((state) => state.pos);

  const {individualRowRefetch} = useSingleRowsData()

  const { cartProductDetails } = posFlowData || {};
  const { productId } = cartProductDetails || {};
  const { title } = stockOutDetails || {}
  const dispatch = useDispatch();
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace || {};

  const quantityRemoveOnchange = (
    quantiRemove: number,
    setFieldValue: (field: string, value: number | string) => void
  ) => {
    const moveQty = decimal18RegEx.test(quantiRemove.toString());
    if (Number(quantiRemove) > removeQtyOnLocationData) {
      setRemoveQuantity(removeQtyOnLocationData);
    } else if (moveQty) {
      setFieldValue("quantityToRemove", quantiRemove);
      setRemoveQuantity(quantiRemove);
    } else if ((quantiRemove.toString()) === "") {
      setFieldValue("quantityToRemove", "");
      setRemoveQuantity(quantiRemove);
    }
  };
  useEffect(() => {
    if (inStockOutSuccess) {
      setTimeout(() => {
        const data = {
          stockWarehouseId,
          body: [productId],
        }
        dispatch(getWarehourseLocationsRequest(data));
        dispatch(stockOutClear());
      }, 2000);
      if(isPickingSuccess) {
        individualRowRefetch()
      }
      setTouched({...touched,['quantityToRemove']: false } )
      setFieldValue("quantityToRemove", "")
      setFieldValue("removeReason", "")
    }
  }, [inStockOutSuccess, isPickingSuccess]);

  useEffect(() => {
    dispatch(fromLocationUnitClear())
    dispatch(fromLocationShelfClear())
  }, [tabValue]);

  useEffect(() => {
    if (!removeRowName) {
      resetForm();
      setRemoveRowName("");
      setRemoveUnitName("");
      setRemoveShelfName("");
    }
  }, [removeRowName])

  useEffect(() => {
    if (title) {
      setTimeout(() => {
        dispatch(stockOutClear());
      }, 2000);
    }
  }, [title]);
  
  const { removeStock } = texts

  const formik = useRemoveStockFormik({ removeQtyOnLocationData })
  const { values, setFieldValue, resetForm, touched, setTouched } = formik || {}
  const {
    removeAdjustStockRow,
    removeAdjustStockUnit,
    removeAdjustStockShelf,
    quantityToRemove,
    removeReason,
  } = values || {};

  const disableButtons = [removeAdjustStockRow, removeAdjustStockUnit, removeAdjustStockShelf, quantityToRemove].includes("")

  useEffect(()=>{
    setRemoveQuantityValue(quantityToRemove)
  }, [quantityToRemove])

  const setRemoveByLocation = (locationData) => {
    if (locationData?.row) {
      dispatch(getWarehouseLocationsClear())
      dispatch(fromLocationShelfClear())
      setRemoveRowName(locationData?.row?.name);
      setFieldValue("removeAdjustStockRow", locationData?.row?.id);
      //
      setRemoveShelfName("")
      setFieldValue("removeAdjustStockShelf", "");
      setRemoveUnitName("")
      setFieldValue("removeAdjustStockUnit", "");
      //
    }
    if (locationData?.unit) {
      dispatch(getWarehouseLocationsClear())
      setRemoveUnitName(locationData?.unit?.name);
      setFieldValue("removeAdjustStockUnit", locationData?.unit?.id);
      dispatch(fromLocationShelfClear())
      //
      setRemoveShelfName("")
      setFieldValue("removeAdjustStockShelf", "");
      //
    }
    if (locationData?.shelf) {
      setRemoveShelfName(locationData?.shelf?.name)
      setFieldValue("removeAdjustStockShelf", locationData?.shelf?.id);
    }
  }

  return (
    <div className="add-main-container">
      <>
        <FormikProvider value={formik}>
          <Form>
            <div className="quantity-adjust-title">
              <span>Remove from location</span>
            </div>
            {tabValue === tabType.remove ?
            <ReturnToGroup
              setSingleLocationData={setRemoveByLocation}
              cartEditModalData={false} 
              editReturnTo={[]}
              disabledMultiple
              disableReturnReplace
              getLocationProductWise
              tabValue={tabValue}
              units={units}
              setUnits={setUnits}
              unitsErrors={unitsErrors}
              setUnitsErrors={setUnitsErrors}
              unitsLoading={unitsLoading}
              setUnitsLoading={setUnitsLoading}
              shelves={shelves}
              setShelves={setShelves}
              shelvesErrors={shelvesErrors}
              setShelvesErrors={setShelvesErrors}
              shelvesLoading={shelvesLoading}
              setShelvesLoading={setShelvesLoading}
            /> : null
             }
            <div className="quantity-adjust-title">Quantity to Remove</div>
            <PosInput
              variant="outlined"
              id="outlined-basic"
              name="quantityToRemove"
              disabled={isStockOutLoading}
              value={quantityToRemove}
              onChange={(e) => {
                const removeQty = decimal18RegEx.test(e.target.value);
                if (!removeQty) {
                  e.target.value = e.target.value.slice(0, -1)
                }
                if (removeQty) {
                  setTouched({...touched,['quantityToRemove']: true } )
                  setFieldValue("quantityToRemove", e.target.value)
                  quantityRemoveOnchange(e.target.value, setFieldValue);
                } else if (!e.target.value) {
                  setTouched({...touched,['quantityToRemove']: true } )
                  setFieldValue("quantityToRemove", "")
                }
              }}
              className="quantity-input input-focus"
              onFocus={(e) => e.target.select()}
            />
            <ErrorMessage name="quantityToRemove" />
            <div className="quantity-adjust-title">
              Reason{" "}
              <span className="optional adjust-reason-optional-text">
                (optional)
              </span>
            </div>
            <PosInput
              variant="filled"
              id="filled-basic"
              autoComplete="off"
              InputProps={{ disableUnderline: true }}
              className="quantity-input"
              type="text"
              name="removeReason"
              onChange={(e) => setFieldValue("removeReason", e.target.value)}
              value={removeReason}
            />
            <div className="adjust-stock-btn-div">
              <Button
                label={removeStock}
                accent={true}
                disabled={disableButtons}
                icon={<CheckIcon />}
                onClick={() => {
                  const products = posFlowData?.cartProducts?.length > 0 && posFlowData?.cartProducts?.map((item) => {
                    if (item.productId === productsData.productId) {
                      return {
                        ...item,
                        removeQtyOnLocationData: values?.quantityToRemove,
                        qtyInStock : (Number(item.qtyInStock) - Number(values?.quantityToRemove))
                      };
                    }
                    return item;
                  });

                  const data = {
                    ...posFlowData,
                    proceedToPayment: false,
                    cartProducts: products,
                  };
                  dispatch(setPosFlowData(data));
                }}
                classes={`${isStockOutLoading ? 'button-is-loading' : "adjust-stock-btn remove-stock-btn"}`}
              />
            </div>
          </Form>
        </FormikProvider>
      </>
    </div>
  );
};

export default Remove;
