import { AnyAction } from "redux";

import {
  DISTRIBUTOR_FETCHING,
  DISTRIBUTOR_FETCH_SUCCESS,
  DISTRIBUTOR_FETCH_ERROR,
} from "./constants";

const initialState = {
  
};
const reducer = function distributorReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case DISTRIBUTOR_FETCHING:
      return { ...state, isLoading: true };
    case DISTRIBUTOR_FETCH_SUCCESS:
      return {...state, ...action.payload, isLoading: false};
    case DISTRIBUTOR_FETCH_ERROR:
      return {...state, ...action.payload, isLoading: false};
    default:
      return state;
  }
};

export default reducer;
