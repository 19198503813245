import moment from "moment";


import { DateFormat, PaginationFormat } from "constants/enum";
import { getComparator } from "utils/helper";
import { returnFixedOrEmptyValue } from "utils/utils";
import { title } from "constants/constant";

export const EnhancedTableBody = ({
  movingAvgCostHistory,
  stableSort
}) => {
  const { Asc, Calories } = PaginationFormat;
  const { MM_DD_YYYY } = DateFormat
  const { noRecordsAvailable } = title
  return movingAvgCostHistory?.length ? (
    <tbody className="moving-history-tbody">
      {
        stableSort(movingAvgCostHistory, getComparator(Asc, Calories))?.map(
          (row, index) => {
            const { takeInTime, orderID, takeInByName, takeInQty, takeInReason, takeInPrice, movingAvgCost,stockWarehouseName } = row;
            return (
              <tr  key={index}>
                <td >{moment(takeInTime).format(MM_DD_YYYY)}</td>
                <td >{takeInByName || ""}</td>
                <td>{orderID || ""}</td>
                <td>{takeInQty || ""}</td>
                <td >{takeInReason || ""}</td>
                <td>{returnFixedOrEmptyValue(takeInPrice, true) || "$0"}</td>
                <td align='right'>{returnFixedOrEmptyValue(movingAvgCost, true) || "$0"}</td>
                <td >{stockWarehouseName || ""}</td>
              </tr>
            );
          }
        )
      }
    </tbody>
  ) : (
    <tbody><tr>
        <td colSpan={7} align="center" className="p-5">{noRecordsAvailable}</td>
      </tr></tbody>
  )
}