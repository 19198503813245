import { FormikProvider, useFormik } from "formik";
import jwt_decode from "jwt-decode";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";

import { PosImage, PosModel } from "apps/pos/Common/uiCore";
import Button from "components/SPButton/Button";
import { springBoardText } from "constants/constant";
import { keys } from "utils/helper";
import { IconButton } from "../SBItems/elements";

import { useDocumentUpload } from "apps/springboard/QuoteDocuments/hooks/useDocumentUpload";
import { useGetDocumentType } from "apps/springboard/QuoteDocuments/hooks/useGetDocumentType";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import { getQuoteReps } from "sagas/quoteDetail/selector";
import { getQuoteDetailDocuments } from "services";

import UploadDocument from "Image/upload_documents.svg";
import "apps/springboard/Component/documentUpload/documentUpload.scss";

interface DocumentUploadProps {
  open: boolean;
  quoteId: number;
  handleCloseModel: () => void;
  updatedDocument: (data) => void;
}

export const DocumentUpload = ({
  open,
  handleCloseModel,
  quoteId,
  updatedDocument,
}: DocumentUploadProps) => {
  const {
    documentUpload,
    dropFileHere,
    browse,
    addDocument,
    description,
    documentType,
    Change,
  } = springBoardText;
  const [file, setFile] = useState(null);
  const quoteReps = useSelector(getQuoteReps);
  const cookies = new Cookies();
  const token = cookies.get("Authorization");
  const decoded: {
    id: string;
  } = jwt_decode(token || "");
  const decodedData = JSON.parse(decoded?.id);

  const personId = decodedData?.DefaultLoginId;
  const { DocumentTypeDetails } = useGetDocumentType();
  // const abortControllerRef = useRef(null);
  const { setDocumentUploadParams, isSuccess, isLoading } = useDocumentUpload();
  const dispatch = useDispatch();

  const handleKeyPress = (e) => {
    if (e.keyCode === keys.esc) {
      handleCloseModel();
    }
  };

  useEffect(() => {
    document?.addEventListener("keydown", handleKeyPress);
    return () => {
      document?.removeEventListener("keydown", handleKeyPress);
    };
  }, [open]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: "",
      documentType: "",
      rep: "",
    },
    onSubmit: (value) => {
      const getSelectedDoc = DocumentTypeDetails.find(
        (item) => item.typeId === Number(value.documentType)
      );
      const getSelectedRep =
        value.rep &&
        quoteReps.find((item) => item.quoteRepID === Number(value.rep));

      const selectedRepPayload = {
        QuoteRepID: getSelectedRep.quoteRepID,
        RepName: getSelectedRep.quoteRepName,
      };

      const finalPayload = {
        JobLotQuoteID: quoteId,
        DocTypeID: getSelectedDoc.typeId,
        DocName: file.name,
        DocDescription: values.description,
      };
      const docData = new FormData();

      if (value.rep) {
        docData.append("QuoteRepID", String(selectedRepPayload.QuoteRepID));
        docData.append("RepName", selectedRepPayload.RepName);
      }

      docData.append("JobLotQuoteID", String(finalPayload.JobLotQuoteID));
      docData.append("DocTypeID", finalPayload.DocTypeID);
      docData.append("DocName", finalPayload.DocName);
      docData.append("DocDescription", finalPayload.DocDescription);
      docData.append("DocSize", file.size);
      docData.append("HandlerID", personId);
      docData.append("File", file);

      //might needed
      // abortControllerRef.current = controller;
      // setSignalAPI(controller.signal);

      setDocumentUploadParams(docData);
    },
  });

  const { values, setFieldValue, handleSubmit } = formik;

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    } else {
      setFile(null);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(snakbarShow({ message: documentUpload }));
      getQuoteDetailDocuments(quoteId).then((response) => {
        const { data } = response || {};
        updatedDocument(data);
      });
      handleCloseModel();
    }
  }, [isSuccess]);

  const handleRemoveFile = () => {
    setFile(null);
  };

  const fileSize = (size) => {
    if (size < 1024) {
      return size + " Bytes";
    } else if (size < 1048576) {
      return (size / 1024).toFixed(2) + " KB";
    } else if (size < 1073741824) {
      return (size / 1048576).toFixed(2) + " MB";
    }
  };

  // const cancelRequest = () => {
  //   if (abortControllerRef.current) {
  //     abortControllerRef.current.abort();
  //   }
  // };

  const cancelForm = () => {
    //Commented code might needed late. If not will remove it
    // if (isLoading) {
    //   const confirm = window.confirm(cancelUploadText);
    //   if (confirm) {
    //     // cancelRequest();
    //     controller.abort();
    //     handleCloseModel();
    //     setFile(null);
    //   }
    // } else {
    //   handleCloseModel();
    //   setFile(null);
    // }
    handleCloseModel();
  };

  return (
    <Fragment>
      <PosModel
        open={open}
        onClose={handleCloseModel}
        className="set-customer-code-modal"
        dialogClassName="set-upload-doc-modal-container"
        dialogHeader={
          <div className="modal-header pl-4">
            <span className="modal-title">
              <div className="pt-2">
                <h3 className="f20-Inter-500-sab mb-0 mb-0">{addDocument}</h3>
              </div>
            </span>
            <IconButton
              name="close"
              className="close-icon"
              type="tertiary"
              onClick={handleCloseModel}
            />
          </div>
        }
      >
        <FormikProvider value={formik}>
          {!file ? (
            <div className="file-upload-container">
              <div className="upload-area">
                <div className="upload-area-icon">
                  <PosImage
                    src={UploadDocument}
                    alt="upload_icon"
                    width={80}
                    height={80}
                  />
                  <div>
                    {dropFileHere}&nbsp;
                    <label className="upload-area-label">
                      {browse}
                      <input
                        type="file"
                        onChange={handleFileChange}
                        onDrop={handleFileChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="file-details">
              <div className="d-flex flex-column">
                <span>{file.name}</span>
                <span className="file-details-size">{fileSize(file.size)}</span>
              </div>
              <div className="d-flex gap-2 mt-2 mb-3">
                <label className="file-change">
                  {Change}
                  <input type="file" onChange={handleFileChange} />
                </label>
                <Button label="Remove" onClick={handleRemoveFile} />
              </div>
              <div className="user-selection">
                <div className="documentType">
                  <span className="form-field-title mb-2 documentType-text">
                    {documentType}
                  </span>
                  <div>
                    <select
                      id="documentType"
                      name="documentType"
                      value={values.documentType}
                      onChange={(e) => {
                        setFieldValue("documentType", e.target.value);
                      }}
                      className="fs-14 documentType-select"
                    >
                      <option value={0}>Select...</option>
                      {DocumentTypeDetails.map((data) => (
                        <option key={data.typeId} value={data.typeId}>
                          {data.typeName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="rep-selection">
                  <span className="form-field-title mb-2 rep-selection-text">
                    Rep&nbsp;<span className="optional">(optional)</span>
                  </span>
                  <div>
                    <select
                      id="rep"
                      name="rep"
                      value={values.rep}
                      onChange={(e) => {
                        setFieldValue("rep", e.target.value);
                      }}
                      className="fs-14 rep-selection-select"
                    >
                      <option value={0}>Select...</option>
                      {quoteReps.map((person) => (
                        <option
                          key={person.quoteRepID}
                          value={person.quoteRepID}
                        >
                          {person.quoteRepName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="description">
                  <span className="form-field-title mb-2 description-text">
                    {description}&nbsp;
                    <span className="optional">(optional)</span>
                  </span>
                  <textarea
                    required
                    name="description"
                    id="description"
                    autoFocus
                    className="new-note-input"
                    onChange={(e) => {
                      setFieldValue("description", e.target.value);
                    }}
                    value={values.description}
                  />
                </div>
                <div className="d-flex gap-2 justify-content-end mt-3 mb-3">
                  <Button label="Cancel" onClick={cancelForm} />
                  <Button
                    label="Upload"
                    type="primary"
                    onClick={handleSubmit}
                    loading={isLoading}
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
          )}
        </FormikProvider>
      </PosModel>
    </Fragment>
  );
};
