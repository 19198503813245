import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
    root: {
      position: "unset",
      "& .MuiTableHead-root": {
        position: "unset",
        color: "black",
      },
    },
    drawer: {
      "& .MuiDrawer-paper": {
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
      },
    },
    discontinuedProduct: {
      fontSize: "0.75rem",
      backgroundColor: "#ffcd5a",
      padding: "0.188rem 0.375rem 0.125rem",
      borderRadius: "1.25rem",
      opacity: 1,
      letterSpacing: "0.0.188rem",
      fontWeight: 500,
      width: "fit-content",
      height: "fit-content",
    },
    clearanceProduct: {
      width: "fit-content",
      height: "fit-content",
      fontSize: "0.75rem",
      backgroundColor: "#5856D6",
      color: "#ffffff !important",
      padding: "0.188rem 0.375rem 0.125rem",
      borderRadius: "1.25rem",
      opacity: 1,
      letterSpacing: "0.019rem",
      marginTop: "0.5rem",
    },
    colorGreen: {
      backgroundColor: "#34C759",
    },
    colorYellow: {
      backgroundColor: "#FFCF6E",
    },
    colorRed: {
      backgroundColor: "#FC6100",
    },
  });