import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
    root: {
      position: "unset",
      "& .MuiTableHead-root": {
        position: "unset",
        color: "black",
      },
    },
    drawer: {
      "& .MuiDrawer-paper": {
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
      },
    },
    takerDrawerSearch: {
      "& input": {
       padding: '0 1.7rem !important'
      },
    }
  });

  export default useStyles;