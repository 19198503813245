const ScheduleItem = (props) => {
    const { schedule } = props;
    const { name } = schedule;
    return (
        schedule.vendorProductGroups.length ? (
            <div className="schedule-item">
                <p className='schedule-name'>
                    {name}
                </p>
                {schedule.vendorProductGroups?.length ? (
                    schedule.vendorProductGroups.map(volumeBreak => (
                        <ScheduleVolumeBreak key={volumeBreak.id} volumeBreak={volumeBreak} />
                    ))
                ) : (
                    <ScheduleVolumeBreak volumeBreak={schedule.vendorProductGroups} />
                )}
            </div>
        ) : null
    )
}

const priceTypes = {
    "PRODUCT_VENDOR_PRICE": "Last Cost",
    "MOVING_AVG_COST": "Moving Avg Cost",
    "PRODUCT_WHOLESALE_PRICE": "Wholesale Price",
};

const ScheduleVolumeBreak = (props) => {
    const { volumeBreak } = props;
    const { breakQty, markupMultiplier, vpgComputePriceType, description } = volumeBreak;
    // const markupInPercentage = parseInt(((parseFloat(markupMultiplier) - 1) * 100).toFixed());
    const priceType = priceTypes[vpgComputePriceType];

    return (
        <div className="schedule-volume-break">
            <p>{breakQty ? breakQty : '-'}</p>
            <p>{markupMultiplier}</p>
            <p title={priceType}>{priceType}</p>
            <p title={description}>{description}</p>
        </div>
    )
}

export default ScheduleItem;