import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "@fontsource/inter";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { Container } from "@mui/material";
import _ from "lodash";

import {
  PosImage,
  PosPageHeading,
  PosInput,
  PosIconButton,
  PosButton,
  PosSnackbar,
  PosHelmet,
} from "apps/pos/Common/uiCore";

import images from "constants/images";
import {
  ArrowBackRoundedIcon,
  CheckCircleOutlineOutlinedIcon,
} from "constants/icons";
import {
  PickedQtyWithLocationProps,
  PickReplaceProps,
  ReturnFlowDataMainProps,
} from "constants/interfaces";
import { numberOnlyRegex, numbersOnlyRegEx, title } from "constants/constant";
import { PosResponseProps, RootState } from "models";
import { Paths } from "enums";
import { getLocalStoraGeItem, showScrollCartPage } from "utils/utils";
import { openAndPrintPdf } from "utils/helper";

import {
  getCompleteReturnPayloadData,
  pickReplaceCartPrintClear,
  pickReplaceCartPrintRequest,
  returnProductsOrdersClear,
  returnProductsOrdersRequest,
  returnProductsPickingRequest,
  searchReturnInvoiceClear,
} from "sagas/returns/actions";
import {
  handlingFeeDataClearData,
  restockingFeeDataClearData,
  setReturnFlowData,
  setTaxMessage,
  shippingFeeDataClearData,
} from "sagas/persistReducer/actions";

import "apps/pos/neworder.scss";
import "apps/pos/PickQuantity.scss";

const PickReplaceQuantity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isReturnProductsOrdersLoading,
    returnProductsPickingData,
    pickReplaceCartPrint,
    pickReplaceCartPrintSuccess,
    returnCartSubmitSuccess,
    pickReplaceCartPrintLoading,
    returnProductsOrdersData,
    isReturnProductsPickingLoading,
  } = useSelector<RootState, PickReplaceProps>((state) => state.returnReducer);

  const {
    returnFlowData,
    shippingFeeData,
    restockingFeeData,
    handlingFeeValue,
  } = useSelector<RootState, ReturnFlowDataMainProps>(
    (state) => state.persistReducer
  );

  const { getRowsActiveDetails } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );

  const [isSnackbarQuantity, setIsSnackbarQuantity] = useState(false);
  const [returnProductsPickingDataBE, setReturnProductsPickingDataBE] =
    useState([]);
  const [notEqualQty, setNotEqualQty] = useState(false);
  const [morePickedQty, setMorePickedQty] = useState(false);
  const [pickReplaceQtyArrFinal, setPickReplaceQtyArrFinal] = useState([]);
  const [sameLocationMoreQty, setSameLocationMoreQty] = useState(false);

  const currentWorkspace = getLocalStoraGeItem("workspace");

  function getUnique(array, key) {
    if (typeof key !== "function") {
      const property = key;
      key = function (item) {
        return item[property];
      };
    }
    return Array?.from(
      array &&
        array
          ?.reduce(function (map, item) {
            const keyInfo = key(item);
            if (!map.has(keyInfo)) map.set(keyInfo, item);
            return map;
          }, new Map())
          .values()
    );
  }
  const dataWithReplaceQty = returnFlowData?.addedReturnedProduct?.filter(
    (item) => Number(item?.replaceQty) > 0
  );

  const pickingPassId =
    dataWithReplaceQty && getUnique(dataWithReplaceQty, "productId");

  useEffect(() => {
    if (
      !returnFlowData?.addedReturnedProduct?.length ||
      !location?.state?.detail
    ) {
      dispatch(returnProductsOrdersClear());
      dispatch(shippingFeeDataClearData());
      dispatch(restockingFeeDataClearData());
      dispatch(handlingFeeDataClearData());
      dispatch(searchReturnInvoiceClear());
      dispatch(
        setReturnFlowData({
          addedReturnedProduct: [],
          returnCartData: {},
        })
      );
      navigate(Paths.posNewOrder);
    }
  }, []);

  useEffect(() => {
    if (returnProductsPickingData?.length) {
      setReturnProductsPickingDataBE(returnProductsPickingData);
    }
  }, [returnProductsPickingData]);

  const fianlPickReplaceShowData = dataWithReplaceQty?.filter((col) => {
    return returnProductsPickingDataBE?.find(
      (selected) =>
        selected?.productName === col?.productName &&
        selected?.productId === col?.productId
    );
  });

  useEffect(() => {
    if (fianlPickReplaceShowData) {
      setPickReplaceQtyArrFinal(fianlPickReplaceShowData);
    }
  }, [returnProductsPickingDataBE]);

  fianlPickReplaceShowData?.map((obj) => {
    const hasPickQtyLocation = returnProductsPickingDataBE?.find((nums) => {
      const { productId: returnProductId, productName: returnProductName } =
        nums;
      const { productName, productId } = obj;
      return returnProductId == productId && returnProductName == productName;
    });

    if (hasPickQtyLocation) {
      const {
        locations,
        manufacturerCode,
        manufacturerName,
        productId,
        productName,
        stockWarehouseID,
      } = hasPickQtyLocation;
      const locationData = locations?.map((item) => {
        const {
          stockRowID,
          stockUnitID,
          stockShelfID,
          unitQtyAvailable,
          pickedQty,
          rowName,
          shelfName,
          unitName,
        } = item;
        return {
          stockWarehouseId: stockWarehouseID,
          productId,
          manufacturerId: obj?.manufacturerId,
          stockRowId: stockRowID,
          stockUnitId: stockUnitID,
          stockShelfId: stockShelfID,
          availableQty: unitQtyAvailable,
          pickedQty,
          unitName,
          rowName,
          shelfName,
          fullLocationName: `${rowName}-${unitName}-${shelfName}`,
        };
      });
      obj.pickedQtyWithLocation = {
        locations: locationData,
        manufacturerCode: manufacturerCode,
        manufacturerName: manufacturerName,
        productId: productId,
        productName: productName,
        stockWarehouseId: stockWarehouseID,
      };
      return obj;
    } else {
      return obj;
    }
  });

  useEffect(() => {
    const returnProductIdArr = pickingPassId?.map(
      (item: PickedQtyWithLocationProps) => item.productId
    );
    dispatch(
      returnProductsPickingRequest(
        returnProductIdArr,
        currentWorkspace?.stockWarehouseId
      )
    );
  }, [returnFlowData?.addedReturnedProduct]);

  const { ticket, defaultIcon } = images;

  const location: any = useLocation();
  const returnAllProcessData = location?.state?.detail;

  _.forEach(returnAllProcessData, (value, key) => {
    value.map((obj) => {
      const hasPickQtyLocation = returnProductsPickingDataBE.find(
        (nums) =>
          nums?.productId == obj?.productId &&
          nums?.productName == obj?.productName &&
          Number(obj?.orderId) === Number(key)
      );

      if (hasPickQtyLocation && Number(obj?.orderId) === Number(key)) {
        const {
          locations,
          manufacturerCode,
          manufacturerName,
          productId,
          productName,
          stockWarehouseID,
        } = hasPickQtyLocation;
        const locationData = locations?.map((item) => {
          const {
            stockWarehouseID,
            stockRowID,
            stockUnitID,
            stockShelfID,
            unitQtyAvailable,
            pickedQty,
          } = item;
          return {
            stockWarehouseId: stockWarehouseID,
            productId: productId,
            manufacturerId: obj?.manufacturerId,
            stockRowId: stockRowID,
            stockUnitId: stockUnitID,
            stockShelfId: stockShelfID,
            unitQtyAvailable: unitQtyAvailable,
            pickedQty: pickedQty,
          };
        });
        obj.pickedQtyWithLocation = {
          locations: locationData,
          manufacturerCode: manufacturerCode,
          manufacturerName: manufacturerName,
          productId: productId,
          productName: productName,
          stockWarehouseID: stockWarehouseID,
        };
        return obj;
      } else {
        return obj;
      }
    });
  });

  const onChangePickQty = (index, id, value) => {
    const copyData = [...pickReplaceQtyArrFinal];
    const data = copyData[index]?.pickedQtyWithLocation?.locations?.map(
      (item, index1) => {
        if (index1 === id) {
          return {
            ...item,
            pickedQty: value,
          };
        }
        return item;
      }
    );
    const dataFinal = copyData?.map((copy, index2) => {
      if (index === index2) {
        const totalPickedValue = data.reduce((total, obj) => {
          return Number(obj.pickedQty ?? 0) + Number(total ?? 0);
        }, 0);

        return {
          ...copy,
          totalPickedValue: Number(totalPickedValue).toFixed(2),
          pickedQtyWithLocation: {
            ...copy?.pickedQtyWithLocation,
            locations: data,
          },
        };
      }
      return copy;
    });

    setPickReplaceQtyArrFinal(dataFinal);
  };

  const currentPathUrl = window.location.href;
  const currentTaker = getLocalStoraGeItem("taker");
  const currentReturnTaker = getLocalStoraGeItem("returnTaker");

  const completeReturn = () => {
    const returnOrdersDetails = [];

    _.forEach(returnAllProcessData, (value, key) => {
      const totalReturnQuantity = value.reduce(
        (total, obj) => Number(obj.returnQty) + Number(total),
        0
      );
      returnOrdersDetails.push({
        orderID: Number(key),
        customerId: value?.[0]?.customerId,
        stockWarehouseId: currentWorkspace?.stockWarehouseId,
        takerID: currentReturnTaker?.personId ?? currentTaker?.personId,
        shipCredit: !totalReturnQuantity
          ? 0
          : Number(
              shippingFeeData?.find(
                (item) => Number(item?.orderId) === Number(key)
              )?.shippingFeeChange
            )
          ? Number(
              shippingFeeData?.find(
                (item) => Number(item?.orderId) === Number(key)
              )?.shippingFeeChange
            )
          : 0,
        restockFee: !totalReturnQuantity
          ? 0
          : Number(
              restockingFeeData?.length &&
                restockingFeeData?.find(
                  (item) => Number(item?.orderId) === Number(key)
                )?.restockingFee
            )
          ? Number(
              restockingFeeData?.length &&
                restockingFeeData?.find(
                  (item) => Number(item?.orderId) === Number(key)
                )?.restockingFee
            )
          : 0,
        handlingFee: !totalReturnQuantity
          ? 0
          : Number(
              handlingFeeValue?.length &&
                handlingFeeValue?.find(
                  (item) => Number(item?.orderId) === Number(key)
                )?.handlingFee
            )
          ? Number(
              handlingFeeValue?.length &&
                handlingFeeValue?.find(
                  (item) => Number(item?.orderId) === Number(key)
                )?.handlingFee
            )
          : 0,
        orderRmaId: 0,
        returnItems: value?.map((item) => {
          const hasPickQtyLocationFinal = pickReplaceQtyArrFinal.find(
            (nums) =>
              nums?.productId === item?.productId &&
              nums?.productName === item?.productName &&
              Number(item?.lineId) === Number(nums?.lineId)
          );

          const { pickedQtyWithLocation } = hasPickQtyLocationFinal || {};
          const {
            lineId,
            returnQty,
            replaceQty,
            reason,
            manufacturerReturnPolicy,
            returnTo,
            productId,
            additionalQty,
            activeFlag
          } = item || {};
          return {
            activeFlag,
            productID: productId,
            lineID: lineId,
            returnQty: Number(returnQty),
            itemPrice: Number(item?.unitPrice),
            replaceQty: Number(replaceQty) ?? 0,
            additionalQty: additionalQty ? Number(additionalQty) : 0,
            rmaReasonID: Number(reason) ?? null,
            mfrReturnPolicy: manufacturerReturnPolicy,
            returnReceiptLocations: returnTo?.map((data) => {
              const selectedSaleFlag = getRowsActiveDetails?.find((item) => {
                return item?.rowID === data?.row.id;
              });

              return {
                stockRowID: data?.row.id,
                stockUnitID: data?.unit.id,
                stockShelfID: data?.shelf.id,
                qtyToReturn: Number(data?.returnQty) + Number(data?.replaceQty),
                notForSell: selectedSaleFlag?.notForSellFlag ?? 0,
              };
            }),
            replacePickupLocations: pickedQtyWithLocation?.locations
              ?.map((location) => {
                const { ...productWithLocationData } = location || {};
                return productWithLocationData;
              })
              .filter((i) => i.pickedQty),
            replaceWithoutPickupLocations:
              pickedQtyWithLocation?.locations?.map((location) => {
                const { ...productWithLocationData } = location || {};
                return productWithLocationData;
              }),
          };
        }),
      });
    });

    let productTotal = [];
    returnOrdersDetails?.length > 0 &&
      returnOrdersDetails.forEach((item) => {
        item.returnItems?.length > 0 &&
          item.returnItems.forEach((obj) => {
            if (obj?.replacePickupLocations?.length) {
              productTotal = [
                ...productTotal,
                ...obj?.replacePickupLocations?.map((data) => {
                  return {
                    ...data,
                    totalAvailibility:
                      obj?.replaceWithoutPickupLocations?.reduce(
                        (total, obj) =>
                          Number(obj?.unitQtyAvailable) + Number(total),
                        0
                      ),
                  };
                }),
              ];
            }
          });
      });

    const pickedAllQtytotals = [];
    productTotal.forEach((info) => {
      const obj = pickedAllQtytotals.find(
        (data) => data.productId === info.productId
      );
      if (obj) {
        obj.pickedQty = obj.pickedQty + info.pickedQty;
      } else {
        pickedAllQtytotals.push(info);
      }
    });

    const moreDataPicked = pickedAllQtytotals?.some(
      (item) => item?.pickedQty > item?.totalAvailibility
    );

    const sumOfAllPickedQty = returnOrdersDetails?.map((item) =>
      item?.returnItems?.map((obj) => {
        const totalPickQuantity = obj?.replacePickupLocations?.reduce(
          (total, obj) => Number(obj?.pickedQty) + Number(total),
          0
        );
        return totalPickQuantity > obj?.replaceQty;
      })
    );
    const checkInForProducts = sumOfAllPickedQty?.map((item) =>
      item?.includes(true)
    );
    const CheckInAll = checkInForProducts?.includes(true);

    const finalData = returnOrdersDetails?.map((item) => {
      return {
        ...item,
        returnItems: item?.returnItems?.map((returnItem) => {
          const { ...finalReturnDetailOrder } = returnItem;
          return finalReturnDetailOrder;
        }),
      };
    });

    const check = returnOrdersDetails
      ?.map((item) => {
        return item?.returnItems;
      })
      .flat();

    // start
    const sameReturnProductData = _.groupBy(check, "productID");
    const finalDetails = [];
    _.forEach(sameReturnProductData, (value) => {
      finalDetails.push(
        value
          ?.map((item) => {
            return item?.replaceWithoutPickupLocations
              ?.map((location) => {
                return location;
              })
              .filter((i) => i.pickedQty);
          })
          .flat()
      );
    });

    const pickedSameQty = finalDetails?.map((info) => {
      const finalPickedQtyOutput = [];
      const uniqueName = Array.from(
        new Set(info.map((x) => x?.fullLocationName))
      );

      uniqueName.forEach((n) => {
        finalPickedQtyOutput.push(
          info
            .filter((x) => x?.fullLocationName === n)
            .reduce((a, item) => {
              if (item?.pickedQty) {
                const val = a["fullLocationName"]
                  ? item?.pickedQty
                  : a["pickedQty"] + item?.pickedQty;
                return { ...item, fullLocationName: n, pickedQty: val };
              }
            }, {})
        );
      });

      return finalPickedQtyOutput;
    });

    const isSameQtyCheck = pickedSameQty?.map((data) => {
      return data?.map((info) => {
        if (info?.pickedQty > info?.unitQtyAvailable) {
          return true;
        }
        return false;
      });
    });

    const checkSameQtyPicked = isSameQtyCheck?.map((uniqueId) => {
      return uniqueId?.some((item) => {
        return item === true;
      });
    });

    const finalIsPickedSame = checkSameQtyPicked.some((item) => {
      return item === true;
    });
    // end

    const returnProcessData = {
      requestUrl: currentPathUrl,
      returnOrders: finalData,
    };

    if (finalIsPickedSame) {
      setSameLocationMoreQty(true);
      setTimeout(() => {
        setSameLocationMoreQty(false);
      }, 5000);
    } else if (moreDataPicked) {
      setMorePickedQty(true);
    } else if (CheckInAll && returnProductsPickingDataBE?.length) {
      setNotEqualQty(true);
    } else {
      dispatch(getCompleteReturnPayloadData(returnAllProcessData));
      dispatch(
        returnProductsOrdersRequest(
          returnProcessData,
          currentWorkspace?.stockWarehouseId
        )
      );
      dispatch(setTaxMessage(true))
    }
  };

  const completeReturnOutOfStock = () => {
    const returnOrdersDetails = [];
    _.forEach(returnAllProcessData, (value, key) => {
      const totalReturnQuantity = value.reduce(
        (total, obj) => Number(obj.returnQty) + Number(total),
        0
      );

      returnOrdersDetails.push({
        orderID: Number(key),
        customerId: value?.[0]?.customerId,
        stockWarehouseId: currentWorkspace?.stockWarehouseId,
        takerID: currentReturnTaker?.personId ?? currentTaker?.personId,
        shipCredit: !totalReturnQuantity
          ? 0
          : Number(
              shippingFeeData?.find(
                (item) => Number(item?.orderId) === Number(key)
              )?.shippingFeeChange
            )
          ? Number(
              shippingFeeData?.find(
                (item) => Number(item?.orderId) === Number(key)
              )?.shippingFeeChange
            )
          : 0,
        restockFee: !totalReturnQuantity
          ? 0
          : Number(
              restockingFeeData?.length &&
                restockingFeeData?.find(
                  (item) => Number(item?.orderId) === Number(key)
                )?.restockingFee
            )
          ? Number(
              restockingFeeData?.length &&
                restockingFeeData?.find(
                  (item) => Number(item?.orderId) === Number(key)
                )?.restockingFee
            )
          : 0,
        handlingFee: !totalReturnQuantity
          ? 0
          : Number(
              handlingFeeValue?.length &&
                handlingFeeValue?.find(
                  (item) => Number(item?.orderId) === Number(key)
                )?.handlingFee
            )
          ? Number(
              handlingFeeValue?.length &&
                handlingFeeValue?.find(
                  (item) => Number(item?.orderId) === Number(key)
                )?.handlingFee
            )
          : 0,
        orderRmaId: 0,
        returnItems: value?.map((item) => {
          const {
            lineId,
            returnQty,
            replaceQty,
            reason,
            manufacturerReturnPolicy,
            returnTo,
            productId,
            activeFlag
          } = item || {};
          return {
            activeFlag,
            productID: productId,
            lineID: lineId,
            returnQty: Number(returnQty),
            itemPrice: Number(item?.unitPrice),
            replaceQty: Number(replaceQty) ?? 0,
            additionalQty: item?.additionalQty
              ? Number(item?.additionalQty)
              : 0,
            rmaReasonID: Number(reason) ?? null,
            mfrReturnPolicy: manufacturerReturnPolicy,
            returnReceiptLocations: returnTo?.map((data) => {
              const selectedSaleFlag = getRowsActiveDetails?.find((item) => {
                return item?.rowID === data?.row.id;
              });

              return {
                stockRowID: data?.row.id,
                stockUnitID: data?.unit.id,
                stockShelfID: data?.shelf.id,
                qtyToReturn: Number(data?.returnQty) + Number(data?.replaceQty),
                notForSell: selectedSaleFlag?.notForSellFlag ?? 0,
              };
            }),
            replacePickupLocations: [],
          };
        }),
      });
    });

    const returnProcessData = {
      requestUrl: currentPathUrl,
      returnOrders: returnOrdersDetails,
    };

    dispatch(
      returnProductsOrdersRequest(
        returnProcessData,
        currentWorkspace?.stockWarehouseId
      )
    );
  };

  useEffect(() => {
    if (returnCartSubmitSuccess) {
      const data = {
        ...returnFlowData,
        returnOrderCompleteData: returnProductsOrdersData?.result,
      };
      dispatch(setReturnFlowData(data));
      dispatch(returnProductsOrdersClear());
      dispatch(shippingFeeDataClearData());
      dispatch(restockingFeeDataClearData());
      dispatch(handlingFeeDataClearData());
      returnFlowData?.addedReturnedProduct?.length &&
        returnFlowData?.addedReturnedProduct?.pop();
      dispatch(
        setReturnFlowData({
          addedReturnedProduct: returnFlowData?.addedReturnedProduct,
          returnCartData: {},
        })
      );
      dispatch(searchReturnInvoiceClear());
      navigate(Paths.posReturnComplete);
    }
  }, [returnCartSubmitSuccess]);

  const getCustomer = (data) => {
    if (!data) return;
    if (!Object.values(data)) return;
    if (!Object.values(data)[0]) return;
    if (!Object.values(data)[0][0]) return;
    return Object.values(data)[0][0];
  };

  const pickReplacePrintInvoice = () => {
    let pickReplacePrintData = {};
    const customer = getCustomer(returnAllProcessData);
    if (!customer) return;
    const cartID = Object.keys(returnAllProcessData)[0];
    // required any here
    const productsArray: any = [].concat(...Object.values(returnAllProcessData));
    pickReplacePrintData = {
      cartId: Number(cartID),
      customerId: customer?.customerId,
      stockWarehouseId: currentWorkspace?.stockWarehouseId,
      stockWarehouseName: currentWorkspace?.warehouseName,
      purchaseOrderNum: cartID,
      customerNote: "",
      products: productsArray?.map((item) => {
        const {
          productId,
          productName,
          manufacturerCode,
          manufacturerName,
          manufacturerId,
          replaceQty,
          itemType
        } = item || {};
        return {
          productId,
          productName,
          manufacturerCode,
          manufacturerName,
          manufacturerId,
          qtyOrdered: replaceQty,
          itemType
          //Might need later
          // unitQuantityAvailable: availableQty,
        };
      }),
    };
    dispatch(pickReplaceCartPrintRequest(pickReplacePrintData));
  };

  useEffect(() => {
    if (pickReplaceCartPrintSuccess) {
      dispatch(pickReplaceCartPrintClear());
      openAndPrintPdf(pickReplaceCartPrint?.data);
    }
  }, [pickReplaceCartPrintSuccess]);

  const finalPickedQtyOnchange = (event, index, id, location) => {
    if (
      !numbersOnlyRegEx.test(
        event.target.value || event.target.value.charAt(0) == "0"
      )
    ) {
      event.target.value = "";
      onChangePickQty(index, id, "");
    } else if (Number(event.target.value) > location.availableQty) {
      onChangePickQty(index, id, location.availableQty);
    } else {
      onChangePickQty(index, id, Number(event.target.value));
    }
  };

  const [height, setHeight] = useState(0);

  const observedDiv = useRef(null);

  const handleElementResized = () => {
    if (observedDiv?.current?.offsetHeight !== null) {
      if (
        observedDiv &&
        observedDiv?.current &&
        observedDiv?.current?.offsetHeight !== height
      ) {
        setHeight(observedDiv?.current?.offsetHeight);
      }
    }
  };

  const resizeObserver = new ResizeObserver(handleElementResized);

  useEffect(() => {
    resizeObserver?.observe(observedDiv?.current);

    return function cleanup() {
      resizeObserver?.disconnect();
    };
  });

  const staticPickingReplaceHeader = document.querySelector(
    ".static-picking-replace-header"
  );

  if (staticPickingReplaceHeader) {
    const pickReplaceQtyFinal = document.querySelector(
      "#picking-info-table-sr"
    );
    if (pickReplaceQtyFinal) {
      pickReplaceQtyFinal[
        "style"
      ].marginTop = `${staticPickingReplaceHeader.clientHeight}px`;
    }
  }

  const useHeightStyles = makeStyles({
    fixedPickedHeader: {
      top: 0
      //might need later
      // "@media (min-width: 960px)": {
      //   top: `calc(${height}px + 40px)`,
      // },
    },
    mainContentTopSpacing: {
      marginTop: "20px",
      overflow: "scroll",
      height: "600px"
      //might need later
      // "@media (min-width: 960px)": {
      //   marginTop: `${staticPickingReplaceHeader?.clientHeight}px`,
      // },
    },
  });

  const pickStickyInfoClasses = useHeightStyles();

  return (
    <>
      <PosHelmet
        title="Simplified Return Replace"
        metaName="Return Replace page"
        content="Return Replace page"
      />
        <PosSnackbar
          open={sameLocationMoreQty}
          message={title.sameLocationQty}
          onClose={() => {
            setSameLocationMoreQty(false);
          }}
        />
        <PosSnackbar
          open={morePickedQty}
          message={title.noMorePickedQty}
          onClose={() => {
            setMorePickedQty(false);
          }}
        />
        <PosSnackbar
          open={notEqualQty}
          message={title.equalReplacePickQty}
          onClose={() => {
            setNotEqualQty(false);
          }}
        />
        <PosSnackbar
          open={isSnackbarQuantity}
          message="Picked quantity can not be more than ordered quantity"
          onClose={() => {
            setIsSnackbarQuantity(false);
          }}
        />
      <div className="new-container pick-replace-quantity-container">
        <section className="pick-replace-quantity-main-section pick-quantity-main-section">
          <div ref={observedDiv} className="static-picking-replace-header">
            <Container className="new-container pick-qty-container pick-qty-header-container">
              <div className="pick-replace-quantity-heading">
                <PosIconButton
                  className="btn back-next-btn-small-left return-picking-qty-back-btn pos-icon-back-btn"
                  onClick={() => {
                    showScrollCartPage();
                    navigate(-1);
                  }}
                >
                  <ArrowBackRoundedIcon />
                </PosIconButton>
                <PosPageHeading
                  heading="Set Replacement Picking Info."
                  blackHeading
                />
                <div className="pick-replace-quantity-right-btn-div pick-quantity-right-btn-div">
                  <PosButton
                    onClick={pickReplacePrintInvoice}
                    disabled={
                      pickReplaceCartPrintLoading ||
                      isReturnProductsPickingLoading
                    }
                    variant="contained"
                    color="secondary"
                    className={`pick-replace-quantity-btn ${
                      pickReplaceCartPrintLoading ? "button-is-loading" : ""
                    }`}
                  >
                    {!pickReplaceCartPrintLoading ? (
                      <>
                        <PosImage src={ticket} alt="ticket" />
                        <span className="icon-btn-right-spacing">
                          Print Pick Ticket
                        </span>
                      </>
                    ) : null}
                  </PosButton>
                </div>
              </div>
              <div className="pick-table-title-section">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container className="">
                    <Grid xs={12} sm={4} md={1} lg={1}>
                      <div className="pick-replace-table-header pick-info-table-header">
                        <span className="title">LINE #</span>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      md={4}
                      lg={4}
                      className="pick-replace-item-name"
                    >
                      <div className="pick-replace-table-header">
                        <span className="title">ITEM</span>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={3} md={1} lg={1}>
                      <div className="pick-replace-table-header text-center">
                        <span className="title">REPLACEMENT QTY.</span>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={3} md={1} lg={1}>
                      <div className="pick-replace-table-header text-center">
                        <span className="title">TOTAL PICKED</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={5}>
                      <Grid container className="">
                        <Grid item xs={4}>
                          <div className="pick-replace-table-header text-center">
                            <span className="title">LOCATION</span>
                          </div>
                        </Grid>
                        <Grid item className="available-qty-title" xs={4}>
                          <div className="pick-replace-table-header text-center">
                            <span className="title">AVAILABLE QTY.</span>
                          </div>
                        </Grid>
                        <Grid item className="picked-qty-title" xs={4}>
                          <div className="pick-replace-table-header text-center">
                            <span className="title">PICKED QTY.</span>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Container>
          </div>
          {isReturnProductsPickingLoading ? null : returnProductsPickingDataBE?.length ? (
            <div
              id="pick-replace-qty-final"
              className={`left-padding ${pickStickyInfoClasses.mainContentTopSpacing}`}
            >
              <Container className="new-container pick-qty-container">
                <div className="picking-info-table">
                  {pickReplaceQtyArrFinal?.length ? (
                    pickReplaceQtyArrFinal?.map((product, index) => {
                      return (
                        <>
                          <Box
                            sx={{ flexGrow: 1 }}
                            key={`${product?.lineId}${index}`}
                            className="pick-replace-content"
                          >
                            <Grid container className="">
                              <Grid
                                item
                                xs={12}
                                sm={3}
                                md={1}
                                lg={1}
                                className={`sticky-header-style ${pickStickyInfoClasses.fixedPickedHeader} pick-content-spacing d-flex pick-product-line-id`}
                              >
                                <div className="left-padding pick-responsive-title pick-id-responsive-title line-id" >
                                  <span>LINE #</span>
                                </div>
                                <div className="pick-replace-table-header text-center pick-id-responsive-title line-id">
                                  <span className="title">
                                    {product?.lineId}
                                  </span>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={5}
                                md={4}
                                lg={4}
                                className={`pick-replace-item-name left-padding sticky-header-style 
                                ${pickStickyInfoClasses.fixedPickedHeader} 
                                pick-content-spacing d-flex`}
                              >
                                <div className="pick-replace-table-header pick-replace-product-img">
                                  <div className={`text-center user-avatar`}>
                                    <PosImage
                                      src={defaultIcon}
                                      className="search-list-img"
                                      alt="product"
                                    />
                                  </div>
                                  <div className="image-bottom-vertical-line">
                                    <span></span>
                                  </div>
                                </div>
                                <div className="pick-replace-table-header">
                                  <div className="column-info">
                                    <div className="btn-text-design">
                                      <span className="picking-product-name">
                                        {product.productName}
                                      </span>
                                      <span className="picking-manufacturer-name">
                                        {product.manufacturerCode}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Grid>

                              <Grid
                                item
                                xs={6}
                                sm={4}
                                md={1}
                                lg={1}
                                className={`sticky-header-style 
                            ${pickStickyInfoClasses.fixedPickedHeader} 
                            pick-content-spacing d-flex text-center pick-order-content-spacing pick-ordered-qty-content-spacing`}
                              >
                                <div className="pick-responsive-title">
                                  <span>REPLACEMENT QTY:</span>
                                </div>
                                <div className="pick-replace-table-header text-center">
                                  <span className="replace-qty">
                                    {product?.replaceQty}
                                  </span>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                md={1}
                                lg={1}
                                className={`sticky-header-style 
                                  ${pickStickyInfoClasses.fixedPickedHeader} 
                                  pick-content-spacing d-flex text-center pick-order-content-spacing`}
                              >
                                <div className="pick-responsive-title">
                                  <span>TOTAL PICKED:</span>
                                </div>
                                <div className="pick-replace-table-header text-center">
                                  <span
                                    className={`${
                                      product?.totalPickedValue === undefined ||
                                      0
                                        ? "light-text"
                                        : "text-black"
                                    } picked-replace-qty`}
                                  >
                                    {Number(product?.totalPickedValue ?? 0)}
                                  </span>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={8}
                                md={5}
                                lg={5}
                                className="picking-info-table pick-content-spacing picking-location-table picked-qty-location-title"
                              >
                                <Grid
                                  container
                                  className="pl-0 pr-0 pick-responsive-title-grid"
                                >
                                  <Grid item xs={4}>
                                    <div className="pick-responsive-title text-center pick-location-responsive-title">
                                      <span>LOCATION</span>
                                    </div>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <div className="pick-responsive-title text-center pick-location-responsive-title">
                                      <span>AVAILABLE QTY.</span>
                                    </div>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <div className="pick-responsive-title text-center pick-location-responsive-title">
                                      <span>PICKED QTY.</span>
                                    </div>
                                  </Grid>
                                </Grid>
                                {product?.pickedQtyWithLocation?.locations?.map(
                                  (location, id) => {
                                    const fullLocationName = `${location?.rowName}-${location?.unitName}-${location?.shelfName}`;
                                    return (
                                      <Grid
                                        container
                                        className="table-info-last-cell pl-0 pr-0"
                                        key={id}
                                      >
                                        <Grid item className="picking-qty-location" xs={4}>
                                          <div className="column-info text-center">
                                            <div className="btn-text-design  pick-cell-table">
                                              <span className="int-16-reg ">
                                                {fullLocationName}
                                              </span>
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item className="available-qty-title" xs={4}>
                                          <div className="column-info text-center">
                                            <div className="btn-text-design pick-cell-table">
                                              <span className="int-16-reg">
                                                {location.availableQty}
                                              </span>
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <div className="column-info text-center replace-pick-qty">
                                            <div className="btn-text-design pick-cell-table text-center">
                                              <PosInput
                                                name="qtyPicked"
                                                variant="standard"
                                                id="standard-basic"
                                                InputProps={{
                                                  disableUnderline: true,
                                                }}
                                                inputProps={{
                                                  maxLength: 8,
                                                  pattern: "d{8}",
                                                }}
                                                placeholder="Enter Qty"
                                                className="pick-table-input pick-cell-table text-center pick-cell-table-input text-center"
                                                value={
                                                  product?.pickedQtyWithLocation
                                                    ?.locations[id].pickedQty
                                                }
                                                // need this comment for only number input
                                                onKeyPress={(event) => {
                                                  if (
                                                    !numberOnlyRegex.test(
                                                      event.key
                                                    )
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onChange={(e) =>
                                                  finalPickedQtyOnchange(
                                                    e,
                                                    index,
                                                    id,
                                                    location
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    );
                                  }
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </Container>
            </div>
          ) : (
            <div className="mt-5">
              <span className="font-weight-600">{title.noPickingData}</span>
            </div>
          )}
          <div className="total-refund-cash-section">
            <Container className="new-container pick-qty-container">
              <div className="d-flex pick-replace-quantity-right-btn-div pick-replace-quantity-complete-btn-div">
                <PosButton
                  onClick={() =>
                    returnProductsPickingDataBE?.length
                      ? completeReturn()
                      : completeReturnOutOfStock()
                  }
                  disabled={
                    isReturnProductsOrdersLoading ||
                    isReturnProductsPickingLoading
                  }
                  variant="contained"
                  color="primary"
                  className={`pick-replace-quantity-btn complete-return-btn return-set-picking-btn ${
                    isReturnProductsOrdersLoading
                      ? "button-accent button-is-loading-black"
                      : ""
                  }`}
                  startIcon={
                    !isReturnProductsOrdersLoading ? (
                      <CheckCircleOutlineOutlinedIcon />
                    ) : null
                  }
                >
                  {!isReturnProductsOrdersLoading ? "Complete Return" : null}
                </PosButton>
              </div>
            </Container>
          </div>
        </section>
      </div>
    </>
  );
};

export default PickReplaceQuantity;
