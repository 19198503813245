import { MouseEvent } from "react";
import styled from "styled-components";
import dayjs from "dayjs";

import "../SpringBoardCommonStyling.scss";
import { statusDetail } from "constants/constant";
import { dateEnum } from "constants/staticData";

export interface OpenQuoteItemProps {
  selectedMainTab: number;
  quoteId: number;
  jobName: string;
  bgColor: string;
  creator: string;
  status: number;
  dateModified: string;
  sentDate: string | null;
  dueDate: string | null;
  expiredDate: string | null;
  company: string;
  onClick: (event: MouseEvent) => void;
}

export const OpenQuote = styled.div`
  &:hover {
    cursor: pointer;
  }
`;


export const OpenQuoteItem = ({
  selectedMainTab,
  quoteId,
  jobName,
  creator,
  status,
  bgColor,
  dateModified,
  sentDate,
  expiredDate,
  company,
  onClick,
}: OpenQuoteItemProps) => {
  
  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const quoteID = window.location.pathname.split("/")[5];
  const selectedQuoteId = (Number(quoteID) === Number(quoteId))
  return (
    <OpenQuote
      className={`d-flex border-bottom align-items-start position-relative inboxlistsItem flex-column quotes-left-side-info-sec ${selectedQuoteId ? "selected-quote-id" : ""}`}
      style={{ padding: "padding: 11px 0" }}
      onClick={(e) => onClick(e)}
    >
      <div className="position-relative d-flex justify-content-between w-100 quote-id-name-sec">
        <div>
          <span className="text-black fw-semibold fs-12 mb-0 open-quotes-id">
            {quoteId}
          </span>
          <span className="mb-1 text-black fs-14 mb-0">
            {jobName.length > 25 ? jobName.substr(0, 24) + "..." : jobName}
          </span>
        </div>
        <div>
          <span className="fs-12 text-gray-500 mb-0">
            {selectedMainTab === 4
              ? expiredDate ? dayjs(expiredDate).format(dateEnum.mmmddyy) : null
              : selectedMainTab === 2
              ? sentDate ? dayjs(sentDate).format(dateEnum.mmmddyy) : null
              : dateModified ? dayjs(dateModified).format(dateEnum.mmmddyy): null}
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center w-100">
        <div
          className="special-tooltip"
          style={{
            backgroundColor: bgColor,
          }}
        >
          <div className="tooltiptext">{statusDetail[status].value}</div>
        </div>
        <span className="fs-12 mb-0 d-flex align-items-center creator-info-sec">
          {creator ? <> <span>{creator}</span>
          <span className="quote-list-small-dot"></span> </> : null}
          <span>
            {company?.length > 20 ? company.substr(0, 19) + "..." : company}
          </span>
        </span>
      </div>
    </OpenQuote>
  );
}
