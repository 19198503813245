import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Button from "components/SPButton/Button";
import { springBoardText } from "constants/constant";
import { GlobalNumbers } from "constants/enum";

import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";
import { OpenSubQuoteItem } from "apps/springboard/Component/OpenSubQuoteItem";
import { useGetSubQuotes } from "services/quoteDetail/hooks/useGetSubQuotes";
import {
  getIsJobHighlightsLoading,
  getIsMajorRepsLoading,
  getParentQuote,
  getQuoteJobHighlights,
  getQuoteMajorReps,
} from "sagas/quoteDetail/selector";
import { distributorData } from "sagas/distributor/selector";

import "apps/springboard/QuoteDetail/QuoteDetail.scss";

interface ChildrenDetails {
  jobQuoteTitle: string;
  jobQuoteID: number;
  creatorName: string;
  createDate: string;
}

export const OpenSubQuote = ({
  jobQuoteID,
  getSubQuoteData,
  loading,
  openJobHighLight,
  handleNavigateSubQuote,
  openMajorReps
}) => {
  const [navigationStack, setNavigationStack] = useState([jobQuoteID]);
  const [currentData, setCurrentData] = useState(getSubQuoteData);
  const [childrenDetails, setChildrenDetails] = useState<ChildrenDetails>();
  const parentQuote = useSelector(getParentQuote);
  const quoteJobHighlights = useSelector(getQuoteJobHighlights);
  const isJobHighlightsLoading = useSelector(getIsJobHighlightsLoading);
  const isMajorRepsLoading = useSelector(getIsMajorRepsLoading)
  const distributor = useSelector(distributorData);
  const quoteMajorReps = useSelector(getQuoteMajorReps)

  const { jobQuoteID: parentJobQuoteID } = parentQuote || {};
  const { subQuotes, addSubQuote, parentQuoteTitle, noSubquotes, fifteen ,notSet } =
    springBoardText;
  const {
    setQuoteParamsId,
    getSubQuoteData: subQuoteData,
    isSubQuoteSuccess,
    isSubQuoteFetching,
  } = useGetSubQuotes();
  
  const majorRepsValue = quoteMajorReps
    ? quoteMajorReps.length > 20
      ? quoteMajorReps.substr(0, 19) + ".."
      : quoteMajorReps
    : notSet;
  const { httpsUrl } = distributor || {};

  useEffect(() => {
    if (isSubQuoteSuccess) {
      setCurrentData(subQuoteData);
    }
  }, [isSubQuoteSuccess]);

  const quoteHighlightLength =
    quoteJobHighlights?.length > fifteen
      ? quoteJobHighlights.substr(0, 14) + ".."
      : quoteJobHighlights;

  const handleAddSubQuote = () => {
    const url = `${httpsUrl}/gmSale/msJobLotQuote_Add_subQuote?parentJobLotQuoteID=${jobQuoteID}`;
    window.open(url, "_blank");
  };

  const handleButtonNavigateSubQuote = (id) => {
    setQuoteParamsId(id);
    setNavigationStack([...navigationStack, id]);
  };

  const handleGoBack = () => {
    const newStack = navigationStack.slice(0, navigationStack.length - 1);
    setNavigationStack(newStack);
    setQuoteParamsId(newStack[newStack.length - 1]);
  };

  const finalData =
    navigationStack.length > GlobalNumbers.ONE ? currentData : getSubQuoteData;
  const quoteData =
    navigationStack.length > GlobalNumbers.ONE ? childrenDetails : parentQuote;

  return (
    <div className="d-flex align-items-center gap-1">
      {loading ? (
        <ShapedSkeleton
          width={180}
          height={30}
          shape="0 0 180 30"
          radius={10}
        />
      ) : parentJobQuoteID === jobQuoteID && !getSubQuoteData?.length ? (
        <Button
          label={addSubQuote}
          leadingIcon="add"
          type="outline"
          size="small"
          onClick={handleAddSubQuote}
        />
      ) : (
        <div className="dropdown quote-hover">
          <Button
            label={
              Number(parentJobQuoteID) !== Number(jobQuoteID)
                ? `Subquote of ${parentJobQuoteID}`
                : `${getSubQuoteData?.length || 0} ${
                    getSubQuoteData?.length > 1 ? "Subquotes" : "Subquote"
                  }`
            }
            trailingIcon="expand_less"
            type="outline"
            size="small"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <div
            className="dropdown-menu p-4 sub-quote-container sub-quote-container"
            aria-labelledby="dropdownMenuButton"
          >
            {navigationStack.length > GlobalNumbers.ONE ? (
              <Button
                label={Number(parentJobQuoteID)}
                onClick={(e) => {
                  e.stopPropagation();
                  handleGoBack();
                }}
                leadingIcon="arrow_back"
                type="none"
                size="small"
                className="parent-quote hoverArrow"
              />
            ) : (
              <div className="fs-10 pb-1 sub-quote-container-title">
                {parentQuoteTitle}
              </div>
            )}
            <OpenSubQuoteItem
              jobName={quoteData?.jobQuoteTitle}
              quoteId={Number(quoteData?.jobQuoteID)}
              fullName={quoteData?.creatorName}
              date={quoteData?.createDate}
              status={5}
              onClick={() => {
                handleNavigateSubQuote(Number(quoteData?.jobQuoteID));
              }}
            />
            <div
              className={`fs-10 pb-1 d-flex flex-align-item-center ${
                parentJobQuoteID !== jobQuoteID ? "mt-2" : ""
              }`}
            >
              <span className="sub-quote-container-title">
                {finalData?.length > GlobalNumbers.ZERO
                  ? `${finalData?.length} ${subQuotes}`
                  : noSubquotes}
              </span>
              {navigationStack.length === GlobalNumbers.ONE && (
                <div className="ml-auto black-color">
                  <Button
                    label={addSubQuote}
                    leadingIcon="add"
                    type="outline"
                    size="small"
                    onClick={handleAddSubQuote}
                  />
                </div>
              )}
            </div>

            {isSubQuoteFetching ? (
               <ShapedSkeleton
               width={180}
               height={50}
               shape="0 0 350 10,0 20 400 10,0 40 100 10 "
               radius={10}
             />
            ) : (
              <div className="sub-quote-list">
                {finalData?.length
                  ? finalData.map((subQuote, index) => {
                      const {
                        jobLotQuoteTitle,
                        childJobLotQuoteCount,
                        jobLotQuoteID,
                        fullName,
                        createTime,
                      } = subQuote || {};

                      return (
                        <OpenSubQuoteItem
                          jobName={jobLotQuoteTitle}
                          onClick={() => {
                            handleNavigateSubQuote(jobLotQuoteID);
                          }}
                          onButtonClick={(e) => {
                            e.stopPropagation();
                            handleButtonNavigateSubQuote(jobLotQuoteID);
                            setChildrenDetails({
                              jobQuoteTitle: jobLotQuoteTitle,
                              jobQuoteID: jobLotQuoteID,
                              creatorName: fullName,
                              createDate: createTime,
                            });
                          }}
                          quoteId={jobLotQuoteID}
                          fullName={fullName}
                          date={createTime}
                          status={5}
                          key={index}
                          childrenQuote={childJobLotQuoteCount}
                        />
                      );
                    })
                  : null}
              </div>
            )}
          </div>
        </div>
      )}
      {isJobHighlightsLoading ? (
        <ShapedSkeleton
          width={180}
          height={30}
          shape="0 0 180 30"
          radius={10}
        />
      ) : quoteJobHighlights?.length ? (
        <Button
          leadingIcon="bolt"
          label={quoteHighlightLength}
          type="outline"
          size="small"
          onClick={openJobHighLight}
        />
      ) : (
        <Button
          label="Add Job Highlights"
          leadingIcon="bolt"
          type="outline"
          size="small"
          onClick={openJobHighLight}
        />
      )}
      {isMajorRepsLoading ? <ShapedSkeleton
        width={180}
        height={30}
        shape="0 0 180 30"
        radius={10}
      /> :
       quoteMajorReps.length ? (
          <Button
            leadingIcon="supervisor_account"
            label={majorRepsValue}
            type="outline"
            size="small"
            onClick={openMajorReps}
          />
        ) : (
          <Button
            label="Add Major Reps"
            leadingIcon="supervisor_account"
            type="outline"
            size="small"
            onClick={openMajorReps}
          />)
      }
    </div>
  );
};
