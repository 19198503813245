import { labels, sectionName } from "apps/product/modules/productDetailsContainer/constants";
import { FormatListBulletedIcon, InfoIcon, MonetizationOnIcon, StarIcon, QueryStatsIcon, PhotoLibraryIcon, WorkspacesIcon, SummarizeIcon, BorderColorIcon, LocalOfferIcon, SquareFootIcon , RestoreIcon , PercentIcon , AssignmentTurnedInIcon , PublicIcon } from "constants/icons"
import { Paths } from "enums";

const ProductDetailsSidebar = ({ handleClick, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12 ,selectedOption , ref13 , distributor , productId , getProductTicketsCountData , ref14}) => {
    const { Starred, Details, Measurements, CostsPrices, MetadataFlags, Reports, ImagesDocs, Accessories, Characteristics, Notes, Coupons ,Logs , RetailPriceSchedules , tickets , Overview} = labels;
    const { StarredOption, OverviewOption, DetailsOption, CostOption, MetadataOption, ReportsOption, ImagesOption, AccessoriesOption, CharacteristicsOption, NotesOption, CouponsOption, LogsOption, RetailPriceSchedulesOption, OverviewMainOption } = sectionName;
    const { numOfAllTickets , numOfOpenTickets } = getProductTicketsCountData || {}
  
    return (
        <div className='product-detail-sidebar'>
            <div className={`product-detail-sidebar-menu ${selectedOption === StarredOption ? "first-title" : ""}`} onClick={() => handleClick(ref1)}>
                <StarIcon />
                {Starred}
            </div>
            <div className={`product-detail-sidebar-menu ${selectedOption === OverviewMainOption ? "first-title" : ""}`} onClick={() => handleClick(ref14)}>
                <InfoIcon />
                {Overview}
            </div>
            <div className={`product-detail-sidebar-menu ${selectedOption === OverviewOption ? "first-title" : ""}`} onClick={() => handleClick(ref2)}>
                <FormatListBulletedIcon />
                {Details}
            </div>
            <div className={`product-detail-sidebar-menu ${selectedOption === DetailsOption ? "first-title" : ""}`} onClick={() => handleClick(ref3)}>
                <SquareFootIcon />
                {Measurements}
            </div>
            <div className={`product-detail-sidebar-menu ${selectedOption === CostOption ? "first-title" : ""}`} onClick={() => handleClick(ref4)}>
                <MonetizationOnIcon />
                {CostsPrices}
            </div>
            <div className={`product-detail-sidebar-menu ${selectedOption === MetadataOption ? "first-title" : ""}`} onClick={() => handleClick(ref5)}>
                <PublicIcon />
                {MetadataFlags}
            </div>
            <div className={`product-detail-sidebar-menu ${selectedOption === RetailPriceSchedulesOption ? "first-title" : ""}`} onClick={() => handleClick(ref13)}>
                <LocalOfferIcon />
                {RetailPriceSchedules}
            </div>
            <div className={`product-detail-sidebar-menu ${selectedOption === ReportsOption ? "first-title" : ""}`} onClick={() => handleClick(ref6)}>
                <QueryStatsIcon />
                {Reports}
            </div>
            <div className={`product-detail-sidebar-menu ${selectedOption === ImagesOption ? "first-title" : ""}`} onClick={() => handleClick(ref7)}>
                <PhotoLibraryIcon />
                {ImagesDocs}
            </div>
            <div className={`product-detail-sidebar-menu ${selectedOption === AccessoriesOption ? "first-title" : ""}`} onClick={() => handleClick(ref8)}>
                <WorkspacesIcon />
                {Accessories}
            </div>
            <div className={`product-detail-sidebar-menu ${selectedOption === CharacteristicsOption ? "first-title" : ""}`} onClick={() => handleClick(ref9)}>
                <SummarizeIcon />
                {Characteristics}
            </div>
            <div className={`product-detail-sidebar-menu ${selectedOption === NotesOption ? "first-title" : ""}`} onClick={() => handleClick(ref10)}>
                <BorderColorIcon />
                {Notes}
            </div>
            <div className={`product-detail-sidebar-menu ${selectedOption === CouponsOption ? "first-title" : ""}`} onClick={() => handleClick(ref11)}>
                <PercentIcon />
                {Coupons}
            </div>
            <div className={`product-detail-sidebar-menu ${selectedOption === LogsOption ? "first-title" : ""}`} onClick={() => handleClick(ref12)}>
                <RestoreIcon />
                {Logs}
            </div>
            <div className={`product-detail-sidebar-menu`}  onClick={() => window.open(`${distributor?.httpsUrl}${Paths.productCreateTicket}?targetTypeID=7&targetID=${productId}`, '_blank', 'noreferrer')}>
                <AssignmentTurnedInIcon />
                {tickets} {`(${numOfOpenTickets}/${numOfAllTickets})`}
            </div>
        </div>
    )
}

export default ProductDetailsSidebar
