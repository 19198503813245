import service from "services/config";
import serviceV2 from "services/config2";

export const shipmentOrdersUrl = ({stockWarehouseId, orderId}) => {
    return serviceV2.get(`shipments/storage/warehouses/${stockWarehouseId}/orders/${orderId}/shipments`)
}

export const storageItemsUrl = ({shipmentId}) => {
    return service.get(`shipments/${shipmentId}/items`);
}

export const storageShipmentsUrlV2 = (stockWarehouseId, customerId) => {
    return serviceV2.get(`shipments/storage/warehouses/${stockWarehouseId}/customers/${customerId}`);
}

export const deliveredStorageItemsUrl = (stockWarehouseId, customerId) => {
    return serviceV2.get(`shipments/storage/warehouses/${stockWarehouseId}/customers/${customerId}/deliveries`)
}

export const updateStorageLocationUrl = ({shipmentID, storageLocation, shipmentInStorageID}: {shipmentID: number; storageLocation: string; shipmentInStorageID: number}) => {
    return serviceV2.put(`shipments/storage/shipments/${shipmentID}/location`, {
        shipmentID,
        storageLocation,
        shipmentInStorageID,
    }).then((response) => {
        return response?.data
    })
}

export const updateEstDeliveryDateUrl = ({shipmentID, estimatedDeliveryTime, shipmentInStorageID}: {shipmentID: number; estimatedDeliveryTime: string; shipmentInStorageID: number}) => {
    return serviceV2.put(`shipments/storage/shipments/${shipmentID}/edd`, {
        shipmentID,
        estimatedDeliveryTime,
        shipmentInStorageID,
    }).then((response) => {
        return response?.data
    })
}

export const getPendingStorageItemsUrl = (shipmentID) => {
    return serviceV2.get(`shipments/storage/shipments/${shipmentID}/items`).then((response) => {
    return response?.data
})
}

export const getPendingShipmentInStorageItemsUrl = (shipmentID,shipmentInStorageID) => {
    return serviceV2.get(`shipments/storage/shipments/${shipmentID}/${shipmentInStorageID}/items`).then((response) => {
    return response?.data
})
}

export const getDeliveredOrderItemsUrl = (shipmentId, deliveryId) => {
    return serviceV2.get(`shipments/storage/shipments/${shipmentId}/deliveries/${deliveryId}/items`).then((response) => {
    return response?.data
})
}

export const deliverEntireShipmentUrl = (payload) => {
    return serviceV2.post(`shipments/storage/delivery/all`, payload).then((response) => {
        return response?.data
    })
}

export const deliverShipmentUrl = (payload) => {
    return serviceV2.post(`shipments/storage/with/items/delivery`, payload).then((response) => {
        return response?.data
    })
}

export const undoDeliveryUrl = (payload) => {
    return serviceV2.post(`shipments/storage/with/items/all/delivery/reverse`, payload).then((response) => {
        return response?.data
    })
}
export const uploadDeliveryProofUrl = (formData, deliveryId) => {
    return serviceV2.post(`shipments/storage/delivery/${deliveryId}/proof/doc/upload`, formData).then((response) => {
        return response?.data
    })
}

export const getDeliverySlipUrl = (orderId, deliveryId) => {
    return serviceV2.get(`shipments/storage/slip/orders/${orderId}/deliveries/${deliveryId}`).then((response) => {
    return response?.data
})
}

//163705