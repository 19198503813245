import { createSelector } from '@reduxjs/toolkit';

export const storage = (state: any) => state?.storageSlice;

export const getStorageItemDetail = createSelector(
    storage,
  (storage) => storage.storageItemDetail,
);
export const getDeliveredStorageItemDetail = createSelector(
    storage,
  (storage) => storage.deliveredStorageItemDetail,
);
export const getDeliveryDetails = createSelector(
    storage,
  (storage) => storage.deliveryDetails,
);
export const getDeliverySuccessData = createSelector(
    storage,
  (storage) => storage.deliverySuccessData,
);
export const getHandlerId = createSelector(
    storage,
  (storage) => storage.handlerId,
);
export const getIsPendingOrderDelivery = createSelector(
    storage,
  (storage) => storage.isPendingOrderDelivery,
);
export const getIsLocationUpdated = createSelector(
    storage,
  (storage) => storage.isLocationUpdated,
);