import { useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core"
import { Container } from "@material-ui/core";
import { InputAdornment } from "@mui/material"

import {PosInput } from "apps/pos/Common/uiCore"
import QuoteMetaInfo from "apps/springboard/Component/QuoteMetadata/QuoteMetaInfo";
import { IconButton } from 'apps/springboard/Component/SBItems/elements';

import { SearchIcon } from "constants/icons";
import { sectionTitle, springBoardText } from "constants/constant";
import { QuoteMetadataItem, QuoteMetadataProps } from "constants/interfaces";

import "./QuoteMetadata.scss"
import { getStarredMetadataData } from "sagas/persistReducer/selector";

const QuoteMetadata = ({ quoteData }: QuoteMetadataProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [starredShow, setStarredShow] = useState(true);

  const { starred } = sectionTitle

  const { starredStart, starredEnd, collaborators, specifier, architect, engineer, specs, fixtureSpecification, fixtureQuantities, controlSpecification, controlBom, specIncomplete, specQuoteCounts, equipment, quoteBatteryPacks, quotingEmCircuiting, quotingInvertor, generator: generatorStatic, exclusionAndException, noEm, typeAbcNoQuoteNoSpecStatic, typeAbcNoQuoteNoQty: typeAbcNoQuoteNoQtyStatic, documentationAndDesign, electricalDrawingEtc, showStarred, hideStarred } = springBoardText;

  const { specifierName, architectName, engineerName, fixtureSpecs, fixtureQty, controlSpecs, controlBOM, specIncompletedFlag, quoteBasedOnAverageSetFlag, quotingBattery, quotingEM, quotingInverter, generator, noEM, typeAbcNoQuoteNoSpec, typeAbcNoQuoteNoQty, electricalDrawing, jobQuoteID
} = quoteData

  const starredMetadataSb = useSelector(getStarredMetadataData);

  const collaboratorsDetails = {
    [specifier]: specifierName,
    [architect]: architectName,
    [engineer]: engineerName
  };

  const specsDetails = {
    [fixtureSpecification]: fixtureSpecs,
    [fixtureQuantities]: fixtureQty,
    [controlSpecification]: controlSpecs,
    [controlBom]: controlBOM,
    [specIncomplete]: specIncompletedFlag,
    [specQuoteCounts]: quoteBasedOnAverageSetFlag
  };

  const equipmentDetails = {
    [quoteBatteryPacks]: quotingBattery,
    [quotingEmCircuiting]: quotingEM,
    [quotingInvertor]: quotingInverter,
    [generatorStatic]: generator,
  };

  const exclusionAndExceptionDetails = {
    [noEm]: noEM,
    [typeAbcNoQuoteNoSpecStatic]: typeAbcNoQuoteNoSpec,
    [typeAbcNoQuoteNoQtyStatic]: typeAbcNoQuoteNoQty,
  };

  const documentationAndDesignDetails = {
    [electricalDrawingEtc]: electricalDrawing,
  };

  
  const quoteMetadataDetails: QuoteMetadataItem[] = [
    { title: collaborators, details: collaboratorsDetails },
    { title: specs, details: specsDetails },
    { title: equipment, details: equipmentDetails },
    { title: exclusionAndException, details: exclusionAndExceptionDetails },
    { title: documentationAndDesign, details: documentationAndDesignDetails },
  ];
  const starredShowFunction = () => {
    setStarredShow(!starredShow)
  }

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const groupedMetaDataByTitle = starredMetadataSb.reduce((accumalator, current) => {
    const { title, ...rest } = current;
    if (!accumalator[title]) {
      accumalator[title] = [rest];
    } else {
      accumalator[title].push(rest);
    }
    return accumalator;
  }, {});

  const mergeDetails = (detailsArray) => {
    const merged = {};
    detailsArray.forEach(item => {
      Object.entries(item.details).forEach(([key,value]) => {
        if (!value ) {
          quoteMetadataDetails.forEach(metadata => {
            if (metadata.details.hasOwnProperty(key)) {
              merged[key] = metadata.details[key];
            }
          });
        } else {
          merged[key] = value;
        }
      });
    });
    return merged;
  };

  const filteredStarredData = Object.entries(groupedMetaDataByTitle).map(([title, details]) => ({
    title,
    details: mergeDetails(details),
    isStarred: details[0].isStarred
  }));

  function hasDetailsValues(item) {
    for (const key in item.details) {
      if (item.details.hasOwnProperty(key) && item.details[key]) {
        return true;
      }
    }
    return false;
  }
  const hasNonNullDetailsItem = filteredStarredData.some(hasDetailsValues);
  return (
    <>
      <Container className="">
        <Grid container className="mt-4">
        <Grid item xs={12}>
          <PosInput
            variant="filled"
            id="filled-basic"
            type="text"
            className="product-detail-search-input search-focus"
            autoFocus
            placeholder="Search"
            onChange={handleChange}
            onKeyDown={(e)=>e.stopPropagation()}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {
            !searchTerm ? 
            <>
                <div
                  className="d-flex justify-content-between main-title-section product-detail-section-bottom-border"
                  id="StarredSection"
                >
                  <div className="sec-main-title mb-2">
                    <span>{starred}</span>
                  </div>
                    <IconButton name={starredShow ? 'unfold_less' : 'unfold_more'} onClick={starredShowFunction} type='tertiary' className={'up-downb-arrow'} iconSize={20} size={30} title={starredShow ? hideStarred : showStarred}/>
                </div>
                  {starredShow ? 
                    filteredStarredData?.length && hasNonNullDetailsItem ? 
                    <>
                        <QuoteMetaInfo quoteMetadataDetails={filteredStarredData} searchTerm={searchTerm} jobQuoteID={jobQuoteID} starredMetadataSb={starredMetadataSb} isStarComponent={true}/>
                    </> : 
                    <div className='group-wrapper'>
                      <div className='group-content'>
                        <p className='schedules-empty-state'>
                          {starredStart}
                            <IconButton name={"star"} iconSize={20} size={16} type='tertiary' className={'mui-starred-icon'} tabIndex={-1} />
                            <span className="pl-3">{starredEnd}</span>                         
                        </p>
                      </div>
                    </div> :null
                }
                 
            </>:
            null
          }
            <div>
              <QuoteMetaInfo quoteMetadataDetails={quoteMetadataDetails} searchTerm={searchTerm} jobQuoteID={jobQuoteID} starredMetadataSb={starredMetadataSb} isStarComponent={false} />
            </div>
        </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default QuoteMetadata