import AdminDialog from "apps/admin/AdminDialog/AdminDialog";
import { CloseIcon } from "constants/icons";
import Modall from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reviewUserEnd } from "sagas/user/actions";
import { Button } from "../../components";
import { ModalProps, RootState } from "../../models";
import { UpdateModalContent } from "../../sagas/modal/actions";
import "../../styles/common.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    paddingBottom: "20px",
    borderRadius: 10,
    width: "90%"
  },
};

const customStylesWithMediumHeight = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    paddingBottom: "20px",
    borderRadius: 10,
    width: "90%"
  },
};


const customStylesWithoutBottomPadding = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    maxHeight: window.innerHeight * 0.8,
    paddingBottom: "20px",
    borderRadius: 10,
    width: "90%"
  },
};

export function ModalNew() {
  const modal = useSelector<RootState, ModalProps>((state) => state.modal);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const close = () => {
    dispatch(
      UpdateModalContent({
        isOpen: false,
        children: null,
        fixedModal: false,
        removePaddingBottom: false,
        noMaxWidth: false,
        classModal:""
      })
    );
    if (!modal?.isAdmin && modal?.closeNavigator !== 'sbDocument') {
      navigate(-1);
    }
    if (modal?.isAdmin && modal?.url) {
      navigate(modal?.url)
    }
    if (modal?.isAdmin && modal?.navigate && !modal.fromHeader) {
      navigate(-1);
    }
  }
  return (
    <>
      {modal?.isAdmin ? (
        <AdminDialog visible={modal.isOpen} onClose={close} classes={modal?.classModal}>
          {modal.children}
        </AdminDialog>
      ) :
        (
          <div>
            <Modall
              portalClassName="react-document-model"
              ariaHideApp={false}
              isOpen={modal.isOpen}
              onRequestClose={() => {
                if(modal?.closeNavigator !== 'sbDocument') {
                  navigate(-1);
                }
                dispatch(
                  UpdateModalContent({
                    isOpen: false,
                    children: null,
                    fixedModal: false,
                    removePaddingBottom: false,
                    noMaxWidth: false
                  })
                );
                dispatch(reviewUserEnd());
              }}
              style={
                modal.mediumHeight ? customStylesWithMediumHeight :
                modal.removePaddingBottom
                  ? customStylesWithoutBottomPadding
                  : customStyles
              }
              overlayClassName="modal-overlay"
            >
              <div className="modal-button-style">
                <Button
                  className="close modal-close-button"
                  type="button"
                  transparent={true}
                  onClick={close}
                >
                  <CloseIcon />
                </Button>
              </div>
              <div>{modal.children}</div>
            </Modall>
          </div>
        )
      }
    </>
  );
}
