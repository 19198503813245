export const adminMessage = {
  inviteSent: "Invite sent",
  failInviteSent: "Failed to send invite. Try again",
  lock: "Account locked",
  unlock: "Account unlocked",
  failLock: "Failed to lock account. Try again",
  failUnlock: "Failed to unlock account. Try again",
  profileUpdate: "Changes saved",
  failProfileUpdate: "Failed to save changes. Try again",
  deactivate: "Account Deactivated",
  failDeactive: "Failed to deactivate account. Try again",
  inviteCancel: "Invite cancelled",
  inviteApprove: "Account approved",
  inviteReject: "Account rejected",
  failInviteReject: "Failed to reject account. Try again",
  failInviteCancel: "Failed to cancel invite. Try again",
  failInviteApprove: "Failed to approve account. Try again",
  noRecordFound: "No records found",
  noUsersFoundFor: "No Users found for ",
  urlInvalid: "Page url is invalid. Maybe add 'https://' at the start"
}

export const adminValidationMessage = {
  newPasswordReq: "Please enter password",
  confirmPasswordReq: "Please enter confirm password",
  passwordMatch: "Passwords must match",
  userProfileNameReq: "First name is required",
  userProfileLastNameReq: "Last name is required",
  userProfileEmailReq: "Email is required",
  userProfilePhoneReq: "Phone Number is required."
}

export const PaymentMethodValidationMessage = {
  pricingSchedule: "Pricing Schedule is required.",
  phoneNumber: "Phone Number is required.",
  digit10Req: "Please enter a 10 digit phone number",
  firstName: "First name is required.",
  lastName: "Last name is required.",
  customerCode: "POS Customer code is required.",
  countryCode: "Country code is required.",
  companyAddress: "Company Address is required.",
  city: "City is required.",
  zip: "Zip is required.",
  extCode: "Ext code is required.",
  emailAddressReq: "Email is required.",
  emailAddressInValid: "Please enter valid email.",
  fixMarginValid: "Fixed margin must be between 0 and 0.99.",
  zipMaxLength: 'ZIP code must be 5 digits',
  companyName: "Company Name is required.",
}

export const bookMarkValidationMessage = {
  titleReq: 'Title is required',
  pageUrlReq: 'Page url is required'
}

export const undoDeliveryModalMessage = {
  reason: "Reason is required"
}