import {
  TENANT_FETCHING,
  TENANT_FETCH_ERROR,
  TENANT_FETCH_SUCCESS,
} from "./constants";

import { Tenant } from "../../models";

export function TenantRequest() {
  return {
    type: TENANT_FETCHING,
  };
}

export function TenantSuccess(payload: Tenant) {
  return {
    type: TENANT_FETCH_SUCCESS,
    payload,
  };
}

export function TenantError(errors: any) {
  return {
    type: TENANT_FETCH_ERROR,
    errors,
  };
}
