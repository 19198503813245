import { Divider } from "@mui/material";

import {
  Container,
  PosIconButton,
  PosPageHeading,
} from "apps/pos/Common/uiCore";

import { getprivacySettingsIconHeight } from "utils/helper";
import { ArrowBackRoundedIcon } from "constants/icons";

import "apps/pos/poscommonstyling.scss";
import { ReactNode } from "react";

interface PageHeaderProps {
  onClose?: () => void,
  title?: string,
  subTitle?: string,
  children?: ReactNode,
  titleColor?: boolean,
  subTitleColor?: boolean,
  noTopBorder?: boolean,
  tabIndex?:number,
}

const PageHeader = ({
  onClose,
  title,
  subTitle,
  children,
  titleColor,
  subTitleColor,
  noTopBorder,
  tabIndex,
}: PageHeaderProps) => {
  const orderLayout = document.querySelector("#order-layout");
  if (orderLayout) {
    orderLayout["style"].marginTop = `${getprivacySettingsIconHeight()}px`;
  }

  return (
    <>
      <Container className="new-container">
        <div className="customer-info-page">
          <div className="customer-info-section customer-info-container">
            <PosIconButton
              className="back-next-btn-small-left customer-info-back-btn pos-icon-back-btn"
              onClick={onClose}
              tabIndex={tabIndex}
            >
              <ArrowBackRoundedIcon />
            </PosIconButton>
            <div className="pricing-modal-header mt-0 mb-0">
              <div className="customer-info-heading">
                <div>
                  <PosPageHeading heading={title} grayHeading={titleColor} />
                  <h1 className="pricing-schedule-heading">
                    <PosPageHeading
                      heading={subTitle}
                      grayHeading={subTitleColor}
                    />
                  </h1>
                </div>
                {children}
              </div>
            </div>
            {noTopBorder ? <></> : <Divider className="update-product-container-line" />}
          </div>
        </div>
      </Container>
    </>
  );
};

export default PageHeader;
