import { useEffect, useState } from 'react';
import useAutocomplete from '@mui/material/useAutocomplete';

import { PosImage } from 'apps/pos/Common/uiCore';

import { SearchIcon } from 'constants/icons';
import images from 'constants/images';

import {retailPriceLabel} from "../../modules/productDetailsContainer/constants";
import { useSearchAccessories } from 'apps/product/modules/productListingContainer/hooks/useSearchAccessories';
import { Input, Listbox } from './style';

export default function UseAutocomplete({ relationType, addNewAccessories, placeHolder }) {

  const {getSearchAccessoriesFn, isSuccess , searchLoading, searchAccessories , searchError} = useSearchAccessories()
  const  { data } = searchAccessories ?? {}
   const { defaultIcon } = images;

  const [searchString, setSearchString] = useState('');
  const [ProductAccessoriesDataByKey, setProductAccessoriesDataByKey] = useState([])

  useEffect(() => {
    if (isSuccess) {
      setProductAccessoriesDataByKey(data)
    }
  }, [isSuccess])

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    popupOpen,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    options: ProductAccessoriesDataByKey ? ProductAccessoriesDataByKey : [],
    getOptionLabel: (option: any) => option.productName,
  });

  useEffect(() => {
    if (!popupOpen) {
      setProductAccessoriesDataByKey([])
      setSearchString("")
    }
  }, [popupOpen, searchString])

  const [productListing, setProductListing] = useState(false);
  useEffect(() => {
    if(searchError && !data?.length && searchString){
      setProductListing(true)
    }else{
      setProductListing(false)
    }
  },[searchError ])

  const AddAccessories = (productId, productDetail) =>  {
    addNewAccessories(productId, relationType, productDetail)
    setSearchString('')
  }

  return (
    <div className='position-relative mt-2'>
      <div {...getRootProps()} className='listgroup-search-input input-focus-styling'>
        <SearchIcon />
        <Input {...getInputProps()}
          value={searchString}
          onChange={(e) => {
            setProductListing(false)
            if(ProductAccessoriesDataByKey){
              setProductAccessoriesDataByKey([])
            }
            if (e.target.value.charAt(0) !== " ") {
              setSearchString(e.target.value);
            }
          }}
          placeholder={placeHolder}
          onKeyPress={(event) => {
            if (event.key === "Enter" && searchString) {
              const body = {
                page: 1,
                size: 50,
                keywords: searchString ? searchString : "",
              };
              getSearchAccessoriesFn(body);
            }
          }}
        />
      </div>
      {groupedOptions?.length > 0 ? (
        <Listbox {...getListboxProps()} className='list-group-listbox'>
          {groupedOptions?.map((option, index) => {
            const { manufacturerCode, productName, thumbImg, productId } = option || {};
            return (
              <li className='p-0 border-0' {...getOptionProps({ option, index })} key={index}>
                <div className='accessories-main-info-sec product-detail-info-sec-border' onClick={() => AddAccessories(productId, option)} >
                  <div className='d-flex accessories-left-info'>
                    <div className="accessories-icon">
                        <PosImage
                          src={thumbImg ? thumbImg : defaultIcon}
                          className="search-list-img"
                          alt="product"
                        />
                    </div>
                    <div className="accessories-data">
                        <div className="accessories-name">
                          <span>{productName}</span>
                        </div>
                        <div className="accessories-number">
                          <span>{manufacturerCode}</span>
                        </div>
                    </div>
                  </div>
                  
                </div>
              </li>
            )
          })}
        </Listbox>
      ) : null
      }
      {
        productListing ? (
          <Listbox {...getListboxProps()} className='list-group-listbox'>
            <li className='p-0 border-0 text-center'>
              {`${retailPriceLabel?.errorMessage} ${searchString}`}
            </li>
        </Listbox>
        ) : null
      }
      {
        searchLoading && searchString ? (
          <Listbox {...getListboxProps()} className='list-group-listbox'>
            <li className='p-0 border-0 text-center'>
              {`${retailPriceLabel?.searchingMessage}`}
            </li>
        </Listbox>
        ) : null
      }

    </div >
  );
}
