import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ProductsSliceI {
  //TODO: will change later
  genericCustomers: any;
}

const initialState: ProductsSliceI = {
  genericCustomers: null,
};

export const genericCustomersSlice = createSlice({
  name: "genericCustomersReducer",
  initialState,
  reducers: {
    genericCustomersSuccess: (state, action: PayloadAction<any>) => {
      state.genericCustomers = action.payload;
    },
    genericCustomersError: (state) => {
      state.genericCustomers = null;
    },
    genericCustomersClear: (state) => {
      state.genericCustomers = null;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  genericCustomersSuccess,
  genericCustomersError,
  genericCustomersClear
} = genericCustomersSlice.actions;

export default genericCustomersSlice.reducer;