import { createSelector } from "@reduxjs/toolkit";
import { user } from "models";

export const userData = (state: { user: user }) =>
  state.user;

export const getLoggedInUser = createSelector(
  userData ,
  (data) => data.loggedInUser
)
