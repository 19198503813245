import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import useFilterSections from "apps/product/Components/ProductDetails/useSectionFilter";
import CreateGridStarIcon from "apps/product/Components/ProductDetails/CreateGridStarIcon";
import CommonEditButton from "apps/product/Components/ProductDetails/CommonEditButton";
import CommonGridSection from "apps/product/Components/ProductDetails/CommonGridSection";
import { PosButton } from "apps/pos/Common/uiCore";

import { labels, sectionName } from "apps/product/modules/productDetailsContainer/constants";
import { sectionTitle } from "constants/constant";
import { getSingleProductDataFunction } from "sagas/productsDetails/selector";
import { commonConstants } from "apps/product/helper/commonConstants";
import { ProductDetailText } from "constants/enum";
import { isoToFormat } from "utils/utils";
import ProductDetailsWholesaleModal from "./ProductDetailModals/ProductDetailsWholesaleModal";
import { useState } from "react";
import { distributorData } from "sagas/distributor/selector";

const ProductOverview = ({
  isStarred,
  searchText,
  handleOverviewSection,
  handleSearchSection
}) => {

  const getSingleProductData = useSelector(getSingleProductDataFunction);
  const distributor = useSelector(distributorData)
  const { details, metadata, costsAndPrices } = getSingleProductData || {}
  const { productName, description, manufacturerName, vendorName, manufacturerCode, dates, productGroupName, breadcrumb, measurements } = details || {}

  const { notSet, code, name, description: Description, manufacturer, vendor, group, category, codes, names, descriptions, manufact, groups, categories, Upc, isMerquery, canBeDrop, UPC, ContainsMercury, CanbeDropShipped, vendorPriceLabel, wholeSalePriceLabel, venderPriceId, wholeSalePriceId, seeHistory } = labels;
 
  const { Vendor, VendorCost ,Wholesale, WholesalePrice, changedFrom, wholesalePrice: salesPriceType } = ProductDetailText

  const {priceUOM, itemOUM, unitPerCases, minimumToOrder, Weight } = commonConstants;

  const { itemMU, itemMuName, muName, priceMU, weight, unitPerCase, minimumCases } = measurements || {}
  const { basePrices } = costsAndPrices || {}
  const { vendorPrice, salesPrice } = basePrices || {}
  const { identifiers, flags } = metadata || {}
  const { dropshipFlag, containMercuryFlag } = flags || {}
  const { upc } = identifiers || {}

  const { overview, metaData, details: detailsSection, costs } = sectionName;

  const { Category: sectionTitleCategory, code: sectionTitleCode, name: sectionTitleName, descriptionTitle, manufacturer: sectionTitleManufacturer, vendor: sectionTitleVendor } = sectionTitle
 
  const { showSection } = useFilterSections({ searchText, sectionName: overview, isStarred });

  const [wholesaleModal, setWholesaleModal] = useState(false);
  const [priceTypeID, setPriceTypeID] = useState();
  const [priceLable, setPriceLable] = useState();

  const openModel = (priceTypeId, priceText) => () => {
    setPriceTypeID(priceTypeId)
    setWholesaleModal(true);
    setPriceLable(priceText)
  }
  return (
    <>
      <section className="product-report-section">
        <Grid container className="product-report-container">
          {showSection(names,name) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={names} label={name} value={productName} sectionName={overview} />
              <CommonGridSection value={productName} />
              <CommonEditButton onClick={handleOverviewSection(sectionTitleName)} />
            </Grid>
          ) : null}
          {showSection(descriptions,Description) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={descriptions} label={Description} value={description} sectionName={overview} />
              <CommonGridSection value={description} />
              <CommonEditButton onClick={handleOverviewSection(descriptionTitle)} />
            </Grid>
          ) : null}
          {showSection(categories,category) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={categories} label={category} value={breadcrumb} sectionName={overview} />
              <Grid item xs={12} sm={8}>
                <div>
                  <span>{breadcrumb}</span>
                </div>
              </Grid>
              <CommonEditButton onClick={handleOverviewSection(sectionTitleCategory)} />
            </Grid>
          ) : null}
          {showSection(groups,group) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={groups} label={group} value={productGroupName} sectionName={overview} />
              <Grid item xs={12} sm={8}>
                {productGroupName ? <div>
                  <span>{productGroupName}</span>
                </div> : <div className="small-text-without-margin gray-text">
                  <span>{notSet}</span>
                </div>}
                {/*  Might need later  {!isOnPriceSchedule ? <div className="d-flex align-items-center mt-2">
                  <MaterialIcon icon="warning" size="20" classes="mr-1 product-detail-warning-color"/>
                  <span>{noPriceSchedule}</span>
                </div> : null} */}
              </Grid>
              <CommonEditButton onClick={handleOverviewSection(group)} />
            </Grid>
          ) : null}
          {showSection(manufact,manufacturer) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={manufact} label={manufacturer} value={manufacturerName} sectionName={overview} />
              <CommonGridSection value={manufacturerName} />
              <CommonEditButton onClick={handleOverviewSection(sectionTitleManufacturer)} />
            </Grid>
          ) : null}
          {showSection(codes,code) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={codes} label={code} value={manufacturerCode} sectionName={overview} />
              <CommonGridSection value={manufacturerCode} />
              <CommonEditButton onClick={handleOverviewSection(sectionTitleCode)} />
            </Grid>
          ) : null}
          {showSection(vendor,vendor) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={vendor} label={vendor} value={vendorName} sectionName={overview} />
              <CommonGridSection value={vendorName} />
              <CommonEditButton onClick={handleOverviewSection(sectionTitleVendor)} />
            </Grid>
          ) : null}
          {showSection(Upc,UPC) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='upc' label={UPC} value={upc} sectionName={metaData} />
              <CommonGridSection value={upc} />
              <CommonEditButton onClick={handleOverviewSection(UPC)} />
            </Grid>
          ) : null}
          {showSection("priceOUM",priceUOM) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='priceOUM' label={priceUOM} value={priceMU} sectionName={detailsSection} />
              <CommonGridSection value={priceMU && muName} displayValue={`${muName} (${priceMU})`} />
              <CommonEditButton onClick={handleOverviewSection(priceUOM)} />
            </Grid>
          ) : null}
          {showSection("itemOUM",itemOUM) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='itemOUM' label={itemOUM} value={itemMU} sectionName={detailsSection} />
              <CommonGridSection value={itemMU && itemMuName} displayValue={`${itemMuName} (${itemMU})`} />
              <CommonEditButton onClick={handleOverviewSection(itemOUM)} />
            </Grid>
          ) : null}
          {showSection("unitPerCase",unitPerCases) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='unitPerCase' label={unitPerCases} value={unitPerCase} sectionName={detailsSection} />
              <CommonGridSection value={unitPerCase} />
              <CommonEditButton onClick={handleOverviewSection(unitPerCases)} />
            </Grid>
          ) : null}
          {showSection("minimumToOrder",minimumToOrder) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='unitPerCase' label={minimumToOrder} value={minimumCases} sectionName={detailsSection} />
              <CommonGridSection value={minimumCases} />
              <CommonEditButton onClick={handleOverviewSection(minimumToOrder)} />
            </Grid>
          ) : null}
          {showSection("wholeSalePrice",WholesalePrice) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='wholeSalePrice' label={WholesalePrice} value={salesPrice?.currentPrice} sectionName={costs} smallText={`${distributor?.shortName} ${salesPriceType}`} />
              <Grid item xs={12} sm={8}>
                <div>
                  <span>${salesPrice?.currentPrice || 0}</span>
                </div>
                <div className="wholesale-des-text gray-text mt-2">
                  <span>{`${changedFrom}${salesPrice?.previousPrice || 0}`}
                    {
                      salesPrice?.lastUpdatedByName ? " by " + salesPrice?.lastUpdatedByName : (dates?.createdByName ? " by " + dates?.createdByName : "")
                    }
                    {salesPrice?.lastUpdatedAt ? " on " + isoToFormat(salesPrice?.lastUpdatedAt, "MM/DD/YYYY") :
                      " on " + isoToFormat(salesPrice?.createdAt, "MM/DD/YYYY")
                    }</span>
                </div>
                <div className="mt-2">
                  <PosButton
                    variant="contained"
                    className="btn product-detail-medium-btn history"
                    size="small"
                    onClick={openModel(wholeSalePriceId, wholeSalePriceLabel)}
                  >
                    {seeHistory}
                  </PosButton>
                </div>
              </Grid>
              <CommonEditButton onClick={handleSearchSection(Wholesale)} />
            </Grid>
          ) : null}
          {showSection("vendorPrice",VendorCost) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='vendorPrice' label={VendorCost} value={vendorPrice?.currentPrice} sectionName={costs} smallText={`Price ${distributor?.shortName} pays to Vendor`} />
              <Grid item xs={12} sm={8}>
                <span>${vendorPrice?.currentPrice || 0}</span>
                <div className="small-text-without-margin gray-text">
                  <span>{`${changedFrom}${vendorPrice?.previousPrice || 0}`}
                    {
                      vendorPrice?.lastUpdatedByName ? " by " + vendorPrice?.lastUpdatedByName : (dates?.createdByName ? " by " + dates?.createdByName : "")
                    }
                    {vendorPrice?.lastUpdatedAt ? " on " + isoToFormat(vendorPrice?.lastUpdatedAt, "MM/DD/YYYY") :
                      " on " + isoToFormat(vendorPrice?.createdAt, "MM/DD/YYYY")
                    }</span>
                </div>
                <div>
                  <PosButton
                    variant="contained"
                    className="btn product-detail-medium-btn mt-2"
                    onClick={openModel(venderPriceId, vendorPriceLabel)}
                  >
                    {seeHistory}
                  </PosButton>
                </div>
              </Grid>
              <CommonEditButton onClick={handleSearchSection(Vendor)} />
            </Grid>
          ) : null}
          {showSection("Weight",Weight) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='Weight' label={Weight} value={weight} sectionName={detailsSection} />
              <CommonGridSection value={weight} />
              <CommonEditButton onClick={handleOverviewSection(Weight)} />
            </Grid>
          ) : null}
          {showSection(isMerquery,ContainsMercury) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='isMerquery' label={ContainsMercury} value={containMercuryFlag} sectionName={metaData} />
              <CommonGridSection value={containMercuryFlag} metaDataFlags={true} />
              <CommonEditButton onClick={handleOverviewSection(isMerquery)} />
            </Grid>
          ) : null}
          {showSection(canBeDrop,CanbeDropShipped) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='canBeDrop' label={CanbeDropShipped} value={dropshipFlag} sectionName={metaData} />
              <CommonGridSection value={dropshipFlag} metaDataFlags={true} />
              <CommonEditButton onClick={handleOverviewSection(isMerquery)} />
            </Grid>
          ) : null}
        </Grid>
      </section>
      {wholesaleModal && <ProductDetailsWholesaleModal setWholesaleModal={setWholesaleModal} wholesaleModal={wholesaleModal} priceTypeID={priceTypeID} priceLable={priceLable} />}
    </>
  );
};

export default ProductOverview;
