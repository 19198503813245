import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { InputAdornment } from "@material-ui/core";

import { ErrorMessage } from "apps/pos/formik/errorMessage";
import { UpdatedOverviewProductDetailSchema } from "apps/pos/formik/validationSchema";
import {
  PosButton,
  PosInput,
  PosSelect,
} from "apps/pos/Common/uiCore";
import IconButton from "apps/product/Modals/Elements/IconButton";
import PosToggle from "apps/pos/Common/uiCore/posToggle/posToggle";
import ReturnToGroup from "../Components/Categorycomponents/ReturnTo/ReturnToGroup";

import {
  sectionName,
  labels,
} from "../modules/productDetailsContainer/constants";
import { fiveDecimalRegex, sectionTitle } from "constants/constant";
import { commonConstants } from "../helper/commonConstants";

import { addManufactureLink, addVendorLink } from "constants/staticData";
import {
  authorizationToken,
  blockInvalidChar, getSalesPrice, setFocusTo,
  setScrollTo,
} from "utils/utils";
import { ProductDetailText } from "constants/enum";

import useProductsDetailsHooks from "../modules/productDetailsContainer/hooks/mutationHooks";

import {
  newProductGroupRequest,
  newProductManufacturerRequest,
  newProductVendorRequest,
} from "sagas/pos/actions";
import { updateOverviewProductStart } from "sagas/productsDetails/singleProductDetails.slice";

import "./ProductDetailsCostsModals.scss";
import "./ProductDetailsCommonModal.scss";

const ProductOverviewModals = ({
  open,
  onClose,
  statusValue,
  updateBasicProduct,
  getCategoriesMenuProductData,
  getSingleProductData,
  distributor,
  getNewProductManufacturerData,
  getNewProductVendorData,
  getNewProductGroupData,
  focusText,
  removeFocus,
}) => {
  const dispatch = useDispatch();

  const { updateProductsOverviewMutation } = useProductsDetailsHooks();

  const { details, metadata, costsAndPrices } = getSingleProductData || {};
  const {
    productId,
    productName,
    description,
    manufacturerCode,
    manufacturerId,
    vendorId,
    categoryId,
    productGroupId,
    activeFlag,
    measurements
  } = details || {};
  const { weight, unitPerCase, minimumCases, priceMU, muName, itemMU, itemMuName, stockUnitPerCase } = measurements || {}
  const { identifiers, flags, seo } = metadata || {}
  const { upc } = identifiers || {}
  const { basePrices } = costsAndPrices || {}
  const { vendorPrice, salesPrice } = basePrices || {}
  const { currentPrice } = vendorPrice || {}
  const { currentPrice: salesCurrentPrice, margin: salesMargin } = salesPrice || {}
  const { dropshipFlag, containMercuryFlag } = flags || {}
  const { hyperlink, folderPath } = seo || {}

  const { shortName } = distributor || {}

  const [isEqual, setIsEqual] = useState(false);
  const [focusFirstInputPrice, setFocusFirstInputPrice] = useState(false);
  const [focusSecondInputPrice, setFocusSecondInputPrice] = useState(false);

  const [returnTo, setReturnTo] = useState({
    row: { categoryId: "", categoryName: "" },
    unit: { categoryId: "", categoryName: "" },
    shelf: { categoryId: "", categoryName: "" },
  });
  const [returnInitTo, setReturnInitTo] = useState({
    row: { categoryId: "", categoryName: "" },
    unit: { categoryId: "", categoryName: "" },
    shelf: { categoryId: "", categoryName: "" },
  });

  useEffect(() => {
    const isEqual = deepEqual(returnTo, returnInitTo);
    setIsEqual(isEqual);
    if (statusValue?.id !== activeFlag) {
      setIsEqual(false);
    } else {
      setIsEqual(true);
    }
  }, [returnTo, returnInitTo, statusValue]);

  const findIds = (data, targetId) => {
    for (const item of data) {
      if (item.categoryId === targetId) {
        return {
          parentId: item?.categoryId,
          parentName: item?.categoryName,
          childId: "",
          childName: "",
          innerChildId: "",
          innerChildName: "",
        };
      }
      if (item.children) {
        for (const childItem of item.children) {
          if (childItem.categoryId === targetId) {
            return {
              parentId: item?.categoryId,
              parentName: item?.categoryName,
              childId: childItem?.categoryId,
              childName: childItem?.categoryName,
              innerChildId: "",
              innerChildName: "",
            };
          }
          if (childItem.children) {
            for (const innerChildItem of childItem.children) {
              if (innerChildItem.categoryId === targetId) {
                return {
                  parentId: item?.categoryId,
                  parentName: item?.categoryName,
                  childId: childItem?.categoryId,
                  childName: childItem?.categoryName,
                  innerChildId: innerChildItem?.categoryId,
                  innerChildName: innerChildItem?.categoryName,
                };
              }
            }
          }
        }
      }
    }
    return null;
  };

  const initalCategory = () => {
    const obj = {
      row: { categoryId: "", categoryName: "" },
      unit: { categoryId: "", categoryName: "" },
      shelf: { categoryId: "", categoryName: "" },
    };
    setReturnTo(obj);
    setReturnInitTo(obj);
  };
  // set an initial line if returnTo is empty
  useEffect(() => {
    if (categoryId && getCategoriesMenuProductData) {
      const result = findIds(getCategoriesMenuProductData, categoryId);
      if (result) {
        const obj = {
          row: {
            categoryId: result?.parentId,
            categoryName: result?.parentName,
          },
          unit: {
            categoryId: result?.childId,
            categoryName: result?.childName,
          },
          shelf: {
            categoryId: result?.innerChildId,
            categoryName: result?.innerChildName,
          },
        };
        setReturnTo(obj);
        setReturnInitTo(obj);
      } else {
        initalCategory();
      }
    } else {
      initalCategory();
    }
  }, []);

  useEffect(() => {
    if (!getNewProductGroupData?.length) {
      dispatch(newProductGroupRequest());
    }
    if (!getNewProductManufacturerData?.length) {
      dispatch(newProductManufacturerRequest());
    }
    if (!getNewProductVendorData?.length) {
      dispatch(newProductVendorRequest());
    }
  }, []);

  const handleClose = (resetForm) => () => {
    resetForm();
    onClose();
    initalCategory();
  };

  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== "object" || typeof obj2 !== "object") return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (!keys2.includes(key)) return false;

      if (!deepEqual(obj1[key], obj2[key])) return false;
    }

    return true;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name, setFieldValue) => {
    setFieldValue(name, e.target.checked);
  }

  return (
    <Formik
      initialValues={{
        productName: productName,
        description: description ? description : null,
        categoryId: categoryId,
        productGroupId: productGroupId || "",
        manufacturerId: manufacturerId,
        manufacturerCode: manufacturerCode,
        vendorId: vendorId,
        upc : upc,
        priceMu: Number(priceMU),
        muName : muName ,
        itemMU : Number(itemMU),
        itemMuName: itemMuName ,
        vendorPrice: currentPrice,
        salesPrice: salesCurrentPrice,
        dropshipFlag: Boolean(dropshipFlag),
        containMercuryFlag: Boolean(containMercuryFlag),
        minimumCases : minimumCases,
        unitPerCase : unitPerCase,
        weight : Number(weight),
        salesMargin: salesMargin?.toFixed(2),
      }}
      validationSchema={UpdatedOverviewProductDetailSchema}
      enableReinitialize
      onSubmit={(values) => {
        const decodedData = authorizationToken("Authorization");
        let categoryID = categoryId;

        if (returnTo?.shelf && returnTo?.shelf?.categoryId) {
          categoryID = returnTo?.shelf?.categoryId;
        } else if (returnTo?.unit && returnTo?.unit?.categoryId) {
          categoryID = returnTo?.unit?.categoryId;
        } else if (returnTo?.row && returnTo?.row?.categoryId) {
          categoryID = returnTo?.row?.categoryId;
        } else {
          categoryID = categoryId;
        }
        const {
          description,
          manufacturerId,
          vendorId,
          manufacturerCode,
          productName,
          productGroupId,
          upc,
          muName,
          itemMU,
          itemMuName,
          priceMu,
          minimumCases,
          unitPerCase,
          weight,
          dropshipFlag,
          salesPrice,
          vendorPrice,
          containMercuryFlag,
        } = values;
        const body = {
          handlerID: decodedData?.DefaultLoginId,
          productID: productId,
          categoryID: categoryID ?? categoryId,
          manufacturerID: manufacturerId,
          vendorID: vendorId,
          productName,
          description,
          manufacturerCode,
          upc,
          minimumCases: Number(minimumCases),
          unitPerCase: Number(unitPerCase),
          priceMU: priceMu ? Number(priceMu) : 0,
          muName,
          salesPrice: Number(salesPrice),
          vendorPrice: Number(vendorPrice),
          dropShipFlag: dropshipFlag ? 1 : 0,
          containMercuryFlag: containMercuryFlag ? 1 : 0,
          weight: weight ? Number(weight) : 0,
          itemMU: itemMU ? Number(itemMU) : 0,
          itemMuName,
          productGroupId: productGroupId ? productGroupId : null,
          hyperlink,
          folderPath,
          stockUnitPerCase
        };
        dispatch(updateOverviewProductStart());
        updateProductsOverviewMutation.mutate(body);
      }}
    >
      {({ values, setFieldValue, resetForm, handleSubmit, dirty, errors, touched }) => {
        const {
          manufacturerCode,
          manufacturerId,
          productName,
          description,
          vendorId,
          productGroupId,
          priceMu,
          upc,
          muName,
          itemMU,
          itemMuName,
          vendorPrice,
          salesPrice,
          unitPerCase,
          minimumCases,
          weight,
          dropshipFlag,
          containMercuryFlag,
          salesMargin
        } = values;
        const {
          Category,
          code,
          name,
          descriptionTitle,
          manufacturer,
          vendor
        } = sectionTitle;
        const { Overview } = sectionName;
        const { priceUOM, unitPerCases, minimumToOrder, Weight, itemOUM } = commonConstants;
        const { Vendor, PriceEach, Prof, Wholesale, wholesalePrice: salesPriceType } = ProductDetailText;
        const { group, Back, SaveChanges, UPC , isMerquery  } = labels;

        const handleCommonChanges = (e, name) => {
          const inputValue = e.target.value;
          if (
            inputValue === "" ||
            fiveDecimalRegex.test(inputValue)
          ) {
            setFieldValue(name, inputValue);
          }
        }

        const handlePriceToPercentage = (e, name, percentageName, venderCurrentPrice) => {
          const inputValue = e.target.value;
          setFieldValue(name, inputValue)
          if (!e.target.value) {
            setFieldValue(percentageName, "")
          } else {
            const percentage = (inputValue ? (((inputValue - venderCurrentPrice) / inputValue) * 100) : 0)
            setFieldValue(percentageName, Number(percentage?.toFixed(2)))
          }
        }

        const handlePercentageToPrice = (e, percentageName, name, venderCurrentPrice) => {
          const inputValue = e.target.value;
          setFieldValue(percentageName, inputValue)
          if (!e.target.value) {
            setFieldValue(name, "")
          } else {
            const price = getSalesPrice(inputValue, venderCurrentPrice)
            setFieldValue(name, price)
          }
        }

        return (
          <>
            <Dialog className="overview-dialog" open={open} onClose={onClose}>
              <div className="dialog-header">
                <h3>{Overview}</h3>
                <IconButton
                  classes="close-btn"
                  secondary={true}
                  title="Close"
                  icon="close"
                  onClick={handleClose(resetForm)}
                />
              </div>
              <DialogContent>
                <div className="product-detail-section-bottom-border mb-4 pb-4">
                  <div className="notes-added-user-name">
                    <span>{name}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100">
                      <PosInput
                        variant="filled"
                        id={name}
                        type="text"
                        className={`product-detail-search-input input-focus-styling`}
                        name="productName"
                        value={productName}
                        onChange={(e) => {
                          setFieldValue("productName", e.target.value);
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        inputRef={(i) =>
                          i && focusText === name && setFocusTo(i, removeFocus)
                        }
                      />
                    </div>
                  </div>
                  <ErrorMessage name="productName" />
                </div>
                <div className="product-detail-section-bottom-border mb-4 pb-4">
                  <div className="notes-added-user-name">
                    <span>{descriptionTitle}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100">
                      <PosInput
                        variant="filled"
                        id={descriptionTitle}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        className="customer-company-address input-focus-styling"
                        name="description"
                        value={description}
                        maxRows={1}
                        onChange={(e) => {
                          setFieldValue("description", e.target.value);
                        }}
                        multiline
                        rows={8}
                        inputRef={(i) =>
                          i &&
                          focusText === descriptionTitle &&
                          setFocusTo(i, removeFocus)
                        }
                      />
                    </div>
                  </div>
                  <ErrorMessage name="description" />
                </div>
                <div className="product-detail-section-bottom-border mb-4 pb-4">
                  <div className="notes-added-user-name">
                    <span>{Category}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div
                      className="w-100"
                      id={Category}
                      ref={(i) =>
                        i &&
                        focusText === Category &&
                        setFocusTo(i, removeFocus)
                      }
                    >
                      <ReturnToGroup
                        categoryMenu={getCategoriesMenuProductData}
                        returnTo={returnTo}
                        setReturnTo={setReturnTo}
                      />
                    </div>
                    <div className="small-text gray-text mt-2">
                      <span className="ml-0">
                        Resets to root category when the product is set as
                        Pending
                      </span>
                    </div>
                  </div>
                </div>
                <div className="product-detail-section-bottom-border mb-4 pb-4">
                  <div className="notes-added-user-name">
                    <span>{group}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100">
                      <PosSelect
                        isSearch
                        className=" product-detail-select"
                        name="productGroupName"
                        variant="filled"
                        id={group}
                        defaultValue={productGroupId}
                        value={productGroupId}
                        onChange={(e) => {
                          setFieldValue("productGroupId", e.target.value);
                        }}
                        options={
                          getNewProductGroupData?.length &&
                          getNewProductGroupData?.map(({ name, id }) => {
                            return {
                              label: name,
                              value: id,
                            };
                          })
                        }
                        inputRef={(i) =>
                          i && focusText === group && setFocusTo(i, removeFocus)
                        }
                      />
                    </div>
                    <ErrorMessage name="productGroupId" />
                  </div>
                  {/*  Might need later <div className="no-pricing-schedule-sec">
                    {!isOnPriceSchedule ? (
                     <div className="d-flex align-items-center mt-2">
                  <MaterialIcon icon="warning" size="20" classes="mr-1 product-detail-warning-color"/>
                  <span>{noPriceSchedule}</span>
                </div> 
                    ) : null}
                  </div> */}
                </div>
                <div className="product-detail-section-bottom-border mb-4 pb-4">
                  <div className="notes-added-user-name d-flex align-items-center justify-content-between">
                    <span>{manufacturer}</span>
                    <a
                      href={`${distributor.httpsUrl}${addManufactureLink}`}
                      target="_blank"
                      className="side-link"
                    >
                      Add Manufacturer
                    </a>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100">
                      <PosSelect
                        isSearch
                        id={manufacturer}
                        className=" product-detail-select"
                        name="manufacturerId"
                        variant="filled"
                        value={manufacturerId}
                        defaultValue={manufacturerId}
                        onChange={(e) => {
                          setFieldValue("manufacturerId", e.target.value);
                        }}
                        inputRef={(i) =>
                          i &&
                          focusText === manufacturer &&
                          setFocusTo(i, removeFocus)
                        }
                        options={
                          getNewProductManufacturerData?.length &&
                          getNewProductManufacturerData?.map(
                            ({ companyName, companyId }) => {
                              return {
                                label: companyName,
                                value: companyId,
                              };
                            }
                          )
                        }
                      />
                    </div>
                  </div>
                  <ErrorMessage name="manufacturerId" />
                </div>
                <div className="product-detail-section-bottom-border mb-4 pb-4">
                  <div className="notes-added-user-name">
                    <span>{code}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100">
                      <PosInput
                        variant="filled"
                        id={code}
                        type="text"
                        className={`product-detail-search-input input-focus-styling`}
                        name="manufacturerCode"
                        value={manufacturerCode}
                        onChange={(e) => {
                          setFieldValue("manufacturerCode", e.target.value);
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        inputRef={(i) =>
                          i && focusText === code && setFocusTo(i, removeFocus)
                        }
                      />
                    </div>
                  </div>
                  <ErrorMessage name="manufacturerCode" />
                </div>
                <div className="product-detail-section-bottom-border mb-4 pb-4">
                  <div className="notes-added-user-name d-flex align-items-center justify-content-between">
                    <span>{vendor}</span>
                    <a
                      href={`${distributor.httpsUrl}${addVendorLink}`}
                      target="_blank"
                      className="side-link"
                    >
                      Add Vendor{" "}
                    </a>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100" id={vendor}>
                      <PosSelect
                        isSearch
                        className=" product-detail-select"
                        name="vendorId"
                        variant="filled"
                        defaultValue={vendorId}
                        value={vendorId}
                        onChange={(e) => {
                          setFieldValue("vendorId", e.target.value);
                        }}
                        options={
                          getNewProductVendorData?.length &&
                          getNewProductVendorData.map(
                            ({ companyName, companyId }) => {
                              return {
                                label: companyName,
                                value: companyId,
                              };
                            }
                          )
                        }
                        inputRef={(i) =>
                          i &&
                          focusText === vendor &&
                          setFocusTo(i, removeFocus)
                        }
                      />
                    </div>
                  </div>
                  <ErrorMessage name="vendorId" />
                </div>
                <div className="product-details-notes-main-content-section table-select pt-0">
                  <div className="notes-added-user-name">
                    <span>{UPC}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100">
                      <PosInput
                        variant="filled"
                        id={UPC}
                        type="number"
                        className="product-detail-search-input input-focus-styling"
                        name="upc"
                        value={upc}
                        onChange={(e) => {
                          setFieldValue("upc", e.target.value);
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        maxLength={12}
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onWheel={(e) => e?.target?.blur()}
                        inputRef={(i) =>
                          i && focusText === UPC && setFocusTo(i, removeFocus)
                        }
                      />
                    </div>
                  </div>
                  <ErrorMessage name="upc" />
                </div>
                <div className="product-details-notes-main-content-section">
                  <div className="notes-added-user-name">
                    <span>{priceUOM}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100 d-flex product-detail-stock-main-div">
                      <div className="product-detail-search-input">
                        <PosInput
                          variant="filled"
                          id={priceUOM}
                          type="number"
                          className="product-detail-counter-sec first-counter-sec"
                          value={priceMu}
                          name="priceMu"
                          onChange={(e) => {
                            setFieldValue("priceMu", e.target.value);
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          onKeyDown={(e) => blockInvalidChar(e)}
                          onWheel={(e) => e?.target?.blur()}
                          inputRef={(i) =>
                            i &&
                            focusText === priceUOM &&
                            setFocusTo(i, removeFocus)
                          }
                        />
                      </div>
                      <div className="product-detail-search-input">
                        <PosInput
                          variant="filled"
                          id="filled-basic"
                          type="text"
                          className="product-detail-counter-sec second-counter-sec"
                          value={muName}
                          name="muName"
                          onChange={(e) => {
                            setFieldValue("muName", e.target.value);
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {(errors.priceMu && touched.priceMu) ||
                    (errors.muName && touched.muName) ? (
                    <div className="validation-error-text-sp  mt-2 int-error-text mb-0">
                      <>{errors.priceMu || errors.muName}</>
                    </div>
                  ) : null}
                </div>
                <div className="product-details-notes-main-content-section">
                  <div className="notes-added-user-name">
                    <span>{itemOUM}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100 d-flex product-detail-stock-main-div">
                      <div className="product-detail-search-input">
                        <PosInput
                          variant="filled"
                          id={itemOUM}
                          type="number"
                          className="product-detail-counter-sec first-counter-sec"
                          value={itemMU}
                          name="itemMU"
                          onChange={(e) => {
                            setFieldValue("itemMU", e.target.value);
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          onKeyDown={(e) => blockInvalidChar(e)}
                          onWheel={(e) => e?.target?.blur()}
                          inputRef={(i) =>
                            i &&
                            focusText === itemOUM &&
                            setFocusTo(i, removeFocus)
                          }
                        />
                      </div>
                      <div className="product-detail-search-input">
                        <PosInput
                          variant="filled"
                          id="filled-basic"
                          type="text"
                          className="product-detail-counter-sec second-counter-sec"
                          value={itemMuName}
                          name="itemMuName"
                          onChange={(e) => {
                            setFieldValue("itemMuName", e.target.value);
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {(errors.itemMU && touched.itemMU) ||
                    (errors.itemMuName && touched.itemMuName) ? (
                    <div className="validation-error-text-sp  mt-2 int-error-text mb-0">
                      <>{errors.itemMU || errors.itemMuName}</>
                    </div>
                  ) : null}
                </div>
                <div className="product-details-notes-main-content-section">
                  <div className="notes-added-user-name">
                    <span>{unitPerCases}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100">
                      <PosInput
                        variant="filled"
                        id={unitPerCases}
                        type="number"
                        className="product-detail-search-input input-focus-styling"
                        value={unitPerCase}
                        name="unitPerCase"
                        onChange={(e) => {
                          setFieldValue("unitPerCase", e.target.value);
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onWheel={(e) => e?.target?.blur()}
                        inputRef={(i) =>
                          i &&
                          focusText === unitPerCases &&
                          setFocusTo(i, removeFocus)
                        }
                      />
                    </div>
                  </div>
                  <ErrorMessage name="unitPerCase" />
                </div>
                <div className="product-details-notes-main-content-section">
                  <div className="notes-added-user-name">
                    <span>{minimumToOrder}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100">
                      <PosInput
                        variant="filled"
                        id={minimumToOrder}
                        type="number"
                        className="product-detail-search-input input-focus-styling"
                        value={minimumCases}
                        name="minimumCases"
                        onChange={(e) => {
                          setFieldValue("minimumCases", e.target.value);
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onWheel={(e) => e?.target?.blur()}
                        inputRef={(i) =>
                          i &&
                          focusText === minimumToOrder &&
                          setFocusTo(i, removeFocus)
                        }
                      />
                    </div>
                  </div>
                  <ErrorMessage name="minimumCases" />
                </div>
                <div className="pb-4">
                  <div className="notes-added-user-name">
                    <span>{Wholesale}</span>
                  </div>
                  <div className="characteristics-main-info price-per-each-sec">
                    <div className="w-100">
                      <PosInput
                        variant="filled"
                        id={Wholesale}
                        className={`product-detail-search-input input-focus-styling ${focusFirstInputPrice
                          ? "input-set-focus first-input-set-focus"
                          : ""
                          }`}
                        onChange={(e) =>
                          handlePriceToPercentage(
                            e,
                            "salesPrice",
                            "salesMargin",
                            vendorPrice
                          )
                        }
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              className="text-adornment ms-1 mt-0"
                            >
                              $
                            </InputAdornment>
                          ),
                        }}
                        onFocus={() => setFocusSecondInputPrice(true)}
                        onBlur={() => setFocusSecondInputPrice(false)}
                        value={salesPrice}
                        type="number"
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onWheel={(e) => e?.target?.blur()}
                        inputRef={(i) =>
                          i &&
                          focusText === Wholesale &&
                          setFocusTo(i, removeFocus)
                        }
                      />
                      <div className="price-per-each-title">
                        <span>
                          {`${PriceEach} ${muName ?? ""}(${priceMU ?? ""})`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="characteristics-main-info prof-percentage-sec">
                    <div className="w-100">
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        className={`product-detail-search-input input-focus-styling ${focusSecondInputPrice
                          ? "input-set-focus second-input-set-focus"
                          : ""
                          }`}
                        onChange={(e) =>
                          handlePercentageToPrice(
                            e,
                            "salesMargin",
                            "salesPrice",
                            vendorPrice
                          )
                        }
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              className="text-adornment ms-1  mt-0"
                            >
                              %
                            </InputAdornment>
                          ),
                        }}
                        onFocus={() => setFocusFirstInputPrice(true)}
                        onBlur={() => setFocusFirstInputPrice(false)}
                        value={salesMargin}
                        type="number"
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onWheel={(e) => e?.target?.blur()}
                      />
                      <div className="price-per-each-title">
                        <span>{Prof}</span>
                      </div>
                    </div>
                  </div>
                  <div className="small-text gray-text mt-2">
                    <span className="ml-0">{`${shortName} ${salesPriceType}`}</span>
                  </div>
                  <ErrorMessage name="salesPrice" />
                </div>
                <div className="pb-4">
                  <div className="notes-added-user-name">
                    <span>{Vendor}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100">
                      <PosInput
                        variant="filled"
                        id={Vendor}
                        className="product-detail-search-input input-focus-styling"
                        name="vendorPrice"
                        value={vendorPrice}
                        onChange={(e) => handleCommonChanges(e, "vendorPrice")}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment
                              className="text-adornment  mt-0"
                              position="start"
                            >
                              $
                            </InputAdornment>
                          ),
                        }}
                        type="number"
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onWheel={(e) => e?.target?.blur()}
                        inputRef={(i) =>
                          i &&
                          focusText === Vendor &&
                          setFocusTo(i, removeFocus)
                        }
                      />
                    </div>
                  </div>
                  <ErrorMessage name="vendorPrice" />
                </div>
                <div className="product-details-notes-main-content-section">
                  <div className="notes-added-user-name">
                    <span>{Weight}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100">
                      <PosInput
                        variant="filled"
                        id={Weight}
                        type="number"
                        className="product-detail-search-input input-focus-styling"
                        onChange={(e) => {
                          setFieldValue("weight", e.target.value);
                        }}
                        name="weight"
                        value={weight}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onWheel={(e) => e?.target?.blur()}
                        inputRef={(i) =>
                          i &&
                          focusText === Weight ?
                          setFocusTo(i, removeFocus) :  focusText === isMerquery  ? setScrollTo(i) : null
                        }
                      />
                    </div>
                  </div>
                  <ErrorMessage name="weight" />
                </div>
                <div className="product-detail-toggle-switch product-detail-info-sec-border metadata-toggle-common-spacing">
                  <PosToggle
                    label="Can be Drop Shipped"
                    value={dropshipFlag}
                    onChange={(e) =>
                      handleChange(e, "dropshipFlag", setFieldValue)
                    }
                  />
                </div>
                <div className="product-detail-toggle-switch product-detail-info-sec-border metadata-toggle-common-spacing">
                  <PosToggle
                    label="Contains Mercury"
                    value={containMercuryFlag}
                    onChange={(e) =>
                      handleChange(e, "containMercuryFlag", setFieldValue)
                    }
                  />
                </div>

              </DialogContent>
              <DialogActions className="notes-model-footer">
                <PosButton
                  variant="contained"
                  onClick={handleClose(resetForm)}
                  className="back-button first-product-detail-btn"
                >
                  {Back}
                </PosButton>
                <PosButton
                  fontWeight
                  className={`btn ml-2 second-product-detail-btn ${updateBasicProduct
                    ? "button-accent button-is-loading-black"
                    : ""
                    }`}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                  disabled={updateBasicProduct || (!dirty && isEqual)}
                >
                  {SaveChanges}
                </PosButton>
              </DialogActions>
            </Dialog>
          </>
        );
      }}
    </Formik>
  );
};

export default ProductOverviewModals;
