import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import texts from "apps/pos/locales";
import { title } from "constants/constant";
import { PosSelect } from "../Common/uiCore";
import { useGetCashOptions } from "../hooks/useCashOptions";
import { setIsApiLoading } from "../pages/customerInfo/CustomerOrders/redux/customerOrder.slice";

const CashDrawerDropdown = ({
  cashOptionsSelected,
  setCashOptionsSelected,
  setCashDrawerError,
  cashDrawerError,
}) => {
  const dispatch = useDispatch();
  const {
    getCashOptionFn,
    isSuccess: isCashOptions,
    isLoading: cashOptionsIsLoading,
    data: cashOptions,
  } = useGetCashOptions();

  useEffect(() => {
    if (!cashOptions) {
      getCashOptionFn();
    }
  }, []);

  const [cashOptionsSelect, setCashOptionsSelect] = useState<{ label: any; value: string; }[]>();
  const isApiLoading = [cashOptionsIsLoading].includes(true);
  useEffect(() => {
    dispatch(setIsApiLoading(isApiLoading));
  }, [isApiLoading]);

  useEffect(() => {
    if (cashOptions) {
      const cashOption = Object.entries(cashOptions).map(([value, label]) => ({
        label,
        value,
      }));
      setCashOptionsSelect(cashOption);
    }
  }, [isCashOptions]);

  const { cashErrorMessage } = texts;
  const { selectCashDrawer } = title;

  return (
    <div className="facility-box form-field-space arrow-black">
      <span className="int-16-reg">{selectCashDrawer}</span>
      <div className="margintop-10">
        <PosSelect
          name="cashOptionsSelected"
          variant="filled"
          id="filled-basic"
          className=" tabDropdown payment-select"
          value={cashOptionsSelected}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            if (e.target.value) {
              setCashOptionsSelected(e.target.value);
              setCashDrawerError(false);
            }
          }}
          options={cashOptionsSelect}
          placeholder={selectCashDrawer}
        />
      </div>
      {cashDrawerError && (
        <p className="validation-error-text margintop-10">{cashErrorMessage}</p>
      )}
    </div>
  );
};

export default CashDrawerDropdown;
