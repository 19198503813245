import { makeStyles } from "@material-ui/core/styles";

export const headCells = [
  {
    id: "createdAt",
    disablePadding: true,
    label: "MONTHLY DEMAND",
    left: true,
    class: "inv-date-col",
  },
  {
    id: "quoteId",
    disablePadding: false,
    label: "QTY.",
    left: true,
    class: "text-right"
  },
  {
    id: "lineId",
    disablePadding: false,
    label: "HITS",
    left: true,
    class: "text-right"
  }
];


export const headLeadCells = [
  {
    id: "createdAt",
    disablePadding: true,
    label: "MM/YY",
    left: true,
    class: "inv-date-col",
  },
  {
    id: "quoteId",
    disablePadding: false,
    label: "QTY.",
    left: true,
  }
];


export const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    border: "none",
    boxShadow:"none"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 325,
    border: "none",
  },
  visuallyHidden: {
    border: "none",
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  }
}));
