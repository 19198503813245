import { useState } from "react";
import styled from "styled-components";

import Button from "components/SPButton/Button";
import { GlobalNumbers } from "constants/enum";
import { statusDetail } from "constants/constant";
import { setDateWithFormat } from "utils/utils";

import { OpenQuote } from "apps/springboard/OpenQuote/OpenQuote";
import 'apps/springboard/QuoteDetail/QuoteDetail.scss';

interface OpenSubQuoteItemProps {
  quoteId: number;
  childrenQuote?: number;
  jobName: string;
  status: string | number;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  fullName?: string;
  date?: string;
}

const StatusCircleStyled = styled.div`
  border-radius: 50%;
  height: 6px;
  position: absolute;
  right: 2px;
  text-align: right;
  width: 6px;
`;

const StatusHoverStyled = styled.div`
  background-color: #000;
  border-radius: 5px;
  bottom: -40px;
  color: #fff;
  font-size: 12px;
  left: 40px;
  padding: 5px;
  position: absolute;
  text-align: left;
  width: max-content;
`;

export const OpenSubQuoteItem = ({
  quoteId,
  jobName,
  status,
  onClick,
  onButtonClick,
  fullName,
  date,
  childrenQuote,
}: OpenSubQuoteItemProps) => {

  const [hover, setHover] = useState(false);
  return (
    <OpenQuote
      className="d-flex py-2 px-3 align-items-start position-relative inboxlistsItem"
      onClick={onClick}
    >
      <div className="text-right position-relative subquote-dot">
        <StatusCircleStyled
          style={{
            background: statusDetail[status].color,
            height: "0.625rem",
            width: "0.625rem",
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
        {hover ? (
          <StatusHoverStyled>{statusDetail[status].value}</StatusHoverStyled>
        ) : null}
      </div>
      <div className="flex-fill ps-2">
        <p className="fw-semibold remove-margin text-black fs-14 letter-spacing-0">
          {jobName?.length > 25 ? jobName.substr(0, 24) + "..." : jobName}
        </p>
        <p className="fs-12 remove-margin letter-spacing-012">
          {fullName && date ? (
            <span>
              {`Created ${fullName ? "by " + fullName : ""}`}
              {` ${date ? "on " + setDateWithFormat(date) : ""}`}
            </span>
          ) : null}
        </p>
        <p className="fs-12 mb-2 letter-spacing-1">{quoteId}</p>
      </div>
     {childrenQuote > GlobalNumbers.ZERO && (<Button
        className="sub-quote-count hoverArrow"      
        label={`${childrenQuote} subquotes`}
        type="outline"
        size="small"
        trailingIcon="chevron_right"
        onClick={(e) => {
          e.stopPropagation();
          if (onButtonClick) onButtonClick(e);
        }}
      />)}
    </OpenQuote>
  );
};
