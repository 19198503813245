import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import {
  Button,
  Input,
  InputOptional,
  Select,
} from "../../../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";

import { Formik } from "formik";
import * as Yup from "yup";

import { paymentMethodsRequest } from "../../../sagas/setting/actions";
import { RootState } from "../../../models";
import { paymentMethodsDetails } from "../../../models/setting";
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const PaymentSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .required("Credit card number required")
    .required("Phone number required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Card number must be digits only"
    ),
  expiryDate: Yup.string().required("card expiry date required"),
  cardCVV: Yup.string().required("CVC required").min(3).max(3),
  username: Yup.string().required("Username required"),
  password: Yup.string().required("Password required"),
});

export function PaymentMethods({distributorId}) {
  const [paymentMethodsDetailsData, setPaymentMethodsDetailsData] = useState<paymentMethodsDetails>();

  const { paymentMethodsDetails } = useSelector<RootState, any>((state) => state.setting);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(paymentMethodsRequest(distributorId));
  },[]);

  useEffect(()=>{
    if(Object.keys(paymentMethodsDetails).length > 0){
      setPaymentMethodsDetailsData(paymentMethodsDetails)
    }
  },[paymentMethodsDetails])
  
  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Payment Methods</div>
        <div className="devider2px" />

        <Formik
          initialValues={{
            cardNumber: "",
            expiryDate: "",
            cardCVV: "",
            username: paymentMethodsDetailsData ? paymentMethodsDetailsData?.paypalConfigs[0]?.username : "",
            password: paymentMethodsDetailsData ? paymentMethodsDetailsData?.paypalConfigs[0]?.password : "",
          }}
          enableReinitialize
          validationSchema={PaymentSchema}
          onSubmit={() => {
            // Required Later
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => {
            return (
              <form id="addContainerForm">
                <div className="carriers-container">
                  <div className="fs-16 fw-600 text-black">Credit Card #1</div>
                  <Input
                    id={"cardNumber"}
                    name={"cardNumber"}
                    label={"Card Number"}
                    onChange={handleChange}
                    value={values.cardNumber}
                    type={"text"}
                    textInputStyle="mt-1"
                  />
                  {errors.cardNumber && touched.cardNumber ? (
                    <p className="validation-error-text">{errors.cardNumber}</p>
                  ) : null}

                  <div className="carriers-select">
                    <Input
                      className="carriers-options"
                      id={"expiryDate"}
                      name={"expiryDate"}
                      label={"Expiry date"}
                      onChange={handleChange}
                      value={values.expiryDate}
                      placeholder={"--/--"}
                      type={"text"}
                      textInputStyle="mt-1"
                    />

                    <Input
                      className="carriers-options"
                      id={"cardCVV"}
                      name={"cardCVV"}
                      onChange={handleChange}
                      value={values.cardCVV}
                      label={"CVC"}
                      type={"text"}
                      placeholder={"---"}
                      textInputStyle="mt-1"
                    />
                  </div>
                  <div className="carriers-select">
                    <div>
                      {errors.expiryDate && touched.expiryDate ? (
                        <p className="validation-error-text">
                          {errors.expiryDate}
                        </p>
                      ) : null}
                    </div>
                    <div>
                      {errors.cardCVV && touched.cardCVV ? (
                        <p className="validation-error-text">
                          {errors.cardCVV}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <InputOptional
                    textInputStyle="mt-1"
                    id={"nameOnCard"}
                    name={"nameOnCard"}
                    label={"Name on card"}
                    type={"text"}
                  />
                  <div className="carriers-default mt-2">
                    <i
                      className="fa fa-check-circle radio-button"
                      color="#000"
                    ></i>
                    <label>Set as default payment method</label>
                  </div>
                </div>

                <br></br>

                <div className="carriers-container">
                  <div className="fs-16 fw-600 text-black">Paypal</div>
                  <Input
                    id={"username"}
                    name={"username"}
                    label={"Username"}
                    onChange={handleChange}
                    value={values.username}
                    type={"text"}
                    textInputStyle="mt-1"
                  />
                  {errors.username && touched.username ? (
                    <p className="validation-error-text"><>{errors.username}</></p>
                  ) : null}
                  <Input
                    textInputStyle="mt-1"
                    id={"password"}
                    name={"password"}
                    label={"Password"}
                    onChange={handleChange}
                    value={values.password}
                    type={"text"}
                  />
                  {errors.password && touched.password ? (
                    <p className="validation-error-text"><>{errors.password}</></p>
                  ) : null}

                  <div className="carriers-select">
                    <Select
                      className="carriers-options"
                      id={"carrierDefaultShipMethod"}
                      name={"carrierDefaultShipMethod"}
                      label={"Vendor"}
                      textInputStyle="mt-1"
                      options={[{ label: "Vendor 1", value: "Vendor 1" }]}
                    />

                    <Select
                      className="carriers-options"
                      id={"carrierAttention"}
                      name={"carrierAttention"}
                      label={"Partner"}
                      textInputStyle="phoneinput mt-1"
                      options={[{ label: "Partner 1", value: "Partner 1" }]}
                    />
                  </div>

                  <div className="carriers-default mt-2">
                    <i
                      className="fa fa-check-circle radio-button"
                      color="#000"
                    ></i>
                    <label>Set as default payment method</label>
                  </div>
                </div>
                <div className="carriers-btn">
                  <i className="fa fa-plus" color="#000"></i>
                  <span>ADD PAYMENT METHOD</span>
                  <span></span>
                </div>

                <div className="devider1px" />
                <Button
                  type={"submit"}
                  onClick={handleSubmit}
                  className="manage-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#jumper_modal"
                >
                  <span className="manage-button-txt">save changes</span>
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </PerfectScrollbar>
  );
}
