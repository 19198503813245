import React from "react";
import styled from "styled-components";

const UnlockUserContentStyled = styled.div`
  padding: 0 2rem;
`;

export function UnlockUserModal() {
  return (
    <UnlockUserContentStyled>
      <h3>Add a new User</h3>
    </UnlockUserContentStyled>
  );
}
