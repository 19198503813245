import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import CheckIndicator from "./check-indicator";
import WaitingIndicator from "./waiting-indicator";

import { layoutForNotepad } from "constants/constant";
import { keys } from "utils/helper";

import { getLoggedInUser } from "sagas/user/selector";
import { getUsersNotes, updateUsersNote } from "services";

import "./notepad.scss";

const Notepad = ({ visible, setVisible }) => {

  const loggedInUser = useSelector(getLoggedInUser);

  const { docked, windowed, openNotepads, syncNotepads } = layoutForNotepad;
  const [notepadBusy, setNotepadBusy] = useState(false);
  const [showCheckIndicator, setShowCheckIndicator] = useState(true);
  const [layout, setLayout] = useState(localStorage.getItem('notepad_layout') || docked);
  const [notes, setNotes] = useState("");
  const [change, setChange] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const floating = params.get("notepad-floating");

  const typingWatcher = useRef(null);
  const ref = useRef(null);
  const newWindowRef = useRef(null); // Track the new window

  const { firstName } = loggedInUser || {};
  const lstChar = firstName?.slice(-1);
  const lastName = lstChar !== "s" ? "s" : "";
  const notePadTitle = firstName ? `${firstName}'${lastName} notepad` : 'notepad';
  const { esc } = keys;
  const isDocked = layout === docked;
  const isVisible = (isDocked && visible) ? 'visible' : 'hidden';

  const notepadChannel = useRef<BroadcastChannel | null>(null);

  useEffect(() => {
    ref.current.focus();

    if (floating) {
      setLayout(windowed);
    }

    notepadChannel.current = new BroadcastChannel('notepadChannel');
    notepadChannel.current.onmessage = listenToMessages;

    if (!layout || isDocked) {
      updateLayout(docked);
    } else {
      if (visible) openNotepadWindow();
    }

    document.addEventListener("visibilitychange", onVisibilityChange);
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("visibilitychange", onVisibilityChange);
    }

  }, [visible, floating]);

  useEffect(() => {
    if (loggedInUser) {
      getStoredNotes();
    }

    ref.current.selectionStart = ref.current.selectionEnd = notes?.length;
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [loggedInUser]);

  useEffect(() => {
    if (change) {
      if (typingWatcher.current) clearTimeout(typingWatcher.current);

      typingWatcher.current = setTimeout(() => {
        saveNotes()
      }, 400);
    }
  }, [notes]);

  useEffect(() => {
    let checkIndicatorTimeout;

    if (!notepadBusy && notes) {
      setShowCheckIndicator(true);
      checkIndicatorTimeout = setTimeout(() => {
        setShowCheckIndicator(false);
      }, 400);
    }

    return () => clearTimeout(checkIndicatorTimeout);
  }, [notepadBusy]);


  const getStoredNotes = async () => {
    const response = await getUsersNotes();
    if (response?.data) {
      const { notes } = response.data;
      setNotes(notes ?? "");
    }
  };

  const onVisibilityChange = async () => {
    if (document.visibilityState === "visible") {
      await getStoredNotes();
    }
  };

  const sendMessage = (action, message = '') => {
    notepadChannel.current?.postMessage(JSON.stringify({
      action,
      message,
    }));
  }

  const openNotepadWindow = () => {
    const MIN_WIDTH = 500;
    const WIDTH_PERCENTAGE = 40;
    const HEIGHT_PERCENTAGE = 80;

    const screenWidth = window.screen.width
    const screenHeight = window.screen.height
    const width = Math.min(MIN_WIDTH, screenWidth * WIDTH_PERCENTAGE / 100)
    const height = screenHeight * HEIGHT_PERCENTAGE / 100
    const top = (screenHeight / 2) - (height / 2);
    const left = screenWidth - width;
    const url = new URL(window.location.href);
    url.searchParams.set('notepad-floating', 'true');
    const notepadWindow = window.open(url.toString(), 'notepadWindow', `width=${width}, height=${height}, top=${top}, left=${left}`);
    newWindowRef.current = notepadWindow; // Store the reference
    setVisible(false);

    return notepadWindow;
  };

  const isWindowed = () => {
    return localStorage.getItem('notepad_layout') === 'windowed';
  }

  const updateLayout = (newLayout) => {
    setLayout(newLayout);
    localStorage.setItem('notepad_layout', newLayout);
  }

  const listenToMessages = (e: MessageEvent) => {
    if (!e.data) {
      return;
    }
    try {
      const { action, message } = JSON.parse(e.data);
      switch (action) {
        case openNotepads:
          handleOpenNotepad(message);
          break;
        case syncNotepads:
          setNotes(message);
          break;
        default:
          console.warn(`Unhandled notepad action: ${action}`);
      }
    } catch (error) {
      console.error("Error processing notepad message:", error);
    }
  };

  const handleOpenNotepad = (message) => {
    if (floating) return
    updateLayout(docked);
    setVisible(true);
    setNotes(message);
  }

  const saveNotes = async (note?: string) => {
    setNotepadBusy(true);
    const data = note ?? notes;
    const body = JSON.stringify({ notes: data });
    const response = await updateUsersNote(body);

    if (response.data) {
      setTimeout(() => {
        setNotepadBusy(false);
      }, 600);
    }

  }
  const onKeyDown = (e) => {
    if (e.key === " " && e.ctrlKey) {
      if (e.repeat) return;
      onShortcutKeypress();
    }
    if (e.keyCode === esc) {
      onEscapePress();
    }
  };

  const onShortcutKeypress = () => {
    if (isWindowed()) {
      if (floating) {
        window.close();
      } else {
        openNotepadWindow();
      }
    } else {
      setVisible(!visible);
    }
  };

  const onEscapePress = () => {
    if (floating) return;
    setVisible(false);
  };

  const closeNotepad = () => {
    if (isWindowed()) {
      window.close()
    }
    else {
      closeOverlay()
    }

  };

  const closeOverlay = () => {
    setVisible(false);
  }

  const handleKeyDown = (e) => {
    if (e.key == " " && e.ctrlKey) {
      setVisible(false);
    }
    e.stopPropagation();
    if (e.repeat) return;
    if (e.keyCode !== esc) return;
    setVisible(false);
  };

  const openInNewWindow = () => {
    updateLayout(windowed);
    openNotepadWindow();
  }

  const dockButton = () => {
    if (notepadBusy) return
    updateLayout(docked);
    sendMessage(openNotepads, notes);
    window.close()
  }

  const onNotesChange = (value) => {
    setChange(true);
    setNotes(value);
  }

  return (
    <>
      <div
        className={`notepad-wrapper ${floating ? ' floating visible' : isVisible}`}
        id="notepad-div"
      >
        <header className="notepad-wrapper-header">
          <button className="notepad-close" onClick={closeNotepad}>
            <svg className="notepad-closebtn-close-icon" width="20" height="20" viewBox="0 0 20 20" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1353_725)">
                <path
                  d="M15.2501 4.75831C14.9251 4.43331 14.4001 4.43331 14.0751 4.75831L10.0001 8.82498L5.9251 4.74998C5.6001 4.42498 5.0751 4.42498 4.7501 4.74998C4.4251 5.07498 4.4251 5.59998 4.7501 5.92498L8.8251 9.99998L4.7501 14.075C4.4251 14.4 4.4251 14.925 4.7501 15.25C5.0751 15.575 5.6001 15.575 5.9251 15.25L10.0001 11.175L14.0751 15.25C14.4001 15.575 14.9251 15.575 15.2501 15.25C15.5751 14.925 15.5751 14.4 15.2501 14.075L11.1751 9.99998L15.2501 5.92498C15.5668 5.60831 15.5668 5.07498 15.2501 4.75831Z"
                  fill="#105223" />
              </g>
              <defs>
                <clipPath id="clip0_1353_725">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <svg className="notepad-closebtn-back-icon" width="20" height="20" viewBox="0 0 20 20" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1353_731)">
                <path
                  d="M12.2584 5.59165C11.9334 5.26665 11.4084 5.26665 11.0834 5.59165L7.2584 9.41665C6.9334 9.74165 6.9334 10.2667 7.2584 10.5917L11.0834 14.4166C11.4084 14.7416 11.9334 14.7416 12.2584 14.4166C12.5834 14.0916 12.5834 13.5667 12.2584 13.2417L9.02507 9.99998L12.2584 6.76665C12.5834 6.44165 12.5751 5.90832 12.2584 5.59165Z"
                  fill="#105223" />
              </g>
              <defs>
                <clipPath id="clip0_1353_731">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>

          </button>
          <div className="notepad-title-group">
            <p className="notepad-title">{notePadTitle}</p>
            <div className={`busy-indicator ${(notepadBusy) ? "indicator-op-1" : "indicator-op-0"}`}>
              <WaitingIndicator busy={notepadBusy} />
              <CheckIndicator show={showCheckIndicator && !notepadBusy} />
            </div>
          </div>
          <div className="notepad-actions">
            {
              isDocked ?
                <button type="button" className="notepad-window-btn" title="Open in a new window" onClick={openInNewWindow}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 2H14.8C15.9201 2 16.4802 2 16.908 2.21799C17.2843 2.40973 17.5903 2.71569 17.782 3.09202C18 3.51984 18 4.0799 18 5.2V11H11V2Z"
                      fill="#105223" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4 2C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V14H16V16H4V4H8V2H4Z"
                      fill="#105223" />
                  </svg>
                </button> :
                <button type="button" className="notepad-dock-btn" title="Dock inside page" onClick={dockButton}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10 2H14.8C15.9201 2 16.4802 2 16.908 2.21799C17.2843 2.40973 17.5903 2.71569 17.782 3.09202C18 3.51984 18 4.07989 18 5.2V14.8C18 15.9201 18 16.4802 17.782 16.908C17.5903 17.2843 17.2843 17.5903 16.908 17.782C16.4802 18 15.9201 18 14.8 18H10V2Z"
                      fill="#105223" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2 4C2 2.89543 2.89543 2 4 2H8V4H4V16H8V18H4C2.89543 18 2 17.1046 2 16V4Z"
                      fill="#105223" />
                  </svg>
                </button>
            }
          </div>
          <div className="divider"></div>
        </header>
        <textarea
          className="notepad-wrapper-textarea"
          spellCheck="false"
          placeholder="Make a note..."
          id="notepad-content"
          value={notes}
          onChange={(e) => {
            sendMessage(syncNotepads, e.target.value)
            onNotesChange(e.target.value)
          }}
          ref={ref}
          onKeyDown={handleKeyDown}
        ></textarea>
      </div>
      <div
        className={`notepad-overlay ${floating ? 'floating visible' : isVisible}`}
        onPointerDown={closeOverlay}
        id="overlay"
      ></div>
    </>
  );
};

export default Notepad;
