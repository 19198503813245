import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import { Button } from "../../../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";

import { subscriptionRequest } from "../../../sagas/setting/actions";
import { RootState } from "../../../models";
import { subscriptionDetails } from "../../../models/setting";
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function Subscription({distributorId}) {

  const [subscriptionDetailsData, setSubscriptionDetailsData] = useState<subscriptionDetails>();

  const { subscriptionDetails } = useSelector<RootState, any>((state) => state.setting);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(subscriptionRequest(distributorId));
  },[]);

  useEffect(() => {
    setSubscriptionDetailsData(subscriptionDetails);
  },[subscriptionDetails])  


  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="subscription-heading">
          <div className="leftbarTitle">Subscription</div>
          <button className="subscription-btn">VIEW PAYMENT HISTORY</button>
        </div>
        <div className="devider2px" />
        <div className="subscription-header">
          <div className="subscription-header-left">
            <div>
              <h2 className="fs-16 fw-600 text-black">Total Users</h2>
            </div>
            <div>
              <h1 className="fs-24 fw-600 text-black">{subscriptionDetailsData && subscriptionDetailsData.maxUserCount}</h1>
            </div>
          </div>

          <Button
            type={"submit"}
            className="manage-btn"
            data-bs-toggle="modal"
            data-bs-target="#jumper_modal"
            buttonStyle="light"
          >
            <span className="manage-lightbutton-txt">Manage Users</span>
          </Button>
        </div>
        <div className="devider1px" />
        <br></br>
        <h1 className="fs-16 fw-600 text-black">Billing Frequency</h1>
        <div className="subscription-types">
          <div className="d-flex">
            <i
              className="fa fa-check-circle fa-lg radio-button"
              color="#000"
            ></i>
            <div className="plan">
              <h1 className="fs-16 fw-600 text-black">Monthly</h1>
              <h3 className="fs-16 fw-600 text-black">
                $50 x 3 users x 1 month{" "}
              </h3>
            </div>
          </div>
          <div className="price">
            <h1>$150</h1>
          </div>
        </div>

        <div className="subscription-types">
          <div className="d-flex">
          <i
            className="fa fa-check-circle fa-lg radio-button"
            aria-hidden="true"
          ></i>
          <div className="plan">
            <h1 className="fs-16 fw-600 text-black">Annual</h1>
            <h3 className="fs-16 fw-600 text-black">$50 x 3 users x 12 month </h3>
          </div>
          </div>
          <div className="price">
            <h1>$1800</h1>
          </div>
        </div>
        <div className="subscription-text">
          <h5 className="fs-16 fw-600 text-black">
            A piece of important information about cancellation and refunds goes
            here in this place.
          </h5>
        </div>

        <div className="devider1px" />

        <Button
          type={"submit"}
          className="manage-btn"
          data-bs-toggle="modal"
          data-bs-target="#jumper_modal"
        >
          <span className="manage-button-txt">save changes</span>
        </Button>
      </div>
    </PerfectScrollbar>
  );
}
