import { useEffect, useRef } from 'react'
import { motion, useMotionTemplate, useScroll, useSpring, useTransform } from 'framer-motion/dist/framer-motion'

import NotesInput from '../NotesInput/'
import NotesItem from '../NotesItem'
import IconButton from '../Elements/IconButton'
import {setFocusTo} from "utils/utils"

import './NotesManager.scss'

const NotesManager = (props) => {
    const { visible, setVisible, notes, focusElement , removeAllNotes , removeNote , updateNotes , loginUerName } = props
    const ref = useRef()

    useEffect(() => {
        if (visible) {
            if (ref.current.open) return
            ref.current?.showModal()
        }
        else {
            ref.current?.close()
        }
    }, [visible])

    useEffect(() => {
        ref.current?.addEventListener('close', closeDialog)

        return () => {
            ref.current?.removeEventListener('close', closeDialog)
        }
    }, [])

    useEffect(() => {
        if (!focusElement) return
        const element = document.getElementById(focusElement)
        setFocusTo(element)
    }, [focusElement])

    const closeDialog = () => {
        setVisible(visible)
    }

    const removeNotes = (id) => {
        let permission = window.confirm(`Are you sure you want to delete this note? This action cannot be undone.`)
        if (!permission) return
        removeNote(id)
    }

    const removeAllNote = () => {
        let permission = window.confirm(`Are you sure you want to remove ${notes.length > 1 ? 'all' : ''} ${notes.length} notes? This action cannot be undone.`)
        if (!permission) return
        removeAllNotes()
    }


    const { scrollY } = useScroll({ container: ref })
    const yInitial = useTransform(scrollY, [200, 250], [-20, 0])
    const opacity = useTransform(scrollY, [200, 250], [0, 1])
    const borderColor = useTransform(scrollY, [0, 50], ['#fff', '#ddd'])
    const physics = {
        stiffness: 1000,
        damping: 100,
    }
    const y = useSpring(yInitial, physics)

    const scrollToTop = () => {
        ref.current?.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <motion.div
        className="backdrop"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
              <motion.dialog
            ref={ref}
            className='notes-dialog'
            
        >
            <motion.header
                className="dialog-header"
                style={{ borderBottom: useMotionTemplate`1px solid ${borderColor}` }}
            >
                <h3>Notes</h3>
                <IconButton
                    classes='close-btn'
                    secondary={true}
                    title='Close'
                    icon='close'
                    onClick={closeDialog}
                />

                <motion.div
                    className='dialog-back-to-top'
                    style={{ opacity, y }}
                >
                    <IconButton
                        classes='dialog-back-to-top-btn'
                        title='Back to top'
                        icon='expand_less'
                        tabIndex={-1}
                        onClick={scrollToTop}
                    />
                </motion.div>
            </motion.header>

            <div className="dialog-body">
                <NotesInput
                    placeholder="Add a new note..."
                    autoFocus={true}
                    showIcon={true}
                    id='add-new-note-input'
                    isNew
                    updateNotes={updateNotes}
                />

                <NotesManagerControls
                    count={notes?.length}
                    removeAll={removeAllNote}
                />

                {notes?.map(note => (
                    <NotesItem
                        key={note.noteId}
                        note={note}
                        remove={() => removeNotes(note?.noteId)}
                        updateNotes={updateNotes}
                        loginUerName={loginUerName}
                    />
                ))}
            </div>
        </motion.dialog>
        </motion.div>
       
    )
}

export default NotesManager

const NotesManagerControls = (props) => {
    const { count, removeAll } = props
    if (!count) return null
    return (
        <div className='notes-manager-controls'>
            <p className='notes-manager-count'>
                {count} added
            </p>
            <button className='notes-manager-btn' onClick={removeAll}>Remove all</button>
        </div>
    )
}
