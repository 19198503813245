const WaitingIndicator = ({ busy }) => {
  return (
    <svg
      className={`waiting-indicator ${busy ? "indicator-op-1" : "indicator-op-0"}`}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
    >
      <path
        d="M7.531,0A1.263,1.263,0,0,0,6.268,1.263v3.79A1.263,1.263,0,0,0,7.531,6.317c.7,0,1.263-1.263,1.263-1.263V1.263A1.263,1.263,0,0,0,7.531,0Z"
        transform="translate(4.476)"
        fill="#105222"
      />
      <path
        d="M3.1,1.831a1.253,1.253,0,0,0-.888.375,1.275,1.275,0,0,0,0,1.8L4.893,6.667A1.256,1.256,0,0,0,6.668,4.891L4,2.206A1.3,1.3,0,0,0,3.1,1.831Z"
        transform="translate(1.313 1.308)"
        fill="#105222"
        opacity="0.9"
      />
      <path
        d="M13.066,1.831a1.3,1.3,0,0,0-.909.375L9.494,4.891A1.256,1.256,0,0,0,11.27,6.667L13.954,4a1.274,1.274,0,0,0,0-1.8,1.253,1.253,0,0,0-.888-.375Z"
        transform="translate(6.539 1.308)"
        fill="#105222"
        opacity="0.3"
      />
      <path
        d="M1.263,6.264a1.263,1.263,0,1,0,0,2.527h3.79a1.263,1.263,0,1,0,0-2.527Z"
        transform="translate(0 4.474)"
        fill="#105222"
        opacity="0.8"
      />
      <path
        d="M11.579,6.264a1.263,1.263,0,1,0,0,2.527h3.79a1.263,1.263,0,1,0,0-2.527Z"
        transform="translate(7.367 4.474)"
        fill="#105222"
        opacity="0.4"
      />
      <path
        d="M4.888,9.489C4.422,9.947,2.2,12.153,2.2,12.153A1.27,1.27,0,1,0,4,13.95l2.664-2.684a1.3,1.3,0,0,0,0-1.776,1.335,1.335,0,0,0-1.766,0Z"
        transform="translate(1.308 6.538)"
        fill="#105222"
        opacity="0.7"
      />
      <path
        d="M9.5,9.488a1.3,1.3,0,0,0,0,1.776l2.664,2.684a1.27,1.27,0,1,0,1.8-1.8L11.271,9.488a1.347,1.347,0,0,0-1.766,0Z"
        transform="translate(6.53 6.54)"
        fill="#105222"
        opacity="0.5"
      />
      <path
        d="M7.526,10.318a1.263,1.263,0,0,0-1.263,1.263v3.79a1.263,1.263,0,0,0,2.527,0v-3.79a1.263,1.263,0,0,0-1.255-1.265Z"
        transform="translate(4.473 7.367)"
        fill="#105222"
        opacity="0.6"
      />
    </svg>
  );
};

export default WaitingIndicator;
