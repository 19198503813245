import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import renderHtml from "react-render-html";
import Avvvatars from "avvvatars-react";

import { ToastContainer, Toast } from "components";
import { useMediaQuery } from "apps/admin/hooks/useMediaQuery";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";

import { quoteDetailMessage } from "models";
import { quoteMessagesSortBy } from "utils/data";
import { springBoardText } from "constants/constant";
import { displayDateFormatValue, setDateWithFormat } from "utils/utils";

import { getMessagesRequest } from "sagas/quoteDetail/actions";
import { getAlertMessage, getAlertShow, getIsErrorAlert, getQuoteMessages, getQuoteMessagesLoading } from "sagas/quoteDetail/selector";

import "react-perfect-scrollbar/dist/css/styles.css";
import Button from "components/SPButton/Button";
import { SendMessage } from "../Component/SendMessage";
import { setOpenMiniNote } from "sagas/quoteDetail/quoteDetailsSlice";

interface quoteMessageProps {
  quoteId: number;
}

export const QuoteMessages = ({ quoteId }: quoteMessageProps) => {
  const dispatch = useDispatch();

  const quoteMessages = useSelector(getQuoteMessages)
  const alertShow = useSelector(getAlertShow)
  const alertMessage = useSelector(getAlertMessage)
  const isErrorAlert = useSelector(getIsErrorAlert)
  const quoteMessagesLoading = useSelector(getQuoteMessagesLoading)
  const [sortIndex, setSortIndex] = useState(0);
  const [sortedArray, setSortedArray] = useState([]);
  const { newMessage, message, noMessageSent, newestFirst, sortBy } =
    springBoardText;
  const [isOpenSendMessage, setIsOpenSendMessage] = useState(false);

  useEffect(() => {
    dispatch(getMessagesRequest(quoteId));
  }, [quoteId]);

  useEffect(() => {
    setSortedArray([...quoteMessages].reverse());
    if (sortIndex === 1) {
      setSortedArray([...sortedArray].reverse());
    }
  }, [quoteMessages, sortIndex]);

  useEffect(() => {
    if (isOpenSendMessage) {
      dispatch(setOpenMiniNote(true));
    } else {
      dispatch(setOpenMiniNote(false));
    }
  }, [isOpenSendMessage]);

  const handleCloseModel = () => {
    setIsOpenSendMessage(false);
  };

  const handleMessageModal = () => {
    setIsOpenSendMessage(true);
  };

  return (
    <>
      <div className="quote-details-messages">
        <div className="d-flex align-items-center justify-content-between mb-4">
          {alertShow && (
            <ToastContainer>
              <Toast
                children={alertMessage}
                status={isErrorAlert ? "Error" : "Success"}
              />
            </ToastContainer>
          )}

          <div className="d-flex align-items-center">
            <div className="fw-medium fs-14">
              <span>
                {quoteMessages?.length
                  ? `${quoteMessages.length} ${message}${
                      quoteMessages.length > 1 ? "s" : ""
                    }`
                  : noMessageSent}
              </span>
            </div>
            {quoteMessages?.length ? (
              <div className="dropdown ms-2">
                <button
                  className="fw-medium fs-14 text-dark btn btn-link p-0 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {`${sortBy} ${
                    sortIndex ? quoteMessagesSortBy[sortIndex] : newestFirst
                  }`}
                </button>

                <div
                  className="dropdown-menu selectList"
                  abyria-labelledby="dropdownMenuButton"
                >
                  {quoteMessagesSortBy.map((option, index) => (
                    <button
                      onClick={() => setSortIndex(index)}
                      className={`btn btn-link ${
                        index === sortIndex ? "active" : ""
                      }`}
                      key={index}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          <div className="header-actions">
            <Button
              label={newMessage}
              leadingIcon="add"
              type="secondary"
              size="small"
              onClick={handleMessageModal}
            />
          </div>
        </div>
        {quoteMessagesLoading ? (
          <ShapedSkeleton
            shape="0 0 26 26 26, 0 134 26 26 26, 46 0 200 14, 46 134 200 14, 621 0 100 14, 621 134 100 14, 46 22 100 14, 46 156 100 14, 46 52 400 10, 46 186 400 10, 46 70 200 10, 46 204 200 10"
            width={721}
            height={214}
            radius={4}
            classes="mt-3"
          />
        ) : (
          <div className="rfi-list-container">
            {sortedArray && sortedArray?.length > 0
              ? sortedArray.map((message, index) => (
                  <>
                    <QuoteMessagesItem messageData={message} key={index} />
                    <div className="divider-grey mb-2" />
                  </>
                ))
              : null}
          </div>
        )}
      </div>
      {isOpenSendMessage && (
        <SendMessage
          quoteId={quoteId}
          open={isOpenSendMessage}
          handleCloseModel={handleCloseModel}
        />
      )}
    </>
  );
};

interface quoteMessagesItemProps {
  messageData: quoteDetailMessage;
}

export const QuoteMessagesItem = ({ messageData }: quoteMessagesItemProps) => {
  const isMobile = useMediaQuery("(max-width: 800px)");
  const avatarBgColor = "#fff";

  const { fromEmail, sendTime, subj, msgBody, toEmail } = messageData || {};
  const startIndex = fromEmail ? fromEmail?.indexOf("<") : 0;
  const name =
    startIndex !== -1
      ? fromEmail?.slice(0, startIndex).trim()
      : fromEmail?.trim();

  return (
    <>
      <div className="message-line mb-2">
        <div>
          <Avvvatars
            value={fromEmail}
            style="shape"
            size={isMobile ? 40 : 45}
            border
            borderSize={2}
            borderColor={avatarBgColor}
          />
        </div>
        <div className="message-content">
          <p className="sender">
            <span className="message-name-text">{name}</span>
            <span className={`message-date-text ${name ? "ml-2" : ""}`}>
              {setDateWithFormat(
                sendTime,
                displayDateFormatValue.monthAndYearTimeFormat
              )}
            </span>
          </p>
          <p className="message-name-text">{subj}</p>
          <p className="message-body-text">{renderHtml(msgBody)}</p>
        </div>
        <div>
          <p className="message-email-text">{toEmail}</p>
        </div>
      </div>
    </>
  );
};
