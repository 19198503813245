import "@fontsource/inter";
import { Formik } from "formik";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AttachedDocumentModal from "apps/pos/AddNewCustomer/AttachedDocumentModal/AttachedDocumentModal";
import MaskInput from "apps/pos/Common/maskInput/maskInput";
import { Container, PosButton, PosCheckbox, PosImage, PosInput, PosPageHeading, PosSelect, PosSnackbar } from "apps/pos/Common/uiCore";
import { ErrorMessage } from "apps/pos/formik/errorMessage";
import AddDocumentModal from "apps/pos/pages/customerInfo/Documents/addDocument/AddDocumentModal";
import SelectTakerModal from "apps/pos/SelectTakerModal/SelectTakerModal";

import {
  addCustomerDetails, addCustomerForm, addTextForm
} from "apps/pos/constants";
import { AddCustomerSchema } from "apps/pos/formik/validationSchema";
import texts from "apps/pos/locales";
import { COUNTRY_CODE, customerCodeRegex, numberRegex, numbersOnlyRegEx, numericOnlyRegex, title } from "constants/constant";
import { documentByModule } from "constants/enum";
import { ArrowBackRoundedIcon, CheckIcon, PersonAddIcon } from "constants/icons";
import images from "constants/images";
import { PosFlowDataProp, ScheduleDataProps, StateDataProps, UploadedDocumentItemProp } from "constants/interfaces";
import { addCustomerLabel } from "constants/posLabel";
import { Label, Paths } from "enums";
import { PosResponseProps, RootState } from "models";
import { blockInvalidChar, blockInvalidCharForPhone, changestringVal, getStringifyData, phoneNumberFormatNew } from "utils/utils";

import { addNewCustomerClear, addNewCustomerModel, addNewCustomerRequest, loadCartRequest, setCustomerAlertHide, setPosFlowData, shippingAddressSelectedData } from "sagas/persistReducer/actions";
import {
  priceSchedulesRequest, setJobNameForAll, setMarkAsQuoteReducer, setShippingInstructionNote, setStoreCreditAmout
} from "sagas/pos/actions";
import { codeExists } from "services";

import "./addNewCustomer.scss";

const AddNewCustomer = ({ state, setState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabCount, setTabCount] = useState(addCustomerForm);
  const [showTakerHere, setShowTakerHere] = useState(false);
  const [stateName, setStateName] = useState("");
  const [statesData, setStatesData] = useState([]);
  const [priceSchedulesData, setpriceSchedulesData] = useState([]);
  const [extCode, setExtCode] = useState("");
  const [pricingScheduleSelected, setPricingScheduleSelected] = useState(null);
  const [personIdToBeSent, setPersonIdToBeSent] = useState(0);
  const [checkValidation, setCheckValidation] = useState(false);
  const [fullNameToSet, setFullNameToSet] = useState("");
  const [taxOpen, setTaxOpen] = useState(false);
  const [showDraftDrawer, setShowDraftDrawer] = useState(false);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [priceCheck, setPriceCheck] = useState(true);
  const [openModel, setOpenModel] = useState(false);
  const [creditCard, setCreditCard] = useState(0);

  const { showPrices, fixMarginRates, freeShip, onlyCreditCard, selectPriceSchedule, priceSchedule, option } = addCustomerLabel;
  const handlePriceCheck = () => {
    setPriceCheck(!priceCheck);
  };
  const handleOnChange = (value, keyName, setFieldValue) => {
    setFieldValue(keyName, value);
  };

  const hiddenFileInput = useRef(null);
  const {
    getStatesData,
    priceScheduleDetails,
    error,
  } = useSelector<RootState, PosResponseProps>((state) => state.pos);
  const { loadCartDetails, posFlowData, customerAddSuccess, errorAddNewCustomer, newCustomerData, isOpenAddCustomer } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const [customerPosValid, setCustomerPosValid] = useState(false);
  const { cartId, customer: loadCustomer } = loadCartDetails || {};
  const { errorNewCustomer } = texts;
  const { customer } = documentByModule;
  const { priceVisible } = posFlowData || {};

  useEffect(() => {
    if (!priceScheduleDetails?.data?.length) {
      dispatch(priceSchedulesRequest({ pageSize: 1, size: 100 }));
    }
    if (priceVisible === undefined) {
      const data = { ...posFlowData, priceVisible: true };
      dispatch(setPosFlowData(data));
    }
  }, []);

  useEffect(() => {
    setStatesData(getStatesData);
  }, [getStatesData, isOpenAddCustomer]);

  useEffect(() => {
    if (priceScheduleDetails?.data?.length) {
      setpriceSchedulesData(priceScheduleDetails?.data);
    }
  }, [priceScheduleDetails]);

  const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));
  const currentTaker = JSON.parse(localStorage.getItem("taker"));

  //Might need in future
  // const handleKeyDown = (e) =>{
  //   const { keyCode } = e;
  //   const {esc} = keys
  //   if(keyCode === esc){
  //       setState({ ...state, right: false });
  //       dispatch(addNewCustomerClear());
  //   }
  // }

  // useEffect(() => {
  //   document?.addEventListener("keyup", handleKeyDown);
  //   return function cleanup() {
  //     document?.removeEventListener("keyup", handleKeyDown);
  //   };
  // }, []);

  useEffect(() => {
    if (!customerAddSuccess && currentTaker === null) {
      setTabCount(0);
      setShowTakerHere(true);
      setPersonIdToBeSent(newCustomerData);
    } else {
      const preparedData = {
        stockWarehouseId: currentWorkspace?.stockWarehouseId,
        customerId: newCustomerData,
        takerId: currentTaker?.personId,
        cartId: null,
      };
      const data = {
        ...posFlowData,
        customer: {
          fullName: fullNameToSet,
          personId: newCustomerData,
        },
        addDocument: [],
        poNumber: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        companyName: "",
        viaSwitchCustomer: false,
        scheduleAdded: false
      };
      dispatch(setShippingInstructionNote(""));
      dispatch(setJobNameForAll(""));
      dispatch(setMarkAsQuoteReducer(false));
      dispatch(setCustomerAlertHide(true));
      dispatch(setStoreCreditAmout(""));
      if (customerAddSuccess) {
        if (!creditCard) {
          setState({ ...state, right: false });
          dispatch(addNewCustomerModel(true));
        } else {
          dispatch(setPosFlowData(data));
          setState({ ...state, right: true });
          dispatch(loadCartRequest(preparedData));
          setShowDraftDrawer(true)
        }
      }

    }
  }, [customerAddSuccess]);

  useEffect(() => {
    if (showDraftDrawer) {
      setShowDraftDrawer(false);
      Boolean(cartId) && navigate(`${Paths.posCart}/${cartId}`);
      const data = {
        ...posFlowData,
        customer: {
          fullName: fullNameToSet,
          personId: newCustomerData,
          ...loadCustomer
        },
        addDocument: []
      };
      dispatch(setPosFlowData(data));
    }
  }, [loadCartDetails]);

  useEffect(() => {
    if (errorAddNewCustomer) {
      setIsSnackbarVisible(true)
      setTimeout(() => {
        setIsSnackbarVisible(false)
        dispatch(addNewCustomerClear());
      }, 2000);
    }
  }, [errorAddNewCustomer]);

  const handleSubmitTaxForm = () => {
    setTabCount(addCustomerDetails);
  };

  const handleChangeModel = () => {
    setTaxOpen(true);
  };

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const { filledBlackAddUser, plusWhiteIcon, greenPartiallyFilled } = images;

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });
  // start
  const onSubmitCustomerData = async (data) => {
    const uploadData = posFlowData?.addDocument?.length &&
      posFlowData?.addDocument.map((item: UploadedDocumentItemProp) => {
        const { file, description, typeId, documentSaveType, locationSaveType, uploadedBy } = item
        return {
          fileName: file,
          description: description,
          typeId: typeId,
          documentSaveType: documentSaveType,
          locationSaveType: locationSaveType,
          uploadedBy: uploadedBy,
        }
      });
    const {
      firstName,
      lastName,
      companyName,
      companyAddress,
      city,
      zip,
      emailAddress,
      phoneNumber,
      customerCode,
      fixMarginRate,
      freeShipCustFlag,
      creditCardOnlyFlag
    } = data;
    const newNumber = phoneNumber && phoneNumber?.replace(/\s+/g, "");
    const areaCodeInfo =
      newNumber && newNumber?.substring(0, 3);
    const prefixInfo =
      newNumber && newNumber?.substring(3, 6);
    const lineNumInfo =
      newNumber && newNumber?.substring(6, 10);
    const formData = new FormData();

    const customerAddedData = {
      firstName,
      lastName,
      companyName,
      address: {
        street: companyAddress,
        city: city,
        postalCode: zip,
        state: stateName,
        country: 'USA',
        extended: '',
      },
      email: emailAddress,
      priceScheduleId: pricingScheduleSelected,
      posCustCode: customerCode,
      showPrices: priceCheck,
      fixMarginCustFlag: fixMarginRate ? (Number(fixMarginRate) > 0 ? 1 : 0) : 0,
      freeShipCustFlag: freeShipCustFlag ? 1 : 0,
      creditCardOnlyFlag: creditCardOnlyFlag ? 1 : 0,
      fixMarginRate: fixMarginRate ? Number(fixMarginRate) : 0,
      phone: {
        intCode: '1',
        areaCode: areaCodeInfo,
        prefix: prefixInfo,
        lineNum: lineNumInfo,
        extn: extCode,
        type: 'DIRECT1',
      },
      uploadFiles: uploadData ? uploadData : null
    }
    setCreditCard(creditCardOnlyFlag ? 1 : 0)
    const formFiles = [];
    posFlowData?.addDocument?.length &&
      posFlowData?.addDocument.map((item: UploadedDocumentItemProp) => {
        const { file, description, typeId, documentSaveType, locationSaveType, uploadedBy, originalFile } = item
        formFiles.push(originalFile);
        const fileUploadedData = {
          file: file,
          description: description,
          typeId: typeId,
          documentSaveType: documentSaveType,
          locationSaveType: locationSaveType,
          uploadedBy: uploadedBy
        }
        return fileUploadedData
      });

    const customerDataFormat = getStringifyData(getStringifyData(customerAddedData))
    formData.append('posNewCustomerRequest', customerDataFormat);

    posFlowData?.addDocument.forEach((item: UploadedDocumentItemProp) => {
      const { originalFile } = item;
      if (originalFile) {
        formData.append('formFiles', originalFile);
      }
    });

    dispatch(shippingAddressSelectedData(false));
    dispatch(addNewCustomerRequest(formData));
  }
  // end
  const phoneCountyCode = [{ label: COUNTRY_CODE, value: COUNTRY_CODE }]
  const setFirstLastName = (e, keyName, setFieldValue) => {
    if (
      !e.target.value.includes("  ") &&
      e.target.value.charAt(0) !== " "
    ) {
      setFieldValue(keyName, e.target.value);
    }
  }

  // Create a new timeout
  let inputTimeout = null;

  // Create a variable to store old text
  let oldInputText = '';

  const handleInputChange = (e, name, setFieldValue) => {

    if (
      e.target.value === "" ||
      customerCodeRegex.test(e.target.value)
    ) {
      // set value in form
      setFirstLastName(e, name, setFieldValue);

      // Clear the timeout on keypress
      clearTimeout(inputTimeout);
      // Set a new timeout for 300ms
      // Get new value from the field, and trim any extra spaces
      inputTimeout = setTimeout(() => {
        const newInput = e.target.value.trim();
        // If new input is different from oldInputText, execute the following
        if (newInput !== oldInputText) {
          // API CALL
          codeExists(newInput).then((res) => {
            if (res?.data && name === "customerCode") {
              setCustomerPosValid(true)
            }
            else {
              setCustomerPosValid(false);
            }
          });
        }

        // Set current text to oldInputText
        oldInputText = newInput;
      }, 1000);
    }
  };

  const onExtCodeChange = (value, setFieldValue) => {
    blockInvalidCharForPhone(value)
    const numericValue = value.replace(numericOnlyRegex, '');
    setFieldValue('extCode', numericValue);
    if (numbersOnlyRegEx.test(value)) {
      setExtCode(value);
      setFieldValue('extCode', value);
    }
  }
  return (
    <Container>
      {!showTakerHere ? (
        <div className="progress-bar-new-customer">
          <div className="progress-bar-step progress-fill"></div>
          <div
            className={`progress-fill
                ${tabCount === addTextForm ||
                tabCount === addCustomerDetails
                ? "fill-progress-bar"
                : "progress-bar-steps"}
              `}
          />
          <div
            className={`progress-fill
                ${tabCount === addCustomerDetails
                ? "fill-progress-bar"
                : "progress-bar-steps"}
              `}
          />
        </div>
      ) : (
        <></>
      )}
      <PosSnackbar
        open={isSnackbarVisible}
        message={error || errorNewCustomer}
        onClose={() => {
          setIsSnackbarVisible(false);
        }}
      />
      {!showTakerHere ? (
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            customerCode: "",
            emailAddress: "",
            phoneNumber: null,
            extCode: null,
            companyName: "",
            companyAddress: "",
            city: "",
            state: "",
            zip: null,
            pricingSchedule: "",
            countryCode: COUNTRY_CODE,
            priceCheck: true,
            fixMarginRate: "",
            freeShipCustFlag: false,
            creditCardOnlyFlag: false,
          }}
          validationSchema={AddCustomerSchema}
          enableReinitialize
          onSubmit={() => {
            {
              stateName && pricingScheduleSelected
                ? setTabCount(addTextForm)
                : setTabCount(addCustomerDetails);
            }
          }}
        >
          {({ handleSubmit, values, handleChange, setFieldValue }) => {

            const { extCode, phoneNumber, countryCode } = values
            const { documentStep, attachDocument, completeRegistration, registeredAccountInfo, addNewCustomer, firstDetailStep } = title
            const { firstName, posCustCode, emailAddress, lastName, phoneNumberInfo, companyName, zip } = Label;

            return (
              <>
                {tabCount === addCustomerForm ? (
                  <>
                    <div className="pricing-modal-header-address">
                      <div className="payment-option-heading">
                        <div>
                          <PosPageHeading
                            heading={addNewCustomer}
                            grayHeading
                            className="mb-1 lts-1"
                          />
                          <PosPageHeading
                            className="lts-1"
                            heading={firstDetailStep}
                            blackHeading
                          />
                        </div>
                        <PersonAddIcon className="mr-1" />
                      </div>
                    </div>
                    <div className="registered-common-info">
                      <PosImage src={greenPartiallyFilled} alt='register-icon' className="mr-2" />
                      <span>
                        {registeredAccountInfo}
                      </span>
                    </div>
                    <div className="facility-box mt-5">
                      <span className="form-field-title">{firstName}</span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 tabInput"
                        name="firstName"
                        autoFocus
                        onChange={(e) => setFirstLastName(e, 'firstName', setFieldValue)}
                        value={values.firstName}
                      />
                      <ErrorMessage name="firstName" />
                    </div>
                    <div className="facility-box form-field-space">
                      <span className="form-field-title">{lastName}</span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 tabInput"
                        name="lastName"
                        onChange={(e) => setFirstLastName(e, 'lastName', setFieldValue)}
                        value={values.lastName}
                      />
                      <ErrorMessage name="lastName" />
                    </div>
                    <div className="facility-box form-field-space">
                      <span className="form-field-title">{posCustCode}</span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 tabInput"
                        name="customerCode"
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => handleInputChange(e, 'customerCode', setFieldValue)}
                        value={values.customerCode}
                      />
                      {customerPosValid ? <p className=" validation-error-text mt-2 int-error-text mb-0"><span>This code already exists. Try a different code.</span></p> : <ErrorMessage name="customerCode" />}
                    </div>
                    <div className="facility-box form-field-space">
                      <span className="form-field-title">{emailAddress}</span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        type="email"
                        className="mt-2 tabInput"
                        name="emailAddress"
                        onChange={(e) => setFirstLastName(e, 'emailAddress', setFieldValue)}
                        value={values.emailAddress}
                      />
                      <span className="username-text">
                        This will be used as their username
                      </span>
                      <ErrorMessage name="emailAddress" />
                    </div>
                    <div className="facility-box form-field-space ">
                      <div className="facilit-phone-number phone-number-field arrow-black row flex-nowrap align-items-end">
                        <div className="w-25">
                          <span className="form-field-title phone-number-field-title mb-1 d-block w-100">
                            {phoneNumberInfo}
                          </span>
                          <PosSelect
                            name="countryCode"
                            variant="filled"
                            id="filled-basic"
                            defaultValue={countryCode}
                            disabled
                            value={countryCode}
                            placeholder={countryCode}
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                numbersOnlyRegEx.test(e.target.value)
                              ) {
                                setFieldValue('countryCode', e.target.value);
                              }
                            }}
                            options={phoneCountyCode}
                          />
                        </div>
                        <div className="w-50 mr-0">
                          <MaskInput
                            mask="999 999 9999"
                            isClassName="masked-phone-number-input mb-0 tabInput-mobile"
                            id="filled-basic"
                            name="phoneNumber"
                            alwaysShowMask={true}
                            onChange={handleChange}
                            onKeyPress={(e) => blockInvalidCharForPhone(e)}
                            value={values.phoneNumber || ""}
                            placeholder="000 000 0000"
                          />
                        </div>
                        <div className="w-25">
                          <div className="phone-ext-title">
                            <span className="form-field-title">Ext</span>
                            <span className="phone-optional-head ml-3">
                              ({option})
                            </span>
                          </div>
                          <PosInput
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            inputProps={{ maxLength: 4 }}
                            id={"extCode"}
                            value={extCode}
                            className="phone-ext-input mb-0 tabInput"
                            onChange={(e) => { onExtCodeChange(e.target.value, setFieldValue) }}
                          />
                        </div>
                      </div>
                      <ErrorMessage name="countryCode" />
                      <ErrorMessage name="phoneNumber" />
                      <hr className="estimator-bottom-line" />
                      {/* <ErrorMessage name="extCode" /> // TODO need this code if the validation change */}
                    </div>
                    <div className="facility-box form-field-space">
                      <span className="form-field-title">
                        {companyName}
                        <span className="phone-optional-head ml-3">
                          ({option})
                        </span>
                      </span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 tabInput"
                        name="companyName"
                        onChange={(e) => setFirstLastName(e, 'companyName', setFieldValue)}
                        value={values.companyName}
                      />
                      <ErrorMessage name="companyName" />
                    </div>
                    <div className="facility-box form-field-space">
                      <span className="form-field-title">Company Address </span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 tabInput"
                        name="companyAddress"
                        onChange={(e) => setFirstLastName(e, 'companyAddress', setFieldValue)}
                        value={values.companyAddress}
                      />
                      <ErrorMessage name="companyAddress" />
                    </div>
                    <div className="facility-box form-field-space">
                      <span className="form-field-title">City</span>
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 tabInput"
                        name="city"
                        onChange={(e) => setFirstLastName(e, 'city', setFieldValue)}
                        value={values.city}
                      />
                      <ErrorMessage name="city" />
                    </div>
                    <div className="facility-box form-field-space arrow-black">
                      <span className="form-field-title mb-2">State</span>
                      <PosSelect
                        // isSearch : Removing Search as not needed for this dropdown
                        name="state"
                        variant="filled"
                        id="filled-basic"
                        className=" tabDropdown"
                        defaultValue={stateName}
                        value={stateName}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          handleChange("state");
                          setStateName(e.target.value);
                        }}
                        options={
                          statesData?.map(({ stateName, stateAbbreviation }: StateDataProps) => {
                            return {
                              label: stateName,
                              value: stateAbbreviation,
                            };
                          })
                        }
                        placeholder="Select a state"
                      />
                      {!stateName && checkValidation ? (
                        <p className="validation-error-text mt-2 int-error-text margin-top0 mb-0">
                          State is required.
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="facility-box form-field-space">
                      <span className="form-field-title">{zip}</span>
                      <PosInput
                        onWheel={(e) => {
                          e.target.blur()
                        }}
                        type='number'
                        variant="filled"
                        id="filled-basic"
                        inputProps={{ maxLength: 6 }}
                        maxLength={5}
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 tabInput"
                        name="zip"
                        onKeyPress={(e) => blockInvalidChar(e)}
                        onChange={(e) => {
                          if (
                            numberRegex.test(e.target.value) && changestringVal) {
                            setFieldValue('zip', e.target.value);
                          }
                        }}
                        value={values.zip}
                      />
                      <ErrorMessage name="zip" />
                    </div>
                    <hr className="estimator-bottom-line" />
                    <div className="facility-box form-field-space arrow-black mt-5">
                      <span className="form-field-title mb-2">
                        {priceSchedule}
                      </span>
                      <PosSelect
                        // isSearch : Removing Search as not needed for this dropdown
                        id={"pricingSchedule"}
                        variant="filled"
                        name="pricingSchedule"
                        className=" tabDropdown"
                        value={values.pricingSchedule}
                        defaultValue={pricingScheduleSelected}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          handleChange("pricingSchdeule");
                          setPricingScheduleSelected(e.target.value);
                          setFieldValue('pricingSchedule', e.target.value);
                        }}
                        placeholder={selectPriceSchedule}
                        options={
                          priceSchedulesData?.length &&
                          priceSchedulesData.map(({ name, id }: ScheduleDataProps) => {
                            return {
                              label: name,
                              value: id,
                            };
                          })
                        }
                      />
                      <ErrorMessage name="pricingSchedule" />
                    </div>
                    <div className="facility-box form-field-space">
                      <span className="form-field-title">{fixMarginRates}</span>
                      <PosInput
                        onWheel={(e) => {
                          e.target.blur()
                        }}
                        type='text'
                        variant="filled"
                        id="filled-basic"
                        inputProps={{ maxLength: 4 }}
                        InputProps={{ disableUnderline: true }}
                        className="mt-2 tabInput"
                        name="fixMarginRate"
                        onKeyPress={(e) => blockInvalidChar(e)}
                        onChange={(e) => {
                          if (
                            changestringVal) {
                            const numericValue = e.target.value.replace(/[^0-9.]/g, '');
                            setFieldValue('fixMarginRate', numericValue);
                          }
                        }}
                        value={values.fixMarginRate}
                        maxLength={4}
                      />
                    </div>
                    <ErrorMessage name="fixMarginRate" />
                    <div className="mt-50">
                      <hr className="estimator-bottom-line" />
                      <PosCheckbox
                        name="priceCheck"
                        checked={priceCheck}
                        onChange={handlePriceCheck}
                        className="tabInput-focus"
                        label={showPrices}
                      />
                    </div>
                    <PosCheckbox
                      name="freeShipCustFlag"
                      checked={values.freeShipCustFlag}
                      className="tabInput-focus"
                      onChange={() => handleOnChange(!values.freeShipCustFlag, 'freeShipCustFlag', setFieldValue)}
                      label={freeShip}
                    />
                    <PosCheckbox
                      name="creditCardOnlyFlag"
                      className="tabInput-focus"
                      checked={values.creditCardOnlyFlag}
                      onChange={() => handleOnChange(!values.creditCardOnlyFlag, 'creditCardOnlyFlag', setFieldValue)}
                      label={onlyCreditCard}
                    />
                    <div
                      className="page-top-btm-padding"
                      onClick={() => {
                        if (values.firstName && values.lastName) {
                          setFullNameToSet(
                            values.firstName + " " + values.lastName
                          );
                        }
                        setCheckValidation(true);
                        !customerPosValid && handleSubmit();
                      }}
                    >
                      <PosButton
                        variant="contained"
                        color="primary"
                        className="preview-shipping-btn btn btn-savechanges-add-cust tabInput-focus add-new-customer-continue-btn"
                        type="submit"
                        disabled={customerPosValid}
                      >
                        Continue
                      </PosButton>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {tabCount === addTextForm ? (
                  <div>
                    <div className="pricing-modal-header-address">
                      <div className="payment-option-heading">
                        <div>
                          <PosPageHeading
                            heading={addNewCustomer}
                            grayHeading
                            className="mb-1"
                          />
                          <PosPageHeading
                            heading={documentStep}
                            blackHeading
                          />
                        </div>
                        <PosImage src={filledBlackAddUser} alt="person-icon" />
                      </div>
                    </div>
                    <div className="mt-4 mb-4">
                      <span className="int-20-reg-bold">
                        {attachDocument}
                      </span>
                    </div>

                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <PosButton
                        variant="contained"
                        color="primary"
                        className="btn-add-customer tax-file-upload-btn btn tabInput-focus new-cust-add-doc-btn"
                        type="button"
                        onClick={() => {
                          setOpenModel(true)
                        }
                        }
                        fontWeight
                        startIcon={<PosImage src={plusWhiteIcon} alt="person-icon" />}
                      >
                        Add Document
                      </PosButton>
                    </div>
                    <div className="p-1 mt-2">
                      {posFlowData?.addDocument?.length ?
                        <>
                          <span>
                            {posFlowData?.addDocument?.length} {`${posFlowData?.addDocument?.length === 1 ? 'Document' : 'Documents'} added`}
                          </span>
                          <PosButton
                            isClassName="tax-chnage-btn tabInput-focus"
                            type="submit"
                            onClick={handleChangeModel}
                          >Change</PosButton>
                        </>
                        : null
                      }
                    </div>
                    <div className="slim-liner mtb-10"></div>
                    <div className="btns-container-discard-save mt-5 flex-row-reverse justify-start">
                      <div
                        className="btn-save-start-order"
                        onClick={() => {
                          handleSubmitTaxForm();
                        }}
                      >
                        <div>
                          <PosButton
                            variant="contained"
                            color="primary"
                            className="btn preview-shipping-btn tabInput-focus add-new-customer-continue-btn"
                            type="submit"
                          >Continue</PosButton>
                        </div>
                      </div>
                      <PosButton
                        variant="contained"
                        startIcon={<ArrowBackRoundedIcon />}
                        className="btn-discard-edit btn tabInput-focus add-new-customer-go-back-btn"
                        onClick={() => setTabCount(addCustomerForm)}
                      > Go back
                      </PosButton>
                    </div>
                    <AttachedDocumentModal
                      taxOpen={taxOpen}
                      setTaxOpen={setTaxOpen}
                      handleClick={handleClick}
                      documentBy={customer}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {tabCount === addCustomerDetails ? (
                  <div>
                    <div className="pricing-modal-header-address">
                      <div className="payment-option-heading">
                        <div>
                          <PosPageHeading
                            heading={addNewCustomer}
                            grayHeading
                            className="mb-1"
                          />
                          <PosPageHeading
                            heading="Step 3: Confirmation"
                            blackHeading
                          />
                        </div>
                        <PosImage src={filledBlackAddUser} alt="person-icon" />
                      </div>
                    </div>
                    <div className="mt-4 mb-4">
                      <span className="int-20-reg-bold">
                        Please verify the details to save the new customer or
                        Edit to make changes.
                      </span>
                    </div>
                    <div className="facility-box">
                      <span className="int-18-reg-bold">
                        {values.firstName} {values.lastName}
                      </span>
                      <span className="int-16-reg">{values.emailAddress}</span>
                    </div>
                    <div className="slim-liner mtb-10"></div>
                    <div className="facility-box">
                      <span className="int-18-reg-bold">
                        {values.companyName}
                      </span>
                      <span className="shipping-subheading int-16-reg">
                        {values.companyAddress}
                      </span>
                      <span className="shipping-subheading int-16-reg">
                        {values.city}, {stateName}
                      </span>
                      <span className="shipping-subheading int-16-reg">
                        {values.zip}
                      </span>
                    </div>
                    <div className="slim-liner mtb-10"></div>
                    <div className="facility-box">
                      <span className="confirmation-title-text mb-1 label-style">
                        USERNAME
                      </span>
                      <span className="int-18-reg-bold">
                        {values.emailAddress}
                      </span>
                    </div>
                    <div className="slim-liner mtb-10"></div>
                    <div className="facility-box">
                      <span className="confirmation-title-text mb-1 label-style">
                        PHONE NUMBER
                      </span>
                      <span className="int-18-reg-bold">
                        {phoneNumberFormatNew(
                          countryCode.toString(),
                          phoneNumber?.substring(0, 3),
                          phoneNumber?.substring(4, 7),
                          phoneNumber?.substring(8, 12),
                          extCode ?? '',
                          true
                        )}
                      </span>
                    </div>
                    <div className="slim-liner mtb-10"></div>
                    <div className="facility-box">
                      <span className="confirmation-title-text mb-1 label-style">
                        PRICING SCHEDULE
                      </span>
                      <span className="int-18-reg-bold">
                        {
                          priceSchedulesData?.filter(
                            (id) => id.id === Number(pricingScheduleSelected)
                          )[0]?.name
                        }
                      </span>
                    </div>
                    {posFlowData?.addDocument?.length ? <>
                      <div className="slim-liner mtb-10"></div>
                      <div className="facility-box">
                        <span className="confirmation-title-text mb-1 label-style">
                          DOCUMENTS
                        </span>
                        <span className="int-18-reg-bold">

                          <span>
                            {posFlowData?.addDocument?.length} {`${posFlowData?.addDocument?.length === 1 ? 'Document' : 'Documents'} added`}
                          </span>

                          <PosButton
                            isClassName="tax-chnage-btn tabInput-focus"
                            type="submit"
                            onClick={handleChangeModel}
                          >Change</PosButton>
                        </span>
                      </div>
                    </> : null
                    }
                    <div className="slim-liner mtb-10"></div>
                    <div className="btns-container-discard-save mt-5 flex-row-reverse justify-start">
                      <div
                        className="btn-save-start-order"
                        onClick={() => {
                          // need regarding registered customr changes
                          // setAccountRegisteredModel(true)
                          onSubmitCustomerData(values)
                        }}
                      >
                        <div>
                          <PosButton
                            startIcon={<CheckIcon />}
                            variant="contained"
                            color="primary"
                            className="btn preview-shipping-btn tabInput-focus add-new-customer-continue-btn"
                            type="submit"
                          >{completeRegistration}</PosButton>
                        </div>
                      </div>
                      <PosButton
                        variant="contained"
                        className="btn-discard-edit btn tabInput-focus add-new-customer-go-back-btn"
                        onClick={() => setTabCount(addCustomerForm)}
                        startIcon={<ArrowBackRoundedIcon />}
                      >
                        Edit
                      </PosButton>
                    </div>
                    <AttachedDocumentModal
                      taxOpen={taxOpen}
                      setTaxOpen={setTaxOpen}
                      handleClick={handleClick}
                      documentBy={customer}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          }}
        </Formik>
      ) : (
        <SelectTakerModal
          newCustomerTakerClass
          setState={setState}
          personIdToBeSent={personIdToBeSent}
        />
      )}

      {openModel ? <AddDocumentModal openModel={openModel} setOpenModel={setOpenModel} taxExemption={false} documentBy={customer} /> : null}
    </Container>
  );
};

export default AddNewCustomer;
