import { useEffect, useMemo, useState } from 'react';

import IconButton from 'apps/admin/components/Elements/IconButton';
import MaterialIcon from 'apps/pos/Common/uiCore/MaterialIcon';
import { Numbers } from 'constants/enum';
import { getLocalStoraGeItem, preventArrowKeys, saleable } from 'utils/utils';
import LocationPicker from '../../LocationPicker';

import './ReturnToLineItem.scss';


const ReturnToLineItem = ({
    initialLocation,
    removeLine,
    setReturnTo,
    cartEditModalData,
    disabledMultiple,
    disableReturnReplace,
    toLocationShelfDetails,
    getLocationProductWise,
    rowsData,
    rowsLoading,
    rowsError,
    unitsData,
    unitsLoading,
    unitsError,
    shelvesData,
    shelvesLoading,
    shelvesError,
    activeFlag,
    isNonSaleableHide,
    isReturnCart,
    reason
}) => {
    const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {}
    const locationData = {
        id: "",
        row: {
            id: 0,
            name: ""
        },
        unit: {
            id: 0,
            name: ""
        },
        shelf: {
            id: 0,
            name: ""
        },
        returnQty: "",
        replaceQty: "",
        overShipmentQty: "",
    }
    const locationDataForPicker = isReturnCart ? initialLocation : stockWarehouseId !== 5 ? initialLocation : locationData;
    const { zero } = Numbers
    const [visible, setVisible] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(locationDataForPicker);

    const { returnQty, replaceQty, overShipmentQty } = selectedLocation || {}
    useEffect(() => {
        setReturnTo(current => {
            const newArray = [...current];
            const currentIndex = newArray.findIndex(item => item.id === selectedLocation.id);
            newArray.splice(currentIndex, 1, selectedLocation);
            return newArray;
        });
    }, [selectedLocation]);

    const openLocationPicker = () => {
        setVisible(() => true);
    }

    const handleReturnQty = (e) => {
        setSelectedLocation(current => ({
            ...current,
            returnQty: e.target.value,
        }));
    }

    const handleOvershipQty = (e) => {
        setSelectedLocation(current => ({
            ...current,
            overShipmentQty: e.target.value,
        }));
    }

    useEffect(() => {
        //When the reason changes return to location and qty will reset to default based on the reason id
        if (reason === 6) {
            setSelectedLocation(current => ({
                ...current,
                returnQty: "",
                replaceQty: ""
            }))
        } else {
            setSelectedLocation(current => ({
                ...current,
                overShipmentQty: ""
            }))
        }

    }, [reason]);

    const handleReplaceQty = (e) => {
        setSelectedLocation(current => ({
            ...current,
            replaceQty: e.target.value,
        }));
    }

    const saleableRows = useMemo(() => Array.isArray(rowsData) ? rowsData.filter(saleable) : [], [rowsData])

    const isButtonDisabled = saleableRows?.length === 1 && unitsData?.length === 1 && shelvesData?.length === 1 && !!selectedLocation.row.id && !!selectedLocation.unit.id && !!selectedLocation.shelf.id
    const finalButtonDisabled = (!isReturnCart && stockWarehouseId !== 5) && (isButtonDisabled || rowsLoading || unitsLoading || shelvesLoading)

    return (
        <>
            <div className={`return-to-line-item ${activeFlag === zero ? "activeFlag-return" : ""}  ${disableReturnReplace ? "disable-return-replace" : ""} ${reason === 6 ? "return-to-line-overship-item" : ""}`}>
                <div className="return-to-input-group-sec">
                    <div className='return-to-responsive-title'>
                        <span>
                            Location
                        </span>
                    </div>
                    <div className="return-to-input-group">
                        <button disabled={finalButtonDisabled} type='button' className={`location-button ${visible ? 'location-button-active' : ''} ${finalButtonDisabled ? 'location-button-pointer-not-allowed' : ''}`} onClick={openLocationPicker}>
                            <span className="location-button-label">
                                {selectedLocation.row.name || selectedLocation.unit.name || selectedLocation.shelf.name ? null : 'Select location'}

                                {selectedLocation.row.name ? selectedLocation.row.name : null}

                                {selectedLocation.unit.name ? '-' : null}
                                {selectedLocation.unit.name ? selectedLocation.unit.name : null}

                                {selectedLocation.shelf.name ? '-' : null}
                                {selectedLocation.shelf.name ? selectedLocation.shelf.name : null}
                            </span>

                            <MaterialIcon icon='arrow_drop_down' size='20' />
                        </button>
                    </div>
                </div>
                {!disableReturnReplace ?
                    reason === 6 ?
                        <div className="return-to-input-group-sec">
                            <div className='return-to-responsive-title'>
                                <span>
                                    Overshipped Qty.
                                </span>
                            </div>
                            <div className="return-to-input-group qty-return-group">
                                <input type="number"
                                    className='return-qty-input'
                                    title='Return quantity'
                                    value={overShipmentQty}
                                    onKeyDown={preventArrowKeys}
                                    onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                                    onChange={handleOvershipQty} />
                            </div>
                        </div>
                        :
                        <>
                            <div className="return-to-input-group-sec">
                                <div className='return-to-responsive-title'>
                                    <span>
                                        Return Qty.
                                    </span>
                                </div>
                                <div className="return-to-input-group qty-return-group">
                                    <input type="number"
                                        className='return-qty-input'
                                        title='Return quantity'
                                        value={returnQty}
                                        onKeyDown={preventArrowKeys}
                                        onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                                        onChange={handleReturnQty} />
                                </div>
                            </div>
                            {activeFlag !== zero &&
                                <div className="return-to-input-group-sec">
                                    <div className='return-to-responsive-title'>
                                        <span>
                                            Replacement Qty.
                                        </span>
                                    </div>
                                    <div className="return-to-input-group qty-return-group">
                                        <input type="number"
                                            className='return-qty-input'
                                            title='Replacement quantity'
                                            value={replaceQty}
                                            onKeyDown={preventArrowKeys}
                                            onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                                            onChange={handleReplaceQty}
                                        />
                                    </div>

                                </div>
                            }
                        </> : null}
                {!disabledMultiple ? <div className={`return-to-input-group return-to-delete-icon qty-return-group`}>
                    <IconButton
                        icon='delete_outline'
                        size='36'
                        iconSize='20'
                        secondary={true}
                        classes='return-line-delete-button'
                        title='Remove'
                        onClick={removeLine} />
                </div> : null}
            </div>

            <LocationPicker
                cartEditModalData={cartEditModalData}
                visible={visible}
                selectedLocation={selectedLocation}
                toLocationShelfDetails={toLocationShelfDetails}
                getLocationProductWise={getLocationProductWise}
                setVisible={setVisible}
                setSelectedLocation={setSelectedLocation}
                rowsData={rowsData}
                rowsLoading={rowsLoading}
                rowsError={rowsError}
                unitsData={unitsData}
                unitsLoading={unitsLoading}
                unitsError={unitsError}
                shelvesData={shelvesData}
                shelvesError={shelvesError}
                shelvesLoading={shelvesLoading}
                isNonSaleableHide={isNonSaleableHide}
                isReturnCart={isReturnCart}
            />
        </>
    );
}

export default ReturnToLineItem;
