import './Icon.scss'

const Icon = (props) => {
    const {
        size = 24,
        name = 'edit',
        className = '',
        color = 'inherit',
    } = props;

    return (
        <div
            className={`icon ${className}`}
            style={{
                '--size': size,
                '--color': color,
            }}
        >
            {name}
        </div >
    )
}

export default Icon