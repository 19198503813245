import serviceV1 from "services/config";
import { default as service, default as serviceV2 } from "services/config2";

import { endpoints } from "apps/pos/endpoints";
import {
  PriceSchedulesRequest,
  RecentCustomersRequest,
} from "apps/pos/models/pos";

const POS_API_PATH = "pos";
const PRICESCHEDULE_API_PATH = "priceschedules";
const NOTES_API_PATH = "/personnotes";
const RETURNS_API_PATH = "returns/rmas";

const multipartFormDataConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
};


export const getPriceSchedules = (payload: PriceSchedulesRequest) => {
  return service
    .get(
      `${POS_API_PATH}/priceschedules?page=${payload.pageSize}&size=${payload.size}`
    )
    .then((response) => {
      return response.data;
    });
};

export function getSavedDraftsList(payload: any) {
  return service
    .get(
      `${POS_API_PATH}/warehouses/${payload.stockWarehouseId}/buyers/${payload.buyerId}/drafts`
    )
    .then((response) => {
      return response.data;
    });
}

export function savedDraftsListPage(payload: any) {
  return service
    .get(
      `${POS_API_PATH}/warehouses/${payload.stockWarehouseId}/buyers/${payload.buyerId}/drafts?page=${payload.page}&size=${payload.size}&sort=${payload.sort}`
    )
    .then((response) => {
      return response.data;
    });
}

export function getTakers() {
  return service.get(`${POS_API_PATH}/takers`).then((response) => {
    return response.data;
  });
}

export function getSalesPersons() {
  return service.get(`${POS_API_PATH}/salespersons`).then((response) => {
    return response.data;
  });
}

export function recentCustomers(payload: RecentCustomersRequest) {
  return service
    .get(
      `${POS_API_PATH}/warehouses/${payload.id}/customers/recent`
    )
    .then((response) => {
      return response.data;
    });
}

export function getProducts(payload: any) {
  return service
    .post(
      `${POS_API_PATH}/warehouses/${payload.stockWarehouseId}/products/${payload.productId}`,
      payload
    )
    .then((response) => {
      return response.data;
    });
}

export function suggestedProducts(payload: any) {
  return service
    .get(
      `${POS_API_PATH}/warehouses/${payload.stockWarehouseId}/buyers/${payload.buyerId}/products/suggest`
    )
    .then((response) => {
      return response.data;
    });
}

export function facilityTypes() {
  return serviceV1.get(`buildings/types`).then((response) => {
    return response.data;
  });
}

export function jobDescription() {
  return serviceV1.get(`job/titles`).then((response) => {
    return response.data;
  });
}

export function states() {
  //TODO: need this code
  // return serviceV1.get(`states/?page=1&size=20`).then((response) => {
  return serviceV1.get(`states/us/poboxes/exclude`).then((response) => {
    return response.data;
  });
}

export function shiptoFacilities(buidlingId: number) {
  return service
    .get(`${POS_API_PATH}/facilities/${buidlingId}/shipto`)
    .then((response) => {
      return response.data;
    });
}

export function shippingFacilities(customerID: number) {
  return service
    .get(`${POS_API_PATH}/customers/${customerID}/facilities`)
    .then((response) => {
      return response.data;
    });
}

export function addShippingFacilities(body: any, customerID: number) {
  return serviceV2
    .post(`${POS_API_PATH}/customers/${customerID}/facilities/ship`, body, multipartFormDataConfig)
    .then((response) => {
      return response.data;
    });
}

export function searchCustomers(body: any) {
  return serviceV2
    .post(`${POS_API_PATH}/search/customers`, body)
    .then((response) => {
      return response;
    });
}

export function searchReturnCustomers(body: any) {
  return serviceV2
    .post(
      `${RETURNS_API_PATH}/simplified/search/customers
    `,
      body
    )
    .then((response) => {
      return response;
    });
}

export function searchProducts(body: any) {
  return service
    .post(`${POS_API_PATH}/search/products`, body)
    .then((response) => {
      return response?.data;
    });
}

export function searchReturnProducts(body: any) {
  return service
    .post(`${RETURNS_API_PATH}/simplified/search/products`, body)
    .then((response) => {
      return response?.data;
    });
}

export function addPriceSchedule({ customerId, priceScheduleId }) {
  return service
    .post(
      `${POS_API_PATH}/customers/${customerId}/priceschedules/${priceScheduleId}`
    )
    .then((response) => {
      return response.data;
    });
}

export function addnewCustomer(payload: any) {
  return service.post(`${POS_API_PATH}/customers`, payload).then((response) => {
    return response.data;
  });
}

export function addnewProduct(payload: any) {
  return serviceV1.post(`products/pending`, payload).then((response) => {
    return response.data;
  });
}
/* Note: This function has to be used to get 
  product on temporary puspose that is 
  the reason it has hard coded ID. It 
  will be changed/updated once the BE 
  makes appropriate changes */
export function getProduct() {
  return serviceV1.post(`products/by/keywords/all`, {
    //manufacturerID: 47838,
    keywords: "33315"
  }).then((response) => {
    return response?.data;
  });
}

export function setCustomerCode({ personId, customerCode }: { personId: number, customerCode: number }) {
  return serviceV2.put(`users/${personId}/customer/poscode`, {
    personId: personId,
    posCustCode: customerCode
  }).then((response) => {
    return response?.data;
  });
}

export function getCustomerById({ customerId }: { customerId: number }) {
  return serviceV1.get(`users/${customerId}/customer`).then((response) => {
    return response?.data;
  });
}

export function checkCustomerCodeIfAlreadyExists({ customerCode }: { customerCode: number }) {
  return serviceV2.post(`users/customer/poscode/exists`, {
    posCustCode: customerCode
  }).then((response) => {
    return response?.data;
  });
}

export function updatePriceSchedule({ customerId, priceScheduleId }) {
  return service
    .put(
      `${POS_API_PATH}/customers/${customerId}/priceschedules/${priceScheduleId}`
    )
    .then((response) => {
      return response.data;
    });
}

export function cartSubmit(payload: any, cartId: number) {
  return service
    .post(`${POS_API_PATH}/cart/${cartId}/process`, payload, multipartFormDataConfig)
    .then((response) => {
      return response.data;
    });
}

export function cartPrint(payload: any) {
  return service
    .post(`${POS_API_PATH}/cart/${payload?.cartId}/print/pdf`, payload)
    .then((response) => {
      return response?.data;
    });
}

export function cartWithTotalsPrint(payload: any) {
  return service
    .post(`${POS_API_PATH}/cart/${payload?.cartId}/totals/print/pdf`, payload)
    .then((response) => {
      return response?.data;
    });
}

export function cartDraft(payload: any, cartId: number) {
  return service
    .post(`${POS_API_PATH}/cart/${cartId}/draft`, payload, multipartFormDataConfig)
    .then((response) => {
      return response.data;
    });
}

export function cartClear(cartId: number) {
  return service
    .delete(`${POS_API_PATH}/drafts/${cartId}/clear`)
    .then((response) => {
      return response?.data;
    });
}

export function cartClearAllDrafts(customerId: number) {
  return service
    .post(`${POS_API_PATH}/customers/${customerId}/drafts/clear`)
    .then((response) => {
      return response?.data;
    });
}

export function loadCart(payload: any) {
  return service
    .post(`${POS_API_PATH}/customers/${payload.customerId}/cart/load`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export function getWarehouseLocation(payload: any) {
  return service
    .post(
      `${POS_API_PATH}/warehouses/${payload.stockWarehouseId}/locations/picking`,
      payload.body
    )
    .then((response) => {
      return response.data;
    });
}

export function getSalesHistory(payload: any) {
  return service
    .get(
      `${POS_API_PATH}/products/${payload.productId}/sales/history`,
      payload.body
    )
    .then((response) => {
      return response.data;
    });
}

export function getStockInformation(payload: any) {
  return service
    .post(`stock/products/units/available`, payload)
    .then((response) => {
      return response.data;
    });
}

export function salesHistory(payload: any) {
  return serviceV2
    .get(
      `invoices/customers/${payload.customerId}/products/${payload.productId}/sales/history?page=${payload.page}&size=${payload.size}`
    )
    .then((response) => {
      return response.data;
    });
}

export function userPriceScheduleData(priceScheduleId: number) {
  return serviceV2
    .get(`${PRICESCHEDULE_API_PATH}/${priceScheduleId}`)
    .then((response) => {
      return response.data;
    });
}
export function getPersonNotes(personId: number) {
  return serviceV2
    .get(`${NOTES_API_PATH}/${personId}/notes`)
    .then((response) => {
      return response.data;
    });
}

export function getCategories() {
  return serviceV1.get(`/categories/menu/root/pending`).then((response) => {
    return response.data;
  });
}

export function getNewProductManufacturer() {
  //old manufacturers
  // return serviceV1.get(`/companies/manufacturers`).then((response) => {
  return serviceV1.get(`/companies/types/5`).then((response) => {
    return response.data;
  });
}

export function getNewProductVendor() {
  //old vendor
  // return serviceV1.get(`/companies/sales/vendors`).then((response) => {
  return serviceV1.get(`/companies/types/7`).then((response) => {
    return response.data;
  });
}

export function getNewProductGroup() {
  return serviceV2.get(`/productgroups?page=1&size=1000`).then((response) => {
    return response.data;
  });
}

// Start Adjust Stock api
//Get Rows
export function getRowsActiveData(payload: any) {
  return serviceV1
    .get(
      `stock/warehouses/${payload.stockWarehouseId}/rows/active?page=${payload.page}&size=${payload.size}`
    )
    .then((response) => {
      return response.data;
    });
}
// Get Unit
export function getUnitsActiveData(payload: any) {
  return serviceV1
    .get(
      `stock/unit/rows/${payload.rowID}/active?page=${payload.page}&size=${payload.size}`
    )
    .then((response) => {
      return response.data;
    });
}
// Get Shelf
export function getShelfData(payload: any) {
  return serviceV1
    .get(
      `stock/rows/${payload.rowID}/units/${payload.unitID}/shelves?page=${payload.page}&size=${payload.size}`
    )
    .then((response) => {
      return response.data;
    });
}
// Stock In
export function stockInData(payload: any) {
  return serviceV1
    .post(
      `stock/products/${payload.productID}/warehouses/${payload.stockWarehouseID}/take/in`,
      payload
    )
    .then((response) => {
      return response.data;
    });
}
// Stock Out
export function stockOutData(payload: any) {
  return serviceV1
    .post(
      `stock/products/${payload.productID}/warehouses/${payload.stockWarehouseID}/take/out`,
      payload
    )
    .then((response) => {
      return response.data;
    });
}
// Stock Move
export function stockMoveData(payload: any) {
  return serviceV1
    .post(
      `stock/products/${payload.productID}/warehouses/${payload.stockWarehouseID}/move`,
      payload
    )
    .then((response) => {
      return response.data;
    });
}
// End Adjust Stock api
// Item Ledger Pagination
export function itemLedger(payload: any) {
  return serviceV2
    .post(`stock/products/${payload.productId}/inout/ledger`, payload)
    .then((response) => {
      return response.data;
    });
}
// From location product
export function fromLocationProduct(payload: any) {
  return serviceV1
    .get(
      `stock/warehouses/${payload.stockWarehouseID}/products/${payload.productID}/locations?page=${payload.page}&size=${payload.size}`
    )
    .then((response) => {
      return response.data;
    });
}

//  From Location Rows
export function getFromLocationRowsData(payload: any) {
  return serviceV2
    .get(
      `stock/warehouses/${payload.stockWarehouseID}/products/${payload.productID}/locations/rows`
    )
    .then((response) => {
      return response.data;
    });
}
//  From Location Units
export function getFromLocationUnitsData(payload: any) {
  return serviceV2
    .get(
      `stock/warehouses/${payload.stockWarehouseID}/products/${payload.productID}/locations/rows/${payload.stockRowID}/units`
    )
    .then((response) => {
      return response.data;
    });
}
//  From Location shelves
export function getFromLocationShelfData(payload: any) {
  return serviceV2
    .get(
      `stock/warehouses/${payload.stockWarehouseID}/products/${payload.productID}/locations/rows/${payload.stockRowID}/units/${payload.stockUnitID}/shelves`
    )
    .then((response) => {
      return response.data;
    });
}

//  Start import Quote
export function getImportQuotesData(payload: any) {
  return serviceV2
    .get(`pos/import/quotes?page=${payload.page}&size=${payload.size}`)
    .then((response) => {
      return response.data;
    });
}
// end import Quote

//  Start import Quote Search
export function getImportQuotesSearchData(payload: any) {
  return serviceV2
    .post(`pos/import/quotes/search`, payload)
    .then((response) => {
      return response.data;
    });
}
// end import Quote Search

//  Start import Quote items
export function getImportQuotesItemsData(payload: any) {
  return serviceV2
    .post(`pos/import/quotes/${payload.quoteId}/items`, payload)
    .then((response) => {
      return response;
    });
}
// end import Quote items

export function getDocumentTypesData(forPos: boolean) {
  return serviceV1.get(`docs/types?page=1&size=20&forPos=${forPos}`).then((response) => {
    return response.data;
  });
}
export function getCompaniesDocumentTypesData(forPos: boolean) {
  return serviceV1.get(`companies/documents/types?page=1&size=20&forPos=${forPos}`).then((response) => {
    return response.data;
  });
}

//  Start Add New Customer 
export function addNewCustomerInfo(payload: any) {
  return serviceV2
    .post(`pos/customers`, payload, multipartFormDataConfig)
    .then((response) => {
      return response;
    });
}
// End Add New Customer 

// get tips data
export function getTips(containerkey: string, page: number) {
  return serviceV2
    .get(
      `/tips/containerkey/${encodeURIComponent(
        containerkey
      )}?page=${page}&size=20`
    )
    .then((response) => {
      return response.data;
    });
}


// Get Customer Docs
export function getCustomerDocsData(payload: any) {
  return serviceV2
    .get(
      `/docs/customers/${payload.customerId}/buildings/${payload.buildingId}/orders/${payload.orderId}`
    )
    .then((response) => {
      return response.data;
    });
}


export function codeExists(posCustCode: string) {
  return serviceV2
    .post(`pos/customers/poscode/exists`, { "posCustCode": posCustCode })
    .then((response) => {
      return response?.data;
    });
}

// Item Ledger Detail
export function itemLedgerInout(payload: any) {
  return serviceV2
    .post(`stock/products/${payload.productId}/inout`, payload)
    .then((response) => {
      return response.data;
    });
}

export function quotesHistory(payload: any) {
  return serviceV2
    .get(
      `quotes/customers/${payload.customerId}/products/${payload.productId}/lines?page=${payload.page}&size=${payload.size}`
    )
    .then((response) => {
      return response.data;
    });
}

export function itemHistory(payload: any) {
  return serviceV2
    .get(
      `invoices/products/${payload.productId}/usage/monthly`
    )
    .then((response) => {
      return response.data;
    });
}

export function purchaseHistory(payload: any) {
  return serviceV2
    .get(
      `purchaseorders/warehouses/${payload.stockWarehouseId}/products/${payload.productId}/lines?page=${payload.page}&size=${payload.size}`
    )
    .then((response) => {
      return response.data;
    })
}

export function invoiceHistory(payload: any) {
  return serviceV2
    .get(
      `invoices/customers/${payload.customerId}/products/${payload.productId}/lines?page=${payload.page}&size=${payload.size}`
    )
    .then((response) => {
      return response.data;
    });
}

export function searchDrafts(body: any) {
  return service
    .post(`${POS_API_PATH}/drafts/by/jobname`, body)
    .then((response) => {
      return response?.data;
    });
}

export function setNoMailFlag(payload: any) {
  return serviceV2.put(`${POS_API_PATH}/customers/${payload.personId}/email/receive/optout/${payload.noMail}`).then((response) => {
    return response?.data;
  });
}

export function posPriceChangeHistory(payload) {
  return serviceV2
    .get(
      `pos/retail/price/change/products/${payload.productId}?page=${payload.page}&size=${payload.size}`
    )
    .then((response) => {
      return response.data;
    });
}

export function movingAvgCostHistory(payload) {
  return serviceV2
    .get(endpoints.movingAvgCost(payload)).then((response) => {
      return response.data;
    });
}

// Get Tax Docs
export function getTaxDocsData(payload) {
  return serviceV2
    .post(
      `/companies/${payload}/certificates/taxexempt`, {}
    )
    .then((response) => {
      return response.data;
    });
}

export function getCustomerRegion(payload) {
  return service
    .get(
      `users/${payload}/region`,
      payload.body
    )
    .then((response) => {
      return response.data;
    });
}