import Cookies from "universal-cookie";
import axios from "axios";

import { store } from "index";

import { AppToURLPathName , Paths} from "enums";

import { apiFiledRequest } from "sagas/pos/actions";
import { getAuthToken } from "./utils";
import { setIsApiLoading } from "apps/pos/pages/customerInfo/CustomerOrders/redux/customerOrder.slice";

const service = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/${AppToURLPathName.Api}/v1`,
  timeout: 70000,
  headers: {
    "Content-Type": "application/json",
  },
});

service.interceptors.request.use(
  function (config) {
    const cookies = new Cookies();
    const token = cookies.get("Authorization");
    config.headers.Authorization = token || "";
    if (!token) {
      if(window.location.pathname.includes("pos/cart")){
        window.location.href = `${window.location.origin}/sso/logout?r=/app/pos/neworder`;
      }else{
        window.location.href = `${window.location.origin}/sso/logout?r=${window.location.pathname}`;
      }
    }
    return config;
  },
  (err) => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config;
      const cookies = new Cookies();
      if (
        err?.response &&
        err?.response?.status &&
        err?.response?.data &&
        err?.response?.data?.code === "token_not_valid" &&
        err?.response?.status === 401 &&
        err?.response?.statusText === "Unauthorized"
      ) {
        originalReq._retry = true;

        return service
          .post("/auth/token/refresh")
          .then((tokenRefreshResponse) => {
            // TODO: save token data in context for memory store, localstorage/cookie to vulnerable
            // TODO: In context if token is not "signed in" send user to signout in context
            if (tokenRefreshResponse?.data?.jwtToken) {
              cookies.set(
                "Authorization",
                "Bearer " + tokenRefreshResponse?.data?.jwtToken,
                { path: "/" }
              );

              originalReq.headers["Authorization"] =
                "Bearer " + tokenRefreshResponse?.data?.token;
            }
            return resolve(tokenRefreshResponse);
          });
      }
      return reject(err);
    });
  }
);

service.interceptors.response.use(
  function (config) {
    const cookies = new Cookies();
    const token = cookies.get("Authorization");
    config.headers.Authorization = token || "";
    return config;
  },
  (err) => {
    const originalReq = err.config;
    const {response} = err || {};
    const {status} = response || {};
    if(response && status){
      store.dispatch(setIsApiLoading(false));
    }
    if (
      err?.response?.status === 401 &&
      err?.response?.status &&
      !originalReq?._retry
    ) {
      originalReq._retry = true;
      const cookies = new Cookies();
      if (
        originalReq.url === "/auth/token/refresh" &&
        err?.response?.status === 401
      ) {
        if(window.location.pathname.includes("pos/cart")){
          window.location.href = `${window.location.origin}/sso/logout?r=/app/pos/neworder`;
        }else{
          window.location.href = `${window.location.origin}/sso/logout?r=${window.location.pathname}`;
        }
      } else {
        return getAuthToken().then((tokenRefreshResponse) => {
          // TODO: save token data in context for memory store, localstorage/cookie to vulnerable
          // TODO: In context if token is not "signed in" send user to signout in context
            if (tokenRefreshResponse?.data?.jwtToken) {
              cookies.set(
                "Authorization",
                "Bearer " + tokenRefreshResponse?.data?.jwtToken,
                { path: "/" }
              );
              originalReq.headers["Authorization"] =
                "Bearer " + tokenRefreshResponse?.data?.jwtToken;
              err.response.config.headers["Authorization"] =
                "Bearer " + tokenRefreshResponse?.data?.jwtToken;
            }
            return axios(err?.response?.config);
          })
          .catch(() => {
            // required code
          });
      }
    } else {
      const {response} = err || {};
      const {status,data,config} = response || {};
      const { title, errors, messages } = data || {};
      const { method } = config || {};
      if(method !== 'get' && originalReq?.url !== Paths.searchProduct){
        if( errors ){
          for (const key in errors) {
           (status !== 200 || status !== 401) &&  store.dispatch(apiFiledRequest({state: true,multiMessage: errors[key], message :""}));
          }
        }else if (errors == null && messages?.length > 0) {
          (status !== 200 || status !== 401) &&  store.dispatch(apiFiledRequest({state: true, multiMessage : messages, message:""}));
        }
        //might needed in future
        // else{
        //   if(!originalReq?.url?.includes("locations/picking")){
        //     (status !== 200 || status !== 401) && store.dispatch(apiFiledRequest({state: true, message : title}));
        //   }
        // }
      }else{
        if(!originalReq?.url?.includes("locations/picking")){
        status === 500 && store.dispatch(apiFiledRequest({state: true, message : title}));
        }
        if(status === 404){
          return Promise.reject({
            status:status,
            message:title,
          });
        }
        throw data;
      }
    }
  }
);

export default service;
