
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        width: "100%",
        "& .MuiInput-input": {
            padding: "1.25rem",
            fontSize: "1.125rem",
            fontWeight: "500",
        },
        "& .MuiFilledInput-root": {
            width: "100%",
            backgroundColor: "#f1f1f1",
            borderRadius: "1rem",
            padding: "1.0625rem",
            "&:hover": {
                backgroundColor: "#f1f1f1",
            },
            "& .MuiFilledInput-underline": {
                backgroundColor: "#f1f1f1",
                "&:before": {
                    border: "none",
                },
            },
            "& .MuiFilledInput-input": {
                padding: "0",
                fontSize: "1.125rem",
                fontWeight: "500",
            }
        },
        "& .MuiOutlinedInput-root": {
            width: "100%",
            borderRadius: "0.625rem",
            padding: "0.9375rem",
            "& .MuiOutlinedInput-input": {
                fontSize: "1.125rem",
                fontWeight: "500",
                padding: "0"
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000 !important",
                height: "100%",
                top: "0",
                borderWidth: "0.063rem !important",
            },
        }
    }
});

export default useStyles;
