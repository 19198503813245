import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { setConvertIdToExpressApi } from "apps/springboard/QuoteItems/request";
import { QueryKeys } from "./queryKeys";

export const useConvertIdToExpress = () => {
  const [jobLotQuoteId, setJobLotQuoteId] = useState(null);

  const { data } = useQuery(
    [QueryKeys.get_convert_id, jobLotQuoteId],
    async () => {
      if (jobLotQuoteId) {
        return setConvertIdToExpressApi(jobLotQuoteId);
      }
    },
    {
      enabled: !!jobLotQuoteId,
    }
  );

  const setConvertIdToExpress = useCallback((data) => {
    setJobLotQuoteId(data);
  }, []);

  return { setConvertIdToExpress, showConvertIdToExpress: data?.data };
};
