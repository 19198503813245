
import { makeStyles } from "@material-ui/core";

import { colors } from "constants/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiButtonBase-root":{
      border:"unset",
    },
    "&.MuiButton-root": {
      lineHeight: "normal",
      fontSize: "16px",
      borderRadius: "0.9375rem",
      display: "flex",
      padding: "0.75rem 1.25rem",
      justifyContent: "center",
      alignItems: "center",
      textTransform: "unset",
      fontWeight: "600",
    },
    "&.MuiButton-contained": { 
      background: colors.lightWhite,
      borderRadius: "0.9375rem",
      color: colors.black,
      boxShadow:"none",
      "&.Mui-disabled" : {
        opacity: 0.65,
      }
    },
    "&.MuiButton-containedPrimary": { 
      background: colors.black,
      borderRadius: "0.9375rem",
      color: colors.white,
      boxShadow:"none",
      "&:hover": {
        backgroundColor: colors.black,
        color: colors.white,
      },
    },
    "&.MuiButton-textSecondary": {
      color: colors.black,
      "&:hover": {
        backgroundColor: colors.brightGray,
      },
    },
  },
  customButton:{
    minWidth:(props:{height:string,width:string})=>props.width,
    maxWidth:(props:{height:string,width:string})=>props.width,
    height:(props:{height:string,width:string})=>props.height
  },
  fontWeight:{
    "& .MuiButton-label":{
      fontWeight:"normal",
    }
  },
});

export default useStyles;
