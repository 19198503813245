import { useQuery } from '@tanstack/react-query';
import { getCashReportSummary } from './constantUrl';
import { stopLoading } from 'utils/utils';

export const useGetCashReport = (payload) => {
  const { isLoading, data } = useQuery(
    ['get-cash-reports', payload],
    async () => {
      return getCashReportSummary(payload).then((response) => {
        return response.data;
      });
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: stopLoading,
      onError: stopLoading
    },
  );
  return { cashReport: data, cashReportIsLoading: isLoading };
};