import * as constants from "./constants";

export function customerDocumentRequest(payload: any) {
  return {
    type: constants.GET_CUSTOMER_DOCUMENT_START,
    payload,
  };
}

export function customerDocumentSuccess(payload: any) {
  return {
    type: constants.GET_CUSTOMER_DOCUMENT_SUCCESS,
    payload,
  };
}

export function customerDocumentError(error: any) {
  return {
    type: constants.GET_CUSTOMER_DOCUMENT_ERROR,
    error,
  };
}

export function taxDocumentRequest(payload: any) {
  return {
    type: constants.GET_TAX_DOCUMENT_START,
    payload,
  };
}

export function taxDocumentSuccess(payload: any) {
  return {
    type: constants.GET_TAX_DOCUMENT_SUCCESS,
    payload,
  };
}

export function taxDocumentError(error: any) {
  return {
    type: constants.GET_TAX_DOCUMENT_ERROR,
    error,
  };
}