
//Helps to set key-pair value in local storage 
export const setToLS = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
}

//Helps to retrieve value of particluar key stored in local storage 
export const getFromLS = (key: string) => {
    const value = localStorage.getItem(key);

    if (value) {
        return JSON.parse(value);
    }
}