import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';

import { uploadDeliveryProofUrl } from './constantUrl';
import { stopLoading } from 'utils/utils';

export const useUploadDeliveryProof = () => {
  const { mutate, isLoading, data, isSuccess } = useMutation(
    async ({formData, deliveryId}: any) => {
      return uploadDeliveryProofUrl(formData, deliveryId)
    },
    {onSuccess : stopLoading,
      onError : stopLoading
});

  const uploadDeliveryProofFn = useCallback(
    (formData, deliveryId) => {
      mutate({formData, deliveryId})
    },[mutate]
  )
  return { uploadDeliveryProofFn, data, uploadDeliveryProofIsLoading: isLoading, isUploadDeliveryProofSuccess: isSuccess };
};