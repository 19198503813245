import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Button, Select } from "../../../components";

import { actorsRequest } from "../../../sagas/setting/actions";

import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const actorDropdownData = [
  {
    title: "IT Admin",
    name: "itAdmin",
    id: "itAdmin",
  },
  {
    title: "Salesperson",
    name: "salesPerson",
    id: "salesPerson",
  },
  {
    title: "Approve Commision",
    name: "approveCommision",
    id: "approveCommision",
  },
  {
    title: "Doc Uploader",
    name: "docUploader",
    id: "docUploader",
  },
  {
    title: "Blog Status Poster",
    name: "blogStatusPoster",
    id: "blogStatusPoster",
  },
  {
    title: "Vendor Agent",
    name: "vendorAgent",
    id: "vendorAgent",
  },
  {
    title: "Deactivate Customer",
    name: "deactivateCustomer",
    id: "deactivateCustomer",
  },
  {
    title: "Customer Password Reset",
    name: "customerPasswordReset",
    id: "customerPasswordReset",
  },
  {
    title: "Sale Price Adjust",
    name: "salePriceAdjust",
    id: "salePriceAdjust",
  },
  {
    title: "Show Invitees",
    name: "showInvitees",
    id: "showInvitees",
  },
  {
    title: "EDI Load",
    name: "eDILoad",
    id: "eDILoad",
  },
  {
    title: "Mfr:Sylvania",
    name: "mfr:Sylvania",
    id: "mfr:Sylvania",
  },
  {
    title: "Vdr:CED",
    name: "vdr:CED",
    id: "vdr:CED",
  },
  {
    title: "Vdr:Sylvania",
    name: "vdr:Sylvania",
    id: "vdr:Sylvania",
  },
  {
    title: "Amazon Buyer",
    name: "amazonBuyer",
    id: "amazonBuyer",
  },
  {
    title: "Amazon Buyer CompanyID",
    name: "amazonBuyerCompanyID",
    id: "amazonBuyerCompanyID",
  },
  {
    title: "Ticket Auto FollowUp Rep",
    name: "ticketAutoFollowUpRep",
    id: "ticketAutoFollowUpRep",
  },
  {
    title: "Ticket Auto Assign Item",
    name: "ticketAutoAssignItem",
    id: "ticketAutoAssignItem",
  },
  {
    title: "Ticket AssigneeID By Seller",
    name: "TicketAssigneeIDBySeller",
    id: "TicketAssigneeIDBySeller",
  },
  {
    title: "Warehouse ManagerID",
    name: "warehouseManagerID",
    id: "warehouseManagerID",
  },
  {
    title: "AD Portal Manager",
    name: "AD Portal Manager",
    id: "AD Portal Manager",
  },
];

export function Actors({distributorId}) {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actorsRequest(distributorId));
  },[]);
  

  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Actors</div>
        <div className="devider2px" />

        <form className="formContainer actors-section-container" id="addActorsForm">
          {actorDropdownData.map((item, index) => {
            return (
              <Select
                key={index}
                id={item?.id}
                name={item?.name}
                label={item?.title}
                labelStyle="select-title-bold"
                textInputStyle="phoneinput"
                options={[
                  { label: "Anne Frank", value: "Anne Frank" },
                  { label: "Marshall Mathers", value: "Marshall Mathers" },
                  { label: "Kenny G", value: "Kenny G" },
                  { label: "Larry Page", value: "Larry Page" },
                ]}
              />
            );
          })}
        </form>
        <div className="devider1px" />

        <Button
          type={"submit"}
          className="manage-btn"
          data-bs-toggle="modal"
          data-bs-target="#jumper_modal"
        >
          <span className="manage-button-txt">save changes</span>
        </Button>
      </div>
    </PerfectScrollbar>
  );
}
