import './UserListItem.scss';

export default function Skeleton() {
    return (
        <div className='skeleton'>
            <div className='skeleton-icon'></div>
            <div className='name-group'>
                <p className='name mb0'></p>
                <p className='email mb0'></p>
            </div>
            <div className='created-at mb0'></div>
            <div className='role mb0'></div>
            <div className='status mb0'></div>
        </div>
    )
}
