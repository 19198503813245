import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  PosButton,
  PosImage,
  PosInput,
  PosPageHeading,
  PosSelect,
} from "apps/pos/Common/uiCore";
import OrderInfo from "./OrderInfo";

import { JobNameInput } from "apps/pos/Common/FacilityInput";

import {
  jobAndReceiptNameRegex,
  numbersOnlyRegEx,
  numericOnlyRegex,
  poNumberRegex,
} from "constants/constant";
import { ArrowBackRoundedIcon } from "constants/icons";

import images from "constants/images";
import { ChangeEvent, useEffect, useState } from "react";
import {
  getStatesRequest,
  isPurchaseOrderNoEdited,
  setBillingDetails,
  setJobNameForAll,
} from "sagas/pos/actions";

import MaskInput from "apps/pos/Common/maskInput/maskInput";
import "apps/pos/PaymentMethod/paymentMethos.scss";
import { AddAddressSchema } from "apps/pos/formik/validationSchema";
import { PosFlowDataProp, StateDataProps } from "constants/interfaces";
import { Formik } from "formik";
import { RootState } from "models";
import {
  blockInvalidChar,
  blockInvalidCharForPhone,
  changestringVal,
} from "utils/utils";
import CustomPaymentField from "../CustomPaymentField/CustomPaymentField";

import { Tab, Tabs } from "@material-ui/core";
import CashDrawerDropdown from "apps/pos/CashDrawerDropdown/CashDrawerDropdown";
import { PurchaseOrderInput } from "apps/pos/Common/PurchaseOrderInput";
import { ErrorMessage } from "apps/pos/formik/errorMessage";
import { creditCardOption } from "constants/staticData";
import { setPosFlowData } from "sagas/persistReducer/actions";

const CardOption = ({
  finalTotal,
  tempJobNameSave,
  setTempJobNameSave,
  cardNumberError,
  receiptNumberError,
  receiptNumber,
  setReceiptNumber,
  setReceiptNumberError,
  getCreditCardType,
  cardType,
  checkNumberValidation,
  subTotal,
  taxAmount,
  shipTaxAmount,
  orderId,
  cashOptionsSelected,
  setCashOptionsSelected,
  setCashDrawerError,
  cashDrawerError,
  // isPayPalAccount,
  // manualCard,
  setManualCard,
  nameOnCard,
  setNameOnCard,
  nameOnCardError,
  setNameOnCardError,
  completeOrderAPI,
  payPalCardNumber,
  setPayPalCardNumber,
  payPalCardNumberError,
  setPayPalCardNumberError,
  payPalCardType,
  setPayPalCardType,
  expiryDate,
  setExpiryDate,
  expiryDateError,
  setExpiryDateError,
  cvvNumber,
  setCvvNumber,
  cvvNumberError,
  setCvvNumberError,
  isBillingDetails,
  setIsBillingDetails,
  setCardNumberError,
  setCheckNumberValidation,
  cardNumber,
  setCardNumber,
  shippingTaxAmount,
  salesPersonName,
  loading,
  shipToTaxFlag,
  shippingFee,
  creditAmtApplied,
  poNumberSave,
  setPoNumberSave,
}) => {
  const dispatch = useDispatch();

  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { getStatesData, billingDetails } = useSelector<RootState, any>(
    (state) => state.pos
  );

  const [stateName, setStateName] = useState("");
  const [stateNameError, setStateNameError] = useState(false);
  const [extCode, setExtCode] = useState("");
  const [tabValue, setTabValue] = useState(0);

  // useEffect(() => {
  //   if(!isPayPalAccount) {
  //     setManualCard(false)
  //   }
  // },[isPayPalAccount])

  useEffect(() => {
    dispatch(getStatesRequest());
  }, []);

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    if (newValue === tabValue) return;
    setTabValue(newValue);
    if (newValue === 0) {
      setManualCard(true);
    } else {
      setManualCard(false);
    }
  };

  const handleForm = (value, setter, error, validationError) => {
    const numericValue = value.replace(numericOnlyRegex, "");
    setter(numericValue);
    if (numbersOnlyRegEx.test(value)) {
      setter(value);
      error(false);
      validationError(false);
    }
  };
  const handleJobName = (e) => {
    if (jobAndReceiptNameRegex.test(e.target.value)) {
      setTempJobNameSave(e.target.value);
      dispatch(setJobNameForAll(e.target.value));
    }
  };
  const handleReceiptNumber = (e) => {
    if (jobAndReceiptNameRegex.test(e.target.value)) {
      setReceiptNumber(e.target.value);
      setReceiptNumberError(false);
    }
  };
  const handleNameOnCard = (e) => {
    if (jobAndReceiptNameRegex.test(e.target.value)) {
      setNameOnCard(e.target.value);
      setNameOnCardError(false);
    }
  };

  useEffect(() => {
    return () => {
      setManualCard(true);
    };
  }, []);
  const { check } = images;

  const handleGoBack = () => {
    if (loading) return;
    setIsBillingDetails(false);
    setManualCard(true);
  };

  const handlePoNumberChange = (e) => {
    if (e.target.value === "" || poNumberRegex.test(e.target.value)) {
      dispatch(isPurchaseOrderNoEdited(true))
      const data = {
        ...posFlowData,
        poNumber: e.target.value,
      };
      dispatch(setPosFlowData(data));
      setPoNumberSave(e.target.value);
    }
  };

  return (
    <div>
      {!isBillingDetails ? (
        <div>
          <div className="pricing-modal-header">
            <div className="payment-option-heading">
              <div>
                <PosPageHeading heading="Payment" grayHeading />
                <div className="pricing-schedule-heading">
                  <PosPageHeading
                    heading={`Collect $${finalTotal} via card`}
                    blackHeading
                  />
                </div>
              </div>
            </div>
          </div>
          <OrderInfo
            subTotal={subTotal}
            taxAmount={taxAmount}
            shipTaxAmount={shipTaxAmount}
            shippingTaxAmount={shippingTaxAmount}
            orderId={orderId}
            shipToTaxFlag={shipToTaxFlag}
            salesPersonName={salesPersonName}
            shippingFee={shippingFee}
            creditAmtApplied={creditAmtApplied}
          />

          {/* {isPayPalAccount ? <div className="process-via"> */}
          {false ? (
            <div className="process-via">
              <span className="process-via-tabs">Process via</span>
              <div className="adjust-toggle-button">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  className={`adjust-stock-tab-container`}
                  selectionFollowsFocus
                >
                  <Tab
                    disableRipple
                    {...a11yProps(0)}
                    label="PayPal"
                    className={``}
                  />
                  <Tab
                    disableRipple
                    {...a11yProps(1)}
                    label="Manual"
                    className={``}
                  />
                </Tabs>
              </div>
            </div>
          ) : null}

          {/* {manualCard ? */}
          {false ? (
            <>
              <div className="">
                <span className="int-16-reg margintop-10">Card Number</span>
                <div className="margintop-10 mb-0 manual-card-input-container">
                  <CustomPaymentField
                    cardNumber={payPalCardNumber}
                    setCardNumber={setPayPalCardNumber}
                    setPayPalCardNumberError={setPayPalCardNumberError}
                    cardType={payPalCardType}
                    setCardType={setPayPalCardType}
                    expiryDate={expiryDate}
                    setExpiryDate={setExpiryDate}
                    cvvNumber={cvvNumber}
                    setCvvNumber={setCvvNumber}
                    setExpiryDateError={setExpiryDateError}
                    setCvvNumberError={setCvvNumberError}
                  />
                </div>
                <div className="d-flex jcsb">
                  {payPalCardNumberError?.isPayPalCardError ? (
                    <p className="validation-error-text margintop-10">
                      Please enter card number.
                    </p>
                  ) : (
                    <></>
                  )}
                  <div className="d-flex">
                    {expiryDateError?.isExpiryDateError ? (
                      <p className="validation-error-text margintop-10 marginright-6">
                        Please enter expiry date.
                      </p>
                    ) : (
                      <></>
                    )}
                    {expiryDateError?.isValidationError ? (
                      <p className="validation-error-text margintop-10 marginright-6">
                        Please enter valid expiry date.
                      </p>
                    ) : (
                      <></>
                    )}
                    {cvvNumberError ? (
                      <p className="validation-error-text margintop-10 marginright-4">
                        Please enter cvc.
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="margintop-10 mb-0">
                <div className="facility-box">
                  <span className="int-16-reg">Name on card</span>
                  <div className="margintop-10 mb-0">
                    <PosInput
                      variant="filled"
                      id="filled-basic"
                      InputProps={{ disableUnderline: true }}
                      value={nameOnCard}
                      onChange={handleNameOnCard}
                    />
                  </div>
                </div>
              </div>
              {nameOnCardError ? (
                <p className="validation-error-text margintop-10">
                  Please enter name on card.
                </p>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <div className="margintop-10 mb-0">
                <div className="manual-card-box">
                  <div className="manual-card-last-4-digit">
                    <span className="int-16-reg">Last 4 digits</span>
                    <div className="margintop-10 mb-0">
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        InputProps={{ disableUnderline: true }}
                        value={cardNumber}
                        className="search-input-focus"
                        maxLength={4}
                        onChange={(e) => {
                          handleForm(
                            e.target.value,
                            setCardNumber,
                            setCardNumberError,
                            setCheckNumberValidation
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="facility-box manual-card-card-type arrow-black">
                    <span className="int-16-reg">Card type</span>
                    <div className="margintop-10 mb-0">
                      <PosSelect
                        name={cardType}
                        value={cardType}
                        onChange={(e) => getCreditCardType(e.target.value)}
                        className=" tabDropdown payment-select"
                        options={creditCardOption}
                      />
                    </div>
                  </div>
                </div>
                {cardNumberError ? (
                  <p className="validation-error-text margintop-10">
                    Please enter card number.
                  </p>
                ) : (
                  <></>
                )}
                {checkNumberValidation && (
                  <p className="validation-error-text">
                    Last 4 digits of credit card are invalid
                  </p>
                )}

                <div className="facility-box margintop-10">
                  <span className="int-16-reg">Receipt number</span>
                  <div className="margintop-10 mb-0">
                    <PosInput
                      variant="filled"
                      id="filled-basic"
                      InputProps={{ disableUnderline: true }}
                      value={receiptNumber}
                      onChange={handleReceiptNumber}
                      className="search-input-focus"
                    />
                  </div>
                </div>
                {receiptNumberError && (
                  <p className="validation-error-text margintop-10">
                    Please enter receipt number.
                  </p>
                )}
              </div>
            </>
          )}
          <div className="margintop-10 mb-0">
            <CashDrawerDropdown
              cashOptionsSelected={cashOptionsSelected}
              setCashOptionsSelected={setCashOptionsSelected}
              setCashDrawerError={setCashDrawerError}
              cashDrawerError={cashDrawerError}
            />
          </div>
          <div className="margintop-10 mb-0">
            <div className="manual-card-last-4-digit">
              <div className="pay-later-job-input input-btn-spacing-div">
                <PurchaseOrderInput
                  value={poNumberSave}
                  onChange={handlePoNumberChange}
                />
                <JobNameInput
                  value={tempJobNameSave}
                  onChange={handleJobName}
                />
              </div>
            </div>
          </div>
          {/* {manualCard ?  */}
          {false ? (
            <>
              <div className="paypal-section-continue-btn">
                <PosButton
                  variant="contained"
                  color="primary"
                  className={`billing-form-continue-btn`}
                  onClick={() => {
                    completeOrderAPI();
                  }}
                >
                  <span className="btn-text complete-order-text">
                    {"Continue"}
                  </span>
                </PosButton>
              </div>
            </>
          ) : null}
        </div>
      ) : (
        <div>
          <div className="pricing-modal-header">
            <div className="payment-option-heading">
              <div>
                <PosPageHeading heading="Card Payment" grayHeading />
                <div className="pricing-schedule-heading">
                  <PosPageHeading
                    heading={`Enter Credit Card Billing Details`}
                    blackHeading
                  />
                </div>
              </div>
            </div>
          </div>

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              title: "",
              jobDescription: "",
              state: "",
              companyName: "",
              companyAddress: "",
              city: "",
              zip: null,
              emailAddress: "",
              phoneNumber: null,
              countryCode: 1,
            }}
            validationSchema={AddAddressSchema} // Change validation schema if required
            enableReinitialize
            onSubmit={() => {
              if (loading) return;
              completeOrderAPI();
            }}
          >
            {({
              handleSubmit,
              values,
              handleChange,
              errors,
              touched,
              setFieldValue,
            }) => {
              const {
                firstName,
                lastName,
                companyName,
                companyAddress,
                city,
                zip,
                emailAddress,
                phoneNumber,
                countryCode,
              } = values;
              return (
                <>
                  <div className="billing-details-form">
                    {
                      <Container>
                        <form className="mt-5 pb-5">
                          <span className="int-20-reg-bold">
                            Personal Information
                          </span>
                          <div className="facility-box form-field-spacing">
                            <span className="billing-form-field-title">
                              First Name
                            </span>
                            <PosInput
                              variant="filled"
                              id="filled-basic"
                              InputProps={{ disableUnderline: true }}
                              className="mb-0 mt-2"
                              name="firstName"
                              onChange={(e) => {
                                if (
                                  !e.target.value.includes("  ") &&
                                  e.target.value.charAt(0) !== " "
                                ) {
                                  setFieldValue("firstName", e.target.value);
                                  dispatch(
                                    setBillingDetails({
                                      ...billingDetails,
                                      firstName: e.target.value,
                                    })
                                  );
                                }
                              }}
                              value={firstName}
                            />
                          </div>
                          {errors.firstName && touched.firstName && (
                            <p className="validation-error-text mt-2">
                              {errors.firstName}
                            </p>
                          )}
                          <div className="facility-box form-field-spacing">
                            <span className="billing-form-field-title">
                              Last Name
                            </span>
                            <PosInput
                              variant="filled"
                              id="filled-basic"
                              InputProps={{ disableUnderline: true }}
                              className="mb-0 mt-2"
                              name="lastName"
                              onChange={(e) => {
                                if (
                                  !e.target.value.includes("  ") &&
                                  e.target.value.charAt(0) !== " "
                                ) {
                                  setFieldValue("lastName", e.target.value);
                                  dispatch(
                                    setBillingDetails({
                                      ...billingDetails,
                                      lastName: e.target.value,
                                    })
                                  );
                                }
                              }}
                              value={lastName}
                            />
                          </div>
                          {errors.lastName && touched.lastName && (
                            <p className="validation-error-text mt-2">
                              {errors.lastName}
                            </p>
                          )}
                          <div className="slim-liner mb-4 form-field-spacing" />
                          <span className="int-20-reg-bold">
                            Company Information
                          </span>
                          <div className="facility-box mt-4">
                            <span className="billing-form-field-title">
                              Company Name
                            </span>
                            <PosInput
                              variant="filled"
                              id="filled-basic"
                              InputProps={{ disableUnderline: true }}
                              className="mb-0 mt-2"
                              name="companyName"
                              onChange={(e) => {
                                if (
                                  !e.target.value.includes("  ") &&
                                  e.target.value.charAt(0) !== " "
                                ) {
                                  setFieldValue("companyName", e.target.value);
                                  dispatch(
                                    setBillingDetails({
                                      ...billingDetails,
                                      companyName: e.target.value,
                                    })
                                  );
                                }
                              }}
                              value={companyName}
                            />
                            {errors.companyName && touched.companyName && (
                              <p className="validation-error-text mt-2">
                                {errors.companyName}
                              </p>
                            )}
                          </div>
                          <div className="facility-box form-field-spacing">
                            <span className="billing-form-field-title">
                              Company Address
                            </span>
                            <PosInput
                              variant="filled"
                              id="filled-basic"
                              InputProps={{ disableUnderline: true }}
                              className="mb-0 mt-2"
                              name="companyAddress"
                              onChange={(e) => {
                                if (
                                  !e.target.value.includes("  ") &&
                                  e.target.value.charAt(0) !== " "
                                ) {
                                  setFieldValue(
                                    "companyAddress",
                                    e.target.value
                                  );
                                  dispatch(
                                    setBillingDetails({
                                      ...billingDetails,
                                      companyAddress: e.target.value,
                                    })
                                  );
                                }
                              }}
                              value={companyAddress}
                            />
                            <span className="address-sub-text">
                              Use street address
                            </span>
                            {errors.companyAddress &&
                              touched.companyAddress && (
                                <p className="validation-error-text mt-2">
                                  {errors.companyAddress}
                                </p>
                              )}
                          </div>
                          <div className="facility-box form-field-spacing">
                            <span className="billing-form-field-title">
                              City
                            </span>
                            <PosInput
                              variant="filled"
                              id="filled-basic"
                              InputProps={{ disableUnderline: true }}
                              className="mb-0 mt-2"
                              name="city"
                              onChange={(e) => {
                                if (
                                  !e.target.value.includes("  ") &&
                                  e.target.value.charAt(0) !== " "
                                ) {
                                  setFieldValue("city", e.target.value);
                                  dispatch(
                                    setBillingDetails({
                                      ...billingDetails,
                                      city: e.target.value,
                                    })
                                  );
                                }
                              }}
                              value={city}
                            />
                            {errors.city && touched.city && (
                              <p className="validation-error-text mt-2">
                                {errors.city}
                              </p>
                            )}
                          </div>
                          <div className="facility-box form-field-spacing arrow-black">
                            <span className="billing-form-field-title mb-2">
                              State
                            </span>
                            <PosSelect
                              isSearch
                              id={"state"}
                              name="state"
                              defaultValue={stateName}
                              value={stateName}
                              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                handleChange("state");
                                setStateName(e.target.value);
                                dispatch(
                                  setBillingDetails({
                                    ...billingDetails,
                                    state: e.target.value,
                                  })
                                );
                              }}
                              options={
                                getStatesData?.length &&
                                getStatesData?.map(
                                  ({ stateName }: StateDataProps) => {
                                    return {
                                      label: stateName,
                                      value: stateName,
                                    };
                                  }
                                )
                              }
                            />
                            {stateName === "" && stateNameError && (
                              <p className="validation-error-text mt-2">
                                Please select a state.
                              </p>
                            )}
                          </div>
                          <div className="facility-box form-field-spacing">
                            <span className="billing-form-field-title">
                              Zip
                            </span>
                            <PosInput
                              type="number"
                              variant="filled"
                              id="filled-basic"
                              InputProps={{ disableUnderline: true }}
                              inputProps={{ maxLength: 6 }}
                              className="mb-0 mt-2"
                              name="zip"
                              onKeyPress={(e) => blockInvalidChar(e)}
                              onChange={(e) => {
                                const re = /^\d{0,10}(\.\d{0,2})?$/;
                                if (
                                  e.target.value === "" ||
                                  (re.test(e.target.value) && changestringVal)
                                ) {
                                  setFieldValue("zip", e.target.value);
                                  dispatch(
                                    setBillingDetails({
                                      ...billingDetails,
                                      zip: e.target.value,
                                    })
                                  );
                                }
                              }}
                              value={zip}
                              onWheel={(e) => {
                                e.target.blur();
                              }}
                            />
                            <ErrorMessage name="zip" />
                          </div>
                          <div className="facility-box form-field-spacing">
                            <span className="billing-form-field-title">
                              Email Address
                            </span>
                            <PosInput
                              variant="filled"
                              id="filled-basic"
                              InputProps={{ disableUnderline: true }}
                              className="mb-0 mt-2"
                              type="email"
                              name="emailAddress"
                              onChange={(e) => {
                                if (
                                  !e.target.value.includes("  ") &&
                                  e.target.value.charAt(0) !== " "
                                ) {
                                  setFieldValue("emailAddress", e.target.value);
                                  dispatch(
                                    setBillingDetails({
                                      ...billingDetails,
                                      email: e.target.value,
                                    })
                                  );
                                }
                              }}
                              value={emailAddress}
                            />
                            {errors.emailAddress && touched.emailAddress && (
                              <p className="validation-error-text mt-2">
                                {errors.emailAddress}
                              </p>
                            )}
                          </div>
                          <div className="facility-box form-field-spacing">
                            <div className="phone-number-title">
                              <span className="billing-form-field-title">
                                Phone Number
                              </span>
                              <div className="phone-ext-title">
                                <span>Ext</span>
                                <span className="phone-optional-head ml-3">
                                  (Optional)
                                </span>
                              </div>
                            </div>
                            <div className="facilit-phone-number">
                              <PosInput
                                variant="filled"
                                id="filled-basic"
                                InputProps={{ disableUnderline: true }}
                                inputProps={{ maxLength: 3 }}
                                disabled
                                className="phone-country-code-input mb-0 mt-2"
                                value={countryCode}
                                onChange={(e) => {
                                  const re = /^[0-9]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    setFieldValue(
                                      "countryCode",
                                      e.target.value
                                    );
                                    dispatch(
                                      setBillingDetails({
                                        ...billingDetails,
                                        countryCode: e.target.value,
                                      })
                                    );
                                  }
                                }}
                              />
                              <div className="masked-phone-number">
                                <MaskInput
                                  mask="999 999 9999"
                                  isClassName="masked-phone-number-input mb-0"
                                  id={"filled-basic"}
                                  name={"phoneNumber"}
                                  alwaysShowMask={true}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "phoneNumber",
                                      e.target.value
                                    );
                                    dispatch(
                                      setBillingDetails({
                                        ...billingDetails,
                                        phoneNumber: e.target.value,
                                      })
                                    );
                                  }}
                                  onKeyPress={(e) =>
                                    blockInvalidCharForPhone(e)
                                  }
                                  value={phoneNumber || ""}
                                />
                              </div>
                              <PosInput
                                variant="filled"
                                InputProps={{ disableUnderline: true }}
                                id={"extCode"}
                                className="phone-ext-input mb-0 mt-2"
                                value={extCode}
                                inputProps={{ maxLength: 4 }}
                                onChange={(e) => {
                                  const re = /^[0-9]+$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    setExtCode(e.target.value);
                                    dispatch(
                                      setBillingDetails({
                                        ...billingDetails,
                                        extCode: e.target.value,
                                      })
                                    );
                                  }
                                }}
                              />
                            </div>
                            <ErrorMessage name="phoneNumber" />
                          </div>
                          <div className="btns-container-discard-save mt-5 d-flex jcsb">
                            <PosButton
                              variant="contained"
                              className="btn-discard new-address-cancel-btn btn"
                              onClick={handleGoBack}
                              startIcon={<ArrowBackRoundedIcon />}
                            >
                              Go Back
                            </PosButton>
                            <PosButton
                              variant="contained"
                              color="primary"
                              className={`${loading ? "loading" : ""
                                } modal-select-default-btn ship-btn-dark justify-content-start payment-complete-order-btn`}
                              onClick={(e) => {
                                e.preventDefault();
                                setStateNameError(true);
                                handleSubmit();
                              }}
                              disabled={loading}
                            >
                              {!loading && (
                                <PosImage
                                  className="icn-btn complete-order-icon"
                                  src={check}
                                  alt="check-icon"
                                />
                              )}
                              <span className="complete-order-text">
                                {loading ? (
                                  <>
                                    <span>Processing...</span>
                                  </>
                                ) : (
                                  "Complete Order"
                                )}
                              </span>
                            </PosButton>
                          </div>
                        </form>
                      </Container>
                    }
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default CardOption;
