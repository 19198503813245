import { useQuery } from "@tanstack/react-query";
import { getSalesPersons } from "apps/pos/pages/Drafts/request";
import { QueryKeys } from "apps/pos/pages/Drafts/hooks/queryKeys";

export const useGetSalesPersons = () => {
  const { data } = useQuery([QueryKeys.getSalesPersons], async () => {
    return getSalesPersons();
  });

  return { salesPersons: data?.data || [] };
};
