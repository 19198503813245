import { useSelector } from "react-redux";

import ScheduleItem from "./ScheduleItem";

import { PosButton } from "apps/pos/Common/uiCore";

import { Paths } from "enums";
import { OpenInNewIcon } from "constants/icons";
import { labels, retailPriceLabel } from "../modules/productDetailsContainer/constants";
import { commonConstants } from "../helper/commonConstants";
import { distributorData } from "sagas/distributor/selector";

import "./ProductDetailsLogsItemModels.scss"
const SchedulesGroup = (props) => {
    const distributor = useSelector(distributorData)

    const { schedules, visible , onClose } = props;
    const filteredSchedules = schedules?.filter(schedule => schedule?.vendorProductGroups.length > 0);

    const { manageSchedules } = labels;
    const {name , breakQty , markup , basePrice , description  , more , viewAllSchedules , message} = retailPriceLabel;

    const mangeSchedules = () => {
        return window.open(`${distributor?.httpsUrl}${Paths.manageSchedulesLink}`, commonConstants.target, commonConstants.noreferrer);
    }

    return (
        filteredSchedules?.length ? (
            <>
                <div className="schedules-group">
                    <div className="schedules-header">
                        <p>
                            {name}
                        </p>
                        <p>
                            {breakQty}
                        </p>
                        <p>
                            {markup}
                        </p>
                        <p>
                            {basePrice}
                        </p>
                        <p>
                            {description}
                        </p>
                    </div>
                    {visible ? filteredSchedules.slice(0, 3).map(schedule => (
                        <ScheduleItem
                            key={schedule.id}
                            schedule={schedule}
                        />
                    )) : filteredSchedules?.map(schedule => (
                        <ScheduleItem
                            key={schedule.id}
                            schedule={schedule}
                        />
                    ))}
                    {(visible && filteredSchedules.length > 3 )? (
                        <div className="more-items-group">
                            <p className="more-items-label">
                                {`+${filteredSchedules.length - 3} ${more}`}
                            </p>
                            <button className='more-items-view-btn' onClick={onClose}>{viewAllSchedules}</button>
                        </div>
                    ) : null}
                </div>
            </>
        ) : (
        <>
            <p className='schedules-empty-state'>
                {message}
            </p>
            <div>
                <PosButton
                    variant="contained"
                    className="btn product-detail-medium-btn mt-2"
                    endIcon={<OpenInNewIcon />}
                    onClick={mangeSchedules}
                >
                    {manageSchedules}
                </PosButton>
            </div>
        </>
        )
    )
}

export default SchedulesGroup;