import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Input,
  Select,
} from "../../../components";
import { carriersRequest } from "../../../sagas/setting/actions";

import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";
import { RootState } from "../../../models";
import { carrierInfo } from "../../../models/setting";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const CarrierSchema = Yup.object().shape({
  carrierName: Yup.string()
    .required("Carrier name required.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for company name"),
  carrierAccNum: Yup.string()
    .required("Carrier email required.")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Account num is not valid"
    ),
  defaultShipMethod: Yup.string().required("Enter default method"),
  defaultAttentionName: Yup.string().required("Enter default attention name"),
  minShippingCharge: Yup.string().required(
    "Please enter min. shipping charge amount"
  ),
});

export function Carriers({distributorId}) {
  const dispatch = useDispatch();

  const [carriersData, setCarriersData] = useState<carrierInfo>();

  const { carrierDetails } = useSelector<RootState, any>((state) => state.setting);


  useEffect(() => {
    dispatch(carriersRequest(distributorId));
  },[]);

  useEffect(()=>{
    setCarriersData(carrierDetails && carrierDetails )
  }, [carrierDetails])

  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Carriers</div>
        <div className="devider2px" />

        <Formik
          enableReinitialize= {true}
          initialValues={{
            carrierName: carriersData && carriersData[0]?.carrierName,
            carrierAccNum:  carriersData && carriersData[0]?.carrierAccountNum ,
            defaultShipMethod: carriersData && carriersData[0]?.defaultShipMethod ? carriersData[0]?.defaultShipMethod : "",
            defaultAttentionName: carriersData && carriersData[0]?.defaultAttentionName ? carriersData[0]?.defaultAttentionName : "",
            minShippingCharge: carriersData && carriersData[0]?.minShippingChargeAmt ? carriersData[0]?.minShippingChargeAmt : "",
          }}
          validationSchema={CarrierSchema}
          onSubmit={() => {
            // Required Later
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => {
            return (
              <form id="addContainerForm">
                <div className="carriers-container">
                  <Input
                    id={"carrierName"}
                    name={"carrierName"}
                    value={values.carrierName}
                    onChange={handleChange}
                    label={"Name"}
                    type={"text"}
                    textInputStyle="mt-1"
                  />
                  {errors.carrierName && touched.carrierName ? (
                    <p className="validation-error-text">
                      <>{errors.carrierName}</>
                    </p>
                  ) : null}
                  <Input
                    textInputStyle="mt-1"
                    id={"carrierAccNum"}
                    name={"carrierAccNum"}
                    value={values.carrierAccNum}
                    onChange={handleChange}
                    label={"Account number"}
                    type={"text"}
                  />
                  {errors.carrierAccNum && touched.carrierAccNum ? (
                    <p className="validation-error-text">
                      <>{errors.carrierAccNum}</>
                    </p>
                  ) : null}

                  <div className="carriers-select">
                    <Select
                      className="carriers-options"
                      id={"defaultShipMethod"}
                      name={"defaultShipMethod"}
                      value={values.defaultShipMethod}
                      onChange={handleChange}
                      label={"Default Ship Method"}
                      textInputStyle="mt-1"
                      options={[
                        {
                          label: "Default Shipping",
                          value: "Default Shipping",
                        },
                      ]}
                    />

                    <Select
                      className="carriers-options"
                      id={"defaultAttentionName"}
                      name={"defaultAttentionName"}
                      value={values.defaultAttentionName}
                      onChange={handleChange}
                      label={"Default Attention Name"}
                      textInputStyle="phoneinput mt-1"
                      options={[
                        {
                          label: "Default Attention",
                          value: "Default Attention",
                        },
                      ]}
                    />
                  </div>
                  {/* <div className="carriers-select">
                    {errors.carrierAccNum && touched.carrierAccNum ? (
                      <p className="validation-error-text">
                        {errors.defaultShipMethod}
                      </p>
                    ) : null}
                    {errors.carrierAccNum && touched.carrierAccNum ? (
                      <p className="validation-error-text">
                        {errors.defaultAttentionName}
                      </p>
                    ) : null}
                  </div> */}
                  <Input
                    textInputStyle="mt-1"
                    id={"minShippingCharge"}
                    name={"minShippingCharge"}
                    value={values.minShippingCharge}
                    onChange={handleChange}
                    label={"Min. Shipping Charge Amt"}
                    type={"text"}
                  />
                  {errors.minShippingCharge && touched.minShippingCharge ? (
                    <p className="validation-error-text">
                      <>{errors.minShippingCharge}</>
                    </p>
                  ) : null}

                  <div className="carriers-default mt-2">
                    <i
                      className="fa fa-check-circle radio-button"
                      color="#000"
                    ></i>
                    <label>Set as default carrier</label>
                  </div>
                </div>

                {/* <div className="carriers-btn">
                  <i className="fa fa-plus" color="#000"></i>
                  <span>ADD NEW CARRIER</span>
                  <span></span>
                </div> */}

                <div className="devider1px" />

                <Button
                  type="submit"
                  onClick={handleSubmit}
                  className="manage-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#jumper_modal"
                >
                  <span className="manage-button-txt">save changes</span>
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </PerfectScrollbar>
  );
}
