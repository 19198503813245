import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import dayjs, { Dayjs, ManipulateType } from "dayjs";

import { ButtonContainer } from "components";
import Button from "components/SPButton/Button";
import { PosDatePicker, PosModel } from "apps/pos/Common/uiCore";
import { IconButton } from "apps/springboard/Component/SBItems/elements";

import { ExtendByData, byDays } from "utils/data";
import { dateType, springBoardText } from "constants/constant";
import {
  extendDays,
  getDateWithAddDays,
  setDateInYearFormat,
} from "utils/dayjsFunc";
import texts from "apps/springboard/locales";

import useExpirationDate from "services/quoteDetail/hooks/useExpirationDate";
import { UpdateModalContent } from "sagas/modal/actions";
import { dateEnum } from "constants/staticData";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";

interface ExpirationDateFormData {
  expDate: string;
}

interface ExpirationDateFormProps {
  open: boolean;
  jobQuoteID: number;
  creatorId: number;
  currentExpDate?: string;
  salespersonName?: string;
  handleCloseModel: () => void;
  handleChangeSuccess?: (string) => void;
}

export const ExpirationDateForm = (props: ExpirationDateFormProps) => {
  const dispatch = useDispatch();
  const {
    changeExpirationDate,
    expireDateMessage,
    expireDateRequired,
    extendBy: extendByLabel,
    change,
    cancel,
    zero,
    one,
    two,
  } = springBoardText;
  const { jobQuoteID, creatorId, open, handleCloseModel, handleChangeSuccess } =
    props;
  const [extendByFlag, setExtendByFlag] = useState(null);
  const getDate = getDateWithAddDays({ daysToAdd: one, period: byDays.days });
  const [selectedDate, setSelectedDate] = useState<Dayjs>(getDate);
  const [isLoading, setIsLoading] = useState(false);
  const [valueChanged, setValueChanged] = useState(false);
  const extendByOptions = [2, 1, 2];
  const timeUnits: ManipulateType[] = ["day", "week", "week"];
  const { setExpirationDatePayload, expirationDateSuccess } =
    useExpirationDate();
  const { handleSubmit, formState, reset } = useForm<ExpirationDateFormData>();

  const calculateDueDate = (currentDate, flag) => {
    switch (flag) {
      case zero:
        return extendDays({
          date: currentDate,
          daysToAdd: two,
          type: byDays.days,
          dateFormat: dateEnum.ymdFormat,
        });
      case one:
        return extendDays({
          date: currentDate,
          daysToAdd: one,
          type: byDays.week,
          dateFormat: dateEnum.ymdFormat,
        });
      case two:
        return extendDays({
          date: currentDate,
          daysToAdd: two,
          type: byDays.week,
          dateFormat: dateEnum.ymdFormat,
        });
      default:
        return setDateInYearFormat(selectedDate);
    }
  };

  useEffect(() => {
    if (expirationDateSuccess) {
      dispatch(snakbarShow({ message: texts.expireDateChange }));
      setIsLoading(false);
      setValueChanged(false);
      reset();
      dispatch(
        UpdateModalContent({
          isOpen: false,
          children: null,
          fixedModal: false,
          removePaddingBottom: false,
          noMaxWidth: false,
        })
      );
      handleCloseModel();
      handleChangeSuccess(dateType.expireDate);
    }
  }, [expirationDateSuccess]);

  const onSubmit = () => {
    setIsLoading(true);
    const expDate = calculateDueDate(new Date(), extendByFlag);
    const selectedExpDate = dayjs(selectedDate).format(dateEnum.mmddyyyy);
    const setPayload = {
      jobQuoteID,
      expDate: expDate || selectedExpDate,
      creatorId,
    };
    setExpirationDatePayload(setPayload);
  };

  const cancelForm = () => {
    dispatch(
      UpdateModalContent({
        children: null,
        isOpen: false,
        fixedModal: false,
        removePaddingBottom: false,
        noMaxWidth: false,
      })
    );
    handleCloseModel();
  };

  const extendBy = (index) => {
    setValueChanged(true);
    setExtendByFlag(index);
    setSelectedDate(dayjs().add(extendByOptions[index], timeUnits[index]));
  };

  return (
    <Fragment>
      <PosModel
        open={open}
        onClose={handleCloseModel}
        className={`set-customer-code-modal`}
        dialogClassName={`set-customer-code-modal-container`}
        dialogHeader={
          <div className="modal-header pl-4">
            <span className="modal-title">
              <div className="pt-2">
                <h3 className="f20-Inter-500-sab mb-0 mb-0">
                  {changeExpirationDate}
                </h3>
              </div>
            </span>
            <IconButton
              name="close"
              className="close-icon"
              type="tertiary"
              onClick={handleCloseModel}
            />
          </div>
        }
      >
        <div className="ml-4">
          <p
            className="fs-16 inter-reg black_text"
            style={{ marginTop: 10, marginBottom: 25 }}
          >
            {expireDateMessage}
          </p>
        </div>
        <form
          className=""
          id="expirationDateForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="set-customer-code-modal-container">
            <div className="notes-added-user-name">
              <span>New Expiry Date</span>
            </div>
            <div className="characteristics-main-info">
              <div className="custom-datePicker storage-date-picker w-100">
                <PosDatePicker
                  value={dayjs(selectedDate)}
                  onChange={(newValue) => {
                    setSelectedDate(newValue);
                    setValueChanged(true);
                  }}
                />
              </div>
            </div>
          </div>
          {formState.errors.expDate && <div>{expireDateRequired}</div>}
          <div className="mb-3 d-flex align-items-center justify-content-between extended-week-info">
            <div
              className="d-flex align-items-center justify-content-sm-around"
              style={{ width: "20rem" }}
            >
              <span className="extend-by-title">{extendByLabel}</span>
              {ExtendByData.map((data: string, index: number) => {
                return (
                  <div
                    key={index}
                    className={`d-flex align-items-center justify-content-center extend ${
                      extendByFlag != null
                        ? extendByFlag === index
                          ? `extend-selected`
                          : `extend-unselected`
                        : ""
                    }`}
                    onClick={() => extendBy(index)}
                  >
                    {data}
                  </div>
                );
              })}
            </div>
          </div>
          <ButtonContainer className="justify-content-end mt-5 gap-2">
            <Button label={cancel} disabled={isLoading} onClick={cancelForm} />
            <Button
              label={change}
              type="primary"
              loading={isLoading}
              disabled={isLoading || !valueChanged}
              onClick={handleSubmit(onSubmit)}
            />
          </ButtonContainer>
        </form>
      </PosModel>
    </Fragment>
  );
};
