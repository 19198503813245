import { Dispatch, SetStateAction, ReactNode } from "react";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
    PosIconButton,
} from "apps/pos/Common/uiCore";

import useStyles from "apps/pos/CustomerNotesModalStyling";

import "./ProductDetailsCommonModal.scss";

export interface ProductDetailsCommonModalProp {
    open?: boolean;
    onClose?: Dispatch<SetStateAction<boolean>>;
    children?: ReactNode;
    modalMainTitle?: string;
    modaSecondTitle?: string;
    isProductDetailModalFooter?: boolean;
    innerBodyClassName?: string;
    mainBodyClassName?:string;
}

export default function ProductDetailsCommonModal({
    open,
    onClose,
    children,
    modalMainTitle,
    modaSecondTitle,
    innerBodyClassName,
    isProductDetailModalFooter,
    mainBodyClassName
}: ProductDetailsCommonModalProp) {

    const classes = useStyles();

    const handleClose = () => {
        onClose(false);
    };

    return (
        <>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={`${classes.modal} product-detail-notes-modal`}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={`${classes.paper} product-detail-notes-paper-div ${mainBodyClassName ? mainBodyClassName :""}`}>
                            <div className="notes-model product-detail-notes-model">
                                <div className="notes-model-header d-flex justify-content-between align-items-center">
                                    <div>
                                        <span className={`${modaSecondTitle ? "text-color-gray d-block " : ""}`}>{modalMainTitle}</span>
                                        {modaSecondTitle ? <span className="d-block">{modaSecondTitle}</span> : null}
                                    </div>
                                    <PosIconButton
                                        onClick={handleClose}
                                        className="notes-model-header-close-icon"
                                        color="primary"
                                    >
                                        <CloseIcon />
                                    </PosIconButton>
                                </div>
                                <div className={`notes-model-content product-detail-noted-modal-content ${innerBodyClassName ? innerBodyClassName : "" } ${isProductDetailModalFooter ? 'detail-modal-content-bottom-spacing' : ''}`}>
                                    {children}
                                </div>
{/* May need later  */}
                                {/* {isProductDetailModalFooter &&
                                    <div className="notes-model-footer">
                                        <div className="d-flex notes-btn-div product-detail-modal-footer-spacing justify-content-end">
                                            <PosButton
                                                variant="contained"
                                                onClick={handleClose}
                                                className="back-button first-product-detail-btn"
                                            >
                                                Back
                                            </PosButton>
                                            <PosButton
                                                fontWeight
                                                className="btn ml-2 second-product-detail-btn"
                                                variant="contained"
                                                color="primary"
                                                onClick={onSubmit}
                                            >
                                                Save Changes
                                            </PosButton>
                                        </div>
                                    </div>} */}
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        </>
    );
}
