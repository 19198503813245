import { useState, useEffect, useMemo } from "react";
import {
  AnimatePresence,
  motion,
  MotionConfig,
} from "framer-motion/dist/framer-motion";
import useMeasure from "react-use-measure";

import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import OverlaySkrim from "../../apps/admin/OverlaySkrim";
import ResizablePanel from "../ResizablePanel";
import IconButton from "apps/admin/components/Elements/IconButton";
import UnitsList from './Components/UnitsListComponent'
import ShelvesList from "./Components/ShelvesListComponent";
import RowsList from "./Components/RowListComponent";

import { fiveWarehouseID  } from 'constants/constant'
import { getLocalStoraGeItem, saleable } from "utils/utils";

import "./LocationPicker.scss";

const transition = {
  type: "spring",
  bounce: 0,
  duration: 0.35,
};

const LocationPicker = ({
  visible,
  setVisible,
  selectedLocation,
  setSelectedLocation,
  cartEditModalData,
  toLocationShelfDetails,
  getLocationProductWise,
  rowsData,
  rowsLoading,
  rowsError,
  unitsData,
  unitsLoading,
  unitsError,
  shelvesData,
  shelvesLoading,
  shelvesError,
  isNonSaleableHide,
  isReturnCart
}) => {
  const [page, setPage] = useState("row");
  const [direction, setDirection] = useState(1);
  const saleableRows = useMemo(() => Array.isArray(rowsData) ? rowsData.filter(saleable) : [], [rowsData])
  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {}

  const warehouse = (!isReturnCart && stockWarehouseId !== fiveWarehouseID);

  const findItem = (array) => {
    return array?.length && array.find((item) => Boolean(item));
  }

  useEffect(() => {
    const row = findItem(saleableRows);
    const unit = findItem(unitsData);
    const shelf = findItem(shelvesData);
    warehouse && setSelectedLocation((current) => ({
      ...current,
      row:{id: row?.stockRowID ?? row?.rowID, name: row?.rowName},
      unit:{id: unit?.stockUnitID ?? unit?.unitID , name: unit?.unitName},
      shelf:{id: shelf?.stockShelfID ?? shelf?.shelfID, name: shelf?.shelfName},
    }));
    if(saleableRows?.length === 1 && unitsData?.length === 1 && shelvesData?.length === 1) {
      warehouse && setSelectedLocation((current) => ({
          ...current,
          row:{id: row?.stockRowID ?? row?.rowID, name: row?.rowName},
          unit:{id: unit?.stockUnitID ?? unit?.unitID , name: unit?.unitName},
          shelf:{id: shelf?.stockShelfID ?? shelf?.shelfID, name: shelf?.shelfName},
        }));
    }
    
  },[saleableRows, unitsData, shelvesData])

  // Might need this code.
  // when the selected row changes
  // update units
  // useEffect(() => {
  //   // reset unit and shelf selection when the selected row changes
  //   !cartEditModalData &&
  //     setSelectedLocation((current) => ({
  //       ...current,
  //       unit: { id: "", name: "" },
  //       shelf: { id: "", name: "" },
  //     }));
  // }, [selectedLocation.row]);

  // Might need this code.
  // const resetSelectedLocation = () => {
  //   !cartEditModalData &&
  //     setSelectedLocation((current) => ({
  //       ...current,
  //       row: { id: "", name: "" },
  //       unit: { id: "", name: "" },
  //       shelf: { id: "", name: "" },
  //     }));
  // };

  const closePicker = () => {
    setVisible(() => false);
  };

  useEffect(() => {
    if (!visible && !cartEditModalData) {
      setPage(() => "row");
      
      if(selectedLocation.row.name == "" || selectedLocation.unit.name == "" || selectedLocation.shelf.name ==""){
        setSelectedLocation((selectedLocation) => ({
        ...selectedLocation,
        row: { id: "", name: "" },
        unit: { id: "", name: "" },
        shelf: { id: "", name: "" },
      }));
      }
      
    }
  }, [visible]);

  const moveForward = () => {
    setDirection(() => 1);

    if (page === "row") {
      setPage(() => "unit");
    }

    if (page === "unit") {
      setPage(() => "shelf");
    }

    if (page === "shelf") {
      closePicker();
    }
  };

  const moveBackward = () => {
    setDirection(() => -1);

    if (page === "shelf") {
      setPage(() => "unit");
    }

    if (page === "unit") {
      setPage(() => "row");
    }
  };

  const variants = {
    initial: (direction) => ({ x: `${10 * direction}%`, opacity: 0 }),
    animate: () => ({ x: "0%", opacity: 1 }),
    exit: () => ({ opacity: 0 }),
  };

  const handleItemClick = (id, name) => {
    // reset if a complete location is already selected
    if (
      selectedLocation.row.name &&
      selectedLocation.unit.name &&
      selectedLocation.shelf.name
    ) {
      !cartEditModalData &&
      setSelectedLocation((current) => ({
        ...current,
        row: { id: "", name: "" },
        unit: { id: "", name: "" },
        shelf: { id: "", name: "" },
      }));
    }

    setSelectedLocation((current) => ({
      ...current,
      [page]: {
        id,
        name,
      },
    }));

    moveForward();
  };

  const [ref] = useMeasure();

  const [query, setQuery] = useState("");

  const handleSearch = (e) => {
    setQuery(() => e.target.value);
  };

  const clearSearchQuery = () => {
    setQuery(() => "");
  };

  useEffect(() => {
    clearSearchQuery();
  }, [page]);

  return (
    <MotionConfig transition={transition}>
      <AnimatePresence>
        {visible ? (
          <>
            <motion.div
              ref={ref}
              className="location-picker-wrapper"
              initial={{ opacity: 0, scale: 0.975 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.975 }}
              transition={{ duration: 0.2, type: "spring", bounce: 0 }}
            >
              <div className="location-picker-header">
                {page !== "row" ? (
                  <motion.button
                    className="location-back-btn"
                    onClick={moveBackward}
                    type="button"
                  >
                    {page !== "row" ? (
                      <MaterialIcon icon="arrow_back" size="20" />
                    ) : null}
                    {page === "unit" ? `${selectedLocation.row.name}` : null}
                    {page === "shelf"
                      ? `${selectedLocation.row.name}-${selectedLocation.unit.name}`
                      : null}
                  </motion.button>
                ) : null}

                <div className="location-picker-header-title">
                  <AnimatePresence initial={false} mode="popLayout">
                    <motion.p className="selection-title">
                      Select {page}
                    </motion.p>
                  </AnimatePresence>

                  <div className="location-picker-search-wrapper">
                    <MaterialIcon
                      icon="search"
                      classes="location-picker-search-icon"
                      size="20"
                    />
                    <input
                      type="text"
                      className="location-picker-search"
                      placeholder="Search"
                      value={query}
                      onChange={handleSearch}
                    />
                    {query.length > 0 ? (
                      <IconButton
                        icon="close"
                        classes="location-search-clear-btn"
                        onClick={clearSearchQuery}
                        size="32"
                        iconSize="20"
                        secondary={true}
                      />
                    ) : null}
                  </div>
                </div>
              </div>

              <ResizablePanel>
                <AnimatePresence initial={false} mode="popLayout">
                  <motion.div
                    className="location-picker-list"
                    variants={variants}
                    key={page}
                    custom={direction}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    {page === "row" ? (
                      <RowsList
                        onItemClick={handleItemClick}
                        selectedLocation={selectedLocation}
                        query={query}
                        rows={rowsData}
                        error={rowsError}
                        isLoading={rowsLoading}
                        isNonSaleableHide={isNonSaleableHide}
                      />
                    ) : null}

                    {page === "unit" ? (
                      <UnitsList
                        onItemClick={handleItemClick}
                        selectedLocation={selectedLocation}
                        query={query}
                        getLocationProductWise={getLocationProductWise}
                        units={unitsData}
                        error={unitsError}
                        isLoading={unitsLoading}
                      />
                    ) : null}

                    {page === "shelf" ? (
                      <ShelvesList
                        toLocationShelfDetails={toLocationShelfDetails}
                        onItemClick={handleItemClick}
                        selectedLocation={selectedLocation}
                        query={query}
                        getLocationProductWise={getLocationProductWise}
                        shelves={shelvesData}
                        error={shelvesError}
                        isLoading={shelvesLoading}
                      />
                    ) : null}
                  </motion.div>
                </AnimatePresence>
              </ResizablePanel>
            </motion.div>
            <OverlaySkrim onClick={closePicker} />
          </>
        ) : null}
      </AnimatePresence>
    </MotionConfig>
  );
};

export default LocationPicker;