import { Divider } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import {
    PosButton,
    PosInput,
    PosPageHeading,
} from "apps/pos/Common/uiCore";
import PosGrid from "../Common/uiCore/posDrawer/posGrid/grid";
import PosMainGrid from "../Common/uiCore/posMainGrid/mainGrid";

import { title, ZERO_NUMBER } from "constants/constant";
import { CheckIcon, ClearIcon } from "constants/icons";
import { PosFlowDataProp } from "constants/interfaces";
import { RootState } from "models";
import { keys } from "utils/helper";
import { blockInvalidChar, roundToTwoDecimalPlaces } from "utils/utils";

import "./drawers.scss";

const BalanceDrawer = ({ finalTotal, skipForNowBalance, taxRate, shippingAmount, taxAmount, shipTaxAmount, creditAmtApplied, changeCreditAmt, includePending }) => {

    const { orderSubtotal, shippingAndHandling, tax, creditFromPastOrders, totalPaymentDue, skipForNow, includeCredit } = title
    const { posFlowData, isTaxExempted } = useSelector<RootState, PosFlowDataProp>(
        (state) => state.persistReducer
    );

    const {
        companyName,
        storeCreditAmt
    } = posFlowData?.customer || {};

    const orderTotal = useMemo(() => {
        return (Number(finalTotal) + Number(Number(shippingAmount)?.toFixed(2)) + (isTaxExempted ? 0 : Number((Number(taxAmount)))));
    }, [finalTotal, shippingAmount, taxAmount, shipTaxAmount, isTaxExempted]);

    useEffect(() => {
        if (orderTotal && storeCreditAmt && finalTotal) {
            const minValue = Math.min(orderTotal, creditAmtApplied)
            changeCreditAmt(minValue.toString())
        }
    }, [orderTotal])

    const totalPayment = useMemo(() => {
        return (Number(finalTotal) + Number(Number(shippingAmount)?.toFixed(2)) + (isTaxExempted ? 0 : Number((Number(taxAmount)).toFixed(2))) - Number(creditAmtApplied ?? 0));
    }, [finalTotal, shippingAmount, taxAmount, shipTaxAmount, creditAmtApplied, isTaxExempted]);


    const isGreaterCredit = useMemo(() => {
        return ((Number(creditAmtApplied) > Number(storeCreditAmt)) || (Number(creditAmtApplied) > Number(roundToTwoDecimalPlaces(orderTotal))));
    }, [storeCreditAmt, orderTotal, creditAmtApplied]);

    const formatNumber = (num) => {
        const formatted = num.toFixed(2);
        return Math.abs(formatted) <= 0.00 ? "0.00" : formatted;
    }

    const handleStoreCreditKeyDown = (e) => {
        blockInvalidChar(e)
        const isInvalidCreditAmount = (isGreaterCredit || (Number(formatNumber(totalPayment)) < ZERO_NUMBER))
        const { keyCode } = e;
        const { enter } = keys;
        if (keyCode === enter && !isInvalidCreditAmount) {
            e.preventDefault()
            includePending(formatNumber(totalPayment));
        }
    }

    return (
        <div>
            <div className="pricing-modal-header mb-4 px-16">
                <h1 className="pricing-schedule-heading">
                    {
                        storeCreditAmt ?
                            <PosPageHeading heading={`${companyName} has store credit. Use it?`} blackHeading /> :
                            null
                    }
                </h1>
            </div>

            <>
                <form className="mt-4 pt-2 px-16" id="addContactInfoForm">
                    <div>
                        <PosMainGrid>
                            <PosGrid xs={12} sm={6} md={8} className="mb-2 balance-left-info">
                                <div>
                                    <span className="int-20-reg fw-500">
                                        {orderSubtotal}
                                    </span>
                                </div>
                            </PosGrid>
                            <PosGrid xs={12} sm={6} md={4} className="mb-2 text-end">
                                <span className="int-20-reg fw-500">
                                    ${finalTotal}
                                </span>
                            </PosGrid>
                            <PosGrid xs={12} sm={6} md={8} className="mb-2 balance-left-info">
                                <div className="balance-left-add-info">
                                    <span className="int-20-reg fw-500">
                                        {shippingAndHandling}
                                    </span>
                                </div>
                            </PosGrid>
                            <PosGrid xs={12} sm={6} md={4} className="mb-2 text-end">
                                <span className="int-20-reg fw-500">
                                    ${Number(shippingAmount)?.toFixed(2)}
                                </span>
                            </PosGrid>
                            <PosGrid xs={12} sm={6} md={8} className="mb-2 balance-left-info">
                                <div className="balance-left-add-info">
                                    <span className="int-20-reg fw-500 ">
                                        {tax} ({isTaxExempted ? 0 : taxRate}%)
                                    </span>
                                </div>
                            </PosGrid>
                            <PosGrid xs={12} sm={6} md={4} className="mb-2 text-end">
                                <span className="int-20-reg fw-500">
                                    ${isTaxExempted ? (0).toFixed(2) : Number(taxAmount)}
                                </span>
                            </PosGrid>
                        </PosMainGrid>
                        <Divider className="pos-divider-black" />
                        {storeCreditAmt ? <>
                            <PosMainGrid>
                                <PosGrid xs={12} sm={6} md={8} className="mb-2 balance-left-info">
                                    <div className="balance-left-remove-info text-warning-balance">
                                        <span className="int-20-reg fw-500">
                                            {`${creditFromPastOrders} ($ ${storeCreditAmt})`}
                                        </span>
                                    </div>
                                </PosGrid>
                                <PosGrid xs={12} sm={6} md={4} className="mb-2">
                                    <PosInput
                                        className={`unpaid-balance ${isGreaterCredit ? "error-invalid" : "search-input-focus"}`}
                                        variant="standard"
                                        id="standard-basic"
                                        InputProps={{ disableUnderline: true }}
                                        inputProps={{ maxLength: 8, pattern: "\d{8}" }}
                                        value={creditAmtApplied}
                                        type="number"
                                        autoFocus
                                        onKeyDown={(e) => handleStoreCreditKeyDown(e)}
                                        onWheel={(e) => (e?.target as HTMLElement)?.blur()}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                        onChange={changeCreditAmt}
                                        placeholder={storeCreditAmt?.toString()}
                                    />
                                </PosGrid>
                            </PosMainGrid>
                        </> : null}
                    </div>
                    <Divider className="pos-divider-balance" />
                    <PosMainGrid>
                        <PosGrid xs={12} sm={6} md={8} className="mb-2 balance-left-info">
                            <span className="int-20-reg fw-500">
                                {totalPaymentDue}
                            </span>
                        </PosGrid>
                        <PosGrid xs={12} sm={6} md={4} className="mb-2 text-end">
                            <span className="int-20-reg fw-500">
                                ${formatNumber(totalPayment)}
                            </span>
                        </PosGrid>
                    </PosMainGrid>
                    <div className="shipping-btns-no mt-5 odd-even-btn">
                        <PosButton
                            variant="contained"
                            className="modal-select-default-btn ship-btn justify-content-start small-btn skip-for-now-btn btn"
                            startIcon={<ClearIcon />}
                            onClick={skipForNowBalance}
                        >
                            {skipForNow}
                        </PosButton>
                        <PosButton
                            fontWeight
                            startIcon={<CheckIcon />}
                            variant="contained"
                            color="primary"
                            className="modal-select-default-btn ship-btn-dark justify-content-start large-btn balance-credit-btn btn"
                            onClick={() => includePending(formatNumber(totalPayment))}
                            disabled={(isGreaterCredit || (Number(formatNumber(totalPayment)) < 0))}
                        >
                            {
                                storeCreditAmt ?
                                    includeCredit : null
                            }

                        </PosButton>
                    </div>
                </form>
            </>
        </div>
    );
};

export default BalanceDrawer;
