import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import "@fontsource/inter";

import {
  PosHelmet,
  PosIconButton,
  PosImage,
  PosPageHeading,
  Paper,
  Container,
} from "apps/pos/Common/uiCore";
import { EnhancedTableHead } from "./SalesHistoryComponent/EnhancedTableHead";
import { EnhancedTableBody } from "./SalesHistoryComponent/EnhancedTableBody";
import { TablePaginationActions } from "./SalesHistoryComponent/TablePaginationActions";

import { useStyles } from "./SalesHistoryComponent/data";

import { ArrowBackRoundedIcon } from "constants/icons";
import images from "constants/images";
import { PosFlowDataProp } from "constants/interfaces";

import { Paths } from "enums";
import { keys } from "utils/helper";
import { RootState } from "models";
import {
  addToCartDrawer,
  getSalesHistoryRequest,
  stockByWarehouseStart,
} from "sagas/pos/actions";

import "apps/pos/SalesHistory/SalesHistory.scss";

const SalesHistory = () => {
  const { posFlowData, loadCartDetails } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { salesHistory, salesHistoryTotal, isLoading, productsData } = useSelector<
    RootState,
    any
  >((state) => state.pos);
  const classes = useStyles();
  const { customer } = posFlowData || {};
  const { personId, customerId,posCustCode, companyName } = customer || {};
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productIdInfo = useParams();


  const { state , pathname } = location;
  const productInfo = state?.detail;
  const productName = productInfo?.productName;

  const isLeapFrongReport = (pathname === `${Paths.product}/${productIdInfo.id}/${Paths.reports}/${Paths.salesHistory}`)
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('productName');

  useEffect(() => {
    const data = {
      customerId :  isLeapFrongReport ? 0 : personId || customerId,
      productId: productIdInfo.id,
      page: page + 1,
      size: rowsPerPage,
    };
    dispatch(getSalesHistoryRequest(data));
  }, [page, rowsPerPage]);

  // start
  function stableSort(salesHistoryInfo, comparator) {
    if (salesHistory) {
      const stabilizedThis =
        salesHistoryInfo && salesHistoryInfo?.map((el, index) => [el, index]);
      stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis && stabilizedThis?.map((el) => el[0]);
    }
  }

  // end
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // TODO:need this code
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const { defaultIcon } = images;

  const backToCartPage = () => {
    const { cartId } = loadCartDetails
    const { productId } = productsData
    dispatch(stockByWarehouseStart([Number(productIdInfo.id)]));
    if(productId) {
      dispatch(addToCartDrawer(true))
    }
    navigate(`${Paths.posCart}/${cartId}`);
  }

  const handleKeyPressDown = (e) => {
    const { keyCode } = e;
    const { esc } = keys;
    const { cartId } = loadCartDetails
      if (keyCode === esc && cartId) {
        backToCartPage();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPressDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPressDown);
    };
  }, [loadCartDetails]);

  const shortProductName = (name) => {
    return name?.length > 25 ? name.slice(0,25)+"..." :name
  }
  return (
    <div className="history-main-section">
      <PosHelmet title={!isLeapFrongReport ? "POS" : `Product Detail - ${isLeapFrongReport ? paramValue : productName} - ${productIdInfo.id}` } metaName="Admin page" content="Admin page" />
        <section className="item-history-section">
          <div className={`item-history-container ${isLeapFrongReport ? "ml-90" : ""}`}>
            {!isLeapFrongReport ?  <PosIconButton
                  className="back-button pos-icon-back-btn"
                  onClick={async () => {
                    backToCartPage();
                  }}
                  tabIndex={-1}
                >
                  <ArrowBackRoundedIcon className="back-icon" />
                </PosIconButton> : null}
              <div className="item-history-title-container">
                <div className="title">
                <PosPageHeading heading="Sales History" blackHeading />
                  <PosPageHeading heading="Who Bought What" grayHeading />
                </div>
                <div className="item-history-products-container">
                  <div className="product-ledger-image">
                    <PosImage
                      src={defaultIcon}
                      alt="product-icon"
                    />
                    <div className="product-name">
                      <div className="name">{isLeapFrongReport ? shortProductName(paramValue) : shortProductName(productName)}</div>
                      <div className="key">{productIdInfo.id}</div>
                    </div>
                    <div className="customer-name">
                      <div className="name">{companyName}</div>
                      <div className="key">{posCustCode}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
                {/*  may need this later
                <div className="download-csv-icon"></div>
                <CsvDownloader
                  filename="myfile"
                  separator=";"
                  columns={salesHistoryColumns}
                  datas={datas}
                >
                  <PosButton
                    startIcon={<GetAppIcon />}
                    variant="contained"
                    className="download-csv-icon"
                  >
                    Download CSV
                  </PosButton>
                </CsvDownloader> */}
          <Container maxWidth={false} className="table-main-container">
            <Paper className="sales-history-table-section">
              <div
                className={`${classes.table} sales-history-table-data`}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead />
                {isLoading ? (
                  <></>
                ) : (
                  <EnhancedTableBody
                    salesHistory={salesHistory}
                    stableSort={stableSort}
                  />
                )}
              </div>
              <TablePagination
                component="div"
                count={salesHistoryTotal ? salesHistoryTotal : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                rowsPerPageOptions={[]}
              />
            </Paper>
        </Container>
    </div>
  );
};

export default SalesHistory;
