import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { revisedDueDateQuote } from '../request';

  export const useEditDueDate = () => {
    const { mutate, isLoading, data, error, isSuccess } = useMutation(
      async (payload) => {
        return revisedDueDateQuote(payload);
      },
      );
  
    const editDueDateFn = useCallback(
      (payload) => {
        mutate(payload);
      },
      [mutate]
    );
  
    return {
      editDueDateFn,
      isLoading,
      data,
      error,
      isSuccess,
    };
  };