import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Will remove 'any' once we get proper data from BE
export interface ProductAccessorieSliceI {
  isGetProductsharacteristics: boolean;
  getProductAccessorieGoesWithData: any;
  getProductAccessorieGoesWithError: any;
  getProductAccessorieReplacesData: any;
  getProductAccessorieReplacesError: any;
  getProductAccessorieGoesWithAllData : any;
  isGetProductsharacteristicsAll : boolean;
  getProductAccessorieGoesWithAllError : any;
  removeAllRelatedItemsByProductData: any;
  isRemoveAllRelatedItemsByProduct : boolean;
  removeAllRelatedItemsByProductDataError : any;
  removeParticularAccessoriesData : any;
  isRemoveParticularAccessories : boolean;
  removeParticularAccessoriesError : any;
  addProductAccessorieData : any;
  isAddProductsAccessories: boolean;
  addProductAccessorieError : any;
}

const initialState: ProductAccessorieSliceI = {
  isGetProductsharacteristics: false,
  getProductAccessorieGoesWithData: null,
  getProductAccessorieGoesWithError: null,
  getProductAccessorieReplacesData : null,
  getProductAccessorieReplacesError: null,
  getProductAccessorieGoesWithAllData : null,
  isGetProductsharacteristicsAll: false,
  getProductAccessorieGoesWithAllError : null,
  removeAllRelatedItemsByProductData: null,
  isRemoveAllRelatedItemsByProduct : false,
  removeAllRelatedItemsByProductDataError : null,
  removeParticularAccessoriesData : null,
  isRemoveParticularAccessories : false,
  removeParticularAccessoriesError : null,
  addProductAccessorieData:null,
  isAddProductsAccessories: false,
  addProductAccessorieError:null,
};

export const productsAccessorieSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    getProductsAccessorieGoesWithStart: (state) => {
      state.getProductAccessorieGoesWithData = null;
      state.isGetProductsharacteristics = true;
    },
    getProductsAccessorieGoesWithSuccess: (state, action: PayloadAction<any>) => {
      state.getProductAccessorieGoesWithData = action.payload.data;
      state.isGetProductsharacteristics = false;
    },
    getProductsAccessorieGoesWithError: (state, action: PayloadAction<any>) => {
      state.getProductAccessorieGoesWithError = action.payload;
      state.getProductAccessorieGoesWithData = null;
      state.isGetProductsharacteristics = false;
    },
    getProductsAccessorieReplacesStart: (state) => {
      state.getProductAccessorieReplacesData = null;
      state.isGetProductsharacteristics = true;
    },
    getProductsAccessorieReplacesSuccess: (state, action: PayloadAction<any>) => {
      state.getProductAccessorieReplacesData = action.payload.data;
      state.isGetProductsharacteristics = false;
    },
    getProductsAccessorieReplacesError: (state, action: PayloadAction<any>) => {
      state.getProductAccessorieReplacesError = action.payload;
      state.getProductAccessorieReplacesData = null;
      state.isGetProductsharacteristics = false;
    },
    getProductsAccessorieGoesWithAllStart: (state) => {
      state.getProductAccessorieGoesWithAllData = null;
      state.isGetProductsharacteristicsAll = true;
    },
    getProductsAccessorieGoesWithAllSuccess: (state, action: PayloadAction<any>) => {
      state.getProductAccessorieGoesWithAllData = action.payload.data;
      state.isGetProductsharacteristicsAll = false;
    },
    getProductsAccessorieGoesWithAllError: (state, action: PayloadAction<any>) => {
      state.getProductAccessorieGoesWithAllError = action.payload;
      state.getProductAccessorieGoesWithAllData = null;
      state.isGetProductsharacteristicsAll = false;
    },
    removeAllRelatedItemsByProductStart: (state) => {
      state.removeAllRelatedItemsByProductData = null;
      state.isRemoveAllRelatedItemsByProduct = true;
    },
    removeAllRelatedItemsByProductSuccess: (state, action: PayloadAction<any>) => {
      state.removeAllRelatedItemsByProductData = action.payload.data;
      state.isRemoveAllRelatedItemsByProduct = false;
    },
    removeAllRelatedItemsByProductError: (state, action: PayloadAction<any>) => {
      state.removeAllRelatedItemsByProductDataError = action.payload;
      state.removeAllRelatedItemsByProductData = null;
      state.isRemoveAllRelatedItemsByProduct = false;
    },
    removeParticularAccessoriesStart: (state) => {
      state.removeParticularAccessoriesData = null;
      state.isRemoveParticularAccessories = true;
    },
    removeParticularAccessoriesSuccess: (state, action: PayloadAction<any>) => {
      state.removeParticularAccessoriesData = action.payload.data;
      state.isRemoveParticularAccessories = false;
    },
    removeParticularAccessoriesError: (state, action: PayloadAction<any>) => {
      state.removeParticularAccessoriesError = action.payload;
      state.removeParticularAccessoriesData = null;
      state.isRemoveParticularAccessories = false;
    },
    addProductsAccessorieStart: (state) => {
      state.addProductAccessorieData = null;
      state.isAddProductsAccessories = true;
    },
    addProductsAccessorieSuccess: (state, action: PayloadAction<any>) => {
      state.addProductAccessorieData = action.payload.data;
      state.isAddProductsAccessories = false;
    },
    addProductsAccessorieError: (state, action: PayloadAction<any>) => {
      state.addProductAccessorieError = action.payload;
      state.addProductAccessorieData = null;
      state.isAddProductsAccessories = false;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  getProductsAccessorieGoesWithStart,
  getProductsAccessorieGoesWithSuccess,
  getProductsAccessorieGoesWithError,
  getProductsAccessorieReplacesStart,
  getProductsAccessorieReplacesSuccess,
  getProductsAccessorieReplacesError,
  getProductsAccessorieGoesWithAllStart,
  getProductsAccessorieGoesWithAllSuccess,
  getProductsAccessorieGoesWithAllError,
  removeAllRelatedItemsByProductStart,
  removeAllRelatedItemsByProductSuccess,
  removeAllRelatedItemsByProductError,
  removeParticularAccessoriesStart,
  removeParticularAccessoriesSuccess,
  removeParticularAccessoriesError,
  addProductsAccessorieStart,
  addProductsAccessorieSuccess,
  addProductsAccessorieError
} = productsAccessorieSlice.actions;

export default productsAccessorieSlice.reducer;
