import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { GlobalNumbers } from "constants/enum";
import "./SplitButton.scss";

interface Options {
  id: any; //any needed here
  label: string;
  visible?: boolean;
}

interface SplitButtonProps {
  options: Options[];
  onClick: (event) => void;
}

const SplitButton = ({ options, onClick }: SplitButtonProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  return (
    <React.Fragment>
      {options && options.length === GlobalNumbers.ONE ? (
        <>
          <Button
              onClick={onClick}
              className="single-button"
              sx={{
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
              id={options[0]?.id}
            >
              {options ? options[0].label : ""}
            </Button>
        </>
      ) : (
        <>
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="Button group with a nested menu"
          >
            <Button
              onClick={onClick}
              className="selected-option"
              sx={{
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
              id={options[0]?.id}
            >
              {options ? options[0].label : ""}
            </Button>
            <Button
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              className="dropdown-options"
              sx={{
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{ zIndex: 1 }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="top-start"
            className="dropdown-popup-modal"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper className="dropdown-popup-modal-paper">
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.slice(1).map((item) => {
                        return (
                          <MenuItem
                            key={item.id}
                            onClick={onClick}
                            id={item.id}
                            className="dropdown-popup-modal-paper-list space-wrap"
                          >
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </React.Fragment>
  );
};

export default SplitButton;
