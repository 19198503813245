import { createSelector } from "@reduxjs/toolkit";
import { quoteDetail, quoteList } from "models";
import { QuoteDetailsSliceValues } from "./quoteDetailsSlice";

export const quotesData = (state: { quoteDetail: quoteDetail }) =>
  state?.quoteDetail;

export const quoteDetailsValues = (state: {
  quoteDetailsSlice: QuoteDetailsSliceValues;
}) => state?.quoteDetailsSlice;

export const quotesListSelector = (state: { quoteList: quoteList }) =>
  state?.quoteList;

export const getOpenMiniNoteValue = createSelector(
  quoteDetailsValues,
  (data) => data?.openMiniNote
);

export const getQuoteCounts = createSelector(
  quotesData,
  (metric) => metric.quoteCounts
);

export const customerQuoteData = createSelector(
  quotesData,
  (metric) => metric.packageInfoData
);

export const quoteIdDetail = createSelector(
  quotesData,
  (metric) => metric.quoteDetail
);

export const getParentQuote = createSelector(
  quotesData,
  (metric) => metric.parentQuote
);

export const getQuoteJobHighlights = createSelector(
  quotesData,
  (metric) => metric.quoteJobHighlights
);

export const getIsJobHighlightsLoading = createSelector(
  quotesData,
  (metric) => metric.isJobHighlightsLoading
);

export const getSalesPersonData = createSelector(
  quotesData,
  (metric) => metric.salesPersonData
)

export const getLikelyHoodCommentSelector = createSelector(
  quotesData,
  (metric) => metric.likelyHoodComment
)

export const getQuoteMajorReps = createSelector(
  quotesData,
  (metric) => metric.quoteMajorReps
)

export const getVersionHistory = createSelector(
  quotesData,
  (metric) => metric.versionHistory
)

export const getOrderDetails = createSelector(
  quotesData,
  (metric) => metric.orderDetails
)

export const getOrderDetailsLoading = createSelector(
  quotesData,
  (metric) => metric.orderDetailsLoading
)

export const getQuoteReps = createSelector(
  quotesData,
  (metric) => metric.quoteReps
)

export const getQuoteItems = createSelector(
  quotesData,
  (metric) => metric.quoteItems
)

export const getQuoteMessages = createSelector(
  quotesData,
  (metric) => metric.quoteMessages
)

export const getAlertShow= createSelector(
  quotesData,
  (metric) => metric.alertShow
)

export const getAlertMessage = createSelector(
  quotesData,
  (metric) => metric.alertMessage
)

export const getIsErrorAlert = createSelector(
  quotesData,
  (metric) => metric.isErrorAlert
)

export const getQuoteMessagesLoading= createSelector(
  quotesData,
  (metric) => metric.quoteMessagesLoading
)

export const getQuoteEndRecords = createSelector(
  quotesData,
  (metric) => metric.quoteEndRecords
)

export const getNotesSelector = createSelector(
  quotesData,
  (metric) => metric.getNotes
);

export const getQuoteItemActionNotes = createSelector(
  quotesData,
  (metric) => metric.quoteItemActionNotes
);

export const getQuoteEstimatorNotes = createSelector(
  quotesData,
  (metric) => metric.quoteEstimatorNotes
);

export const getQuoteFollowUpNotes = createSelector(
  quotesData,
  (metric) => metric.quoteFollowUpNotes
);

export const getIsEstimatorLoading = createSelector(
  quotesData,
  (metric) => metric.isEstimatorLoading
);

export const getIsInitialLoading = createSelector(
  quotesData,
  (metric) => metric.isInitialLoading
);

export const getIsItemActionLoading = createSelector(
  quotesData,
  (metric) => metric.isItemActionLoading
);

export const getQuoteDocs = createSelector(
  quotesData,
  (metric) => metric.quoteDocs
);

export const getQuoteRepsLoading = createSelector(
  quotesData,
  (metric) => metric.quoteRepsLoading
);

export const getQuoteRfi = createSelector(
  quotesData,
  (metric) => metric.quoteRfi
);

export const getQuoteRfiLoading = createSelector(
  quotesData,
  (metric) => metric.quoteRfiLoading
);

export const getIsMajorRepsLoading = createSelector(
  quotesData,
  (metric) => metric.isMajorRepsLoading
);

export const getQuoteListItem = createSelector(
  quotesListSelector,
  (metric) => metric.quoteList
)

export const getTotalQuotes = createSelector(
  quotesListSelector,
  (metric) => metric.totalQuotes
)
