import { PosButton, PosModel } from "../Common/uiCore";
import { Button } from "components";

import { ClearIcon } from "constants/icons";
import { backOrderConfirmationModalConstants } from "constants/constant";

import "./backOrderConfirmationModal.scss";

const BackOrderConfirmationModal = ({totalItems, open, onCloseModal, continueToPayment}) => {
  const {item, items, backorder, backorders, Backorder, continueWith, messageBodyMiddleText, backorderForIt, backordersForThem, has, have } = backOrderConfirmationModalConstants
    return (
        <>
            <PosModel
                className={`set-customer-code-modal`}
                dialogClassName={`set-customer-code-modal-container set-storage-modal-container put-storage-modal-container`}
                open={open}
                onClose={onCloseModal}
                dialogHeader={
                    <div className="modal-header align-items-center">
                      <span className="modal-title">
                        {`${Backorder} ${totalItems} ${totalItems > 1 ? items : item}?`}
                      </span>
                    <Button className="bg-transparent border-0 p-0 button-focus" onClick={onCloseModal}>
                      <ClearIcon
                        className="clear-icon"
                      />
                    </Button>
                    </div>
                  }
                dialogFooter={
                    <>
                      <div
                      className={`d-flex flex-wrap jcfe w-100 flex-row-reverse justify-end`}
                      >
                      <div className="d-flex flex-wrap">
                        <PosButton
                          variant="contained"
                          color="secondary"
                          className={`btn-font-600 add-to-return-cart-button mt-2 back-order-confirmation-modal-btn btn`}
                          fontWeight
                          onClick={() => {
                            continueToPayment()
                            onCloseModal()
                          }}
                        >
                          {`${continueWith} ${totalItems > 1 ? backorders : backorder}`}
                        </PosButton>
                      </div>
                        <div>
                          <PosButton
                            type="button"
                            variant="contained"
                            className={`add-to-return-cart-button mr-2 mt-2 btn-font-600 set-storage-back-btn btn`}
                            fontWeight
                            onClick={onCloseModal}
                          >
                            Back
                          </PosButton>
                        </div>                   
                      </div>
                    </>
                  }
            >
                <div className="message-body">
                    {`${totalItems} ${totalItems > 1 ? `${items} ${have}` : `${item} ${has}`} ${messageBodyMiddleText} ${totalItems > 1 ? backordersForThem : backorderForIt}`}
                </div>
            </PosModel>
        </>
    );
};

export default BackOrderConfirmationModal;
