import { Fragment } from "react";

import { PosModel } from "apps/pos/Common/uiCore";
import { IconButton } from "apps/springboard/Component/SBItems/elements";
import Button from "components/SPButton/Button";
import { ButtonContainerSBbuttons } from "components";

import { alertMessage, springBoardText, title } from "constants/constant";

export function ConfirmationModal(props) {
  const { open, handleCloseModel, newQuoteStatus, handleConfirmation, isConfirmLoading } = props
  const { confirmation } = title
  const { no } = springBoardText
  const { quoteStatusChangeConfirmation } = alertMessage
  return (
    <Fragment>
      <PosModel
        open={open}
        onClose={handleCloseModel}
        className="set-customer-code-modal"
        dialogClassName="set-customer-code-modal-container"
        dialogHeader={
          <div className="modal-header pl-4">
            <span className="modal-title">
              <div className="pt-2"><h3 className="f20-Inter-500-sab mb-0 mb-0">{confirmation}
              </h3></div>
            </span>
            <IconButton
              name='close'
              className="close-icon"
              type='tertiary'
              onClick={handleCloseModel}
            />
          </div>
        }>
        <div>
          <h6>{`${quoteStatusChangeConfirmation} ${newQuoteStatus}?`}</h6>
          <ButtonContainerSBbuttons className="justify-content-end mt-4 gap-2">
            <Button
              label={no}
              loading={isConfirmLoading}
              disabled={isConfirmLoading}
              onClick={handleCloseModel}
            />
            <Button
              label={`Set as ${newQuoteStatus}`}
              type='primary'
              loading={isConfirmLoading}
              disabled={isConfirmLoading}
              onClick={() => handleConfirmation(newQuoteStatus)}
            />
          </ButtonContainerSBbuttons>
        </div>

      </PosModel>
    </Fragment>
  )
}