import { ProductImage } from "../../elements";
import InlineNote from "../InlineNote";
import "./ItemLine.scss";

const ItemLine = ({ item }) => {
  const {
    itemCode = "",
    manufacturerName = "",
    note = "",
    unitQtyQuoted = 0,
    adjustedSalesPrice = 0,
    repMarginPercent = 0,
    itemType = "",
    controlType = "",
    leadTime = "",
    indexNumber,
    productID,
    lineOrGroupPrice,
    lineOrGroupCost,
    tenantPayPrice,
  } = item;
  return (
    <li className="item-line">
      <div className="item-line-product">
        <p>{indexNumber}</p>
        <div className="product-details">
          <ProductImage src={""} alt={productID} className="item-image" />
          <div className="item-details-group">
            <p className="item-name">{productID}</p>
            <p className="manufacturer-name">{manufacturerName}</p>
            <p className="item-code">{itemCode}</p>
            <InlineNote note={note} type="item" />
          </div>
        </div>
        <p>{itemType}</p>
        <p
          className={`item-qty ${unitQtyQuoted === 0 ? "item-qty-empty" : ""}`}
        >
          {unitQtyQuoted}
        </p>
      </div>

      <div className="item-line-prices">
        <p>
          <span
            aria-label={`Extended Price: $ ${lineOrGroupPrice}`}
            role="text"
          >
            {lineOrGroupPrice ? `$ ${lineOrGroupPrice}` : ""}
          </span>
          <span aria-label={`Unit Price: $ ${adjustedSalesPrice}`} role="text">
            {adjustedSalesPrice ? `$ ${adjustedSalesPrice}` : ""}
          </span>
        </p>
        <p>
          <span aria-label={`Extended Cost: $ ${lineOrGroupCost}`} role="text">
            {lineOrGroupCost ? `$ ${lineOrGroupCost}` : ""}
          </span>
          <span aria-label={`Unit Cost: $ ${tenantPayPrice}`} role="text">
            {tenantPayPrice ? `$ ${tenantPayPrice}` : ""}
          </span>
        </p>
        <p aria-label={`Margin: ${repMarginPercent}%`} role="text">
          {repMarginPercent ? `${repMarginPercent}%` : ""}
        </p>
      </div>

      <div className="item-line-metadata">
        <p>{controlType}</p>
        <p>{leadTime}</p>
      </div>
    </li>
  );
};

export default ItemLine;
