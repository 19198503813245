import { useState } from "react";
import { Divider, Grid, Box } from "@mui/material";

import { PosImage } from "apps/pos/Common/uiCore";

import images from "constants/images";
import DeliveryDetailsModals from "apps/pos/storage/DeliveryDetails";

import "apps/pos/storage/putInStorage.scss";
import "apps/pos/pages/customerInfo/Common/customerCommonStyling.scss";

const CustomerStoragePendingItems = ({data}) => {
  
  const { defaultIcon } = images;

  const [deliveryDetails, setDeliveryDetails] = useState(false);

  return (
    <div className="put-storage-container delivery-items-data">
      <div className="mt-3">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} className="table-header-styling">
            <Grid item xs={4} className="fs-10">
              <div className="storage-first-sec-title">
                ITEM
              </div>
            </Grid>
            <Grid item xs={1} className="fs-10">
              PRODUCT ID
            </Grid>
            <Grid item xs={2} className="fs-10">
              MANUFACTURER
            </Grid>
            <Grid item xs={1} className="fs-10 text-right">
              IN STORAGE
            </Grid>
            <Grid item xs={1} className="fs-10 text-right">
              DELIVERED
            </Grid>
            <Grid item xs={1} className="fs-10 text-right">
              AWAITING
            </Grid>
            <Grid item xs={2} className="fs-10">
              <div className="storage-qty-title">

              </div>
            </Grid>
          </Grid>
        </Box>
      </div>

    <div className="put-in-storage-table">
    {data?.map((item, index) => {
        const {productName, manufacturerCode, manufacturerName, productID, qtyInStorage, qtyDelivered} = item;
        return (
          <div key={`index${index}`}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} className="order-items">
                <Grid item xs={12} sm={8} md={6} lg={4} className="customer-first-info-col storage-data-info">
                  <PosImage src={defaultIcon} alt="filter" className='customer-storage-img' />
                  <div className="customer-name fs-16">
                    <span className="fw-medium">{productName}</span>
                    <span className="int-14-reg-gray">{manufacturerCode}</span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={1} className="fs-16 storage-data-info">
                  <div className="d-flex align-items-center">
                    <span className="fs-10 small-screen-title mr-2">PRODUCT ID: </span> {productID}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} className="fs-16 storage-data-info">
                  <div className="d-flex align-items-center">
                    <span className="fs-10 small-screen-title mr-2">MANUFACTURER: </span> {manufacturerName}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1} className="fs-16 storage-text-alignment storage-data-info">
                  <div className="d-flex align-items-center">
                    <span className="fs-10 small-screen-title mr-2">IN STORAGE: </span> {qtyInStorage}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1} className="fs-16 storage-text-alignment storage-data-info">
                  <div className="d-flex align-items-center">
                    <span className="fs-10 small-screen-title mr-2">DELIVERED: </span> {qtyDelivered}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={1} className="fs-16 storage-text-alignment storage-data-info">
                  <div className="d-flex align-items-center">
                    <span className="fs-10 small-screen-title mr-2">AWAITING: </span> {qtyInStorage}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} className="text-right">

                </Grid>
              </Grid>
              <Divider className="update-product-container-line" />
            </Box>
          </div>
        );
      })}
    </div>
     
      <DeliveryDetailsModals
        deliveryDetails={deliveryDetails}
        setDeliveryDetails={setDeliveryDetails}
      />
    </div>
  );
};

export default CustomerStoragePendingItems;
