import React from "react";

import images from "constants/images";

interface Props {
  src: string;
  title?: string;
  width?: number;
  height?: number;
  alt: string;
  className?: string;
  onClick?: (event: any) => void;
}
const PosImage = ({
  src,
  width,
  height,
  alt,
  className,
  title,
  onClick,
}: Props) => {
  const { defaultIcon } = images;
  return (
    <img
      src={src}
      title={title}
      alt={alt}
      width={width}
      height={height}
      className={className}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = defaultIcon;
      }}
      onClick={onClick}
      loading="lazy"
    />
  );
};
export default PosImage;
