interface LoadingProps {
  type?: string;
  value?: number;
  message?: string;
  className?:string
}

// Update to add more options
export function Loading({ className  }: LoadingProps) {
  return (
    <div className={`${className} spinner`}>
      
      <div className="lds-dual-ring m-5" role="progressbar">
      </div>
    </div>
  );
}
