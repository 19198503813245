import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import {
  Button,
  InputOptional,
  Select,
} from "../../../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";

import { Formik } from "formik";
import * as Yup from "yup";

import { phonesDetailsRequest } from "../../../sagas/setting/actions";
import { RootState } from "../../../models";
import { phonesDetails } from "../../../models/setting";
import MaskInput from "apps/pos/Common/maskInput/maskInput";
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const PhoneSchema = Yup.object().shape({
  phoneOne: Yup.string()
    .required("Phone number required"),
  phoneTwo: Yup.string()
    .required("Phone number required."),
});

export function Phones({distributorId}) {

  const [phonesDetailsData, setPhonesDetailsData] = useState<phonesDetails>();

  const { phonesDetails } = useSelector<RootState, any>((state) => state.setting);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(phonesDetailsRequest(distributorId));
  },[]);

    useEffect(() => {
      setPhonesDetailsData(phonesDetails);
    }, [phonesDetails]);

    
  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Phones</div>
        <div className="devider2px" />

        <Formik
          initialValues={{
            phoneOne: phonesDetailsData && phonesDetailsData[0]?.fullPhoneNum,
            phoneTwo: phonesDetailsData && phonesDetailsData[1]?.fullPhoneNum,
          }}
          enableReinitialize= {true}
          validationSchema={PhoneSchema}
          onSubmit={() => {
            // Required Later
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => {
            return (
              <form className="phone-container" id="addPhonesForm">
                <div className="container">
                  <div className="typeText mb-0">Direct 1</div>
                  <div className="d-flex">
                    <Select
                      id={"CompanyPhone"}
                      name={"CompanyPhone"}
                      label={"Company Phone"}
                      textInputStyle="phoneinput"
                      options={[
                        { label: "+1", value: "+1" },
                        { label: "+91", value: "+91" },
                      ]}
                    />

                    <div className="phone-number-container setting-phone-number-info">
                    <MaskInput                    
                      mask="(999) 999-9999"
                      isClassName="masked-phone-input"                     
                      id={"phone"}
                      name={"phoneOne"}
                        alwaysShowMask={true}
                        onChange={handleChange}
                        value={values.phoneOne}
                    />
                    </div>
                    <div className="ext-container">
                      <InputOptional
                        textInputStyle="extinput"
                        id={"addExt"}
                        name={"addExt"}
                        label={"Ext"}
                        type={"text"}
                        placeholder={""}
                      />
                    </div>
                  </div>
                  <div className="dimensions mt-2">
                    An Optional description for this email distro goes here in
                    this space like this
                  </div>
                  {errors.phoneOne && touched.phoneOne ? (
                    <p className="validation-error-text-sp">
                      <>{errors.phoneOne}</>
                    </p>
                  ) : null}
                </div>
                <div className="container">
                  <div className="typeText mb-0">Direct 2</div>
                  <div className="d-flex">
                    <Select
                      id={"CompanyPhone"}
                      name={"CompanyPhone"}
                      label={"Company Phone"}
                      textInputStyle="phoneinput"
                      options={[
                        { label: "+1", value: "+1" },
                        { label: "+91", value: "+91" },
                      ]}
                    />

                    <div className="phone-number-container setting-phone-number-info">
                    <MaskInput                    
                      mask="(999) 999-9999"
                      isClassName="masked-phone-input"
                      id="phone"
                        name={"companyPhone"}
                        alwaysShowMask={true}
                        onChange={handleChange}
                        value={values.phoneTwo}
                    />                      
                    </div>
                    <div className="ext-container">
                      <InputOptional
                        textInputStyle="extinput"
                        id={"addExt"}
                        name={"addExt"}
                        label={"Ext"}
                        type={"text"}
                        placeholder={""}
                      />
                    </div>
                  </div>
                  <div className="dimensions mt-2">
                    An Optional description for this email distro goes here in
                    this space like this
                  </div>
                  {errors.phoneTwo && touched.phoneTwo ? (
                    <p className="validation-error-text-sp">
                      <>{errors.phoneTwo}</>
                    </p>
                  ) : null}
                </div>
                <div className="devider1px" />

                <Button
                  type="submit"
                  onClick={handleSubmit}
                  className="manage-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#jumper_modal"
                >
                  <span className="manage-button-txt">save changes</span>
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </PerfectScrollbar>
  );
}
