import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IStorage {
  storageItemDetail: any;
}

const initialState = {
    storageItemDetail: {},
    deliveryDetails: {},
    deliverySuccessData: {},
    deliveredStorageItemDetail: {},
    handlerId: null,
    isPendingOrderDelivery: false,
    isLocationUpdated: false,
};

export const storageSlice = createSlice({
    name: "storageSlice",
    initialState,
    reducers: {
      
      setEditStorageDetailItem: (state, action: PayloadAction<any>) => {
        state.storageItemDetail = action.payload;
      },
      setDeliveredStorageItemDetail: (state, action: PayloadAction<any>) => {
        state.deliveredStorageItemDetail = action.payload;
      },
      setFinalDeliveryDetails: (state, action: PayloadAction<any>) => {
        state.deliveryDetails = action.payload;
      },
      setDeliverySuccessData: (state, action: PayloadAction<any>) => {
        state.deliverySuccessData = action.payload;
      },
      setHandlerId: (state, action: PayloadAction<any>) => {
        state.handlerId = action.payload;
      },
      setIsPendingOrderDelivery: (state, action: PayloadAction<boolean>) => {
        state.isPendingOrderDelivery = action.payload;
      },
      setUpdateLocationSuccess: (state, action: PayloadAction<boolean>) => {
        state.isLocationUpdated = action.payload;
      },
    }
  });
  
  // Action creators are generated for each case reducer function
  export const {
    setEditStorageDetailItem,
    setDeliveredStorageItemDetail,
    setFinalDeliveryDetails,
    setDeliverySuccessData,
    setHandlerId,
    setIsPendingOrderDelivery,
    setUpdateLocationSuccess,
  } = storageSlice.actions;
  
  export default storageSlice.reducer;