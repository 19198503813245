import {
  TENANT_FETCHING,
  TENANT_FETCH_ERROR,
  TENANT_FETCH_SUCCESS,
} from "./constants";

import { AnyAction } from "redux";

const initialState = {
  tenantDetails: {},
};
const reducer = function tenantReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case TENANT_FETCHING:
      return { ...state, isLoading: true };
    case TENANT_FETCH_SUCCESS:
      return { ...state, ...action.payload, isLoading: false };
    case TENANT_FETCH_ERROR:
      return { ...state, ...action.payload, isLoading: false };
    default:
      return state;
  }
};

export default reducer;
