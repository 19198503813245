import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "@fontsource/inter";
import { Paths } from "../../enums";

const PosRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (true) {
      navigate(Paths.posNewOrder);
    }
  }, []);
  return <div></div>;
};

export default PosRedirect;
