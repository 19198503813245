import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { allRowURL } from "./constantUrl";
import { getLocalStoraGeItem } from "utils/utils";

import { getAllRowsDataRequest, getAllRowsDataSuccess, getAllRowsDataFail } from "sagas/pos/actions";


export const useAllRowsData = () => {
  const dispatch = useDispatch();

  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {}; 

  const { refetch: allRowRefetch } = useQuery(
    ["all-rows"],
    async () => {
      dispatch(getAllRowsDataRequest());
      return allRowURL(stockWarehouseId).then((response) => {
        return response?.data;
      });
    },
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        dispatch(getAllRowsDataSuccess(data));
      },
      onError(err) {
        dispatch(getAllRowsDataFail(err));
      },
    }
  );

  // may need later
  const getAllRows = useCallback(() => {
    allRowRefetch()
    },[])

  return { getAllRows };
};