import ItemGroupHeader from "apps/springboard/Component/SBItems/Items/ItemGroupHeader";
import ItemLine from "apps/springboard/Component/SBItems/Items/ItemLine";
import "./ItemGroup.scss";
const ItemGroup = ({
  rep,
  quoteRepId,
  jobQuoteID,
  distributor,
  buyPriceReceivedFromRepFlag,
}) => {
  const { data } = rep;

  return (
    <div className="item-group">
      <ItemGroupHeader
        rep={rep}
        buyPriceReceivedFromRepFlag={buyPriceReceivedFromRepFlag}
        quoteRepId={quoteRepId}
        jobQuoteID={jobQuoteID}
        distributor={distributor}
      />
      <ul className="item-lines">
        {data.map((item, index) => (
          <ItemLine key={`${rep}-item-${index}`} item={item} />
        ))}
      </ul>
    </div>
  );
};

export default ItemGroup;
