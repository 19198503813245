import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
  PosButton,
  PosCheckbox,
  PosIconButton,
  PosImage,
} from "apps/pos/Common/uiCore";

import images from "constants/images";
import { renamePersonData } from "utils/helper";
import { RootState } from "models";
import { PosFlowDataProp } from "constants/interfaces";
import { getLocalStoraGeItem } from "utils/utils";

import { getPersonNotes } from "services/personNotes/personNotes.service";
import { loadCartRequest, setPosFlowData, shippingAddressSelectedData } from "sagas/persistReducer/actions";
import { getShippingFacilitiesClear } from "sagas/pos/actions";

import useStyles from "./CustomerNotesModalStyling";
import "./CustomerNotesModal.scss";

export interface changeTaxModelProp {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  handleNewOrder?: any;
  draftCartId?: string;
  setIsSnackbarVisibleResume?: any;
}

export default function CustomerNotesModal({
  open,
  onClose,
  handleNewOrder,
  draftCartId,
  setIsSnackbarVisibleResume

}: changeTaxModelProp) {
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>((state) => state.persistReducer);
  const [currentUserNote, setCurrentUserNote] = useState<any>();
  const [bannedSwitch, setBannedSwitch] = useState(true);

  const handleBannedSwitchCheck = () => {
    setBannedSwitch((previousValue) => !previousValue);
  };

  const classes = useStyles();

  const handleClose = () => {
    onClose(false);
  };
  const dispatch = useDispatch()
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const currentTakerInfoStorage = getLocalStoraGeItem("taker");

  const bySwitchCustomerSelectionFlow = (customerId: number,) => {
    dispatch(shippingAddressSelectedData(false));
    dispatch(getShippingFacilitiesClear());
    const data = {
      ...posFlowData,
      customer: {
        ...posFlowData?.customer,
        buyOnBanNote: banNoteCheck ? true : false
      }
    }
    dispatch(setPosFlowData(data));
    const preparedData = {
      stockWarehouseId: currentWorkspace?.stockWarehouseId,
      customerId,
      takerId: currentTakerInfoStorage?.personId,
      stockWarehouseName: currentWorkspace.warehouseName
        ? currentWorkspace.warehouseName
        : "",
      cartId: null,
    };
    dispatch(loadCartRequest(preparedData));
  };


  useEffect(() => {
    if (posFlowData?.customer?.hasInternalNotes === true) {
      getPersonNotes(posFlowData?.customer.customerId).then((res) => {
        setCurrentUserNote(res);
      });
    }
  }, [posFlowData?.customer]);

  const bannedCustomerNot =
    currentUserNote &&
    currentUserNote?.notes.some((note) => {
      return note?.isBanned === true;
    });
  const ackCustomerNot =
    currentUserNote &&
    currentUserNote?.notes.some((note) => {
      return note.isAckRequired === true;
    });

  const banNoteCheck = bannedCustomerNot || ackCustomerNot;

  const checkboxLabel = bannedCustomerNot
    ? "I understand the customer is banned and wish to proceed anyway"
    : ackCustomerNot && "I Acknowledge";

  const isBannedData =
    currentUserNote &&
    currentUserNote?.notes.filter((note) => {
      return note?.isBanned === true;
    });
  const isAckData =
    currentUserNote &&
    currentUserNote?.notes.filter((note) => {
      return note.isBanned === false && note.isAckRequired === true;
    });
  const isAllData =
    currentUserNote &&
    currentUserNote?.notes.filter((note) => {
      return note.isBanned === false && note.isAckRequired === false;
    });

  const { bannedNoteIcon, impNoteIcon } = images;
  const currentTaker = getLocalStoraGeItem("taker");

  const handleNotes = () => {
    if (posFlowData?.isCartAvailable) {
      if (
        !posFlowData.isLastCustomerGeneric ||
        !posFlowData.isLastCustomerGeneric ===
        posFlowData?.customer.isPosGeneric
      ) {
        bySwitchCustomerSelectionFlow(posFlowData?.customer.customerId);
      } else {
        // setIsSnackbarVisible(true);
        //TODO:need later
      }
    } else {
      handleClose()
      if (draftCartId) {
        setIsSnackbarVisibleResume(true)
        const { stockWarehouseId } = currentWorkspace || {};
        const { customer } = posFlowData || {};
        const { customerId } = customer || {};
        const { personId: takerId } = currentTaker
        const preparedData = {
          stockWarehouseId,
          customerId,
          takerId,
          cartId: draftCartId,
        };
        dispatch(loadCartRequest(preparedData));
      } else {
        handleNewOrder();
      }
    }
  }

  return (
    <>
      {(isBannedData?.length > 0 ||
        isAckData?.length > 0 ||
        isAllData?.length > 0) && (
          <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <div className="notes-model">
                    <div className="notes-model-header d-flex justify-content-between align-items-center">
                      <div>
                        <span>Notes for {renamePersonData(posFlowData?.customer?.posCustCode, posFlowData?.customer?.companyName)}</span>
                      </div>
                      <PosIconButton
                        onClick={handleClose}
                        className="notes-model-header-close-icon notes-modal-close-btn"
                        color="primary"
                      >
                        <CloseIcon />
                      </PosIconButton>
                    </div>
                    <div className="notes-model-content">
                      {isBannedData?.length > 0 && (
                        <div className="banned-sec-info">
                          <div className="banned-sec-info-title align-items-center">
                            <div className="banned-danger-icon">
                              <PosImage src={bannedNoteIcon} alt="banned_icon" />
                            </div>
                            <span>{renamePersonData(posFlowData?.customer?.posCustCode, posFlowData?.customer?.companyName)} is banned</span>
                          </div>

                          {isBannedData.map((note, index) => {
                            return (
                              <div key={index}>
                                <div className="banned-sec-info-data">
                                  <span>
                                    {note.isBanned === true ? note.note : ""}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {isAckData?.length > 0 && (
                        <div className="notes-sec-info pb-0">
                          <div className="notes-sec-border">
                            <div className="notes-sec-info-title d-flex align-items-center">
                              <span>IMPORTANT</span>
                              <div className="imp-notes-icon">
                                <PosImage src={impNoteIcon} alt="note_icon" />
                              </div>
                            </div>
                            {isAckData.map((note, index) => {
                              return (
                                <div key={index}>
                                  <div className="notes-sec-info-data">
                                    <span>{note.note}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {isAllData?.length > 0 && (
                        <div className="notes-sec-info">
                          <div className="notes-sec-info-title">
                            <span>OTHER NOTES</span>
                          </div>
                          {isAllData.map((note, index) => {
                            return (
                              <div key={index}>
                                <div className="notes-sec-info-data">
                                  <span>{note.note}</span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div className="notes-model-footer">
                      {checkboxLabel && (
                        <div className="hide-price-checkbox">
                          <PosCheckbox
                            label={checkboxLabel}
                            checked={!bannedSwitch}
                            onChange={handleBannedSwitchCheck}
                          />
                        </div>
                      )}
                      <div className="d-flex justify-content-between notes-btn-div">
                        <PosButton
                          variant="contained"
                          onClick={handleClose}
                          className="back-button customer-notes-back-btn"
                        >
                          Back
                        </PosButton>
                        <PosButton
                          fontWeight
                          className="btn customer-notes-continue-btn"
                          variant="contained"
                          color="primary"
                          disabled={checkboxLabel && bannedSwitch}
                          onClick={handleNotes}
                        >
                          Continue
                        </PosButton>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            </Modal>
          </div>
        )}
    </>
  );
}
