import { all, call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

import * as services from "../../services";
import * as actions from "./actions";
import * as constants from "./constants";

//Worker Saga

function* getCompanyDataAsync(action: AnyAction) {
  try {
    const companyData = yield call(services.getCompanyDetails, action.payload);
    yield put(actions.companyDetailsSuccess(companyData));
  } catch (errors) {
    yield put(actions.companyDetailsError("errors"));
  }
}

function* getContactInfoAsync(action: AnyAction) {
  try {
    const contactInfoData = yield call(services.getContactInfo, action.payload);
    yield put(actions.contactInfoSuccess(contactInfoData));
  } catch (errors) {
    yield put(actions.contactInfoError(errors));
  }
}

function* getDomainAndUrlAsync(action: AnyAction) {
  try {
    const domainUrlData = yield call(services.getDomainAndUrl, action.payload);
    yield put(actions.domainUrlSuccess(domainUrlData));
  } catch (errors) {
    yield put(actions.domainUrlError(errors));
  }
}

function* getLogoAsync(action: AnyAction) {
  try {
    const logoData = yield call(services.getLogos, action.payload);
    yield put(actions.logoSuccess(logoData));
  } catch (errors) {
    yield put(actions.logoError(errors));
  }
}

function* getEmailDitrosAsync(action: AnyAction) {
  try {
    const emailDistrosData = yield call(
      services.getEmailDistros,
      action.payload
    );
    yield put(actions.emailDistrosSuccess(emailDistrosData));
  } catch (errors) {
    yield put(actions.emailDistrosError("errors"));
  }
}

function* getCarriersAsync(action: AnyAction) {
  try {
    const carriersData = yield call(services.getCarriers, action.payload);
    yield put(actions.carriersSuccess(carriersData));
  } catch (errors) {
    yield put(actions.carriersError(errors));
  }
}

function* getSignaturesAsync(action: AnyAction) {
  try {
    const signaturesData = yield call(services.getSignature, action.payload);
    yield put(actions.signatureSuccess(signaturesData));
  } catch (errors) {
    yield put(actions.signatureError(errors));
  }
}

function* getPhonesAsync(action: AnyAction) {
  try {
    const phonesData = yield call(services.getPhones, action.payload);
    yield put(actions.phonesDetailsSuccess(phonesData));
  } catch (errors) {
    yield put(actions.phonesDetailsError(errors));
  }
}

function* getPaymentMethodsAsync(action: AnyAction) {
  try {
    const paymentMethodsData = yield call(
      services.getPaymentMethods,
      action.payload
    );
    yield put(actions.paymentMethodsSuccess(paymentMethodsData));
  } catch (errors) {
    yield put(actions.paymentMethodsError("errors"));
  }
}

function* getSubscriptionAsync(action: AnyAction) {
  try {
    const subscriptionData = yield call(
      services.getSubscription,
      action.payload
    );
    yield put(actions.subscriptionSuccess(subscriptionData));
  } catch (errors) {
    yield put(actions.subscriptionError(errors));
  }
}

function* getSocialLinksAsync(action: AnyAction) {
  try {
    const socialLinksData = yield call(services.getSocialLinks, action.payload);
    yield put(actions.socialLinksSuccess(socialLinksData));
  } catch (errors) {
    yield put(actions.socialLinksError(errors));
  }
}

function* getTrampolineSettingsAsync(action: AnyAction) {
  try {
    const trampolineData = yield call(services.getTrampoline, action.payload);
    yield put(actions.trampolineSettingsSuccess(trampolineData));
  } catch (errors) {
    yield put(actions.trampolineSettingsError(errors));
  }
}

function* getCannedResponsesAsync(action: AnyAction) {
  try {
    const cannedResponsesData = yield call(
      services.getCannedResponses,
      action.payload
    );
    yield put(actions.cannedResponsesSuccess(cannedResponsesData));
  } catch (errors) {
    yield put(actions.cannedResponsesError(errors));
  }
}

function* getApperancesAsync(action: AnyAction) {
  try {
    const appearanceData = yield call(services.getAppearance, action.payload);
    yield put(actions.appearanceSuccess(appearanceData.data));
  } catch (errors) {
    yield put(actions.appearanceError(errors));
  }
}

function* getProcurementPartnersAsync(action: AnyAction) {
  try {
    const procurementpartnersData = yield call(
      services.getProcurementPartners,
      action.payload
    );
    yield put(actions.procurementPartnersSuccess(procurementpartnersData.data));
  } catch (errors) {
    yield put(actions.procurementPartnersError("errors"));
  }
}

function* getActorsAsync(action: AnyAction) {
  try {
    const actorsData = yield call(services.getActors, action.payload);
    yield put(actions.actorsSuccess(actorsData));
  } catch (errors) {
    yield put(actions.actorsError(errors));
  }
}

function* getWarehousesAsync(action: AnyAction) {
  try {
    const warehousesData = yield call(services.getWarehouses, action.payload);
    yield put(actions.warehousesSuccess(warehousesData));
  } catch (errors) {
    yield put(actions.warehousesError(errors));
  }
}

function* getHrRepresentativeAsync(action: AnyAction) {
  try {
    const hrRepresentativeData = yield call(services.getHrRep, action.payload);
    yield put(actions.hrRepresentativeSuccess(hrRepresentativeData));
  } catch (errors) {
    yield put(actions.hrRepresentativeError(errors));
  }
}

//Watcher Saga

function* getCompanyDataWatcher() {
  yield takeLatest(constants.GET_COMPANY_DETAILS_START, getCompanyDataAsync);
}

function* getContactInfoWatcher() {
  yield takeLatest(constants.GET_CONTACT_INFO_START, getContactInfoAsync);
}

function* getDomainAndUrlWatcher() {
  yield takeLatest(constants.GET_DOMAIN_URL_START, getDomainAndUrlAsync);
}

function* getLogoWatcher() {
  yield takeLatest(constants.GET_LOGO_START, getLogoAsync);
}

function* getEmailDistrosWatcher() {
  yield takeLatest(constants.GET_EMAIL_DISTROS_START, getEmailDitrosAsync);
}

function* getCarriersWatcher() {
  yield takeLatest(constants.GET_CARRIERS_DETAILS_START, getCarriersAsync);
}

function* getSignaturesWatcher() {
  yield takeLatest(constants.GET_SIGNATURES_DETAILS_START, getSignaturesAsync);
}

function* getPhonesWatcher() {
  yield takeLatest(constants.GET_LOGO_START, getPhonesAsync);
}

function* getPaymentMethodsWatcher() {
  yield takeLatest(
    constants.GET_PAYMENT_METHODS_DETAILS_START,
    getPaymentMethodsAsync
  );
}

function* getSubscriptionWatcher() {
  yield takeLatest(
    constants.GET_SUBSCRIPTION_DETAILS_START,
    getSubscriptionAsync
  );
}

function* getSocialLinksWatcher() {
  yield takeLatest(constants.GET_SOCIAL_LINKS_START, getSocialLinksAsync);
}

function* getTrampolineSettingsWatcher() {
  yield takeLatest(
    constants.GET_TRAMPOLINE_SETTINGS_START,
    getTrampolineSettingsAsync
  );
}

function* getCannedResponsesWatcher() {
  yield takeLatest(
    constants.GET_CANNED_RESPONSES_START,
    getCannedResponsesAsync
  );
}

function* getApperancesWatcher() {
  yield takeLatest(constants.GET_APPEARANCE_DETAILS_START, getApperancesAsync);
}

function* getProcurementPartnersWatcher() {
  yield takeLatest(
    constants.GET_PROCUREMENT_PARTNERS_DETAILS_START,
    getProcurementPartnersAsync
  );
}

function* getActorsWatcher() {
  yield takeLatest(constants.GET_ACTORS_DETAILS_START, getActorsAsync);
}

function* getWarehousesWatcher() {
  yield takeLatest(constants.GET_WAREHOUSES_DETAILS_START, getWarehousesAsync);
}

function* getHrRepresentativeWatcher() {
  yield takeLatest(
    constants.GET_TRAMPOLINE_SETTINGS_START,
    getHrRepresentativeAsync
  );
}

export default function* quoteDetailSaga() {
  yield all([
    getCompanyDataWatcher(),
    getContactInfoWatcher(),
    getDomainAndUrlWatcher(),
    getLogoWatcher(),
    getEmailDistrosWatcher(),
    getCarriersWatcher(),
    getSignaturesWatcher(),
    getPhonesWatcher(),
    getPaymentMethodsWatcher(),
    getSubscriptionWatcher(),
    getSocialLinksWatcher(),
    getTrampolineSettingsWatcher(),
    getCannedResponsesWatcher(),
    getApperancesWatcher(),
    getProcurementPartnersWatcher(),
    getActorsWatcher(),
    getWarehousesWatcher(),
    getHrRepresentativeWatcher(),
  ]);
}
