import authenticatedUser from "./authenticatedUser/reducer";
import { combineReducers } from "redux";
import distributor from "./distributor/reducer";
import modal from "./modal/reducer";
import tenant from "./tenant/reducer";
import quoteDetail from "./quoteDetail/reducer";
import quoteList from "./quoteList/reducer";
import setting from "./setting/reducer";
import user from "./user/reducer";
import pos from "./pos/reducer";
import posImportQuote from "./pos/importQuotes/reducer";
import priceScheduleReducer from "./priceSchedules/reducer";
import persistReducer from './persistReducer/reducer'
import returnReducer from './returns/reducer';
import documentReducer from './pos/documentUpload/reducer'
import genericCustomersReducer from './../sagas/genericCustomers/genericCustomers.slice'
import snakbarReducer from './../sagas/pos/snakbar/snakbar.slice'
import productSliceReducer from '../apps/product/modules/productListingContainer/redux/product.slice'
import productVendorsSlice from '../apps/product/modules/productListingContainer/redux/productVendors.slice';
import productManufacturersSlice from '../apps/product/modules/productListingContainer/redux/productManufacturers.slice';
import productFiltersSlice  from "apps/product/modules/productListingContainer/redux/productFilters.slice";
import singleProductSlice from './productsDetails/singleProductDetails.slice';
import productsCouponSlice from './productsDetails/productCoupons.slice';
import productsCharacteristicSlice from './productsDetails/productCharacteristics.slice';
import productsPriceHistorySlice  from "./productsDetails/productCosePrice.slice";
import productsNoteSlice from './productsDetails/productNotes.slice';
import singleProductCharacteristicsSlice from './productsDetails/singleProductCharacteristics.slice';
import productsUserDiscontinuedSlice from './productsDetails/productsUserDiscontinued.slice';
import productsUserClearanceSlice from './productsDetails/productsUserClearance.slice';
import productsStockSlice from './productsDetails/productStock.slice';
import productsStatusSlice from './productsDetails/productsStatus.slice';
import productsUserGroupsSlice from './productsDetails/productGroups.slice';
import privacySettings from './privacySettings/privacySettings.slice';
import storageSlice from "apps/pos/pages/customerInfo/CustomerStorage/redux/storage.slice";
import customerOrderSlice from "apps/pos/pages/customerInfo/CustomerOrders/redux/customerOrder.slice";
import productsAccessorieSlice from "./productsDetails/productAccessories.slice";
import quoteDetailsSlice from "sagas/quoteDetail/quoteDetailsSlice";

export default combineReducers({
  quoteDetailsSlice,
  authenticatedUser,
  distributor,
  tenant,
  modal,
  quoteDetail,
  quoteList,
  setting,
  user,
  pos,
  posImportQuote,
  persistReducer,
  priceScheduleReducer,
  returnReducer,
  documentReducer,
  genericCustomersReducer,
  snakbarReducer,
  productSliceReducer,
  productVendorsSlice,
  productManufacturersSlice,
  singleProductSlice,
  singleProductCharacteristicsSlice,
  productsUserDiscontinuedSlice,
  productsUserClearanceSlice,
  productsStockSlice,
  productsStatusSlice,
  productsUserGroupsSlice,
  privacySettings,
  storageSlice,
  customerOrderSlice,
  productFiltersSlice,
  productsCouponSlice,
  productsNoteSlice,
  productsCharacteristicSlice,
  productsAccessorieSlice,
  productsPriceHistorySlice
});
