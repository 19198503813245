import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";

import "./Button.scss";

const Button = (props) => {
  const {
    leadingIcon = "",
    trailingIcon = "",
    label = "",
    type = "secondary",
    disabled = false,
    onClick = null,
    href = "",
    target = "",
    size = "medium",
    loading = false,
    classes = "",
    isDownload = false,
  } = props;

  const btnClasses = `sb-btn type-${type} size-${size} ${
    leadingIcon ? "icon-leading" : ""
  } ${trailingIcon ? "icon-trailing" : ""} ${loading ? "busy" : ""} ${classes}`;

  return href ? (
    <a
      href={href}
      target={target ?? null}
      className={btnClasses}
      disabled={disabled}
      download={isDownload}
    >
      <ButtonContent
        leadingIcon={leadingIcon}
        label={label}
        trailingIcon={trailingIcon}
      />
    </a>
  ) : (
    <button
      className={btnClasses}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      <ButtonContent
        leadingIcon={leadingIcon}
        label={label}
        trailingIcon={trailingIcon}
      />
    </button>
  );
};

export default Button;

const ButtonContent = ({ leadingIcon, label, trailingIcon }) => {
  const iconSize = 18;
  return (
    <>
      {leadingIcon ? <MaterialIcon icon={leadingIcon} size={iconSize} /> : null}
      <span className="label">{label}</span>
      {trailingIcon ? (
        <MaterialIcon icon={trailingIcon} size={iconSize} />
      ) : null}
    </>
  );
};
