import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { addProductsAccessorieError, addProductsAccessorieSuccess, getProductsAccessorieGoesWithAllError, getProductsAccessorieGoesWithAllSuccess, getProductsAccessorieGoesWithError, getProductsAccessorieGoesWithSuccess, getProductsAccessorieReplacesError, getProductsAccessorieReplacesSuccess, removeAllRelatedItemsByProductError, removeAllRelatedItemsByProductSuccess, removeParticularAccessoriesError, removeParticularAccessoriesSuccess } from "sagas/productsDetails/productAccessories.slice";
import { AddCharacteristicError, AddCharacteristicSuccess, GetAllCharacteristicTypeListError, GetAllCharacteristicTypeListSuccess, getAllProductsCharacteristicsError, getAllProductsCharacteristicsSuccess, getProductsCharacteristicsError, getProductsCharacteristicsSuccess, removeAllCharacteristicsSuccess, removeParticularCharacteristicsSuccess } from "sagas/productsDetails/productCharacteristics.slice";
import { getProductsPriceHistoryError, getProductsPriceHistorySuccess } from "sagas/productsDetails/productCosePrice.slice";
import { addProductsCouponsError, addProductsCouponsSuccess, getAllProductsCouponsError, getAllProductsCouponsSuccess, getProductsCouponsByKeyError, getProductsCouponsByKeySuccess, getProductsCouponsError, getProductsCouponsSuccess, removeAllProductsCouponsError, removeAllProductsCouponsSuccess, removeProductsCouponsError, removeProductsCouponsSuccess } from "sagas/productsDetails/productCoupons.slice";

import {
  createProductsUserGroupsError,
  createProductsUserGroupsSuccess,
  getHistoryForProductsGroupsError,
  getHistoryForProductsGroupsSuccess,
  updateProductsPriceGroupsError,
  updateProductsPriceGroupsSuccess,
} from "sagas/productsDetails/productGroups.slice";
import { addNotesProductError, addNotesProductSuccess, getProductsNotesError, getProductsNotesSuccess, removeAllNotesProductError, removeAllNotesProductSuccess, removeNotesProductError, removeNotesProductSuccess, updateNotesProductError, updateNotesProductSuccess } from "sagas/productsDetails/productNotes.slice";
import {
  createProductsStockError,
  createProductsStockSuccess,
  deleteProductsStockError,
  deleteProductsStockSuccess,
  getProductsStockMinMaxError,
  getProductsStockMinMaxSuccess,
  updateProductsStockError,
  updateProductsStockSuccess,
} from "sagas/productsDetails/productStock.slice";
import {
  updateProductsStatusError,
  updateProductsStatusSuccess,
} from "sagas/productsDetails/productsStatus.slice";
import {
  createProductsPriceClearanceError,
  createProductsPriceClearanceSuccess,
  createProductsUserClearanceError,
  createProductsUserClearanceSuccess,
  deleteProductsClearanceError,
  deleteProductsClearanceSuccess,
} from "sagas/productsDetails/productsUserClearance.slice";
import {
  createProductsUserDiscontinuedError,
  createProductsUserDiscontinuedSuccess,
  deleteProductsDiscontinuedError,
  deleteProductsDiscontinuedSuccess,
  updateProductsPriceDiscontinuedError,
  updateProductsPriceDiscontinuedSuccess,
} from "sagas/productsDetails/productsUserDiscontinued.slice";
import {
  getSingleProductCharacteristicsError,
  getSingleProductCharacteristicsSuccess,
} from "sagas/productsDetails/singleProductCharacteristics.slice";
import {
  getSingleProductSuccess,
  getSingleProductError,
  updateBasicProductSuccess,
  updateBasicProductError,
  updateSinglePendingProductSuccess,
  updateSinglePendingProductError,
  updateMeasurementsProductSuccess,
  updateMeasurementsProductError,
  updateIdentifiersProductSuccess,
  updateIdentifiersProductError,
  updateFlagesProductSuccess,
  updateSeoProductSuccess,
  updateFlagesProductError,
  updateSeoProductError,
  updateImagesProductSuccess,
  updateImagesProductError,
  updateCostAndPriceProductError,
  updateCostAndPriceProductSuccess,
  getCategoriesMenuProductSuccess,
  getCategoriesMenuProductError,
  getLogsProductSuccess,
  getLogsProductError,
  getRetailPriceScedulesProductSuccess,
  getRetailPriceScedulesProductError,
  removeClearancePriceProductSuccess,
  removeClearancePriceProductError,
  removeDiscontinuedPriceProductSuccess,
  removeDiscontinuedPriceProductError,
  updateRetailPriceScedulesProductSuccess,
  updateRetailPriceScedulesProductError,
  deletePriceScedulesProductSuccess,
  deletePriceScedulesProductError,
  addMinMaxBatchProductSuccess,
  addMinMaxBatchProductError,
  removeMinMaxBatchProductSuccess,
  removeMinMaxBatchProductError,
  getProductTicketsCountSuccess,
  getProductTicketsCountError,
  updateOverviewProductSuccess,
  updateOverviewProductError,
} from "sagas/productsDetails/singleProductDetails.slice";

import {
  AddCharacteristic,
  GetAllCharacteristicTypeList,
  addAccessories,
  addCoupons,
  addMinMaxBatch,
  addNotes,
  createProductsPriceClearance,
  createProductsUserClearance,
  createProductsUserDiscontinued,
  createStock,
  deletePriceScedules,
  deleteProductsClearance,
  deleteProductsDiscontinued,
  deleteStock,
  getAccessories,
  getAccessoriesAll,
  getAllCoupons,
  getCategoriesMenu,
  getCharacteristics,
  getCharacteristicsAll,
  getCoupons,
  getCouponsById,
  getHistoryForProductGroups,
  getLogs,
  getMinMaxStock,
  getNotes,
  getPriceAndCost,
  getProductGroups,
  getRetailPriceScedules,
  getSingleProductCharacteristics,
  getSingleProducts,
  removeAllCharacteristics,
  removeAllCoupons,
  removeAllNotes,
  removeAllRelatedItemsByProduct,
  removeClearance,
  removeCouponsByRowId,
  removeDiscontinued,
  removeNotes,
  removeParticularAccessories,
  removeParticularCharacteristics,
  updateNotes,
  updateProductBasic,
  updateProductCostAndPrice,
  updateProductFlages,
  updateProductGroups,
  updateProductIdentifiers,
  updateProductImage,
  updateProductMeasurements,
  updateProductSeo,
  updateProductsPriceDiscontinued,
  updateProductsStatus,
  updateRetailPriceScedules,
  updateSinglePendingProducts,
  updateStock,
  deleteMinMaxBatch,
  getTicketProductCount,
  updateOverview,
} from "services";

const useProductsDetailsHooks = () => {
  const dispatch = useDispatch();

  // Mutations to get products after search
  const getSingleProductsMutation = useMutation({
    mutationFn: getSingleProducts,
    onSuccess: (data) => {
      dispatch(getSingleProductSuccess(data));
    },
    onError: (error) => {
      dispatch(getSingleProductError(error));
    },
  });

  const updateBasicProductsMutation = useMutation({
    mutationFn: updateProductBasic,
    onSuccess: (data) => {
      dispatch(updateBasicProductSuccess(data));
    },
    onError: (error) => {
      dispatch(updateBasicProductError(error));
    },
  });

  const updateSinglePendingProductsMutation = useMutation({
    mutationFn: updateSinglePendingProducts,
    onSuccess: (data) => {
      dispatch(updateSinglePendingProductSuccess(data));
    },
    onError: (error) => {
      dispatch(updateSinglePendingProductError(error));
    },
  });


  const getSingleProductsCharacteristicsMutation = useMutation({
    mutationFn: getSingleProductCharacteristics,
    onSuccess: (data) => {
      dispatch(getSingleProductCharacteristicsSuccess(data));
    },
    onError: (error) => {
      dispatch(getSingleProductCharacteristicsError(error));
    },
  });

  const createProductsUserClearanceMutation = useMutation({
    mutationFn: createProductsUserClearance,
    onSuccess: (data) => {
      dispatch(createProductsUserClearanceSuccess(data));
    },
    onError: (error) => {
      dispatch(createProductsUserClearanceError(error));
    },
  });

  const createProductsPriceClearanceMutation = useMutation({
    mutationFn: createProductsPriceClearance,
    onSuccess: (data) => {
      dispatch(createProductsPriceClearanceSuccess(data));
    },
    onError: (error) => {
      dispatch(createProductsPriceClearanceError(error));
    },
  });

  const deleteProductsClearanceMutation = useMutation({
    mutationFn: deleteProductsClearance,
    onSuccess: (data) => {
      dispatch(deleteProductsClearanceSuccess(data));
    },
    onError: (error) => {
      dispatch(deleteProductsClearanceError(error));
    },
  });

  const updateProductsStatusMutation = useMutation({
    mutationFn: updateProductsStatus,
    onSuccess: (data) => {
      dispatch(updateProductsStatusSuccess(data));
    },
    onError: (error) => {
      dispatch(updateProductsStatusError(error));
    },
  });

  const createProductsUserDiscontinuedMutation = useMutation({
    mutationFn: createProductsUserDiscontinued,
    onSuccess: (data) => {
      dispatch(createProductsUserDiscontinuedSuccess(data));
    },
    onError: (error) => {
      dispatch(createProductsUserDiscontinuedError(error));
    },
  });

  const updateProductsPriceDiscontinuedMutation = useMutation({
    mutationFn: updateProductsPriceDiscontinued,
    onSuccess: (data) => {
      dispatch(updateProductsPriceDiscontinuedSuccess(data));
    },
    onError: (error) => {
      dispatch(updateProductsPriceDiscontinuedError(error));
    },
  });

  const deleteProductsDiscontinuedMutation = useMutation({
    mutationFn: deleteProductsDiscontinued,
    onSuccess: (data) => {
      dispatch(deleteProductsDiscontinuedSuccess(data));
    },
    onError: (error) => {
      dispatch(deleteProductsDiscontinuedError(error));
    },
  });

  const createStockMutation = useMutation({
    mutationFn: createStock,
    onSuccess: (data) => {
      dispatch(createProductsStockSuccess(data));
    },
    onError: (error) => {
      dispatch(createProductsStockError(error));
    },
  });

  const updateStockMutation = useMutation({
    mutationFn: updateStock,
    onSuccess: (data) => {
      dispatch(updateProductsStockSuccess(data));
    },
    onError: (error) => {
      dispatch(updateProductsStockError(error));
    },
  });

  const deleteStockMutation = useMutation({
    mutationFn: deleteStock,
    onSuccess: (data) => {
      dispatch(deleteProductsStockSuccess(data));
    },
    onError: (error) => {
      dispatch(deleteProductsStockError(error));
    },
  });

  const getProductGroupsMutation = useMutation({
    mutationFn: getProductGroups,
    onSuccess: (data) => {
      dispatch(createProductsUserGroupsSuccess(data));
    },
    onError: (error) => {
      dispatch(createProductsUserGroupsError(error));
    },
  });

  const updateProductGroupsMutation = useMutation({
    mutationFn: updateProductGroups,
    onSuccess: (data) => {
      dispatch(updateProductsPriceGroupsSuccess(data));
    },
    onError: (error) => {
      dispatch(updateProductsPriceGroupsError(error));
    },
  });

  const getHistoryForProductGroupsMutation = useMutation({
    mutationFn: getHistoryForProductGroups,
    onSuccess: (data) => {
      dispatch(getHistoryForProductsGroupsSuccess(data));
    },
    onError: (error) => {
      dispatch(getHistoryForProductsGroupsError(error));
    },
  });

  const getProductMinMaxStockMutation = useMutation({
    mutationFn: getMinMaxStock,
    onSuccess: (data) => {
      dispatch(getProductsStockMinMaxSuccess(data));
    },
    onError: (error) => {
      dispatch(getProductsStockMinMaxError(error));
    },
  });

  const getProductCouponsMutation = useMutation({
    mutationFn: getCoupons,
    onSuccess: (data) => {
      dispatch(getProductsCouponsSuccess(data));
    },
    onError: (error) => {
      dispatch(getProductsCouponsError(error));
    },
  });
  const getProductCouponsByKeyMutation = useMutation({
    mutationFn: getCouponsById,
    onSuccess: (data) => {
      dispatch(getProductsCouponsByKeySuccess(data));
    },
    onError: (error) => {
      dispatch(getProductsCouponsByKeyError(error));
    },
  });

  const getProductNotesMutation = useMutation({
    mutationFn: getNotes,
    onSuccess: (data) => {
      dispatch(getProductsNotesSuccess(data));
    },
    onError: (error) => {
      dispatch(getProductsNotesError(error));
    },
  });

  const getProductCharacteristicsMutation = useMutation({
    mutationFn: getCharacteristics,
    onSuccess: (data) => {
      dispatch(getProductsCharacteristicsSuccess(data));
    },
    onError: (error) => {
      dispatch(getProductsCharacteristicsError(error));
    },
  });

  const getProductAccessorieGoesWithMutation = useMutation({
    mutationFn: getAccessories,
    onSuccess: (data) => {
      dispatch(getProductsAccessorieGoesWithSuccess(data));
    },
    onError: (error) => {
      dispatch(getProductsAccessorieGoesWithError(error));
    },
  });

  const getProductReplacesMutation = useMutation({
    mutationFn: getAccessories,
    onSuccess: (data) => {
      dispatch(getProductsAccessorieReplacesSuccess(data));
    },
    onError: (error) => {
      dispatch(getProductsAccessorieReplacesError(error));
    },
  });

  // See History for product 
  const getProductsPriceHistoryMutation = useMutation({
    mutationFn: getPriceAndCost,
    onSuccess: (data) => {
      dispatch(getProductsPriceHistorySuccess(data));
    },
    onError: (error) => {
      dispatch(getProductsPriceHistoryError(error));
    },
  });

  const getProductAllAccessorieGoesWithMutation = useMutation({
    mutationFn: getAccessoriesAll,
    onSuccess: (data) => {
      dispatch(getProductsAccessorieGoesWithAllSuccess(data));
    },
    onError: (error) => {
      dispatch(getProductsAccessorieGoesWithAllError(error));
    },
  });

  const removeParticularAccessoriesMutation = useMutation({
    mutationFn: removeParticularAccessories,
    onSuccess: (data) => {
      dispatch(removeParticularAccessoriesSuccess(data));
    },
    onError: (error) => {
      dispatch(removeParticularAccessoriesError(error));
    },
  });

  const removeAllRelatedItemsByProductMutation = useMutation({
    mutationFn: removeAllRelatedItemsByProduct,
    onSuccess: (data) => {
      dispatch(removeAllRelatedItemsByProductSuccess(data));
    },
    onError: (error) => {
      dispatch(removeAllRelatedItemsByProductError(error));
    },
  });

  const getCharacteristicsAllMutation = useMutation({
    mutationFn: getCharacteristicsAll,
    onSuccess: (data) => {
      dispatch(getAllProductsCharacteristicsSuccess(data));
    },
    onError: (error) => {
      dispatch(getAllProductsCharacteristicsError(error));
    }
  })

  const getCouponsAllMutation = useMutation({
    mutationFn: getAllCoupons,
    onSuccess: (data) => {
      dispatch(getAllProductsCouponsSuccess(data));
    },
    onError: (error) => {
      dispatch(getAllProductsCouponsError(error));
    }
  })

  const removeCouponsByRowIdMutation = useMutation({
    mutationFn: removeCouponsByRowId,
    onSuccess: (data) => {
      dispatch(removeProductsCouponsSuccess(data));
    },
    onError: (error) => {
      dispatch(removeProductsCouponsError(error));
    },
  });

  const removeAllCouponsMutation = useMutation({
    mutationFn: removeAllCoupons,
    onSuccess: (data) => {
      dispatch(removeAllProductsCouponsSuccess(data));
    },
    onError: (error) => {
      dispatch(removeAllProductsCouponsError(error));
    },
  });

  const addCouponsMutation = useMutation({
    mutationFn: addCoupons,
    onSuccess: (data) => {
      dispatch(addProductsCouponsSuccess(data));
    },
    onError: (error) => {
      dispatch(addProductsCouponsError(error));
    },
  });

  const updateMeasurementsProductsMutation = useMutation({
    mutationFn: updateProductMeasurements,
    onSuccess: (data) => {
      dispatch(updateMeasurementsProductSuccess(data));
    },
    onError: (error) => {
      dispatch(updateMeasurementsProductError(error));
    },
  });

  const updateIdentifiersProductsMutation = useMutation({
    mutationFn: updateProductIdentifiers,
    onSuccess: (data) => {
      dispatch(updateIdentifiersProductSuccess(data));
    },
    onError: (error) => {
      dispatch(updateIdentifiersProductError(error));
    },
  });

  const updateFlagesProductsMutation = useMutation({
    mutationFn: updateProductFlages,
    onSuccess: (data) => {
      dispatch(updateFlagesProductSuccess(data));
    },
    onError: (error) => {
      dispatch(updateFlagesProductError(error));
    },
  });

  const updateSeoProductsMutation = useMutation({
    mutationFn: updateProductSeo,
    onSuccess: (data) => {
      dispatch(updateSeoProductSuccess(data));
    },
    onError: (error) => {
      dispatch(updateSeoProductError(error));
    },
  });

  const updateImageProductsMutation = useMutation({
    mutationFn: updateProductImage,
    onSuccess: (data) => {
      dispatch(updateImagesProductSuccess(data));
    },
    onError: (error) => {
      dispatch(updateImagesProductError(error));
    },
  });

  const updateCostAndPriceProductsMutation = useMutation({
    mutationFn: updateProductCostAndPrice,
    onSuccess: (data) => {
      dispatch(updateCostAndPriceProductSuccess(data));
    },
    onError: (error) => {
      dispatch(updateCostAndPriceProductError(error));
    },
  });

  const removeParticularCharacteristicsMutation = useMutation({
    mutationFn: removeParticularCharacteristics,
    onSuccess: (data) => {
      dispatch(removeParticularCharacteristicsSuccess(data));
    },
    onError: (error) => {
      dispatch(removeParticularAccessoriesError(error));
    },
  });

  const removeAllCharacteristicsMutation = useMutation({
    mutationFn: removeAllCharacteristics,
    onSuccess: (data) => {
      dispatch(removeAllCharacteristicsSuccess(data));
    },
    onError: (error) => {
      dispatch(removeAllRelatedItemsByProductError(error));
    },
  });

  const GetAllCharacteristicTypeListMutation = useMutation({
    mutationFn: GetAllCharacteristicTypeList,
    onSuccess: (data) => {
      dispatch(GetAllCharacteristicTypeListSuccess(data));
    },
    onError: (error) => {
      dispatch(GetAllCharacteristicTypeListError(error));
    },
  });

  const AddCharacteristicMutation = useMutation({
    mutationFn: AddCharacteristic,
    onSuccess: (data) => {
      dispatch(AddCharacteristicSuccess(data));
    },
    onError: (error) => {
      dispatch(AddCharacteristicError(error));
    },
  });



  const addNotesProductsMutation = useMutation({
    mutationFn: addNotes,
    onSuccess: (data) => {
      dispatch(addNotesProductSuccess(data));
    },
    onError: (error) => {
      dispatch(addNotesProductError(error));
    },
  });

  const removeNotesProductsMutation = useMutation({
    mutationFn: removeNotes,
    onSuccess: (data) => {
      dispatch(removeNotesProductSuccess(data));
    },
    onError: (error) => {
      dispatch(removeNotesProductError(error));
    },
  });

  const removeAllNotesProductsMutation = useMutation({
    mutationFn: removeAllNotes,
    onSuccess: (data) => {
      dispatch(removeAllNotesProductSuccess(data));
    },
    onError: (error) => {
      dispatch(removeAllNotesProductError(error));
    },
  });

  const updateNotesProductsMutation = useMutation({
    mutationFn: updateNotes,
    onSuccess: (data) => {
      dispatch(updateNotesProductSuccess(data));
    },
    onError: (error) => {
      dispatch(updateNotesProductError(error));
    },
  });

  const getCategoriesMenuProductsMutation = useMutation({
    mutationFn: getCategoriesMenu,
    onSuccess: (data) => {
      dispatch(getCategoriesMenuProductSuccess(data));
    },
    onError: (error) => {
      dispatch(getCategoriesMenuProductError(error));
    }
  });

  const getLogsProductsMutation = useMutation({
    mutationFn: getLogs,
    onSuccess: (data) => {
      dispatch(getLogsProductSuccess(data));
    },
    onError: (error) => {
      dispatch(getLogsProductError(error));
    },
  });

  const addAccessoriesAccessorieWithMutation = useMutation({
    mutationFn: addAccessories,
    onSuccess: (data) => {
      dispatch(addProductsAccessorieSuccess(data));
    },
    onError: (error) => {
      dispatch(addProductsAccessorieError(error));
    },
  });

  const getRetailPriceScedulesProductsMutation = useMutation({
    mutationFn: getRetailPriceScedules,
    onSuccess: (data) => {
      dispatch(getRetailPriceScedulesProductSuccess(data));
    },
    onError: (error) => {
      dispatch(getRetailPriceScedulesProductError(error));
    }
  });

  const removeClearancePriceProductsMutation = useMutation({
    mutationFn: removeClearance,
    onSuccess: (data) => {
      dispatch(removeClearancePriceProductSuccess(data));
    },
    onError: (error) => {
      dispatch(removeClearancePriceProductError(error));
    }
  });

  const removeDiscontinuedPriceProductsMutation = useMutation({
    mutationFn: removeDiscontinued,
    onSuccess: (data) => {
      dispatch(removeDiscontinuedPriceProductSuccess(data));
    },
    onError: (error) => {
      dispatch(removeDiscontinuedPriceProductError(error));
    }
  });

  const updateRetailPriceScedulesProductsMutation = useMutation({
    mutationFn: updateRetailPriceScedules,
    onSuccess: (data) => {
      dispatch(updateRetailPriceScedulesProductSuccess(data));
    },
    onError: (error) => {
      dispatch(updateRetailPriceScedulesProductError(error));
    }
  });
  

  const deletePriceScedulesProductsMutation = useMutation({
    mutationFn: deletePriceScedules,
    onSuccess: (data) => {
      dispatch(deletePriceScedulesProductSuccess(data));
    },
    onError: (error) => {
      dispatch(deletePriceScedulesProductError(error));
    }
  });

  const addMinMaxBatchsProductsMutation = useMutation({
    mutationFn: addMinMaxBatch,
    onSuccess: (data) => {
      dispatch(addMinMaxBatchProductSuccess(data));
    },
    onError: (error) => {
      dispatch(addMinMaxBatchProductError(error));
    }
  });

  const removeMinMaxBatchesProductsMutation = useMutation({
    mutationFn: deleteMinMaxBatch,
    onSuccess: (data) => {
      dispatch(removeMinMaxBatchProductSuccess(data));
    },
    onError: (error) => {
      dispatch(removeMinMaxBatchProductError(error));
    }
  });
  
  const getProductsTicketsCountMutation = useMutation({
    mutationFn: getTicketProductCount,
    onSuccess: (data) => {
      dispatch(getProductTicketsCountSuccess(data));
    },
    onError: (error) => {
      dispatch(getProductTicketsCountError(error));
    },
  });

  const updateProductsOverviewMutation = useMutation({
    mutationFn: updateOverview,
    onSuccess: (data) => {
      dispatch(updateOverviewProductSuccess(data));
    },
    onError: (error) => {
      dispatch(updateOverviewProductError(error));
    },
  });
  return {
    getSingleProductsMutation,
    updateBasicProductsMutation,
    getSingleProductsCharacteristicsMutation,
    createProductsUserClearanceMutation,
    createProductsPriceClearanceMutation,
    deleteProductsClearanceMutation,
    updateProductsStatusMutation,
    createProductsUserDiscontinuedMutation,
    updateProductsPriceDiscontinuedMutation,
    deleteProductsDiscontinuedMutation,
    createStockMutation,
    updateStockMutation,
    deleteStockMutation,
    getProductGroupsMutation,
    updateProductGroupsMutation,
    getHistoryForProductGroupsMutation,
    getProductMinMaxStockMutation,
    getCouponsAllMutation,
    getProductCouponsMutation,
    getProductCouponsByKeyMutation,
    getProductNotesMutation,
    getProductCharacteristicsMutation,
    getProductAccessorieGoesWithMutation,
    getProductReplacesMutation,
    updateSinglePendingProductsMutation,
    getProductsPriceHistoryMutation,
    getProductAllAccessorieGoesWithMutation,
    removeParticularAccessoriesMutation,
    removeAllRelatedItemsByProductMutation,
    getCharacteristicsAllMutation,
    removeCouponsByRowIdMutation,
    removeAllCouponsMutation,
    addCouponsMutation,
    updateMeasurementsProductsMutation,
    updateIdentifiersProductsMutation,
    updateFlagesProductsMutation,
    updateSeoProductsMutation,
    updateImageProductsMutation,
    removeParticularCharacteristicsMutation,
    removeAllCharacteristicsMutation,
    GetAllCharacteristicTypeListMutation,
    AddCharacteristicMutation,
    updateCostAndPriceProductsMutation,
    addNotesProductsMutation,
    removeNotesProductsMutation,
    removeAllNotesProductsMutation,
    updateNotesProductsMutation,
    getCategoriesMenuProductsMutation,
    getLogsProductsMutation,
    addAccessoriesAccessorieWithMutation,
    getRetailPriceScedulesProductsMutation,
    removeClearancePriceProductsMutation,
    removeDiscontinuedPriceProductsMutation,
    updateRetailPriceScedulesProductsMutation,
    deletePriceScedulesProductsMutation,
    addMinMaxBatchsProductsMutation,
    removeMinMaxBatchesProductsMutation,
    getProductsTicketsCountMutation,
    updateProductsOverviewMutation
  };
};
export default useProductsDetailsHooks;