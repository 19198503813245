import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { genericCustomersError, genericCustomersSuccess } from "sagas/genericCustomers/genericCustomers.slice";
import { genericCustomers } from "services";

const GenericCustomersHooks = () => {
  const dispatch = useDispatch();

  // Mutations to get products after search
  const getGenericCustomersMutation = useMutation({
    mutationFn: genericCustomers,
    onSuccess: (res) => {
      dispatch(genericCustomersSuccess(res.data));
    },
    onError: (error:any) => {
      dispatch(genericCustomersError(error));
    },
  });

  return { getGenericCustomersMutation };
};

export default GenericCustomersHooks;