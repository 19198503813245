import "./skeleton.scss"

const Skeleton = (props) => {
  const { count = 3 } = props;
   return <ul className='skeleton-list pl-0'>
    {
      Array(count).fill(true).map((item, index) => (
      <li
      className='skeleton-item'
      key={'list-skeleton-' + index}
      style={{
        transitionDelay: `${index * 0.05}s`
      }}
    />
    ))
    }
  </ul>
}

export default Skeleton