import { useState, useEffect } from "react";
import { AnimatePresence, motion, MotionConfig } from "framer-motion/dist/framer-motion";

import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import OverlaySkrim from "../OverlaySkrim";
import ResizablePanel from "../ResizablePanel";
import useMeasure from "react-use-measure";
import IconButton from "../IconButton";

import './LocationPicker.scss';

const searchFor = (word, array) => {
    let key = 'categoryName';

    return array.filter(function (e) {
        return e[key]?.toLowerCase().includes(word?.toLowerCase());
    })
}

const transition = {
    type: 'spring',
    bounce: 0,
    duration: 0.35,
};

const LocationPicker = (props) => {
    const { visible, setVisible, selectedLocation, setSelectedLocation, categoryMenu } = props;
    const [page, setPage] = useState('row');
    const [direction, setDirection] = useState(1);

    // when the selected row changes
    // update units 
    useEffect(() => {
        if (selectedLocation?.row && selectedLocation?.unit && selectedLocation?.shelf) {
            setSelectedLocation(current => ({
                ...current
            }))
        } else {
            // reset unit and shelf selection when the selected row changes
            setSelectedLocation(current => ({
                ...current,
                unit: { categoryId: '', categoryName: '' },
                shelf: { categoryId: '', categoryName: '' },
            }));
        }
    }, [selectedLocation.row, selectedLocation.unit, selectedLocation.shelf]);

    const resetSelectedLocation = () => {
        setSelectedLocation(current => ({
            ...current,
            row: { categoryId: '', categoryName: '' },
            unit: { categoryId: '', categoryName: '' },
            shelf: { categoryId: '', categoryName: '' },
        }));
    }

    const closePicker = () => {
        setVisible(false);
    }

    // useEffect(() => {
    //     if (!visible) {
    //         if (!selectedLocation.row.categoryName || !selectedLocation.unit.categoryName || !selectedLocation.shelf.categoryName) {
    //             resetSelectedLocation();
    //         }
    //         setPage('row');
    //     }
    // }, [visible]);

    const moveForward = () => {
        setDirection(1);

        if (page === 'row') {
            setPage('unit');
        }

        if (page === 'unit') {
            setPage('shelf');
        }

        if (page === 'shelf') {
            closePicker();
        }
    }

    const moveBackward = () => {
        setDirection(-1);

        if (page === 'shelf') {
            setPage('unit');
        }

        if (page === 'unit') {
            setPage('row');
        }
    }

    let variants = {
        initial: (direction) => ({ x: `${10 * direction}%`, opacity: 0 }),
        animate: () => ({ x: '0%', opacity: 1 }),
        exit: () => ({ opacity: 0 }),
    }

    const handleItemClick = (id, name) => {
        // reset if a complete location is already selected
        if (selectedLocation.row.categoryName && selectedLocation.unit.categoryName && selectedLocation.shelf.categoryName) {
            resetSelectedLocation();
        }

        setSelectedLocation(current => ({
            ...current,
            [page]: {
                categoryId: id,
                categoryName: name,
            }
        }));
        if (page === "row") {
            const selectedMenuItem = categoryMenu.find(item => item.categoryId === id);

            const childArray = selectedMenuItem ? selectedMenuItem.children : [];
            if (childArray?.length) {
                moveForward();
            } else { closePicker(); }
        }
        if (page === "unit") {
            const { row } = selectedLocation || {}

            const parentItem = categoryMenu.find(item => item.categoryId === row?.categoryId);

            if (parentItem && parentItem?.children) {
                const childItem = parentItem?.children?.find(item => item.categoryId === id);
                const childArray = childItem ? childItem.children : [];
                if (childArray?.length) {
                    moveForward();
                } else {
                    closePicker();
                }
            }
        }
        if (page === 'shelf') {
            moveForward();
        }
    }

    const [ref] = useMeasure();

    const [query, setQuery] = useState('');

    const handleSearch = (e) => {
        setQuery(e.target.value);
    }

    const clearSearchQuery = () => {
        setQuery('');
    }

    useEffect(() => {
        clearSearchQuery();
    }, [page]);

    return (
        <MotionConfig transition={transition} >
            <AnimatePresence>
                {
                    visible ? (
                        <>
                            <motion.div
                                ref={ref}
                                className="location-picker-wrapper"
                                initial={{ opacity: 0, scale: 0.975 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.975 }}
                                transition={{ duration: 0.2, type: 'spring', bounce: 0 }}
                                // style={{
                                //     left: `${window.innerWidth / 2 - bounds.width / 2}px`,
                                // }}
                            >
                                <div className="location-picker-header">
                                    {page !== 'row' ? (
                                        <motion.button
                                            className='location-back-btn'
                                            onClick={moveBackward}
                                            variants={variants}
                                            initial={{ x: '-5%', opacity: 0 }}
                                            animate='animate'
                                        >
                                            {page !== 'row' ? <MaterialIcon icon="arrow_back" size="20" /> : null}
                                        </motion.button>
                                    ) : null}

                                    <div className="location-picker-header-title">
                                        <AnimatePresence
                                            initial={false}
                                            mode='popLayout'
                                        >
                                            <motion.p
                                                className="selection-title"
                                                variants={variants}
                                                key={page}
                                                initial='initial'
                                                animate='animate'
                                                exit='exit'
                                            >
                                                {page === 'row' ? "Select Category" : null}
                                                {page === 'unit' ? `${selectedLocation.row.categoryName}` : null}
                                                {page === 'shelf' ? `${selectedLocation.unit.categoryName}` : null}
                                            </motion.p>
                                        </AnimatePresence>

                                        <div className="location-picker-search-wrapper">
                                            <MaterialIcon icon='search' classes='location-picker-search-icon' size='20' />
                                            <input
                                                type="text"
                                                className="location-picker-search"
                                                placeholder='Search'
                                                value={query}
                                                onChange={handleSearch}
                                            />
                                            {
                                                query.length > 0 ? (
                                                    <IconButton
                                                        icon='close'
                                                        classes='location-search-clear-btn'
                                                        onClick={clearSearchQuery}
                                                        size='32'
                                                        iconSize='20'
                                                        secondary={true}
                                                    />
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>

                                <ResizablePanel>
                                    <AnimatePresence
                                        initial={false}
                                        mode='popLayout'
                                    >
                                        <motion.div
                                            className="location-picker-list"
                                            variants={variants}
                                            key={page}
                                            custom={direction}
                                            initial='initial'
                                            animate='animate'
                                            exit='exit'
                                        >
                                            {page === 'row' ? (
                                                <RowsList
                                                    onItemClick={handleItemClick}
                                                    selectedLocation={selectedLocation}
                                                    query={query}
                                                    categoryMenu={categoryMenu}
                                                />
                                            ) : null}

                                            {page === 'unit' ? (
                                                <UnitsList
                                                    onItemClick={handleItemClick}
                                                    selectedLocation={selectedLocation}
                                                    query={query}
                                                    categoryMenu={categoryMenu}
                                                />
                                            ) : null}

                                            {page === 'shelf' ? (
                                                <ShelvesList
                                                    onItemClick={handleItemClick}
                                                    selectedLocation={selectedLocation}
                                                    query={query}
                                                    categoryMenu={categoryMenu}
                                                />
                                            ) : null}
                                        </motion.div>
                                    </AnimatePresence>
                                </ResizablePanel>
                            </motion.div>
                            <OverlaySkrim onClick={closePicker} />
                        </>
                    ) : null
                }
            </AnimatePresence>
        </MotionConfig>
    );
}

export default LocationPicker;


const LocationItem = (props) => {
    const { item, onClick, checked , isShow } = props;
    const handleClick = () => {
        onClick(item.id, item.name);
    }

    return (
        <li>
            <button
                className={`location-picker-item ${checked ? 'location-picker-item-checked' : ''}`}
                onClick={handleClick}
                data-location-id={item.id}
                data-location-name={item.name}
            >
                {checked ? (
                    <MaterialIcon icon="check" size="20" classes="checked-icon" />
                ) : null}

                <span className="location-picker-item-label" title={item.name}>
                    {item.name}
                </span>

                { isShow ? <MaterialIcon icon="chevron_right" size="20" classes='arrow-icon' /> : null}
            </button>
        </li>
    )
}

const RowsList = (props) => {
    const { onItemClick, selectedLocation, query, categoryMenu } = props;
    if (!categoryMenu?.length) return <LocationPickerEmptyState />

    return (
        <>
            {
                searchFor(query, categoryMenu).map(row => {
                    return (<LocationItem
                        key={row.categoryId}
                        item={{
                            id: row.categoryId,
                            name: row.categoryName,
                        }}
                        onClick={onItemClick}
                        checked={selectedLocation.row.categoryId === row.categoryId}
                        isShow={Boolean(row?.children?.length)}
                    />)
                })
            }
        </>
    )
}

const UnitsList = (props) => {
    const { onItemClick, selectedLocation, query, categoryMenu } = props;
    const { row } = selectedLocation || {}

    const selectedMenuItem = categoryMenu.find(item => item.categoryId === row?.categoryId);
    const childArray = selectedMenuItem ? selectedMenuItem.children : [];
    if (!childArray?.length) return <LocationPickerEmptyState />
    return (
        <>
            {
                searchFor(query, childArray).map(unit => (
                    <LocationItem
                        key={unit.categoryId}
                        item={{
                            id: unit.categoryId,
                            name: unit.categoryName,
                        }}
                        onClick={onItemClick}
                        checked={selectedLocation.unit.categoryId === unit.categoryId}
                        isShow={Boolean(unit?.children?.length)}
                    />
                ))
            }
        </>
    )
}

const ShelvesList = (props) => {
    const { onItemClick, selectedLocation, query, categoryMenu } = props;

    const { row, unit } = selectedLocation || {}
    let childArray = []
    const parentItem = categoryMenu.find(item => item.categoryId === row?.categoryId);

    if (parentItem && parentItem?.children) {
        const childItem = parentItem?.children?.find(item => item.categoryId === unit?.categoryId);
        childArray = childItem ? childItem.children : [];
        if (!childArray?.length) return <LocationPickerEmptyState />
    }

    return (
        <>
            {
                searchFor(query, childArray).map(shelf => (
                    <LocationItem
                        key={shelf.categoryId}
                        item={{
                            id: shelf.categoryId,
                            name: shelf.categoryName
                        }}
                        onClick={onItemClick}
                        checked={selectedLocation.shelf.categoryId === shelf.categoryId}
                    />
                ))
            }
        </>
    )
}

const LocationPickerEmptyState = () => {
    return (
        <div className="location-picker-empty-state">
            <div></div>
            <div></div>
        </div>
    )
}