const headerQuoteItems = [
  "Damin Sales",
  "New York Digital",
  "Assign a new Rep",
];

const quoteItemData = [
  {
    title: "Damin Sales",
    data: [
      {
        index: 1,
        quantity: 50,
        type: "",
        code: "HAYBAY150/D10",
        manufacturer: "Emergi-Lite Co.",
        unitPrice: 433.33,
        notes: "",
        productID: 2474451,
        unitCost: 187.62,
        itemMargin: 27.5,
        repCost: 5565.5,
        repMargin: 0,
        repPrice: 7610.5,
        repNote: "01454361",
      },
      {
        index: 2,
        quantity: 50,
        type: "",
        code: "R16SPF",
        manufacturer: "Emergi-Lite Co.",
        unitPrice: 433.33,
        notes: "Fixture discontinued for the...",
        productID: 2474451,
        unitCost: 45.62,
        itemMargin: 127.5,
        repCost: 0,
        repMargin: 0,
        repPrice: 0,
        repNote: "",
      },
    ],
  },
  {
    title: "New York Digital",
    data: [
      {
        index: 3,
        quantity: 50,
        type: "1-Inverter",
        code: "EMILC-55-S 55W Surface Mount Low Capacity...",
        manufacturer: "Emergi-Lite Co.",
        unitPrice: 433.33,
        notes: "",
        productID: 2474451,
        unitCost: 187.62,
        itemMargin: 27.5,
        repCost: 5565.5,
        repMargin: 0,
        repPrice: 7610.5,
        repNote: "2340-16-10458-6",
      },
      {
        index: 4,
        quantity: 50,
        type: "1-Inverter",
        code: "PXHW 12000LM MDU5FR MVO...",
        manufacturer: "Emergi-Lite Co.",
        unitPrice: 433.33,
        notes: "",
        productID: 2474451,
        unitCost: 45.62,
        itemMargin: 127.5,
        repCost: 0,
        repMargin: 0,
        repPrice: 0,
        repNote: "",
      },
    ],
  },
];

const QuoteStatus = ["Expired", "New", "Revision", "Dead(no-quote)" , "Activate"];

const QuoteStatusTabs = [
  "",
  "New",
  "Sent",
  "Converted",
  "Expired",
  "Won",
  "Dead",
]

const QuoteStatusTab = {
  1 : "New",
  5 : "Sent",
  2 : "Converted",
  0 : "Expired",
  4 : "Won",
  6 : "Dead",
}

const byDays = {
  days : "day",
  week : "week",
}

const QuoteLabelStatus = [
  "Expired",
  "New",
  "Converted",
  "Deleted",
  "Order Placed",
  "Sent",
  "Dead(killed)",
  "Dead(no-quote)",
];

const NewQuoteStatus = [
  "New",
  "Revision",
  "Expired",
  "Sent",
  "Dead(killed)",
  "Dead(no-quote)",
];
const ExpiredQuoteStatus = ["Revision", "Expired", "Sent", "Dead(killed)", "Dead(no-quote)"];
const ExtendByData = ["2 days", "1 week", "2 weeks"];
const QuoteGroupBy = [
  "Date added",
  "Document type",
  "File type",
  "REP",
  "Uploader",
];

const QuoteSortBy = ["Name", "Number", "Date added", "Size"];
const quoteMessagesSortBy = ["Newest first", "Oldest first"];
const QuoteRepsData = [
  {
    title: "Damin Sales",
    assignDate: "Assigned on Apr 01, 2021",
    BuyPrice: "No",
    QuoteUploaded: "No",
  },
  {
    title: "New York Digital",
    assignDate: "Assigned on Apr 01, 2021",
    BuyPrice: "No",
    QuoteUploaded: "No",
  },
];
const PriceRecdStatus = ["No", "Yes"];
const TicketStatus = [
  { title: "Ask Doug Stone for a submittal", link: "autoTextSubmittal" },
  {
    title: "Ask Estimator for Unit Costs and Buy Prices",
    link: "autoTextUnitCost",
  },
  { title: "Tell Salesperson Job is ready to send", link: "autoTextJobReady" },
  { title: "Ask quotation person for lead time", link: "autoTextLeadTime" },
  { title: "Ask quotation person to revise quote", link: "reviseQuote" },
  { title: "Ask colleague to check quote", link: "checkQuote" },
  { title: "Follow up with Reps", link: "followUpRep" },
  { title: "Ask to assign items", link: "assignItem" },
];

const QuoteTickets = [
  "Create a new ticket",
  "View all tickets",
  "Ask Doug Stone for submittial",
  "Ask Estimator for unit Costs and Buy Prices",
  "Tell Salesperson Jon is ready to send",
  "Ask quotation person for lead time",
  "Ask quotation person to revise quote",
  "Follow up with Reps",
  "Ask to assign items",
];
const likelihoodData = [0, 20, 40, 60, 80, 100];

const DocumentSortBy = ["Recent first", "Oldest first"];

const statusConditions = {
  "Dead(no-quote)": ['Dead(killed)', 'Dead(no-quote)'],
  "Expired": ['Expired'],
  "New": ['New', 'Activate'],
  "Sent": ['Sent', 'Activate'],

};

export {
  headerQuoteItems,
  quoteItemData,
  QuoteStatus,
  QuoteLabelStatus,
  QuoteGroupBy,
  QuoteSortBy,
  quoteMessagesSortBy,
  QuoteRepsData,
  PriceRecdStatus,
  TicketStatus,
  QuoteTickets,
  NewQuoteStatus,
  ExpiredQuoteStatus,
  ExtendByData,
  likelihoodData,
  DocumentSortBy,
  QuoteStatusTabs,
  statusConditions,
  QuoteStatusTab,
  byDays
};
