import { Grid } from "@material-ui/core";

import CommonEditButton from "apps/product/Components/ProductDetails/CommonEditButton";
import useFilterSections from "apps/product/Components/ProductDetails/useSectionFilter";
import CreateGridStarIcon from "apps/product/Components/ProductDetails/CreateGridStarIcon";

import { sectionName } from "apps/product/modules/productDetailsContainer/constants";


const ProductDetailsCharacteristics = ({ isStarred = false, searchText,productCharacteristicsData, handleSearchCharacteristics }) => {
  const { characteristics } = sectionName;
  const { showSection } = useFilterSections({ searchText, sectionName: characteristics, isStarred });

  return (
    <>
      <section className="product-report-section">
        {productCharacteristicsData?.length ?
          productCharacteristicsData.map((option) => {
            const { productCharacteristicId, productCharacteristicTypeName, productCharacteristicValue , productCharacteristicValue2 } = option || {}
            return (
              <Grid container className="product-report-container" key={productCharacteristicId}>
                {showSection(productCharacteristicTypeName,productCharacteristicTypeName) ?  (
                  <Grid container className="report-div">
                    <CreateGridStarIcon className={productCharacteristicTypeName} label={productCharacteristicTypeName} value={productCharacteristicId} sectionName={characteristics} />
                    <Grid item xs={12} sm={8}>
                      <span className="d-block">{productCharacteristicValue}</span>
                      <span className="d-block">{productCharacteristicValue2}</span> 
                    </Grid>
                    <CommonEditButton onClick={handleSearchCharacteristics(productCharacteristicTypeName)} />
                  </Grid>
                ) : null }
              </Grid>
            )
          })
          : null
        }
      </section>
    </>
  );
};

export default ProductDetailsCharacteristics;
