import { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { useSelector } from "react-redux";

import LocationItem from "./LocationItemComponenet";
import LocationPickerEmptyState from "./LocationPickerEmptyStateComponent";

import { title } from "constants/constant";
import { AppToURLPathName } from "enums";

import { searchFor } from '../helper'
import { fetcher } from "utils/service";
import { PosResponseProps, RootState } from "models";
import { getLocalStoraGeItem } from "utils/utils";

const UnitsList = ({ onItemClick, selectedLocation, query, getLocationProductWise, units, error, isLoading }) => {

  const { productsData } = useSelector<RootState, PosResponseProps>(
    (state) =>({...state.pos})
  );

  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {} ;

    const rowURL = useMemo(()=>{
      return getLocationProductWise ? 
      `${process.env.REACT_APP_API_BASE_URL}/${AppToURLPathName.Api}/v2/stock/warehouses/${stockWarehouseId}/products/${productsData.productId}/locations/rows/${selectedLocation.row.id}/units` : 
      `${process.env.REACT_APP_API_BASE_URL}/${AppToURLPathName.Api}/v1/stock/unit/rows/${selectedLocation.row.id}/active?page=1&size=100`
    }, [selectedLocation.row.id, getLocationProductWise])

    const {
      data: allUnits = [],
      error: unitsError,
      isLoading: unitsLoading,
    } = useSWR(
      rowURL,
      fetcher,
      {
        revalidateOnFocus: false,
      },
    );

    const [finalUnits, setfinalUnits] = useState(units)

    useEffect(() => {
      if(getLocationProductWise) {
        setfinalUnits(units)
      } 
        setfinalUnits(allUnits)
    },[getLocationProductWise, allUnits, units])

    
    if (isLoading || unitsLoading) return <LocationPickerEmptyState />;
    if (error?.length || unitsError)
      return (
        <span className="location-picker-error-message">
          Unable to load units for {selectedLocation.row.name}
        </span>
      );
  
    return (
      <>
        {searchFor(query, finalUnits, "unit")?.length > 0 ?
          searchFor(query, finalUnits, "unit").map((unit) => (
          <LocationItem
            key={unit.unitID}
            item={{
              id: unit.unitID ? unit.unitID : unit.stockUnitID,
              name: unit.unitName,
            }}
            onClick={onItemClick}
            checked={selectedLocation.unit.id === unit.unitID}
          />
        ))
          :  
          <span className="list-header">{title.noDataFound}</span>
        }
      </>
    );
  };

  export default UnitsList