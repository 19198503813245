import service from "services/config2";
import serviceV1 from "services/config";
import serviceV2 from "services/config2";
import { ReturnCartAllDataProps } from "constants/interfaces";

const RETURNS_API_PATH = "returns/rmas";

export function getReason(payload: any) {
  return service
    .get(`${RETURNS_API_PATH}/reasons`, payload.body)
    .then((response) => {
      return response;
    });
}

export function getSearchInvoiceList(payload: any) {
  return serviceV2
    .get(`${RETURNS_API_PATH}/simplified/search/invoices`, payload.body)
    .then((response) => {
      return response;
    });
}

// return Products Picking

export function returnProductsPicking(payload: number, stockWarehouseId) {
  return serviceV2
    .post(
      `${RETURNS_API_PATH}/warehouses/${stockWarehouseId}/locations/picking`,
      payload
    )
    .then((response) => {
      return response?.data;
    });
}

// return Products Ticket Print

export function returnProductsTicketPrint(payload: any) {
  return serviceV2
    .post(`${RETURNS_API_PATH}/return/print/ticket/pdf`, payload)
    .then((response) => {
      return response?.data;
    });
}

// return Products Credit Memo

export function returnProductsCreditMemo(payload: any) {
  return serviceV2
    .post(`${RETURNS_API_PATH}/return/merge/creditmemo/pdf`, payload)
    .then((response) => {
      return response?.data;
    });
}

// return Products Orders

export function returnProductsOrders(payload: ReturnCartAllDataProps, stockWarehouseId) {
  return serviceV2
    .post(
      `${RETURNS_API_PATH}/warehouses/${stockWarehouseId}/orders/return`,
      payload
    )
    .then((response) => {
      return response?.data;
    });
}

// start
export function searchReturnInvoice(body: any) {
  return serviceV2
    .post(
      `${RETURNS_API_PATH}/simplified/search/invoices
    `,
      body
    )
    .then((response) => {
      return response;
    });
}

export function searchReturnReason() {
  return serviceV1.get(`/returns/rmas/reasons`).then((response) => {
    return response;
  });
}
// end