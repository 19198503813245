import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import CreateGridStarIcon from "apps/product/Components/ProductDetails/CreateGridStarIcon";
import CommonGridSection from "apps/product/Components/ProductDetails/CommonGridSection";
import useFilterSections from "apps/product/Components/ProductDetails/useSectionFilter";

import { sectionName } from "apps/product/modules/productDetailsContainer/constants";
import { Paths } from "enums";
import { commonConstants } from "apps/product/helper/commonConstants";
import { getSingleProductDataFunction } from "sagas/productsDetails/selector";

import "apps/product/Styles/ProductDetailsReports.scss";

const ProductDetailsReports = ({ isStarred = false, searchText }) => {
  const params = useParams();

  const getSingleProductData = useSelector(getSingleProductDataFunction);

  const { details } = getSingleProductData || {}

  const productID = params?.id;

  const { reports } = sectionName;
  const { showSection } = useFilterSections({ searchText, sectionName: reports, isStarred });

  const purchaseHistory = () => {
    return window.open(`${Paths.product}/${productID}/${Paths.reports}/${Paths.purchaseHistoryReport}?productName=${details?.productName}`, commonConstants.target, commonConstants.noreferrer);
  }
  const itemHistory = () => {
    return window.open(`${Paths.product}/${productID}/${Paths.reports}/${Paths.itemHistory}`, commonConstants.target, commonConstants.noreferrer);
  }
  const itemLedger = () => {
    return window.open(`${Paths.product}/${productID}/${Paths.reports}/${Paths.ledger}`, commonConstants.target, commonConstants.noreferrer);
  }
  // May need Later
  const quoteHistory = () => {
    return window.open(`${Paths.product}/${productID}/${Paths.reports}/${Paths.quotesHistory}?productName=${details?.productName}`, commonConstants.target, commonConstants.noreferrer);
  }
  const saleHistory = () => {
    return window.open(`${Paths.product}/${productID}/${Paths.reports}/${Paths.salesHistory}?productName=${details?.productName}`, commonConstants.target, commonConstants.noreferrer);
  }
  const invoiceHistory = () => {
    return window.open(`${Paths.product}/${productID}/${Paths.reports}/${Paths.invoiceHistoryReport}?productName=${details?.productName}`, commonConstants.target, commonConstants.noreferrer);
  }

  const posPriceChangeHistory = () => {
    return window.open(`${Paths.product}/${productID}/${Paths.reports}/${Paths.posPriceChangeReport}?productName=${details?.productName}`, commonConstants.target, commonConstants.noreferrer);
  }
   
  const movingAvgCostChangeHistory = () => {
    return window.open(`${Paths.product}/${productID}/${Paths.reports}/${Paths.movingAvgCostChangeReport}?productName=${details?.productName}`, commonConstants.target, commonConstants.noreferrer);
  }

  return (
    <>
      <section className="product-report-section">
        <Grid container className="product-report-container">
          {showSection("ItemLedger",'Item Ledger') ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='ItemLedger' label='Item Ledger' value='ItemLedger' sectionName={reports} />   
              <CommonGridSection value="See Item Ledger" onClick={itemLedger} reportsSection={true} />
            </Grid>
          ) : null}
          {showSection("ItemHistory",'Item History') ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='ItemHistory' label='Item History' value='ItemHistory' sectionName={reports} />   
              <CommonGridSection value="See Item History" onClick={itemHistory} reportsSection={true} />
            </Grid>
          ) : null}
          {showSection("QuoteHistory",'Quote History') ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='QuoteHistory' label='Quote History' value='QuoteHistory' sectionName={reports} />   
              <CommonGridSection value="See Quote History" onClick={quoteHistory} reportsSection={true} />
            </Grid>
          ) : null}
          {showSection("SalesHistory",'Sales History') ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='SalesHistory' label='Sales History' value='SalesHistory' sectionName={reports} />   
              <CommonGridSection value="See Sales History" onClick={saleHistory} reportsSection={true} />
            </Grid>
          ) : null}
          {showSection("PurchaseHistory",'Purchase History') ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='PurchaseHistory' label='Purchase History' value='PurchaseHistory' sectionName={reports} /> 
              <CommonGridSection value="See Purchase History" onClick={purchaseHistory} reportsSection={true} />
            </Grid>
          ) : null}
          {showSection("InvoiceHistory",'Invoice History') ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='InvoiceHistory' label='Invoice History' value='InvoiceHistory' sectionName={reports} />   
              <CommonGridSection value="See Invoice History" onClick={invoiceHistory} reportsSection={true} />
            </Grid>
          ) : null}
          {showSection("POSPriceChangeHistory",'POS Price Change History') ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='POSPriceChangeHistory' label='POS Price Change History' value='POSPriceChangeHistory' sectionName={reports} />   
              <CommonGridSection value="See POS Price Change history " onClick={posPriceChangeHistory} reportsSection={true} />
            </Grid>
          ) : null}
          {showSection("MovingAvgCostChangeHistory",'Moving Avg. Cost Change History') ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='MovingAvgCostChangeHistory' label='Moving Avg. Cost Change History' value='MovingAvgCostChangeHistory' sectionName={reports} />
              <CommonGridSection value="See Moving Avg. Cost Change history " onClick={movingAvgCostChangeHistory} reportsSection={true} />
            </Grid>
          ) : null}
        </Grid>
      </section>
    </>
  );
};

export default ProductDetailsReports;