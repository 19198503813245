import { Box, LinearProgress } from '@mui/material';

const FileUploadProgress = ({ progress }) => {

    return (
        <Box sx={{ width: '100%', mt: 2 }} className='file-upload-progress'>
            <LinearProgress variant="determinate" value={progress} />
        </Box>
    );
};

export default FileUploadProgress;
