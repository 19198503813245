import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { uploadDocument } from "../request";

export const useDocumentUpload = () => {

  // const [signal,setSignal] = useState();

  const { mutate, isLoading, data, error, isSuccess } = useMutation(
    async (payload) => {
      return uploadDocument(payload);
    },
  );

  const setDocumentUploadParams = useCallback(
    (payload) => {
      mutate(payload);
    },
    [mutate]
  );

  // const setSignalAPI = useCallback(
  //   (payload) => {
  //     setSignal(payload);
  //   },
  //   [mutate]
  // );

  return {
    setDocumentUploadParams,
    isLoading,
    data,
    error,
    isSuccess,
  };
};
