import { Tooltip } from "@material-ui/core";

import { TableColumn } from "components/TableColumn/TableColumn";

import { getComparator } from "utils/helper";
import { isoToFormat, showDot } from "utils/utils";
import { PaginationFormat } from "constants/enum";
import { title } from "constants/constant";

export const EnhancedTableBody = ({
  quotesHistory,
  stableSort
}) => {

  const { Asc, Calories } = PaginationFormat;

  return quotesHistory?.length ? (
    stableSort(quotesHistory, getComparator(Asc, Calories))?.map(
      (row, index) => {
        const { createdAt, quoteId, lineId, companyName, posCustCode, fullName, qtyQuoted, openValue, adjustedSalesPrice, poNum , jobName } = row
        const poNumber = showDot(poNum, 10);
        const job = showDot(jobName, 10);
        return (
          <div className="table-body-row-quote-history" key={createdAt + "_" + index}>
            <TableColumn className="inv-date-col" value={isoToFormat(createdAt,"MM/DD/YY")} />
            <Tooltip title={poNum} placement="top">
              <div className={`table-body-col`}>
                {poNumber || ""}
              </div>
            </Tooltip>
            <Tooltip title={jobName} placement="top">
              <div className={`table-body-col`}>
                {job || ""}
              </div>
            </Tooltip>
            <TableColumn value={quoteId || ""}/>
            <TableColumn value={lineId || ""}/>
            <TableColumn className="customer-col-width">
              <div className="flex-1">{companyName || ""}</div>
              <div className="flex-1 customer-code">{posCustCode || fullName}</div>
            </TableColumn>
            <TableColumn value={openValue ? openValue.toFixed(2) : ""}/>
            <TableColumn value={qtyQuoted ? qtyQuoted.toFixed(2)+"/F" : ""}/>
            <TableColumn value={adjustedSalesPrice ? adjustedSalesPrice.toFixed(2)+"/M" : ""}/>
          </div>
        );
      }
    )
  ) : (
    <div className="sales-history-no-data-found">
      {title.noDataFound}
    </div>
  )
}