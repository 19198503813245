import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import ListBoxDropDown from "../Components/ListBoxDropDown";
import ProductDetailsCommonModal from "./ProductDetailsCommonModal";
import useProductsDetailsHooks from "../modules/productDetailsContainer/hooks/mutationHooks";
import Skeleton from "apps/product/Components/Skeleton/Skeleton";

import { ProductDetailsCouponsModalsProp } from "constants/interfaces";
import { labels } from "../modules/productDetailsContainer/constants";

import { getAllProductsCouponsStart } from "sagas/productsDetails/productCoupons.slice";
import { getAllProductCoupons, getIsAddProductsCoupons } from "sagas/productsDetails/selector";
import { getLoggedInUser } from "sagas/user/selector";

import "./ProductDetailsCommonModal.scss";
import "./productDetailsCouponsModals.scss";


export default function ProductDetailsCouponsModals({
    open,
    onClose,
    productCouponsData,
    removeAllCoupons,
    couponRemoveById,
    couponsList,
    AddCoupon,
}: ProductDetailsCouponsModalsProp) {
     
    const dispatch = useDispatch()
    const { getCouponsAllMutation } = useProductsDetailsHooks();
    const { Attached , Detach , DetachAll } = labels
    const getAllProductCouponsData = useSelector(getAllProductCoupons)
    const isAddProductsCoupons = useSelector(getIsAddProductsCoupons)
    const loggedInUser = useSelector(getLoggedInUser)
    const { fullName } = loggedInUser;
    const [skeleton , setSkeleton] = useState(false)

    useEffect(() => {
        if (!getAllProductCouponsData?.length) {
            dispatch(getAllProductsCouponsStart());
            getCouponsAllMutation.mutate();
        }
    }, [])

    useEffect(()=>{
        if (isAddProductsCoupons){
            setSkeleton(true)
        }
        else{
            setTimeout(() => { setSkeleton(false) },1000)
        }
    }, [isAddProductsCoupons])

    return (
        <>
            <ProductDetailsCommonModal
                open={open}
                onClose={onClose}
                modalMainTitle="Coupons">
                <ListBoxDropDown couponsList={couponsList} AddCoupon={AddCoupon}/>
                {skeleton && !productCouponsData?.length  ?
                    <div className="mt-3">
                        <Skeleton loading={skeleton}></Skeleton>
                    </div>
                    :null
                }
                {
                    productCouponsData?.length ?
                        <> 
                            <div className="d-flex justify-content-between total-notes-count-section">
                                <div className="notes-added-title">
                                    <span>
                                        {`${productCouponsData?.length} ${Attached}`}
                                    </span>
                                </div>
                                <div className="notes-remove-title" onClick={removeAllCoupons}>
                                    <span>
                                        {DetachAll}
                                    </span>
                                </div>
                            </div>       
                            {skeleton ?
                                <div className="mt-3">
                                    <Skeleton loading={skeleton}></Skeleton>
                                </div>
                                :null
                            }              
                            {
                                productCouponsData?.map((option) => {
                                    const { coupon, rowID } = option || {}
                                    const { couponCode, oneTimeFlag, couponDiscount, createDate, couponDescription, minOrderAmt, startDate, endDate, } = coupon || {}
                                    return (

                                        <div className="product-details-notes-main-content-section">
                                            <div className="coupons-dotted-border-sec">
                                                <div className="coupon-main-info">
                                                    <span className="coupon-name">
                                                        {couponCode}
                                                    </span>
                                                    <span className="coupon-discount">
                                                        {`${couponDiscount ? couponDiscount.toFixed(2) : 0}%`}
                                                    </span>
                                                </div>
                                                <div className="coupon-timing-info coupon-common-styling mb-2">
                                                    {oneTimeFlag ? <span className="coupon-timing">
                                                        <span>One-time</span>
                                                    </span> : null}

                                                    <span className="coupon-dot" />
                                                    <span className="coupon-validity coupon-common-styling">
                                                        Valid {startDate ? moment(startDate).format("ll") : ""}-{endDate ? moment(endDate).format("ll") : ""}
                                                    </span>
                                                </div>
                                                <div className="coupon-min-value coupon-common-styling mb-2">
                                                    <span>
                                                        {`Min. order ${minOrderAmt ? minOrderAmt.toFixed(2) : 0}`}
                                                    </span>
                                                </div>
                                                <div className="coupon-new-offer coupon-common-styling">
                                                    <span>
                                                        {couponDescription}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="last-edited-info">
                                                    <span>
                                                        {`Attached by ${fullName} on ${createDate ? moment(createDate).format("ll") : ""}`}
                                                    </span>
                                                </div>
                                                <div className="notes-remove-title" onClick={couponRemoveById(rowID)}>
                                                    <span>
                                                     {Detach}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                        : null
                }
            </ProductDetailsCommonModal>
        </>
    );
}
