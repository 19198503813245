import { useSelector } from "react-redux";
import Fuse from 'fuse.js';
import { hasKeyInCategories, isKeyAvailable } from "apps/product/helper/product-details-helper";
import { getStaredProductsDetails } from "sagas/persistReducer/selector";

const useFilterSections = ({searchText = "" ,sectionName , isStarred = false} ) =>{

  const starredProductsDetails = useSelector(getStaredProductsDetails);

  const hasKeyInCategory = (key: string) =>
    isKeyAvailable(sectionName, key, starredProductsDetails);

  const isSearchKey = (label: string) => {
  if (!searchText) {
    return true;
  }


  const fuseOptions = {
    threshold: 0.2,
    keys: ['item'], 
  };

  const fuse = new Fuse([{ item: label }], fuseOptions);
  const result = fuse.search(searchText);

  return result.length > 0;
};

  const showSection = (key: string ,label:string) => {
    return searchText ? isSearchKey(label) : hasKeyInCategories(isStarred, key, hasKeyInCategory) || !isStarred;
  }

  return { showSection, hasKeyInCategory };
}

export default useFilterSections;