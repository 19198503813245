import { AnyAction } from "redux";
import _ from "loadsh";

import * as constants from "./constants";

// const {clearance, discontinued, salesOverride, priceScheduleSingle, priceScheduleVolume, lastPosRetailPrice,
//   salesPrice, vendorPrice} = ProductTypesTODO:need this code for refactoring

const initialState = {
  importQuotesDetails: [],
  isImportQuotesLoading: false,
  importQuotesTotal: 0,
  importQuotesSearchDetails: [],
  // qtyInStockDetails:[],TODO:need this code for refactoring
  // qtyOutOfStockDetails: [],
  // qtyDiscontinuedAvailableStock: [],
  // qtyDiscontinuedOutOfStock: [],
  isImportQuotesSearchLoading: false,
  importQuotesSearchTotal: 0,
  importQuotesSearchError:"",
  isImportQuotesItemsLoading:false,
  importQuotesItemsDetails:[],
  importQuotesItemsError:"",
  selectedIndex: -1,
};

const reducer = function PosImportQuotesReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {

    // Start import Quote
    case constants.IMPORT_QUOTE_START:
      return { ...state, isImportQuotesLoading: true };
    case constants.IMPORT_QUOTE_SUCCESS:
      const importQuoteId = state.importQuotesDetails;
      return {
        ...state,
        importQuotesDetails: _.uniqBy(
          [...importQuoteId, ...action.payload.data],
          "quoteId"
        ),
        importQuotesTotal: action.payload.total,
        isImportQuotesLoading: false,
      };
    case constants.IMPORT_QUOTE_ERROR:
      return {
        ...state,
        importQuotesDetails: [],
        isImportQuotesLoading: false,
      };
    case constants.IMPORT_QUOTE_CLEAR:
      return {
        ...state,
        importQuotesDetails: [],
        importQuotesTotal: 0,
        isImportQuotesLoading: false,
      };
    // End import Quote

    // Start import Quote Search
    case constants.IMPORT_QUOTE_SEARCH_START:
      return { ...state, isImportQuotesSearchLoading: true };
    case constants.IMPORT_QUOTE_SEARCH_SUCCESS:
      return {
        ...state,
        importQuotesSearchDetails: _.uniqBy(action.payload.data,"quoteId"
        ),
        importQuotesSearchTotal: action.payload.total,
        isImportQuotesSearchLoading: false,
      };
    case constants.IMPORT_QUOTE_SEARCH_ERROR:
      return {
        ...state,
        importQuotesSearchDetails: [],
        importQuotesSearchError: action.error,
        isImportQuotesSearchLoading: false,
      };
    case constants.IMPORT_QUOTE_SEARCH_CLEAR:
      return {
        ...state,
        importQuotesSearchDetails: [],
        importQuotesSearchTotal: 0,
        importQuotesSearchError: "",
        isImportQuotesSearchLoading: false,
      };
    // End import Quote Search

    // Start import Quote Items
    case constants.IMPORT_QUOTE_ITEMS_START:
      return { ...state, isImportQuotesItemsLoading: true };
    case constants.IMPORT_QUOTE_ITEMS_SUCCESS:      
      return {
        ...state,
        importQuotesItemsDetails: action.payload.data,
        isImportQuotesItemsLoading: false,
      };
    case constants.IMPORT_QUOTE_ITEMS_ERROR:
      return {
        ...state,
        importQuotesItemsDetails: [],
        importQuotesItemsError: action.error,
        isImportQuotesItemsLoading: false,
      };
    case constants.IMPORT_QUOTE_ITEMS_CLEAR:
      return {
        ...state,
        importQuotesItemsDetails: [],
        importQuotesItemsError:"",
        isImportQuotesItemsLoading: false,
      };
    // End import Quote Items

    // // Start import Quote Items TODO:need this code for refactoring
    // case constants.IMPORT_QUOTE_ITEMS_START:
    //   return { ...state, isImportQuotesItemsLoading: true };
    // case constants.IMPORT_QUOTE_ITEMS_SUCCESS: 
    //   // qtyInStock data
    //   const qtyStockData = action.payload.data.filter((item) => item?.posProductResponse?.qtyInStock > 0 && item?.posProductResponse?.productPrices[0].type !== discontinued && (item?.posProductResponse?.qtyInStock > item?.qtyOrdered || item?.posProductResponse?.qtyInStock === item?.qtyOrdered)).map((item) => {
    //     const productTypeWise = productTypeConverter(item?.posProductResponse?.productPrices);
    //     return { ...item, ...productTypeWise, isSelected: true }});
    //   // Out Of Stock
    //   const outOfStockData = action.payload.data?.filter((item) => (item?.posProductResponse?.qtyInStock === 0 || item?.posProductResponse?.qtyInStock < item?.qtyOrdered) && item?.posProductResponse?.productPrices[0].type !== discontinued).map((item) => {
    //     const productTypeWise = productTypeConverter(item?.posProductResponse?.productPrices);
    //     return { ...item, ...productTypeWise, isSelected: true }
    //   });

    //   // Discontinued, Available in Stock
    //   const qtyDiscontinuedAvailableStockData = action.payload.data?.filter((item) => item?.posProductResponse?.qtyInStock > 0 && item?.posProductResponse?.productPrices[0].type === discontinued ).map((item) => {
    //     const productTypeWise = productTypeConverter(item?.posProductResponse?.productPrices);
    //     return { ...item,...productTypeWise, isSelected: true }});

    //   // discontinued Out Of Stock
    //   const qtyDiscontinuedOutOfStockData = action.payload.data?.filter((item) => item?.posProductResponse?.qtyInStock === 0 && item?.posProductResponse?.productPrices[0].type === discontinued && item?.posProductResponse?.qtyInStock < item?.qtyOrdered).map((item) => {
    //     const productTypeWise = productTypeConverter(item?.posProductResponse?.productPrices);
    //     return { ...item,...productTypeWise}
    //   });

    //   return {
    //     ...state,
    //     importQuotesItemsDetails: action.payload.data,
    //     qtyInStockDetails: qtyStockData,
    //     qtyOutOfStockDetails: outOfStockData,
    //     qtyDiscontinuedAvailableStock: qtyDiscontinuedAvailableStockData,
    //     qtyDiscontinuedOutOfStock: qtyDiscontinuedOutOfStockData,
    //     isImportQuotesItemsLoading: false,
    //   };
    // case constants.IMPORT_QUOTE_ITEMS_ERROR:
    //   return {
    //     ...state,
    //     importQuotesItemsDetails: [],
    //     qtyInStockDetails:[],
    //     importQuotesItemsError: action.error,
    //     isImportQuotesItemsLoading: false,
    //   };
    // case constants.IMPORT_QUOTE_ITEMS_CLEAR:
    //   return {
    //     ...state,
    //     importQuotesItemsDetails: [],
    //     qtyInStockDetails:[],
    //     importQuotesItemsError:"",
    //     isImportQuotesItemsLoading: false,
    //   };
    // // End import Quote Items
    case constants.ARR0W_INDEX_RESET_IMPORT_QUOTE:
      return { ...state, selectedIndex: -1 };
    case constants.ARR0W_DOWN_INDEX_IMPORT_QUOTE:
      if (state.selectedIndex >= action.payload - 1) {
        return {
          ...state,
        };
      } else if (state.selectedIndex >= action.payload) {

        return {
          ...state,
        };
      }
      return { ...state, selectedIndex: state.selectedIndex + 1 };
    case constants.ARR0W_UP_INDEX_IMPORT_QUOTE:
      if (state.selectedIndex === 0) {
        return {
          ...state,
        };
      }
      return { ...state, selectedIndex: state.selectedIndex - 1 }; 
    default:
      return state;

  }
};
export default reducer;
