import { Dispatch, SetStateAction } from "react";
import { Modal, Typography, Box } from "@material-ui/core";

import { PosButton } from "apps/pos/Common/uiCore";

import { ClearIcon } from "constants/icons";
import { Paths } from "enums";

import "apps/pos/PrivacySettings/privacySettings.scss";
import "apps/pos/poscommonstyling.scss";
import useStyles from "./style";

interface docsProps {
  creditMemoBase64String?: number | string;
  creditMemoUrl?: string;
  invoicePdfBase64String?: number | string;
  invoiceUrl?: string;
}
interface partiallyReturnOrdersDataProps {
  additionalQty?: number;
  backOrderID?: number;
  customerID?: number;
  docs?: docsProps;
  isSuccess?: boolean;
  message?: string;
  noSaleableQty?: number;
  orderID?: number;
  orderRmaID?: number;
  paidVia?: string;
  // currently not getting data so passing any type. WIll add once getting proper data
  paymentRef1?: any;
  replaceQty?: number;
  returnAmount?: number;
  returnQty?: number;
}

interface returnCartModalProps {
  leaveCartModalOpen?: boolean;
  setLeaveCartModalOpen?: Dispatch<SetStateAction<boolean>>;
  handleLeaveCartModal?: (string) => void;
  handleSkipCartModal?: () => void;
  handleGoToReturnCartModal?: () => void;
  isRetry?: boolean;
  partiallyReturnOrdersData?: partiallyReturnOrdersDataProps[];
}

const LeaveReturnCartModal = (props: returnCartModalProps) => {
  const {
    leaveCartModalOpen,
    setLeaveCartModalOpen,
    handleLeaveCartModal,
    isRetry,
    handleSkipCartModal,
    handleGoToReturnCartModal,
    partiallyReturnOrdersData,
  } = props;
  const classes = useStyles();

  const leaveProcess = `${partiallyReturnOrdersData?.length} order${
    partiallyReturnOrdersData?.length > 1 ? "s" : ""
  } ${
    partiallyReturnOrdersData?.length > 1 ? "were" : "was"
  } not processed. Are you sure you want to continue without processing them?`;
  const removeCart =
    "Are you sure you want to leave Returns Cart? All items in the cart will be removed.";

  return (
    <>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal} modal-btn-content-width common-modal-btn-style`}
          open={leaveCartModalOpen}
          onClose={() => setLeaveCartModalOpen(false)}
        >
          <Box>
            <div className={classes.paper}>
              <ClearIcon
                onClick={() => setLeaveCartModalOpen(false)}
                className={classes.closeIcon}
              />
              <div className={classes.headerWidth}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className={classes.header}
                >
                  {isRetry
                    ? "Returns processed partially"
                    : "Leave Returns Cart?"}
                </Typography>
                <Typography className={classes.innerdata}>
                  {isRetry ? leaveProcess : removeCart}
                </Typography>
              </div>
              <div className={classes.buttondiv}>
                <PosButton
                  onClick={() => {
                    if (isRetry) {
                      handleSkipCartModal();
                      setLeaveCartModalOpen(false);
                    } else {
                      setLeaveCartModalOpen(false);
                    }
                  }}
                  variant="contained"
                  className={classes.buttonfirst}
                  fontWeight
                >
                  {isRetry ? "Skip" : "Go Back"}
                </PosButton>
                <PosButton
                  onClick={() =>
                    isRetry
                      ? handleGoToReturnCartModal()
                      : handleLeaveCartModal(Paths.posNewOrder)
                  }
                  variant="contained"
                  color="primary"
                  className={classes.buttonseconds}
                  fontWeight
                >
                  {isRetry ? "Retry failed returns" : "Leave Return Cart"}
                </PosButton>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default LeaveReturnCartModal;
