import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Will remove 'any' once we get proper data from BE
export interface ProductStatusSliceI {
  updateProductsStatus: boolean;
  updateProductsStatusData: any;
  updateProductsStatusError: any;
}

const initialState: ProductStatusSliceI = {
  updateProductsStatus: false,
  updateProductsStatusData: null,
  updateProductsStatusError: null,
};

export const productsStatusSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    updateProductsStatusStart: (state) => {
      state.updateProductsStatusData = null;
      state.updateProductsStatus = true;
    },
    updateProductsStatusSuccess: (state, action: PayloadAction<any>) => {
      state.updateProductsStatusData = action.payload;
      state.updateProductsStatus = false;
    },
    updateProductsStatusError: (state, action: PayloadAction<any>) => {
      state.updateProductsStatusError = action.payload;
      state.updateProductsStatusData = null;
      state.updateProductsStatus = false;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  updateProductsStatusStart,
  updateProductsStatusSuccess,
  updateProductsStatusError
} = productsStatusSlice.actions;

export default productsStatusSlice.reducer;
