import moment from "moment";

import { TableColumn } from "components/TableColumn/TableColumn";

import { PaginationFormat } from "constants/enum";
import { getComparator } from "utils/helper";
import { returnFixedOrEmptyValue } from "utils/utils";
import { ArrowForwardIcon } from "constants/icons";

export const EnhancedTableBody = ({
  posPriceChangeHistory,
  stableSort
}) => {
  const { Asc, Calories } = PaginationFormat;

  return posPriceChangeHistory?.length ? (
    stableSort(posPriceChangeHistory, getComparator(Asc, Calories))?.map(
      (row, index) => {
        const { createdAt, orderId, takerName, vendorPrice, initialPosRetailPrice, finalPosRetailPrice } = row;
        const percentage = ((finalPosRetailPrice - (initialPosRetailPrice))/initialPosRetailPrice)  * 100 ;
        const val = percentage ? `${percentage > 0 ? "+" + percentage.toFixed(2) : percentage.toFixed(2)}%`: '0.00%';
        return (
          <div className="table-body-row-invoice" key={index}>
            <TableColumn className="inv-date-col" value={moment(createdAt, "DD-MM-YYYY").format("MM/DD/YY")} />
            <TableColumn className="customer-col-width" value={takerName || ""} />
            <TableColumn value={orderId || ""} />
            <TableColumn value={returnFixedOrEmptyValue(vendorPrice, true)} />
            <TableColumn value={returnFixedOrEmptyValue(initialPosRetailPrice, true) || `$0`} />
            <TableColumn className="text-center-icon">
              <ArrowForwardIcon className="font-small-icon" />
            </TableColumn>
            <TableColumn value={returnFixedOrEmptyValue(finalPosRetailPrice, true)} />
            <TableColumn>
              <span className={`${percentage && percentage > 0 ? "dark-mint-green-text-label" : "dark-red-color-text-label"}`}>
                {initialPosRetailPrice ? val : ''}
              </span>
            </TableColumn>
          </div>
        );
      }
    )
  ) : (
    <div className="sales-history-no-data-found">
      No Data Found
    </div>
  )
}