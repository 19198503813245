import { UserInviteRequest } from "../../models";
import serviceV2 from "../config2";
const AUTH_API_PATH = "/auth";

export function inviteUser(body: UserInviteRequest) {
  return serviceV2
    .post(`${AUTH_API_PATH}/portal/invite`, body)
    .then((response) => {
      return response?.data;
    })
}

export function resendInviteUser(personId: number, managerId: number) {
  return serviceV2
    .post(
      `${AUTH_API_PATH}/users/${personId}/portal/by/${managerId}/invite/resend`
    )
    .then((response) => {
      return response?.data;
    });
}

export function cancelInviteUser(personId: number) {
  return serviceV2
    .post(`${AUTH_API_PATH}/users/${personId}/portal/invite/rescind`)
    .then((response) => {
      return response;
    });
}

export function resetPassword(body: string) {
  return serviceV2
    .post(`${AUTH_API_PATH}/password/reset/request`, body)
    .then((response) => {
      return response?.data;
    });
}

export function newPassword(body: string) {
  return serviceV2
    .post(`${AUTH_API_PATH}/password/reset`, body)
    .then((response) => {
      return response?.data;
    });
}

export function newPasswordToken(personId: any) {
  return serviceV2
    .post(`${AUTH_API_PATH}/users/${personId}/password/reset/token`)
    .then((response) => {
      return response?.data;
    });
}
