import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";

import { updateCloseDate } from "services/quoteDetail/request";

export const useUpdateCloseDate = () => {
  const { mutate, isSuccess } = useMutation(
    async (payload: any) => {
      return updateCloseDate(payload);
    }
  );

  const setCloseDateParams = useCallback(
    (payload) => {
      mutate(payload);
    },
    [mutate]
  );

  return {
    setCloseDateParams,
    isCloseDateUpdateSuccess: isSuccess,
  };
};
