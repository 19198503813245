import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { TextField } from "@material-ui/core";

import { PosModel } from "apps/pos/Common/uiCore";
import texts from "apps/springboard/locales";
import { ButtonContainerSBbuttons } from "components";
import { IconButton } from "apps/springboard/Component/SBItems/elements";
import Button from "components/SPButton/Button";

import { likelihoodData } from "utils/data";
import { springBoardText } from "constants/constant";

import { UpdateModalContent } from "sagas/modal/actions";
import { setLikelyHoodComment, setQuoteDetail } from "sagas/quoteDetail/actions";
import { getLikelyHoodComment, getQuoteDetail, updateLikeliHoodComment, updateLikeliHoodOfClose } from "services";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";

import "./LikelihoodOfCloseForm.scss"
interface LikelihoodOfCloseFormData {
    percentage: number;
    comments: string;
}

interface ExpirationDateProps {
    jobQuoteID: number;
    likelihoodOfClose: number;
    likelyHoodComment: string;
    open: boolean;
    handleCloseModel: () => void;
    handleChangeSuccess:()=>void
}

export const LikelihoodOfCloseForm = (props: ExpirationDateProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [valueChanged, setValueChanged] = useState(false)
    const [likeliHoodFlag, setLikeliHoodFlag] = useState(
        props?.likelihoodOfClose ? props?.likelihoodOfClose / 20 : 0
    );
    const {
        jobQuoteID,
        likelyHoodComment,
        handleCloseModel,
        open,
        handleChangeSuccess
    } = props;
    const [comment, setComment] = useState(likelyHoodComment);
    const { selectPercentage , likelihoodOfClose , percentageRequired , comments : commentsLabel} = springBoardText;
    const { handleSubmit, formState} =
        useForm<LikelihoodOfCloseFormData>();

    const onSubmit = () => {
        setIsLoading(true)
        updateLikeliHoodComment(jobQuoteID, {
            comments: comment,
            jobQuoteID,
        }).then(() => {

            getLikelyHoodComment(jobQuoteID).then((res) => {
                dispatch(setLikelyHoodComment(res?.data));
            });
            dispatch(
                UpdateModalContent({
                    isOpen: false,
                    children: null,
                    fixedModal: false,
                    removePaddingBottom: false,
                    noMaxWidth: false,
                })
            );

            getQuoteDetail(jobQuoteID).then((res) => {
                dispatch(setQuoteDetail(res?.data));
            });
        });

        updateLikeliHoodOfClose(jobQuoteID, likeliHoodFlag * 20).then(() => {
            handleCloseModel();
            handleChangeSuccess();
            dispatch(snakbarShow({message : texts.likelihoodUpdated}));
            setIsLoading(false);
            setValueChanged(false);
        });
    };

    const calculateLines = (text) => {
        const lines = text.split('\n').length;
        return lines <= 5 ? lines : 5;
    };

    const cancelForm = () => {
            dispatch(
                UpdateModalContent({
                    children: null,
                    isOpen: false,
                    fixedModal: false,
                    removePaddingBottom: false,
                    noMaxWidth: false,
                })
            );
            handleCloseModel();
    }

    const handleKeyDown = (e) => {
        e.stopPropagation();
    }
    
    return (
        <Fragment>
            <PosModel
                open={open}
                onClose={handleCloseModel}
                className={`set-customer-code-modal`}
                dialogClassName={`set-customer-code-modal-container`}
                dialogHeader={
                    <div className="modal-header pl-4">
                        <span className="modal-title">
                            <div className="pt-2"><h3 className="f20-Inter-500-sab mb-0 mb-0">{likelihoodOfClose}
                            </h3></div>
                        </span>
                        <IconButton
                            name='close'
                            className="close-icon"
                            type='tertiary'
                            onClick={handleCloseModel}
                        />
                    </div>
                }>
                <form
                    className="mt-2"
                    id="estimatorForm"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <span className="inter-reg black_text form-field-title">{selectPercentage}</span>
                    <div className="tabs">
                        {
                            likelihoodData.map((item, index) => (
                                <div className='tab-button' key={'likelihood-' + item}>
                                    <input
                                        type="radio"
                                        id={'likelihood-' + item}
                                        name="likelihood-selection"
                                        checked={likeliHoodFlag !== null && likeliHoodFlag === index}
                                        value={item}
                                        onChange={() => 
                                            {
                                                setLikeliHoodFlag(index)
                                                setValueChanged(true)
                                            }
                                        }
                                        className='tab-radio-button visually-hidden'
                                    />
                                    <label
                                        className="tab"
                                        htmlFor={'likelihood-' + item}
                                    >
                                        {item}%
                                    </label>
                                </div>
                            ))
                        }
                    </div>
                    {formState.errors.percentage && <div>{percentageRequired}</div>}
                    <div onKeyDown={handleKeyDown}>
                        <div className="order-num-input ml-5">
                            <span className="form-field-title text-black">
                                {commentsLabel}
                            </span>
                        </div>
                        <div className="w-100">
                            <TextField
                                id={"comments"}
                                variant="filled"
                                multiline
                                name={"comments"}
                                value={comment}
                                onChange={(e) => {
                                    setComment((e.target as HTMLInputElement).value);
                                    setValueChanged(true)
                                }}
                                className="product-detail-search-input input-focus-styling scroll--input"
                                rows={calculateLines(comment)}
                                fullWidth
                                maxRows={5}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                        </div>
                    </div>

                    <ButtonContainerSBbuttons className="justify-content-end mt-4 gap-2">
                        <Button
                            label={'Cancel'}
                            loading={isLoading}
                            disabled={isLoading}
                            onClick={cancelForm}
                        />
                        <Button                          
                            label={'Save'}
                            type='primary'
                            loading={isLoading}
                            disabled={isLoading || !valueChanged}
                            onClick={handleSubmit(onSubmit)}
                        /> 
                    </ButtonContainerSBbuttons>
                </form>
            </PosModel>
        </Fragment>
    );
}