import { Box, Container, Drawer } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

import Button from "apps/pos/Common/Button/Button";
import { PosImage } from "apps/pos/Common/uiCore";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";
import images from "constants/images";
import { PosResponseProps, RootState } from "models";

import DeleteDraftComponent from "apps/pos/SavedOrdersFoundComponent/DeleteDraftComponent";
import { constantCustomerInfo } from "apps/pos/pages/customerInfo/constantHelper";
import "components/Table/table.scss";
import { drawerUseStyles } from "theme/GlobalStyles";

const DynamicTable = ({
  data,
  isLoading,
  getColumnHeaders,
  handleScroll,
  doesHaveMore,
  handleSearchCustomer,
  excludedColumns,
  clearCartIdAPI,
  customerDraftDelete,
  setCustomerDraftDelete,
  searchValue,
}) => {
  const columns = getColumnHeaders(data, excludedColumns);
  const { deleteDarkGrayIcon, deleteHoverDarkGrayIcon } = images;
  const tableRowRef = useRef<React.Ref<HTMLTableRowElement>>();
  const { selectedIndexGeneric } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );
  const classes = drawerUseStyles();
  const [deleteCartId, setDeleteCartId] = useState<any>({});
  const [deletePopUp, setDeletePopUp] = useState(false);
  const clearAllDraftAPI = () => {
    deletePopUp && setDeletePopUp(false);
  };

  const removeDraft = (draft) => {
    setDeleteCartId(draft);
    setDeletePopUp(true);
  };

  return (
    <>
      <section>
        <TableContainer
          component={Paper}
          className="customer-info-table-container"
        >
          <InfiniteScroll
            dataLength={data?.length || 0}
            next={handleScroll}
            height={600}
            hasMore={doesHaveMore}
            loader={false}
            scrollThreshold={0.9}
          >
            {isLoading ? (
              <ShapedSkeleton
                width={120}
                height={60}
                shape="0 0 120 44"
                fullWidth={true}
              />
            ) : (
              <>
                {data.length > 0 ? (
                  <Table
                    aria-label="simple table"
                    className="customer-table-styling"
                  >
                    <TableHead className="customer-draft-header">
                      <TableRow>
                        {columns.map((col) => (
                          <TableCell
                            key={col.key}
                            className="customer-info-table-title second-table-row"
                          >
                            <span className="customer-info-table-title-text">
                              {col.label}
                            </span>
                          </TableCell>
                        ))}
                        <TableCell className="customer-info-table-title second-table-row">
                          <span className="customer-info-table-title-text"/>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data.map((row, index) => (
                          <TableRow
                            className={`customer-table-data-row draft-data-row ${
                              index === selectedIndexGeneric
                                ? "selectedRow"
                                : ""
                            }`}
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                index === selectedIndexGeneric
                                  ? "lightgray"
                                  : "white",
                            }}
                            ref={tableRowRef[index]}
                            id={`tableRowRef${index}`}
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e) => {
                              if (
                                e.key === "Enter" &&
                                !document.activeElement.classList.contains(
                                  "button-focus-delete-draft"
                                )
                              ) {
                                handleSearchCustomer(row);
                              }
                            }}
                          >
                            {columns.map((col) => (
                              <TableCell
                                className="draft-customer-table-row-data-styling"
                                key={`${col.key}-${index}`}
                                onClick={() => handleSearchCustomer(row)}
                              >
                                <span>
                                  {col.format
                                    ? col.format(row[col.key])
                                    : row[col.key]}
                                </span>
                              </TableCell>
                            ))}
                            <TableCell key={`delete-${index}`}>
                              <div className="draft-customer-table-row-data-styling customer-table-row-hover-data d-flex h-100 ">
                                <button
                                  className="customer-table-row-remove hover-with-delete border-0 bg-transparent p-0 button-focus-delete-draft"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeDraft(row);
                                    setCustomerDraftDelete(true);
                                  }}
                                >
                                  <PosImage
                                    src={deleteDarkGrayIcon}
                                    alt="delete-icon"
                                    className="delet-order-img"
                                  />
                                  <PosImage
                                    src={deleteHoverDarkGrayIcon}
                                    alt="delete-icon"
                                    className="delet-order-hover-img"
                                  />
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                ) : !searchValue ? (
                  <div className="border-remove">
                    <div className="mt-5 text-center">
                      <PosImage
                        src={images.noDraftsIcon}
                        height={105}
                        width={186}
                        alt="add product"
                      />
                    </div>
                    <div>
                      <span className="int-20-reg d-block empty-state-fonts d-flex text-center">
                        {constantCustomerInfo.draftsEmptyState}
                      </span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div>
                      <span className="justify-content-center int-20-reg d-flex empty-state-fonts text-center">
                      {constantCustomerInfo.noDraftFound} "{searchValue}"
                      </span>
                    </div>
                  </>
                )}
              </>
            )}
          </InfiniteScroll>
        </TableContainer>
      </section>
      <Drawer
        anchor={"right"}
        open={customerDraftDelete}
        hideBackdrop={false}
        className={`${classes.drawer} main-drawer-classes default-drawer-style no-cart-drawer main-drawer-classes drawer-scrollbar-style customer-sec-delete-draft-drawer`}
        onClose={() => setCustomerDraftDelete(false)}
        id="cartDrawerContent"
      >
        <Box sx={{ width: 600 }} role="presentation">
          <Button
            classes="back-next-btn-small back-responsive-btn responsive-back-btn"
            onClick={() => setCustomerDraftDelete(false)}
            tabIndex={-1}
          >
            <i className="fas fa-arrow-left" />
          </Button>
        </Box>
        <Container className="modal-content-style modal-container-styling">
          <DeleteDraftComponent
            deleteAllPopUp={false}
            deleteCartId={deleteCartId}
            clearAllDraftAPI={clearAllDraftAPI}
            setDeletePopUp={setDeletePopUp}
            clearCartIdAPI={() => {
              clearCartIdAPI(deleteCartId);
            }}
            setCustomerDraftDelete={setCustomerDraftDelete}
          />
        </Container>
      </Drawer>
    </>
  );
};

export default DynamicTable;
