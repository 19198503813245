import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Button, MultilineTextArea } from "../../../components";

import { cannedResponsesRequest } from "../../../sagas/setting/actions";

import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";
import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import { RootState } from "../../../models";
import { cannedResponses } from "../../../models/setting";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const CannedResponseSchema = Yup.object().shape({
  adNote: Yup.string(),
  footNote: Yup.string().required("Foot note required"),
  autoTextQuotes: Yup.string().required("Text for quotes required"),
  autoTextTickets: Yup.string().required("Text for tickets required"),
  disclaimerForB2B: Yup.string().required("Disclaimer for b2b required"),
  disclaimerForCreditCard: Yup.string().required(
    "Disclaimer for card required"
  ),
});

export function CannedResponses({distributorId}) {

  const [cannedResponsesData, setCannedResponsesData] = useState<cannedResponses>();

  const { cannedResponses } = useSelector<RootState, any>((state) => state.setting);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(cannedResponsesRequest(distributorId));
  },[]);

  useEffect(() => {
    setCannedResponsesData(cannedResponses);
  }, [cannedResponses]);    

  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Canned Responses</div>
        <div className="devider2px" />

        <Formik
          initialValues={{
            adNote: cannedResponsesData?.adNote,
            footNote: cannedResponsesData?.quoteConfig?.footNote,
            autoTextQuotes: cannedResponsesData?.autoText?.forQuote,
            autoTextTickets: cannedResponsesData?.autoText?.forTicket,
            disclaimerForB2B: cannedResponsesData?.quoteConfig?.disclaimerForB2B,
            disclaimerForCreditCard: cannedResponsesData?.quoteConfig?.disclaimerForCreditCard,
          }}
          enableReinitialize
          validationSchema={CannedResponseSchema}
          onSubmit={() => {
            // required Later
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => {
            return (
              <form className="formContainer" id="addSignatureForm">
                <MultilineTextArea
                  textInputStyle="input-style mt-1"
                  id={"adNote"}
                  name={"adNote"}
                  onChange={handleChange}
                  value={values.adNote}
                  label={"Ad Note (optional)"}
                />
                {errors.adNote && touched.adNote ? (
                  <p className="validation-error-text">{errors.adNote}</p>
                ) : null}

                <MultilineTextArea
                  textInputStyle="input-style mt-1"
                  id={"adNote"}
                  name={"adNote"}
                  onChange={handleChange}
                  value={values.footNote}
                  label={"Footnote"}
                />
                {errors.footNote && touched.footNote ? (
                  <p className="validation-error-text">{errors.footNote}</p>
                ) : null}

                <div className="fs-16 text-black fw-600 mt-5">Auto Text</div>
                <MultilineTextArea
                  textInputStyle="input-style mt-1"
                  id={"adNote"}
                  name={"adNote"}
                  onChange={handleChange}
                  value={values.autoTextQuotes}
                  label={"Auto text for Quotes"}
                  className='mt-2'
                />
                {errors.autoTextQuotes && touched.autoTextQuotes ? (
                  <p className="validation-error-text">
                    {errors.autoTextQuotes}
                  </p>
                ) : null}

                <MultilineTextArea
                  textInputStyle="input-style mt-1"
                  id={"adNote"}
                  name={"adNote"}
                  onChange={handleChange}
                  value={values.autoTextTickets}
                  label={"Auto text for Tickets"}
                />
                {errors.autoTextTickets && touched.autoTextTickets ? (
                  <p className="validation-error-text">
                    {errors.autoTextTickets}
                  </p>
                ) : null}

                <div className="fs-16 text-black fw-600 mt-5">Disclaimers</div>

                <MultilineTextArea
                  textInputStyle="input-style mt-1"
                  id={"adNote"}
                  name={"adNote"}
                  onChange={handleChange}
                  value={values.disclaimerForB2B}
                  label={"Disclaimer for B2B"}
                  className='mt-2'
                />
                {errors.disclaimerForB2B && touched.disclaimerForB2B ? (
                  <p className="validation-error-text">
                    {errors.disclaimerForB2B}
                  </p>
                ) : null}

                <MultilineTextArea
                  textInputStyle="input-style mt-1"
                  id={"adNote"}
                  name={"adNote"}
                  onChange={handleChange}
                  value={values.disclaimerForCreditCard}
                  label={"Disclaimer for Credit Card"}
                />
                {errors.disclaimerForCreditCard &&
                touched.disclaimerForCreditCard ? (
                  <p className="validation-error-text">
                    {errors.disclaimerForCreditCard}
                  </p>
                ) : null}

                <div className="devider1px" />

                <Button
                  type={"submit"}
                  onClick={handleSubmit}
                  className="manage-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#jumper_modal"
                >
                  <span className="manage-button-txt">save changes</span>
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </PerfectScrollbar>
  );
}
