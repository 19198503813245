import "@fontsource/inter";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Container, PosButton, PosHelmet, PosPageHeading } from "apps/pos/Common/uiCore";
import { AddIcon } from "constants/icons";
import { Paths } from "enums";
import { RootState } from "models";
import { beforeUnloadCart, openAndPrintPdf } from "utils/helper";

import {
  clearLoadCartDetails,
  setCustomerAlertHide,
  setPosFlowData,
  shippingAddressSelectedData
} from "sagas/persistReducer/actions";
import { setJobNameForAll, setMarkAsQuoteReducer, setShippingInstructionNote, setStoreCreditAmout } from "sagas/pos/actions";

import "./neworder.scss";

const OrderCompleted = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { posFlowData } = useSelector<RootState, any>(
    (state) => state.persistReducer
  );

  const { checkInvoiceReload } = useSelector<RootState, any>(
    (state) => state.pos
  );

  const { priceVisible } = posFlowData || {};

  const printOrderInvoice = posFlowData?.orderCompleteData?.docs?.invoicePdfBase64;
  const orderCompleteId = posFlowData?.orderCompleteData?.orderId;

  const printInvoice = (data) => {
    openAndPrintPdf(data)
  };

  const handleKeyPress = (e) => {
    const { keyCode } = e;
    if (true) {
      if (keyCode === 73) {
        printInvoice(printOrderInvoice);
      } else if (keyCode === 78) {
        dispatch(clearLoadCartDetails());
        const data = {
          priceVisible: priceVisible,
        };
        dispatch(setPosFlowData(data));
        navigate(Paths.posNewOrder);
      }
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document?.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  useEffect(() => {
    if (!posFlowData?.orderCompleteData?.orderId || checkInvoiceReload) {
      navigate(Paths.posNewOrder);
      dispatch(clearLoadCartDetails());
    }
    const payload = {
      ...posFlowData,
      cartProducts: [],
      addInStorage: []
    }
    dispatch(setPosFlowData(payload));
    beforeUnloadCart([])
  }, []);
  return (
    <Container className="new-container mt-10">
      <PosHelmet title="POS" metaName="Admin page" content="Admin page" />
      <div className="order-complete">
        <div className="order-row">
          <div className="order-cell">
            <PosPageHeading
              heading="Order Complete"
              grayHeading
              className="mb-1"
            />
            <PosPageHeading heading={`ID #${orderCompleteId}`} blackHeading />
          </div>
          <div>
            <PosButton
              variant="contained"
              color="secondary"
              className="modal-select-default-btn ship-btn justify-content-start w300 mt-2 order-complete-print-invoice-btn btn"
              onClick={() => printInvoice(printOrderInvoice)}
              tabIndex={1}
            >
              <div className="d-flex justify-content-between w-100 align-items-center">
                <span className="d-flex align-items-center">
                  <i className="fa fa-print icn-btn mr-2"></i>Print Invoice
                </span>
                <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut">
                  I
                </span>
              </div>
            </PosButton>
          </div>
        </div>
        <div className="order-row btm-border">
          <div className="order-cell">
            <span className="int-20-reg-bold">
              <i className="fas fa-check-circle icn-btn"></i> Order placed and
              processed successfully.
            </span>
          </div>
          <div onKeyDown={(e) => handleKeyPress(e)}>
            <PosButton
              variant="contained"
              color="primary"
              className="modal-select-default-btn ship-btn-dark justify-content-start mt-2 order-complete-print-invoice-btn btn"
              tabIndex={2}
              onClick={() => {
                dispatch(clearLoadCartDetails());
                const data = {
                  priceVisible: priceVisible,
                  addDocument: [],
                  poNumber: "",
                  firstName: "",
                  lastName: "",
                  phone: "",
                  email: "",
                  companyName: "",
                };
                dispatch(setPosFlowData(data));
                dispatch(setShippingInstructionNote(""));
                dispatch(setJobNameForAll(""));
                dispatch(setMarkAsQuoteReducer(false));
                dispatch(setCustomerAlertHide(true));
                dispatch(setStoreCreditAmout(""));
                navigate(Paths.posNewOrder);
                dispatch(shippingAddressSelectedData(false))
              }}
            >
              <div className="d-flex justify-content-between w-100 align-items-center">
                <span className="text-white d-flex align-items-center">
                  <AddIcon className="mr-2" /> Start a New Order
                </span>
                <span className="keyboard-shortcut-pay keyboard-shortcut avatar-shortcut">
                  N
                </span>
              </div>
            </PosButton>
          </div>
        </div>
        <div className="order-row">
          <div className="order-cell">
            <span></span>
          </div>
          <div></div>
        </div>
      </div>
    </Container>
  );
};

export default OrderCompleted;
