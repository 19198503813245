import "@fontsource/inter";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";

interface FooterBlockProps {
  infoLabel?: string;
  infoLabelStyling?: string;
  onClick?: () => void;
  label?: string;
  mainClass?: string;
  value?: any;
  statusFlag?: any;
  tabIndex?: any;
  clickable?: any;
  notClickable?: any;
  hidden?: boolean;
}

const FooterBlock = (props: FooterBlockProps) => {
  const {
    label,
    value,
    statusFlag,
    tabIndex,
    clickable,
    onClick,
    notClickable,
    mainClass,
    infoLabel,
    infoLabelStyling,
    hidden,
  } = props;

  return notClickable ? (
    <div
      className={`footer-block ${
        clickable ? "clickable" : mainClass ? mainClass : ""
      }`}
    >
      {label && <span className="label label-with-icon">{label}</span>}

      {value && (
        <span
          className="value"
          dangerouslySetInnerHTML={{ __html: value }}
        ></span>
      )}
    </div>
  ) : (
    <div
      className={`footer-block ${clickable ? "clickable" : "disabled-block"} ${
        hidden ? "hidden-footerblock" : ""
      }`}
      tabIndex={tabIndex ? tabIndex : -1}
      onClick={onClick}
    >
      {label && (
        <span className="label label-with-icon">
          {label}
          {statusFlag && <MoneyOffIcon className="money-off-icon" />}
          <span className={infoLabelStyling}>{infoLabel}</span>
        </span>
      )}

      {value && (
        <span
          className={`value`}
          dangerouslySetInnerHTML={{ __html: value }}
        ></span>
      )}
    </div>
  );
};

export default FooterBlock;
