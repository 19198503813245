import { useFormik } from "formik";
import { userFormValidation } from "layouts/formik/validationSchema";
import { useState } from "react";
import { addFavoriteLink } from "services";

import { TargetValue } from "enums";
import { adminMessage } from "constants/message";
const AddBookmark = (props) => {
    const { personID, favoritesList, setFavoritesList, closeAllDialogs } = props
    const { self , blank  } = TargetValue;

    const initialValues = {
        linkName: document?.title,
        linkRoute: window?.location?.toString(),
        target: self
    };
    const [loader, setLoader] = useState(false);
    const [success , setSuccess] = useState(false);

    const formik = useFormik({
        initialValues,
        validationSchema: userFormValidation,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            setLoader(true);
            const reqBody = {
                personID: personID,
                linkName :values?.linkName,
                linkRoute : values?.linkRoute,
                target : values?.target
            }
            try {
                const responseData = await addFavoriteLink(personID,reqBody);
                setLoader(false)
                if (responseData && responseData?.data) {
                    setSuccess(true)
                    const newAdded = {
                        bookmarkID: responseData?.data,
                        ...reqBody
                    }
                    if (!favoritesList?.length) {
                        setFavoritesList([newAdded]);
                    } else {
                        setFavoritesList(prevFavoritesList => {
                            const newFavoritesList = [...prevFavoritesList]; // create a copy of the current array
                            newFavoritesList.unshift(newAdded); // add the new record at index 0
                            return newFavoritesList; // return the updated array
                          });
                    }
                    setTimeout(() => {
                        closeAllDialogs()
                    }, 1000);
                } 
            } catch (err) {
                setLoader(false)
                if(err.status === 500){
                    formik.setErrors({ linkRoute: adminMessage.urlInvalid });
                }else{
                    closeAllDialogs()
                }

            }
        }
    });
    const { values, handleSubmit, setFieldValue, touched, errors } = formik

    return (
        <>
            <header>
                <p className="dialog-title">Add Bookmark</p>
                <button className="icon-btn dialog-close-btn" type="button" onClick={closeAllDialogs}>
                    <span>Close</span>
                </button>
            </header>
            <form id="addBookmarkForm" onSubmit={handleSubmit}>
                <div className="add-bookmark-form">
                    <div className="form-input-group title-group">
                        <input
                            type="text"
                            name="linkName"
                            id="linkName"
                            autoComplete="off"
                            placeholder="Title"
                            value={values.linkName}
                            onChange={(e) => setFieldValue("linkName", e.target.value)}
                            autoFocus
                            className={errors.linkName ? "error" : ""}
                        />
                        {touched.linkName && errors.linkName ? (
                            <span className="form-input-message">
                                {errors.linkName}
                            </span>
                        ) : null}
                    </div>

                    <div className="form-input-group link-group">
                        <input
                            type="text"
                            name="linkRoute"
                            id="linkRoute"
                            inputMode="url"
                            autoComplete="off"
                            placeholder="Page url"
                            value={values.linkRoute}
                            onChange={(e) => setFieldValue("linkRoute", e.target.value)}
                            className={errors.linkRoute ? "error" : ""}
                        />
                        {touched.linkRoute && errors.linkRoute ? (
                            <span className="form-input-message">
                                {errors.linkRoute}
                            </span>
                        ) : null}
                    </div>

                    <div className="form-checkbox-group">
                        <input type="checkbox" name="target" id="target"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setFieldValue("target", blank)
                                } else {
                                    setFieldValue("target", self)
                                }
                            }}
                        />
                        <label htmlFor="target">Open in a new tab</label>
                    </div>

                    <div className="actions">
                        <button className={`add-bookmark-btn ${loader ? "btn-loading" : ( success ? "btn-success": "")}`} type="submit">Add</button>
                    </div>
                </div>
            </form>
        </>
    )
}
export default AddBookmark;