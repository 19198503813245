import { adminValidationMessage } from "constants/message";
import { object, ref, string } from "yup";

const { newPasswordReq, confirmPasswordReq, passwordMatch, userProfileNameReq, userProfileLastNameReq, userProfileEmailReq, userProfilePhoneReq } = adminValidationMessage;

const newPasswordValidateSchema = object().shape({
  newPassword: string()
    .required(newPasswordReq),
  confirmPassword: string()
    .required(confirmPasswordReq)
    .oneOf(
      [ref("newPassword"), null],
      passwordMatch
    ),
});

const userFormValidation = object().shape({
  userProfileName: string()
    .required(userProfileNameReq),
  userProfileLastName: string()
    .required(userProfileLastNameReq),
  userProfileEmail: string()
    .required(userProfileEmailReq),
  countryCode: string()
    .required(userProfilePhoneReq),
  userProfilePhone: string()
    .required(userProfilePhoneReq)
})

export {
  newPasswordValidateSchema,
  userFormValidation
};
