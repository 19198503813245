import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';

import { searchAccessoriesProduct } from '../request';
import { SearchAccessoriesProductsPayload } from '../types';

export const useSearchAccessories = () => {
  const { mutate, isLoading, data, error, isSuccess } = useMutation(
    async (payload: SearchAccessoriesProductsPayload) => {
      return searchAccessoriesProduct(payload);
    });

  const getSearchAccessoriesFn = useCallback(
    (payload: SearchAccessoriesProductsPayload) => {
      mutate(payload);
    },
    [mutate]
  );
  
  return {
    getSearchAccessoriesFn,
    searchLoading : isLoading,
    searchAccessories : data?.data ?? [],
    searchError : error,
    isSuccess,
  };
};