import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';

import { undoDeliveryUrl } from './constantUrl';
import { stopLoading } from 'utils/utils';

export const useUndoDelivery = () => {

  const { mutate, isLoading, data, isSuccess } = useMutation(
    async (payload) => {
      return undoDeliveryUrl(payload)
    },
    {onSuccess : stopLoading,
      onError : stopLoading
});

  const undoDeliveryFn = useCallback(
    (payload) => {
      mutate(payload)
    },[mutate]
  )
  return { undoDeliveryFn, undoDeliverySuccessData: data, undoDeliveryIsloading: isLoading, undoDeliveryIsSuccess: isSuccess };
};