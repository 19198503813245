import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  PosButton,
  PosIconButton,
  PosInput,
  PosModel,
  PosSelect,
} from "apps/pos/Common/uiCore";
import AddDocumentFor from "./AddDocumentFor";
import DocumentSelection from "./DocumentSelection";
import UploadDocumentComponent from "./UploadDocumentModal";

import { addressProofTypeId, snackbarDetail } from "constants/constant";
import { endpoints } from "constants/endpoint";
import {
  documentByModule,
  localStorageDataGet,
  locationType,
} from "constants/enum";
import { AddIcon, ClearIcon } from "constants/icons";
import { AddDocumentModalProps, PosFlowDataProp } from "constants/interfaces";
import { Paths } from "enums";
import { getLocalStoraGeItem } from "utils/utils";

import { AddDocumentSchema, UploadDeliveryProofSchema } from "apps/pos/formik/validationSchema";
import { PosResponseProps, RootState } from "models";

import { setPosFlowData } from "sagas/persistReducer/actions";
import { arrowResetGeneric, getCustomerRegionRequest, getDocumentTypesRequest, getShippingFacilitiesRequest } from "sagas/pos/actions";
import { customerDocumentRequest, taxDocumentRequest } from "sagas/pos/documentUpload/actions";
import serviceV2 from "services/config2";
import { setIsApiLoading } from "../../CustomerOrders/redux/customerOrder.slice";
import { useUploadDeliveryProof } from "../../CustomerStorage/hooks/useUploadDeliveryProof";
import { getDeliveredStorageItemDetail, getDeliverySuccessData, getHandlerId, getIsPendingOrderDelivery } from "../../CustomerStorage/redux/selector";

import "apps/pos/pages/customerInfo/Documents/addDocument/addDocument.scss";
import "apps/pos/pages/customerInfo/Documents/document.scss";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";

const AddDocumentModal = ({
  openModel,
  setOpenModel,
  locationDocument,
  taxExemption,
  documentBy,
  isOrderDocument,
  isDeliveryProofModal,
  deliveryProofPayload,
}: AddDocumentModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uploadDeliveryProofFn, isUploadDeliveryProofSuccess, uploadDeliveryProofIsLoading } = useUploadDeliveryProof();

  const isApiLoading = [uploadDeliveryProofIsLoading].includes(true)

  useEffect(() => {
    dispatch(setIsApiLoading(isApiLoading))
  }, [isApiLoading])


  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );

  const deliveryID = useSelector(getDeliverySuccessData)

  const handlerID = useSelector(getHandlerId)
  const isPendingOrderDelivery = useSelector(getIsPendingOrderDelivery);
  const deliveredStorageItemDetail = useSelector(getDeliveredStorageItemDetail)

  const { orderId, shipmentId, deliveryId, handlerId } = deliveredStorageItemDetail

  const [addDocument, setAddDocument] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [progress, setProgress] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [controller, setController] = useState(null);
  const [selectedValue, setSelectedValue] = useState('buyer');
  const [regionOptions, setRegionOptions] = useState([]);
  const { documentTypesDetails, shippingFacilities, uploadDocRegion } = useSelector<RootState, PosResponseProps>(
    (state) => state.pos
  );

  const [opnDocumentUpload, setOpenDocumentUpload] = useState(false);
  const { privateDoc } = documentByModule;

  const currentTakerInfoStorage = getLocalStoraGeItem(
    localStorageDataGet.taker
  );
  const takerId = currentTakerInfoStorage?.personId;

  const isGenericUser = useMemo(() => {
    return posFlowData?.customer ? posFlowData?.customer.isPosGeneric : false;
  }, [posFlowData]);

  const documentType = useMemo(() => {
    return documentBy === documentByModule.customerList
      ?
      documentByModule.taxExemptCertificate :
      taxExemption ? documentByModule.taxExemptCerti : documentByModule.addressProf
  }, [taxExemption]);

  useEffect(() => {
    dispatch(getDocumentTypesRequest(documentBy === documentByModule.customerList ? true : false));
    setAddDocument([]);

    dispatch(getShippingFacilitiesRequest(posFlowData?.customer?.customerId));
    dispatch(getCustomerRegionRequest(posFlowData?.customer?.customerId));
  }, []);

  useEffect(() => {
    if (isUploadDeliveryProofSuccess) {
      setOpenModel(false);
      setTimeout(() => {
        navigate(Paths.customer);
      }, 2500);
    }
  }, [isUploadDeliveryProofSuccess])

  const moveItemToTop = (data, typeId) => {
    const index = data.findIndex((item) => {
      return item.docTypeID === typeId;
    });

    if (index !== -1) {
      const item = data.splice(index, 1)[0];
      data.unshift(item);
    }
    return data
  }

  useEffect(() => {
    if (isGenericUser) {
      const finalPrivateDocs = moveItemToTop(documentTypesDetails, addressProofTypeId)
      setDocumentTypes(finalPrivateDocs);
    } else {
      setDocumentTypes(documentTypesDetails);
    }
  }, [documentTypesDetails]);

  useEffect(() => {
    setAddDocument(posFlowData.addDocument);
  }, [posFlowData.addDocument]);

  const handleUploadDeliveryProof = (values) => {
    const { file, description } = values;

    const finalUploadDeliveryProofPayload = isPendingOrderDelivery ? {
      ...deliveryProofPayload,
      deliveryID: deliveryID?.deliveryID ?? deliveryID?.data,
      managerID: handlerID,
      docDescription: description,
      file: file,
    } : {
      orderID: orderId,
      shipmentID: shipmentId,
      deliveryID: deliveryId,
      managerID: handlerId,
      docDescription: description,
      file: file,
    }

    const { docDescription, orderID, deliveryID: finalDeliveryId, shipmentID, managerID } = finalUploadDeliveryProofPayload

    const docDescriptionFormat = JSON.stringify(docDescription);

    const formData = new FormData();
    formData.append("OrderID", orderID);
    formData.append("DeliveryID", finalDeliveryId);
    formData.append("ShipmentID", shipmentID);
    formData.append("ManagerID", managerID);
    formData.append("DocDescription", docDescriptionFormat);
    formData.append("File", file[0]);

    uploadDeliveryProofFn(formData, finalDeliveryId)

  }

  const handleSubmitData = (values) => {
    if (documentBy === documentByModule.customerList) {
      uploadDocumentFromCustomerList(values);
      setOpenDocumentUpload(true);
    } else {

      if (isDeliveryProofModal) {
        handleUploadDeliveryProof(values)
      } else {
        const { file, description, documentSaveType } =
          values;
        const docFilteredData = documentTypes?.find(
          (data) => (data.docTypeName || data.companyDocTypeName) === documentSaveType
        );
        const objectUrl = URL.createObjectURL(file[0]);
        const data = {
          ...posFlowData,
          isOrderDocument: isOrderDocument ? isOrderDocument : false,
          addDocument: [
            ...addDocument,
            {
              file: file[0].name,
              description: description,
              typeId: docFilteredData?.docTypeID || docFilteredData?.companyDocTypeID,
              documentSaveType: privateDoc,
              locationSaveType: locationType.current,
              uploadedBy: takerId,
              size: file[0].size,
              customerId: posFlowData?.customer?.customerId,
              documentBy: documentBy,
              fileUrl: objectUrl,
              originalFile: file[0],
              documentSaveTypeName: docFilteredData?.docTypeName || docFilteredData?.companyDocTypeName,
              modifiedAt: new Date().toUTCString(),
              readOnly: false,
            },
          ],
        };
        const locationDocumentdata = {
          ...posFlowData,
          isOrderDocument: isOrderDocument ? isOrderDocument : false,
          addDocument: [
            ...addDocument,
            {
              file: file[0].name,
              description: description,
              typeId: docFilteredData?.docTypeID || docFilteredData?.companyDocTypeID,
              documentSaveType: privateDoc,
              locationSaveType: locationType.current,
              uploadedBy: takerId,
              size: file[0].size,
              customerId: posFlowData?.customer?.customerId,
              documentBy: documentBy,
              fileUrl: objectUrl,
              originalFile: file[0],
              documentSaveTypeName: docFilteredData?.docTypeName || docFilteredData?.companyDocTypeName,
              modifiedAt: new Date().toUTCString(),
              readOnly: false,
            },
          ],
        };
        dispatch(
          setPosFlowData(locationDocument ? locationDocumentdata : data)
        );
        setAddDocument([]);
        setOpenModel(false);
      }
    }

  }
  const initialValues = {
    file: [],
    description: "",
    typeId: 0,
    documentSaveType: documentType,
    locationSaveType: locationType.all,
    uploadedBy: 0,
    addedCompanyIDs: [],
    applyToRegion: 0
  }

  const formik = useFormik({
    initialValues,
    validationSchema: isDeliveryProofModal ? UploadDeliveryProofSchema : AddDocumentSchema,
    enableReinitialize: false,
    onSubmit: handleSubmitData
  });

  const { errors, touched, values, handleSubmit, setFieldValue } = formik;
  const uploadDocumentFromCustomerList = (values) => {
    const { file, description, documentSaveType, addedCompanyIDs, applyToRegion } =
      values;
    const docFilteredData = documentTypes?.find(
      (data) => (data.docTypeName || data.companyDocTypeName) === documentSaveType
    );

    let companies = [];

    if (selectedValue === 'shipping') {
      companies = shippingFacilities
        .filter(item => addedCompanyIDs.includes(item.companyID))
        .map(item => ({ "companyID": item.companyID, "stateID": item.stateId }));

    } else {
      companies = [{ "companyID": uploadDocRegion ? uploadDocRegion.companyID : 0, "stateID": applyToRegion }]
    }
    const preparedData = {
      companyStates: companies,
      isBuyerCompany: addedCompanyIDs?.length ? false : true,
      personID: posFlowData?.customer?.customerId,
      uploadFile: {
        file: null,
        docName: file[0].name,
        description: description,
        docTypeID: docFilteredData?.docTypeID || docFilteredData?.companyDocTypeID,
        uploadedBy: takerId
      }
    }
    const processDataFormat = JSON.stringify(JSON.stringify(preparedData));

    const formData = new FormData();
    formData.append("uploadCompanyDocsForMultipleLocationsRequest", processDataFormat);

    if (file) {
      formData.append("formFile", file[0]);
    }
    setProgress(0);
    setTimeRemaining(0);
    setStartTime(0);
    uploadFile(formData);
  }

  const uploadFile = async (formData) => {
    setStartTime(Date.now()); // Set the start time when the upload begins
    const newController = new AbortController();
    setController(newController);
    try {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        signal: newController.signal,
        onUploadProgress: function (progressEvent) {
          const { loaded, total } = progressEvent;
          const percent = Math.round((loaded * 100) / total)
          setProgress(percent) // hook to set the value of current level that needs to be passed to the progressbar
          // Calculate the elapsed time
          const elapsedTime = (Date.now() - startTime) / 1000; // Time in seconds
          const uploadSpeed = loaded / elapsedTime; // Bytes per second
          const remainingBytes = total - loaded;
          const estimatedTimeLeft = remainingBytes / uploadSpeed; // Time remaining in seconds

          setTimeRemaining(estimatedTimeLeft);
        }
      }
      await serviceV2
        .post(endpoints.uploadDocument, formData, config).then((response) => {
          if (response?.data && response.data?.data) {
            dispatch(snakbarShow({ message: snackbarDetail.uploadDoc }));
            setOpenModel(false);
            setOpenDocumentUpload(false);
            dispatch(customerDocumentRequest({
              customerId: Number(posFlowData?.customer?.customerId),
              buildingId: 0,
              orderId: 0,
            }))
            dispatch(taxDocumentRequest(Number(posFlowData?.customer?.companyID)));
            setTimeout(() => {
              setProgress(0);
            }, 1000);
          } else {
            resetProgress();
          }
        }).catch((error) => {
          console.error('Error uploading file API:', error);
          resetProgress();
        })
    } catch (error) {
      console.error('Error uploading file:', error);
      resetProgress();
    } finally {
      setController(null); // Reset the controller after the request is completed or canceled
    }
  };

  const resetProgress = () => {
    setProgress(0);
    setTimeRemaining(0);
    setStartTime(0);
    setOpenDocumentUpload(false);
  }
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Function to cancel the API request
  const cancelRequest = () => {
    if (controller) {
      resetProgress();
      controller.abort(); // Cancel the request
      setController(null);
    }
  };

  const addCompanyIds = (companyIds) => {
    setFieldValue("addedCompanyIDs", companyIds);
  }

  useEffect(() => {
    if (uploadDocRegion && uploadDocRegion.regions) {
      const regionOption = Object.entries(uploadDocRegion.regions).map(([key, value]) => ({
        label: key,
        value: value,
      }));
      setRegionOptions(regionOption);
    }
  }, [uploadDocRegion]);

  return (
    <>
      {
        opnDocumentUpload ?
          <UploadDocumentComponent openModel={opnDocumentUpload} isDeliveryProofModal={isDeliveryProofModal} progress={progress} timeRemaining={timeRemaining} cancelRequest={cancelRequest} fileName={values.file} />
          :
          <PosModel
            dialogClassName={`${isDeliveryProofModal ? 'delivery-proof-add-doc-modal' : ''} add-document-modal`}
            open={openModel}
            onClose={() => {
              setOpenModel(false);
              dispatch(arrowResetGeneric());
            }}
          >
            <div className="add-document-container">
              <PosIconButton
                className="clear-icon button-focus"
                onClick={() => setOpenModel(false)}
              >
                <ClearIcon />
              </PosIconButton>
              <div className="title-modal">{`${isDeliveryProofModal ? "Add Customer Signed Delivery Proof" : "Add Document"}`}</div>
              <form >
                <div className="form-scrollbar">
                  <div className="title-text font-weight-600">
                    Select a document or drop anywhere to add
                  </div>

                  <UploadComponent
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                  {
                    touched?.file && errors?.file ? <p className=" validation-error-text mt-2 int-error-text mb-0">
                      <span>{values.file?.length ? 'This file is too large, please select a file smaller than 100 MB' : 'Select a file'}</span>
                    </p> : null
                  }
                  {!isDeliveryProofModal ?
                    <>
                      <div className="title-text font-weight-600">
                        Document Type
                      </div>
                      <div className="arrow-black">
                        <PosSelect
                          name="documentSaveType"
                          className=" tabDropdown"
                          disabled={
                            taxExemption
                          }
                          onChange={(e) => {
                            setFieldValue("documentSaveType", e.target.value);
                          }}
                          value={values.documentSaveType}
                          defaultValue={values.documentSaveType}
                          options={
                            documentTypes?.length
                              ? documentTypes.map(({ docTypeName, companyDocTypeName }) => {
                                return {
                                  label: docTypeName || companyDocTypeName,
                                  value: docTypeName || companyDocTypeName,
                                };
                              })
                              : null
                          }
                        />
                      </div>
                      {
                        touched?.documentSaveType && errors?.documentSaveType ? <p className=" validation-error-text mt-2 int-error-text mb-0">
                          <span>Document Type is required</span>
                        </p> : null
                      }
                    </> : null
                  }
                  {
                    (documentByModule.taxExemptCertificate === values.documentSaveType || documentByModule.taxExemptCerti === values.documentSaveType) && documentBy === documentByModule.customerList ?
                      <>
                        <div className="title-text font-weight-600">
                          Add document for
                        </div>
                        <DocumentSelection selectedValue={selectedValue} handleRadioChange={handleRadioChange} />
                        {selectedValue === 'shipping' ?
                          <>
                            <div className="title-text font-weight-600">
                              Select Shipping Locations
                            </div>
                            <AddDocumentFor filteredOptions={shippingFacilities} addCompanyIds={addCompanyIds} preValue={values.addedCompanyIDs} />
                          </> : <><div className="title-text font-weight-600">
                            Apply to Region
                          </div>
                            <div className="arrow-black">
                              <PosSelect
                                name="applyToRegion"
                                className=" tabDropdown"
                                onChange={(e) => {
                                  setFieldValue("applyToRegion", e.target.value);
                                }}
                                value={values.applyToRegion}
                                defaultValue={values.applyToRegion}
                                options={
                                  regionOptions?.length
                                    ? regionOptions.map(({ label, value }) => {
                                      return {
                                        label: label,
                                        value: value,
                                      };
                                    })
                                    : null
                                }
                              />
                            </div></>
                        }
                      </> : null
                  }
                  <div className="title-text font-weight-600">
                    Description
                    <span className="phone-optional-head ml-3">(Optional)</span>
                  </div>
                  <div className="description-data mb-1">
                    <PosInput
                      name="description"
                      variant="filled"
                      id="filled-basic"
                      className="search-input-focus"
                      InputProps={{ disableUnderline: true }}
                      maxRows={1}
                      multiline
                      rows={5}
                      value={values.description}
                      onChange={(e) => {
                        setFieldValue("description", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="add-button">
                  <PosButton
                    disabled={isApiLoading}
                    className={isDeliveryProofModal ? "delivery-proof-upload-btn add-document-btn" : "delivery-proof-add-doc-btn add-document-btn"}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                    startIcon={isDeliveryProofModal && <AddIcon />}
                  >
                    {isDeliveryProofModal ? 'Upload' : 'Add Document'}
                  </PosButton>
                </div>
              </form>
            </div>
          </PosModel>
      }
    </>
  );
};

export default AddDocumentModal;

const UploadComponent = (props) => {
  const { setFieldValue, values } = props;
  const { file } = values;
  const dropzone = useDropzone({
    onDrop: (acceptedFiles) => {
      setFieldValue("file", acceptedFiles);
    },
    // multiple: false
  });

  useEffect(() => {
    if (!values.file?.length) {
      open()
    }
  }, [])

  const { getRootProps, getInputProps, open } = dropzone;
  return (
    <div>
      <div {...getRootProps({ className: "dropzone" })}>
        <div className="file-upload-main-input ">
          <input {...getInputProps()} />
          {file?.length ? (
            file.map((file, i) => <div key={i}>{file.name}</div>)
          ) : (
            <span>Choose file...</span>
          )}
        </div>
      </div>
    </div>
  );
};
// end
