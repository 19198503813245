import { useState } from "react";
import { Formik } from "formik";
import { InputAdornment } from "@material-ui/core";

import { PosButton, PosInput } from "apps/pos/Common/uiCore";

import { CheckIcon } from "constants/icons";
import { numberRegex, title } from "constants/constant";
import { blockInvalidChar } from "utils/utils";
// import texts from "apps/pos/locales";

import "./OrderTotalChangeModal.scss";
interface Props {
  finalTotal: string;
  isTotal: boolean;
  setTotal: (number) => void;
  total: string;
  handleSubmitChange: (number, string , boolean) => void;
  profit?:string
  modalRef?: React.Ref<HTMLDivElement>
}
const OrderTotalChangeModal = ({ handleSubmitChange, finalTotal, isTotal, total, modalRef }: Props) => {
  const [isReset , setIsReset] = useState(false)
  const {orderTotalTitel , resetButton , saveChanges } = title;
  //TODO: might need later
  // const validate = (values) => {
  //   const { orderTotal } = values
  //   const errors: { orderTotal ?:string} = {};
  //   const newPercentage = (Number(orderTotal) * Number(profit)) / Number(finalTotal);
  //   if (!isFinite(newPercentage) || newPercentage > 100) {
  //     errors.orderTotal = texts.orderTotalError
  //   }
  //   return errors;
  // };

  return (
    <Formik
      initialValues={{
        orderTotal: total,
      }}
      // validate={validate}
      validateOnChange
      enableReinitialize
      onSubmit={(value) => {
        handleSubmitChange(Number(value.orderTotal), finalTotal , isReset)
      }}
    >
      {({ handleSubmit, values, setFieldValue, errors }) => {
        const { orderTotal } = values
        const restockFeeOnChange = (e) => {
          if (
            !e.target.value ||
            numberRegex.test(e.target.value)
          ) {
            setFieldValue("orderTotal", e.target.value)
          }
        }
        const reset = () => {
          setIsReset(true)
          setFieldValue('orderTotal', finalTotal);
        }
        return (
          <div className="restocking-fee-modal" ref={modalRef}>
            <span className="input-title">{orderTotalTitel}</span>
            <div className="restocking-fee-amount-input-div">
              <div className="fee-amount-input-div">
                <div className="fee-amt-input-div">
                  <PosInput
                    variant="standard"
                    id="standard-basic"
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                    autoFocus
                    className="fee-amt-input"
                    name="orderTotal"
                    type='number'
                    value={orderTotal}
                    onKeyPress={(e) => blockInvalidChar(e)}
                    onChange={(e) => restockFeeOnChange(e)}
                    onWheel={(e) => {
                      e.target.blur()
                    }}
                  />
                </div>

              </div>
            </div>
            {errors.orderTotal ? <p className="validation-error-text mt-2 int-error-text mb-0">{`${errors.orderTotal}`}</p> :null}
            <div className="use-default-button-section flex-row-reverse">
              <PosButton
                variant="contained"
                color="primary"
                className="use-default-btn ms-auto btn"
                disabled={!orderTotal}
                startIcon={<CheckIcon />}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {saveChanges}
              </PosButton>
              {isTotal ? <PosButton
                variant="contained"
                color="secondary"
                className="use-default-btn btn"
                disabled={!orderTotal}
                onClick={() => reset()}
              >
                {resetButton}
              </PosButton> : null}
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default OrderTotalChangeModal;