import SkeletonWrapper from "components/ShapedSkeletonWrapper/SkeletonWrapper";

interface Props {
  onClick?: () => void;
  label: string;
  value: string | number;
  showSkeleton?: boolean;
  textColorBlack?: boolean;
  skeletonConfig?: {
    shape: string;
    width: number;
    height: number;
    radius: number;
  };
  isEdit?: boolean;
  valueClassName?: string;
  className?: string;
}

const HeaderSelection = ({
  onClick,
  label,
  value,
  showSkeleton,
  textColorBlack,
  skeletonConfig,
  isEdit,
  valueClassName,
  className,
}: Props) => {
  return (
    <>
      {isEdit ? (
        <button
          data-bs-toggle="modal"
          data-bs-target="#jumper_modal"
          data-bs-dismiss="#jumper_modal"
          className={`meta-form pr-space is-clickable border-0 text-start ${className}`}
          onClick={onClick}
        >
          <p className="status-title-color fs-10 inter-reg mb-1">{label}</p>
          {showSkeleton ? (
            <SkeletonWrapper {...skeletonConfig} />
          ) : (
            <div
              className={`${valueClassName} fw-medium fs-14 inter-med text-black txt-align-left ${
                textColorBlack ? "text-black" : "light-gray-text"
              }`}
            >
              {value}
            </div>
          )}
        </button>
      ) : (
          <div className="pr-space is-not-clickable">
            <p className="status-title-color fs-10 inter-reg mb-1">{label}</p>
            <div
              className={`fw-medium inter-med fs-14 text-black ${valueClassName}`}
            >
              {value}
            </div>
          </div>
      )}
    </>
  );
};

HeaderSelection.defaultProps = {
  skeletonConfig: {
    shape: "0 5 83 16",
    width: 83,
    height: 56,
    radius: 1,
  },
};

export default HeaderSelection;
