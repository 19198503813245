import { PosTooltip } from "./uiCore";

interface HandleLongDataProps {
    jobName: string | number,
    className?: string,
    longStringClass?: string,
}

const HandleLongData = (props: HandleLongDataProps) => {
    const {
        jobName,
        className,
    } = props;

    if (jobName?.toString().length > 20) {
        return (
            <PosTooltip title={jobName} placement="top">
                <span className={`job-name-limit cursor-pointer ${className ?? ''}`}>{jobName}</span>
            </PosTooltip>
        )
    }
    else {
        return <span className={`job-name-limit ${className ?? ''}`}>{jobName}</span>
    }
};

export default HandleLongData;
