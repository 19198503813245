import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import NotesDialog from "./NotesDialog/NotesDialog";
import { Toast, ToastContainer } from "components";
import { MiniNoteProps, QuoteNoteProps } from "apps/springboard/QuoteNote/type";

import { authorizationToken } from "utils/utils";

import {
  getEstimatorNotesRequest,
  getItemActionNotesRequest,
  getJobHighlightsRequest,
  getMajorRepsRequest,
  getNotesRequest,
  getFollowUpNoteRequest,
} from "sagas/quoteDetail/actions";
import { setOpenMiniNote } from "sagas/quoteDetail/quoteDetailsSlice";
import { getAlertMessage, getAlertShow, getIsErrorAlert, getIsEstimatorLoading, getIsInitialLoading, getIsItemActionLoading, getIsJobHighlightsLoading, getIsMajorRepsLoading, getNotesSelector, getQuoteEstimatorNotes, getQuoteFollowUpNotes, getQuoteItemActionNotes, getQuoteJobHighlights, getQuoteMajorReps } from "sagas/quoteDetail/selector";

import "styles/admin/springboard/notes.scss";


const Skeleton = () => {
  return (
    <div className="skeleton-notes">
      <div className="note-line" />
      <div className="note-line" />
    </div>
  );
};

function MiniNote({
  categoryName,
  highlighted = false,
  majorReps=false,
  notes,
  quoteId,
  followUpNotes = false,
  loading,
  noteLength = 0,
}: MiniNoteProps) {
  const { DefaultLoginId } = authorizationToken("Authorization") || {};
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddNote, setOpenAddNote] = useState(false);
  const dispatch = useDispatch();

  const openCloseNotes = () => {
    setOpenDialog(!openDialog);
    dispatch(setOpenMiniNote(false))
  };

  const openIcon = () => {
    setOpenDialog(true);
    setOpenAddNote(true);
  };

  return (
    <>
      <div onClick={openCloseNotes} className="mini-note">
        <header>
          <div className="title">
            {highlighted ? <MaterialIcon icon="bolt" size="24" /> : null}
            {majorReps ? <MaterialIcon icon="supervisor_account" size="24" /> : null}
            <h4>{categoryName}</h4>
          </div>
          <button onClick={openIcon} className="icon-button-notes">
            <MaterialIcon icon={followUpNotes ? "add" : "edit"} size="20" />
          </button>
        </header>
        <div className="note-main-content">
          <ul>
            {loading ? (
              <Skeleton />
            ) : followUpNotes ? (
              <>
                {Array.isArray(notes) && notes.map(({ posterID, notes }, index) => (
                  <li key={"mini_note" + posterID + index}> {notes} </li>
                ))}
              </>
            ) : typeof notes === 'string' ? (
              <li>{notes} </li>
            ) : null}
          </ul>
        </div>
      </div>
      {openDialog ? (
        <NotesDialog
          open={openDialog}
          notes={notes}
          category={categoryName}
          followUp={followUpNotes}
          close={openCloseNotes}
          quoteId={quoteId}
          handlerID={DefaultLoginId}
          highlighted={highlighted}
          noteLength={noteLength}
          openAddNote={openAddNote}
          setOpenAddNote={setOpenAddNote}
          setOpenMiniNote={setOpenMiniNote}
        />
      ) : null}
    </>
  );
}

export function QuoteNote({ quoteId }: QuoteNoteProps) {
  const dispatch = useDispatch();
  const alertShow = useSelector(getAlertShow)
  const alertMessage = useSelector(getAlertMessage)
  const isErrorAlert = useSelector(getIsErrorAlert)
  const quoteMajorReps = useSelector(getQuoteMajorReps)
  const isMajorRepsLoading = useSelector(getIsMajorRepsLoading)
  const quoteJobHighlights = useSelector(getQuoteJobHighlights);
  const isJobHighlightsLoading = useSelector(getIsJobHighlightsLoading);
  const getNotes = useSelector(getNotesSelector);
  const quoteItemActionNotes = useSelector(getQuoteItemActionNotes);
  const quoteEstimatorNotes = useSelector(getQuoteEstimatorNotes);
  const quoteFollowUpNotes = useSelector(getQuoteFollowUpNotes);
  const isEstimatorLoading = useSelector(getIsEstimatorLoading);
  const isInitialLoading = useSelector(getIsInitialLoading);
  const isItemActionLoading = useSelector(getIsItemActionLoading);
  useEffect(() => {
    // Initial Notes
    dispatch(getNotesRequest(quoteId));
    // Estimator Notes
    dispatch(getEstimatorNotesRequest(quoteId));
    // Item Action Notes
    dispatch(getItemActionNotesRequest(quoteId));
    // Job Highlights
    dispatch(getJobHighlightsRequest(quoteId));
    // Major Reps
    dispatch(getMajorRepsRequest(quoteId));
    //Follow-Up
    dispatch(getFollowUpNoteRequest(quoteId));
  }, [quoteId]);

  return (
    <>
      {alertShow && (
        <ToastContainer>
          <Toast
            children={alertMessage}
            status={isErrorAlert ? "Error" : "Success"}
          />
        </ToastContainer>
      )}

      <div className="quote-details-notes mini-notes mt-4">
        <MiniNote
          categoryName="Initial Notes"
          notes={getNotes}
          quoteId={quoteId}
          loading={isInitialLoading}
        />
        <MiniNote
          categoryName="Estimator Notes"
          notes={quoteEstimatorNotes}
          quoteId={quoteId}
          loading={isEstimatorLoading}
          noteLength={8000}
        />
        <MiniNote
          categoryName="Job Highlights"
          highlighted
          notes={quoteJobHighlights}
          quoteId={quoteId}
          loading={isJobHighlightsLoading}
          noteLength={500}
        />
        <MiniNote
          categoryName="Item Action Notes"
          notes={quoteItemActionNotes}
          quoteId={quoteId}
          loading={isItemActionLoading}
          noteLength={8000}
        />
        <MiniNote
          categoryName="Follow-up Notes"
          notes={quoteFollowUpNotes}
          quoteId={quoteId}
          followUpNotes
          loading={false}
        />
        <MiniNote
          categoryName="Major Reps"
          majorReps
          notes={quoteMajorReps}
          quoteId={quoteId}
          loading={isMajorRepsLoading}
          noteLength={500}
        />
      </div>
    </>
  );
}
