import { Modal, Typography, Box } from "@material-ui/core";

import { PosButton } from "apps/pos/Common/uiCore";

import { ClearIcon } from "constants/icons";

import "apps/pos/PrivacySettings/privacySettings.scss";
import "apps/pos/poscommonstyling.scss";
import useStyles from "./style";

const RemoveReturnCartOrder = ({
  removeOrder,
  setRemoveOrder,
  handleRemoveOrder,
  orderArrayValue,
  orderArrayId,
}) => {
  const classes = useStyles();
  return (
    <>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={removeOrder}
          onClose={() => setRemoveOrder(false)}
        >
          <Box>
            <div className={classes.paper}>
              <ClearIcon
                onClick={() => setRemoveOrder(false)}
                className={classes.closeIcon}
              />
              <div className={classes.headerWidth}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className={classes.header}
                >
                  Remove Order #{orderArrayId}
                </Typography>
                <Typography className={classes.innerdata}>
                  Are you sure you want to remove all items from Order #
                  {orderArrayId}?
                </Typography>
              </div>
              <div className={classes.buttondiv}>
                <PosButton
                  onClick={() => setRemoveOrder(false)}
                  variant="contained"
                  className={classes.buttonfirst}
                  fontWeight
                >
                  Keep
                </PosButton>
                <PosButton
                  onClick={() => handleRemoveOrder()}
                  variant="contained"
                  color="primary"
                  className={classes.buttonseconds}
                  fontWeight
                >
                  Remove {`${orderArrayValue?.length}`}{" "}
                  {`${orderArrayValue?.length > 1 ? "Items" : "Item"}`}
                </PosButton>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default RemoveReturnCartOrder;
