import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { getDeliverySlipUrl } from "./constantUrl";
import { stopLoading } from "utils/utils";

export const useGetDeliverySlip = () => {
  
  const { mutate, isLoading, data, error, isSuccess } = useMutation(
    async ({orderId, deliveryId}: {orderId: number; deliveryId: number}) => {
      return getDeliverySlipUrl(orderId, deliveryId);
    },
    {
      retry: false,
      onSuccess: stopLoading,
      onError:stopLoading
    },
  );

  const getDeliverySlip = useCallback(
    (orderId, deliveryId) => {
      mutate({orderId, deliveryId});
    },
    [mutate]
  );

  return {
    getDeliverySlip,
    getDeliverySlipIsLoading: isLoading,
    getDeliverySlipData: data,
    error,
    isSuccess,
  };
};
