import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ChangePasswordModal from "apps/admin/ChangePassword/ChangePassword";
import Button from "apps/admin/components/Elements/Button";
import TextInput from "apps/admin/components/Elements/TextInput";
import { newPasswordValidateSchema } from "apps/admin/formik/validationSchema";
import { ManageUserModal } from "apps/admin/ManageUserForm/ManageUserForm";
import { AuthenticatedUser, NewPasswordProps, RootState } from "models";

import {
  newPassword,
  newPasswordRequsetEnd,
  newPasswordToken
} from "sagas/authenticatedUser/actions";
import { UpdateModalContent } from "sagas/modal/actions";

import "./NewPasswordModal.scss";

function NewPasswordModal({ user, loginUser, isUser }: NewPasswordProps) {
  const [passwordsVisible, setPasswordsVisible] = useState(false);
  const dispatch = useDispatch();

  const { newPasswordSave, newPasswordTokenSave, isSetPassword } = useSelector<
    RootState,
    AuthenticatedUser
  >((state) => state.authenticatedUser);

  useEffect(() => {
    dispatch(newPasswordToken(user?.personId));
  }, [user]);


  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const handleChangePassword = () => {
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        classModal: "change-password-dialog",
        children: (
          <ChangePasswordModal
            isUser={isUser}
            loginUser={loginUser}
            user={user}
            onSuccess={() => {
              dispatch(
                UpdateModalContent({
                  isOpen: false,
                  children: null,
                  fixedModal: true,
                  removePaddingBottom: false,
                  noMaxWidth: false
                })
              );
            }}
          />
        ),
        fixedModal: false,
        removePaddingBottom: true,
        noMaxWidth: false
      })
    );
  };
  const handleSuccessPassword = () => {
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        classModal:"user-profile-dialog",
        children: (
          <ManageUserModal
            isUser={isUser}
            loginUser={loginUser}
            user={user}
            isApproval={false}
            currentTab={2}
          />
        ),
        fixedModal: true,
        removePaddingBottom: false,
        noMaxWidth: false
      })
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema: newPasswordValidateSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      dispatch(
        newPassword({
          personId: user?.personId,
          password: values.confirmPassword,
          token: newPasswordTokenSave,
        })
      );
    },
  });

  useEffect(() => {
    if (newPasswordSave) {
      handleSuccessPassword();
      dispatch(newPasswordRequsetEnd());
    }
  }, [newPasswordSave]);

  const handleKeyDown = (e) => {
    e.stopPropagation()
  }

  const { errors, touched, values, handleSubmit, handleChange } = formik;
  return (
    <Fragment>
      <header className="dialog-header">
        <h3 className="dialog-title">
          Set new password
        </h3>
      </header>

      <form onSubmit={handleSubmit}>
        <div className="dialog-body" onKeyDown={(e) => handleKeyDown(e)}>
          <PasswordInput
            id="newPassword"
            name="newPassword"
            autofocus={true}
            label="New Password"
            passwordsVisible={passwordsVisible}
            setPasswordsVisible={setPasswordsVisible}
            hasError={touched.newPassword && errors.newPassword}
            description={errors.newPassword}
            value={values.newPassword}
            onChange={handleChange}
          />

          <PasswordInput
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            passwordsVisible={passwordsVisible}
            setPasswordsVisible={setPasswordsVisible}
            hasError={touched.confirmPassword && errors.confirmPassword}
            description={errors.confirmPassword}
            value={values.confirmPassword}
            onChange={handleChange}
          />
        </div>

        <footer className="dialog-footer">
          <Button
            label="Back"
            secondary="true"
            onClick={handleChangePassword}
          />
          <Button
            type="submit"
            label="Change Password"
            accent="true"
            classes={`${isSetPassword ? 'button-is-loading' : ""}`}
            disabled={isSetPassword}
            onClick={handleSubmit}
          />
        </footer>
      </form>
    </Fragment>
  );
}

export default NewPasswordModal;

const PasswordInput = (props) => {
  const { id, name, label, passwordsVisible, setPasswordsVisible, description, hasError, value, onChange, autofocus } = props;

  const togglePasswordVisibility = () => {
    setPasswordsVisible(current => !current);
  }
  return (
    <TextInput
      id={id}
      name={name}
      type={passwordsVisible ? "text" : "password"}
      label={label}
      buttonIcon={passwordsVisible ? "visibility" : "visibility_off"}
      buttonLabel={passwordsVisible ? "Hide password" : "Show password"}
      buttonClasses={passwordsVisible ? "show-password-button-active" : "show-password-button-inactive"}
      wide="true"
      autofocus={autofocus}
      onButtonClick={togglePasswordVisibility}
      description={description}
      hasError={hasError}
      value={value}
      onChange={onChange}
    />
  )
}