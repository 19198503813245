import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import "@fontsource/inter";

import {
  PosHelmet,
  PosIconButton,
  PosImage,
  PosPageHeading,
  Paper,
  Container,
} from "apps/pos/Common/uiCore";
import { EnhancedTableHead } from "./PosPriceChangeHistoryComponent/EnhancedTableHead";
import { EnhancedTableBody } from "./PosPriceChangeHistoryComponent/EnhancedTableBody";
import { TablePaginationActions } from "./PosPriceChangeHistoryComponent/TablePaginationActions";
import { useStyles } from "./PosPriceChangeHistoryComponent/data";

import { ArrowBackRoundedIcon } from "constants/icons";
import images from "constants/images";
import { PosResponseProps, RootState } from "models";
import { PosFlowDataProp } from "constants/interfaces";
import { Paths } from "enums";
import { keys } from "utils/helper";

import {
  addToCartDrawer,
  getPosPriceChangeHistoryRequest,
  stockByWarehouseStart,
} from "sagas/pos/actions";

import "../pos/InvoiceHistory.scss";

const PosPriceChangeHistory = () => {
  const { posFlowData, loadCartDetails} = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { posPriceChanagHistory, posPriceChanagHistoryTotal, isLoading  , productsData} = useSelector<
    RootState,
    PosResponseProps
  >((state) => state.pos);
  const { customer } = posFlowData || {};
  const { personId, customerId } = customer || {};

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productIdInfo = useParams();

  const { state , pathname } = location;
  const productInfo = state?.detail;
  const productName = productInfo?.productName;

  const isLeapFrongReport = (pathname === `${Paths.product}/${productIdInfo.id}/${Paths.reports}/${Paths.posPriceChangeReport}`)
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('productName');

  useEffect(() => {
    const data = {
      customerId :  isLeapFrongReport ? 0 : personId || customerId,
      productId: productIdInfo.id,
      page: page + 1,
      size: rowsPerPage,
    };
    dispatch(getPosPriceChangeHistoryRequest(data));
  }, [page, rowsPerPage]);

  // start
  function stableSort(invoiceHistoryInfo, comparator) {
    if (posPriceChanagHistory) {
      const stabilizedThis =
        invoiceHistoryInfo && invoiceHistoryInfo?.map((el, index) => [el, index]);
      stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis && stabilizedThis?.map((el) => el[0]);
    }
  }

  // end
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const { defaultIcon } = images;

  const backToCartPage = () => {
    const { cartId } = loadCartDetails
    const { productId } = productsData
    dispatch(stockByWarehouseStart([Number(productIdInfo.id)]));
    if(productId) {
      dispatch(addToCartDrawer(true))
    }
    navigate(`${Paths.posCart}/${cartId}`);
  }

  const handleKeyPressDown = (e) => {
    const { keyCode } = e;
    const { esc } = keys;
    const { cartId } = loadCartDetails
      if (keyCode === esc && cartId) {
        backToCartPage();
      }
    }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPressDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPressDown);
    };
  }, [loadCartDetails, productsData]);


  return (
    <div className="history-main-section">
      <PosHelmet title={!isLeapFrongReport ? "POS" : `Product Detail - ${isLeapFrongReport ? paramValue : productName} - ${productIdInfo.id}` } metaName="Admin page" content="Admin page" />
      <section className="item-history-section">
          <div className={`item-history-container ${isLeapFrongReport ? "ml-90" : ""}`}>
          {!isLeapFrongReport ?  <PosIconButton
                className="back-button pos-icon-back-btn"
                onClick={() => {
                  backToCartPage()
                }}
                tabIndex={-1}
              >
                <ArrowBackRoundedIcon className="back-icon" />
              </PosIconButton>: null}
            <div className="item-history-title-container">
              <div className="title">
              <PosPageHeading heading="POS Price Change History"/>
              </div>
              <div className="item-history-products-container">
                <div className="product-ledger-image">
                  <PosImage
                    src={defaultIcon}
                    alt="product-icon"
                  />
                  <div className="product-ledger-name">
                  <div className="name">{isLeapFrongReport ? paramValue : productName}</div>
                    <div className="key">{productIdInfo.id}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      <Container maxWidth={false} className="table-main-container">
          <Paper className="invoice-history-table-section">
            <div
              className={`${classes.table} invoice-history-table-data`}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead />
              {isLoading ? (
                <></>
              ) : (
                <EnhancedTableBody
                posPriceChangeHistory={posPriceChanagHistory}
                  stableSort={stableSort}
                />
              )}
            </div>
            <TablePagination
              component="div"
              count={posPriceChanagHistoryTotal ? posPriceChanagHistoryTotal : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              rowsPerPageOptions={[]}
            />
          </Paper>
      </Container>
    </div>
  );
};

export default PosPriceChangeHistory;
