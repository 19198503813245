import { Popover } from "@material-ui/core";
import React, { Dispatch, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ManageUserModal } from "components";
import Notepad from "components/Notepad";

import {
  MAX_RECENT_SEARCH_COUNT,
  desiredPattern,
  searchApiUrlSuffixes,
  tabNamesList,
} from "constants/constant";
import { PaginationFormat, hashRoute, statusCode } from "constants/enum";
import { supportLink } from "constants/staticData";
import { QAStatus } from "enums";
import { Distributor, RootState, Tenant, user } from "models";
import {
  beforeUnloadCart,
  getRecentSearches,
  keys,
  saveRecentSearches,
} from "utils/helper";

import { UpdateModalContent } from "sagas/modal/actions";
import { setPosFlowData } from "sagas/persistReducer/actions";
import {
  searchApiUrlSuffix,
  universalSearch,
} from "services/user/user.service";

import "layouts/css/nav.scss";
import "layouts/css/search.scss";

interface HeaderProps {
  setter: (item: any) => void;
  handleToggleSidebar?: (value: boolean) => void;
  collapsed?: boolean;
  currentUserData?: {
    fullName: string;
    email: string;
  };
  setCurrentUserData?: Dispatch<any>;
}

export function Header({
  handleToggleSidebar,
  collapsed,
  setCurrentUserData,
  currentUserData,
}: HeaderProps) {
  const { loggedInUser } = useSelector<RootState, user>((state) => state.user);

  useEffect(() => {
    setCurrentUserData(loggedInUser);
  }, [loggedInUser]);

  const [searchData, setSearchData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [visible, setVisible] = useState(false);

  const { posFlowData } = useSelector<RootState, any>(
    (state) => state.persistReducer
  );
  const distributor = useSelector<RootState, Distributor>(
    (state) => state.distributor
  );
  const tenant = useSelector<RootState, Tenant>((state) => state.tenant);

  const routes = useLocation();
  const { pathname } = routes;
  const dropdownOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const dropdownClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { cartProducts } = posFlowData || {};

  const clearCart = () => {
    beforeUnloadCart([]);
    const isMatch = desiredPattern.test(pathname);
    if (isMatch && cartProducts && cartProducts.length > 0) {
      const data = {
        ...posFlowData,
        isLogoutClick: true,
      };
      dispatch(setPosFlowData(data));
    } else {
      const pathName = window.location.pathname;
      const data = {
        ...posFlowData,
        cartProducts: [],
        addInStorage: [],
        poNumber: "",
        customer: {},
        savedCart: {},
        schedule: {},
        taker: {},
        pickOrderQuantity: [],
        pickQuantityArr: [],
        finalQtyArrayInfoData: [],
        shippingCharge: { shippingAmt: 0, shippingAmtTax: 0 },
        isLogoutClick: false,
      };
      dispatch(setPosFlowData(data));
      localStorage.removeItem("returnRecentUser");
      localStorage.removeItem("returnTaker");
      if (window.location.pathname.includes("pos/cart")) {
        window.location.href = `${window.location.origin}/sso/logout?r=/app/pos/neworder`;
      } else {
        window.location.href = `${window.location.origin}/sso/logout?r=${pathName}`;
      }
    }
  };

  const profileOpen = () => {
    dropdownClose();
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        navigate: false,
        classModal: "user-profile-dialog",
        children: (
          <ManageUserModal
            isUser={true}
            user={currentUserData}
            loginUser={currentUserData}
            isApproval={false}
            fromHeader={true}
          />
        ),
        modalSize: "xl",
        fixedModal: true,
        removePaddingBottom: false,
        noMaxWidth: false,
      })
    );
  };

  // Start Search Logic
  const { One, Twenty } = PaginationFormat;
  const { enter, esc, q } = keys;
  const [isSearchFocus, setIsSearchFocus] = useState<boolean>(false); // Focuse set for search
  const [searchResult, setSearchResult] = useState([]); // Display Table Result
  const [searchLoader, setSearchLoader] = useState<boolean>(false); // Loader set for Search
  const [searchText, setSearchText] = useState<string>(""); // Search text store
  const [searchQuery, setSearchQuery] = useState<string>(""); // Search text store For API call
  const [currentSearchTab, setCurrentSearchTab] = useState(""); //Current select tab from search data result
  const [universalSearchPage, setUniversalSearchPage] = useState<number>(One); // Lazy Loading Page Number
  const [moreUniversalSearchAvailable, setMoreUniversalSearchAvailable] =
    useState<boolean>(false); // Store that next Pagination is there or not

  const location = useLocation();
  const hasHash = location.hash;

  let scrollPos: any = 0;
  const scrollTopRef = useRef<HTMLDivElement>(null);
  const [recentSearches, setRecentSearches] = useState<string[]>(); // Store recent search in this variable

  const searchRefHeader = useRef(null); // Ref for search textbox
  const searchBackRefHeader = useRef(null); // Ref for search back button
  const navigate = useNavigate();
  useEffect(() => {
    const data = getRecentSearches();
    if (data) {
      setRecentSearches(JSON.parse(data));
    }

    document?.addEventListener("keydown", (e) => {
      const { keyCode } = e;
      if (e.repeat) return;
      // When we press ctrl+q then set focus on search and hide the background
      if (e.ctrlKey && keyCode == q) {
        searchRefHeader.current?.click();
      }

      // click on ESC key then hide search
      if (keyCode === esc) {
        searchBackRefHeader.current?.click();
      }
    });
    return function cleanup() {
      document?.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (hasHash === hashRoute.search) {
      if (searchRefHeader.current) {
        searchRefHeader.current?.focus();
      }
      setIsSearchFocus(true);
    } else {
      setIsSearchFocus(false);
    }
  }, [hasHash]);

  // When search open disable scroll
  const disableScroll = () => {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
    document.documentElement.style.overflowY = "scroll";
    document.documentElement.style.scrollBehavior = "smooth";

    // save current scroll position
    scrollPos = document.body.style.top;
  };

  // When search hide enable scroll
  const enableScroll = () => {
    document.body.style.position = "";
    document.body.style.top = "";
    document.body.style.width = "";
    document.documentElement.style.overflowY = "";
    document.documentElement.style.scrollBehavior = "auto";

    // restore scroll position
    window.scrollTo({
      top: parseInt(scrollPos || "0") * -1,
      left: 0,
      behavior: "auto",
    });
  };

  const setDefaultStatus = () => {
    setSearchData([]);
    setSearchResult([]);
    setSearchLoader(false);
    setCurrentSearchTab("");
    setMoreUniversalSearchAvailable(false);
    setUniversalSearchPage(One);
  };

  // When i click on search text box : call this function
  const clickedOnSearch = (e) => {
    e.preventDefault();
    // enable focus and disable the scroll
    setIsSearchFocus(true);
    disableScroll();
    navigate({ hash: hashRoute.search }); //append hash in route
    setDefaultStatus(); // Reset all state variable
  };

  // When search is open and click back icon then this function call
  const back = () => {
    //Remove #search
    navigate({ hash: "" });
    // disable focus and enable the scroll
    setIsSearchFocus(false);
    if (searchRefHeader.current) {
      searchRefHeader.current.blur();
    }
    enableScroll();
    // Remove search Data
    setSearchData([]);
    setDefaultStatus(); // Reset all state variable
  };

  // add search query to the beginning of recentSearches
  const addToRecentSearches = (query: string) => {
    let newSearch = [];
    // add item to the beginning of recentSearches
    if (recentSearches?.length) {
      const currentSearch = recentSearches;
      currentSearch.unshift(query);
      // limit recentSearches to MAX_RECENT_SEARCH_COUNT;
      if (currentSearch.length > MAX_RECENT_SEARCH_COUNT) {
        currentSearch.splice(MAX_RECENT_SEARCH_COUNT);
      }
      // keep only unique values in recentSearches
      newSearch = [...new Set(currentSearch)];
    } else {
      newSearch = [...new Set([query])];
    }

    // save to recentSearches State
    setRecentSearches(newSearch);

    // save to sessionStorage
    saveRecentSearches(newSearch);
  };

  const deleteAllRecentSearches = () => {
    // remove all recentSearches
    setRecentSearches([]);

    // save to sessionStorage
    saveRecentSearches([]);

    return recentSearches;
  };

  const handleKeyPress = (event) => {
    const { keyCode } = event;
    if (keyCode === enter) {
      if (searchText) {
        addToRecentSearches(searchText);
      }
      setSearchQuery(searchText);
      // API call function
      universalSearchApiCall();
    }
    if (keyCode !== esc) {
      event.stopPropagation();
    }
  };

  const recentSearchesClick = (text) => {
    setSearchText(text);
    setIsSearchFocus(true);
    setSearchQuery(text);
    // API call function
    universalSearchApiCall(text);
  };

  const scrollBackToTop = () => {
    if (scrollTopRef.current) {
      scrollTopRef.current.scrollTop = 0;
    }
  };

  const onChangeSearch = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData([]);
    }
  }, [searchText]);

  const getUniversalData = (obj) => {
    setSearchLoader(true); // when we add api call then true
    universalSearch(obj).then((data) => {
      setSearchLoader(false);
      if (searchRefHeader.current) {
        searchRefHeader.current.blur();
      }
      if (data?.data) {
        setSearchData([data.data]);
      } else {
        if (data.status === statusCode.notFound) {
          setSearchData([tabNamesList]);
        }
      }
    });
  };
  const universalSearchApiCall = (text?: string) => {
    const obj = {
      page: One,
      size: Twenty,
    };

    if (text) {
      obj["query"] = text;
      getUniversalData(obj);
    } else {
      if (!searchText) {
        setSearchData([]);
      } else {
        obj["query"] = searchText;
        getUniversalData(obj);
      }
    }
  };

  const searchTab = (e, key) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentSearchTab(key);
    setUniversalSearchPage(One);
    scrollBackToTop();
  };

  //when currentTab  set or change than that data store in searchResult
  useEffect(() => {
    if (searchData.length) {
      const keyData = searchData[0][currentSearchTab];
      if (keyData) {
        setSearchResult(keyData?.data);
        setMoreUniversalSearchAvailable(keyData?.isPaginated);
      } else {
        setSearchResult([]);
        setMoreUniversalSearchAvailable(false);
      }
    } else {
      setSearchResult([]);
      setMoreUniversalSearchAvailable(false);
    }
  }, [currentSearchTab]);

  //Search Lazy Loading

  useEffect(() => {
    (async () => {
      if (
        moreUniversalSearchAvailable &&
        searchQuery &&
        universalSearchPage !== One
      ) {
        const body = {
          page: universalSearchPage,
          size: Twenty,
          query: searchQuery,
        };
        const response = await searchApiUrlSuffix(
          searchApiUrlSuffixes[currentSearchTab],
          body
        );
        if (response?.data) {
          const { data, isPaginated } = response.data;
          const previousSearch = [...searchResult, ...data];
          setMoreUniversalSearchAvailable(isPaginated);
          setSearchResult(previousSearch);
        } else {
          setMoreUniversalSearchAvailable(false);
        }
      }
    })();
  }, [universalSearchPage]);

  useEffect(() => {
    if (!searchResult) return;

    const observerOptions = {
      root: document.querySelector(".body"),
      rootMargin: "0px",
      threshold: 0,
    };
    // watch all tips when they enter the viewport
    const watchTips = (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        setUniversalSearchPage((prev) => prev + 1);
      });
    };

    // observe the tips footer to lazily load more available tips
    const tipsObserver = new IntersectionObserver(watchTips, observerOptions);

    const tipItems = document.querySelectorAll(".more-results");
    tipItems.forEach((item) => {
      tipsObserver.observe(item);
    });

    // cleanup
    return () => {
      tipsObserver.disconnect();
    };
  }, [searchResult]);

  const removeTags = (title) => {
    const div = document.createElement("div");
    div.innerHTML = title;
    const text = div.textContent || div.innerText || "";
    return text.trim();
  };

  const openNotepad = () => {
    setVisible(c => !c);
  }
  return (
    <>
      <nav
        className="topnav navbar navbar-expand justify-content-between justify-content-sm-start navbar-light bg-white"
        id="sidenavAccordion"
      >
        <header className="master-header">
          <div className="header-wrapper">
            <div className={`header-left-block ${isSearchFocus ? "hidden" : ""}`}>
              <button
                type="button"
                className="sidenav-switch"
                onClick={() => handleToggleSidebar(!collapsed)}
                title="Expand sidebar"
              >
                {!collapsed ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="sidenav-switch-icon sidenav-switch-expand"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="sidenav-switch-icon sidenav-switch-collapse"
                    enableBackground="new 0 0 24 24"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0,0h24v24H0V0z" fill="none" />
                    <path d="M4,18h11c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H4c-0.55,0-1,0.45-1,1v0C3,17.55,3.45,18,4,18z M4,13h8c0.55,0,1-0.45,1-1v0 c0-0.55-0.45-1-1-1H4c-0.55,0-1,0.45-1,1v0C3,12.55,3.45,13,4,13z M3,7L3,7c0,0.55,0.45,1,1,1h11c0.55,0,1-0.45,1-1v0 c0-0.55-0.45-1-1-1H4C3.45,6,3,6.45,3,7z M20.3,14.88L17.42,12l2.88-2.88c0.39-0.39,0.39-1.02,0-1.41l0,0 c-0.39-0.39-1.02-0.39-1.41,0l-3.59,3.59c-0.39,0.39-0.39,1.02,0,1.41l3.59,3.59c0.39,0.39,1.02,0.39,1.41,0l0,0 C20.68,15.91,20.69,15.27,20.3,14.88z" />
                    <path d="M0,0h24v24H0V0z" fill="none" />
                  </svg>
                )}
              </button>
              <div className="header-logo-wrapper">
                <a href="/sso/default" className="header-logo-link">
                  <img
                    src={
                      tenant.current_runtime === QAStatus.qa
                        ? `${distributor?.logos[0]?.defaultLogo.replace(
                          "logo.png",
                          "_QA_logo.png"
                        )}`
                        : `${distributor?.cloudConfig
                          ?.distributorCloudFileStorageUrl
                        }images/logo/${distributor?.logos?.find(
                          (logo) =>
                            logo.warehouseId ===
                            distributor?.warehouses?.find(
                              (elem) => elem.isDefault === true
                            ).id
                        ).headerLogo
                        }`
                    }
                    alt={`${tenant.name} Logo`}
                    className="header-logo"
                    draggable="false"
                    loading="eager"
                  />
                </a>
              </div>
            </div>
            {/* Search */}
            <div
              className={`header-search-wrapper ${isSearchFocus ? "" : "header-mobile-hidden"
                }`}
              tab-index="0"
            >
              <div
                className={`header-search-icon ${searchLoader ? "header-search-icon-loading" : ""
                  }`}
              ></div>
              <input
                type="text"
                id="headerSearch"
                className="header-search"
                placeholder="Search"
                autoComplete="off"
                tab-index="1"
                name="searchText"
                value={searchText}
                onClick={clickedOnSearch}
                onChange={onChangeSearch}
                onKeyDown={handleKeyPress}
                ref={searchRefHeader}
              />
              <button
                type="button"
                className={`header-search-clear-btn ${searchText ? "" : "header-search-clear-btn-hidden"
                  }`}
                onClick={() => {
                  setSearchText("");
                }}
              >
                Clear
              </button>

              <span className="header-search-shortcut">
                <span>Ctrl</span>+<span>Q</span>
              </span>

              <section
                className={`header-search-main ${isSearchFocus ? "" : "hidden"}`}
              >
                <div
                  className={`search-suggestions ${recentSearches?.length && !searchData?.length && !searchLoader
                    ? ""
                    : "hidden"
                    }`}
                >
                  <div className="search-suggestions-header">
                    <p className="title">Recently searched</p>
                    <div className="actions">
                      <button
                        type="button"
                        className="clear-all-searches-btn"
                        onClick={() => {
                          deleteAllRecentSearches();
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  {recentSearches?.length ? (
                    <div className="search-suggestions-list">
                      {recentSearches.map((recent, index) => {
                        return (
                          <button
                            key={recent + "_" + index}
                            className="search-suggestion-item"
                            type="button"
                            title={`Search &quot;${recent}&quot;`}
                            onClick={() => {
                              recentSearchesClick(recent);
                            }}
                          >
                            <span className="label">{recent}</span>
                          </button>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
                <div className="search-results-wrapper" ref={scrollTopRef}>
                  <ul
                    className={`search-tabs ${!searchData?.length ? "hidden" : ""
                      }`}
                  >
                    {searchData.length
                      ? Object.keys(tabNamesList).map((key, index) => {
                        if (!currentSearchTab && !index) {
                          setCurrentSearchTab(key);
                        }
                        const keyData = searchData[0][key];
                        const total = keyData ? keyData.total : 0;
                        const resultWord = tabNamesList[key]
                          ? total === 1
                            ? tabNamesList[key].slice(0, -1)
                            : tabNamesList[key]
                          : key;
                        const active = currentSearchTab === key;
                        return (
                          <button
                            key={key}
                            className={`search-tab ${active ? "active" : ""} ${!total ? "faded" : ""
                              }`}
                            value={key}
                            data-count={
                              total
                                ? Intl.NumberFormat("en", {
                                  notation: "compact",
                                }).format(total)
                                : 0
                            }
                            title={`${!total
                              ? "No"
                              : Intl.NumberFormat("en", {
                                notation: "standard",
                              }).format(total)
                              } ${resultWord} found`}
                            type="button"
                            onClick={(e) => {
                              searchTab(e, key);
                            }}
                          >
                            {resultWord}
                          </button>
                        );
                      })
                      : null}
                  </ul>

                  <div
                    className={`search-results-container ${!currentSearchTab ? "hidden" : ""
                      }`}
                  >
                    {searchResult?.length ? (
                      <div
                        className="search-tab-block"
                        data-tabname={currentSearchTab}
                        key={currentSearchTab}
                      >
                        <div className="header">
                          <span className="header-label">Date</span>
                          <span className="header-label">Name</span>
                          <span className="header-label">Type</span>
                          <span className="header-label">Status</span>
                        </div>
                        <ul className="list-search">
                          {searchResult?.map((item) => {
                            const toNormalCase = (text) =>
                              text?.substring(0, 1).toUpperCase() +
                              text?.substring(1).toLowerCase();
                            const getTextAfterUnderscore = (text) =>
                              text?.lastIndexOf("_") > 0
                                ? text.substring(text.lastIndexOf("_") + 1)
                                : text;
                            const itemDate = new Date(item.dateTime);
                            const itemDateFormatted = new Intl.DateTimeFormat(
                              "en-US",
                              { dateStyle: "medium" }
                            ).format(itemDate);
                            const itemType = toNormalCase(item.type);
                            const itemStatus = toNormalCase(
                              getTextAfterUnderscore(item.status)
                            );
                            const sanitizedTitle = removeTags(item.title);
                            const sanitizedDescription = removeTags(
                              item.description
                            );
                            return (
                              <a
                                id={item?.id}
                                href={item?.link}
                                className="search-result"
                                key={currentSearchTab + "_" + item?.id}
                              >
                                <p className="date">{itemDateFormatted}</p>
                                <div className="name">
                                  <p
                                    className="title"
                                    dangerouslySetInnerHTML={{
                                      __html: item.title,
                                    }}
                                    title={sanitizedTitle}
                                  ></p>
                                  <p
                                    className="description"
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                    title={sanitizedDescription}
                                  ></p>
                                </div>
                                <p className="type">{itemType}</p>
                                <p className="status">
                                  {itemStatus?.length > 0 ? itemStatus : ""}
                                </p>
                              </a>
                            );
                          })}
                        </ul>
                        {searchText && moreUniversalSearchAvailable ? (
                          <div
                            className="more-results"
                            data-container-tab={currentSearchTab}
                            data-page={universalSearchPage}
                          ></div>
                        ) : null}
                      </div>
                    ) : (
                      <div
                        className="search-tab-block"
                        data-tabname={currentSearchTab}
                        key={currentSearchTab}
                      >
                        <div className="search-empty-state-container">
                          <p className="message">
                            No{" "}
                            {tabNamesList[currentSearchTab]
                              ? tabNamesList[currentSearchTab]
                              : currentSearchTab}{" "}
                            found for "{searchQuery}"
                          </p>
                          <p className="desc">Try using a different keyword</p>
                          <div className="more-links"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>

              <p
                className={`header-search-tooltip ${searchText ? "hidden" : ""}`}
              >
                Press Enter
                <span className="return-icon"></span>
                to search
              </p>
            </div>

            <div className={`header-actions ${isSearchFocus ? "hidden" : ""}`}>
              <div className="header-action header-action-search sidenav-mobile-only">
                <a href={hashRoute.search} className="icon-button" title="Search">
                  Search
                </a>
              </div>
              {/* Support and Profile and Notepad */}
              <div className="header-action header-action-notepad sidenav-desktop-only">
                <button
                  className="icon-button"
                  tab-index="2"
                  onClick={openNotepad}
                />
              </div>
              <div className="header-action header-action-support sidenav-desktop-only">
                <a
                  href={supportLink}
                  rel="noopener noreferrer"
                  className="icon-button"
                  target="_blank"
                  title="Support"
                  tab-index="3"
                >
                  Support
                </a>
              </div>
              <div
                className="header-action header-action-profile sidenav-desktop-only"
                tab-index="4"
              >
                <button
                  className="icon-button logout-button"
                  type="button"
                  onClick={dropdownOpen}
                >
                  <div className="header-profile-avatar">
                    {currentUserData?.fullName
                      ?.match(/(^\S\S?|\b\S)?/g)
                      .join("")
                      .match(/(^\S|\S$)?/g)
                      .join("")
                      .toUpperCase()}
                  </div>
                </button>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={dropdownClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className="header-profile-wrapper">
                    <div className="header-profile-info">
                      <span className="header-profile-name">
                        {(currentUserData && currentUserData?.fullName) || ""}
                      </span>
                      <span className="header-profile-email">
                        {currentUserData?.email}
                      </span>
                    </div>
                    <div className="header-profile-links">
                      <a
                        className="header-profile-link"
                        onClick={profileOpen}
                        tab-index="0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v2c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4z" />
                        </svg>
                        <span>Manage account</span>
                      </a>
                      <div className="header-profile-links-divider"></div>
                      <span
                        className="header-profile-link"
                        tab-index="0"
                        onClick={clearCart}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          enableBackground="new 0 0 24 24"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <g>
                            <path d="M0,0h24v24H0V0z" fill="none" />
                          </g>
                          <g>
                            <g>
                              <path d="M5,5h6c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h6c0.55,0,1-0.45,1-1v0 c0-0.55-0.45-1-1-1H5V5z" />
                              <path d="M20.65,11.65l-2.79-2.79C17.54,8.54,17,8.76,17,9.21V11h-7c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h7v1.79 c0,0.45,0.54,0.67,0.85,0.35l2.79-2.79C20.84,12.16,20.84,11.84,20.65,11.65z" />
                            </g>
                          </g>
                        </svg>
                        <span>Logout</span>
                      </span>
                    </div>
                  </div>
                </Popover>
              </div>
            </div>

            <button
              className={`close-search-btn ${isSearchFocus ? "" : "hidden"}`}
              title="Back [Esc]"
              type="button"
              ref={searchBackRefHeader}
              onClick={() => {
                back();
              }}
            ></button>
          </div>
        </header>
      </nav>
      <Notepad visible={visible} setVisible={setVisible} />
    </>
  );
}
