import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "apps/pos/pages/Drafts/hooks/queryKeys";
import { getDraftsDetails } from "apps/pos/pages/Drafts/request";
import { useState } from "react";

export const useGetDraftsDetails = () => {
  const [params, setParams] = useState();
  const { data, isFetching, isSuccess } = useQuery(
    [QueryKeys.getSalesPersons, params],
    async () => {
      if (params) {
        return getDraftsDetails(params);
      }
    },
    {
      cacheTime: 0,
    }
  );

  const setDraftsParma = (data) => {
    if (data) {
      setParams(data);
    }
  };
  return {
    draftsValues: data || {},
    setDraftsParma,
    isDraftFetching: isFetching,
    isDraftSuccess: isSuccess,
  };
};
