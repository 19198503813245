import { itemListHeader } from "constants/constant";

import "./ItemGroupHeader.scss"

const ItemListHeader = () => {
    return (
        <div className="item-list-header">
            <div className="item-tab-header">
                <p>{itemListHeader.line}</p>
                <p>{itemListHeader.item}</p>
                <p>{itemListHeader.type}</p>
                <p>{itemListHeader.qty}</p>
            </div>
            <div className="prices">
                <p>{itemListHeader.price}</p>
                <p>{itemListHeader.cost}</p>
                <p>{itemListHeader.margin}</p>
            </div>
            <div className="metadata">
                <p>{itemListHeader.ctrlType}</p>
                <p>{itemListHeader.leadTime}</p>
            </div>
        </div>
    )
}

export default ItemListHeader;