import { PosImage } from "apps/pos/Common/uiCore";
import { buttonTitle } from "constants/constant";
import images from "constants/images";

const ProductStatusDetail = ({
  statusValue,
  setStatusBox,
  productStatusOptions,
  statusBox,
  handleSelectStatus,
  inputRef,
  id
}) => {
  const { checkSmall, downSmallArrow } = images;
  const { active } = buttonTitle;

    return (
        <>
            <div id={id} ref={inputRef} className={`main-detail-product-status product-status-${statusValue?.status ? statusValue?.status : 'Active'}`} onClick={() => setStatusBox(!statusBox)} >
                <span className="status-icon"></span>
                <span onClick={() => setStatusBox(!statusBox)} className={`selected-status-label ${statusBox ? 'text-underline' : ''}`}>
                    {statusValue?.label ? statusValue?.label : active}
                </span>
                <PosImage src={downSmallArrow} alt="arrow" className={statusBox ? 'up-arrow' : 'down-arrow'} />
            </div>
            <div className="product-detail-main-status-div">
                {statusBox && <div className="product-detail-status">
                    {productStatusOptions?.map((item) => {
                        return (
                            <div className="d-flex align-items-start product-detail-status-item" onClick={() => handleSelectStatus(item)}>
                                {statusValue?.status === item?.status &&
                                    <PosImage src={checkSmall} alt="checked product" className="product-detail-status-checked-icon" />}
                                <div className={`product-status-${item?.status} product-detail-status-sec`}>
                                    <div className="status-name">
                                        <span className="status-icon"></span>
                                        {item?.label}
                                    </div>
                                    <div className="status-subtitle">{item?.subLabel}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>}
            </div>
        </>
    )
}

export default ProductStatusDetail
