import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { number, object, string } from "yup";
import dayjs from "dayjs";

import {
  PosButton,
  PosInput,
  PosModel,
  PosPageHeading,
} from "apps/pos/Common/uiCore";
import { Button } from "components";
import { setFinalDeliveryDetails, setHandlerId } from "../pages/customerInfo/CustomerStorage/redux/storage.slice";
import DatePickerNew from "../Common/DatePickerNew/DatePickerNew";

import { CheckIcon, ClearIcon } from "constants/icons";
import { numbersWithDecimalRegEx } from "constants/constant";

import "./setStorage.scss";

interface StorageDataModalProps {
  setDeliveryDetails?: (boolean) => void;
  deliveryDetails?: boolean;
  isDeliverEntireShipment?: boolean;
  itemsDetail?: any;
  deliverEntireShipmentFn?: any;
  deliverShipmentFn?: any;
  isApiLoading?: boolean;
}

const DeliveryDetailsModals = ({
  deliveryDetails,
  setDeliveryDetails,
  isDeliverEntireShipment,
  itemsDetail,
  deliverEntireShipmentFn,
  deliverShipmentFn,
  isApiLoading
}: StorageDataModalProps) => {

  const {state} = useLocation();
  const dispatch = useDispatch();

  const currentTaker = JSON.parse(localStorage.getItem("taker"));

  const formik = useFormik({
    initialValues: {
      handlerID: currentTaker?.personId, // takerId,,
      orderID: state?.orderId,
      shipMethod: "Customer Pickup",
      trackingNum: "",
      referenceNum: "",
      tenantPaidShipFee: 0,
      deliveryTime: dayjs().toISOString(),
    },
    validationSchema: object().shape({
      shipMethod: string().required("Ship Method is required."),
      trackingNum: string().required("Tracking Information is required."),
      tenantPaidShipFee: number().required("Ship Fee Paid is required."),
      deliveryTime: string().required("Delivery Date is required."),
    }) ,
    onSubmit: (values) => {
      const finalValues = {
        ...values,
        tenantPaidShipFee: Number(values?.tenantPaidShipFee)
      }
      
      dispatch(setHandlerId(values?.handlerID))
      dispatch(setFinalDeliveryDetails(finalValues))
      
      if(isDeliverEntireShipment) {
        deliverEntireShipmentFn({
          ...finalValues,
        })
      } else {
        const finalItems = itemsDetail?.items?.map((item, index) => {
          return {
            qtyInDelivery: Number(item.qtyInDelivery),
            salesPrice: state?.pendingItemsData[index]?.salesPrice,
            shipmentInStorageItemID: state?.pendingItemsData[index]?.shipmentInStorageItemID,
          }
        }).filter((item) => item?.qtyInDelivery > 0)

        deliverShipmentFn({
          ...finalValues,
          deliveryItemValue: state?.shipmentItemValue,
          items: finalItems,
        })
      }
    },
  });

  const { values, setFieldValue, handleSubmit, errors, touched } = formik;

  const handleClose = () => {
    if(!isApiLoading){
      setDeliveryDetails(false)
    }
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <PosModel
            open={deliveryDetails}
            onClose={handleClose}
            className={`set-customer-code-modal`}
            dialogClassName={`set-customer-code-modal-container storage-data-modal-container set-storage-modal-container`}
            dialogHeader={
              <div className="modal-header">
                <div>
                  <PosPageHeading
                    heading="Set Delivery Details"
                    blackHeading
                    className="small-page-heading"
                  />
                </div>
                <Button className="bg-transparent border-0 p-0 button-focus" tabIndex={0} onClick={handleClose} >
                  <ClearIcon
                    className="clear-icon"
                  />
                </Button>
               
              </div>
            }
            dialogFooter={
              <>
                <div className="d-flex justify-content-between w-100 flex-wrap flex-row-reverse">
                 
                  <PosButton
                    disabled={isApiLoading}
                    variant="contained"
                    color="primary"
                    className={`return-cart-new-order add-to-return-cart-button mt-2 btn`}
                    startIcon={<CheckIcon />}
                    fontWeight
                    onClick={() => handleSubmit()}
                  >
                    Complete Delivery
                  </PosButton>
                  <PosButton
                    type="button"
                    variant="contained"
                    className={`add-to-return-cart-button mr-2 mt-2 btn-font-600 custom-size-5 btn`}
                    fontWeight
                    onClick={handleClose}
                  >
                    Back
                  </PosButton>
                </div>
              </>
            }
          >
            <div className="set-storage-type-section modal-input-bottom-spacing">
              <div className="title-text font-weight-600 mb-1">Ship Method</div>
              <PosInput
                variant="filled"
                id="filled-basic"
                autoFocus
                InputProps={{ disableUnderline: true }}
                className="input-focus-styling"
                name="shipMethod"
                value={values?.shipMethod}
                onChange={(e) => setFieldValue("shipMethod", e.target.value)}
              />
            </div>
            {errors.shipMethod && touched.shipMethod ? (
                            <p className="validation-error-text">
                                {errors.shipMethod}
                            </p>
                        ) : null}
            <div className="set-storage-type-section modal-input-bottom-spacing">
              <div className="title-text font-weight-600 mb-1">
                Tracking Information
              </div>
              <PosInput
                variant="filled"
                id="filled-basic"
                InputProps={{ disableUnderline: true }}
                className="input-focus-styling"
                name="trackingNum"
                value={values?.trackingNum}
                onChange={(e) =>
                  setFieldValue("trackingNum", e.target.value)
                }
              />
            </div>
            {errors.trackingNum && touched.trackingNum ? (
                            <p className="validation-error-text">
                                {errors.trackingNum}
                            </p>
                        ) : null}
            <div className="set-storage-type-section modal-input-bottom-spacing">
              <div className="title-text mb-1">
                <span className="font-weight-600">Reference # </span>{" "}
                <span className="optional-text">(optional)</span>
              </div>
              <PosInput
                variant="filled"
                id="filled-basic"
                InputProps={{ disableUnderline: true }}
                className="input-focus-styling"
                name="referenceNum"
                value={values?.referenceNum}
                onChange={(e) => setFieldValue("referenceNum", e.target.value)}
              />
            </div>
            <div className="set-storage-type-section modal-input-bottom-spacing">
              <div className="title-text mb-1">
                <span className="font-weight-600">Ship Fee Paid </span>{" "}
                <span className="optional-text">(optional)</span>
              </div>
              <PosInput
                className={`ship-fee-paid search-input-focus`}
                variant="standard"
                id="standard-basic"
                InputProps={{ disableUnderline: true }}
                value={values?.tenantPaidShipFee}
                onFocus={(event) => event.target.select()}
                onChange={(e) => {
                  if(numbersWithDecimalRegEx.test(e.target.value)) {
                    setFieldValue("tenantPaidShipFee", e.target.value)
                  }
                }}
              />
            </div>
            {errors.tenantPaidShipFee && touched.tenantPaidShipFee ? (
                            <p className="validation-error-text">
                                {errors.tenantPaidShipFee}
                            </p>
                        ) : null}
            <div className="set-storage-type-section modal-input-bottom-spacing">
              <div className="title-text font-weight-600 mb-1">
                Delivery Date
              </div>
              <div className="custom--datepicker">
                <DatePickerNew value={dayjs(values?.deliveryTime).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    {
                      if(e.target.value){
                        setFieldValue(
                          "deliveryTime",
                          dayjs(e.target.value)
                        )
                      }else{
                        setFieldValue(
                          "deliveryTime",
                          ""
                        )
                      }
                    }
                  } />
              </div>
            </div>
            {errors.deliveryTime && touched.deliveryTime ? (
                            <p className="validation-error-text">
                                {errors.deliveryTime}
                            </p>
                        ) : null}
          </PosModel>
        </Form>
      </FormikProvider>
    </>
  );
};

export default DeliveryDetailsModals;
