import PerfectScrollbar from "react-perfect-scrollbar";

import { Button, Select } from "../../../components";

import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const themeColor = [
  {
    colorCode: "#F1F1F1",
    colorName: "Paper",
  },
  {
    colorCode: "#BBE2F3",
    colorName: "Sky",
  },
  {
    colorCode: "#9CF1CE",
    colorName: "Leaf",
  },
  {
    colorCode: "#F6DFD5",
    colorName: "Sand",
  },
  {
    colorCode: "#E9D1FE",
    colorName: "Flower",
  },
];

export function Appearance() {

  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Appearance</div>
        <div className="devider2px" />

        <form className="formContainer" id="addAppearanceForm">
          <div className="addressTitle">Theme</div>

          <div style={{ width: "50%" }}>
            <Select
              id={"theme"}
              name={"theme"}
              label={""}
              textInputStyle="phoneinput"
              disabled
              options={[
                { label: "Light", value: "Light" },
                // { label: "Dark", value: "Dark" },
              ]}
            />
          </div>

          <div className="addressTitle">Color Theme</div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {themeColor.map((theme) => {
              return (
                <div
                  style={{
                    // border: "1px solid black",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                    borderWidth: 1,
                    marginRight: 10,
                    minHeight: 120,
                    minWidth: 120,
                    width: 120,
                    height: 120,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: theme.colorCode,
                      width: 50,
                      height: 50,
                      borderRadius: 25,
                    }}
                  />
                  <span>{theme.colorName}</span>
                </div>
              );
            })}
          </div>
        </form>
        <div className="devider1px" />

        <Button
          type={"submit"}
          className="manage-btn"
          data-bs-toggle="modal"
          data-bs-target="#jumper_modal"
        >
          <span className="manage-button-txt">save changes</span>
        </Button>
      </div>
    </PerfectScrollbar>
  );
}
