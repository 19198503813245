export const addOrRemoveProperty = (
  category: string,
  key: string,
  obj: any,
) => {
  if (!obj) {
    obj = {};
  }
  // If the category doesn't exist yet, add it to the obj
  if (!obj[category]) {
    obj[category] = [];
  }
  // Check if the key-value pair exists in the category array
  const index = obj[category].findIndex(({ key: k }) => k === key);
  // If the key-value pair doesn't exist, add it to the category array
  if (index === -1) {
    obj[category].push({ key });
  }
  // If the key-value pair already exists, remove it from the category array
  else {
    obj[category].splice(index, 1);
  }
  return obj;
};

export const isKeyAvailable = (
  category: string,
  key: string,
  obj: any
) => {
  // Check if the object exists
  if (!obj) {
    return false;
  }

  // Check if the category exists in the  object
  if (!obj.hasOwnProperty(category)) {
    return false;
  }

  // Check if the key exists in the category array
  return obj[category].some(({ key: k }) => k === key);
};
export const isCategoryAvailable = (
  productId: string,
  category: string,
  obj: any
) => {
  if (!obj) {
    return false;
  }

  // Check if the product ID exists in the object
  if (!obj.hasOwnProperty(productId)) {
    return false;
  }

  // Check if the category exists in the product ID object
  if (!obj[productId].hasOwnProperty(category)) {
    return false;
  }

  if (!obj[productId][category]?.length) {
    return false;
  }
  return true;
};

export const addOrRemoveStarredItems = (
  key: string,
  obj: any,
  productID: string
): any => {
  if (!obj) {
    obj = {};
  }

  // If the productID doesn't exist yet, add it to the object
  if (!obj[productID]) {
    obj[productID] = [];
  }

  // Check if the key exists in the productID array
  const index = obj[productID].indexOf(key);

  // If the key doesn't exist, add it to the productID array
  if (index === -1) {
    obj[productID].push(key);
  }
  // If the key already exists, remove it from the productID array
  else {
    obj[productID].splice(index, 1);
  }
  return obj;
};

export const isKeyPresent = (
  obj: any,
  productID: string,
  key: string
): boolean => {
  if (obj && obj[productID] && Array.isArray(obj[productID])) {
    return obj[productID].includes(key);
  }

  return false;
};

export const hasKeyInCategories = (
  isStarred: boolean,
  className: string,
  hasKeyInCategory
) => {
  return isStarred && hasKeyInCategory(className)
};
