import { makeStyles } from "@material-ui/core";
import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle<{ theme: any }>`

  a {
    color: ${({ theme }) => theme.colors.text} !important;
    cursor: pointer;
  }
  a:hover{
    color:${({ theme }) => theme.colors.link.text} !important;
    cursor: pointer;
  } 
  a:visited{
    color:${({ theme }) => theme.colors.text} !important;
    cursor: pointer;
  } 
  a.active{
    color: ${({ theme }) => theme.colors.text} !important;
    cursor: pointer;
    border-color: transparent transparent ${({ theme }) =>
      theme.colors.link.text} transparent !important;
  }
  a.active:hover{
    color: ${({ theme }) => theme.colors.link.hover} !important;
    cursor: pointer;
    border-color: transparent transparent ${({ theme }) =>
      theme.colors.link.hover} transparent !important;
  }
  button.btn {
  }
  button.cancel {
    background-color: #ff0000;
    color: ${({ theme }) => theme.colors.text};
  }
  button:disabled {
    background-color: #ebebeb;
    color: #333333;
  }

  .bg-theme{
    background-color: ${({ theme }) => theme.colors.Nav.background};
  }
  .bg-theme-btn{
    background-color: ${({ theme }) => theme.colors.Nav.itemIcon};
    border: 1px solid ${({ theme }) => theme.colors.Nav.itemIcon};
  }
  .optionText{
    color : #000;
    margin-left:10px;
    font-size:14px;
    font-family:Inter , Regular;

  }

  .bg-theme-nav-item{
    color: ${({ theme }) => theme.colors.Nav.itemIcon};
  }
  .dropdown-header{
    background-color: ${({ theme }) => theme.colors.Nav.background} !important;
  }
  .is-active{
    background-color: ${({ theme }) => theme.colors.Nav.background} !important;
    color: ${({ theme }) => theme.colors.link.text} !important;
  }

  .pro-sidebar {
    padding-top: 60px;
    height:100vh;
    > .pro-sidebar-inner {
      background:  ${({ theme }) => theme.colors.Nav.background} !important;
    }
  }

  .navbar-toggler-icon{
    color : ${({ theme }) => theme.colors.Nav.itemIcon};
  }



  .dropdown-toggle{
    background-color:transparent !important;
  }
  .dropdown-toggle::after{
    margin-left: 10px !important;
  }
  

  &.collapsed {
    .pro-menu {
      > ul {
        > .pro-menu-item {
          > .pro-inner-item {
            > .suffix-wrapper,
            > .prefix-wrapper {
              opacity: 0;
            }
          }
          > .pro-inner-list-item {
            background-color: $submenu-bg-color-collapsed;
            z-index: 111;
          }
          position: relative;
          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            cursor: pointer;
          }
          &.pro-sub-menu {
            position: relative;

            > .pro-inner-item {
              pointer-events: none;

              > .pro-arrow-wrapper {
                display: none;
              }
            }
            > .pro-inner-list-item {
              height: auto !important;
              position: fixed;
              visibility: hidden;
              min-width: 220px;
              max-width: 270px;
              background-color: transparent;
              max-height: 100%;
              padding-left: 3px;
              &.has-arrow {
                padding-left: 10px;
              }
              > .popper-inner {
                max-height: 100vh;
                overflow-y: auto;
                background-color: ${({ theme }) =>
                  theme.colors.Nav.background} !important;
                padding-left: 20px;
                border-radius: 4px;
              }
            }
            &:hover {
              > .pro-inner-list-item {
                transition: visibility, transform 0.3s;
                visibility: visible;
              }
              .pro-icon-wrapper {
                .pro-icon {
                  animation: swing ease-in-out 0.5s 1 alternate;
                }
              }
            }

            .pro-inner-list-item {
              .pro-sub-menu-item,
              .pro-inner-item {
                padding: 8px 30px 8px 5px;
              }
            }
          }
        }
      }
    }
  }

  .pro-sidebar {
    .pro-menu {
      .pro-menu-item {
        &.active {
          color:  ${({ theme }) => theme.colors.text};
        }

        > .pro-inner-item {
        
          &:focus {
            color: ${({ theme }) => theme.colors.text};
          }
  
          > .pro-icon-wrapper {
            margin-right: 10px;
            font-size: 14px;
            width: $icon-size;
            min-width: $icon-size;
            height: $icon-size;
            line-height: $icon-size;
            text-align: center;
            display: inline-block;
  
            .pro-icon {
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              color:${({ theme }) => theme.colors.text};
            }
          }

          > .pro-item-content {
            flex-grow: 1;
            flex-shrink: 1;
            color:${({ theme }) => theme.colors.text};
            &:hover {
              color: ${({ theme }) => theme.colors.link.hover};
            }
            @extend %text-truncate;
          }
  
          &:hover {
            color: ${({ theme }) => theme.colors.link.hover};
            .pro-icon-wrapper {
              .pro-icon {
                animation: swing ease-in-out 0.5s 1 alternate;
              }
            }
          }
        }
  
     
      }
    }
  }


@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e0e5ec;
  border-radius: 0.35rem;
}


.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0061f2;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
            animation: none;
  }
}

@media only screen and (max-width: 600px) {
  .d-flex {
    display: flex !important;
    justify-content: space-between;
    flex-direction: column;
  }
} 

`;

export const drawerUseStyles = makeStyles({
  drawer: {
    "& .MuiBackdrop-root": {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  },
  taxDrawer:{
    "& .MuiBox-root": {
    width : "750px",
    },
  }
});