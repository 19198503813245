import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { QueryKeys } from "./queryKeys";
import { getDueDateEditFlag } from "../request";

export const useVerifyDueDate = () => {
  const [createId, setCreateId] = useState();
  const { data, isFetching } = useQuery(
    [QueryKeys.verifyDueDate, createId],
    async () => {
      if (createId) {
        return getDueDateEditFlag(createId);
      }
    }
  );

  const setVerifyDueDate = useCallback(
    (id) => {
      setCreateId(id);
    },
    [createId]
  );

  return {
    loading: isFetching,
    isEditDueDate: data?.data,
    setVerifyDueDate,
  };
};
