import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { EnhancedTableHead } from "./ItemHistoryComponent/EnhancedTableHead";
import { EnhancedTableBody } from "./ItemHistoryComponent/EnhancedTableBody";
import { useStyles } from "./ItemHistoryComponent/data";

import {
  Divider,
  Container,
  PosIconButton,
  PosImage,
  PosHelmet,
  PosPageHeading,
  Paper
} from "apps/pos/Common/uiCore";
import {
  ArrowBackRoundedIcon,
} from "constants/icons";
import images from "constants/images";
import { itemHistoryLabel } from "constants/enum";
import { PosResponseProps, RootState } from "models";
import { Paths } from "enums";
import { keys } from "utils/helper";
import { PosFlowDataProp } from "constants/interfaces";
import { addToCartDrawer, getItemHistoryRequest } from "sagas/pos/actions";

import "./itemHistory.scss";

const ItemHistory = () => {

  const { itemHistory, isLoading, productsData } = useSelector<
    RootState,
    PosResponseProps
  >((state) => state.pos);

  const { loadCartDetails } = useSelector<
    RootState,
    PosFlowDataProp
  >((state) => state.persistReducer);

  const classes = useStyles();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productIdInfo = useParams();
  const { pathname } = location;
  const isLeapFrongReport = (pathname === `${Paths.product}/${productIdInfo.id}/${Paths.reports}/${Paths.itemHistory}`)

  const {
    productId,
    productName,
    avgMonthlyUsage,
    avgDeviationPct,
    monthsInSeason,
    demandQtyHits,
    leadData
  } = itemHistory || {}

  useEffect(() => {
    const data = {
      productId: productIdInfo.id,
    };
    dispatch(getItemHistoryRequest(data));
  }, []);

  const { defaultIcon } = images;
  const  {avgMonthly , avgDeviation , monthsInSeasons } = itemHistoryLabel;
  const priceInfo = (title: string, price: number | string) => {
    return (
      <div className="show-price-wrapper-main">
        <div className="price-title">{title}</div>
        <div className="price-key">{price}</div>
      </div>
    );
  };

  const backToCartPage = () => {
    const { cartId } = loadCartDetails
    const { productId } = productsData
    if(productId) {
      dispatch(addToCartDrawer(true))
    }
    navigate(`${Paths.posCart}/${cartId}`);
  }

  const handleKeyPressDown = (e) => {
    const { keyCode } = e;
    const { esc } = keys;
    const { cartId } = loadCartDetails
    if (keyCode === esc && cartId) {
      backToCartPage();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPressDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPressDown);
    };
  }, [loadCartDetails, productsData]);

  return (
    <>
      <div className="item-history-main-section">
        <PosHelmet title={!isLeapFrongReport ? "POS" : `Product Detail - ${productName} - ${productIdInfo.id}` } metaName="item-history" content="item-history" />
        <section className={`item-history-section ${isLeapFrongReport ? "pl-122" : ""}`}>
          <div className="item-history-container">
            {!isLeapFrongReport ?
              <PosIconButton
                className="back-button"
                onClick={() => backToCartPage()}
                tabIndex={-1}
              >
                <ArrowBackRoundedIcon className="back-icon" />
              </PosIconButton> : null}
            <div className="item-history-title-container">
              <div className="title">
                <PosPageHeading heading="Item History" blackHeading />
                <PosPageHeading heading="Avg. Monthly Values" grayHeading />
              </div>
              <div className="item-history-products-container">
                <div className="product-ledger-image">
                  <PosImage
                    src={defaultIcon}
                    alt="product-icon"
                  />
                  <div className="product-ledger-name">
                    <div className="name">{productName}</div>
                    <div className="key">{productId}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Container maxWidth={false} className="table-main-container">
          <section className="price-quantity-section">
            <Divider className="divider-div" />
            <div className="show-price-quantity-container ml-10">
              {priceInfo(
                avgMonthly,
                avgMonthlyUsage ? avgMonthlyUsage?.toFixed(2) : "0.00"
              )}
              {priceInfo(avgDeviation, avgDeviationPct ?  avgDeviationPct?.toFixed(2) + "%": "0%")}
              {priceInfo(monthsInSeasons, monthsInSeason ? monthsInSeason?.toFixed(2) : "0")}
            </div>
            <div className="row mt-4">
              <div className="col-md-4">
                <Paper className="item-history-data-grid-container shadow-none pe-5">
                  <div
                    className={`${classes.table}`}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead />
                    {isLoading ? (
                      <></>
                    ) : (
                      <EnhancedTableBody
                        itemHistory={demandQtyHits ? demandQtyHits.slice(0,12) : []}
                      />
                    )}
                  </div>
                </Paper>
              </div>
              <div className="col-md-4">
                <Paper className="item-history-data-grid-container shadow-none pe-5">
                  <div
                    className={`${classes.table}`}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead />
                    {isLoading ? (
                      <></>
                    ) : (
                      <EnhancedTableBody
                        itemHistory={demandQtyHits ? demandQtyHits.slice(12, 24) : []}
                      />
                    )}
                  </div>
                </Paper>
              </div>
              <div className="col-md-4 border-start">
                <div className="lead-header ms-3 pt-1">
                  Lead Data
                </div>
                <Paper className="item-history-data-grid-container shadow-none ms-3">
                  <div
                    className={`${classes.table}`}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead lead/>
                    {isLoading ? (
                      <></>
                    ) : (
                      <EnhancedTableBody lead
                        itemHistory={leadData}
                      />
                    )}
                  </div>
                </Paper>
              </div>
            </div>
          </section>
        </Container>
      </div>
    </>
  );
};

export default ItemHistory;
