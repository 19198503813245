import serviceV2 from "services/config2";

export const orderHistory = (stockWarehouseId, customerId, page, pageSize) => {
    return serviceV2.get(`pos/orders/warehouses/${stockWarehouseId}/customers/${customerId}/status/completed?numOfDays=90&page=${page}&size=${pageSize}`)
}

export const addShipmentInStorage = (payload) => {
    return serviceV2.post(`shipments/storage/with/items`, payload).then((response) => {
        return response?.data
    })
}
export const addEntireShipmentInStorage = (payload) => {
    return serviceV2.post(`shipments/storage`, payload).then((response) => {
        return response?.data
    })
}