import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import StorageDataModal from "apps/pos/storage/StorageData";

import "apps/pos/pages/customerInfo/CustomerDrafts/customerDrafts.scss";
import "apps/pos/pages/customerInfo/CustomerOrders/customerOrders.scss";
import { PosFlowDataProp } from "constants/interfaces";
import { RootState } from "models";
import { getLocalStoraGeItem, stopLoading } from "utils/utils";
import { setIsDeliveredApiLoading, setIsPendingApiLoading } from "../CustomerOrders/redux/customerOrder.slice";
import StorageTab from "./StorageTab";
import { useGetDeliveredStorageItems } from "./hooks/useGetDeliveredStorageItems";
import { useGetStorageShipments } from "./hooks/useGetStorageShipments";

interface CustomerStorageProps {
  storageSearchInputRef?: React.RefObject<HTMLDivElement>;
  storagePillsRef?: React.RefObject<HTMLDivElement>;
  storageTopDataHeight?: number;
  savedOrderFoundInfoRef?:React.MutableRefObject<any>
}

const CustomerStorage = ({ storageSearchInputRef, storagePillsRef, storageTopDataHeight, savedOrderFoundInfoRef }: CustomerStorageProps) => {

  const currentWorkspace = getLocalStoraGeItem("workspace");

  const { stockWarehouseId } = currentWorkspace;

  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
    );
  const {customer} = posFlowData || {};

  const { storageShipments: shipmentOrdersData, getStorageShipmentsIsLoading, getStorageShipmentData } =
    useGetStorageShipments();

    useEffect(() => {
      if(stockWarehouseId && customer?.customerId) {
        getStorageShipmentData(stockWarehouseId, customer?.customerId)
      }

    },[stockWarehouseId, customer?.customerId])

  const { deliveredStorageItemsData, getDeliveredStorageItemsIsLoading } =
    useGetDeliveredStorageItems(stockWarehouseId, customer?.customerId);

  // const [sortIndex, setSortIndex] = useState(0);
  const [storageDataModal, setStorageDataModal] = useState(false);

  const isPendingApiLoading = [getStorageShipmentsIsLoading].includes(true)
  const isDeliveredApiLoading = [getDeliveredStorageItemsIsLoading].includes(true)

  useEffect(() => {
    dispatch(setIsPendingApiLoading(isPendingApiLoading));
  }, [isPendingApiLoading]);

  useEffect(() => {
    dispatch(setIsDeliveredApiLoading(isDeliveredApiLoading));
  }, [isDeliveredApiLoading]);


  useEffect(() =>{
    return stopLoading
  },[])

  const dispatch = useDispatch();

  const handleSetStorageDataModal = (value) => {
    setStorageDataModal(value);
  };

  return (
    <>
      <section className="document-main-section">
        <div className="document-container">
          <StorageTab shipmentOrdersData={shipmentOrdersData} deliveredStorageItemsData={deliveredStorageItemsData}
          storageSearchInputRef={storageSearchInputRef}
          storagePillsRef={storagePillsRef}
          storageTopDataHeight={storageTopDataHeight}
          isPendingApiLoading={isPendingApiLoading}
          isDeliveredApiLoading={isDeliveredApiLoading}
          savedOrderFoundInfoRef={savedOrderFoundInfoRef}
          />
        </div>
      </section>

      <StorageDataModal
        storageDataModal={storageDataModal}
        setStorageDataModal={handleSetStorageDataModal}
      />
    </>
  );
};
export default CustomerStorage;
