import { Checkbox, CheckboxProps, FormControlLabel, FormGroup } from "@mui/material";

import useStyles from "./style";

interface Props extends CheckboxProps {
  label: string;
}

const PosCheckbox = ({ checked, onChange, label, disabled, className }: Props) => {
  const classes = useStyles();
  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChange} disableRipple className={`${classes.root} ${className}`} disabled={disabled}/>}
        label={label}
      />
    </FormGroup>
  );
};

export default PosCheckbox;
