import { Button, ButtonProps } from "@material-ui/core";

import useStyles from "./style";

interface MuiButtonProps extends ButtonProps {
  isClassName?: string;
  fontWeight?: boolean;
  width?:string;
  height?:string;
  id?:string;
}

const PosButton = ({
  variant,
  onClick,
  children,
  disabled,
  startIcon,
  endIcon,
  className,
  type,
  color,
  isClassName,
  size,
  fontWeight,
  onMouseEnter,
  onMouseLeave,
  tabIndex,
  width,
  height, 
  title,
  id
}: MuiButtonProps) => {
  const classes = useStyles({ width, height });

  return (
    <Button
    tabIndex={tabIndex}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    className={
        isClassName ||
        `${className} ${classes.root} ${fontWeight ? classes.fontWeight : ""} ${width ? classes.customButton : ""}`
      }
      color={color}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      disableRipple
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      size={size}
      title={title}
      id={id}
    >
      {children}
    </Button>
  );
};

export default PosButton;
