import { useState } from "react";
import { Formik } from "formik";

import { PosButton, PosInput } from "apps/pos/Common/uiCore";

import { CheckIcon } from "constants/icons";
import { numberRegex, title } from "constants/constant";
import { blockInvalidChar } from "utils/utils";

import "./GpmChangeModal.scss";

interface Props {
  profit: string;
  setProfitPercentage: (number) => void;
  profitPercentageOrignal: string;
  isProfitPercentage: boolean;
  handleSubmitChange : (number , string , boolean) => void;
  modalRef?: React.Ref<HTMLDivElement>
}
const GpmChangeModal = ({ handleSubmitChange, profit, profitPercentageOrignal, isProfitPercentage, modalRef }: Props) => {
  const [isReset , setIsReset] = useState(false);
  const {grossProfitMargin , resetButton , applyMargin} = title;
  return (
    <Formik
      initialValues={{
        rate: profit,
      }}
      enableReinitialize
      onSubmit={(value) => {
        handleSubmitChange(Number(value.rate),profitPercentageOrignal,isReset)
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        const { rate } = values;
        const restockRateOnChange = (event) => {
          if (
            !event.target.value ||
            numberRegex.test(event.target.value)
          ) {
            setFieldValue('rate', event.target.value);
          }
        }

        const reset = () => {
          setIsReset(true)
          setFieldValue('rate', profitPercentageOrignal);
        }

        return (
          <div className="restocking-fee-modal" ref={modalRef}>
              <span className="input-title">{grossProfitMargin}</span>
              <div className="restocking-fee-amount-input-div">
                <div className="fee-amount-input-div">
                  <div className="fee-amt-input-div">
                    <PosInput
                      variant="standard"
                      id="standard-basic"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: <div className="rate-icon">%</div>,
                      }}
                      className="rate-input"
                      name="rate"
                      type='number'
                      autoFocus
                      value={rate}
                      onKeyPress={(e) => blockInvalidChar(e)}
                      onChange={(e) => restockRateOnChange(e)}
                      onWheel={(e) => {
                        e.target.blur()
                      }}
                    />
                  </div>
                </div>
              </div>
            <div className="use-default-button-section flex-row-reverse">
              <PosButton
                variant="contained"
                color="primary"
                className="use-default-btn ms-auto btn"
                startIcon={<CheckIcon />}
                onClick={() => handleSubmit()}
              >
                {applyMargin}
              </PosButton>
              {isProfitPercentage ? <PosButton
                variant="contained"
                color="secondary"
                className="use-default-btn btn"
                onClick={() => reset()}
              >
                {resetButton}
              </PosButton> : null}            
            </div>
          </div>
        );
      }}

    </Formik>
  );
};
export default GpmChangeModal;