import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import OrderInfo from "./OrderInfo";

import CashDrawerDropdown from "apps/pos/CashDrawerDropdown/CashDrawerDropdown";
import { JobNameInput } from "apps/pos/Common/FacilityInput";
import { PosButton, PosInput, PosPageHeading } from "apps/pos/Common/uiCore";

import {
  coinDenominations,
  coinValues,
  jobAndReceiptNameRegex,
  poNumberRegex,
  sectionTitle,
  staticTenderValue,
  title,
} from "constants/constant";

import { InputAdornment } from "@material-ui/core";
import { PurchaseOrderInput } from "apps/pos/Common/PurchaseOrderInput";
import { setPosFlowData } from "sagas/persistReducer/actions";
import { getPosFlowData } from "sagas/persistReducer/selector";
import { isPurchaseOrderNoEdited, setJobNameForAll } from "sagas/pos/actions";

const CashComponent = ({
  finalTotal,
  tempJobNameSave,
  tenderAmount,
  oneDecimal,
  setTempJobNameSave,
  setTenderAmount,
  cashOptionsSelected,
  setCashOptionsSelected,
  cashDrawerError,
  setCashDrawerError,
  subTotal,
  taxAmount,
  shipTaxAmount,
  orderId,
  shippingTaxAmount,
  salesPersonName,
  shipToTaxFlag,
  shippingFee,
  creditAmtApplied,
  poNumberSave,
  setPoNumberSave,
}) => {
  const dispatch = useDispatch();
  const posFlowData = useSelector(getPosFlowData);

  const inputRef = useRef(null);
  const { tenderAmountTitle, returnChangeTitle } = sectionTitle;
  const [suggestions, setSuggestions] = useState([]);
  const handleJobName = (e) => {
    if (jobAndReceiptNameRegex.test(e.target.value)) {
      setTempJobNameSave(e.target.value);
      dispatch(setJobNameForAll(e.target.value));
    }
  };

  useEffect(() => {
    // Calculate the header width based on its content
    const headerElement = document.getElementById("cashDrawerHeader");
    if (headerElement) {
      const newHeaderWidth = headerElement.offsetWidth;
      if (inputRef.current) {
        inputRef.current.style.width = `${newHeaderWidth}px`;
      }
    }
  }, []);

  const returnCharge = tenderAmount - finalTotal;
  const suggest = (orderTotal) => {
    const suggestions = new Set();
    if (orderTotal === 0) return [];
    // Determine the base amount (nearest tens)
    const baseAmount = Math.ceil(orderTotal / 10) * 10;
    // Add the base amount to suggestions
    suggestions.add(baseAmount);
    // Add a higher multiple of 10 for amounts greater than the base
    if (baseAmount < orderTotal) {
      suggestions.add(baseAmount + 10);
    }
    // Determine the base amount (nearest hundreds)
    const baseAmountHundred = Math.ceil(orderTotal / 100) * 100;
    // Add the base amount to suggestions
    suggestions.add(baseAmountHundred);
    // Add a higher multiple of 100 for amounts greater than the base
    if (baseAmountHundred < orderTotal) {
      suggestions.add(baseAmountHundred + 100);
    }
    // Suggest a rounded amount like 50 if the order total is close to 50
    if (orderTotal < 50) {
      suggestions.add(50);
    }
    // Suggest rounded amounts for larger orders
    for (let i = 1000; i <= 100000; i *= 10) {
      if (orderTotal >= i) {
        suggestions.add(Math.ceil(orderTotal / i) * i);
      }
    }
    // Add coin denominations to suggestions for tiny amounts
    if (orderTotal <= 5) {
      for (const denom of coinDenominations) {
        if (orderTotal < denom) {
          suggestions.add(denom);
        }
      }
    }
    return Array.from(suggestions)
      .sort((a: number, b: number) => a - b)
      .slice(0, 4);
  };

  useEffect(() => {
    setSuggestions(
      !finalTotal || finalTotal === 0 ? [] : suggest(parseFloat(finalTotal))
    );
  }, [finalTotal]);

  const suggestionLabel = (suggestion) => {
    const suggestionIndex = coinDenominations.indexOf(suggestion);
    if (suggestionIndex === -1) return suggestion;
    return coinValues[suggestionIndex].name;
  };

  const handlePoNumberChange = (e) => {
    if (e.target.value === "" || poNumberRegex.test(e.target.value)) {
      dispatch(isPurchaseOrderNoEdited(true))
      const data = {
        ...posFlowData,
        poNumber: e.target.value,
      };
      dispatch(setPosFlowData(data));
      setPoNumberSave(e.target.value);
    }
  };

  return (
    <div>
      <div className="pricing-modal-header">
        <div className="payment-option-heading">
          <div>
            <PosPageHeading heading="Payment" grayHeading />
            <div className="pricing-schedule-heading-checkout mb-4">
              <PosPageHeading
                id="cashDrawerHeader"
                heading={`Collect $${finalTotal} in Cash`}
                blackHeading
              />
            </div>
          </div>
        </div>
      </div>
      <OrderInfo
        subTotal={subTotal}
        taxAmount={taxAmount}
        shipTaxAmount={shipTaxAmount}
        orderId={orderId}
        shippingTaxAmount={shippingTaxAmount}
        salesPersonName={salesPersonName}
        shipToTaxFlag={shipToTaxFlag}
        shippingFee={shippingFee}
        creditAmtApplied={creditAmtApplied}
      />
      <div className="d-flex">
        <div>
          <span className="int-16-reg">{tenderAmountTitle}</span>
          <div
            className="margintop-10"
            ref={inputRef}
          >
            <PosInput
              variant="outlined"
              id="standard-basic"
              autoFocus
              value={tenderAmount}
              onFocus={(e) => {
                e.target.select();
              }}
              InputProps={{
                // disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start" className="start-dollar-icon">
                    $
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                const validated = e.target.value.match(oneDecimal);
                if (validated) {
                  setTenderAmount(e.target.value);
                }
              }}
              className="double-bordered-input"
              onBlur={() => {
                if (tenderAmount) {
                  setTenderAmount(Number(Number(tenderAmount)?.toFixed(2)));
                }
              }}
            />
          </div>
          <div className="new-tender-options">
            {suggestions?.map((suggestion) => (
              <PosButton
                key={suggestion}
                title={`${title.tenderSuggestionTitle} ${suggestionLabel(
                  suggestion
                )}`}
                variant="contained"
                color="secondary"
                className="tender-amount-options button-focus"
                onClick={() => {
                  setTenderAmount(suggestion);
                }}
              >
                $ {suggestionLabel(suggestion)}
              </PosButton>
            ))}
          </div>
        </div>
        <div className="pl-3 d-flex direction-column">
          {returnCharge === 0 ? (
            <>
              <span className="int-16-reg">{returnChangeTitle}</span>
              <span className="change-amount fw-400 change-amount-disabled">
                ${" "}
                {tenderAmount
                  ? Math.abs(returnCharge).toFixed(2)
                  : staticTenderValue}
              </span>
            </>
          ) : returnCharge > 0 ? (
            <>
              <span className="int-16-reg">{returnChangeTitle}</span>
              <span className="change-amount fw-400">
                ${" "}
                {tenderAmount
                  ? Math.abs(returnCharge).toFixed(2)
                  : staticTenderValue}
              </span>
            </>
          ) : (
            <>
              <span className="int-16-reg">{returnChangeTitle}</span>
              <span className="change-amount fw-400 change-amount-disabled">
                $ {staticTenderValue}
              </span>
            </>
          )}
        </div>
      </div>
      <div className="facility-box mt-4">
        <div className="cash-margin-top">
          <div className="form-field-space-custom-drop-down-div">
            <CashDrawerDropdown
              cashOptionsSelected={cashOptionsSelected}
              setCashOptionsSelected={setCashOptionsSelected}
              setCashDrawerError={setCashDrawerError}
              cashDrawerError={cashDrawerError}
            />
          </div>
          <div className="margintop-10 pay-later-job-input input-btn-spacing-div">
            <PurchaseOrderInput
              value={poNumberSave}
              onChange={handlePoNumberChange}
            />
            <JobNameInput value={tempJobNameSave} onChange={handleJobName} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashComponent;
