import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import useFilterSections from "apps/product/Components/ProductDetails/useSectionFilter";
import CreateGridStarIcon from "apps/product/Components/ProductDetails/CreateGridStarIcon";
import CommonEditButton from "apps/product/Components/ProductDetails/CommonEditButton";
import CommonGridSection from "apps/product/Components/ProductDetails/CommonGridSection";
import ProductStatusShow from "./ProductStatusShow";

import { labels, sectionName } from "apps/product/modules/productDetailsContainer/constants";
import { sectionTitle } from "constants/constant";
import { setDateWithHrMmFormat, setDateWithUsFromNow } from "utils/dayjsFunc";
import { getSingleProductDataFunction } from "sagas/productsDetails/selector";

const ProductDetailsOverview = ({
  statusValue,
  isStarred,
  searchText,
  handleDetailsOverviewSection,
}) => {

  
  const getSingleProductData = useSelector(getSingleProductDataFunction);
  const { details, availability } = getSingleProductData || {}
  const { productId, productName, description, manufacturerName, vendorName, manufacturerCode, dates, productGroupName, breadcrumb } = details || {}
  const { updatedAt, createdAt } = dates || {}
  const { availability: available, daysToShip } = availability || {};
  const { notSet, id, code, name, description: Description, status, manufacturer, vendor, group, category, availability: Availability, daysToShip: DayToShip,addedOn, lastUpdated, addOn, LastUpdated, iD, codes, names, descriptions, statuses, manufact, groups, categories, availabiliti, daystoship
  } = labels;
  const { overview } = sectionName;
  const { Category: sectionTitleCategory, DaysToShip, Availability: sectionTitleAvailability, code: sectionTitleCode, name: sectionTitleName, descriptionTitle, manufacturer: sectionTitleManufacturer, vendor: sectionTitleVendor, status: sectionTitleStatus, } = sectionTitle

  const { showSection } = useFilterSections({ searchText, sectionName: overview, isStarred });

  return (
    <>
      <section className="product-report-section">
        <Grid container className="product-report-container">
          {showSection(iD,id) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={iD} label={id} value={productId} sectionName={overview} />
               <CommonGridSection value={productId}/>
            </Grid>
          ) : null}
          {showSection(codes,code) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={codes} label={code} value={manufacturerCode} sectionName={overview} />
              <CommonGridSection value={manufacturerCode}/>
              <CommonEditButton onClick={handleDetailsOverviewSection(sectionTitleCode)}/>
            </Grid>
          ) : null}
          {showSection(names,name) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={names} label={name} value={productName} sectionName={overview} />
              <CommonGridSection value={productName}/>
              <CommonEditButton onClick={handleDetailsOverviewSection(sectionTitleName)} />
            </Grid>
          ) : null}
          {showSection(descriptions,Description) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={descriptions} label={Description} value={description} sectionName={overview} />
              <CommonGridSection value={description}/>
              <CommonEditButton onClick={handleDetailsOverviewSection(descriptionTitle)} />
            </Grid>
          ) : null}
          {showSection(statuses,status) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={statuses} label={status} value={statusValue?.subLabel} sectionName={overview} />
              <Grid item xs={12} sm={8}>
                <div>
                  <ProductStatusShow
                    statusValue={statusValue}
                  />
                </div>
                <div className="small-text-without-margin gray-text">
                  <span>{statusValue?.subLabel}</span>
                </div>
              </Grid>
              <CommonEditButton onClick={handleDetailsOverviewSection(sectionTitleStatus)} />
            </Grid>
          ) : null}
          {showSection(manufact,manufacturer) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={manufact} label={manufacturer} value={manufacturerName} sectionName={overview} />
              <CommonGridSection value={manufacturerName}/>
              <CommonEditButton onClick={handleDetailsOverviewSection(sectionTitleManufacturer)} />
            </Grid>
          ) : null}
          {showSection(vendor,vendor) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={vendor} label={vendor} value={vendorName} sectionName={overview} />
              <CommonGridSection value={vendorName}/>
              <CommonEditButton onClick={handleDetailsOverviewSection(sectionTitleVendor)} />
            </Grid>
          ) : null}
          {showSection(groups,group) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={groups} label={group} value={productGroupName} sectionName={overview} />
              <Grid item xs={12} sm={8}>
                {productGroupName ? <div>
                  <span>{productGroupName}</span>
                </div> : <div className="small-text-without-margin gray-text">
                  <span>{notSet}</span>
                </div>}
                {/* Might need later {!isOnPriceSchedule ? <div className="d-flex align-items-center mt-2">
                  <MaterialIcon icon="warning" size="20" classes="mr-1 product-detail-warning-color"/>
                  <span>{noPriceSchedule}</span>
                </div> : null} */}
              </Grid>

            </Grid>
          ) : null}
          {showSection(categories,category) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={categories} label={category} value={breadcrumb} sectionName={overview} />
              <Grid item xs={12} sm={8}>
                <div>
                  <span>{breadcrumb}</span>
                </div>
              </Grid>
              <CommonEditButton onClick={handleDetailsOverviewSection(sectionTitleCategory)} />
            </Grid>
          ) : null}
          {showSection(availabiliti,Availability) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={availabiliti} label={Availability} value={available} sectionName={overview} />
              <CommonGridSection value={available}/>
              <CommonEditButton onClick={handleDetailsOverviewSection(sectionTitleAvailability)} />
            </Grid>
          ) : null}
          {showSection(daystoship,DayToShip) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={daystoship} label={DayToShip} value={daysToShip} sectionName={overview} />
              <CommonGridSection value={daysToShip}/>
              <CommonEditButton onClick={handleDetailsOverviewSection(DaysToShip)} />
            </Grid>
          ) : null}
          {showSection(addOn,addedOn) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={addOn} label={addedOn} value={createdAt} sectionName={overview} />
              <Grid item xs={12} sm={8}>
                <div>
                  <span>{createdAt ? setDateWithHrMmFormat(createdAt) : ""}  {dates?.createdByName ? "by " + dates?.createdByName : ""}</span>
                </div>
                <div className="small-text-without-margin gray-text">
                  <span>{createdAt ? setDateWithUsFromNow(createdAt) : ""}</span>
                </div>
              </Grid>
            </Grid>
          ) : null}
          {showSection(LastUpdated,lastUpdated) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className={LastUpdated} label={lastUpdated} value={updatedAt} sectionName={overview} />
              <Grid item xs={12} sm={8}>
                {
                  (!updatedAt && !dates?.updatedByName) ?
                    <div className="small-text-without-margin gray-text">
                      <span>{notSet}</span>
                    </div>
                    : <>
                      <div>
                        <span>{updatedAt ? setDateWithHrMmFormat(updatedAt) : ""}  {dates?.updatedByName ? "by " + dates?.updatedByName : ""}</span>
                      </div>
                      <div className="small-text-without-margin gray-text">
                        <span>{updatedAt ? setDateWithUsFromNow(updatedAt) : ""}</span>
                      </div>
                    </>
                }
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </section>
    </>
  );
};

export default ProductDetailsOverview;
