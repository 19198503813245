import * as React from "react";

const NoDataFound = (props) => (
  <div className="svg-btm-text">
    <svg xmlns="http://www.w3.org/2000/svg" width={180} height={125} {...props}>
      <defs>
        <filter
          id="a"
          x={73.645}
          y={39.336}
          width={84.853}
          height={84.853}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={4} dy={2} />
          <feGaussianBlur result="blur" />
          <feFlood floodColor="#fff" result="color" />
          <feComposite operator="out" in="SourceGraphic" in2="blur" />
          <feComposite operator="in" in="color" />
          <feComposite operator="in" in2="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Group 886">
        <path data-name="Rectangle 261" fill="#fff" d="M0 0h180v125H0z" />
        <g data-name="Group 883">
          <g
            data-name="Rectangle 259"
            transform="translate(0 6)"
            fill="#fff"
            stroke="#ddd"
            strokeWidth={2}
            strokeDasharray={5}
          >
            <rect width={180} height={100} rx={30} stroke="none" />
            <rect x={1} y={1} width={178} height={98} rx={29} fill="none" />
          </g>
          <g data-name="Group 867" transform="translate(-838.969 -440.304)">
            <rect
              data-name="Rectangle 255"
              width={50}
              height={70}
              rx={25}
              transform="translate(903.969 461.304)"
            />
            <g data-name="Group 872">
              <path
                data-name="Path 129"
                d="M917 484.078h24v24h-24Z"
                fill="none"
              />
              <path
                data-name="Path 130"
                d="M933.5 496.078a2.5 2.5 0 1 0-2.5-2.5 2.492 2.492 0 0 0 2.5 2.5Zm-7.5-1a3 3 0 1 0-3-3 2.987 2.987 0 0 0 3 3Zm7.5 3c-1.83 0-5.5.92-5.5 2.75v1.25a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-1.25c0-1.83-3.67-2.75-5.5-2.75Zm-7.5-1c-2.33 0-7 1.17-7 3.5v1.5a1 1 0 0 0 1 1h6v-2.25a3.941 3.941 0 0 1 2.37-3.47 12.283 12.283 0 0 0-2.37-.28Z"
                fill="#fff"
              />
            </g>
          </g>
          <path
            data-name="Rectangle 252"
            d="m135.87 102.975 1.414-1.414 21.213 21.213-1.414 1.414z"
          />
          <path
            data-name="Intersection 2"
            d="M87.482 90.875a30 30 0 0 1 7.376-30.326A29.9 29.9 0 0 1 115 51.781V66a25 25 0 0 1-25 25q-1.275 0-2.518-.125Z"
            fill="#1a72e9"
          />
          <g data-type="innerShadowGroup">
            <g transform="translate(.001 -.004)" filter="url(#a)">
              <g data-name="Path 128" fill="#fff">
                <path d="M94.853 60.547a30 30 0 1 1 0 42.426 30 30 0 0 1 0-42.426Z" />
                <path d="M96.267 61.961a27.909 27.909 0 0 0-8.201 19.799 27.909 27.909 0 0 0 8.201 19.799 27.91 27.91 0 0 0 19.799 8.201 27.909 27.909 0 0 0 19.8-8.201 27.91 27.91 0 0 0 8.201-19.799 27.91 27.91 0 0 0-8.202-19.799 27.909 27.909 0 0 0-19.799-8.201 27.91 27.91 0 0 0-19.799 8.201m-1.414-1.414c11.716-11.716 30.71-11.716 42.427 0 11.715 11.716 11.715 30.71 0 42.426-11.716 11.716-30.711 11.716-42.427 0-11.716-11.716-11.716-30.71 0-42.426Z" />
              </g>
            </g>
            <g data-name="Path 128" fill="none">
              <path d="M94.858 60.549a30 30 0 1 1 0 42.426 30 30 0 0 1 0-42.426Z" />
              <path
                d="M96.272 61.963a27.909 27.909 0 0 0-8.201 19.799 27.909 27.909 0 0 0 8.201 19.799 27.91 27.91 0 0 0 19.799 8.201 27.909 27.909 0 0 0 19.8-8.201 27.91 27.91 0 0 0 8.201-19.799 27.91 27.91 0 0 0-8.202-19.799 27.909 27.909 0 0 0-19.799-8.201 27.91 27.91 0 0 0-19.799 8.201m-1.414-1.414c11.716-11.716 30.71-11.716 42.427 0 11.715 11.716 11.715 30.71 0 42.426-11.716 11.716-30.711 11.716-42.427 0-11.716-11.716-11.716-30.71 0-42.426Z"
                fill="#000"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <span className="btm-svg-textline">Search for a customer to start a new order</span>
  </div>
);

export default NoDataFound;
