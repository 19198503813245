import { motion } from 'framer-motion/dist/framer-motion';
import { useEffect, useRef, useState } from 'react';
import GenerateCashDrawer from 'layouts/DialogComponent/GenerateCashDrawer/GenerateCashDrawer';
import { dialogAnimVariants } from "constants/constant";
import "layouts/css/nav.scss";
import "layouts/css/bookmarks.scss"
import "layouts/css/cashdrawer.scss"

const CashDrawerDialog = (props) => {
    const { visible, onClose, modelName,cashMenuUrl } = props;
    const dialogRef = useRef();
    const [dialogSkrim, setDialogSkrim] = useState(false);
    
    useEffect(() => {
        if (visible) {
            dialogRef.current.focus();
            showDialogSkrim();
        }
    }, [visible]);

    // close the dialog on ESC keypress
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key !== 'Escape') return;
            if (e.repeat) return;
            onClose();
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, []);
    
    const showDialogSkrim = () => {
        document.body.classList.add("noscroll");
        setDialogSkrim(true);
    }

    const hideDialogSkrim = () => {
        document.body.classList.remove("noscroll");
        setDialogSkrim(false);
    }
    const closeAllDialogs = () => {
        onClose(false);
        hideDialogSkrim();
    }

    return (
        visible ? (
            <>
                <div className={`dialog-skrim ${dialogSkrim ? "visible" : ""}`} onClick={closeAllDialogs} />
                
                    <motion.div
                        className="bookmarks-dialog add-bookmark-dialog cashDrawer-dialog"
                        initial='initial'
                        animate='animate'
                        exit='exit'
                        variants={dialogAnimVariants}
                        ref={dialogRef}
                    >
                    <GenerateCashDrawer modelName={modelName} closeAllDialogs={closeAllDialogs} cashMenuUrl={cashMenuUrl}></GenerateCashDrawer>
                    </motion.div> 
            </>
        ) : null
    );
}

export default CashDrawerDialog;
