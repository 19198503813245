import {
  all,
  call,
  put,
  takeLatest,
} from "redux-saga/effects";
import { AnyAction } from "redux";

import * as services from "../../services";
import * as actions from "./actions";
import * as constants from "./constants";

//Worker Saga

function* loadCartAsync(action: AnyAction) {
  
  try {
    const cartData = yield call(services.loadCart, action.payload);
    yield put(actions.loadCartSuccess(cartData));
  } catch (errors) {
    yield put(actions.loadCartError(errors.detail));
  }
}

function* loadCartWatcher() {
  yield takeLatest(constants.LOAD_CART_START, loadCartAsync);
}

function* addNewCustomerInfoAsync(action: AnyAction) {
  try {
    const addNewCustomerInfoData = yield call(
      services.addNewCustomerInfo,
      action.payload
    );

    yield put(
      actions.addNewCustomerSuccess(addNewCustomerInfoData)
    );
  } catch (errors) {
    yield put(actions.addNewCustomerError(errors.detail));
  }
}

function* addNewCustomerInfoWatcher() {
  yield takeLatest(
    constants.ADD_NEW_CUSTOMER_INFO_START,
    addNewCustomerInfoAsync
  );
}

function* savedDraftsListPageAsync(action: AnyAction) {
  try {
    const savedDrafts = yield call(services.savedDraftsListPage, action.payload);
    yield put(actions.savedDraftsListPageSuccess(savedDrafts));
  } catch (errors) {
    yield put(actions.savedDraftsListPageError("errors"));
  }
}

 function* savedDraftsListPageWatcher() {
  yield takeLatest(constants.GET_DRAFTS_START, savedDraftsListPageAsync);
}

export default function* persistSaga() {
  yield all([
    loadCartWatcher(),  
    addNewCustomerInfoWatcher(),
    savedDraftsListPageWatcher()
  ]);
}

