import { MouseEvent } from "react";
import { useTheme } from "@material-ui/core/styles";

import {
  PosIconButton,
} from "apps/pos/Common/uiCore";

import { useStyles } from "./data";

import { TablePaginationActionsProps } from "./type";

import {
  SkipNextIcon,
  SkipPreviousIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "constants/icons";

export const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }: TablePaginationActionsProps) => {

  const classes = useStyles();
  const theme = useTheme();

  const handleFirstPageButtonClick = (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };
  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };
  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };
  const handleLastPageButtonClick = (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <div className={classes.root}>
      <PosIconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        tabIndex={1}
        className={`pagination-button-focus ${classes.nextIcon}`}
      >
        {theme.direction === "rtl" ? <SkipNextIcon /> : <SkipPreviousIcon />}
      </PosIconButton>
      <PosIconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        tabIndex={1}
        className={`pagination-button-focus ${classes.nextIcon}`}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </PosIconButton>
      <PosIconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        tabIndex={1}
        className={`pagination-button-focus ${classes.nextIcon}`}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </PosIconButton>
      <PosIconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        tabIndex={1}
        className={`pagination-button-focus ${classes.nextIcon}`}
      >
        {theme.direction === "rtl" ? <SkipPreviousIcon /> : <SkipNextIcon />}
      </PosIconButton>
    </div>
  );
};