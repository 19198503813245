import { DistributorError, DistributorSuccess } from "./actions";
import { call, put, takeLatest } from "redux-saga/effects";

import { AnyAction } from "redux";
import { DISTRIBUTOR_FETCHING } from "./constants";
import { getDistributor } from "../../services";

export function* DistributorFetchFlow(action: AnyAction): any {
  try {
    const distributorResponse = yield call(
      getDistributor,
      action.distributorId
    );
    yield put(DistributorSuccess(distributorResponse));
  } catch (errors) {
    yield put(DistributorError(errors));
  }
}

function* distributorWatcher() {
  yield takeLatest(DISTRIBUTOR_FETCHING, DistributorFetchFlow);
}

export default distributorWatcher;
