import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/SPButton/Button";

import { springBoardText } from "constants/constant";
import { keys } from "utils/helper";

import * as services from "services";
import {
  getEstimatorNotesRequest,
  getItemActionNotesRequest,
  getJobHighlightsRequest,
  getMajorRepsRequest,
  getNotesRequest,
  getFollowUpNoteRequest
} from "sagas/quoteDetail/actions";
import { setOpenMiniNote } from "sagas/quoteDetail/quoteDetailsSlice";
import { getQuoteReps } from "sagas/quoteDetail/selector";
import Avvvatars from "avvvatars-react";

const NewNoteInput = (props) => {
  const dispatch = useDispatch();
  const quoteReps = useSelector(getQuoteReps)
  const { close, category, quoteId, handlerID, note, setNote, noteLength, notes, preventUnsavedChanges } =
    props;
    const [busy, setBusy] = useState(false);
  const {
    initialNotes,
    estimatorNotes,
    majorReps,
    jobHighlights,
    itemActionNotes,
    save,
    cancel,
    followUpNotes,
  } = springBoardText;
  const isMajorReps = category === majorReps
  const processNewNote = () => {
    setBusy(true);
    apiCall();
  };
  
  const apiCall = async () => {
    let body = {
      handlerID,
      jobQuoteID: quoteId,
    };

    switch (category) {
      case initialNotes:
        body.initialNote = note;
        await services.updateInitialNote(body).then(() => {
          setBusy(false);
          dispatch(getNotesRequest(quoteId));
          close();
        });
        break;
      case estimatorNotes:
        body.estimatorNotes = note;
        await services.updateEstimatorNote(body).then(() => {
          setBusy(false);
          dispatch(getEstimatorNotesRequest(quoteId));
          close();
        });
        break;
      case majorReps:
        body.majorReps = note;
        await services.updateMajorReps(body).then(() => {
          setBusy(false);
          dispatch(getMajorRepsRequest(quoteId));
          close();
        });
        break;
      case jobHighlights:
        body.jobHighlights = note;
        await services.updateJobHighlightNote(body).then(() => {
          setBusy(false);
          dispatch(getJobHighlightsRequest(quoteId));
          close();
        });
        break;
      case itemActionNotes:
        body.itemActionNotes = note;
        await services.updateItemsActionNote(body).then(() => {
          setBusy(false);
          dispatch(getItemActionNotesRequest(quoteId));
          close();
        });
        break;
      case followUpNotes:
        delete body.handlerID;
        body.posterID = handlerID;
        body.notes = note;
        await services.addFollowUpNote(body).then(() => {
          setBusy(false);
          dispatch(getFollowUpNoteRequest(quoteId));
          close();
        });
        break;
      default:
        break;
    }
  };

  const focus = () => {
    inputRef.current.focus();
  };

  const inputRef = useRef();
  const formRef = useRef();

  useEffect(focus, []);

  const handleChange = (e) => {
    setNote(e.target.value);
    dispatch(setOpenMiniNote(false))
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
  };
  
  const handleMajorRepChange = (rep) => {
    const potentialNewLength = note ? note.length + 1 + rep.quoteRepName.length : rep.quoteRepName.length;

    // Check if the new length would exceed or equal the noteLength
    if (potentialNewLength >= noteLength) {
      return; // Do nothing if it exceeds or is equal to the noteLength
    }
    setNote(prevNote => {
      const updatedNote = prevNote ? prevNote + '\n' + rep.quoteRepName : rep.quoteRepName;
      return updatedNote;
    });
  }

  const handleCancelButton = () => {
      close()
  }

  const handleKeyDownEvent = (e) => {
    if (e.keyCode === keys.esc) { 
      if (notes !== note) {
        preventUnsavedChanges(e);
      } else {
        close()
      }
    }
  }

  return (
    <>
      <form onSubmit={processNewNote} ref={formRef} onKeyDown={handleKeyDown}>
        <textarea
          autoFocus
          ref={inputRef}
          className="new-note-input"
          onChange={handleChange}
          disabled={busy}
          value={note}
          rows={20}
          onKeyDown={handleKeyDownEvent}
          maxLength={noteLength ? noteLength : undefined}
        />
      </form>
      {
        isMajorReps ?
          <>
            <div className="suggested-rep-names">
              {quoteReps.map((rep, idx) => (
                <button
                  className='rep-btn'
                  key={idx}
                  type="button"
                  onClick={() => handleMajorRepChange(rep)}
                  style={{ animationDelay: (0.01 * idx) + 's' }}
                >
                  <Avvvatars value={rep.quoteRepName} size={24} />
                  {rep.quoteRepName}
                </button>
              ))}
            </div>
          </> : null
      }
      <footer className="justify-content-between">
        <div
          className={`note-text-limit ${
            note?.length === noteLength ? "text-red" : ""
          }`}
        >
          {noteLength ? note?.length + "/" + noteLength : null}
        </div>
        <div className="d-flex gap-2">
          <Button label={cancel} onClick={handleCancelButton} />
          <Button
            label={save}
            type="primary"
            onClick={processNewNote}
            disabled={busy || (category === followUpNotes && !note?.length) || note === notes}
            loading={busy}
          />
        </div>
      </footer>
    </>
  );
};

export default NewNoteInput;
