import { TableColumn } from "components/TableColumn/TableColumn";

export const EnhancedTableBody = ({
  itemHistory,
  lead = false
}) => {

  return itemHistory?.length ? (
    itemHistory?.map(
      (row, index) => {
        const { monthsAgo, qty, hits, mmyy } = row;
        const monthsAgos = monthsAgo ? monthsAgo.replace(":", "") : "";
        return (
          <div className={`${lead ? "table-body-row-history-lead" : "table-body-row-history"}`} key={index}>
            <TableColumn className="inv-date-col" value={lead ? mmyy : monthsAgos} />
            <TableColumn className="text-right" value={qty} />
            {lead ? null : <TableColumn className="text-right" value={hits} />}
          </div>
        );
      }
    )
  ) : (
    <div className="sales-history-no-data-found">
      No Data Found
    </div>
  )
}