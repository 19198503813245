import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import {
  Button,
  Input,
} from "../../../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";

import { Formik } from "formik";
import * as Yup from "yup";

import { signatureRequest } from "../../../sagas/setting/actions";
import { RootState } from "../../../models";
import { signatureInfo } from "../../../models/setting";
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const SignatureSchema = Yup.object().shape({
  signatureName: Yup.string()
    .min(2, "Too short!")
    .max(30, "Too long!!!")
    .required("Name on signature required."),
  signatureEmail: Yup.string()
    .email("Please Enter valid email")
    .required("Email on signature required."),
  signature: Yup.string().required("Signature required."),
  signatureWithPhone: Yup.string().required(
    "Signature with no phone required."
  ),
});

export function Signatures({distributorId}) {
  const [signatureInfoData, setSignatureInfoData] = useState<signatureInfo>();


  const { signatureInfo } = useSelector<RootState, any>((state) => state.setting);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(signatureRequest(distributorId));
  },[]);

  useEffect(()=>{
    setSignatureInfoData(signatureInfo);
  },[signatureInfo])  

  return (
    
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Signatures</div>
        <div className="devider2px" />

        <Formik
          initialValues={{
            signatureName: signatureInfoData?.nameOnSignature,
            signatureEmail: signatureInfoData?.emailOnSignature,
            signature: signatureInfoData?.signature,
            signatureWithPhone: signatureInfoData?.signatureWithNoPhone,
          }}
          enableReinitialize= {true}
          validationSchema={SignatureSchema}
          onSubmit={() => {
            // Required Later
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => {
            return (
              <form
                // onSubmit={handleSubmit}
                className="formContainer"
                id="addSignatureForm"
              >
                <Input
                  textInputStyle="mt-1"
                  id={"signatureName"}
                  name={"signatureName"}
                  label={"Name on Signature"}
                  value={values.signatureName}
                  onChange={handleChange}
                  type="text"
                  placeholder={""}
                />

                <div className="decription">
                  Describe for this field goes here in this space
                </div>

                {errors.signatureName && touched.signatureName ? (
                  <div className="validation-error-text-sp">
                    <>{errors.signatureName}</>
                  </div>
                ) : null}

                <Input
                  textInputStyle="mt-1"
                  id={"signatureEmail"}
                  name={"signatureEmail"}
                  value={values.signatureEmail}
                  onChange={handleChange}
                  label={"Email on Signature"}
                  type="text"
                  placeholder={""}
                />

                <div className="decription">
                  Describe for this field goes here in this space
                </div>

                {errors.signatureEmail && touched.signatureEmail ? (
                  <p className="validation-error-text-sp">
                    <>{errors.signatureEmail}</>
                  </p>
                ) : null}

                <Input
                  textInputStyle="mt-1"
                  id={"signature"}
                  name={"signature"}
                  label={"Signature"}
                  value={values.signature}
                  onChange={handleChange}
                  type="text"
                  placeholder={""}
                />

                <div className="decription">
                  Describe for this field goes here in this space
                </div>

                {errors.signature && touched.signature ? (
                  <div className="validation-error-text-sp">
                    {errors.signature}
                  </div>
                ) : null}

                <Input
                  textInputStyle="mt-1"
                  id={"signatureWithPhone"}
                  name={"signatureWithPhone"}
                  label={"Signature With No Phone"}
                  value={values.signatureWithPhone}
                  onChange={handleChange}
                  type="text"
                  placeholder={""}
                />

                <div className="decription">
                  Describe for this field goes here in this space
                </div>

                {errors.signatureWithPhone && touched.signatureWithPhone ? (
                  <div className="validation-error-text-sp">
                    {errors.signatureWithPhone}
                  </div>
                ) : null}

                <div className="devider1px" />

                <Button
                  type="submit"
                  className="manage-btn"
                  data-bs-toggle="modal"
                  onClick={handleSubmit}
                  data-bs-target="#jumper_modal"
                >
                  <span className="manage-button-txt">save changes</span>
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </PerfectScrollbar>
  );
}
