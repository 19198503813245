import { constantCustomerCode } from "apps/pos/pages/customerInfo/Modal/type";

import { PosModel, PosButton, PosImage } from "apps/pos/Common/uiCore";
import { Button } from "components";
import { ClearIcon, HourglassTopIcon } from "constants/icons";
import { title } from "constants/constant";
import images from "constants/images";

import "./AddNewCustomerModal.scss";

const AddNewCustomerModal = ({
    accountRegisteredModel,
    setAccountRegisteredModel,
}) => {

    const { updateLogo } = images
    const { close } = constantCustomerCode
    const { registeredAccount, awaitingApproval, approvalEmail } = title

    return (
        <>
            <PosModel
                open={accountRegisteredModel}
                onClose={setAccountRegisteredModel}
                className="set-customer-code-modal"
                dialogClassName="set-customer-code-modal-container"
                dialogHeader={
                    <div className="modal-header">
                        <div className="account-register-titles-modal-header d-flex justify-content-between w-100 pb-3">
                            <div className="d-flex flex-column account-register-titles">
                                <div className="d-flex mb-2 align-items-center">
                                    <PosImage src={updateLogo} alt='register-icon' className="mr-2" />
                                    <span className="modal-title">
                                        {registeredAccount}
                                    </span>
                                </div>
                                <div className="d-flex awaiting-title align-items-center">
                                    <HourglassTopIcon className="mr-2" />
                                    <span className="modal-title">
                                        {awaitingApproval}...
                                    </span>
                                </div>
                            </div>
                            <Button className="border-0 bg-transparent  p-0" onClick={setAccountRegisteredModel}>
                            <ClearIcon
                                className="clear-icon"
                            />
                            </Button>
                        </div>
                    </div>
                }
                dialogFooter={
                    <>
                        <div className="d-flex">
                            <PosButton
                                type="button"
                                variant="contained"
                                color="primary"
                                className="add-to-return-cart-button mr-2 button-focus-class"
                                fontWeight
                                onClick={setAccountRegisteredModel}
                            >
                                {close}
                            </PosButton>
                        </div>
                    </>
                }
            >
                <div>
                    <span className="text-black">{approvalEmail}</span>
                </div>
            </PosModel>
        </>
    );
};
export default AddNewCustomerModal;