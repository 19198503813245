import { makeStyles, TableCell, TableRow, withStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 500,
  },
  root: {
    position: "unset",
    "& .MuiTableHead-root": {
      position: "unset",
      color: "black",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: 30,
      borderBottomLeftRadius: 30,
    },
  },
  headerWidthReturn: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  returnSearchHeading: {
    marginTop: "4%",
    fontSize: "1rem",
  },
  noSearchReturnDiv: {
    display: "flex",
    margin: "9.0625rem",
    justifyContent: "center",
    height: "19.375rem"
  },
}));

export const StyledTableRow = withStyles(() => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  rows: {
    borderBottom: "1px solid darkGray",
  },
}))(TableRow);

export const StyledTableCell = withStyles(() => ({
  head: {
    color: "#aaa",
    fontSize: 11,
    fontFamily: "Inter, Regular",
    paddingLeft: 0,
    borderBottomWidth: 0,
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "Inter, Regular",
    borderBottomWidth: 0,
    paddingLeft: 0,
  },
}))(TableCell);

export default useStyles;
