import React, { ReactElement, ReactNode } from "react";
import '@fontsource/inter';

import styled from "styled-components";

import "../../../styles/admin/UserList/UserListStyle.css"


export interface Tab {
  id: string;
  label: string | ReactElement;
  selected?: boolean;
  disabled?: boolean;
  content: ReactNode | void;
  onClick?: () => void;
}
export interface TabNavigationProps {
  className?: string;
  tabs: Tab[];
}

const TabHeader = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: flex-start;

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-color: transparent transparent #000 transparent !important;
    background-color: transparent;
    border-bottom: 3px solid transparent;
  }

  .nav-tabs .nav-item .nav-link.disabled {
    color: #a7aeb8 !important;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: transparent;
    background-color: transparent;
  }

  .nav-tabs .nav-link:hover {
    color: #000000 !important;
  }
`;

export function TabsNavigation({
  className,
  tabs,
  ...props
}: TabNavigationProps) {

  return (
    <div>
      <TabHeader
        className={`card-header border-bottom tab-style ${
          className ? className : ""
        }`}
        {...props}
      >
        <ul
          className="nav nav-tabs card-header-tabs"
          id="cardTab"
          role="tablist"
        >
          {tabs.map((tab, i) => {
            return (
              <li key={i} className="nav-item">
                <a
                  className={`nav-link fs-14 inter-reg ${
                    tab.selected ? "active" : ""
                  } ${tab.disabled ? "disabled" : ""}`}
                  id={`${tab.id}-tab`}
                  data-toggle="tab"
                  role="tab"
                  aria-disabled={tab.disabled}
                  aria-controls={tab.id}
                  aria-selected={tab.selected}
                  onClick={() => {
                    tab.onClick();
                  }}
                >
                  {tab.label}
                </a>
              </li>
            );
          })}
        </ul>
      </TabHeader>
      <div className="card-b inboxlists">
        <div className="tab-content" id="cardTabContent">
          {tabs.map((tab, i) => (
            <div
              key={i}
              className={`tab-pane fade ${tab.selected ? "show active" : ""} ${
                tab.disabled
              }`}
              id={tab.id}
              role="tabpanel"
              aria-labelledby={`${tab.id}-tab`}
            >
              {tab.content ? tab.content : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
