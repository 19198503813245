import { useMutation } from '@tanstack/react-query';
import { storageShipmentsUrlV2 } from './constantUrl';
import { stopLoading } from 'utils/utils';
import { useCallback } from 'react';

export const useGetStorageShipments = () => {
  const { isLoading, data, mutate } = useMutation(
    async ({stockWarehouseId, customerId}: any) => {
      return storageShipmentsUrlV2(stockWarehouseId, customerId).then((response) => {
        return response.data;
      });
    },
    {
      retry: false,
      onSuccess: stopLoading,
      onError: stopLoading
    },
  );
  
  const getStorageShipmentData = useCallback(
    (stockWarehouseId, customerId) => {
      mutate({stockWarehouseId, customerId});
    },
    [mutate]
  );

  return { storageShipments: data, getStorageShipmentsIsLoading: isLoading, getStorageShipmentData };
};