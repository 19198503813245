import React from 'react';
import { useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";

import QuoteInfoSub from "apps/springboard/Component/QuoteMetadata/QuoteInfoSub";

import { QuoteMetaInfoProps } from 'constants/interfaces';

import { manipulateStarredMetadataSb } from "sagas/persistReducer/actions";
import { StarredMetadataSb } from 'sagas/persistReducer/type';


const QuoteMetaInfo: React.FC<QuoteMetaInfoProps> = ({ quoteMetadataDetails, searchTerm, starredMetadataSb, isStarComponent }) => {
  const dispatch = useDispatch();
  const handleStarButtonClick = (key, value, title) => {
    const starredItemIndex = starredMetadataSb.findIndex(item =>
      Object.keys(item.details).includes(key) && item.isStarred
    );
    const updatedStarredMetadataSb = [...starredMetadataSb];
    if (starredItemIndex !== -1) {
      updatedStarredMetadataSb.splice(starredItemIndex, 1);
    } else {
      updatedStarredMetadataSb.push({ title, details: { [key]: '' }, isStarred: true });
    }
    dispatch(manipulateStarredMetadataSb(updatedStarredMetadataSb));
  };

  const renderQuotesMetadata = (data: StarredMetadataSb[]) => (
    <section className={`${searchTerm ? "mt-4" : "" } quote-metadata-section`}>
      <Grid container className="quote-metadata-container">
        {data.map(({ title, details, isStarred }) =>
          Object.entries(details).map(([key, value]) => {
            const starredItem = starredMetadataSb.find(item =>
              Object.keys(item.details).includes(key) && item.isStarred
            );
            const isItemStarred = starredItem?.isStarred;
            if (!searchTerm || key.toLowerCase().includes(searchTerm.toLowerCase())) {
              return (
                <QuoteInfoSub
                  key={key}
                  value={value}
                  isStarred={isStarred}
                  isItemStarred={isItemStarred}
                  handleStarButtonClick={handleStarButtonClick}
                  datakey={key}
                  title={title}
                />
              );
            }
            return null;
          })
        )}
      </Grid>
    </section>
  );

  return (
    <>
      {isStarComponent ? renderQuotesMetadata(quoteMetadataDetails) :
        searchTerm ? renderQuotesMetadata(quoteMetadataDetails.filter(data => data.details && Object.values(data.details).some(value => value))) :
          quoteMetadataDetails.map(({ title, details, isStarred }) => {
            const hasDetails = Object.values(details).some(value => value)
            if (hasDetails){
              return (
                <React.Fragment key={title}>
                  {Object.values(details).some(value => value) && !isStarred && (
                    <div className="d-flex justify-content-between main-title-section overview-tab" id="DetailsSection">
                      <div className="sec-main-title">
                        <span>{title}</span>
                      </div>
                    </div>
                  )}
                  <div className="product-detail-section-bottom-border" />
                  {renderQuotesMetadata([{ title, details, isStarred }])}
                </React.Fragment>
              )
            }
            })
      }
    </>
  );
};

export default QuoteMetaInfo;
