import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "loadsh";
import { Box } from "@material-ui/core";
import "@fontsource/inter";

import {
  PosPageHeading,
  PosInput,
  PosIconButton,
  Container,
} from "apps/pos/Common/uiCore";
import SelectTakerModal from "./SelectTakerModal/SelectTakerModal";
import SavedOrdersFound from "./SavedOrdersFound";

import { scrollOnContent } from "utils/utils";
import { ClearIcon, SkipNextIcon, SearchIcon } from "constants/icons";
import { getContentById, keys, renamePersonData } from "utils/helper";

import { PosResponseProps, RootState } from "models";
import {
  loadCartRequest,
  loadCartSuccessClear,
  setPosFlowData,
} from "sagas/persistReducer/actions";
import {
  arrowDraftDownIndex,
  arrowDraftUpIndex,
  priceSchedulesRequest,
  updatePriceSchedulesStart,
  arrowDraftReset
} from "sagas/pos/actions";
import { useNavigate } from "react-router-dom";
import { Paths } from "enums";
import { PosFlowDataProp } from "constants/interfaces";

const PricingScheduleModal = (props) => {
  const {
    state,
    setState,
    selectSchedule,
    showTaker,
    setSelectSchedule,
    newOrderShippingAddress,
    onCloseDrawer,
    drawerRef,
  } = props;

  const searchBuildingRef = useRef<any>();

  const [drawerState, setDrawerState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { posFlowData, isLoadCartSuccess, loadCartDetails } = useSelector<
    RootState,
    PosFlowDataProp
  >((state) => state.persistReducer);
  const {
    priceScheduleDetails,
    selectedDraftIndex,
    isLoading,
    draftLoadMessage,
    savedDrafts,
  } = useSelector<RootState, PosResponseProps>((state) => {
    return { ...state.pos, ...state.persistReducer };
  });
  const [pageSize, setPageSize] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState([]);
  const [priceSchedulesData, setpriceSchedulesData] = useState<any>([]);
  const [enterPress, setEnterPress] = useState(false);
  const [changeHeadingText, setChangeHeadingText] = useState(false);
  const [doesHaveMore, setHaveMore] = useState(true);
  const [downPress, setDownPress] = useState(false);
  const [upPress, setUpPress] = useState(false);
  const [draftCartModal, setDraftCartModal] = useState(false);

  useEffect(() => {
    if (!priceScheduleDetails?.data?.length) {
      dispatch(priceSchedulesRequest({ pageSize, size: 20 }));
    }
  }, []);

  useEffect(() => {
    if (priceScheduleDetails?.data?.length) {
      setpriceSchedulesData(
        _.uniqBy([...priceSchedulesData, ...priceScheduleDetails?.data], "id")
      );
    }
  }, [priceScheduleDetails, priceSchedulesData.length]);

  function handleScroll() {
    setPageSize(pageSize + 1);
    if (priceSchedulesData?.length === priceScheduleDetails?.total) {
      setHaveMore(false);
    }
    if (priceSchedulesData?.length !== priceScheduleDetails?.total) {
      dispatch(priceSchedulesRequest({ pageSize: pageSize, size: 20 }));
    }
  }
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    if (!searchString) {
      setSearchData(priceSchedulesData);
    }
  }, [searchString]);

  useEffect(() => {
    if (draftLoadMessage) {
      setDraftCartModal(true);
      setDrawerState({ ...drawerState, right: true });
    }
  }, [draftLoadMessage]);

  const currentTaker = JSON.parse(localStorage.getItem("taker"));
  const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));

  useEffect(() => {
    if (isLoadCartSuccess && loadCartDetails?.cartId) {
      Boolean(loadCartDetails?.cartId) &&
        navigate(`${Paths.posCart}/${loadCartDetails?.cartId}`);
    }
    dispatch(loadCartSuccessClear(false));
  }, [isLoadCartSuccess]);

  const frequentlyUsed = JSON.parse(localStorage.getItem("schedule")) || [];
  const { data : scheduleData } = priceScheduleDetails || {}
  
  const availSchedules = scheduleData?.filter(price =>
    frequentlyUsed.some(schedules => price.id === schedules.id)
  ) || [];

  const setSchedulesDataWithDrawer = async (item) => {
    const data = {
      ...posFlowData,
      schedule: item,
      customerInfoUpdated: true,
      scheduleAdded: true
    };

    const oldSchedules = JSON.parse(localStorage.getItem("schedule")) || [];
    const itemIndex = _.findIndex(oldSchedules, item);
    if (itemIndex !== -1) {
      oldSchedules.splice(itemIndex, 1);
    }
    oldSchedules.unshift(item);
    oldSchedules.splice(5);
    localStorage.setItem("schedule", JSON.stringify(oldSchedules));

    if (!selectSchedule) {
      dispatch(
        updatePriceSchedulesStart({
          customerId: posFlowData?.customer?.customerId,
          priceScheduleId: item.id,
        })
      );
    }
    dispatch(setPosFlowData(data));
    if (currentTaker?.fullName) {
      const preparedData = {
        stockWarehouseId:
          currentWorkspace?.stockWarehouseId ||
          posFlowData?.workspace?.stockWarehouseId,
        customerId: posFlowData?.customer?.customerId,
        takerId: currentTaker?.personId,
        cartId: null,
      };
      dispatch(loadCartRequest(preparedData));
    }
    if (showTaker) {
      setDrawerState({ ...drawerState, right: true });
    }
    setSelectSchedule(false);
  };
  const draftRowRef = useRef<any>();
  const draftSecondRowRef = useRef<any>();

  const scrollToSetting = (selectedDraftIndex) => {
    let scrollIndex;
    if (selectedDraftIndex < availSchedules?.length) {
      scrollIndex = selectedDraftIndex;
    } else {
      scrollIndex = selectedDraftIndex - availSchedules?.length;
    }

    const elementId =
      selectedDraftIndex < availSchedules?.length
        ? document?.getElementById(`draftRowRef${scrollIndex}`)
        : document?.getElementById(`draftSecondRowRef${scrollIndex}`);

    elementId?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  useEffect(() => {
    if (downPress) {
      const dataLength = availSchedules?.length
        ? availSchedules?.length + priceSchedulesData?.length
        : priceSchedulesData?.length;
      scrollToSetting(selectedDraftIndex + 1);
      dispatch(arrowDraftDownIndex(dataLength));
      setDownPress(false);
    }
  }, [downPress]);

  useEffect(() => {
    if (upPress) {
      if (selectedDraftIndex > -1) {
        scrollToSetting(selectedDraftIndex);
      }
      setUpPress(false);
    }
  }, [upPress]);

  function handleKeyPress(e) {
    const { keyCode } = e;
    const { d } = keys;  
    if (
      document?.activeElement !== searchBuildingRef.current
    ) {
      if (keyCode === d) {
        onCloseDrawer();
      }
    } else if (keyCode === 40) {
      //need this
      // setPageSize(pageSize + 1);
      // if (priceSchedulesData?.length === priceScheduleDetails?.total) {
      //   setHaveMore(false);
      // }
      // dispatch(priceSchedulesRequest({ pageSize: pageSize + 1, size: 20 }));
      setDownPress(true);
    } else if (keyCode === 38) {
      dispatch(arrowDraftUpIndex());
      setUpPress(true);
    } else if (keyCode === 13) {
      setEnterPress(true);
    }
  }

  const handleSelectPriceSchedule = async ({item}) => {
    const currentTaker = JSON.parse(
      await localStorage.getItem("taker")
    );

    if (currentTaker?.personId) {
      setSchedulesDataWithDrawer(item);
    } else {
      const data = {
        ...posFlowData,
        schedule: item,
      };
      dispatch(setPosFlowData(data));
      setDrawerState({
        ...drawerState,
        right: true,
      });
    }
  }

  useEffect(() => {
    if (enterPress) {
      if (searchData.length) {
        selectScheduleList(searchData[selectedDraftIndex]);
      } else {
        if (
          selectedDraftIndex > -1 &&
          selectedDraftIndex < availSchedules?.length
        ) {
          selectScheduleList(frequentlyUsed[selectedDraftIndex]);
        }
        if (
          (selectedDraftIndex > -1 &&
            selectedDraftIndex > availSchedules.length) ||
          (selectedDraftIndex > -1 &&
            selectedDraftIndex === availSchedules.length)
        ) {
          selectScheduleList(
            priceSchedulesData[selectedDraftIndex - availSchedules.length]
          );
        }
      }
      setEnterPress(false);
    }
  }, [enterPress]);

  const selectScheduleList = (item) => {
    const currentTaker = JSON.parse(localStorage.getItem("taker"));

    if (currentTaker?.personId) {
      setSchedulesDataWithDrawer(item);
    } else {
      const data = {
        ...posFlowData,
        schedule: item,
      };
      dispatch(setPosFlowData(data));
      setDrawerState({ ...drawerState, right: true });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const takerSelected = JSON.parse(localStorage.getItem("taker"));

  useEffect(() => {
    scrollOnContent();
  }, []);

  // start
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const contentSec = getContentById();

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, [contentSec]);

  useEffect(() => {
    const elHeight = document.getElementById("drawer-header")?.clientHeight;
    if (contentSec && matches) {
      contentSec.style.paddingTop = `${elHeight + 20}px`;
    } else if (contentSec && !matches) {
      contentSec.style.paddingTop = "0px";
    }
  }, [contentSec, matches]);

  // end

  return (
    <>
      <Box role="presentation" className="h-100 modal-box">
        {newOrderShippingAddress ? (
          <Fragment></Fragment>
        ) : (
          <PosIconButton
            className="back-next-btn-small btn responsive-back-btn pos-icon-back-btn"
            onClick={onCloseDrawer}
            tabIndex={-1}
          >
            <ClearIcon className="back-icon" />
          </PosIconButton>
        )}
        <Container
          className="modal-content-style modal-container-styling"
          ref={drawerRef}
          id="noScrollContent"
        >
          {!takerSelected && (
            <SelectTakerModal
              newCustomerTakerClass
              state={drawerState}
              setState={setDrawerState}
            />
          )}
          <main>
            <div>
              <div className="drawer-static-header" id="drawer-header">
                <div>
                  <div className="pricing-modal-header">
                    <PosPageHeading
                      // heading={`(${posFlowData?.customer?.posCustCode}) ${posFlowData?.customer?.companyName}`}
                      heading={renamePersonData(
                        posFlowData?.customer?.posCustCode,
                        posFlowData?.customer?.companyName
                      )}
                      grayHeading
                    />
                    <h1 className="pricing-schedule-heading">
                      <PosPageHeading
                        heading="Select Pricing Schedule"
                        blackHeading
                      />
                    </h1>
                  </div>
                  {posFlowData?.customer?.priceScheduleId ? (
                    <div
                      className="modal-select-default-btn schedule-section"
                      onClick={() => setState({ ...state, right: false })}
                    >
                      <div className="select-default-text">
                        <SkipNextIcon />
                        <div className="default-schedule-heading">
                          <span className="int-14-reg-white">
                            Keep current schedule
                          </span>
                          <span className="schedule-number-info">
                            {posFlowData?.schedule?.name
                              ? posFlowData?.schedule?.name
                              : posFlowData?.customer?.priceScheduleId
                              ? posFlowData?.customer?.priceScheduleName
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div>
                        <span className="schedule-shortcut">D</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {priceScheduleDetails?.data?.length && (
                  <>
                    <div className="search-with-close-input">
                      <div className="search-icon">
                        <SearchIcon />
                      </div>
                      <PosInput
                        variant="outlined"
                        id="outlined-basic"
                        className="double-bordered-input"
                        placeholder="Search Pricing Schedule"
                        inputRef={searchBuildingRef}
                        value={searchString}
                        autoFocus
                        onChange={(e) => {
                          if (
                            !e.target.value.includes("  ") &&
                            e.target.value.charAt(0) !== " "
                          ) {
                            if (e.target.value) {
                              setChangeHeadingText(true);
                            }
                            setSearchString(e?.target?.value);
                            const searchValue = e?.target?.value.toLowerCase();
                            setSearchData(
                              priceSchedulesData?.filter((search) =>
                                search.name?.toLowerCase().includes(searchValue)
                              )
                            );
                            dispatch(arrowDraftReset())
                          }
                        }}
                      />
                      <div
                        className="close-icon"
                        onClick={() => setSearchString("")}
                      >
                        <ClearIcon />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {priceScheduleDetails?.data?.length ? (
                <>
                  <div
                    className="drawer-content-scroll modal-subheading mb-0 mt-0 position-relative drawer-bottom-sec-styling pb-0"
                    id="shippingScrollStyling"
                  >
                    {(searchData?.length &&
                      searchData?.length === priceSchedulesData?.length) ||
                    !searchData?.length ? (
                      <div className="pricing-frequently">
                        {availSchedules?.length ? (
                          <span className="modal-subheading mb-0 mt-0">
                            FREQUENTLY USED
                          </span>
                        ) : (
                          <></>
                        )}
                        {availSchedules?.length
                          ? availSchedules?.map((item, index) => {
                              return (
                                <div
                                  className="all-schedules-names mt-0"
                                  key={index}
                                >
                                  <div
                                    key={item.id}
                                    className={
                                      index === selectedDraftIndex
                                        ? "frequently-used-schedule frequently-used-schedule-index"
                                        : "frequently-used-schedule"
                                    }
                                    onClick={() => {
                                      setSchedulesDataWithDrawer(item);
                                      setSelectSchedule(false);
                                    }}
                                  >
                                    <h3 className="select-schedule-list schedule-list-styling">
                                      {item.name}{" "}
                                    </h3>
                                    <i className="fas fa-arrow-right right-icon"></i>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className={`${
                        availSchedules?.length ? "" : "mt-0"
                      } all-pricing-schedules`}
                    >
                      {!searchData.length && changeHeadingText ? (
                        <span className="modal-subheading mb-0">
                          No search results found
                        </span>
                      ) : (
                        <span
                          className={`${
                            availSchedules?.length ? "" : "mt-0"
                          } modal-subheading mb-0`}
                        >
                          ALL SCHEDULES
                        </span>
                      )}

                      <div className="scroll-styling">
                        <InfiniteScroll
                          dataLength={priceSchedulesData?.length || ""}
                          next={() => {
                            handleScroll();
                          }}
                          height={
                            posFlowData?.customer?.priceScheduleId ? 620 : 700
                          }
                          hasMore={doesHaveMore}
                          loader={
                            doesHaveMore ? <div></div> : <h4>Limit Reached</h4>
                          }
                        >
                          {priceSchedulesData?.length && searchData.length
                            ? searchData?.map((item, index) => {
                                return (
                                  <div
                                    key={item.id}
                                    ref={draftRowRef[index]}
                                    id={`draftRowRef${index}`}
                                    className={
                                      index === selectedDraftIndex
                                        ? "frequently-used-schedule frequently-used-schedule-index"
                                        : "frequently-used-schedule"
                                    }
                                    onClick={()=>handleSelectPriceSchedule({item})}
                                  >
                                    <h3 className="select-schedule-list">
                                      {item.name}
                                    </h3>
                                    <i className="fas fa-arrow-right right-icon"></i>
                                  </div>
                                );
                              })
                            : priceSchedulesData?.length &&
                              !searchString &&
                              priceSchedulesData?.map((item, index) => {
                                return (
                                  <div
                                    ref={draftSecondRowRef[index]}
                                    id={`draftSecondRowRef${index}`}
                                    className="all-schedules-names"
                                    key={index}
                                  >
                                    <div
                                      key={item.id}
                                      className={
                                        index + availSchedules?.length ===
                                        selectedDraftIndex
                                          ? "frequently-used-schedule frequently-used-schedule-index"
                                          : "frequently-used-schedule"
                                      }
                                      onClick={()=>handleSelectPriceSchedule({item})}
                                    >
                                      <h3 className="select-schedule-list">
                                        {item.name}
                                      </h3>
                                      <i className="fas fa-arrow-right right-icon"></i>
                                    </div>
                                  </div>
                                );
                              })}
                        </InfiniteScroll>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                !isLoading && (
                  <span
                    className="modal-subheading mb-0 mt-0"
                    style={{ fontSize: 12, textAlign: "center" }}
                  >
                    No schedules found
                  </span>
                )
              )}
            </div>
          </main>
          {draftCartModal && savedDrafts?.data?.length ? (
            <SavedOrdersFound state={drawerState} setState={setDrawerState} />
          ) : (
            <></>
          )}
          <section className="modal-button"></section>
        </Container>
      </Box>
    </>
  );
};

export default PricingScheduleModal;
