import useMeasure from "react-use-measure";
import { motion } from 'framer-motion/dist/framer-motion';

const ResizablePanel = (props) => {
    const { children } = props;
    let [ref, bounds] = useMeasure();


    return (
        <motion.div
            animate={{ height: bounds.height > 0 ? bounds.height : 'auto' }}
        >
            <div ref={ref}>
                {children}
            </div>
        </motion.div>
    )
}

export default ResizablePanel;