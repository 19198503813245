import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { InputAdornment } from "@mui/material";

import { PosImage, PosInput } from "apps/pos/Common/uiCore";

import { CloseIcon, SearchIcon } from "constants/icons";
import { title } from "constants/constant";
import images from "constants/images";

import "./searchCommonComponent.scss";
import { useDispatch } from "react-redux";
import { returnSearchedCustomerDataAction, returnSearchedInvoiceDataAction, returnSearchedProductDataAction } from "sagas/returns/actions";

interface Props {
    searchProductValue?: string;
    searchProductOnClick?: () => void;
    searchProductOnChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
    searchProductImage?: string
    searchCustomerValue?: string | number;
    searchCustomerOnClick?: () => void;
    searchCustomerOnChange?: (e) => void;
    customerKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
    checkProduct?: () => void;
    searchFromReturnCart?: boolean;
}

const SearchCommonComponent = ({
    searchProductValue,
    searchProductOnClick,
    searchProductOnChange,
    searchProductImage,
    searchCustomerValue,
    searchCustomerOnClick,
    searchCustomerOnChange,
    customerKeyPress,
    onKeyDown,
    searchFromReturnCart
}: Props) => {
    const [avatarListVisible, SetAvatarListVisible] = useState(false);
    const [searchedProductInfo, setSearchedProductInfo] = useState(searchProductValue);

const dispatch = useDispatch();

    const returnCustomerSearchRef = useRef<HTMLInputElement>();

    function handleKeyDown(e) {
        const { keyCode } = e;
        if (keyCode === 191 || keyCode === 111) {
            setTimeout(() => {
                returnCustomerSearchRef?.current?.focus();
                SetAvatarListVisible(true);
            }, 1);
        }
    }

    useEffect(() => {
        document?.addEventListener("keydown", handleKeyDown);
        return function cleanup() {
            document?.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const handleFocus = () => {
        SetAvatarListVisible(true)
    }

    useEffect(() => {
        if (searchProductValue) {
            setSearchedProductInfo(searchProductValue)
        }
    }, [searchProductValue])

    const {
        defaultIcon
    } = images;

    return (
        <>
            <div className="return-cart-search-input-container">
                <PosInput
                    autoFocus={!searchFromReturnCart}
                    variant="filled"
                    label=''
                    InputProps={{
                        disableUnderline: true,
                        readOnly: false,
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className="search-order-input-first-adornment"
                            >
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            avatarListVisible && <InputAdornment
                                position="start"
                                className="search-order-input-last-adornment"
                            >
                                <div className="to-enter-search-div" 
                                onClick={() => {
                                    dispatch(returnSearchedCustomerDataAction(''))
                                    dispatch(returnSearchedInvoiceDataAction(''))
                                }}
                                >
                                    <span>Press Enter</span>
                                    <PosImage
                                        src={images.pressEnter}
                                        alt="enter-icon"
                                        className="press-enter-image"
                                    />
                                    <span>to search</span>
                                    <CloseIcon onClick={() => {
                                        SetAvatarListVisible(false)
                                        dispatch(returnSearchedCustomerDataAction(''))
                                        dispatch(returnSearchedInvoiceDataAction(''))
                                        }} />
                                </div>
                            </InputAdornment>
                        ),
                    }}
                    onFocus={() => {
                        handleFocus();
                    }}
                    className={`${avatarListVisible ? "return-cart-search-order-input-focused" : "return-cart-search-order-input"}`}
                    id="outlined-basic"
                    placeholder={title.searchInvoice}
                    value={searchCustomerValue}
                    onClick={searchCustomerOnClick}
                    onChange={(e) => {
                        searchCustomerOnChange(e)
                    }}
                    onKeyPress={customerKeyPress}
                    onKeyDown={onKeyDown}
                    inputRef={returnCustomerSearchRef}
                />
                <div className="w-100 return-cart-search-new-product-input">
                    <PosInput
                        variant="filled"
                        id="standard-basic"
                        label={`${searchedProductInfo ? "Product to Return" : ""}`}
                        InputProps={{
                            disableUnderline: true,
                            readOnly: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (

                                <InputAdornment position="end">
                                    {searchedProductInfo &&
                                        <>
                                            <PosImage src={searchProductImage ?? defaultIcon} alt="product-icon" />
                                        </>
                                    }

                                </InputAdornment>
                            )
                        }}
                        placeholder={title.searchReturnProduct}
                        className={`${!avatarListVisible ? 'return-cart-input-product' : ''} return-cart-search-product-input`}
                        value={searchedProductInfo}
                        onClick={searchProductOnClick}
                        onChange={() => searchProductOnChange}
                    />
                    {searchedProductInfo && <div className="product-remove-close-icon" 
                    onClick={() => {
                        setSearchedProductInfo('')
                        dispatch(returnSearchedProductDataAction({}))
                    }}
                    >
                        <CloseIcon />
                    </div>}
                </div>
            </div>
        </>
    );
};
export default SearchCommonComponent;
