import { useState } from "react";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { ManageUserModal } from "apps/admin/ManageUserForm/ManageUserForm";
import { User } from "models";

import { UpdateModalContent } from "sagas/modal/actions";
import {
  getAllUsers
} from "sagas/user/actions";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";

import { adminMessage } from "constants/message";
import { removeUserbyName } from "services";
import Button from "../components/Elements/Button/Button";
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";

import './DeactivateAccountDialog.scss';
interface UserSettingsFormData {
  userSettingsRemove: string;
}

interface DeActiveFormProps {
  user: User;
  onSuccess: (text) => void;
  loginUser: User;
  currentPage: number;
  isUser: boolean;
}

function DeactivateUserForm({ user, loginUser, currentPage, isUser }: DeActiveFormProps) {
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const dispatch = useDispatch();
  const handleCheckbox = () => {
    setCheckboxClicked(!checkboxClicked);
  };
  const {
    handleSubmit: userSettingsHandleSubmit,
  } = useForm<UserSettingsFormData>();
  const userSettingsOnSubmit = () => {
    return [];
  };
  const [isBtnDisable, setIsBtnDisable] = useState(false)

  const removeUserApi = () => {
    if (checkboxClicked) {
      setIsBtnDisable(true)
      removeUserbyName(user.username).then((data) => {
        setIsBtnDisable(false)
        if (data?.data) {
          dispatch(getAllUsers(currentPage));
          dispatch(snakbarShow({message :adminMessage.deactivate}))
        } else {
          dispatch(snakbarShow({message :adminMessage.failDeactive}))
        }
        handleCloseModel()
      }).catch(() => {
        setIsBtnDisable(false)
        handleCloseModel()
      });
    }
  }
  const handleCloseModel = () => {
    dispatch(
      UpdateModalContent({
        isOpen: false,
        children: null,
        fixedModal: true,
        removePaddingBottom: false,
        noMaxWidth: false
      })
    );
  }
  const handleCancel = () => {
    dispatch(
      UpdateModalContent({
        isOpen: true,
        isAdmin: true,
        classModal:"user-profile-dialog",
        children: <ManageUserModal user={user} loginUser={loginUser} isApproval={false} currentTab={2} isUser={isUser} />,
        fixedModal: true,
        removePaddingBottom: false,
        noMaxWidth: false
      })
    );
  };

  const lstChar = user.fullName.slice(-1);
  return (
    <div
      onSubmit={userSettingsHandleSubmit(userSettingsOnSubmit)}
    >
      <header className="dialog-header">
        <h3 className="dialog-title">
          Deactivate Account
        </h3>
      </header>

      <div className="dialog-body">
        <div className="deactivate-alert-message">
          <MaterialIcon
            icon="warning"
            classes="deactivate-alert-icon"
          />
          <div className='deactivate-alert-message-group'>
            <p>
              {
                loginUser?.personId === user?.personId ?
                  "This will deactivate your account and you will no longer be able to access Jumper portal."
                  : (`This will deactivate ${user.fullName}'${lstChar !== 's' ? 's' : ""} account and they will no longer
            be able to access Jumper portal.`)
              }
            </p>
            <p>
              All personal data will still be securely stored and available in case the account is&nbsp;reinstated.
            </p>
          </div>
        </div>

        <p className='support-link'>
          If you would like to permanently terminate this account, please&nbsp;<a href="https://jumperware.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noreferrer">Contact Support</a>.
        </p>

        <div className="deactivation-confirmation">
          <input type="checkbox"
            id='userSettingsRemove'
            onChange={handleCheckbox}
          />
          <label htmlFor="userSettingsRemove">
            I understand the consequences, and wish to&nbsp;proceed
          </label>
        </div>
      </div>

      <footer className="dialog-footer">
        <Button
          label="Cancel"
          onClick={handleCancel}
        />
        <Button
          label="Deactivate Account"
          mobileLabel="Deactivate"
          secondary="true"
          classes={`button-danger ${isBtnDisable ? 'button-is-loading' : ""}`}
          onClick={removeUserApi}
          disabled={!checkboxClicked}
        />
      </footer>
    </div>
  );
}

export default DeactivateUserForm;
