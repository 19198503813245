import InfoBlock from '../InfoBlock';

const Prices = ({custRepPrice, repCost, itemMarginPercent}) => {
    return (
        <div className="item-group-prices">
            <InfoBlock label='Rep Price' value={custRepPrice ? `$ ${custRepPrice}` : ''} />
            <InfoBlock label='Rep Cost' value={repCost ? `$ ${repCost}` : ''} />
            <InfoBlock label='Rep Margin' value={itemMarginPercent ? `${itemMarginPercent}%` : ''} />
        </div>
    )
}

export default Prices;