import _ from "loadsh";
import { AnyAction } from "redux";

import { ProductTypes } from "constants/enum";
import { ProductPriceProps } from "constants/interfaces";
import * as constants from "./constants";

const initialState = {
  isLoading: false,
  isStateLoading: false,
  isProductAddedLoading: false,
  isPickingSuccess: false,
  workStationDetails: {},
  priceScheduleDetails: {},
  takerDetails: {},
  productsDetails: {},
  suggestedProductsDetails: {},
  salesPersonDetails: {},
  recentCustomers: [],
  searchCustomers: [],
  searchCustomersLength: 0,
  shiptoFacilities: {},
  getIsShipToFacilitiesSuccess: false,
  searchProducts: [],
  shippingFacilities: {},
  salesHistory: [],
  salesHistoryTotal: 0,
  addShippingFacilities: {},
  addShippingFacilitiesSuccess: false,
  addShippingFacilitiesError: false,
  getFacilityData: [],
  getJobDescriptionData: [],
  getStatesData: [],
  priceSchedule: {},
  priceScheduleList: {},
  allPriceSchdulesList: [],
  selectedPriceSchedule: {},
  productPriceSchedule: {},
  addpriceSchedules: [],
  addProducts: {},
  selectedProducts: {},
  updateSelectedPriceSchedule: {},
  updateProducts: [],
  updateSelectedProducts: {},
  removePriceSchedule: {},
  removeProducts: {},
  removeSelectedPS: {},
  cartSubmit: {},
  cartPrintSuccess: false,
  cartPrint: {},
  cartDraft: {},
  cartDraftSuccess: false,
  isGoToCart: false,
  notAddedNewProductData: false,
  loadCartDetails: {},
  getWarehouseLocations: [],
  posFlowData: {},
  productsData: {} as any,
  // TODO:Temp as any
  productUpdateFlag: false,
  updateProductsData: {},
  error: "",
  productLoadSuccess: false,
  //TODO: do not remove this undefined as this is part of flow
  updatedPrice: undefined,
  savedDrafts: [],
  errorProcess: "",
  shiptoSuccess: false,
  checkInvoiceReload: true,
  newCustomerData: {},
  newProductData: null,
  productAddedSuccess: false,
  productAddedFormData: {},
  draftLoadMessage: false,
  draftLoadError: false,
  stockWarehouseData: [],
  searchProductsTotal: 0,
  selectedIndex: -1,
  selectedIndexGeneric: -1,
  selectedProductIndex: -1,
  selectedDraftIndex: -1,
  selectedCartDraftIndex: -1,
  selectedTakerIndex: -1,
  customerDataCount: 0,
  productIndexInfo: 0,
  getSalesHistory: [],
  newPrice: 0,
  updateItemModal: false,
  availableLocationInfo: [],
  getCategoriesData: [],
  getNewProductManufacturerData: [],
  getNewProductVendorData: [],
  getNewProductGroupData: [],
  isCategoriesLoading: false,
  isProductManufacturerLoading: false,
  isProductVendorLoading: false,
  isProductGroupLoading: false,
  getRowsActiveDetails: [],
  isRowActiveLoading: false,
  getUnitActiveDetails: [],
  isUnitActiveLoading: false,
  getShelfDetails: [],
  isShelfLoading: false,
  isStockInLoading: false,
  isStockOutLoading: false,
  stockInDetails: [],
  stockOutDetails: [],
  isStockMoveLoading: false,
  stockMoveDetails: [],
  isItemLedgerLoaded: false,
  itemLedgerDetails: [],
  fromLocationProductDetails: [],
  isFromLocationProductLoading: false,
  fromLocationRowDetails: [],
  isFromLocationRowLoading: false,
  fromLocationUnitDetails: [],
  isFromLocationUnitLoading: false,
  fromLocationShelfDetails: [],
  isFromLocationShelfLoading: false,
  inStockAddSuccess: false,
  inStockOutSuccess: false,
  inStockMoveSuccess: false,
  documentTypesDetails: [],
  documentTypesLoading: false,
  isApiFiledError: false,
  searchCustomerResponse: false,
  isReturnProductLoading: false,
  isReturnCustomerLoading: false,
  getUnitActiveDetailsId: [],
  getShelfDetailsId: [],
  isPriceScheduleLoading: false,
  isWareHouseAvailable: false,
  isStockUpdateProduct: false,
  warehouseLoading: false,
  isEnterDisabled: false,
  getTabShipping: 0,
  getProductIndex: null,
  processLoading: false,
  getCancelPrice: 0,
  isTakerDetailsSuccess: false,
  warehouseLocationLoader: false,
  productDataCount: 0,
  headerHeightCount: 0,
  orderInstructionNote: null,
  shippingInstructionNote: "",
  quoteExpiryDate: "",
  temporaryStorageLocationNote: "",
  isTabDisable: true,
  allRowsData: [],
  allRowDataLoading: false,
  allRowsDataError: [],
  singleRowsData: [],
  singleRowDataLoading: false,
  singleRowsDataError: [],
  isItemLedgerInoutLoaded: false,
  itemLedgerInoutDetails: {},
  isMoveTabDisable: false,
  quotesHistory: [],
  quotesHistoryTotal: 0,
  itemHistory: [],
  purchaseHistory: [],
  purchaseHistoryTotal: 0,
  invoiceHistory: [],
  invoiceHistoryTotal: 0,
  isPurchaseOrderNoEdit: false,
  draftListError: false,
  searchDrafts: [],
  searchDraftsTotal: 0,
  draftDataCount: 0,
  jobNameForAll: "",
  noMailStatus: undefined,
  storeCreditAmount: "",
  priceCheckReducer: false,
  markAsQuoteReducer: false,
  unpaidBalance: "",
  newProductIds: [],
  billingDetails: {
    firstName: null,
    lastName: null,
    companyName: null,
    companyAddress: null,
    city: null,
    state: null,
    zip: null,
    email: null,
    countryCode: null,
    phoneNumber: null,
    extCode: null,
  },
  isProductSearch: false,
  isCartDraftLoading: false,
  posPriceChanagHistory: [],
  posPriceChanagHistoryTotal: 0,
  movingAvgCostHistory: [],
  movingAvgCostHistoryTotal: 0,
  lastProductQty: 0,
  lastProductViewIndex: null,
  uploadDocRegion: null,
};

const { typeClearance, typeDiscontinued, typeSalesPrice, typeVendorPrice, typePriceScheduleSingle, typePriceScheduleVolume, typeLastPosRetailPrice, typeSalesOverride, typeMovingAvgCost } = ProductTypes


const reducer = function posReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case constants.GET_WORKSTATIONS_START:
      return { ...state, isLoading: true };
    case constants.GET_WORKSTATIONS_SUCCESS:
      return {
        ...state,
        workStationDetails: action.payload,
        isLoading: false,
      };
    case constants.GET_WORKSTATIONS_ERROR:
      return { ...state, workStationDetails: action.payload, isLoading: false };

    // Price Schedules
    case constants.GET_PRICESCHEDULES_START:
      return { ...state, isLoading: true, pageSize: action.payload, isPriceScheduleLoading: true };
    case constants.GET_PRICESCHEDULES_SUCCESS:
      return {
        ...state,
        priceScheduleDetails: action.payload,
        isLoading: false,
        isPriceScheduleLoading: false
      };
    case constants.GET_PRICESCHEDULES_ERROR:
      return {
        ...state,
        priceScheduleDetails: action.payload,
        isLoading: false,
        isPriceScheduleLoading: false
      };
    case constants.GET_PRICESCHEDULES_CLEAR:
      return {
        ...state,
        priceScheduleDetails: [],
        isLoading: false,
        isPriceScheduleLoading: false
      };

    // Saved Drafts
    case constants.GET_SAVED_DRAFTS_START:
      return { ...state, isLoading: true, draftListError: false };
    case constants.GET_SAVED_DRAFTS_SUCCESS:
      return {
        ...state,
        savedDrafts: action.payload,
        draftLoadMessage: true,
        isLoading: false,
        draftListError: false
      };
    case constants.GET_SAVED_DRAFTS_ERROR:
      return {
        ...state,
        savedDrafts: action.payload,
        draftLoadError: true,
        isLoading: false,
        draftListError: true
      };
    case constants.GET_SAVED_DRAFTS_RESET:
      return {
        ...state,
        savedDrafts: action.payload,
        draftLoadError: false,
        draftLoadMessage: false,
        isLoading: false,
        draftListError: false
      };

    // TAKER
    case constants.GET_TAKERS_START:
      return { ...state, isLoading: true, isTakerDetailsSuccess: false };
    case constants.GET_TAKERS_SUCCESS:
      return { ...state, takerDetails: action.payload, isLoading: false, isTakerDetailsSuccess: true };
    case constants.GET_TAKERS_ERROR:
      return { ...state, takerDetails: action.payload, isLoading: false, isTakerDetailsSuccess: false };

    case constants.GET_SALESPERSONS_START:
      return { ...state, isLoading: true };
    case constants.GET_SALESPERSONS_SUCCESS:
      return { ...state, salesPersonDetails: action.payload, isLoading: false };
    case constants.GET_SALESPERSONS_ERROR:
      return { ...state, salesPersonDetails: action.payload, isLoading: false };

    case constants.GET_RECENT_CUSTOMERS_START:
      return { ...state, isRecentCustomerLoading: true };
    case constants.GET_RECENT_CUSTOMERS_SUCCESS:
      return {
        ...state,
        recentCustomers: action.payload.data,
        isRecentCustomerLoading: false,
      };
    case constants.GET_RECENT_CUSTOMERS_ERROR:
      return { ...state, recentCustomers: action.payload };
    case constants.GET_RECENT_CUSTOMERS_CLEAR:
      return { ...state, recentCustomers: [], isLoading: false };

    case constants.GET_PRODUCTS_START:
      return { ...state, isLoading: true, productLoadSuccess: false, updatedQty: 0, productUpdateFlag: Boolean(action.payload.productUpdate), };
    case constants.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        ...action.payload?.notes?.length && (!state.productUpdateFlag) ? {} : { isWareHouseDrawer: true },
        productLoadSuccess: true,
        productsData: {
          ...action.payload,
          updatedAvailableQty: action.payload?.qtyInStock,
          clearance: action.payload?.productPrices?.find((item: ProductPriceProps) => item.type === typeClearance),
          discontinued: action.payload?.productPrices?.find((item: ProductPriceProps) => item.type === typeDiscontinued),
          salesOverride: action.payload?.productPrices?.find((item: ProductPriceProps) => item.type === typeSalesOverride),
          priceScheduleSingle: action.payload?.productPrices?.find((item: ProductPriceProps) => item.type === typePriceScheduleSingle),
          priceScheduleVolume: action.payload?.productPrices?.filter((item: ProductPriceProps) => item.type === typePriceScheduleVolume),
          lastPosRetailPrice: action.payload?.productPrices?.find((item: ProductPriceProps) => item.type === typeLastPosRetailPrice),
          salesPrice: action.payload?.productPrices?.find((item: ProductPriceProps) => item.type === typeSalesPrice),
          vendorPrice: action.payload?.productPrices?.find((item: ProductPriceProps) => item.type === typeVendorPrice),
          movingAvgCost: action.payload?.productPrices?.find((item: ProductPriceProps) => item.type === typeMovingAvgCost),
          productPrices: action.payload?.productPrices.map((item) => {
            return {
              ...item,
              //TODO: do not remove this undefined as this is part of flow
              setPrice: undefined,
              setInitialCustomPrice: undefined,
            }
          })
        },
        isLoading: false,
      };
    case constants.DISABLE_ENTER_ADD_TO_CART:
      return {
        ...state,
        isEnterDisabled: action.payload
      };

    case constants.ADD_TO_CART_DRAWER:
      return {
        ...state,
        isWareHouseDrawer: action.payload
      };

    case constants.SET_CHANGE_PRICE:
      const data = [...state.productsData?.productPrices]
      const productData = data?.map((item) => {
        return { ...item, setPrice: Number(action.payload) }
      })
      return {
        ...state,
        //TODO: do not remove this undefined as this is part of flow
        updatedPrice: undefined,
        productsData: {
          ...state.productsData,
          productPrices: productData,
        },
      };

    case constants.SET_INITIAL_CUSTOM_PRICE:
      const initialData = [...state.productsData?.productPrices]
      const initialProductData = initialData?.map((item) => {
        return { ...item, setInitialCustomPrice: Number(action.payload) }
      })
      return {
        ...state,
        productsData: {
          ...state.productsData,
          productPrices: initialProductData,
        },
      };

    case constants.GET_PRODUCTS_ERROR:
      return {
        ...state,
        productUpdateFlag: false,
        productsData: action.payload,
        isLoading: false,
        productLoadSuccess: false
      };
    case constants.GET_PRODUCTS_DATA_CLEAR:
      return {
        ...state,
        productUpdateFlag: false,
        productsData: {},
        isLoading: false,
        productLoadSuccess: false,
        //TODO: do not remove this undefined as this is part of flow
        updatedPrice: undefined,
      };

    case constants.EDIT_PRODUCT:
      return {
        ...state,
        productUpdateFlag: true,
        productsData: action.payload.productsData,
        isLoading: false,
        // updatedPrice: action.payload.updatedPrice,
        updatedQty: action.payload.updatedQty,
        updatedProduct: action.payload.updatedProduct,
        productIndexInfo: action.payload.productIndex,
      };

    case constants.GET_SUGGESTED_PRODUCTS_START:
      return { ...state, isLoading: true };
    case constants.GET_SUGGESTED_PRODUCTS_SUCCESS:
      return {
        ...state,
        suggestedProductsData: action.payload.data,
        isLoading: false,
      };
    case constants.GET_SUGGESTED_PRODUCTS_ERROR:
      return {
        ...state,
        suggestedProductsData: action.payload,
        isLoading: false,
      };
    case constants.GET_SUGGESTED_PRODUCTS_CLEAR:
      return {
        ...state,
        suggestedProductsData: [],
        isLoading: false,
      };

    case constants.GET_FACILITY_TYPES_START:
      return { ...state, isLoading: true };
    case constants.GET_FACILITY_TYPES_SUCCESS:
      return {
        ...state,
        getFacilityData: action.payload.data,
        isLoading: false,
      };
    case constants.GET_FACILITY_TYPES_ERROR:
      return { ...state, isLoading: false };

    case constants.GET_JOB_DESCRIPTION_START:
      return { ...state, isLoading: true };
    case constants.GET_JOB_DESCRIPTION_SUCCESS:
      return {
        ...state,
        getJobDescriptionData: action.payload.data,
        isLoading: false,
      };
    case constants.GET_JOB_DESCRIPTION_ERROR:
      return { ...state, isLoading: false };

    case constants.GET_STATES_START:
      return { ...state, isStateLoading: true };
    case constants.GET_STATES_SUCCESS:
      return {
        ...state,
        getStatesData: action.payload.data,
        isStateLoading: false,
      };
    case constants.GET_STATES_ERROR:
      return { ...state, isStateLoading: false };

    case constants.GET_SHIPTO_FACILITIES_START:
      return {
        ...state,
        isLoading: true,
        getIsShipToFacilitiesSuccess: false,
        // shiptoFacilities: {},
      };
    case constants.GET_SHIPTO_FACILITIES_SUCCESS:
      return {
        ...state,
        shiptoFacilities: action.payload,
        isLoading: false,
        getIsShipToFacilitiesSuccess: true,
      };
    case constants.GET_SHIPTO_FACILITIES_ERROR:
      return { ...state, isLoading: false, getIsShipToFacilitiesSuccess: false, };

    case constants.GET_SHIPPING_FACILITIES_START:
      return { ...state, isLoading: true };
    case constants.GET_SHIPPING_FACILITIES_SUCCESS:
      return {
        ...state,
        shippingFacilities: action.payload.data,
        isLoading: false,
      };
    case constants.GET_SHIPPING_FACILITIES_ERROR:
      return { ...state, isLoading: false };
    case constants.GET_SHIPPING_FACILITIES_CLEAR:
      return {
        ...state,
        shippingFacilities: [],
      };

    // start
    case constants.GET_SALES_HISTORY_START:
      return { ...state, isLoading: true, salesHistory: initialState.salesHistory, salesHistoryTotal: initialState.salesHistoryTotal };
    case constants.GET_SALES_HISTORY_SUCCESS:
      return {
        ...state,
        salesHistory: action.payload.data,
        salesHistoryTotal: action.payload.total,
        isLoading: false,
      };
    case constants.GET_SALES_HISTORY_ERROR:
      return { ...state, isLoading: false };
    // end

    case constants.RESET_NEW_ADDRESS:
      return { ...state };
    case constants.ADD_SHIPPING_FACILITIES_START:
      return { ...state, isLoading: true, addShippingFacilitiesSuccess: false, addShippingFacilitiesError: false };
    case constants.ADD_SHIPPING_FACILITIES_SUCCESS:
      return {
        ...state,
        addShippingFacilities: action.payload,
        addShippingFacilitiesSuccess: true,
        isLoading: false,
        addShippingFacilitiesError: false,
      };
    case constants.ADD_SHIPPING_FACILITIES_ERROR:
      return {
        ...state,
        isLoading: false,
        addShippingFacilitiesSuccess: false,
        addShippingFacilitiesError: true,
        error: action.error,
      };
    case constants.ADD_SHIPPING_FACILITIES_CLEAR:
      return {
        ...state,
        isLoading: false,
        addShippingFacilitiesSuccess: false,
        addShippingFacilitiesError: false,
      };

    case constants.SEARCH_CUSTOMERS_START:
      return { ...state, isLoading: true, searchCustomerResponse: false };
    case constants.SEARCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        searchCustomers: action.payload.data.data,
        searchCustomersLength: action.payload.data.total,
        isLoading: false,
        searchCustomerResponse: false,
        customerDataCount:
          action.payload.data.total > 20
            ? state.customerDataCount + action.payload.data.count
            : action.payload.data.count,
        error: '',
        isReturnCustomerLoading: false
      };
    case constants.SEARCH_CUSTOMERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        searchCustomerResponse: true,
        isReturnCustomerLoading: false
      };
    case constants.CLEAR_SEARCH_CUSTOMER:
      return {
        ...state,
        searchCustomers: [],
        searchCustomersLength: 0,
        isLoading: false,
        error: "",
        customerDataCount: 0,
        searchCustomerResponse: false,
        isReturnCustomerLoading: false
      };

    case constants.SEARCH_RETURN_CUSTOMERS_START:
      return {
        ...state, isReturnCustomerLoading: true,
        searchCustomerResponse: false, isLoading: true,
      };

    case constants.SEARCH_PRODUCTS_START:
      return { ...state, availableLocationInfo: [], isLoading: true, isProductSearch: true };
    case constants.SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        searchProducts: _.uniqBy(action.payload.data, "productId"),
        availableLocationInfo: action.payload.data.map((i) => i.productId),
        searchProductsTotal: action.payload.total,
        isLoading: false,
        isProductSearch: false,
        isReturnProductLoading: false,
        error: "",
        productDataCount:
          action.payload.total > 20
            ? state.productDataCount + action.payload.count
            : action.payload.count,
      };
    case constants.SEARCH_PRODUCTS_ERROR:
      return { ...state, isLoading: false, isReturnProductLoading: false, error: action.error, productDataCount: 0, isProductSearch: false };
    case constants.CLEAR_SEARCH_PRODUCT:
      return {
        ...state,
        searchProducts: [],
        availableLocationInfo: [],
        stockWarehouseData: [],
        searchProductsTotal: 0,
        isLoading: false,
        isReturnProductLoading: false,
        isProductSearch: false,
        error: "",
        productDataCount: 0
      };

    case constants.SEARCH_RETURN_PRODUCTS_START:
      return { ...state, availableLocationInfo: [], isReturnProductLoading: true, isLoading: true };

    case constants.ADD_NEW_PRODUCT_START:
      return {
        ...state,
        isProductAddedLoading: true,
        productAddedSuccess: false,
      };
    case constants.ADD_NEW_PRODUCT_SUCCESS:
      return {
        ...state,
        newProductData: action.payload.data,
        isProductAddedLoading: false,
        productAddedSuccess: true,
      };
    case constants.ADD_NEW_PRODUCT_ERROR:
      return {
        ...state,
        isProductAddedLoading: false,
        productAddedSuccess: false,
        error: action.error,
      };
    case constants.ADD_NEW_PRODUCT_CLEAR:
      return {
        ...state,
        isProductAddedLoading: false,
        productAddedSuccess: false,
        error: {},
      };

    case constants.ADD_PRICE_SCHEDULE_START:
      return { ...state, isLoading: true };
    case constants.ADD_PRICE_SCHEDULE_SUCCESS:
      return { ...state, priceScheduleList: action.payload, isLoading: false };
    case constants.ADD_PRICE_SCHEDULE_ERROR:
      return { ...state, priceScheduleList: action.payload, isLoading: false };

    case constants.UPDATE_PRICE_SCHEDULE_START:
      return { ...state, isLoading: true, updatePriceScheduleLoading: true, updatePriceScheduleSuccess: false };
    case constants.UPDATE_PRICE_SCHEDULE_SUCCESS:
      return { ...state, priceSchedule: action.payload, isLoading: false, updatePriceScheduleLoading: false, updatePriceScheduleSuccess: true };
    case constants.UPDATE_PRICE_SCHEDULE_ERROR:
      return { ...state, priceSchedule: action.payload, isLoading: false, updatePriceScheduleLoading: false, updatePriceScheduleSuccess: false };

    case constants.STOCK_BY_WAREHOUSE_START:
      return { ...state, isLoading: true, isWareHouseAvailable: true };
    case constants.STOCK_BY_WAREHOUSE_SUCCESS:
      const indf = state.stockWarehouseData;
      return {
        ...state,
        isLoading: false,
        // stockWarehouseData: action.payload, TODO:need this code
        stockWarehouseData: _.uniqBy(
          [...indf, ...action.payload.data],
          "productId"
        ),
        isWareHouseAvailable: false,
        warehouseLoading: false,
      };
    case constants.STOCK_BY_WAREHOUSE_ERROR:
      return { ...state, priceSchedule: action.payload, isLoading: false, isWareHouseAvailable: false, };
    case constants.STOCK_BY_WAREHOUSE_CLEAR:
      return { ...state, stockWarehouseData: [], isLoading: false, isWareHouseAvailable: false, };

    case constants.CART_SUBMIT_START:
      return { ...state, processLoading: true, cartSubmitSuccess: false };
    case constants.CART_SUBMIT_SUCCESS:
      return {
        ...state,
        cartSubmit: action.payload,
        processLoading: false,
        cartSubmitSuccess: true,
        errorProcess: "",
      };
    case constants.CART_SUBMIT_ERROR:
      return {
        ...state,
        cartSubmit: action.payload,
        processLoading: false,
        cartSubmitSuccess: false,
        errorProcess: action.error,
      };
    case constants.CART_SUBMIT_CLEAR:
      return {
        ...state,
        processLoading: false,
        cartSubmitSuccess: false,
        errorProcess: "",
      };

    case constants.CART_PRINT_START:
      return { ...state, isLoading: true, cartPrintSuccess: false };
    case constants.CART_PRINT_SUCCESS:
      return {
        ...state,
        cartPrint: action.payload,
        isLoading: false,
        cartPrintSuccess: true,
      };
    case constants.CART_PRINT_ERROR:
      return {
        ...state,
        cartPrint: action.payload,
        isLoading: false,
        cartPrintSuccess: false,
      };
    case constants.CART_PRINT_CLEAR:
      return {
        ...state,
        cartPrint: {},
        isLoading: false,
        cartPrintSuccess: false,
      };

    case constants.CART_PRINT_WITH_TOTALS_START:
      return { ...state, isLoading: true, cartPrintSuccess: false };
    case constants.CART_PRINT_WITH_TOTALS_SUCCESS:
      return {
        ...state,
        cartPrint: action.payload,
        isLoading: false,
        cartPrintSuccess: true,
      };
    case constants.CART_PRINT_WITH_TOTALS_ERROR:
      return {
        ...state,
        cartPrint: action.payload,
        isLoading: false,
        cartPrintSuccess: false,
      };
    case constants.CART_PRINT_WITH_TOTALS_CLEAR:
      return {
        ...state,
        cartPrint: {},
        isLoading: false,
        cartPrintSuccess: false,
      };
    case constants.IS_GO_TO_CART:
      return { ...state, isGoToCart: action.payload };
    case constants.NOT_ADDED_NEW_PRODUCT:
      return { ...state, notAddedNewProductData: action.payload };

    case constants.CART_DRAFT_START:
      return { ...state, isLoading: true, cartDraftSuccess: false };
    case constants.CART_DRAFT_SUCCESS:
      return {
        ...state,
        cartDraft: action.payload,
        cartDraftSuccess: true,
        isLoading: false,
      };
    case constants.CART_DRAFT_ERROR:
      return {
        ...state,
        cartDraft: action.payload,
        isLoading: false,
        cartDraftSuccess: false,
      };
    case constants.CART_DRAFT_CLEAR:
      return { ...state, isLoading: true, cartDraftSuccess: false };

    case constants.GET_WAREHOUSE_LOCATIONS_START:
      return { ...state, isLoading: true, isPickingSuccess: false, warehouseLocationLoader: true };
    case constants.GET_WAREHOUSE_LOCATIONS_SUCCESS:
      return {
        ...state,
        isPickingSuccess: true,
        getWarehouseLocations: action.payload,
        isLoading: false,
        warehouseLocationLoader: false,
      };
    case constants.GET_WAREHOUSE_LOCATIONS_ERROR:
      return { ...state, getWarehouseLocations: [], isLoading: false, isPickingSuccess: false, warehouseLocationLoader: false };
    case constants.GET_WAREHOUSE_LOCATIONS_CLEAR:
      return { ...state, getWarehouseLocations: [], isLoading: false, warehouseLocationLoader: false };

    case constants.CHECK_PAGE_REFRESH:
      return { ...state, checkInvoiceReload: action.payload };

    case "ARR0W_INDEX_RESET_GENERIC":
      return { ...state, selectedIndexGeneric: -1 };
    case "ARR0W_UP_INDEX_GENERIC":
      if (state.selectedIndexGeneric === 0) {
        return {
          ...state,
        };
      }
      return { ...state, selectedIndexGeneric: state.selectedIndexGeneric - 1 };
    case "ARR0W_DOWN_INDEX_GENERIC":
      if (state.selectedIndexGeneric >= action.payload - 1) {
        return {
          ...state,
        };
      } else if (state.selectedIndexGeneric >= action.payload) {

        return {
          ...state,
        };
      }
      return { ...state, selectedIndexGeneric: state.selectedIndexGeneric + 1 };

    case "ARR0W_DOWN_INDEX":
      // if (state.searchProducts.length === state.selectedIndex + 1) {
      //   return {
      //     ...state,
      //   };
      // }
      if (state.selectedIndex >= action.payload - 1) {
        return {
          ...state,
        };
      } else if (state.selectedIndex >= action.payload) {

        return {
          ...state,
        };
      }
      return { ...state, selectedIndex: state.selectedIndex + 1 };

    case "ARR0W_UP_INDEX":
      if (state.selectedIndex === 0) {
        return {
          ...state,
        };
      }
      return { ...state, selectedIndex: state.selectedIndex - 1 };
    case "ARR0W_INDEX_RESET":
      return { ...state, selectedIndex: -1 };

    case "SET_SELECTED_INDEX":
      return { ...state, selectedIndex: action.payload };

    case "ARR0W_DOWN_INDEX_PRODUCT_PAGE":
      if (state.selectedProductIndex >= action.payload - 1) {
        return {
          ...state,
        };
      }
      return { ...state, selectedProductIndex: state.selectedProductIndex + 1 };
    case "ARR0W_UP_INDEX_PRODUCT_PAGE":
      if (state.selectedProductIndex === 0) {
        return {
          ...state,
        };
      }
      return { ...state, selectedProductIndex: state.selectedProductIndex - 1 };
    case "SET_SELECTED_PRODUCT_INDEX":
      return { ...state, selectedProductIndex: action.payload };

    case "ARR0W_INDEX_RESET_PRODUCT_PAGE":
      return { ...state, selectedProductIndex: -1 };

    case "ARR0W_DRAFT_DOWN_INDEX":
      if (state.selectedDraftIndex >= action.payload - 1) {
        return {
          ...state,
        };
      }
      return { ...state, selectedDraftIndex: state.selectedDraftIndex + 1 };
    case "ARR0W_DRAFT_UP_INDEX":
      if (state.selectedDraftIndex === 0) {
        return {
          ...state,
        };
      }
      return { ...state, selectedDraftIndex: state.selectedDraftIndex - 1 };
    case "ARR0W_DRAFT_INDEX_RESET":
      return { ...state, selectedDraftIndex: -1 };

    case "GET_NEW_PRICE":
      return { ...state, updatedPrice: action.payload };

    case "GET_UPDATE_ITEM_MODAL":
      return { ...state, updateItemModal: action.payload };

    case constants.GET_CATEGORIES_REQUEST:
      return { ...state, isCategoriesLoading: true };
    case constants.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        getCategoriesData: action.payload.data,
        isCategoriesLoading: false,
      };
    case constants.GET_CATEGORIES_ERROR:
      return { ...state, isCategoriesLoading: false };

    case constants.GET_NEW_PRODUCT_MANUFACTURER_REQUEST:
      return { ...state, isProductManufacturerLoading: true };
    case constants.GET_NEW_PRODUCT_MANUFACTURER_SUCCESS:
      return {
        ...state,
        getNewProductManufacturerData: action.payload.data,
        isProductManufacturerLoading: false,
      };
    case constants.GET_NEW_PRODUCT_MANUFACTURER_ERROR:
      return { ...state, isProductManufacturerLoading: false };

    case constants.GET_NEW_PRODUCT_VENDOR_REQUEST:
      return { ...state, isProductVendorLoading: true };
    case constants.GET_NEW_PRODUCT_VENDOR_SUCCESS:
      return {
        ...state,
        getNewProductVendorData: action.payload.data,
        isProductVendorLoading: false,
      };
    case constants.GET_NEW_PRODUCT_VENDOR_ERROR:
      return { ...state, isProductVendorLoading: false };

    case constants.GET_NEW_PRODUCT_GROUP_REQUEST:
      return { ...state, isProductGroupLoading: true };
    case constants.GET_NEW_PRODUCT_GROUP_SUCCESS:
      return {
        ...state,
        getNewProductGroupData: action.payload.data,
        isProductGroupLoading: false,
      };
    case constants.GET_NEW_PRODUCT_GROUP_ERROR:
      return { ...state, isProductGroupLoading: false };

    // Start Adjust Stock api
    //  Get Rows
    case constants.GET_ROWS_ACTIVE_START:
      return { ...state, isRowActiveLoading: true };
    case constants.GET_ROWS_ACTIVE_SUCCESS:
      return {
        ...state,
        getRowsActiveDetails: action.payload,
        isRowActiveLoading: false,
      };
    case constants.GET_ROWS_ACTIVE_ERROR:
      return { ...state, getRowsActiveDetails: [], isRowActiveLoading: false };
    case constants.GET_ROWS_ACTIVE_CLEAR:
      return { ...state, getRowsActiveDetails: [], isRowActiveLoading: false };
    //  Get Units
    case constants.GET_UNIT_ACTIVE_START:
      return { ...state, isUnitActiveLoading: true };
    case constants.GET_UNIT_ACTIVE_SUCCESS:
      return {
        ...state,
        getUnitActiveDetails: action.payload,
        getUnitActiveDetailsId: [...state.getUnitActiveDetailsId, ...action.payload],
        isUnitActiveLoading: false,
      };
    case constants.GET_UNIT_ACTIVE_ERROR:
      return { ...state, getUnitActiveDetails: [], isUnitActiveLoading: false };
    case constants.GET_UNIT_ACTIVE_CLEAR:
      return { ...state, getUnitActiveDetails: [], isUnitActiveLoading: false };
    //  Get Shelf
    case constants.GET_SHELF_START:
      return { ...state, isShelfLoading: true };
    case constants.GET_SHELF_SUCCESS:
      return {
        ...state,
        getShelfDetails: action.payload,
        getShelfDetailsId: [...state.getShelfDetailsId, ...action.payload],
        isShelfLoading: false,
      };
    case constants.GET_SHELF_ERROR:
      return { ...state, getShelfDetails: [], isShelfLoading: false };
    case constants.GET_SHELF_CLEAR:
      return { ...state, getShelfDetails: [], isShelfLoading: false };
    // stock In
    case constants.STOCK_IN_START:
      return { ...state, isStockInLoading: true, inStockAddSuccess: false };
    case constants.STOCK_IN_SUCCESS:
      return {
        ...state,
        inStockAddSuccess: true,
        stockInDetails: action.payload,
        isStockInLoading: false,
        isStockUpdateProduct: true
      };
    case constants.STOCK_IN_ERROR:
      return {
        ...state,
        stockInDetails: [],
        isStockInLoading: false,
        inStockAddSuccess: false,
        isStockUpdateProduct: true
      };
    case constants.STOCK_IN_CLEAR:
      return {
        ...state,
        stockInDetails: [],
        isStockInLoading: false,
        inStockAddSuccess: false,
      };
    // stock Out
    case constants.STOCK_OUT_START:
      return { ...state, isStockOutLoading: true, inStockOutSuccess: false };
    case constants.STOCK_OUT_SUCCESS:
      return {
        ...state,
        inStockOutSuccess: true,
        stockOutDetails: action.payload,
        isStockOutLoading: false,
        isStockUpdateProduct: true
      };
    case constants.STOCK_OUT_ERROR:
      return {
        ...state,
        stockOutDetails: [],
        isStockOutLoading: false,
        inStockOutSuccess: false,
        isStockUpdateProduct: true
      };
    case constants.STOCK_OUT_CLEAR:
      return {
        ...state,
        stockOutDetails: [],
        isStockOutLoading: false,
        inStockOutSuccess: false,
      };
    // stock Move
    case constants.STOCK_MOVE_START:
      return { ...state, isStockMoveLoading: true, inStockMoveSuccess: false };
    case constants.STOCK_MOVE_SUCCESS:
      return {
        ...state,
        inStockMoveSuccess: true,
        stockMoveDetails: action.payload,
        isStockMoveLoading: false,
        isStockUpdateProduct: true
      };
    case constants.STOCK_MOVE_ERROR:
      return { ...state, stockMoveDetails: [], isStockMoveLoading: false, inStockMoveSuccess: false, isStockUpdateProduct: true };
    case constants.STOCK_MOVE_CLEAR:
      return { ...state, stockMoveDetails: [], isStockMoveLoading: false, inStockMoveSuccess: false };
    // End Adjust Stock api
    //Start Item Ledger Pagination
    case constants.ITEM_LEDGER_START:
      return { ...state, isItemLedgerLoaded: true };
    case constants.ITEM_LEDGER_SUCCESS:
      return {
        ...state,
        itemLedgerDetails: action.payload,
        isItemLedgerLoaded: false,
      };
    case constants.ITEM_LEDGER_ERROR:
      return {
        ...state,
        itemLedgerDetails: [],
        isItemLedgerLoaded: false,
        error: action.error,
      };
    case constants.ITEM_LEDGER_CLEAR:
      return { ...state, itemLedgerDetails: [], isItemLedgerLoaded: false };
    //End Item Ledger
    //Start From location product
    case constants.FROM_LOCATION_PRODUCT_START:
      return { ...state, isFromLocationProductLoading: true };
    case constants.FROM_LOCATION_PRODUCT_SUCCESS:
      return {
        ...state,
        fromLocationProductDetails: action.payload,
        isFromLocationProductLoading: false,
      };
    case constants.FROM_LOCATION_PRODUCT_ERROR:
      return {
        ...state,
        fromLocationProductDetails: [],
        isFromLocationProductLoading: false,
      };
    case constants.FROM_LOCATION_PRODUCT_CLEAR:
      return {
        ...state,
        fromLocationProductDetails: [],
        isFromLocationProductLoading: false,
      };
    //End Item Ledger

    //Start From location Row
    case constants.FROM_LOCATION_ROW_START:
      return { ...state, isFromLocationRowLoading: true };
    case constants.FROM_LOCATION_ROW_SUCCESS:
      return {
        ...state,
        fromLocationRowDetails: action.payload,
        isFromLocationRowLoading: false,
      };
    case constants.FROM_LOCATION_ROW_ERROR:
      return {
        ...state,
        fromLocationRowDetails: [],
        isFromLocationRowLoading: false,
      };
    case constants.FROM_LOCATION_ROW_CLEAR:
      return {
        ...state,
        fromLocationRowDetails: [],
        isFromLocationRowLoading: false,
      };
    //End From location Row

    //Start From location Unit
    case constants.FROM_LOCATION_UNIT_START:
      return { ...state, isFromLocationUnitLoading: true };
    case constants.FROM_LOCATION_UNIT_SUCCESS:
      return {
        ...state,
        fromLocationUnitDetails: action.payload,
        isFromLocationUnitLoading: false,
      };
    case constants.FROM_LOCATION_UNIT_ERROR:
      return {
        ...state,
        fromLocationUnitDetails: [],
        isFromLocationUnitLoading: false,
      };
    case constants.FROM_LOCATION_UNIT_CLEAR:
      return {
        ...state,
        fromLocationUnitDetails: [],
        isFromLocationUnitLoading: false,
      };
    //End From location Unit

    //Start From location Shelf
    case constants.FROM_LOCATION_SHELF_START:
      return { ...state, isFromLocationShelfLoading: true };
    case constants.FROM_LOCATION_SHELF_SUCCESS:
      return {
        ...state,
        fromLocationShelfDetails: action.payload,
        isFromLocationShelfLoading: false,
      };
    case constants.FROM_LOCATION_SHELF_ERROR:
      return { ...state, fromLocationShelfDetails: [], isFromLocationShelfLoading: false };
    case constants.FROM_LOCATION_SHELF_CLEAR:
      return { ...state, fromLocationShelfDetails: [], isFromLocationShelfLoading: false };
    //End From location Shelf

    case constants.DOCUMENT_TYPES_REQUEST:
      return { ...state, documentTypesLoading: true };
    case constants.DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        documentTypesDetails: action.payload,
        documentTypesLoading: false,
      };
    case constants.DOCUMENT_TYPES_ERROR:
      return { ...state, documentTypesLoading: false, error: action.error };

    //Start Show API Failed snackbar 
    case constants.API_FAILED_START:
      return { ...state, isApiFiledError: action.payload };
    //End Show API Failed snackbar
    // Start AdjustStock Update then use for API call 
    case constants.GET_TAB_SHIPPING:
      return { ...state, getTabShipping: action.payload }
    case constants.GET_PRODUCT_INDEX:
      return { ...state, getProductIndex: action.payload }
    // End AdjustStock Update then use for API call 
    case constants.SET_CANCEL_PRICE:
      return { ...state, getCancelPrice: action.payload };

    case constants.DISABLE_ADJUST_STOCK_TAB:
      return {
        ...state,
        isTabDisable: action.payload,
      }

    case constants.GET_ALL_ROWS_START:
      return {
        ...state,
        allRowDataLoading: true,
        // Might need
        // allRowsData: initialState.allRowsData,
      }
    case constants.GET_ALL_ROWS_SUCCESS:
      return {
        ...state,
        allRowDataLoading: false,
        allRowsData: action.payload,
      }
    case constants.GET_ALL_ROWS_ERROR:
      return {
        ...state,
        allRowDataLoading: false,
        allRowsData: initialState.allRowsData,
        allRowsDataError: action.payload,
      }

    case constants.GET_SINGLE_ROWS_START:
      return {
        ...state,
        singleRowDataLoading: true,
        singleRowsData: initialState.singleRowsData,
      }
    case constants.GET_SINGLE_ROWS_SUCCESS:
      return {
        ...state,
        singleRowDataLoading: false,
        singleRowsData: action.payload,
      }
    case constants.GET_SINGLE_ROWS_ERROR:
      return {
        ...state,
        singleRowDataLoading: false,
        singleRowsData: initialState.singleRowsData,
        singleRowsDataError: action.payload,
      }

    // start
    case constants.HEADER_HEIGHT_COUNT:
      return { ...state, headerHeightCount: action.payload };
    // end
    case constants.SET_ORDER_INSTRUCTION_NOTE:
      return {
        ...state,
        orderInstructionNote: action.payload,
      };
    case constants.SET_QUOTE_EXPIRY_DATE:
      return {
        ...state,
        quoteExpiryDate: action.payload,
      };
    case constants.SET_SHIPPING_INSTRUCTION_NOTE:
      return {
        ...state,
        shippingInstructionNote: action.payload,
      };
    case constants.SET_TEMPORARY_LOCATION_INSTRUCTION_NOTE:
      return {
        ...state,
        temporaryStorageLocationNote: action.payload,
      };
    //Start Item Ledger Detail
    case constants.ITEM_LEDGER_INOUT_START:
      return { ...state, isItemLedgerInoutLoaded: true };
    case constants.ITEM_LEDGER_INOUT_SUCCESS:
      return {
        ...state,
        itemLedgerInoutDetails: action.payload,
        isItemLedgerInoutLoaded: false,
      };
    case constants.ITEM_LEDGER_INOUT_ERROR:
      return {
        ...state,
        itemLedgerInoutDetails: {},
        isItemLedgerInoutLoaded: false,
        error: action.error,
      };
    case constants.ITEM_LEDGER_INOUT_CLEAR:
      return { ...state, itemLedgerInoutDetails: {}, isItemLedgerInoutLoaded: false };
    //End Item Ledger

    case constants.SET_MOVE_TAB_DISABLE:
      return { ...state, isMoveTabDisable: action.payload };
    // start
    case constants.GET_QUOTE_HISTORY_START:
      return { ...state, isLoading: true, quotesHistory: initialState.quotesHistory, quotesHistoryTotal: initialState.quotesHistoryTotal };
    case constants.GET_QUOTE_HISTORY_SUCCESS:
      return {
        ...state,
        quotesHistory: action.payload.data,
        quotesHistoryTotal: action.payload.total,
        isLoading: false,
      };
    case constants.GET_QUOTE_HISTORY_ERROR:
      return { ...state, isLoading: false };
    // end
    // start
    case constants.GET_ITEM_HISTORY_START:
      return { ...state, isLoading: true, itemHistory: initialState.itemHistory };
    case constants.GET_ITEM_HISTORY_SUCCESS:
      return {
        ...state,
        itemHistory: action.payload,
        isLoading: false,
      };
    case constants.GET_ITEM_HISTORY_ERROR:
      return { ...state, isLoading: false };
    // end
    // start
    case constants.GET_PURCHASE_HISTORY_START:
      return { ...state, isLoading: true, purchaseHistory: initialState.purchaseHistory, purchaseHistoryTotal: initialState.purchaseHistoryTotal };
    case constants.GET_PURCHASE_HISTORY_SUCCESS:
      return {
        ...state,
        purchaseHistory: action.payload.data,
        purchaseHistoryTotal: action.payload.total,
        isLoading: false,
      };
    case constants.GET_PURCHASE_HISTORY_ERROR:
      return { ...state, isLoading: false }
    case constants.GET_INVOICE_HISTORY_START:
      return { ...state, isLoading: true, invoiceHistory: initialState.invoiceHistory, invoiceHistoryTotal: initialState.invoiceHistoryTotal };
    case constants.GET_INVOICE_HISTORY_SUCCESS:
      return {
        ...state,
        invoiceHistoryTotal: action.payload.total,
        invoiceHistory: action.payload.data,
        isLoading: false,
      };
    case constants.GET_INVOICE_HISTORY_ERROR:
      return { ...state, isLoading: false };
    // end
    case constants.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case constants.PURCHASE_ORDER_NUMBER_EDIT:
      return { ...state, isPurchaseOrderNoEdit: action.payload };

    case constants.SEARCH_DRAFT_START:
      return { ...state, searchDrafts: [], isLoading: true, isDraftSearchLoading: true };
    case constants.SEARCH_DRAFT_SUCCESS:
      return {
        ...state,
        searchDrafts: action.payload,
        searchDraftsTotal: action.payload.total,
        isLoading: false,
        isDraftSearchLoading: false,
        draftDataCount:
          action.payload.total > 20
            ? state.draftDataCount + action.payload.count
            : action.payload.count,
      };
    case constants.SEARCH_DRAFT_ERROR:
      return { ...state, isLoading: false, error: action.error, draftDataCount: 0, isDraftSearchLoading: false };
    case constants.CLEAR_SEARCH_DRAFT:
      return {
        ...state,
        searchDrafts: [],
        searchDraftsTotal: 0,
        isLoading: false,
        draftDataCount: 0,
        isDraftSearchLoading: false
      };
    case constants.SET_JOB_NAME:
      return {
        ...state,
        jobNameForAll: action.payload,
      };
    case constants.NO_MAIL_START:
      return { ...state, isLoading: true };
    case constants.NO_MAIL_SUCCESS:
      return { ...state, noMailStatus: action.payload, isLoading: false };
    case constants.NO_MAIL_ERROR:
      return { ...state, noMailStatus: action.payload, isLoading: false };
    case constants.SET_STORE_CREDIT_AMOUNT:
      return {
        ...state,
        storeCreditAmount: action.payload,
      };
    case constants.SET_PRICE_CHECK:
      return {
        ...state,
        priceCheckReducer: action.payload,
      };
    case constants.SET_MARK_AS_QUOTE:
      return {
        ...state,
        markAsQuoteReducer: action.payload,
      };
    case constants.SET_UNPAID_BALANCE:
      return {
        ...state,
        unpaidBalance: action.payload,
      };
    case constants.NEW_PRODUCT_ID:
      return {
        ...state,
        newProductIds: action.payload,
      };
    case constants.SET_BILLING_DETAILS:
      return {
        ...state,
        billingDetails: action.payload,
      };
    case constants.RESET_BILLING_DETAILS:
      return {
        ...state,
        billingDetails: initialState.billingDetails,
      };
    case constants.CLEAR_CART_DRAFTS_START:
      return { ...state, isLoading: true, isCartDraftLoading: true };
    case constants.CLEAR_CART_DRAFTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCartDraftLoading: false
      };
    case constants.CLEAR_CART_DRAFTS_ERROR:
      return {
        ...state,
        isLoading: false,
        isCartDraftLoading: false
      };
    case constants.GET_POS_PRICE_CHANGE_HISTORY_START:
      return { ...state, isLoading: true, posPriceChanagHistory: initialState.posPriceChanagHistory, posPriceChanagHistoryTotal: initialState.posPriceChanagHistoryTotal };
    case constants.GET_POS_PRICE_CHANGE_HISTORY_SUCCESS:
      return {
        ...state,
        posPriceChanagHistoryTotal: action.payload.total,
        posPriceChanagHistory: action.payload.data,
        isLoading: false,
      };
    case constants.GET_POS_PRICE_CHANGE_HISTORY_ERROR:
      return { ...state, isLoading: false };

    case constants.GET_MOVING_AVG_COST_HISTORY_START:
      return { ...state, isLoading: true, movingAvgCostHistory: initialState.movingAvgCostHistory, movingAvgCostHistoryTotal: initialState.movingAvgCostHistoryTotal };
    case constants.GET_MOVING_AVG_COST_HISTORY_SUCCESS:
      return {
        ...state,
        movingAvgCostHistoryTotal: action.payload.total,
        movingAvgCostHistory: action.payload.data,
        isLoading: false,
      };
    case constants.GET_MOVING_AVG_COST_HISTORY_ERROR:
      return { ...state, isLoading: false };
    // end
    case constants.ADD_TO_QTY:
      return {
        ...state,
        lastProductQty: action.payload
      };
    case constants.ADD_TO_INDEX:
      return {
        ...state,
        lastProductViewIndex: action.payload
      };
    case constants.UPDATE_PRICE_SCHEDULE_FALSE:
      return {
        ...state,
        updatePriceScheduleSuccess: false
      };
    case constants.CUSTOMER_REGION_LIST_START:
      return {
        ...state,
        uploadDocRegion: null
      };
    case constants.CUSTOMER_REGION_LIST_SUCCESS:
      return {
        ...state,
        uploadDocRegion: action.payload
      };
    case constants.CUSTOMER_REGION_LIST_ERROR:
      return {
        ...state,
        uploadDocRegion: null
      };
    default:
      return state;
  }
};
export default reducer;