import "@fontsource/inter";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Divider,
  PosButton,
  PosInput,
  PosPageHeading,
} from "apps/pos/Common/uiCore";
import JPSwitch from "components/Switch/JPSwitch";
import Button from "./Common/Button/Button";
import AddDocumentModal from "./pages/customerInfo/Documents/addDocument/AddDocumentModal";
import OpenDocumentModal from "./pages/customerInfo/Documents/openDocument/openDocumentModal";

import { documentByModule } from "constants/enum";
import { AddIcon, CheckIcon, ClearIcon } from "constants/icons";
import { PosFlowDataProp } from "constants/interfaces";
import { Paths } from "enums";
import { RootState } from "models";

import {
  setCustomTaxRate,
  setExemptTax,
  setPosFlowData,
  setTabIndexNumber,
  setTaxExemptManually,
} from "sagas/persistReducer/actions";

import { snackbarDetail } from "constants/constant";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";
import "./taxCalculations.scss";

const TaxCalculation = ({
  shipTaxAmount,
  taxRate,
  setTaxRate,
  taxAmount,
  setCalculateTax,
  setCustomerInfo,
  setIsToggleChange,
  taxExemptFlag
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [countDocument, setCountDocument] = useState(0);
  const { posFlowData, loadCartDetails, isTaxExempted } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { shiptoFacilities, customer } = posFlowData || {};

  const [taxExempt, setTaxExempt] = useState<boolean>(isTaxExempted);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [openModel, setOpenModel] = useState(false);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [newTaxValue, setNewTaxValue] = useState("");
  const [readOnlyInfo, setReadOnlyInfo] = useState(false);
  const [openDocumentModal, setDocumentOpenModal] = useState(false);
  const [keyIndex, setkeyIndex] = useState("");
  const [deleteDocumentData, setDeleteDocumentData] = useState(false);
  const [documentDataObj, setDocumentDataObj] = useState(null);
  const [documentListItem, setDocumentListItem] = useState([]);
  const [isStatusHandler, setIsStatusHandler] = useState(true);
  const [disableEdit, setDisableEdit] = useState(false);

  useEffect(() => {
    if ((shiptoFacilities?.tax?.taxRate === 0 && shiptoFacilities?.tax?.shipTaxFlag === 0) || customer?.taxExemptFlag) {
      setTaxExempt(true);
      setIsStatusHandler(false);
      setDisableEdit(true);
    }
  }, [shiptoFacilities?.tax?.shipTaxFlag, taxExempt, isStatusHandler, taxExemptFlag])

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });
  const taxStatusHandler = () => {
    dispatch(setTaxExemptManually(true))
    setIsStatusHandler(false)
    setTaxExempt(!taxExempt);
    setCalculateTax(!taxExempt);
    setIsToggleChange(!taxExempt)
    dispatch(setExemptTax(!taxExempt));
    if (posFlowData?.defaultTaxStatus) {
      dispatch(
        setPosFlowData({
          ...posFlowData,
          defaultTaxStatus: false,
        })
      );
    } else {
      dispatch(
        setPosFlowData({
          ...posFlowData,
          defaultTaxStatus: true,
        })
      );
    }
  };

  const documentsHandler = (documents) => {

    const countDocuments = documents.filter(
      (e) =>
        e?.documentSaveTypeName?.toLowerCase() ===
        documentByModule.taxExemptCerti.toLowerCase() && !e.readOnly
    ).length;
    setCountDocument(countDocuments);
    return countDocuments;
  }

  const taxExemptvalue = (value) => {
    setTaxExempt(value);
    dispatch(setExemptTax(value));
  }
  useEffect(() => {
    const countDocuments = documentsHandler(posFlowData.addDocument);
    setDocumentListItem(posFlowData.addDocument);
    if (countDocuments > 0) {
      taxExemptvalue(true);
      setDisableEdit(true);
      if (!isTaxExempted) {
        dispatch(snakbarShow({ message: snackbarDetail.taxExamptMessage }));
      }
    } else {
      if (isStatusHandler) {
        taxExemptvalue(taxExempt);
        setCalculateTax(taxExempt);
      }
    }
  }, [posFlowData.addDocument, taxExempt]);

  function removeDocumentItem(indexNumber) {
    const newDocumentList = documentListItem?.filter(
      (data) => new Date(data?.modifiedAt).getTime().toString() !== indexNumber
    );
    setDocumentListItem(newDocumentList);
    const data = {
      ...posFlowData,
      isOrderDocument: false,
      addDocument: newDocumentList,
    };
    const countDocuments = documentsHandler(newDocumentList);
    if (countDocuments > 0) {
      setDisableEdit(true);
      taxExemptvalue(true);
    } else {
      if ((!customer?.taxExemptFlag || shiptoFacilities?.tax?.taxRate !== 0 && shiptoFacilities?.tax?.shipTaxFlag !== 0)) {
        taxExemptvalue(false);
        setDisableEdit(false);
      }
    }
    setCalculateTax(true);
    dispatch(setPosFlowData(data));
    setDeleteDocumentData(false);
  }

  const handleCallback = () => {
    // Required Later
  };

  const handleClick = (_, modifiedAt) => {
    const timeStampModify = new Date(modifiedAt).getTime();
    setkeyIndex(timeStampModify.toString());
  };

  const viewDocument = () => {
    const { cartId } = loadCartDetails || {};
    setCustomerInfo(true);
    dispatch(setTabIndexNumber(1));
    navigate(`${Paths.customer}?cart=${cartId}`, { state: { detail: posFlowData?.customer } });
  }

  const handleOpenModel = () => {
    setOpenModel(true);
  }
  return (
    <>
      <Container className="modal-content-style">
        <main>
          <div className="shipping-address-exempt flex-column">
            <PosPageHeading className="mb-4" heading="Tax" blackHeading />
            <div className="d-flex jcsb">
              <div className="w50 btn-text-design">
                <span className="int-18-reg-bold">Set as exempt</span>
                <span className="tax-ded">
                  {taxExempt
                    ? "This order will be Tax FREE"
                    : "Tax will be added to this order"}
                </span>
              </div>
              <div>
                <div className="w50">
                  <div>
                    <div className="toggle-focus">
                      <JPSwitch
                        {...label}
                        checked={taxExempt}
                        onClick={taxStatusHandler}
                        disabled={disableEdit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider className="pos-divider" />
          <div>
            <div className="shipping-address-row jcsb">
              <div className="w50">
                <span className="int-18-reg">
                  Tax({taxExempt ? 0 : taxRate}%)
                </span>
              </div>

              <div>
                <div className="w50">
                  <div>
                    <span className="int-18-reg-bold">
                      <sup className="dollar-sup">$</sup>
                      {taxExempt
                        ? (0).toFixed(2)
                        : (Number(taxAmount) + Number(shipTaxAmount)).toFixed(
                          2
                        )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="tax-docs">
              <div className="td-block">
                <div className="td-header">
                  <div className="td-header-copy">
                    <h3>{`Tax Exempt ${countDocument === 1 ? "Document" : "Documents"}`}</h3>
                    <span>
                      {countDocument}{" "}
                      {`${countDocument === 1 ? "Document" : "Documents"
                        } added`}
                    </span>
                  </div>
                  <div className="d-flex">
                    <PosButton
                      variant="contained"
                      className="btn-add-customer tax-file-upload-btn mr-2 tax-modal-upload-btn btn"
                      type="button"
                      fontWeight
                      onClick={viewDocument}
                    >
                      View All
                    </PosButton>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <PosButton
                        variant="contained"
                        startIcon={<AddIcon />}
                        color="primary"
                        className="btn-add-customer tax-file-upload-btn btn tax-modal-upload-btn"
                        type="button"
                        onClick={handleOpenModel}
                        fontWeight
                      >
                        Add
                      </PosButton>
                    </div>
                  </div>
                </div>

                <div className="td-list">
                  {posFlowData?.addDocument.map((item, index) => {
                    const date = typeof (item?.lastModified) !== 'string' ? new Intl.DateTimeFormat("en-GB", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    }).format(item?.lastModified) : item?.lastModified;
                    if (
                      item?.customerId === shiptoFacilities?.recipient?.companyID

                    ) {
                      return (
                        <Button
                          className="td-item uploading border-0 bg-transparent"
                          key={index}
                          onClick={() => {
                            handleClick(index, item?.modifiedAt);
                            setDocumentOpenModal(true);
                            setDocumentDataObj({ item, date });
                            item.readOnly
                              ? setReadOnlyInfo(true)
                              : setReadOnlyInfo(false);
                          }}
                        >
                          <div className="item-info">
                            <span className="item-name">{item.file}</span>
                            <span className="item-timestamp-gray">
                              {moment(item?.modifiedAt).format("MMM D, YYYY")}
                            </span>
                          </div>
                        </Button>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            {/* end */}

            {enableEdit ? (
              <>
                <div className="facility-box margintop-10">
                  <span className="payment-text-light">
                    Change tax rate (%) for this order
                  </span>

                  <div className="order-search-results margintop-10 margin-0">
                    <PosInput
                      variant="standard"
                      id="standard-basic"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ maxLength: 6 }}
                      value={newTaxValue}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onChange={(e) => {
                        const percentRegex = /^[+-]?\d*(?:[.,]\d*)?$/;
                        if (percentRegex.test(e.target.value)) {
                          setNewTaxValue(e.target.value);
                        }
                      }}
                      className="order-search-input-results"
                    />
                  </div>
                </div>

                {/* // edit buttons here */}
                <div className="btns-container-discard-save mtb20">
                  <Button
                    classes="btn-discard"
                    onClick={() => {
                      setEnableEdit(false);
                    }}
                  >
                    <ClearIcon />
                    Cancel
                  </Button>
                  <Button
                    classes="btn-savechanges"
                    onClick={() => {
                      if (newTaxValue) {
                        setTaxRate(newTaxValue);
                        setEnableEdit(false);
                        setCustomTaxRate(newTaxValue);
                      }
                    }}
                  >
                    <CheckIcon />
                    Change Tax rate
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </main>
        {posFlowData?.addDocument?.length > 0 && (
          <OpenDocumentModal
            readOnlyInfo={readOnlyInfo}
            open={openDocumentModal}
            setOpenModel={setDocumentOpenModal}
            keyIndex={keyIndex}
            setDeleteDocumentData={setDeleteDocumentData}
            deleteDocumentData={deleteDocumentData}
            removeDocumentItem={removeDocumentItem}
            fileData={documentDataObj}
            handleCallback={handleCallback}
            taxExemption={true}
          />
        )}
        {openModel ? (
          <AddDocumentModal
            locationDocument
            openModel={openModel}
            setOpenModel={setOpenModel}
            taxExemption={true}
            isOrderDocument={true}
            documentBy={documentByModule.tax}
          />
        ) : null}
      </Container>
    </>
  );
};

export default TaxCalculation;
