import * as constants from "./constants";

export function companyDetailsRequest(payload: any) {
  return {
    type: constants.GET_COMPANY_DETAILS_START,
    payload,
  };
}

export function companyDetailsSuccess(payload: any) {
  return {
    type: constants.GET_COMPANY_DETAILS_SUCCESS,
    payload,
  };
}

export function companyDetailsError(payload: string) {
  return {
    type: constants.GET_COMPANY_DETAILS_ERROR,
    payload,
  };
}

export function contactInfoRequest(payload: any) {
  return {
    type: constants.GET_CONTACT_INFO_START,
    payload,
  };
}

export function contactInfoSuccess(payload: any) {
  return {
    type: constants.GET_CONTACT_INFO_SUCCESS,
    payload,
  };
}

export function contactInfoError(payload: string) {
  return {
    type: constants.GET_CONTACT_INFO_ERROR,
    payload,
  };
}

export function domainUrlRequest(payload: any) {
  return {
    type: constants.GET_DOMAIN_URL_START,
    payload,
  };
}

export function domainUrlSuccess(payload: any) {
  return {
    type: constants.GET_DOMAIN_URL_SUCCESS,
    payload,
  };
}

export function domainUrlError(payload: string) {
  return {
    type: constants.GET_DOMAIN_URL_ERROR,
    payload,
  };
}

export function logoRequest(payload: any) {
  return {
    type: constants.GET_LOGO_START,
    payload,
  };
}

export function logoSuccess(payload: any) {
  return {
    type: constants.GET_LOGO_SUCCESS,
    payload,
  };
}

export function logoError(payload: string) {
  return {
    type: constants.GET_LOGO_ERROR,
    payload,
  };
}

export function emailDistrosRequest(payload: any) {
  return {
    type: constants.GET_EMAIL_DISTROS_START,
    payload,
  };
}

export function emailDistrosSuccess(payload: any) {
  return {
    type: constants.GET_EMAIL_DISTROS_SUCCESS,
    payload,
  };
}

export function emailDistrosError(payload: string) {
  return {
    type: constants.GET_EMAIL_DISTROS_ERROR,
    payload,
  };
}

export function carriersRequest(payload: any) {
  return {
    type: constants.GET_CARRIERS_DETAILS_START,
    payload,
  };
}

export function carriersSuccess(payload: any) {
  return {
    type: constants.GET_CARRIERS_DETAILS_SUCCESS,
    payload,
  };
}

export function carriersError(payload: string) {
  return {
    type: constants.GET_CARRIERS_DETAILS_ERROR,
    payload,
  };
}

export function signatureRequest(payload: any) {
  return {
    type: constants.GET_SIGNATURES_DETAILS_START,
    payload,
  };
}

export function signatureSuccess(payload: any) {
  return {
    type: constants.GET_SIGNATURES_DETAILS_SUCCESS,
    payload,
  };
}

export function signatureError(payload: string) {
  return {
    type: constants.GET_SIGNATURES_DETAILS_ERROR,
    payload,
  };
}

export function phonesDetailsRequest(payload: any) {
  return {
    type: constants.GET_PHONES_DETAILS_START,
    payload,
  };
}

export function phonesDetailsSuccess(payload: any) {
  return {
    type: constants.GET_PHONES_DETAILS_SUCCESS,
    payload,
  };
}

export function phonesDetailsError(payload: string) {
  return {
    type: constants.GET_PHONES_DETAILS_ERROR,
    payload,
  };
}

export function paymentMethodsRequest(payload: any) {
  return {
    type: constants.GET_PAYMENT_METHODS_DETAILS_START,
    payload,
  };
}

export function paymentMethodsSuccess(payload: any) {
  return {
    type: constants.GET_PAYMENT_METHODS_DETAILS_SUCCESS,
    payload,
  };
}

export function paymentMethodsError(payload: string) {
  return {
    type: constants.GET_PAYMENT_METHODS_DETAILS_ERROR,
    payload,
  };
}

export function subscriptionRequest(payload: any) {
  return {
    type: constants.GET_SUBSCRIPTION_DETAILS_START,
    payload,
  };
}

export function subscriptionSuccess(payload: any) {
  return {
    type: constants.GET_SUBSCRIPTION_DETAILS_SUCCESS,
    payload,
  };
}

export function subscriptionError(payload: string) {
  return {
    type: constants.GET_SUBSCRIPTION_DETAILS_ERROR,
    payload,
  };
}

export function socialLinksRequest(payload: any) {
  return {
    type: constants.GET_SOCIAL_LINKS_START,
    payload,
  };
}

export function socialLinksSuccess(payload: any) {
  return {
    type: constants.GET_SOCIAL_LINKS_SUCCESS,
    payload,
  };
}

export function socialLinksError(payload: string) {
  return {
    type: constants.GET_SOCIAL_LINKS_ERROR,
    payload,
  };
}

export function trampolineSettingsRequest(payload: any) {
  return {
    type: constants.GET_TRAMPOLINE_SETTINGS_START,
    payload,
  };
}

export function trampolineSettingsSuccess(payload: any) {
  return {
    type: constants.GET_TRAMPOLINE_SETTINGS_SUCCESS,
    payload,
  };
}

export function trampolineSettingsError(payload: string) {
  return {
    type: constants.GET_TRAMPOLINE_SETTINGS_ERROR,
    payload,
  };
}
//newwwwww
export function cannedResponsesRequest(payload: any) {
  return {
    type: constants.GET_CANNED_RESPONSES_START,
    payload,
  };
}

export function cannedResponsesSuccess(payload: any) {
  return {
    type: constants.GET_CANNED_RESPONSES_SUCCESS,
    payload,
  };
}

export function cannedResponsesError(payload: string) {
  return {
    type: constants.GET_CANNED_RESPONSES_ERROR,
    payload,
  };
}

export function appearanceRequest(payload: any) {
  return {
    type: constants.GET_APPEARANCE_DETAILS_START,
    payload,
  };
}

export function appearanceSuccess(payload: any) {
  return {
    type: constants.GET_APPEARANCE_DETAILS_SUCCESS,
    payload,
  };
}

export function appearanceError(payload: string) {
  return {
    type: constants.GET_APPEARANCE_DETAILS_ERROR,
    payload,
  };
}

export function procurementPartnersRequest(payload: any) {
  return {
    type: constants.GET_PROCUREMENT_PARTNERS_DETAILS_START,
    payload,
  };
}

export function procurementPartnersSuccess(payload: any) {
  return {
    type: constants.GET_PROCUREMENT_PARTNERS_DETAILS_SUCCESS,
    payload,
  };
}

export function procurementPartnersError(payload: string) {
  return {
    type: constants.GET_PROCUREMENT_PARTNERS_DETAILS_ERROR,
    payload,
  };
}

export function actorsRequest(payload: any) {
  return {
    type: constants.GET_ACTORS_DETAILS_START,
    payload,
  };
}

export function actorsSuccess(payload: any) {
  return {
    type: constants.GET_ACTORS_DETAILS_SUCCESS,
    payload,
  };
}

export function actorsError(payload: string) {
  return {
    type: constants.GET_ACTORS_DETAILS_ERROR,
    payload,
  };
}

export function warehousesRequest(payload: any) {
  return {
    type: constants.GET_WAREHOUSES_DETAILS_START,
    payload,
  };
}

export function warehousesSuccess(payload: any) {
  return {
    type: constants.GET_WAREHOUSES_DETAILS_SUCCESS,
    payload,
  };
}

export function warehousesError(payload: string) {
  return {
    type: constants.GET_WAREHOUSES_DETAILS_ERROR,
    payload,
  };
}

export function hrRepresentativeRequest(payload: any) {
  return {
    type: constants.GET_HR_REPRESENTATIVE_DETAILS_START,
    payload,
  };
}

export function hrRepresentativeSuccess(payload: any) {
  return {
    type: constants.GET_HR_REPRESENTATIVE_DETAILS_SUCCESS,
    payload,
  };
}

export function hrRepresentativeError(payload: string) {
  return {
    type: constants.GET_HR_REPRESENTATIVE_DETAILS_ERROR,
    payload,
  };
}
