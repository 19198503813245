import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";

import Button from "components/SPButton/Button";
import { ButtonContainerSBbuttons } from "components";
import { PosCheckbox, PosModel, PosSelect } from "apps/pos/Common/uiCore";
import { IconButton } from "apps/springboard/Component/SBItems/elements";

import texts from "apps/springboard/locales";
import { springBoardText } from "constants/constant";

import { assignToEstimator } from "services";
import { UpdateModalContent } from "sagas/modal/actions";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";


interface EstimatorFormData {
    newEstimator: string;
    assignToSubquotes: number;
    notifyCustomer: number;
}

interface SalespersonProps {
    open: boolean;
    assignedDate: string;
    salespersonName: string;
    salespersonData: any[];
    jobQuoteID: number;
    handlerID?: number;
    salespersonID?: number;
    removeEstimatorFn?: ({ jobQuoteID, handlerID }: any) => void;
    handleCloseModel?: () => void
    isLoadingForRemove?: boolean
    isSuccess?: boolean
    handleChangeSuccess?: () => void
    hasSubQuotes?:boolean
}
export const EstimatorForm = ({
    open,
    assignedDate,
    salespersonName,
    salespersonData,
    jobQuoteID,
    handlerID,
    salespersonID,
    removeEstimatorFn,
    handleCloseModel,
    isLoadingForRemove,
    handleChangeSuccess,
    hasSubQuotes
}: SalespersonProps) => {
    const dispatch = useDispatch();
    const { formState, reset } =
        useForm<EstimatorFormData>();

    const [newEstimator, setNewEstimator] = useState(salespersonID);
    const [assignToSubquotes, setAssignToSubquotes] = useState(false);
    const [notifyCustomerForThisQuote , setNotifyCustomerForThisQuote] = useState(false)
    const [notifyCustomerForAllQuote, setNotifyCustomerForAllQuote] = useState(false)
    const [valueChanged, setValueChanged] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { assignToAllSubQuotes, notifyCustomer, estimatorUpdated, notifyCustomerAllSubquotes ,estimatorUpdatedAllSubquotes } = texts;
    const { changeEstimator, newEstimator: newEstimatorLabel, estimatorRequired, assignedOn, currentEstimator, assignEstimator } = springBoardText;

    const ChangeEstimator = () => {
        const snakbarMessage = assignToSubquotes ? estimatorUpdatedAllSubquotes :estimatorUpdated
        let quoteFlag;
        if (!assignToSubquotes) {
            quoteFlag = 0;
        } else {
            quoteFlag = 1;
        }
        setIsLoading(true);
        assignToEstimator(
            jobQuoteID,
            Number(newEstimator),
            handlerID,
            quoteFlag,
            notifyCustomerForThisQuote,
            notifyCustomerForAllQuote        
            ).then(() => {
            handleCloseModel();
            setValueChanged(false);
            handleChangeSuccess();
            dispatch(snakbarShow({ message: snakbarMessage }))
            setIsLoading(false);
            reset();
        });
    }

    const cancelEstimator = () => {
        dispatch(
            UpdateModalContent({
                children: null,
                isOpen: false,
                fixedModal: false,
                removePaddingBottom: false,
                noMaxWidth: false,
            })
        );
        handleCloseModel()
    }

    const removeEstimator = (e) => {
        e.preventDefault()
        removeEstimatorFn({ id: jobQuoteID, handlerID })
    }
    return (
        <Fragment>
            <PosModel
                open={open}
                onClose={handleCloseModel}
                className={`set-customer-code-modal ${(isLoading || isLoadingForRemove) && "busy"}`}
                dialogClassName={`set-customer-code-modal-container`}
                dialogHeader={
                    <div className="modal-header pl-4">
                        <span className="modal-title">
                            <div className="pt-2"><h3 className="f20-Inter-500-sab mb-0 mb-0">{salespersonName ? changeEstimator : assignEstimator}
                            </h3></div>
                        </span>
                        <IconButton
                            name='close'
                            className="close-icon"
                            type='tertiary'
                            onClick={handleCloseModel}
                        />
                    </div>
                }>
                <form
                    id="estimatorForm"
                >
                    <div className="arrow-black">
                        <span className="form-field-title mb-2">{newEstimatorLabel}</span>
                        <PosSelect
                            id={"newEstimator"}
                            name={"newEstimator"}
                            label={"New Estimator"}
                            defaultValue={salespersonID}
                            onChange={((e) => {
                                setNewEstimator(e.target.value)
                                setValueChanged(true)
                            }
                            )}
                            options={salespersonData}
                        />
                    </div>
                    {formState.errors.newEstimator && <div>{estimatorRequired}</div>}

                    <div className="mt-4">
                        <div className={`${hasSubQuotes ? "notify-customer-check":""}`}>
                        <PosCheckbox
                            label={notifyCustomer}
                            className="custom-select"
                            onChange={() => setNotifyCustomerForThisQuote((previousValue) => !previousValue)}
                            checked={notifyCustomerForThisQuote}
                        />
                        </div>
                        {hasSubQuotes ? <> 
                        <PosCheckbox
                            label={assignToAllSubQuotes}
                            checked={assignToSubquotes}
                            onChange={() => setAssignToSubquotes((previousValue) => !previousValue)}
                            className="custom-select"
                        />
                            <PosCheckbox
                                label={notifyCustomerAllSubquotes}
                                className={`custom-select ${!assignToSubquotes ?"notify-allSubQuote-check" :""}`}
                                disabled={!assignToSubquotes}
                                onChange={() => setNotifyCustomerForAllQuote((previousValue) => !previousValue)}
                                checked={notifyCustomerForAllQuote}
                            /></>:null}
                       
                    </div>
                    <section className="subquote-report-section">
                        <Grid container className="subquote-report-container">
                            {salespersonName ?
                                <Grid container className="report-div">
                                    <Grid item xs={12} sm={7}>
                                        <div className="document-types-title">
                                            <span>{currentEstimator}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <div className="send_rfi_row_new ml-2">
                                            <span>{salespersonName}</span>
                                        </div>
                                    </Grid>
                                </Grid>
                                : null}
                            {assignedDate ? <Grid container className="report-div">
                                <Grid item xs={12} sm={7}>
                                    <div className="document-types-title">
                                        <span>{assignedOn}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <div className="send_rfi_row_new ml-2">
                                        <span>{assignedDate}</span>
                                    </div>
                                </Grid>
                            </Grid> : null}
                        </Grid>
                    </section>

                    <ButtonContainerSBbuttons className="justify-content-between mt-4">
                        <div>
                            {salespersonName ?
                                <Button
                                    label="Remove"
                                    disabled={isLoadingForRemove || isLoading}
                                    onClick={(e) => removeEstimator(e)}
                                    type="danger"
                                    loading={isLoadingForRemove}
                                />
                                : null}
                        </div>

                        <div className="d-flex gap-2">
                            <Button
                                label="Cancel"
                                disabled={isLoadingForRemove || isLoading}
                                onClick={cancelEstimator}
                            >
                            </Button>
                            <Button
                                label={salespersonName ? 'Change' : "Assign"}
                                type='primary'
                                loading={isLoading}
                                disabled={isLoading || isLoadingForRemove || !valueChanged}
                                onClick={ChangeEstimator}
                            >                           
                            </Button>
                        </div>
                    </ButtonContainerSBbuttons>
                </form>
            </PosModel>
        </Fragment>
    );
}