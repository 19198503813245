import { Radio, FormControlLabel, FormGroup, RadioGroup } from "@mui/material";

import useStyles from "./style";

interface Props  {
  label: string[];
  groupName: string;
  className?:string;
  selected?:string;
  onChange?:any;
}

const PosRadio = ({  label,groupName,className,onChange,selected }: Props) => {
  const classes = useStyles();
  
  return (
    <FormGroup>
      <RadioGroup  aria-labelledby="demo-radio-buttons-group-label" name={groupName} value={selected} onChange={onChange}>
      {
        label.map((value,index)=>{
          return (
          <FormControlLabel key={index}
          control={<Radio value={value} checked={selected == value}   className={`${classes.root} ${className}`}/>}
          label={value}/>)
        })
      }
      
      </RadioGroup>
    </FormGroup>
  );
};

export default PosRadio;
