import { ReactNode } from "react";
import { Grid, GridProps } from "@material-ui/core";

interface GridInfoProps extends GridProps {
    containerClass?: string;
    children: ReactNode;
}

const PosMainGrid = ({
    containerClass,
    children
}: GridInfoProps) => {

    return (
        <Grid container className={containerClass}>
            {children}
        </Grid>
    );
};

export default PosMainGrid;
