import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
    "&.MuiInputBase-root": {
      borderRadius: "1rem",
      backgroundColor: "#f1f1f1",
      "&.Mui-focused": {
        borderBottom: "none",
      },
      "&:focus": {
        borderBottomColor: "#f1f1f1",
      },
      "& .MuiFilledInput-root": {
        "&.MuiFilledInput-underline:before": {
          borderBottom: "1px solid #000 !important",
        },
      },
    },
    "& .MuiFilledInput-input": {
      padding: "1.375rem",
      fontWeight: "500",
      borderRadius: "1rem",
      backgroundColor: "#f1f1f1",
    },
  },
  placeholder: {
    color: "#000",
  },
  searchBox: {
    position: 'sticky',
    zIndex: 1,
    backgroundColor: 'white',
    top: '0',
  },
  select: {
    "& ul": {
      padding: "1.25rem !important",
      "& .option-display": {
        display: 'contents'
      },
      "& .select-menu-item": {
        fontWeight: "500",
        fontSize: "1.125rem",
        padding:'4px',
        "&:hover": {
          backgroundColor: "#FC6100",
          color: "white",
          paddingLeft: "0.625rem",
        },
        cursor: "pointer",
      },
    },
    "& li": {
      padding: "1.25rem",
      "& option": {
        fontWeight: "500",
        fontSize: "1.125rem",
        "&:hover": {
          backgroundColor: "#FC6100",
          color: "white",
          paddingLeft: "0.625rem",
        },
        cursor: "pointer",
      },
      
    },
    "& .Mui-selected": {
      backgroundColor: "white",
      color: "black",
      paddingLeft: "0.625rem",
    },
  },
  selectSearch: {
    marginBottom: "1rem",
    "& .MuiInputBase-root": {
      padding: '0.7rem 1rem',
      borderRadius: '0.4rem',
      "&:hover": {
        borderColor: '#cbcbcb',
      },
      "& input": {
        fontSize: '1rem !important'
      },
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: '#cbcbcb !important',
        borderWidth: '0'
      }
    },
  }
});

export default useStyles;
