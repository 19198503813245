import { makeStyles } from "../posStyle";

import { colors } from "constants/styles";

const useStyle = makeStyles(({
    title: {
      fontSize: "1.3125rem",
      fontFamily: "Source Code Pro",
      fontStyle: "normal",
      color: colors.titleColor,
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    buttonClassName: {
      padding: '0',
      minWidth: '1.875rem',
      width: '1.5rem',
      height: '1.5rem',
    },
    closeSharp: {
      fontSize: '1.25rem'
    }
  }));
export default useStyle;