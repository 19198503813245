import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IProductsVendors } from "../types";



const initialState: IProductsVendors = {
  isProductVendorsSearch: false,
  getProductVendorsData: null,
  getProductVendorsDataError: null
};

export const productVendorsSlice = createSlice({
  name: "productVendorsSlice",
  initialState,
  reducers: {
    getProductVendorsStart: (state) => {
      state.getProductVendorsData = null;
      state.isProductVendorsSearch = true;
    },
    getProductVendorsSuccess: (state, action: PayloadAction<any>) => {  // Will remove 'any' once we get proper data from BE
      state.getProductVendorsData = action.payload;
      state.isProductVendorsSearch = false;
    },
    getProductVendorsError: (state, action: PayloadAction<any>) => { // Will remove 'any' once we get proper data from BE
      state.getProductVendorsDataError = action.payload;
      state.getProductVendorsData = null;
      state.isProductVendorsSearch = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
   getProductVendorsStart,
   getProductVendorsSuccess,
   getProductVendorsError
} = productVendorsSlice.actions;

export default productVendorsSlice.reducer;
