import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Button, Input, InputOptional, Select } from "../../../components";

import "../../../styles/admin/paymentHistory/paymentHistory.css";
import "../../../styles/admin/setting/setting.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../styles/common.css";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { warehousesRequest } from "../../../sagas/setting/actions";
import { RootState } from "../../../models";
import { warehousesInfo } from "../../../models/setting";
import MaskInput from "apps/pos/Common/maskInput/maskInput";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function Warehouses({distributorId}) {
  const [warehousesData, setWarehousesData] = useState<warehousesInfo>();

  const { warehouses } = useSelector<RootState, any>((state) => state.setting);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(warehousesRequest(distributorId));
  },[]);

  useEffect(() => {
    setWarehousesData(warehouses && warehouses);
  }, [warehouses]);

  
  
  const WarehouseSchema = Yup.object().shape({
    warehouseName: Yup.string().required("Warehouse name required"),
    supervisorName: Yup.string()
    .required("Supervisor name required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    phoneNumber: Yup.string().required("Phone number required"),
    // postalCode: Yup.string()
    //   .required("Postal code required")
    //   .matches(
      //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Phone number is not valid"
      //   )
      //   .max(6),
      address1: Yup.string().required("Address required"),
      // street: Yup.string().required("Street required"),
    city: Yup.string().required("City required"),
    state: Yup.string()
    .required("State required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for company name"),
  });
  
  
  return (
    <PerfectScrollbar options={perfectScrollbarOptions}>
      <div className="setting-list-container">
        <div className="leftbarTitle">Warehouses 55</div>
        <div className="devider2px" />

        <Formik
          initialValues={{
            warehouseName: warehousesData && warehousesData[0]?.label,
            supervisorName: warehousesData && warehousesData[0]?.supervisorName,
            phoneNumber: warehousesData && warehousesData[0]?.phone?.fullPhoneNum,
            // postalCode: "",
            address1: warehousesData && warehousesData[0]?.address?.addressLine,
            address2: "",
            street:  warehousesData && warehousesData[0]?.address?.street,
            city:  warehousesData && warehousesData[0]?.address?.city,
            state:  warehousesData && warehousesData[0]?.address?.state,
            zip:  warehousesData && warehousesData[0]?.address?.zip,
            country:  warehousesData && warehousesData[0]?.address?.country,
          }}
          enableReinitialize
          validationSchema={WarehouseSchema}
          onSubmit={() => {
            // Required Later
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => {
            return (
              <form
                className="formContainer warehouse-container"
                id="addWarehousesInfoForm"
              >
                <Input
                  id={"warehouseName"}
                  name={"warehouseName"}
                  label={"Warehouse Name"}
                  type={"text"}
                  onChange={handleChange}
                  value={values.warehouseName}
                  placeholder={""}
                />

                {errors.warehouseName && touched.warehouseName ? (
                  <p className="validation-error-text">
                    <>{errors.warehouseName}</>
                  </p>
                ) : null}

                <Input
                  id={"supervisorName"}
                  name={"supervisorName"}
                  label={"Supervisor Name"}
                  type={"text"}
                  onChange={handleChange}
                  value={values.supervisorName}
                  placeholder={""}
                />

                {errors.supervisorName && touched.supervisorName ? (
                  <p className="validation-error-text">
                    <>{errors.supervisorName}</>
                  </p>
                ) : null}

                <div className="d-flex">
                  <Select
                    id={"phone"}
                    name={"phone"}
                    label={"Phone"}
                    options={[
                      { label: "+1", value: "+1" },
                      { label: "+91", value: "+91" },
                    ]}
                  />

                  <div className="phone-number-container setting-phone-number-info">
                  <MaskInput                    
                      mask="(999) 999-9999"
                      isClassName="masked-phone-input"
                      id="phoneNumber"
                      name="phoneNumber"
                      alwaysShowMask={true}
                      onChange={handleChange}
                      value={values.phoneNumber}
                    />
                  </div>
                  <div className="ext-container">
                    <InputOptional
                      textInputStyle="extinput"
                      id={"addExt"}
                      name={"addExt"}
                      label={"Ext"}
                      type={"text"}
                      placeholder={""}
                    />
                  </div>
                </div>

                {errors.phoneNumber && touched.phoneNumber ? (
                  <p className="validation-error-text"><>{errors.phoneNumber}</></p>
                ) : null}

                <div className="addressTitle mb-0">Address</div>

                {/* <Input
                  id={"addPostalCode"}
                  name={"addPostalCode"}
                  label={"Postal Code"}
                  type={"text"}
                  onChange={handleChange}
                  value={values.postalCode}
                  placeholder={""}
                />
                {errors.postalCode && touched.postalCode ? (
                  <p className="validation-error-text">{errors.postalCode}</p>
                ) : null} */}
                <Input
                  id={"address1"}
                  name={"address1"}
                  label={"Address 1"}
                  onChange={handleChange}
                  value={values.address1}
                  type={"text"}
                  placeholder={""}
                  className='mt-3'
                />
                {errors.address1 && touched.address1 ? (
                  <p className="validation-error-text"><>{errors.address1}</></p>
                ) : null}
                <InputOptional
                  id={"address2"}
                  name={"address2"}
                  label={"Address 2"}
                  onChange={handleChange}
                  value={values.address2}
                  type={"text"}
                  placeholder={""}
                  className=' warehouse-label'
                />
                {errors.address2 && touched.address2 ? (
                  <p className="validation-error-text"><>{errors.address2}</></p>
                ) : null}
                <Input
                  id={"Street"}
                  name={"Street"}
                  label={"Street"}
                  onChange={handleChange}
                  value={values.street}
                  type={"text"}
                  placeholder={""}
                />
                {errors.city && touched.city ? (
                  <p className="validation-error-text"><>{errors.city}</></p>
                ) : null}
                <div className="d-flex">
                  <Input
                    className="cityinput"
                    id={"city"}
                    name={"city"}
                    onChange={handleChange}
                    value={values.city}
                    label={"City"}
                    type={"text"}
                    placeholder={""}
                  />
                  <div className="width-20"></div>
                  <Input
                    className="cityinput"
                    id={"state"}
                    name={"state"}
                    onChange={handleChange}
                    value={values.state}
                    label={"State"}
                    type={"text"}
                    placeholder={""}
                  />
                </div>
                <div className="carriers-select">
                  <div>
                    {errors.state && touched.state ? (
                      <p className="validation-error-text"><>{errors.state}</></p>
                    ) : null}
                  </div>
                  <div>
                    {errors.zip && touched.zip ? (
                      <p className="validation-error-text"><>{errors.zip}</></p>
                    ) : null}
                  </div>
                </div>

                <div>
                  <i className="fa fa-check-circle" color="#000" />
                  <span className="set-default-text">
                    Set as default warehouse
                  </span>
                </div>

                {/* <div className="add-warehouse-container">
                  <i className="fa fa-plus" color="#000" />
                  <span className="add-warehouse-text">ADD NEW WAREHOUSE</span>
                  <span></span>
                </div> */}
                <div className="devider1px" />

                <Button
                  type={"submit"}
                  onClick={handleSubmit}
                  className="manage-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#jumper_modal"
                >
                  <span className="manage-button-txt">save changes</span>
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </PerfectScrollbar>
  );
}
