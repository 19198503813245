import service from "../config2";

const DISTRIBUTOR_API_PATH = "distributors";

export function getCompanyDetails(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/company`)
    .then((response) => {
      return response.data;
    });
}

export function getContactInfo(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/contact`)
    .then((response) => {
      return response.data;
    });
}

export function getDomainAndUrl(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/domain`)
    .then((response) => {
      return response.data;
    });
}

export function getLogos(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/logos`)
    .then((response) => {
      return response.data;
    });
}

export function getEmailDistros(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/emails`)
    .then((response) => {
      return response.data;
    });
}
export function getCarriers(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/carriers`)
    .then((response) => {
      return response.data;
    });
}

export function getSignature(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/signature`)
    .then((response) => {
      return response.data;
    });
}

export function getPhones(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/phones`)
    .then((response) => {
      return response.data;
    });
}

export function getPaymentMethods(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/paymentmethods`)
    .then((response) => {
      return response.data;
    });
}

export function getSubscription(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/subscription`)
    .then((response) => {
      return response.data;
    });
}

export function getSocialLinks(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/socialmedia`)
    .then((response) => {
      return response.data;
    });
}

export function getTrampoline(key: number) {
  return service
    .get(`/${DISTRIBUTOR_API_PATH}/${key}/trampoline`)
    .then((response) => {
      return response.data;
    });
}

export function getCannedResponses(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/cannedresponse`)
    .then((response) => {
      return response.data;
    });
}

export function getAppearance(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/appearance`)
    .then((response) => {
      return response.data;
    });
}

export function getProcurementPartners(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/procurementpartners`)
    .then((response) => {
      return response.data;
    });
}

export function getActors(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/actors`)
    .then((response) => {
      return response.data;
    });
}

export function getWarehouses(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/warehouses`)
    .then((response) => {
      return response.data;
    });
}

export function getHrRep(key: number) {
  return service
    .get(`${DISTRIBUTOR_API_PATH}/${key}/hrrep`)
    .then((response) => {
      return response.data;
    });
}

export function homepageSearch(keywords: string) {
  return service
    .post(`search`, {
      keywords,
    })
    .then((response) => {
      return response.data;
    });
}
