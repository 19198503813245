import { motion } from 'framer-motion/dist/framer-motion';
import { useEffect, useRef, useState } from 'react';
import AddBookmark from 'layouts/DialogComponent/AddBookmark/AddBookmark';
import EditBookmark from 'layouts/DialogComponent/EditBookmark/EditBookmark';
import ManageBookmark from 'layouts/DialogComponent/ManageBookmark/ManageBookmark'
import { deleteFavoriteLinkById, deleteFavoriteLinks } from "services";

import { ModelNameLabel } from "enums";
import { dialogAnimVariants } from "constants/constant";


import "layouts/css/nav.scss";
import "layouts/css/bookmarks.scss"

const BookMarkDialog = (props) => {
    const { visible, onClose, modelName, favoritesList, setModelName, personID, setFavoritesList } = props;
    const dialogRef = useRef();
    const [dialogSkrim, setDialogSkrim] = useState(false);
    const [editBookmark, setEditBookmark] = useState("");
    const [deleteBookmarkId, setDeleteBookmarkId] = useState("");
    const [editBookmarkId, setEditBookmarkId] = useState("");
    const { add, edit, manage } = ModelNameLabel

    useEffect(() => {

        // set focus to the dialog when visible
        if (visible) {
            dialogRef.current.focus();
            showDialogSkrim();
        }
    }, [visible]);

    // close the dialog on ESC keypress
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key !== 'Escape') return;
            if (e.repeat) return;
            onClose();
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, []);
    
    const showDialogSkrim = () => {
        document.body.classList.add("noscroll");
        setDialogSkrim(true);
    }

    const hideDialogSkrim = () => {
        document.body.classList.remove("noscroll");
        setDialogSkrim(false);
    }

    let dialogs = document.querySelectorAll("dialog");

    const closeAllDialogs = () => {
        onClose(false);
        dialogs.forEach(dialog => {
            dialog.close();
        });
        hideDialogSkrim();
        setModelName("");
        setEditBookmark("");
        setDeleteBookmarkId("");
        setEditBookmarkId("");
    }

    const changeModel = (modelName) => {
        setModelName(modelName)
    }

    useEffect(() => {
        if (deleteBookmarkId) {
            if (deleteBookmarkId === "all") {
                if (favoritesList.length === 0) return;
                let confirmationMessage = `Are you sure you want to delete ${favoritesList.length === 1 ? 'the saved bookmark' : `all ${favoritesList.length} bookmarks`}? This cannot be undone.`
                let permission = window.confirm(confirmationMessage);
                if (!permission) {
                    setDeleteBookmarkId("");
                    return;
                } else {
                    deleteFavoriteLinks(personID).then((data) => {
                        setDeleteBookmarkId("");
                        if (data?.data) {
                            setDeleteBookmarkId("");
                            setFavoritesList([]);
                        }
                    });
                }
            } else {
                let confirmationMessage = `Delete this bookmark? This cannot be undone.`
                let permission = window.confirm(confirmationMessage);
                if (!permission) {
                    setDeleteBookmarkId("");
                    return;
                } else {
                    deleteFavoriteLinkById(deleteBookmarkId, personID).then((data) => {
                        setDeleteBookmarkId("");
                        if (data?.data) {
                            deleteSavedBookmark()
                        }
                    });
                }
            }
        }
    }, [deleteBookmarkId, favoritesList])

    const deleteSavedBookmark = () => {
        let filteredBookmarks = favoritesList.filter((bookmark) => bookmark.bookmarkID !== deleteBookmarkId);
        setFavoritesList(filteredBookmarks)
    }

    useEffect(() => {
        if (editBookmarkId) {
            let filteredBookmarks = favoritesList.filter((bookmark) => bookmark.bookmarkID === editBookmarkId);
            setEditBookmark(filteredBookmarks?.length ? filteredBookmarks[0] : "")
        }
    }, [editBookmarkId])

    return (
        visible ? (
            <>
                <div className={`dialog-skrim ${dialogSkrim ? "visible" : ""}`} onClick={closeAllDialogs} ></div>
                {
                    modelName && modelName === add ?
                        <motion.div
                            className="bookmarks-dialog add-bookmark-dialog"
                            initial='initial'
                            animate='animate'
                            exit='exit'
                            variants={dialogAnimVariants}
                            ref={dialogRef}
                        >
                            <AddBookmark closeAllDialogs={closeAllDialogs} personID={personID} favoritesList={favoritesList} setFavoritesList={setFavoritesList}></AddBookmark>
                        </motion.div> :
                        modelName && modelName === manage ?
                            <motion.div
                                className="bookmarks-dialog manage-bookmarks-dialog"
                                initial='initial'
                                animate='animate'
                                exit='exit'
                                variants={dialogAnimVariants}
                                ref={dialogRef}
                            >
                                <ManageBookmark setEditBookmarkId={setEditBookmarkId} closeAllDialogs={closeAllDialogs} changeModel={changeModel} favoritesList={favoritesList} setFavoritesList={setFavoritesList} personID={personID} setDeleteBookmarkId={setDeleteBookmarkId}></ManageBookmark>
                            </motion.div> :
                            modelName && modelName === edit ?
                                <motion.div
                                    className="bookmarks-dialog edit-bookmark-dialog"
                                    initial='initial'
                                    animate='animate'
                                    exit='exit'
                                    variants={dialogAnimVariants}
                                    ref={dialogRef}
                                >
                                    <EditBookmark closeAllDialogs={closeAllDialogs} changeModel={changeModel} bookmarkDetail={editBookmark} personID={personID} favoritesList={favoritesList} setFavoritesList={setFavoritesList} setDeleteBookmarkId={setDeleteBookmarkId}></EditBookmark>
                                </motion.div> : null
                }
            </>
        ) : null
    );
}

export default BookMarkDialog;
