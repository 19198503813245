/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "loadsh";

import * as Styled from "apps/springboard/QuoteStyleComponent/QuoteStyledComponent";
import ItemGroupWrapper from "apps/springboard/Component/SBItems/Items/ItemGroupWrapper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ButtonComponent from "components/SPButton/Button";

import { setQuoteItems } from "sagas/quoteDetail/actions";
import { IconButton } from "../Component/SBItems/elements";
import SplitButton from "components/SplitButton/SplitButton";

import {
  Distributor,
  quoteDetail,
  QuoteDetailItem,
  QuoteITitle,
  RootState,
} from "models";
import { featureFlags } from "apps/springboard/utils/featureFlags";
import ShapedSkeleton from "apps/springboard/Component/ShapedSkeleton";

import { springBoardDocumentsItems, springBoardText } from "constants/constant";

import { useConvertToExpress } from "./hooks/useConvertToExpress";
import { usePlaceOrder } from "./hooks/usePlaceOrder";
import { useConvertIdToExpress } from "./hooks/useConvertIdToExpress";

import {
  getQuoteItemsbyQuoteId,
  getItemCostTotal,
  getTenantDetail,
} from "services";
import { getQuoteItems, getQuoteReps } from "sagas/quoteDetail/selector";
import { distributorData } from "sagas/distributor/selector";
import '../QuoteDetail/QuoteDetail.scss';
import "styles/admin/springboard/quoteDetails.scss";
import "../SpringBoardCommonStyling.scss";
import "styles/common.css";
import { GlobalNumbers } from "constants/enum";

const StyledTableCell = withStyles(() => ({
  head: {
    color: "#aaa",
    fontSize: 12,
    fontFamily: "Inter, Regular",
    paddingLeft: 0,
    borderBottomWidth: 0,
  },
  body: {
    fontSize: 14,
    fontFamily: "Inter, Regular",
    borderBottomWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
}))(TableCell);

const StyledTableCellTotal = withStyles(() => ({
  head: {
    color: "#aaa",
    fontSize: 12,
    fontFamily: "Inter, Regular",
    paddingLeft: 0,
    borderBottomWidth: 0,
  },
  body: {
    fontSize: 14,
    fontFamily: "Inter, Regular",
    borderBottomWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
    fontWeight: 500,
  },
}))(TableCell);

const TotalTableRow = withStyles(() => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: "#F5F5F5",
    borderBottomColor: "#fff",
    borderBottomWidth: 1,
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 500,
    backgroundColor:"rgba(245, 245, 245, 0.8)",
    borderTopLeftRadius:"1.25rem",
    borderTopRightRadius:"1.25rem",
    border:"0.125rem solid #fff",
    backdropFilter:"blur(0.625rem) saturate(1.5)",
  },
  root: {
    position: "unset",
    "& .MuiTableHead-root": {
      position: "unset",
    },
  },
});

interface QuoteItemProps {
  quoteData: QuoteDetailItem;
  goToDocumentTab: () => void;
  buyPriceReceivedFromRepFlag: number;
}

const QuoteItems = ({ quoteData, goToDocumentTab, buyPriceReceivedFromRepFlag }: QuoteItemProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [quoteItemsData, setQuoteItemsData] = useState([]);
  const [countTotal, setCountTotal] = useState(0);
  const [quoteFilterTotal, setQuoteFilterTotal] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [quoteItemsTitle, setQuoteItemsTitle] = useState<QuoteITitle[]>();
  const [matchData, setMatchData] = useState([]);
  const [jobQuoteID, setJobQuoteID] = useState();
  const [quoteRepId, setQuoteRepId] = useState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEmailEl, setEmailAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorOrderEl, setOrderAnchorEl] = useState<null | HTMLElement>(null);
  const [tenantPlan, setTenantPlan] = useState("");
  const [quoteItemLoading, setQuoteItemLoading] = useState(true);
  const { totalRepPrice, jobQuoteCost, jobLotQuoteTotal, shipFee, marginPercent, itemTotal } = quoteData;

  const quoteReps = useSelector(getQuoteReps)
  const quoteItems = useSelector(getQuoteItems)
  const [quoteItemsFinalData] = quoteItems || [];
  const { productID } = quoteItemsFinalData || {}
  const checkProductId = quoteItems.every(product => product.productID > GlobalNumbers.ZERO);
  const distributor = useSelector(distributorData)

  const {setPlaceOrderId ,placeOrderFlag} = usePlaceOrder()
  const {setConvertToExpress ,showConvertToExpress} = useConvertToExpress()
  const {setConvertIdToExpress ,showConvertIdToExpress} = useConvertIdToExpress()
  const { data: isPlaceOrder } = placeOrderFlag || {};
  const { data: isConvertToExpress } = showConvertToExpress || {};
  const { isPreOrderIsReadyToConvert } = isConvertToExpress || {};
  const { data: convertQuoteID } = showConvertIdToExpress || {};

  const {
    springBoardLite,
    lite,
    full,
    allReps,
    assignedReps,
    assignNewRep,
    editItems,
    total,
    pricingMethod,
    itemRepMargin,
    itemTotal: itemTotalName,
    jobQuoteTotal,
    shippingCharge,
    margin,
    emailQuote,
    setAsWon,
    previewPdf,
    convertToExpressQuote,
    updateMargins,
    placeOrder,
    somethingWrong,
    statusNotValidForQuoteEmail,
    reviewConvertToExpressQuoteAndPlaceOrder
  } = springBoardDocumentsItems;
  const { item, noItem } = springBoardText;
  const { jobQuoteID: quoteJobId } = quoteData || {};

  const itemOptionsArray = [
    {
      border: true,
      itemOptionName: "Ask to assign items ",
      key: "1",
      openLink: `${distributor?.httpsUrl}/tickets/msTicket_create.aspx?assignItem=1&targetTypeID=1&targetID=${jobQuoteID}`,
    },
    {
      itemOptionName: "Layout for Customer",
      key: "2",
      openLink: `${distributor?.httpsUrl}/gmSale/msJobLotQuote_detailForCustomer?quotePackageID=${quoteRepId}&jobLotQuoteID=${quoteJobId}`,
    },
    {
      itemOptionName: "Shuffle/Rearrange",
      key: "3",
      openLink: `${distributor?.httpsUrl}/gmSale/msJobLotQuote_shuffleItems?jobLotQuoteID=${quoteJobId}`,
    },
    Boolean(productID) && {
      border: true,
      itemOptionName: "Insert items into an order",
      key: "4",
      openLink: `${distributor?.httpsUrl}/gmSale/msJobLotQuote_insertItemInOrder?jobLotQuoteID=${quoteJobId}`,
    },
    {
      itemOptionName: "Lead Time Schedule",
      key: "5",
      openLink: `${distributor?.httpsUrl}/gmSale/msJobLotQuote_leadTimeSchedule?jobLotQuoteID=${quoteJobId}`,
    },
    {
      itemOptionName: "Create Submittal Cover Sheet",
      key: "6",
      openLink: `${distributor?.httpsUrl}/gmSale/msJobLotQuote_submittal_coverSheet_add?jobLotQuoteID=${quoteJobId}`,
    },
    {
      border: true,
      itemOptionName: "Copy to PDF submittal callout data",
      key: "7",
      openLink: `${distributor?.httpsUrl}/gmSale/msJobLotQuote_submittal_copyCalloutData?jobLotQuoteID=${quoteJobId}`,
    },
    {
      itemOptionName: "View Sent History",
      key: "8",
      openLink: `${distributor?.httpsUrl}/gmSale/msJobLotQuote_viewSentHistory?jobLotQuoteID=${quoteJobId}`,
    },
    {
      itemOptionName: "Project Status Report",
      key: "9",
      openLink: `${distributor?.httpsUrl}/gmSale/msJobLotQuote_projectStatus_report?jobLotQuoteID=${quoteJobId}`,
    },
    {
      itemOptionName: "Copy Items From A Quote",
      key: "10",
      openLink: `${distributor?.httpsUrl}/gmSale/msJobLotQuote_copyItemsFromQuote?jobLotQuoteID=${quoteJobId}`,
    },
  ].filter(Boolean);

  useEffect(() => {
    getTenantDetail().then((result) => {
      const plan = result?.subscription?.plan?.name || "";
      setTenantPlan(plan === springBoardLite ? lite : full);
    });
  }, []);

  useEffect(() => {
    setPlaceOrderId(quoteData?.jobQuoteID)
    setConvertToExpress(quoteData?.jobQuoteID)
    setConvertIdToExpress(quoteData?.jobQuoteID)
  },[quoteData?.jobQuoteID])

  useEffect(() => {
    const tempData = [];

    quoteItemsData &&
      quoteItemsData.length &&
      quoteItemsData.forEach((item) => {
        quoteReps &&
          quoteReps.length &&
          quoteReps.forEach((item1) => {
            if (item.title === item1.quoteRepName) {
              tempData.push(item1);
            }
          });
      });

    setMatchData(tempData);
  }, [quoteReps, quoteItemsData]);

  useEffect(() => {
    let loadedQuoteItems = false;
    let counts = 1;

    getQuoteItemsbyQuoteId(quoteJobId).then((quoteItem) => {
      setQuoteItemLoading(false);
      const { count, data } = quoteItem || {};
      if (!loadedQuoteItems) {
        dispatch(setQuoteItems(data));
        const quoteItemTitle = [{ title: allReps }];
        const result = _(data)
          .groupBy((o) => o?.repName || "")
          .map((data, title) => ({ title, data }))
          .value();
        const sortedData = result.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        const sortBasedOnType = sortedData.sort((a, b) => {
          const comparison = a.rowNum - b.rowNum;
          if (comparison === GlobalNumbers.ZERO) {
            return a.itemCode.localeCompare(b.itemCode);
          }
          return comparison;
        });
        sortBasedOnType.forEach((data, ind) => {
          data.data.forEach((_, index) => {
            result[ind].data[index].indexNumber = counts++;
          });
          quoteItemTitle.push({ title: data?.title });
        });
        setQuoteItemsData(result);
        setFilterData(result);
        setCountTotal(count);
        setQuoteFilterTotal(data);
        setQuoteItemsTitle(quoteItemTitle);
        setJobQuoteID(quoteItem && data?.[0]?.jobQuoteID);
        setQuoteRepId(quoteItem && data?.[0]?.quoteRepID);
      }
    });
    return () => {
      loadedQuoteItems = true;
    };
  }, [quoteData?.jobQuoteID]);

  useEffect(() => {
    const data = filterData;
    const data1 = quoteFilterTotal;
    if (
      quoteItemsTitle &&
      quoteItemsTitle.length &&
      quoteItemsTitle[selectedIndex]?.title === allReps
    ) {
      setQuoteItemsData(filterData);
    } else {
      const tempData = data.filter(
        (e) => e.title === quoteItemsTitle[selectedIndex]?.title
      );
      const tempData1 = data1.filter(
        (e) => e.repName === quoteItemsTitle[selectedIndex]?.title
      );
      setQuoteItemsData(tempData);
    }
  }, [selectedIndex, quoteItemsTitle]);

  const open = Boolean(anchorEl);
  const openEmail = Boolean(anchorEmailEl);
  const openOrder = Boolean(anchorOrderEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openLink = (url) => {
    window.open(url, "_blank");
  };

  const emailQuoteOpen = () => {
    setEmailAnchorEl(null);
    if (quoteData.status > 0 && quoteData.status !== 3) {
      getItemCostTotal(jobQuoteID).then((quoteItem) => {
        let unitCostTot = 0;
        let repCostTot = quoteItem?.unitCostTotal;
        unitCostTot = Math?.round(unitCostTot);
        repCostTot = Math?.round(repCostTot);
        if (unitCostTot <= repCostTot) {
          openLink(
            `${distributor?.httpsUrl}/gmSale/msJobLotQuote_send.aspx?jobLotQuoteID=${jobQuoteID}`                            );
        } else {
          alert("Something Wrong");
        }
      });
    } else {
      alert("Status not valid for Quote Email");
    }
  }

  const dropdownOptions = [
    {
      label: placeOrder,
      id: 1,
      visible: isPlaceOrder,
    },
    { label: convertToExpressQuote, id: 2, visible: isPreOrderIsReadyToConvert && checkProductId },
    { label: updateMargins, id: 3, visible: true },
    { label: reviewConvertToExpressQuoteAndPlaceOrder, id: 4, visible: isPreOrderIsReadyToConvert && checkProductId },
  ].filter((item) => item?.visible);

  const getSellerId = async () => {
    return localStorage.getItem("sellerID");
  };

  const handleSplitButton =  (event) => {
    const { target } = event || {};
    switch (Number(target.id)) {
      case GlobalNumbers.ONE:
        if (quoteData.status > 0 && quoteData.status !== 3) {
          getItemCostTotal(jobQuoteID).then(async (quoteItem) => {
            let unitCostTot = 0;
            let repCostTot = quoteItem?.unitCostTotal;
            unitCostTot = Math?.round(unitCostTot);
            repCostTot = Math?.round(repCostTot);
            if (unitCostTot <= repCostTot) {
              const sellerID = await getSellerId();
              // Will remove later -> Old Link : `${distributor?.httpsUrl}/gmSale/msJobLotQuote_send.aspx?jobLotQuoteID=${jobQuoteID}&sellerID=${sellerID}`
              openLink(
                `${distributor?.httpsUrl}/shopping/job-lot-quote-check.aspx?jobLotQuoteID=${jobQuoteID}&sellerID=${sellerID}`
              );
            } else {
              alert(somethingWrong);
            }
          });
        } else {
          alert(statusNotValidForQuoteEmail);
        }
        break;
      case GlobalNumbers.TWO:
        setOrderAnchorEl(null);
        openLink(
          `${distributor?.httpsUrl}/gmSale/msJobLotQuote_convert.aspx?jobLotQuoteID=${jobQuoteID}`
        );
        break;
      case GlobalNumbers.THREE:
        setOrderAnchorEl(null);
        openLink(
          `${distributor?.httpsUrl}/gmSale/msJobLotQuote_preview?jobLotQuoteID=${jobQuoteID}`
        );
        break;
      case GlobalNumbers.FOUR:
        setOrderAnchorEl(null);
        openLink(
          `${distributor?.httpsUrl}/gmSale/msJobLotQuote_ReviewConvetedQuote?convertQuoteID=${convertQuoteID}&jobLotQuoteID=${jobQuoteID}`
        );
        break;
    }
  };

  return (
    <div className="quote-details-items">
      {quoteItemLoading ? 
        <ShapedSkeleton
          shape='0 0 76 20, 0 24 104 14, 88 74 200 14, 477 160 100 14, 477 74 100 14, 593 74 100 14, 593 160 100 14, 709 74 100 14, 709 160 100 14, 88 92 100 14, 88 160 200 14, 88 178 100 14, 0 74 24 14, 0 160 24 14, 40 74 32 32, 40 160 32 32, 114 24 104 14'
          width={809}
          height={192}
          radius={4}
          classes="mt-3"
        /> : 
      <>
        <div className="filter-header">
        <div className="filter-sub-header">
          <span className="fw-medium fs-14 ">
          {Boolean(countTotal)
            ? `${countTotal} ${item}${
                countTotal > 1 ? "s" : ""
          }`
          : noItem}
          </span>
          <div className="dropdown spr-dropdwn">
            <button
              className="fs-14 btn p-0 dropdown-toggle inter-med"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {quoteItemsTitle &&
                quoteItemsTitle.length &&
                quoteItemsTitle[selectedIndex]?.title}
            </button>
            <div
              className="dropdown-menu selectList"
              aria-labelledby="dropdownMenuButton"
            >
              <Styled.DropDownHeader>{assignedReps}</Styled.DropDownHeader>
              {quoteItemsTitle &&
                quoteItemsTitle.length &&
                quoteItemsTitle.map((option, index) => {
                  return (
                    <Styled.DropDownItem
                      key={index}
                      onClick={() => {
                        setSelectedIndex(index);
                      }}
                      className="p-1"
                    >
                      <span className="fs-14 inter-reg">{option?.title}</span>
                    </Styled.DropDownItem>
                  );
                })}
              <Styled.DropDownItem
                key={0}
                onClick={() => {
                  window.open(
                    `${distributor?.httpsUrl}/gmSale/msJobLotQuote_repAssigned_add?jobLotQuoteID=${quoteJobId}`,
                    "_blank"
                  );
                }}
                className="p-1"
              >
                <span className="fs-14 inter-reg">{assignNewRep}</span>
                <i className="fas fa-plus" />
              </Styled.DropDownItem>
            </div>
          </div>
        </div>
        <div className="header-actions">
            {featureFlags.editItemsBtn && (<ButtonComponent
              label={editItems}
              leadingIcon='edit_note'
              type='secondary'
              size='small'
              href={`${distributor?.httpsUrl}/gmSale/msJobLotQuote_RepItem_Add?jobLotQuoteID=${jobQuoteID}&quoteRepID=${quoteRepId}`}
              target='_blank'
            />)}
            {/* <i className="fas fa-ellipsis-v" color="#000" /> */}
            <IconButton type="tertiary" name={"more_vert"} className="side-menu"
              aria-labelledby="dropdownMenuButton"
              onClick={handleClick} />
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "30ch",
                padding: 10,
                borderRadius: 10,
              },
            }}
          >
            {itemOptionsArray.map((item) => {
              return (
                <MenuItem
                  key={item.key}
                  onClick={() => {
                    handleClose();
                    openLink(`${item.openLink}`);
                  }}
                  className={`${item.border ? "div_bottom_border" : ""}`}
                >
                  {item.itemOptionName}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>
      <>
    {quoteItemsData?.length ? (
      <>
      <ItemGroupWrapper
        quoteItemsData={quoteItemsData}
        jobQuoteID={jobQuoteID}
        quoteRepId={quoteRepId}
        distributor={distributor}
        buyPriceReceivedFromRepFlag={buyPriceReceivedFromRepFlag}
      />
      <TableContainer className="item-total-table">
        <Table className={classes.table}>
          <TableBody>
              <TotalTableRow
                key={`repSwitch_total1`}
                style={{
                  marginTop: 50,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                  backgroundColor:"transparent",
                  borderBottomColor:"transparent",
                  borderBottomWidth:0
                }}
              >
                <StyledTableCellTotal
                  component="th"
                  scope="row"
                  colSpan={7}
                  style={{ paddingLeft: 15 }}
                  className="text-black quote-item-total-row"
                >
                  {total}
                </StyledTableCellTotal>

                <StyledTableCellTotal
                  align="left"
                  className="text-black fw-500 quote-item-total-row"
                >
                  { totalRepPrice ? `$ ${totalRepPrice || ""}` : null }
                </StyledTableCellTotal>
                <StyledTableCellTotal
                  colSpan={4}
                  align="left"
                  className="text-black fw-500 quote-item-total-row"
                >
                   { jobQuoteCost ? `$ ${jobQuoteCost || ""}` : null }
                </StyledTableCellTotal>
              </TotalTableRow>
            

            <TotalTableRow key={`repSwitch_total3`}
            style={{
              backgroundColor:"transparent",
              borderBottomColor:"transparent",
              borderBottomWidth:0
            }}>
              <StyledTableCell
                align="left"
                className="text-black fw-500"
                component="th"
                scope="row"
                colSpan={6}
              >
                <div
                  className="fdr"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div className="px-3">
                    <p className="fs-10 inter-reg mb-1 text-black fw-500">
                      {pricingMethod}
                    </p>
                    <div className="fw-medium fs-14 text-dark text-black fw-500">
                      {itemRepMargin}
                    </div>
                  </div>
                  <div className="px-3">
                    <p className="fs-10 inter-reg mb-1 text-black fw-500">
                      {itemTotalName}
                    </p>
                    <div className="fw-medium fs-14 text-dark">
                    { itemTotal ? `$ ${itemTotal}` : null }
                    </div>
                  </div>
                  <div className="px-3">
                    <p className="fs-10 inter-reg mb-1 text-black fw-500">
                      {jobQuoteTotal}
                    </p>
                    <div className="fw-medium fs-14 text-black fw-500">
                    { jobLotQuoteTotal ? `$ ${jobLotQuoteTotal}` : null }
                    </div>
                  </div>
                  <div className="px-3">
                    <p className="fs-10 inter-reg mb-1 text-black fw-500">
                      {shippingCharge}
                    </p>
                    <div className="fw-medium fs-14 text-dark">
                    { shipFee ? `$ ${shipFee}` : null }
                    </div>
                  </div>
                  <div className="px-3">
                    <p className="fs-10 inter-reg mb-1 text-black fw-500">
                      {margin}
                    </p>
                    <div className="fw-medium fs-14 text-black fw-500">
                      {marginPercent}%
                    </div>
                  </div>
                </div>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className="text-black fw-500"
                colSpan={2}
              >
                <div className="d-flex">
                  <Button
                    className="email-quote-btn email-quote-main-btn"
                    id="demo-customized-button"
                    aria-controls="demo-customized-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      openLink(
                        `${distributor?.httpsUrl}/gmSale/msJobLotQuote_previewPDF?jobLotQuoteID=${jobQuoteID}`
                      );
                    }}
                    style={{ backgroundColor: "#fff", marginRight: "2px" }}
                  >
                    <span>{previewPdf}</span>
                  </Button>
                  <Button
                    id="demo-customized-button"
                    aria-controls="demo-customized-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={(e) => setEmailAnchorEl(e.currentTarget)}
                    style={{
                      backgroundColor: "white",
                      width: "fit-content",
                      padding: "0px 10px",
                      minWidth: "unset",
                    }}                   
                    className="email-quote-btn-dropdown"
                    endIcon={<i className="fas fa-caret-down" />}
                  ></Button>
                </div>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEmailEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  open={openEmail}
                  onClose={() => setEmailAnchorEl(null)}
                  PaperProps={{
                    style: {
                      width: "30ch",
                      borderRadius: "10px",
                    },
                  }}
                >
                  <MenuItem
                    key={"1"}
                    onClick={emailQuoteOpen}
                  >
                    {emailQuote}
                  </MenuItem>
                </Menu>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className="text-black fw-500"
                colSpan={2}
              >
                {tenantPlan === "LITE" && quoteData?.status === 5 ? (
                  <>
                    <Button
                      id="demo-customized-button"
                      aria-controls="demo-customized-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      onClick={() => {
                        // getTest(joblotQuoteId, quoteData?.creatorID);
                        openLink(
                          `${distributor?.httpsUrl}/msJobLotQuote_SetAsWon?jobLotQuoteID=${jobQuoteID}&managerID=${quoteData?.creatorID}`                        );
                      }}
                      style={{ backgroundColor: "#fff", width: "130px" }}
                    >
                      <span>{setAsWon}</span>
                    </Button>
                  </>
                ) : true ? (
                  <>
                    <div className="table-btn-container">
                      <SplitButton options={dropdownOptions} onClick={(event) => handleSplitButton(event)}/>
                    </div>
                  </>
                ) : null}
              </StyledTableCell>
            </TotalTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      </>
        ) : (
          null
        )}
      </> 
      </>}
    </div>
  );
};

export default QuoteItems;
