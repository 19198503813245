import { Grid } from "@material-ui/core";
import CommonEditButton from "apps/product/Components/ProductDetails/CommonEditButton";
import useFilterSections from "apps/product/Components/ProductDetails/useSectionFilter";
import CreateGridStarIcon from "apps/product/Components/ProductDetails/CreateGridStarIcon";
import { sectionName } from "apps/product/modules/productDetailsContainer/constants";
import { dayJsFormat } from "constants/staticData";
import { formatDate } from "utils/dayjsFunc";


const ProductDetailsNotes = ({ isStarred = false, searchText, notes, handleNotesSearch }) => {

  const { notes:notesSection } = sectionName;
  const { showSection } = useFilterSections({ searchText, sectionName: notesSection, isStarred }); 

  return (
    <>
      <section className="product-report-section">
        {
          notes?.length ?
            notes.map((option) => {
              const {note , noteId , modifiedByName , modifiedAt , modifiedBy} = option;
              const smallText = modifiedAt ? formatDate(new Date(modifiedAt), dayJsFormat.MMDDYYYY) : ""
              return(
              <Grid container className="product-report-container" key={`${modifiedBy + "_" + noteId}`}>
                {showSection(note,modifiedByName) ? (
                  <Grid container className="report-div">
                      <CreateGridStarIcon className={note} label={modifiedByName} value={noteId} sectionName={notesSection} smallText={smallText}/>
                    <Grid item xs={12} sm={8}>
                      <span>{note}</span>
                    </Grid>
                      <CommonEditButton onClick={handleNotesSearch(noteId)} />
                  </Grid>
                ) : null}
              </Grid>
            )})
            : null
        }

      </section>
    </>
  );
};

export default ProductDetailsNotes;
