import { headCells } from './data';

export const EnhancedTableHead = (props) => {
    const {
      classes,
      order,
      orderBy,
    } = props;
  
    return (
      <div className="table-head-row-purchase">
        {headCells.map((headCell) => (
          <div
            className={`purchase-history-table-header ${headCell.class}`}
            key={headCell.id}
            style={{
              textAlign: "left",
            }}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </span>
            ) : null}
          </div>
        ))}
      </div>
    );
  }