import React, { useState, useEffect } from "react";
import { CannedResponses } from "./CannedResponses";
import { Carriers } from "./Carriers";
import { getTenantDetail } from "../../../services";

// Settings page components
import { Company } from "./Company";
import { ContactInfo } from "./ContactInfo";
import { DomainUrls } from "./DomainUrls";
import { EmailDistors } from "./EmailDistors";
import { Logo } from "./logo";
import { PaymentMethods } from "./PaymentMethods";
import { Phones } from "./Phones";
import { Signatures } from "./Signatures";
import { SocialLinks } from "./SocialLinks";
import { Subscription } from "./Subscription";
import { TrampolineSettings } from "./TrampolineSettings";
import { Helmet } from "react-helmet";

import { Appearance } from "./Appearance";
// import { ProcurementPartners } from "./ProcurementPartners";
import { Actors } from "./Actors";
import { HrRepresentative } from "./HrRepresentative";
import { Warehouses } from "./Warehouses";
import "../../../styles/admin/setting/setting.css";
import { Container } from "@material-ui/core";

export function Settings({distributorId}) {
  const [tenantPlan, setTenantPlan] = useState("");

  const scrollToSetting = (element: string) => {
    const elementId = document?.getElementById(element);
    elementId?.scrollIntoView({
      behavior: "smooth",
      // block: "end",
      // inline: "nearest",
    });
  };

  useEffect(() => {
    getTenantDetail().then((result) => {
      const plan = result?.subscription?.plan?.name || "";
      setTenantPlan(plan === "Springboard LITE" ? "LITE" : "FULL");
    });
  }, []);

  return (
    <>
      <div className="main-container justify-content-center setting-main-section">
        <Helmet>
          <title>Admin</title>
          <meta name="Admin page" content="admin page" />
        </Helmet>
        <Container className="d-flex">
          <div className="list-container">
            <div>
              <h2 className="heading text-black">Settings</h2>
            </div>
            <div style={{ marginTop: 30 }}>
              <ul className="list">
                <li
                  className="list-item"
                  onClick={() => {
                    scrollToSetting("company");
                  }}
                >
                  Company
                </li>
                <li
                  className="list-item"
                  onClick={() => {
                    scrollToSetting("contact-info");
                  }}
                >
                  Contact Info
                </li>
                <li
                  className="list-item"
                  onClick={() => {
                    scrollToSetting("domain-urls");
                  }}
                >
                  Domain and Urls
                </li>
                <li
                  className="list-item"
                  onClick={() => {
                    scrollToSetting("logo");
                  }}
                >
                  Logo
                </li>
                <li
                  className="list-item"
                  onClick={() => {
                    scrollToSetting("email-distors");
                  }}
                >
                  Email Distros
                </li>
                {tenantPlan === "FULL" && (
                  <li
                    className="list-item"
                    onClick={() => {
                      scrollToSetting("carriers");
                    }}
                  >
                    Carriers
                  </li>
                )}
                <li
                  className="list-item"
                  onClick={() => {
                    scrollToSetting("signatures");
                  }}
                >
                  Signatures
                </li>
                <li
                  className="list-item"
                  onClick={() => {
                    scrollToSetting("phones");
                  }}
                >
                  Phones
                </li>
                {tenantPlan === "FULL" && (
                  <li
                    className="list-item"
                    onClick={() => {
                      scrollToSetting("payment-methods");
                    }}
                  >
                    Payment Methods
                  </li>
                )}
                <li
                  className="list-item"
                  onClick={() => {
                    scrollToSetting("subscription");
                  }}
                >
                  Subscription
                </li>
                {tenantPlan === "FULL" && (
                  <>
                    <li
                      className="list-item"
                      onClick={() => {
                        scrollToSetting("social-links");
                      }}
                    >
                      Social links
                    </li>
                    <li
                      className="list-item"
                      onClick={() => {
                        scrollToSetting("trampoline-settings");
                      }}
                    >
                      Trampoline settings
                    </li>
                  </>
                )}
                <li
                  className="list-item"
                  onClick={() => {
                    scrollToSetting("canned-responses");
                  }}
                >
                  Canned Responses
                </li>
                <li
                  className="list-item"
                  onClick={() => {
                    scrollToSetting("appearance");
                  }}
                >
                  Appearance
                </li>
                {/* <li
                className="list-item"
                onClick={() => {
                  scrollToSetting("procurement-partners");
                }}
              >
                Procurement Partners
              </li> */}
                <li
                  className="list-item"
                  onClick={() => {
                    scrollToSetting("actors");
                  }}
                >
                  Actors
                </li>
                <li
                  className="list-item"
                  onClick={() => {
                    scrollToSetting("warehouses");
                  }}
                >
                  Warehouses
                </li>
                {tenantPlan === "FULL" && (
                  <li
                    className="list-item"
                    onClick={() => {
                      scrollToSetting("hr-representative");
                    }}
                  >
                    HR Representative
                  </li>
                )}
              </ul>
            </div>
          </div>

          {/*All Settings components will go here*/}
          <div className="d-flex justify-content-end w-100 setting-container">
            <div className="setting-right-content-section">
              <div id="company">
                <Company tenantPlan={tenantPlan} distributorId={distributorId} />
              </div>
              <div id="contact-info">
                <ContactInfo distributorId={distributorId}/>
              </div>
              <div id="domain-urls">
                <DomainUrls distributorId={distributorId}/>
              </div>
              <div id="logo">
                <Logo distributorId={distributorId}/>
              </div>
              <div id="email-distors">
                <EmailDistors tenantPlan={tenantPlan} distributorId={distributorId}/>
              </div>
              {tenantPlan === "FULL" && (
                <div id="carriers">
                  <Carriers distributorId={distributorId}/>
                </div>
              )}
              <div id="signatures">
                <Signatures distributorId={distributorId}/>
              </div>
              <div id="phones">
                <Phones distributorId={distributorId}/>
              </div>
              {tenantPlan === "FULL" && (
                <div id="payment-methods">
                  <PaymentMethods distributorId={distributorId}/>
                </div>
              )}
              <div id="subscription">
                <Subscription distributorId={distributorId}/>
              </div>
              {tenantPlan === "FULL" && (
                <>
                  <div id="social-links">
                    <SocialLinks distributorId={distributorId}/>
                  </div>
                  <div id="trampoline-settings">
                    <TrampolineSettings distributorId={distributorId}/>
                  </div>
                </>
              )}
              <div id="canned-responses">
                <CannedResponses distributorId={distributorId}/>
              </div>
              <div id="appearance" className="mb-5">
                <Appearance />
              </div>
              {/* <div id="procurement-partners" className="mb-5">
            <ProcurementPartners />
          </div> */}
              <div id="actors" className="mb-5">
                <Actors distributorId={distributorId}/>
              </div>
              <div id="warehouses" className="mb-5">
                <Warehouses distributorId={distributorId}/>
              </div>
              {tenantPlan === "FULL" && (
                <div id="hr-representative" className="mb-5">
                  <HrRepresentative distributorId={distributorId}/>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
