import { Formik } from "formik";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import { InputAdornment } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { ErrorMessage } from "apps/pos/formik/errorMessage";
import { UpdatedCostAndPriceProductDetailSchema } from "apps/pos/formik/validationSchema";
import {
  PosButton,
  PosCheckbox,
  PosDatePicker,
  PosInput,
} from "apps/pos/Common/uiCore";
import IconButton from "apps/product/Modals/Elements/IconButton";

import { ProductDetailText } from "constants/enum";
import {
  alertMessage,
  fiveDecimalRegex,
  overrideMarginMote,
  wholesaleMarginNote,
} from "constants/constant";
import {
  authorizationToken,
  blockInvalidChar,
  getSalesPrice,
} from "utils/utils";
import { labels } from "../../modules/productDetailsContainer/constants";
import { setFocusTo } from "utils/utils";

import useProductsDetailsHooks from "../../modules/productDetailsContainer/hooks/mutationHooks";
import {
  removeClearancePriceProductStart,
  removeDiscontinuedPriceProductStart,
  updateCostAndPriceProductStart,
} from "sagas/productsDetails/singleProductDetails.slice";

import "../ProductDetailsCostsModals.scss";
import "../ProductDetailsCommonModal.scss";

const ProductDetailsCostsModals = ({
  open,
  onClose,
  focusText,
  distributor,
  getSingleProductData,
  updateCostAndPriceProduct,
  removeFocus,
}) => {
  const dispatch = useDispatch();

  const {
    updateCostAndPriceProductsMutation,
    removeDiscontinuedPriceProductsMutation,
    removeClearancePriceProductsMutation,
  } = useProductsDetailsHooks();

  const { confirmDeleteForDiscontinued, confirmDeleteForClearance } =
    alertMessage;
  const [focusFirstInputPrice, setFocusFirstInputPrice] = useState(false);
  const [focusSecondInputPrice, setFocusSecondInputPrice] = useState(false);
  const [focusFirstInputOverride, setFocusFirstInputOverride] = useState(false);
  const [focusSecondInputOverride, setFocusSecondInputOverride] =
    useState(false);
  const [focusFirstInputDiscontinued, setFocusFirstInputDiscontinued] =
    useState(false);
  const [focusSecondInputDiscontinued, setFocusSecondInputDiscontinued] =
    useState(false);
  const [focusFirstInputClearance, setFocusFirstInputClearance] =
    useState(false);
  const [focusSecondInputClearance, setFocusSecondInputClearance] =
    useState(false);

  const { costsAndPrices, details, availability } = getSingleProductData || {};
  const { productId, measurements } = details || {};
  const { priceMU, muName } = measurements || {};
  const { basePrices, displayPrices } = costsAndPrices || {};
  const { qtyInStock } = availability || {};
  const {
    vendorPrice,
    overridePrice,
    clearance,
    discontinued,
    salesPrice,
    movingAvgCost,
  } = basePrices || {};
  const {
    manufacturerPrice,
    stockPrice,
    hotBuyPrice,
    hotBuyExpiryDate,
    goldenRodPrice,
    claimBackPrice,
    claimBackFlag,
    listPrice,
  } = displayPrices || {};
  const { currentPrice } = vendorPrice || {};
  const { currentPrice: salesCurrentPrice, margin: salesMargin } =
    salesPrice || {};
  const { currentPrice: overrideCurrentPrice, margin: overrideMargin } =
    overridePrice || {};
  const { currentPrice: clearanceCurrentPrice, margin: clearancedMargin } =
    clearance || {};
  const { currentPrice: discontinuedCurrentPrice, margin: discontinuedMargin } =
    discontinued || {};
  const { shortName } = distributor || {};

  const {
    Cost,
    Vendor,
    Stock,
    PriceEach,
    Remove,
    Prof,
    Margin,
    Price,
    Wholesale,
    Override,
    AppiedToPos,
    Discontinued,
    Clearance,
    ClearanceQty,
    Manufacturer,
    Hot_Buy,
    Hot_Buy_Expiry,
    Golden_Rod,
    Claim_Back,
    Use_Claim_back_price,
    wholesalePrice: salesPriceType,
  } = ProductDetailText;
  const { SaveChanges, Back, CostsPrices, list, hotBuy, claimBack } = labels;

  const handleClose = (resetForm) => () => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (!focusText) return;
    const element = document.getElementById(focusText);
    setFocusTo(element);
  }, [focusText]);

  return (
    <Formik
      initialValues={{
        vendorPrice: currentPrice,
        salesPrice: salesCurrentPrice,
        overridePrice: overrideCurrentPrice,
        manufacturerPrice: manufacturerPrice,
        stockPrice: stockPrice,
        listPrice: listPrice,
        goldenRodPrice: goldenRodPrice,
        claimBackPrice: claimBackPrice,
        claimBackFlag: claimBackFlag,
        hotBuyPrice: hotBuyPrice,
        hotBuyExpiryDate: hotBuyExpiryDate,
        clearancePrice: clearanceCurrentPrice,
        unitQty: qtyInStock,
        discontinuedPrice: discontinuedCurrentPrice,
        salesMargin: salesMargin?.toFixed(2),
        overrideMargin: overrideMargin?.toFixed(2),
        discontinuedMargin: discontinuedMargin?.toFixed(2),
        clearancedMargin: clearancedMargin?.toFixed(2),
      }}
      validationSchema={UpdatedCostAndPriceProductDetailSchema}
      enableReinitialize
      onSubmit={(values) => {
        const { DefaultLoginId } = authorizationToken("Authorization") || {};
        const {
          vendorPrice,
          salesPrice,
          overridePrice,
          manufacturerPrice,
          stockPrice,
          listPrice,
          goldenRodPrice,
          claimBackPrice,
          hotBuyPrice,
          hotBuyExpiryDate,
          clearancePrice,
          unitQty,
          discontinuedPrice,
          claimBackFlag,
        } = values;

        const body = {
          productId,
          handlerId: DefaultLoginId,
          vendorPrice: Number(vendorPrice),
          salesPrice: Number(salesPrice),
          overridePrice: overridePrice ? Number(overridePrice) : null,
          manufacturerPrice: Number(manufacturerPrice),
          stockPrice: Number(stockPrice),
          listPrice: Number(listPrice),
          goldenRodPrice: Number(goldenRodPrice),
          claimBackPrice: Number(claimBackPrice),
          hotBuyPrice: Number(hotBuyPrice),
          hotBuyExpiryDate,
          clearancePrice: clearancePrice ? Number(clearancePrice) : null,
          unitQty: unitQty ? Number(unitQty) : null,
          discontinuedPrice: discontinuedPrice
            ? Number(discontinuedPrice)
            : null,
          claimBackFlag: claimBackFlag ? 1 : 0,
        };

        dispatch(updateCostAndPriceProductStart());
        updateCostAndPriceProductsMutation.mutate(body);
      }}
    >
      {({ values, setFieldValue, handleSubmit, resetForm, dirty }) => {
        const {
          claimBackFlag,
          vendorPrice,
          manufacturerPrice,
          stockPrice,
          goldenRodPrice,
          salesPrice,
          salesMargin,
          overridePrice,
          overrideMargin,
          discontinuedPrice,
          discontinuedMargin,
          clearancedMargin,
          clearancePrice,
          unitQty,
          hotBuyPrice,
          listPrice,
          claimBackPrice,
        } = values;
        const handleCommonChanges = (e, name) => {
          const inputValue = e.target.value;
          if (inputValue === "" || fiveDecimalRegex.test(inputValue)) {
            setFieldValue(name, inputValue);
          }
        };

        const handleOnChange = (value, keyName) => {
          setFieldValue(keyName, value);
        };

        const handlePriceToPercentage = (
          e,
          name,
          percentageName,
          venderCurrentPrice
        ) => {
          const inputValue = e.target.value;
          setFieldValue(name, inputValue);
          if (!e.target.value) {
            setFieldValue(percentageName, "");
          } else {
            const percentage = 100 - (100 * venderCurrentPrice/inputValue)
            setFieldValue(percentageName, Number(percentage?.toFixed(2)));
          }
        };

        const handlePercentageToPrice = (
          e,
          percentageName,
          name,
          venderCurrentPrice
        ) => {
          const inputValue = e.target.value;
          setFieldValue(percentageName, inputValue);
          if (!e.target.value) {
            setFieldValue(name, "");
          } else if (name === "salesPrice") {
            const selesPrice = (100 * venderCurrentPrice) / (100 - inputValue);
            setFieldValue(name, selesPrice);
          } else if (name === "overridePrice") {
            const overridePrice =
              (100 * movingAvgCost?.currentPrice) / (100 - inputValue);
            setFieldValue(name, overridePrice);
          } else {
            const price = getSalesPrice(inputValue, venderCurrentPrice);
            setFieldValue(name, price);
          }
        };

        const removeDiscontinued = () => {
          const permission = window.confirm(confirmDeleteForDiscontinued);
          if (!permission) return;
          const body = { productID: Number(productId) };
          dispatch(removeDiscontinuedPriceProductStart());
          removeDiscontinuedPriceProductsMutation.mutate(body);
          setFieldValue("discontinuedPrice", "");
          setFieldValue("discontinuedMargin", "");
        };

        const removeClearance = () => {
          const permission = window.confirm(confirmDeleteForClearance);
          if (!permission) return;
          const body = { productID: Number(productId) };
          dispatch(removeClearancePriceProductStart());
          removeClearancePriceProductsMutation.mutate(body);
          setFieldValue("clearancePrice", "");
          setFieldValue("clearancedMargin", "");
        };

        return (
          <Dialog className="overview-dialog" open={open} onClose={onClose}>
            <div className="dialog-header">
              <h3>{CostsPrices}</h3>
              <IconButton
                classes="close-btn"
                secondary={true}
                title="Close"
                icon="close"
                onClick={handleClose(resetForm)}
              />
            </div>

            <DialogContent className="">
              <div className="pb-2">
                <div className="notes-added-title">
                  <span>{Cost}</span>
                </div>
              </div>
              <div className="pb-4">
                <div className="notes-added-user-name">
                  <span>{Vendor}</span>
                </div>
                <div className="characteristics-main-info">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={Vendor}
                      className="product-detail-search-input input-focus-styling"
                      name="vendorPrice"
                      value={vendorPrice}
                      onChange={(e) => handleCommonChanges(e, "vendorPrice")}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            className="text-adornment"
                            position="start"
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                      type="number"
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => e?.target?.blur()}
                      inputRef={(i) =>
                        i && focusText === Vendor && setFocusTo(i, removeFocus)
                      }
                    />
                  </div>
                </div>
                <ErrorMessage name="vendorPrice" />
              </div>
              <div className="product-details-notes-main-content-section">
                <div className="notes-added-user-name">
                  <span>{Manufacturer}</span>
                </div>
                <div className="characteristics-main-info">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={Manufacturer}
                      className="product-detail-search-input input-focus-styling"
                      onChange={(e) =>
                        handleCommonChanges(e, "manufacturerPrice")
                      }
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            className="text-adornment"
                            position="start"
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                      value={manufacturerPrice}
                      type="number"
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => e?.target?.blur()}
                      inputRef={(i) =>
                        i &&
                        focusText === Manufacturer &&
                        setFocusTo(i, removeFocus)
                      }
                    />
                  </div>
                </div>
                <ErrorMessage name="manufacturerPrice" />
              </div>
              <div className="product-details-notes-main-content-section">
                <div className="notes-added-user-name">
                  <span>{Golden_Rod}</span>
                </div>
                <div className="characteristics-main-info">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={Golden_Rod}
                      className="product-detail-search-input input-focus-styling"
                      onChange={(e) => handleCommonChanges(e, "goldenRodPrice")}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            className="text-adornment"
                            position="start"
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                      value={goldenRodPrice}
                      type="number"
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => e?.target?.blur()}
                      inputRef={(i) =>
                        i &&
                        focusText === Golden_Rod &&
                        setFocusTo(i, removeFocus)
                      }
                    />
                  </div>
                </div>
                <ErrorMessage name="goldenRodPrice" />
              </div>
              <div className="product-details-notes-main-content-section">
                <div className="notes-added-user-name">
                  <span>{Stock}</span>
                </div>
                <div className="characteristics-main-info">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={Stock}
                      className="product-detail-search-input input-focus-styling"
                      onChange={(e) => handleCommonChanges(e, "stockPrice")}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            className="text-adornment"
                            position="start"
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                      value={stockPrice}
                      type="number"
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => e?.target?.blur()}
                      inputRef={(i) =>
                        i && focusText === Stock && setFocusTo(i, removeFocus)
                      }
                    />
                  </div>
                </div>
                <ErrorMessage name="stockPrice" />
              </div>
              <div className="pb-2">
                <div className="notes-added-title">
                  <span>{Price}</span>
                </div>
              </div>
              <div className="pb-4">
                <div className="notes-added-user-name">
                  <span>{Wholesale}</span>
                </div>
                <div className="characteristics-main-info price-per-each-sec">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={Wholesale}
                      className={`product-detail-search-input input-focus-styling ${
                        focusFirstInputPrice
                          ? "input-set-focus first-input-set-focus"
                          : ""
                      }`}
                      onChange={(e) =>
                        handlePriceToPercentage(
                          e,
                          "salesPrice",
                          "salesMargin",
                          vendorPrice
                        )
                      }
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            className="text-adornment ms-1"
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                      onFocus={() => setFocusSecondInputPrice(true)}
                      onBlur={() => setFocusSecondInputPrice(false)}
                      value={salesPrice}
                      type="number"
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => e?.target?.blur()}
                      inputRef={(i) =>
                        i &&
                        focusText === Wholesale &&
                        setFocusTo(i, removeFocus)
                      }
                    />
                    <div className="price-per-each-title">
                      <span>
                        {`${PriceEach} ${muName ?? ""}(${priceMU ?? ""})`}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="characteristics-main-info prof-percentage-sec">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id="filled-basic"
                      className={`product-detail-search-input input-focus-styling ${
                        focusSecondInputPrice
                          ? "input-set-focus second-input-set-focus"
                          : ""
                      }`}
                      onChange={(e) =>
                        handlePercentageToPrice(
                          e,
                          "salesMargin",
                          "salesPrice",
                          vendorPrice
                        )
                      }
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            className="text-adornment ms-1"
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      onFocus={() => setFocusFirstInputPrice(true)}
                      onBlur={() => setFocusFirstInputPrice(false)}
                      value={salesMargin}
                      type="number"
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => e?.target?.blur()}
                    />
                    <div className="price-per-each-title">
                      <span>{Margin}</span>
                    </div>
                  </div>
                </div>
                <div className="small-text gray-text mt-2">
                  <span className="ml-0">{`${shortName} ${salesPriceType} ${wholesaleMarginNote}`}</span>
                </div>
                <ErrorMessage name="salesPrice" />
              </div>
              <div className="product-details-notes-main-content-section">
                <div className="notes-added-user-name">
                  <span>{Override}</span>
                </div>
                <div className="characteristics-main-info price-per-each-sec">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={Override}
                      className={`product-detail-search-input input-focus-styling ${
                        focusFirstInputOverride
                          ? "input-set-focus first-input-set-focus"
                          : ""
                      }`}
                      onChange={(e) =>
                        handlePriceToPercentage(
                          e,
                          "overridePrice",
                          "overrideMargin",
                          movingAvgCost?.currentPrice
                        )
                      }
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            className="text-adornment ms-1"
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                      onFocus={() => setFocusSecondInputOverride(true)}
                      onBlur={() => setFocusSecondInputOverride(false)}
                      value={overridePrice}
                      type="number"
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => e?.target?.blur()}
                      inputRef={(i) =>
                        i &&
                        focusText === Override &&
                        setFocusTo(i, removeFocus)
                      }
                    />
                    <div className="price-per-each-title">
                      <span>
                        {`${PriceEach} ${muName ?? ""}(${priceMU ?? ""})`}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="characteristics-main-info prof-percentage-sec">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id="filled-basic"
                      className={`product-detail-search-input input-focus-styling ${
                        focusSecondInputOverride
                          ? "input-set-focus second-input-set-focus"
                          : ""
                      }`}
                      onChange={(e) =>
                        handlePercentageToPrice(
                          e,
                          "overrideMargin",
                          "overridePrice",
                          vendorPrice
                        )
                      }
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            className="text-adornment ms-1"
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      onFocus={() => setFocusFirstInputOverride(true)}
                      onBlur={() => setFocusFirstInputOverride(false)}
                      type="number"
                      value={overrideMargin}
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => e?.target?.blur()}
                    />
                    <div className="price-per-each-title">
                      <span>{Margin}</span>
                    </div>
                  </div>
                </div>
                <div className="small-text gray-text mt-2">
                  <span className="ml-0">{`${AppiedToPos} ${overrideMarginMote}`}</span>
                </div>
                <ErrorMessage name="overridePrice" />
              </div>
              <div className="product-details-notes-main-content-section">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="notes-added-user-name">
                    <span>{Discontinued}</span>
                  </div>
                  {discontinuedCurrentPrice ? (
                    <button
                      className="extra-small-text gray-text bg-transparent border-0"
                      onClick={removeDiscontinued}
                    >
                      {Remove}
                    </button>
                  ) : null}
                </div>

                <div className="characteristics-main-info price-per-each-sec">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={Discontinued}
                      tabIndex={0}
                      className={`product-detail-search-input input-focus-styling ${
                        focusFirstInputDiscontinued
                          ? "input-set-focus first-input-set-focus"
                          : ""
                      }`}
                      onChange={(e) =>
                        handlePriceToPercentage(
                          e,
                          "discontinuedPrice",
                          "discontinuedMargin",
                          vendorPrice
                        )
                      }
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            className="text-adornment ms-1"
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                      onFocus={() => setFocusSecondInputDiscontinued(true)}
                      onBlur={() => setFocusSecondInputDiscontinued(false)}
                      value={discontinuedPrice}
                      type="number"
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => e?.target?.blur()}
                      inputRef={(i) =>
                        i &&
                        focusText === Discontinued &&
                        setFocusTo(i, removeFocus)
                      }
                    />
                    <div className="price-per-each-title">
                      <span>
                        {`${PriceEach} ${muName ?? ""}(${priceMU ?? ""})`}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="characteristics-main-info prof-percentage-sec">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id="filled-basic"
                      className={`product-detail-search-input input-focus-styling ${
                        focusSecondInputDiscontinued
                          ? "input-set-focus second-input-set-focus"
                          : ""
                      }`}
                      onChange={(e) =>
                        handlePercentageToPrice(
                          e,
                          "discontinuedMargin",
                          "discontinuedPrice",
                          vendorPrice
                        )
                      }
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            className="text-adornment ms-1"
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      onFocus={() => setFocusFirstInputDiscontinued(true)}
                      onBlur={() => setFocusFirstInputDiscontinued(false)}
                      value={discontinuedMargin}
                      type="number"
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => e?.target?.blur()}
                    />
                    <div className="price-per-each-title">
                      <span>{Prof}</span>
                    </div>
                  </div>
                </div>
                <ErrorMessage name="discontinuedPrice" />
              </div>
              <div className="product-details-notes-main-content-section">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="notes-added-user-name">
                    <span>{Clearance}</span>
                  </div>
                  {clearanceCurrentPrice ? (
                    <button
                      className="extra-small-text gray-text bg-transparent border-0"
                      onClick={removeClearance}
                    >
                      {Remove}
                    </button>
                  ) : null}
                </div>
                <div className="characteristics-main-info price-per-each-sec">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={Clearance}
                      className={`product-detail-search-input input-focus-styling ${
                        focusFirstInputClearance
                          ? "input-set-focus first-input-set-focus"
                          : ""
                      }`}
                      onChange={(e) =>
                        handlePriceToPercentage(
                          e,
                          "clearancePrice",
                          "clearancedMargin",
                          vendorPrice
                        )
                      }
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            className="text-adornment ms-1"
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                      onFocus={() => setFocusSecondInputClearance(true)}
                      onBlur={() => setFocusSecondInputClearance(false)}
                      value={clearancePrice}
                      type="number"
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => e?.target?.blur()}
                      inputRef={(i) =>
                        i &&
                        focusText === Clearance &&
                        setFocusTo(i, removeFocus)
                      }
                    />
                    <div className="price-per-each-title">
                      <span>
                        {`${PriceEach} ${muName ?? ""}(${priceMU ?? ""})`}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="characteristics-main-info prof-percentage-sec">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id="filled-basic"
                      className={`product-detail-search-input input-focus-styling ${
                        focusSecondInputClearance
                          ? "input-set-focus second-input-set-focus"
                          : ""
                      }`}
                      onChange={(e) =>
                        handlePercentageToPrice(
                          e,
                          "clearancedMargin",
                          "clearancePrice",
                          vendorPrice
                        )
                      }
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            className="text-adornment ms-1"
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      onFocus={() => setFocusFirstInputClearance(true)}
                      onBlur={() => setFocusFirstInputClearance(false)}
                      value={clearancedMargin}
                      type="number"
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => e?.target?.blur()}
                    />
                    <div className="price-per-each-title">
                      <span>{Margin}</span>
                    </div>
                  </div>
                </div>
                <ErrorMessage name="clearancePrice" />
              </div>
              {clearanceCurrentPrice ? (
                <div className="product-details-notes-main-content-section">
                  <div className="notes-added-user-name">
                    <span>{ClearanceQty}</span>
                  </div>
                  <div className="characteristics-main-info">
                    <div className="w-100">
                      <PosInput
                        variant="filled"
                        id="filled-basic"
                        type="number"
                        className="product-detail-search-input input-focus-styling"
                        onChange={(e) => handleCommonChanges(e, "stockPrice")}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        value={unitQty}
                        onKeyDown={(e) => blockInvalidChar(e)}
                        onWheel={(e) => e?.target?.blur()}
                        inputRef={(i) =>
                          i &&
                          focusText === ClearanceQty &&
                          setFocusTo(i, removeFocus)
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="product-details-notes-main-content-section">
                <div className="notes-added-user-name">
                  <span>List</span>
                </div>
                <div className="characteristics-main-info">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={list}
                      type="number"
                      className="product-detail-search-input input-focus-styling"
                      onChange={(e) => handleCommonChanges(e, "listPrice")}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            className="text-adornment"
                            position="start"
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                      value={listPrice}
                      onKeyDown={(e) => blockInvalidChar(e)}
                      onWheel={(e) => e?.target?.blur()}
                      inputRef={(i) =>
                        i && focusText === list && setFocusTo(i, removeFocus)
                      }
                    />
                  </div>
                </div>
                <ErrorMessage name="listPrice" />
              </div>
              <div className="product-details-notes-main-content-section">
                <div className="d-flex half-width-detail-sec">
                  <div className="half-width-left-detail-div">
                    <div className="notes-added-user-name">
                      <span>{Hot_Buy}</span>
                    </div>
                    <div className="characteristics-main-info">
                      <div className="w-100">
                        <PosInput
                          variant="filled"
                          id={hotBuy}
                          type="number"
                          className="product-detail-search-input input-focus-styling"
                          onChange={(e) =>
                            handleCommonChanges(e, "hotBuyPrice")
                          }
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment
                                className="text-adornment"
                                position="start"
                              >
                                $
                              </InputAdornment>
                            ),
                          }}
                          value={hotBuyPrice}
                          onKeyDown={(e) => blockInvalidChar(e)}
                          onWheel={(e) => e?.target?.blur()}
                          inputRef={(i) =>
                            i &&
                            focusText === Hot_Buy &&
                            setFocusTo(i, removeFocus)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="set-customer-code-modal-container half-width-right-detail-div">
                    <div className="notes-added-user-name">
                      <span>{Hot_Buy_Expiry}</span>
                    </div>
                    <div className="characteristics-main-info">
                      <div className="custom-datePicker storage-date-picker w-100">
                        <PosDatePicker
                          value={dayjs(hotBuyExpiryDate)}
                          onChange={(newValue) =>
                            setFieldValue("hotBuyExpiryDate", dayjs(newValue))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <ErrorMessage name="hotBuyPrice" />
              </div>
              <div className="product-details-notes-main-content-section">
                <div className="notes-added-user-name">
                  <span>{Claim_Back}</span>
                </div>
                <div className="characteristics-main-info">
                  <div className="w-100">
                    <PosInput
                      variant="filled"
                      id={claimBack}
                      type="number"
                      name="claimBackPrice"
                      className="product-detail-search-input input-focus-styling"
                      onChange={(e) => handleCommonChanges(e, "claimBackPrice")}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            className="text-adornment"
                            position="start"
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                      value={claimBackPrice}
                      inputRef={(i) =>
                        i &&
                        focusText === claimBack &&
                        setFocusTo(i, removeFocus)
                      }
                    />
                  </div>
                </div>
                <div>
                  <PosCheckbox
                    label={Use_Claim_back_price}
                    checked={Boolean(claimBackFlag)}
                    name="claimBackFlag"
                    onChange={() =>
                      handleOnChange(!claimBackFlag, "claimBackFlag")
                    }
                  />
                </div>
              </div>
            </DialogContent>

            <DialogActions className="notes-model-footer">
              <div className="notes-footer-wrapper d-flex notes-btn-div product-detail-modal-footer-spacing justify-content-end">
                <PosButton
                  variant="contained"
                  onClick={handleClose(resetForm)}
                  className="back-button first-product-detail-btn"
                >
                  {Back}
                </PosButton>
                <PosButton
                  fontWeight
                  className={`btn ml-2 second-product-detail-btn ${
                    updateCostAndPriceProduct
                      ? "button-accent button-is-loading-black"
                      : ""
                  }`}
                  variant="contained"
                  color="primary"
                  disabled={updateCostAndPriceProduct || !dirty}
                  onClick={() => handleSubmit()}
                >
                  {SaveChanges}
                </PosButton>
              </div>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ProductDetailsCostsModals;
