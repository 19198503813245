import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { activateQuote } from "../request";

const useExpirationDate = () => {
  const { mutate, isSuccess } = useMutation(async (payload) => {
      return activateQuote(payload);
  });

  const setExpirationDatePayload = useCallback(
    (payload) => {
      mutate(payload);
    },
    [mutate]
  );

  return {
    expirationDateSuccess: isSuccess,
    setExpirationDatePayload,
  };
};

export default useExpirationDate;
