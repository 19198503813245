import * as constants from "sagas/authenticatedUser/constants";

import { AuthenticatedUser } from "models";

export function resetPassword(payload: any) {
  return {
    type: constants.RESET_PASSWORD_REQUEST_START,
    payload,
  };
}

export function resetPasswordRequsetSuccess(payload: AuthenticatedUser) {
  return {
    type: constants.RESET_PASSWORD_REQUEST_SUCCESS,
    payload,
  };
}

export function resetPasswordRequsetError(errors: any) {
  return {
    type: constants.RESET_PASSWORD_REQUEST_ERROR,
    errors,
  };
}

export function resetPasswordRequsetEnd() {
  return {
    type: constants.NEW_PASSWORD_TOKEN_END,
  };
}

export function newPassword(payload: any) {
  return {
    type: constants.NEW_PASSWORD_START,
    payload,
  };
}

export function newPasswordRequsetSuccess(payload: AuthenticatedUser) {
  return {
    type: constants.NEW_PASSWORD_SUCCESS,
    payload,
  };
}

export function newPasswordRequsetEnd() {
  return {
    type: constants.NEW_PASSWORD_END,
  };
}

export function newPasswordRequsetError(errors: any) {
  return {
    type: constants.NEW_PASSWORD_ERROR,
    errors,
  };
}

export function newPasswordToken(payload: any) {
  return {
    type: constants.NEW_PASSWORD_TOKEN_START,
    payload,
  };
}

export function newPasswordRequsetTokenSuccess(payload: any) {
  return {
    type: constants.NEW_PASSWORD_TOKEN_SUCCESS,
    payload,
  };
}

export function newPasswordRequsetTokenError(errors: any) {
  return {
    type: constants.NEW_PASSWORD_TOKEN_ERROR,
    errors,
  };
}

export function inviteStart(payload) {
  return {
    type: constants.INVITE_START,
    payload,
  };
}

export function inviteSuccess(payload) {
  return {
    type: constants.INVITE_SUCCESS,
    payload,
  };
}

export function inviteError(payload) {
  return {
    type: constants.INVITE_ERROR,
    payload,
  };
}