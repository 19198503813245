import { useEffect } from "react";
import { FormikProvider } from "formik";
import { useSelector, useDispatch } from "react-redux";

import { PosInput } from "apps/pos/Common/uiCore";
import { ErrorMessage } from "apps/pos/formik/errorMessage";
import Button from "apps/admin/components/Elements/Button";
import ReturnToGroup from "components/ReturnTo/ReturnToGroup";

import { CheckIcon } from "constants/icons";
import { PosResponseProps, RootState } from "models";
import { getLocalStoraGeItem } from "utils/utils";
import { PosFlowDataProp } from "constants/interfaces";
import texts from "apps/pos/locales";
import useAddStockFormik from "./useAddStockFormik";
import { decimal18RegEx } from "constants/constant";
import { tabType } from "constants/enum";
import { AddProps } from "../type";
import { useSingleRowsData } from "../queryHooks/useSingleRowData";
import {
  getShelfClear,
  getWarehourseLocationsRequest,
  stockInClear,
} from "sagas/pos/actions";

import "./add.scss";

const Add = ({
  rowName,
  tabValue,
  setQuantityAdd,
  setRowName,
  setUnitName,
  setShelfName,
  units,
  setUnits,
  unitsErrors,
  setUnitsErrors,
  unitsLoading,
  setUnitsLoading,
  shelves,
  setShelves,
  shelvesErrors,
  setShelvesErrors,
  shelvesLoading,
  setShelvesLoading,
}: AddProps) => {
  const { posFlowData } = useSelector<RootState, PosFlowDataProp>(
    (state) => state.persistReducer
  );
  const { stockInDetails, inStockAddSuccess, isStockInLoading, isPickingSuccess } =
    useSelector<RootState, PosResponseProps>((state) => state.pos);

    const {individualRowRefetch} = useSingleRowsData()

  const { cartProductDetails } = posFlowData || {};
  const { productId } = cartProductDetails || {};
  const { title } = stockInDetails || {};

  const dispatch = useDispatch();
  const currentWorkspace = getLocalStoraGeItem("workspace");
  const { stockWarehouseId } = currentWorkspace || {};
  const { addStock } = texts;

  useEffect(() => {
    if (inStockAddSuccess) {
      setTimeout(() => {
        const data = {
          stockWarehouseId,
          body: [productId],
        };
        // remove later if not needed
        dispatch(getWarehourseLocationsRequest(data));
        dispatch(stockInClear());
      }, 1000);
      setFieldValue("quantityToAdd", "");
      setFieldValue("addReason", "");
      individualRowRefetch()
    }
  }, [inStockAddSuccess, isPickingSuccess]);

  useEffect(() => {
    if (title) {
      setTimeout(() => {
        dispatch(stockInClear());
      }, 1000);
    }
  }, [title]);

  useEffect(() => {
    if (!rowName) {
      resetForm();
      setRowName("");
      setUnitName("");
      setShelfName("");
    }
  }, [rowName]);

  const formik = useAddStockFormik();

  const { values, setFieldValue, resetForm, handleSubmit, isValid} = formik || {};

  const {
    quantityToAdd,
    addReason,
  } = values || {};

  useEffect(()=>{
    setQuantityAdd(Number(quantityToAdd));
  }, [quantityToAdd])

  return (
    <div className="add-main-container">
      <>
        <FormikProvider value={formik}>
          <div className="quantity-adjust-title">
            <span>Add to location</span>
          </div>
          {tabValue === tabType.add ? 
          <ReturnToGroup
            setSingleLocationData={(locationData) => {
              if (locationData?.row) {
                setFieldValue("addAdjustStockRow", locationData?.row?.id);
                setRowName(locationData?.row?.name);
                dispatch(getShelfClear())
                  setUnitName("");
                  setFieldValue("addAdjustStockUnit", "");
                  setShelfName("");
                  setFieldValue("addAdjustStockShelf", "");
              }
              if (locationData?.unit) {
                dispatch(getShelfClear())
                setUnitName(locationData?.unit?.name);
                setFieldValue("addAdjustStockUnit", locationData?.unit?.id);
                 setFieldValue("addAdjustStockShelf", "");
                 setShelfName("");
              }
              if (locationData?.shelf) {
                setFieldValue("addAdjustStockShelf", locationData?.shelf?.id);
                setShelfName(locationData?.shelf?.name);
              }
            }}
            cartEditModalData={false} 
            editReturnTo={[]}
            disabledMultiple
            disableReturnReplace
            tabValue={tabValue}
            units={units}
            setUnits={setUnits}
            unitsErrors={unitsErrors}
            setUnitsErrors={setUnitsErrors}
            unitsLoading={unitsLoading}
            setUnitsLoading={setUnitsLoading}
            shelves={shelves}
            setShelves={setShelves}
            shelvesErrors={shelvesErrors}
            setShelvesErrors={setShelvesErrors}
            shelvesLoading={shelvesLoading}
            setShelvesLoading={setShelvesLoading}
            isNonSaleableHide={true}
          /> : null}
          <div className="quantity-adjust-title">Quantity to Add</div>
          <PosInput
            variant="outlined"
            id="outlined-basic"
            disabled={isStockInLoading}
            name="quantityToAdd"
            value={quantityToAdd}
            onChange={(e) => {
              const addQty = decimal18RegEx.test(e.target.value);
              if (!addQty) {
                e.target.value = e.target.value.slice(0, -1);
              }
              if (addQty) {
                setFieldValue("quantityToAdd", e.target.value);
              } else if (!e.target.value) {
                setFieldValue("quantityToAdd", "");
              }
            }}
            className="quantity-input input-focus"
            onFocus={(e) => e.target.select()}
          />
          <ErrorMessage name="quantityToAdd" />
          <div className="quantity-adjust-title">
            Reason{" "}
            <span className="optional adjust-reason-optional-text">
              (optional)
            </span>
          </div>
          <PosInput
            variant="filled"
            id="filled-basic"
            autoComplete="off"
            InputProps={{ disableUnderline: true }}
            className="quantity-input"
            type="text"
            name="addReason"
            onChange={(e) => {
              setFieldValue("addReason", e.target.value);
            }}
            value={addReason}
          />
          <div className="adjust-stock-btn-div">
            <Button
              label={addStock}
              accent={true}
              disabled={!isValid}
              icon={<CheckIcon />}
              onClick={() => {
                handleSubmit();
              }}
              classes={`${
                isStockInLoading ? "button-is-loading" : "adjust-stock-btn add-stock-btn"
              }`}
            />
          </div>
        </FormikProvider>
      </>
    </div>
  );
};

export default Add;
