import './InfoBlock.scss';

const InfoBlock = ({ label, value, className = '' }) => {
    return (
        <div className={`info-block-sb ${className}`} >
            <p className='info-block-label'>{label}</p>
            <p className='info-block-value'>{value}</p>
        </div>
    )
}

export default InfoBlock