export const RESET_PASSWORD_REQUEST_START = "RESET_PASSWORD_REQUEST_START";
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";
export const RESET_PASSWORD_REQUEST_ERROR = "RESET_PASSWORD_REQUEST_ERROR";

export const NEW_PASSWORD_START = "NEW_PASSWORD_START";
export const NEW_PASSWORD_SUCCESS = "NEW_PASSWORD_SUCCESS";
export const NEW_PASSWORD_END = "NEW_PASSWORD_END";
export const NEW_PASSWORD_ERROR = "NEW_PASSWORD_ERROR";

export const NEW_PASSWORD_TOKEN_START = "NEW_PASSWORD_TOKEN_START";
export const NEW_PASSWORD_TOKEN_SUCCESS = "NEW_PASSWORD_TOKEN_SUCCESS";
export const NEW_PASSWORD_TOKEN_ERROR = "NEW_PASSWORD_TOKEN_ERROR";
export const NEW_PASSWORD_TOKEN_END = "NEW_PASSWORD_TOKEN_END";

export const RESET_LINK = "Reset link sent";
export const START ="start";
export const SUCCESS = "success";
export const ERROR = "error";
export const ERROR_RESET_LINK = "Failed to send reset link. Try again";
export const PASSWORD_CHANGES = "Password changed";
export const PASSWORD_CHANGES_ERROR ="Failed to change password. Try again";

export const INVITE_START = "INVITE_START";
export const INVITE_SUCCESS = "INVITE_SUCCESS";
export const INVITE_ERROR = "INVITE_ERROR";