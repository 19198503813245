import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CommonErrorReponce, CommonSuccessReponce } from "apps/product/modules/productDetailsContainer/types";

// Will remove 'any' once we get proper data from BE

interface IProductData {
  noteId: number,
  productId: number,
  note: string,
  modifiedAt: string,
  modifiedBy: number,
  modifiedByName: string
}
export interface ProductNotesSliceI {
  isGetProductsNotes: boolean;
  getProductNotesData: IProductData[];
  getProductNotesError: CommonErrorReponce;
  addProductNotesData: CommonSuccessReponce,
  isAddProductsNotes: boolean;
  addProductNotesError: CommonErrorReponce,
  removeProductNotesData: CommonSuccessReponce,
  isRemoveProductsNotes: boolean;
  removeProductNotesError: CommonErrorReponce,
  removeAllProductNotesData: CommonSuccessReponce,
  isRemoveAllProductsNotes: boolean;
  removeAllProductNotesError: CommonErrorReponce,
  updateNotesProductData: CommonSuccessReponce,
  isUpdateProductsNotes: boolean;
  updateNotesProductError: CommonErrorReponce,
}

const initialState: ProductNotesSliceI = {
  isGetProductsNotes: false,
  getProductNotesData: null,
  getProductNotesError: null,
  addProductNotesData: null,
  isAddProductsNotes: false,
  addProductNotesError: null,
  removeProductNotesData: null,
  isRemoveProductsNotes: false,
  removeProductNotesError: null,
  removeAllProductNotesData: null,
  isRemoveAllProductsNotes: false,
  removeAllProductNotesError: null,
  updateNotesProductData: null,
  isUpdateProductsNotes: false,
  updateNotesProductError: null,
};

export const productsNoteSlice = createSlice({
  name: "productsReducer",
  initialState,
  reducers: {
    getProductsNotesStart: (state) => {
      state.getProductNotesData = null;
      state.isGetProductsNotes = true;
      state.isAddProductsNotes = false;
    },
    getProductsNotesSuccess: (state, action: PayloadAction<any>) => {
      state.getProductNotesData = action.payload;
      state.isGetProductsNotes = false;
    },
    getProductsNotesError: (state, action: PayloadAction<any>) => {
      state.getProductNotesError = action.payload;
      state.getProductNotesData = null;
      state.isGetProductsNotes = false;
    },
    addNotesProductStart: (state) => {
      state.addProductNotesData = null;
      state.isAddProductsNotes = true;
    },
    addNotesProductSuccess: (state, action: PayloadAction<any>) => {
      state.addProductNotesData = action.payload;
      state.isAddProductsNotes = false;
    },
    addNotesProductError: (state, action: PayloadAction<any>) => {
      state.addProductNotesError = action.payload;
      state.addProductNotesData = null;
      state.isAddProductsNotes = false;
    },
    removeNotesNotesStart: (state) => {
      state.removeProductNotesData = null;
      state.isRemoveProductsNotes = true;
    },
    removeNotesProductSuccess: (state, action: PayloadAction<any>) => {
      state.removeProductNotesData = action.payload;
      state.isRemoveProductsNotes = false;
      state.removeProductNotesError = null;
    },
    removeNotesProductError: (state, action: PayloadAction<any>) => {
      state.removeProductNotesError = action.payload;
      state.removeProductNotesData = null;
      state.isRemoveProductsNotes = false;
    },
    removeAllNotesProductStart: (state) => {
      state.removeAllProductNotesData = null;
      state.isRemoveAllProductsNotes = true;
    },
    removeAllNotesProductSuccess: (state, action: PayloadAction<any>) => {
      state.removeAllProductNotesData = action.payload;
      state.isRemoveAllProductsNotes = false;
      state.removeAllProductNotesError = null;
    },
    removeAllNotesProductError: (state, action: PayloadAction<any>) => {
      state.removeAllProductNotesError = action.payload;
      state.removeAllProductNotesData = null;
      state.isRemoveAllProductsNotes = false;
    },
    updateNotesProductStart: (state) => {
      state.updateNotesProductData = null;
      state.isUpdateProductsNotes = true;
    },
    updateNotesProductSuccess: (state, action: PayloadAction<any>) => {
      state.updateNotesProductData = action.payload;
      state.isUpdateProductsNotes = false;
    },
    updateNotesProductError: (state, action: PayloadAction<any>) => {
      state.updateNotesProductError = action.payload;
      state.updateNotesProductData = null;
      state.isUpdateProductsNotes = false;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  getProductsNotesStart,
  getProductsNotesSuccess,
  getProductsNotesError,
  addNotesProductStart,
  addNotesProductSuccess,
  addNotesProductError,
  removeNotesNotesStart,
  removeNotesProductSuccess,
  removeNotesProductError,
  removeAllNotesProductStart,
  removeAllNotesProductSuccess,
  removeAllNotesProductError,
  updateNotesProductStart,
  updateNotesProductSuccess,
  updateNotesProductError
} = productsNoteSlice.actions;

export default productsNoteSlice.reducer;
