import '../ProductDetailsLogsItemModels.scss';
import LogItem from './LogsItem';

const LogsGroup = (props) => {
    const { logs , visible = false , handleOpenCloseLogsModal , total } = props;

    const formatDate = (date) => {
        return date.toLocaleDateString('en-US', {
            year: '2-digit',
            month: 'short',
            day: 'numeric',
        });
    }
    // Might need late :
    // let sortedObjects = []
    // if(visible){
    //     // Filter out objects with missing formatted dates
    //     const filteredObjects = logs?.filter((obj) => obj?.createdAt);

    //     // Sort the filtered objects based on formatted dates
    //     if(filteredObjects?.length){
    //         sortedObjects = filteredObjects.sort((a, b) => {
    //             if (a.createdAt > b.createdAt) {
    //                 return -1;
    //             } else {
    //                 return 1;
    //             }
    //         });
    //     }
    // }
    
    function groupAndSortLogsByDate(logs) {
        const groupedLogs = {};

        for (const log of logs) {
            const date = new Date(log?.createdAt);
            const formattedDate = formatDate(date);

            if (!groupedLogs[formattedDate]) {
                groupedLogs[formattedDate] = {
                    date: formattedDate,
                    records: [],
                };
            }

            groupedLogs[formattedDate].records.push(log);
        }

        //  Might need late : Sort the records within each group by date in descending order
        // for (const date in groupedLogs) {
        //     groupedLogs[date].records.sort((a, b) => {
        //         return new Date(a.createdAt) - new Date(b.createdAt);
        //     });
        // }

        // Convert the grouped logs object into an array and sort it based on date
        const sortedGroupedLogs = Object.values(groupedLogs).sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });

        return sortedGroupedLogs;
    }
    const groupedLogs = logs?.length ? groupAndSortLogsByDate(visible ? logs?.slice(0, 3) : logs) : []
    const todaysDate = formatDate(new Date());

    return (
        logs?.length ? (
            <>
                {groupedLogs.map(group => (
                    <div className="log-group" key={group.date}>
                        <div className="log-group-header">
                        <div className="log-group-date">
                            <h5>
                                <time dateTime={group.date}>
                                    {group.date === todaysDate ? 'Today' : group.date}
                                </time>
                            </h5>
                        </div>
                        </div>
                        <div className="log-group-content">
                            {group.records.map((log, index) => (
                                <LogItem
                                    key={index}
                                    log={log}
                                />
                            ))}
                        </div>
                    </div>
                ))}
                {(visible && logs.length > 3) ? (
                    <div className="more-items-group">
                        <p className="more-items-label">
                            +{total - 3} more
                        </p>
                        <button className='more-items-view-btn' onClick={handleOpenCloseLogsModal}>View all Logs</button>
                    </div>
                ) : null}
            </>
        ) : (
            <p className='logs-empty-state'>
                Product activity will appear here.
            </p>
        )
    )
}

export default LogsGroup;