import { put, takeLeading } from "redux-saga/effects";

import { AnyAction } from "redux";
import { MODAL_UPDATE_CONTENT } from "./constants";
import { UpdateModalContent } from "./actions";

export function* ModalUpdateContentFlow(action: AnyAction): any {
  yield put(UpdateModalContent(action.payload));
}

function* modalWatcher() {
  yield takeLeading(MODAL_UPDATE_CONTENT, ModalUpdateContentFlow);
}

export default modalWatcher;
