import { useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { PosDatePicker, PosModel, PosPageHeading } from "apps/pos/Common/uiCore";
import Button from "components/SPButton/Button";
import { IconButton } from "apps/springboard/Component/SBItems/elements";
import { minDate1970 } from "constants/staticData";

import { getMovingAvgCostHistoryRequest } from "sagas/pos/actions";

const CustomRangeDateModal = ({
  setCustomDateRangeModal,
  customDateRangeModal,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  stockWarehouseId,
  page,
  rowsPerPage,
  setCustomRangeSelected,
  handleUnCheckOfRadios,
  resetPagination,
  customRangeSelected,
  isLeapFrongReport,
  productId
}) => {
  
  const dispatch = useDispatch();

  const closeModal = () => {
    setCustomDateRangeModal(false)
  }

  useEffect(()=>{
    setFromDate(customRangeSelected ? fromDate :dayjs().subtract(7, 'day'))
  },[])

  const handleSetRange = () => {
    const data = {
      stockWareHouseId: isLeapFrongReport ? 0 : stockWarehouseId,
      page: page + 1,
      size: rowsPerPage,
      startDate: fromDate,
      endDate: toDate,
      productId:productId
    };
    dispatch(getMovingAvgCostHistoryRequest(data));
    setCustomRangeSelected(true)
    handleUnCheckOfRadios()
    resetPagination()
    setCustomDateRangeModal(false)
  }

  return (
    <>
      <PosModel
        open={customDateRangeModal}
        onClose={setCustomDateRangeModal}
        dialogClassName={`set-customer-code-modal-container set-storage-modal-container put-storage-modal-container`}
        dialogHeader={
          <div className="modal-header">
            <div className="d-flex flex-column mt-3">
              <PosPageHeading
                heading={"Select Custom Range"}
                blackHeading
                mediumHeading
              />
            </div>
             <IconButton
                name='close'
                className="close-icon"
                type='tertiary'
                onClick={closeModal}
              />
          </div>
        }
      >
        <>
            <div className="datePicker-group">
              <div className="set-storage-type-section">
                <div className="title-text mb-2 font-weight-600">
                  From
                </div>
                <div className="custom-datePicker storage-date-picker">
                <PosDatePicker value={fromDate} onChange={(newValue) => { setFromDate(dayjs(newValue)) }} isDefaultSet={false} toDate={toDate} fromDate={dayjs(minDate1970)} />
                </div>
              </div>
              <div className="set-storage-type-section">
                <div className="title-text mb-2 font-weight-600">
                  To
                </div>
                <div className="custom-datePicker storage-date-picker">
                  <PosDatePicker value={toDate} onChange={(newValue) => { setToDate(dayjs(newValue)) }} isDefaultSet={false} fromDate={fromDate} toDate={dayjs()}/>
                </div>
              </div>
            </div>
          <div className="moving-history-actions">
            <Button
              label="Set"
              type='primary'
              onClick={handleSetRange}
            >
            </Button>
          </div>
        </>
      </PosModel>
    </>
  );
};
export default CustomRangeDateModal;
