import { Grid } from "@material-ui/core";

import useFilterSections from "apps/product/Components/ProductDetails/useSectionFilter";
import CreateGridStarIcon from "apps/product/Components/ProductDetails/CreateGridStarIcon";

import { sectionName } from "apps/product/modules/productDetailsContainer/constants";
import { dayJsFormat } from "constants/staticData";
import { formatDate } from "utils/dayjsFunc";

const ProductDetailsCoupons = ({ isStarred = false , searchText,productCouponsData}) => {
  const { coupons } = sectionName;
  const { showSection } = useFilterSections({ searchText, sectionName: coupons, isStarred });

  return (
    <>
      <section className="product-report-section">
        {
          productCouponsData?.length ?
          productCouponsData.map((option) => {
              const { coupon } = option || {} 
              const { couponCode, oneTimeFlag, couponDiscount, couponDescription, minOrderAmt, startDate, endDate , couponID} = coupon || {}
            const smallText = `Valid ${formatDate(startDate, dayJsFormat.MMDDYYYY)} - ${formatDate(endDate, dayJsFormat.MMDDYYYY)}`
              return (
                <Grid container className="product-report-container" key={`${couponCode + "_" + couponID}`}>
                  {showSection(couponCode,couponCode) ?  (
                    <Grid container className="report-div">
                      <CreateGridStarIcon className={couponCode} label={couponCode} value={couponCode} sectionName={coupons} smallText={smallText} oneTimeFlag={oneTimeFlag}/>
                      <Grid item xs={12} sm={8} className="position-relative">
                        <div>
                          <span>{`${couponDiscount ? couponDiscount.toFixed(2) : 0}%`}</span>
                        </div>
                        <div className="mt-2">
                          <span>{`Min. order ${minOrderAmt ? minOrderAmt.toFixed(2) : 0}`}</span>
                        </div>
                        <div className="wholesale-des-text gray-text mt-2">
                          <span>{couponDescription}</span>
                        </div>
                      </Grid>
                    </Grid>
                  ) : null }
                </Grid>
              )
            })
            : null
        }
      </section>
    </>
  );
};

export default ProductDetailsCoupons;
