import { useRef, useState } from "react";
import styled from "styled-components";

import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";


export interface DocModuleProps extends CardModuleProps {
  isCardModule?: boolean;
}

interface ListModuleProps {
  number: number;
  rep: string;
  uploadedBy: string;
  docTitle: string;
  fileName: string;
  onClick?: () => void;
}

interface CardModuleProps {
  docTitle: string;
  fileName: string;
  previewImg?: string;
  number: number;
  rep: string;
  uploadedBy?: string;
  onClick?: () => void;
  absolutePath ?: string
}

export const DocModuleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Card = styled.div`
  width: 300px;
  cursor: pointer;
`;

export function CardModule({
  absolutePath,
  fileName,
  docTitle,
  onClick,
}: CardModuleProps) {

  const errorRef = useRef(false);
  const [state, setState] = useState('loading');

  const onLoad = () => setState('complete');

  const onError = () => {
      if (errorRef.current) return;
      setState('error');
      errorRef.current = true;
  }

  return (
    <button className='sb-doc-card' onClick={onClick}>
      <div className={`sb-doc-preview-wrapper ${state}`}>
        <img
          src={absolutePath}
          alt=""
          onLoad={onLoad}
          onError={onError}
          className={`sb-doc-preview ${state}`}
          loading='lazy'
        />
        <MaterialIcon icon="insert_drive_file" classes={`placeholder icon ${state}`}/>
      </div>
      <div className="sb-doc-info">
        <div className="sb-doc-name-wrapper">
          <p className="sb-doc-name">{fileName}</p>
        </div>
        <div className="sb-doc-footer">
          {docTitle ? <p className="sb-doc-type">{docTitle}</p> : null}
          {fileName ? <p className="sb-doc-extension">{fileName?.split(".").pop()}</p> : null}
        </div>
      </div>
    </button>
  );
}

export function ListModule({
  number,
  rep,
  uploadedBy,
  docTitle,
  fileName,
  ...props
}: ListModuleProps) {
  return (
    <div
      className="d-flex w-75 justify-content-between border-black align-items-center"
      {...props}
    >
      <p className="col-1">{number}</p>
      <p className="col-4">
        {fileName.length > 25 ? fileName.substr(0, 24) + "..." : fileName}
      </p>
      <p className="col-1 text-sm ml-2">
        <strong className="pl-2 pr-2 rounded-sm bg-gray-200">
          {fileName.split(".").pop()}
        </strong>
      </p>
      <p className="col-4">
        {docTitle.length > 20 ? docTitle.substr(0, 19) + "..." : docTitle}
      </p>
      <p className="col-1">{rep}</p>
      <p className="col-1">{uploadedBy}</p>
    </div>
  );
}

export function DocModule({ isCardModule, ...props }: DocModuleProps) {
  return isCardModule ? (
    <CardModule
      {...props}
      number={props.number}
      rep={props.rep}
      fileName={props.fileName}
      docTitle={props.docTitle}
      previewImg={props.previewImg}
      onClick={() => props.onClick()}
    />
  ) : (
    <ListModule
      {...props}
      number={props.number}
      fileName={props.fileName}
      docTitle={props.docTitle}
      rep={props.rep}
      uploadedBy={props.uploadedBy}
      onClick={() => props.onClick()}
    />
  );
}
