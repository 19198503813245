import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";

export const UserProfileSettingsButton = (props) => {
    const { label, description, onClick, icon, classes, disabled, iconClasses } = props;
  
    return (
      <button
        className={`settings-button ${classes ? classes : ''}`}
        onClick={onClick}
        disabled={disabled}
      >
        <MaterialIcon icon={icon} classes={`settings-button-icon ${iconClasses ? iconClasses : ''}`} size="20" />
  
  
        <div className="settings-button-label-group">
          <span className="settings-button-label">
            {label}
          </span>
  
          {description ? (
            <span className="settings-button-description">
              {description}
            </span>
          ) : null}
        </div>
  
        <MaterialIcon icon="chevron_right" classes="settings-button-arrow-icon" />
      </button>
    )
  }