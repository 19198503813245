import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, FormikProvider, useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Divider, Grid, Box } from "@mui/material";
import { object, number, array, string } from "yup";
import { ErrorMessage } from "../formik/errorMessage";

import PageHeader from "apps/pos/Common/PageHeader/PageHeader";
import {
  PosButton,
  PosHelmet,
  PosImage,
  PosInput,
} from "apps/pos/Common/uiCore";
import { getPutInStorageItems, getShipmentId } from "../pages/customerInfo/CustomerOrders/redux/selector";

import SetStorageDetailModal from "./SetStorageDetail";
import { Keys } from "constants/enum";
import { Paths } from "enums";
import { CheckIcon } from "constants/icons";
import images from "constants/images";
import texts from "apps/pos/locales";

import "./putInStorage.scss";

const PutInStorage = () => {
  const navigate = useNavigate();
  const location = useLocation()

  const { defaultIcon } = images;
  const {
    item,
    manufacturer,
    qtyInShipment,
    helmetTitle,
    putInStorage,
    Shipment,
    qtyToPutInStorage,
    qtyAlreadyInStorage,
  } = texts;

  const [storageModal, setStorageModal] = useState(false);
  const [putEntireShipment, setPutEntireShipment] = useState(false);

  const putInStorageItems = useSelector(getPutInStorageItems);
  const shipmentId = useSelector(getShipmentId);

  const formik = useFormik({
    initialValues: {
      items: [],
    },
    validationSchema: object().shape({
      items: array(
        object({
          shipmentItemID: number(),
          productID: number(),
          manufacturerCode: string(),
          productName: string(),
          itemType: string(),
          qtyPutInStorage: string(),
        })
      ),
    }),
    onSubmit: () => {
      // console.log("values");
    },
  });

  const { values, setFieldValue} = formik;

  const handleQtyPutInStorage = (
    e,
    qtyInShipment,
    qtyAlreadyInStorage,
    index
  ) => {
    const {target: { value }} = e;
    const difference = Number(qtyInShipment - qtyAlreadyInStorage);
    if (Number(value) >= difference) {
      setFieldValue(`items[${index}].qtyPutInStorage`, difference);
    } else if (Number(value) < difference) {
      setFieldValue(`items[${index}].qtyPutInStorage`, value);
    }
  };

  useEffect(() => {
    if (putInStorageItems?.length) {
      putInStorageItems?.forEach((item, index: number) => {
        setFieldValue(`items[${index}].shipmentItemID`, item.shipmentItemID);
        setFieldValue(`items[${index}].productID`, item.productID);
        setFieldValue(
          `items[${index}].manufacturerCode`,
          item.manufacturerCode
        );
        setFieldValue(`items[${index}].productName`, item.productName);
        setFieldValue(`items[${index}].itemType`, item.itemType);
        setFieldValue(`items[${index}].qtyPutInStorage`, '');
      });
    }
  }, [putInStorageItems]);

  const handlePutInStorageButton = () => {
    setPutEntireShipment(false);
    setStorageModal(true);
  }
    const handleKeyDown = (event) => {
    if (event.key === Keys.escape && location.pathname === Paths.putInStorage) {
      navigate(Paths.customer);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate]);
  
  const isPutInStorageBtnDisabled = values?.items?.map((item) => {
    return item?.qtyPutInStorage > 0
  })?.includes(true)

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <div className="put-storage-container">
            <PosHelmet
              title={helmetTitle}
              metaName="PutInStorage page"
              content="PutInStorage page"
            />
            <PageHeader
              onClose={() => navigate(-1)}
              title={putInStorage}
              titleColor={false}
              subTitleColor
              subTitle={`${Shipment} #${shipmentId ?? ''}`}
              tabIndex={-1}
            >
              <div className="d-flex flex-wrap">
                <div className="btn-change-customer mb-3">
                  {/* May need later */}
                  {/* <PosButton
                    height="2.75rem"
                    width="13rem"
                    variant="contained"
                    startIcon={<DoneAllIcon />}
                    onClick={() => {
                      setPutEntireShipment(true);
                      setStorageModal(true);
                    }}
                  >
                    {putAllInStorage}
                  </PosButton> */}
                </div>
                <div>
                  <PosButton
                    className="btn mb-3 put-in-storage-btn btn"
                    color="primary"
                    variant="contained"
                    startIcon={<CheckIcon />}
                    onClick={handlePutInStorageButton}
                    disabled={!isPutInStorageBtnDisabled}
                  >
                    {putInStorage}
                  </PosButton>
                </div>
              </div>
            </PageHeader>
            <Container className="new-container mt-3">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} className="table-header-styling">
                  <Grid item xs={5} className="fs-10">
                    <div className="storage-first-sec-title">{item}</div>
                  </Grid>
                  <Grid item xs={2} className="fs-10">
                    {manufacturer}
                  </Grid>
                  <Grid item xs={1} className="fs-10 text-right">
                    {qtyInShipment}
                  </Grid>
                  <Grid item xs={2} className="fs-10 text-right">
                    <span className=" mr-3">
                      {qtyAlreadyInStorage}
                    </span>
                  </Grid>
                  <Grid item xs={2} className="fs-10">
                    <div className="storage-qty-title">{qtyToPutInStorage}</div>
                  </Grid>
                </Grid>
              </Box>
            </Container>

            <div className="put-in-storage-table">
              {putInStorageItems?.map((item, index) => {
                const {productName, productID, manufacturerName, unitQtyInShipment, qtyHasPutInStorage} = item
                return (
                  <Container className="new-container" key={`index${index}`}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2} className="order-items">
                        <Grid item xs={12} sm={8} md={8} lg={5} className="customer-first-info-col storage-data-info">
                          <PosImage
                            src={defaultIcon}
                            alt="filter"
                            className="customer-storage-img"
                          />
                          <div className="customer-name fs-16">
                            <span className="fw-medium">{productName}</span>
                            <span className="int-14-reg-gray">
                              {productID}
                            </span>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2} className="fs-16 storage-data-info">

                          <div className="d-flex align-items-center">
                            <span className="fs-10 small-screen-title mr-2">{manufacturer}: </span> {manufacturerName}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={1} className="fs-16 storage-text-alignment storage-data-info">
                          <div className="d-flex align-items-center">
                            <span className="fs-10 small-screen-title mr-2">{qtyInShipment}: </span> {unitQtyInShipment}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2} className="fs-16 storage-text-alignment storage-data-info">
                          <div className="d-flex align-items-center mr-3">
                            <span className="fs-10 small-screen-title mr-2">{qtyAlreadyInStorage}: </span> {qtyHasPutInStorage}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2} className="storage-text-alignment storage-data-info">
                          <div className="d-flex align-items-center">
                            <span className="fs-10 small-screen-title mr-2">{qtyToPutInStorage}: </span>
                            <PosInput
                              className="qty-input input-focus-styling input-out-focus-light-text "
                              variant="filled"
                              id="filled-basic"
                              autoFocus={!index}
                              InputProps={{ disableUnderline: true }}
                              name={`items[${index}].qtyPutInStorage`}
                              value={values?.items[index]?.qtyPutInStorage}
                              onChange={(e) => {
                                handleQtyPutInStorage(
                                  e,
                                  unitQtyInShipment,
                                  qtyHasPutInStorage,
                                  index
                                );
                              }}
                              onFocus={(e) => {
                                e.target.select();
                              }}
                            />
                          </div>
                          <ErrorMessage name={`items[${index}].qtyPutInStorage`} />
                        </Grid>
                      </Grid>
                      <Divider className="update-product-container-line" />
                    </Box>
                  </Container>
                );
              })}
            </div>
           
            <SetStorageDetailModal
              storageModal={storageModal}
              setStorageModal={setStorageModal}
              itemsDetail={values}
              isPutEntireShipment={putEntireShipment}
            />
          </div>
        </Form>
      </FormikProvider>
    </>
  );
};

export default PutInStorage;
