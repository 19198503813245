import { useState } from "react";
import { useDispatch } from "react-redux";
import { InputAdornment } from "@material-ui/core";
import { Formik } from "formik";

import { PosButton, PosInput } from "apps/pos/Common/uiCore";

import { CheckIcon } from "constants/icons";
import { numberRegex } from "constants/constant";
import { handlingFeeDataInfo, restockingFeeDataInfo, shippingFeeDataInfo } from "sagas/persistReducer/actions";
import { blockInvalidChar } from "utils/utils";

import "./shippingCreditModal.scss"

interface ShippingCreditModalProps {
  handleShippingCreditCallback?: (number) => void;
  setModal?: (boolean) => void;
  shippingReturnedInfo?: number;
  setShippingFeeInfo?: number;
  setShippingCreditInfo?: number;
  shippingPriceTotal?: number;
  shippingFeeIndex?: number;
  passShippingData?: number;
  restockingTotalInfo ?: number;
  shippingFee ?: number;
  initialSubTotalAmount ?: number;
}

const ShippingCreditModal = ({
  handleShippingCreditCallback,
  setModal,
  shippingReturnedInfo,
  setShippingFeeInfo,
  setShippingCreditInfo,
  shippingFeeIndex,
  passShippingData,
  restockingTotalInfo,
  shippingFee,
  initialSubTotalAmount
}: ShippingCreditModalProps) => {

  const dispatch = useDispatch();

  const [lessCreditData, setLessCreditData] = useState(false);

  const availableShippingCredit = setShippingFeeInfo - setShippingCreditInfo;

  // Use Default Logic ::
   const totalValueOfReturnItem = restockingTotalInfo / initialSubTotalAmount ;
   const defaultUse = totalValueOfReturnItem * shippingFee;
   const twoDigitDefualtUseValue = defaultUse.toFixed(2)

  return (
    <Formik
      initialValues={{
        shippingCredit: passShippingData ? passShippingData : null,
      }}
      enableReinitialize
      onSubmit={(values) => {
        if(values?.shippingCredit > availableShippingCredit) {
          setLessCreditData(true)
        }
        else {
        handleShippingCreditCallback(values)
        dispatch(shippingFeeDataInfo({
          orderId: Number(shippingFeeIndex),
          shippingFeeChange: Number(values?.shippingCredit),
        }))

        dispatch(handlingFeeDataInfo({
          orderId: Number(shippingFeeIndex),
          handlingFee: Number(0)
        }))

        dispatch(restockingFeeDataInfo({
          orderId: Number(shippingFeeIndex), 
          restockingFee: Number(0),   
          restockingRate: Number(0),
        }))
        
        setModal(false);
      }
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        const { shippingCredit } = values
        const defaultBtnClick = (e) => {
          e.preventDefault();
          setFieldValue("shippingCredit", twoDigitDefualtUseValue);
        }

        const handleShippingFeeValue = (event) => {
          if (
            event.target.value === "" ||
            numberRegex.test(event.target.value)
          ) {
            setFieldValue("shippingCredit", event.target.value);
            setLessCreditData(false)
          }
        }

        const handleFocuse = (event) => {
          if(Number(event.target.value) === 0) {
            event.target.value = "";
            setFieldValue("shippingCredit", event.target.value);
          }
        }

        return (
          <section className="shipping-credit-modal">
          <div className="shipping-credit-input">
            <span className="input-title">Shipping Credit</span>
            <PosInput
            variant="outlined"
            type="number"
            className={`${lessCreditData ? 'mb-2' : ''} credit-input` }
            autoFocus
            name="shippingCredit"
            value={shippingCredit}
            onKeyPress={(e) => blockInvalidChar(e)}
            onChange={(e) => handleShippingFeeValue(e)}
            onFocus={(e) => handleFocuse(e)}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                  $
                </InputAdornment>
              ),
            }}
            onWheel={(e) => (e?.target as HTMLElement)?.blur()}
          />
        </div>
        {lessCreditData &&
        <div className="no-more-shipping-credit">
            <span className="title custome-error-color">Cannot be more than Avl. Shipping Credit: $ {availableShippingCredit}</span>
        </div>
        }

        <div className="items-return-list">
          <div className="shipping-credit-wrapper-main">
            <span className="title">Avl. Shipping Credit</span>
            <span className="title-id">$ {availableShippingCredit}</span>
          </div>
          <div className="shipping-credit-wrapper-main">
            <span className="title">Shipping Fee</span>
            <span className="title-id">$ {setShippingFeeInfo}</span>
          </div>
          <div className="shipping-credit-wrapper-main">
            <span className="title">Items Returned</span>
            <span className="title-id">{shippingReturnedInfo}</span>
          </div>
        </div>

        <div className="use-default-button-section">
          <PosButton variant="contained" color="secondary" className="use-default-btn"
            onClick={defaultBtnClick}
          >
              Use Default (${twoDigitDefualtUseValue})
            </PosButton>
            <PosButton
              onClick={() => {
                handleSubmit();
              }}
              disabled={lessCreditData}
              variant="contained" color="primary" className="use-default-btn" startIcon={<CheckIcon />}>
              Save Changes
            </PosButton>
        </div>
    </section>
);
    }}

  </Formik>
  )
}
export default ShippingCreditModal;