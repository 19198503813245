import { PosButton, PosIconButton, PosModel } from "apps/pos/Common/uiCore";
import { ClearIcon } from "constants/icons";
import { useDispatch } from "react-redux";

import { addToCartDrawer, productsDataClear } from "sagas/pos/actions";

import "apps/pos/pages/customerInfo/Documents/openEditDocument/openEditDocumentModal.scss";

const CartPageProductNotes = ({ productsData, setNotesOpen, notesOpen, callType }) => {
  const dispatch = useDispatch();

  const handleNotes = () => {
    setNotesOpen(false)
    callType && dispatch(productsDataClear());
  };

  const notesHandler = () => {
    setNotesOpen(false)
    dispatch(addToCartDrawer(true))
  }

  return (
    <>
      <PosModel
        dialogClassName="edit-document-info-modal product-notes-modal"
        open={notesOpen}
        onClose={handleNotes}
      >
        <div className="edit-document-info-section">
          <PosIconButton className="close-modal-icon" onClick={handleNotes}>
            <ClearIcon />
          </PosIconButton>
        </div>
        <div className="edit-document-container">
          <div className="edit-document-title-section">
            <div className="edit-document-title">
              Notes for {productsData.productName}
            </div>
          </div>
          <div className="edit-document-type-section">
            <div className="edit-document-type-text">
              {productsData.notes?.map((item) => {
                return <div className="mt-4">{item}</div>;
              })}
            </div>
          </div>
          {callType ? <div className="product-notes-bottom-section w-100">
              <div>
                <PosButton
                  variant="contained"
                  className="cancel-btn"
                  onClick={handleNotes}
                >
                  Back
                </PosButton>
              </div>
              <div>
                <PosButton
                  variant="contained"
                  color="primary"
                  className="delete-confirmation-btn"
                  onClick={notesHandler}
                >
                  Continue
                </PosButton>
              </div>
          </div> : null}
        </div>
      </PosModel>
    </>
  );
};

export default CartPageProductNotes;
