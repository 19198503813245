import "@fontsource/inter";
import { Box, Drawer } from "@material-ui/core";
import _ from "loadsh";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTimeout } from "timers";

import {
  Container,
  PosIconButton,
  PosInput,
  PosPageHeading,
} from "apps/pos/Common/uiCore";
import SavedOrdersFound from "apps/pos/SavedOrdersFound";
import TakerList from "./TakerList";

import {
  ArrowBackRoundedIcon,
  ArrowUpwardIcon,
  ClearIcon,
  SearchIcon,
  SkipNextIcon,
} from "constants/icons";

import { drawerUseStyles } from "theme/GlobalStyles";
import { errorConstants, scrollBehaviour, UesrType, useStyles } from "./commonConstant";

import { Paths } from "enums";
import { RootState } from "models";
import { getContentById, keys } from "utils/helper";
import { scrollOnContent } from "utils/utils";

import { snackbarDetail } from "constants/constant";
import { loadCartRequest, setPosFlowData } from "sagas/persistReducer/actions";
import {
  arrowDownIndex,
  arrowReset,
  arrowUpIndex,
  savedDraftsReset,
} from "sagas/pos/actions";
import { snakbarShow } from "sagas/pos/snakbar/snakbar.slice";



const SelectTakerSRDrawer = ({
  setSelectTakerDrawer,
  selectTakerDrawer,
  onCloseDrawer,
  setIsConditionTrue,
  isConditionTrue
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [searchString, setSearchString] = useState("");

  const {
    takerDetails,
    draftLoadError,
    draftLoadMessage,
    isLoading,
    selectedIndex,
  } = useSelector<RootState, any>((state) => state.pos);
  const { posFlowData } = useSelector<RootState, any>(
    (state) => state.persistReducer
  );
  const myRef = useRef(null);
  const searchTakerRef = useRef(null);
  const navigate = useNavigate();

  const [takerData, setTakerData] = useState<any>({});
  const [draftCartModal, setDraftCartModal] = useState(false);
  const [availTakers, setAvailTakers] = useState([])

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const recentUsers =
    JSON.parse(localStorage.getItem("returnRecentUser")) || [];
  useEffect(() => {
    if (takerDetails && !isLoading) {
      const newData = takerDetails?.data?.filter(user =>
        recentUsers?.some(recentTaker => user.personId === recentTaker.personId)
      ) || [];
      setAvailTakers(newData);
    }
  }, [takerDetails, isLoading])

  useEffect(() => {
    if (draftLoadMessage) {
      setDraftCartModal(true);
      setState({ ...state, right: true });
    }
  }, [draftLoadMessage]);

  const allTakerData = useMemo(() => {
    const takersData = [];
    let takerDataCount = 0;
    const { data: takerList } = takerData
    if (searchString) {
      const takerSearchList = takerList.filter((data) =>
        data?.fullName.toLowerCase().includes(searchString.toLowerCase())
      );
      takerDataCount = takerDataCount + takerSearchList?.length;
      takerSearchList.length &&
        takersData.push({
          label: UesrType.allUser,
          list: takerSearchList,
        });
    } else {
      if (availTakers?.length) {
        takerDataCount = takerDataCount + availTakers?.length;
        takersData.push({
          label: UesrType.recent,
          list: availTakers || [],
        });
      }

      if (takerList?.length) {
        const filteredTakerList = takerList?.filter(({ personId }) => {
          return !availTakers
            .map(({ personId }) => personId)
            .includes(personId);
        });
        takerDataCount = takerDataCount + filteredTakerList?.length;
        takersData.push({
          label: UesrType.allUser,
          list: filteredTakerList || [],
        });
      }
    }
    return {
      takersData,
      takerDataCount,
    };
  }, [availTakers, searchString, takerData]);

  useEffect(() => {
    if (draftLoadError) {
      const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));
      const currentTaker = JSON.parse(localStorage.getItem("returnTaker"));
      const preparedData = {
        stockWarehouseId: currentWorkspace.stockWarehouseId,
        customerId: posFlowData?.customer?.customerId,
        takerId: currentTaker?.personId,
        cartId: null,
      };
      dispatch(loadCartRequest(preparedData));
      setTimeout(() => {
        setDraftCartModal(false);
        navigate(Paths.posReturnCart);
        dispatch(savedDraftsReset());
      }, 100);
    }
  }, [draftLoadError]);

  useEffect(() => {
    setTakerData(takerDetails);
  }, [takerDetails]);

  async function selectFromAllUsers(returnTaker: any) {
    const data = {
      ...posFlowData,
      returnTaker,
    };

    returnTaker && localStorage.setItem("returnTaker", JSON.stringify(returnTaker));
    const oldRecentUser =
      JSON.parse(await localStorage.getItem("returnRecentUser")) || [];
    if (!_.find(oldRecentUser, returnTaker)) {
      oldRecentUser.push(returnTaker);
      localStorage.setItem("returnRecentUser", JSON.stringify(oldRecentUser));
    } else {
      const oldRecentUser =
        JSON.parse(await localStorage.getItem("returnRecentUser")) || [];
      const filterRecentUser =
        oldRecentUser?.length &&
        oldRecentUser?.filter(
          (item) => item.personId !== returnTaker?.personId
        );
      filterRecentUser.push(returnTaker);
      localStorage.setItem(
        "returnRecentUser",
        JSON.stringify(filterRecentUser)
      );
    }
    dispatch(setPosFlowData(data));
    setIsConditionTrue(true)
    setSelectTakerDrawer(false);
    dispatch(snakbarShow({ message: snackbarDetail.takerChange }))
  }

  function selectLastTaker() {
    let taker = null;
    if (availTakers?.length) {
      taker = availTakers[availTakers.length - 1];
    }
    const data = {
      ...posFlowData,
      taker,
    };
    dispatch(setPosFlowData(data));
    setSelectTakerDrawer(false);
    setIsConditionTrue(true)
    if (selectTakerDrawer) {
      setIsConditionTrue(true)
      setSelectTakerDrawer(false);
    }
    navigate(Paths.posReturnCart);
  }

  const takerRowRef = useRef<any>();

  const scrollToSetting = (selectedIndex) => {
    const elementId = document?.getElementById(`takerRowRef${selectedIndex}`);
    elementId && elementId?.scrollIntoView(scrollBehaviour);
  };

  useHotkeys('enter', () => {
    const completeTakerList = []
    allTakerData.takersData.map(({ list }) => { list.map((takerDetails) => completeTakerList.push(takerDetails)) })
    selectFromAllUsers(completeTakerList[selectedIndex])
  }
  )

  function handleKeyPress(e) {
    const { keyCode } = e;
    const { t, downarrow, uparrow } = keys
    if (availTakers.length) {
      if (keyCode === t) {
        selectLastTaker();
        setState({ ...state, right: false });
      }
    }
    if (keyCode === downarrow) {
      searchTakerRef.current.blur();
      scrollToSetting(selectedIndex + 1);
      dispatch(arrowDownIndex(allTakerData.takerDataCount));
    } else if (keyCode === uparrow) {
      searchTakerRef.current.blur();
      dispatch(arrowUpIndex());
      if (selectedIndex > 0) {
        scrollToSetting(selectedIndex);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return function cleanup() {
      dispatch(arrowReset());
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [allTakerData.takerDataCount, searchTakerRef]);

  const drawerClass = drawerUseStyles();

  useEffect(() => {
    scrollOnContent();
  }, []);

  // start
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const contentSec = getContentById();

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, [contentSec]);

  useEffect(() => {
    const elHeight = document.getElementById("drawer-header")?.clientHeight;
    if (contentSec && matches) {
      getContentById().style.paddingTop = `${elHeight + 65}px`;
    } else if (contentSec && !matches) {
      getContentById().style.paddingTop = "0px";
    }
  }, [contentSec, matches]);
  // end

  const { takersData } = allTakerData

  return (
    <Box role="presentation" className={`taker-modal-section h-100`}>
      {isConditionTrue && (
        <PosIconButton
          className="back-next-btn-small btn responsive-back-btn pos-icon-back-btn"
          onClick={onCloseDrawer}
        >
          <ClearIcon className="back-icon" />
        </PosIconButton>
      )}
      <div ref={myRef}></div>
      <Container
        className="modal-content-style modal-container-styling"
        id="noScrollContent"
      >
        <main>
          <div>
            <div className="drawer-static-header" id="drawer-header">
              <div className="pricing-modal-header">
                <PosPageHeading
                  heading="Who is processing this return?"
                  grayHeading
                />
                <h1 className="pricing-schedule-heading">
                  <PosPageHeading heading="Select Taker" blackHeading />
                </h1>
              </div>
              {availTakers.length && isConditionTrue ? (
                <div className="modal-select-default-btn schedule-section">
                  <div className="select-default-text">
                    <div>
                      <SkipNextIcon />
                    </div>
                    <div
                      className="default-schedule-heading"
                      onClick={() => selectLastTaker()}
                    >
                      <span className="int-14-reg-white">
                        Keep current taker
                      </span>
                      <span className="schedule-number-info">
                        {JSON.parse(localStorage.getItem("returnTaker"))?.fullName}
                      </span>
                    </div>
                  </div>
                  <div>
                    <span className="schedule-shortcut">T</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {takerDetails?.data?.length ? (
                <div className="search-with-close-input xxxxx">
                  <div className="search-icon">
                    <SearchIcon />
                  </div>
                  <PosInput
                    inputRef={searchTakerRef}
                    variant="outlined"
                    id="outlined-basic"
                    className="double-bordered-input"
                    placeholder="Search taker"
                    value={searchString}
                    onChange={(e) => {
                      if (
                        !e.target.value.includes("  ") &&
                        e.target.value.charAt(0) !== " "
                      ) {
                        setSearchString(e.target.value);
                      }
                    }}
                  />
                  <div
                    className="close-icon"
                    onClick={() => setSearchString("")}
                  >
                    <ClearIcon />
                  </div>
                </div>
              ) : (
                <div className="search-with-close-input"></div>
              )}
            </div>

            {takersData?.length ? (
              <div
                className="drawer-content-scroll modal-subheading mb-0 mt-0 position-relative drawer-bottom-sec-styling"
                id="shippingScrollStyling"
              >
                <TakerList
                  selectFromAllUsers={selectFromAllUsers}
                  takerRowRef={takerRowRef}
                  allTakerData={allTakerData}
                  selectedIndex={selectedIndex}
                />
                <div className="back-to-top-section">
                  <div
                    className="back-to-top-btn"
                    onClick={() => myRef.current.scrollIntoView()}
                  >
                    <ArrowUpwardIcon />
                    <span className="back-to-top-text">Jump back to top</span>
                    <span> </span>
                  </div>
                </div>
              </div>
            ) : (
              !isLoading && (
                <div
                  className="drawer-content-scroll modal-subheading mb-0 mt-0 position-relative drawer-bottom-sec-styling"
                  id="shippingScrollStyling"
                >
                  <div className="modal-subheading mb-0 mt-0">
                    {searchString
                      ? errorConstants.noSearchResult
                      : errorConstants.noTakerFound}
                  </div>
                </div>
              )
            )}
          </div>
        </main>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          className={`${classes.drawer} ${drawerClass.drawer} default-drawer-style no-cart-drawer main-drawer-classes drawer-scrollbar-style`}
        >
          <Box sx={{ width: 750 }} role="presentation">
            <PosIconButton
              className="back-next-btn-small btn back-responsive-btn responsive-back-btn pos-icon-back-btn"
              onClick={() => setState({ ...state, right: false })}
            >
              <ArrowBackRoundedIcon className="back-icon" />
            </PosIconButton>
            {draftCartModal ? (
              <SavedOrdersFound
                state={state}
                setState={setState}
              />
            ) : (
              <></>
            )}
          </Box>
        </Drawer>
      </Container>
    </Box>
  );
};

export default SelectTakerSRDrawer;
