import { endpoints } from "apps/springboard/endpoints";
import service from "services/config";
import serviceV2 from "services/config2";

export const setPlaceOrderFlag = (id) => {
  return serviceV2.get(endpoints.setPlaceOrderId(id));
};

export const setConvertToExpressApi = (jobLotQuoteId) => {
  return service.get(endpoints.convertToExpress(jobLotQuoteId));
};

export const setConvertIdToExpressApi = (jobLotQuoteId) => {
  return service.get(endpoints.convertIdToExpress(jobLotQuoteId));
};