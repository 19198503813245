import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { InputAdornment } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { PosInput, PosModel, PosPageHeading } from "apps/pos/Common/uiCore";

import { ArrowForwardIcon, ClearIcon, CloseIcon, SearchIcon } from "constants/icons";
import { staticData } from "constants/constant";
import { commonConstants } from "apps/product/helper/commonConstants";


import useProductsDetailsHooks from "apps/product/modules/productDetailsContainer/hooks/mutationHooks";
import { getIsGetProductsPriceHistory, getProductPriceHistory } from "sagas/productsDetails/selector";
import { getProductsPriceHistoryReset, getProductsPriceHistoryStart } from "sagas/productsDetails/productCosePrice.slice";

import "./productDetailsWholesaleModal.scss";
interface ProductDetailsWholesaleModalProps {
    setWholesaleModal: (boolean) => void;
    wholesaleModal: boolean;
    priceTypeID: number;
    priceLable: string;
}
const ProductDetailsWholesaleModal = ({
    setWholesaleModal,
    wholesaleModal,
    priceTypeID,
    priceLable
}: ProductDetailsWholesaleModalProps) => {

    const params = useParams();
    const dispatch = useDispatch();

    const getProductPriceHistoryData = useSelector(getProductPriceHistory)
    const isGetProductsPriceHistory = useSelector(getIsGetProductsPriceHistory)
    const {
        getProductsPriceHistoryMutation
    } = useProductsDetailsHooks();

    const { DATE , CHANGEDBY , OLDPRICE , NEWPRICE } = commonConstants;

    const [searchValue, setSearchValue] = useState("");
    const [doesHaveMore, setHaveMore] = useState<boolean>(true);
    const [historyData, setHistoryData] = useState([]);
    const [filteredHistoryData, setFilteredHistoryData] = useState(
        []
    );
    const [pageNumber, setPageNumber] = useState(1);
    const { loading, noRecordFound } = staticData;
    useEffect(() => {
        if (getProductPriceHistoryData?.data?.length) {
            setHistoryData([...historyData, ...getProductPriceHistoryData?.data])
        }
    }, [getProductPriceHistoryData])

    useEffect(() => {
        if (historyData?.length) {
            setFilteredHistoryData(historyData)
        }
    }, [historyData])

    useEffect(() => {
        const body = {
            productsId: Number(params?.id),
            priceTypeId: Number(priceTypeID),
            page: 1,
            size: 20,
        }
        // Get PriceType wise Price History
        dispatch(getProductsPriceHistoryStart())
        getProductsPriceHistoryMutation.mutate(body);
    }, [priceTypeID])

    const closeModal = () => {
        setWholesaleModal(false);
        dispatch(getProductsPriceHistoryReset())
    };

    const handleClearSearch = () => {
        setSearchValue("");
        setFilteredHistoryData(historyData);
    };

    const handleSearchChange = (e) => {
        const { target: { value } } = e;
        setSearchValue(value);

        const results = historyData?.filter((item) =>
            [item.firstName.toLowerCase(), item.lastName.toLowerCase(), item.margin, item.newPrice, item.oldPrice, item.changeDate].some((field) =>
                field.toString().includes(value.toLowerCase())
            ));
        setFilteredHistoryData(results);
    };

    const handleScroll = () => {
        if (!getProductPriceHistoryData?.isPaginated) {
            setHaveMore(false);
        }
        else {
            setPageNumber(pageNumber + 1);
            const body = {
                productsId: Number(params?.id),
                priceTypeId: Number(priceTypeID),
                page: pageNumber + 1,
                size: 20,
            };
            // Get PriceType wise Price History
            dispatch(getProductsPriceHistoryStart())
            getProductsPriceHistoryMutation.mutate(body);
        }
    };

    return (
        <>
            <PosModel
                open={wholesaleModal}
                onClose={closeModal}
                className={`set-customer-code-modal`}
                dialogClassName={`set-customer-code-modal-container set-storage-modal-container put-storage-modal-container price-history-modal`}
                dialogHeader={
                    <div className="modal-header">
                        <div className="d-flex flex-column">
                            <PosPageHeading
                                heading='Price History'
                                grayHeading
                                mediumHeading
                                className="mb-2"
                            />
                            <PosPageHeading
                                heading={priceLable}
                                blackHeading
                                mediumHeading
                            />
                        </div>
                        <ClearIcon className="clear-icon text-gray" onClick={closeModal} />
                    </div>
                }
                dialogFooter={
                    <>
                        <div className={`d-flex flex-wrap`}>
                        </div>
                    </>
                }
            >
                <>
                    <section className="document-main-section">
                        <div className="document-container">
                            <div className="input-with-add-button pt-0">
                                <PosInput
                                    value={searchValue}
                                    variant="filled"
                                    id="filled-basic"
                                    type="text"
                                    className="document-search-input"
                                    autoFocus
                                    placeholder='Filter…'
                                    onChange={handleSearchChange}
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            searchValue ? <InputAdornment
                                                position="end"
                                                className="search-order-input-last-adornment"
                                            >
                                                <div className="d-flex align-items-center">
                                                    <CloseIcon className="cursor-pointer ml-2" onClick={handleClearSearch} />
                                                </div>
                                            </InputAdornment> : <></>
                                        ),
                                    }}
                                />
                            </div>
                            <TableContainer
                                component={Paper}
                                className="customer-info-table-container mt-4"
                            >
                                <InfiniteScroll
                                    dataLength={historyData?.length || 0}
                                    next={handleScroll}
                                    height={400}
                                    hasMore={doesHaveMore}
                                    loader={false}
                                    scrollThreshold={0.9}
                                >
                                    <Table
                                        aria-label="simple table"
                                        className="customer-table-styling"
                                    >
                                        <TableHead className="customer-draft-header header-text">
                                            <TableRow>
                                                <TableCell className="customer-info-table-title first-table-row">
                                                    {DATE}
                                                </TableCell>
                                                <TableCell className="customer-info-table-title second-table-row min-w-220">
                                                    {CHANGEDBY}
                                                </TableCell>
                                                <TableCell className="customer-info-table-title third-table-row min-w-150">
                                                    {OLDPRICE}
                                                </TableCell>
                                                <TableCell className="customer-info-table-title third-table-row" />
                                                <TableCell className="customer-info-table-title fourth-table-row min-w-100">
                                                    {NEWPRICE}
                                                </TableCell>
                                                <TableCell className="customer-info-table-title fifth-table-row">
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                filteredHistoryData?.length ?
                                                    filteredHistoryData?.map((data) => {
                                                        const { firstName, lastName, margin, newPrice, oldPrice, changeDate } = data;
                                                        return (
                                                            <TableRow
                                                                className="customer-table-data-row draft-data-row"
                                                                sx={{
                                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                                }}
                                                            >
                                                                <TableCell className="customer-table-row-data-styling">
                                                                    <span>
                                                                        {changeDate}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell className="customer-table-row-data-styling change-by-cell"
                                                                >
                                                                    <span>
                                                                        {firstName} {lastName}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell className="customer-table-row-data-styling"
                                                                >
                                                                    <span>
                                                                        {oldPrice ? oldPrice.toFixed(5) : 0.00}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell><ArrowForwardIcon className="font-small-icon" /></TableCell>
                                                                <TableCell className="customer-table-row-data-styling"
                                                                >
                                                                    <span>
                                                                        {newPrice ? newPrice.toFixed(5) : 0.00}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell className="customer-table-row-data-styling"
                                                                >
                                                                    <span className={`${margin && margin > 0 ? "dark-mint-green-text-label" : "dark-red-color-text-label"}`}>
                                                                        {margin ? `${margin > 0 ? "+" + margin.toFixed(2) : margin.toFixed(2)}` : 0.00}%
                                                                    </span>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                    :
                                                    <TableRow className="customer-table-data-row draft-data-row"
                                                        sx={{
                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                        }}>
                                                        <TableCell colSpan={6} className="customer-table-row-data-styling text-center-td">
                                                            <span>
                                                                {isGetProductsPriceHistory ? loading : noRecordFound}
                                                            </span>
                                                        </TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </InfiniteScroll>
                            </TableContainer>
                        </div>
                    </section>
                </>
            </PosModel>
        </>
    );
};
export default ProductDetailsWholesaleModal;
