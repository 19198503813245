import { useState } from 'react';
import LocationPicker from '../../LocationPicker';
import MaterialIcon from "apps/pos/Common/uiCore/MaterialIcon";
import './ReturnToLineItem.scss';
import { motion } from 'framer-motion/dist/framer-motion';

const ReturnToLineItem = (props) => {
    const { returnTo , setReturnTo , categoryMenu } = props;

    const [visible, setVisible] = useState(false);

    const openLocationPicker = () => {
        setVisible(true);
    }
    return (
        <>
            <motion.div layout className="category-picker return-to-line-item bg-transparent max-w-full d-block"
                initial={{ opacity: 0.75, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
            >
                <button className={`location-button w-100 ${visible ? 'location-button-active' : ''}`} onClick={openLocationPicker}>
                    <span className="location-button-label">
                        {returnTo.row.categoryName || returnTo.unit.categoryName || returnTo.shelf.categoryName ? null : 'Select Category'}

                        {returnTo.row.categoryName ? returnTo.row.categoryName : null}

                        {returnTo.unit.categoryName ? '-' : null}
                        {returnTo.unit.categoryName ? returnTo.unit.categoryName : null}

                        {returnTo.shelf.categoryName ? '-' : null}
                        {returnTo.shelf.categoryName ? returnTo.shelf.categoryName : null}
                    </span>

                    <MaterialIcon icon='arrow_drop_down' size='20' />
                </button>
            </motion.div>

            <LocationPicker
                visible={visible}
                setVisible={setVisible}
                selectedLocation={returnTo}
                setSelectedLocation={setReturnTo}
                categoryMenu={categoryMenu}
            />
        </>
    );
}

export default ReturnToLineItem;
