import { ChangeEvent } from "react";

export const searchProductOnChange = (
  e: ChangeEvent<HTMLSelectElement>,
  setSelectProduct
) => {
  if (!e.target.value.includes("  ") && e.target.value.charAt(0) !== " ") {
    setSelectProduct(e.target.value);
  }
};

function containsAnyLetters(str) {
  return /[a-zA-Z]/.test(str);
}

export const searchCustomerOnChange = (event, setScmodel, setInvoiceNumber) => {
  if (containsAnyLetters(event.target.value)) {
    setScmodel(true);
  } else {
    setInvoiceNumber(event.target.value);
  }
};

export const keyPressHandle = (
  selectProduct,
  searchReturnInvoiceRequest,
  selectCustomer,
  invoiceNumber,
  dispatch
) => {
  const currentWorkspace = JSON.parse(localStorage.getItem("workspace"));
  const payload = {
    stockWarehouseId: currentWorkspace?.stockWarehouseId,
    productId: selectProduct?.productId,
    customerId: selectCustomer?.customerId,
    invoiceOrOrderId: Number(invoiceNumber) ? Number(invoiceNumber) : 0,
    numOfDays: 0,
    page: 1,
    size: 10,
  };
  (Number(invoiceNumber) ||
    (selectCustomer?.customerId && selectProduct?.productId)) &&
    dispatch(searchReturnInvoiceRequest(payload));
};
