import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PosButton, PosModel, PosPageHeading } from "apps/pos/Common/uiCore";
import { Button } from "components";

import { getStorageItemDetail } from "../pages/customerInfo/CustomerStorage/redux/selector";

import { CheckIcon, ClearIcon } from "constants/icons";
import SetStorageModal from "./setStorage";
import { Paths } from "enums";

import "./setStorage.scss";

interface StorageDataModalProps {
  setStorageDataModal: (boolean) => void;
  storageDataModal: boolean;
}

const StorageDataModal = ({
  storageDataModal,
  setStorageDataModal,
}: StorageDataModalProps) => {
  const navigate = useNavigate();
  const [storageModal, setStorageModal] = useState(false);

  const storageItemDetail = useSelector(getStorageItemDetail);

  const {
    inStorageDate,
    orderID,
    shipmentID,
    purchaseOrderNum,
    jobName,
    storageLocation,
    estDeliveryDate,
    shipmentItemValue,
    shipAddress,
    // shipmentInStorageID, //May need later
  } = storageItemDetail;

  const storageDetailsModal = () => {
    const storageDataOptions = [
      {
        title: "Order ID",
        primaryValue: `${orderID}`,
      },
      {
        title: "Put-in Date",
        primaryValue: `${inStorageDate}`,
        secondaryValue: "",
      },
      {
        title: "Est. Delivery Date",
        primaryValue: `${estDeliveryDate}`,
        secondaryValue: "",
      },
      {
        title: "Customer PO",
        primaryValue: `${purchaseOrderNum}`,
      },
      {
        title: "Job Name",
        primaryValue: `${jobName}`,
      },
      {
        title: "Location",
        primaryValue: `${storageLocation}`,
      },
      {
        title: "Value of Remaining Items",
        primaryValue: `$${shipmentItemValue}`,
      },
      {
        title: "Ship Address",
        primaryValue: `${shipAddress}`,
      },
    ];

    return (
      <>
        {storageDataOptions?.map((item) => {
          return (
            <>
              <div className="storage-data">
                <div className="storage-data-left-sec">
                  <div className="storage-data-title">
                    <span>{item?.title}</span>
                  </div>
                </div>
                <div className="d-flex flex-column storage-data-right-sec">
                  <div className="storage-first-data">
                    <span>{item?.primaryValue}</span>
                  </div>
                  <div className="storage-second-data">
                    <span>{item?.secondaryValue}</span>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </>
    );
  };

  const handleEditStorageDetailButton = () => {
    setStorageModal(true);
    setStorageDataModal(false);
  }

  return (
    <>
      <PosModel
        open={storageDataModal}
        onClose={() => setStorageDataModal(false)}
        className={`set-customer-code-modal`}
        dialogClassName={`set-customer-code-modal-container storage-data-modal-container set-storage-modal-container`}
        dialogHeader={
          <div className="modal-header">
            <div>
              <PosPageHeading
                heading="Items in Storage"
                blackHeading
                className="mb-1 small-page-heading"
              />
              <PosPageHeading
                heading={`Shipment #${shipmentID}`}
                grayHeading
                className="small-page-heading"
              />
            </div>
            <Button className="bg-transparent border-0 p-0 button-focus" onClick={() => setStorageDataModal(false)}>
              <ClearIcon
                className="clear-icon"
              />
            </Button>
          </div>
        }
        dialogFooter={
          <>
            <div className="d-flex justify-content-between w-100 flex-wrap">
              <PosButton
                type="button"
                variant="contained"
                className={`add-to-return-cart-button mr-2 mt-2 btn-font-600 w-auto h-auto btn`}
                fontWeight
                onClick={handleEditStorageDetailButton}
              >
                Edit Storage Details
              </PosButton>
              <PosButton
                variant="contained"
                color="primary"
                className={`add-to-return-cart-button mt-2 w-auto h-auto btn`}
                startIcon={<CheckIcon />}
                fontWeight
                onClick={() => navigate(Paths.deliverItemsInStorage)}
              >
                Deliver
              </PosButton>
            </div>
          </>
        }
      >
        {storageDetailsModal()}
      </PosModel>
      <SetStorageModal
        storageModal={storageModal}
        setStorageModal={setStorageModal}
        // storageLocation={storageLocation}//May need later
        // estDeliveryDate={estDeliveryDate}//May need later
        // shipmentID={shipmentID}//May need later
        // shipmentInStorageID={shipmentInStorageID}//May need later
      />
    </>
  );
};

export default StorageDataModal;
