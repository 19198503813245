import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { PosButton } from "apps/pos/Common/uiCore";
import CommonEditButton from "apps/product/Components/ProductDetails/CommonEditButton";
import CreateGridStarIcon from "apps/product/Components/ProductDetails/CreateGridStarIcon";
import CommonGridSection from "apps/product/Components/ProductDetails/CommonGridSection";
import useFilterSections from "apps/product/Components/ProductDetails/useSectionFilter";

import {
} from "apps/product/helper/product-details-helper";
import { labels, sectionName } from "apps/product/modules/productDetailsContainer/constants";
import { getSingleProductDataFunction } from "sagas/productsDetails/selector";

const ProductsDetailsMetadata = ({ isStarred = false , searchText , handleOpenCloseMetadataSeoModal , handleOpenCloseMetadataFlagesModal , handleOpenCloseMetadataModal, handleSearchIdentifiers, handleSearchSEO }) => {

  const getSingleProductData = useSelector(getSingleProductDataFunction);
  const { edit, Identifiers, UPC, EAN, GTIN , GCID, ASIN, Asin, Gcid, Gtin, Ean, Upc, canBeDrop, isMerquery, hidePrice, hideItem, Virtual, Hyperlink, Keywords, Footnote, FolderPath, Folder_Path, SEO, ClearanceEmailBlast, HidefromAmazonFeed, Hidepriceandpreventonlineordering, ContainsMercury, CanbeDropShipped, Flags } = labels
  const { metadata } = getSingleProductData || {}
  const { identifiers, flags, seo } = metadata || {}
  const { upc, ean, gtin, gcid, amazonAsin } = identifiers || {}
  const { dropshipFlag, containMercuryFlag, hidePriceFlag, hideFromAmazonFeed, isVirtual, isForClearanceEmailBlast } = flags || {}
  const { footNote, hyperlink, keywords , folderPath } = seo || {}
  const { metaData } = sectionName;
  const { showSection } = useFilterSections({ searchText, sectionName: metaData, isStarred });

  return (
    <>
      <section className="product-report-section">
        <Grid container className="product-report-container">
          {(!isStarred && !searchText) ? (
            <Grid container>
              <Grid item xs={6} className="meta-data-label p-0 pb-2">
                <span>{Identifiers}</span>
              </Grid>
              <Grid item xs={6} className="p-0 line-height-22 pb-2">
                <div className="d-flex justify-content-end edit-main-btn">
                  <PosButton
                    variant="contained"
                    className="btn-discard btn font-size title-edit-section"
                    onClick={handleOpenCloseMetadataModal}
                  >
                    {edit}
                  </PosButton>
                </div>
              </Grid>
            </Grid>
          ): null}
          {showSection(Upc,UPC) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='upc' label={UPC} value={upc} sectionName={metaData} />
              <CommonGridSection value={upc} />
              <CommonEditButton onClick={handleSearchIdentifiers(UPC)} />
            </Grid>
          ) : null }
          {showSection(Ean,EAN) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='ean' label={EAN} value={ean} sectionName={metaData} />
              <CommonGridSection value={ean} />
              <CommonEditButton onClick={handleSearchIdentifiers(EAN)} />
            </Grid>
          ) : null }
          {showSection(Gtin,GTIN) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='gtin' label={GTIN} value={gtin} sectionName={metaData} />
              <CommonGridSection value={gtin} />
              <CommonEditButton onClick={handleSearchIdentifiers(GTIN)} />
            </Grid>
          ) : null }
          {showSection(Gcid,GCID) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='gcid' label={GCID} value={gcid} sectionName={metaData} />
              <CommonGridSection value={gcid} />
              <CommonEditButton onClick={handleSearchIdentifiers(GCID)} />
            </Grid>
          ) :null }
          {showSection(Asin, ASIN)? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='asin' label={ASIN} value={amazonAsin} sectionName={metaData} />
              <CommonGridSection value={amazonAsin} />
              <CommonEditButton onClick={handleSearchIdentifiers(ASIN)} />
            </Grid>
          ) : null }
          {(!isStarred && !searchText) ? (
            <Grid container>
              <Grid item xs={6} className="meta-data-label p-0 pb-2">
                <span>{Flags}</span>
              </Grid>
              <Grid item xs={6} className="p-0 line-height-22 pb-2 mt-2">
                <div className="d-flex justify-content-end edit-main-btn">
                  <PosButton
                    variant="contained"
                    className="btn-discard btn font-size title-edit-section"
                    onClick={handleOpenCloseMetadataFlagesModal}
                  >
                    {edit}
                  </PosButton>
                </div>
              </Grid>
            </Grid>
          ) : null }
          {showSection(canBeDrop,CanbeDropShipped) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='canBeDrop' label={CanbeDropShipped} value={dropshipFlag} sectionName={metaData} />
              <CommonGridSection value={dropshipFlag} metaDataFlags={true}/>
            </Grid>
          ) : null }
          {showSection(isMerquery,ContainsMercury) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='isMerquery' label={ContainsMercury} value={containMercuryFlag} sectionName={metaData} />
              <CommonGridSection value={containMercuryFlag} metaDataFlags={true} />
            </Grid>
          ) : null}
          {showSection(hidePrice,Hidepriceandpreventonlineordering) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='hidePrice' label={Hidepriceandpreventonlineordering} value={hidePriceFlag} sectionName={metaData} />
              <CommonGridSection value={hidePriceFlag} metaDataFlags={true} />
            </Grid>
          ) : null }
          {showSection("hideFromAmazonFeed",HidefromAmazonFeed) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='hideFromAmazonFeed' label={HidefromAmazonFeed} value={hideFromAmazonFeed} sectionName={metaData} />
              <CommonGridSection value={containMercuryFlag} metaDataFlags={true} />
            </Grid>
          ) : null }
          {showSection(Virtual,Virtual)?(
            <Grid container className="report-div">
              <CreateGridStarIcon className='Virtual' label={Virtual} value={isVirtual} sectionName={metaData} />
              <CommonGridSection value={isVirtual} metaDataFlags={true} />
            </Grid>
          ) : null }
          {showSection(hideItem,ClearanceEmailBlast)? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='hideItem' label={ClearanceEmailBlast} value={isForClearanceEmailBlast} sectionName={metaData} />
              <CommonGridSection value={isForClearanceEmailBlast} metaDataFlags={true} />
            </Grid>
          ) : null}
          {(!isStarred && !searchText) ?  (
            <Grid container>
              <Grid item xs={6} className="meta-data-label p-0 pb-2">
                <span>{SEO}</span>
              </Grid>
              <Grid item xs={6} className="p-0 line-height-22 pb-2">
                <div className="d-flex justify-content-end edit-main-btn">
                  <PosButton
                    variant="contained"
                    className="btn-discard btn font-size title-edit-section"
                    onClick={handleOpenCloseMetadataSeoModal}
                  >
                    {edit}
                  </PosButton>
                </div>
              </Grid>
            </Grid>
          ) : null }

          {showSection(Keywords,Keywords) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='Keywords' label={Keywords} value={keywords} sectionName={metaData} />
              <CommonGridSection value={keywords} />
              <CommonEditButton onClick={handleSearchSEO(Keywords)} />
            </Grid>
          ) : null }
          {showSection(Footnote,Footnote)? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='Footnote' label={Footnote} value={footNote} sectionName={metaData} />
              <CommonGridSection value={footNote} />
              <CommonEditButton onClick={handleSearchSEO(Footnote)} />
            </Grid>
          ): null}
          {showSection(Hyperlink,Hyperlink) ? (
            <Grid container className="report-div">
              <CreateGridStarIcon className='Hyperlink' label={Hyperlink} value={hyperlink} sectionName={metaData} />
              <Grid item xs={12} sm={8}>
                <div className="hyperlink-text-black">
                  {/* <span className="int-16-reg pr-0 light-gray-text">{fixedLink}</span> */}
                  {hyperlink}
                </div>
              </Grid>
            </Grid>
          ) : null }
          {showSection(FolderPath,Folder_Path)?(
            <Grid container className="report-div">
              <CreateGridStarIcon className='FolderPath' label={Folder_Path} value={folderPath} sectionName={metaData} />
              <CommonGridSection value={folderPath} />
            </Grid>
          ): null}
          </Grid>
      </section>
    </>
  );
};

export default ProductsDetailsMetadata;
