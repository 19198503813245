import { useRef } from 'react';

const useScrollLock = () => {
    const scrollPos = useRef(null);

    const disableScroll = () => {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.documentElement.style.overflowY = 'scroll';
        document.body.style.overflowX = "initial";
        let querySelectorMain = document.querySelector(".main-content-collapsed");
        if(querySelectorMain) {
            document.querySelector(".main-content-collapsed").style.height = 'auto'; 
        }

        scrollPos.current = document.body.style.top;
    }

    const enableScroll = () => {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        document.documentElement.style.overflowY = '';
        document.body.style.overflowX = "hidden";
        let querySelectorMain = document.querySelector(".main-content-collapsed");
        if(querySelectorMain) {
            document.querySelector(".main-content-collapsed").style.height = 'inherit';
        }
        document.documentElement.style.scrollBehavior = 'auto';
        window.scrollTo({ top: (parseInt(scrollPos.current || '0') * -1), left: 0, behavior: "auto" });
    }

    const toggleScroll = (state) => {
        if (state) {
            enableScroll();
        }
        else {
            disableScroll();
        }
    }

    return {
        toggleScroll
    };
}

export default useScrollLock;