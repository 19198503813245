import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";

import LocationItem from "./LocationItemComponenet";
import LocationPickerEmptyState from "./LocationPickerEmptyStateComponent";

import { title } from "constants/constant";
import { PosResponseProps, RootState } from "models";
import { AppToURLPathName } from "enums";

import { fetcher } from "utils/service";
import { searchFor } from "../helper";
import { getLocalStoraGeItem } from "utils/utils";

const ShelvesList = ({ onItemClick, selectedLocation, query, toLocationShelfDetails, getLocationProductWise, shelves, error, isLoading }) => {

  const { productsData } = useSelector<RootState, PosResponseProps>(
    (state) =>({...state.pos})
  );

  const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {} ;

    const rowURL = useMemo(()=>{
      return getLocationProductWise ? 
      `${process.env.REACT_APP_API_BASE_URL}/${AppToURLPathName.Api}/v2/stock/warehouses/${stockWarehouseId}/products/${productsData.productId}/locations/rows/${selectedLocation.row.id}/units/${selectedLocation.unit.id}/shelves` : 
      `${process.env.REACT_APP_API_BASE_URL}/${AppToURLPathName.Api}/v1/stock/rows/${selectedLocation.row.id}/units/${selectedLocation.unit.id}/shelves?page=1&size=100`
    }, [selectedLocation, getLocationProductWise])

    const {
      data: allShelves = [],
      error: shelvesErrors,
      isLoading: shelvesLoading,
    } = useSWR(
      rowURL,
      fetcher,
      {
        revalidateOnFocus: false,
      },
    );

    const [finalShelves, setFinalShelves] = useState(shelves)

    useEffect(() => {
      if(getLocationProductWise) {
        setFinalShelves(shelves)
      }  
        setFinalShelves(allShelves)
    },[getLocationProductWise, shelves, allShelves])
  
    const filteredShelves = useMemo(()=>{
      if(getLocationProductWise){
        return toLocationShelfDetails ? finalShelves.filter(({stockShelfID}) => stockShelfID !== toLocationShelfDetails.shelfID) : finalShelves
      } else {
        return toLocationShelfDetails ? finalShelves.filter(({shelfID}) => shelfID !== toLocationShelfDetails.shelfID) : finalShelves
      }
    },[finalShelves, toLocationShelfDetails, getLocationProductWise])
  
    if (isLoading || shelvesLoading) return <LocationPickerEmptyState />;
    if (error?.length || shelvesErrors)
      return (
        <span className="location-picker-error-message">
          Unable to load shelves for {selectedLocation.row.name}-
          {selectedLocation.unit.name}
        </span>
      );
  
    return (
      <>
        {searchFor(query, filteredShelves, "shelf")?.length > 0 ?
          searchFor(query, filteredShelves, "shelf").map((shelf) => (
          <LocationItem
            key={shelf.shelfID}
            item={{
              id: shelf.shelfID ? shelf.shelfID : shelf.stockShelfID,
              name: shelf.shelfName,
            }}
            onClick={onItemClick}
            checked={selectedLocation.shelf.id === shelf.shelfID}
          />
          ))
          : 
          <span className="list-header">{title.noDataFound}</span>
      }
      </>
    );
  };

  export default ShelvesList