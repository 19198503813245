import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ItemProps } from "../hooks/useAddShipmentInStorage";

export interface IOrder {
  putInStorageItems: ItemProps[];
  orderId: number | null;
  shipmentId: number | null;
  isApiLoading: boolean;
}

const initialState = {
    putInStorageItems: [],
    orderId: null,
    shipmentId: null,
    isApiLoading: false,
    isDeliveredApiLoading : false,
    isPendingApiLoading : false
};

export const customerOrderSlice = createSlice({
    name: "customerOrderSlice",
    initialState,
    reducers: {
      setPutInStorageItems: (state, action: PayloadAction<any>) => {
        state.putInStorageItems = action.payload;
      },
      setOrderId: (state, action: PayloadAction<number>) => {
        state.orderId = action.payload;
      },
      setShipmentId: (state, action: PayloadAction<number>) => {
        state.shipmentId = action.payload;
      },
      setIsApiLoading: (state, action: PayloadAction<boolean>) => {
        state.isApiLoading = action.payload;
      },
      setIsPendingApiLoading: (state, action: PayloadAction<boolean>) => {
        state.isPendingApiLoading = action.payload;
      },
      setIsDeliveredApiLoading: (state, action: PayloadAction<boolean>) => {
        state.isDeliveredApiLoading = action.payload;
      },
    }
  });
  
  // Action creators are generated for each case reducer function
  export const {
    setPutInStorageItems,
    setOrderId,
    setShipmentId,
    setIsApiLoading,
    setIsPendingApiLoading,
    setIsDeliveredApiLoading
  } = customerOrderSlice.actions;
  
  export default customerOrderSlice.reducer;