import { useMemo, useState } from "react";
import moment from "moment";
import { InputAdornment } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { PosImage, PosInput, PosPageHeading } from "apps/pos/Common/uiCore";
import { Button } from "components";
import HandleLongData from "apps/pos/Common/HandleLongData";
import { SearchIcon } from "constants/icons";
import { dateEnum } from "constants/staticData";
import { RootState } from "models";
import { renamePersonData } from "utils/helper";
import { title } from "constants/constant";
import { PosFlowDataProp } from "constants/interfaces";
import { Paths } from "enums";

import { loadProductDraftAction } from "sagas/persistReducer/actions";

import "./allocatedStockInAllDrafts.scss";

const AllocatedStockInAllDrafts = ({ draftsByProductIdData, cartProductDetails, allocatedDraftRowRef }) => {

  const dispatch = useDispatch();

  const { selectedCartDraftIndex } = useSelector<RootState, PosFlowDataProp>((state) => (state.persistReducer));

  const [searchString, setSearchString] = useState("");

  const handleSearch = (value) => {
    if (
      !value.includes("  ") &&
      value.charAt(0) !== " "
    ) {
      setSearchString(value);
    }
  }

  const currentDateLocal = moment().format(dateEnum.ymdFormat);

  const filterdDraftList = useMemo(() => {
    let tempDraftList = draftsByProductIdData;
    if (searchString) {
      tempDraftList = tempDraftList?.filter(
        (data) =>
          data.jobName
            ?.toLocaleLowerCase()
            ?.includes(searchString.toLocaleLowerCase()) ||
          data.companyName
            ?.toLocaleLowerCase()
            ?.includes(searchString.toLocaleLowerCase()) ||
          data.purchaseOrderNum
            ?.toLocaleLowerCase()
            ?.includes(searchString.toLocaleLowerCase())
      );
    }
    return tempDraftList;
  }, [draftsByProductIdData, searchString]);

  const sortedByDate = filterdDraftList.reduce((groups, draft) => {
    
    const date = moment(draft.createdAt).format(dateEnum.ymdFormat);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(draft);
    return groups;
  }, []);

  const dates = Object.keys(sortedByDate).sort();
  const sortedDateObject = Object.fromEntries(dates.map(date => [date, sortedByDate[date]]));

  const handleProductDraft = (cartId) => () => {
    dispatch(loadProductDraftAction(true))
    window.open(`${Paths.posCart}/${cartId}`, '_blank', 'noreferrer');
  }

  const { allocatedStockInAllDrafts, POLabel } = title;

  return (
    <div className="available-stock-container" ref={allocatedDraftRowRef}>
      <div className="allocated-stock-in-drafts-header">
        <PosPageHeading
          className="available-stock-name"
          heading={allocatedStockInAllDrafts}
          blackHeading
        />
        <div className="available-stock-product">

          <div className="available-stock-image-and-text">
            <div className="available-stock-product-img">
              <PosImage src={cartProductDetails?.thumbImg} className="item-img" alt="item-img" />
            </div>
            <div className="available-stock-product-info">
              <div className="available-stock-product-name">
                {cartProductDetails?.productName}
              </div>
              <div className="available-stock-manufacturer-code">
                {cartProductDetails?.manufacturerCode}
              </div>
            </div>
          </div>
        </div>
        <div className="available-search-input-box">
          <PosInput
            variant="filled"
            id="filled-basic"
            type="text"
            className="document-search-input input-focus-styling"
            autoFocus
            placeholder={`Job name, Customer, PO #`}
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="modal-subheading modal-subheading-delete mb-2">
          <div className="sort-by-sec">
            <span className="fw-medium text-black d-flex sort-by-div">
              DRAFT ORDER
            </span>
          </div>
          <div className="fw-medium delete-all-draft-btn-sec">
            ALLOCATED QTY.
          </div>
        </div>
      </div>
      <div className="allocated-stock-in-drafts-body">
        {filterdDraftList?.length ? (
          Object.keys(sortedDateObject).map((item, index) => {
            const itemDate = (moment(item).fromNow().toUpperCase());
            const checkDate = currentDateLocal === item;
            return (
              <div key={`draft-list-${index}`} id="shippingScrollStyling" className={'searchString' ? '' : 'pt-0'}>
                <div className="d-flex align-items-center mt-20">
                  <div className="date-title">
                    {checkDate
                      ? dateEnum.dateToday + ", " + itemDate
                      : itemDate}
                  </div>
                  <div className="divider-date" />
                </div>
                {sortedDateObject[item]?.map((draft, index) => {
                  const { cartId, jobName, purchaseOrderNum, companyName, qtyOrdered } = draft;
                  return (
                    <div
                      key={`productDraftList-${index}`}
                      ref={allocatedDraftRowRef[index]}
                      id={`allocatedDraftRowRef${index}`}
                    >
                      <div
                        className={`frequently-used-schedule-focus ${
                          index === selectedCartDraftIndex
                            ? "frequently-used-schedule frequently-used-schedule-index pd0"
                            : "frequently-used-schedule "
                        }`
                        }
                      >
                        <Button className="saved-jobname border-0 bg-transparent" onClick={handleProductDraft(cartId)}>
                          <h3 className="select-schedule-list select-list-data d-flex flex-wrap text-start">
                            <HandleLongData jobName={jobName} />
                          </h3>
                          <div className="d-flex align-items-center mt-1">
                            <span className="int-16-reg-gray">
                              {renamePersonData(companyName)}
                            </span>
                          </div>
                          <div className="d-flex align-items-center mt-1">
                            <span className="int-12-reg-gray">
                              {cartId}
                            </span>
                            <div className="draft-badge" />
                            <span className="int-12-reg-gray d-flex w-100">
                              <span className="mr-1 min-width-fit-content">{POLabel}</span><HandleLongData jobName={purchaseOrderNum} />
                            </span>
                          </div>
                        </Button>

                        <div>
                          {qtyOrdered}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })
        ) : (null)}
      </div>
    </div>
  );
};

export default AllocatedStockInAllDrafts;
