import { useEffect, useState } from "react";

import { Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Typography } from "@material-ui/core";
import Select from '@mui/material/Select';

const AddDocumentFor = ({ filteredOptions, addCompanyIds, preValue }) => {
    const [values, setValues] = useState<string[]>([]);
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(true); // Control open state

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 350,
            },
        },
    };

    useEffect(() => {
        setValues(preValue ?? []);
        if (preValue?.length) {
            setOpen(false);
        }
    }, []);

    const renderValue = (selected) => (
        <div>
            {selected.map((value) => (
                <Chip key={value} label={value} />
            ))}
        </div>
    );

    const handleChange = (event) => {
        const newValues = event.target.value;
        addCompanyIds(newValues)
        setValues(newValues);
    };

    const handleSelectAll = (event) => {
        event.stopPropagation(); // Stop event propagation
        const allValues = filteredOptions.map(option => option.companyID);
        setValues(allValues);
        addCompanyIds(allValues);
    };

    const handleDeselectAll = (event) => {
        event.stopPropagation(); // Stop event propagation
        setValues([]);
        addCompanyIds([]);
    };

    useEffect(() => {
        if (filteredOptions?.length === values.length) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [values, filteredOptions]);

    const showOneCompanyName = () => {
        const matchedObject = filteredOptions.find(obj => obj.companyID === values[0]);
        // If a match is found, return the name; otherwise, return 
        return matchedObject ? matchedObject.companyName : "";
    }

    return (
        <div className="add-document-list-select-dropdown">
            <FormControl>
                <InputLabel >{values.length > 1 ? `${values.length} locations selected` : values.length === 1 ? showOneCompanyName() : 'Select Location'}</InputLabel>

                <Select
                    multiple
                    value={values}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                    renderValue={renderValue}
                    id={'select-location'}
                    open={open} // Controls whether the dropdown is open or closed
                    onClose={() => setOpen(false)} // Handle closing the dropdown
                    onOpen={() => setOpen(true)} // Handle opening the dropdown
                >
                    <div className="main-label-select-location">
                        <Typography variant="body2">{`${values.length} selected`}</Typography>
                        {show ?
                            <Typography variant="body2" className="selection" onClick={handleDeselectAll}>
                                Deselect all
                            </Typography>
                            : <Typography variant="body2" className="selection" onClick={handleSelectAll}>
                                Select All
                            </Typography>}
                    </div>
                    {filteredOptions.map((key) => {
                        return (
                            <MenuItem
                                key={key.companyID}
                                value={key.companyID}
                                className={`add-document-list-filter-options cursor-pointer`}
                            >
                                <Checkbox checked={values.includes(key.companyID)} />
                                <ListItemText primary={key.companyName} secondary={key.buildingName} />
                                {key.taxExemptFlag ? <div className="show-exempt-label">
                                    <span>exempt</span>
                                </div> : null}
                            </MenuItem>
                        );
                    })}

                </Select>
            </FormControl>
        </div>
    );
};

export default AddDocumentFor;
