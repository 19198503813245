import TextareaAutosize from "react-autosize-textarea";

import { PropsPOSTextareaAutosize } from "./type";

import "./POSTextAreaAutoResizeStyle.scss";

const POSTextareaAutosize = ({
  placeholder = "",
  id,
  name,
  className,
  value,
  onChange,
  onFocus,
  onBlur
}: PropsPOSTextareaAutosize) => (
  <div className="pos-auto-size-text-area-container">
    <TextareaAutosize
      id={id}
      name={name}
      className={`${className} pos-auto-size-text-area`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  </div>
);

export default POSTextareaAutosize;
