import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { ErrorMessage } from "apps/pos/formik/errorMessage";
import { UpdatedMeasurementsProductDetailSchema } from "apps/pos/formik/validationSchema";
import { PosButton, PosImage, PosInput, PosSelect } from "apps/pos/Common/uiCore";
import useProductsDetailsHooks from "../../modules/productDetailsContainer/hooks/mutationHooks";

import images from "constants/images";
import { commonConstants } from "../../helper/commonConstants";
import { authorizationToken, blockInvalidChar, getLocalStoraGeItem, setFocusTo } from "utils/utils";
import { labels } from "../../modules/productDetailsContainer/constants";
import IconButton from 'apps/product/Modals/Elements/IconButton'

import { getProductsStockMinMaxStart } from "sagas/productsDetails/productStock.slice";
import { updateMeasurementsProductStart, removeMinMaxBatchProductStart } from "sagas/productsDetails/singleProductDetails.slice";

import "./ProductDetailsModals.scss";
import "../ProductDetailsCommonModal.scss";


const ProductDetailsModals = ({
    open,
    onClose,
    workStationId,
    setWorkStationId,
    updateMeasurementsProduct,
    getSingleProductData,
    distributor,
    getProductsStockMinMaxData,
    focusText,
    removeFocus
}) => {
    const {
        getProductMinMaxStockMutation,
        updateMeasurementsProductsMutation,
        removeMinMaxBatchesProductsMutation,
    } = useProductsDetailsHooks();

    const dispatch = useDispatch();
    const params = useParams();
    
    const { Measurements } = labels;
    const { deleteDarkGrayIcon, deleteHoverDarkGrayIcon } = images
    const { priceUOM, unitPerCases, minimumToOrder, MIN, MAX, INCREMENT, StockMinMax, Weight, Back, SaveChanges } = commonConstants;

    const { details } = getSingleProductData || {}
    const { productId, measurements } = details || {}
    const { weight, unitPerCase, minimumCases, priceMU, muName, stockUnitPerCase, itemMU, itemMuName } = measurements || {}
    const { minLevel, maxLevel, increment, id: minMaxStockId } = getProductsStockMinMaxData || {}
    const { stockWarehouseId } = getLocalStoraGeItem("workspace") || {};

    const [workStationArrayData, setWorkStationArrayData] = useState([]);

    useEffect(() => {
        const workstationArray = [];

        distributor?.warehouses.map((location) => {
            const object = {
                stockWarehouseId: location?.stockWarehouseId,
                warehouseName: location?.label,
            };

            workstationArray.push(object);

            setWorkStationArrayData(workstationArray);
        });
        setWorkStationId(stockWarehouseId);
    }, []);

    const workstationChangeEvent = (e, setFieldValue, setFieldTouched) => {
        setWorkStationId(parseInt(e.target.value));
        minMaxApiCall(e.target.value)
        setFieldValue("workStationId", e.target.value)
        setFieldValue("min", "");
        setFieldValue("max", "");
        setFieldValue("increment", "")
        setFieldTouched("workStationId", true);
    };

    const minMaxApiCall = (id) => {
        dispatch(getProductsStockMinMaxStart())
        const payloadForStockMinMax = { stockWarehouseID: id, productID: Number(params?.id) }
        getProductMinMaxStockMutation.mutate(payloadForStockMinMax);
    }

    const handleClose = (resetForm) => () => {
        resetForm();
        onClose();
    }

    const removeStockMinMax = (setFieldValue) => () => {
        setFieldValue("min", "");
        setFieldValue("max", "");
        setFieldValue("increment", "")
        dispatch(removeMinMaxBatchProductStart())
        removeMinMaxBatchesProductsMutation.mutate(minMaxStockId)
    }
    
    return (
        <Formik
        initialValues={{
            unitPerCase: unitPerCase,
            minimumCases: minimumCases,
            weight: weight ? weight : "",
            workStationId: workStationId,
            min: minLevel,
            max: maxLevel,
            increment: increment,
                priceMu: priceMU,
                muName: muName
            }}
            validationSchema={UpdatedMeasurementsProductDetailSchema}
            enableReinitialize
            onSubmit={(values) => {
                const { DefaultLoginId } = authorizationToken("Authorization") || {};
                const { weight, priceMu, muName, minimumCases, unitPerCase, min, max, increment } = values
                const body = {
                    productId: productId,
                    handlerId: DefaultLoginId,
                    weight: weight ? Number(weight) : 0,
                    priceMU: priceMu ? Number(priceMu) : 0,
                    muName: muName,
                    itemMU: itemMU,
                    itemMuName: itemMuName,
                    minimumCases: minimumCases ? Number(minimumCases) : 0,
                    unitPerCase: unitPerCase ? Number(unitPerCase) : 0,
                    stockUnitPerCase: stockUnitPerCase,
                    stockItemMinMax: {
                        stockWarehouseId: workStationId,
                        productId: productId,
                        minLevel: min ? Number(min) : 0,
                        maxLevel: max ? Number(max) : 0,
                        increment: increment ? Number(increment) : 0,
                        id: minMaxStockId || 0,
                        handlerId: DefaultLoginId
                    }
                }
                dispatch(updateMeasurementsProductStart());
                updateMeasurementsProductsMutation.mutate(body);
            }}
        >
            {({ values, setFieldValue, resetForm, handleSubmit, errors, touched, dirty, setFieldTouched, }) => {
                const { unitPerCase, minimumCases, weight, min, max, increment, priceMu, muName } = values

                return (
                    <>
                        <Dialog
                            className='overview-dialog'
                            open={open}
                            onClose={onClose}
                        >
                            <div className="dialog-header">
                                <h3>{Measurements}</h3>
                                <IconButton
                                    classes='close-btn'
                                    secondary={true}
                                    title='Close'
                                    icon='close'
                                    onClick={handleClose(resetForm)}
                                />
                            </div>
                            <DialogContent>
                                <div className="product-details-notes-main-content-section table-select pt-0">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {StockMinMax}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosSelect
                                                className=" product-detail-select"
                                                name="workStationId"
                                                variant="filled"
                                                id={StockMinMax}
                                                value={workStationId}
                                                onChange={(e) => workstationChangeEvent(e, setFieldValue, setFieldTouched)}
                                                options={
                                                    workStationArrayData?.length &&
                                                    workStationArrayData.map(
                                                        ({
                                                            warehouseName,
                                                            stockWarehouseId,
                                                        }) => {
                                                            return {
                                                                label: warehouseName,
                                                                value: stockWarehouseId,
                                                            };
                                                        }
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pb-5">
                                    <div className="characteristics-main-info">
                                        <div className="w-100 d-flex product-detail-stock-main-div">
                                            <div className="product-detail-search-input">
                                                <div className="product-detail-partial-sec">
                                                    <span>
                                                        {MIN}
                                                    </span>
                                                </div>
                                                <PosInput
                                                    variant="filled"
                                                    id={StockMinMax}
                                                    type="number"
                                                    className="product-detail-counter-sec first-counter-sec"
                                                    value={min}
                                                    name="min"
                                                    onChange={(e) => {
                                                        setFieldValue("min", e.target.value)
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                    onKeyDown={(e) => blockInvalidChar(e)}
                                                    onWheel={(e) => (e?.target)?.blur()}
                                                />
                                            </div>
                                            <div className="product-detail-search-input">
                                                <div className="product-detail-partial-sec">
                                                    <span>
                                                        {MAX}
                                                    </span>
                                                </div>
                                                <PosInput
                                                    variant="filled"
                                                    id={MAX}
                                                    type="number"
                                                    className="product-detail-counter-sec second-counter-sec"
                                                    value={max}
                                                    name="max"
                                                    onChange={(e) => {
                                                        setFieldValue("max", e.target.value)
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                    onKeyDown={(e) => blockInvalidChar(e)}
                                                    onWheel={(e) => (e?.target)?.blur()}
                                                />
                                            </div>
                                            <div className="product-detail-search-input">
                                                <div className="product-detail-partial-sec">
                                                    <span>
                                                        {INCREMENT}
                                                    </span>
                                                </div>
                                                <PosInput
                                                    variant="filled"
                                                    id={INCREMENT}
                                                    type="number"
                                                    className="product-detail-counter-sec third-counter-sec"
                                                    value={increment}
                                                    name="increment"
                                                    onChange={(e) => {
                                                        setFieldValue("increment", e.target.value)
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                    onKeyDown={(e) => blockInvalidChar(e)}
                                                    onWheel={(e) => (e?.target)?.blur()}
                                                />
                                            </div>
                                            <div className="product-detail-stock-delete-sec">
                                                <div className="product-detail-partial-sec">
                                                    <span>

                                                    </span>
                                                </div>
                                                <div className="hover-with-delete-productDetail" onClick={removeStockMinMax(setFieldValue)}>
                                                    <PosImage
                                                        src={deleteDarkGrayIcon}
                                                        alt="delete-icon"
                                                        className="delet-order-img"
                                                    />
                                                    <PosImage
                                                        src={deleteHoverDarkGrayIcon}
                                                        alt="delete-icon"
                                                        className="delet-order-hover-img"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {(errors.min || errors.max || errors.increment) ? (
                                            <div className="validation-error-text-sp  mt-2 int-error-text mb-0">
                                                {errors.min || errors.max || errors.increment}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="product-details-notes-main-content-section">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {priceUOM}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100 d-flex product-detail-stock-main-div">
                                            <div className="product-detail-search-input">
                                                <PosInput
                                                    variant="filled"
                                                    id={priceUOM}
                                                    type="number"
                                                    className="product-detail-counter-sec first-counter-sec"
                                                    value={priceMu}
                                                    name="priceMu"
                                                    onChange={(e) => {
                                                        setFieldValue("priceMu", e.target.value)
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                    onKeyDown={(e) => blockInvalidChar(e)}
                                                    onWheel={(e) => (e?.target)?.blur()}
                                                    inputRef={i => i && focusText === priceUOM && setFocusTo(i,removeFocus)}
                                                />
                                            </div>
                                            <div className="product-detail-search-input">
                                                <PosInput
                                                    variant="filled"
                                                    id="filled-basic"
                                                    type="text"
                                                    className="product-detail-counter-sec second-counter-sec"
                                                    value={muName}
                                                    name="muName"
                                                    onChange={(e) => {
                                                        setFieldValue("muName", e.target.value)
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {((errors.priceMu && touched.priceMu) || (errors.muName && touched.muName)) ? (
                                        <div className="validation-error-text-sp  mt-2 int-error-text mb-0">
                                            <>{errors.priceMu || errors.muName}</>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="product-details-notes-main-content-section">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {unitPerCases}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={unitPerCases}
                                                type="number"
                                                className="product-detail-search-input input-focus-styling"
                                                value={unitPerCase}
                                                name="unitPerCase"
                                                onChange={(e) => {
                                                    setFieldValue("unitPerCase", e.target.value)
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                onKeyDown={(e) => blockInvalidChar(e)}
                                                onWheel={(e) => (e?.target)?.blur()}
                                                inputRef={i => i && focusText === unitPerCases && setFocusTo(i,removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="unitPerCase" />
                                </div>
                                <div className="product-details-notes-main-content-section">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {minimumToOrder}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={minimumToOrder}
                                                type="number"
                                                className="product-detail-search-input input-focus-styling"
                                                value={minimumCases}
                                                name="minimumCases"
                                                onChange={(e) => {
                                                    setFieldValue("minimumCases", e.target.value)
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                onKeyDown={(e) => blockInvalidChar(e)}
                                                onWheel={(e) => (e?.target)?.blur()}
                                                inputRef={i => i && focusText === minimumToOrder && setFocusTo(i,removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="minimumCases" />
                                </div>
                                <div className="product-details-notes-main-content-section">
                                    <div className="notes-added-user-name">
                                        <span>
                                            {Weight}
                                        </span>
                                    </div>
                                    <div className="characteristics-main-info">
                                        <div className="w-100">
                                            <PosInput
                                                variant="filled"
                                                id={Weight}
                                                type="number"
                                                className="product-detail-search-input input-focus-styling"
                                                onChange={(e) => {
                                                    setFieldValue("weight", e.target.value)
                                                }}
                                                name="weight"
                                                value={weight}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                onKeyDown={(e) => blockInvalidChar(e)}
                                                onWheel={(e) => (e?.target)?.blur()}
                                                inputRef={i => i && focusText === Weight && setFocusTo(i,removeFocus)}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="weight" />
                                </div>
                            </DialogContent>
                            <DialogActions className="notes-model-footer">
                                <div className="notes-footer-wrapper d-flex notes-btn-div product-detail-modal-footer-spacing justify-content-end">
                                    <PosButton
                                        variant="contained"
                                        onClick={handleClose(resetForm)}
                                        className="back-button first-product-detail-btn"
                                    >
                                        {Back}
                                    </PosButton>
                                    <PosButton
                                        fontWeight
                                        className={`btn ml-2 second-product-detail-btn ${updateMeasurementsProduct ? "button-accent button-is-loading-black" : ""}`}
                                        variant="contained"
                                        color="primary"
                                        disabled={updateMeasurementsProduct || !dirty}
                                        onClick={() => handleSubmit()}
                                    >
                                        {SaveChanges}
                                    </PosButton>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </>
                );
            }}
        </Formik>
    );
}

export default ProductDetailsModals;