import { useDispatch } from "react-redux";

import { PosModel } from "apps/pos/Common/uiCore";
import ImportQuote from "./importQuote/importQuote";
import SelectItemImport from "./selectItemImport";

import { arrowResetProductPage } from "sagas/pos/actions";
import { arrowResetImportQuote } from "sagas/pos/importQuotes/action";

import "./importQuote.scss";


interface Props {
  open: boolean;
  setOpenModel: (e: boolean) => void;
  setCartEmpty?: (e: boolean) => void;
  suggestionListVisible?: (e: boolean) => void;
  setIsSnackbarVisible: (e: boolean) => void;
  importQuotePageProductSearchRef?: React.MutableRefObject<any>
  selectItemImportModel?: boolean
  setSelectItemImportModel?: (value: boolean) => void
}

const ImportQuoteModel = ({
  open,
  setOpenModel,
  setCartEmpty,
  suggestionListVisible,
  setIsSnackbarVisible,
  importQuotePageProductSearchRef,
  selectItemImportModel,
  setSelectItemImportModel
}: Props) => {
  const dispatch = useDispatch();

  const handleModalClose = () =>{
    setOpenModel(false);
    setSelectItemImportModel(false);
    dispatch(arrowResetProductPage());
    dispatch(arrowResetImportQuote());
  }
  
  return (
    <>
      <PosModel
        open={open}
        onClose={handleModalClose}
        dialogClassName="quote-model-main-container"
      >
        {!selectItemImportModel && (
          <ImportQuote
            closeModel={() => setOpenModel(false)}
            changeItemView={() => setSelectItemImportModel(true)}
            importQuotePageProductSearchRef={importQuotePageProductSearchRef}
          />
        )}
        {selectItemImportModel && (
          <SelectItemImport
            closeModel={() => setOpenModel(false)}
            backButtonOnClick={() => setSelectItemImportModel(false)}
            setCartEmpty={setCartEmpty}
            suggestionListVisible={suggestionListVisible}
            setIsSnackbarVisible={setIsSnackbarVisible}
          />
        )}
      </PosModel>
    </>
  );
};
export default ImportQuoteModel;
